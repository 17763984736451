////////////////////////////////////////////////////////////
//     							                          //
//  Program: GooglePlaces.jsx                             //
//  Application: Common                                   //
//  Option: address autocomplete                          //
//  Developer: Jigar Soni , NP  						  //
//  Date: 2022-08-02                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import GoogleLogo from "../../assets/images/powered-by-google.png";

function GooglePlacesW9(props) {
  const [address, setAddress] = useState("");
  const [unit, setUnit] = useState(props.unit ? props.unit : "");
  const [street1, setStreet1] = useState("");
  // const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [county, setCounty] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipCode] = useState("");

  let componentType = "";
  useEffect(() => {
    let street1_data = "";
    let city_data = "";
    let state_data = "";
    let county_data = "";
    let country_data = "";
    let zipcode_data = "";
    setAddress((unit ? unit+", " : '')+(street1 ? street1+", " : '')+(city ? city+", " : '')+(state ? state+", " : '')+(county ? county+", " : '')+(country ? country+", " : '')+(zipcode ? zipcode : ''));
    props.getChildData1(unit,street1?street1:street1_data,city?city:city_data,state?state:state_data,county?county:county_data,country?country:country_data,zipcode?zipcode:zipcode_data)
  }, [unit,street1,city,state,county,country,zipcode]);
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    // let unit_data = "";
    let street1_data = "";
    let city_data = "";
    let state_data = "";
    let county_data = "";
    let country_data = "";
    let zipcode_data = "";

    results[0].address_components.map((item) => {
      componentType = item.types[0];
      switch (componentType) {
        case "route":
          setStreet1(item.long_name ? item.long_name : item.short_name);
          street1_data = item.long_name ? item.long_name : item.short_name;
          break;
        // case 'neighborhood':
        //   setStreet2(item.short_name);
        //   break;
        case "locality":
          setCity(item.long_name ? item.long_name : item.short_name);
          city_data = item.long_name ? item.long_name : item.short_name;
          break;
        case "administrative_area_level_2":
          setCounty(item.long_name ? item.long_name : item.short_name);
          county_data = item.long_name ? item.long_name : item.short_name;
          break;
        case "administrative_area_level_1":
          setState(item.long_name ? item.long_name : item.short_name);
          state_data = item.long_name ? item.long_name : item.short_name;
          break;
        case "country":
          setCountry(item.long_name ? item.long_name : item.short_name);
          country_data = item.long_name ? item.long_name : item.short_name;
          break;
        case "postal_code":
          // setZipCode(item.short_name ? item.short_name : "");
          // zipcode_data = item.short_name ? item.short_name : "";
          setZipCode(item.short_name ? item.short_name :"");
          zipcode_data = item.short_name ? item.short_name : "";
          break;
        default:
          return "";
      }
    });
   
     props.getChildData1(unit,street1_data,city_data,state_data,county_data,country_data,zipcode_data);
     setUnit(unit);
    // console.log("testzip",zipcode_data);
    // setZipCode(zipcode);
  };
// console.log("test street",unit)
  const searchOptions = {
    componentRestrictions: { country: ["us"] },
    fields: ["address_components", "geometry", "postal_code"],
    types: ["address"],
  };

  const renderFooter = () => (
    <div className="dropdown-footer">
      <div>
        <img src={GoogleLogo} alt="Powered by Google" />
      </div>
    </div>
  );

  return (
    <div className="row pd-b-5 border-bottom-0 mg-t-0 pd-lg-l-0 pd-lg-r-15 pd-xs-l-15 w-100 p-0">
      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-9 col-xxl-9 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15  mg-b-15">
        
        <PlacesAutocomplete
          value={address}
          // type ={hidden}
          onChange={setAddress}
          onSelect={handleSelect}
          searchOptions={searchOptions}
          renderFooter={renderFooter}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <>
              <div className="input-group-prepend prefix-dollar google_icon_pre">
                <span className="input-group-text percanteg_symbl ">
                  {/* <i className="fas fa-map-marker-alt	"></i> */}
                </span>
                <input {...getInputProps({ placeholder: "Address" })} hidden />
              </div>

              {loading ? <div>...loading</div> : null}
              {suggestions.map((suggestion, i) => {
                const style = {
                  backgroundColor: suggestion.active ? "#000" : "#fff",
                  color: suggestion.active ? "#fff" : "#000",
                  padding: "8px 12px",
                };
                return (
                  <div
                    key={i}
                    {...getSuggestionItemProps(suggestion, { style })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
             
            </>
          )}
        </PlacesAutocomplete>
      </div>
      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
        <label className="form-label text-left pd-b-5">
          Street Address
        </label>
        <Form.Control
          onChange={(event) => setStreet1(event.target.value)}
          type="text"
          name="street_1"
          // value={props.street1 ? props.street1 : (street1 || "")}
          value={street1 ? street1 : props.street1 ? props.street1 : ""}
        />
      </div>
      {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-r-30 mg-b-15">
          <label className="form-label text-left  pd-b-5">
              Street 2 <span className="optional">(Optional)</span>
          </label>
          <Form.Control
              type="text"
              onChange={setStreet2}
              name="street_2"
              value={street2 || ""}
          />
          </div> */}
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
        <label className="form-label text-left pd-b-5">
          Apt. no./House no. <span className="optional">(if applicable)</span>
        </label>
        <Form.Control
          onChange={(event) => setUnit(event.target.value)}
          type="text"
          name="unit"
          value={unit ? unit : props.unit ? props.unit : ""}
          maxLength="20"
        />
      </div>
      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
        <label className="form-label text-left pd-b-5">
          City 
        </label>
        <Form.Control
          onChange={(event) => setCity(event.target.value)}
          type="text"
          name="city_name"
          value={city ? city : props.city ? props.city : ""}
          autoComplete="off"
        />
      </div>
      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
        <label className="form-label text-left pd-b-5">
          Zip Code 
          {/* <span className="text-danger">*</span> */}
        </label>
        <Form.Control
          onChange={(event) => setZipCode(event.target.value)}
          type="text"
          name="zipcode"
          value={zipcode ? zipcode : props.zipcode ? props.zipcode : ""}
          maxLength="5"
          onKeyPress={global.onKeyPressEvent.numberValidation}
        />
      </div>
      {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
        <label className="form-label text-left pd-b-5">
          County
          <span className="optional">(Optional)</span>
        </label>
        <Form.Control
          onChange={(event) => setCounty(event.target.value)}
          type="text"
          name="county_name"
          value={county ? county : props.county ? props.county : ""}
          autoComplete="off"
        />
      </div> */}
      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
        <label className="form-label text-left pd-b-5">
          State 
        </label>
        <Form.Control
          onChange={(event) => setState(event.target.value)}
          type="text"
          name="state_name"
          value={state ? state : props.state ? props.state : ""}
          autoComplete="off"
        />
      </div>
      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15  mg-b-15">
        <label className="form-label text-left pd-b-5">
          Country 
        </label>
        <Form.Control
          onChange={(event) => setCountry(event.target.value)}
          type="text"
          name="country_name"
          value={country ? country : props.country ? props.country : ""}
          autoComplete="off"
        />
      </div>
    </div>
  );
}

export default GooglePlacesW9;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: UnitsView.jsx                         //
//  Application: UnitsView                             //
//  Option: For view Units View details                //
//  Developer: Ashish Kumar 				              //
//  Date: 2022-02-09                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import UnitsService from "../../services/UnitsService";
import Basic from "../unit/Basic";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import unitsImage from "./../../assets/images/units_placeholder.png";
import UnitCost from "./UnitCost";
import UnitDocuments from "./UnitDocuments";
import PropertySidebar from "../common/PropertySidebar";
import UnitCredential from "./UnitCredential";
import UnitAmenities from "./UnitAmenities";

export class UnitsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: false,
      loading: false,
      toggleSwitchDark2: true,
      status_id: 1,
      open: true,
      eventKey: "4",
      slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      imageUrl: unitsImage,
      confirm_back: 0,
      activeClass: "basic",
      unit_number: "",
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.getConfirmStatus = this.getConfirmStatus.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.getImgStatus = this.getImgStatus.bind(this);
  }

  async componentDidMount() {
    if (this.state.rooms_list === "rooms") {
      document.getElementById("roomsListTab").click();
    }
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await UnitsService.getUnits(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        //   resData = res.data ? res.data : {};
        resData.imageUrl = res.data.unit_main_image_url
          ? res.data.unit_main_image_url
          : unitsImage;
        resData.unit_number = res.data.unit_number ? res.data.unit_number : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  getImgStatus(imgFile) {
    this.setState({ imageUrl: imgFile });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return (
        "Units" + (this.state.unit_number ? " : " + this.state.unit_number : "")
      );
    } else {
      return "Units";
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 info_box_model">
                    <h3>{this.getTitle()}</h3>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-0">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="basic"
                    onSelect={(e) => this.getActiveClass(e)}
                    eventKey={this.state.activeClass}
                  >
                    <ScrollingCarousel
                      rightArrow={true}
                      leftArrow={true}
                      className="swipesection scroll_nav_mob"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="basic"
                          className={
                            this.state.activeClass === "basic" ? "active" : ""
                          }
                        >
                          Basic
                        </Nav.Link>
                      </Nav.Item>
                      {global.userPermissions.checkPermission("units-update") ? <>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="unitsAmenities"
                          className={
                            this.state.activeClass === "unitsAmenities"
                              ? "active"
                              : ""
                          }
                        >
                          Amenities
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="unitsFees"
                          className={
                            this.state.activeClass === "unitsFees"
                              ? "active"
                              : ""
                          }
                        >
                          Fees
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="unitsDocuments"
                          className={
                            this.state.activeClass === "unitsDocuments"
                              ? "active"
                              : ""
                          }
                        >
                          Documents
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="unitscredential"
                          className={
                            this.state.activeClass === "unitscredential"
                              ? "active"
                              : ""
                          }
                        >
                         Credentials
                        </Nav.Link>
                      </Nav.Item>
                      </> :""}
                    </ScrollingCarousel>

                    <Tab.Content>
                      <Tab.Pane eventKey="basic">
                        <Basic
                          slug={this.state.slug}
                          property_slug={this.state.property_slug}
                          imgFile={this.state.imageUrl}
                        />
                      </Tab.Pane>
                     
                      <Tab.Pane eventKey="unitsAmenities">
                        <UnitAmenities
                          unit_slug={this.state.slug}
                          property_slug={this.state.property_slug}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="unitsFees">
                        <UnitCost
                          unit_slug={this.state.slug}
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="unitsDocuments">
                        <UnitDocuments
                          unit_slug={this.state.slug}
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}
                        />
                      </Tab.Pane>
                      {global.userPermissions.checkPermission('units-update') &&
                      <Tab.Pane eventKey="unitscredential">
                        <UnitCredential
                          unit_slug={this.state.slug}
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}
                        />
                      </Tab.Pane>
                      }
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default UnitsView;

//     							                               //
//  Program: Roles.jsx                                         //
//  Application: roles                                         //
//  Option: Users Listing                                      //
//  Developer: Mitesh Prajapati 		                       //
//  Date: 2022-08-10                                           //
//                                                             //
/////////////////////////////////////////////////////////     ///

import React, { Component } from "react";
import { Container, Nav, Tab, Table } from "react-bootstrap";
// import SettingSidebar from "../common/SettingSidebar";
import RolePermissionService from "../../services/RolePermissionService";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import AuthenticationService from "../../services/AuthenticationService";
// import PhoneNumberFormat from "../common/PhoneNumberFormat";
import ReactDatatable from "@ashvin27/react-datatable";
import NoData from "../common/NoData";
import Alerts from "../common/Alerts";
import UsersSidebar from "../common/UsersSidebar";

class RolePermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      showSpinner: false,
      loading: false,
      input: {},
      errors: {},
      activeClass: "alerts",
      deleteRoleModal: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      roleData: "",
      role_title: "",
      role_group_title: "",
      roleGroupList: [],
      total_record: 0,
      dataList: [],
      records: [],
      select_all: false,
      role_group: "",
      UserGroupList: [],
      selectedRoleGroupList: [],
      slug: this.props.match.params.slug,
      defaultLandingPageList: [
        { label: "Dashboard", value: 1 },
        { label: "Building Onboarding", value: 3 },
        { label: "Properties", value: 4 },
        { label: "Snapshot", value: 2 },
      ],
      selectedDefaultLandingPageList: [],
      columns: [
        {
          key: "user_name",
          text: "Name",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.first_name + " " + record.last_name;
          },
        },
        {
          key: "email",
          text: "Email",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.email;
          },
        },
        {
          key: "phone_number",
          text: "Phone",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber = record.phone_number
              ? record.phone_number.replace(/[\s-]/g, "")
              : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits = record.phone_number ? record.phone_number : [];
            }

            var number = record.phone_number
              ? lastTenDigits.trim().replace(/[^0-9]/g, "")
              : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.phone_number ? finalnumber : "";
          },
        },
        {
          key: "role_group",
          text: "Role Group",
          className: "hand-cursor",
          sortable: false,
          cell: (record) => {
            return this.state.UserGroupList.map((dataList) => {
              if (dataList.value === record.role_group) {
                return dataList.key;
              }
            });
          },
        },
      ],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
    };
    this.handleChangeRoleGroupList = this.handleChangeRoleGroupList.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.changeHandlerSelectAll = this.changeHandlerSelectAll.bind(this);
    this.handleCheckboxChangeSelectAll =
      this.handleCheckboxChangeSelectAll.bind(this);
    this.saveRolePermission = this.saveRolePermission.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangeDefaultLandingPageList =
      this.handleChangeDefaultLandingPageList.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.userData();
  }

  // to get data of roles
  async getData() {
    this.setState({ showSpinner: true, loading: true });
    let dataList = [];
    let roleGroupList = [];
    let role_group_title = "";
    let role_title = "";
    let role_group = "";
    let selectAllChecked = false;
    let res = await RolePermissionService.getRolePermissionStatusList(
      this.state.slug
    );
    if (res.data.length <= 0) {
      this.props.history.push("/roles");
    } else {
      if (global.successStatus.includes(res.status)) {
        dataList = res.data ? res.data.data : [];
        dataList.forEach((item) => {
          let allPermissionsChecked = item.permission.every(
            (perm) => perm.status_id === 1
          );
          if (allPermissionsChecked) {
            item.status_id = true;
          } else {
            item.status_id = false;
          }
        });

        selectAllChecked = dataList.every((item) => item.status_id === true);

        role_group_title = res.data.roleGroupName;
        role_group = res.data.roleData.role_group;
        role_title = res.data.roleData.role_title;
      }
      var count = 0;
      res.data.roleGroupList.map((item, i) => {
        var groupList = [];
        groupList.value = item.value;
        groupList.label = item.key;
        roleGroupList[count] = groupList;
        count++;
      });
      var selectedRoleGroupList = [];
      selectedRoleGroupList = res.data.roleData
        ? [
            {
              value: res.data.roleData.role_group,
              label: role_group_title,
            },
          ]
        : "";
      var selectedDefaultLandingPageList = [];
      selectedDefaultLandingPageList = res.data.roleData
        ? [
            {
              value: res.data.roleData.default_landing_page,
              label:
                res.data.roleData.default_landing_page == 1
                  ? "Dashboard"
                  : res.data.roleData.default_landing_page == 2
                  ? "Snapshot"
                  : res.data.roleData.default_landing_page == 3
                  ? "Building Onboarding"
                  : res.data.roleData.default_landing_page == 4
                  ? "Properties"
                  : "",
            },
          ]
        : "";
      this.setState({
        showSpinner: false,
        loading: false,
        role_group: role_group,
        roleData: res.data.roleData,
        roleGroupList: roleGroupList,
        dataList: dataList,
        selectedRoleGroupList: selectedRoleGroupList,
        selectedDefaultLandingPageList: selectedDefaultLandingPageList,
        role_group_title: role_group_title,
        role_title: role_title,
        select_all: selectAllChecked,
      });
    }
  }

  async userData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    var UserGroupList = [];
    let res = await AuthenticationService.getUserList(
      (queryString = ""),
      this.state.slug
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.userData.data : [];
      UserGroupList = res.data ? res.data.groupList : [];
      totalRecords = list ? res.data.userData.total : 0;
      //totalRecords = res.data ? res.data.total : 0
    }

    this.setState({
      showSpinner: false,
      loading: false,
      records: list,
      total_record: totalRecords,
      UserGroupList: UserGroupList,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ [event.target.name]: event.target.value, input: input });
    // Validate
    //this.validator.showMessageFor(event.target.name);
    //this.customValidate();
    this.setState({ errors: "" });
  };

  handleChangeDefaultLandingPageList(value) {
    var selectedLandingPageValue = [{ value: value.value, label: value.label }];
    this.setState({
      selectedDefaultLandingPageList: selectedLandingPageValue,
      default_landing_page: value.value,
    });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  // handleCheckboxChange = (e, x, j) => {
  //   const { name } = e.target;
  //   var value = e.target.checked ? 1 : 0;
  //   const dataList = [...this.state.dataList];
  //   dataList[x]["permission"][j][name] = value;
  //   var isAllSelected = 1;
  //   dataList[x].permission.forEach((item, i) => {
  //     if (item.status_id !== 1) {
  //       isAllSelected = 0;
  //     }
  //   });
  //   dataList[x]["status_id"] = isAllSelected;
  //   this.setState({ dataList: dataList });
  // };

  handleCheckboxChange = (e, i, j) => {
    const checked = e.target.checked;
    const { dataList } = this.state;
    dataList[i].permission[j].status_id = checked;

    const allPermissionsSelected = dataList[i].permission.every(
      (perm) => perm.status_id
    );
    dataList[i].status_id = allPermissionsSelected;

    const allSelected = dataList.every((item) => item.status_id);
    this.setState({ dataList, select_all: allSelected });
  };

  handleCheckboxChangeSelectAll = (e) => {
    const checked = e.target.checked;
    const updatedDataList = this.state.dataList.map((item) => {
      item.status_id = checked;
      if ((item.status_id = checked)) {
        item.parent_module_id = 1;
      } else {
        item.parent_module_id = 0;
      }
      item.permission.forEach((perm) => (perm.status_id = checked));
      return item;
    });
    this.setState({ dataList: updatedDataList, select_all: checked });
  };

  // changeHandlerSelectAll = (e, x) => {
  //   const { name } = e.target;
  //   var value = e.target.checked ? 1 : 0;
  //   const dataList = [...this.state.dataList];
  //   dataList[x][name] = value;
  //   dataList[x].permission.forEach((item, i) => {
  //     dataList[x]["permission"][i][name] = value;
  //   });
  //   this.setState({ dataList: dataList });
  // };

  changeHandlerSelectAll = (e, i) => {
    const checked = e.target.checked;
    const { dataList } = this.state;
    dataList[i].status_id = checked;
    if ((dataList[i].status_id = checked)) {
      dataList[i].parent_module_id = 1;
    } else {
      dataList[i].parent_module_id = 0;
    }
    dataList[i].permission.forEach((perm) => (perm.status_id = checked));

    const allSelected = dataList.every((item) => item.status_id);
    this.setState({ dataList, select_all: allSelected });
  };

  handleChangeRoleGroupList(value) {
    var selectedValue = [{ value: value.value, label: value.label }];
    this.setState({
      selectedRoleGroupList: selectedValue,
      role_group: value.value,
    });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
  }

  saveRolePermission = async (e) => {
    e.preventDefault();
    var count = 0;
    var permission_list = [];
    this.setState({ showSpinner: true, isSubmit: true });
    this.state.dataList.map((item, i) => {
      item.permission.map((item1, j) => {
        var permission_data = {};
        permission_data.module_id = item.module_id;
        permission_data.module_permission_id = item1.module_permission_id;
        permission_data.status_id = item1.status_id ? item1.status_id : 0;
        permission_list[count] = permission_data;
        count++;
      });
    });
    let inputData = {
      role_title: this.state.role_title,
      role_group: this.state.role_group,
      default_landing_page: this.state.default_landing_page,
      permission: permission_list,
    };
    if (this.state.slug !== undefined) {
      let res = await RolePermissionService.setRolePermissionStatusList(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.getData();
        // setTimeout(
        //   () => this.props.history.push("/role-permission/" + this.state.slug),
        //   global.redirect_time
        // );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.errorMessage
              ? res.data.errorMessage
              : res.data.message,
          });
        }
      }
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  rowClickedHandler = (event, data, rowIndex) => {
    global.userPermissions.checkPermission("users-update") &&
      this.props.history.push("/users/update/" + data.slug);
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <UsersSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel">
                  <div className="col-md-12 p-0">
                    <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                      <div className="row row-xs">
                        <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                        <div className="col-md-12">
                          <div className="dashbaord-pageHeadertitle d-flex flex-wrap justify-content-between align-items-center">
                            <h3 className="mb-0 brnd_heading">
                              {" "}
                              Role : {this.state.role_title}
                            </h3>
                            {this.state.activeClass == "alerts" && (
                              <div className="d-flex justify-content-end">
                                <Link to={"/roles"}>
                                  <button
                                    type="button"
                                    className="btn-success-outline"
                                  >
                                    Cancel
                                  </button>
                                </Link>
                                {global.userPermissions.checkPermission(
                                  "role-update"
                                ) && (
                                  <button
                                    className="btn-success ml-3"
                                    type="button"
                                    onClick={this.saveRolePermission}
                                    disabled={
                                      this.state.isSubmit ? true : false
                                    }
                                  >
                                    {this.state.isSubmit
                                      ? global.loader
                                      : this.state.slug !== undefined
                                      ? "Save"
                                      : "Save"}
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="scrolling-carousel pd-l-0 mg-t-30">
                        <div className="col-md-12 pl-0 pr-0  ">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="alerts"
                          onSelect={(e) => this.getActiveClass(e)}
                          eventKey={this.state.activeClass}
                        >
                          <ScrollingCarousel
                            rightArrow={true}
                            leftArrow={true}
                            className="swipesection"
                          >
                            <Nav.Item>
                              <Nav.Link
                                eventKey="alerts"
                                id="infoForm"
                                className={
                                  this.state.activeClass === "alerts"
                                    ? "active"
                                    : ""
                                }
                              >
                                Permissions
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                              <Nav.Link
                                eventKey="alert-activity"
                                id="alert-activity"
                                className={
                                  this.state.activeClass === "alert-activity"
                                    ? "active"
                                    : ""
                                }
                              >
                                User
                              </Nav.Link>
                            </Nav.Item>
                          </ScrollingCarousel>
                          <Tab.Content>
                            <Tab.Pane eventKey="alerts">
                              <div className="form-group mg-t-30 row row ml-0 mr-0">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-0">
                                    Role Name
                                  </label>
                                  <input
                                    name="role_title"
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Role Name"
                                    value={this.state.role_title}
                                    onChange={this.changeHandler}
                                    maxLength="75"
                                    tabIndex="1"
                                  />
                                  {this.state.errors.role_title ? (
                                    <div className="text-danger">
                                      {this.state.errors.role_title}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-0">
                                    Role Group
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    menuPlacement="auto"
                                    value={this.state.selectedRoleGroupList}
                                    options={this.state.roleGroupList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeRoleGroupList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedRoleGroupList
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                  {this.state.errors.role_group ? (
                                    <div className="text-danger">
                                      {this.state.errors.role_group}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                                  <label className="form-label pd-b-0">
                                    Default Landing Page
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    menuPlacement="auto"
                                    value={
                                      this.state.selectedDefaultLandingPageList
                                    }
                                    options={this.state.defaultLandingPageList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeDefaultLandingPageList(
                                        value
                                      )
                                    }
                                    defaultValue={
                                      this.state.selectedDefaultLandingPageList
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />

                                  {this.state.errors.role_group ? (
                                    <div className="text-danger">
                                      {this.state.errors.role_group}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="form-group pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0 row ml-0 mr-0">
                                {/* <label className="col-md-1 col-form-label form-label text-left">Permissions<span className="text-danger">*</span></label> */}
                                <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                                  <Table
                                    striped
                                    bordered
                                    hover
                                    className="rolepermission"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="col-md-2">
                                          <div className="select_all_tbl">
                                            <div className="ckbox form-check">
                                              <input
                                                name="select_all"
                                                type={"checkbox"}
                                                id={`select_all`}
                                                onChange={(e) =>
                                                  this.handleCheckboxChangeSelectAll(
                                                    e
                                                  )
                                                }
                                                className="form-check-input"
                                                checked={this.state.select_all}
                                              />
                                              <label
                                                title=""
                                                htmlFor="select_all"
                                                className="form-check-label mr-2"
                                              ></label>
                                            </div>
                                            Module
                                          </div>
                                        </th>
                                        <th className="col-md-10">
                                          Permissions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.dataList.map((item, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="align-middle pt-3 pb-3">
                                              <div className="align-items-center ckbox form-check">
                                                <div className="col-lg-12 d-flex p-0 tems-center role_permiss_td_fst mg-b-0 align-items-center">
                                                  <input
                                                    type="checkbox"
                                                    name="status_id"
                                                    className="mg-r-10 mb-0 w-auto"
                                                    id={"select_all" + i}
                                                    onChange={(e) =>
                                                      this.changeHandlerSelectAll(
                                                        e,
                                                        i
                                                      )
                                                    }
                                                    checked={item.status_id}
                                                  />
                                                  <label
                                                    title=""
                                                    htmlFor={"select_all" + i}
                                                    className="form-label text-left pd-t-2 m-0"
                                                  >
                                                    {item.name}
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td className="align-middle pt-3 pb-3">
                                              <div className="row col-md-12">
                                                {item.permission.map(
                                                  (item1, j) => {
                                                    return (
                                                      <div
                                                        className="col-md-2 d-flex align-items-center checkbox_align_role"
                                                        key={j}
                                                      >
                                                        <div className="col-lg-12 p-0 d-flex tems-center role_permiss_td_fstmg-b-0 align-items-center">
                                                          <input
                                                            type="checkbox"
                                                            name="status_id"
                                                            value={
                                                              item1.module_permission_id
                                                            }
                                                            id={i + "_" + j}
                                                            className="mg-r-10 mb-0 w-auto"
                                                            onChange={(e) =>
                                                              this.handleCheckboxChange(
                                                                e,
                                                                i,
                                                                j
                                                              )
                                                            }
                                                            checked={
                                                              item1.status_id
                                                            }
                                                          />

                                                          <label
                                                            className="form-label text-left pd-t-2 m-0"
                                                            htmlFor={
                                                              i + "_" + j
                                                            }
                                                          >
                                                            {item1.name}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="alert-activity">
                              {this.state.total_record > 0 ||
                              this.state.filter_value !== "" ? (
                                <div className="mg-t-20 brandList">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    // loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              ) : (
                                <NoData msg="users" />
                              )}
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default RolePermission;

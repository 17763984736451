import React, { Component } from "react";
// import deleteicon from "../../assets/images/delete.svg";
// import pluswIcon from "./../../assets/images/plus.svg";
// import { Form } from "react-bootstrap";
// import Cropper from "react-easy-crop";
// import Modal from "react-bootstrap/Modal";
import getCroppedImg from "./cropImage";
// import Card from "react-bootstrap/Card";
class CropImageHtml extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedFiles: "",
      updateFileList: this.props.uploadedFiles,
      newRelatedFiles: [],
      deletedFileList: [],
      isImageSelected: "",
      crop: { x: 1, y: 1 },
      // cropSize: { width: 1024, height: 683 },
      zoom: 1,
      rotation: 0,
      aspect: 1024 / 683,
      croppedAreaPixels: null,
      croppedArea: null,
      photoURL: "",
      cropURL: "",
      description: "",
      // is_main_img: false,
    };
    this.changeHandler = this.changeHandler.bind(this);
  }
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels);
    this.setState({
      croppedAreaPixels: croppedAreaPixels,
      croppedArea: croppedArea,
    });
    // console.log("1", this.state.newRelatedFiles);
    this.cropImage();
  };
  cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        this.state.photoURL,
        this.state.croppedAreaPixels,
        this.state.rotation
      );
      console.log(file);
      this.setState({ cropURL: url });
    } catch (error) {
      // console.log(error.message);
    }
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  changeZoom = (e) => {
    this.setState({ zoom: e.target.value });
  };
  componentDidMount() {
    this.removeNewSelectedFile = this.removeNewSelectedFile.bind(this);
    this.removeUpdatedFile = this.removeUpdatedFile.bind(this);
  }

  //@logic New preview file array set here.
  previewFileList = async (event) => {
    const allFiles = event.target.files;
    /* //@logic delete old single file when selecting new single file */
    if (this.props.uploadType === "single") {
      // let deletedFileList = this.state.deletedFileList;
      // console.log(deletedFileList.length, deletedFileList.length <= 1);
      //commented on 08/02/22 by NP & GP for underfined errror
      // if (deletedFileList.length === 0) {
      //     this.removeUpdatedFile(0);
      // }
    }

    let newRelatedFiles = [];
    for (let i = 0; i < allFiles.length; i++) {
      let fileObject = allFiles[i];
      //Display preview if it's image
      if (fileObject.name.match(/.(jpg|jpeg|png|gif|webp)$/i)) {
        allFiles[i]["src"] = URL.createObjectURL(fileObject);
        this.setState({ photoURL: allFiles[i]["src"] });
      } else {
        allFiles[i]["src"] = "";
      }
      newRelatedFiles.push(allFiles[i]);
    }
    this.setState({
      newRelatedFiles: newRelatedFiles,
      isImageSelected: "none",
    });
  };

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    // let newRelatedFiles = this.state.newRelatedFiles;
    // if (event.target.name === "description" || event.target.name === "is_main_img" ) {
    //   newRelatedFiles[index][event.target.name] = value;
    //   if(event.target.name === "description" && (this.props.uploadType === "multiple" ||this.props.uploadType === "single")){
    //     this.setState({ newRelatedFiles: newRelatedFiles, description : value });
    //     this.props.newRelatedFilesDescChange(index, value);
    //   }
    //   if(event.target.name === "is_main_img" && (this.props.uploadType === "multiple" ||this.props.uploadType === "single") ){
    //     this.setState({ newRelatedFiles: newRelatedFiles, is_main_img : value });
    //     this.props.newRelatedFilesMainImgChange(index, value);
    //   }
    // } else {
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // }
  };

  //@logic New file remove manage here
  removeNewSelectedFile = async (key) => {
    let newFileList = this.state.newRelatedFiles;
    delete newFileList[key];
    this.setState({
      newRelatedFiles: newFileList,
      isImageSelected: "",
    });
  };

  //@logic updated file remove manage here.
  removeUpdatedFile = async (key) => {
    // console.log(key);
    let updateFileList = this.state.updateFileList;
    // console.log("updateFileList", updateFileList);
    let deletedFileList = this.state.deletedFileList;
    // console.log("deletedFileList", deletedFileList);
    // let tempArr = updateFileList[key];
    let tempArr = {
      fieldName: this.props.fieldName,
      uploadedTo: this.props.uploadOn,
      fileDestination: this.props.destinationFolder,
      ...updateFileList[key],
    };
    deletedFileList.push(tempArr);
    this.props.deletedFiles.push(tempArr);
    delete updateFileList[key];
    this.setState({
      updateFileList: updateFileList,
      deletedFileList: deletedFileList,
    });
    // this.props.onRemovedFiles(deletedFileList);
  };

  render() {
    const allowMultiple = this.props.uploadType === "multiple" ? true : false;
    const dragClassName =
      this.props.drag === "true"
        ? "image-upload-wrap"
        : "multiImgBuuton file_widget_class";
    const inputDragClassName =
      this.props.drag === "true" ? "file-upload-input" : "file_widget_class";

    return (
      <div className="form-group mt-1 mb-0">
        <div
          className="row border-none align-items-center"
          style={{ display: this.state.isImageSelected }}
        >
          <div className="col-md-12 mx-auto mb-4">
            <div className={dragClassName}>
              {/* <label htmlFor="contained-button-file">
                <div
                  variant="file"
                  // type="file"
                  className="btn-multiupload-outline d-flex flex-column text-center file_widget_class"
                >
                  <img src={pluswIcon} alt="" />
                  <span className="add">
                    <b>{this.props.dragText}</b>
                  </span>
                </div>
              </label> */}
              <input
                style={{}}
                id="contained-button-file"
                type="file"
                multiple={allowMultiple}
                name={this.props.fieldName}
                title={this.props.fieldTitle}
                className={
                  this.props.inputClassName +
                  " file_widget_class " +
                  inputDragClassName
                }
                allowedtype={this.props.allowedType}
                maxfilesize={this.props.maxFileSize}
                maxfilesizetype={this.props.maxFileSizeType}
                maxfileallowed={this.props.maxFileAllowed}
                uploadon={this.props.uploadOn}
                slug={this.props.slug}
                reftableid={this.props.refTableId}
                displayfilename={this.props.displayFileName}
                documenttypeslug={this.props.documentTypeSlug}
                description={this.props.description}
                mediatype={this.props.mediaType}
                propertySlug={this.props.propertySlug}
                h1={this.props.h1}
                h2={this.props.h2}
                sortorder={this.props.sortOrder}
                statusid={this.props.statusId}
                checkvalidation={this.props.checkValidation}
                resizeoptions={this.props.resizeOptions}
                destinationfolder={this.props.destinationFolder}
                onChange={this.previewFileList}
                uploadedfiles={this.props.uploadedFiles}
                // onchange="readURL(this);"
                accept={this.props.accept}
                dailyDelivery={this.props.dailyDelivery}
                validationFire={this.props.validationFire}
              />
              {this.props.drag === "true" && (
                <div className="drag-text">
                  <h5>{this.props.dragText}</h5>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* for displaying image preview */}
        <div className="row">
          <div className="multi-gallery">
            {this.state.newRelatedFiles.length > 0 &&
              this.state.newRelatedFiles.map((val, key) => {
                return (
                  <React.Fragment key={key}>
                    <div className="multi-gallery-item">
                      <div className="multi-gallery-image-div">
                        <img src={val.src} alt="" />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
export default CropImageHtml;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: OwnershipTypeService.js                          //
//  Application: Property                                 //
//  Option: Used for manage Ownership type                //
//  Developer: NP           						      //
//  Date: 2021-01-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class OwnershipTypeService {

  getOwnership(queryString) {
    let endPoint = "ownership-type"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createOwnership(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "ownership-type";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getOwnershipBySlug(slug) {
    let endPoint = "ownership-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateOwnership(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "ownership-type/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteOwnership(slug) {
    let endPoint = "ownership-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new OwnershipTypeService();

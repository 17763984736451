////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ApplicantDetailView.jsx                                //
//  Application:  Applicant                                   //
//  Option: For view  Applicant details                      //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Container, Modal } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import LeasingSidebar from "../common/LeasingSidebar";
import Moment from "moment";
import ApplicantsDetailService from "../../services/ApplicantsDetailService";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";

export class ApplicantDetailView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      isHasEnquiry: false,
      selectedStatusList: {},
      applicantDetailsTotal: 0,
      inquirydata: [],
      pathname: window.location.pathname,
      deletedFileList: [],
      descShow: 0,
      descShowPricing: 0,
    };
    this.photoUrl = React.createRef();
    this.fullText = this.fullText.bind(this);
    this.fullTextPricing = this.fullTextPricing.bind(this);
    this.deleteApplicantsDetail = this.deleteApplicantsDetail.bind(this);
    this.opendeleteApplicantModal = this.opendeleteApplicantModal.bind(this);
    this.closedeleteApplicantModal = this.closedeleteApplicantModal.bind(this);
  }

  opendeleteApplicantModal(slug) {
    this.setState({ deleteApplicantModal: true, slug: slug });
  }

  closedeleteApplicantModal() {
    this.setState({ deleteApplicantModal: false, slug: "" });
  }

  async deleteApplicantsDetail(slug) {
    this.setState({ showSpinner: true });
    let res = await ApplicantsDetailService.deleteApplicantsDetail(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteApplicantModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" +
                  this.state.property_slug +
                  "/applicant-detail"
              : "/applicant-detail"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteApplicantModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      var inquiryData = [];
      var applicantDetailsTotal = 0;
      let res = await ApplicantsDetailService.getApplicantsDetailBySlug(
        this.state.slug
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        if (
          res.data &&
          res.data.applicant_info &&
          res.data.applicant_info.phone_number
        ) {
          this.phoneNumberAutoFormat(res.data.applicant_info.phone_number);
        }
        inquiryData = res.data.inquiryData ? res.data.inquiryData : [];
        var selectedStatusList = {};
        global.statuslist.length > 0 &&
          global.statuslist.forEach((item, i) => {
            if (item.value === res.data.status_id) {
              selectedStatusList = {
                value: item.value,
                label: item.label,
              };
            }
          });
        this.setState({ isHasEnquiry: true });
      }
      this.setState(resData);
      this.setState({
        input: resData,
        inquirydata: inquiryData,
        selectedStatusList: selectedStatusList,
        applicantDetailsTotal: applicantDetailsTotal,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ phone_number: finalnumber });
  };

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  getTitle() {
    return "Inquiry";
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextPricing() {
    if (this.state.descShowPricing === 0) {
      this.setState({ descShowPricing: 1 });
    } else {
      this.setState({ descShowPricing: 0 });
    }
  }

  render() {
    const listItems =
      this.state.inquirydata.length > 0
        ? this.state.inquirydata.map((element, i) => {
            return (
              <li key={i}>
                <p>
                  {element.new_inquiry_status} :{" "}
                  {Moment(element.updated_at).format(global.dateTimeFormat)}
                </p>
              </li>
            );
          })
        : "";
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LeasingSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLeasingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                          {global.userPermissions.checkPermission(
                            "inquiry-delete"
                          ) && (
                            <span
                              onClick={() => {
                                this.opendeleteApplicantModal(this.state.slug);
                              }}
                              className="deleteicon mg-r-20"
                            >
                              <img src={deleteicon} alt="" />
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {global.userPermissions.checkPermission(
                        "inquiry-update"
                      ) && (
                        <Link
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/applicant-detail/update/" +
                                this.state.slug
                              : "/applicant-detail/update/" +
                                this.state.property_slug +
                                "/" +
                                this.state.slug
                          }
                          className="mg-l-2"
                        >
                          <button className="btn-success-outline-small">
                            <img src={editIcon} alt="Edit" />
                            Edit
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="m-0 listing_detail">
                      <div className="row align-items-center col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-xs-l-0 pd-xs-r-0 pd-l-0 pd-r-0 border-bottom-0">
                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                            <p className="az-profile-name-text pl-0">
                              First Name
                            </p>
                            <p className="media-body">
                              {this.state.applicant_info
                                ? this.state.applicant_info.first_name
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                            <p className="az-profile-name-text pl-0">
                              Last Name
                            </p>
                            <p className="media-body">
                              {this.state.applicant_info
                                ? this.state.applicant_info.last_name
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                            <p className="az-profile-name-text pl-0">Email</p>
                            <p className="media-body">
                              {this.state.applicant_info
                                ? this.state.applicant_info.email
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                            <p className="az-profile-name-text pl-0">Phone</p>
                            <p className="media-body">
                              {this.state.phone_number
                                ? this.state.phone_number
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                            <p className="az-profile-name-text pl-0">
                              Desired Move In Date
                            </p>
                            <p className="media-body">
                              {this.state.desired_move_in_date
                                ? Moment(
                                    this.state.desired_move_in_date
                                  ).format(global.dateFormat)
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                            <p className="az-profile-name-text pl-0">
                              Application Date
                            </p>
                            <p className="media-body">
                              {this.state.tour_date_time
                                ? Moment(this.state.tour_date_time).format(
                                    global.dateFormat
                                  )
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                            <p className="az-profile-name-text pl-0">
                              Application Status
                            </p>
                            <p className="media-body">
                              {this.state.selectedStatusList
                                ? this.state.selectedStatusList.label
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                            <p className="az-profile-name-text pl-0">
                              Move In Cost
                            </p>
                            <p className="media-body">
                              {this.state.move_in_cost_payment
                                ? "$" + this.state.move_in_cost_payment
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0">
                            <ul className="new_list_inquiry">{listItems}</ul>
                          </div>
                          <div className="col-md-12 pd-lg-l-15 pd-xs-l-0">
                            <p className="az-profile-name-text pl-0">Notes</p>
                            <div className="para">
                              {this.state.notes ? ( //check if notes is exist
                                <p className="p_wrap1">
                                  {this.state.descShow
                                    ? this.state.notes.slice(
                                        0,
                                        global.showMoreLength
                                      )
                                    : this.state.notes}

                                  {this.state.notes.length >
                                    global.showMoreLength && (
                                    <span onClick={this.fullText}>
                                      {this.state.descShow ? (
                                        <p className="hand-cursor showmore">
                                          ...Show more{" "}
                                        </p>
                                      ) : (
                                        <p className="hand-cursor showmore">
                                          Show Less{" "}
                                        </p>
                                      )}
                                    </span>
                                  )}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteApplicantModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteApplicantModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteApplicantModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteApplicantsDetail(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default ApplicantDetailView;

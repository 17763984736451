//     							                          //
//  Program: AllPropertiesCleaningSidebar.jsx                      //
//  Application: Property                                 //
//  Option: common component House Keeping Sidebar        //
//  Developer: NP 		                                  //
//  Date: 2022-04-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from 'react-bootstrap';

class AllPropertiesCleaningSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.remove("d-none");
        document.querySelector(".newSubmenu").classList.remove("d-none");
      } else {
        data.classList.replace("left", "hide");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.add("d-none");
        document.querySelector(".newSubmenu").classList.add("d-none");
      }
    }
  }

  toggleChildMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector(".newSubmenu").classList.toggle("d-none");
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
      } else {
        data.classList.replace("left", "hide");
      }
    }
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Cleaning
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  
                  <ul>
                    {global.userPermissions.checkPermission(
                      "cleaning-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("housekeeping-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/housekeeping-calender"}
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "cleaning-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("housekeeping-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/housekeeping-list"}
                        >
                          Cleaning
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "maintenance-requests-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("problems")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/problems"}
                        >
                          Problems
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Cleaning
              </span>
              <ul>
                <li>
                  
                  <ul>
                    {global.userPermissions.checkPermission(
                      "cleaning-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("housekeeping-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/housekeeping-calender"}
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "cleaning-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("housekeeping-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/housekeeping-list"}
                        >
                          Cleaning
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "maintenance-requests-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("problems")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/problems"}
                        >
                          Problems
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AllPropertiesCleaningSidebar;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: LocationTypeService.js                       //
//  Application: LocationType                             //
//  Option: Used for manage Location Type                 //
//  Developer: Ashish Kumar  						      //
//  Date: 2022-01-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class LocationTypeService {
  getLocationType(queryString) {
    let endPoint = "location-type"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createLocationType(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "location-type";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getLocationTypeBySlug(slug) {
    let endPoint = "location-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateLocationType(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "location-type/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteLocationType(slug) {
    let endPoint = "location-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new LocationTypeService();

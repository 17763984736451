//////////////////////////////////////////////////////////////
//     							                                        //
//  Program: AccessibilityFeatureTypeService.js        //
//  Application: AccessibilityFeatureTypeService                        //
//  Option: Used for manage Accessibility Feature Type //
//  Developer: Ashish Kumar  						                    //
//  Date: 2022-05-11                                        //
//                                                          //
//////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AccessibilityFeatureTypeService {
  getAccessibilityFeatureType() {
    let endPoint = "accessibility-feature-type";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createAccessibilityFeatureType(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "accessibility-feature-type";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getAccessibilityFeatureTypeBySlug(slug) {
    let endPoint = "accessibility-feature-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateAccessibilityFeatureType(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "accessibility-feature-type/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteAccessibilityFeatureType(slug) {
    let endPoint = "accessibility-feature-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new AccessibilityFeatureTypeService();

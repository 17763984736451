////////////////////////////////////////////////////////////
//     							                                      //
//  Program: NearByAttractionView.jsx                     //
//  Application:  NearByAttraction                        //
//  Option: For view  NearByAttraction details            //
//  Developer: NP                                         //
//  Date: 2022-05-06                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Container, Modal } from "react-bootstrap";
import NearByAttractionService from "../../services/NearByAttractionService";
import eventImage from "./../../assets/images/eventimg_placeholder.png";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import PropertySidebar from "../common/PropertySidebar";

export class NearByAttractionView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      imageUrl: eventImage,
      descShow: 0,
      descShowPricing: 0,
    };
    this.eventImage = React.createRef();
    this.fullText = this.fullText.bind(this);
    this.deleteAttraction = this.deleteAttraction.bind(this);
    this.opendeleteNearByModal = this.opendeleteNearByModal.bind(this);
    this.closedeleteNearByModal = this.closedeleteNearByModal.bind(this);
    this.openWebsite = this.openWebsite.bind(this);
  }

  opendeleteNearByModal(slug) {
    this.setState({ deleteNearByModal: true, slug: slug });
  }

  closedeleteNearByModal() {
    this.setState({ deleteNearByModal: false, slug: "" });
  }

  async deleteAttraction(slug) {
    this.setState({ showSpinner: true });
    let res = await NearByAttractionService.deleteNearByAttraction(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteNearByModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" +
              this.state.property_slug +
              "/near-by-attractions"
          ),
        global.redirect_time
      );
    } else {
      this.closedeleteNearByModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await NearByAttractionService.getNearByAttractionBySlug(
        this.state.slug
      );
      if (global.successStatus.includes(res.status)) {
        resData.nearby_attraction_name = res.data.nearby_attraction_name
          ? res.data.nearby_attraction_name
          : "";
        resData.attraction_type_slug = res.data.attraction_type_slug
          ? res.data.attraction_type_slug
          : "";
        resData.attraction_type_name = res.data.attraction_type_name
          ? res.data.attraction_type_name
          : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.event_image = res.data.event_image ? res.data.event_image : "";
        resData.imageUrl = res.data.event_image_thumb_url
          ? res.data.event_image_thumb_url
          : eventImage;
        resData.opening_hour = res.data.opening_hour
          ? res.data.opening_hour
          : "";
        resData.website = res.data.website ? res.data.website : "";
        resData.latitude = res.data.latitude ? res.data.latitude : "";
        resData.longitude = res.data.longitude ? res.data.longitude : "";
        resData.contact_name = res.data.contact_name
          ? res.data.contact_name
          : "";
        resData.contact_email = res.data.contact_email
          ? res.data.contact_email
          : "";

        if (res.data && res.data.contact_phone) {
          this.phoneNumberAutoFormat(res.data.contact_phone);
        }
        resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resData.city = res.data.city ? res.data.city.city_name : "";
        resData.state = res.data.state ? res.data.state.state_name : "";
        resData.country = res.data.country ? res.data.country.country_name : "";
        resData.county = res.data.county ? res.data.county.county_name : "";
        resData.zipcode = res.data.zipcode ? res.data.zipcode : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        resData.distance_value = res.data.distance_value
          ? res.data.distance_value
          : null;
        resData.distanceUnitData = res.data.distanceUnitData
          ? res.data.distanceUnitData
          : {};
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ contact_phone: finalnumber });
  };

  getTitle() {
    return "Near By";
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  openWebsite() {
    var url = this.state.website ? this.state.website : "";
    window.open(url, "_blank");
  }

  render() {
    const url = this.state.website ? this.state.website : "";
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                          {global.userPermissions.checkPermission(
                            "near-by-delete"
                          ) && (
                            <span
                              onClick={() => {
                                this.opendeleteNearByModal(this.state.slug);
                              }}
                              className="deleteicon mg-r-20"
                            >
                              <img src={deleteicon} />
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {global.userPermissions.checkPermission(
                        "near-by-update"
                      ) && (
                        <Link
                          to={
                            "/properties/view/" +
                            this.state.property_slug +
                            "/near-by-attractions/update/" +
                            this.state.slug
                          }
                          className="mg-l-2"
                        >
                          <button className="btn-success-outline-small">
                            <img src={editIcon} alt="Edit" />
                            Edit
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                      <h3 className="mg-l-0 background-head">Basic</h3>
                    </div>
                    <div className="row align-items-center col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 mg-b-20 mg-xs-b-0 pd-l-30 pd-r-30">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                        <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                          <div className="az-img-user avatar-md online mg-b-8">
                            <figure>
                              <b
                                className={
                                  this.state.status_id === 1
                                    ? "status-active"
                                    : "status-inactive"
                                }
                              ></b>
                              <img
                                src={
                                  this.state.imageUrl
                                    ? this.state.imageUrl
                                    : eventImage
                                }
                                alt="Basic Event Image"
                                className="rounded-circle"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-8 p-0">
                        <div className="propname">
                          <p className="az-profile-name-text pl-0">Name</p>
                          <p className="media-body">
                            {this.state.nearby_attraction_name
                              ? this.state.nearby_attraction_name
                              : ""}
                          </p>
                          <p className="az-profile-name-text pl-0">Type</p>
                          <p className="media-body">
                            {this.state.attraction_type_name
                              ? this.state.attraction_type_name
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                      <h3 className="mg-l-5 background-head">Location</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-12 pd-l-30 pd-r-30">
                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-15 pd-xs-l-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Street 1
                            </p>
                            <p className="media-body">
                              {this.state.street_1 ? this.state.street_1 : ""}
                            </p>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Street 2
                            </p>
                            <p className="media-body">
                              {this.state.street_2 ? this.state.street_2 : ""}
                            </p>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">City</p>
                            <p className="media-body">
                              {this.state.city ? this.state.city : ""}
                            </p>
                          </div>
                        </div>

                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-l-15 pd-xs-l-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">State</p>
                            <p className="media-body">
                              {this.state.state ? this.state.state : ""}
                            </p>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Zip Code
                            </p>
                            <p className="media-body">
                              {this.state.zipcode ? this.state.zipcode : ""}
                            </p>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">County</p>
                            <p className="media-body">
                              {this.state.county ? this.state.county : ""}
                            </p>
                          </div>
                        </div>

                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-l-15 pd-xs-l-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">Country</p>
                            <p className="media-body">
                              {this.state.country ? this.state.country : ""}
                            </p>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Latitude
                            </p>
                            <p className="media-body">
                              {this.state.latitude ? this.state.latitude : ""}
                            </p>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Longitude
                            </p>
                            <p className="media-body">
                              {this.state.longitude ? this.state.longitude : ""}
                            </p>
                          </div>
                        </div>
                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-l-15 pd-xs-l-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Opening Hours
                            </p>
                            <p className="media-body">
                              {this.state.opening_hour
                                ? this.state.opening_hour
                                : ""}
                            </p>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">Map URL</p>
                            <p
                              className="hand-cursor break-word link-url"
                              onClick={this.openWebsite}
                            >
                              {url}
                            </p>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Distance
                            </p>
                            <p className="media-body">
                              {this.state.distance_value
                                ? this.state.distance_value
                                : ""}{" "}
                              {this.state.distanceUnitData
                                ? this.state.distanceUnitData.distance_unit_name
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                      <h3 className="mg-l-5 background-head">Contact</h3>
                    </div>
                    <div className="row">
                      <div className="col-md-12 pd-l-30 pd-r-30">
                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-l-15 pd-xs-l-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Contact Name
                            </p>
                            <p className="media-body">
                              {this.state.contact_name
                                ? this.state.contact_name
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">Phone</p>
                            <p className="media-body">
                              {this.state.contact_phone
                                ? this.state.contact_phone
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">Email</p>
                            <p className="media-body">
                              {this.state.contact_email
                                ? this.state.contact_email
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row col-md-12 mg-md-t-0">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                        <p className="az-profile-name-text pl-0">Description</p>
                        <div className="para">
                          {this.state.description ? (
                            <p className="p_wrap1">
                              {this.state.descShow
                                ? this.state.description.slice(
                                    0,
                                    global.showMoreLength
                                  )
                                : this.state.description}

                              {this.state.description.length >
                                global.showMoreLength && (
                                <span onClick={this.fullText}>
                                  {this.state.descShow ? (
                                    <p className="hand-cursor showmore">
                                      ...Show more{" "}
                                    </p>
                                  ) : (
                                    <p className="hand-cursor showmore">
                                      Show Less{" "}
                                    </p>
                                  )}
                                </span>
                              )}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteNearByModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteNearByModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className=" m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteNearByModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteAttraction(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default NearByAttractionView;

//     							                          //
//  Program: ReportSidebar.jsx                           //
//  Application: Reports                                 //
//  Option: common component Report Sidebar TAbs         //
//  Developer: NP		                                 //
//  Date: 2022-04-13                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from 'react-bootstrap';
class AllPropertyReportSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.remove("d-none");
        document.querySelector(".newSubmenu").classList.remove("d-none");
      } else {
        data.classList.replace("left", "hide");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.add("d-none");
        document.querySelector(".newSubmenu").classList.add("d-none");
      }
    }
  }

  toggleChildMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector(".newSubmenu").classList.toggle("d-none");
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
      } else {
        data.classList.replace("left", "hide");
      }
    }
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Reports
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <span>Reports</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "top-performer-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("top-performer")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/top-performer"}
                        >
                          Top Performer
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "resident-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("resident-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/resident-payout"}
                        >
                          Resident Payout
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "homesharing-activity-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("homesharing-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/homesharing-reports"}
                        >
                          Homesharing Activity
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "occupancy-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("occupancy-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/occupancy-reports"}
                        >
                          Occupancy
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "oh-revenue-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("oh-earning-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/oh-earning-reports"}
                        >
                          OH Revenue
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-tax-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-tax")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/property-tax"}
                        >
                          Property Tax
                        </Link>
                      </li>
                    )}

                    {global.userPermissions.checkPermission(
                      "channel-payout-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ru-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/ru-payout"}
                        >
                          Channel Payout
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>Incidentals & Waiver</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "guest-form-deposit-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest-form-deposit")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={ "/guest-form-deposit"
                          }
                        >
                        Incidentals Deposit
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "guest-form-waiver-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest-form-waiver")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/guest-form-waiver"
                          }
                        >
                       Waiver Fee Report
                        </Link>
                      </li>
                    )}
                     {global.userPermissions.checkPermission(
                      "guest-form-wavio-transfer-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("wavio-transfer-fee-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/wavio-transfer-fee-list"
                          }
                        >
                      Waiver Payout Report
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>Property Payout</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-payout-report")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/property-payout-report"}
                        >
                          By Unit and Reservation
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-reservation-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/property-reservation-payout"}
                        >
                          By Reservation
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-unit-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/property-unit-payout"}
                        >
                          By Unit
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Reports
              </span>
              <ul>
                <li>
                  <span>Reports</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "resident-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("resident-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/resident-payout"}
                        >
                          Resident Payout
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "homesharing-activity-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("homesharing-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/homesharing-reports"}
                        >
                          Homesharing Activity
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "occupancy-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("occupancy-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/occupancy-reports"}
                        >
                          Occupancy
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "oh-revenue-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("oh-earning-reports")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/oh-earning-reports"}
                        >
                          OH Revenue
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-tax-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-tax")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/property-tax"}
                        >
                          Property Tax
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "channel-payout-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("channels-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/channels-payout"}
                        >
                          Channel Payout
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>Incidentals & Waiver</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "guest-form-deposit-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest-form-deposit")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={ "/guest-form-deposit"
                          }
                        >
                        Incidentals Deposit
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "guest-form-waiver-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest-form-waiver")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/guest-form-waiver"
                          }
                        >
                       Waiver Fee Report
                        </Link>
                      </li>
                    )}
                     {global.userPermissions.checkPermission(
                      "guest-form-wavio-transfer-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("wavio-transfer-fee-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/wavio-transfer-fee-list"
                          }
                        >
                      Waiver Payout Report
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>Property Payout</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-payout-report")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/property-payout-report"}
                        >
                          By Unit and Reservation
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-reservation-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/property-reservation-payout"}
                        >
                          By Reservation
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-payout-report-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-unit-payout")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/property-unit-payout"}
                        >
                          By Unit
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AllPropertyReportSidebar;

import React, { Component } from "react";
import LatchService from "../../../services/LatchService";
import Alerts from "../../common/Alerts";
import { Modal, InputGroup, Form, Container, Table } from "react-bootstrap";
export class LatchCleanerAccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            property_slug: this.props.propertySlug,
            unit_slug: "",
            user_slug: "",
            showAlertModal: false,
            showOtpModal: false,
            showOtpErrorModal: false,
            showSuccessModal: false,
            alertModalTitle: "",
            alertModalType: "",
            userSlug: "",
            unitSlug: "",
            latchAccessSchedules: [],
            otpValue: "",
        }
    }

    async componentDidMount() {
        this.getData();
    }
    async getData() {
        let resLatchAccess = await LatchService.getCleanerLatchAccessList(`property_slug=${this.state.property_slug}`);
        let tempList = [];
        if (global.successStatus.includes(resLatchAccess.status)) {
            tempList = resLatchAccess.data ? resLatchAccess.data : [];
        }
        this.setState({ latchAccessSchedules: tempList });
    }

    async inviteUser(unit_slug, user_slug) {
        let resInviteUser = [];
        let inputData = { property_slug: this.state.property_slug, unit_slug, user_slug };
        resInviteUser = await LatchService.manualInviteUser(inputData);
        console.log(resInviteUser);
        if (global.successStatus.includes(resInviteUser.status)) {
            this.setState({
                showOtpModal: true,
                showOtpErrorModal: false,
                showAlertModal: true,
                showSuccessModal: false,
                alertModalTitle: "success",
                alertModalMessage: resInviteUser.message || "Success",
                alertModalType: "success",
                unit_slug,
                user_slug
            });
        } else {
            this.setState({
                showOtpModal: false,
                showOtpErrorModal: true,
                showAlertModal: true,
                showSuccessModal: false,
                alertModalTitle: resInviteUser.data.message ? resInviteUser.data.message : "Error!",
                alertModalMessage: resInviteUser.data.message || "Error!",
                alertModalType: "error",
            });
        }
    }

    async veriftyOTP() {
        let resInviteUser = [];
        let inputData = { otp: this.state.otpValue, user_slug: this.state.user_slug };
        resInviteUser = await LatchService.manualVeriftyOTP(inputData);
        if (global.successStatus.includes(resInviteUser.status)) {
            this.setState({
                showSuccessModal: true,
                showOtpModal: false,
                showOtpErrorModal: false,
                showAlertModal: true,
                alertModalTitle: resInviteUser.data.message || "Success",
                alertModalMessage: resInviteUser.data.message || "Success",
                alertModalType: "success",
                otpValue: ""
            });
        } else {
            this.setState({
                showSuccessModal: false,
                showOtpModal: false,
                showOtpErrorModal: true,
                showAlertModal: true,
                alertModalTitle: resInviteUser.data.message || "Error!",
                alertModalMessage: resInviteUser.data.message || "Error!",
                alertModalType: "error",
                otpValue: ""
            });
        }
    }

    closeoModal() {
        this.setState({ showOtpModal: false, showOtpErrorModal: false, showAlertModal: false, showSuccessModal: false });
    }

    changeOTPHandler = (event) => {
        this.setState({ otpValue: event.target.value });
    };

    render() {
        return (
            <main>
                <Container className="dashboardRightcard latch_table_cust">
                    <div className="d-flex flex-column">
                        <div className="row row-xs mg-t-0 pd-t-0">
                            <div className="col-md-10 right settingsidebarNav">
                                <div className="pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0 mg-t-10 ">
                                    <div className="table-responsive">
                                        <div className="col-md-12 pl-0 pr-0">
                                            <Alerts show={this.state.showAlertModal} type={this.state.alertModalType} title={this.state.alertModalTitle} message={this.state.alertModalMessage} />
                                        </div>
                                        <Table hover className="mg-b-0">
                                            <thead>
                                                <tr>
                                                    <th className="w-25">Unit Name</th>
                                                    <th className="w-25">Door Name</th>
                                                    <th className="w-50">Email</th>
                                                    <th className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.latchAccessSchedules && this.state.latchAccessSchedules.length > 0 ? (
                                                    this.state.latchAccessSchedules.map(
                                                        (item, indx) => {
                                                            return (
                                                                <tr key={indx}>
                                                                    <td className="hand-cursor">{item.unit_name}</td>
                                                                    <td>{item.door_name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td className="text-center">
                                                                        <button className="btn btn-latch-theme " onClick={() => this.inviteUser(item.unit_slug, item.user_slug)}>Verify OTP</button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td colSpan="12" align="center">No latch access schedule found.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <Modal show={this.state.showOtpModal} className="searchModalbox" centered>
                    <Modal.Body className="width_402 p-4 border_radius_14">
                        <span className="welcomeClose" onClick={() => this.closeoModal()}>{global.closee}</span>
                        <h3 className="text-center">Verity OTP</h3>
                        <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                            <InputGroup className="mt-3">
                                <label>Enter OTP</label>
                                <div className="col-md-12 p-0">
                                    <Form.Control type="text" onChange={this.changeOTPHandler} name="otp" value={this.state.otpValue || ""} maxLength="6" />
                                </div>
                            </InputGroup>
                        </div>
                        <div className="d-flex justify-content-center mt-3 row">
                            <button type="button" className="m-0 btn btn-outline-light btn-block" onClick={() => this.closeoModal()} >
                                Cancel
                            </button>
                            <button type="button" className="m-0 btn btn btn-success btn-block" onClick={() => this.veriftyOTP()} >
                                {this.state.isSubmit ? global.loader : "Verify OTP"}
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showOtpErrorModal} className="searchModalbox" centered>
                    <Modal.Body className="width_402 p-4 border_radius_14">
                        <span className="welcomeClose" onClick={() => this.closeoModal()}>{global.closee}</span>
                        <h3 className="text-center">Error: OTP verification</h3>
                        <p className="text-center">{this.state.alertModalMessage}</p>
                        <div className="d-flex justify-content-center mt-3 row">
                            <button type="button" className="m-0 btn btn-outline-light btn-block" onClick={() => this.closeoModal()} >Close</button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showSuccessModal} className="searchModalbox" centered>
                    <Modal.Body className="width_402 p-4 border_radius_14">
                        <span className="welcomeClose" onClick={() => this.closeoModal()}>{global.closee}</span>
                        <h3 className="text-center">OTP verification done</h3>
                        <p className="text-center">{this.state.alertModalMessage}</p>
                        <div className="d-flex justify-content-center mt-3 row">
                            <button type="button" className="m-0 btn btn-outline-light btn-block" onClick={() => this.closeoModal()} >Close</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </main>
        );
    }
}
export default LatchCleanerAccess;

/////////////////////////////////////////////////////
//     							                   //
//  Program: Basic.jsx                             //
//  Application: Property                          //
//  Option: List all Property Basic info           //
//  Developer: NP, Prakash 						   //
//  Date: 2022-02-09                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import brandsmallLogo from "./../../assets/images/brand_placeholder.png";
import PropertyService from "../../services/PropertyService";
import PropertyTypeService from "../../services/PropertyTypeService";
import TimezoneService from "../../services/TimezoneService";
import BrandService from "../../services/BrandService";
import PropertySuitabilityService from "../../services/PropertySuitabilityService";
import ParkingTypeService from "../../services/ParkingTypeService";
// import renderHTML from "react-render-html";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
// import infosize from "../../assets/images/Info.svg";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import propertyImg from "./../../assets/images/property_placeholder.png";
import propertyLogo from "./../../assets/images/property_placeholder.png";
// import ShowMoreText from "react-show-more-text";
// import LinesEllipsis from 'react-lines-ellipsis'
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
// import ShowMoreText from "react-show-more-text";
export class Basic extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      // propertySlug: this.props.property_slug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      affordable_component_check: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      propertyList: [],
      timeZoneList: [],
      newTimeZoneList: [],
      selectedTimeZoneList: [],
      yearList: [],
      brandList: [],
      newBrandList: [],
      selectedBrandList: [],
      suitabilityList: [],
      parkingTypeList: [],
      newParkingTypeList: [],
      selectedParkingTypeList: [],
      suitability_id: [],
      newSuitabilityList: [],
      selectedSuitabilityList: [],
      ada_compliant: 2,
      imageUrl: propertyLogo,
      imgPropUrl: propertyImg,
      brand_image: brandsmallLogo,
      status_id: "",
      confirm_back: 0,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type")
        ? sessionStorage.getItem("property_type")
        : "",
      property_name: sessionStorage.getItem("property_name")
        ? sessionStorage.getItem("property_name")
        : "",
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      descShow: 0,
      descShowRules: 0,
      propertyListTotal: 0,
      brandListTotal: 0,
      timeZoneListTotal: 0,
      suitabilityListTotal: 0,
      parkingTypeListTotal: 0,
    };
    this.propertyLogo = React.createRef();
    this.propertyImg = React.createRef();
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.getPropertyAffordable = this.getPropertyAffordable.bind(this);
    this.fullText = this.fullText.bind(this);
    this.fullTextRules = this.fullTextRules.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getData();
    this.getPropertyData();
    this.getTimezoneData();
    this.getBrandData();
    this.getSuitabilityData();
    this.getParkingTypeData();
  }

  async getData() {
    if (this.props.property_slug !== undefined) {
      var resData = {};

      let res = await PropertyService.getProperty(this.props.property_slug);
      if (global.successStatus.includes(res.status)) {
        this.props.affordable(res.data.affordable_component_check);
        this.props.logo(
          res.data.property_name,
          res.data.property_type.property_type_name,
          res.data.year_value,
          res.data.property_logo_url,
          this.props.property_slug
        );

        resData = res.data ? res.data : {};
        resData.sort_order =
          res.data.sort_order === 0 ? "" : res.data.sort_order;
        //for checkbox
        resData.observer_day_ligth_saving =
          res.data.observer_day_ligth_saving === 1 ? true : false;
        resData.international_property =
          res.data.international_property === 1 ? true : false;
        // resData.full_listing = res.data.full_listing === 1 ? true : false;
        resData.pet_allowed = res.data.pet_allowed === 1 ? true : false;
        resData.affordable_component_check =
          res.data.affordable_component_check === 1 ? true : false;
        resData.status_id = res.data.status_id === 1 ? true : false;
        //for view
        resData.country = res.data.country ? res.data.country.country_name : "";
        resData.suitability_name_data = res.data.suitability_name_data
          ? res.data.suitability_name_data
          : "";
        //for edit
        resData.ada_compliant = res.data.ada_compliant
          ? res.data.ada_compliant
          : 2;
        resData.property_type_id = res.data.property_type
          ? res.data.property_type.slug
          : "";
        resData.brand_id = res.data.brand ? res.data.brand.slug : "";
        resData.timezone_id = res.data.timezone ? res.data.timezone.slug : "";
        resData.parking_type_id = res.data.parking_type
          ? res.data.parking_type.slug
          : "";
        resData.imageUrl = res.data.property_logo_url
          ? res.data.property_logo_url
          : propertyLogo;
        resData.imgPropUrl = res.data.property_main_image_url
          ? res.data.property_main_image_url
          : propertyImg;
        resData.year_value = res.data.year_value ? res.data.year_value : "";
        resData.brand_image = res.data.brand
          ? res.data.brand.brand_logo_url
          : brandsmallLogo;
        if (this.state.viewMode === 1) {
          var selectedSuitabilityList = [];

          res.data.suitabilityData.forEach((item, i) => {
            selectedSuitabilityList = [
              ...selectedSuitabilityList,
              {
                value: item.slug,
                label: item.suitability_name,
              },
            ];
          });

          var selectedPropertyList = [];
          selectedPropertyList = res.data.property_type
            ? [
                {
                  value: res.data.property_type.slug,
                  label: res.data.property_type.property_type_name,
                },
              ]
            : "";

          var selectedBrandList = [];
          selectedBrandList = res.data.brand
            ? [
                {
                  value: res.data.brand.slug,
                  label: res.data.brand.brand_name,
                },
              ]
            : "";

          var selectedTimeZoneList = [];
          selectedTimeZoneList = res.data.timezone
            ? [
                {
                  value: res.data.timezone.slug,
                  label: res.data.timezone.timezone_name,
                },
              ]
            : "";

          var selectedParkingTypeList = [];
          selectedParkingTypeList = res.data.parking_type
            ? [
                {
                  value: res.data.parking_type.slug,
                  label: res.data.parking_type.parking_type_name,
                },
              ]
            : "";
        }
      }
      this.setState(resData);
      this.setState({
        selectedSuitabilityList: selectedSuitabilityList,
        selectedPropertyList: selectedPropertyList,
        selectedBrandList: selectedBrandList,
        selectedTimeZoneList: selectedTimeZoneList,
        selectedParkingTypeList: selectedParkingTypeList,
      });
      this.setState({ suitability_id: selectedSuitabilityList });
    }
  }

  async getPropertyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var propertyList = [];
    var propertyListTotal = 0;
    let res = await PropertyTypeService.getPropertyType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;
    }
    const newPropertyList =
      propertyListTotal > 0
        ? propertyList.map(({ slug, property_type_name }) => ({
            value: slug,
            label: property_type_name,
          }))
        : [];
    this.setState({
      propertyList: propertyList,
      newPropertyList: newPropertyList,
      propertyListTotal: propertyListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getTimezoneData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var timeZoneList = [];
    var timeZoneListTotal = 0;
    let res = await TimezoneService.getTimezone(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      timeZoneList = res.data ? res.data : [];
      timeZoneListTotal = timeZoneList ? timeZoneList.length : 0;
    }
    const newTimeZoneList =
      timeZoneListTotal > 0
        ? timeZoneList.map(({ slug, timezone_name }) => ({
            value: slug,
            label: timezone_name,
          }))
        : [];
    this.setState({
      timeZoneList: timeZoneList,
      newTimeZoneList: newTimeZoneList,
      timeZoneListTotal: timeZoneListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getBrandData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var brandList = [];
    var brandListTotal = 0;

    let res = await BrandService.getBrandList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      brandList = res.data ? res.data : [];
      brandListTotal = brandList ? brandList.length : 0;
    }
    const newBrandList =
      brandListTotal > 0
        ? brandList.map(({ slug, brand_name }) => ({
            value: slug,
            label: brand_name,
          }))
        : [];
    this.setState({
      brandList: brandList,
      newBrandList: newBrandList,
      brandListTotal: brandListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSuitabilityData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var suitabilityList = [];
    var suitabilityListTotal = 0;
    let res = await PropertySuitabilityService.getPropertySuitability(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      suitabilityList = res.data ? res.data : [];
      suitabilityListTotal = suitabilityList ? suitabilityList.length : 0;
    }
    const newSuitabilityList =
      suitabilityListTotal > 0
        ? suitabilityList.map(({ slug, suitability_name }) => ({
            value: slug,
            label: suitability_name,
          }))
        : [];
    this.setState({
      suitabilityList: suitabilityList,
      newSuitabilityList: newSuitabilityList,
      suitabilityListTotal: suitabilityListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getParkingTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var parkingTypeList = [];
    var parkingTypeListTotal = 0;
    let res = await ParkingTypeService.getParkingType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      parkingTypeList = res.data ? res.data : [];
      parkingTypeListTotal = parkingTypeList ? parkingTypeList.length : 0;
    }
    const newParkingTypeList =
      parkingTypeListTotal > 0
        ? parkingTypeList.map(({ slug, parking_type_name }) => ({
            value: slug,
            label: parking_type_name,
          }))
        : [];
    this.setState({
      parkingTypeList: parkingTypeList,
      newParkingTypeList: newParkingTypeList,
      parkingTypeListTotal: parkingTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  handleChangeSuitabilityList(value) {
    let suitability_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        suitability_id.push(item.value);
      });
    }
    this.setState({
      selectedSuitabilityList: value,
      suitability_id: suitability_id,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
    this.setState({ confirm_back: 1 });
  };

  handleChangePropertyList(value) {
    let property_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_type_id.push(item.value);
      });
    }
    const property_type_id_value = value !== null ? value.value : [];
    this.setState({
      selectedPropertyList: value,
      property_type_id: property_type_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeBrandList(value) {
    let brand_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        brand_id.push(item.value);
      });
    }
    const brand_id_value = value !== null ? value.value : [];
    this.setState({
      selectedBrandList: value,
      brand_id: brand_id_value,
    });

    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeParkingTypeList(value) {
    let parking_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        parking_type_id.push(item.value);
      });
    }
    const parking_type_value = value !== null ? value.value : [];
    this.setState({
      selectedParkingTypeList: value,
      parking_type_id: parking_type_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeTimeZoneList(value) {
    let timezone_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        timezone_id.push(item.value);
      });
    }
    const timezone_value = value !== null ? value.value : [];
    this.setState({
      selectedTimeZoneList: value,
      timezone_id: timezone_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  // Custom validation rules
  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.year_value !== undefined &&
      input.year_value !== "" &&
      input.year_value !== null &&
      input.year_value.length < 4
    ) {
      isValid = false;
      errors["year_value"] = "The year built must be at least 4 numbers.";
    }
    if (
      input.description !== undefined &&
      input.description !== "" &&
      input.description !== null &&
      input.description.length > 100000
    ) {
      isValid = false;
      errors["description"] =
        "The unit description character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  // onMainImageChange = async (event) => {
  //     if (event.target.files[0] !== undefined) {
  //       this.setState({ showSpinner: true });
  //       const fileData = new FormData();
  //       fileData.append(
  //         "property_main_image",
  //         event.target.files[0],
  //         event.target.files[0].name
  //       );
  //       let res = await PropertyService.uploadPropertyMainImg(fileData);
  //       if (global.successStatus.includes(res.status)) {
  //         this.setState({
  //           property_main_image: res.data.fileName,
  //           imgPropUrl: res.data.filePath,
  //         });
  //         this.setState({ showSpinner: false });
  //       } else if (res.status === 413) {
  //         this.setState({
  //           showSpinner: false,
  //           showAlertModal: true,
  //           alertModalType: "error",
  //           alertModalTitle: "Error!",
  //           alertModalMessage: "Content Too Large",
  //         });
  //       } else {
  //         let alertMessage = "";
  //         if (res.data.result.errorDetail !== null) {
  //           let errors = {};
  //           res.data.errorCode = "Validation Failed";
  //           res.data.result.errorDetail.forEach((item) => {
  //             errors[item.errorField] = item.errorMessage[0];
  //             // alertMessage += item.errorMessage[0]+'\n';
  //             res.data.errorMessage = "";
  //           });
  //           this.setState({ errors: errors });
  //         }
  //         this.setState({
  //           showSpinner: false,
  //           showAlertModal: true,
  //           alertModalType: "error",
  //           alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
  //           alertModalMessage:
  //             alertMessage !== "" ? alertMessage : res.data.message,
  //         });
  //       }
  //       setTimeout(
  //         () => this.setState({ showAlertModal: false }),
  //         global.alert_close_time
  //       );
  //     }
  //   };

  // onLogoImageChange = async (event) => {
  //   if (event.target.files[0] !== undefined) {
  //     this.setState({ showSpinner: true });
  //     const fileData = new FormData();
  //     fileData.append(
  //       "property_logo",
  //       event.target.files[0],
  //       event.target.files[0].name
  //     );
  //     let res = await PropertyService.uploadPropertyLogo(fileData);
  //     if (global.successStatus.includes(res.status)) {
  //       this.setState({
  //         property_logo: res.data.fileName,
  //         imageUrl: res.data.filePath,
  //       });
  //       this.setState({ showSpinner: false });
  //     } else if (res.status === 413) {
  //       this.setState({
  //         showSpinner: false,
  //         showAlertModal: true,
  //         alertModalType: "error",
  //         alertModalTitle: "Error!",
  //         alertModalMessage: "Content Too Large",
  //       });
  //     } else {
  //       let alertMessage = "";
  //       if (res.data.result.errorDetail !== null) {
  //         let errors = {};
  //         res.data.errorCode = "Validation Failed";
  //         res.data.result.errorDetail.forEach((item) => {
  //           errors[item.errorField] = item.errorMessage[0];
  //           // alertMessage += item.errorMessage[0]+'\n';
  //           res.data.errorMessage = "";
  //         });
  //         this.setState({ errors: errors });
  //       }
  //       this.setState({
  //         showSpinner: false,
  //         showAlertModal: true,
  //         alertModalType: "error",
  //         alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
  //         alertModalMessage:
  //           alertMessage !== "" ? alertMessage : res.data.message,
  //       });
  //     }
  //     setTimeout(
  //       () => this.setState({ showAlertModal: false }),
  //       global.alert_close_time
  //     );
  //   }
  // };

  saveOrUpdateProperty = async (e) => {
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });

      this.state.suitability_id = this.state.selectedSuitabilityList
        ? this.state.selectedSuitabilityList.map(function (obj) {
            return obj.value;
          })
        : "";
      let multiSuitabilityString = this.state.suitability_id
        ? this.state.suitability_id.join(",")
        : "";

      let inputData = {
        //first tab data
        tab: 1,
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        property_company_legal_entity: this.state.property_company_legal_entity,
        management_company_legal_entity:
          this.state.management_company_legal_entity,
        joint_venture_company_legal_entity:
          this.state.joint_venture_company_legal_entity,
        tax_id: this.state.tax_id,
        property_type_slug: this.state.property_type_id,
        description: this.state.description,
        property_rules: this.state.property_rules,
        year_value: this.state.year_value,
        timezone_slug: this.state.timezone_id,
        observer_day_ligth_saving:
          this.state.observer_day_ligth_saving === true ? 1 : 2,
        international_property:
          this.state.international_property === true ? 1 : 2,
        brand_slug: this.state.brand_id,
        // full_listing: this.state.full_listing === true ? 1 : 2,
        pet_allowed: this.state.pet_allowed === true ? 1 : 0,
        affordable_component_check:
          this.state.affordable_component_check === true ? 1 : 0,
        // home_page_title: this.state.home_page_title,
        suitability_slug: multiSuitabilityString,
        parking_type_slug: this.state.parking_type_id,
        property_logo: this.state.property_logo,
        property_main_image: this.state.property_main_image,
        status_id: this.state.status_id === true ? 1 : 2,
        sort_order: this.state.sort_order,
      };

      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        // inputData.status_id = this.state.status_id ? this.state.status_id : 2;
        // this.props.logo(this.state.property_name,this.state.property_type,this.state.year_value,this.state.imageUrl);

        let res = await PropertyService.updateProperty(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          sessionStorage.setItem(
            "property_slug",
            this.state.slug ? this.state.slug : ""
          );
          sessionStorage.setItem(
            "property_name",
            res.data.property_name ? res.data.property_name : ""
          );
          sessionStorage.setItem(
            "property_type",
            res.data.property_type
              ? res.data.property_type.property_type_name
              : ""
          );
          sessionStorage.setItem(
            "year_value",
            res.data.year_value ? res.data.year_value : ""
          );
          sessionStorage.setItem(
            "property_logo_url",
            res.data.property_logo_url ? res.data.property_logo_url : ""
          );
          sessionStorage.setItem(
            "property_logo_thumb_url",
            res.data.property_logo_thumb_url ? res.data.property_logo_thumb_url : ""
          );

          localStorage.setItem("property_slug",this.state.property_slug);
          localStorage.setItem("property_name",res.data.property_name);
          localStorage.setItem("property_type",res.data.property_type.property_type_name);
          localStorage.setItem("year_value",res.data.year_value);
          localStorage.setItem("property_logo_url",res.data.property_logo_url);
          localStorage.setItem("property_logo_thumb_url",res.data.property_logo_thumb_url);

          this.getData();
          this.getPropertyAffordable();
          this.setState({ viewMode: 0 });
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      // const err = this.validator.errorMessages;
      // const err1 = Object.values(this.validator.errorMessages);
      // if(Object.values(this.validator.errorMessages) !== null){
      //   const err2 = Object.keys(this.validator.errorMessages);
      //   console.log(err,err1,err2);
      // }

      // err.forEach(element => {
      //   console.log("1",element);
      //   if(element !== null){
      //     console.log("2",element);
      //     const input = document.getElementById(element);
      //     input.scrollIntoView({behavior: 'smooth'});
      //   }
      // });

      // const err = Object.keys(this.validator.errorMessages);
      //  console.log("err", err, err[0]);
      // // const input = document.getElementsByName(err[0]);
      // const input = document.getElementById(err[0]);
      // console.log("input", input);
      // input.scrollIntoView({behavior: 'smooth'});

      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      }
    }
  };

  // get affordable
  async getPropertyAffordable() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await PropertyService.getPropertyAffordable(this.state.slug);
    if (global.successStatus.includes(res.status)) {
      resData.affordable_component_check = res.data.affordable_component_check
        ? res.data.affordable_component_check
        : 0;
    }
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  changeView() {
    var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
      this.setState({ selectedSuitabilityList: [] });
      this.setState({ newSuitabilityList: [] });
      this.setState({ suitability_id: "" });
      this.getData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
      this.setState({ selectedSuitabilityList: [] });
      this.setState({ newSuitabilityList: [] });
      this.setState({ suitability_id: "" });
      this.getData();
      this.getSuitabilityData();
    }
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <div className="dashboardRightcard">
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <div className="d-flex justify-content-end">
          {/* <Link to={'/properties/update/'+this.props.property_slug+"/basic"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
          {this.state.viewMode === 0 ? (
            <button
              className="btn-success-outline-small"
              onClick={this.changeView}
            >
              <img src={editIcon} />
              Edit
            </button>
          ) : (
            <div className="d-flex">
              <button
                type="button"
                className="btn-success-outline-small"
                onClick={() => {
                  if (this.state.confirm_back === 1)
                    if (window.confirm("Do you want to exit without saving?"))
                      this.changeView();
                    else return false;
                  else this.changeView();
                }}
              >
                Cancel
              </button>
              <button
                className="btn-success ml-3"
                onClick={this.saveOrUpdateProperty}
              >
                Save
              </button>
            </div>
          )}
        </div>

        <div className="col-md-12 pl-0 pr-0 mg-t-15">
          <Alerts
            show={this.state.showAlertModal}
            // show="true"
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>
        {this.state.viewMode === 0 ? (
          <>
            <div className="row">
              <div className="col-md-3">
                <div className="demo-avatar-group d-flex justify-content-center flex-column">
                  <div className="az-img-user avatar-md online mg-b-8">
                    <figure>
                      {this.state.status_id === true ? (
                        <b className="status-active"></b>
                      ) : (
                        ""
                      )}
                      {this.state.status_id === false ? (
                        <b className="status-inactive"></b>
                      ) : (
                        ""
                      )}
                      <img
                        src={
                          this.props.imgFile
                            ? this.props.imgFile
                            : this.state.imageUrl
                            ? this.state.imageUrl
                            : propertyLogo
                        }
                        alt="Basic Property Photo"
                        className="rounded-circle"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="propname">
                  <h5>
                    {" "}
                    {sessionStorage.getItem("property_name")
                      ? sessionStorage.getItem("property_name")
                      : "Property"}{" "}
                  </h5>
                  <ul>
                    <li>
                      {" "}
                      {sessionStorage.getItem("property_type")
                        ? sessionStorage.getItem("property_type")
                        : ""}{" "}
                    </li>
                    {sessionStorage.getItem("year_value") ? <li></li> : ""}
                    <li>
                      {sessionStorage.getItem("year_value")
                        ? "Year " + sessionStorage.getItem("year_value")
                        : ""}
                    </li>
                  </ul>
                </div>
                {/* <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                              <div className='col-md-4'>
                                  <p className="az-profile-name-text pl-0">Property Company Legal Entity</p>
                                  <p className="media-body">{this.state.property_company_legal_entity ? this.state.property_company_legal_entity : ""}</p>
                              </div>
                              <div className='col-md-4'>
                                  <p className="az-profile-name-text pl-0">Management Company Legal Entity</p>
                                  <p className="media-body">{this.state.management_company_legal_entity ? this.state.management_company_legal_entity : ""}</p>
                              </div>
                              <div className='col-md-4'>
                                  <p className="az-profile-name-text pl-0">Joint Venture Company Legal Entity</p>
                                  <p className="media-body">{this.state.joint_venture_company_legal_entity ? this.state.joint_venture_company_legal_entity : ""}</p>
                              </div>
                            </div> */}
                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Property SKU</p>
                    <p className="media-body">
                      {this.state.aka_name ? this.state.aka_name : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Tax ID</p>
                    <p className="media-body">
                      {this.state.tax_id ? this.state.tax_id : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Pets Allowed</p>
                    <p className="media-body">
                      {this.state.pet_allowed === true ? "Yes" : "No"}
                    </p>
                  </div>
                </div>

                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Timezone</p>
                    <p className="media-body">
                      {this.state.timezone
                        ? this.state.timezone.time_offset +
                          " " +
                          this.state.timezone.timezone_name
                        : ""}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">
                      Observes Daylight Savings
                    </p>
                    <p className="media-body">
                      {this.state.observer_day_ligth_saving === true
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">International</p>
                    <p className="media-body">
                      {this.state.international_property === true
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                </div>

                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                  <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Affordable</p>
                    <p className="media-body">
                      {this.state.affordable_component_check === true
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mg-t-10 mg-b-30 pd-b-2" />
            <div className="row">
              <div className="col-md-12 pd-l-30 pd-r-30">
                <p className="az-profile-name-text pl-0">
                  Marketing Description:
                </p>
                <p className="para">
                  {/* <ShowMoreText>{this.state.description ? renderHTML("" +this.state.description) : ""}</ShowMoreText> */}
                  {this.state.description ? ( //check if description is exist
                    <p className="p_wrap1">
                      {this.state.descShow
                        ? this.state.description.slice(0, global.showMoreLength)
                        : this.state.description}

                      {this.state.description.length >
                        global.showMoreLength && (
                        <span onClick={this.fullText}>
                          {this.state.descShow ? (
                            <p className="hand-cursor showmore">
                              ...Show more{" "}
                            </p>
                          ) : (
                            <p className="hand-cursor showmore">Show Less </p>
                          )}
                        </span>
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
            <hr className="mg-t-30 mg-b-30 pd-b-2" />
            <div className="row">
              <div className="col-md-12 pd-l-30 pd-r-30">
                <p className="az-profile-name-text pl-0">House Rules:</p>
                <p className="para">
                  {this.state.property_rules ? ( //check if property_rules is exist
                  <p className="p_wrap1">
                        {this.state.descShow
                          ? this.state.website_cookie.slice(
                              0,
                              global.showMoreLength
                            )
                          : this.state.website_cookie}

                        {this.state.website_cookie.length >
                          global.showMoreLength && (
                          <span onClick={this.fullText}>
                            {this.state.descShow ? (
                              <p className="hand-cursor showmore">
                                ...Show more{" "}
                              </p>
                            ) : (
                              <p className="hand-cursor showmore">
                                Show Less{" "}
                              </p>
                            )}
                          </span>
                        )}
                        </p>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
            <hr className="mg-t-30 mg-b-30 pd-b-2" />
            <div className="row">
              <div className="col-md-12">
                <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                  <div className="col-md-4">
                    <div className="row mg-0">
                      <div className="brand-sm-logo mg-b-8">
                        <figure>
                          <img
                            src={
                              this.state.brand_image
                                ? this.state.brand_image
                                : brandsmallLogo
                            }
                            alt="Brand Logo"
                            className="rounded-circle"
                          />
                        </figure>
                      </div>
                      <div className="mg-l-6">
                        <p className="az-profile-name-text pl-0">Brand:</p>
                        <p className="media-body">
                          {this.state.brand ? this.state.brand.brand_name : ""}
                        </p>
                      </div>
                    </div>
                    <p className="az-profile-name-text pl-0">Suitability:</p>
                    <p className="media-body">
                      {this.state.suitability_name_data
                        ? this.state.suitability_name_data
                        : ""}
                    </p>
                  </div>

                  {/* <div className="col-md-4">
                    <p className="az-profile-name-text pl-0">Parking Type:</p>
                    <p className="media-body">
                      {this.state.parking_type
                        ? this.state.parking_type.parking_type_name
                        : ""}
                    </p>
                  </div> */}

                  <div className="col-md-4">
                    {/* <p className="az-profile-name-text pl-0">Home Page Title:</p>
                                <p className="media-body">{this.state.home_page_title ? this.state.home_page_title : ""}</p> */}

                    <p className="az-profile-name-text pl-0">ADA Compliant:</p>
                    <p className="media-body">
                      {this.state.ada_compliant === 1 ? "Yes" : "No"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {this.state.viewMode === 1 ? (
          <div className="m-0">
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  DBA <span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="property_name"
                  value={this.state.property_name}
                  maxLength="50"

                  // placeholder="DBA"
                />
                {this.state.errors.property_name ? (
                  <div className="text-danger">
                    {this.state.errors.property_name}
                  </div>
                ) : (
                  this.validator.message(
                    "property_name",
                    this.state.property_name,
                    "required|max:50",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Property SKU <span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  onChange={this.changeHandler}
                  type="text"
                  // placeholder="SKU"
                  name="aka_name"
                  onKeyPress={global.onKeyPressEvent.alphaNumValidation}
                  value={this.state.aka_name}
                  maxLength="3"
                />
                {this.state.errors.aka_name ? (
                  <div className="text-danger">
                    {this.state.errors.aka_name}
                  </div>
                ) : (
                  this.validator.message(
                    "property_sku",
                    this.state.aka_name,
                    "required|alpha_num_dash|max:3",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                      <div className="col-md-3 pl-0">
                        <label className="form-label text-left mg-b-0">
                          Property Company Legal Entity
                        </label>
                      </div>

                      <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                        <Form.Control
                          onChange={this.changeHandler}
                          maxLength="50"
                          type="text"
                          name="property_company_legal_entity"
                          value={this.state.property_company_legal_entity}
                          // placeholder="Property Company Legal Entity"
                        />
                        {this.state.errors.property_company_legal_entity ? (
                          <div className="text-danger">
                            {this.state.errors.property_company_legal_entity}
                          </div>
                        ) : (
                          this.validator.message(
                            "property_company_legal_entity",
                            this.state.property_company_legal_entity,
                            "max:50",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                    </div>
                    <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                      <div className="col-md-3 pl-0">
                        <label className="form-label text-left mg-b-0">
                          Management Company Legal Entity
                        </label>
                      </div>

                      <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                        <Form.Control
                          onChange={this.changeHandler}
                          maxLength="50"
                          type="text"
                          name="management_company_legal_entity"
                          value={this.state.management_company_legal_entity}
                          // placeholder="Management Company Legal Entity"
                        />
                        {this.state.errors.management_company_legal_entity ? (
                          <div className="text-danger">
                            {this.state.errors.management_company_legal_entity}
                          </div>
                        ) : (
                          this.validator.message(
                            "management_company_legal_entity",
                            this.state.management_company_legal_entity,
                            "max:50",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                    </div>
                    <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                      <div className="col-md-3 pl-0">
                        <label className="form-label text-left mg-b-0">
                          Joint Venture Company Legal Entity
                        </label>
                      </div>

                      <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                        <Form.Control
                          onChange={this.changeHandler}
                          maxLength="50"
                          type="text"
                          name="joint_venture_company_legal_entity"
                          value={this.state.joint_venture_company_legal_entity}
                          // placeholder="Joint Venture Company Legal Entity"
                        />
                        {this.state.errors.joint_venture_company_legal_entity ? (
                          <div className="text-danger">
                            {this.state.errors.joint_venture_company_legal_entity}
                          </div>
                        ) : (
                          this.validator.message(
                            "joint_venture_company_legal_entity",
                            this.state.joint_venture_company_legal_entity,
                            "max:50",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                    </div> */}
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Tax ID <span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  onChange={this.changeHandler}
                  name="tax_id"
                  value={this.state.tax_id}
                  maxLength="50"
                  type="text"
                  // placeholder="Tax ID"
                />
                {this.state.errors.tax_id ? (
                  <div className="text-danger">{this.state.errors.tax_id}</div>
                ) : (
                  this.validator.message(
                    "tax_id",
                    this.state.tax_id,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Property Type <span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={this.state.selectedPropertyList}
                  options={this.state.newPropertyList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangePropertyList(value)}
                  defaultValue={this.state.selectedPropertyList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />

                {/* <select
                        className=""
                        onChange={this.changeHandler}
                        name="property_type_id"
                        value={this.state.property_type_id}

                        >
                        <option key="" value="">
                            Select Property Type
                        </option>
                        {this.state.propertyList
                            // .sort((a, b) => (a.label > b.label ? 1 : -1))
                            .map((option) => (
                            <option
                                key={option.slug}
                                value={option.slug}
                            >
                                {option.property_type_name}
                            </option>
                            ))}
                        </select> */}
                {this.state.errors.property_type_id ? (
                  <div className="text-danger">
                    {this.state.errors.property_type_id}
                  </div>
                ) : (
                  this.validator.message(
                    "property_type",
                    this.state.property_type_id,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Brand <span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={this.state.selectedBrandList}
                  options={this.state.newBrandList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeBrandList(value)}
                  defaultValue={this.state.selectedBrandList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />

                {/* <select
                        className=""
                        onChange={this.changeHandler}
                        name="brand_id"
                        value={this.state.brand_id}

                        >
                        <option key="" value="">
                            Select Brand
                        </option>
                          {this.state.brandList
                            // .sort((a, b) => (a.label > b.label ? 1 : -1))
                            .map((option) => (
                            <option
                                key={option.slug}
                                value={option.slug}
                            >
                                {option.brand_name}
                            </option>
                            ))}
                        </select> */}
                {this.state.errors.brand_id ? (
                  <div className="text-danger">
                    {this.state.errors.brand_id}
                  </div>
                ) : (
                  this.validator.message(
                    "brand",
                    this.state.brand_id,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-start pd-t-30 pd-b-30">
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <InputGroup className="mt-3">
                  <label className="form-label text-left mg-b-0">
                    Marketing Description
                  </label>

                  <Form.Control
                    onChange={this.changeHandler}
                    name="description"
                    value={this.state.description}
                    className="form-control max_width_100"
                    id="description"
                    as="textarea"
                    rows={global.textareaRowLength}
                    // placeholder="Username"
                  />
                </InputGroup>
                {this.state.errors.description ? (
                  <div className="text-danger">
                    {this.state.errors.description}
                  </div>
                ) : (
                  this.validator.message(
                    "description",
                    this.state.description,
                    "max:100000",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-start pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0"></div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <InputGroup className="mt-3">
                  <label className="form-label text-left mg-b-0">
                    House Rules
                  </label>

                  <Form.Control
                    onChange={this.changeHandler}
                    name="property_rules"
                    value={this.state.property_rules}
                    className="form-control max_width_100"
                    id="property_rules"
                    as="textarea"
                    rows={global.textareaRowLength}
                    // placeholder="Username"
                  />
                </InputGroup>
                {this.state.errors.property_rules ? (
                  <div className="text-danger">
                    {this.state.errors.property_rules}
                  </div>
                ) : (
                  this.validator.message(
                    "property_rules",
                    this.state.property_rules,
                    "max:100000",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Year Built
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  onChange={this.changeHandler}
                  name="year_value"
                  value={this.state.year_value}
                  className="form-control max_width_100"
                  id="inlineFormInput"
                  type="text"
                  minLength="4"
                  maxLength="4"
                  autoComplete="off"
                  // placeholder="Year"
                  onKeyPress={global.onKeyPressEvent.numberValidation}
                />
                {this.state.errors.year_value ? (
                  <div className="text-danger">
                    {this.state.errors.year_value}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Timezone</label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={this.state.selectedTimeZoneList}
                  options={this.state.newTimeZoneList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeTimeZoneList(value)}
                  defaultValue={this.state.selectedTimeZoneList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />

                {/* <select
                        className=""
                        onChange={this.changeHandler}
                        name="timezone_id"
                        value={this.state.timezone_id}

                        >
                        <option key="" value="">
                            Select Property Timezone
                        </option>
                        {this.state.timeZoneList
                            // .sort((a, b) => (a.label > b.label ? 1 : -1))
                            .map((option) => (
                            <option
                                key={option.slug}
                                value={option.slug}
                            >
                                {option.timezone_name}
                            </option>
                            ))}
                        </select> */}
                {this.state.errors.timezone_id ? (
                  <div className="text-danger">
                    {this.state.errors.timezone_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Observes Daylight Savings
                </label>
              </div>
              <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                <Form.Check
                  name="observer_day_ligth_saving"
                  type={"checkbox"}
                  id={`observer_day_ligth_saving`}
                  label={""}
                  className={"ckbox"}
                  onChange={this.changeHandler}
                  checked={this.state.observer_day_ligth_saving}
                />
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  International
                </label>
              </div>
              <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                <Form.Check
                  name="international_property"
                  type={"checkbox"}
                  id={`international_property`}
                  label={""}
                  className={"ckbox"}
                  onChange={this.changeHandler}
                  checked={this.state.international_property}
                />
              </div>
            </div>
            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                    <div className="col-md-3 pl-0">
                        <label className="form-label text-left mg-b-0">
                            Full Listing
                        </label>
                    </div>
                    <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                        <Form.Check
                        name="full_listing"
                        type={"checkbox"}
                        id={`full_listing`}
                        label={""}
                        className={"ckbox"}
                        onChange={this.changeHandler}
                        checked={this.state.full_listing}
                        />
                    </div>
                    </div> */}
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Pets Allowed
                </label>
              </div>
              <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                <Form.Check
                  name="pet_allowed"
                  type={"checkbox"}
                  id={`pet_allowed`}
                  label={""}
                  className={"ckbox"}
                  onChange={this.changeHandler}
                  checked={this.state.pet_allowed}
                />
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Affordable
                </label>
              </div>
              <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                <Form.Check
                  name="affordable_component_check"
                  type={"checkbox"}
                  id={`affordable_component_check`}
                  label={""}
                  className={"ckbox"}
                  onChange={this.changeHandler}
                  checked={this.state.affordable_component_check}
                />
              </div>
            </div>
            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                    <div className="col-md-3 pl-0">
                        <label className="form-label text-left mg-b-0">
                        Home Page Title
                        </label>
                    </div>

                    <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                        <Form.Control
                        type="text"
                        onChange={this.changeHandler}
                        name="home_page_title"
                        value={this.state.home_page_title}
                        maxLength="50"

                        // placeholder="Home Page Title"
                        />
                        {this.state.errors.home_page_title ? (
                        <div className="text-danger">
                            {this.state.errors.home_page_title}
                        </div>
                        ) : (
                        this.validator.message(
                            "home_page_title",
                            this.state.home_page_title,
                            "max:50",
                            { className: "text-danger" }
                        )
                        )}
                    </div>
                    </div>   */}
            <div className="row row-xs align-items-start pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Suitability
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  isMulti
                  styles={customStyles}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={this.state.selectedSuitabilityList}
                  options={this.state.newSuitabilityList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeSuitabilityList(value)}
                  defaultValue={this.state.selectedSuitabilityList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}

                  // theme={(theme) => ({
                  //   ...theme,
                  //   colors: {
                  //     ...theme.colors,
                  //     primary: 'black',
                  //   },
                  // })}
                />
                {this.state.errors.suitability_id ? (
                  <div className="text-danger">
                    {this.state.errors.suitability_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Parking Type
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedParkingTypeList}
                  options={this.state.newParkingTypeList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeParkingTypeList(value)}
                  defaultValue={this.state.selectedParkingTypeList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
                {this.state.errors.parking_type_id ? (
                  <div className="text-danger">
                    {this.state.errors.parking_type_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  ADA Compliant
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0 d-flex justify-content-start">
                <Form.Check
                  onChange={this.changeHandler}
                  name="ada_compliant"
                  value="1"
                  checked={this.state.ada_compliant === 1 ? true : false}
                  type={"radio"}
                  id="1"
                  label={"Yes"}
                  className={"rdiobox mg-r-30"}
                />
                <Form.Check
                  onChange={this.changeHandler}
                  name="ada_compliant"
                  value="2"
                  checked={this.state.ada_compliant === 2 ? true : false}
                  type={"radio"}
                  id="2"
                  label={"No"}
                  className={"rdiobox"}
                />
              </div>
            </div>

            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Status</label>
              </div>

              <div className="col-md-7 col-lg-6 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                <b className={this.state.status_id ? "active" : "inactive"}></b>
                <div
                  className={
                    this.state.status_id ? "az-toggle on" : "az-toggle"
                  }
                  onClick={() =>
                    this.setState({
                      status_id: !this.state.status_id,
                    })
                  }
                >
                  <span></span>
                </div>
              </div>
            </div>
            {/* <div className="row row-xs align-items-baseline pd-t-30 pd-b-30">
                    <div className="col-md-3 pl-0">
                        <label className="form-label text-left mg-b-0">
                        Sort Order
                        </label>
                    </div>

                    <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                        <Form.Control
                        onChange={this.changeHandler}
                        name="sort_order"
                        value={this.state.sort_order}
                        onKeyPress={
                            global.onKeyPressEvent.numberValidation
                        }
                        type="text"
                        // placeholder="Sort Order"
                        />
                        <span className="d-inline-flex align-items-start justify-content-start infosize">
                        <img src={infosize} className="mg-r-5" />
                        <p>Sort Order will use for listing sequence.</p>
                        </span>
                        {this.state.errors.sort_order ? (
                        <div className="text-danger">
                            {this.state.errors.sort_order}
                        </div>
                        ) : (
                        this.validator.message(
                            "sort_order",
                            this.state.sort_order,
                            "numeric|min:0,num",
                            { className: "text-danger" }
                        )
                        )}
                    </div>
                    </div> */}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Basic;

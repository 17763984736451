import React, { Component } from "react";
import { Container, Tab, Nav, InputGroup, Modal, Form } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import ActivityHistory from "./ActivityHistory";
import { Link } from "react-router-dom";
import pluswIcon from "./../../../assets/images/plus.svg";
import PropertyService from "../../../services/PropertyService";
import UnitsService from "../../../services/UnitsService";
import LatchService from "../../../services/LatchService";
import Moment from "moment";
import filterIcon from "./../../../assets/images/filter-gray.svg";
import ReactDatatable from "@ashvin27/react-datatable";
import Select from "react-select";
class LatchActivity extends Component {

  constructor(props) {
    super(props);
    this.state = {
      property_slug: this.props.match.params.propertySlug,
      activeClass: "latch-access",
      lockType: 2,
      tab: 1,
      total_record: 0,
      isSearch: global.isSearch,
      records: [],
      extraButtons: [],
      tableQueryString: "",
      customQueryString: "",
      filter_value: "",
      door_name: "",
      input: {},
      loading: false,
      showSpinner: false,
      keysTabNumber: localStorage.getItem("keys_activity_tab_number")
      ? parseInt(localStorage.getItem("keys_activity_tab_number"))
      : "",
      columns: [
        {
          key: "unit_name",
          text: "Unit Name",
          className: "unit_name hand-cursor",
          sortable: true,
          cell: (record) => record.unit_name || ""
        },
        {
          key: "door_name",
          text: "Door Name",
          className: "door_name hand-cursor",
          sortable: true,
          cell: (record) => record.door_name || ""
        },
        {
          key: "start_time",
          text: "Start Time",
          className: "start_time hand-cursor",
          sortable: true,
          cell: (record) => record.start_time ? Moment(record.start_time).format(global.dateTimeFormat) : ""
        },
        {
          key: "end_time",
          text: "End Time",
          className: "end_time hand-cursor",
          sortable: true,
          cell: (record) => record.end_time ? Moment(record.end_time).format(global.dateTimeFormat) : ""
        },
        {
          key: "first_name",
          text: "Name",
          className: "first_name hand-cursor",
          sortable: true,
          cell: (record) => record.first_name != "" ?  record.first_name  + " " + record.last_name : ""  
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor",
          sortable: true,
          cell: (record) => record.email || ""
        },
        {
          key: "ref_table_id",
          text: "Type",
          className: "ref_table_id hand-cursor",
          sortable: true,
          // cell: (record) => record.ref_table_id && record.ref_table_id == 1 ? "Guest" : record.ref_table_id == 2 ? "Resident" : "Cleaner"
          cell: (record) => record.key_type ? record.key_type : ""
        },
        {
          key: "status_id",
          text: "Access Status",
          className: "status_id hand-cursor text-left",
          sortable: true,
          cell: (record) => record.status_id == 4 ? "Created" : "Pending"
        },
      ],
      config: {
        sort: { column: "unit_name", order: "desc" },
        key_column: "latch_access_id",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: { excel: global.excel_button, print: global.print_button, extra: false },
        language: { no_data_text: "No data found.", },
      },
      unitOptions: [],
      selectedUnitId: 0,
      doorOptions: [],
      selectedDoorId: 0,
      searchEmail: "",
      is_search: 0
    };
    this.filterData = this.filterData.bind(this);
  }
  async componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      console.log("keysTabNumber===>>",this.state.keysTabNumber);
      switch (this.state.keysTabNumber) {
        case 1:
          document.getElementById("latch-access").click();
          this.getData();
          break;
        case 2:
          document.getElementById("latch-activity").click();
          this.getData();
          break;
        default:
          document.getElementById("latch-access").click();
      }
      this.getLockType();
      this.getData();
      this.getUnitData();
      this.getDoorOptionData();
    }
    // document.getElementById("latch-access").click();

  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    this.setState({ is_search: 0 });
    this.setState({ module_search: "" });
    if (e === "latch-access") {
      this.setState({ keysTabNumber: 4, isRefresh: 4 });
      setTimeout(() => {
        localStorage.setItem(
          "latch_activity_tab_number",
          this.state.keysTabNumber ? this.state.keysTabNumber : ""
        );
        this.getData();
      }, 5);
    } else if (e === "latch-activity") {
      this.setState({ keysTabNumber: 2, isRefresh: 2 });
      setTimeout(() => {
        localStorage.setItem(
          "latch_activity_tab_number",
          this.state.keysTabNumber ? this.state.keysTabNumber : ""
        );
      }, 5);
    }
  }

  // getActiveClass(e) {
  //   this.setState({ activeClass: e });
  //   this.setState({ module_search: "" });
  //   if (e === "latch-access") {
  //     this.setState({ tab: 1, isRefresh: 1 });
  //     setTimeout(() => { localStorage.setItem("latch_activity_tab_number", this.state.tab || 1); }, 5);
  //   }
  //   else if (e === "latch-activity") {
  //     this.setState({ tab: 2, isRefresh: 1 });
  //     setTimeout(() => { localStorage.setItem("latch_activity_tab_number", this.state.tab || 2); }, 5);
  //   }
  // }
  async getLockType() {
    let lockType = 0;
    let res = await PropertyService.getProperty(this.state.property_slug);
    if (global.successStatus.includes(res.status) && res.data && res.data.lock_type) {
      lockType = res.data.lock_type;
    }
    this.setState({ lockType: lockType });
  }
  async getUnitData() {
    let unitOptions = [];
    let res = await UnitsService.getUnitsList(`property_slug=${this.state.property_slug}&is_dropdown=1`);
    if (global.successStatus.includes(res.status) && res.data) {
      let unitList = res.data ? res.data : [];
      if (unitList && unitList.length > 0) {
        unitOptions = unitList.map(({ unit_id, unit_number, unit_name }) => {
          return { value: unit_id, label: unit_name || unit_number }
        })
      }
    }
    this.setState({ unitOptions: unitOptions });
  }
  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    let finalQueryString = `property_slug=${this.state.property_slug}`;
    if (queryString)
      finalQueryString = `${finalQueryString}&${queryString}`
    let res = await LatchService.getLatchAccessSchedules(finalQueryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({ total_record: totalRecords, records: list });
  }
  async getDoorOptionData() {
    let doorOptions = [];
    let res = await LatchService.getDoorDropdownList(`property_slug=${this.state.property_slug}`);
    if (global.successStatus.includes(res.status) && res.data) {
      let doorList = res.data ? res.data : [];
      if (doorList && doorList.length > 0) {
        doorOptions = doorList.map(({ latch_door_id, door_name }) => {
          return { value: latch_door_id, label: door_name }
        })
      }
    }
    this.setState({ doorOptions: doorOptions });
  }
  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () =>
            this.getData(queryString + "&tab=" + this.state.tab + "&is_list=1"),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString + "&tab=" + this.state.tab + "&is_list=1");
    }
  };
  
  handleChangeUnitList(value) {
    this.setState({ selectedUnitId: value });
  }

  handleChangeDoorList(value) {
    this.setState({ selectedDoorId: value });
  }

  changeEmailHandler = (event) => {
    this.setState({ searchEmail: event.target.value });
  };

  filterData() {
    if (this.state.selectedDoorId || this.state.selectedUnitId || this.state.searchEmail) {
      let queryString = `unit_id=${this.state.selectedUnitId.value || 0}&latch_door_id=${this.state.selectedDoorId.value || 0}&email=${this.state.searchEmail || ""}`;
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.getData(queryString);
    }
  }
  clearFilter() {
    window.location.search = "";
    this.setState({
      is_search: 0,
      selectedDoorId: {},
      selectedUnitId: {},
      searchEmail: ""
    });
  }

  getTitle() {
    if (this.state.activeClass === "latch-access") {
      return "Access";
    }
    if (this.state.activeClass === "latch-activity") {
      return "Activity";
    }
  }

  rowClickedHandler = (event, data, rowIndex) => {
    window.open(`/properties/view/${this.state.property_slug}/latches/access-view/${data.slug}`);
  };
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0 keycafe-header-sticky-">
                  <div className="dashbaord-pageHeadertitle dashboardRightcard d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns keycafemappingtitle">
                  <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0 fout_btn_w">
                        {global.userPermissions.checkPermission(
                          "latch-manual-edit"
                        ) &&
                          this.state.property_slug && (
                            <Link
                              to={
                                this.props.match.params.propertySlug !==
                                undefined
                                  ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/latches/activity-add"
                                  : "/latches/activity-add/" +
                                    this.state.property_slug
                              }
                            >
                              {this.state.activeClass === "latch-access" ? (
                                <button className="btn-success-outline-small ">
                                  <img src={pluswIcon} alt="" /> Add
                                </button>
                              ) : (
                                ""
                              )}
                            </Link>
                          )}
                        {this.state.activeClass === "latch-access" ? (
                          <button
                            onClick={this.searchToggle}
                            className="btn-success-outline-small ml-3"
                          >
                            <img src={filterIcon} alt="filterIcon" />{" "}
                            {this.state.isSearch === 1 ? "Search" : "Search"}
                          </button>
                        ) : (
                          ""
                        )}
                    </div>
                  </div>
                </div>
                {
                  this.state.lockType === 2
                    ?
                    <div className="scrolling-carousel pd-l-15">
                      <Tab.Container id="left-tabs-example" defaultActiveKey="alerts" onSelect={(e) => this.getActiveClass(e)} eventKey={this.state.activeClass}>
                        <ScrollingCarousel rightArrow={true} leftArrow={true} className="swipesection scroll_nav_mob">
                          <Nav.Item>
                            <Nav.Link eventKey="latch-access" id="latch-access" className={this.state.activeClass === "latch-access" ? "active" : ""}>All Access</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="latch-activity" id="latch-activity" className={this.state.activeClass === "latch-activity" ? "active" : ""}>
                              All Activity
                            </Nav.Link>
                          </Nav.Item>
                        </ScrollingCarousel>
                        <Tab.Content className="pd-l-15 pd-r-15">
                          <Tab.Pane eventKey="latch-access">
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                              <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
                                <p>{this.props.test}</p>
                                <div className="pd-lg-l-0 pd-xs-l-15">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                                    config={this.state.total_record > global.page_size ? this.state.config : this.state.config}
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    loading={this.state.loading}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="latch-activity">
                            <ActivityHistory propertySlug={this.state.property_slug} />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                    :
                    this.state.lockType === 1
                      ?
                      <div className="col-md-12 mg-t-0 mg-b-10 pd-t-15 pd-b-10 pd-lg-l-45 pd-xs-l-15 pd-r-0">
                        <p className="pets_no_text text-left font-italic">This property is using Key Cafe.</p>
                      </div>
                      : ""
                }
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.isSearch} className="modal-large searchModalbox" centered onKeyPress={(e) => { if (e.key === "Enter") { this.filterData(); } }} >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>{global.closee}</span>
            <h3 className="text-center">Search in Latch Access</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <InputGroup className="mt-3">
                <label>Unit</label>
                <div className="col-md-12 p-0">
                  <Select
                    styles={customStyles}
                    isClearable={true}
                    className="multiselect"
                    classNamePrefix={"my-custom-react-select"}
                    menuPlacement="auto"
                    value={this.state.selectedUnitId}
                    options={this.state.unitOptions}
                    onChange={(value) => this.handleChangeUnitList(value)}
                    defaultValue={this.state.selectedUnitId}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 2,
                      colors: {
                        ...theme.colors,
                        primary: "#fff",
                        primary75: "#000",
                        primary50: "#000",
                        primary25: "#000",
                      },
                    })}
                  />
                </div>
              </InputGroup>
              <InputGroup className="mt-3">
                <label>Latch Doors</label>
                <div className="col-md-12 p-0">
                  <Select
                    styles={customStyles}
                    isClearable={true}
                    className="multiselect"
                    classNamePrefix={"my-custom-react-select"}
                    menuPlacement="auto"
                    value={this.state.selectedDoorId}
                    options={this.state.doorOptions}
                    onChange={(value) => this.handleChangeDoorList(value)}
                    defaultValue={this.state.selectedDoorId}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 2,
                      colors: {
                        ...theme.colors,
                        primary: "#fff",
                        primary75: "#000",
                        primary50: "#000",
                        primary25: "#000",
                      },
                    })}
                  />
                </div>
              </InputGroup>
              <InputGroup className="mt-3">
                <label>Email</label>
                <div className="col-md-12 p-0">
                  <Form.Control
                    type="email"
                    onChange={this.changeEmailHandler}
                    name="email"
                    value={this.state.searchEmail || ""}
                    maxLength="50"
                  />
                </div>
              </InputGroup>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button type="button" className="m-0 btn btn-outline-light btn-block" onClick={() => this.searchToggle()} >
                Cancel
              </button>
              <button type="button" className="m-0 btn btn btn-success btn-block" onClick={this.filterData} disabled={this.state.isSubmit ? true : false} >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default LatchActivity;

///////////////////////////////////////////////////////////
//  Program: lista.jsx                                  //
//  Application: lista                                  //
//  Option: add all lista                               //
//  Developer:  Raj Kumar                                      //
//  Date: 2022-12-05                                     //
///////////////////////////////////////////////////////////

import CrmSettingSidebar from "../common/CrmSettingSidebar";
import ContactSidebar from "../common/ContactSidebar";
import Alerts from "../common/Alerts";
import React, { Component } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import CrmListService from "../../services/CrmListService";
import modaldeleteicon from "./../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import ReactDatatable from "@ashvin27/react-datatable";
import deleteicon from "../../assets/images/delete.svg";
import { FileUploader } from "react-drag-drop-files";
import { read, utils } from "xlsx";
import * as Vars from "./../../Vars";
import BrandSidebar from "../common/BrandSidebar";
import ClientSidebar from "../common/ClientSidebar";
const DatabaseColMapping = Vars.default.DatabaseColMapping.filter((item) => {
  return item.module_name === "csb_list";
});
class CrmCsvListForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const pathStr = props.match.path;
    const pathArray = pathStr.split(":");
    this.state = {
      slug: props.match.params.crmSlug,
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : "",
      brand_slug:
        pathArray[0] === "/brand/view/" ? this.props.match.params.slug : "",
      client_slug:
        pathArray[0] === "/client/view/" ? this.props.match.params.slug : "",
      confirm_back: 0,
      input: {},
      errors: {},
      usertotype: "",
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      deleteTowerModal: false,
      status_id: true,
      list_name: "",
      listNameError: null,
      moduleList: global.modulelist,
      file_rows: [],
      file_columns: [],
      key_list: [],
      import_type: 1,
      file_name: "",
      file_url: "",
      total_record: 0,
      records: [],
      memberList: [],
      memberListTotal: 0,
      checkedValueList: [],
      ConfirmationModal: false,
      database_cols_mapping: DatabaseColMapping,
      moduleTypeList: global.moduleReferlist.filter((item) => {
        return item.slug;
      }),
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      columns: [
        {
          key: "first_name",
          text: "First Name",
          className: "name hand-cursor",
          cell: (record) => {
            return record.first_name ? record.first_name : "";
          },
        },

        {
          key: "last_name",
          text: "Last Name",
          className: "name hand-cursor",
          cell: (record) => {
            return record.last_name ? record.last_name : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor text-left",
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "action",
          text: "Action",
          className: "contact_phone hand-cursor text-center",
          cell: (record) => {
            return (
              <div className="btn-icon-list d-block text-center">
                <img
                  src={deleteicon}
                  alt="Delete"
                  onClick={() => this.openDeleteMemberModal(record.member_slug)}
                  className="hand-cursor"
                />
              </div>
            );
          },
        },
      ],
    };
    this.saveOrUpdateList = this.saveOrUpdateList.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.confirmImport = this.confirmImport.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
    this.openDeleteMemberModal = this.openDeleteMemberModal.bind(this);
    this.closeDeleteMemberModal = this.closeDeleteMemberModal.bind(this);
    this.getDataFromSlug = this.getDataFromSlug.bind(this);
    this.handleCheckCK = this.handleCheckCK.bind(this);
  }

  componentDidMount() {
    this.getDataFromSlug();
  }
  validate() {
    let listNameError = "";

    if (!this.state.list_name) {
      listNameError = "This field is required.";
    }
    if (listNameError) {
      this.setState({ listNameError });
      return false;
    }
    return true;
  }
  saveOrUpdateList = async (e) => {
    e.preventDefault();
    this.setState({
      alertModalMessage: "",
    });
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true });
      const fileData = new FormData();
      fileData.append(
        "mapped_columns",
        JSON.stringify(this.state.database_cols_mapping)
      );
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append(
        "list_name",
        this.state.list_name ? this.state.list_name : ""
      );
      fileData.append(
        "description",
        this.state.description ? this.state.description : ""
      );
      fileData.append("list_type", 3);
      fileData.append(
        "status_id",
        this.state.status_id === true
          ? 1
          : this.state.status_id === false
          ? 2
          : ""
      );
      fileData.append("selectedKey", this.state.checkedValueList);
      fileData.append(
        "propertySlug",
        this.state.property_slug ? this.state.property_slug : ""
      );
      fileData.append(
        "brandSlug",
        this.state.brand_slug ? this.state.brand_slug : ""
      );
      fileData.append(
        "clientSlug",
        this.state.client_slug ? this.state.client_slug : ""
      );
      fileData.append(
        "crmType",
        this.state.property_slug
          ? 2
          : this.state.brand_slug
          ? 3
          : this.state.client_slug
          ? 4
          : 1
      );

      if (this.state.slug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmListService.updateCrmList(this.state.slug, fileData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" + this.state.property_slug + "/crm-list"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-list"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-list"
                  : "/crm-list"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmListService.createCrmlist(fileData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" + this.state.property_slug + "/crm-list"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-list"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-list"
                  : "/crm-list"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isSubmit: false });
      this.validator.showMessages();
    }
  };

  async getDataFromSlug(queryString = "") {
    if (this.state.slug !== undefined) {
      var memberList = [];
      var memberListTotal = 0;
      var resData = {};
      let res = await CrmListService.getCrmList(
        this.props.match.params.crmSlug,
        queryString ? "&" + queryString : ""
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.list_name = resData.list_name ? resData.list_name : "";
        resData.description = resData.description ? resData.description : "";
        memberList = res.data.manualLists.data ? res.data.manualLists.data : [];
        memberListTotal = res.data.manualLists ? res.data.manualLists.total : 0;
      }
      this.setState({
        records: memberList,
        memberListTotal: memberListTotal,
        memberList: memberList,
      });
      this.setState(resData);
    }
  }
  async confirmImport() {
    if (this.validator.allValid()) {
      this.setState({
        showSpinner: true,
        isSubmit: true,
        ConfirmationModal: false,
      });
      const fileData = new FormData();
      fileData.append(
        "mapped_columns",
        JSON.stringify(this.state.database_cols_mapping)
      );
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append(
        "list_name",
        this.state.list_name ? this.state.list_name : ""
      );
      fileData.append(
        "description",
        this.state.description ? this.state.description : ""
      );
      fileData.append("list_type", 3);
      fileData.append(
        "status_id",
        this.state.status_id ? this.state.status_id : 1
      );
      fileData.append(
        "brandSlug",
        this.state.brand_slug ? this.state.brand_slug : ""
      );
      fileData.append(
        "clientSlug",
        this.state.client_slug ? this.state.client_slug : ""
      );
      fileData.append(
        "crmType",
        this.state.property_slug
          ? 2
          : this.state.brand_slug
          ? 3
          : this.state.client_slug
          ? 4
          : 1
      );
      if (this.state.slug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmListService.updateCrmList(this.state.slug, fileData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" + this.state.property_slug + "/crm-list"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-list"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-list"
                  : "/crm-list"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await CrmListService.createCrmlist(fileData);
        if (global.successStatus.includes(res.status)) {
          this.setState({ importModal: false });
          this.setState({ importResponseModel: true });
          this.setState({ successResponse: true });
          this.setState({ successMessage: res.message });
          this.setState({
            file_rows: [],
            file_columns: [],
          });
          if (res.data) {
            const totalRecord = res.data.total_record;
            const successRecord = res.data.success_record;
            const skipRecord = res.data.skip_record;
            const recordError = res.data.record_error_array;
            const FailedRecord = res.data.failed_record;
            this.setState({
              totalRecord: totalRecord,
            });
            this.setState({
              successRecord: successRecord,
            });
            this.setState({
              skipRecord: skipRecord,
            });
            this.setState({
              recordError: recordError,
            });
            this.setState({
              FailedRecord: FailedRecord,
            });
          }
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" + this.state.property_slug + "/crm-list"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-list"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-list"
                  : "/crm-list"
              ),
            global.redirect_time
          );
        } else {
          this.setState({ database_cols_mapping: DatabaseColMapping });
          this.setState({ importModal: false });
          this.setState({ importResponseModel: true });
          this.setState({ errorResponse: true });
          this.setState({ errorMessage: res.data.message });
          if (res.data) {
            const totalRecord = res.data.total_record;
            const successRecord = res.data.success_record;
            const skipRecord = res.data.skip_record;
            const recordError = res.data.record_error_array;
            const FailedRecord = res.data.failed_record;
            if (successRecord !== undefined) {
              this.setState({
                totalRecord: totalRecord,
              });
              this.setState({
                successRecord: successRecord,
              });
              this.setState({
                skipRecord: skipRecord,
              });
              this.setState({
                recordError: recordError,
              });
              this.setState({
                FailedRecord: FailedRecord,
              });
            }
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false, show: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ ConfirmationModal: false });
      this.validator.showMessages();
    }
  }

  async deleteMember(slug) {
    this.setState({ showSpinner: true });
    let res = await CrmListService.deleteCrmMember(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteMemberModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getDataFromSlug();
    } else {
      this.closeDeleteMemberModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteMemberModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  openConfirmationModal() {
    this.setState({ ConfirmationModal: true });
  }
  openDeleteMemberModal(slug) {
    this.setState({ deleteMemberModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteMemberModal() {
    this.setState({ deleteMemberModal: false });
    this.setState({ slug: "" });
  }
  closeConfirmationModal() {
    this.setState({ ConfirmationModal: false });
  }
  handleChange = (file) => {
    this.setState({ database_cols_mapping: DatabaseColMapping });
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, { raw: true });
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 0,
            defval: "",
          });
          if (rows.length > 0) {
            let columns = Object.keys(rows[0]);
            let available_columns = columns.map((col) => {
              let val = col
                .replace("-", "_")
                .replace(/[^\w ]/g, "")
                .replace(/^\s+|\s+$/gm, "")
                .split(" ")
                .join("_")
                .replace("-", "_")
                .toLowerCase();
              return {
                label: col,
                value: val,
              };
            });
            let checkedValueList = [];
            rows.forEach((item, index) => {
              checkedValueList.push(index + 1);
            });
            this.setState({
              file_rows: rows,
              file_columns: columns,
              available_file_columns: available_columns,
              checkedValueList: checkedValueList,
              key_list: checkedValueList,
            });
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
    this.setState({ file_name: file.name });
    this.setState({ file_url: file });
  };
  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  closeConfirmImportModal() {
    this.setState({
      importModal: false,
      file_columns: [],
      file_rows: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
    });
    this.validator.hideMessages();
  }
  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
  }
  handleCheckCK(event, indexVal) {
    if (event.target.checked) {
      var Array = this.state.checkedValueList;
      let checkedValue = Number(event.target.value);
      Array.push(checkedValue);
      this.setState({
        checkedValueList: Array,
      });
    } else {
      var array = this.state.checkedValueList;
      var index = array.indexOf(indexVal);
      array.splice(index, 1);
      this.setState({
        checkedValueList: array,
      });
    }
  }

  render() {
    return (
      <main>
        <Container className="innter-container over-fl  container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    {this.state.property_slug ? (
                      <ContactSidebar
                        property_slug={this.state.property_slug}
                      />
                    ) : this.state.brand_slug ? (
                      <BrandSidebar brand_slug={this.state.brand_slug} />
                    ) : this.state.client_slug ? (
                      <ClientSidebar clientSlug={this.state.client_slug} />
                    ) : (
                      <CrmSettingSidebar />
                    )}
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel drop_user">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Lists</h3>
                          <div className="d-flex justify-content-end">
                            <Link
                              to={
                                this.state.property_slug
                                  ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/crm-list"
                                  : this.state.brand_slug
                                  ? "/brand/view/" +
                                    this.state.brand_slug +
                                    "/crm-list"
                                  : this.state.client_slug
                                  ? "/client/view/" +
                                    this.state.client_slug +
                                    "/crm-list"
                                  : "/crm-list"
                              }
                            >
                              <button className="btn-success-outline mg-r-20">
                                Cancel
                              </button>
                            </Link>
                            <button
                              type="button"
                              className="btn-success-outline-small ml-3 button-width"
                              onClick={this.saveOrUpdateList}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug
                                ? "Save"
                                : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pd-lg-l-0 pd-lg-t-0 ">
                    <div className="table-responsive roles   ">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0 mt-3">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      <div className="mg-t-18 brandList table_issue_roles">
                        <div className=" ">
                          <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                              <label className="form-label text-left">
                                List{" "}
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="list_name"
                                value={this.state.list_name}
                                maxLength="50"
                                autoComplete="off"
                              />
                              {this.state.errors.list_name ? (
                                <div className="text-danger">
                                  {this.state.errors.list_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "list",
                                  this.state.list_name,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                              <label className="form-label text-left mg-t-15">
                                List Description{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="description"
                                value={this.state.description}
                                className="form-control max_width_100"
                                id="description"
                                as="textarea"
                                rows="4"
                              />
                            </div>
                            <div className="col-md-4 download-sample list_upload_box elx_pdf_epo pt-0 importModalbox mg-t-20 ">
                              <div className="col-lg-12 col-md-12 col-sm-12 ">
                                <div className="">
                                  <form name="myFile">
                                    <FileUploader
                                      onChange={this.changeHandler}
                                      handleChange={this.handleChange}
                                      name="file_name"
                                      types={["csv", "xlsx", "xls", "ods"]}
                                      label="Drop File Here Max Size 30 MB"
                                    />
                                  </form>
                                </div>
                                <Link
                                  to="/import_sample/recipient-list.csv"
                                  target="_blank"
                                  download="recipient-list.csv"
                                  className="dwon_imp_file"
                                >
                                  Download sample list for Import
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-8 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                            <div className="col-md-1 pl-0">
                              <label className="form-label text-left">
                                Status
                              </label>
                            </div>

                            <div className="col-8 col-md-7 col-lg-7 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                              <b
                                className={
                                  this.state.status_id ? "active" : "inactive"
                                }
                              ></b>
                              <div
                                className={
                                  this.state.status_id
                                    ? "az-toggle on"
                                    : "az-toggle"
                                }
                                onClick={() => this.changeStatus()}
                              >
                                <span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.file_columns.length ? (
                          <>
                            <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                              <div className="col-sm-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                <h3 className="mg-l-5  background-head d-flex  align-items-center justify-content-start">
                                  {" "}
                                  Recipients List{" "}
                                </h3>
                              </div>
                              <div className="col-sm-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 csv_tbl_formate">
                                <div className="table-responsive">
                                  <table className="table file_preview_tbl csv_preview_tbl">
                                    <thead>
                                      <tr>
                                        <th scope="col">Select</th>
                                        {this.state.file_columns.length
                                          ? this.state.file_columns.map(
                                              (cols, index) => (
                                                <th scope="col" key={index}>
                                                  {cols}
                                                </th>
                                              )
                                            )
                                          : ""}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.file_rows.length
                                        ? this.state.file_rows.map(
                                            (file_row, index) => {
                                              let colVals =
                                                Object.values(file_row);
                                              return (
                                                <tr key={index}>
                                                  <td className="addCSVListChecked w-20">
                                                    <div className="formcheck-section addcheckproperty mg-b-0">
                                                      <Form.Check
                                                        type={"checkbox"}
                                                        id={`allow_direct_booking ${index}`}
                                                        label={""}
                                                        value={index + 1}
                                                        className={"ckbox"}
                                                        onChange={(e) =>
                                                          this.handleCheckCK(
                                                            e,
                                                            index + 1
                                                          )
                                                        }
                                                        checked={this.state.checkedValueList.find(
                                                          (item) =>
                                                            item === index + 1
                                                        )}
                                                      />
                                                    </div>
                                                  </td>
                                                  {colVals.map(
                                                    (colVal, idx) => {
                                                      return (
                                                        <td key={idx}>
                                                          {colVal}
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                                </tr>
                                              );
                                            }
                                          )
                                        : ""}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : this.state.memberListTotal > 0 ? (
                          <>
                            <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                              <div className="col-sm-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                <h3 className="mg-l-5  background-head d-flex  align-items-center justify-content-start">
                                  Recipients List
                                </h3>
                              </div>
                              <div className="col-sm-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                                <div className="mg-t-18 brandList">
                                  <div className="pd-lg-l-0 pd-xs-l-15">
                                    <ReactDatatable
                                      className="table no-wrap mg-b-30 pd-b-2 brandtable text-align-left"
                                      config={
                                        this.state.memberListTotal >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      total_record={this.state.memberListTotal}
                                      onChange={this.tableChangeHandler}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={this.state.ConfirmationModal}
          onHide={() => {
            if (
              window.confirm(
                "Are you sure to exit the pop up without run Confirmation?"
              )
            )
              this.closeConfirmationModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeConfirmationModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <h3>Ready for import?</h3>
              <p>
                Do you really want to run this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeConfirmationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  disabled={this.state.isSubmit ? true : false}
                  onClick={this.confirmImport}
                >
                  {this.state.isSubmit ? global.loader : "Yes"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.deleteMemberModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closeDeleteMemberModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeDeleteMemberModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteMemberModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteMember(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default CrmCsvListForm;

//     							                          //
//  Program: CalendarSidebar.jsx                          //
//  Application: Property                                 //
//  Option: common component Calendar Sidebar             //
//  Developer: NP 		                                  //
//  Date: 2022-04-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from 'react-bootstrap';
class CalendarSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Reservations
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "reservation-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reservation-calendar")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/reservation-calendar"
                          }
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "reservations-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reservations")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/reservations"
                          }
                        >
                          Reservations
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("inquiry-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("inquiry")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/inquiry"
                          }
                        >
                          Inquiry
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "check-ins-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checkin-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/checkin-calender"
                          }
                        >
                          Check Ins
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "check-outs-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checkout-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/checkout-calender"
                          }
                        >
                          Check Outs
                        </Link>
                      </li>
                    )}
                    {/* <li>
                      <Link
                        className={
                          this.isPathActive("guest")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/guest"}
                      >
                        Guests
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                          className={
                            this.isPathActive("requests")
                            ? "nav-link active"
                            : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/requests"}
                      >
                          Inbox
                      </Link>
                      </li> */}
                    {/* <li>
                      <Link
                          className={
                            this.isPathActive("tour-calender")
                            ? "nav-link active"
                            : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/tour-calender"}
                      >
                          Tour Calendar
                      </Link>
                      </li>
                      <li>
                      <Link
                          className={
                            this.isPathActive("guest-calender")
                            ? "nav-link active"
                            : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/guest-calender"}
                      >
                          Guest Calendar
                      </Link>
                      </li>
                      <li>
                      <Link
                          className={
                            this.isPathActive("housekeeping-calender")
                            ? "nav-link active"
                            : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/housekeeping-calender"}
                      >
                          Housekeeping Calendar
                      </Link>
                      </li>
                      <li>
                      <Link
                          className={
                            this.isPathActive("event-calender")
                            ? "nav-link active"
                            : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/event-calender"}
                      >
                          Events Calendar
                      </Link>
                      </li> */}
                  </ul>
                </li>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission("guests-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/guest"
                          }
                        >
                          Guests
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "reservations-guest-reviews"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reviews")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/reviews"
                          }
                        >
                          Guest Reviews
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              {/* <a href="/" className="back"> <i className="fas fa-chevron-left"></i> Reservations</a> */}
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Reservations
              </span>
              <ul>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "reservation-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reservation-calendar")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/reservation-calendar"
                          }
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "reservations-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reservations")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/reservations"
                          }
                        >
                          Reservations
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("inquiry-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("inquiry")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/inquiry"
                          }
                        >
                          Inquiry
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "check-ins-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checkin-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/checkin-calender"
                          }
                        >
                          Check Ins
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "check-outs-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checkout-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/checkout-calender"
                          }
                        >
                          Check Outs
                        </Link>
                      </li>
                    )}
                    {/* <li>
                          <Link
                            className={
                              this.isPathActive("guest")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/guest"}
                          >
                            Guests
                          </Link>
                        </li> */}
                    {/* <li>
                          <Link
                              className={
                                this.isPathActive("requests")
                                ? "nav-link active"
                                : "nav-link"
                              }
                              data-toggle="tab"
                              to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/requests"}
                          >
                              Inbox
                          </Link>
                          </li> */}
                    {/* <li>
                          <Link
                              className={
                                this.isPathActive("tour-calender")
                                ? "nav-link active"
                                : "nav-link"
                              }
                              data-toggle="tab"
                              to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/tour-calender"}
                          >
                              Tour Calendar
                          </Link>
                          </li>
                          <li>
                          <Link
                              className={
                                this.isPathActive("guest-calender")
                                ? "nav-link active"
                                : "nav-link"
                              }
                              data-toggle="tab"
                              to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/guest-calender"}
                          >
                              Guest Calendar
                          </Link>
                          </li>
                          <li>
                          <Link
                              className={
                                this.isPathActive("housekeeping-calender")
                                ? "nav-link active"
                                : "nav-link"
                              }
                              data-toggle="tab"
                              to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/housekeeping-calender"}
                          >
                              Housekeeping Calendar
                          </Link>
                          </li>
                          <li>
                          <Link
                              className={
                                this.isPathActive("event-calender")
                                ? "nav-link active"
                                : "nav-link"
                              }
                              data-toggle="tab"
                              to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/event-calender"}
                          >
                              Events Calendar
                          </Link>
                          </li> */}
                  </ul>
                </li>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission("guests-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/guest"
                          }
                        >
                          Guests
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "reservations-guest-reviews"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reviews")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/reviews"
                          }
                        >
                          Guest Reviews
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CalendarSidebar;

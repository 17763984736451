/////////////////////////////////////////////////////////////
//  Program: messages.jsx                                  //
//  Application: messages                                  //
//  Option: Home Sharing Listing                           //
//  Developer: PS, Kiran                                   //
//  Date: 2022-09-07                                       //
///////////////////////////////////////////////////////// ///
// import React, { Component, Suspense, lazy } from "react";
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Form } from "react-bootstrap";
import search from "./../../assets/images/Search.svg";
import MsgFilter from "./../../assets/images/Filternew.svg";
import guestimg from "./../../assets/images/profile-nogender.png";
import { Modal } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import closeIcon from "./../../assets/images/close-wel.svg";
import TextareaAutosize from "react-autosize-textarea";
import DropdownButton from "react-bootstrap/DropdownButton";
import gallery from "./../../assets/images/gallery.svg";
import quickreply from "./../../assets/images/quick-reply.svg";
import postarrow from "./../../assets/images/postarrow.svg";
import ohp from "./../../assets/images/logo.jpg";
import RUmessageService from "../../services/RUmessageService";
import NoData from "../common/NoData";
import Moment from "moment";
import moment from "moment-timezone";
import renderHTML from "react-render-html";
import pdfIcon from "./../../assets/images/pdf-icon.png";
import excelIcon from "./../../assets/images/xlsx.png";
import wordIcon from "./../../assets/images/word.png";
import placeholderImage from "./../../assets/images/gallery.png";
import SimpleReactValidator from "simple-react-validator";
import SelectRecipient from "./SelectRecipient";
// import MessageRightSection from "./MessageRightSection";
import ReservationService from "../../services/ReservationService";
import ohp1 from "./../../assets/images/oh_logo_lg_new.png";
import airbnb from "./../../assets/images/airbnb.svg";
import expedia from "./../../assets/images/expedia_logo.svg";
import vrbo from "./../../assets/images/vrbo_new.PNG";
import bookingcom from "./../../assets/images/Bookingcom.png";
import KeycafeService from "../../services/KeycafeService";
import ChatIcon from "./../../assets/images/chat-icon.svg";
// import PhoneNumberFormat from "../common/PhoneNumberFormat";
import { Link } from "react-router-dom";
// import MessageMiddleSection from "./MessageMiddleSection";
import Gallery from "react-grid-gallery";
import Picker from "emoji-picker-react";
import mobmenu from "./../../assets/images/mobmenu.svg";
import Messageemp from "./../../assets/images/Message_info.png"
import MessageFilter from "./MessageFilter";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

class MessageIndex extends Component {
  constructor(props) {

    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      showaddContactModal: false,
      pathname: window.location.pathname,
      // property_slug: localStorage.getItem("message_page") == 1 ? "undefined" : (this.props.match.params.outsidePropertySlug ? this.props.match.params.outsidePropertySlug : (this.props.match != undefined && this.props.match.params.propertySlug ? this.props.match.params.propertySlug : sessionStorage.getItem("property_slug"))),
      property_slug: this.props.match != undefined && this.props.match.params.propertySlug ? this.props.match.params.propertySlug : "",
      roleGroup: global.currerntRoleGroup ? global.currerntRoleGroup : null,
      role_slug: global.currerntRoleSlug ? global.currerntRoleSlug : null,
      loginUser: global.userData.slug,
      queryString: null,
      thread_type: null,
      reservation_slug: null,
      user_slug: null,
      OutSideThreadSlug: this.props.match != undefined ? this.props.match.params.threadSlug : "",
      customer_support: null,
      has_concierge_view: null,
      is_support: 1,
      userHeader: null,
      userInfo: null,
      reservationDetail: undefined,
      HostPayout: undefined,
      guestPaid: undefined,
      input: {},
      errors: {},
      sendBtn: false,
      SOCKET_SERVER_URL: process.env.REACT_APP_WEBSOCKET_ENDPOINT
        ? process.env.REACT_APP_WEBSOCKET_ENDPOINT
        : "",
      has_concierge_permission: 0,
      assignPropertyString: [],
      pageLoad: 1,
      isUnreadCount: 0,
      imagePreviewForChat: process.env.REACT_APP_IMAGE_PREVIEW
        ? process.env.REACT_APP_IMAGE_PREVIEW
        : "",
      httpMatch: /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g,
      replyYou: "You: ",
      recipt_box: false,
      isNewModelOpen: 0,
      defaultPageLoad: 0,
      userSlug: "",
      showSpinner: false,
      isFilterModel: 0,
      //threadSlug:
      isHouseOpen: false,
      isArrivalOpen: false,
      apiRes: false,
      page: 2,
      per_page: 7,
      hasMore: true,
      isFilterApply: false,
      isSearch: false,
      defaultPageNumber: 0,
      text: '',
    };
    this.imagePreview = this.imagePreview.bind(this);
    this.imageClose = this.imageClose.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.searchforThreadChangeHandler = this.searchforThreadChangeHandler.bind(this);
    this.postMessage = this.postMessage.bind(this);
    this.messageListCreateAfterPost = this.messageListCreateAfterPost.bind(this);
    this.openModal = this.openModal.bind(this);
    this.isFilterModel = this.isFilterModel.bind(this);
    this.openHouse = this.openHouse.bind(this);
    this.openArrival = this.openArrival.bind(this);
    this.getThreadList = this.getThreadList.bind(this);
  }
  openRecipt() {
    this.setState({
      recipt_box: false
    })
  }
  closeRecipt() {
    this.setState({
      recipt_box: true
    })
  }
  componentDidMount() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    this.setState({ today: today });
    today = moment(moment.utc(today).toDate()).local().format("MM/DD/YYYY");
    if (this.state.OutSideThreadSlug != undefined && this.state.OutSideThreadSlug != "") {
      this.getThreadBySlug(this.state.OutSideThreadSlug);
    } else {
      this.getThreadList();
    }
    var page = 1;
    if (window.location.pathname == "/messages") {
      page = 1;
    } else {
      page = 2;
    }
    this.setState({
      page: page,
    })
  }
  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    if (typeof (val) == 'string') {
      const cleanedNumber = val != undefined ? val.replace(/[\s-]/g, "") : "";
      if (cleanedNumber.length > 10) {
        lastTenDigits = cleanedNumber.slice(-10);
      } else {
        lastTenDigits = val;
      }
      var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
      var finalnumber = "";
      if (number.length < 4) {
        finalnumber = number;
      } else if (number.length < 7) {
        finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
      } else if (number.length < 11) {
        finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
      } else {
        finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      }
      this.setState({ phone_number: finalnumber });
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    var socketData = nextProps.socketData;
    if (socketData !== undefined && nextProps.msgCountGlobal === 1) {

      var socketDataObj = [socketData];
      var existArry = this.state.messageList;
      var threadList = this.state.threadList;
      var socketDataArr = [];
      var lastIndex = "";
      var current_thread_slug = "";
      var current_thread_Index = 0;
      if (this.state.page != "" && this.state.page === 2) { //propert wise    
        current_thread_slug = this.state.OutSideThreadSlug != undefined ? this.state.OutSideThreadSlug : localStorage.getItem("current_thread");
        current_thread_Index = 0;
      } else {  //Global       
        if (threadList.length > 0) {
          current_thread_slug = localStorage.getItem("current_thread");
          current_thread_Index = localStorage.getItem("current_index");
        }
      }
      //START :: CASE 1 => inner property page 2
      if (this.state.page == 2 && this.state.property_slug == socketData.property_slug){
        if (current_thread_slug != undefined && threadList != undefined && threadList.length > 0 && (this.state.page === 1 || this.state.page === 2)) {
          if (current_thread_slug === socketData.thread_slug) {
            /* Start:: Prepair thread preview ****************/
            if (threadList != undefined && threadList.length > 0) {
              var currentThreadArray = threadList[current_thread_Index];
              if (currentThreadArray != undefined) {
                // if (this.state.loginUser != socketData.auth_from_user_slug) {
                currentThreadArray.preview = "";
                if (socketData.message_type === 3) {
                  currentThreadArray.message_type = 3;
                  let msg = socketData && socketData.preview ? socketData.preview : "";
                  currentThreadArray.preview =
                    msg.length > 20
                      ? `${msg.substring(0, 20)}...`
                      : msg.substring(0, 20);
                } else {
                  currentThreadArray.message_type = 1;
                }
                currentThreadArray.last_message_date = socketData.create_date;
                //}
              }
            }
            /* End:: Prepair thread preview ********************/

            /* start:: Perpair message list */
            var socketArrDate = Moment(socketData.create_date).format("YYYY-MM-DD");
            var dateStatus = false;
            if (existArry != undefined && existArry.length > 0) {
              for (var i = 0; i < existArry.length; i++) {
                var ExistArrdate = existArry[i].date;
                if (ExistArrdate === socketArrDate) {
                  dateStatus = true;
                  break;
                }
              }
            }
            if (dateStatus === true) {
              if (existArry.length > 0) {
                lastIndex = existArry.length - 1;
                if (existArry[lastIndex].message.length > 0) {
                  /* check message exist on message list or not   */
                  if (global.userData.slug !== socketData.from_user_slug) {
                    socketDataArr = [
                      ...existArry[lastIndex].message,
                      ...socketDataObj,
                    ];
                    postArr = [
                      {
                        date: Moment(socketData.create_date).format("YYYY-MM-DD"),
                        message: socketDataArr,
                      },
                    ];
                    var newArr = existArry.slice(0, -1);
                    var aaaa = [...newArr, ...postArr];

                    this.setState({ messageList: aaaa });
                  }
                }
              }
            } else {
              var postArr = [];
              postArr = [
                {
                  date: socketData.create_date,
                  message: socketDataObj,
                },
              ];
              if (this.state.messageList) {
                socketDataArr = [...this.state.messageList, ...postArr];
              } else {
                socketDataArr = [...postArr];
              }
              this.setState({
                messageList: socketDataArr,
                dateAvailable: true,
              });
            }
            /* end:: Perpair message list */
          } else {
            if (threadList != undefined && threadList.length > 0) {
              var threadIndex = threadList.findIndex(
                (obj) => obj.slug === socketData.thread_slug
              );
              var index = threadIndex;
              var removedObj = threadList[index];
              if (global.userData.slug !== socketData.auth_from_user_slug && removedObj != undefined && threadIndex >= 0) {
                // removedObj.unread_count = '1';
                removedObj.last_message_date = socketData.create_date;
                removedObj.preview = "";
                if (socketData.message_type === 3) {
                  removedObj.message_type = 3;
                  var msg = socketData.preview ? socketData.preview : "";
                  removedObj.preview =
                    msg.length > 20
                      ? `${msg.substring(0, 20)}...`
                      : msg.substring(0, 20);
                } else {
                  removedObj.message_type = 1;
                }
              }

              if (threadIndex == -1) {
                this.setState({ pageLoad: 0, thread_slug: current_thread_slug });
                var socketThreadNeedPrepair = 1;
                if (this.state.isFilterApply === true || this.state.isSearch === true) {
                  var roleGroupString = this.state.queryString != undefined ? this.state.queryString.split("&") : "";
                  if (roleGroupString != undefined) {
                    var roleGroupVar = roleGroupString[4];
                    var roleGroup = roleGroupVar != undefined ? roleGroupVar.split("=") : "";
                    var roleGroupVal = roleGroup != undefined && roleGroup[1] != undefined ? roleGroup[1] : "";
                    if (socketData.role_group_name == "Guest") {
                      var socketRoleGroup = 5;
                    }
                    if (socketData.role_group_name == "Staff") {
                      socketRoleGroup = 3;
                    }
                    if (socketData.role_group_name == "Resident") {
                      socketRoleGroup = 4;
                    }
                    if (roleGroupVal != undefined && socketRoleGroup == roleGroupVal) {
                      socketThreadNeedPrepair = 1;
                    } else {
                      socketThreadNeedPrepair = 1;
                    }
                  }
                }
                /* Create thread object */
                if (socketThreadNeedPrepair == 1) {
                  msg = socketData.preview ? socketData.preview : "";
                  var threadObject =
                  {
                    "slug": socketData.thread_slug ? socketData.thread_slug : "",
                    "thread_type": socketData.thread_type ? socketData.thread_type : "",
                    "preview": msg.length > 20 ? `${msg.substring(0, 20)}...` : msg.substring(0, 20),
                    "set_channel_name": socketData.set_channel_name ? socketData.set_channel_name : "",
                    "checkin_date": socketData.checkin_date ? socketData.checkin_date : null,
                    "checkout_date": socketData.checkout_date ? socketData.checkout_date : null,
                    "role_group_name": socketData.role_group_name ? socketData.role_group_name : "",
                    "unit_name": socketData.unit_name ? socketData.unit_name : "",
                    "message_type": socketData.message_type ? socketData.message_type : "",
                    "unread_count": 1,
                    "last_message_date": socketData.last_message_date ? socketData.last_message_date : "",
                    "thread_owner": socketData.thread_owner ? socketData.thread_owner : "",
                    "property": {
                      "aka_name": socketData.property && socketData.property.aka_name ? socketData.property.aka_name : "",
                      "property_logo_url": socketData.property && socketData.property.property_logo_thumb_url ? socketData.property.property_logo_thumb_url : "",
                    }
                  }
                  threadList.splice(threadIndex, 1);
                  threadList = [threadObject, ...threadList];
                  this.setState({ threadList: threadList });
                }
                
              }

            }
          }
        }
      }
       //START :: CASE 2 =>Global page 1 
      if (this.state.page == 1 ){
        if (current_thread_slug != undefined && threadList != undefined && threadList.length > 0 && (this.state.page === 1 || this.state.page === 2)) {
          if (current_thread_slug === socketData.thread_slug) {
            /* Start:: Prepair thread preview ****************/
            if (threadList != undefined && threadList.length > 0) {
               currentThreadArray = threadList[current_thread_Index];
              if (currentThreadArray != undefined) {
                // if (this.state.loginUser != socketData.auth_from_user_slug) {
                currentThreadArray.preview = "";
                if (socketData.message_type === 3) {
                  currentThreadArray.message_type = 3;
                  let msg = socketData && socketData.preview ? socketData.preview : "";
                  currentThreadArray.preview =
                    msg.length > 20
                      ? `${msg.substring(0, 20)}...`
                      : msg.substring(0, 20);
                } else {
                  currentThreadArray.message_type = 1;
                }
                currentThreadArray.last_message_date = socketData.create_date;
                //}
              }
            }
            /* End:: Prepair thread preview ********************/

            /* start:: Perpair message list */
             socketArrDate = Moment(socketData.create_date).format("YYYY-MM-DD");
             dateStatus = false;
            if (existArry != undefined && existArry.length > 0) {
              for (var j = 0; j < existArry.length; j++) {
                 ExistArrdate = existArry[j].date;
                if (ExistArrdate === socketArrDate) {
                  dateStatus = true;
                  break;
                }
              }
            }
            if (dateStatus === true) {
              if (existArry.length > 0) {
                lastIndex = existArry.length - 1;
                if (existArry[lastIndex].message.length > 0) {
                  /* check message exist on message list or not   */
                  if (global.userData.slug !== socketData.from_user_slug) {
                    socketDataArr = [
                      ...existArry[lastIndex].message,
                      ...socketDataObj,
                    ];
                    postArr = [
                      {
                        date: Moment(socketData.create_date).format("YYYY-MM-DD"),
                        message: socketDataArr,
                      },
                    ];
                     newArr = existArry.slice(0, -1);
                     aaaa = [...newArr, ...postArr];

                    this.setState({ messageList: aaaa });
                  }
                }
              }
            } else {
               postArr = [];
              postArr = [
                {
                  date: socketData.create_date,
                  message: socketDataObj,
                },
              ];
              if (this.state.messageList) {
                socketDataArr = [...this.state.messageList, ...postArr];
              } else {
                socketDataArr = [...postArr];
              }
              this.setState({
                messageList: socketDataArr,
                dateAvailable: true,
              });
            }
            /* end:: Perpair message list */
          } else {
            if (threadList != undefined && threadList.length > 0) {
               threadIndex = threadList.findIndex(
                (obj) => obj.slug === socketData.thread_slug
              );
               index = threadIndex;
               removedObj = threadList[index];
              if (global.userData.slug !== socketData.auth_from_user_slug && removedObj != undefined && threadIndex >= 0) {
                // removedObj.unread_count = '1';
                removedObj.last_message_date = socketData.create_date;
                removedObj.preview = "";
                if (socketData.message_type === 3) {
                  removedObj.message_type = 3;
                   msg = socketData.preview ? socketData.preview : "";
                  removedObj.preview =
                    msg.length > 20
                      ? `${msg.substring(0, 20)}...`
                      : msg.substring(0, 20);
                } else {
                  removedObj.message_type = 1;
                }
              }

              if (threadIndex == -1) {
                this.setState({ pageLoad: 0, thread_slug: current_thread_slug });
                 socketThreadNeedPrepair = 1;
                if (this.state.isFilterApply === true || this.state.isSearch === true) {
                   roleGroupString = this.state.queryString != undefined ? this.state.queryString.split("&") : "";
                  if (roleGroupString != undefined) {
                     roleGroupVar = roleGroupString[4];
                     roleGroup = roleGroupVar != undefined ? roleGroupVar.split("=") : "";
                     roleGroupVal = roleGroup != undefined && roleGroup[1] != undefined ? roleGroup[1] : "";
                    if (socketData.role_group_name == "Guest") {
                       socketRoleGroup = 5;
                    }
                    if (socketData.role_group_name == "Staff") {
                      socketRoleGroup = 3;
                    }
                    if (socketData.role_group_name == "Resident") {
                      socketRoleGroup = 4;
                    }
                    if (roleGroupVal != undefined && socketRoleGroup == roleGroupVal) {
                      socketThreadNeedPrepair = 1;
                    } else {
                      socketThreadNeedPrepair = 1;
                    }
                  }
                }
                /* Create thread object */
                if (socketThreadNeedPrepair == 1) {
                  msg = socketData.preview ? socketData.preview : "";
                   threadObject =
                  {
                    "slug": socketData.thread_slug ? socketData.thread_slug : "",
                    "thread_type": socketData.thread_type ? socketData.thread_type : "",
                    "preview": msg.length > 20 ? `${msg.substring(0, 20)}...` : msg.substring(0, 20),
                    "set_channel_name": socketData.set_channel_name ? socketData.set_channel_name : "",
                    "checkin_date": socketData.checkin_date ? socketData.checkin_date : null,
                    "checkout_date": socketData.checkout_date ? socketData.checkout_date : null,
                    "role_group_name": socketData.role_group_name ? socketData.role_group_name : "",
                    "unit_name": socketData.unit_name ? socketData.unit_name : "",
                    "message_type": socketData.message_type ? socketData.message_type : "",
                    "unread_count": 1,
                    "last_message_date": socketData.last_message_date ? socketData.last_message_date : "",
                    "thread_owner": socketData.thread_owner ? socketData.thread_owner : "",
                    "property": {
                      "aka_name": socketData.property && socketData.property.aka_name ? socketData.property.aka_name : "",
                      "property_logo_url": socketData.property && socketData.property.property_logo_thumb_url ? socketData.property.property_logo_thumb_url : "",
                    }
                  }
                  threadList.splice(threadIndex, 1);
                  threadList = [threadObject, ...threadList];
                  this.setState({ threadList: threadList });
                }
                
              }

            }
          }
        }
      }
    }

    /* chat box scroll */
    if (window.location.pathname == "/messages" || window.location.pathname == `/properties/view/${this.state.property_slug}/messages/${current_thread_slug}` || window.location.pathname == `/properties/view/${this.state.property_slug}/messages`) {
      setTimeout(() => {
        this.scrollTop();
      }, 100);
    }
  }
  async getThreadBySlug(OutSideThreadSlug){
   // let totalCount = 0;
    let threadList = [];
    let thread_slug = "";
    let chatGptEnable = "";
    this.setState({ showSpinner: true});
    let res = await RUmessageService.getThreadBySlug(OutSideThreadSlug);
    if (global.successStatus.includes(res.status)) {
      threadList = res.data ? res.data : [];
      //totalCount = res.data ? res.data.total: 0;
      thread_slug = res.data && threadList[0] ? threadList[0].slug : "";
      chatGptEnable = res.data && threadList[0] ? threadList[0].chat_gpt_enable : "";
        if (thread_slug == undefined) {
          thread_slug = localStorage.getItem("current_thread");
        }
        this.setState({
          threadList: threadList,
         // totalCount: totalCount,
          thread_slug: OutSideThreadSlug ? OutSideThreadSlug : thread_slug,
          newthreadList: undefined,
          showSpinner: false,
          chatGptEnable: chatGptEnable,
        });
        if (this.state.thread_slug != "" && this.state.thread_slug != undefined) {
          if (window.document.getElementsByClassName("thread-" + this.state.thread_slug)[0] != undefined) {
              var reserThredScroll = window.document.getElementsByClassName("thread-" + this.state.thread_slug)[0];
            // reserThredScroll.scrollIntoView(false);
            reserThredScroll.click();
          }
        }
        //this.threadScrollTop();
    }
  }
  /* In the reservation serction create/open resident thread */
  async postThreadForResident() {
    let inputData = {
      property_slug: this.state.reservationDetail
        ? this.state.reservationDetail.resident_property
        : "",
      role_slug: this.state.role_slug,
      user_slug: this.state.reservationDetail
        ? this.state.reservationDetail.resident_slug
        : "",
      thread_type: global.threadType.Resident,
    };
    let res = await RUmessageService.createThread(inputData);
    if (global.successStatus.includes(res.status)) {
      var thread_slug = res.data ? res.data.slug : [];
      var isNewMessage = res.data ? res.data.is_new : "";

      if (isNewMessage === true) {
        this.getThreadList();
        var screenLoad = 1;
      } else {
        window.open("/properties/view/" +inputData.property_slug +"/messages/" + thread_slug, "_blank")
      }
    }
    this.setState({
      thread_slug: thread_slug,
      pageLoad: screenLoad,
    });
  }
  /* thread list */
  async getThreadList(queryString = "") {
    var isFilterApply =  queryString ? true : false; 
    if(this.state.isSearch === true){
      isFilterApply = false;
    }
    this.setState({ showSpinner: true, isFilterApply: isFilterApply , queryString:queryString});
    let threadList = [];
    let thread_slug = null;
    let totalCount = 0;
    let mergeData = [];
    if(this.state.defaultPageNumber === 0){
      var pageNo = this.state.threadList != undefined && this.state.threadList.length > 0 && this.state.threadList ? Math.ceil(this.state.threadList.length/this.state.per_page) + 1 : 1;
    }else{
       pageNo =  1;
       this.setState({ threadList: []});
    }
    let res = await RUmessageService.getThreadList(queryString? queryString: "property_slug=" +this.state.property_slug +"&role_slug=" + this.state.role_slug + "&page=" + pageNo +"&per_page=" + this.state.per_page);
    if (global.successStatus.includes(res.status)) {
      threadList = res.data ? res.data.data : [];
      totalCount = res.data ? res.data.total: 0;
      if(this.state.isSearch === true){
         mergeData = threadList;
      }else if(isFilterApply === true){
         mergeData = threadList;
      }else{
         mergeData =  this.state.threadList != undefined && this.state.threadList.length > 0 && this.state.threadList ? [...this.state.threadList, ...threadList] : threadList;
      }
       
      if (threadList != undefined && threadList.length == 0) {
        this.setState({
          userInfo: null,
          property_logo: "",
          reservationDetail: null,
          userHeader: null,
        });
      } else {
          if (this.state.pageLoad === 1) {
            if (mergeData != undefined && mergeData.length > 0) {
              if (mergeData[0].slug != undefined) {
                thread_slug = mergeData[0].slug;
              }
            }
          } else {
            thread_slug = res.data ? mergeData[0].slug : this.state.thread_slug;
            if (thread_slug == undefined) {
              thread_slug = localStorage.getItem("current_thread");
            }
          } 
      }
    }
    this.setState({
      threadList: mergeData,
      totalCount: totalCount,
      thread_slug: this.state.OutSideThreadSlug ? this.state.OutSideThreadSlug : thread_slug,
      newthreadList: undefined,
      showSpinner: false,
    });
    this.setState({ showSpinner: false });
    if (this.state.pageLoad === 1) {
      if (this.state.thread_slug != "" && this.state.thread_slug != undefined) {
        if (window.document.getElementsByClassName("thread-" + this.state.thread_slug)[0] != undefined) {
            var reserThredScroll = window.document.getElementsByClassName("thread-" + this.state.thread_slug)[0];
          // reserThredScroll.scrollIntoView(false);
          reserThredScroll.click();
        }
      }
    }
   //this.threadScrollTop();
  }
  /* Single thread click */
  async threadClick(rec, i) {
    /* Call unread badge count */
    this.uncountUpdate(rec.slug);
    /* Remove unread batch */
    var selectedThreadArr = this.state.threadList[i];
    if (selectedThreadArr !== undefined) {
      selectedThreadArr.unread_count = 0;
    }
    /* Find unread count in the existing thread list */
    if (this.state.threadList.length > 0) {
      const found = this.state.threadList.some((el) => el.unread_count === 1);
      if (found === false) {
        //this.props.updatePropsGlobalmsg(0);
      }
    }
    var reservation_slug = rec.reservation_slug ? rec.reservation_slug : null;
  
    // var reciver_name = rec.reciver_name ? rec.reciver_name : null;
    this.setState({ renderFirstTime: 0, isUnreadCount: 0, message: "" });
    if(reservation_slug != null ){  
      this.getReservationDetail(reservation_slug)
    }else{
      if (rec.thread_owner) {
        var value = rec.thread_owner ?? '';
        if (value) {
          var parts = value.split(" ");
          var firstName = parts['0'];
          var lastName = parts['1'];
        }
      }
      var userInfo = {
        first_name:firstName ?? "",
        last_name: lastName ?? "",
        email: rec.email ?? "",
        //phone_number: rec.phone_number ? this.phoneNumberAutoFormat(rec.phone_number) :  "",
        unit: rec.unit_name ?? "",
      };
      var userHeader = {
        userName: rec.thread_owner ?? "",
        userRole: rec.role_group_name ?? "",
      };
        this.setState({ reservationDetail: null,guestPaid:null});
    }
    /* For Unit number show right side */
    if (rec.thread_type === 3) {
      var unit_name = rec.unit ? rec.unit.unit_name : "";
    } else {
      unit_name = rec.unit_name ? rec.unit_name : "";
    }

    this.setState({
      property_logo: rec.property != null && rec.property != undefined && rec.property.property_logo_url ? rec.property.property_logo_url
        : ohp,
      property_aka: rec.property ? rec.property.aka_name : "",
      thread_type: rec.thread_type,
      thread_slug: rec.slug,
      userHeader: userHeader,
      userInfo: userInfo,
      isUnreadCount: 0,
      unit_name: unit_name,
      listingName: rec.listing_name ?? "",
      chatGptEnable: rec.chat_gpt_enable ?? "",
      threadTypeName: rec.set_channel_name && rec.set_channel_name == "AirbnbGuestInquiry" ? "Inquiry" : "",
      property_slug_For_Instruction:
        rec.property && rec.property.slug ? rec.property.slug : "",
        
    });
    /* For deleteing unread batch when click on thread */
    let messageList = [];
    if (rec.slug != undefined) {
      var apiMessageList = await this.getMessageList(rec.slug);
      messageList = [...apiMessageList];
      this.setState({
        messageList: messageList,
      });
      localStorage.setItem("current_thread", rec.slug);
      localStorage.setItem("current_index", i);
      this.setState({
        thread_slug: rec.slug,
      });
     this.scrollTop();
    }
  }
  /* thread wise message list */
  async getMessageList(thread_slug) {
    var apiMessageList = [];
    let res = await RUmessageService.getMessageListByThread("thread_slug=" + thread_slug + "&role_slug=" + global.currerntRoleSlug);
    if (global.successStatus.includes(res.status)) {
      apiMessageList = res.data ? res.data : [];
    }
    return apiMessageList;
  }
  /* Reservation data */
  async getReservationDetail(reservationSlug) {
    var reservation_detail = [];
    /* For Key access */
    let ress = await ReservationService.getKeyCafeAccess(reservationSlug);
    if (global.successStatus.includes(ress.status)) {
      var booking_code = ress.data ? ress.data.booking_code : "";
    }
    /*For Arrival Instruction */
    let resss = await KeycafeService.getKeycafeInstruction(
      "property_slug=" + this.state.property_slug_For_Instruction
    );
    if (global.successStatus.includes(resss.status)) {
      var instruction =
        resss.data && resss.data.keycafe_description
          ? resss.data.keycafe_description
          : "";
    }

    /* For reservation detail */
    let res = await ReservationService.getReservation(reservationSlug);
    var reservation_detail_slug ="";
    if (global.successStatus.includes(res.status)) {
      reservation_detail = res.data ? res.data : [];
      reservation_detail_slug = res.data ? res.data.slug: [];

      sessionStorage.setItem(
        "property_slug",
        res.data.property ? res.data.property.slug : ""
      );
      sessionStorage.setItem(
        "property_name",
        res.data.property ? res.data.property.property_name : ""
      );
      sessionStorage.setItem(
        "property_logo_thumb_url",
        res.data.property ? res.data.property.property_logo_thumb_url : ""
      );
      sessionStorage.setItem(
        "property_sku",
        res.data.property ? res.data.property.aka_name : ""
      );
      localStorage.setItem("property_slug",  res.data.property ? res.data.property.slug : "");
      localStorage.setItem("property_name", res.data.property ? res.data.property.property_name : "");
      localStorage.setItem("property_logo_thumb_url", res.data.property ? res.data.property.property_logo_thumb_url : "");
      localStorage.setItem("property_sku", res.data.property ? res.data.property.aka_name : "");    
      if (res.data.feeArray) {
        res.data.feeArray.forEach((item, i) => {
          if (item.fees_name === "Cleaning Fee") {
            reservation_detail.cleaning_fee = item.fees_amount
              ? item.fees_amount
                .replace(/\.00$/, "")
                .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              : "";
          }
          if (item.fees_name === "Service Fee") {
            reservation_detail.guest_service_fee = item.fees_amount
              ? item.fees_amount
                .replace(/\.00$/, "")
                .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              : "";
          }
        });
      }
      if (res.data.taxArray) {
        if (res.data.taxArray.tax_name === "Occupancy Tax Fee") {
          reservation_detail.occupancy_taxes = res.data.taxArray.tax_amount
            ? res.data.taxArray.tax_amount
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : "";
        }
      }
      if (res.data.status_id === 1) {
        reservation_detail.status_id = "Confirmed";
      } else if (res.data.status_id === 2) {
        reservation_detail.status_id = "Cancelled";
      } else if (res.data.status_id === 3) {
        reservation_detail.status_id = "Payment Pending";
      } else {
        reservation_detail.status_id = "";
      }
      if (res.data.set_channel) {
        if (res.data.set_channel === global.setChannel.Airbnb) {
          reservation_detail.set_channel = airbnb;
        }
        if (res.data.set_channel === global.setChannel.expedia) {
          reservation_detail.set_channel = expedia;
        }
        if (res.data.set_channel === global.setChannel.vrbo) {
          reservation_detail.set_channel = vrbo;
        }
        if (res.data.set_channel === global.setChannel.bookingcom) {
          reservation_detail.set_channel = bookingcom;
        }
        if (
          res.data.set_channel === global.setChannel.Pms ||
          res.data.set_channel === global.setChannel.Website
        ) {
          reservation_detail.set_channel = ohp1;
        }
      }
      if (reservation_detail.guest_user !== undefined) {
        reservation_detail.recipient_name = reservation_detail.user
          ? reservation_detail.user.first_name +
          " " +
          reservation_detail.user.last_name
          : reservation_detail.guest_user.first_name +
          " " +
          reservation_detail.guest_user.last_name;
      }

      var userInfo = {
        first_name: reservation_detail.first_name ? reservation_detail.first_name : "",
        last_name: reservation_detail.last_name ? reservation_detail.last_name : "",
        role_title: global.RoleGroupName.Guest,
        email: reservation_detail.email ? reservation_detail.email : "",
        phone_number: reservation_detail.phone_number ? this.phoneNumberAutoFormat(reservation_detail.phone_number): "",
        // phone_number: reservation_detail.phone_number ? reservation_detail.phone_number: "",
        // address: reservation_detail.guest_user
        //   ? reservation_detail.guest_user.address
        //   : "",
      };
      var userHeader = {
        profile: reservation_detail.user
          ? reservation_detail.user.profile_thumb_url
          : "",
        userName: reservation_detail.recipient_name,
        userRole: global.RoleGroupName.Guest,
      };
      if (reservation_detail.guest_user) {
        var street_1 = reservation_detail.guest_user.street_1 ?? "";
        var street_2 = reservation_detail.guest_user.street_2 ?? "";
        var zip_code = reservation_detail.guest_user.zip_code ?? "";
        var city = reservation_detail.guest_user.city
          ? reservation_detail.guest_user.city.city_name
          : "";
        var state = reservation_detail.guest_user.state
          ? reservation_detail.guest_user.state.state_name
          : "";
        var country = reservation_detail.guest_user.country
          ? reservation_detail.guest_user.country.country_name
          : "";
        var guestAddress =
          street_1 +
          " " +
          street_2 +
          " " +
          city +
          " " +
          state +
          " " +
          country +
          " " +
          zip_code;
      }

      var reservationDetail = {
        listing_name: reservation_detail.listing_details
          ? reservation_detail.listing_details.listing_name
          : "",
        unit_name: reservation_detail.unit
          ? reservation_detail.unit.unit_name
          : "",

        resident_name: reservation_detail.resident_user
          ? reservation_detail.resident_user.first_name +
          " " +
          reservation_detail.resident_user.last_name
          : "",
        resident_slug: reservation_detail.resident_user
          ? reservation_detail.resident_user.slug
          : "",
        resident_property: reservation_detail.property
          ? reservation_detail.property.slug
          : "",
        no_of_guest: reservation_detail.number_of_guest
          ? reservation_detail.number_of_guest
          : "",
        no_of_night: reservation_detail.total_stay_nights
          ? reservation_detail.total_stay_nights
          : "",
        confirmation_code: reservation_detail.confirmation_code
          ? reservation_detail.confirmation_code
          : "",

        status: reservation_detail.status_id
          ? reservation_detail.status_id
          : "",
        check_in: reservation_detail.checkin_date
          ? reservation_detail.checkin_date
          : "",
        check_out: reservation_detail.checkout_date
          ? reservation_detail.checkout_date
          : "",
        parking_slot: reservation_detail.listing_details
          ? reservation_detail.listing_details.parking_slot
          : "",
        unit_code: reservation_detail.unit
          ? reservation_detail.unit.unit_code
          : "",
        keyCafeAccessCode: booking_code ? booking_code : "",
        instruction: instruction ? instruction : "",
        wifiDetail: reservation_detail.listing_details
          ? reservation_detail.listing_details.wi_fi_name
          : "",
        arrivalInstruction: reservation_detail.property
          ? reservation_detail.property.arrival_instruction
          : "",
          guestManual: reservation_detail.property
          ? reservation_detail.property.guest_manual
          : "",
          lockType: reservation_detail.property
          ? reservation_detail.property.lock_type
          : "",
        resUrl:
          "/properties/view/" +
          (reservation_detail.property
            ? reservation_detail.property.slug
            : "") +
          "/reservations/view/" +
          reservation_detail_slug,
        channel: reservation_detail.set_channel,
        quickAddress: guestAddress,
        created_at: reservation_detail.created_at,
        adults: reservation_detail.adults,
      };
      var guestPaid = {
        total_stay_nights: reservation_detail.total_stay_nights
          ? reservation_detail.total_stay_nights
          : "",
        total_nightly_rate: reservation_detail.total_nightly_rate
          ? reservation_detail.total_nightly_rate
          : "",
        nightly_rate: reservation_detail.nightly_rate
          ? reservation_detail.nightly_rate
          : "",
        cleaning_fee: reservation_detail.cleaning_fee,
        service_fee: reservation_detail.guest_service_fee,
        occupancy_taxes: reservation_detail.occupancy_taxes,
        guest_paid: reservation_detail.guest_paid,
      };
    }

    this.setState({
      reservation_slug: this.state.reservation_slug,
      userInfo: userInfo,
      userHeader: userHeader,
      reservationDetail: reservationDetail,
      guestPaid: guestPaid,
    });
  }
  /* quick template data */
  async getTemplateList(thread_type) {
    var queryString = "";
    if (thread_type === 1) {
      queryString =
        "reservation_slug=" + this.state.reservation_slug + "&roleGroup=5";
    } else if (thread_type === 2) {
      queryString = "user_slug=" + this.state.user_slug + "&roleGroup=3";
    } else if (thread_type === 3) {
      queryString = "user_slug=" + this.state.user_slug + "&roleGroup=4";
    }
    var templateList = [];
    let res = await RUmessageService.getMessageTemplateList(
      queryString ? queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      templateList = res.data ? res.data : [];
    }
    this.setState({
      showquickreplyModal: true,
      templateList: templateList,
    });
  }
  /* Click on use this reply button in quick reply template list */
  getEditedTemplate(record) {
    this.setState({ showquickreplyModal: false });
    var message = record.message ? record.message : "";
    var newMassage = "";
    var guestName = this.state.userHeader.userName
      ? this.state.userHeader.userName
      : "";
    if (this.state.thread_type === 1) {
      if (this.state.reservationDetail != undefined) {
       var confCode = this.state.reservationDetail.confirmation_code ?? "";
       var checkin = this.state.reservationDetail.check_in ?? "";
       var checkout = this.state.reservationDetail.check_out ?? "";
       var address = this.state.reservationDetail.quickAddress ?? "";
       var unitNumber = this.state.reservationDetail.unit_name ?? "";
       var listing = this.state.reservationDetail.listing_name ?? "";
       var parkingSpace = this.state.reservationDetail.parking_slot ?? "";
       var wifiName = this.state.reservationDetail.wifiDetail ?? "";
       var wifiPassword = this.state.reservationDetail.wifiDetail ?? "";
       var keyCafeCode = this.state.reservationDetail.keyCafeAccessCode ?? "";
        var instruction = this.state.reservationDetail.instruction ?? "";
       var arrivalInstruction = this.state.reservationDetail.arrivalInstruction ?? "";
        var div = document.createElement("div");
        div.innerHTML = instruction;
       var keyIns = div.textContent || div.innerText || "";
      }
    }
    if (message != undefined) {
      if (this.state.thread_type === 1) {
        newMassage = message ? message
        .replace("[Guest Name]", guestName ?? "")
        .replace("[Confirmation Code]", confCode ?? "")
        .replace("[Check-In]", Moment(checkin).format(global.dateTimeFormat) ?? "")
        .replace("[Check-Out]", Moment(checkout).format(global.dateTimeFormat) ?? "")
        .replace("[Address]", address ?? "")
        .replace("[Unit No]", unitNumber ?? "")
        .replace("[Listing]", listing ?? "")
        .replace("[Parking Space]", parkingSpace ?? "")
        .replace("[Wifi-Name]", wifiName ?? "")
        .replace("[Wifi-Password]", wifiPassword ?? "")
        .replace("[Key-Cafe-Code]", keyCafeCode ?? "")
        .replace("[Key-Instruction]", keyIns ?? "")
        .replace("[Arrival-Instruction]", arrivalInstruction ?? "")
          : "";
      }
      if (this.state.thread_type === 2) {
        newMassage = message ? message
          .replace("[Staff Name]", guestName ?? "")
         .replace("[Unit No]", unitNumber ?? "") 
          : "";
      }
      if (this.state.thread_type === 3) {
        newMassage = message ? message
         .replace("[Resident Name]", guestName ?? "")
         .replace("[Unit No]", unitNumber ?? "") 
          : "";
      }

      this.setState({
        message: newMassage,
        sendBtn: true,
      });
    }
    this.setState({
      message: newMassage,
    });
  }
  /* Logic for post message */
  async postMessage(thread_slug) {
    this.setState({ isSubmit: true, images: [], pageLoad: 0, sendBtn: false });
    if (window.document.getElementsByClassName("msgPost")[0] != undefined) {
      window.document.getElementsByClassName("msgPost")[0].innerHTML = "";
    }
    if (this.state.file != undefined && this.state.file.length > 0) {
      await this.ImageUpload(this.state.file, thread_slug);
    } else {
      if (this.state.file == undefined || this.state.file.length === 0) {
        if (this.state.message != undefined) {
          var newMsg = this.state.message ? this.state.message.replace(/\n/g, "<br/>"): "";
        }
        var NewMsgList = this.messageListCreateAfterPost(thread_slug, newMsg);
        /* call back state */
        this.setState({ messageList: NewMsgList }, () => {
          this.scrollTop();
        });
        this.setState({ message: newMsg });
        this.MessageUpload(thread_slug);
      }
    }
    this.setState({ file: [], body: "", message: "" });
  }
  /* Image upload method */
  ImageUpload(ImageArr, thread_slug) {
    this.setState({ showSpinner: true, loading: true });
    let arrImg = [];
    let msgData = this.state.message ?? "";
    ImageArr.forEach(async (img, i) => {
      let fileName = img.file.name;
      let bodyFormData = new FormData();
      bodyFormData.append("file_name", img.file, fileName);
      bodyFormData.append("orgFileDestinationFolder", "messages");
      bodyFormData.append("fieldName", "file_name");
      bodyFormData.append("mediaType", 1);
      bodyFormData.append("request_from", "MSG");
      bodyFormData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      const response = await RUmessageService.uploadImage(bodyFormData);
      if (global.successStatus.includes(response.status)) {
        arrImg.push({ fileName: response.data.fileName, mediaType: 1 , filePath: response.data.filePath });
        if (arrImg.length == ImageArr.length) {
          await this.MessageUpload(thread_slug, arrImg, msgData);
          this.setState({ showSpinner: false, loading: false, file: [] });
        }
      }
    });
  }
  /* Message post api */
  async MessageUpload(thread_slug, arrImg, msgData) {
    var inputData = {};
    if (this.state.message != undefined) {
      var message = this.state.message ? this.state.message : msgData;
      var body = message.replace(/\n/g, "<br/>") ?? "";
      inputData = {
        thread_slug: thread_slug,
        Body: body,
        images: arrImg != undefined ? arrImg : [],
        user_role_group_id: this.state.roleGroup,
        role_slug: this.state.role_slug,
      };
    }

    if (thread_slug) {
      let res = await RUmessageService.createMessage(inputData);
      this.setState({ message: "" });
      if (global.successStatus.includes(res.status)) {
        window.document.getElementById("message").value = "";
        if (arrImg != undefined && arrImg.length > 0) {
          var apiMessageList = await this.getMessageList(thread_slug);
          this.setState({
            messageList: apiMessageList,
            socketArr: [],
          });
          /* update Thread list preview and time update */
          var curtLocalStgIndex = localStorage.getItem("current_index");
          var curtLocalStgArr = this.state.threadList[curtLocalStgIndex];
          if (curtLocalStgArr != undefined) {
            curtLocalStgArr.message_type = 1;
          }
        }
        localStorage.setItem("msgCount", 1);
      }
      this.setState({ body: "", message: "" });
    } else {
      alert("Please select user for chat");
    }
    if (arrImg != undefined && arrImg.length > 0) {
      this.scrollTop();
    }
  }
  /* Creat message array with existing list */
  messageListCreateAfterPost(thread_slug, body) {
    var appendObject = [
      {
        from_user_slug: global.userData.slug,
        create_date: new Date().toISOString(), //date use utc
        thread_slug: thread_slug,
        message_body: body,
        IsIncoming: false,
        galleries: [],
        message_type: 3,
        sender_name:'OHAI',
        sender: {
          first_name: global.userData.first_name,
          last_name: global.userData.last_name,
          profile_thumb_url: global.userData.profile_thumb_url,
        },
      },
    ];
    if (localStorage.getItem("current_thread") === thread_slug) {
      var socketArrDate = Moment(new Date()).format("YYYY-MM-DD");
      var existArry = this.state.messageList;
      var dateStatus = false;
      if (existArry != undefined && existArry.length > 0) {
        for (var i = 0; i < existArry.length; i++) {
          var ExistArrdate = existArry[i].date;
          if (ExistArrdate === socketArrDate) {
            dateStatus = true;
            break;
          }
        }
      }
      if (dateStatus === true) {
        if (existArry.length > 0) {
          var lastIndex = existArry.length - 1;
          if (existArry[lastIndex].message.length > 0) {
            /* check message exist on message list or not   */
            var socketDataArr = [
              ...existArry[lastIndex].message,
              ...appendObject,
            ];
            postArr = [
              {
                date: Moment(appendObject.create_date).format("YYYY-MM-DD"),
                message: socketDataArr,
              },
            ];
            var newArr = existArry.slice(0, -1);
            var newMsgArr = [...newArr, ...postArr];
          }
        }
      } else {
        var postArr = [];
        postArr = [
          {
            date: Moment(new Date()).format("YYYY-MM-DD"),
            message: appendObject,
          },
        ];
        if (this.state.messageList) {
          newMsgArr = [...this.state.messageList, ...postArr];
        } else {
          newMsgArr = [...postArr];
        }
        this.setState({
          dateAvailable: true,
        });
      }

      /* current thread match (preview update on existing thread list) */
      var currentThreadArray =
        this.state.threadList[localStorage.getItem("current_index")];
      if (currentThreadArray != undefined) {
        if (appendObject[0].message_type === 3) {
          currentThreadArray.message_type = 3;
          var msg = appendObject && appendObject[0] && appendObject[0].message_body ? appendObject[0].message_body.replace(/[<]br[^>]*[>]/gi, ""):"";
          currentThreadArray.preview =
            this.state.replyYou +
            (msg.length > 20
              ? `${renderHTML(msg.substring(0, 20))}...`
              : renderHTML(msg.substring(0, 20)));
          currentThreadArray.last_message_date = appendObject[0].create_date;
        }
      }
    }
    return newMsgArr;
  }
/* unread Count update from database */
  async uncountUpdate(thread_slug){
    var unreadCount = [];
    let res = await RUmessageService.unreadCountUpdate(thread_slug);
    if (global.successStatus.includes(res.status)) {
        unreadCount = res.data ? res.data : [];
    }
    this.setState({unreadCount: unreadCount});
  }

  getTitle() {
    return "Messages";
  }
  openModal() {
    this.setState({
      isNewModelOpen: 1,
      apiRes:true,
    });
  }
  closeModal() {
    this.setState({
      showquickreplyModal: false,
    });
  }
  isNewModelOpenUpdate(isNewModelClose) {
    this.setState({ isNewModelOpen: isNewModelClose });
  }
  newMessagAapiReasponce(apiRes) {
    this.setState({ apiRes: apiRes });
  }
  isRightSideOpen(isRightSecClose) {
    this.setState({ recipt_box: isRightSecClose });
  }
  scrollTop = () => {
    const input = window.document.getElementsByClassName("chatList");
    if (input != undefined) {
      var last = input[input.length - 1];
      if(last != undefined){
        last.scrollTop =  last.scrollHeight;
      }
      
    }
  };
  threadScrollTop = () => {
    const threadDiv = window.document.getElementsByClassName("Thread-list");
    if (threadDiv != undefined) {
      var threadDivLast = threadDiv[threadDiv.length - 1];
      if(threadDivLast != undefined){
        threadDivLast.scrollTop =  threadDivLast.scrollHeight;
      }    
    }
  };
  imagePreview(event) {
    var files = event.target.files;
    var images = [];
    var file = [];
    for (let i = 0; i < files.length; i++) {
      file.push({ file: files[i], src: URL.createObjectURL(files[i]) });
      images.push({ file: files[i], src: URL.createObjectURL(files[i]) });
    }
    this.setState({
      images: images,
      file: file,
      sendBtn: true,
    });
    window.document.getElementById("message").click();
  }
  imageClose(i) {
    let images = [...this.state.images];
    images.splice(i, 1);
    var sendBtn = images.length > 0 ? true : false;
    this.setState({ images, sendBtn: sendBtn });
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    let cursor = event.target.selectionStart;
    let sendBtn = false;
    input[event.target.name] = value;
    if (value != "") {
      sendBtn = true;
    }
    this.setState({
      [event.target.name]: value,
      input: input,
      sendBtn: sendBtn,
      cursor: cursor,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  customValidate() {
    let isValid = true;
    return isValid;
  }
  onEmojiClick = (event) => {
    const cursor = this.state.cursor;
    let text = "";
    if (this.state.message != undefined) {
      text =
        this.state.message.slice(0, cursor) +
        event.emoji +
        this.state.message.slice(cursor);
    }
    var message = "";
    if (this.state.message != undefined) {
      message = window.document.getElementById("message").value = text;
    } else {
      message = window.document.getElementById("message").value = event.emoji;
    }
    this.setState({
      emoji: event.emoji,
      code: event.unified,
      message: message,
      sendBtn: true,
    });
    window.document.getElementById("message").click();
  };
  searchforThreadChangeHandler = (event) => {
    let value = event.target.value;
    let searchVal = value.toLowerCase();
    let queryString = "is_search=1&property_slug=" + this.state.property_slug +
        "&role_slug=" + this.state.role_slug +
        "&search_value=" + searchVal;  
        if (event.key === 'Enter') {
          if (this.state.isFilterApply === true) { 
            this.getThreadListFliter(this.state.filterString + "&"+ queryString);
          } else {
            this.getThreadList(queryString);
            this.setState({isSearch: true, isFilterApply:false});
          }
        }
  };
  isFilterModel() {
    this.setState({
      isFilterModel: 1,   
    });
  }
  isFilterModelUpdate(isfileterModelClose) {
    this.setState({ isFilterModel: isfileterModelClose });
  }
  customThreadfilter(filterString, defaultPageNumber) {
    this.setState({ messageList:null, reservationDetail: null, userHeader: null, userInfo: null, defaultPageNumber: defaultPageNumber});
    if (filterString) {
      this.getThreadListFliter(filterString);
      this.setState({ isFilterModel: 0, filterString: filterString, defaultPageLoad: 0});
    }else{
        this.getThreadList();   
        this.setState({ filterString: undefined, defaultPageNumber: 0});      
    }
  }
  async getThreadListFliter(filterString = "") {
    var isFilterApply =  filterString ? true : false; 
    if(this.state.isSearch === true){
      isFilterApply = false;
    }
    
    this.setState({ showSpinner: true, isFilterApply: isFilterApply , filterString:filterString});
    let threadList = [];
    let thread_slug = null;
    let totalCount = 0;
    let res = await RUmessageService.getThreadListFliter(filterString);
    if (global.successStatus.includes(res.status)) {
      threadList = res.data ? res.data.data : [];
      totalCount = res.data ? res.data.total: 0;
      thread_slug = threadList != undefined && threadList.length > 0 ? threadList[0].slug : this.state.thread_slug;
    }
    this.setState({
      threadList: threadList,
      totalCount: totalCount,
      thread_slug: this.state.OutSideThreadSlug ? this.state.OutSideThreadSlug : thread_slug,
      newthreadList: undefined,
      showSpinner: false,
    });
    this.setState({ showSpinner: false });
    if (this.state.pageLoad === 1) {
      if (this.state.thread_slug != "" && this.state.thread_slug != undefined) {
        if (window.document.getElementsByClassName("thread-" + this.state.thread_slug)[0] != undefined) {
            var reserThredScroll = window.document.getElementsByClassName("thread-" + this.state.thread_slug)[0];
          // reserThredScroll.scrollIntoView(false);
          reserThredScroll.click();
        }
      }
    }
    //this.threadScrollTop();
  }
  openHouse() {
    this.setState({
      isHouseOpen: true,
    });
  }
  closeHouse() {
    this.setState({
      isHouseOpen: false,
    });
  }
  openArrival() {
    this.setState({
      isArrivalOpen: true,
    });
  }
  closeArrival() {
    this.setState({
      isArrivalOpen: false,
    });
  }
  fetchMoreData(){
    this.getThreadList();
  }
  async chatBotStatusUpdate(value) {
    let chatGptEnable = "";
    let inputData = {
      thread_slug: this.state.thread_slug,
      role_slug: this.state.role_slug,
      chat_gpt_enable: value,
    };
    let res = await RUmessageService.chatGpt(inputData);
    if (global.successStatus.includes(res.status)) {
      chatGptEnable = res.data ? res.data.chat_gpt_enable : 0;
    }
    this.setState({
      chatGptEnable: chatGptEnable,
    });
  }
  render() {
    return (
      <main className="msg_srl_sec">
        <Container className="innter-container">
          <div className="message pd-t-20  pd-lg-l-30 pd-xs-l-15 pd-r-0 ">
            <div className="col-md-12 pd-l-0 pd-r-0">
              <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns">
                <h3>{this.getTitle()}</h3>
                <button
                  className="btn-success-outline mr-0 newMsgBtn"
                  onClick={() => this.openModal()}
                >
                  <img src={pluswIcon} /> {this.state.apiRes === true ? global.loader : "New Message"} 
                </button>
              </div>
            </div>
            <div className="col-lg-12 pd-l-0 pd-r-0 position-relative">
              <div className="dashboardRightcard mg-t-15 pt-3">
                <div className="row m-0 w-100 message_mode_ration">
                  <div className="col-lg-3 col-xs-12 pd-l-5  pd-r-5">
                    <div className="leftSection position-relative">
                      <div className="header d-flex">
                        <div className="searchSection w-100">
                          <Form.Control
                            id="search-form"
                            type="text"
                            placeholder="Search by name..."
                            name="search"
                            onKeyDown={this.searchforThreadChangeHandler}
                            value={this.state.search}
                          />
                          <span className="hand-cursor">
                            <img src={search} />
                          </span>
                        </div>
                        <div>
                          <button
                            className= {this.state.isFilterApply === true ? "msg_filter_btn filter_count" :"msg_filter_btn"}
                            onClick={() => this.isFilterModel()}
                          >
                            <img src={MsgFilter} />
                            {/* {this.state.isFilterApply == true ? (<><span></span></>) : ""} */}                          
                          </button>
                        </div>
                      </div>

                      {/* START:: THREAD SECTION */}
                      <div className="listItem Thread-list" >   
                        <ul>
                          {this.state.threadList != undefined &&
                            this.state.threadList.length > 0
                            ? this.state.threadList.map((rec, i) => {
                              if (rec.preview != null) {
                                var pr = rec.preview ? rec.preview.substring(0, 20) :"";
                              }
                              if (rec.checkin_date !== null && rec.checkin_date !== undefined && rec.checkin_date !== "0000-00-00" && rec.checkin_date !== "0000-00-00 00:00:00") {
                                var CheckInDate = Moment(rec.checkin_date).format(global.dateFormat);
                              } else {
                                CheckInDate = null;
                              }
                              if (rec.checkout_date !== null && rec.checkout_date !== undefined && rec.checkout_date !== "0000-00-00"  && rec.checkout_date !== "0000-00-00 00:00:00") {
                                var checkOutDate = Moment(rec.checkout_date).format(global.dateFormat);
                              } else {
                                checkOutDate = null;
                              }
                              if (rec.set_channel_name != null && rec.set_channel_name == "AirbnbGuestInquiry") {
                               var communication = "Inquiry";
                              } else {
                                communication = null;
                              }
                              return (
                                <li
                                  key={i}
                                  className={"thread-" + rec.slug}
                                  onClick={() => this.threadClick(rec, i)}
                                >
                                  <a
                                    href="#"
                                    className={
                                      this.state.thread_slug == rec.slug
                                        ? "active"
                                        : ""
                                    }
                                    >
                                    <div className="side_message">
                                      <figure>
                                        <img
                                          src={rec.property ? rec.property.property_logo_url
                                            : ohp

                                          }
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = guestimg;
                                          }}
                                        />
                                      </figure>
                                      <p>
                                        {rec.property
                                          ? rec.property.aka_name
                                          : "OH"}
                                      </p>
                                    </div>
                                    <div className="content">
                                      <div className="d-flex flex-column w-75-custom">
                                        {rec.thread_type == 1 || rec.thread_type == 3 ? (<><b>{rec.thread_owner } </b></>):(rec.login_id !== rec.created_by &&
                                          rec.login_id !== rec.user_id ? (<> {rec.sender_name ? rec.sender_name :""}</>) : (rec.reciver_name ? rec.reciver_name :"") )}
                                        
                                        {/*============== END NAME SECTION ===============================*/}
                                        <span>
                                          {/* For guest */}
                                          {rec.thread_type === global.threadType.Guest
                                            ? rec.role_group_name + (rec.unit_name && rec.unit_name !=0 ? " | " + rec.unit_name : "")
                                            + (CheckInDate != null ? " | " + CheckInDate + " - " + checkOutDate : (communication != null ? " | " + communication:"")) : ""}
                                          {/* For staff */}
                                          {rec.thread_type ==
                                            global.threadType.Staff
                                            ? (rec.role_group_name ? rec.role_group_name : "") + " | " + (rec.user_role ? rec.user_role :"") : ""}

                                          {/* For resident */}
                                          {rec.thread_type ==
                                            global.threadType.Resident
                                            ? (rec.role_group_name ? rec.role_group_name : "") +
                                            " | " + (rec.unit_name ? rec.unit_name : "")
                                            : ""}
                                        </span>
                                        {/*============== END ROLE AND RESERVATION AND UNIT SECTION ===============================*/}
                                        <span className="recive_msg d-flex">
                                          {/* preview for image */}
                                          {rec.message_type == 1 ? (
                                            <>
                                              <img src={gallery} />
                                              <span className="ml-1">
                                                Photo
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {/* preview for document */}
                                          {rec.message_type == 2 ? (
                                            <>
                                              <img src={document} />{" "}
                                              <span> Document</span>{" "}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {/* preview for string */}
                                          {rec.message_type == 3 ? pr : ""}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-end flex-column align-items-end flex-place-self w-25-custom">
                                        <i>
                                          {rec.last_message_date
                                            ? this.state.today ==
                                              moment(
                                                moment
                                                  .utc(rec.last_message_date)
                                                  .toDate()
                                              )
                                                .local()
                                                .format("MM/DD/YYYY")
                                              ? moment(
                                                moment
                                                  .utc(
                                                    rec.last_message_date
                                                  )
                                                  .toDate()
                                              )
                                                .local()
                                                .format(global.timeFormat)
                                              : moment(
                                                moment
                                                  .utc(
                                                    rec.last_message_date
                                                  )
                                                  .toDate()
                                              )
                                                .local()
                                                .format(
                                                  global.dateTimeFormat
                                                )
                                            : ""}
                                        </i>

                                        <b
                                          className={
                                            rec.unread_count === 1
                                              ? "aleart_msg unread-" +
                                              rec.slug
                                              : ""
                                          }></b>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              );
                            })
                            :(this.state.isSearch === true ? "No record found." : "")}
                        </ul>                                     
                      </div>
                      {this.state.isFilterApply === false && this.state.isSearch === false && this.state.threadList && this.state.threadList.length > 6 ? (<><div className="scroll_btm_box" onClick={() => this.fetchMoreData()}>
                      <span>Show More </span>
                      </div> </>) : ""}
                    </div>
                  </div>
                  {this.state.defaultPageLoad == 0 ? (<>
                    <div className={this.state.recipt_box == true ? "col-lg-6 col-xs-12 pd-lg-l-5 pd-lg-r-5 pd-xs-l-5 pd-xs-r-5 message_box_show" : 'col-lg-6 col-xs-12 pd-lg-l-5 pd-lg-r-5 pd-xs-l-5  pd-xs-r-5 '}>
                      {/* START:: CHAT SECTION SECTION */}
                      <div className="middleSection position-relative">
                        {this.state.userHeader != undefined ? (
                          <div className="header">
                            <div className="d-flex">
                              <figure>
                                <img
                                  src={
                                    this.state.property_logo
                                      ? this.state.property_logo
                                      : ohp
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = guestimg;
                                  }}
                                />
                              </figure>
                              <h6>
                                {this.state.userHeader.userName} | {this.state.userHeader.userRole}  {this.state.threadTypeName ? " | " + this.state.threadTypeName :"" }
                              </h6>
                            </div>

                            <span variant="outline-light" className="hand-cursor recipt_btn_ham">
                              <img
                                src={mobmenu}
                                className={this.state.recipt_box == true ? " " : " d-none"}
                                onClick={() => this.openRecipt()}
                              />
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="chatSection postion-relative">
                          {/* messag list array */}
                          <div className="chatList" id="chatList">
                          {/* <a href="#" className="btn_msg_load" >See Previous Messages</a> */}
                            {this.state.messageList != undefined &&
                              this.state.messageList.length > 0
                              ? this.state.messageList.map((rec, i) => {
                                return (
                                  <div className="listItem" key={i}>
                                    <span className="status">
                                      {Moment(rec.date).format(
                                        global.dateFormat
                                      )}
                                    </span>
                                    <ul>
                                      {rec.message != undefined &&
                                        rec.message.length > 0
                                        ? rec.message.map((el, index) => {
                                          var msgData = el.message_body
                                            ? el.message_body
                                            : "";
                                            if (msgData != null && msgData != undefined) {
                                              var msgData1 = msgData.replace(/<br ?\/?>/g, "\r<br>");
                                              var text = msgData1;
                                              const linksFound = text.match(/(?:www|https?)[^\s]+/g);
                                              const aLink = [];
                                              if (linksFound != null) {
                                                for (let i = 0; i < linksFound.length; i++) {
                                                  let replace = linksFound[i];
                                                  if (!(linksFound[i].match(/(http(s?)):\/\//))) { replace = 'http://' + linksFound[i] }
                                                  let linkText = replace.split('/')[2];
                                                  if (linkText.substring(0, 3) == 'www') { linkText = linkText.replace('www.', '') }
                                                  if (linkText.match(/youtu/)) {

                                                    let youtubeID = replace.split('/').slice(-1)[0];
                                                    aLink.push('<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' + youtubeID + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>')
                                                  }
                                                  else if (linkText.match(/vimeo/)) {
                                                    let vimeoID = replace.split('/').slice(-1)[0];
                                                    aLink.push('<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' + vimeoID + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>')
                                                  }
                                                  else {
                                                    aLink.push('<a href="' + replace + '" target="_blank">' + replace + '</a>');
                                                  }
                                                  text = text.split(linksFound[i]).map(item => { return aLink[i].includes('iframe') ? item.trim() : item }).join(aLink[i]);
                                                }
                                                

                                              }
                                            }
                                        
                                          return (
                                            <li
                                           

                                              className="main_user"
                                              key={index}>
                                               <p className="message_name">{
                                                            el.sender_name != undefined ? el.sender_name : (el.sender !=
                                                              undefined &&
                                                              Object.keys(
                                                                el.sender
                                                              ).length != 0
                                                              ? el.sender
                                                                .first_name +
                                                              " " +
                                                              el.sender
                                                                .last_name
                                                              : "")
                                                          }</p>
                                              <div className="chatContent">
                                                <figure>
                                                  <img
                                                    src={guestimg}
                                                    onError={({
                                                      currentTarget,
                                                    }) => {
                                                      currentTarget.onerror =
                                                        null; // prevents looping
                                                      currentTarget.src =
                                                        guestimg;
                                                    }}
                                                  />
                                                </figure>
                                                <div className="content">
                                                  <div className="d-flex flex-column chatmsgcontent">
                                                    <div
                                                      className={
                                                        "chatmessage"
                                                      }>
                                                      <span className="chat_para">
                                                        {el.message_body
                                                          ? renderHTML(
                                                            text
                                                          ) : ""}
                                                          
                                                         
                                                             <ul className="rating_list" >
                                                             
                                                             {el.rating !=undefined && el.rating.length > 0 ?
                                                             
                                                             el.rating.map((elll, ii) => {
                                                              
                                                             return( <li key={ii}><span>{elll.labal_name}</span>: {elll.labal_rating} </li>)
                                                            }) 
                                                            
                                                            :""}
                                                              </ul>
                                                              
                                                         
                                                      </span>

                                                      {el.galleries.length >
                                                        0 ? (
                                                        <>
                                                          <div>
                                                            <React.Fragment>
                                                              <Gallery
                                                                backdropClosesModal={
                                                                  false
                                                                }
                                                                preloadNextImage={
                                                                  true
                                                                }
                                                                images={
                                                                  el.galleries ? (
                                                                    el.galleries
                                                                  ) : (
                                                                    <NoData msg="file uploaded" />
                                                                  )
                                                                }
                                                              />
                                                            </React.Fragment>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <i className="user_time_inside">
                                                        
                                                        {moment(
                                                          moment
                                                            .utc(
                                                              el.create_date
                                                            )
                                                            .toDate()
                                                        )
                                                          .local()
                                                          .format(
                                                            global.timeFormat
                                                          )}
                                                      </i>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        })
                                        : ""}
                                    </ul>
                                  </div>
                                );
                              })
                              : ""}
                          </div>
                              <div className="Chatbox">
                              <>
                                <span>
                                  {[""].map((direction) => (
                                    <DropdownButton
                                      key={direction}
                                      id={`dropdown-button-drop-${direction}`}
                                      drop={direction}
                                      variant="secondary"
                                      title={` ${direction} `}>
                                      <span>
                                        <div className="fileUpload" eventKey="1">
                                          <label className="hand-cursor d-flex">
                                            <input
                                              type="file"
                                              name="gallery_img"
                                              id="file"
                                              onChange={this.imagePreview}
                                              accept="image/png,image/jpg,image/jpeg"
                                              multiple
                                            />
                                            <img src={gallery} /> Gallery
                                          </label>
                                        </div>
                                      </span>
                                    </DropdownButton>
                                  ))}
                                  {global.userPermissions.checkPermission(
                                    "communication-message-see-quick-reply-template"
                                  ) ? (
                                    <>
                                      <button
                                        onClick={() =>
                                          this.getTemplateList(
                                            this.state.thread_type
                                          )
                                        }
                                        className="qickReplyicon">
                                        <img src={quickreply} />
                                      </button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                                {/* Image preview */}
                                <div className="tumbImg">
                                  <ul>
                                    {this.state.images
                                      ? this.state.images.map((val, i) => {
                                        return (
                                          <li key={i}>
                                            <figure>
                                              <img
                                                src={
                                                  val.file.name.match(
                                                    /.(jpg|jpeg|png|gif)$/i
                                                  )
                                                    ? val.src
                                                    : val.file.name.match(
                                                      /.(pdf)$/i
                                                    )
                                                      ? pdfIcon
                                                      : val.file.name.match(
                                                        /.(docx|doc)$/i
                                                      )
                                                        ? wordIcon
                                                        : val.file.name.match(
                                                          /.(xlsx|csv|xls)$/i
                                                        )
                                                          ? excelIcon
                                                          : ""
                                                }
                                                onError={({ currentTarget }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src =
                                                    placeholderImage;
                                                }}
                                              />
                                            </figure>
                                            <button>
                                              <img
                                                src={closeIcon}
                                                onClick={() => this.imageClose(i)}
                                              />
                                            </button>
                                          </li>
                                        );
                                      })
                                      : ""}
                                  </ul>
                                </div>
                                {/* Emoji */}
                                <div className="flex-wrap">
                                  {["up"].map((direction) => (
                                    <DropdownButton
                                      key={direction}
                                      id={`dropdown-button-drop-${direction}`}
                                      drop={direction}
                                      variant="secondary"
                                      className="emojidropdown"
                                      title="">
                                      <div>
                                        <Picker
                                          onEmojiClick={this.onEmojiClick}
                                          disableAutoFocus={true}
                                          native
                                          className="test"
                                        />
                                      </div>
                                    </DropdownButton>
                                  ))}
                                </div>
                                <TextareaAutosize
                                  onResize={(e) => { }}
                                  onChange={this.changeHandler}
                                  name="message"
                                  id="message"
                                  className="msgPost"
                                  placeholder="Type your message..."
                                  value={
                                    this.state.message ? this.state.message : ""
                                  }
                                />
                                <button
                                  onClick={() =>
                                    this.postMessage(this.state.thread_slug)
                                  }
                                  className={
                                    this.state.sendBtn == true
                                      ? "post"
                                      : "post d-none"
                                  }>
                                  <img src={postarrow} className="m-0" />
                                </button>
                              </>
                          </div>
                          {this.state.chatGptEnable && this.state.chatGptEnable == 1 ? (<>
                            <div className="chat_ai_b">
                           <a href="javascript:viod(0)" className="btn btn-chat-ai"  onClick={() => this.chatBotStatusUpdate(0)} >
                              Pause AI Bot
                           </a>
                         </div>
                          </>) : (<>
                            <div className="chat_ai_b">
                                <a href="javascript:viod(0)" className="btn btn-chat-ai active" onClick={() => this.chatBotStatusUpdate(1)}>
                                Resume AI Bot
                           </a>
                         </div>
                          </>)}
                        </div>
                      </div>
                    </div>
                    {/* <MessageRightSection userSlug={this.state.userSlug} reservation_slug = {this.state.reservation_slug} isRightSideOpen={this.isRightSideOpen.bind(this)}></MessageRightSection> */}
                    <div className={this.state.recipt_box == true ? "col-lg-3 pd-l-5 col-xs-12  pd-lg-r-15 pd-xs-l-5  pd-xs-r-5 recipt_scroll d-none" : 'col-lg-3 pd-l-5 col-xs-12  pd-lg-r-15 pd-xs-l-5  pd-xs-r-5 recipt_scroll'}>
                      {/* START:: INFORMATION SECTION */}
                      {this.state.userInfo != null ? (
                        <div className="rightSection">
                          <span variant="outline-light" className="hand-cursor recipt_close">
                            <img
                              src={closeIcon}
                              className="closeicon m-0"
                              onClick={() => this.closeRecipt()}
                            />
                          </span>
                          <div className="reservationInfo">
            
                            <div className="header">
                              <h6> {this.state.reservationDetail  ? this.state.reservationDetail.listing_name : (this.state.thread_type == 2 ? "Staff details" : (this.state.thread_type == 1 ? "Guest details" : "Resident details"))} </h6>
                            </div>
                          </div>
                          {this.state.reservationDetail == undefined ? (
                            <div className="reservation_box">
                                <div className="row">
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>First Name</label>
                                      <p>
                                        {this.state.userInfo.first_name}
                                        
                                      </p>
                                    </div>
                                  </div>

                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Last Name</label>
                                      <p>
                                      {this.state.userInfo.last_name
                                          ? " " + this.state.userInfo.last_name
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                
                                {this.state.listingName == undefined ? (<>
                                  <div className=" col-md-12 col-12">
                                    <div className="resr_info">
                                      <label>Email</label>
                                      <p>
                                      {this.state.userInfo.email}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Mobile</label>
                                      <p>
                                      {this.state.userInfo && this.state.userInfo.phone_number? this.state.phone_number
                                      : ""}
                                         {/* {this.state.userInfo && this.state.userInfo.phone_number ? (
                                          <PhoneNumberFormat
                                            phone_number={this.state.userInfo.phone_number}
                                            disableCountryCode
                                          />
                                        ) : (
                                          ""
                                        )} */}
                                        
                                      </p>
                                    </div>
                                </div> 
                                </>) : <>
                                    {this.state.listingName ? (<><div className=" col-md-12 col-12">
                                    <div className="resr_info">
                                      <label>Listing Name</label>
                                      <p>
                                      {this.state.listingName ? this.state.listingName
                                      : ""}
                                        
                                      </p>
                                    </div>
                                    </div> 
                                    {/* <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Unit Name</label>
                                      <p>
                                      {this.state.unit_name ? this.state.unit_name
                                      : ""}
                                        
                                      </p>
                                    </div>
                                      </div> */}
                                    </>) : ""}
                                
                                </>}
                                  {this.state.thread_type == 3 ? (<><div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Unit Name</label>
                                      <p>
                                      {this.state.userInfo.unit}
                                      </p>
                                    </div>
                                </div><div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Mobile</label>
                                      <p>
                                      {this.state.userInfo && this.state.userInfo.phone_number? this.state.userInfo.phone_number
                                      : (this.state.phone_number ? this.state.phone_number :"")}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Email</label>
                                      <p>
                                      {this.state.userInfo && this.state.userInfo.email? this.state.userInfo.email
                                      : (this.state.email ? this.state.email : "")}
                                      </p>
                                    </div>
                                </div></>) : ""}
                               
                                  

                                </div>
                              </div>

                            ) : (
                              ""
                            )}
                          {this.state.reservationDetail != undefined ? (
                            <><div className="reservation_box">
                            <div className="row">
                              <div className=" col-md-6 col-12">
                                <div className="resr_info brdr-rgh-r">
                                  <label>Check-In </label>
                                    <p>{ this.state.reservationDetail && this.state.reservationDetail.check_in
                                      ? Moment(
                                        this.state.reservationDetail
                                          .check_in
                                      ).format(global.dateTimeFormat)
                                    : ""}</p>
                                </div>  
                              </div>
                              <div className=" col-md-6 col-12">
                                <div className="resr_info ">
                                  <label>Check-Out </label>
                                  <p>
                                      { this.state.reservationDetail && this.state.reservationDetail.check_in
                                        ? Moment(
                                          this.state.reservationDetail
                                            .check_out
                                        ).format(global.dateTimeFormat)
                                        : ""}
                                    </p>
                                </div>
                              </div>
                            </div>
                          </div></>

                            ) : (
                              ""
                            )}
                          {this.state.reservationDetail != undefined ? (
                            <>
                              <div className="reservation_box">
                                <div className="row">
                                  <div className="col-12 p-0">
                                    <div className="header">
                                      <h6>Reservation Details</h6>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Resident Name </label>
                                      <p>
                                        {
                                          this.state.reservationDetail
                                            .resident_name
                                        }
                                        {this.state.reservationDetail
                                          .resident_name != "" &&
                                          this.state.reservationDetail
                                            .resident_name != null &&
                                          this.state.reservationDetail
                                            .resident_name != undefined ? (
                                          <>
                                            {" "}
                                            <a
                                              href="#"
                                              onClick={() =>
                                                this.postThreadForResident()
                                              }>
                                              {/* <i className="typcn typcn-messages"></i> */}
                                              <img src={ChatIcon} alt="" />
                                            </a>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Unit Name </label>
                                      <p>
                                      {this.state.reservationDetail.unit_name}
                                      </p>
                                    </div>
                                  </div>                         
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Confirmation Code </label>
                                      <p>
                                        {
                                          this.state.reservationDetail
                                            .confirmation_code
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Channel </label>
                                      <p>
                                        {this.state.reservationDetail != undefined ? (
                                          <div className="channel_img">
                                            <figure>
                                              <img
                                                src={
                                                  this.state.reservationDetail.channel
                                                    ? this.state.reservationDetail
                                                      .channel
                                                    : ""
                                                }
                                                onError={({ currentTarget }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src = guestimg;
                                                }}
                                              />
                                            </figure>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Status </label>
                                      <p>
                                        {
                                          this.state.reservationDetail
                                            .status
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Booking Date </label>
                                      <p>
                                        {this.state.reservationDetail.created_at
                                          ? Moment(
                                            this.state.reservationDetail
                                              .created_at
                                          ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>
                                        {this.state.reservationDetail
                                          .no_of_guest == 1
                                          ? "No of Guest"
                                          : "No of Guests"}{" "}
                                      </label>
                                      <p>
                                        {this.state.reservationDetail.no_of_guest}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Adults </label>
                                      <p>
                                        {
                                          this.state.reservationDetail
                                            .adults
                                        }
                                      </p>
                                    </div>
                                  </div> 
                                </div>
                              </div>
                              <div className="reservation_box">
                                <div className="row">
                                  <div className="col-12 p-0">
                                    <div className="header">
                                    <h6> {this.state.thread_type == 1 ? "Guest" : "User"} details </h6>
                                    </div>
                                  </div>

                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>First Name</label>
                                      <p>
                                        {this.state.userInfo.first_name}
                                        
                                      </p>
                                    </div>
                                  </div>

                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Last Name</label>
                                      <p>
                                      {this.state.userInfo.last_name
                                          ? " " + this.state.userInfo.last_name
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-12">
                                    <div className="resr_info">
                                      <label>Email</label>
                                      <p>
                                      {this.state.userInfo.email}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Mobile</label>
                                      <p>
                                      {this.state.userInfo && this.state.userInfo.phone_number? 
                                       
                                        this.state.phone_number
                                       :""}
                                        {/* {this.state.userInfo && this.state.userInfo.phone_number ? (
                                          <PhoneNumberFormat
                                            phone_number={this.state.userInfo.phone_number}
                                            disableCountryCode
                                          />
                                        ) : (
                                          ""
                                        )} */}
                                      
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="reservation_box">
                                <div className="row">
                                  <div className="col-12 p-0">
                                    <div className="header">
                                    <h6>Arrival Instructions </h6>
                                    <a href="#" className="btn-success-outline btn_send_mail" onClick={() => this.openArrival()}>Show More</a>
                                    </div>
                                    
                                  </div>
                                        {this.state.reservationDetail.lockType == 2 ? "" : (<>  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Your Key Code</label>
                                      <p>
                                       {this.state.reservationDetail.keyCafeAccessCode}                                   
                                      </p>
                                    </div>
                                  </div></>)}
                                
                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Your Parking Slot</label>
                                      <p>
                                       {this.state.reservationDetail.parking_slot}                                   
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>  

                                 <div className="reservation_box">
                                <div className="row">
                                  <div className="col-12 p-0">
                                    <div className="header">
                                    <h6>House Manual </h6>
                                    <a href="#"  onClick={() => this.openHouse()} className="btn-success-outline btn_send_mail">Show More</a>
                                    </div>
                                  </div>

                                  <div className=" col-md-6 col-12">
                                    <div className="resr_info">
                                      <label>Wi-Fi Name</label>
                                      <p>
                                        {this.state.reservationDetail.wifiDetail}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" col-md-6 col-12 ">
                                    <div className="resr_info">
                                      <label>Wi-Fi Password</label>
                                      <p>
                                        {this.state.reservationDetail.wifiDetail}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>          
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.guestPaid != undefined ? (
                            <>
                              {" "}
                              <div className="guestpaid">
                                <div className="header">
                                  <h6>Price Details</h6>
                                </div>
                                <div className="mainContent">
                                  <ul>
                                    <li>
                                      <i>
                                        {this.state.guestPaid.nightly_rate
                                          ? "$" +
                                          this.state.guestPaid.nightly_rate
                                          : ""}{" "}
                                        x{" "}
                                        {this.state.guestPaid.total_stay_nights ==
                                          1
                                          ? this.state.guestPaid
                                            .total_stay_nights + " night"
                                          : this.state.guestPaid
                                            .total_stay_nights + " nights"}{" "}
                                      </i>
                                      <b>
                                        {this.state.guestPaid.total_nightly_rate
                                          ? "$" +
                                          this.state.guestPaid
                                            .total_nightly_rate
                                          : ""}
                                      </b>
                                    </li>

                                    <li>
                                      <i>Cleaning fee</i>
                                      <b>
                                        {this.state.guestPaid.cleaning_fee
                                          ? "$" +
                                          this.state.guestPaid.cleaning_fee
                                          : ""}
                                      </b>
                                    </li>

                                    <li>
                                      <i> Service fee </i>
                                      <b>
                                        {this.state.guestPaid.service_fee
                                          ? "$" + this.state.guestPaid.service_fee
                                          : ""}
                                      </b>
                                    </li>

                                    <li>
                                      <i>Occupancy taxes </i>
                                      <b>
                                        {this.state.guestPaid.occupancy_taxes
                                          ? "$" +
                                          this.state.guestPaid.occupancy_taxes
                                          : ""}
                                      </b>
                                    </li>
                                    <li>
                                      <i>Total (USD) </i>
                                      <b>
                                        {this.state.guestPaid.guest_paid
                                          ? "$" + this.state.guestPaid.guest_paid
                                          : ""}
                                      </b>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.reservationDetail != undefined ? (
                            <>
                              <div className="d-flex justify-content-center ">
                                <p className="p-0">
                                  <Link
                                    to={this.state.reservationDetail.resUrl}
                                    target="_blank">
                                    <button
                                      type="button"
                                      className="btn-success-outline btn_send_mail mt-3">
                                      View
                                    </button>
                                  </Link>
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>



                  </>): ""}

                  {this.state.defaultPageLoad == 1 ? <div className="col-lg-9 col-xs-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-5 pd-xs-r-5 message_empty_box">
                    <div className="empt_box">
                      <figure>
                        <img src={Messageemp} alt="" />
                      </figure>
                    </div>
                  </div> : ""}

                </div>
              </div>
            </div>
          </div>
        </Container>
        {this.state.isNewModelOpen === 1 ? <SelectRecipient isNewModelOpen={this.state.isNewModelOpen} isNewModelOpenUpdate={this.isNewModelOpenUpdate.bind(this)}
        newMessagAapiReasponce={this.newMessagAapiReasponce.bind(this)} propertySlug ={this.state.property_slug}
        
        ></SelectRecipient> : ""}

        {/* Add new contact modalbox */}
        <Modal
          show={this.state.showquickreplyModal}
          onHide={() => this.closeModal("showquickreplyModal")}
          className="showquickreplyModal model-lg_custome"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg">
          <Modal.Body>
            <div className="header">
              <h3>Quick Replies</h3>

              <span
                className="welcomeClose"
                onClick={() => this.closeModal("showquickreplyModal")}>
                {global.closee}
              </span>
            </div>
            {/* dropdown search user */}
            <div className="listSetction">
              <ul>
                {this.state.templateList != undefined &&
                  this.state.templateList.length > 0 ? (
                  this.state.templateList.map((temp, i) => {
                    return (
                      <li key={i}>
                        <div className="content">
                          <b>{temp.title}</b>
                          <p>{temp.message}</p>
                          <button
                            className="mx-auto m-0 btn btn-outline-light mr-0"
                            onClick={(e) => this.getEditedTemplate(temp)}>
                            Use this reply
                          </button>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <NoData />
                )}
              </ul>
            </div>
            {/* End dropdown search user */}
          </Modal.Body>
        </Modal>
        {/* End Add new contact modalbox */}
        {this.state.isFilterModel === 1 ?
          <MessageFilter
            isFilterModel={this.state.isFilterModel}
            isFilterModelUpdate={this.isFilterModelUpdate.bind(this)}
            customThreadfilter={this.customThreadfilter.bind(this)}
            filterString={this.state.filterString}
            property_slug={this.props.match != undefined && this.props.match.params.propertySlug ? this.props.match.params.propertySlug : ""}
          >
          </MessageFilter> : ""}

          <Modal
          show={this.state.isHouseOpen}
          onHide={() => this.closeHouse("isHouseOpen")}
          className="showquickreplyModal model-lg_custome"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg">
          <Modal.Body>
            <div className="header">
              <h3>House Manual</h3>

              <span
                className="welcomeClose"
                onClick={() => this.closeHouse("isHouseOpen")}>
                {global.closee}
              </span>
            </div>
            <div className="listSetction">
              <p>{this.state.reservationDetail ? renderHTML(this.state.reservationDetail.guestManual ? this.state.reservationDetail.guestManual : "") : ""}</p>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.isArrivalOpen}
          onHide={() => this.closeArrival("isArrivalOpen")}
          className="showquickreplyModal model-lg_custome msg_arrival_custom"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg">
          <Modal.Body>
            <div className="header">
              <h3>Arrival Instruction</h3>

              <span
                className="welcomeClose"
                onClick={() => this.closeArrival("isArrivalOpen")}>
                {global.closee}
              </span>
            </div>
            <div className="listSetction">
              {/* <p>{this.state.reservationDetail ? renderHTML(this.state.reservationDetail.arrivalInstruction ? this.state.reservationDetail.arrivalInstruction : "") : ""}</p> */}
            
              <HTMLEllipsis
                  unsafeHTML={
                    this.state.reservationDetail  && this.state.reservationDetail.arrivalInstruction ? this.state.reservationDetail.arrivalInstruction : ""
                  }
                  maxLine="5000"
                  ellipsis=""
                  trimright="true"
                  basedOn="letters"
                />
            </div>
          </Modal.Body>
        </Modal>


      </main>
    );
  }
}
export default MessageIndex;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: UnitsService.js                              //
//  Application: Units                                    //
//  Option: Used for manage Units                       //
//  Developer: Ashish Kumar						                     //
//  Date: 2022-02-03                                         //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class UnitsService {

  getUnitsList(queryString) {
    let endPoint = "unit" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getUnitsAvailabilityList(queryString) {
    let endPoint = "get-unit-availability" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getUnits(slug) {
    let endPoint = "unit/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createUnits(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "unit";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateUnits(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-unit/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteUnits(slug) {
    let endPoint = "unit/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadUnitsLogo(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'upload-unit-logo';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      'endPoint': endPoint,
      'addditionalHeaderData': addditionalHeaderData,
      'postData': postData
    }
    let response = ApiService.postCall(inputData);
    return response;
  }

  exportUnitsData(queryString) {
    let endPoint = "export-unit" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  //for avilability
  getUnitsAval(queryString) {
    let endPoint = "unit-availability" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  addUnitsAval(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "unit-availability";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getUnitAvalBySlug(slug) {
    let endPoint = 'unit-availability/' + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      'endPoint': endPoint,
      'addditionalHeaderData': addditionalHeaderData,
      'postData': postData
    }
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateUnitAval(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'unit-availability/' + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      'endPoint': endPoint,
      'addditionalHeaderData': addditionalHeaderData,
      'postData': postData
    }
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteUnitAval(slug) {
    let endPoint = 'unit-availability/' + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      'endPoint': endPoint,
      'addditionalHeaderData': addditionalHeaderData,
      'postData': postData
    }
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  exportDataFilewise(data) {
    let endPoint = "unit-export";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
        endPoint: endPoint,
        addditionalHeaderData: addditionalHeaderData,
        postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  importData(data) {
    let endPoint = "unit-import";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.uploadFileCall(inputData);
    return response;
  }

  
  getUnitEarningConfigurationData(queryString) {
    let endPoint = "unit-payout-configuration" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

}

export default new UnitsService();

////////////////////////////////////////////////////////////
//     							                          //
//  Program: AmenityType.jsx                            //
//  Application: Property                                   //
//  Option: to view Amenity Type data                   //
//  Developer: Ashish Kumar  			                  //
//  Date: 2022-01-05                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup, Tab, Nav } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import AmenityTypeService from "../../services/AmenityTypeService";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";
export class AmenityType extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      tab: "active",
      amnityTypeTabData: localStorage.getItem("amenity_type_tab_data") ? localStorage.getItem("amenity_type_tab_data") : "",
      //for Amenity type
      addAmenityTypeModal: false,
      editAmenityTypeModal: false,
      deleteAmenityTypeModal: false,
      amenityTypeList: [],
      amenityTypeListTotal: 0,
      amenity_type_name: "",
    };
    this.changeHandler = this.changeHandler.bind(this);

    /*for amenity type */
    this.openAddAmenityTypeModal = this.openAddAmenityTypeModal.bind(this);
    this.openEditAmenityTypeModal = this.openEditAmenityTypeModal.bind(this);
    this.openDeleteAmenityTypeModal =
      this.openDeleteAmenityTypeModal.bind(this);
    this.saveAmenityType = this.saveAmenityType.bind(this);
    this.getAmenityTypeDetails = this.getAmenityTypeDetails.bind(this);
    this.updateAmenityType = this.updateAmenityType.bind(this);
    this.deleteAmenityType = this.deleteAmenityType.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
  }

  componentDidMount() {
    switch (this.state.amnityTypeTabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
  }

  /* to get amenity type data */
  async getAmenityTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var amenityTypeList = [];
    var amenityTypeListTotal = 0;

    let res = await AmenityTypeService.getAmenityType(
      queryString ? queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      amenityTypeList = res.data.data ? res.data.data : [];
      amenityTypeListTotal = amenityTypeList ? amenityTypeList.length : 0;
    }
    this.setState({
      amenityTypeList: amenityTypeList,
      amenityTypeListTotal: amenityTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save amenity type data */
  saveAmenityType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.amenity_location_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        amenity_type_name: this.state.amenity_type_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await AmenityTypeService.createAmenityType(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddAmenityTypeModal();
        this.setState({
          amenity_type_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getAmenityTypeData("tab=" + this.state.tab);
      } else {
        // let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage:
        //     alertMessage !== "" ? alertMessage : res.data.errorMessage,
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get amenity type details data */
  async getAmenityTypeDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await AmenityTypeService.getAmenityTypeBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.amenity_type_name = res.data.amenity_type_name
          ? res.data.amenity_type_name
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update amenity type data */
  updateAmenityType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.amenity_location_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        amenity_type_name: this.state.amenity_type_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await AmenityTypeService.updateAmenityType(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditAmenityTypeModal();
        this.setState({
          amenity_type_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getAmenityTypeData("tab=" + this.state.tab);
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete amenity type data */
  async deleteAmenityType(slug) {
    this.setState({ showSpinner: true });
    let res = await AmenityTypeService.deleteAmenityType(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAmenityTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getAmenityTypeData("tab=" + this.state.tab);
    } else {
      this.closeDeleteAmenityTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getAmenityTypeData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "amenity_type_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getAmenityTypeData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "amenity_type_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* for amenity type modal */
  openAddAmenityTypeModal() {
    this.setState({ addAmenityTypeModal: true });
  }

  closeAddAmenityTypeModal() {
    this.setState({ addAmenityTypeModal: false });
    this.setState({
      amenity_type_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openEditAmenityTypeModal(slug) {
    this.getAmenityTypeDetails(slug);
    this.setState({ editAmenityTypeModal: true });
    this.setState({ slug: slug });
  }

  closeEditAmenityTypeModal() {
    this.setState({ editAmenityTypeModal: false });
    this.setState({
      slug: "",
      amenity_type_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openDeleteAmenityTypeModal(slug) {
    this.setState({ deleteAmenityTypeModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAmenityTypeModal() {
    this.setState({ deleteAmenityTypeModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Amenity Type";
  }

  render() {
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                            <Button
                              variant="outline-dark btn-block btn-with-icon"
                              onClick={this.openAddAmenityTypeModal}
                            >
                              <img src={pluswIcon} alt="plusIcon" /> Add
                            </Button>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="scrolling-carousel scroll-slide mg-lg-l-0 mg-xs-l-15">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="all"
                          onSelect={(e) => this.getActiveClass(e)}
                          eventKey={this.state.activeClass}
                          className="pd-r-60"
                        >
                          <ScrollingCarousel
                            rightArrow={true}
                            leftArrow={true}
                            className="swipesection p-0"
                          >
                            <Nav.Item>
                              <Nav.Link
                                eventKey="activeTab"
                                id="activeTab"
                                className={
                                  this.state.activeClass === "activeTab"
                                    ? "active"
                                    : ""
                                }
                              >
                                Active
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                              <Nav.Link
                                eventKey="inactiveTab"
                                id="inactiveTab"
                                className={
                                  this.state.activeClass === "inactiveTab"
                                    ? "active"
                                    : ""
                                }
                              >
                                Inactive
                              </Nav.Link>
                            </Nav.Item>
                          </ScrollingCarousel>
                          <Tab.Content>
                            <Tab.Pane eventKey="activeTab">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                  <div className="">
                                    <Table hover className="mg-b-0">
                                      <thead>
                                        <tr>
                                          <th className="w-50">Amenity Type Name</th>
                                          <th className="w-25">Status</th>
                                          {global.userPermissions.checkPermission(
                                            "master-data-delete"
                                          ) && <th className="text-center">Action</th>}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.amenityTypeListTotal > 0 ? (
                                          this.state.amenityTypeList.map((item, i) => {
                                            return (
                                              <tr key={i}>
                                                <td
                                                  className="hand-cursor"
                                                  onClick={() => {
                                                    global.userPermissions.checkPermission(
                                                      "master-data-delete"
                                                    ) &&
                                                      this.openEditAmenityTypeModal(
                                                        item.slug
                                                      );
                                                  }}
                                                >
                                                  {item.amenity_type_name}
                                                </td>
                                                <td>
                                                  {item.status_id === 1 ? (
                                                    <b className="active"></b>
                                                  ) : (
                                                    <b></b>
                                                  )}
                                                </td>
                                                {global.userPermissions.checkPermission(
                                                  "master-data-delete"
                                                ) && (
                                                    <td>
                                                      <div className="btn-icon-list d-block text-center">
                                                        <img
                                                          src={deleteicon}
                                                          alt="Delete"
                                                          onClick={() =>
                                                            this.openDeleteAmenityTypeModal(
                                                              item.slug
                                                            )
                                                          }
                                                          className="hand-cursor"
                                                        />
                                                      </div>
                                                    </td>
                                                  )}
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <tr>
                                            <td colSpan="9" align="center">
                                              There are no amenity type added in the system.
                                              You may add them by clicking Add button.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                ) : (
                                  <NoData msg="active amenity type" />
                                )}
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="inactiveTab">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                  <div className="">
                                    <Table hover className="mg-b-0">
                                      <thead>
                                        <tr>
                                          <th className="w-50">Amenity Type Name</th>
                                          <th className="w-25">Status</th>
                                          {global.userPermissions.checkPermission(
                                            "master-data-delete"
                                          ) && <th className="text-center">Action</th>}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.amenityTypeListTotal > 0 ? (
                                          this.state.amenityTypeList.map((item, i) => {
                                            return (
                                              <tr key={i}>
                                                <td
                                                  className="hand-cursor"
                                                  onClick={() => {
                                                    global.userPermissions.checkPermission(
                                                      "master-data-delete"
                                                    ) &&
                                                      this.openEditAmenityTypeModal(
                                                        item.slug
                                                      );
                                                  }}
                                                >
                                                  {item.amenity_type_name}
                                                </td>
                                                <td>
                                                  {item.status_id === 1 ? (
                                                    <b className="active"></b>
                                                  ) : (
                                                    <b></b>
                                                  )}
                                                </td>
                                                {global.userPermissions.checkPermission(
                                                  "master-data-delete"
                                                ) && (
                                                    <td>
                                                      <div className="btn-icon-list d-block text-center">
                                                        <img
                                                          src={deleteicon}
                                                          alt="Delete"
                                                          onClick={() =>
                                                            this.openDeleteAmenityTypeModal(
                                                              item.slug
                                                            )
                                                          }
                                                          className="hand-cursor"
                                                        />
                                                      </div>
                                                    </td>
                                                  )}
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <tr>
                                            <td colSpan="9" align="center">
                                              There are no amenity type added in the system.
                                              You may add them by clicking Add button.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                ) : (
                                  <NoData msg="inactive amenity type" />
                                )}
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add amenity type popup modal */}
        <Modal
          show={this.state.addAmenityTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddAmenityTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddAmenityTypeModal()}
            >
              {global.closee}
            </span>

            <h3 className="text-center">Add Amenity Type</h3>
            <label>Amenity Type Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="amenity_type_name"
              value={this.state.amenity_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Amenity Type Name"
            />
            {this.state.errors.amenity_type_name ? (
              <div className="text-danger">
                {this.state.errors.amenity_type_name}
              </div>
            ) : (
              this.validator.message(
                "amenity_type_name",
                this.state.amenity_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddAmenityTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveAmenityType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit amenity type popup modal */}
        <Modal
          show={this.state.editAmenityTypeModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditAmenityTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditAmenityTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Amenity Type</h3>
            <label>Amenity Type Name </label>
            <Form.Control
              onChange={this.changeHandler}
              name="amenity_type_name"
              value={this.state.amenity_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Amenity Type Name"
            />
            {this.state.errors.amenity_type_name ? (
              <div className="text-danger">
                {this.state.errors.amenity_type_name}
              </div>
            ) : (
              this.validator.message(
                "amenity_type_name",
                this.state.amenity_type_name,
                "required",
                { className: "text-danger" }
              )
            )}
            <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditAmenityTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.updateAmenityType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete amenity type popup modal */}
        <Modal
          show={this.state.deleteAmenityTypeModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteAmenityTypeModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteAmenityTypeModal()}
              >
                {global.closee}
              </span>

              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAmenityTypeModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteAmenityType(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default AmenityType;

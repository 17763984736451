///////////////////////////////////////////////
//     							             //
//  Program: AppPropertySettingService.js                 //
//  Application: Services                    //
//  Option: Used for AppPropertySetting data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-02-09                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class AppPropertySettingService {

    getAppPropertySettingByPropertySlug(slug) {
        let endPoint = "get-app-property-setting/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateAppPropertySetting(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "update-app-property-setting";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    uploadAppPropertyLogo(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "upload-app-property-logo";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    uploadAppPropertyMainImage(data) {
        delete data.errors;
        delete data.input;

        let endPoint = "upload-app-property-main-image";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    uploadProfileCoverImage(data) {
        delete data.errors;
        delete data.input;

        let endPoint = "upload-profile-cover-image";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

}

export default new AppPropertySettingService();

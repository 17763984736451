////////////////////////////////////////////////////////////
//     							                          //
//  Program: WidgetService.js                            //
//  Application: Services                                 //
//  Option: to view widgets count                       //
//  Developer: Kavita singh						         //
//  Date: 2023-03-03                                     //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class WidgetService {
  // get widget list count by property slug
  getWidgetListCountBySlug(slug) {
    let endPoint = "get-widget-info/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // get widget list count without property slug
  getWidgetListCount() {
    let endPoint = "get-widget-info";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new WidgetService();

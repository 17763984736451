///////////////////////////////////////////////
//     							             //
//  Program: FaqService.js                 //
//  Application: Services                    //
//  Option: Used for Applicants data              //
//  Developer: Saurabh Shukla                 //
//  Date: 2022-02-09                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class FaqService {
    getFaq(queryString) {
        let endPoint = "knowledge-base" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    getFaqBySlug(slug) {
        let endPoint = "knowledge-base/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateFaq(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "knowledge-base/" + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.putCall(inputData);
        return response;
    }

    createFaq(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "knowledge-base";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    deleteFaq(slug) {
        let endPoint = "knowledge-base/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
    }
    getKbCategory(queryString) {
        let endPoint = "kb-category" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    getKbSubCategory(queryString) {
        let endPoint = "kb-sub-category" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
       
        let response = ApiService.getCall(inputData);
        return response;
    }
}

export default new FaqService();

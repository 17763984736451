//     							                          //
//  Program: GuestSidebar.jsx                             //
//  Application: Property                                 //
//  Option: common component Guest Sidebar                //
//  Developer: NP 		                                  //
//  Date: 2022-04-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";

class GuestSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug") ? sessionStorage.getItem("property_slug") : "",
    };
  }

  isPathActive(path){
    return window.location.pathname.includes(path);
  }

  render() {
    return (
        <nav className="nav nav-pills flex-column pd-r-8">
                <ul>
                    <li>
                        <ul>
                          <li>
                            <Link
                                className={
                                  this.isPathActive("guests")
                                  ? "nav-link active"
                                  : "nav-link"
                                }
                                data-toggle="tab"
                                to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/guests"}
                            >
                                Guests
                            </Link>
                            </li>
                            {/* <li>
                            <Link
                                className={
                                  this.isPathActive("requests")
                                  ? "nav-link active"
                                  : "nav-link"
                                }
                                data-toggle="tab"
                                to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/requests"}
                            >
                                Requests
                            </Link>
                            </li> */}
                            {/* <li>
                            <Link
                                className={
                                  this.isPathActive("maintenance-request")
                                  ? "nav-link active"
                                  : "nav-link"
                                }
                                data-toggle="tab"
                                to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/maintenance-request"}
                            >
                                Maintenance
                            </Link>
                            </li>
                            <li>
                            <Link
                                className={
                                  this.isPathActive("guest-chat")
                                  ? "nav-link active"
                                  : "nav-link"
                                }
                                data-toggle="tab"
                                to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/guest-chat"}
                            >
                                Messages
                            </Link>
                            </li> */}
                            {/* <li>
                            <Link
                                className={
                                  this.isPathActive("checklists")
                                  ? "nav-link active"
                                  : "nav-link"
                                }
                                data-toggle="tab"
                                to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/checklists"}
                            >
                                Housekeeping
                            </Link>
                            </li> */}
                        </ul>
                    </li>
                </ul>
        </nav>
    );
  }
}
export default GuestSidebar;
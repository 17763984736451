////////////////////////////////////////////////////////////
//     							                                      //
//  Program: CostPayableAtService.js                      //
//  Application: CostPayableAt                            //
//  Option: Used for manage Cost Payable AT               //
//  Developer: Ashish Kumar  						                  //
//  Date: 2022-01-06                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class CostPayableAtService {
  getCostPayableAt(queryString) {
    let endPoint = "cost-payable-at"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createCostPayableAt(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "cost-payable-at";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getCostPayableAtBySlug(slug) {
    let endPoint = "cost-payable-at/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateCostPayableAt(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "cost-payable-at/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteCostPayableAt(slug) {
    let endPoint = "cost-payable-at/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new CostPayableAtService();

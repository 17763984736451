////////////////////////////////////////////////////////////
//     							                                      //
//  Program: UnitsDocForm.jsx                   //
//  Application:  Unit Documents                      //
//  Option: For add or update Unit Documents         //
//  Developer: Ashish Kumar                            //
//  Date: 2022-01-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, Card, Accordion, Button ,InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import MultiImageService from "../../services/MultiImageService";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import Alerts from "../common/Alerts";
import FileWidget from "../fileUploader/FileWidget";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MasterDataService from "../../services/MasterDataService";
import propDoc from "../../assets/images/doc_placeholder.png";
// import pluswIcon from "./../../assets/images/plus.svg";
// import infosize from "../../assets/images/Info.svg";
import BackButton from "../../assets/images/back-button.svg";

export class UnitsDocForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      unit_slug: this.props.match.params.unitSlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      documentTypeList: [],
      documentTypeListTotal: 0,
      document_type_slug: "",
      display_file_name: "",
      media_type: "",
      file_name: "",
      description: "",
      sort_order: "",
      open: true,
      eventKey: "3",
      imgUrl: propDoc,
      pathname: window.location.pathname,
      deletedFiles: [],
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
    };
    this.propDoc = React.createRef();
    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdatePropertyDocuments =
      this.saveOrUpdatePropertyDocuments.bind(this);
  }

  async componentDidMount() {
    this.getDocumentTypeData();
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let inputData = {
        path: "units",
        refTableId: 5,
        slug: this.state.unit_slug,
        mediaType: 2,
        docsSlug: this.state.slug,
      };
      let res = await MultiImageService.getPropertyDocumentsBySlug(inputData);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.display_file_name = res.data.display_file_name
          ? res.data.display_file_name
          : "";
        resData.document_type_slug = res.data.document_type_data
          ? res.data.document_type_data.slug
          : "";
        resData.file_name = res.data.file_name ? res.data.file_name : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get document type data */
  async getDocumentTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var documentTypeList = [];
    var documentTypeListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=document_type",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      documentTypeList = res.data ? res.data : [];
      documentTypeListTotal = documentTypeList ? documentTypeList.length : 0;
    }

    this.setState({
      documentTypeList: documentTypeList,
      documentTypeListTotal: documentTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdatePropertyDocuments = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun("units_form");
      let inputData = {
        display_file_name: this.state.display_file_name
          ? this.state.display_file_name
          : "",
        document_type_slug: this.state.document_type_slug
          ? this.state.document_type_slug
          : "",
        file_name: this.state.file_name ? this.state.file_name : "",
        description: this.state.description ? this.state.description : "",
        media_type: 2,
        ref_id: this.state.property_slug ? this.state.property_slug : "",
        sort_order: this.state.sort_order ? this.state.sort_order : "",
        status_id: this.state.status_id === true ? 1 : 2,
        availableFiles: output.response,
        deletedFiles: this.state.deletedFileList,
        docsSlug: this.state.slug ? this.state.slug : "",
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await MultiImageService.updateMultiImage(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/units/view/" +
                  this.state.unit_slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update Unit Documents";
    } else {
      return "Add Unit Documents";
    }
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          <div className="row row-xs">
            <div className="col-md-10 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link to="/properties">Properties</Link>
                <span> / </span>
                <Link
                  to={
                    "/properties/view/" +
                    this.state.property_slug +
                    "/documents"
                  }
                >
                  PropertyDocuments
                </Link>
                <span> / </span>
                <span>{this.getTitle()}</span>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <p className="d-flex justify-content-end">
                <span className="back-parent d-flex align-items-center">
                  <Link
                    to={
                      "/properties/view/" +
                      this.state.property_slug +
                      "/documents"
                    }
                  >
                    <img src={BackButton} alt="" className="back-button" />
                  </Link>
                  <Link
                    to={
                      "/properties/view/" +
                      this.state.property_slug +
                      "/documents"
                    }
                  >
                    Back
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </Container>

        <Container>
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-15 pd-t-4">
              <div className="col-md-3">
                <aside>
                  <div className="topHead">
                    <div className="demo-avatar-group">
                      <div className="az-img-user avatar-md online">
                        <img
                          src={
                            sessionStorage.getItem("property_logo_url")
                              ? sessionStorage.getItem("property_logo_url")
                              : propertyLogo
                          }
                          assName="rounded-circle"
                          alt=""
                        />
                      </div>
                      <div className="propname">
                        <h5>
                          {sessionStorage.getItem("property_name")
                            ? sessionStorage.getItem("property_name")
                            : "Property"}
                        </h5>
                        <ul>
                          <li>
                            {sessionStorage.getItem("property_type")
                              ? sessionStorage.getItem("property_type")
                              : ""}
                          </li>
                          {sessionStorage.getItem("year_value") ? (
                            <li></li>
                          ) : (
                            ""
                          )}
                          <li>
                            {sessionStorage.getItem("year_value")
                              ? "Year " + sessionStorage.getItem("year_value")
                              : ""}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <Accordion defaultActiveKey="3">
                      <Card onClick={() => this.setOpen(!this.state.open, "0")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Property Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                              this.state.eventKey === "0" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse
                          eventKey="0"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${
                                this.state.pathname.match("/basic")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic"
                              }
                            >
                              Property
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/tower")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              }
                            >
                              Towers
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/floors")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floors"
                              }
                            >
                              Floors
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/amenitites")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/amenitites"
                              }
                            >
                              Common Amenities
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/pet-policy")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                              }
                            >
                              Pet Policy
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/staff")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                            >
                              Staff
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/documents")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/documents"
                              }
                            >
                              Documents & Leases
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/property-level-tax")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              }
                            >
                              Taxes
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/nearby-attractions")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/nearby-attractions"
                              }
                            >
                              Near By Attractions
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/events")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                              }
                            >
                              Events
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card onClick={() => this.setOpen(!this.state.open, "3")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Units Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                              this.state.eventKey === "3" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="3"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${
                                this.state.pathname.match("/floor-plans")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floor-plans"
                              }
                            >
                              Floor Plans
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/units")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units"
                              }
                            >
                              Units
                            </Link>
                            {/* <Link
                              className={`${
                                this.state.pathname.match("/rooms")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/rooms"
                              }
                            >
                              Rooms
                            </Link> */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </nav>
                </aside>
              </div>
              <div className="col-md-9 ">
                <div className="scrolling-carousel pd-l-15">
                  <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2">
                    <h3>{this.getTitle()}</h3>
                  </div>

                  <form id="units_form">
                    <div className="dashboardRightcard">
                      <div className="d-flex justify-content-end">
                        <Link
                          to={
                            "/properties/view/" +
                            this.state.property_slug +
                            "/documents"
                          }
                        >
                          <button
                            type="button"
                            className="btn-success-outline-small"
                          >
                            Cancel
                          </button>
                        </Link>

                        <button
                          className="btn-success ml-3"
                          type="button"
                          onClick={this.saveOrUpdatePropertyDocuments}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          {this.state.isSubmit
                            ? global.loader
                            : this.state.slug !== undefined
                            ? "Save"
                            : "Save"}
                        </button>
                      </div>
                      <div className="col-md-12 pl-0 pr-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0">
                        <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                          <div className="col-md-3 pl-0">
                            <label className="form-label text-left mg-b-0">
                              Name
                            </label>
                          </div>
                          <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                            <Form.Control
                              onChange={this.changeHandler}
                              name="display_file_name"
                              value={this.state.display_file_name}
                              className="form-control max_width_100"
                              type="text"
                            />
                            {this.state.errors.display_file_name ? (
                              <div className="text-danger">
                                {this.state.errors.display_file_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "name",
                                this.state.display_file_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                        <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                          <div className="col-md-3 pl-0">
                            <label className="form-label text-left mg-b-0">
                              Property Documents Type{" "}
                            </label>
                          </div>
                          <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                            <select
                              onChange={this.changeHandler}
                              name="document_type_slug"
                              value={this.state.document_type_slug}
                            >
                              <option key="" value="">
                                Select Property Documents Type
                              </option>

                              {this.state.documentTypeList.map((option) => (
                                <option
                                  className="form-control"
                                  key={option.slug}
                                  value={option.slug}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            {this.state.errors.document_type_slug ? (
                              <div className="text-danger">
                                {this.state.errors.document_type_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "document_type",
                                this.state.document_type_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                        <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                          <div className="col-md-3 pl-0">
                            <label className="form-label text-left mg-b-0">
                              Document
                            </label>
                          </div>
                          <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                            <FileWidget
                              fieldName="file_name"
                              fieldTitle="Select Document"
                              checkValidation="true"
                              allowedType="image/jpg,image/png,application/pdf,application/doc,application/xlsx"
                              maxFileSize={1000}
                              maxFileSizeType="mb"
                              maxFileAllowed={10}
                              uploadOn="s3"
                              slug={this.state.unit_slug}
                              refTableId="5"
                              displayFileName={this.state.display_file_name}
                              documentTypeSlug={this.state.document_type_slug}
                              description={this.state.description}
                              mediaType="2"
                              sortOrder={
                                this.state.sort_order !== ""
                                  ? this.state.sort_order
                                  : 0
                              }
                              statusId={this.state.status_id === true ? 1 : 2}
                              helpText="to download sample file."
                              sampleFileUrl="/sample.jpg"
                              drag="false"
                              dragText=""
                              inputClassName="form-control"
                              labelClassName="form-label"
                              formID="units_form"
                              uploadType="single"
                              resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                              propertySlug={""}
                              destinationFolder="units"
                              uploadedFiles={this.state.profile_image2}
                              deletedFiles={this.state.deletedFileList}
                              acceptOnly="image/jpg,image/png,application/pdf,application/doc,application/xlsx"
                              displayPreviewStyle="vertical"
                            />
                            {/* {this.state.errors.file_name ? (
                              <div className="text-danger">
                                {this.state.errors.file_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "file_name",
                                this.state.file_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )} */}
                          </div>
                          {this.state.slug !== undefined ? (
                            <a
                              href={this.state.file_name}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View File
                            </a>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                          <div className="col-md-3 pl-0"></div>
                          <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                            <InputGroup className="mt-3">
                              <label className="form-label text-left mg-b-0">
                                Description
                              </label>

                              <Form.Control
                                onChange={this.changeHandler}
                                name="description"
                                value={this.state.description}
                                className="form-control max_width_100"
                                id="description"
                                as="textarea"
                                rows={global.textareaRowLength}
                                // placeholder="Username"
                              />
                            </InputGroup>
                          </div>
                        </div>

                        {/* <div className="row row-xs align-items-baseline pd-t-30 pd-b-30">
                          <div className="col-md-3 pl-0">
                            <label className="form-label text-left mg-b-0">
                              Sort Order
                            </label>
                          </div>
                          <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                            <Form.Control
                              onChange={this.changeHandler}
                              name="sort_order"
                              value={this.state.sort_order}
                              onKeyPress={
                                global.onKeyPressEvent.numberValidation
                              }
                              className="form-control max_width_100"
                              type="text"
                              // placeholder="Sort Order"
                            />
                            <span className="d-inline-flex align-items-start justify-content-start infosize">
                              <img src={infosize} className="mg-r-5" />
                              <p>Sort Order will use for listing sequence.</p>
                            </span>
                            {this.state.errors.sort_order ? (
                              <div className="text-danger">
                                {this.state.errors.sort_order}
                              </div>
                            ) : (
                              this.validator.message(
                                "sort_order",
                                this.state.sort_order,
                                "numeric|min:0,num",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div> */}

                        <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                          <div className="col-md-3 pl-0">
                            <label className="form-label text-left mg-b-0">
                              Status
                            </label>
                          </div>
                          <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                            <div className="d-flex align-items-center justify-content-start mt-3">
                              <div
                                className={
                                  this.state.status_id
                                    ? "az-toggle on"
                                    : "az-toggle"
                                }
                                onClick={() =>
                                  this.setState({
                                    status_id: !this.state.status_id,
                                  })
                                }
                              >
                                <span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="ht-50"></div>
          </div>
        </Container>
      </main>
    );
  }
}

export default UnitsDocForm;

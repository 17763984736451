import React, { Component } from "react";
import { Container, Tab, Nav, Table, Modal } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import GalleryProperty from "react-grid-gallery";
import NoData from "../common/NoData";
import ProblemService from "../../services/ProblemService";
import { Link } from "react-router-dom";
import DownloadImg from "../../assets/images/download_svg.svg";
import MultiImageService from "../../services/MultiImageService";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Moment from "moment";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import ReservationService from "../../services/ReservationService";
import AllPropertiesCleaningSidebar from "../common/AllPropertiesCleaningSidebar";
import renderHTML from "react-render-html";
export class HouseKeepingView extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      slug: this.props.match.params.slug,
      isSubmit: false,
      sort_order: "",
      title: "",
      note: "",
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      due_date: "",
      activeClass: "post_cleaning",
      isRefresh: false,
      mroNumber: "",
      createdBy: "",
      createdAt: "",
      inspectionNo: "",
      updatedBy: "",
      updatedAt: "",
      imageData: [],
      mroImages: [],
      deletedFileList: [],
      mroCategoryListTotal: 0,
      mroList: [],
      editChat: 0,
      chatUpdate: "",
      setRadioValue: 2,
      files_data: [],
      gallery_img: [],
      unitName: "",
      unitMappedId: "",
      resultData: "",
      reservationDetailUrl: "",
      confirmation_code: "",
      cleanerName: "",
      checklist: "",
      checklistItems: "",
      checklistItemsLength: 0,
      tbnb_project_id: "",
      tbnb_problems: "",
      tbnb_problems_length: 0,
      residentDetailUrl: "",
      deleteHouseKeepingModal: false,
      cleaning_type: 0,
      summary: "",
      description: "",
      status_display: "",
      keycafe_ref_table_id: "",
      booking_code: "",
      timeTaken: "",
      cleaning_method: 1,
    };
    this.initialState = this.state;
    this.defaultData = {};
    this.openDeleteHouseKeepingModal =
      this.openDeleteHouseKeepingModal.bind(this);
  }

  componentDidMount() {
    document.getElementById("post_cleaning").click();
    this.getCleaningImages();
    // this.getChecklist();
  }

  getActiveClass(e) {
    if (e === "notes") {
      this.setState({ isRefresh: true });
    } else {
      this.setState({ isRefresh: false });
    }
    this.setState({ activeClass: e });
  }

  async getCleaningImages() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await ProblemService.getCleaning(this.state.slug);
      var multiImageList = [];
      var multiImageListTotalPro = 0;
      if (global.successStatus.includes(res.status)) {
        resData.mroSlug = res.data ? res.data.slug : "";
        resData.title = res.data ? res.data.title : "";
        resData.note = res.data ? res.data.note : "";
        this.setState({ resultData: res.data });
        // this.state.resultData.tbnb_project.project_id
        var cleaningType = res.data.cleaning_type ? res.data.cleaning_type : 0;
        var summary = res.data.summary ? res.data.summary : "";
        var description = res.data.description ? res.data.description : 0;
        var status_display = res.data.status_display
          ? res.data.status_display
          : "";
        var keycafe_ref_table_id = res.data.keycafe_ref_table_id
          ? res.data.keycafe_ref_table_id
          : "";
        var booking_code = res.data.booking_code ? res.data.booking_code : "";
        var timeTaken = res.data.timeTaken ? res.data.timeTaken : "";
        this.setState({
          cleaning_type: cleaningType,
          summary: summary,
          description: description,
          status_display: status_display,
          keycafe_ref_table_id: keycafe_ref_table_id,
          booking_code: booking_code,
          timeTaken: timeTaken,
        });

        if (
          this.state.resultData &&
          this.state.resultData.reservation &&
          this.state.resultData.ref_table_id == 12
        ) {
          var resUrl =
            "/properties/view/" +
            this.state.property_slug +
            "/reservations/view/" +
            this.state.resultData.reservation.slug;
          var confirmationCode =
            this.state.resultData.reservation.confirmation_code;
          this.setState({
            reservationDetailUrl: resUrl,
            confirmation_code: confirmationCode,
          });
        } else {
          var residentUrl =
            "/properties/view/" +
            this.state.property_slug +
            "/listing/" +
            (this.state.resultData.listing_unavailable &&
            this.state.resultData.listing_unavailable.listing &&
            this.state.resultData.listing_unavailable.listing.slug
              ? this.state.resultData.listing_unavailable.listing.slug +
                "/info?request_from=cleaning"
              : "");
          this.setState({
            residentDetailUrl: residentUrl,
          });
        }
        var unit_name;
        if (res.data.ref_table_id == 12) {
          if (res.data.reservation.unit) {
            unit_name = res.data.reservation.unit.unit_name
              ? res.data.reservation.unit.unit_name
              : res.data.reservation.unit.unit_number;
          }
        } else {
          if (
            res.data.listing_unavailable &&
            res.data.listing_unavailable.unit &&
            res.data.ref_table_id == 10
          ) {
            unit_name = res.data.listing_unavailable.unit.unit_name
              ? res.data.listing_unavailable.unit.unit_name
              : res.data.listing_unavailable.unit.unit_number;
          } else {
            unit_name = res.data.unit_name
              ? res.data.unit_name
              : res.data.unit_number;
          }
        }

        var cleaner_name;
        var resident_name;
        if (res.data.tbnb_project) {
          cleaner_name =
            res.data.tbnb_project.contractor_first_name &&
            res.data.tbnb_project.contractor_last_name
              ? res.data.tbnb_project.contractor_first_name +
                " " +
                res.data.tbnb_project.contractor_last_name
              : "";
          resident_name =
            res.data.tbnb_project.resident_name
              ? res.data.tbnb_project.resident_name
              : "Concierge";
          this.setState({ tbnb_project_id: res.data.tbnb_project.project_id });
        }

        var mapped_unit;
        if (res.data.mapped_unit) {
          mapped_unit = res.data.mapped_unit ? res.data.mapped_unit.key_id : "";
        }
        resData.gallery_img = res.data.cleaning_images
          ? res.data.cleaning_images
          : "";

        multiImageList = res.data.cleaning_images
          ? res.data.cleaning_images
          : [];
        multiImageListTotalPro = multiImageList.length;
        resData.cleaning_method = res.data.cleaning_method
          ? res.data.cleaning_method
          : 0;
        this.getMROData();
      }
      multiImageList.map((opt, i) => {
        var orgFile = opt.uri;
        var mediumImg = opt.uri;
        var fileName = "cleaning_image_" + i;
        multiImageList[i]["src"] = orgFile;
        multiImageList[i]["thumbnail"] = mediumImg;
        multiImageList[i]["main_image"] = orgFile;
        multiImageList[i]["tags"] = [
          {
            value: (
              <img
                src={DownloadImg}
                className="problem_img_new"
                alt=""
                onClick={() => this.getDownloadFile(orgFile, fileName)}
              />
            ),
            key: `download image ${i}`,
            title: `Download Image`,
          },
        ];
      });
      const newMultiImageListPro = multiImageList;
      this.setState({
        multiImageList: multiImageList,
        multiImageListTotalPro: multiImageListTotalPro,
        newMultiImageListPro: newMultiImageListPro,
        unitName: unit_name,
        unitMappedId: mapped_unit,
        cleanerName: cleaner_name,
        resident_name: resident_name,
      });
      this.setState(resData);
      this.setState({ input: resData });
      this.defaultData = resData;
    }
    this.setState({ loading: false });
    this.getChecklist();
  }

  async getChecklist() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resChecklist = {};
      var resChecklistItems = {};
      var checklistItemsLength = 0;
      let result = await ProblemService.getChecklist(this.state.slug);
      if (global.successStatus.includes(result.status)) {
        resChecklist = result.data ? result.data : "";
        resChecklistItems =
          this.state.cleaning_method === 2
            ? result.data.checklist_items
              ? result.data.checklist_items
              : ""
            : resChecklist;
        checklistItemsLength = resChecklistItems ? resChecklistItems.length : 0;
        this.setState({
          checklist: resChecklist,
          checklistItems: resChecklistItems,
          checklistItemsLength: checklistItemsLength,
        });
      }
    }
    this.setState({ loading: false });
  }

  async getMROData() {
    this.setState({ loading: true });
    if (
      this.state.tbnb_project_id !== undefined &&
      this.state.tbnb_project_id !== ""
    ) {
      let res = await ProblemService.getBySlug(this.state.tbnb_project_id);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          tbnb_problems: res.data.result,
          tbnb_problems_length: res.data.result.length,
        });
      }
      this.setState({ loading: false });
    }
  }

  async deleteHouseKeeping(slug) {
    this.setState({ showSpinner: true });
    let res = await ReservationService.deleteHouseCleaning(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeHouseKeepingModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" +
                  this.state.property_slug +
                  "/housekeeping-list"
              : "/housekeeping-list"
          ),
        global.redirect_time
      );
    } else {
      this.closeHouseKeepingModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({
      [event.target.name]: value,
      input: input,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    if (event.target.name === "comment") {
      this.setState({ errors: {} });
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  openDeleteHouseKeepingModal(slug) {
    this.setState({ deleteHouseKeepingModal: true });
    this.setState({ slug: slug });
  }

  closeHouseKeepingModal() {
    this.setState({ deleteHouseKeepingModal: false });
    this.setState({ slug: "" });
  }

  getTitle() {
    return "Cleaning";
  }
  /*file download */
  async getDownloadFile(url, name) {
    let file_name = name;
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }
  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <HouseKeepingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <AllPropertiesCleaningSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>

                    {this.state.cleaning_type === 3 ? (
                      <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
                        <span
                          onClick={() => {
                            this.openDeleteHouseKeepingModal(this.state.slug);
                          }}
                          className="deleteicon mg-r-20"
                        >
                          <img src={deleteicon} alt="" />
                        </span>
                        <button
                          type="button"
                          className="btn-success-outline-small"
                          onClick={() => {
                            this.props.history.push(
                              this.props.match.params.propertySlug !== undefined
                                ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/housekeeping-list"
                                : "/housekeeping-list"
                            );
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          this.props.history.push(
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                  this.state.property_slug +
                                  "/housekeeping-list"
                              : "/housekeeping-list"
                          );
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15 pd-r-15 scroll-slide">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="today"
                    onSelect={(e) => this.getActiveClass(e)}
                    eventKey={this.state.activeClass}
                    className="pd-r-60"
                  >
                    <ScrollingCarousel
                      rightArrow={true}
                      leftArrow={true}
                      className="swipesection scroll_nav_mob"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="post_cleaning"
                          id="post_cleaning"
                          className={
                            this.state.activeClass === "post_cleaning"
                              ? "active"
                              : ""
                          }
                        >
                          Post Cleaning
                        </Nav.Link>
                      </Nav.Item>
                      {global.userPermissions.checkPermission(
                        "maintenance-requests-list"
                      ) &&
                        this.state.cleaning_method === 2 && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="problems"
                              id="problems"
                              className={
                                this.state.activeClass === "problems"
                                  ? "active"
                                  : ""
                              }
                            >
                              Problems
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      <Nav.Item>
                        <Nav.Link
                          eventKey="checklist"
                          id="checklist"
                          className={
                            this.state.activeClass === "checklist"
                              ? "active"
                              : ""
                          }
                        >
                          Checklist
                        </Nav.Link>
                      </Nav.Item>
                    </ScrollingCarousel>
                    <Tab.Content>
                      <Tab.Pane eventKey="post_cleaning">
                        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                          <div className="col-md-12 pl-0 pr-0">
                            <div className="scrolling-carousel pd-l-15">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0">
                                  <Alerts
                                    show={this.state.showAlertModal}
                                    type={this.state.alertModalType}
                                    title={this.state.alertModalTitle}
                                    message={this.state.alertModalMessage}
                                  />
                                </div>
                                <div className="mg-t-20">
                                  <div className="row align-items-basline pd-b-0 border-bottom-0 pd-r-15">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                      <p className="az-profile-name-text pl-0">
                                        Unit{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.unitName}
                                      </p>
                                    </div>

                                    {this.state.reservationDetailUrl &&
                                    this.state.cleaning_type == 1 ? (
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                        <p className="az-profile-name-text pl-0">
                                          Reservation Confirmation Code{" "}
                                        </p>
                                        <Link
                                          to={this.state.reservationDetailUrl}
                                          target="_blank"
                                          className="hand-cursor showmore"
                                        >
                                          {this.state.confirmation_code}
                                        </Link>
                                      </div>
                                    ) : this.state.cleaning_type == 2 ? (
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                        <p className="az-profile-name-text pl-0">
                                          Resident Stay{" "}
                                        </p>
                                        <Link
                                          to={this.state.residentDetailUrl}
                                          target="_blank"
                                          className="hand-cursor showmore"
                                        >
                                          Show Details
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail"></div>
                                    )}
                                    {this.state.cleaning_method === 2 ? (
                                      <>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Project No.{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.resultData &&
                                            this.state.resultData.tbnb_project
                                              ? this.state.resultData
                                                  .tbnb_project.project_id
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Reported By{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.cleanerName
                                              ? this.state.cleanerName
                                              : ""}
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                      <p className="az-profile-name-text pl-0">
                                        Schedule Start{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.resultData &&
                                        this.state.resultData.started_on
                                          ? Moment(
                                              this.state.resultData.started_on
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                      <p className="az-profile-name-text pl-0">
                                        Schedule Completion{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.resultData &&
                                        this.state.resultData.completed_on
                                          ? Moment(
                                              this.state.resultData.completed_on
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                    {this.state.cleaning_method === 2 ? (
                                      <>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Actual Start{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.resultData &&
                                            this.state.resultData
                                              .tbnb_project &&
                                            this.state.resultData.tbnb_project
                                              .asign_started_at
                                              ? Moment(
                                                  this.state.resultData
                                                    .tbnb_project
                                                    .asign_started_at
                                                ).format(global.dateTimeFormat)
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Actual Completion{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.resultData &&
                                            this.state.resultData
                                              .tbnb_project &&
                                            this.state.resultData.tbnb_project
                                              .asign_completed_at
                                              ? Moment(
                                                  this.state.resultData
                                                    .tbnb_project
                                                    .asign_completed_at
                                                ).format(global.dateTimeFormat)
                                              : ""}
                                          </p>
                                        </div>

                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Cleaner Name{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.cleanerName
                                              ? this.state.cleanerName
                                              : ""}
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                      <p className="az-profile-name-text pl-0">
                                        Cleaning Type{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.cleaning_type == 1
                                          ? "Reservation"
                                          : this.state.cleaning_type == 2
                                          ? "Resident"
                                          : this.state.cleaning_type == 3
                                          ? "Direct"
                                          : ""}
                                      </p>
                                    </div>
                                    {this.state.cleaning_method === 2 ? (
                                      <>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Cleaning Access Code{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.keycafe_ref_table_id ==
                                            2
                                              ? this.state.booking_code
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Time Taken{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.timeTaken &&
                                            this.state.timeTaken !== null
                                              ? this.state.timeTaken
                                              : ""}
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                      <p className="az-profile-name-text pl-0">
                                        Cleaning Status{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.status_display
                                          ? this.state.status_display
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                      <p className="az-profile-name-text pl-0">
                                        Private Notes{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.summary
                                          ? this.state.summary
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                      <p className="az-profile-name-text pl-0">
                                        Notes To Teammate{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.description
                                          ? this.state.description
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                      <p className="az-profile-name-text pl-0">
                                        Account Type{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.resident_name
                                          ? this.state.resident_name
                                          : "Concierge"}
                                      </p>
                                    </div>
                                    {/* // */}
                                    {this.state.cleaning_method === 2 ? (
                                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 ">
                                        <p className="az-profile-name-text pl-0 mg-l-0">
                                          Cleaning Images{" "}
                                        </p>
                                        <React.Fragment>
                                          <div className="row row-xs align-items-center justify-content-center pd-t-30 pd-b-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-0 mg-l-0- addmultiImages ml-0- cleaingimage">
                                            {this.state.multiImageListTotalPro >
                                            0 ? (
                                              <React.Fragment className="pl-0">
                                                <GalleryProperty
                                                  backdropClosesModal={false}
                                                  preloadNextImage={true}
                                                  images={
                                                    this.state
                                                      .newMultiImageListPro
                                                      ? this.state
                                                          .newMultiImageListPro
                                                      : ""
                                                  }
                                                  enableImageSelection={false}
                                                  showImageCount={false}
                                                />
                                              </React.Fragment>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </React.Fragment>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="problems">
                        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                          <div className="col-md-12 pl-0 pr-0">
                            <div className="scrolling-carousel pd-l-15">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0">
                                  <Alerts
                                    show={this.state.showAlertModal}
                                    type={this.state.alertModalType}
                                    title={this.state.alertModalTitle}
                                    message={this.state.alertModalMessage}
                                  />
                                </div>
                                <div className="mg-t-20 cleaningpastview">
                                  <div className="row align-items-basline pd-b-0 border-bottom-0 pd-r-15">
                                    {this.state.tbnb_problems_length > 0
                                      ? this.state.tbnb_problems.map(
                                          (items, index) => {
                                            var multiImageList = items.images
                                              ? items.images
                                              : [];
                                            return (
                                              <>
                                                <div className="problemsection">
                                                  <div className="row mg-back-30">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                                      <p className="az-profile-name-text pl-0">
                                                        Issue{" "}
                                                      </p>
                                                      <p className="media-body">
                                                        {items.title
                                                          ? items.title
                                                          : ""}
                                                      </p>
                                                    </div>

                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                                      <p className="az-profile-name-text pl-0">
                                                        Description{" "}
                                                      </p>
                                                      <p className="media-body">
                                                        {items.note
                                                          ? items.note
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                                      <p className="az-profile-name-text pl-0">
                                                        Status{" "}
                                                      </p>
                                                      <p className="media-body">
                                                        {items.status_display
                                                          ? items.status_display
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                                      <p className="az-profile-name-text pl-0">
                                                        Reported By{" "}
                                                      </p>
                                                      <p className="media-body">
                                                        {items &&
                                                        items.tbnb_project &&
                                                        items.tbnb_project
                                                          .contractor_first_name !==
                                                          null &&
                                                        items.tbnb_project
                                                          .contractor_last_name !==
                                                          null
                                                          ? items.tbnb_project
                                                              .contractor_first_name +
                                                            " " +
                                                            items.tbnb_project
                                                              .contractor_last_name
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                                      <p className="az-profile-name-text pl-0">
                                                        Create Date{" "}
                                                      </p>
                                                      <p className="media-body">
                                                        {items.created_at &&
                                                        items.created_at !==
                                                          null
                                                          ? Moment(
                                                              items.created_at
                                                            ).format(
                                                              global.dateFormat
                                                            )
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                                      <p className="az-profile-name-text pl-0">
                                                        Last Update{" "}
                                                      </p>
                                                      <p className="media-body">
                                                        {items.updated_at &&
                                                        items.updated_at !==
                                                          null
                                                          ? Moment(
                                                              items.updated_at
                                                            ).format(
                                                              global.dateFormat
                                                            )
                                                          : ""}
                                                      </p>
                                                    </div>

                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-0 pd-xs-l-0 pd-lg-r-0 pd-xs-r-0 mg-b-15 ">
                                                      <p className="az-profile-name-text problemlable">
                                                        Problem Images{" "}
                                                      </p>
                                                      {multiImageList.map(
                                                        (opt, i) => {
                                                          var orgFile = opt.uri;
                                                          var mediumImg =
                                                            opt.uri;
                                                          var fileName =
                                                            "problem_image_" +
                                                            i;
                                                          multiImageList[i][
                                                            "src"
                                                          ] = orgFile;
                                                          multiImageList[i][
                                                            "thumbnail"
                                                          ] = mediumImg;
                                                          multiImageList[i][
                                                            "main_image"
                                                          ] = orgFile;
                                                          multiImageList[i][
                                                            "tags"
                                                          ] = [
                                                            {
                                                              value: (
                                                                <img
                                                                  src={
                                                                    DownloadImg
                                                                  }
                                                                  alt=""
                                                                  className="problem_img_new"
                                                                  onClick={() =>
                                                                    this.getDownloadFile(
                                                                      orgFile,
                                                                      fileName
                                                                    )
                                                                  }
                                                                />
                                                              ),
                                                              key: `download image ${i}`,
                                                              title: `Download Image`,
                                                            },
                                                          ];
                                                        }
                                                      )}
                                                      <React.Fragment>
                                                        <div className="row row-xs align-items-center justify-content-center pd-t-30 pd-b-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-0 mg-l-5 addmultiImages ml-0- col-12 cleaingimage">
                                                          <React.Fragment>
                                                            <GalleryProperty
                                                              backdropClosesModal={
                                                                false
                                                              }
                                                              preloadNextImage={
                                                                true
                                                              }
                                                              images={
                                                                multiImageList
                                                                  ? multiImageList
                                                                  : ""
                                                              }
                                                              enableImageSelection={
                                                                false
                                                              }
                                                              showImageCount={
                                                                false
                                                              }
                                                            />
                                                          </React.Fragment>
                                                        </div>
                                                      </React.Fragment>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        )
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="checklist">
                        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                          <div className="col-md-12 pl-0 pr-0">
                            <div className="scrolling-carousel pd-l-15">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                <div className="mg-t-20">
                                  <div className="row align-items-basline pd-b-0 border-bottom-0 pd-r-15">
                                    {this.state.cleaning_method === 2 ? (
                                      <>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Title{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.checklist.title}
                                          </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Description{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.checklist.description}
                                          </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Actual Start{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.resultData &&
                                            this.state.resultData
                                              .tbnb_project &&
                                            this.state.resultData.tbnb_project
                                              .asign_started_at
                                              ? Moment(
                                                  this.state.resultData
                                                    .tbnb_project
                                                    .asign_started_at
                                                ).format(global.dateTimeFormat)
                                              : ""}
                                          </p>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                          <p className="az-profile-name-text pl-0">
                                            Actual Completion{" "}
                                          </p>
                                          <p className="media-body">
                                            {this.state.resultData &&
                                            this.state.resultData
                                              .tbnb_project &&
                                            this.state.resultData.tbnb_project
                                              .asign_completed_at
                                              ? Moment(
                                                  this.state.resultData
                                                    .tbnb_project
                                                    .asign_completed_at
                                                ).format(global.dateTimeFormat)
                                              : ""}
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                                      {this.state.checklistItemsLength > 0 ? (
                                        <div className="table-responsive houskeeping_tbl">
                                          <Table hover>
                                            <thead>
                                              <tr>
                                                {this.state.cleaning_method ===
                                                  1 &&
                                                this.state.cleaning_type ===
                                                  2 ? (
                                                  <>
                                                    <th className="issue_tbl_cols">
                                                      Title
                                                    </th>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                <th
                                                  className={
                                                    this.state
                                                      .cleaning_method === 2
                                                      ? "issue_tbl_cols"
                                                      : "text-left issue_tbl_cols"
                                                  }
                                                >
                                                  Description
                                                </th>
                                                {this.state.cleaning_method ===
                                                  1 &&
                                                this.state.cleaning_type ===
                                                  2 ? (
                                                  <>
                                                    <th className="issue_tbl_cols">
                                                      Status
                                                    </th>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                {this.state.cleaning_method ===
                                                2 ? (
                                                  <>
                                                    <th>Main Image</th>
                                                    <th className="issue_tbl_cols">
                                                      Cleaning Image
                                                    </th>
                                                    <th className="text-left issue_tbl_cols">
                                                      Cleaning Comments
                                                    </th>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.checklistItemsLength >
                                              0
                                                ? this.state.checklistItems.map(
                                                    (items, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          {this.state
                                                            .cleaning_method ===
                                                            1 &&
                                                          this.state
                                                            .cleaning_type ===
                                                            2 ? (
                                                            <>
                                                              <td className="issue_tbl_cols">
                                                                {items
                                                                  ? items.title
                                                                  : ""}
                                                              </td>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                          <td
                                                            className={
                                                              this.state
                                                                .cleaning_method ===
                                                              2
                                                                ? "issue_tbl_cols"
                                                                : "text-left issue_tbl_cols"
                                                            }
                                                          >
                                                            {this.state
                                                              .cleaning_method ===
                                                              1 &&
                                                            this.state
                                                              .cleaning_type ===
                                                              2
                                                              ? items
                                                                ? renderHTML(
                                                                    items.description
                                                                  )
                                                                : ""
                                                              : items
                                                              ? items.description
                                                              : ""}
                                                          </td>
                                                          {this.state
                                                            .cleaning_method ===
                                                            1 &&
                                                          this.state
                                                            .cleaning_type ===
                                                            2 ? (
                                                            <>
                                                              <td className="issue_tbl_cols">
                                                                {items &&
                                                                items.status_id ===
                                                                  0
                                                                  ? "Pending"
                                                                  : items.status_id ===
                                                                    1
                                                                  ? "Completed"
                                                                  : items.status_id ===
                                                                    2
                                                                  ? "Rejected"
                                                                  : ""}
                                                              </td>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {this.state
                                                            .cleaning_method ===
                                                          2 ? (
                                                            <>
                                                              <td>
                                                                {items &&
                                                                items.oh_uri ? (
                                                                  <a
                                                                    href={
                                                                      items.oh_uri
                                                                    }
                                                                    target="blank"
                                                                    key={
                                                                      "link" +
                                                                      index
                                                                    }
                                                                  >
                                                                    <img
                                                                      src={
                                                                        items.oh_uri
                                                                      }
                                                                      alt={
                                                                        index
                                                                      }
                                                                      style={{
                                                                        height:
                                                                          "80px",
                                                                        width:
                                                                          "80px",
                                                                        objectFit:
                                                                          "cover",
                                                                        margin:
                                                                          "0 10px 10px 0",
                                                                      }}
                                                                    />
                                                                  </a>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </td>
                                                              <td>
                                                                {items &&
                                                                items.status &&
                                                                items.status
                                                                  .cleaner_images &&
                                                                items.status
                                                                  .cleaner_images
                                                                  .length > 0
                                                                  ? items.status.cleaner_images.map(
                                                                      (
                                                                        opt,
                                                                        cleanerImageIndex
                                                                      ) => {
                                                                        return (
                                                                          <a
                                                                            href={
                                                                              opt.oh_uri
                                                                            }
                                                                            target="blank"
                                                                            key={
                                                                              "link" +
                                                                              cleanerImageIndex
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={
                                                                                opt.oh_uri
                                                                              }
                                                                              alt={
                                                                                cleanerImageIndex
                                                                              }
                                                                              key={
                                                                                cleanerImageIndex
                                                                              }
                                                                              style={{
                                                                                height:
                                                                                  "80px",
                                                                                width:
                                                                                  "80px",
                                                                                objectFit:
                                                                                  "cover",
                                                                                margin:
                                                                                  "0 10px 10px 0",
                                                                              }}
                                                                            />
                                                                          </a>
                                                                        );
                                                                      }
                                                                    )
                                                                  : ""}
                                                              </td>
                                                              <td className="issue_tbl_cols text-left">
                                                                {items &&
                                                                items.status
                                                                  ? items.status
                                                                      .comment
                                                                  : ""}
                                                              </td>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </tr>
                                                      );
                                                    }
                                                  )
                                                : ""}
                                            </tbody>
                                          </Table>
                                        </div>
                                      ) : (
                                        <NoData msg="checklist items" />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {/* Housekeeping delete modal*/}
        <Modal show={this.state.deleteHouseKeepingModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeHouseKeepingModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeHouseKeepingModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteHouseKeeping(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default HouseKeepingView;

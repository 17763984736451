////////////////////////////////////////////////////////////
//     							                                      //
//  Program: LisitingFees.jsx                             //
//  Application: Lisiting Fees                            //
//  Option: List all LisitingFees                         //
//  Developer: Np						                              //
//  Date: 2022-02-16                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import ReactDatatable from "@ashvin27/react-datatable";
import PropertyCostService from "../../services/PropertyCostService";
import { Form, Modal, Table, InputGroup, Col } from "react-bootstrap";
import Alerts from "../common/Alerts";
import TaxService from "../../services/TaxService";
import CostTypeService from "../../services/CostTypeService";
import ListingService from "../../services/ListingService";
import CostPayableByService from "../../services/CostPayableByService";
import CostPayableAtService from "../../services/CostPayableAtService";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import Moment from "moment";
import DatePicker from "react-datepicker";
import pluswIcon from "./../../assets/images/plus.svg";
import Select from "react-select";

export class ListingFees extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      open: global.showOpen,
      eventKey: "",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      listing_slug: this.props.listing_slug,
      property_slug: this.props.property_slug,
      isSubmit: false,
      nightly_rate_error: false,
      cost_name: "",
      estimated: false,
      mandatory: false,
      refundable: false,
      pathname: window.location.pathname,
      listingPriceLabTotal: 0,
      costTypeList: [],
      newCostTypeList: [],
      newSelectedCostTypeList: [],
      costPayableAtList: [],
      newCostPayableAtList: [],
      selectedCostPayableAtList: [],
      costPayableByList: [],
      newCostPayableByList: [],
      selectedCostPayableByList: [],
      selectedCostTypeList: [],
      alreadySelectedCostTypeList: [],
      seasonal_price_slug: undefined,
      los_discount_slug: undefined,
      editLOSDiscountModal: false,
      stays_from: "",
      stays_upto: "",
      discount: "",
      priceType: "",
      costList: [],
      costListTotal: 0,
      taxList: [],
      taxListTotal: 0,
      cost_slug: "",
      confirm_back: 0,
      security_deposite: "",
      additional_fee: "",
      taxes: "",
      total_nightly_fee: "",
      auto_pricing: false,
      nightly_rate: "",
      nightly_rate_error_message: "",
      listingPriceLab: [],
      addSeasonalPriceModal: false,
      editSeasonalPriceModal: false,
      FeeTypeDataTotal: 1,
      FeeTypeData: [
        {
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: 0,
          estimated: 0,
          mandatory: 0,
        },
      ],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "from_date",
          text: "From",
          className: "from_date",
          sortable: true,
          cell: (record) => {
            return record.from_date
              ? Moment(record.from_date, "YYYY/MM/DD").format(global.dateFormat)
              : "";
          },
        },
        {
          key: "to_date",
          text: "To",
          className: "to_date",
          sortable: true,
          cell: (record) => {
            return record.to_date
              ? Moment(record.to_date, "YYYY/MM/DD").format(global.dateFormat)
              : "";
          },
        },
        {
          key: "special_price",
          text: "Nightly Rate",
          className: "special_price",
          sortable: true,
          cell: (record) => {
            return record.special_price
              ? "$" +
                  global.onKeyPressEvent.numberWithCommasForDecimal(
                    record.special_price.replace(/\.00$/, "")
                  )
              : "";
          },
        },
      ],
      publishListingModal: false,
      publishMessage: "",
    };

    this.dateSeasonalPriceChangeHandler =
      this.dateSeasonalPriceChangeHandler.bind(this);
    this.seasonalPriceChangeHandler =
      this.seasonalPriceChangeHandler.bind(this);
    this.losDiscountChangeHandler = this.losDiscountChangeHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateFees = this.saveOrUpdateFees.bind(this);
    this.getCostDetails = this.getCostDetails.bind(this);
    this.changeView = this.changeView.bind(this);
    this.openDeleteUnitCostModal = this.openDeleteUnitCostModal.bind(this);
    this.closeDeleteUnitCostModal = this.closeDeleteUnitCostModal.bind(this);
    this.deleteCost = this.deleteCost.bind(this);
    this.fullText = this.fullText.bind(this);
    // this.changeView2 = this.changeView2.bind(this);
    // this.changeView3 = this.changeView3.bind(this);
    // this.saveOrUpdateSpecialPrice = this.saveOrUpdateSpecialPrice.bind(this);
    // this.saveOrUpdateLOSDiscount = this.saveOrUpdateLOSDiscount.bind(this);
    this.updateSpecialPrice = this.updateSpecialPrice.bind(this);
    this.saveSpecialPrice = this.saveSpecialPrice.bind(this);
    this.openDeleteSpecialPriceModal =
      this.openDeleteSpecialPriceModal.bind(this);
    this.closeDeleteSpecialPriceModal =
      this.closeDeleteSpecialPriceModal.bind(this);
    this.openEditSpecialPriceModal = this.openEditSpecialPriceModal.bind(this);
    this.openEditLOSDiscountModal = this.openEditLOSDiscountModal.bind(this);
    this.openPublishListingModal = this.openPublishListingModal.bind(this);
    this.closePublishListingModal = this.closePublishListingModal.bind(this);
    this.publishListing = this.publishListing.bind(this);
    this.openAddSeasonalPriceModal = this.openAddSeasonalPriceModal.bind(this);
    this.closeAddSeasonalPriceModal =
      this.closeAddSeasonalPriceModal.bind(this);
    this.closeEditSeasonalPriceModal =
      this.closeEditSeasonalPriceModal.bind(this);
    this.openAddLOSDiscountModal = this.openAddLOSDiscountModal.bind(this);
    this.closeAddLOSDiscountModal = this.closeAddLOSDiscountModal.bind(this);
    this.closeEditLOSDiscountModal = this.closeEditLOSDiscountModal.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getData();
    this.getCost();
    this.getTaxData();
    this.getListingData();
    this.getSpecialPriceData();
    this.getLosDiscountData();
    this.getInfoData();
  }

  async getCost(queryString = "") {
    var list = [];
    var totalRecords = 0;
    var FeeTypeDataTotal = 0;
    let res = await PropertyCostService.getAllCosts(
      this.state.listing_slug + "/5"
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = list ? list.length : 0;

      const values = [];
      if (totalRecords > 0) {
        list.forEach((item, i) => {
          values.push({
            slug: item ? item.slug : "",
            cost_type_slug: item.cost_type ? item.cost_type.slug : "",
            cost_payable_by_slug: item.cost_payable_by
              ? item.cost_payable_by.slug
              : "",
            cost_payable_at_slug: item.cost_payable_at
              ? item.cost_payable_at.slug
              : "",
            cost_name: item.cost_name
              ? parseFloat(item.cost_name)
                  .toFixed(2)
                  .replace(/\.00$/, "")
                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              : "",
            refundable: item.refundable ? item.refundable : 0,
            estimated: item.estimated ? item.estimated : 0,
            mandatory: item.mandatory ? item.mandatory : 0,
            selected_cost_type: {
              value: item.cost_type ? item.cost_type.slug : "",
              label: item.cost_type ? item.cost_type.cost_type_name : "",
            },
            selected_cost_by: {
              value: item.cost_payable_by ? item.cost_payable_by.slug : "",
              label: item.cost_payable_by
                ? item.cost_payable_by.cost_payable_by_name
                : "",
            },
            selected_cost_at: {
              value: item.cost_payable_at ? item.cost_payable_at.slug : "",
              label: item.cost_payable_at
                ? item.cost_payable_at.cost_payable_at_name
                : "",
            },
          });
        });
      } else {
        values.push({
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: 0,
          estimated: 0,
          mandatory: 0,
        });
      }

      FeeTypeDataTotal = values.length;
      setTimeout(() => {
        this.setState({
          FeeTypeData: values,
          FeeTypeDataTotal: FeeTypeDataTotal,
        });
      }, 200);
    }
    this.setState({
      fees_total_record: totalRecords,
      fees_records: list,
    });
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  openAddSeasonalPriceModal() {
    this.setState({ addSeasonalPriceModal: true });
    this.setState({
      special_price: "",
      to_date: "",
      from_date: "",
      seasonal_price_slug: undefined,
      errors: {},
    });
  }

  closeAddSeasonalPriceModal() {
    this.setState({ addSeasonalPriceModal: false });
    this.setState({
      special_price: "",
      to_date: "",
      from_date: "",
      seasonal_price_slug: undefined,
      errors: {},
    });
    this.getSpecialPriceData();
    this.getLosDiscountData();
    this.validator.hideMessages();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  openAddLOSDiscountModal() {
    this.setState({ addLOSDiscountModal: true });
    this.setState({
      los_from_date: "",
      los_to_date: "",
      stays_from: "",
      stays_upto: "",
      discount: "",
      los_discount_slug: undefined,
      errors: {},
    });
  }

  closeAddLOSDiscountModal() {
    this.setState({ addLOSDiscountModal: false });
    this.setState({
      los_from_date: "",
      los_to_date: "",
      stays_from: "",
      discount: "",
      stays_upto: "",
      los_discount_slug: undefined,
      errors: {},
    });
    this.getSpecialPriceData();
    this.getLosDiscountData();
    this.validator.hideMessages();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  closeEditSeasonalPriceModal() {
    this.setState({ editSeasonalPriceModal: false });
    this.setState({
      special_price: "",
      to_date: "",
      from_date: "",
      seasonal_price_slug: undefined,
      errors: {},
    });
    this.getSpecialPriceData();
    this.getLosDiscountData();
    this.validator.hideMessages();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  closeEditLOSDiscountModal() {
    this.setState({ editLOSDiscountModal: false });
    this.setState({
      los_from_date: "",
      los_to_date: "",
      stays_from: "",
      discount: "",
      stays_upto: "",
      los_discount_slug: "",
      errors: {},
    });
    this.getSpecialPriceData();
    this.getLosDiscountData();
    this.validator.hideMessages();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  openDeleteUnitCostModal(slug) {
    this.setState({ deleteUnitCostModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteUnitCostModal() {
    this.setState({ deleteUnitCostModal: false });
    this.setState({ slug: "" });
  }

  openDeleteSpecialPriceModal(from, to, priceType) {
    this.setState({ deleteUnavailableDateModal: true });
    this.setState({ fromDate: from, toDate: to, priceType: priceType });
  }

  async openEditSpecialPriceModal(from, to, priceType, slug) {
    this.setState({ editSeasonalPriceModal: true });
    this.setState({
      updateFrom: from,
      updateTo: to,
      seasonal_price_slug: slug,
    });
    var resData = {};
    let dateData = {
      listing_slug: this.props.listing_slug,
      fromDate: from,
      toDate: to,
      priceType: priceType,
    };
    let res = await ListingService.getListingSpecialPrice(dateData);
    if (global.successStatus.includes(res.status)) {
      resData.unavailable_slug = res.data.slug ? res.data.slug : "";
      resData.special_price = res.data.special_price
        ? res.data.special_price.replace(/\.00$/, "")
        : "";
      resData.from_date = res.data.from_date
        ? Moment(res.data.from_date).toDate("dd-MM-YYYY")
        : null;
      resData.from_date_b = res.data.from_date ? res.data.from_date : null;
      resData.to_date = res.data.to_date
        ? Moment(res.data.to_date).toDate("dd-MM-YYYY")
        : null;
      resData.to_date_b = res.data.to_date ? res.data.to_date : null;
    }
    this.setState(resData);
    setTimeout(() => {
      this.getListingUpdateSpecialPrice(this.props.listing_slug, from, to, 1);
    });
  }

  async openEditLOSDiscountModal(from, to, priceType, slug) {
    this.setState({ editLOSDiscountModal: true });
    this.setState({
      losUpdateFrom: from,
      losUpdateTo: to,
      los_discount_slug: slug,
    });
    var resData = {};
    let dateData = {
      listing_slug: this.props.listing_slug,
      fromDate: from,
      toDate: to,
      priceType: priceType,
      los_discount_slug: slug,
    };
    let res = await ListingService.getListingLOSDiscount(dateData);
    if (global.successStatus.includes(res.status)) {
      resData.unavailable_slug = res.data.slug ? res.data.slug : "";
      resData.discount = res.data.discount ? res.data.discount : "";
      resData.stays_from = res.data.stays_from ? res.data.stays_from : "";
      resData.stays_upto = res.data.stays_upto ? res.data.stays_upto : "";
      resData.los_from_date = res.data.from_date
        ? Moment(res.data.from_date).toDate("dd-MM-YYYY")
        : null;
      resData.los_from_date_b = res.data.from_date ? res.data.from_date : null;
      resData.los_to_date = res.data.to_date
        ? Moment(res.data.to_date).toDate("dd-MM-YYYY")
        : null;
      resData.los_to_date_b = res.data.to_date ? res.data.to_date : null;
    }
    this.setState(resData);
    setTimeout(() => {
      this.getListingLOSDiscountBDates(this.props.listing_slug, from, to, 2);
    }, 100);
  }

  async getListingUpdateSpecialPrice(listingSlug, from, to, type) {
    var totalRecords = 0;
    var checkDate = {};
    var dates = [];
    let res = await ListingService.getListingUpdateSpecialPrice(
      listingSlug,
      from,
      to,
      type
    );

    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
      totalRecords = checkDate.length ? checkDate.length : 0;
    }
    if (totalRecords > 0) {
      checkDate.map((item, index) => {
        let staretDate = new Date(item.from_date + " 00:00:00");
        let endDate = new Date(item.to_date + " 00:00:00");
        const date = new Date(staretDate.getTime());

        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      });
      this.setState({ disabled_dates: dates });
    } else {
      this.setState({ disabled_dates: [] });
    }
  }

  async getListingLOSDiscountBDates(listingSlug, from, to, type) {
    var totalRecords = 0;
    var checkDate = {};
    var dates = [];
    let res = await ListingService.getListingLOSDiscountBlockedDate(
      listingSlug,
      from,
      to,
      type
    );

    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
      totalRecords = checkDate.length ? checkDate.length : 0;
    }
    if (totalRecords > 0) {
      checkDate.map((item, index) => {
        let staretDate = new Date(item.from_date + " 00:00:00");
        let endDate = new Date(item.to_date + " 00:00:00");
        const date = new Date(staretDate.getTime());

        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      });
      this.setState({ los_disabled_dates: dates });
    } else {
      this.setState({ los_disabled_dates: [] });
    }
  }

  closeDeleteSpecialPriceModal() {
    this.setState({ deleteUnavailableDateModal: false });
    this.setState({ fromDate: "", toDate: "", priceType: "" });
  }

  async getListingPirceLabData(queryString = "") {
    var listingPriceLab = [];
    var listingPriceLabTotal = 0;
    let res = await ListingService.getListingPriceLabList(
      "listing_slug=" +
        this.props.listing_slug +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      listingPriceLab = res.data ? res.data.data : [];
      listingPriceLabTotal = res.data ? res.data.total : 0;
    }
    this.setState({
      listingPriceLabTotal: listingPriceLabTotal,
      listingPriceLab: listingPriceLab,
    });
  }

  tableChangeHandler1 = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getListingPirceLabData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getListingPirceLabData(queryString);
    }
  };

  async getSpecialPriceData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    var checkDate = {};
    var dates = [];
    let res = await ListingService.getListingSpecialPriceList(
      "listing_slug=" +
        this.props.listing_slug +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      checkDate = res.data ? res.data : {};

      totalRecords = list.length ? list.length : 0;
      //totalRecords = res.data ? res.data.total : 0
    }
    if (totalRecords > 0) {
      checkDate.map((item, index) => {
        let staretDate = new Date(item.from_date + " 00:00:00");
        let endDate = new Date(item.to_date + " 00:00:00");
        const date = new Date(staretDate.getTime());

        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      });
      this.setState({ disabled_dates: dates });
    } else {
      this.setState({ disabled_dates: [] });
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  async getLosDiscountData(queryString = "") {
    var list1 = [];
    var totalRecords1 = 0;
    var checkDate1 = {};
    var dates1 = [];
    let res = await ListingService.getListingLOSDiscountList(
      "listing_slug=" +
        this.props.listing_slug +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      list1 = res.data ? res.data : [];
      checkDate1 = res.data ? res.data : {};

      totalRecords1 = list1.length ? list1.length : 0;
      //totalRecords = res.data ? res.data.total : 0
    }
    if (totalRecords1 > 0) {
      checkDate1.map((item, index) => {
        let staretDate1 = new Date(item.from_date + " 00:00:00");
        let endDate1 = new Date(item.to_date + " 00:00:00");
        const date1 = new Date(staretDate1.getTime());

        while (date1 <= endDate1) {
          dates1.push(new Date(date1));
          date1.setDate(date1.getDate() + 1);
        }
      });
      this.setState({ los_disabled_dates: dates1 });
    } else {
      this.setState({ los_disabled_dates: [] });
    }
    this.setState({
      total_record1: totalRecords1,
      records1: list1,
    });
  }

  async getInfoData() {
    var resData = {};
    let res = await ListingService.getListing(this.props.listing_slug);

    if (global.successStatus.includes(res.status)) {
      resData.listing_from_date = res.data.from_date
        ? Moment(res.data.from_date, "MM/DD/YYYY").toDate("MM/DD/YYYY")
        : null;
      resData.listing_to_date = res.data.to_date
        ? Moment(res.data.to_date, "MM/DD/YYYY").toDate("MM/DD/YYYY")
        : null;
    }
    this.setState(resData);
  }

  saveSpecialPrice = async (e) => {
    e.preventDefault();
    if (
      !this.validator.errorMessages.from_date &&
      !this.validator.errorMessages.to_date &&
      !this.validator.errorMessages.special_price
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputInsertData = {
        listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
        special_price: this.state.special_price ? this.state.special_price : "",
        from_date: this.state.from_date
          ? Moment(this.state.from_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        to_date: this.state.to_date
          ? Moment(this.state.to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        price_type: 1,
      };
      let res = await ListingService.createListingSpecialPrice(inputInsertData);
      if (global.successStatus.includes(res.status)) {
        var last_insert_slug = res.data.last_insert_slug;
        this.setState({
          tabWizard: 1,
          listing_slug: last_insert_slug,
          from_date: "",
          to_date: "",
          discount: "",
          special_price: "",
          los_from_date: "",
          los_to_date: "",
          stays_from: "",
          stays_upto: "",
        });
        this.validator.hideMessages();
        this.closeAddSeasonalPriceModal();
        this.openPublishListingModal(res.message);
      } else {
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
    } else {
      this.validator.showMessages();
    }
  };

  updateSpecialPrice = async (e) => {
    e.preventDefault();
    if (
      !this.validator.errorMessages.from_date &&
      !this.validator.errorMessages.to_date &&
      !this.validator.errorMessages.special_price
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputUpdateData = {
        listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
        special_price: this.state.special_price ? this.state.special_price : "",
        from_date: this.state.from_date
          ? Moment(this.state.from_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        to_date: this.state.to_date
          ? Moment(this.state.to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        price_type: 1,
        updateFrom: this.state.updateFrom
          ? Moment(this.state.updateFrom, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        updateTo: this.state.updateTo
          ? Moment(this.state.updateTo, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
      };
      if (this.state.updateFrom && this.state.updateTo) {
        let res = await ListingService.updateListingSpecialPrice(
          inputUpdateData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            updateFrom: "",
            updateTo: "",
            from_date: "",
            to_date: "",
            discount: "",
            special_price: "",
            los_from_date: "",
            los_to_date: "",
            stays_from: "",
            stays_upto: "",
          });
          this.validator.hideMessages();
          this.closeEditSeasonalPriceModal();
          this.openPublishListingModal(res.message);
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
    } else {
      this.validator.showMessages();
    }
  };

  saveLOSDiscount = async (e) => {
    e.preventDefault();
    if (
      !this.validator.errorMessages.discount &&
      !this.validator.errorMessages.los_from_date &&
      !this.validator.errorMessages.los_from_date &&
      !this.validator.errorMessages.stays_from &&
      !this.validator.errorMessages.stays_upto
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
        discount: this.state.discount ? this.state.discount : "",
        los_from_date: this.state.los_from_date
          ? Moment(this.state.los_from_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        los_to_date: this.state.los_to_date
          ? Moment(this.state.los_to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        price_type: 2,
        stays_from: this.state.stays_from ? this.state.stays_from : "",
        stays_upto: this.state.stays_upto ? this.state.stays_upto : "",
      };

      let res = await ListingService.createListingSpecialPrice(inputData);

      if (global.successStatus.includes(res.status)) {
        var last_insert_slug = res.data.last_insert_slug;
        this.setState({ tabWizard: 1, listing_slug: last_insert_slug });
        this.setState({
          from_date: "",
          to_date: "",
          discount: "",
          special_price: "",
          los_from_date: "",
          los_to_date: "",
          stays_from: "",
          stays_upto: "",
        });
        this.validator.hideMessages();
        this.closeAddLOSDiscountModal();
        this.openPublishListingModal(res.message);
      } else {
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
    } else {
      this.validator.showMessages();
    }
  };

  updateLOSDiscount = async (e) => {
    e.preventDefault();
    if (
      !this.validator.errorMessages.discount &&
      !this.validator.errorMessages.los_from_date &&
      !this.validator.errorMessages.los_from_date &&
      !this.validator.errorMessages.stays_from &&
      !this.validator.errorMessages.stays_upto
    ) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputUpdateData = {
        listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
        discount: this.state.discount ? this.state.discount : "",
        los_from_date: this.state.los_from_date
          ? Moment(this.state.los_from_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        los_to_date: this.state.los_to_date
          ? Moment(this.state.los_to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        price_type: 2,
        stays_from: this.state.stays_from ? this.state.stays_from : "",
        stays_upto: this.state.stays_upto ? this.state.stays_upto : "",
        updateFrom: this.state.losUpdateFrom
          ? Moment(this.state.losUpdateFrom, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        updateTo: this.state.losUpdateTo
          ? Moment(this.state.losUpdateTo, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
      };
      if (this.state.losUpdateFrom && this.state.losUpdateTo) {
        let res = await ListingService.updateListingSpecialPrice(
          inputUpdateData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            discount: "",
            special_price: "",
            los_from_date: "",
            los_to_date: "",
            stays_from: "",
            stays_upto: "",
          });
          this.validator.hideMessages();
          this.closeEditLOSDiscountModal();
          this.openPublishListingModal(res.message);
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
    } else {
      this.validator.showMessages();
    }
  };

  saveOrUpdateFees = async (e) => {
    e.preventDefault();
    if (this.customValidate() && !this.validator.errorMessages.nightly_rate) {
      this.setState({ showSpinner: true, isSubmit: true });
      if (this.state.listing_slug && this.state.unit_slug) {
        let inputData = {
          tab: "price",
          listing_name: this.state.listing_name,
          unit_slug: this.state.unit_slug,
          pet_data: this.state.pet_data,
          auto_pricing: this.state.auto_pricing === true ? 1 : 0,
          nightly_rate: this.state.nightly_rate
            ? parseFloat(this.state.nightly_rate)
                .toFixed(2)
                .replace(/\.00$/, "")
            : 0,
          security_deposite: this.state.security_deposite
            ? parseFloat(this.state.security_deposite)
                .toFixed(2)
                .replace(/\.00$/, "")
            : null,
          additional_fee: this.state.additional_fee
            ? parseFloat(this.state.additional_fee)
                .toFixed(2)
                .replace(/\.00$/, "")
            : null,
          taxes: this.state.taxes
            ? parseFloat(this.state.taxes).toFixed(2).replace(/\.00$/, "")
            : null,
          total_nightly_fee: this.state.total_nightly_fee
            ? this.state.total_nightly_fee
            : null,
          cleaning_fee: this.state.cleaning_fee ? this.state.cleaning_fee : 0,
          occupancy_taxes_array: this.state.occupancy_taxes_array
            ? this.state.occupancy_taxes_array
            : [],
        };
        let feeData = {
          level: 5,
          slug: this.state.listing_slug,
          cost_data: this.state.FeeTypeData,
        };
        let res = await ListingService.updateListing(
          this.state.listing_slug,
          inputData
        );
        let res1 = await PropertyCostService.updateCosts(feeData);
        if (
          global.successStatus.includes(res.status) ||
          global.successStatus.includes(res1.status)
        ) {
          this.getListingData();
          this.changeView();
          this.openPublishListingModal(res.message);
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let errors = {};
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Please select unit from info tab !",
        });
        this.setState({ errors: errors });
      }
      this.setState({ showSpinner: false, isSubmit: false });
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  // saveOrUpdateSpecialPrice = async (e) => {
  //   e.preventDefault();
  //   if (this.validator.allValid() && this.customValidate()) {
  //     this.setState({ showSpinner: true, isSubmit: true });
  //     let inputInsertData = {
  //       listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
  //       special_price: this.state.special_price ? this.state.special_price : "",
  //       from_date: this.state.from_date
  //         ? Moment(this.state.from_date, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //       to_date: this.state.to_date
  //         ? Moment(this.state.to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //       price_type: 1,
  //     };
  //     let inputUpdateData = {
  //       listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
  //       special_price: this.state.special_price ? this.state.special_price : "",
  //       from_date: this.state.from_date
  //         ? Moment(this.state.from_date, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //       to_date: this.state.to_date
  //         ? Moment(this.state.to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //       price_type: 1,
  //       updateFrom: this.state.updateFrom
  //         ? Moment(this.state.updateFrom, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //       updateTo: this.state.updateTo
  //         ? Moment(this.state.updateTo, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //     };
  //     if (this.state.updateFrom && this.state.updateTo) {
  //       let res = await ListingService.updateListingSpecialPrice(
  //         inputUpdateData
  //       );
  //       if (global.successStatus.includes(res.status)) {
  //         // this.setState({
  //         //   showAlertModal: true,
  //         //   alertModalType: "success",
  //         //   alertModalTitle: "Success",
  //         //   alertModalMessage: res.message ? res.message : "Success",
  //         // });
  //         this.setState({
  //           updateFrom: "",
  //           updateTo: "",
  //           from_date: "",
  //           to_date: "",
  //           discount: "",
  //           special_price: "",
  //           los_from_date: "",
  //           los_to_date: "",
  //           stays_from: "",
  //           stays_upto: "",
  //         });
  //         this.validator.hideMessages();
  //         this.changeView3();
  //         this.openPublishListingModal(res.message);
  //       } else {
  //         if (res.data.result.errorDetail !== null) {
  //           let errors = {};
  //           res.data.errorCode = "Validation Failed";
  //           res.data.result.errorDetail.forEach((item) => {
  //             errors[item.errorField] = item.errorMessage[0];
  //             res.data.errorMessage = "";
  //           });
  //           this.setState({ errors: errors });
  //         }
  //       }
  //     } else {
  //       let res = await ListingService.createListingSpecialPrice(
  //         inputInsertData
  //       );

  //       if (global.successStatus.includes(res.status)) {
  //         // this.setState({
  //         //   showAlertModal: true,
  //         //   alertModalType: "success",
  //         //   alertModalTitle: "Success",
  //         //   alertModalMessage: res.message ? res.message : "Success",
  //         // });
  //         var last_insert_slug = res.data.last_insert_slug;
  //         this.setState({
  //           tabWizard: 1,
  //           listing_slug: last_insert_slug,
  //           from_date: "",
  //           to_date: "",
  //           discount: "",
  //           special_price: "",
  //           los_from_date: "",
  //           los_to_date: "",
  //           stays_from: "",
  //           stays_upto: "",
  //         });
  //         this.validator.hideMessages();
  //         this.changeView3();
  //         this.openPublishListingModal(res.message);
  //       } else {
  //         if (res.data.result.errorDetail !== null) {
  //           let errors = {};
  //           res.data.errorCode = "Validation Failed";
  //           res.data.result.errorDetail.forEach((item) => {
  //             errors[item.errorField] = item.errorMessage[0];
  //             res.data.errorMessage = "";
  //           });
  //           this.setState({ errors: errors });
  //         }
  //       }
  //     }
  //     this.setState({ showSpinner: false, isSubmit: false });
  //   } else {
  //     this.validator.showMessages();
  //   }
  // };

  // saveOrUpdateLOSDiscount = async (e) => {
  //   e.preventDefault();
  //   if (this.validator.allValid() && this.customValidate()) {
  //     this.setState({ showSpinner: true, isSubmit: true });
  //     let inputData = {
  //       listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
  //       discount: this.state.discount ? this.state.discount : "",
  //       from_date: this.state.los_from_date
  //         ? Moment(this.state.los_from_date, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //       to_date: this.state.los_to_date
  //         ? Moment(this.state.los_to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //       price_type: 2,
  //       stays_from: this.state.stays_from ? this.state.stays_from : "",
  //       stays_upto: this.state.stays_upto ? this.state.stays_upto : "",
  //     };
  //     let inputUpdateData = {
  //       listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
  //       discount: this.state.discount ? this.state.discount : "",
  //       from_date: this.state.los_from_date
  //         ? Moment(this.state.los_from_date, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //       to_date: this.state.los_to_date
  //         ? Moment(this.state.los_to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //       price_type: 2,
  //       stays_from: this.state.stays_from ? this.state.stays_from : "",
  //       stays_upto: this.state.stays_upto ? this.state.stays_upto : "",
  //       updateFrom: this.state.losUpdateFrom
  //         ? Moment(this.state.losUpdateFrom, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //       updateTo: this.state.losUpdateTo
  //         ? Moment(this.state.losUpdateTo, "YYYY-MM-DD").format("YYYY-MM-DD")
  //         : null,
  //     };
  //     if (this.state.losUpdateFrom && this.state.losUpdateTo) {
  //       let res = await ListingService.updateListingSpecialPrice(
  //         inputUpdateData
  //       );
  //       if (global.successStatus.includes(res.status)) {
  //         // this.setState({
  //         //   showAlertModal: true,
  //         //   alertModalType: "success",
  //         //   alertModalTitle: "Success",
  //         //   alertModalMessage: res.message ? res.message : "Success",
  //         // });
  //         this.setState({
  //           from_date: "",
  //           to_date: "",
  //           discount: "",
  //           special_price: "",
  //           los_from_date: "",
  //           los_to_date: "",
  //           stays_from: "",
  //           stays_upto: "",
  //         });
  //         this.validator.hideMessages();
  //         this.changeView2();
  //         this.openPublishListingModal(res.message);
  //       } else {
  //         if (res.data.result.errorDetail !== null) {
  //           let errors = {};
  //           res.data.errorCode = "Validation Failed";
  //           res.data.result.errorDetail.forEach((item) => {
  //             errors[item.errorField] = item.errorMessage[0];
  //             res.data.errorMessage = "";
  //           });
  //           this.setState({ errors: errors });
  //         }
  //       }
  //     } else {
  //       let res = await ListingService.createListingSpecialPrice(inputData);

  //       if (global.successStatus.includes(res.status)) {
  //         // this.setState({
  //         //   showAlertModal: true,
  //         //   alertModalType: "success",
  //         //   alertModalTitle: "Success",
  //         //   alertModalMessage: res.message ? res.message : "Success",
  //         // });
  //         var last_insert_slug = res.data.last_insert_slug;
  //         this.setState({ tabWizard: 1, listing_slug: last_insert_slug });
  //         this.setState({
  //           from_date: "",
  //           to_date: "",
  //           discount: "",
  //           special_price: "",
  //           los_from_date: "",
  //           los_to_date: "",
  //           stays_from: "",
  //           stays_upto: "",
  //         });
  //         this.validator.hideMessages();
  //         this.changeView2();
  //         this.openPublishListingModal(res.message);
  //       } else {
  //         if (res.data.result.errorDetail !== null) {
  //           let errors = {};
  //           res.data.errorCode = "Validation Failed";
  //           res.data.result.errorDetail.forEach((item) => {
  //             errors[item.errorField] = item.errorMessage[0];
  //             res.data.errorMessage = "";
  //           });
  //           this.setState({ errors: errors });
  //         }
  //       }
  //     }

  //     this.setState({ showSpinner: false, isSubmit: false });
  //   } else {
  //     this.validator.showMessages();
  //   }
  // };

  async deleteUnavailableDate(from, to, priceType) {
    this.setState({ showSpinner: true });
    let dateData = {
      listing_slug: this.props.listing_slug,
      fromDate: from,
      toDate: to,
      priceType: priceType,
    };
    let res = await ListingService.deleteListingSpecialPrice(dateData);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteSpecialPriceModal();

      this.setState({ disabled_dates: [], los_disabled_dates: [] });
      this.getSpecialPriceData();
      this.getLosDiscountData();
      this.openPublishListingModal();
    } else {
      this.closeDeleteSpecialPriceModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteSpecialPriceModal();
  }
  /* for custom pricing  end*/

  async getListingData() {
    if (this.props.listing_slug !== undefined) {
      var resData = {};
      let res = await ListingService.getListing(this.props.listing_slug);

      if (global.successStatus.includes(res.status)) {
        resData.listing_name = res.data.listing_name
          ? res.data.listing_name
          : "";
        resData.unit_slug = res.data.unit ? res.data.unit.slug : "";
        resData.property_pet_allowed = res.data.property
          ? res.data.property.pet_allowed
          : "";
        resData.listing_pet_allowed = res.data.listing_pet_allowed
          ? res.data.listing_pet_allowed
          : "";
        resData.pet_policy_restrictions = res.data.property
          ? res.data.property.pet_policy_restrictions
          : "";
        resData.auto_pricing =
          res.data && res.data.auto_pricing && res.data.auto_pricing === 1
            ? true
            : false;
        if (resData.auto_pricing === true) {
          this.getListingPirceLabData();
        } else {
          this.getSpecialPriceData();
          this.getLosDiscountData();
        }
        resData.nightly_rate = res.data.nightly_rate
          ? parseFloat(res.data.nightly_rate)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.security_deposite = res.data.security_deposite
          ? parseFloat(res.data.security_deposite)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.additional_fee = res.data.additional_fee
          ? parseFloat(res.data.additional_fee)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.taxes = res.data.taxes
          ? parseFloat(res.data.taxes)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.cleaning_fee = res.data.cleaning_fee
          ? parseFloat(res.data.cleaning_fee)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
      }
      this.setState(resData);
    }
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    var costList = [];
    var costListTotal = 0;
    var additionalFee = 0;
    let res = await PropertyCostService.getAllCosts(
      this.state.listing_slug + "/5"
    );
    if (global.successStatus.includes(res.status)) {
      costList = res.data ? res.data : [];
      costListTotal = costList ? costList.length : 0;
    }

    this.setState({ costList: costList, costListTotal: costListTotal });
    this.setState({ showSpinner: false, loading: false });
    setTimeout(() => {
      this.state.costList.map((opt) => {
        additionalFee += +opt.cost_name;
      });
      this.setState({ additional_fee: additionalFee });
    }, 200);
  }

  async getTaxData() {
    this.setState({ showSpinner: true, loading: true });
    var taxList = [];
    var taxListTotal = 0;
    let res = await TaxService.getTax(
      "property_slug=" + this.state.property_slug + "&is_active=1"
    );
    if (global.successStatus.includes(res.status)) {
      taxList = res.data ? res.data.data : [];
      taxListTotal = taxList ? taxList.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      taxListTotal: taxListTotal,
      taxList: taxList,
    });
    this.getTaxesData();
  }

  async getTaxesData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var masterTaxList = [];
    var masterTaxTotalRecords = 0;
    var both_total_record = 0;
    let res = await TaxService.getTax(
      "property_slug=" +
        this.state.property_slug +
        "&is_property=1" +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      masterTaxList = res.data ? res.data : [];
      masterTaxTotalRecords = masterTaxList ? masterTaxList.length : 0;
    }
    if (masterTaxTotalRecords > 0) {
      masterTaxList.map((item, i) => {
        masterTaxList[i]["property_level_tax_name"] = item.general_tax_name;
        masterTaxList[i]["tax_type_name"] = item.tax_type_name;
        masterTaxList[i]["property_amount"] = item.general_tax_value;
        masterTaxList[i]["status_id"] = item.status_id;
        masterTaxList[i]["is_disable"] = true;
      });
    }
    // this.setState({ showSpinner: false, loading: false, });
    this.setState({
      masterTaxTotalRecords: masterTaxTotalRecords,
      masterTaxList: masterTaxList,
    });
    both_total_record =
      parseInt(masterTaxTotalRecords) + parseInt(this.state.taxListTotal);
    if (this.state.taxListTotal !== 0) {
      this.setState({ taxList: [...this.state.taxList, ...masterTaxList] });
      this.setState({ taxListTotal: both_total_record });
    } else {
      this.setState({ taxList: masterTaxList });
      this.setState({ taxListTotal: masterTaxTotalRecords });
    }
  }

  async getCostDetails(slug) {
    this.setState({ cost_slug: slug });
    this.changeView();
    this.setState({ showSpinner: true, loading: true });
    if (this.state.listing_slug !== undefined) {
      var resData = {};
      let res = await PropertyCostService.getCostsBySlug(
        this.state.listing_slug + "/3/" + slug
      );
      if (global.successStatus.includes(res.status)) {
        resData.cost_name = res.data.cost_name ? res.data.cost_name : "";
        resData.cost_type_slug = res.data.cost_type
          ? res.data.cost_type.slug
          : "";
        resData.cost_payable_at_slug = res.data.cost_payable_at
          ? res.data.cost_payable_at.slug
          : "";
        resData.cost_payable_by_slug = res.data.cost_payable_by
          ? res.data.cost_payable_by.slug
          : "";
        resData.estimated = res.data.estimated === 1 ? true : false;
        resData.mandatory = res.data.mandatory === 1 ? true : false;
        resData.refundable = res.data.refundable === 1 ? true : false;
        resData.status_id = res.data.status_id === 1 ? true : false;

        var newSelectedCostTypeList = [];
        newSelectedCostTypeList = res.data.cost_type
          ? [
              {
                value: res.data.cost_type ? res.data.cost_type.slug : "",
                label: res.data.cost_type
                  ? res.data.cost_type.cost_type_name
                  : "",
              },
            ]
          : "";

        var selectedCostPayableAtList = [];
        selectedCostPayableAtList = res.data.cost_payable_at
          ? [
              {
                value: res.data.cost_payable_at
                  ? res.data.cost_payable_at.slug
                  : "",
                label: res.data.cost_payable_at
                  ? res.data.cost_payable_at.cost_payable_at_name
                  : "",
              },
            ]
          : "";

        var selectedCostPayableByList = [];
        selectedCostPayableByList = res.data.cost_payable_by
          ? [
              {
                value: res.data.cost_payable_by
                  ? res.data.cost_payable_by.slug
                  : "",
                label: res.data.cost_payable_by
                  ? res.data.cost_payable_by.cost_payable_by_name
                  : "",
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({
        newSelectedCostTypeList: newSelectedCostTypeList,
        selectedCostPayableAtList: selectedCostPayableAtList,
        selectedCostPayableByList: selectedCostPayableByList,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Type list data */
  async getCostTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costTypeList = [];

    let res = await CostTypeService.getCostType(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costTypeList = res.data ? res.data : [];
    }
    const newCostTypeList = costTypeList.map(({ slug, cost_type_name }) => ({
      value: slug,
      label: cost_type_name,
    }));
    this.setState({
      costTypeList: costTypeList,
      newCostTypeList: newCostTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSelectedCostType() {
    this.setState({ showSpinner: true, loading: true });
    var selectedCostTypeList = [];

    let res = await CostTypeService.getSelectedCostType(
      this.state.listing_slug + "/4"
    );

    if (global.successStatus.includes(res.status)) {
      selectedCostTypeList = res.data ? res.data : [];
    }
    const alreadySelectedCostTypeList = selectedCostTypeList.map(
      ({ slug, cost_type_name }) => ({
        value: slug,
        label: cost_type_name,
      })
    );
    this.setState({
      selectedCostTypeList: selectedCostTypeList,
      alreadySelectedCostTypeList: alreadySelectedCostTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable At list data */
  async getCostPayableAtData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costPayableAtList = [];

    let res = await CostPayableAtService.getCostPayableAt(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableAtList = res.data ? res.data : [];
    }
    const newCostPayableAtList = costPayableAtList.map(
      ({ slug, cost_payable_at_name }) => ({
        value: slug,
        label: cost_payable_at_name,
      })
    );
    this.setState({
      costPayableAtList: costPayableAtList,
      newCostPayableAtList: newCostPayableAtList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable By list data */
  async getCostPayableByData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costPayableByList = [];

    let res = await CostPayableByService.getCostPayableBy(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableByList = res.data ? res.data : [];
    }
    const newCostPayableByList = costPayableByList.map(
      ({ slug, cost_payable_by_name }) => ({
        value: slug,
        label: cost_payable_by_name,
      })
    );
    this.setState({
      costPayableByList: costPayableByList,
      newCostPayableByList: newCostPayableByList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async deleteCost(slug) {
    this.setState({ showSpinner: true });
    let res = await PropertyCostService.deleteCost(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteUnitCostModal();
      this.setState({ viewMode: 0, cost_slug: "" });
      this.getData();
      this.openPublishListingModal();
    } else {
      this.closeDeleteUnitCostModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    if (
      event.target.name === "nightly_rate" ||
      event.target.name === "cleaning_fee"
    ) {
      let nightly_rate =
        event.target.name === "nightly_rate"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : ""
          : this.state.nightly_rate
          ? this.state.nightly_rate.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
      if (
        this.state.auto_pricing === false &&
        event.target.name === "nightly_rate" &&
        (nightly_rate === "" ||
          nightly_rate === null ||
          nightly_rate === undefined)
      ) {
        var nightly_rate_error_message = "The base price field is required.";
        this.setState({
          nightly_rate_error: true,
          nightly_rate_error_message: nightly_rate_error_message,
        });
      } else {
        this.setState({
          nightly_rate_error: false,
          nightly_rate_error_message: "",
        });
      }

      let cleaning_fee =
        event.target.name === "cleaning_fee"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.cleaning_fee
          ? this.state.cleaning_fee.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : 0;

      this.setState({ nightly_rate: nightly_rate, cleaning_fee: cleaning_fee });
    }

    if (event.target.name === "security_deposite") {
      let security_deposite =
        event.target.name === "security_deposite"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.security_deposite
          ? this.state.security_deposite.replace(
              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
              ""
            )
          : "";
      this.setState({ security_deposite: security_deposite });
    }
    if (event.target.name === "additional_fee") {
      let additional_fee =
        event.target.name === "additional_fee"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.additional_fee
          ? this.state.additional_fee.replace(
              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
              ""
            )
          : "";
      this.setState({ additional_fee: additional_fee });
    }
    if (event.target.name === "total_nightly_fee") {
      let total_nightly_fee =
        event.target.name === "total_nightly_fee"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.total_nightly_fee
          ? this.state.total_nightly_fee.replace(
              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
              ""
            )
          : "";
      this.setState({ total_nightly_fee: total_nightly_fee });
    }
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  losDiscountChangeHandler = (event) => {
    let input = this.state.input;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  seasonalPriceChangeHandler = (event) => {
    let input = this.state.input;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  dateSeasonalPriceChangeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setTimeout(() => {
      if (event.target.name === "from_date") {
        let checkin_date_val = this.state.input.from_date;
        let checkInDate = Moment(checkin_date_val).format("YYYY-MM-DD");
        if (this.state.seasonal_price_slug !== undefined) {
          this.getSeasonalPriceUpdateMaxDate(
            this.state.seasonal_price_slug,
            this.state.listing_slug,
            checkInDate,
            1
          );
        } else {
          this.getSeasonalPriceMaxDate(this.state.listing_slug, checkInDate, 1);
        }
        this.setState({ to_date: "" });
      }
    }, 200);
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  dateLOSDiscountChangeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setTimeout(() => {
      if (event.target.name === "los_from_date") {
        let los_checkin_date_val = this.state.input.los_from_date;
        let losCheckInDate = Moment(los_checkin_date_val).format("YYYY-MM-DD");
        if (this.state.los_discount_slug !== undefined) {
          this.getSeasonalPriceUpdateMaxDate(
            this.state.los_discount_slug,
            this.state.listing_slug,
            losCheckInDate,
            2
          );
        } else {
          this.getSeasonalPriceMaxDate(
            this.state.listing_slug,
            losCheckInDate,
            2
          );
        }
        this.setState({ los_to_date: "" });
      }
    }, 200);
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  async getSeasonalPriceUpdateMaxDate(slug, listing_slug, date, priceType) {
    var checkDateMax = [];
    let res = await ListingService.getSeasonalPricepdateNextCheckInDate(
      slug,
      listing_slug,
      date,
      priceType
    );
    if (global.successStatus.includes(res.status)) {
      checkDateMax.set_max_date =
        res && res.data && res.data.set_max_date ? res.data.set_max_date : "";

      var maxCheckOutDate = checkDateMax.set_max_date
        ? Moment(checkDateMax.set_max_date).toDate("dd/MM/YY")
        : this.state.listingTodate
        ? this.state.listingTodate
        : "";
    }
    this.setState({ maxCheckoutDate: maxCheckOutDate });
  }

  async getSeasonalPriceMaxDate(listingSlug, date, priceType) {
    var checkDateMax = [];
    let res = await ListingService.getSeasonalPriceNextCheckInDate(
      listingSlug,
      date,
      priceType
    );
    if (global.successStatus.includes(res.status)) {
      checkDateMax.set_max_date =
        res && res.data && res.data.set_max_date ? res.data.set_max_date : "";

      var maxCheckOutDate = checkDateMax.set_max_date
        ? Moment(checkDateMax.set_max_date).toDate("dd/MM/YY")
        : this.state.listingTodate
        ? this.state.listingTodate
        : "";
    }
    this.setState({ maxCheckoutDate: maxCheckOutDate });
  }

  handleChangeSelectedCostTypeList(value) {
    let cost_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        cost_type_slug.push(item.value);
      });
    }
    const cost_type_slug_value = value !== null ? value.value : [];
    this.setState({
      newSelectedCostTypeList: value,
      cost_type_slug: cost_type_slug_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeCostPayableByList(value) {
    let cost_payable_by_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        cost_payable_by_slug.push(item.value);
      });
    }
    const cost_payable_by_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCostPayableByList: value,
      cost_payable_by_slug: cost_payable_by_slug_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeCostPayableAtList(value) {
    let cost_payable_at_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        cost_payable_at_slug.push(item.value);
      });
    }
    const cost_payable_at_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCostPayableAtList: value,
      cost_payable_at_slug: cost_payable_at_slug_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleAddFieldsCost = () => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];
    var FeeTypeDataTotal = 1;
    values.push({
      cost_type_slug: "",
      cost_payable_by_slug: "",
      cost_payable_at_slug: "",
      cost_name: "",
      refundable: 0,
      estimated: 0,
      mandatory: 0,
    });
    FeeTypeDataTotal = values.length;

    this.setState({
      FeeTypeData: values,
      FeeTypeDataTotal: FeeTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFieldsCost = (i) => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];
    values.splice(i, 1);
    var FeeTypeDataTotal = 0;
    FeeTypeDataTotal = values.length;
    this.setState({ FeeTypeData: values, cost_data: values });
    this.setState({
      FeeTypeDataTotal: FeeTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectOne = (index, event) => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];

    values[index].cost_type_slug = event.value;
    values[index].selected_cost_type = {
      label: event.label,
      value: event.value,
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectTwo = (index, event) => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];
    values[index].cost_payable_at_slug = event.value;
    values[index].selected_cost_at = {
      label: event.label,
      value: event.value,
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectThree = (index, event) => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];
    values[index].cost_payable_by_slug = event.value;
    values[index].selected_cost_by = {
      label: event.label,
      value: event.value,
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeCost = (index, event) => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];

    if (event.target.name === "cost_name") {
      values[index].cost_name = event.target.value
        .replace(/\.00$/, "")
        .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "");
    }
    if (event.target.name === "refundable") {
      values[index].refundable = event.target.checked ? 1 : 0;
    }
    if (event.target.name === "estimated") {
      values[index].estimated = event.target.checked ? 1 : 0;
    }
    if (event.target.name === "mandatory") {
      values[index].mandatory = event.target.checked ? 1 : 0;
    }
    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  customValidate() {
    let isValid = true;
    if (
      this.state.auto_pricing === false &&
      (this.state.nightly_rate === "" ||
        this.state.nightly_rate === null ||
        this.state.nightly_rate === undefined)
    ) {
      var nightly_rate_error_message = "The base price field is required.";
      this.setState({
        nightly_rate_error_message: nightly_rate_error_message,
        nightly_rate_error: true,
      });
      isValid = false;
    } else {
      this.setState({
        nightly_rate_error_message: "",
        nightly_rate_error: false,
      });
      isValid = true;
    }
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({
        viewMode: 0,
        from_date: "",
        to_date: "",
        unavailable_slug: "",
        special_price: "",
        errors: {},
        disabled_dates: [],
        auto_pricing: false,
      });
      if (
        this.state.auto_pricing === false &&
        (this.state.nightly_rate === "" ||
          this.state.nightly_rate === null ||
          this.state.nightly_rate === undefined)
      ) {
        this.setState({
          nightly_rate_error: true,
        });
      } else {
        this.setState({
          nightly_rate_error_message: "",
          nightly_rate_error: false,
        });
      }
      this.setState({ confirm_back: 0 });
      this.getData();
      this.getCost();
      this.getListingData();
      this.getSpecialPriceData();
      this.getLosDiscountData();
      this.getListingData();
      this.setState({
        cost_slug: "",
        newSelectedCostTypeList: [],
        selectedCostPayableAtList: [],
        selectedCostPayableByList: [],
      });
      this.setState({
        cost_name: "",
        cost_type_slug: "",
        cost_payable_at_slug: "",
        cost_payable_by_slug: "",
        refundable: "",
        estimated: "",
        mandatory: "",
        errors: {},
        nightly_rate: "",
        cleaning_fee: "",
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
    } else {
      this.setState({
        viewMode: 1,
        from_date: "",
        to_date: "",
        unavailable_slug: "",
        special_price: "",
        errors: {},
        updateFrom: "",
        updateTo: "",
        disabled_dates: [],
        auto_pricing: false,
      });
      if (
        this.state.auto_pricing === false &&
        (this.state.nightly_rate === "" ||
          this.state.nightly_rate === null ||
          this.state.nightly_rate === undefined)
      ) {
        this.setState({
          nightly_rate_error: true,
        });
      } else {
        this.setState({
          nightly_rate_error_message: "",
          nightly_rate_error: false,
        });
      }
      this.setState({ confirm_back: 0 });
      this.getData();
      this.getCost();
      this.getListingData();
      this.getSpecialPriceData();
      this.getLosDiscountData();
      this.getListingData();
      this.setState({
        cost_name: "",
        cost_type_slug: "",
        cost_payable_at_slug: "",
        cost_payable_by_slug: "",
        refundable: "",
        estimated: "",
        mandatory: "",
        errors: {},
        newSelectedCostTypeList: [],
        selectedCostPayableAtList: [],
        selectedCostPayableByList: [],
        nightly_rate: "",
        cleaning_fee: "",
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
      if (this.state.cost_slug === "") {
        this.getCostTypeData();
        this.getCostPayableAtData();
        this.getCostPayableByData();
      } else {
        this.getCostDetails(this.state.cost_slug);
      }
    }
  }

  // changeView3() {
  //   if (this.state.viewMode === 3) {
  //     this.setState({
  //       viewMode: 0,
  //       from_date: "",
  //       to_date: "",
  //       unavailable_slug: "",
  //       special_price: "",
  //       errors: {},
  //       disabled_dates: [],
  //       los_from_date: "",
  //       los_to_date: "",
  //       stays_from: "",
  //       stays_upto: "",
  //       discount: "",
  //     });
  //     this.setState({ confirm_back: 0 });
  //     this.getSpecialPriceData();
  //     this.getLosDiscountData();
  //     this.getListingData();
  //     this.validator.hideMessages();
  //   } else {
  //     this.setState({
  //       viewMode: 3,
  //       from_date: "",
  //       to_date: "",
  //       unavailable_slug: "",
  //       special_price: "",
  //       errors: {},
  //       updateFrom: "",
  //       updateTo: "",
  //       disabled_dates: [],
  //       los_from_date: "",
  //       los_to_date: "",
  //       stays_from: "",
  //       stays_upto: "",
  //       discount: "",
  //     });
  //     this.setState({ confirm_back: 0 });
  //     this.getSpecialPriceData();
  //     this.getLosDiscountData();
  //     this.getListingData();
  //     this.validator.hideMessages();
  //     this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  //   }
  // }

  // changeView2() {
  //   if (this.state.viewMode === 2) {
  //     this.setState({
  //       viewMode: 0,
  //       los_from_date: "",
  //       los_to_date: "",
  //       unavailable_slug: "",
  //       discount: "",
  //       stays_from: "",
  //       stays_upto: "",
  //       errors: {},
  //       los_disabled_dates: [],
  //       from_date: "",
  //       to_date: "",
  //     });
  //     this.setState({ confirm_back: 0 });
  //     this.getSpecialPriceData();
  //     this.getLosDiscountData();
  //     this.validator.hideMessages();
  //   } else {
  //     this.setState({
  //       viewMode: 2,
  //       los_from_date: "",
  //       los_to_date: "",
  //       unavailable_slug: "",
  //       discount: "",
  //       stays_from: "",
  //       stays_upto: "",
  //       errors: {},
  //       losUpdateFrom: "",
  //       losUpdateTo: "",
  //       los_disabled_dates: [],
  //       from_date: "",
  //       to_date: "",
  //     });
  //     this.validator.hideMessages();
  //     this.setState({ confirm_back: 0 });
  //     this.getSpecialPriceData();
  //     this.getLosDiscountData();
  //     this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  //   }
  // }

  getTitle() {
    return "Price";
  }

  openPublishListingModal(message) {
    this.setState({ publishListingModal: true, publishMessage: message });
  }

  closePublishListingModal() {
    this.setState({ publishListingModal: false, publishMessage: "" });
  }

  async publishListing() {
    this.setState({ publishData: true });
    let inputData = {
      data: {
        listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
        property_slug: this.props.property_slug ? this.props.property_slug : "",
        unit_slug: this.props.unit_slug ? this.props.unit_slug : "",
        floor_plan_slug: this.props.floor_plan_slug
          ? this.props.floor_plan_slug
          : "",
      },
    };
    let res = await ListingService.publishListing(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
    this.closePublishListingModal();
    this.setState({ publishData: false });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
            {this.state.viewMode === 0 &&
            global.userPermissions.checkPermission("single-unit-update") ? (
              <>
                <button
                  className="btn-success-outline-small ml-3 mr-3"
                  onClick={this.changeView}
                >
                  <img src={editIcon} alt="" />
                  Edit
                </button>
              </>
            ) : (
              <>
                {this.state.cost_slug !== "" ? (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        this.openDeleteUnitCostModal(this.state.cost_slug);
                      }}
                      className="btn-danger-outline mg-r-20"
                    >
                      <img src={deleteicon} alt="" />
                      Delete
                    </button>{" "}
                    <span
                      onClick={() => {
                        this.openDeleteUnitCostModal(this.state.slug);
                      }}
                      className="deleteicon mg-r-20"
                    >
                      <img src={deleteicon} alt="" />
                    </span>
                  </>
                ) : (
                  ""
                )}
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn-success-outline-small ml-3"
                    onClick={() => {
                      if (this.state.confirm_back === 1) {
                        if (
                          window.confirm("Do you want to exit without saving?")
                        ) {
                          // if (this.state.viewMode === 2) {
                          //   this.changeView2();
                          // } else if (this.state.viewMode === 3) {
                          //   this.changeView3();
                          // } else {
                          this.changeView();
                          // }
                        } else {
                          return false;
                        }
                      } else {
                        // if (this.state.viewMode === 2) {
                        //   this.changeView2();
                        // } else if (this.state.viewMode === 3) {
                        //   this.changeView3();
                        // } else {
                        this.changeView();
                        // }
                      }
                    }}
                  >
                    Cancel
                  </button>

                  {
                    // this.state.viewMode === 2 &&
                    // global.userPermissions.checkPermission(
                    //   "single-unit-update"
                    // ) ? (
                    //   <button
                    //     className="btn-success mr-3 "
                    //     onClick={this.saveOrUpdateLOSDiscount}
                    //   >
                    //     Save
                    //   </button>
                    // ) : this.state.viewMode === 3 &&
                    //   global.userPermissions.checkPermission(
                    //     "single-unit-update"
                    //   ) ? (
                    //   <button
                    //     className="btn-success mr-3"
                    //     onClick={this.saveOrUpdateSpecialPrice}
                    //   >
                    //     Save
                    //   </button>
                    // ) :

                    global.userPermissions.checkPermission(
                      "single-unit-update"
                    ) ? (
                      <button
                        className="btn-success  mr-3"
                        onClick={this.saveOrUpdateFees}
                      >
                        {this.state.isSubmit ? global.loader : "Save"}
                      </button>
                    ) : (
                      ""
                    )
                  }

                  {/* {this.state.viewMode === 2 &&
                  global.userPermissions.checkPermission(
                    "single-unit-update"
                  ) ? (
                    <button
                      className="btn-success mr-3 "
                      onClick={this.saveOrUpdateLOSDiscount}
                    >
                      Save
                    </button>
                  ) : this.state.viewMode === 3 &&
                    global.userPermissions.checkPermission(
                      "single-unit-update"
                    ) ? (
                    <button
                      className="btn-success mr-3"
                      onClick={this.saveOrUpdateSpecialPrice}
                    >
                      Save
                    </button>
                  ) : global.userPermissions.checkPermission(
                      "single-unit-update"
                    ) ? (
                    <button
                      className="btn-success  mr-3"
                      onClick={this.saveOrUpdateFees}
                    >
                      Save
                    </button>
                  ) : (
                    ""
                  )} */}
                </div>
              </>
            )}
          </div>
          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0  mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          {this.state.viewMode === 0 ? (
            <div className="row m-0 listing_fee_price">
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 ">
                <h3 className="mg-l-0 background-head">Price</h3>
              </div>
              <div className="row col-md-12 mg-lg-t-0 mg-xs-t-10 mg-md-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 border-bottom-0">
                {global.userType !== 4 ? (
                  <>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 pd-l-30 pd-r-30 pl-0 listing_detail">
                      <p className="az-profile-name-text pl-0">
                        PriceLabs Enabled
                      </p>
                      <p className="media-body">
                        {this.state.auto_pricing === true ? "Yes" : "No"}
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="row col-12 p-0 align-items-center pd-b-30 border-bottom-0 pd-lg-r-15">
                {global.userType !== 4 ? (
                  <>
                    {this.state.auto_pricing === false ? (
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-20 pd-lg-r-30">
                        <p className="az-profile-name-text pl-0">Base Price</p>
                        <p className="media-body">
                          {this.state.nightly_rate
                            ? "$" +
                              global.onKeyPressEvent.numberWithCommasForDecimal(
                                this.state.nightly_rate.replace(/\.00$/, "")
                              )
                            : ""}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-20 pd-lg-r-30">
                      <p className="az-profile-name-text pl-0">Cleaning Fee</p>
                      <p className="media-body">
                        {this.state.cleaning_fee
                          ? "$" +
                            global.onKeyPressEvent.numberWithCommasForDecimal(
                              this.state.cleaning_fee
                            )
                          : ""}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-20 pd-lg-r-30">
                      <p className="az-profile-name-text pl-0">
                        Security Deposit
                      </p>
                      <p className="media-body">
                        {this.state.security_deposite
                          ? "$" +
                            global.onKeyPressEvent.numberWithCommasForDecimal(
                              this.state.security_deposite
                            )
                          : ""}
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                      <label className="form-label text-left pd-b-5">
                        Note: If you’re using dynamic pricing with PriceLabs,
                        disregard the sections below. If you would like to add
                        dynamic pricing with PriceLabs, let your concierge know.
                      </label>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <div className="background-head d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                  <h3 className="mb-0">Auto Pricing (PriceLabs)</h3>
                </div>
              </div>
              {this.state.auto_pricing === true ? (
                <React.Fragment>
                  <div className="col-lg-12 pd-xs-l-15 pd-lg-l-0 pd-xs-r-0 pd-lg-r-15">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      {this.state.listingPriceLabTotal > 0 ? (
                        <div className="mg-t-18 brandList auto-pricing-tbl">
                          <div className="pd-lg-l-0 smart_price_row">
                            <ReactDatatable
                              className="table mg-b-30 pd-b-2 add-no-wrap"
                              config={this.state.config}
                              records={this.state.listingPriceLab}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              total_record={this.state.listingPriceLabTotal}
                              onChange={this.tableChangeHandler1}
                            />
                          </div>
                        </div>
                      ) : (
                        <p className="pets_no_text">
                          Adjust your PriceLabs settings directly from your
                          PriceLabs account.
                        </p>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                  PriceLabs automated price is not enabled.
                </p>
              )}
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <div className="background-head d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                  <h3 className="mb-0">Seasonal Price</h3>
                  {this.state.auto_pricing === false &&
                  global.userPermissions.checkPermission(
                    "single-unit-update"
                  ) ? (
                    <React.Fragment>
                      <div className="d-flex">
                        <button
                          className="btn-success-outline-small "
                          onClick={this.openAddSeasonalPriceModal}
                        >
                          Add
                        </button>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.state.auto_pricing === false ? (
                <React.Fragment>
                  <div className="row col-md-12 mg-lg-t-0 mg-xs-t-10 mg-md-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 border-bottom-0">
                    <div className="table-responsive">
                      <Table hover className="table_heading_top">
                        <thead>
                          <tr>
                            <th className="w-40">From</th>
                            <th className="w-40">To</th>
                            <th className="w-40">Nightly Rate</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.total_record > 0 ? (
                            this.state.records.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    onClick={() =>
                                      this.openEditSpecialPriceModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        1,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {Moment(
                                      item.from_date,
                                      "YYYY/MM/DD"
                                    ).format(global.dateFormat)}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditSpecialPriceModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        1,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {Moment(item.to_date, "YYYY/MM/DD").format(
                                      global.dateFormat
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditSpecialPriceModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        1,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {item.special_price
                                      ? "$" +
                                        global.onKeyPressEvent.numberWithCommasForDecimal(
                                          item.special_price.replace(
                                            /\.00$/,
                                            ""
                                          )
                                        )
                                      : ""}
                                  </td>

                                  <td>
                                    <div className="btn-icon-list d-block text-center">
                                      <img
                                        src={deleteicon}
                                        alt="Delete"
                                        onClick={() =>
                                          this.openDeleteSpecialPriceModal(
                                            Moment(
                                              item.from_date,
                                              "YYYY/MM/DD"
                                            ).format("YYYY-MM-DD"),
                                            Moment(
                                              item.to_date,
                                              "YYYY/MM/DD"
                                            ).format("YYYY-MM-DD"),
                                            1
                                          )
                                        }
                                        className="hand-cursor"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                There is no seasonal pricing added in the
                                system. You may add them by clicking Add button.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                  Since pricelabs is enabled, you can not select seasonal price.
                </p>
              )}
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <div className="background-head d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                  <h3 className="mb-0">Discounts: Length of Stay</h3>
                  {this.state.auto_pricing === false &&
                  global.userPermissions.checkPermission(
                    "single-unit-update"
                  ) ? (
                    <React.Fragment>
                      <div className="d-flex">
                        <button
                          className="btn-success-outline-small"
                          onClick={this.openAddLOSDiscountModal}
                        >
                          Add
                        </button>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.state.auto_pricing === false ? (
                <React.Fragment>
                  <div className="row col-md-12 mg-lg-t-0 mg-xs-t-10 mg-md-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 border-bottom-0">
                    <div className="table-responsive">
                      <Table hover className="table_heading_top">
                        <thead>
                          <tr>
                            <th className="w-40">From</th>
                            <th className="w-40">To</th>
                            <th className="w-40">Stays From (days)</th>
                            <th className="w-40">Stays Up To (days) </th>
                            <th className="w-40">Discount</th>

                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.total_record1 > 0 ? (
                            this.state.records1.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    onClick={() =>
                                      this.openEditLOSDiscountModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        2,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {Moment(
                                      item.from_date,
                                      "YYYY/MM/DD"
                                    ).format(global.dateFormat)}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditLOSDiscountModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        2,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {Moment(item.to_date, "YYYY/MM/DD").format(
                                      global.dateFormat
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditLOSDiscountModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        2,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {item.stays_from ? item.stays_from : ""}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditLOSDiscountModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        2
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {item.stays_upto ? item.stays_upto : ""}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditLOSDiscountModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        2
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {item.discount
                                      ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                          item.discount
                                        ) + "%"
                                      : ""}
                                  </td>
                                  <td>
                                    <div className="btn-icon-list d-block text-center">
                                      <img
                                        src={deleteicon}
                                        alt="Delete"
                                        onClick={() =>
                                          this.openDeleteSpecialPriceModal(
                                            Moment(
                                              item.from_date,
                                              "YYYY/MM/DD"
                                            ).format("YYYY-MM-DD"),
                                            Moment(
                                              item.to_date,
                                              "YYYY/MM/DD"
                                            ).format("YYYY-MM-DD"),
                                            2
                                          )
                                        }
                                        className="hand-cursor"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                There are no length of stay discounts added in
                                the system. You may add them by clicking Add
                                button.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                  Since pricelabs is enabled, you can not select length of stay
                  discounts.
                </p>
              )}
              {global.userType !== 4 ? (
                <>
                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <h3 className="mg-l-0 background-head">General Fees</h3>
                  </div>
                  <div className="table-responsive pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                    <Table hover className="table_heading_top">
                      <thead>
                        <tr>
                          <th className="w-20">Fee Type</th>
                          <th className="w-20">Payable By</th>
                          <th className="w-20">Payable At</th>
                          <th className="w-20 text-left">Fee</th>
                          <th className="w-20">Refundable</th>
                          <th className="w-20">Estimated</th>
                          <th className="w-20 text-left">Mandatory</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.costListTotal > 0 ? (
                          <>
                            {this.state.costList.map((option, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {option.cost_type
                                      ? option.cost_type.cost_type_name
                                      : ""}
                                  </td>
                                  <td>
                                    {option.cost_payable_by
                                      ? option.cost_payable_by
                                          .cost_payable_by_name
                                      : ""}
                                  </td>
                                  <td>
                                    {option.cost_payable_at
                                      ? option.cost_payable_at
                                          .cost_payable_at_name
                                      : ""}
                                  </td>

                                  <td>
                                    {option.refundable === 1 ? "Yes" : "No"}
                                  </td>
                                  <td>
                                    {option.estimated === 1 ? "Yes" : "No"}
                                  </td>
                                  <td className="text-left">
                                    {option.mandatory === 1 ? "Yes" : "No"}
                                  </td>
                                  <td className="text-left">
                                    {option.cost_name
                                      ? "$" +
                                        global.onKeyPressEvent.numberWithCommasForDecimal(
                                          option.cost_name.replace(/\.00$/, "")
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colSpan="9" align="center">
                              There are no general fees added in the system.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-lg-12 mg-lg-t-20 mg-xs-t-10 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">
                  Occupancy Tax Distribution
                </h3>
              </div>
              <div className="table-responsive pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <Table hover className="table_heading_top">
                  <thead>
                    <tr>
                      <th className="w-30">Name</th>
                      <th className="w-30 text-left">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.taxListTotal > 0 ||
                    this.state.taxOccupancyListTotal > 0 ? (
                      <React.Fragment>
                        {this.state.taxList.map((option, i) => {
                          var aa = option.tax_type_name
                            ? option.tax_type_name
                            : option.tax_type
                            ? option.tax_type.tax_type_name
                            : "";
                          return (
                            <tr key={i}>
                              <td>
                                {option.property_level_tax_name
                                  ? option.property_level_tax_name
                                  : ""}
                              </td>

                              <td className="text-left">
                                {option.property_amount
                                  ? aa.includes("$")
                                    ? "$" +
                                      global.onKeyPressEvent.numberWithCommasForDecimal(
                                        parseFloat(option.property_amount)
                                          .toFixed(2)
                                          .replace(/\.00$/, "")
                                          .replace(
                                            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                            ""
                                          )
                                      )
                                    : parseFloat(option.property_amount)
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                        .replace(
                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                          ""
                                        ) + "%"
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          There are no occupancy taxes added in the system.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            ""
          )}

          {this.state.viewMode === 1 ? (
            <div className="m-0 listing_fee_price">
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">Price</h3>
              </div>
              <div className="row align-items-center pd-lg-b-15 pd-xs-b-0 border-bottom-0 pd-lg-r-15">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    PriceLabs Enabled{" "}
                  </label>
                  <Form.Check
                    name="auto_pricing"
                    type={"checkbox"}
                    id={`auto_pricing`}
                    label={""}
                    className={"ckbox"}
                    onChange={this.changeHandler}
                    checked={this.state.auto_pricing}
                  />
                </div>
              </div>
              <div className="row align-items-center pd-b-30 border-bottom-0 pd-lg-r-15">
                {this.state.auto_pricing === false ? (
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-20 pd-lg-r-30">
                    <label className="form-label text-left pd-b-5">
                      Base Price
                    </label>
                    <div className="input-group-prepend prefix-dollar">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="text"
                        onChange={this.changeHandler}
                        name="nightly_rate"
                        value={
                          this.state.nightly_rate
                            ? this.state.nightly_rate.replace(
                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                ""
                              )
                            : "" || ""
                        }
                        maxLength="10"
                        onKeyPress={global.onKeyPressEvent.floatValidation}
                      />
                    </div>
                    {this.state.nightly_rate_error === true ? (
                      <div className="text-danger">
                        {this.state.nightly_rate_error_message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-20 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    Cleaning Fee
                  </label>
                  <div className="input-group-prepend prefix-dollar">
                    <span className="input-group-text">$</span>
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="cleaning_fee"
                      value={
                        this.state.cleaning_fee
                          ? this.state.cleaning_fee.replace(
                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                              ""
                            )
                          : "" || ""
                      }
                      maxLength="10"
                      onKeyPress={global.onKeyPressEvent.floatValidation}
                    />
                    {this.state.errors.cleaning_fee ? (
                      <div className="text-danger">
                        {this.state.errors.cleaning_fee}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-20 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    Security Deposit
                  </label>
                  <div className="input-group-prepend prefix-dollar">
                    <span className="input-group-text">$</span>
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="security_deposite"
                      value={
                        this.state.security_deposite
                          ? parseFloat(this.state.security_deposite)
                              .toFixed(2)
                              .replace(/\.00$/, "")
                              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
                          : "" || ""
                      }
                      maxLength="10"
                      onKeyPress={global.onKeyPressEvent.floatValidation}
                    />
                    {this.state.errors.security_deposite ? (
                      <div className="text-danger">
                        {this.state.errors.security_deposite}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    Note: If you’re using dynamic pricing with PriceLabs,
                    disregard the sections below. If you would like to add
                    dynamic pricing with PriceLabs, let your concierge know.
                  </label>
                </div>
              </div>
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <div className="background-head d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                  <h3 className="mb-0">Auto Pricing (PriceLabs)</h3>
                </div>
              </div>
              {this.state.auto_pricing === true ? (
                <React.Fragment>
                  <div className="col-lg-12 pd-xs-l-15 pd-lg-l-0 pd-xs-r-0 pd-lg-r-15">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      {this.state.listingPriceLabTotal > 0 ? (
                        <div className="mg-t-18 brandList">
                          <div className="pd-lg-l-0 smart_price_row">
                            <ReactDatatable
                              className="table mg-b-30 pd-b-2 add-no-wrap"
                              config={this.state.config}
                              records={this.state.listingPriceLab}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              total_record={this.state.listingPriceLabTotal}
                              onChange={this.tableChangeHandler1}
                            />
                          </div>
                        </div>
                      ) : (
                        <p className="pets_no_text">
                          Adjust your PriceLabs settings directly from your
                          PriceLabs account.
                        </p>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-25">
                  PriceLabs automated price is not enabled.
                </p>
              )}
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <div className="background-head d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                  <h3 className="mb-0">Seasonal Price</h3>
                  {this.state.auto_pricing === false &&
                  global.userPermissions.checkPermission(
                    "single-unit-update"
                  ) ? (
                    <React.Fragment>
                      <div className="d-flex">
                        <button
                          className="btn-success-outline-small "
                          onClick={this.openAddSeasonalPriceModal}
                        >
                          Add
                        </button>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.state.auto_pricing === false ? (
                <React.Fragment>
                  <div className="row col-md-12 mg-lg-t-0 mg-xs-t-10 mg-md-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 border-bottom-0">
                    <div className="table-responsive">
                      <Table hover className="table_heading_top">
                        <thead>
                          <tr>
                            <th className="w-40">From</th>
                            <th className="w-40">To</th>
                            <th className="w-40">Nightly Rate</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.total_record > 0 ? (
                            this.state.records.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    onClick={() =>
                                      this.openEditSpecialPriceModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        1,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {Moment(
                                      item.from_date,
                                      "YYYY/MM/DD"
                                    ).format(global.dateFormat)}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditSpecialPriceModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        1,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {Moment(item.to_date, "YYYY/MM/DD").format(
                                      global.dateFormat
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditSpecialPriceModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        1,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {item.special_price
                                      ? "$" +
                                        global.onKeyPressEvent.numberWithCommasForDecimal(
                                          item.special_price.replace(
                                            /\.00$/,
                                            ""
                                          )
                                        )
                                      : ""}
                                  </td>

                                  <td>
                                    <div className="btn-icon-list d-block text-center">
                                      <img
                                        src={deleteicon}
                                        alt="Delete"
                                        onClick={() =>
                                          this.openDeleteSpecialPriceModal(
                                            Moment(
                                              item.from_date,
                                              "YYYY/MM/DD"
                                            ).format("YYYY-MM-DD"),
                                            Moment(
                                              item.to_date,
                                              "YYYY/MM/DD"
                                            ).format("YYYY-MM-DD"),
                                            1
                                          )
                                        }
                                        className="hand-cursor"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                There are no seasonal pricing added in the
                                system. You may add them by clicking Add button.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                  Since pricelabs is enabled, you can not select seasonal price.
                </p>
              )}
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <div className="background-head d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                  <h3 className="mb-0">Discounts: Length of Stay</h3>
                  {this.state.auto_pricing === false &&
                  global.userPermissions.checkPermission(
                    "single-unit-update"
                  ) ? (
                    <React.Fragment>
                      <div className="d-flex">
                        <button
                          className="btn-success-outline-small"
                          onClick={this.openAddLOSDiscountModal}
                        >
                          Add
                        </button>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.state.auto_pricing === false ? (
                <React.Fragment>
                  <div className="row col-md-12 mg-lg-t-0 mg-xs-t-10 mg-md-t-0 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 border-bottom-0">
                    <div className="table-responsive">
                      <Table hover className="table_heading_top">
                        <thead>
                          <tr>
                            <th className="w-40">From</th>
                            <th className="w-40">To</th>
                            <th className="w-40">Stays From (days)</th>
                            <th className="w-40">Stays Up To (days) </th>
                            <th className="w-40">Discount</th>

                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.total_record1 > 0 ? (
                            this.state.records1.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    onClick={() =>
                                      this.openEditLOSDiscountModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        2,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {Moment(
                                      item.from_date,
                                      "YYYY/MM/DD"
                                    ).format(global.dateFormat)}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditLOSDiscountModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        2,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {Moment(item.to_date, "YYYY/MM/DD").format(
                                      global.dateFormat
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditLOSDiscountModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        2,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {item.stays_from ? item.stays_from : ""}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditLOSDiscountModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        2,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {item.stays_upto ? item.stays_upto : ""}
                                  </td>
                                  <td
                                    onClick={() =>
                                      this.openEditLOSDiscountModal(
                                        Moment(
                                          item.from_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        Moment(
                                          item.to_date,
                                          "YYYY/MM/DD"
                                        ).format("YYYY-MM-DD"),
                                        2,
                                        item.slug
                                      )
                                    }
                                    className="hand-cursor"
                                  >
                                    {item.discount
                                      ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                          item.discount
                                        ) + "%"
                                      : ""}
                                  </td>
                                  <td>
                                    <div className="btn-icon-list d-block text-center">
                                      <img
                                        src={deleteicon}
                                        alt="Delete"
                                        onClick={() =>
                                          this.openDeleteSpecialPriceModal(
                                            Moment(
                                              item.from_date,
                                              "YYYY/MM/DD"
                                            ).format("YYYY-MM-DD"),
                                            Moment(
                                              item.to_date,
                                              "YYYY/MM/DD"
                                            ).format("YYYY-MM-DD"),
                                            2
                                          )
                                        }
                                        className="hand-cursor"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                There are no length of stay discounts added in
                                the system. You may add them by clicking Add
                                button.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                  Since pricelabs is enabled, you can not select length of stay
                  discounts.
                </p>
              )}
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">General Fees</h3>
              </div>
              <div className="table-responsive pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 table_inside_drop">
                <Table hover className="table_heading_top">
                  <thead>
                    <tr>
                      <th className="w-20">Fee Type</th>
                      <th className="w-20">Payable By</th>
                      <th className="w-20">Payable At</th>
                      <th className="w-20 text-left">Fee</th>
                      <th className="w-20">Refundable</th>
                      <th className="w-20">Estimated</th>
                      <th className="w-20">Mandatory</th>
                      <th className="w-20"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.FeeTypeData.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td className="w-1">
                            {" "}
                            <Col xs={12} className="pd-l-0 pd-r-2 colmn1">
                              <InputGroup className="mt-0 m-b-1">
                                <div className="col-md-12 p-0">
                                  <Select
                                    styles={customStyles}
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    menuPlacement="auto"
                                    onChange={(value) =>
                                      this.handleChangeSelectOne(i, value)
                                    }
                                    options={this.state.newCostTypeList}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                    value={data.selected_cost_type}
                                    defaultValue={data.selected_cost_type}
                                  />
                                </div>
                              </InputGroup>
                            </Col>
                          </td>
                          <td className="w-1">
                            <Col xs={12} className="pd-l-2 pd-r-2 colmn1">
                              <Select
                                styles={customStyles}
                                menuPlacement="auto"
                                className="multiselect"
                                classNamePrefix={"my-custom-react-select"}
                                name="cost_payable_by_slug"
                                value={data.selected_cost_by}
                                options={this.state.newCostPayableByList}
                                defaultValue={data.selected_cost_by}
                                onChange={(value) =>
                                  this.handleChangeSelectThree(i, value)
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </Col>
                          </td>
                          <td className="w-1">
                            <Col xs={12} className="pd-l-2 pd-r-2 colmn1">
                              <Select
                                styles={customStyles}
                                menuPlacement="auto"
                                className="multiselect"
                                classNamePrefix={"my-custom-react-select"}
                                name="cost_payable_at_slug"
                                value={data.selected_cost_at}
                                options={this.state.newCostPayableAtList}
                                defaultValue={data.selected_cost_at}
                                onChange={(value) =>
                                  this.handleChangeSelectTwo(i, value)
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                            </Col>
                          </td>
                          <td className="w-2">
                            {" "}
                            <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                              <InputGroup className="mt-0 m-b-1">
                                <div className="input-group-prepend prefix-dollar">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    name="cost_name"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    value={
                                      data.cost_name
                                        ? data.cost_name
                                            .replace(/\.00$/, "")
                                            .replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            ) || ""
                                        : ""
                                    }
                                    onChange={(event) =>
                                      this.handleChangeCost(i, event)
                                    }
                                  />
                                </div>
                              </InputGroup>
                            </Col>
                          </td>
                          <td className="w-3">
                            {" "}
                            <Col
                              xs={12}
                              className="pd-l-5 pd-r-5 pd-t-2 align-items-center colmn2"
                            >
                              <div className="ckbox form-check">
                                <input
                                  type="checkbox"
                                  id={`refundable_` + i}
                                  name="refundable"
                                  className="form-check-input ml-0 w-auto"
                                  onChange={(event) =>
                                    this.handleChangeCost(i, event)
                                  }
                                  checked={data.refundable}
                                />
                                <label
                                  title=""
                                  htmlFor={`refundable_` + i}
                                  className="form-check-label"
                                ></label>
                              </div>
                            </Col>
                          </td>
                          <td className="w-3">
                            {" "}
                            <Col
                              xs={12}
                              className="pd-l-5 pd-r-5 pd-t-2 colmn2"
                            >
                              <div className="ckbox form-check">
                                <input
                                  type="checkbox"
                                  id={`estimated_` + i}
                                  name="estimated"
                                  className="form-check-input ml-0 w-auto"
                                  onChange={(event) =>
                                    this.handleChangeCost(i, event)
                                  }
                                  checked={data.estimated}
                                />
                                <label
                                  htmlFor={`estimated_` + i}
                                  className="form-check-label"
                                ></label>
                              </div>
                            </Col>
                          </td>
                          <td className="w-3">
                            {" "}
                            <Col
                              xs={12}
                              className="pd-l-2 pd-r-2 pd-t-2 colmn2"
                            >
                              <div className="ckbox form-check">
                                <input
                                  type="checkbox"
                                  id={`mandatory_` + i}
                                  name="mandatory"
                                  className="form-check-input ml-0 w-auto"
                                  onChange={(event) =>
                                    this.handleChangeCost(i, event)
                                  }
                                  checked={data.mandatory}
                                />
                                <label
                                  htmlFor={`mandatory_` + i}
                                  className="form-check-label"
                                ></label>
                              </div>
                            </Col>
                          </td>
                          <td className="w-3">
                            <Col
                              xs={12}
                              className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                            >
                              {this.state.FeeTypeDataTotal === 1 && i === 0 ? (
                                <>
                                  {data.slug ? (
                                    <div className="d-flex  justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                      <button
                                        onClick={() => {
                                          this.handleRemoveFieldsCost(i);
                                          this.deleteCost(data.slug);
                                        }}
                                        className={"btn"}
                                      >
                                        <img src={deleteicon} alt="Delete" />
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                    <button
                                      onClick={this.handleAddFieldsCost}
                                      className="btn"
                                    >
                                      <img src={pluswIcon} alt="Add" />
                                    </button>
                                  </div>
                                </>
                              ) : i < this.state.FeeTypeDataTotal - 1 ? (
                                <>
                                  <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                    <button
                                      onClick={() => {
                                        this.handleRemoveFieldsCost(i);
                                        this.deleteCost(data.slug);
                                      }}
                                      className={"btn"}
                                    >
                                      <img src={deleteicon} alt="Delete" />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {this.state.FeeTypeDataTotal !== 0 ? (
                                    <>
                                      <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                        <button
                                          onClick={() => {
                                            this.handleRemoveFieldsCost(i);
                                            this.deleteCost(data.slug);
                                          }}
                                          className={"btn"}
                                        >
                                          <img src={deleteicon} alt="Delete" />
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                    <button
                                      onClick={this.handleAddFieldsCost}
                                      className="btn"
                                    >
                                      <img src={pluswIcon} alt="Add" />
                                    </button>
                                  </div>
                                </>
                              )}
                            </Col>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>{" "}
              <div className="col-lg-12  pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">
                  Occupancy Tax Distribution
                </h3>
              </div>
              <div className="table-responsive pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <Table hover className="table_heading_top">
                  <thead>
                    <tr>
                      <th className="w-30">Name</th>
                      <th className="w-30 text-left">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.taxListTotal > 0 ||
                    this.state.taxOccupancyListTotal > 0 ? (
                      <React.Fragment>
                        {this.state.taxList.map((option, i) => {
                          var aa = option.tax_type_name
                            ? option.tax_type_name
                            : option.tax_type
                            ? option.tax_type.tax_type_name
                            : "";
                          return (
                            <tr key={i}>
                              <td>
                                {option.property_level_tax_name
                                  ? option.property_level_tax_name
                                  : ""}
                              </td>

                              <td className="text-left">
                                {option.property_amount
                                  ? aa.includes("$")
                                    ? "$" +
                                      global.onKeyPressEvent.numberWithCommasForDecimal(
                                        parseFloat(option.property_amount)
                                          .toFixed(2)
                                          .replace(/\.00$/, "")
                                          .replace(
                                            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                            ""
                                          )
                                      )
                                    : parseFloat(option.property_amount)
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                        .replace(
                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                          ""
                                        ) + "%"
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          There are no occupancy taxes added in the system.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* {this.state.viewMode === 2 ? (
            <div className="m-0">
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">
                  Discounts: Length of Stay
                </h3>
              </div>
              <div className="row align-items-basline pd-b-0 border-bottom-0 pd-r-15 pd-lg-l-0 pd-xs-l-15">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">From</label>

                  <DatePicker
                    className="form-control max_width_100"
                    disabledKeyboardNavigation
                    strictParsing
                    scrollableYearDropdown
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    excludeDates={this.state.los_disabled_dates}
                    //maxDate={this.state.to_date}
                    name="los_from_date"
                    dateFormat="MM/dd/yy"
                    minDate={
                      this.state.listing_from_date
                        ? this.state.listing_from_date
                        : new Date()
                    }
                    maxDate={
                      this.state.listing_to_date
                        ? this.state.listing_to_date
                        : ""
                    }
                    // maxDate={this.state.to_date}
                    selected={this.state.los_from_date}
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "los_from_date",
                          value: data,
                        },
                      })
                    }
                  />
                  {this.state.errors.los_from_date ? (
                    <div className="text-danger">
                      {this.state.errors.los_from_date}
                    </div>
                  ) : (
                    this.validator.message(
                      "from_date",
                      this.state.los_from_date,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">To</label>
                  <DatePicker
                    className="form-control max_width_100"
                    disabledKeyboardNavigation
                    strictParsing
                    scrollableYearDropdown
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    excludeDates={this.state.los_disabled_dates}
                    minDate={
                      this.state.los_from_date
                        ? this.state.los_from_date
                        : new Date()
                    }
                    maxDate={
                      this.state.listing_to_date
                        ? this.state.listing_to_date
                        : ""
                    }
                    name="los_to_date"
                    dateFormat="MM/dd/yy"
                    selected={this.state.los_to_date}
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "los_to_date",
                          value: data,
                        },
                      })
                    }
                  />
                  {this.state.errors.los_to_date ? (
                    <div className="text-danger">
                      {this.state.errors.los_to_date}
                    </div>
                  ) : (
                    this.validator.message(
                      "to_date",
                      this.state.los_to_date,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    Stays From (days)
                  </label>

                  <Form.Control
                    type="text"
                    onChange={this.changeHandler}
                    name="stays_from"
                    value={
                      this.state.stays_from ? this.state.stays_from : "" || ""
                    }
                    maxLength="10"
                    onKeyPress={global.onKeyPressEvent.numberValidation}
                  />
                  {this.state.errors.stays_from ? (
                    <div className="text-danger">
                      {this.state.errors.stays_from}
                    </div>
                  ) : (
                    this.validator.message(
                      "stays_from (days)",
                      this.state.stays_from,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    Stays Up To (days)
                  </label>
                  <Form.Control
                    type="text"
                    onChange={this.changeHandler}
                    name="stays_upto"
                    value={
                      this.state.stays_upto ? this.state.stays_upto : "" || ""
                    }
                    maxLength="10"
                    onKeyPress={global.onKeyPressEvent.numberValidation}
                  />
                  {this.state.errors.stays_upto ? (
                    <div className="text-danger">
                      {this.state.errors.stays_upto}
                    </div>
                  ) : (
                    this.validator.message(
                      "stays_up_to (days)",
                      this.state.stays_upto,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    Discount
                  </label>
                  <div className="input-group-prepend prefix-dollar percanteg-prefix">
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="discount"
                      value={
                        this.state.discount ? this.state.discount : "" || ""
                      }
                      maxLength="10"
                      onKeyPress={global.onKeyPressEvent.floatValidation}
                    />
                    <span className="input-group-text percanteg_symbl">%</span>
                  </div>
                  {this.state.errors.discount ? (
                    <div className="text-danger">
                      {this.state.errors.discount}
                    </div>
                  ) : (
                    this.validator.message(
                      "discount",
                      this.state.discount,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}

          {/* {this.state.viewMode === 3 ? (
            <div className="m-0">
              <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">Seasonal Price</h3>
              </div>
              <div className="row align-items-basline pd-b-0 border-bottom-0 pd-r-15 pd-lg-l-0 pd-xs-l-15">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">From</label>

                  <DatePicker
                    className="form-control max_width_100"
                    disabledKeyboardNavigation
                    strictParsing
                    scrollableYearDropdown
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    excludeDates={this.state.disabled_dates}
                    //maxDate={this.state.to_date}
                    name="from_date"
                    dateFormat="MM/dd/yy"
                    minDate={
                      this.state.listing_from_date
                        ? this.state.listing_from_date
                        : new Date()
                    }
                    maxDate={
                      this.state.listing_to_date
                        ? this.state.listing_to_date
                        : ""
                    }
                    // maxDate={this.state.to_date}
                    selected={this.state.from_date}
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "from_date",
                          value: data,
                        },
                      })
                    }
                  />
                  {this.state.errors.from_date ? (
                    <div className="text-danger">
                      {this.state.errors.from_date}
                    </div>
                  ) : (
                    this.validator.message(
                      "from_date",
                      this.state.from_date,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">To</label>
                  <DatePicker
                    className="form-control max_width_100"
                    disabledKeyboardNavigation
                    strictParsing
                    scrollableYearDropdown
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    excludeDates={this.state.disabled_dates}
                    minDate={
                      this.state.from_date ? this.state.from_date : new Date()
                    }
                    maxDate={
                      this.state.listing_to_date
                        ? this.state.listing_to_date
                        : ""
                    }
                    name="to_date"
                    dateFormat="MM/dd/yy"
                    selected={this.state.to_date}
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "to_date",
                          value: data,
                        },
                      })
                    }
                  />
                  {this.state.errors.to_date ? (
                    <div className="text-danger">
                      {this.state.errors.to_date}
                    </div>
                  ) : (
                    this.validator.message(
                      "to_date",
                      this.state.to_date,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <label className="form-label text-left pd-b-5">
                    Nightly Rate
                  </label>
                  <div className="input-group-prepend prefix-dollar">
                    <span className="input-group-text">$</span>
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="special_price"
                      value={
                        this.state.special_price
                          ? this.state.special_price.replace(
                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                              ""
                            )
                          : "" || ""
                      }
                      maxLength="10"
                      onKeyPress={global.onKeyPressEvent.floatValidation}
                    />
                  </div>
                  {this.state.errors.special_price ? (
                    <div className="text-danger">
                      {this.state.errors.special_price}
                    </div>
                  ) : (
                    this.validator.message(
                      "nightly rate",
                      this.state.special_price,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}
        </div>
        <Modal
          show={this.state.deleteUnavailableDateModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closeDeleteSpecialPriceModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeDeleteSpecialPriceModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={deleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className=" m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteSpecialPriceModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteUnavailableDate(
                      this.state.fromDate,
                      this.state.toDate,
                      this.state.priceType
                    )
                  }
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteUnitCostModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteUnitCostModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteUnitCostModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCost(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.publishListingModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closedeleteListingModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closePublishListingModal()}
              >
                {global.closee}
              </span>
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              {/* <h3>Publish to OTA?</h3> */}
              <h3></h3>
              <p>
                {this.state.publishMessage
                  ? this.state.publishMessage
                  : "Listing is successfully updated! Your changes are not published to OTA yet, do you want to publish now ? You can still publish from Sync tab later."}
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block button-width"
                  onClick={() => this.closePublishListingModal()}
                >
                  Sync Later
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block button-width"
                  onClick={this.publishListing}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Sync Now"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/** Seasonal Price Add Model **/}
        <Modal
          show={this.state.addSeasonalPriceModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddSeasonalPriceModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddSeasonalPriceModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Seasonal Price</h3>
            <InputGroup className="mt-3">
              <label>From</label>
              <DatePicker
                className="form-control max_width_100"
                disabledKeyboardNavigation
                strictParsing
                scrollableYearDropdown
                autoComplete="off"
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={global.yearDropdownItemNumber}
                excludeDates={this.state.disabled_dates}
                //maxDate={this.state.to_date}
                name="from_date"
                dateFormat="MM/dd/yy"
                minDate={
                  this.state.listing_from_date
                    ? this.state.listing_from_date
                    : new Date()
                }
                maxDate={
                  this.state.listing_to_date ? this.state.listing_to_date : ""
                }
                // maxDate={this.state.to_date}
                selected={this.state.from_date}
                onChange={(data) =>
                  this.dateSeasonalPriceChangeHandler({
                    target: {
                      type: "date",
                      name: "from_date",
                      value: data,
                    },
                  })
                }
              />
            </InputGroup>
            {this.state.errors.from_date ? (
              <div className="text-danger">{this.state.errors.from_date}</div>
            ) : (
              this.validator.message(
                "from_date",
                this.state.from_date,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>To</label>
              <div className="col-md-12 p-0">
                <DatePicker
                  className="form-control max_width_100"
                  disabledKeyboardNavigation
                  strictParsing
                  scrollableYearDropdown
                  autoComplete="off"
                  showMonthDropdown
                  showYearDropdown
                  yearDropdownItemNumber={global.yearDropdownItemNumber}
                  excludeDates={this.state.disabled_dates}
                  minDate={
                    this.state.from_date ? this.state.from_date : new Date()
                  }
                  maxDate={
                    this.state.maxCheckoutDate
                      ? this.state.maxCheckoutDate
                      : this.state.listing_to_date
                      ? this.state.listing_to_date
                      : ""
                  }
                  name="to_date"
                  dateFormat="MM/dd/yy"
                  selected={this.state.to_date}
                  onChange={(data) =>
                    this.dateSeasonalPriceChangeHandler({
                      target: {
                        type: "date",
                        name: "to_date",
                        value: data,
                      },
                    })
                  }
                />
              </div>
            </InputGroup>
            {this.state.errors.to_date ? (
              <div className="text-danger">{this.state.errors.to_date}</div>
            ) : (
              this.validator.message(
                "to_date",
                this.state.to_date,
                "required",
                { className: "text-danger" }
              )
            )}
            <InputGroup className="mt-3">
              <label>Nightly Rate</label>
              <div className="col-md-12 p-0">
                <div className="input-group-prepend prefix-dollar">
                  <span className="input-group-text">$</span>
                  <Form.Control
                    type="text"
                    onChange={this.seasonalPriceChangeHandler}
                    name="special_price"
                    value={
                      this.state.special_price
                        ? this.state.special_price.replace(
                            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                            ""
                          )
                        : "" || ""
                    }
                    maxLength="10"
                    onKeyPress={global.onKeyPressEvent.floatValidation}
                  />
                </div>
              </div>
            </InputGroup>
            {this.state.errors.special_price ? (
              <div className="text-danger">
                {this.state.errors.special_price}
              </div>
            ) : (
              this.validator.message(
                "nightly rate",
                this.state.special_price,
                "required",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddSeasonalPriceModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveSpecialPrice}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Edit Seasonal Price Modal */}
        <Modal
          show={this.state.editSeasonalPriceModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditSeasonalPriceModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditSeasonalPriceModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Seasonal Price</h3>
            <InputGroup className="mt-3">
              <label>From</label>
              <DatePicker
                className="form-control max_width_100"
                disabledKeyboardNavigation
                strictParsing
                scrollableYearDropdown
                autoComplete="off"
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={global.yearDropdownItemNumber}
                excludeDates={this.state.disabled_dates}
                name="from_date"
                dateFormat="MM/dd/yy"
                minDate={
                  this.state.listing_from_date
                    ? this.state.listing_from_date
                    : new Date()
                }
                maxDate={
                  this.state.listing_to_date ? this.state.listing_to_date : ""
                }
                selected={this.state.from_date}
                onChange={(data) =>
                  this.dateSeasonalPriceChangeHandler({
                    target: {
                      type: "date",
                      name: "from_date",
                      value: data,
                    },
                  })
                }
              />
            </InputGroup>
            {this.state.errors.from_date ? (
              <div className="text-danger">{this.state.errors.from_date}</div>
            ) : (
              this.validator.message(
                "from_date",
                this.state.from_date,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>To</label>
              <DatePicker
                className="form-control max_width_100"
                disabledKeyboardNavigation
                strictParsing
                scrollableYearDropdown
                autoComplete="off"
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={global.yearDropdownItemNumber}
                excludeDates={this.state.disabled_dates}
                minDate={
                  this.state.from_date ? this.state.from_date : new Date()
                }
                maxDate={
                  this.state.maxCheckoutDate
                    ? this.state.maxCheckoutDate
                    : this.state.listing_to_date
                    ? this.state.listing_to_date
                    : ""
                }
                name="to_date"
                dateFormat="MM/dd/yy"
                selected={this.state.to_date}
                onChange={(data) =>
                  this.dateSeasonalPriceChangeHandler({
                    target: {
                      type: "date",
                      name: "to_date",
                      value: data,
                    },
                  })
                }
              />
            </InputGroup>
            {this.state.errors.to_date ? (
              <div className="text-danger">{this.state.errors.to_date}</div>
            ) : (
              this.validator.message(
                "to_date",
                this.state.to_date,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mb-3 mt-4">
              <label>Nightly Rate</label>
              <div className="col-md-12 p-0">
                <div className="input-group-prepend prefix-dollar">
                  <span className="input-group-text">$</span>
                  <Form.Control
                    type="text"
                    onChange={this.seasonalPriceChangeHandler}
                    name="special_price"
                    value={
                      this.state.special_price
                        ? this.state.special_price.replace(
                            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                            ""
                          )
                        : "" || ""
                    }
                    maxLength="10"
                    onKeyPress={global.onKeyPressEvent.floatValidation}
                  />
                </div>
              </div>
            </InputGroup>
            {this.state.errors.special_price ? (
              <div className="text-danger">
                {this.state.errors.special_price}
              </div>
            ) : (
              this.validator.message(
                "nightly rate",
                this.state.special_price,
                "required",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditSeasonalPriceModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.updateSpecialPrice}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/** Add Discounts: Length of Stay Model **/}
        <Modal
          show={this.state.addLOSDiscountModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddLOSDiscountModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddLOSDiscountModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Discounts: Length of Stay</h3>
            <InputGroup className="mt-3">
              <label>From</label>
              <DatePicker
                className="form-control max_width_100"
                disabledKeyboardNavigation
                strictParsing
                scrollableYearDropdown
                autoComplete="off"
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={global.yearDropdownItemNumber}
                excludeDates={this.state.los_disabled_dates}
                //maxDate={this.state.to_date}
                name="los_from_date"
                dateFormat="MM/dd/yy"
                minDate={
                  this.state.listing_from_date
                    ? this.state.listing_from_date
                    : new Date()
                }
                maxDate={
                  this.state.listing_to_date ? this.state.listing_to_date : ""
                }
                // maxDate={this.state.to_date}
                selected={this.state.los_from_date}
                onChange={(data) =>
                  this.dateLOSDiscountChangeHandler({
                    target: {
                      type: "date",
                      name: "los_from_date",
                      value: data,
                    },
                  })
                }
              />
            </InputGroup>
            {this.state.errors.los_from_date ? (
              <div className="text-danger">
                {this.state.errors.los_from_date}
              </div>
            ) : (
              this.validator.message(
                "from date",
                this.state.los_from_date,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>To</label>
              <DatePicker
                className="form-control max_width_100"
                disabledKeyboardNavigation
                strictParsing
                scrollableYearDropdown
                autoComplete="off"
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={global.yearDropdownItemNumber}
                excludeDates={this.state.los_disabled_dates}
                minDate={
                  this.state.los_from_date
                    ? this.state.los_from_date
                    : new Date()
                }
                maxDate={
                  this.state.maxCheckoutDate
                    ? this.state.maxCheckoutDate
                    : this.state.listing_to_date
                    ? this.state.listing_to_date
                    : ""
                }
                name="los_to_date"
                dateFormat="MM/dd/yy"
                selected={this.state.los_to_date}
                onChange={(data) =>
                  this.dateLOSDiscountChangeHandler({
                    target: {
                      type: "date",
                      name: "los_to_date",
                      value: data,
                    },
                  })
                }
              />
            </InputGroup>
            {this.state.errors.los_to_date ? (
              <div className="text-danger">{this.state.errors.los_to_date}</div>
            ) : (
              this.validator.message(
                "to date",
                this.state.los_to_date,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Stays From (days)</label>
              <Form.Control
                type="text"
                onChange={this.losDiscountChangeHandler}
                name="stays_from"
                value={this.state.stays_from ? this.state.stays_from : "" || ""}
                maxLength="10"
                onKeyPress={global.onKeyPressEvent.numberValidation}
              />
            </InputGroup>
            {this.state.errors.stays_from ? (
              <div className="text-danger">{this.state.errors.stays_from}</div>
            ) : (
              this.validator.message(
                "stays_from (days)",
                this.state.stays_from,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Stays Up To (days)</label>
              <Form.Control
                type="text"
                onChange={this.losDiscountChangeHandler}
                name="stays_upto"
                value={this.state.stays_upto ? this.state.stays_upto : "" || ""}
                maxLength="10"
                onKeyPress={global.onKeyPressEvent.numberValidation}
              />
            </InputGroup>
            {this.state.errors.stays_upto ? (
              <div className="text-danger">{this.state.errors.stays_upto}</div>
            ) : (
              this.validator.message(
                "stays_up_to (days)",
                this.state.stays_upto,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Discount</label>
              <div className="col-md-12 p-0">
                <div className="input-group-prepend prefix-dollar percanteg-prefix">
                  <Form.Control
                    type="text"
                    onChange={this.losDiscountChangeHandler}
                    name="discount"
                    value={this.state.discount ? this.state.discount : "" || ""}
                    maxLength="10"
                    onKeyPress={global.onKeyPressEvent.floatValidation}
                  />
                  <span className="input-group-text percanteg_symbl">%</span>
                </div>{" "}
              </div>
            </InputGroup>
            {this.state.errors.discount ? (
              <div className="text-danger">{this.state.errors.discount}</div>
            ) : (
              this.validator.message(
                "discount",
                this.state.discount,
                "required",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddLOSDiscountModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveLOSDiscount}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Edit Discounts: Length of Stay Modal */}
        <Modal
          show={this.state.editLOSDiscountModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditLOSDiscountModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditLOSDiscountModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Discounts: Length of Stay</h3>

            <InputGroup className="mt-3">
              <label>From</label>
              <DatePicker
                className="form-control max_width_100"
                disabledKeyboardNavigation
                strictParsing
                scrollableYearDropdown
                autoComplete="off"
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={global.yearDropdownItemNumber}
                excludeDates={this.state.los_disabled_dates}
                //maxDate={this.state.to_date}
                name="los_from_date"
                dateFormat="MM/dd/yy"
                minDate={
                  this.state.listing_from_date
                    ? this.state.listing_from_date
                    : new Date()
                }
                maxDate={
                  this.state.listing_to_date ? this.state.listing_to_date : ""
                }
                // maxDate={this.state.to_date}
                selected={this.state.los_from_date}
                onChange={(data) =>
                  this.dateLOSDiscountChangeHandler({
                    target: {
                      type: "date",
                      name: "los_from_date",
                      value: data,
                    },
                  })
                }
              />
            </InputGroup>
            {this.state.errors.los_from_date ? (
              <div className="text-danger">
                {this.state.errors.los_from_date}
              </div>
            ) : (
              this.validator.message(
                "from date",
                this.state.los_from_date,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>To</label>
              <DatePicker
                className="form-control max_width_100"
                disabledKeyboardNavigation
                strictParsing
                scrollableYearDropdown
                autoComplete="off"
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={global.yearDropdownItemNumber}
                excludeDates={this.state.los_disabled_dates}
                minDate={
                  this.state.los_from_date
                    ? this.state.los_from_date
                    : new Date()
                }
                maxDate={
                  this.state.maxCheckoutDate
                    ? this.state.maxCheckoutDate
                    : this.state.listing_to_date
                    ? this.state.listing_to_date
                    : ""
                }
                name="los_to_date"
                dateFormat="MM/dd/yy"
                selected={this.state.los_to_date}
                onChange={(data) =>
                  this.dateLOSDiscountChangeHandler({
                    target: {
                      type: "date",
                      name: "los_to_date",
                      value: data,
                    },
                  })
                }
              />
            </InputGroup>
            {this.state.errors.los_to_date ? (
              <div className="text-danger">{this.state.errors.los_to_date}</div>
            ) : (
              this.validator.message(
                "to date",
                this.state.los_to_date,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Stays From (days)</label>
              <Form.Control
                type="text"
                onChange={this.losDiscountChangeHandler}
                name="stays_from"
                value={this.state.stays_from ? this.state.stays_from : "" || ""}
                maxLength="10"
                onKeyPress={global.onKeyPressEvent.numberValidation}
              />
            </InputGroup>
            {this.state.errors.stays_from ? (
              <div className="text-danger">{this.state.errors.stays_from}</div>
            ) : (
              this.validator.message(
                "stays from (days)",
                this.state.stays_from,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Stays Up To (days)</label>
              <Form.Control
                type="text"
                onChange={this.losDiscountChangeHandler}
                name="stays_upto"
                value={this.state.stays_upto ? this.state.stays_upto : "" || ""}
                maxLength="10"
                onKeyPress={global.onKeyPressEvent.numberValidation}
              />
            </InputGroup>
            {this.state.errors.stays_upto ? (
              <div className="text-danger">{this.state.errors.stays_upto}</div>
            ) : (
              this.validator.message(
                "stays up to (days)",
                this.state.stays_upto,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Discount</label>
              <div className="col-md-12 p-0">
                <div className="input-group-prepend prefix-dollar percanteg-prefix">
                  <Form.Control
                    type="text"
                    onChange={this.losDiscountChangeHandler}
                    name="discount"
                    value={this.state.discount ? this.state.discount : "" || ""}
                    maxLength="10"
                    onKeyPress={global.onKeyPressEvent.floatValidation}
                  />
                  <span className="input-group-text percanteg_symbl">%</span>
                </div>{" "}
              </div>
            </InputGroup>
            {this.state.errors.discount ? (
              <div className="text-danger">{this.state.errors.discount}</div>
            ) : (
              this.validator.message(
                "discount",
                this.state.discount,
                "required",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditLOSDiscountModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.updateLOSDiscount}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ListingFees;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: NearByAttractionService.js                   //
//  Application: Misc                                     //
//  Option: Used for manage near by attraction            //
//  Developer: Ashish Kumar          					  //
//  Date: 2021-01-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class EcommerceService {

  getEcommerce(queryString) {
    let endPoint = "get-ecommerce"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
 
  createEcommerce(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "post-ecommerce";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getEcommerceBySlug(slug) {
    let endPoint = "get-ecommerce/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateEcommerce(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "post-ecommerce/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteEcommerce(slug) {
    let endPoint = "ecommerce-delete/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadEcommerceImage(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'upload-ecommerce-image';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
    let response = ApiService.postCall(inputData);
    return response;
  }

  //for property 
  getPropertyNearByAttraction(slug) {
    let endPoint = "get-property-near-by-attraction/"+ slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  attachPropertyNearByAttraction(slug,data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "attach-property-near-by-attraction/"+slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  detachPropertyNearByAttraction(slug,data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "detach-property-near-by-attraction/"+slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new EcommerceService();

///////////////////////////////////////////////
//     							                         //
//  Program: TimezoneService.js                     //
//  Application: Services                    //
//  Option: Used for Unity type list         //
//  Developer: Ashish Kumar                  //
//  Date: 2022-01-04                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class TimezoneService {
  getTimezone(queryString) {
    let endPoint = "timezone"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createTimezone(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "timezone";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getTimezoneBySlug(slug) {
    let endPoint = "timezone/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateTimezone(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "timezone/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteTimezone(slug) {
    let endPoint = "timezone/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new TimezoneService();

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ClientView.jsx                               //
//  Application: Client                                   //
//  Option: For view Client details                        //
//  Developer: NP				                                  //
//  Date: 2022-11-22                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClientService from "../../services/ClientService";
import companyLogo from "./../../assets/images/company_placeholder.png";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import ClientSidebar from "../common/ClientSidebar";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";

export class ClientView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: this.props.match.params.slug,
      showSpinner: false,
      loading: true,
      records: [],
      status_id: "",
    };
    this.closeModal = this.closeModal.bind(this);
    this.deleteBrand = this.deleteBrand.bind(this);
    this.opendeleteBrandModal = this.opendeleteBrandModal.bind(this);
    this.fullText = this.fullText.bind(this);
    this.fullText2 = this.fullText2.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      var phoneNumber = "";
      var orionHausPhoneNumber = "";
      let res = await ClientService.getClient(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data ? res.data : {};
        resData.slug = res.data.slug ? res.data.slug : "";
        resData.client_name = res.data.client_name ? res.data.client_name : "";
        resData.client_logo_thumb_url = res.data.client_logo_thumb_url
          ? res.data.client_logo_thumb_url
          : "";
        resData.aka_name = res.data.aka_name ? res.data.aka_name : "";
        resData.legal_entity_name = res.data.legal_entity_name
          ? res.data.legal_entity_name
          : "";
        resData.tax_id = res.data.tax_id ? res.data.tax_id : "";
        resData.contact_name = res.data.contact_name
          ? res.data.contact_name
          : "";
        resData.phone_number = res.data.phone_number
          ? res.data.phone_number
          : "";
        if (res.data && res.data.phone_number) {
          phoneNumber = await this.phoneNumberAutoFormat(res.data.phone_number);
        }
        if (
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.phone_number
        ) {
          orionHausPhoneNumber = await this.phoneNumberAutoFormat(
            res.data.orion_haus_account_manager.phone_number
          );
        }
        resData.email = res.data.email ? res.data.email : "";
        resData.website_cookie = res.data.website_cookie
          ? res.data.website_cookie
          : "";
        resData.term_condition = res.data.term_condition
          ? res.data.term_condition
          : "";

        resData.total_brand_count = res.data.total_brand_count
          ? res.data.total_brand_count
          : "";
        resData.total_property_count = res.data.total_property_count
          ? res.data.total_property_count
          : "";
        resData.total_unit_count = res.data.total_unit_count
          ? res.data.total_unit_count
          : "";

        resData.orion_haus_account_manager_name =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.first_name
            ? res.data.orion_haus_account_manager.first_name
            : "";
        resData.orion_haus_email =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.email
            ? res.data.orion_haus_account_manager.email
            : "";
        resData.orion_haus_phone_number =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.phone_number
            ? res.data.orion_haus_account_manager.phone_number
            : "";
      }
      this.setState(resData);
      this.setState({
        phoneNumber: phoneNumber,
        orionHausPhoneNumber: orionHausPhoneNumber,
      });
    }
    this.setState({ showSpinner: false });
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    return finalnumber;
  };

  opendeleteBrandModal(slug) {
    this.setState({ deleteBrandModal: true });
  }

  closedeleteBrandModal() {
    this.setState({ deleteBrandModal: false });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullText2() {
    if (this.state.descShow2 === 0) {
      this.setState({ descShow2: 1 });
    } else {
      this.setState({ descShow2: 0 });
    }
  }

  getTitle() {
    return "Brand Detail";
  }

  async deleteBrand(slug) {
    this.setState({ showSpinner: true });
    let res = await ClientService.deleteClient(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteBrandModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        records: this.state.records.filter((list) => list.slug !== slug),
      });
      setTimeout(
        () => this.props.history.push("/brands"),
        global.redirect_time
      );
    } else {
      this.closedeleteBrandModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  render() {
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
          <Container className="innter-container ">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <ClientSidebar clientSlug={this.state.slug} />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="scrolling-carousel">
                    <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                      <div className="row row-xs">
                        <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                        <div className="col-md-12">
                          <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                            <h3 className="mb-0 brnd_heading">
                              {this.state.client_name
                                ? this.state.client_name
                                : ""}
                            </h3>
                            <div className="d-flex flex-wrap- bothBtn align-items-center">
                              {global.userPermissions.checkPermission(
                                "clients-delete"
                              ) && (
                                <span
                                  onClick={() => {
                                    this.opendeleteBrandModal(this.state.slug);
                                  }}
                                  className="deleteicon mg-r-20"
                                >
                                  <img src={deleteicon} alt="" />
                                </span>
                              )}

                              {global.userPermissions.checkPermission(
                                "clients-update"
                              ) && (
                                <Link to={"/client/update/" + this.state.slug}>
                                  <button className="btn-success-outline">
                                    <img src={editIcon} alt="" />
                                    Edit
                                  </button>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                      <div className="row row-xs mg-t-30 pd-t-2 cardsection">
                        <div className="col-md-4 col-lg-3 mg-b-30 cardLeft">
                          <div className="dashboardLeftcard az-profile-overview">
                            <div className="az-img-user ml-auto mr-auto align-items-center mt-0">
                              <figure>
                                <img
                                  src={
                                    this.state.client_logo_thumb_url
                                      ? this.state.client_logo_thumb_url
                                      : companyLogo
                                  }
                                  alt=""
                                />
                              </figure>
                            </div>
                            {/* az-img-user */}

                            <div className="d-flex justify-content-center text-center mg-b-20 mg-t-20">
                              <div>
                                <p className="az-profile-name-text">DBA</p>
                                <p className="media-body">
                                  {this.state.client_name
                                    ? this.state.client_name
                                    : ""}
                                </p>

                                <p className="az-profile-name-text">
                                  Client SKU
                                </p>
                                <p className="media-body">
                                  {this.state.aka_name
                                    ? this.state.aka_name
                                    : ""}
                                </p>

                                <p className="az-profile-name-text">
                                  Legal Entity
                                </p>
                                <p className="media-body">
                                  {this.state.legal_entity_name
                                    ? this.state.legal_entity_name
                                    : ""}
                                </p>

                                <p className="az-profile-name-text">Tax ID</p>
                                <p className="media-body">
                                  {this.state.tax_id ? this.state.tax_id : ""}
                                </p>

                                {/* manager info start */}
                                <div className="overview">
                                  <h3 className="media-body mg-t-30 mb-2">
                                    Orion Haus Account Manager
                                  </h3>
                                </div>
                                <p className="media-body p-0 m-0">
                                  {this.state.orion_haus_account_manager_name
                                    ? this.state.orion_haus_account_manager_name
                                    : ""}
                                </p>
                                <p className="media-body p-0 m-0">
                                  {this.state.orion_haus_email
                                    ? this.state.orion_haus_email
                                    : ""}
                                </p>
                                <p className="media-body p-0 m-0">
                                  {this.state.orionHausPhoneNumber
                                    ? this.state.orionHausPhoneNumber
                                    : ""}
                                </p>
                                {/* manager info end */}
                              </div>
                            </div>
                          </div>
                          {/* az-profile-overview */}
                        </div>

                        <div className="col-md-8 col-lg-9 mg-b-30 cardRight">
                          <div className="dashboardRightcard overview p-0">
                            <div className="row">
                              <div className="col-md-12 pd-l-15 pd-r-15 mg-b-15">
                                <div className="dashbaord-pageHeadertitle overview-title d-flex flex-wrap- justify-content-between align-items-center rounded-0">
                                  <div className="col-md-4">
                                    <h3>
                                      {this.state.total_brand_count
                                        ? this.state.total_brand_count
                                        : "0"}{" "}
                                      {this.state.total_brand_count === 0 ||
                                      this.state.total_brand_count === 1
                                        ? "Brand"
                                        : "Brands"}
                                    </h3>
                                  </div>
                                  <div className="col-md-4">
                                    <h3>
                                      {this.state.total_property_count
                                        ? this.state.total_property_count
                                        : "0"}{" "}
                                      {this.state.total_property_count === 0 ||
                                      this.state.total_property_count === 1
                                        ? "Property"
                                        : "Properties"}
                                    </h3>
                                  </div>
                                  <div className="col-md-4">
                                    {" "}
                                    <h3>
                                      {this.state.total_unit_count
                                        ? this.state.total_unit_count
                                        : "0"}{" "}
                                      {this.state.total_unit_count === 0 ||
                                      this.state.total_unit_count === 1
                                        ? "Unit"
                                        : "Units"}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mg-t-0 mg-md-t-0 pd-20 pd-t-30 pd-b-30 ml-0 overview-section">
                              <div className="dashboardRightcard overviewSection">
                                <div className="row m-0 mg-t-0 mg-md-t-0">
                                  <div className="col-md-3">
                                    <p className="az-profile-name-text pl-0">
                                      Contact Name
                                    </p>
                                    <p className="media-body">
                                      {this.state.contact_name
                                        ? this.state.contact_name
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-md-3">
                                    <p className="az-profile-name-text pl-0">
                                      Phone
                                    </p>
                                    <p className="media-body">
                                      {this.state.phoneNumber
                                        ? this.state.phoneNumber
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-md-3">
                                    <p className="az-profile-name-text pl-0">
                                      Email
                                    </p>
                                    <p className="media-body">
                                      {this.state.email ? this.state.email : ""}
                                    </p>
                                  </div>
                                </div>

                                <hr className="mg-t-10 mg-b-30 pd-b-2" />

                                <div className="row m-0 mg-t-0 mg-md-t-0">
                                  <div className="col-md-12">
                                    <p className="az-profile-name-text pl-0">
                                      Website Cookies
                                    </p>
                                    <div className="para">
                                      {this.state.website_cookie ? ( //check if website_cookie is exist
                                        <p className="p_wrap1">
                                          {this.state.descShow
                                            ? this.state.website_cookie.slice(
                                                0,
                                                global.showMoreLength
                                              )
                                            : this.state.website_cookie}

                                          {this.state.website_cookie.length >
                                            global.showMoreLength && (
                                            <span onClick={this.fullText}>
                                              {this.state.descShow ? (
                                                <p className="hand-cursor showmore">
                                                  ...Show more{" "}
                                                </p>
                                              ) : (
                                                <p className="hand-cursor showmore">
                                                  Show Less{" "}
                                                </p>
                                              )}
                                            </span>
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <p className="az-profile-name-text pl-0">
                                      Terms & Conditions
                                    </p>
                                    <div className="para">
                                      {this.state.term_condition ? ( //check if term_condition is exist
                                        <p className="p_wrap1">
                                          {this.state.descShow
                                            ? this.state.term_condition.slice(
                                                0,
                                                global.showMoreLength
                                              )
                                            : this.state.term_condition}

                                          {this.state.term_condition.length >
                                            global.showMoreLength && (
                                            <span onClick={this.fullText}>
                                              {this.state.descShow ? (
                                                <p className="hand-cursor showmore">
                                                  ...Show more{" "}
                                                </p>
                                              ) : (
                                                <p className="hand-cursor showmore">
                                                  Show Less{" "}
                                                </p>
                                              )}
                                            </span>
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        <Modal
          show={this.state.deleteBrandModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closedeleteBrandModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteBrandModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteBrand(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ClientView;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: FloorPlan.jsx                                //
//  Application: FloorPlan                                //
//  Option: List all FloorPlan                            //
//  Developer: Ashish Kumar  						                  //
//  Date: 2021-12-28                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import { Container, Row, Col, Modal, InputGroup, Form } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import FloorPlanService from "../../services/FloorPlanService";
import ListingService from "../../services/ListingService";
import FlooringService from "../../services/FlooringService";
import HeatingService from "../../services/HeatingService";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Select from "react-select";
import NoData from "../common/NoData";
import PropertySidebar from "../common/PropertySidebar";
import queryString from "query-string";
import DatePicker from "react-datepicker";
import Moment from "moment";

let queryParams = queryString.parse(window.location.search);

export class FloorPlan extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "3",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      publishData: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_level_tax_name: [],
      property_slug: this.props.match.params.propertySlug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "property_floor_plan_name",
          text: "Name",
          className: "property_floor_plan_name hand-cursor",
          sortable: true,
          width: '8%',
          cell: (record) => {
            return record.slug
              ? record.property_floor_plan_name
              : record.property_floor_plan_name;
          },
        },
        {
          key: "bedroom",
          text: "Bedrooms",
          className: "bedroom hand-cursor",
          sortable: true,
          width: '7%',
          cell: (record) => {
            return record.bedroom ? record.bedroom : "";
          },
        },
        {
          key: "bath",
          text: "Bathrooms",
          className: "bath hand-cursor",
          sortable: true,
          width: '7%',
          cell: (record) => {
            return record.bath ? record.bath : "";
          },
        },
        {
          key: "square_foot",
          text: "Square Foot",
          className: "square_foot hand-cursor",
          sortable: true,
          width: '8%',
          cell: (record) => {
            return record.square_foot ? record.square_foot.replace(/\.00$/, '') : "";
          },
        },
        {
          key: "balcony",
          text: "Balcony (Sq. ft)",
          className: "balcony hand-cursor",
          sortable: true,
          width: '10%',
          cell: (record) => {
            return record.balcony ? record.balcony.replace(/\.00$/, '') : "";
          },
        },
        {
          key: "interior",
          text: "Interior (Sq. ft)",
          className: "interior hand-cursor",
          sortable: true,
          width: '11%',
          cell: (record) => {
            return record.interior ? record.interior.replace(/\.00$/, '') : "";
          },
        },
        // {
        //   key: "heating",
        //   text: "Heating",
        //   className: "heating hand-cursor",
        //   sortable: true,
        //   width: '7%',
        //   cell: (record) => {
        //     return record.heating ? record.heating.heating_name : "";
        //   },
        // },
        // {
        //   key: "flooring",
        //   text: "Flooring",
        //   className: "flooring hand-cursor",
        //   sortable: true,
        //   width: '7%',
        //   cell: (record) => {
        //     return record.flooring ? record.flooring.flooring_name : "";
        //   },
        // },
        {
          key: "taxes",
          text: "Taxes",
          className: "taxes hand-cursor",
          sortable: false,
          width: '10%',
          cell: (record) => {
            if (record.taxes.length > 0) {
              const property_level_tax_name = [];
              record.taxes.forEach((item, i) => {
                property_level_tax_name.push(
                  " " + item.property_level_tax_name
                );
              });
              return property_level_tax_name.toString();
            } else {
              return "";
            }
          },
        },
        {
          key: "listing_name",
          text: "Listing",
          className: "listing_name hand-cursor",
          sortable: false,
          width: '20%',
          cell: (record) => {
            return record.listing_details ? record.listing_details.listing_name : "";
          },
        },
        // {
        //   key: "status_id",
        //   text: "Status",
        //   className: "status_id",
        //   sortable: false,
        //   width: '6%',
        //   cell: (record) => {
        //     return record.status_id === 1 ? (
        //       <b className="status-active">Active</b>
        //     ) : (
        //       <b className="status-inactive">InActive</b>
        //     );
        //   },
        // },
      ],
      module_search: "",
      is_search: 0,
      isSearch: global.isSearch,
      heatingList: [],
      newHeatingList: [],
      selectedHeatingList: [],
      flooringList: [],
      newFlooringList: [],
      selectedFlooringList: [],
      furnished_data: [
        {
          label: "Furnished",
          value: 1,
          slug: "furnished"
        },
        {
          label: "Unfurnished",
          value: 2,
          slug: "unfurnished"
        }
      ],
      search_date : "",
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.publishAllListing = this.publishAllListing.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    this.setState({ publishData: true});
    if (queryParams.isGlobal === "1") {
      this.getData("is_search=1&" + queryParams.global_col_name + "=" + queryParams.global_search);
    } else {
      this.getData();
    }
    this.setState({ publishData: false});
  }

  async getData(queryString = "") {
    //this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;

    let res = await FloorPlanService.getFloorPlan(
      "property_slug=" + this.state.property_slug + (queryString ? "&" + queryString : '')
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0
    }
    this.setState({

      total_record: totalRecords,
      records: list,
    });
    //this.setState({  showSpinner: false, loading: false,});
  }
  // =================[For Full sync]===================
  // TODO: Full Sync button on Floor Plan              *
  // DEV: Madhav                                       *
  // DATE: 29-03-2023                                  *
  //====================================================
  async publishAllListing() {
    this.setState({ publishData: true});
    let property_slug =  {
      slug: this.state.property_slug ? this.state.property_slug : ""
    };
    let res = await ListingService.publishAllListing(property_slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
         
      });
    }
    this.setState({ publishData: false});
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = 'asc';
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Floor Plans";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("floor-plans/view/" + data.slug);
  };

  /* to get flooring data list */
  async getFlooringData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var flooringList = [];
    var flooringListTotal = 0;

    let res = await FlooringService.getFlooring(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      flooringList = res.data ? res.data : [];
      flooringListTotal = flooringList ? flooringList.length : 0;
    }
    const newFlooringList =
      flooringListTotal > 0
        ? flooringList.map(({ slug, flooring_name }) => ({
          value: slug,
          label: flooring_name,
        }))
        : [];
    this.setState({
      flooringList: flooringList,
      flooringListTotal: flooringListTotal,
      newFlooringList: newFlooringList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get Heating room data */
  async getHeatingData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var heatingList = [];
    var heatingListTotal = 0;

    let res = await HeatingService.getHeating(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      heatingList = res.data ? res.data : [];
      heatingListTotal = heatingList ? heatingList.length : 0;
    }
    const newHeatingList =
      heatingListTotal > 0
        ? heatingList.map(({ slug, heating_name }) => ({
          value: slug,
          label: heating_name,
        }))
        : [];
    this.setState({
      heatingList: heatingList,
      heatingListTotal: heatingListTotal,
      newHeatingList: newHeatingList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  handleChangeFlooringList(value) {
    let flooring = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        flooring.push(item.value);
      });
    }
    const flooring_value = value !== null ? value.value : [];
    this.setState({
      selectedFlooringList: value,
      flooring: flooring_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeHeatingList(value) {
    let heating = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        heating.push(item.value);
      });
    }
    const heating_value = value !== null ? value.value : [];
    this.setState({
      selectedHeatingList: value,
      heating: heating_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeFurnishedList(value){
    let furnished_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        furnished_slug.push(item.value);
      });
    }
    const furnished_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedFurnishedList: value,
      furnished_slug: furnished_slug_value,
    });
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({ property_floor_plan_name: "", flooring: "", heating: "", bedroom: "", bath: "", interior: "", balcony: "", square_foot: "" , search_date:"", furnished_slug:"", selectedFurnishedList:[] });
    this.setState({ module_search: "" });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getHeatingData();
      this.getFlooringData();
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let startDate = this.state.search_date
    ? Moment(this.state.search_date).format("YYYY-MM-DD")
    : "";

    let queryString =
      "property_floor_plan_name=" +
      (this.state.property_floor_plan_name ? this.state.property_floor_plan_name : '') +
      "&flooring_slug=" +
      (this.state.flooring ? this.state.flooring : '') +
      "&heating_slug=" +
      (this.state.heating ? this.state.heating : '') +
      "&bedroom=" +
      (this.state.bedroom ? this.state.bedroom : '') +
      "&bath=" +
      (this.state.bath ? this.state.bath : '') +
      "&interior=" +
      (this.state.interior ? this.state.interior : '') +
      "&balcony=" +
      (this.state.balcony ? this.state.balcony : '') +
      "&square_foot=" +
      (this.state.square_foot ? this.state.square_foot : '') +
      "&furniture_type=" +
      (this.state.furnished_slug ? this.state.furnished_slug : "") +
      "&Move_in_date=" +
      startDate +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.property_floor_plan_name ? "Floor Plan Name - " + this.state.property_floor_plan_name : ''
    moduleSearchString = this.state.flooring ? (moduleSearchString ? (moduleSearchString + " ," + " Flooring - " + this.state.selectedFlooringList.label) : (" Flooring - " + this.state.selectedFlooringList.label)) : moduleSearchString
    moduleSearchString = this.state.heating ? (moduleSearchString ? (moduleSearchString + " ," + " Heating - " + this.state.selectedHeatingList.label) : (" Heating - " + this.state.selectedHeatingList.label)) : moduleSearchString
    moduleSearchString = this.state.bedroom ? (moduleSearchString ? (moduleSearchString + " ," + " Bedroom - " + this.state.bedroom) : (" Bedroom - " + this.state.bedroom)) : moduleSearchString
    moduleSearchString = this.state.bath ? (moduleSearchString ? (moduleSearchString + " ," + " Bath - " + this.state.bath) : (" Bath - " + this.state.bath)) : moduleSearchString
    moduleSearchString = this.state.interior ? (moduleSearchString ? (moduleSearchString + " ," + " Interior - " + this.state.interior) : (" Interior - " + this.state.interior)) : moduleSearchString
    moduleSearchString = this.state.balcony ? (moduleSearchString ? (moduleSearchString + " ," + " Balcony - " + this.state.balcony) : (" Balcony - " + this.state.balcony)) : moduleSearchString
    moduleSearchString = this.state.square_foot ? (moduleSearchString ? (moduleSearchString + " ," + " Square Foot - " + this.state.square_foot) : (" Interior - " + this.state.square_foot)) : moduleSearchString
    moduleSearchString = this.state.furnished_slug ? (moduleSearchString ? (moduleSearchString + " ," + " Furnished Type - " + this.state.selectedFurnishedList.label) : (" Furnished Type - " + this.state.selectedFurnishedList.label)) : moduleSearchString
    moduleSearchString = this.state.search_date ? (moduleSearchString ? (moduleSearchString + " ," + " Date - " + Moment(this.state.search_date).format(global.dateFormat)) : (" Date - " + Moment(this.state.search_date).format(global.dateFormat))) : moduleSearchString

    if (this.state.property_floor_plan_name || this.state.flooring || this.state.heating || this.state.bedroom || this.state.bath || this.state.interior || this.state.balcony || this.state.square_foot || this.state.furnished_slug || this.state.search_date) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(',') });
      this.getData(queryString);
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    {/* <PropertyViewSidebar /> */}
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 ">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission('floor-plans-add') &&
                          <Link to={"floor-plans/add"}>
                            <button className="btn-success-outline-small mr-3">
                              <img src={pluswIcon} alt="" /> Add
                            </button>
                          </Link>
                        }
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="scrolling-carousel pd-l-15">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">

                    {(Array.isArray(this.state.alertModalMessage)) ?
                          (
                            <>
                            {this.state.alertModalMessage ? this.state.alertModalMessage.map((opt, item)=>{
                              return(
                              <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15" key={item}>
                                <Alerts
                                  show={this.state.showAlertModal}
                                  // show="true"
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={opt}
                                />
                              </div>
  
                              )
                            })
                            
                        : 
                            <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                              <Alerts
                                    show={this.state.showAlertModal}
                                    type={this.state.alertModalType}
                                    title={this.state.alertModalTitle}
                                    message={this.state.alertModalMessage}
                                />
                            </div>
                            }
                          </>
                        )
                        : 
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                          <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                            />
                        </div>
                    }
                      {this.state.showFilter ? (
                        <Row className="align-items-center order_form">
                          <Col md={3} lg={5} xl={5} className="mb-md-0 mb-3 pl-0">
                            <input
                              name="filter_value"
                              className="form-control"
                              autoComplete="off"
                              // placeholder="Filter Value"
                              value={this.state.filter_value}
                              onChange={this.changeHandler}
                              maxLength="30"
                            />{" "}
                          </Col>
                          <Col md={5} lg={5} xl={5}>
                            <Row>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-30 mg-r-20"
                              >
                                Search
                              </button>
                              <button
                                onClick={this.resetDate}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      {queryParams.isGlobal === "1" ? <><div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">{"Search applied on: " + queryParams.global_col_label + " - " + queryParams.global_search}
                        <button onClick={this.clearFilter} className="mg-l-15 btn-success-outline-small">Clear</button></div>
                      </> : ""}
                      {this.state.is_search === 1 ?
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel justify_mob_filter">
                            <div className="custm-filter pd-lg-l-0 pd-xs-l-15">
                              <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                              <div className="filter_bg_cust">
                                {
                                  this.state.module_search.map((item, i) => {
                                    return (
                                      <span key={i}>{item}</span>
                                    )
                                  })
                                }
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >Clear</button>
                              </div>
                            </div>
                          </div>
                        </>
                        : ''}
                      {this.state.total_record > 0 || this.state.filter_value !== "" || this.state.is_search === 1 ? (
                        <div className="mg-t-18 brandList d-block">
                           <div className="pd-lg-l-0 pd-xs-l-15">
                          <ReactDatatable
                            className="table mg-b-30 pd-b-2 add-no-wrap table_last_row"
                            config={this.state.total_record > global.page_size ? this.state.config : this.state.config}
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            // loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            // onRowClicked={this.rowClickedHandler}
                            onRowClicked={this.rowClickedHandler}
                          />
                        </div>
                        </div>
                      ) : (
                        <NoData msg={global.commonMessages.checkMessage("FLOOR_PLANS")} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(
            e
          ) => {
            if (
              e.key ===
              "Enter"
            ) {
              this.filterData()
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.searchToggle()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Search in Floor Plans</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>
                    Name
                  </label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="property_floor_plan_name"
                      value={this.state.property_floor_plan_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>
                    Flooring
                  </label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedFlooringList}
                      options={this.state.newFlooringList}
                      getOptionValue={(option) =>
                        `${option.label}`
                      }
                      onChange={(value) =>
                        this.handleChangeFlooringList(value)
                      }
                      defaultValue={
                        this.state.selectedFlooringList
                      }
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>
                    Heating
                  </label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedHeatingList}
                      options={this.state.newHeatingList}
                      getOptionValue={(option) =>
                        `${option.label}`
                      }
                      onChange={(value) =>
                        this.handleChangeHeatingList(value)
                      }
                      defaultValue={
                        this.state.selectedHeatingList
                      }
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>
                    Square Foot
                  </label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="square_foot"
                      value={this.state.square_foot}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
                
                <InputGroup className="mt-3">
                  <label>Furnished Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedFurnishedList}
                      options={this.state.furnished_data}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeFurnishedList(value)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>
                    Bedrooms
                  </label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="bedroom"
                      value={this.state.bedroom}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>
                    Bathrooms
                  </label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="bath"
                      value={this.state.bath}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>
                    Balcony (Sq. ft)
                  </label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="balcony"
                      value={this.state.balcony}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>
                    Interior (Sq. ft)
                  </label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="interior"
                      value={this.state.interior}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                <label>Date</label>
                  <div className="col-md-12 p-0">
                  <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="search_date"
                      selected={this.state.search_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "search_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default FloorPlan;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertyNearByAttraction.jsx                 //
//  Application: PropertyNearByAttraction                 //
//  Option: List all Property NearByAttraction            //
//  Developer: NP, Prakash 						          //
//  Date: 2022-02-10                                      //
//                                                       //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import NearByAttractionService from "../../services/NearByAttractionService";
import { Link } from "react-router-dom";
import { Container, Form, Accordion, Card, Button } from "react-bootstrap";
import propertyLogo from "./../../assets/images/property_placeholder.png"
import { Modal } from "react-bootstrap";
import infoBig from "../../assets/images/info-b.svg"
import forwardarrow from "../../assets/images/forward-arrow-ami.svg"
import backwardarrow from "../../assets/images/back-arrow-ami.svg"
import renderHTML from 'react-render-html'
import Alerts from "../common/Alerts"
import infosize from "../../assets/images/Info.svg"
import editIcon from "./../../assets/images/edit-pen-icon.svg";

export class PropertyNearByAttractionOld extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      importModal: false,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      nearByAttractionList: [],
      propertyNearByAttractionList: [],
      slugList: [],
      slugUncheckList: [],
      property_logo_url: sessionStorage.getItem('property_logo_url'),
      property_type: sessionStorage.getItem('property_type'),
      property_name: sessionStorage.getItem('property_name'),
      year_value: sessionStorage.getItem('year_value') ? sessionStorage.getItem('year_value') : '',
      viewMode: 0,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.saveNearByAttraction = this.saveNearByAttraction.bind(this);
    this.discardNearByAttraction = this.discardNearByAttraction.bind(this);
    this.getNearByAttractionDetails = this.getNearByAttractionDetails.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleUnCheckboxChange = this.handleUnCheckboxChange.bind(this);
    this.changeView = this.changeView.bind(this);
  }

  componentDidMount() {
    this.getNearByAttractionData();
    this.getPropertyNearByAttractionData();
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ slugList: [] });
      this.setState({ slugUncheckList: [] });
      this.getPropertyNearByAttractionData();
    } else {
      this.setState({ viewMode: 1 });
      this.getNearByAttractionData();
      this.getPropertyNearByAttractionData();
    }
  }

  /* to get near by attractions data */
  async getNearByAttractionData() {
    this.setState({ showSpinner: true, loading: true });
    var nearByAttractionList = [];

    let res = await NearByAttractionService.getNearByAttractionForCity(this.state.property_slug);
    if (global.successStatus.includes(res.status)) {
      nearByAttractionList = res.data ? res.data : [];
    }
    this.setState({ nearByAttractionList: nearByAttractionList });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get property near by attractions data */
  async getPropertyNearByAttractionData() {
    this.setState({ showSpinner: true, loading: true });
    var propertyNearByAttractionList = [];

    let res = await NearByAttractionService.getPropertyNearByAttraction(this.state.property_slug);
    if (global.successStatus.includes(res.status)) {
      propertyNearByAttractionList = res.data ? res.data : []
    }
    this.setState({ propertyNearByAttractionList: propertyNearByAttractionList });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save near by attractions data */
  saveNearByAttraction = async (e) => {
    e.preventDefault();
    if (this.state.slugList.length > 0) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        attractionData: this.state.slugList
      };

      let res = await NearByAttractionService.attachPropertyNearByAttraction(this.state.property_slug, inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        var check = document.getElementsByName("one");
        for (var i = 0; i < check.length; i++) {
          document.getElementsByName("one")[i].checked = false;
        }
        this.setState({ slugList: [] });
        this.getNearByAttractionData();
        this.getPropertyNearByAttractionData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      alert("Select atleast one attraction")
    }
  };

  /* to discard near by attractions data */
  discardNearByAttraction = async (e) => {
    e.preventDefault();
    if (this.state.slugUncheckList.length > 0) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        attractionData: this.state.slugUncheckList
      };

      let res = await NearByAttractionService.detachPropertyNearByAttraction(this.state.property_slug, inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        var check = document.getElementsByName("one");
        for (var i = 0; i < check.length; i++) {
          document.getElementsByName("one")[i].checked = false;
        }
        this.setState({ slugUncheckList: [] });
        this.getNearByAttractionData();
        this.getPropertyNearByAttractionData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      alert("Select atleast one attraction")
    }
  };

  /* To get near by attractions details data */
  async getNearByAttractionDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await NearByAttractionService.getNearByAttractionBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.nearby_attraction_name = res.data.nearby_attraction_name ? res.data.nearby_attraction_name : "";
        resData.attraction_type_name = res.data.attraction_type_name ? res.data.attraction_type_name : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.opening_hour = res.data.opening_hour ? res.data.opening_hour : "";
        resData.website = res.data.website ? res.data.website : "";
        resData.latitude = res.data.latitude ? res.data.latitude : "";
        resData.longitude = res.data.longitude ? res.data.longitude : "";
        resData.contact_name = res.data.contact_name ? res.data.contact_name : "";
        resData.contact_email = res.data.contact_email ? res.data.contact_email : "";
        resData.contact_phone = res.data.contact_phone ? res.data.contact_phone : "";
        resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resData.city = res.data.city_name ? res.data.city_name : "";
        resData.country = res.data.country_name ? res.data.country_name : "";
        resData.county = res.data.county_name ? res.data.county_name : "";
        resData.state = res.data.state_name ? res.data.state_name : "";
        resData.zipcode = res.data.zipcode ? res.data.zipcode : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  confirmImportModal(slug) {
    this.getNearByAttractionDetails(slug);
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  }

  handleCheckboxChange = event => {
    let newArray = [...this.state.slugList, event.target.id];
    if (this.state.slugList.includes(event.target.id)) {
      newArray = newArray.filter(slug => slug !== event.target.id);
    }
    this.setState({ slugList: newArray });
  }

  handleUnCheckboxChange = event => {
    let newArray = [...this.state.slugUncheckList, event.target.id];
    if (this.state.slugUncheckList.includes(event.target.id)) {
      newArray = newArray.filter(slug => slug !== event.target.id);
    }
    this.setState({ slugUncheckList: newArray });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Near By Attractions"
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          <div className="row row-xs">
            <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link to="/properties">Properties</Link>
                <span> / </span>
                <span>{this.getTitle()}</span>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-15 pd-t-4">
              <div className="col-md-3">
                <aside>
                  <div className="topHead">
                    <div className="demo-avatar-group">
                      <div className="az-img-user avatar-md online">
                        <img src={sessionStorage.getItem('property_logo_url') ? sessionStorage.getItem('property_logo_url') : propertyLogo} assName="rounded-circle" alt="" />
                      </div>
                      <div className="propname">
                        <h5> {sessionStorage.getItem('property_name') ? sessionStorage.getItem('property_name') : "Property"} </h5>
                        <ul>
                          <li> {sessionStorage.getItem('property_type') ? sessionStorage.getItem('property_type') : ""} </li>
                          {sessionStorage.getItem('year_value') ? <li></li> : ''}
                          <li>{sessionStorage.getItem('year_value') ? "Year " + sessionStorage.getItem('year_value') : ''}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "0")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Property Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "0" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse
                          eventKey="0"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/basic")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic"
                              }
                            >
                              Property
                            </Link>

                            <Link
                              className={`${this.state.pathname.match("/tower")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              }
                            >
                              Towers
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/floors")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floors"
                              }
                            >
                              Floors
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/amenitites")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/amenitites"
                              }
                            >
                              Common Amenities
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/pet-policy")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                              }
                            >
                              Pet Policy
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/staff")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                            >
                              Staff
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/documents")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/documents"
                              }
                            >
                              Documents & Leases
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/property-level-tax")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              }
                            >
                              Taxes
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/nearby-attractions")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/nearby-attractions"
                              }
                            >
                              Near By Attractions
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/events")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                              }
                            >
                              Events
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card onClick={() => this.setOpen(!this.state.open, "3")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Units Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "3" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="3"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/floor-plans")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floor-plans"
                              }
                            >
                              Floor Plans
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/units")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units"
                              }
                            >
                              Units
                            </Link>
                            {/* <Link
                                                    className={`${
                                                      this.state.pathname.match("/rooms")
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                    }`}
                                                    data-toggle="tab"
                                                    to={
                                                      "/properties/view/" +
                                                      this.state.property_slug +
                                                      "/rooms"
                                                    }
                                                  >
                                                    Rooms
                                                  </Link> */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </nav>
                </aside>
              </div>
              <div className="col-md-9 ">
                <div className="propSection pd-l-15">
                  <h3>{this.getTitle()}</h3>
                  <span className="d-inline-flex align-items-baseline justify-content-start infosize">
                    <img src={infosize} className="mg-r-5" />
                    <p>Can add new near by attraction from master data.</p>
                  </span>
                  <div className="aminitesSection">
                    <div className="dashboardRightcard pd-l-6 pd-r-6">
                      <div className="col-md-12 d-flex justify-content-end pd-l-0 pd-r-0 mg-b-15">
                        {this.state.viewMode === 0 ? (
                          <button
                            className="btn-success-outline-small"
                            onClick={this.changeView}
                          >
                            <img src={editIcon} />
                            Edit
                          </button>
                        ) : (
                          <div className="d-flex">
                            <button
                              type="button"
                              className="btn-success-outline-small"
                              onClick={this.changeView}
                            >
                              Back
                            </button>
                          </div>
                        )}
                      </div>
                      {/* Alert Model */}
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                      {this.state.viewMode === 0 ? (
                        <div className="m-0">
                          <div className="col-md-12 pl-0 pr-0"></div>
                          <h3>Attractions near property</h3>
                          <ul className="attraction">
                            {this.state.propertyNearByAttractionList.length > 0 ? (
                              this.state.propertyNearByAttractionList.map((item, i) => {
                                return (
                                  <li className="m-3" key={i}>
                                    {item.nearby_attraction_name ? item.nearby_attraction_name : ""}
                                  </li>
                                );
                              })
                            ) : (
                              <h6 className="mg-t-15">There are no near by attraction added in the system. You may add them by clicking Add button.</h6>
                            )}
                          </ul>
                        </div>
                      )
                        :
                        <div className="col-md-12">
                          <div className="row m-0 w-100">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0">
                              <hr className="mg-t-0 mg-b-15 pd-b-1" />
                              <h5>All Available Near By Attractions</h5>
                              <hr className="mg-b-0 mg-t-15 pd-t-0" />
                              <div className="left">
                                <ul>
                                  <li>
                                    {this.state.nearByAttractionList.length > 0 ?
                                      this.state.nearByAttractionList
                                        .filter((option) => {
                                          const val1 =
                                            this.state.propertyNearByAttractionList.find(
                                              (opt) => {
                                                return (
                                                  opt.slug === option.slug
                                                );
                                              }
                                            );
                                          if (val1) return false;
                                          else return true;
                                        })
                                        .map((item, i) => {
                                          return (
                                            <div className="selectcard" key={i}>
                                              <img src={infoBig} onClick={() => this.confirmImportModal(item.slug)} />
                                              <Form.Check
                                                name="one"
                                                type={'checkbox'}
                                                id={item.slug}
                                                label={item.nearby_attraction_name ? item.nearby_attraction_name : ''}
                                                className={'ckbox'}
                                                onChange={this.handleCheckboxChange}
                                              />
                                              <span>{item.attraction_type_name ? item.attraction_type_name : ''}</span>
                                            </div>
                                          )
                                        })
                                      :
                                      <h6 className="mg-t-15">There are no near by attraction added in the system.</h6>
                                    }
                                  </li>
                                </ul>
                                {this.state.nearByAttractionList.length > 0 ?
                                  <div className="move-btn">
                                    <button className="green-btn" onClick={this.saveNearByAttraction} disabled={!this.state.slugList.length > 0 ? true : false}>
                                      <img src={forwardarrow} />
                                    </button>
                                    <button className="basic-btn" onClick={this.discardNearByAttraction} disabled={!this.state.slugUncheckList.length > 0 ? true : false}>
                                      <img src={backwardarrow} />
                                    </button>
                                  </div> : ''}
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0">
                              <hr className="mg-t-0 mg-b-15 pd-b-1" />
                              <h5>Selected Near By Attractions</h5>
                              <hr className="mg-b-0 mg-t-15 pd-t-0" />
                              <div className="right">
                                <ul>
                                  <li>
                                    {this.state.propertyNearByAttractionList.length > 0 ?
                                      this.state.propertyNearByAttractionList.map((item, i) => {
                                        return (
                                          <div className="selectcard" key={i}>
                                            <img src={infoBig} onClick={() => this.confirmImportModal(item.slug)} />
                                            <Form.Check
                                              name="one"
                                              type={'checkbox'}
                                              id={item.slug}
                                              label={item.nearby_attraction_name ? item.nearby_attraction_name : ''}
                                              className={'ckbox'}
                                              onChange={this.handleUnCheckboxChange}
                                            />
                                            <span>{item.attraction_type ? item.attraction_type.attraction_type_name : ''}</span>
                                          </div>
                                        )
                                      })
                                      :
                                      <h6 className="mg-t-15">There are no near by attraction added in the system. You may add them by clicking Add button.</h6>
                                    }
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ht-50"></div>
          </div>
        </Container>

        {/* amenitites view modal */}
        <Modal show={this.state.importModal} onHide={() => this.closeConfirmImportModal()} centered className="infomodal">
          <Modal.Body className="p-4 border_radius_14 modal-lg">
            <span
              className="welcomeClose"
              onClick={() => this.closeConfirmImportModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-left pd-l-15 pd-r-15">Near By Attraction Details</h3>
            <div className="row col-md-12 mg-t-40 mg-md-t-0">
              <div className='col-md-6'>
                <p className="az-profile-name-text pl-0"> Name:</p>
                <p className="media-body">{this.state.nearby_attraction_name ? this.state.nearby_attraction_name : ""}</p>
              </div>

              <div className='col-md-6'>
                <p className="az-profile-name-text pl-0">Category:</p>
                <p className="media-body">{this.state.attraction_type_name ? this.state.attraction_type_name : ""}</p>
              </div>
            </div>
            <hr className="mg-t-10 mg-b-30 pd-b-2" />
            <div className="row">
              {this.state.description ?
                <div className="col-md-12 pd-l-30 pd-r-30">
                  <p className="az-profile-name-text pl-0">Description:</p>
                  <p className="para">
                    {this.state.description ? renderHTML(this.state.description) : ""}
                  </p>
                </div>
                : ''}
              {this.state.opening_hour ?
                <div className="col-md-12 pd-l-30 pd-r-30">
                  <p className="az-profile-name-text pl-0">Opening Hours:</p>
                  <p className="media-body">
                    {this.state.opening_hour ? renderHTML(this.state.opening_hour) : ""}
                  </p>
                </div>
                : ''}
            </div>
            <hr className="mg-t-10 mg-b-30" />
            <div className="row">
              <div className='col-md-6'>
                <p className="az-profile-name-text pl-0"> Contact Name:</p>
                <p className="media-body">{this.state.contact_name ? this.state.contact_name : ""}</p>
              </div>
              <div className='col-md-6'>
                <p className="az-profile-name-text pl-0"> Contact Phone:</p>
                <p className="media-body">{this.state.contact_phone ? this.state.contact_phone : ""}</p>
              </div>

              <div className='col-md-6'>
                <p className="az-profile-name-text pl-0">Contact Email:</p>
                <p className="media-body">{this.state.contact_email ? this.state.contact_email : ""}</p>
              </div>
              <div className='col-md-6'>
                <p className="az-profile-name-text pl-0"> Website:</p>
                <p className="media-body">{this.state.website ? this.state.website : ""}</p>
              </div>
            </div>
            <hr className="mg-t-10 mg-b-30 pd-b-2" />

            <div className="row mg-t-40 mg-md-t-0">
              <div className='col-md-4'>
                <p className="az-profile-name-text pl-0">Street 1:</p>
                <p className="media-body">{this.state.street_1 ? this.state.street_1 : ""}</p>
              </div>
              <div className='col-md-4'>
                <p className="az-profile-name-text pl-0">State:</p>
                <p className="media-body">{this.state.state ? this.state.state : ""}</p>
              </div>
              <div className='col-md-4'>
                <p className="az-profile-name-text pl-0">Zip Code:</p>
                <p className="media-body">{this.state.zipcode ? this.state.zipcode : ""}</p>
              </div>

              <div className='col-md-4'>
                <p className="az-profile-name-text pl-0">Street 2:</p>
                <p className="media-body">{this.state.street_2 ? this.state.street_2 : ""}</p>
              </div>
              <div className='col-md-4'>
                <p className="az-profile-name-text pl-0">County:</p>
                <p className="media-body">{this.state.county ? this.state.county : ""}</p>
              </div>
              <div className='col-md-4'>
                <p className="az-profile-name-text pl-0">Latitude:</p>
                <p className="media-body">{this.state.latitude ? this.state.latitude : ""}</p>
              </div>

              <div className='col-md-4'>
                <p className="az-profile-name-text pl-0">City:</p>
                <p className="media-body">{this.state.city ? this.state.city : ""}</p>
              </div>
              <div className='col-md-4'>
                <p className="az-profile-name-text pl-0">Country:</p>
                <p className="media-body">{this.state.country ? this.state.country : ""}</p>
              </div>
              <div className='col-md-4'>
                <p className="az-profile-name-text pl-0">Longitude:</p>
                <p className="media-body">{this.state.longitude ? this.state.longitude : ""}</p>
              </div>
            </div>

          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default PropertyNearByAttractionOld;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: FloorPlanAccessibility.jsx                   //
//  Application: Floor Plan                               //
//  Option: List all Listing Accessibility                //
//  Developer: Ashish Kumar					                      //
//  Date: 2022-07-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
// import { Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import AccessibilityFeaturesService from "../../services/AccessibilityFeaturesService";
import editIcon from "./../../assets/images/edit-pen-icon.svg";

export class FloorPlanAccessibility extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      open: global.showOpen,
      eventKey: "",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      isSubmit: false,
      pathname: window.location.pathname,
      importModal: false,
      accessibilityList: [],
      floorPlanAccessibilityList: [],
      selectedAccessibility: [],
      slugUncheckList: [],
      // confirm_back: 0,
      select_all: false,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changeView = this.changeView.bind(this);
    this.getAccessibilityDetails = this.getAccessibilityDetails.bind(this);
    this.addFloorPlanAccessibility = this.addFloorPlanAccessibility.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  async componentDidMount() {
    this.getAccessibilityData();
    this.getFloorPlanLevelAccessibilityData();
  }

  /* to get floor plan level accessibility data */
  async getFloorPlanLevelAccessibilityData() {
    var floorPlanLevelAccessibilityList = [];
    var floorPlanLevelAccessibilityListTotal = 0;
    let res = await AccessibilityFeaturesService.getPropertyAccessibility(
      this.props.property_slug + "/4"
    );
    if (global.successStatus.includes(res.status)) {
      floorPlanLevelAccessibilityList = res.data ? res.data : [];
      floorPlanLevelAccessibilityListTotal = floorPlanLevelAccessibilityList
        ? floorPlanLevelAccessibilityList.length
        : 0;
    }
    this.setState({
      floorPlanLevelAccessibilityList: floorPlanLevelAccessibilityList,
      floorPlanLevelAccessibilityListTotal: floorPlanLevelAccessibilityListTotal,
    });
  }

  /* to accessibility data */
  async getAccessibilityData(queryString = "") {
    var accessibilityList = [];
    var accessibilityListTotal = 0;

    let res = await AccessibilityFeaturesService.getAccessibilityFeature(
      "level=4",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      accessibilityList = res.data ? res.data.data : [];
      accessibilityListTotal = accessibilityList ? accessibilityList.length : 0;
    }
    this.setState({
      accessibilityList: accessibilityList,
      accessibilityListTotal: accessibilityListTotal,
    });

    this.getPropertyAccessibilityData();
  }

  /* to get property accessibility data */
  async getPropertyAccessibilityData() {
    var floorPlanAccessibilityList = [];
    var floorPlanAccessibilityListTotal = 0;
    var selectedAccessibility = [];
    let res = await AccessibilityFeaturesService.getPropertyAccessibility(
      this.props.floor_plan_slug + "/4"
    );
    if (global.successStatus.includes(res.status)) {
      floorPlanAccessibilityList = res.data ? res.data : [];
      floorPlanAccessibilityListTotal = floorPlanAccessibilityList
        ? floorPlanAccessibilityList.length
        : 0;
    }
    floorPlanAccessibilityList.forEach((opt) => {
      selectedAccessibility.push(opt.slug);
    });
    var checkAccessibilityList = this.state.accessibilityList;
    this.state.accessibilityList.map((item, i) => {
      floorPlanAccessibilityList.map((options) => {
        if (item.slug === options.slug) {
          checkAccessibilityList[i].checked = true;
        } else {
          if (checkAccessibilityList[i].checked !== true) {
            checkAccessibilityList[i].checked = false;
          }
        }
      });
    });

    this.setState({
      selectedAccessibility: selectedAccessibility,
      accessibilityList: checkAccessibilityList,
      floorPlanAccessibilityList: floorPlanAccessibilityList,
      floorPlanAccessibilityListTotal: floorPlanAccessibilityListTotal,
    });
    if (
      this.state.accessibilityListTotal === this.state.floorPlanAccessibilityListTotal
    ) {
      this.setState({ select_all: true });
    } else {
      this.setState({ select_all: false });
    }
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 1 });
    } else {
      if (this.state.floorPlanAccessibilityListTotal > 0) {
        this.setState({ viewMode: 0 });
      } else {
        this.setState({ viewMode: 1 });
      }
    }
  }

  /* to save accessibility data */
  addFloorPlanAccessibility = async (e) => {
    e.preventDefault();

    if (this.state.floor_plan_slug || this.props.floor_plan_slug) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        accessibilityData:
          this.state.selectedAccessibility.length > 0
            ? this.state.selectedAccessibility
            : [""],
      };

      let res = await AccessibilityFeaturesService.addOrUpdateAccessibility(
        this.props.floor_plan_slug + "/4",
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        var check = document.getElementsByName("one");
        for (var i = 0; i < check.length; i++) {
          document.getElementsByName("one")[i].checked = false;
        }
        this.setState({ selectedAccessibility: [] });
        this.getPropertyAccessibilityData();
        this.setState({ viewMode: 0 });
        // setTimeout(
        //   () => document.getElementById("listingPrice").click(),
        //   global.redirect_time
        // );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showSpinner: false,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: "Please create your floor plan first.",
      });
      setTimeout(
        () => document.getElementById("infoForm").click(),
        global.alert_close_time
      );
    }
  };

  /* To accessibility details data */
  async getAccessibilityDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await AccessibilityFeaturesService.getAccessibilityFeatureBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.accessibility_feature_name = res.data.accessibility_feature_name
        ? res.data.accessibility_feature_name
        : "";
      resData.accessibility_features_location_name = res.data
        .accessibility_features_location
        ? res.data.accessibility_features_location
          .accessibility_features_location_name
        : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.tag_line = res.data.tag_line ? res.data.tag_line : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  confirmImportModal(slug) {
    this.getAccessibilityDetails(slug);
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event) => {
    this.setState({ select_all: "" });
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    var accessibilityList = this.state.accessibilityList;
    let newArray = [...this.state.accessibilityList];
    if (event.target.name === "select_all") {
      if (event.target.checked === true) {
        accessibilityList.forEach((item, i) => {
          accessibilityList[i].checked = true;
        });
        newArray.forEach((item, i) => {
          newArray[i] = item.slug;
        });
      } else {
        accessibilityList.forEach((item, i) => {
          accessibilityList[i].checked = false;
        });
        newArray.forEach((item, i) => {
          newArray[i] = "";
        });
      }
    }
    this.setState({ selectedAccessibility: newArray });
  };

  handleCheckboxChange = (event, index) => {
    this.setState({ select_all: "" });
    let newArray = [...this.state.selectedAccessibility, event.target.id];
    if (this.state.selectedAccessibility.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    var accessibilityList = this.state.accessibilityList;
    accessibilityList[index].checked = event.target.checked === true ? true : false;
    this.setState({ selectedAccessibility: newArray });
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }

  changeView() {
    // var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ selectedAccessibility: [] });
      this.setState({ slugUncheckList: [] });
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
      // this.getPropertyAccessibilityData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
      // this.getAccessibilityData();
      this.getPropertyAccessibilityData();
    }
  }

  render() {
    return (
      <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns">
          {this.state.viewMode === 0 && global.userPermissions.checkPermission('floor-plans-update') ? (
            <button
              className="btn-success-outline-small mr-3"
              onClick={this.changeView}
            >
              <img src={editIcon} alt="Edit" />
              {this.state.floorPlanAccessibilityListTotal > 0 ? "Edit" : "Edit"}
            </button>
          ) : (
            <div className="d-flex">
              <button
                type="button"
                className="btn-success-outline-small mr-3"
                onClick={this.changeView}
              >
                Cancel
              </button>
              {global.userPermissions.checkPermission('floor-plans-update') &&
              <button
                className="btn-success-outline-small mr-3"
                onClick={this.addFloorPlanAccessibility}
              >
                Save{" "}
              </button>
              }
            </div>
          )}
        </div>
        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>
        {this.state.viewMode === 0 ? (
          <React.Fragment>
            <div className="m-0">
              <div className="col-lg-12 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">Floor Plan Accessibility Features</h3>
              </div>
              {this.state.floorPlanAccessibilityListTotal > 0 ? (
                <React.Fragment>
                  <div className="aminityListView pd-l-10 pd-r-10 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                    <ul className="attraction">
                      {this.state.floorPlanAccessibilityListTotal > 0
                        ? this.state.floorPlanAccessibilityList.map((item, i) => {
                            return (
                              <li className="m-2" key={i}>
                                {item.accessibility_feature_name ? item.accessibility_feature_name : ""}
                              </li>
                            );
                          })
                        : ""}
                    </ul>
                  </div>
                </React.Fragment>
              ) : (
                <h6 className="mg-t-20 text-center w-100">
                  There are no floor plan accessibility features added in the system. You may
                  add them by clicking Edit button.
                </h6>
              )}
            </div>
          </React.Fragment>
        ) : (
          ""
        )}

        {this.state.viewMode === 1 ? (
          <div className="m-0">
            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <h3 className="mg-l-0 background-head d-flex justify-content-between align-items-center">
                Floor Plan Accessibility Features
              </h3>
            </div>
            <div className="row align-items-center pd-b-30 border-bottom-0 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-0 pd-r-0 pd-r-40 mg-b-0">
                <div className="select-all-checkbox d-block w-100 mg-b-20">
                  <div className="ckbox form-check">
                    <label  htmlFor="select_all" className="form-label text-left pd-b-5 mg-l-20 mg-r-10">
                      <b>Select All</b>
                    </label>
                    <input
                      name="select_all"
                      type={"checkbox"}
                      id={`select_all`}
                      onChange={this.changeHandler}
                      className="form-check-input"
                      checked={this.state.select_all}
                    />
                    <label
                      title=""
                      htmlFor="select_all"
                      className="form-check-label"
                    ></label>
                  </div>
                </div>
                <div className="aminityList">
                  {this.state.accessibilityListTotal > 0 ? (
                    <ul className="formcheck-section mg-b-0 flex-wrap">
                      {this.state.accessibilityListTotal > 0
                        ? this.state.accessibilityList.map((item, i) => {
                            var check_res =
                              item.checked === true ? "checked" : "";

                            return (
                              <React.Fragment key={i}>
                                <li>
                                  <div className="ckbox form-check">
                                    <label  htmlFor={item.slug} className="form-label text-left mg-l-20 pd-b-5">
                                      {item.accessibility_feature_name}
                                    </label>
                                    <input
                                      name="affordable_component_check"
                                      type="checkbox"
                                      id={item.slug}
                                      className="form-check-input"
                                      onChange={(e) =>
                                        this.handleCheckboxChange(e, i)
                                      }
                                      checked={check_res}
                                    />

                                    <label
                                      title=""
                                      htmlFor={item.slug}
                                      className="form-check-label"
                                    ></label>
                                  </div>
                                </li>
                              </React.Fragment>
                            );
                          })
                        : ""}
                    </ul>
                  ) : (
                    <h6 className="mg-t-20">
                      There are no floor plan accessibility features added in the system.
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default FloorPlanAccessibility;

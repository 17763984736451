////////////////////////////////////////////////////////////
//     							                                      //
//  Program: TowerForm.jsx                                //
//  Application:  Tower                                   //
//  Option: For add or update Tower                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-01-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import TowerService from "../../services/TowerService";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import modaldeleteicon from "./../../assets/images/delete.svg";
import PropertySidebar from "../common/PropertySidebar";

export class TowerForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      tower_slug: this.props.match.params.slug,
      isSubmit: false,
      tower_name: "",
      aka_name: "",
      sort_order: "",
      pathname: window.location.pathname,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateTower = this.saveOrUpdateTower.bind(this);
    this.deleteTower = this.deleteTower.bind(this);
    this.opendeleteTowerModal = this.opendeleteTowerModal.bind(this);
  }

  async componentDidMount() {
    // this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    if (this.state.tower_slug !== undefined) {
      var resData = {};
      let res = await TowerService.getTowerBySlug(this.state.tower_slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.tower_name = res.data.tower_name ? res.data.tower_name : "";
        resData.aka_name = res.data.aka_name ? res.data.aka_name : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    //  this.setState({ showSpinner: false, loading: false });
  }

  opendeleteTowerModal(slug) {
    this.setState({ deleteTowerModal: true, slug: slug });
  }

  closedeleteTowerModal() {
    this.setState({ deleteTowerModal: false, slug: "" });
  }

  async deleteTower(slug) {
    this.setState({ showSpinner: true });
    let res = await TowerService.deleteTower(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteTowerModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/tower"
          ),
        global.redirect_time
      );
    } else {
      this.closedeleteTowerModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  saveOrUpdateTower = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        tower_name: this.state.tower_name,
        aka_name: this.state.aka_name,
        property_slug: this.state.property_slug,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.tower_slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await TowerService.updateTower(
          this.state.tower_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/tower"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await TowerService.createTower(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/tower"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };
  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.tower_slug !== undefined) {
      return "Towers";
    } else {
      return "Towers";
    }
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                        {global.userPermissions.checkPermission('towers-delete') &&
                          <span
                            onClick={() => {
                              this.opendeleteTowerModal(this.state.slug);
                            }}
                            className="deleteicon mg-r-20"
                          >
                            <img src={deleteicon} alt="" />
                          </span>
                        }
                        </>
                      ) : (
                        ""
                      )}

                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                              this.state.property_slug +
                              "/tower"
                            );
                        }}
                      >
                        Cancel
                      </button>
                

                    {this.state.slug !== undefined &&
                      global.userPermissions.checkPermission(
                        "towers-update"
                      ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveOrUpdateTower}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : global.userPermissions.checkPermission(
                          "towers-add"
                        ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveOrUpdateTower}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : (
                        ""
                      )}

                    

                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="tower_form">
                    <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0">
                        <div className="row align-items-center  pd-l-15 pd-r-15 pd-t-30 pd-b-30 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Tower Name
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="tower_name"
                              value={this.state.tower_name}
                              className="form-control max_width_100"
                              type="text"
                            />
                            {this.state.errors.tower_name ? (
                              <div className="text-danger">
                                {this.state.errors.tower_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "tower_name",
                                this.state.tower_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 pd-xs-l-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                             Tower SKU
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="aka_name"
                              value={this.state.aka_name}
                              onKeyPress={
                                global.onKeyPressEvent.alphaNumValidation
                              }
                              className="form-control max_width_100"
                              type="text"
                              maxLength="3"
                            />
                            {this.state.errors.aka_name ? (
                              <div className="text-danger">
                                {this.state.errors.aka_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "tower_sku",
                                this.state.aka_name,
                                "required|alpha_num_dash|max:3",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteTowerModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteTowerModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteTowerModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteTower(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default TowerForm;

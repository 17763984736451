////////////////////////////////////////////////////////////
//     							                          //
//  Program: AmentiyTypeLookup.jsx                         //
//  Application: AmentiyTypeLookup                         //
//  Developer: Bhavit				                          //
//  Date: 2022-06-20                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
// import LookUpMappingService from "../../services/LookUpMappingService";
import { Container, Button, Tab, Nav, Table, Modal, InputGroup, Form } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import Select from "react-select";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import RUMappingService from "../../services/RUMappingService";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import NoData from "../common/NoData";
import filterIcon from "./../../assets/images/filter-gray.svg";
// import deleteicon from "../../assets/images/delete.svg";

export class AmentiyTypeLookup extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isPageLoaded: true,
      open: true,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      is_available: "",
      available_from: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      unitsFilter: [],
      mappingArray: [],
      tab: "activeTab",
      is_search: 0,
      usertabData: localStorage.getItem("user_tab_data")
        ? localStorage.getItem("user_tab_data")
        : "",
      activeClass: "activeTab",
      newStatusList: [
        { label: "Pending", value: 2 },
        { label: "Completed", value: 1 },
      ],
      selectedStatusList: [],
      selectedCleaningList: [],
      filter_value: "",
      lastUpdatedSlug: "",
      // date: this.props.match.params.date,
      listing_slug: this.props.match.params.listingSlug,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      record1: [],
      config: {
        sort: {
          column: "scheduled_on",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "scheduled_on",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        // show_filter: global.show_filter,
        show_filter: false,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_number",
          text: "Amenity",
          className: "unit_number",
          sortable: true,
          cell: (record) => {
            return record.amenity_name;
          },
        },
        {
          key: "inspection_status",
          text: "RU Amenity",
          className: "inspection_status drop_cust_w",
          sortable: true,
          cell: (record) => {
            // return record.inspection_status === 1
            //   ? "COMPLETED"
            //   : "INCOMPLETE";
            const customStyles = {
              option: (provided, state) => ({
                ...provided,
                color:
                  state.isSelected && state.isFocused
                    ? "white"
                    : state.isSelected
                      ? "black"
                      : state.isFocused && "white",
                backgroundColor:
                  state.isSelected && state.isFocused
                    ? "black"
                    : state.isFocused && "black",
              }),
            };

            return (
              <div>
                <Select
                  isMulti
                  styles={customStyles}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={
                    this.state.isPageLoaded
                      ? this.state.selectedCleaningList.filter(
                        (product) => product.data === record.amenity_id
                      )
                      : this.state.selectedCleaningList
                  }
                  defaultValue={this.state.selectedCleaningList}
                  options={this.state.cleaning_list}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) =>
                    this.handleChangeStatusList(value, record.amenity_id)
                  }
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            );
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.setMapping = this.setMapping.bind(this);
    this.opensearchAmenityModal = this.opensearchAmenityModal.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  handleChangeStatusList(value,amenity_id) {
    this.setState({ isPageLoaded: false });
    var state = [...this.state.mappingArray];
    var mapping = { amenity_id: amenity_id, ru_amenity_id: value };
    state.push(mapping);
    this.setState({ mappingArray: state, selectedCleaningList: this.value });
  }
  /*for amenity search modal */
  opensearchAmenityModal() {
    this.getData();
    this.setState({ searchAmenityModal: true });
  }

  /*for amenity search modal close */
  closesearchAmenityModal() {
    this.setState({ searchAmenityModal: false });
    this.setState({
      amenity_name: "",
    });
    this.validator.hideMessages();
  }
  /* to search amenity data */
  searchAmenity = async (e) => {
    this.setState({ showSpinner: true, loading: true });

    e.preventDefault();
    let inputData = 'ru_amenity_name=' + this.state.ru_amenity_name + '&' + 'amenity_name=' + this.state.amenity_name;

    var moduleSearchString = "";

    moduleSearchString = this.state.ru_amenity_name
      ? "Amenity Name - " + this.state.ru_amenity_name
      : "";
    moduleSearchString = this.state.amenity_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Amenity Name - " + this.state.amenity_name
        : " Amenity Name - " + this.state.amenity_name
      : moduleSearchString;
    // let res = await RUMappingService.getAmennityLookUpMappingList(inputData);
    this.setState({ module_search: moduleSearchString.split(',') });
    this.getData(inputData);

    this.setState({ showSpinner: false, loading: false, is_search: 1 });
    this.setState({ searchAmenityModal: false });
    this.setState({
      ru_amenity_name: "",
      amenity_name: ""
    });
  }

  clearFilter() {
    var moduleSearchString = "";
    moduleSearchString = this.state.ru_amenity_name
      ? "amenity_name- " + this.state.ru_amenity_name
      : moduleSearchString;
    this.setState({ module_search: moduleSearchString });

    if (this.state.ru_amenity_name) {
      this.setState({
        ru_amenity_name: "",
        amenity_name: ""
      });
      this.setState({ module_search: "", is_search: 0 });
      this.props.history.push("/amenity-lookup");
    } else {
      window.location.search = "";
      this.setState({ is_search: 0 });
      this.setState({
        ru_amenity_name: "",
        amenity_name: ""
      });
      this.setState({ module_search: "" });
    }
  }

  async componentDidMount() {
    switch (this.state.usertabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
    this.getData();
  }

  async getData(queryString = "") {
    var list = [];
    var list1 = [];
    var newList1 = [];
    var totalRecords = 0;
    var totalRecords1 = 0;
    var totalAlwaysOn = 0;
    var alwaysOnList = [];
    let res = await RUMappingService.getAmennityLookUpMappingList(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data && res.data.amenityData ? res.data.amenityData : [];
      list1 = res.data && res.data.amenityRUData ? res.data.amenityRUData : [];
      alwaysOnList =
        res.data && res.data.amenityAlwaysOnRUData
          ? res.data.amenityAlwaysOnRUData
          : [];
      totalRecords = list ? list.length : 0;
      totalRecords1 = list1 ? list1.length : 0;

      totalAlwaysOn = res.data.amenityAlwaysOnRUData
        ? res.data.amenityAlwaysOnRUData.length
        : 0;
      newList1 =
        totalRecords1 > 0
          ? list1.map(({ ru_amenity_id, ru_amenity_name }) => ({
            value: ru_amenity_id,
            label: ru_amenity_id + " - " + ru_amenity_name,
          }))
          : [];
    }

    var selectedCleaningList = [];

    res.data.amenityData.length > 0 &&
      res.data.amenityData.forEach((item, i) => {
        res.data.amenityRUData.forEach((item2, i) => {
          if (item2.oh_amenity_id === item.amenity_id) {
            selectedCleaningList = [
              ...selectedCleaningList,
              {
                value: item2.ru_amenity_id,
                data: item2.oh_amenity_id,
                label: item2.ru_amenity_id + " - " + item2.ru_amenity_name,
              },
            ];
          }
        });
      });

      function getUnique(array, key) {
        if (typeof key !== 'function') {
          const property = key;
          key = function(item) { return item[property]; };
        }
        return Array.from(array.reduce(function(map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map()).values());
      }
    this.setState({
      total_record: totalRecords,
      records: list,
      total_record1: totalRecords1,
      cleaning_list: getUnique(newList1,'value'),
      selectedCleaningList: selectedCleaningList,
      alwaysOnList: alwaysOnList,
      totalAlwaysOn: totalAlwaysOn
    });
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  async setMapping() {
    let inputData = {
      mappingArray: this.state.mappingArray,
    };
    var res = await RUMappingService.setAmenityMapping(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      window.location.reload();
      // setTimeout(() => this.props.history.push("/amenity-lookup"), global.redirect_time);
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customFilter() {
    let queryString = "&status_id=" + this.state.status_id + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ filter_value: "", status_id: "", selectedStatusList: [] });
    this.getData();
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(this.state.tab, queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(this.state.tab, queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Amenities Mapping";
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    // if(e === "allCleaning"){
    //   this.setState({ tab: 6 });      //for calendar
    // } else
    if (e === "today") {
      this.setState({ tab: 1 });
      this.getData(1);
    } else if (e === "tomorrow") {
      this.setState({ tab: 2 });
      this.getData(2);
    } else if (e === "completed") {
      this.setState({ tab: 3 });
      this.getData(3);
    } else if (e === "pending") {
      this.setState({ tab: 4 });
      this.getData(4);
    } else if (e === "all") {
      this.setState({ tab: 5 });
      this.getData(5);
    } else {
      this.setState({ tab: 6 });
    }
  }

  // rowClickedHandler = (event, data, rowIndex) => {
  //   this.props.history.push("reservations/view/" + data.slug);
  // };

  render() {
    // var date = Moment(new Date()).format(global.dateFormat);
    // var date1 = Moment(new Date()).add(1, 'days').format(global.dateFormat);
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <MasterSidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right settingsidebarNav">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                    {global.userPermissions.checkPermission("master-data-update") &&
                      <Button
                        variant="outline-dark btn-block btn-with-icon"
                        onClick={this.setMapping}
                      >
                        <img src={pluswIcon} alt="" /> Save
                      </Button>
                    }
                      <Button

                        onClick={this.opensearchAmenityModal}
                        className="outline-dark btn-block btn-with-icon mt-0 ml-3"
                      >
                        <img src={filterIcon} alt="filterIcon" />{" "}
                        {this.state.isSearch === 1 ? "Search" : "Search"}
                      </Button>

                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="col-md-12 pd-lg-l-45 pd-lg-r-30 pd-md-l-45 pd-md-r-30 pd-xs-l-15 pd-xs-r-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>

                  <div className="scrolling-carousel pd-lg-l-15 pd-xs-l-15 scroll-slide">
                    {this.state.is_search === 1 ? (
                      <>
                        <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                          <div className="custm-filter pd-lg-l-0 pd-xs-l-15">
                            <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                            <div className="filter_bg_cust">
                              {
                                this.state.module_search.map((item, i) => {
                                  return (
                                    <span key={i}>{item}</span>
                                  )
                                })
                              }
                              <button
                                onClick={this.clearFilter}
                                className="btn-fliter-clear"
                              >Clear</button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="all"
                      onSelect={(e) => this.getActiveClass(e)}
                      eventKey={this.state.activeClass}
                      className="pd-r-60"
                    >
                      <ScrollingCarousel
                        rightArrow={true}
                        leftArrow={true}
                        className="swipesection"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="activeTab"
                            id="activeTab"
                            className={
                              this.state.activeClass === "activeTab"
                                ? "active"
                                : ""
                            }
                          >
                            Map
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            eventKey="inactiveTab"
                            id="inactiveTab"
                            className={
                              this.state.activeClass === "inactiveTab"
                                ? "active"
                                : ""
                            }
                          >
                            Always On
                          </Nav.Link>
                        </Nav.Item>
                      </ScrollingCarousel>
                      <Tab.Content>
                        <Tab.Pane eventKey="activeTab">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            {this.state.total_record !== 0 ? (
                              <div className="mg-t-18 brandList">
                                <div className="pd-lg-l-0 pd-xs-l-15 pd-md-l-30 lookup_table">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100 table_last_row "
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    // loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="mg-t-30 text-center">
                                <NoData />
                              </div>
                            )}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="inactiveTab">
                          <div className="dashboardRightcard background-none pt-0">
                            <div className="mg-t-18 brandList">
                              <div className="pd-lg-l-0 pd-xs-l-15 pd-md-l-30 pd-lg-r-30 amty_map_tbl">


                                <Table hover className="mg-b-0">
                                  <thead>
                                    <tr>
                                      <th className="w-50">Always On RU Amenity</th>
                                      <th className="w-50">Level</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.totalAlwaysOn > 0 ? (
                                      this.state.alwaysOnList.map((item, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="hand-cursor">
                                              {item.ru_amenity_name}
                                            </td>
                                            <td className="hand-cursor">
                                              {item.amenity_lookup_level == 2 ? "Listing Level" : item.amenity_lookup_level == 1 ? "Property Level": item.amenity_lookup_level == 3 ? "Floor Plan Level" :item.amenity_lookup_level == 4 ? "Unit Level":""}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan="9" align="center">
                                          <NoData />
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>

                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>

                  {/* {this.state.total_record !== 0 ? (
                    <div className="mg-t-18 brandList">
                      <div className="pd-lg-l-15 pd-xs-l-15 pd-md-l-30 lookup_table">
                        <ReactDatatable
                          className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100 table_last_row "
                          config={
                            this.state.total_record > global.page_size
                              ? this.state.config
                              : this.state.config
                          }
                          records={this.state.records}
                          columns={this.state.columns}
                          extraButtons={this.state.extraButtons}
                          dynamic={true}
                          // loading={this.state.loading}
                          total_record={this.state.total_record}
                          onChange={this.tableChangeHandler}
                          onRowClicked={this.rowClickedHandler}
                        />
                </div>
              </div>
                  ) : (
                    <div className="mg-t-30 text-center">
                      <p>No amenities mapping</p>
            </div>
                  )} */}
          </div>
              </div>
            </div>
          </div>
        </Container>
        {/* search  amenity popup modal */}
        <Modal
          show={this.state.searchAmenityModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without Searching?"))
              this.closesearchAmenityModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closesearchAmenityModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Search Amenity</h3>
            <InputGroup className="mt-3">
              <label>Amenity Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="ru_amenity_name"
                value={this.state.ru_amenity_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                hidden={this.state.activeClass === "activeTab" ? true : false}
              // placeholder="Amenity Name"
              />
            </InputGroup>
            <InputGroup className="mt-3">
              {/* <label>Amenity RU Name</label> */}
              <Form.Control
                onChange={this.changeHandler}
                name="amenity_name"
                value={this.state.amenity_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                hidden={this.state.activeClass === "activeTab" ? false : true}
              // placeholder="Amenity Name"
              />
            </InputGroup>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closesearchAmenityModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.searchAmenity}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>


      </main>
    );
  }
}

export default AmentiyTypeLookup;
import React, { Component } from "react";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import OwnershipTypeService from "../../services/OwnershipTypeService";
import CommServedService from "../../services/CommServedService";
import SmokingTypeService from "../../services/SmokingTypeService";
import LeedBuildingService from "../../services/LeedBuildingService";
import MonthService from "../../services/MonthService";
import SimpleReactValidator from "simple-react-validator";
import { Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import Select from "react-select";
import infosize from "../../assets/images/Info.svg";
import MasterDataService from "../../services/MasterDataService";
import InputMask from "react-input-mask";
// import UnitCategoryService from "../../services/UnitCategoryService";

export class PropertyAttributesView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      // propertySlug: this.props.property_slug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      ownershipTypeList: [],
      newOwnershipTypeList: [],
      selectedOwnershipTypeList: [],
      monthList: [],
      newMonthList: [],
      selectedMonthList: [],
      commServedList: [],
      smokingTypeList: [],
      newSmokingTypeList: [],
      selectedSmokingTypeList: [],
      leedBuildingList: [],
      newLeedBuildingList: [],
      selectedLeedBuildingList: [],
      selectedCommServedList: [],
      check_in_time: "",
      check_out_time: "",
      confirm_back: 0,
      minimumAgeList: [],
      newMinimumAgeList: [],
      selectedMinimumAgeList: [],
      // basementList: [],
      // newBasementList: [],
      // selectedBasementList: [],
      // gardenList: [],
      // newGardenList: [],
      // selectedGardenList: [],
      // furnishedList: [],
      // newFurnishedList: [],
      // selectedFurnishedList: [],
      parkingList: [],
      newParkingList: [],
      selectedParkingList: [],
      // basement_name: "",
      minimum_age_name: "",
      // garden_name: "",
      parking_name: "",
      furnished_name: "",
      // unitCategoryList: [],
      // newUnitCategoryList: [],
      // selectedUnitCategoryList: [],
    };
    this.changeView = this.changeView.bind(this);
    this.saveOrUpdateProperty = this.saveOrUpdateProperty.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getData();
  }

  async getData() {
    if (this.props.property_slug !== undefined) {
      this.getOwnershipTypeData();
      this.getFiscalMonthData();
      this.getCommServedData();
      this.getSmokingTypeData();
      this.getLeedBuildingData();
      // this.getBasementData();
      // this.getGardenData();
      this.getMinimumAgeData();
      this.getParkingData();
      // this.getFurnishedData();
      // this.getUnitCategoryData();

      var resData = {};

      let res = await PropertyService.getProperty(this.props.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        //for view
        resData.ownership_type_name = res.data.ownership_type
          ? res.data.ownership_type.ownership_type_name
          : "";
        resData.leed_building_name = res.data.leed_building
          ? res.data.leed_building.leed_building_name
          : "";
        resData.smoking_type_name = res.data.smoking_type
          ? res.data.smoking_type.smoking_type_name
          : "";
        resData.unit_category_slug = res.data.unit_category
          ? res.data.unit_category.slug
          : "";
        resData.unit_category_name = res.data.unit_category
          ? res.data.unit_category.unit_category_name
          : "";
        resData.month_name = res.data.month
          ? res.data.month.fiscal_month_value
          : "";
        resData.communities_served_name_data = res.data
          .communities_served_name_data
          ? res.data.communities_served_name_data
          : "";
        resData.check_in_time = res.data.check_in_time
          ? res.data.check_in_time
          : "";
        resData.check_out_time = res.data.check_out_time
          ? res.data.check_out_time
          : "";
        resData.insurance_required =
          res.data.insurance_required === 1 ? true : false;
        resData.digital_lock_view = res.data.digital_lock === 1 ? true : false;
        // resData.move_in_window = res.data.move_in_window
        //   ? res.data.move_in_window
        //   : "";
        res.data.masterData.map((item) => {
          // if (resData.basement === item.slug) {
          //   const basement = item.name;
          //   this.setState({ basement_name: basement });
          // }

          if (resData.minimum_age === item.slug) {
            const minimum_age = item.name;
            this.setState({ minimum_age_name: minimum_age });
          }

          // if (resData.garden === item.slug) {
          //   const garden = item.name;
          //   this.setState({ garden_name: garden });
          // }

          if (resData.parking === item.slug) {
            const parking = item.name;
            this.setState({ parking_name: parking });
          }
          // if (resData.furnished === item.slug) {
          //   const furnished = item.name;
          //   this.setState({ furnished_name: furnished });
          // }
          // if (resData.month_id === item.slug) {
          //   const month_id = item.name;
          //   this.setState({ month_name: month_id });
          // }
        });
        //for edit
        resData.digital_lock = res.data.digital_lock === 1 ? true : false;
        // resData.move_in_window = res.data.move_in_window
        //   ? res.data.move_in_window
        //   : "";
        resData.month_id = res.data.month ? res.data.month.fiscal_month_no : "";
        resData.ownership_type_id = res.data.ownership_type
          ? res.data.ownership_type.slug
          : "";
        resData.smoking_type_id = res.data.smoking_type
          ? res.data.smoking_type.slug
          : "";
        resData.leed_building_id = res.data.leed_building
          ? res.data.leed_building.slug
          : "";
        resData.minimum_age = res.data.minimum_age ? res.data.minimum_age : "";
        // resData.basement = res.data.basement ? res.data.basement : "";
        // resData.garden = res.data.garden ? res.data.garden : "";
        resData.parking = res.data.parking ? res.data.parking : "";
        // resData.furnished = res.data.furnished ? res.data.furnished : "";
        if (this.state.viewMode === 1) {
          var selectedCommServedList = [];
          res.data.communitiesServedData.forEach((item, i) => {
            selectedCommServedList = [
              ...selectedCommServedList,
              {
                value: item.slug,
                label: item.communities_served_name,
              },
            ];
          });

          // var selectedUnitCategoryList = [];
          // selectedUnitCategoryList = res.data.unit_category
          //   ? [
          //       {
          //         value: res.data.unit_category.slug,
          //         label: res.data.unit_category.unit_category_name,
          //       },
          //     ]
          //   : "";

          var selectedOwnershipTypeList = [];
          selectedOwnershipTypeList = res.data.ownership_type
            ? [
              {
                value: res.data.ownership_type.slug,
                label: res.data.ownership_type.ownership_type_name,
              },
            ]
            : "";

          var selectedMonthList = [];
          selectedMonthList = res.data.month
            ? [
              {
                value: res.data.month.fiscal_month_no,
                label: res.data.month.fiscal_month_value,
              },
            ]
            : "";

          var selectedSmokingTypeList = [];
          selectedSmokingTypeList = res.data.smoking_type
            ? [
              {
                value: res.data.smoking_type.slug,
                label: res.data.smoking_type.smoking_type_name,
              },
            ]
            : "";

          var selectedLeedBuildingList = [];
          selectedLeedBuildingList = res.data.leed_building
            ? [
              {
                value: res.data.leed_building.slug,
                label: res.data.leed_building.leed_building_name,
              },
            ]
            : "";

          var selectedMinimumAgeList = [];
          // var selectedBasementList = [];
          // var selectedGardenList = [];
          var selectedParkingList = [];
          // var selectedFurnishedList = [];


          res.data.masterData.map((item) => {
            // if (resData.basement === item.slug) {
            //   selectedBasementList = res.data.masterData
            //     ? [
            //         {
            //           value: item.slug,
            //           label: item.name,
            //         },
            //       ]
            //     : "";
            // }
            // });
            //   res.data.masterData.map((item) => {
            if (resData.minimum_age === item.slug) {
              selectedMinimumAgeList = res.data.masterData
                ? [
                  {
                    value: item.slug,
                    label: item.name,
                  },
                ]
                : "";
            }
            // });
            //   res.data.masterData.map((item) => {
            // if (resData.garden === item.slug) {
            //   selectedGardenList = res.data.masterData
            //     ? [
            //         {
            //           value: item.slug,
            //           label: item.name,
            //         },
            //       ]
            //     : "";
            // }
            // });
            //   res.data.masterData.map((item) => {
            if (resData.parking === item.slug) {
              selectedParkingList = res.data.masterData
                ? [
                  {
                    value: item.slug,
                    label: item.name,
                  },
                ]
                : "";
            }
            // });
            //    res.data.masterData.map((item) => {
            // if (resData.furnished === item.slug) {
            //   selectedFurnishedList = res.data.masterData
            //     ? [
            //         {
            //           value: item.slug,
            //           label: item.name,
            //         },
            //       ]
            //     : "";
            // }
          });
        }
      }
      this.setState(resData);
      this.setState({
        selectedCommServedList: selectedCommServedList,
        selectedOwnershipTypeList: selectedOwnershipTypeList,
        selectedMonthList: selectedMonthList,
        selectedSmokingTypeList: selectedSmokingTypeList,
        selectedLeedBuildingList: selectedLeedBuildingList,
        selectedMinimumAgeList: selectedMinimumAgeList,
        // selectedBasementList: selectedBasementList,
        // selectedGardenList: selectedGardenList,
        selectedParkingList: selectedParkingList,
        // selectedFurnishedList: selectedFurnishedList,
        // selectedUnitCategoryList: selectedUnitCategoryList
      });
      this.setState({ communities_served_id: selectedCommServedList });
    }
  }

  /* to get Unit Category data list */
  // async getUnitCategoryData(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var unitCategoryList = [];
  //   var unitCategoryListTotal = 0;

  //   let res = await UnitCategoryService.getUnitCategory(
  //     queryString ? "&" + queryString : ""
  //   );

  //   if (global.successStatus.includes(res.status)) {
  //     unitCategoryList = res.data.data ? res.data.data : [];
  //     unitCategoryListTotal = unitCategoryList ? unitCategoryList.length : 0;
  //   }
  //   const newUnitCategoryList = unitCategoryList.map(({ slug, unit_category_name }) => ({
  //     value: slug,
  //     label: unit_category_name,
  //   }));

  //   this.setState({
  //     unitCategoryList: unitCategoryList,
  //     unitCategoryListTotal: unitCategoryListTotal,
  //     newUnitCategoryList: newUnitCategoryList
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }


  /* to get minimum age data */
  async getMinimumAgeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var minimumAgeList = [];
    var minimumAgeListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=minimum_age",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      minimumAgeList = res.data ? res.data : [];
      minimumAgeListTotal = minimumAgeList ? minimumAgeList.length : 0;
    }
    const newMinimumAgeList = minimumAgeListTotal > 0 ? minimumAgeList.map(({ slug, name }) => ({
      value: slug,
      label: name,
    })) : "";
    this.setState({
      minimumAgeList: minimumAgeList,
      minimumAgeListTotal: minimumAgeListTotal,
      newMinimumAgeList: newMinimumAgeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get parking data list */
  async getParkingData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var parkingList = [];
    var parkingListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=parking",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      parkingList = res.data ? res.data : [];
      parkingListTotal = parkingList ? parkingList.length : 0;
    }
    const newParkingList = parkingListTotal > 0 ? parkingList.map(({ slug, name }) => ({
      value: slug,
      label: name,
    })) : "";
    this.setState({
      parkingList: parkingList,
      parkingListTotal: parkingListTotal,
      newParkingList: newParkingList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get basement data */
  // async getBasementData(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var basementList = [];
  //   var basementListTotal = 0;

  //   let res = await MasterDataService.getMasterData(
  //     "is_dropdown=1&title=basement",
  //     queryString ? "&" + queryString : ""
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     basementList = res.data ? res.data : [];
  //     basementListTotal = basementList ? basementList.length : 0;
  //   }
  //   const newBasementList = basementList.map(({ slug, name }) => ({
  //     value: slug,
  //     label: name,
  //   }));
  //   this.setState({
  //     basementList: basementList,
  //     basementListTotal: basementListTotal,
  //     newBasementList: newBasementList,
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  /* to get garden data */
  // async getGardenData(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var gardenList = [];
  //   var gardenListTotal = 0;

  //   let res = await MasterDataService.getMasterData(
  //     "is_dropdown=1&title=garden",
  //     queryString ? "&" + queryString : ""
  //   );

  //   if (global.successStatus.includes(res.status)) {
  //     gardenList = res.data ? res.data : [];
  //     gardenListTotal = gardenList ? gardenList.length : 0;
  //   }
  //   const newGardenList = gardenList.map(({ slug, name }) => ({
  //     value: slug,
  //     label: name,
  //   }));
  //   this.setState({
  //     gardenList: gardenList,
  //     gardenListTotal: gardenListTotal,
  //     newGardenList: newGardenList,
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  /* to get furnished room data */
  // async getFurnishedData(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var furnishedList = [];
  //   var furnishedListTotal = 0;

  //   let res = await MasterDataService.getMasterData(
  //     "is_dropdown=1&title=furnished",
  //     queryString ? "&" + queryString : ""
  //   );

  //   if (global.successStatus.includes(res.status)) {
  //     furnishedList = res.data ? res.data : [];
  //     furnishedListTotal = furnishedList ? furnishedList.length : 0;
  //   }
  //   const newFurnishedList = furnishedList.map(({ slug, name }) => ({
  //     value: slug,
  //     label: name,
  //   }));
  //   this.setState({
  //     furnishedList: furnishedList,
  //     furnishedListTotal: furnishedListTotal,
  //     newFurnishedList: newFurnishedList,
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  async getOwnershipTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var ownershipTypeList = [];

    let res = await OwnershipTypeService.getOwnership(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      ownershipTypeList = res.data ? res.data : [];
    }
    const newOwnershipTypeList = ownershipTypeList.map(
      ({ slug, ownership_type_name }) => ({
        value: slug,
        label: ownership_type_name,
      })
    );
    this.setState({
      ownershipTypeList: ownershipTypeList,
      newOwnershipTypeList: newOwnershipTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getFiscalMonthData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var monthList = [];

    let res = await MonthService.getMonths(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      monthList = res.data ? res.data : [];
    }
    const newMonthList = monthList.map(({ fiscal_month_no, fiscal_month_value }) => ({
      value: fiscal_month_no,
      label: fiscal_month_value,
    }));
    this.setState({ monthList: monthList, newMonthList: newMonthList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCommServedData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var commServedList = [];

    let res = await CommServedService.getCommServed(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      commServedList = res.data ? res.data : [];
    }
    const newCommServedList = commServedList.map(
      ({ slug, communities_served_name }) => ({
        value: slug,
        label: communities_served_name,
      })
    );
    this.setState({
      commServedList: commServedList,
      newCommServedList: newCommServedList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSmokingTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var smokingTypeList = [];

    let res = await SmokingTypeService.getSmokingType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      smokingTypeList = res.data ? res.data : [];
    }
    const newSmokingTypeList = smokingTypeList.map(
      ({ slug, smoking_type_name }) => ({
        value: slug,
        label: smoking_type_name,
      })
    );
    this.setState({
      smokingTypeList: smokingTypeList,
      newSmokingTypeList: newSmokingTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getLeedBuildingData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var leedBuildingList = [];

    let res = await LeedBuildingService.getLeedBuilding(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      leedBuildingList = res.data ? res.data : [];
    }
    const newLeedBuildingList = leedBuildingList.map(
      ({ slug, leed_building_name }) => ({
        value: slug,
        label: leed_building_name,
      })
    );
    this.setState({
      leedBuildingList: leedBuildingList,
      newLeedBuildingList: newLeedBuildingList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateProperty = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });

      this.state.communities_served_id = this.state.selectedCommServedList
        ? this.state.selectedCommServedList.map(function (obj) {
          return obj.value;
        })
        : "";

      let multiCommServedString = this.state.communities_served_id
        ? this.state.communities_served_id.join(",")
        : "";

      let inputData = {
        tab: 4,
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        ownership_type_slug: this.state.ownership_type_id,
        communities_served_slug: multiCommServedString,
        smoking_type_slug: this.state.smoking_type_id,
        leed_building_slug: this.state.leed_building_id,
        month_id: this.state.month_id,
        asset_managers: this.state.asset_managers,
        check_in_time: this.state.check_in_time ? this.state.check_in_time : "",
        check_out_time: this.state.check_out_time
          ? this.state.check_out_time
          : "",
        insurance_required: this.state.insurance_required === true ? 1 : 2,
        digital_lock: this.state.digital_lock === true ? 1 : 2,
        // move_in_window: this.state.move_in_window
        //   ? this.state.move_in_window
        //   : "",
        minimum_age: this.state.minimum_age ? this.state.minimum_age : "",
        // basement: this.state.basement ? this.state.basement : "",
        // garden: this.state.garden ? this.state.garden : "",
        parking: this.state.parking ? this.state.parking : "",
        // furnished: this.state.furnished ? this.state.furnished : "",
        // unit_category_slug: this.state.unit_category_slug ? this.state.unit_category_slug : "",
      };

      if (this.props.property_slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.setState({ viewMode: 0 });
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
      }
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    // if (event.target.name === "check_in_time" || event.target.name === "check_out_time") {
    //   var regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    //   if(!regex.test(value)){
    //       event.preventDefault();
    //       return false;
    //   }
    // }

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  };

  handleChangeOwnershipTypeList(value) {
    let ownership_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        ownership_type_id.push(item.value);
      });
    }
    const ownership_type_id_value = value !== null ? value.value : [];
    this.setState({
      selectedOwnershipTypeList: value,
      ownership_type_id: ownership_type_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeMonthList(value) {
    let month_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        month_id.push(item.value);
      });
    }
    const month_id_value = value !== null ? value.value : [];
    this.setState({
      selectedMonthList: value,
      month_id: month_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeSmokingTypeList(value) {
    let smoking_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        smoking_type_id.push(item.value);
      });
    }
    const smoking_type_id_value = value !== null ? value.value : [];
    this.setState({
      selectedSmokingTypeList: value,
      smoking_type_id: smoking_type_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeLeedBuildingList(value) {
    let leed_building_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        leed_building_id.push(item.value);
      });
    }
    const leed_building_id_value = value !== null ? value.value : [];
    this.setState({
      selectedLeedBuildingList: value,
      leed_building_id: leed_building_id_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  handleChangeMinimumAgeList(value) {
    let minimum_age = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        minimum_age.push(item.value);
      });
    }
    const minimum_age_value = value !== null ? value.value : [];
    this.setState({
      selectedMinimumAgeList: value,
      minimum_age: minimum_age_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  // handleChangeBasementList(value) {
  //   let basement = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       basement.push(item.value);
  //     });
  //   }
  //   this.setState({
  //     selectedBasementList: value,
  //     basement: value.value,
  //   });
  // }

  // handleChangeGardenList(value) {
  //   let garden = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       garden.push(item.value);
  //     });
  //   }
  //   this.setState({
  //     selectedGardenList: value,
  //     garden: value.value,
  //   });
  // }

  handleChangeParkingList(value) {
    let parking = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        parking.push(item.value);
      });
    }
    const parking_value = value !== null ? value.value : [];
    this.setState({
      selectedParkingList: value,
      parking: parking_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  // handleChangeFurnishedList(value) {
  //   let furnished = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       furnished.push(item.value);
  //     });
  //   }
  //   this.setState({
  //     selectedFurnishedList: value,
  //     furnished: value.value,
  //   });
  // }

  // handleChangeUnitCategoryList(value) {
  //   let unit_category_slug = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       unit_category_slug.push(item.value);
  //     });
  //   }
  //   this.setState({
  //     selectedUnitCategoryList: value,
  //     unit_category_slug: value.value,
  //   });
  // }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
      this.setState({ selectedCommServedList: [] });
      this.setState({ newCommServedList: [] });
      this.setState({
        communities_served_id: "",
        // move_in_window: "",
        // basement_name: "",
        minimum_age_name: "",
        // garden_name: "",
        parking_name: "",
        // furnished_name: "",
        digital_lock_view: "",
      });
      this.getData();
      this.validator.hideMessages();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
      this.setState({ selectedCommServedList: [] });
      this.setState({ newCommServedList: [] });
      this.setState({
        communities_served_id: "",
        // move_in_window: "",
        // basement_name: "",
        minimum_age_name: "",
        // garden_name: "",
        parking_name: "",
        // furnished_name: "",
        digital_lock_view: "",
      });
      this.getCommServedData();
      this.getData();
    }
  }

  handleChangeCommServedList(value) {
    let communities_served_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        communities_served_id.push(item.value);
      });
    }
    this.setState({
      selectedCommServedList: value,
      communities_served_id: communities_served_id,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  render() {

    const customStyles = {

      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),   
        color: (state.isSelected && state.isFocused) ? "white" : (state.isSelected ? "black" : state.isFocused && "white"),
        backgroundColor: (state.isSelected && state.isFocused) ? 'black' : (state.isFocused && "black"),
      })
    }


    return (
      <div className="dashboardRightcard">
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <div className="row- d-flex justify-content-end">
          {/* <Link to={'/properties/update/'+this.props.property_slug+"/contact"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
          {this.state.viewMode === 0 ? (
            <button
              className="btn-success-outline-small"
              onClick={this.changeView}
            >
              <img src={editIcon} />
              Edit
            </button>
          ) : (
            <div className="d-flex">
              <button
                type="button"
                className="btn-success-outline-small"
                onClick={() => {
                  if (this.state.confirm_back === 1)
                    if (window.confirm("Do you want to exit without saving?"))
                      this.changeView();
                    else return false;
                  else this.changeView();
                }}
              >
                Cancel
              </button>
              <button
                className="btn-success ml-3"
                onClick={this.saveOrUpdateProperty}
              >
                Save
              </button>
            </div>
          )}
        </div>
        <div className="col-md-12 pl-0 pr-0 mg-t-15">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>

        {this.state.viewMode === 0 ? (
          <div className="row">
            <div className="col-md-12">
              <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">Ownership Type</p>
                  <p className="media-body">
                    {this.state.ownership_type_name
                      ? this.state.ownership_type_name
                      : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  {/* <p className="az-profile-name-text pl-0">
                    Fiscal Year Start Month
                  </p>
                  <p className="media-body">
                    {this.state.month_name ? this.state.month_name : ""}
                  </p> */}
                  <p className="az-profile-name-text pl-0">Minimum Age</p>
                  <p className="media-body">
                    {this.state.minimum_age_name
                      ? this.state.minimum_age_name
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">
                    Class Type
                  </p>
                  <p className="media-body">
                    {this.state.communities_served_name_data
                      ? this.state.communities_served_name_data
                      : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">Smoking Type</p>
                  <p className="media-body">
                    {this.state.smoking_type_name
                      ? this.state.smoking_type_name
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">LEED Certified</p>
                  <p className="media-body">
                    {this.state.leed_building_name
                      ? this.state.leed_building_name
                      : ""}
                  </p>
                </div>

                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">Asset Manager</p>
                  <p className="media-body">
                    {this.state.asset_managers
                      ? this.state.asset_managers
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row col-md-12 mg-md-t-0">
                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">Check in times</p>
                  <p className="media-body">
                    {this.state.check_in_time ? this.state.check_in_time : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">Check out times</p>
                  <p className="media-body">
                    {this.state.check_out_time
                      ? this.state.check_out_time
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row col-md-12 mg-md-t-0">
                {/* <div className="col-md-6"> */}
                {/* <p className="az-profile-name-text pl-0">
                    Insurance Required
                  </p>
                  <p className="media-body">
                    {this.state.insurance_required === true ? "Yes" : "No"}
                  </p> */}
                {/* <p className="az-profile-name-text pl-0">Furnished</p>
                  <p className="media-body">
                    {this.state.furnished_name
                      ? this.state.furnished_name
                      : ""}
                  </p>
                  <p className="az-profile-name-text pl-0">Move In Window</p>
                  <p className="media-body">
                    {this.state.move_in_window
                      ? this.state.move_in_window
                      : ""}
                  </p> */}
                {/* <p className="az-profile-name-text pl-0">Basement</p>
                  <p className="media-body">
                    {this.state.basement_name ? this.state.basement_name : ""}
                  </p> */}
                {/* <p className="az-profile-name-text pl-0">Parking</p>
                  <p className="media-body">
                    {this.state.parking_name ? this.state.parking_name : ""}
                  </p> */}
                {/* <p className="az-profile-name-text pl-0">Minimum Age</p>
                  <p className="media-body">
                    {this.state.minimum_age_name
                      ? this.state.minimum_age_name
                      : ""}
                  </p> */}
                {/* </div> */}
                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">Digital Locks</p>
                  <p className="media-body">
                    {this.state.digital_lock_view === true ? "Yes" : "No"}
                  </p>

                  {/* <p className="az-profile-name-text pl-0">Garden</p>
                  <p className="media-body">
                    {this.state.garden_name ? this.state.garden_name : ""}
                  </p> */}
                  {/* <p className="az-profile-name-text pl-0">Unit Category</p>
                  <p className="media-body">
                    {this.state.unit_category_name ? this.state.unit_category_name : ""}
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.viewMode === 1 ? (
          <div className="m-0">
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Ownership Type <span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={this.state.selectedOwnershipTypeList}
                  options={this.state.newOwnershipTypeList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) =>
                    this.handleChangeOwnershipTypeList(value)
                  }
                  defaultValue={this.state.selectedOwnershipTypeList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="ownership_type_id"
                  value={this.state.ownership_type_id}
                >
                  <option key="" value="">
                    Select Ownership Type
                  </option>
                  {this.state.ownershipTypeList
                    // .sort((a, b) => (a.label > b.label ? 1 : -1))
                    .map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.ownership_type_name}
                      </option>
                    ))}
                </select> */}
                {this.state.errors.ownership_type_id ? (
                  <div className="text-danger">
                    {this.state.errors.ownership_type_id}
                  </div>
                ) : (
                  this.validator.message(
                    "ownership_type",
                    this.state.ownership_type_id,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                <div className="col-md-3 pl-0">
                  <label className="form-label text-left mg-b-0">
                    Unit Category{" "}
                    <span className="text-danger">*</span>
                  </label>
                </div>

                <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedUnitCategoryList}
                  options={this.state.newUnitCategoryList}
                  onChange={(value) => this.handleChangeUnitCategoryList(value)}
                  defaultValue={this.state.selectedUnitCategoryList}
                />
                  
                {this.state.errors.unit_category_slug ? (
                  <div className="text-danger">
                    {this.state.errors.unit_category_slug}
                  </div>
                ) : (
                  this.validator.message(
                    "unit_category",
                    this.state.unit_category_slug,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div> */}
            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Fiscal Year Start Month
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedMonthList}
                  options={this.state.newMonthList}
                  onChange={(value) => this.handleChangeMonthList(value)}
                  defaultValue={this.state.selectedMonthList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,                    
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                   })}
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="month_id"
                  value={this.state.month_id}
                >
                  <option key="" value="">
                    Select Fiscal Month
                  </option>
                  {this.state.monthList
                    // .sort((a, b) => (a.label > b.label ? 1 : -1))
                    .map((option) => (
                      <option
                        key={option.fiscal_month_no}
                        value={option.fiscal_month_no}
                      >
                        {option.fiscal_month_value}
                      </option>
                    ))}
                </select> */}
            {/* {this.state.errors.month_id ? (
                  <div className="text-danger">
                    {this.state.errors.month_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Class Type
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  isMulti
                  styles={customStyles}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={this.state.selectedCommServedList}
                  options={this.state.newCommServedList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeCommServedList(value)}
                  defaultValue={this.state.selectedCommServedList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {this.state.errors.communities_served_id ? (
                  <div className="text-danger">
                    {this.state.errors.communities_served_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Smoking Type
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={this.state.selectedSmokingTypeList}
                  options={this.state.newSmokingTypeList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeSmokingTypeList(value)}
                  defaultValue={this.state.selectedSmokingTypeList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="smoking_type_id"
                  value={this.state.smoking_type_id}
                >
                  <option key="" value="">
                    Select Smoking Type
                  </option>
                  {this.state.smokingTypeList
                    // .sort((a, b) => (a.label > b.label ? 1 : -1))
                    .map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.smoking_type_name}
                      </option>
                    ))}
                </select> */}
                {this.state.errors.smoking_type_id ? (
                  <div className="text-danger">
                    {this.state.errors.smoking_type_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  LEED Certified
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={this.state.selectedLeedBuildingList}
                  options={this.state.newLeedBuildingList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeLeedBuildingList(value)}
                  defaultValue={this.state.selectedLeedBuildingList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="leed_building_id"
                  value={this.state.leed_building_id}
                >
                  <option key="" value="">
                    Select LEED Certified
                  </option>
                  {this.state.leedBuildingList
                    // .sort((a, b) => (a.label > b.label ? 1 : -1))
                    .map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.leed_building_name}
                      </option>
                    ))}
                </select> */}
                {this.state.errors.leed_building_id ? (
                  <div className="text-danger">
                    {this.state.errors.leed_building_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Asset Manager
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="asset_managers"
                  value={this.state.asset_managers}
                  maxLength="50"
                // placeholder="Asset Manager"
                />
                {this.state.errors.asset_managers ? (
                  <div className="text-danger">
                    {this.state.errors.asset_managers}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Check In Times
                  {/* <span className="text-danger">*</span> */}
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <span className="d-inline-flex align-items-start justify-content-start infosize">
                  <img src={infosize} className="mg-r-5" />
                  <p>
                    Time format will be HH:MM AM/PM 12 hours. (i.e. 07:30 PM)
                  </p>
                </span>
                <InputMask
                  name="check_in_time"
                  onChange={this.changeHandler}
                  value={this.state.check_in_time}
                  mask="99:99 aa"
                />
                {/* <input
                  type="text"
                  name="check_in_time"
                  value={this.state.check_in_time}
                  onKeyPress={global.onKeyPressEvent.timeValidation}
                  onChange={this.changeHandler}
                  // selected={this.state.check_in_time}
                /> */}
                {this.state.errors.check_in_time ? (
                  <div className="text-danger">
                    {this.state.errors.check_in_time}
                  </div>
                ) : (
                  this.validator.message(
                    "check_in_time",
                    this.state.check_in_time,
                    "",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Check Out Times
                  {/* <span className="text-danger">*</span> */}
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <span className="d-inline-flex align-items-start justify-content-start infosize">
                  <img src={infosize} className="mg-r-5" />
                  <p>
                    Time format will be HH:MM AM/PM 12 hours. (i.e. 07:30 PM)
                  </p>
                </span>
                <InputMask
                  name="check_out_time"
                  onChange={this.changeHandler}
                  value={this.state.check_out_time}
                  mask="99:99 aa"
                />
                {/* <input
                  type="text"
                  name="check_out_time"
                  onKeyPress={global.onKeyPressEvent.timeValidation}
                  onChange={this.changeHandler}
                  value={this.state.check_out_time}
                /> */}
                {this.state.errors.check_out_time ? (
                  <div className="text-danger">
                    {this.state.errors.check_out_time}
                  </div>
                ) : (
                  this.validator.message(
                    "check_out_time",
                    this.state.check_out_time,
                    "",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Insurance Required
                </label>
              </div>
              <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                <Form.Check
                  name="insurance_required"
                  type={"checkbox"}
                  id={`insurance_required`}
                  label={""}
                  className={"ckbox"}
                  onChange={this.changeHandler}
                  checked={this.state.insurance_required}
                />
              </div>
            </div> */}
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Digital Locks
                </label>
              </div>
              <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                <Form.Check
                  name="digital_lock"
                  type={"checkbox"}
                  id={`digital_lock`}
                  label={""}
                  className={"ckbox"}
                  onChange={this.changeHandler}
                  checked={this.state.digital_lock}
                />
              </div>
            </div>

            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Move-In Window
                </label>
              </div>

              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="move_in_window"
                  value={this.state.move_in_window}
                  maxLength="50"
                  // placeholder="Move-In Window"
                />
              </div>
            </div> */}

            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Minimum Age to Rent
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={this.state.selectedMinimumAgeList}
                  options={this.state.newMinimumAgeList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeMinimumAgeList(value)}
                  defaultValue={this.state.selectedMinimumAgeList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="minimum_age"
                  value={this.state.minimum_age}
                >
                  <option key="" value="">
                    Select Minimum Age
                  </option>
                  {this.state.minimumAgeList.map((option) => (
                    <option key={option.slug} value={option.slug}>
                      {option.name}
                    </option>
                  ))}
                </select> */}
                {this.state.errors.minimum_age ? (
                  <div className="text-danger">
                    {this.state.errors.minimum_age}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Basement</label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedBasementList}
                  options={this.state.newBasementList}
                  onChange={(value) => this.handleChangeBasementList(value)}
                  defaultValue={this.state.selectedBasementList}
                />
               
                {this.state.errors.basement ? (
                  <div className="text-danger">
                    {this.state.errors.basement}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}

            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Garden </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedGardenList}
                  options={this.state.newGardenList}
                  onChange={(value) => this.handleChangeGardenList(value)}
                  defaultValue={this.state.selectedGardenList}
                />
                
                {this.state.errors.garden ? (
                  <div className="text-danger">{this.state.errors.garden}</div>
                ) : (
                  ""
                )}
              </div>
            </div> */}

            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Parking </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  styles={customStyles}
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedParkingList}
                  options={this.state.newParkingList}
                  onChange={(value) => this.handleChangeParkingList(value)}
                  defaultValue={this.state.selectedParkingList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,                    
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                   })}
                />
                {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="parking"
                  value={this.state.parking}
                >
                  <option key="" value="">
                    Select Parking
                  </option>
                  {this.state.parkingList.map((option) => (
                    <option key={option.slug} value={option.slug}>
                      {option.name}
                    </option>
                  ))}
                </select> */}
            {/* {this.state.errors.parking ? (
                  <div className="text-danger">{this.state.errors.parking}</div>
                ) : (
                  ""
                )}
              </div>
            </div> */}
            {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">Furnished</label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Select
                  className="multiselect"
                  menuPlacement="auto"
                  value={this.state.selectedFurnishedList}
                  options={this.state.newFurnishedList}
                  onChange={(value) => this.handleChangeFurnishedList(value)}
                  defaultValue={this.state.selectedFurnishedList}
                />
               
                {this.state.errors.furnished ? (
                  <div className="text-danger">
                    {this.state.errors.furnished}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default PropertyAttributesView;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: OnKeyPressEvent.jsx                          //
//  Application: Common Components                        //
//  Option: For to prevent specific key value             //
//  Developer: CV  						                  //
//  Date: 2021-04-13                                      //
////////////////////////////////////////////////////////////

import { Component } from "react";

class CommonMessages extends Component {
  checkMessage(pageName) {
    switch (pageName) {
      case "BRAND_INACTIVE":
        return "Inactive Brands";
      case "BRAND_ACTIVE":
        return "Active Brands";
      case "CHECKLIST_TEMPLATE":
        return "Checklist Templates";
      case "CRM_STAFF":
        return "Staff Contacts";
      case "CRM_VENDOR":
        return "Vendor Contacts";
      case "CRM_RESIDENT":
        return "Resident Contacts";
      case "RESIDENTS":
        return "ResidentS";
      case "GUESTS":
        return "Guests";
      case "PROPERTY_DOCUMENTS":
        // return "Property Documents";
        return "Property Files";
      case "UNIT_DOCUMENTS":
        return "Unit Documents";
      case "LEASE_DOCUMENTS":
        return "Lease Documents";
      case "TOWERS":
        return "Towers";
      case "FLOORS":
        return "Floors";
      case "FLOOR_PLANS":
        return "Floor Plans";
      case "UNITS":
        return "Units";
      case "USERS_ACTIVE":
        return "Active Users";
      case "USERS_INACTIVE":
        return "Inactive Users";
      case "RESERVATIONS":
        return "Reservations";
      case "CURRENTLY_HOSTING_RESERVATIONS":
        return "Currently Hosting Reservations";
      case "UPCOMING_RESERVATIONS":
        return "Upcoming Reservations";
      case "COMPLETED_RESERVATIONS":
        return "Completed Reservations";
      case "CURRENT_LEASES":
        return "Current Leases";
      case "UPCOMING_LEASES":
        return "Upcoming Leases";
      case "PAST_LEASES":
        return "Past Leases";
      case "INACTIVE_LEASES":
        return "Inactive Leases";
      case "LEASES":
        return "Leases";
      case "LISTING_INACTIVE":
        return "Inactive Listings";
      case "LISTING_ACTIVE":
        return "Active Listings";
      case "PROPERTY_TAXES":
        return "Taxes";
      case "OWNERS":
        return "Owners";
      case "PROPERTY_FEES":
        return "Fees";
      case "PROPERTY_AMENITIES":
        return "Property Amenities";
      case "UNIT_AMENITIES":
        return "Unit Amenities";
      case "FLOOR_PLAN_AMENITIES":
        return "Floor Plan Amenities";
      case "LISTING_AMENITIES":
        return "Listing Amenities";
      case "CREDENTIALS":
        return "Credentials";
      case "IMAGES":
        return "Images";
      case "NEARBYATRACTION":
        return "Near By";
      case "EVENST":
        return "Events";
      case "INFLUENCERS":
        return "Influencers";
      case "LEASING":
        return "leases";
      case "APPLICANTS":
        return "Applicants";
      case "APPLICATNSDETAILS":
        return "Inquiry";
      case "RENTS":
        return "Rents";
      case "MRO":
        return "Maintenance Requests";
      case "KEYCAFE":
        return "Units";
      case "ALERT":
        return "Alert";
      case "QUERYSUPORT":
        return "Support";
      case "ROLE":
        return "Roles";
      case "REFUNDABLEPOLICY":
        return "Cancellation Policy";
      case "GUESTMANNUAL":
        return "House Manual";
      case "HEALTHSEFTY":
        return "Health & Safety";
      case "GETTINGAROUND":
        return "Getting Around";
      case "MARKETING":
        return "Marketing Copy";
      case "HOUSERULES":
        return "House Rules";
      //for master data
      case "ACCESSIBILITYFEATURETYPE":
        return "Accessibility Feature Type ";
      case "ACCESSIBILITYFEATURE":
        return "Accessibility Feature ";
      case "AFFORDABLETYPE":
        return "Affordable Type ";
      case "AGENCYCATEGORY":
        return "Agency Category ";
      case "AMANITYTYPE":
        return "Amenity Type ";
      case "AMENITY":
        return "Amenities ";
      case "CHANNEL":
        return "Channel ";
      case "CLASSTYPE":
        return "Class Type ";
      case "DOCUMENTTYPE":
        return "Document Type ";
      case "EVENTTYPE":
        return "Event Type ";
      case "FLOORING":
        return "Flooring Type ";
      case "HEATINGTYPE":
        return "Heating Type ";
      case "LEEDBUILDING":
        return "LEED Certification Type ";
      case "LOCATIONTYPE":
        return "Location Type ";
      case "MAROCATEGORY":
        return "MRO Category ";
      case "OWNERSHIPTYPE":
        return "Ownership Type ";
      case "PARKINGTYPE":
        return "Parking Type ";
      case "PETTYPE":
        return "Pet Type ";
      case "PROPERTYTYPE":
        return "Property Type ";
      case "ROOMCATEGORY":
        return "Room Category ";
      case "ROOMTYPE":
        return "Room Type ";
      case "SMOKINGTYPE":
        return "Smoking Type ";
      case "STAFFCONTACTTYPE":
        return "Staff Type";
      case "PROPERTYSUTABILITYTYPE":
        return "Suitability";
      case "TIMEZONE":
        return "Timezone";
      case "COSTPAYABLEAT":
        return "Cost Payable At";
      case "COSTPAYABLEBY":
        return "Cost Payable By";
      case "COSTTYPE":
        return "Fee Type";
      case "GENERALTAX":
        return "Taxes";
      case "COUNTRY":
        return "Country";
      case "STATE":
        return "State";
      case "COUNTY":
        return "County";
      case "CITY":
        return "City";
      case "METRO":
        return "Metro";
      case "SUBMETRO":
        return "Sub Metro";
      case "PROPERTYTYPELOOKUP":
        return "Floor Plans LookUp";
      case "AMENITYTYPELOOKUP":
        return "Amenity Type LookUp";
      case "NEARBY":
        return "Near By LookUp";
      case "FEETYPELOOKUP":
        return "Fees LookUp";
      case "MASTERTAXLOOKUPS":
        return "Master Tax LookUp";
      case "TAXESLOOKUP":
        return "Taxes LookUp";
      case "COUNTRYLOOKUP":
        return "Countries LookUp";
      case "STATELOOKUP":
        return "States LookUp";
      case "CITYLOOKUP":
        return "City LookUp";
      case "AGENTTYPELOOKUP":
        return "Channels LookUp ";
      case "OTATYPELOOKUP":
        return "Properties Ota LookUp ";
      case "INACTIVE_PROPERTIES":
        return "Inactive Properties";
      case "ACTIVE_PROPERTIES":
        return "Active Properties";
      case " QUICK_REPLIES":
        return "Quick Replies";
      //Search

      case "RESIDENT":
        return "Residents";
      case "SEARCHGUEST":
        return "Guests";

      default:
        return "Records";
    }
  }
}
export default CommonMessages;

//     							                          //
//  Program: HomeSharingListing.jsx                       //
//  Application: HomeSharing                              //
//  Option: Home Sharing Listing                          //
//  Developer: NP 		                                  //
//  Date: 2022-04-02                                      //
//                                                        //
///////////////////////////////////////////////////////// //

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import LeasingService from "../../services/LeasingService";
import ErrorLogo from "./../../assets/images/errorImage.svg";
import RentService from "../../services/RentService";
import SuccessLogo from "./../../assets/images/successImage.svg";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, InputGroup, Form } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import Alerts from "../common/Alerts";
import NoData from "../common/NoData";
import Moment from "moment";

import queryString from "query-string";
import LeasingSidebar from "../common/LeasingSidebar";
 import filterIcon from "./../../assets/images/filter-gray.svg"; 
import exportIcon from "./../../assets/images/export.svg";
import { FileUploader } from "react-drag-drop-files";
import deleteicon from "./../../assets/images/delete.svg";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";
import DatePicker from "react-datepicker";
import Select from "react-select";
import UnitsService from "../../services/UnitsService";
import * as Vars from "./../../Vars";
import { read, utils } from "xlsx";
import DataHubAutomationService from "../../services/DataHubAutomationService";
const DatabaseCols = Vars.default.DatabaseCols.filter((item) => {
  return item.module_name == "unit_rent";
});
const DatabaseColMapping = Vars.default.DatabaseColMapping.filter((item) => {
  return item.module_name == "unit_rent";
});
const unitRentRequiredFields = Vars.default.unitRentRequiredFields;

let queryParams = queryString.parse(window.location.search);

class Rents extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      errors: {},
      importModal: false,
      file_url: "",
      file_name: "",
      open: true,
      eventKey: "0",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      module_search: "",
      exportMesssage: "",
      exportFilePath: "",
      exportResult: "",
      alertType: "xlsx",
      is_search: 0,
      isSearch: global.isSearch,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      deleteRoleModal: false,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      records: [],
      file_rows: [],
      file_columns: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_number",
          text: "Unit",
          className: "unit_number td_clickable",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.unit_name ? record.unit_name : record.unit_number;
          },
        },
        {
          key: "rent_month",
          text: "Rent Month",
          className: "rent_month text-left td_clickable",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.rent_month;
          },
        },
        {
          key: "amount",
          text: "Amount",
          className: "amount td_clickable",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.slug ? record.amount : record.amount;
          },
        },
        {
          key: "rent_paid_date",
          text: "Rent Paid Date",
          className: "rent_paid_date td_clickable",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.slug ? record.rent_paid_date : record.rent_paid_date;
          },
        },
        {
          key: "rent_paid_by",
          text: "Rent Paid By",
          className: "rent_paid_by td_clickable",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.slug ? record.rent_paid_by : record.rent_paid_by;
          },
        },
        {
          key: "delete",
          text: "Action",
          className: "rent_paid_by  ",
          sortable: true,
          width: 300,
          cell: (record) => {
            return (
              <img
                src={deleteicon}
                alt=""
                className="hand-cursor"
                onClick={() => this.openDeleteRoleModal(record.slug)}
              />
            );
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.confirmImport = this.confirmImport.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeUnitList = this.handleChangeUnitList.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      this.getData();
    }
    this.getUnitList();

  }

  closeConfirmationModal() {
    this.setState({ ConfirmationModal: false });
  }

  async openConfirmationModal() {
    if (this.state.file_url) {
      const missingColumns = this.state.database_cols_mapping.filter((item) => {
        return (
          unitRentRequiredFields.includes(item.database_column) &&
          item.file_column === ""
        );
      });

      if (missingColumns.length > 0) {
        this.setState({
          requiredColumnsErrMsg: "Please select all required column.",
        });
        return; // return missingColumns if you want to return the array
      } else {
        this.setState({ requiredColumnsErrMsg: "" });
      }
      this.setState({
        showSpinner: true,
        isSubmit: true,
        ConfirmationModal: false,
      });
      const fileData = new FormData();
      fileData.append(
        "mapped_columns",
        JSON.stringify(this.state.database_cols_mapping)
      );
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append("property_slug", this.state.property_slug);
      fileData.append("import_type", this.state.import_type);
      let res = await DataHubAutomationService.RentimportPreview(
        fileData
      );
      if (global.successStatus.includes(res.status)) {
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const recordError = res.data.record_error_array;
          const FailedRecord = res.data.failed_record;
          const is_date_invalid = res.data.is_date_invalid;
          if (is_date_invalid > 0) {
            this.setState({
              is_date_invalid: true,
            });
          } else {
            this.setState({
              is_date_invalid: false,
            });
          }
          this.setState({
            totalRecord: totalRecord,
          });
          this.setState({
            successRecord: successRecord,
          });
          this.setState({
            skipRecord: skipRecord,
          });
          this.setState({
            recordError: recordError,
          });
          this.setState({
            FailedRecord: FailedRecord,
          });
        }
      } else {
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const recordError = res.data.record_error_array;
          const FailedRecord = res.data.failed_record;
          const is_date_invalid = res.data.is_date_invalid;
          if (is_date_invalid > 0) {
            this.setState({
              is_date_invalid: true,
            });
          } else {
            this.setState({
              is_date_invalid: false,
            });
          }
          if (successRecord !== undefined) {
            this.setState({
              totalRecord: totalRecord,
            });
            this.setState({
              successRecord: successRecord,
            });
            this.setState({
              skipRecord: skipRecord,
            });
            this.setState({
              recordError: recordError,
            });
            this.setState({
              FailedRecord: FailedRecord,
            });
          }
        }
      }
      setTimeout(() => {
        this.setState({ showSpinner: false, isSubmit: false, show: false });
        this.setState({ ConfirmationModal: true });
      }, 2000);
    } else {
      this.setState({ ConfirmationModal: false });
      this.validator.showMessages();
    }
  }

  handleChangeImportColsList(value, index) {
    let elements = [...this.state.database_cols_mapping];
    elements[index].file_column = value.value;
    this.setState({ elements });
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getData();
      this.getUnitList();
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    let res = await LeasingService.getRentList(
      "property_slug=" + this.state.property_slug +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

   /* to get unit data list */
   async getUnitList(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + (this.state.property_slug ? this.state.property_slug : "") +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });

    this.setState({ showSpinner: false, loading: false });
  }
  openDeleteRoleModal(slug) {
    this.setState({ deleteRoleModal: true });
    this.setState({ UnitSlug: slug });
  }

  async deleteUnitRent(slug) {
    this.setState({ showSpinner: true });
    let res = await RentService.deleteRent(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteRoleModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        records: this.state.records.filter((list) => list.slug !== slug),
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "rent"
              : "/rent"
          ),
        global.redirect_time
      );
    } else {
      this.closeDeleteRoleModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
    setTimeout(
      () =>
        this.props.history.push(
          this.props.match.params.propertySlug !== undefined ? "rent" : "/rent"
        ),
      global.redirect_time
    );
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({ amount: "", unit_number: "" });
    this.setState({ module_search: "" });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let rentMonth = this.state.rent_month
      ? Moment(this.state.rent_month).format("YYYY/MM/DD")
      : "";
      let rentPaidDate = this.state.rent_paid_date
      ? Moment(this.state.rent_paid_date).format("YYYY/MM/DD")
      : "";
    let queryString =
      "amount=" +
      (this.state.amount ? this.state.amount : "") +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&rent_month=" +
      rentMonth +
      "&rent_paid_by=" +
      (this.state.rent_paid_by ? this.state.rent_paid_by : "") + 
      "&rent_paid_date=" +
      rentPaidDate +
      "&is_search=1";
    

    let moduleSearchString = "";
    moduleSearchString = this.state.amount
      ? "Amount - " + this.state.amount
      : "";

      moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
          " Unit Number- " +
          this.state.selectedUnitList.label
        : " Unit Number - " + this.state.selectedUnitList.label
      : moduleSearchString;

      moduleSearchString = this.state.rent_month
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
          " Rent Month - " +
          Moment(this.state.rent_month).format(global.dateFormat)
        : " Rent Month - " +
          Moment(this.state.rent_month).format(global.dateFormat)
      : moduleSearchString;

      moduleSearchString = this.state.rent_paid_by
      ? moduleSearchString
        ? moduleSearchString + " ," + " Rent Paid By - " + this.state.rent_paid_by
        : " Rent Paid By - " + this.state.rent_paid_by
      : moduleSearchString;

      moduleSearchString = this.state.rent_paid_date
      ? moduleSearchString
        ? moduleSearchString +
        " ," +
          " Rent Paid Date - " +
          Moment(this.state.rent_paid_date).format(global.dateFormat)
        : " Rent Paid Date - " +
          Moment(this.state.rent_paid_date).format(global.dateFormat)
      : moduleSearchString;

    if (this.state.amount || this.state.unit_slug || this.state.rent_paid_date || this.state.rent_paid_by || this.state.rent_month) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(',') });
      this.getData(queryString);
    }
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  async ExportData() {
    let data = {
      property_slug: this.state.property_slug,
      file_type: this.state.alertType,
      where_column: this.state.customQueryString,
    };
    let res = await RentService.exportDataFilewise(data);
    this.setState({ exportMesssage: res.message });
    this.setState({ exportResult: data });
    this.setState({ exportFilePath: res.data.filePath });
  }

  exportToggle = () => {
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
    } else {
      this.setState({ isModel: 1 });
    }
  };

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  closeDeleteRoleModal() {
    this.setState({ deleteRoleModal: false });
    this.setState({ slug: "" });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Unit Rent";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    // this.props.history.push("unit-rent/update/" + data.slug);
  };

  handleChange = (file) => {
    this.setState({ database_cols_mapping: DatabaseColMapping });
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, { raw: true });
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 0,
            defval: "",
          });
          if (rows.length > 0) {
            let columns = Object.keys(rows[0]);
            let available_columns = columns.map((col) => {
              let val = col
                .replace("-", "_")
                .replace(/[^\w ]/g, "")
                .replace(/^\s+|\s+$/gm, "")
                .split(" ")
                .join("_")
                .replace("-", "_")
                .toLowerCase();
              return {
                label: col,
                value: val,
              };
            });
            this.setState({
              file_rows: rows,
              file_columns: columns,
              available_file_columns: available_columns,
            });
          } else {
            this.setState({ file_name: "" });
            this.setState({ file_url: "" });
            return false;
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
    this.setState({ file_name: file.name });
    this.setState({ file_url: file });
  };

  async confirmImport() {
    if (this.state.file_url) {
      this.setState({
        showSpinner: true,
        isSubmit: true,
        ConfirmationModal: false,
      });
      const fileData = new FormData();
      fileData.append(
        "mapped_columns",
        JSON.stringify(this.state.database_cols_mapping)
      );
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append("property_slug", this.state.property_slug);
      fileData.append("import_type", this.state.import_type);
      let res = await RentService.importData(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({ importModal: false });
        this.setState({ importResponseModel: true });
        this.setState({ successResponse: true });
        this.setState({ successMessage: res.message });
        this.setState({
          file_rows: [],
          file_columns: [],
          available_file_columns: [],
          database_cols_mapping: DatabaseColMapping,
          requiredColumnsErrMsg : '',
        });
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const recordError = res.data.record_error_array;
          const FailedRecord = res.data.failed_record;
          this.setState({
            totalRecord: totalRecord,
          });
          this.setState({
            successRecord: successRecord,
          });
          this.setState({
            skipRecord: skipRecord,
          });
          this.setState({
            recordError: recordError,
          });
          this.setState({
            FailedRecord: FailedRecord,
          });
        }
        this.getData();
       
      } else {
        this.setState({ database_cols_mapping: DatabaseColMapping });
        this.setState({ importModal: false });
        this.setState({ importResponseModel: true });
        this.setState({ errorResponse: true });
        this.setState({ errorMessage: res.data.message });
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const recordError = res.data.record_error_array;
          const FailedRecord = res.data.failed_record;
          if (successRecord !== undefined) {
            this.setState({
              totalRecord: totalRecord,
            });
            this.setState({
              successRecord: successRecord,
            });
            this.setState({
              skipRecord: skipRecord,
            });
            this.setState({
              recordError: recordError,
            });
            this.setState({
              FailedRecord: FailedRecord,
            });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false, show: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ ConfirmationModal: false });
      this.validator.showMessages();
    }
  }

  confirmImportModal() {
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({
      file_rows: [],
      file_columns: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
      requiredColumnsErrMsg: "",
      file_url: "",
      file_name: "",
      import_type: "",
      importModal: false,
    });
    this.validator.hideMessages();
    //need to reload page if file field is mapped 
    for (let i = 0; i < DatabaseColMapping.length; i++) {
      if (DatabaseColMapping[i].file_column !== '') {
        window.location.reload();
      }
    }
  }

  closeimportResponseModel() {
    this.setState({ importResponseModel: false });
    this.validator.hideMessages();
    window.location.reload();
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <LeasingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyLeasingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div className="col-md-10 right">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission("rent-add") && this.state.property_slug && (
                          <Link
                            to={
                              this.props.match.params.propertySlug !== undefined
                                ? "unit-rent/add"
                                : "/unit-rent/add/" + this.state.property_slug
                            }
                          >
                            <button className="btn-success-outline-small">
                              <img src={pluswIcon} alt="" /> Add
                            </button>
                          </Link>
                        )}
                         <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small ml-3"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button> 
                        {global.userPermissions.checkPermission(
                          "rent-export"
                        ) && (
                          <button
                            className="btn-success-outline-small ml-3"
                            onClick={this.exportToggle}
                          >
                            Export
                          </button>
                        )}
                        {global.userPermissions.checkPermission(
                          "rent-import"
                        ) && (
                          <button
                            type="button"
                            onClick={() => this.confirmImportModal()}
                            className="btn-success-outline ml-3"
                          >
                            {" "}
                            <img src={exportIcon} alt="" /> Import{" "}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.props.match.params.propertySlug === undefined ? (
                    <>
                    <div className="row pd-lg-l-15 pd-xs-l-15">
                    <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                      <OutSidePropertySelectBox
                        propertySlug={this.getPropertySlug.bind(this)}
                        outSidePropertySlug={this.state.outSidePropertySlug}
                      />
                    </div>
                    <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                      {this.state.is_search === 1 ? (
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter">
                              <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                              <div className="filter_bg_cust">
                                {
                                  this.state.module_search.map((item, i) => {
                                    return (
                                      <span key={i}>{item}</span>
                                    )
                                  })
                                }
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >Clear</button>
                              </div>
                            </div>
                          </div>
                        </>
                  ) : (
                    ""
                  )}
                    </div>
                    </div>
                    </>
                  ) : (
                    <>
                    <div className="col-xxl-12 pd-r-15 pd-l-15">
                      {this.state.is_search === 1 ? (
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter">
                              <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                              <div className="filter_bg_cust">
                                {
                                  this.state.module_search.map((item, i) => {
                                    return (
                                      <span key={i}>{item}</span>
                                    )
                                  })
                                }
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >Clear</button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    </>
                  )}
                  <div className="scrolling-carousel pd-l-15">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.showFilter ? (
                        <Row className="align-items-center order_form">
                          <Col
                            md={3}
                            lg={5}
                            xl={5}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <input
                              name="filter_value"
                              className="form-control"
                              autoComplete="off"
                              value={this.state.filter_value}
                              onChange={this.changeHandler}
                              maxLength="30"
                            />
                          </Col>
                          <Col md={5} lg={5} xl={5}>
                            <Row>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-30 mg-r-20"
                              >
                                Search
                              </button>
                              <button
                                onClick={this.resetDate}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      {queryParams.isGlobal === "1" ? (
                        <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                          {"Search applied on: " +
                            queryParams.global_col_label +
                            " - " +
                            queryParams.global_search}
                          <button
                            onClick={this.clearFilter}
                            className="mg-l-15 btn-success-outline-small"
                          >
                            Clear
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      
                      {this.state.total_record !== 0 ||
                      this.state.is_search === 1 ? (
                        <div className="mg-t-18 brandList pd-l-15 d-block">
                          <div className="pd-lg-l-0 pd-xs-l-10 rent_tbl">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 text-align-left pd-lg-l-0 pd-xs-l-15"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="mg-t-30 text-center">
                                <NoData msg="rents" />
                              </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Unit Rent</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
            <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
               <InputGroup className="mt-3"> 
                  <label>Unit Number</label>
                   <div className="col-md-12 p-0">
                   <Select 
                          styles={customStyles}
                          isClearable={true}
                          className="multiselect"
                          classNamePrefix={"my-custom-react-select"}
                          menuPlacement="auto"
                          value={this.state.selectedUnitList}
                          options={this.state.newUnitList}
                          onChange={(value) => this.handleChangeUnitList(value)}
                          defaultValue={this.state.selectedUnitList}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 2,
                            colors: {
                              ...theme.colors,
                              primary: "#fff",
                              primary75: "#000",
                              primary50: "#000",
                              primary25: "#000",
                            },
                          })}
                        />
                  </div>
                 </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Amount</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="amount"
                      value={this.state.amount}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Rent Month</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="rent_month"
                    selected={this.state.rent_month}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "rent_month",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Rent Paid Date</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="rent_paid_date"
                    selected={this.state.rent_paid_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "rent_paid_date",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Rent Paid By</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="rent_paid_by"
                      value={this.state.rent_paid_by}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
             
              
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
        <Modal show={this.state.isModel} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.exportToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Export</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <InputGroup className="mt-3">
                  <label>Select Export File Type : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-l-15 pd-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="xlsx"
                        checked={this.state.alertType === "xlsx" ? true : false}
                        type={"radio"}
                        id="xlsx"
                        label={"XLSX"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="csv"
                        checked={this.state.alertType === "csv" ? true : false}
                        type={"radio"}
                        id="csv"
                        label={"CSV"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>

                {this.state.exportMesssage !== "" && (
                  <div>
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {this.state.exportFilePath !== undefined && (
                      <a href={this.state.exportFilePath}> Download File </a>
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                  >
                    {this.state.isSubmit ? global.loader : "Export"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block"
                    onClick={() => this.exportToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* import data modal */}
        <Modal
          show={this.state.importModal}
          className="modal-xs importModalbox"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-center mt-0 row">
                  <form name="myFile">
                    <FileUploader
                      onChange={this.changeHandler}
                      handleChange={this.handleChange}
                      name="file_name"
                      types={["csv", "xlsx", "xls", "ods"]}
                      label="Drop File Here Max Size 30 MB"
                    />

                    {this.state.errors.file_name ? (
                      <div className="text-danger">
                        {this.state.errors.file_name}
                      </div>
                    ) : (
                      this.validator.message(
                        "import_file",
                        this.state.file_name,
                        "required",
                        { className: "text-danger" }
                      )
                    )}

                    <div className="d-flex justify-content-center mt-3 ">
                      <button
                        type="button"
                        className="m-0 btn btn-outline-light btn-block"
                        onClick={() => this.closeConfirmImportModal()}
                      >
                        Cancel
                      </button>

                      <button
                        type="button"
                        className="m-0 btn btn btn-success btn-block"
                        onClick={this.confirmImport}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit ? global.loader : "Import"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row col-12 m-0 download-sample">
              <Link
                to="/import_sample/Rent.csv"
                target="_blank"
                download="Rent.csv"
              >
                Download sample template for Import
              </Link>
            </div>
          </Modal.Body>
        </Modal>
        {/* import data modal */}
        <Modal
          show={this.state.importResponseModel}
          className="modal-xs"
          centered
        >
          <Modal.Body className="width_402 p-0 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeimportResponseModel()}
            >
              {global.closee}
            </span>
            {/* <h3 className="text-center">Record detail</h3> */}
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-between mt-0 row">
                  {this.state.errorResponse == true ? (
                    <div className="msg col-12 text-center mt-4">
                      <img src={ErrorLogo} alt="filterIcon" className="mb-2" />{" "}
                      <p className="mb-0">{this.state.errorMessage}</p>{" "}
                    </div>
                  ) : (
                    <div className="msg col-12 text-center mt-4">
                      <img
                        src={SuccessLogo}
                        alt="filterIcon"
                        className="mb-2"
                      />
                      <p className="mb-0">{this.state.successMessage}</p>{" "}
                    </div>
                  )}
                  {this.state.totalRecord ? (
                    <div className="mt-0">
                      <div className="row flex-wrap m-0">
                        <div className="row p-4 pb-0-cust">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Total Record
                            </p>
                            <p className="media-body">
                              {this.state.totalRecord}{" "}
                            </p>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Success Record
                            </p>
                            <p className="media-body">
                              {this.state.successRecord}{" "}
                            </p>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Skip Record
                            </p>
                            <p className="media-body">
                              {this.state.skipRecord}{" "}
                            </p>
                          </div>{" "}
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Failed Record
                            </p>
                            <p className="media-body">
                              {this.state.FailedRecord}
                            </p>
                          </div>
                        </div>
                        <div className="row pl-rm-cust pb-0-cust w-100">
                          <div className="col-sm-12">
                            <p className="az-profile-name-text pl-0">
                              Log Informations
                            </p>
                          </div>
                        </div>
                        <div className="row information-logs-container w-100">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
                            <p
                              className="media-body pr-3"
                              dangerouslySetInnerHTML={{
                                __html: this.state.recordError
                                  ? this.state.recordError
                                  : "No any record skipped.",
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.deleteRoleModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteRoleModal()}
              >
                {global.closee}
              </span>
              <img src={deleteicon} alt="" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-between mt-3 row">
                <button
                  type="button"
                  className="mx-auto m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteRoleModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="mx-auto m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteUnitRent(this.state.UnitSlug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.importModal}
          className=" importModalbox import_model_big"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="row">
              <div className=" col-md-12 m-0">
                {this.state.file_rows.length ? (
                  <div className="view_tbl_bdr">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive view_tbl_exp">
                          <table className="table file_preview_tbl">
                            <thead>
                              <tr>
                                <th scope="col">Id</th>
                                {this.state.file_columns.length
                                  ? this.state.file_columns.map(
                                      (cols, index) => (
                                        <th scope="col" key={index}>
                                          {cols}
                                        </th>
                                      )
                                    )
                                  : ""}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.file_rows.length
                                ? this.state.file_rows.map(
                                    (file_row, index) => {
                                      let colVals = Object.values(file_row);
                                      return (
                                        <tr key={index}>
                                          <th scope="row">{index + 1}</th>
                                          {colVals.map((colVal, idx) => {
                                            return <td key={idx}>{colVal}</td>;
                                          })}
                                        </tr>
                                      );
                                    }
                                  )
                                : ""}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row mt-2">
                  <div className="col-md-8 ">
                    <div className="row desk_expo">
                      <div className="col-md-6 pr-0">
                        <div className="export_tbl_fst">
                          <p>Database Columns</p>
                        </div>
                      </div>
                      <div className="col-md-6 pl-0 ">
                        <div className="export_tbl_fst">
                          <p>File Column</p>
                        </div>
                      </div>
                    </div>
                    <div className="file_export_hgt">
                      <div className="col-md-6 p-0">
                        <div className="export_tbl_fst">
                          <p className="mob-expo">Columns</p>
                          <ul>
                            {DatabaseCols.map((column, index) => {
                              return <li key={index}>{column.label}{unitRentRequiredFields.includes(
                                    column.value
                                  ) ? (
                                    <label className="text-danger m-0">*</label>
                                  ) : (
                                    ""
                                  )}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6 p-0">
                        <div className="export_tbl_sec">
                          <p className="mob-expo">Select Columns</p>
                          <ul>
                            {DatabaseCols.map((column, index) => {
                              return (
                                <li key={index}>
                                  <Select
                                    placeholder={"Select For " + column.label}
                                    styles={customStyles}
                                    className="multiselect exp_filter_slect"
                                    menuPlacement="bottom"
                                    menuPosition="fixed"
                                    options={this.state.available_file_columns}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeImportColsList(
                                        value,
                                        index
                                      )
                                    }
                                    defaultValue={[]}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-4 m-0 download-sample elx_pdf_epo pt-0">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                      <div className="">
                        <form name="myFile">
                          <FileUploader
                            onChange={this.changeHandler}
                            handleChange={this.handleChange}
                            name="file_name"
                            types={["csv", "xlsx", "xls", "ods"]}
                            label="Drop File Here Max Size 30 MB"
                          />

                          {this.state.errors.file_name ? (
                            <div className="text-danger">
                              {this.state.errors.file_name}
                            </div>
                          ) : (
                            this.validator.message(
                              "import_file",
                              this.state.file_name,
                              "required",
                              { className: "text-danger" }
                            )
                          )}

                          {this.state.requiredColumnsErrMsg !== "" ? (
                            <div className="text-danger">
                              {this.state.requiredColumnsErrMsg}
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="d-flex justify-content-center mt-3 ">
                            <button
                              type="button"
                              className=" m-0 btn btn-outline-light btn-block"
                              onClick={() => this.closeConfirmImportModal()}
                            >
                              Cancel
                            </button>

                            <button
                              type="button"
                              className="m m-0 btn btn btn-success btn-block"
                              onClick={() => this.openConfirmationModal()}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit ? global.loader : "Import"}
                            </button>
                          </div>
                        </form>
                      </div>
                      <Link
                        to="/import_sample/rent.csv"
                        target="_blank"
                        download="rent.csv"
                        className="dwon_imp_file"
                      >
                        Download sample template for Import
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Confirm popup modal */}
        <Modal
          show={this.state.ConfirmationModal}
          onHide={() => {
            if (
              window.confirm(
                "Are you sure to exit the pop up without run Confirmation?"
              )
            )
              this.closeConfirmationModal();
          }}
          centered
          className="exp_conf_modal"
        >
          <Modal.Body className="width_402 p-0 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeConfirmationModal()}
            >
              {global.closee}
            </span>
            {/* before import preview */}
            <div className="moreMsg mt-3">
              <div className="row flex-wrap m-0">
                <div className="row p-4 pb-0-cust">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Total Record</p>
                    <p className="media-body">{this.state.totalRecord} </p>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Success Record</p>
                    <p className="media-body">{this.state.successRecord} </p>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Skip Record</p>
                    <p className="media-body">{this.state.skipRecord} </p>
                  </div>{" "}
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Failed Record</p>
                    <p className="media-body">{this.state.FailedRecord}</p>
                  </div>
                </div>
                <div className="row pl-rm-cust pb-0-cust w-100">
                  <div className="col-sm-12">
                    <p className="az-profile-name-text pl-0">
                      Log Informations
                    </p>
                  </div>
                </div>
                <div className="row information-logs-container w-100 mb-3">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
                    <p
                      className="media-body mb-0 pr-3"
                      dangerouslySetInnerHTML={{
                        __html: this.state.recordError
                          ? this.state.recordError
                          : "No any record skipped.",
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            {/* end code before import preview */}
            <div className="text-center p-4">
              <h3>Ready for import?</h3>
              <p>
                Do you really want to run this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeConfirmationModal()}
                >
                  Cancel
                </button>
                {this.state.is_date_invalid == false ? (
                  <button
                    type="button"
                    className="m-0 btn btn btn-danger btn-block"
                    disabled={this.state.isSubmit ? true : false}
                    onClick={this.confirmImport}
                  >
                    {this.state.isSubmit ? global.loader : "Yes"}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="m-0 btn btn btn-danger btn-block"
                    disabled={true}
                  >
                    {this.state.isSubmit ? global.loader : "Yes"}
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

      </>
    );
  }
}
export default Rents;

import React from "react";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import KeycafeService from "../../../services/KeycafeService";
import { FileUploader } from "react-drag-drop-files";
import SimpleReactValidator from "simple-react-validator";
import plusnewicon from "./../../../assets/images/plus-w.svg";
import modalswapicon from "./../../../assets/images/fob.png";
import ErrorLogo from "./../../../assets/images/errorImage.svg";
import SuccessLogo from "./../../../assets/images/successImage.svg";
import NoData from "../../common/NoData";
import { read, utils } from "xlsx";
import { Link } from "react-router-dom";
import Alerts from "../../common/Alerts";

const Fob = (props) => {
  const [units, setUnits] = useState([]);
  const [messagemodalIsOpen, setmessageModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [filename, setFileName] = useState("");
  const [errors, setErrors] = useState({});
  const [fileurl, setFileUrl] = useState("");
  const [isSubmit, setSubmit] = useState(false);
  const [isSwapSubmit, setSwapSubmit] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState("");
  const [alertModalType, setAlertModalType] = useState("");
  const [alertModalTitle, setAlertModalTitle] = useState("");
  const [swapmodalIsOpen, setswapModalIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(-1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [state, setState] = useState({
    extraData: window.location.pathname.split("/")[4],
    total_record: 0,
    records: [],
    extraButtons: [],
    tableQueryString: "",
    customQueryString: "",
    filter_value: "",
    isSearch: global.isSearch,
    is_search: 0,
    unit_number: "",
    input: {},
    property_slug: props.property_slug,
    activeClass: "alert-activity",
    columns: [
      {
        key: "fob_id",
        text: "Fob#",
        className: "available_from hand-cursor",
        sortable: false,
        cell: (record) => {
          return record.fob_id ? record.fob_id : "";
        },
      },
      {
        key: "unit_name",
        text: "Unit Name",
        className: "unit_number hand-cursor text-left",
        sortable: false,
        cell: (record) => {
          return record.unit_name ? record.unit_name : "";
        },
      },
    ],
    config: {
      sort: {
        column: "",
        order: "",
      },
      key_column: "fob_id",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: global.show_pagination,
      pagination: "advance",
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found.",
      },
    },
  });
  function changeHandler(event, index) {
    let value = event.target.value;
    let mapData = [...units];
    mapData[index].fob_id = value;
    setUnits(mapData);
  }

  useEffect(() => {
    if (selectedValue !== -1) {
      setswapModalIsOpen(true);
    } else {
      setswapModalIsOpen(false);
    }
  }, [selectedValue]);

  function swapKey(event, index) {
    setSelectedValue(index);
    setswapModalIsOpen(true);
  }
  function resetKey() {
    setSelectedValue(-1);
  }
  function closePop() {
    setmessageModalIsOpen(false);
  }
  const confirmSwap = async (event, index) => {
    event.preventDefault();
    let fobId = units[index].fob_id;
    let keyId = units[index].key_id;
    let unitId = units[index].unit_id;
    setSwapSubmit(true);
    let res = [];
    let inputData = {
      property_slug: props.propertySlug,
      unit_id: unitId,
      key_id: keyId,
      fob_id: fobId,
    };
    res = await KeycafeService.addFob(inputData);
    setShowAlertModal(true);
    if (global.successStatus.includes(res.status)) {
      setmessageModalIsOpen(true);
      setErrorMessage("Success");
      // setAlertModalTitle("Success");
      setAlertModalMessage(res.message ? res.message : "Success");
      let mapData = [...units];
      setUnits(mapData);
    } else {
      setmessageModalIsOpen(true);
      setErrorMessage("error");
      setAlertModalTitle(res.data.errorCode ? res.data.errorCode : "Error!");
      setAlertModalMessage(
        res.data.errorMessage ? res.data.errorMessage : res.data.message
      );
    }
    resetKey();
    setSwapSubmit(false);
    getData();
    setTimeout(() => setShowAlertModal(false), global.alert_close_time);
  };

  const handleChange = async (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, { raw: true });
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 0,
            defval: "",
          });
          if (rows.length > 0) {
            let columns = Object.keys(rows[0]);
            let available_columns = columns.map((col) => {
              let val = col
                .replace("-", "_")
                .replace(/[^\w ]/g, "")
                .replace(/^\s+|\s+$/gm, "")
                .split(" ")
                .join("_")
                .replace("-", "_")
                .toLowerCase();
              return {
                label: col,
                value: val,
              };
            });
            setState({
              ...state,
              file_rows: rows,
              file_columns: columns,
              available_file_columns: available_columns,
            });
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
    setFileName(file.name);
    setFileUrl(file);
  };
  const filterData = async (event) => {
    event.preventDefault();
    setSubmit(true);

    let fields = simpleValidator.current.fields;
    let isError = 0;
    let isSubmit = true;
    let res = [];

    {
    Object.keys(fields).map((key) => {
  if (!fields[key]) {
    isError = 1;
  }
});
    }
    if (isError === 1) {
      isSubmit = false;
      setSubmit(isSubmit);
      setErrors(simpleValidator.current.errorMessages);
    } else {
      if (setFileUrl) {
        isSubmit = true;
        setSubmit(isSubmit);
        const fileData = new FormData();
        fileData.append("import_file", fileurl);
        fileData.append("file_name", filename);
        fileData.append("property_slug", props.propertySlug);
        res = await KeycafeService.getCsvimport(fileData);

        if (global.successStatus.includes(res.status)) {
          setSubmit(false);
          setFileName("");
          setFileUrl("");
          setErrors("");
          setModalIsOpen(false);
          setmessageModalIsOpen(true);
          setErrorMessage("Success");
          setAlertModalMessage(res.message ? res.message : "Success");
         
        }else {
          setSubmit(false);
          setFileName("");
          setFileUrl("");
          setErrors("");
          setModalIsOpen(false);
          setmessageModalIsOpen(true);
          setErrorMessage("error");
          setAlertModalTitle(res.data.errorCode ? res.data.errorCode : "Error!");
          setAlertModalMessage(
            res.data.errorMessage ? res.data.errorMessage : res.data.message
          );
        }
        setTimeout(() => setShowAlertModal(false), global.alert_close_time);
        getData();
      }
    }
  };

  
  function changeHandlerfob(event, index) {
    event.preventDefault();
    let value = event.target.value;
    let mapData = [...filename];
    mapData[index].file_name = value;
    setErrors(simpleValidator.current.errorMessages);
  }

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const confirmImportModal = () => {
    setModalIsOpen(true);
    setErrors("");
    setAlertModalMessage("");
    setAlertModalType("");
    setAlertModalTitle("");
  };

  const getData = async (queryString = "") => {
    var list = [];
    queryString =
      queryString !== ""
        ? "property_slug=" +
          props.propertySlug +
          (queryString ? "&" + queryString : "")
        : "property_slug=" +
          props.propertySlug +
          (queryString ? "&" + queryString : "");
    let res = await KeycafeService.getKeycafeFob(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res ? res.data : {};
    }
    setUnits(list);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns fob_tbl_align">
      <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns ">
        <button
          className="btn-success-outline-small"
          type="button"
          onClick={confirmImportModal}
        >
          Import
        </button>
      </div>
      <div className="row align-items-center border-bottom-0 mapp_head_desk m-0">
        <div className="col-md-11 pd-xs-l-0 pd-xs-r-0">
          <div className="row w-100">
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-15 pd-lg-r-30 pd-xs-r-0 ">
              <label className="form-label text-left mg-b-0">Unit</label>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-12 pd-lg-r-30 pd-xs-r-0 ">
              <label className="form-label text-left mg-b-0">Fob#</label>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-10 pd-lg-r-30 pd-xs-r-0 ">
              <label className="form-label text-left mg-b-0">Key ID</label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 pd-lg-r-15 pd-lg-l-15 pd-xs-l-0 pd-xs-r-0 mg-t-15">
        <Alerts
          show={showAlertModal}
          type={alertModalType}
          title={alertModalTitle}
          message={alertModalMessage}
        />
      </div>
      <div className="mg-t-18 d-block">
        <div className="pd-lg-l-0 pd-xs-l-0">
          {units.length > 0 ? (
            units.map((item, index) => {
              return (
                <div key={index} className="row w-100 m-0">
                  <div className="col-md-11 pd-xs-l-0 pd-xs-r-0">
                    <div className="row w-100">
                      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                        <label className="form-label text-left mg-b-0 mapp_head_mob">
                          Unit
                        </label>
                        <Form.Control
                          name="unit_number"
                          value={item.unit_name ? item.unit_name : ""}
                          disabled={true}
                          className="form-control max_width_100"
                          type="text"
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                        <label className="form-label text-left mg-b-0 mapp_head_mob">
                          Fob#
                        </label>
                        <Form.Control
                          onChange={(e) => {
                            changeHandler(e, index);
                            setSelectedRow(index);
                          }}
                          name="fob_id"
                          value={item.fob_id ? item.fob_id : ""}
                          className="form-control max_width_100"
                          type="text"
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 pd-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                        <label className="form-label text-left mg-b-0 mapp_head_mob">
                          Key
                        </label>
                        <Form.Control
                          name="key_id"
                          value={item.key_id ? item.key_id : ""}
                          disabled={true}
                          className="form-control max_width_100"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 pd-lg-l-0 pd-xs-l-15 pd-lg-b-0 pd-xs-b-15">
                    <div className=" text_btn_both">
                      {selectedRow === index && item.fob_id !== ""&&(
                        <button
                          type="button"
                          onClick={(e) => swapKey(e, index)}
                          className="btn btn-sync"
                        >
                        <img src={plusnewicon} alt="modal-delete-icon" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <NoData msg="Fobs" />
          )}
        </div>
      </div>

      {/* import data modal */}
      <Modal show={modalIsOpen} className="modal-xs importModalbox" centered>
        <Modal.Body className="width_402 p-4 border_radius_14">
          <div className="row col-12 m-0">
            <div className="col-lg-12 col-md-12 col-sm-12 p-0">
              <div className="d-flex justify-content-center mt-0 row">
                <form name="myFile">
                  <FileUploader
                    onChange={changeHandlerfob}
                    handleChange={handleChange}
                    name="file_name"
                    types={["csv", "xlsx", "xls", "ods"]}
                    label="Drop File Here Max Size 30 MB"
                  />
                  {simpleValidator.current.message(
                    "file_name",
                    filename,
                    "required"
                  )}
                  {errors.file_name !== "" ? (
                    <div className="text-danger">{errors.file_name} </div>
                  ) : (
                    ""
                  )}

                  <div className="d-flex justify-content-center mt-3 ">
                    <button
                      type="button"
                      className="m-0 btn btn-outline-light btn-block"
                      onClick={setModalIsOpenToFalse}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="m-0 btn btn btn-success btn-block"
                      onClick={filterData}
                      disabled={isSubmit ? true : false}
                    >
                      {isSubmit ? global.loader : "Import"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row col-12 m-0 download-sample">
            <Link
              to="/import_sample/Fob.xlsx"
              target="_blank"
              download="Fob.xlsx"
            >
              Download sample template for Import
            </Link>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={swapmodalIsOpen} centered>
        <Modal.Body className="width_402 p-4 border_radius_14">
          <div className="text-center">
          <img src={modalswapicon} alt="modal-delete-icon" />
            {/* <img src={modalswapicon} alt="modal-delete-icon" /> */}
            <h3>
              {selectedValue !== -1
                ? "Would you like to add a fob to this key for unit " +
                  " " +
                  units[selectedValue].unit_name +
                  " " +
                  "?"
                : ""}
            </h3>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => resetKey()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-danger btn-block"
                onClick={(e) => confirmSwap(e, selectedValue)}
                disabled={isSubmit ? true : false}
              >
                {isSwapSubmit ? global.loader : "Confirm"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={messagemodalIsOpen} centered>
        <Modal.Body className="width_402 p-4 border_radius_14">
          <div className="text-center">
            {errorMessage !== "Success" ? (
              <div className="msg col-12 text-center mt-4">
                <img src={ErrorLogo} alt="filterIcon" className="mb-2" />{" "}
                <p className="mb-0 text-danger">{alertModalMessage}</p>{" "}
              </div>
            ) : (
              <div className="msg col-12 text-center mt-4">
                <img src={SuccessLogo} alt="filterIcon" className="mb-2" />
                <p className="mb-0 text-success">{alertModalMessage}</p>{" "}
              </div>
            )}
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => closePop()}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Fob;

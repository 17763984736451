//     							                          //
//  Program: SettingsSidebar.jsx                          //
//  Application: Settings                                 //
//  Option: common component Settings Sidebarr TAbs       //
//  Developer: NP 		                                  //
//  Date: 2022-04-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";
class SettingsSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
      lockType: localStorage.getItem("propertyLockType")
        ? localStorage.getItem("propertyLockType")
        : 0,
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Integrations
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <span>Integrations</span>
                  <ul>
                    {/* {global.userPermissions.checkPermission("locks-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("locks")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/locks"
                          }
                        >
                          Locks
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("chatbot-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("chatbot")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/chatbot"
                          }
                        >
                          Chatbot
                        </Link>
                      </li>
                    )} */}
                    {global.userPermissions.checkPermission(
                      "noiseaware-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("noiseaware-template")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/noiseaware-template"
                          }
                        >
                          NoiseAware
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "turnoverbnb-mapping-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("tbnb-mapping")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/tbnb-mapping"
                          }
                        >
                          TurnoverBnB
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "key-cafe-mapping-view"
                    ) &&
                      localStorage.getItem("propertyLockType") == 1 && (
                        <li>
                          <Link
                            className={
                              this.isPathActive("keycafe-mapping")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={
                              "/properties/view/" +
                              (this.props.property_slug
                                ? this.props.property_slug
                                : this.state.property_slug) +
                              "/keycafe-mapping"
                            }
                          >
                            Keycafe
                          </Link>
                        </li>
                      )}
                    {global.userPermissions.checkPermission(
                      "latch-mapping-view"
                    ) &&
                      localStorage.getItem("propertyLockType") == 2 && (
                        <li>
                          <Link
                            className={
                              this.isPathActive("latch-mapping")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={
                              "/properties/view/" +
                              (this.props.property_slug
                                ? this.props.property_slug
                                : this.state.property_slug) +
                              "/latch-mapping"
                            }
                          >
                            Latch
                          </Link>
                        </li>
                      )}
                    {/* {global.userPermissions.checkPermission("airbnb-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("airbnb")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/airbnb"
                          }
                        >
                          Airbnb
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("google-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("google")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/google"
                          }
                        >
                          Google
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "ils-export-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ils-export")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/ils-export"
                          }
                        >
                          ILS Export
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "ota-export-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ota-export")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/ota-export"
                          }
                        >
                          OTA Export
                        </Link>
                      </li>
                    )} */}
                    {/* {!global.userPermissions.checkPermission(
                      "floor-plans-mapping"
                      ) && (
                        <li>
                          <Link
                            className={
                              this.isPathActive("floor-plans-mapping")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={
                              "/properties/view/" +
                              (this.props.property_slug
                                ? this.props.property_slug
                                : this.state.property_slug) +
                              "/floor-plans-mapping"
                            }
                          >
                            Property Floor Plan Mapping
                          </Link>
                        </li>
                      )} */}
                    {global.userPermissions.checkPermission(
                      "datahub-mapping-view"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("automation-data-hub-settings") ||
                            this.isPathActive(
                              "automation-processing-log-import"
                            )
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/automation-data-hub-settings"
                          }
                        >
                          Data Import
                        </Link>
                      </li>
                    )}

                    {global.userPermissions.checkPermission(
                      "datahub-export-mapping-view"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("export-automation") ||
                            this.isPathActive(
                              "automation-processing-log-export"
                            )
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/export-automation"
                          }
                        >
                          Data Export
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Integrations
              </span>
              <ul>
                <li>
                  <ul>
                    <li>
                      <span>Integrations</span>
                      <ul>
                        {/* {global.userPermissions.checkPermission("locks-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("locks")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/locks"
                          }
                        >
                          Locks
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("chatbot-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("chatbot")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/chatbot"
                          }
                        >
                          Chatbot
                        </Link>
                      </li>
                    )} */}
                        {global.userPermissions.checkPermission(
                          "noiseaware-list"
                        ) && (
                          <li>
                            <Link
                              className={
                                this.isPathActive("noiseaware-template")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                (this.props.property_slug
                                  ? this.props.property_slug
                                  : this.state.property_slug) +
                                "/noiseaware-template"
                              }
                            >
                              NoiseAware
                            </Link>
                          </li>
                        )}
                        {global.userPermissions.checkPermission(
                          "key-cafe-mapping-view"
                        ) && (
                          <li>
                            <Link
                              className={
                                this.isPathActive("keycafe-mapping")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                (this.props.property_slug
                                  ? this.props.property_slug
                                  : this.state.property_slug) +
                                "/keycafe-mapping"
                              }
                            >
                              Keycafe
                            </Link>
                          </li>
                        )}
                        {global.userPermissions.checkPermission(
                          "turnoverbnb-mapping-list"
                        ) && (
                          <li>
                            <Link
                              className={
                                this.isPathActive("tbnb-mapping")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                (this.props.property_slug
                                  ? this.props.property_slug
                                  : this.state.property_slug) +
                                "/tbnb-mapping"
                              }
                            >
                              TurnoverBnB
                            </Link>
                          </li>
                        )}
                        {/* {global.userPermissions.checkPermission("airbnb-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("airbnb")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/airbnb"
                          }
                        >
                          Airbnb
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("google-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("google")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/google"
                          }
                        >
                          Google
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "ils-export-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ils-export")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/ils-export"
                          }
                        >
                          ILS Export
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "ota-export-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ota-export")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/ota-export"
                          }
                        >
                          OTA Export
                        </Link>
                      </li>
                    )} */}
                        {/* {!global.userPermissions.checkPermission(
                      "floor-plans-mapping"
                      ) && (
                        <li>
                          <Link
                            className={
                              this.isPathActive("floor-plans-mapping")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={
                              "/properties/view/" +
                              (this.props.property_slug
                                ? this.props.property_slug
                                : this.state.property_slug) +
                              "/floor-plans-mapping"
                            }
                          >
                            Property Floor Plan Mapping
                          </Link>
                        </li>
                      )} */}
                        {global.userPermissions.checkPermission(
                          "datahub-mapping-view"
                        ) && (
                          <li>
                            <Link
                              className={
                                this.isPathActive(
                                  "automation-data-hub-settings"
                                ) ||
                                this.isPathActive(
                                  "automation-processing-log-import"
                                )
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                (this.props.property_slug
                                  ? this.props.property_slug
                                  : this.state.property_slug) +
                                "/automation-data-hub-settings"
                              }
                            >
                              Data Import
                            </Link>
                          </li>
                        )}

                        {global.userPermissions.checkPermission(
                          "datahub-export-mapping-view"
                        ) && (
                          <li>
                            <Link
                              className={
                                this.isPathActive("export-automation") ||
                                this.isPathActive(
                                  "automation-processing-log-export"
                                )
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                (this.props.property_slug
                                  ? this.props.property_slug
                                  : this.state.property_slug) +
                                "/export-automation"
                              }
                            >
                              Data Export
                            </Link>
                          </li>
                        )}
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default SettingsSidebar;

///////////////////////////////////////////////////////////
//  Program: Emails.jsx                                  //
//  Application: Emails                                  //
//  Option: add all Emails                               //
//  Developer:  NP                                       //
//  Date: 2022-12-05                                     //
///////////////////////////////////////////////////////////

import SettingSidebar from "../common/SettingSidebar";
import Alerts from "../common/Alerts";
import React, { Component } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import AllTemplateService from "../../services/AllTemplateService";
import SchedulerService from "../../services/SchedulerService";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import InputMask from "react-input-mask";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import PropertyService from "../../services/PropertyService";
import Moment from "moment-timezone";

const options = [];
obj = {};
for (var i = 0; i < 60; i += 5) {
  var obj = {};
  obj["value"] = i + 5;
  obj["label"] = i + 5 + " min";
  options.push(obj);
}

const hroption = [];
hrobj = {};
for (var h = 1; h <= 60; h++) {
  var hrobj = {};
  hrobj["value"] = h;
  hrobj["label"] = h + " hrs";
  hroption.push(hrobj);
}

const daysoption = [];
daysobj = {};
for (var d = 1; d <= 15; d++) {
  var daysobj = {};
  daysobj["value"] = d;
  daysobj["label"] = d + " day";
  daysoption.push(daysobj);
}
class SchedularForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      slug: props.match.params.slug,
      confirm_back: 0,
      input: {},
      errors: {},
      usertotype: "",
      isSubmit: false,
      showAlertModal: false,
      toError: null,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      selectedActionList: [],
      selectedsecondlist: [],
      selectedhoursList: [],
      staffList: [],
      newstaffList: [],
      selectedFireList: [],
      selectedStaffToList: [],
      selectedMintuesList: [],
      selectedDaysList: [],
      selectedDayscheckoutList: [],
      selectedHeader: [],
      selectedProperty: [],
      deleteTowerModal: false,
      status_id: true,
      time: "00:00 AM",
      timezone_time: "00:00 AM",
      typeList: global.triggerPoint,
      payoutWhenToSend: global.payoutWhenToSend,
      reservationInquiry: global.reservationInquiry,
      firing: global.firing,
      guestform: global.guestform,
      firing12: global.firing12,
      firingexpringlease: global.firingexpringlease,
      firingleaseinquiry: global.firingleaseinquiry,
      onboarding: global.onboarding,
      resident: global.resident,
      cleaning: global.cleaning,
      cleaningnotstart: global.cleaningnotstart,
      cleaningstillnotstart: global.cleaningstillnotstart,
      reviewReminder: global.reviewReminder,
      headerlogo: global.headerlogo,
      guestformsubmitted: global.guestformsubmitted,
      homesharing: global.homesharing,
      supportformsubmitted: global.supportformsubmitted,
      residentstayconfirmation: global.residentstayconfirmation,
      bookingdatechanged: global.bookingdatechanged,
      bookingcancelled: global.bookingcancelled,
      residentopendate: global.residentopendate,
      bulidingonboarding: global.bulidingonboarding,
      bulidingonboardingpending: global.bulidingonboardingpending,
      newHrList: hroption,
      newMinList: options,
      moduleList: global.modulelist,
      dayslist: daysoption,
      moduleTypeList: global.moduleReferlist.filter((item) => {
        // return item.slug === props.match.params.slug
        return item.slug;
      }),
      subjectVariableList: global.modulesubjectVariable.filter((item) => {
        // return item.slug === props.match.params.slug
        return item.slug;
      }),
      template_desc_info: {
        guest:
          "Message ( Available Merge Fields : [Guest Name] [Confirmation Code] [Check-In] [Check-Out] [Addres] [Unit No] )",
        staff: "Message ( Available Merge Fields : [Staff Name] [Unit No] )",
        resident:
          "Message ( Available Merge Fields : [Resident Name] [Unit No] )",
      },
    };
    this.saveOrUpdateEmail = this.saveOrUpdateEmail.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.setContent = this.setContent.bind(this);
    this.openDeleteNearByAttractionModal =
      this.openDeleteNearByAttractionModal.bind(this);
    this.closeDeleteAttrcationModal =
      this.closeDeleteAttrcationModal.bind(this);
  }

  componentDidMount() {
    this.getStaffData();
    this.getPropertyList();
  }

  validate() {
    let toError = "";
    if (this.state.actiontotype !== 4) {
      if (this.state.slug !== undefined) {
        if (
          this.state.selectedFireList.length === 0 &&
          this.state.trigger_point !== 4
        ) {
          toError = "The trigger time field is required.";
        }
      } else {
        if (!this.state.selectedFireList.value) {
          toError = "The trigger time field is required.";
        }
      }
    }

    if (toError) {
      this.setState({ toError });
      return false;
    }
    return true;
  }
  async getPropertyList(queryString = "") {
    var propertyList = [];
    var propertyListTotal = 0;
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;
    }
    var newPropertyList =
      propertyListTotal > 0
        ? propertyList.map(({ slug, property_name }) => ({
            value: slug,
            label: property_name,
          }))
        : [];

    this.setState({
      propertyList: propertyList,
      propertyListTotal: propertyListTotal,
      newPropertyList: newPropertyList,
    });
    this.getDataFromSlug();
  }
  async getStaffData(queryString = "") {
    var staffList = [];
    var staffListTotal = 0;

    let res = await AllTemplateService.getTemplateList("is_dropdown=1");
    if (global.successStatus.includes(res.status)) {
      staffList = res.data ? res.data : [];
      staffListTotal = staffList ? staffList.length : 0;
    }
    var newStaffList =
      staffListTotal > 0
        ? staffList.map(({ template_id, template_name }) => ({
            value: template_id,
            label: template_name,
          }))
        : [];
    this.setState({
      staffListTotal: staffListTotal,
      newStaffList: newStaffList,
    });
  }

  saveOrUpdateEmail = async (e) => {
    e.preventDefault();
    var property = [];
    this.setState({
      alertModalMessage: "",
    });
    if (this.validate() && this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true });
      if (this.state.selectedProperty != null) {
        this.state.selectedProperty.forEach((item) => {
          property = [...property, item.value];
        });
      }
      let propertydata = property.join(",");
      let inputData = {
        trigger_point: this.state.actiontotype
          ? this.state.actiontotype
          : this.state.selectedActionList[0].value,
        template_id: this.state.template_id
          ? this.state.template_id
          : this.state.selectedStaffToList[0].value,
        days:
          this.state.getvalueoffires === global.whentosend1 ||
          this.state.trigger_time === global.whentosend1
            ? this.state.days
            : this.state.getvalueoffires === global.whentosend2 ||
              this.state.trigger_time === global.whentosend2
            ? this.state.days
            : "",
        minutes:
          this.state.getvalueoffires === global.whentosend1 ||
          this.state.trigger_time === global.whentosend1
            ? this.state.minutes
            : this.state.getvalueoffires === global.whentosend2 ||
              this.state.trigger_time === global.whentosend2
            ? this.state.minutes
            : "",
        hourly:
          this.state.getvalueoffires === global.whentosend1 ||
          this.state.trigger_time === global.whentosend1
            ? this.state.hourly
            : this.state.getvalueoffires === global.whentosend2 ||
              this.state.trigger_time === global.whentosend2
            ? this.state.hourly
            : "",
        time:
          this.state.actiontotype === global.whentosend3 ||
          this.state.trigger_point === global.whentosend3
            ? this.state.time
            : "",
        timzonetime:
          this.state.actiontotype === global.whentosend3 ||
          this.state.trigger_point === global.whentosend3
            ? Intl.DateTimeFormat().resolvedOptions().timeZone
            : "",
        trigger_time:
          this.state.actiontotype === global.whentosend3
            ? ""
            : this.state.getvalueoffires
            ? this.state.getvalueoffires
            : this.state.trigger_point !== global.whentosend3
            ? this.state.selectedFireList[0].value
            : "",
        property_slug: this.state.selectedProperty ? propertydata : "",
        status_id: this.state.status_id ? this.state.status_id : 2,
      };
      if (this.state.slug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await SchedulerService.updateScheduler(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/scheduler"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        this.setState({ loading: true, isSubmit: true });
        let res = await SchedulerService.createScheduler(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/scheduler"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isSubmit: false });
      this.validator.showMessages();
    }
  };

  async getDataFromSlug() {
    if (this.state.slug !== undefined) {
      var resData = {};
      var checkout_time = "";
      let res = await SchedulerService.getScheduler(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.time = resData.time ? resData.time : "";
        resData.timezone_time = resData.timezone_time
          ? Moment(Moment.utc(resData.timezone_time, "hh:mm A"))
              .local()
              .format("hh:mm A")
          : "";
        checkout_time = resData.time ? resData.time : "";
        resData.property_slug = resData.property_slug
          ? resData.property_slug
          : "";
        var propertydata = resData.property_slug
          ? resData.property_slug.split(",")
          : "";
        resData.status_id = res.data
          ? res.data.status_id === 1
            ? true
            : false
          : false;
        var selectedActionList = [];
        this.state.typeList.forEach((item, i) => {
          if (item.value == res.data.trigger_point) {
            selectedActionList = res.data
              ? [
                  {
                    value: item.value,
                    label: item.label,
                  },
                ]
              : [];
          }
        });
        var selectedFireList = [];
        selectedFireList =
          res.data.trigger_time === 1
            ? [{ key: 1, value: 1, label: "Immediately After" }]
            : res.data.trigger_time === 2
            ? [{ key: 2, value: 2, label: "After" }]
            : res.data.trigger_time === 3
            ? [{ key: 3, value: 3, label: "Before" }]
            : [];
        var selectedHeader = [];
        selectedHeader =
          res.data.header_logo === 1
            ? [{ key: 1, value: 1, label: "Property" }]
            : res.data.header_logo === 2
            ? [{ key: 2, value: 2, label: "Orion Haus" }]
            : [];
        var selectedStaffToList = [];
        selectedStaffToList = res.data.template
          ? [
              {
                value: res.data.template_id,
                label: res.data.template.template_name,
              },
            ]
          : [];

        var selectedProperty = [];
        this.state.newPropertyList.forEach((item1, i) => {
          propertydata.length > 0 &&
            propertydata.forEach((item, i) => {
              if (item === item1.value) {
                selectedProperty = [
                  ...selectedProperty,
                  {
                    value: item,
                    label: item1.label,
                  },
                ];
              }
            });
        });

        var selectedDaysList = [];
        if (res.data.trigger_point === 2) {
          this.state.dayslist.forEach((item, i) => {
            if (item.value === res.data.days) {
              selectedDaysList = res.data
                ? [
                    {
                      value: res.data.days,
                      label: item.label,
                    },
                  ]
                : [];
            }
          });
        }

        var selectedDayscheckoutList = [];
        if (res.data.trigger_point === 3) {
          this.state.dayslist.forEach((item, i) => {
            if (item.value === res.data.days) {
              selectedDayscheckoutList = res.data
                ? [
                    {
                      value: res.data.days,
                      label: item.label,
                    },
                  ]
                : [];
            }
          });
        }

        var selectedMintuesList = [];
        if (res.data.trigger_point === 1) {
          this.state.newMinList.forEach((item, i) => {
            if (item.value === res.data.minutes) {
              selectedMintuesList = res.data
                ? [
                    {
                      value: res.data.minutes,
                      label: item.label,
                    },
                  ]
                : [];
            }
          });
        }

        var selectedhoursList = [];
        if (res.data.trigger_point === 1) {
          this.state.newHrList.forEach((item, i) => {
            if (item.value === res.data.hourly) {
              selectedhoursList = res.data
                ? [
                    {
                      value: res.data.hourly,
                      label: item.label,
                    },
                  ]
                : [];
            }
          });
        }
      }
      this.setState({
        selectedDaysList: selectedDaysList,
        selectedhoursList: selectedhoursList,
        selectedMintuesList: selectedMintuesList,
        selectedActionList: selectedActionList,
        selectedStaffToList: selectedStaffToList,
        selectedFireList: selectedFireList,
        selectedDayscheckoutList: selectedDayscheckoutList,
        selectedHeader: selectedHeader,
        selectedProperty: selectedProperty,
      });
      this.setState(resData);
      this.setState({ checkout_time: checkout_time });
    }
  }

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    if (event.target.name === "time" || event.target.name === "checkout_time") {
      var datetime = value.split(":");
      var lengthdatetime = (datetime[0].match(/\d/g) || []).length;
      if (lengthdatetime === 1) {
        datetime[0] = datetime[0].replace("_", "0");
      }
      if (parseInt(datetime[0]) > 12) {
        datetime[0] = "12";
      }
      datetime[1] = datetime[1].replace("_", "0");
      var minute = datetime[1].split(" ");
      var lengthminute = (minute[0].match(/\d/g) || []).length;

      /* const minute = [
        '__',''

      ]; */
      if (parseInt(minute[0]) > 59 || lengthminute <= 0) {
        minute[0] = "00";
      }
      const meridian = ["AM", "PM", "am", "pm", "A_", "P_", "a_", "p_"];
      const result = meridian.includes(minute[1].toLowerCase());
      minute[1] = result ? minute[1] : "AM";

      value = datetime[0] + ":" + minute[0] + " " + minute[1].toUpperCase();
      this.setState({ timezone_time: value });
    }

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  handleChangeMintuesList(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }

    const type = value !== null ? value.value : [];
    this.setState({
      selectedMintuesList: value,
      mintues: type,
    });
  }

  handleChangecheckoutDaysList(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }

    const type = value !== null ? value.value : [];
    this.setState({
      selectedDayscheckoutList: value,
      checkoutdays: type,
    });
  }

  handleChangetemplate(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedStaffToList: value,
      template_id: type,
    });
  }

  handleChangeActionList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedActionList: value,
      actiontotype: type,
    });
    this.setState({
      getvalueoffires: "",
    });
    if (this.state.slug !== undefined) {
      this.setState({
        trigger_point: "",
      });
    }
    this.setState({ confirm_back: 1 });
  }

  handleChangeHoursList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    this.setState({
      selectedhoursList: value,
      getvalueofhours: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangefireList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    // const type = value !== null ? value.value : [];
    this.setState({
      selectedFireList: value,
      getvalueoffires: value.value,
    });
    this.setState({
      confirm_back: 1,
      toError: null,
    });
    if (this.state.slug !== undefined) {
      this.setState({
        trigger_time: "",
      });
    }
  }
  handleChangeProperty(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }
    this.setState({
      selectedProperty: value,
      user_id: user_id,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  openDeleteNearByAttractionModal(slug) {
    this.setState({ deleteNearByAttractionModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAttrcationModal() {
    this.setState({ deleteNearByAttractionModal: false });
    this.setState({ slug: "" });
  }

  async deleteCredential(slug) {
    this.setState({ showSpinner: true });
    let res = await SchedulerService.deleteScheduler(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAttrcationModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      setTimeout(
        () => this.props.history.push("/scheduler"),
        global.redirect_time
      );
    } else {
      this.closeDeleteAttrcationModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    // this.validator.hideMessages();
    // if (!this.state.status_id) {
    //   this.validator.showMessages();
    // } else {
    //   this.validator.showMessageFor("room_name");
    // }
  }

  setContent(value) {
    this.setState({
      body: value,
    });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container over-fl p-0">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <SettingSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel drop_user scdule_sec">
                  <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                    <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0 dashboardRightcard background-none">
                      <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Workflow</h3>

                          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                            {this.state.slug !== undefined
                              ? global.userPermissions.checkPermission(
                                  "workflow-delete"
                                ) && (
                                  <span
                                    onClick={() => {
                                      this.openDeleteNearByAttractionModal(
                                        this.state.slug
                                      );
                                    }}
                                    className="deleteicon mg-r-20"
                                  >
                                    <img src={deleteicon} />
                                  </span>
                                )
                              : ""}

                            <Link to={"/scheduler"}>
                              <button className="btn-success-outline mg-r-20">
                                Cancel
                              </button>
                            </Link>

                            {global.userPermissions.checkPermission(
                              "workflow-update"
                            ) && (
                              <button
                                type="button"
                                className="btn-success-outline-small ml-3 button-width"
                                onClick={this.saveOrUpdateEmail}
                                disabled={this.state.isSubmit ? true : false}
                              >
                                {this.state.isSubmit
                                  ? global.loader
                                  : this.state.slug
                                  ? "Update"
                                  : "Save"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pd-lg-l-15 pd-lg-r-15 pd-lg-t-10 ">
                      <div className="table-responsive roles   ">
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0 mt-3">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>

                        <div className="mg-t-18 brandList table_issue_roles">
                          <div className=" ">
                            <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                <div className="">
                                  <label className="form-label text-left pd-b-5">
                                    Action{" "}
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    menuPlacement="auto"
                                    value={this.state.selectedActionList}
                                    options={this.state.typeList}
                                    onChange={(value) =>
                                      this.handleChangeActionList(value)
                                    }
                                    defaultValue={this.state.selectedActionList}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                  {this.state.errors.trigger_point ? (
                                    <div className="text-danger">
                                      {this.state.errors.trigger_point}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "action",
                                      this.state.selectedActionList,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                              </div>
                              {this.state.actiontotype === 4 ||
                              this.state.trigger_point === 4 ? (
                                ""
                              ) : (
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    When to send{" "}
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    menuPlacement="auto"
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    value={this.state.selectedFireList}
                                    options={
                                      this.state.actiontotype === 1 ||
                                      this.state.actiontotype === 5 ||
                                      this.state.trigger_point === 1 ||
                                      this.state.trigger_point === 5
                                        ? this.state.firing12
                                        : this.state.actiontotype === 6 ||
                                          this.state.trigger_point === 6
                                        ? this.state.firingexpringlease
                                        : this.state.actiontotype === 7 ||
                                          this.state.trigger_point === 7
                                        ? this.state.firingleaseinquiry
                                        : this.state.actiontotype === 11 ||
                                          this.state.trigger_point === 11
                                        ? this.state.onboarding
                                        : this.state.actiontotype === 12 ||
                                          this.state.trigger_point === 12
                                        ? this.state.onboarding
                                        : this.state.actiontotype === 13 ||
                                          this.state.trigger_point === 13
                                        ? this.state.onboarding
                                        : this.state.actiontotype === 14 ||
                                          this.state.trigger_point === 14
                                        ? this.state.onboarding
                                        : this.state.actiontotype === 15 ||
                                          this.state.trigger_point === 15
                                        ? this.state.payoutWhenToSend
                                        : this.state.actiontotype === 16 ||
                                          this.state.trigger_point === 16
                                        ? this.state.guestform
                                        : this.state.actiontotype === 17 ||
                                          this.state.trigger_point === 17
                                        ? this.state.resident
                                        : this.state.actiontotype === 18 ||
                                          this.state.trigger_point === 18
                                        ? this.state.resident
                                        : this.state.actiontotype === 26 ||
                                          this.state.trigger_point === 26
                                        ? this.state.cleaning
                                        : this.state.actiontotype === 20 ||
                                          this.state.trigger_point === 20 ||
                                          this.state.actiontotype === 27 ||
                                          this.state.trigger_point === 27
                                        ? this.state.cleaning
                                        : this.state.actiontotype === 21 ||
                                          this.state.trigger_point === 21 ||
                                          this.state.actiontotype === 28 ||
                                          this.state.trigger_point === 28
                                        ? this.state.cleaning
                                        : this.state.actiontotype === 22 ||
                                          this.state.trigger_point === 22 ||
                                          this.state.actiontotype === 29 ||
                                          this.state.trigger_point === 29
                                        ? this.state.cleaning
                                        : this.state.actiontotype === 23 ||
                                          this.state.trigger_point === 23 ||
                                          this.state.actiontotype === 30 ||
                                          this.state.trigger_point === 30
                                        ? this.state.cleaning
                                        : this.state.actiontotype === 25 ||
                                          this.state.trigger_point === 25
                                        ? this.state.cleaning
                                        : this.state.actiontotype === 31 ||
                                          this.state.trigger_point === 31
                                        ? this.state.reviewReminder
                                        : this.state.actiontotype === 32 ||
                                          this.state.trigger_point === 32
                                        ? this.state.reservationInquiry
                                        : this.state.actiontotype === 24 ||
                                          this.state.trigger_point === 24
                                        ? this.state.cleaningnotstart
                                        : this.state.actiontotype === 33 ||
                                          this.state.trigger_point === 33
                                        ? this.state.cleaningstillnotstart
                                        : this.state.actiontotype === 34 ||
                                          this.state.trigger_point === 34
                                        ? this.state.guestformsubmitted
                                        : this.state.actiontotype === 36 ||
                                          this.state.trigger_point === 36
                                        ? this.state.homesharing
                                        : this.state.actiontotype === 35 ||
                                          this.state.trigger_point === 35
                                        ? this.state.supportformsubmitted
                                        : this.state.actiontotype === 37 ||
                                          this.state.trigger_point === 37
                                        ? this.state.residentstayconfirmation
                                        : this.state.actiontotype === 39 ||
                                          this.state.trigger_point === 39
                                        ? this.state.bookingcancelled
                                        : this.state.actiontotype === 38 ||
                                          this.state.trigger_point === 38
                                        ? this.state.bookingdatechanged
                                        : this.state.actiontotype === 40 ||
                                          this.state.trigger_point === 40
                                        ? this.state.residentopendate
                                        : this.state.actiontotype === 41 ||
                                          this.state.trigger_point === 41
                                        ? this.state.residentstayconfirmation
                                        : this.state.actiontotype === 42 ||
                                          this.state.trigger_point === 42
                                        ? this.state.homesharing
                                        : this.state.actiontotype === 43 ||
                                          this.state.trigger_point === 43
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 44 ||
                                          this.state.trigger_point === 44
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 45 ||
                                          this.state.trigger_point === 45
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 46 ||
                                          this.state.trigger_point === 46
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 47 ||
                                          this.state.trigger_point === 47
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 48 ||
                                          this.state.trigger_point === 48
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 49 ||
                                          this.state.trigger_point === 49
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 50 ||
                                          this.state.trigger_point === 50
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 51 ||
                                          this.state.trigger_point === 51
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 52 ||
                                          this.state.trigger_point === 52
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 53 ||
                                          this.state.trigger_point === 53
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 54 ||
                                          this.state.trigger_point === 54
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 55 ||
                                          this.state.trigger_point === 55
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 56 ||
                                          this.state.trigger_point === 56
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 57 ||
                                          this.state.trigger_point === 57
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 58 ||
                                          this.state.trigger_point === 58
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 59 ||
                                          this.state.trigger_point === 59
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 60 ||
                                          this.state.trigger_point === 60
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 61 ||
                                          this.state.trigger_point === 61
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 62 ||
                                          this.state.trigger_point === 62
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 63 ||
                                          this.state.trigger_point === 63
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 64 ||
                                          this.state.trigger_point === 64
                                        ? this.state.bulidingonboardingpending
                                        : this.state.actiontotype === 65 ||
                                          this.state.trigger_point === 65
                                        ? this.state.bulidingonboarding
                                        : this.state.actiontotype === 66 ||
                                        this.state.trigger_point === 66
                                        ? this.state.payoutWhenToSend
                                        : this.state.firing
                                    }
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangefireList(value)
                                    }
                                    defaultValue={this.state.selectedFireList}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                  <div className="text-danger">
                                    {this.state.toError}
                                  </div>
                                </div>
                              )}

                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                <label className="form-label text-left pd-b-5">
                                  Template{" "}
                                </label>
                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  menuPlacement="auto"
                                  className="multiselect"
                                  classNamePrefix={"my-custom-react-select"}
                                  value={this.state.selectedStaffToList}
                                  options={this.state.newStaffList}
                                  getOptionValue={(option) => `${option.label}`}
                                  onChange={(value) =>
                                    this.handleChangetemplate(value)
                                  }
                                  defaultValue={this.state.selectedStaffToList}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                                {this.state.errors.template_id ? (
                                  <div className="text-danger">
                                    {this.state.errors.template_id}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "template ",
                                    this.state.selectedStaffToList,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}
                              </div>

                              {this.state.actiontotype === 4 ||
                              this.state.trigger_point === 4 ? (
                                <>
                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                    <div className="">
                                      <label className="form-label text-left pd-b-5">
                                        Time{" "}
                                      </label>
                                      <InputMask
                                        name="time"
                                        onChange={this.changeHandler}
                                        value={this.state.timezone_time}
                                        mask="99:99 aa"
                                      />
                                      {this.state.errors.time ? (
                                        <div className="text-danger">
                                          {this.state.errors.time}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30 schdl_ppt_drop">
                                    <div className="">
                                      <label className="form-label text-left pd-b-5">
                                        Properties{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        isMulti
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        classNamePrefix={
                                          "my-custom-react-select"
                                        }
                                        menuPlacement="auto"
                                        value={this.state.selectedProperty}
                                        options={this.state.newPropertyList}
                                        onChange={(value) =>
                                          this.handleChangeProperty(value)
                                        }
                                        defaultValue={
                                          this.state.selectedProperty
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                    <div className="col-md-1 pl-0">
                                      <label className="form-label text-left pd-b-5">
                                        Status
                                      </label>
                                    </div>

                                    <div className="col-8 col-md-7 col-lg-7 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                      <b
                                        className={
                                          this.state.status_id == 1
                                            ? "active"
                                            : "inactive"
                                        }
                                      ></b>
                                      <div
                                        className={
                                          this.state.status_id == 1
                                            ? "az-toggle on"
                                            : "az-toggle"
                                        }
                                        onClick={() => this.changeStatus()}
                                      >
                                        <span></span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>

                            {this.state.getvalueoffires === 2 ||
                            this.state.trigger_time === 2 ||
                            this.state.getvalueoffires === 3 ||
                            this.state.trigger_time === 3 ? (
                              <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Days{" "}
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="days"
                                    value={this.state.days}
                                    maxLength="2"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                  />
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Hours{" "}
                                  </label>
                                  <Form.Control
                                    disabled={
                                      this.state.actiontotype === 6 ||
                                      this.state.trigger_point === 6
                                        ? true
                                        : false
                                    }
                                    onChange={this.changeHandler}
                                    type="text"
                                    name="hourly"
                                    value={this.state.hourly}
                                    maxLength="2"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                  />
                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                  <div className="">
                                    <label className="form-label text-left pd-b-5">
                                      Minutes{" "}
                                    </label>
                                    <Form.Control
                                      disabled={
                                        this.state.actiontotype === 6 ||
                                        this.state.trigger_point === 6
                                          ? true
                                          : false
                                      }
                                      onChange={this.changeHandler}
                                      type="text"
                                      name="minutes"
                                      value={this.state.minutes}
                                      maxLength="2"
                                      onKeyPress={
                                        global.onKeyPressEvent.numberValidation
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.actiontotype === 4 ||
                            this.state.trigger_point === 4 ? (
                              ""
                            ) : (
                              <>
                                <div className="row pd-lg-l-0 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                    <div className="">
                                      <label className="form-label text-left pd-b-5">
                                        Properties{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Select
                                        isMulti
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        classNamePrefix={
                                          "my-custom-react-select"
                                        }
                                        menuPlacement="auto"
                                        value={this.state.selectedProperty}
                                        options={this.state.newPropertyList}
                                        onChange={(value) =>
                                          this.handleChangeProperty(value)
                                        }
                                        defaultValue={
                                          this.state.selectedProperty
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                                    <div className="col-md-1 pl-0">
                                      <label className="form-label text-left pd-b-5">
                                        Status
                                      </label>
                                    </div>

                                    <div className="col-8 col-md-7 col-lg-7 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                      <b
                                        className={
                                          this.state.status_id == 1
                                            ? "active"
                                            : "inactive"
                                        }
                                      ></b>
                                      <div
                                        className={
                                          this.state.status_id
                                            ? "az-toggle on"
                                            : "az-toggle"
                                        }
                                        onClick={() => this.changeStatus()}
                                      >
                                        <span></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deleteNearByAttractionModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAttrcationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCredential(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default SchedularForm;

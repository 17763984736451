///////////////////////////////////////////////////////////
//  Program: lista.jsx                                  //
//  Application: lista                                  //
//  Option: add all lista                               //
//  Developer:  raj kumar                                       //
//  Date: 2022-12-05                                     //
///////////////////////////////////////////////////////////

import CrmSettingSidebar from "../common/CrmSettingSidebar";
import ContactSidebar from "../common/ContactSidebar";
import Alerts from "../common/Alerts";
import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import CrmListService from "../../services/CrmListService";
import SimpleReactValidator from "simple-react-validator";
import BrandSidebar from "../common/BrandSidebar";
import ClientSidebar from "../common/ClientSidebar";
class CrmManualListForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const pathStr = props.match.path;
    const pathArray = pathStr.split(":");
    this.state = {
      tags: [],
      slug: props.match.params.crmSlug,
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : "",
      brand_slug:
        pathArray[0] === "/brand/view/" ? this.props.match.params.slug : "",
      client_slug:
        pathArray[0] === "/client/view/" ? this.props.match.params.slug : "",
      confirm_back: 0,
      input: {},
      errors: {},
      usertotype: "",
      list_name: "",
      listNameError: null,
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      deleteTowerModal: false,
      status_id: true,

      moduleList: global.modulelist,
      moduleTypeList: global.moduleReferlist.filter((item) => {
        return item.slug;
      }),
    };
    this.saveOrUpdateList = this.saveOrUpdateList.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    this.getDataFromSlug();
  }

  removeTag = (i) => {
    const newTags = [...this.state.tags];
    newTags.splice(i, 1);
    this.setState({ tags: newTags });
  };

  inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (
        this.state.tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({ tags: [...this.state.tags, val] });
      this.tagInput.value = null;
    } else if (e.key === "Backspace" && !val) {
      this.removeTag(this.state.tags.length - 1);
    }
  };
  validate() {
    let listNameError = "";

    if (!this.state.list_name) {
      listNameError = "This field is required.";
    }
    if (listNameError) {
      this.setState({ listNameError });
      return false;
    }
    return true;
  }
  saveOrUpdateList = async (e) => {
    e.preventDefault();
    this.setState({
      alertModalMessage: "",
    });
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true });
      let inputData = {
        list_name: this.state.list_name ? this.state.list_name : "",
        description: this.state.description ? this.state.description : "",
        manualUsers: this.state.tags.length > 0 ? this.state.tags : [],
        status_id: this.state.status_id ? this.state.status_id : 2,
        propertySlug: this.state.property_slug ? this.state.property_slug : "",
        brandSlug: this.state.brand_slug ? this.state.brand_slug : "",
        clientSlug: this.state.client_slug ? this.state.client_slug : "",
        crmType: this.state.property_slug
          ? 2
          : this.state.brand_slug
          ? 3
          : this.state.client_slug
          ? 4
          : 1,
        list_type: 2,
      };

      if (this.state.slug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmListService.updateCrmList(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" + this.state.property_slug + "/crm-list"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-list"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-list"
                  : "/crm-list"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        this.setState({ loading: true, isSubmit: true });
        let res = await CrmListService.createCrmlist(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.state.property_slug
                  ? "/properties/view/" + this.state.property_slug + "/crm-list"
                  : this.state.brand_slug
                  ? "/brand/view/" + this.state.brand_slug + "/crm-list"
                  : this.state.client_slug
                  ? "/client/view/" + this.state.client_slug + "/crm-list"
                  : "/crm-list"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isSubmit: false });
      this.validator.showMessages();
    }
  };

  async getDataFromSlug() {
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await CrmListService.getCrmList(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.list_name = resData.list_name ? resData.list_name : "";
        resData.description = resData.description ? resData.description : "";
        resData.crmType = resData.crm_type
          ? resData.crm_type
          : resData.crm_type;
        resData.propertySlugs = resData.property_slug
          ? resData.property_slug
          : "";
        resData.brandSlugs = resData.brand_slug ? resData.brand_slug : "";
        resData.clientSlugs = resData.client_slug ? resData.client_slug : "";
        var manualLists = resData.manualLists ? resData.manualLists : [];

        var manualListData = [];
        if (manualLists !== "null") {
          manualLists.forEach((item, i) => {
            manualListData.push(item.email);
          });
        }
      }
      this.setState({
        tags: manualListData,
      });
      this.setState(resData);
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
  }

  render() {
    const { tags } = this.state;
    return (
      <main>
        <Container className="innter-container over-fl  container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    {this.state.property_slug ? (
                      <ContactSidebar
                        property_slug={this.state.property_slug}
                      />
                    ) : this.state.brand_slug ? (
                      <BrandSidebar brand_slug={this.state.brand_slug} />
                    ) : this.state.client_slug ? (
                      <ClientSidebar clientSlug={this.state.client_slug} />
                    ) : (
                      <CrmSettingSidebar />
                    )}
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel drop_user">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Lists</h3>
                          <div className="d-flex justify-content-end">
                            <Link
                              to={
                                this.state.property_slug
                                  ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/crm-list"
                                  : this.state.brand_slug
                                  ? "/brand/view/" +
                                    this.state.brand_slug +
                                    "/crm-list"
                                  : this.state.client_slug
                                  ? "/client/view/" +
                                    this.state.client_slug +
                                    "/crm-list"
                                  : "/crm-list"
                              }
                            >
                              <button className="btn-success-outline mg-r-20">
                                Cancel
                              </button>
                            </Link>
                            <button
                              type="button"
                              className="btn-success-outline-small ml-3 button-width"
                              onClick={this.saveOrUpdateList}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug
                                ? "Save"
                                : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pd-lg-l-0 pd-lg-t-0 ">
                    <div className="table-responsive roles   ">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0 mt-3">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      <div className="mg-t-18 brandList table_issue_roles">
                        <div className=" ">
                          <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                              <label className="form-label text-left">
                                List{" "}
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="list_name"
                                value={this.state.list_name}
                                maxLength="50"
                                autoComplete="off"
                              />
                              {this.state.errors.list_name ? (
                                <div className="text-danger">
                                  {this.state.errors.list_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "list name",
                                  this.state.list_name,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-15 pd-xs-r-15 pd-lg-l-15 pd-lg-r-30">
                              <label className="form-label text-left ">
                                List Description{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="description"
                                value={this.state.description}
                                className="form-control max_width_100"
                                id="description"
                                as="textarea"
                                rows="4"
                              />
                            </div>
                          </div>
                          <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30 manuals">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-30">
                              <div className="col-12 p-0">
                                <label className="form-label text-left">
                                  Add Emails
                                </label>
                                <div className="input-tag">
                                  <ul className="input-tag__tags">
                                    {tags.map((tag, i) => (
                                      <li key={tag}>
                                        <span>{tag}</span>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            this.removeTag(i);
                                          }}
                                        >
                                          <svg
                                            height="14"
                                            width="14"
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                            focusable="false"
                                            className="css-6q0nyr-Svg"
                                          >
                                            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                          </svg>
                                        </button>
                                      </li>
                                    ))}
                                    <li className="input-tag__tags__input">
                                      <input
                                        type="text"
                                        onKeyDown={this.inputKeyDown}
                                        ref={(c) => {
                                          this.tagInput = c;
                                        }}
                                        placeholder="Enter email.."
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row pd-lg-l-15 pd-lg-r-15 pd-xs-l-30 pd-xs-r-30">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-8 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                            <div className="col-md-1 pl-0">
                              <label className="form-label text-left">
                                Status
                              </label>
                            </div>

                            <div className="col-8 col-md-7 col-lg-7 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                              <b
                                className={
                                  this.state.status_id ? "active" : "inactive"
                                }
                              ></b>
                              <div
                                className={
                                  this.state.status_id
                                    ? "az-toggle on"
                                    : "az-toggle"
                                }
                                onClick={() => this.changeStatus()}
                              >
                                <span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default CrmManualListForm;

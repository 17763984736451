//     							                               //
//  Program: UsersForm.jsx                                     //
//  Application: users add or update                           //
//  Option: Users Add or update                                //
//  Developer: Mitesh Prajapati 		                       //
//  Date: 2022-09-06                                           //
//                                                             //
/////////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import CredentailService from "../../services/CredentailService";
import CompanyService from "../../services/CompanyService";
import BrandService from "../../services/BrandService";
import Alerts from "../common/Alerts";
import UnitsService from "../../services/UnitsService";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import ClientService from "../../services/ClientService";
import openeye from "../../assets/images/openeye.png";
import closeeye from "../../assets/images/closeeye.png";

class CredentailsFrom extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      status_id: true,
      pathname: window.location.pathname,
      confirm_back: 0,
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      countryListTotal: 0,
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      cityListTotal: 0,
      is_id_verified: false,
      propertyList: [],
      propertyListTotal: 0,
      newPropertyList: [],
      selectedPropertyList: [],
      selectedBrandsList: [],
      newCompanyList: [],
      selectedCompanyList: [],
      selectedClientList: [],
      newClientList: [],
      phone_no: "",
      roleList: [],
      isSearch: 0,
      roleListTotal: 0,
      newRoleList: [],
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      selectedRoleList: [],
      selectedPropertyUnitList: [],
      role_id: "",
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      activeClass: "activeTab",
      passwordType: "password",
      tab: "active",
      Moduledata: [
        { value: "5", label: "Units" },
        { value: "3", label: "Properties" },
        { value: "2", label: "Brands" },
        { value: "1", label: "Company" },
        { value: "26", label: "Clients" },
      ],
      usertabData: localStorage.getItem("user_tab_data")
        ? localStorage.getItem("user_tab_data")
        : "",
    };

    this.initialState = this.state;
    this.defaultData = {};
    this.handleChangePropertyList = this.handleChangePropertyList.bind(this);
    this.handleChangePropertybyUnitList =
      this.handleChangePropertybyUnitList.bind(this);
    this.handleChangeRoleList = this.handleChangeRoleList.bind(this);
    this.handleChangeBrandsList = this.handleChangeBrandsList.bind(this);
    this.handleChangeCompanyList = this.handleChangeCompanyList.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.openDeleteNearByAttractionModal =
      this.openDeleteNearByAttractionModal.bind(this);
    this.closeDeleteAttrcationModal =
      this.closeDeleteAttrcationModal.bind(this);
    this.deleteCredential = this.deleteCredential.bind(this);
  }

  componentDidMount() {
    // document.getElementById("activeTab").click();
    this.getData();
    this.getUnitData();
    this.getBrandsData();
    this.getCompanyData();
    this.getClientsData();

    // this.getCredentialData();
  }

  handleEmailInputFocus = () => {
    this.emailInput.removeAttribute("readonly");
  };

  handlePasswordInputFocus = () => {
    this.passwordInput.removeAttribute("readonly");
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  togglePassword = () => {
    if (this.state.slug !== undefined) {
      this.showPassword();
    } else {
      this.setState({ passwordType: "text" });
    }
  };

  togglePasswordHide = () => {
    this.setState({
      passwordType: "password",
      custom_type_name: "************",
    });
  };

  showPassword = () => {
    const { isPasswordModal } = this.state;

    if (isPasswordModal) {
      this.setState({
        isPasswordModal: false,
        custom_type_name: "************",
        errors: {},
      });
    } else {
      this.setState({ isPasswordModal: true, password: "", errors: {} });
    }
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  };

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    if (this.state.slug !== undefined) {
      let res = await CredentailService.GetOneCredential(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          custom_key_name: res.data ? res.data.custom_key_name : "",
          custom_type_name: "************",
          custom_value_name: res.data ? res.data.custom_value_name : "",
          web_url: res.data ? res.data.web_url : "",
          notes: res.data ? res.data.notes : "",
          ref_id: res.data ? res.data.ref_id : "",
          ref_table_id: res.data ? res.data.ref_table_id : "",
          slug_for_update: res.data ? res.data.slug : "",
        });

        var selectedRoleList = [];
        selectedRoleList =
          this.state.ref_table_id == 3
            ? [{ value: "3", label: "Properties" }]
            : this.state.ref_table_id == 5
            ? [{ value: "5", label: "Units" }]
            : this.state.ref_table_id == 1
            ? [{ value: "1", label: "Company" }]
            : this.state.ref_table_id == 2
            ? [{ value: "2", label: "Brands" }]
            : this.state.ref_table_id == 26
            ? [{ value: "26", label: "Clients" }]
            : "";
        var selectedBrandsList = [];
        var selectedpropertyUnitList = [];
        var selectedPropertyList = [];
        var selectedUnitList = [];
        var selectedCompanyList = [];
        var selectedClientList = [];
        if (this.state.ref_table_id == 5) {
          selectedpropertyUnitList = res.data
            ? {
                value: res.data.pslug,
                label: res.data.property_name,
              }
            : "";

          selectedUnitList = res.data
            ? {
                value: res.data.slug,
                label: res.data.unit_name
                  ? res.data.unit_name
                  : res.data.unit_number,
              }
            : "";
        } else if (this.state.ref_table_id == 2) {
          selectedBrandsList = res.data
            ? {
                value: res.data.slug,
                label: res.data.brand_name,
              }
            : "";
        } else if (this.state.ref_table_id == 1) {
          selectedCompanyList = res.data
            ? {
                value: res.data.company_slug,
                label: res.data.company_name,
              }
            : "";
        } else if (this.state.ref_table_id == 26) {
          selectedClientList = res.data
            ? {
                value: res.data.clientSlug,
                label: res.data.client_name,
              }
            : "";
        } else {
          selectedPropertyList = res.data
            ? {
                value: res.data.slug,
                label: res.data.property_name,
              }
            : "";
        }

        this.setState({
          selectedRoleList: selectedRoleList,
          selectedPropertyList: selectedPropertyList,
          selectedUnitList: selectedUnitList,
          selectedpropertyUnitList: selectedpropertyUnitList,
          selectedBrandsList: selectedBrandsList,
          selectedCompanyList: selectedCompanyList,
          selectedClientList: selectedClientList,
        });

        this.defaultData = resData;

        if (this.state.slug !== undefined && this.state.ref_table_id == 5) {
          this.getUnitData(this.state.selectedpropertyUnitList.value);
        }
      }
    }

    this.setState({ showSpinner: false, loading: false });

    this.getPropertyData();
  }

  async getPropertyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var propertyList = [];
    var newPropertyList = [];
    var propertyListTotal = 0;

    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;

      newPropertyList =
        propertyListTotal > 0
          ? propertyList.map(({ slug, property_name }) => ({
              value: slug,
              label: property_name,
            }))
          : [];
    }
    this.setState({
      propertyList: propertyList,
      newPropertyList: newPropertyList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await UnitsService.getUnitsList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name, property_id }) => ({
            value: slug,
            property_id: property_id,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getBrandsData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var brandList = [];
    var brandListTotal = 0;
    let res = await BrandService.getBrandList(
      "brand" + (queryString ? "?" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      brandList = res.data.data ? res.data.data : [];
      brandListTotal = brandList ? brandList.length : 0;
    }
    const newBrandsList =
      brandListTotal > 0
        ? brandList.map(({ slug, brand_name }) => ({
            value: slug,
            label: brand_name ? brand_name : brand_name,
          }))
        : [];
    this.setState({
      newBrandsList: newBrandsList,
      brandList: brandList,
      brandListTotal: brandListTotal,
    });
  }

  async getCompanyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var companyList = [];
    var companyListTotal = 0;
    let res = await CompanyService.getAllCompanyData(
      queryString ? "?" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      companyList = res.data ? res.data : [];
      companyListTotal = companyList ? companyList.length : 0;
    }
    const newCompanyList =
      companyListTotal > 0
        ? companyList.map(({ slug, company_name }) => ({
            value: slug,
            label: company_name ? company_name : "",
          }))
        : [];
    this.setState({
      newCompanyList: newCompanyList,
      companyList: companyList,
      companyListTotal: companyListTotal,
    });
  }

  async getClientsData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var clientList = [];
    var clientListTotal = 0;
    let res = await ClientService.getClientList(
      queryString ? "?" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      clientList = res.data.data ? res.data.data : [];
      clientListTotal = clientList ? clientList.length : 0;
    }
    const newClientList =
      clientListTotal > 0
        ? clientList.map(({ slug, client_name }) => ({
            value: slug,
            label: client_name ? client_name : "",
          }))
        : [];
    this.setState({
      newClientList: newClientList,
      clientList: clientList,
      clientListTotal: clientListTotal,
    });
  }

  openDeleteNearByAttractionModal(slug) {
    this.setState({ deleteNearByAttractionModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAttrcationModal() {
    this.setState({ deleteNearByAttractionModal: false });
    this.setState({ slug: "" });
  }

  async deleteCredential(slug) {
    this.setState({ showSpinner: true });
    let res = await CredentailService.deleteCredential(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAttrcationModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      setTimeout(
        () => this.props.history.push("/credentials"),
        global.redirect_time
      );
    } else {
      this.closeDeleteAttrcationModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  handleChangePropertyList(value) {
    let property_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_id.push(item.value);
      });
    }
    this.setState({
      selectedPropertyList: value,
      property_id: property_id,
    });
  }

  handleChangeBrandsList(value) {
    var brand_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        brand_id.push(item.value);
      });
    }
    this.setState({
      selectedBrandsList: value,
      brand_id: brand_id,
    });
  }

  handleChangeRoleList = (value) => {
    this.setState({
      selectedRoleList: value ? [value] : [],
      role_id: value ? value.value : "",
      ref_table_id: value ? "" : this.state.ref_table_id,
    });
  };

  handleChangePropertybyUnitList(value) {
    let property_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_id.push(item.value);
      });
    }
    this.setState({
      selectedpropertyUnitList: value,
      property_id_Unit: property_id,
    });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCompanyList(value) {
    let company_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        company_id.push(item.value);
      });
    }
    this.setState({
      selectedCompanyList: value,
      company_id: company_id,
    });
  }

  handleChangeClientList(value) {
    let client_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        client_id.push(item.value);
      });
    }
    this.setState({
      selectedClientList: value,
      client_id: client_id,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.notes !== undefined &&
      input.notes !== "" &&
      input.notes !== null &&
      input.notes.length > 10000
    ) {
      isValid = false;
      errors["notes"] = "Notes character limit has been exceed.";
    }
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.setState({ errors: errors });
    return isValid;
  };

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("status_id");
    }
  }

  userData = async () => {
    if (this.validateFields()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        custom_type_name: this.state.custom_type_name ?? "",
        custom_key_name: this.state.custom_key_name ?? "",
        custom_value_name: this.state.custom_value_name ?? "",
        web_url: this.state.web_url ?? "",
        notes: this.state.notes ?? "",
        ref_table_id: this.state.role_id || this.state.ref_table_id,
        ref_slug:
          this.state.role_id == 3
            ? this.state.selectedPropertyList.value
            : this.state.ref_table_id === 3
            ? this.state.selectedPropertyList.value
            : this.state.role_id == 2
            ? this.state.selectedBrandsList.value
            : this.state.role_id == 26
            ? this.state.selectedClientList.value
            : this.state.ref_table_id === 2
            ? this.state.selectedBrandsList.value
            : this.state.role_id == 1
            ? "OMAS01CTBU2J"
            : this.state.ref_table_id == 1
            ? "OMAS01CTBU2J"
            : this.state.role_id == 5
            ? this.state.unit_slug
            : this.state.ref_table_id == 5
            ? this.state.selectedUnitList.value
            : this.state.ref_table_id == 26
            ? this.state.selectedClientList.value
            : "",
      };
      if (this.state.slug !== undefined) {
        let res = await CredentailService.UpdateallCredential(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.props.history.push("/credentials"),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors });
          }
        }
      } else {
        let res = await CredentailService.createallCredential(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.props.history.push("/credentials"),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors });
          }
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  onChangePhone(value, country, e, formattedValue) {
    this.setState({ phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }

  redirectToUserPage() {
    this.props.history.push("/credentials");
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.UserActivity(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.UserActivity(queryString);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmit: true });
    try {
      const { slug, password } = this.state;
      const inputData = { slug, password };
      if (this.validator.allValid()) {
        const res = await CredentailService.fetchPass(inputData);

        if (global.successStatus.includes(res.status)) {
          const { custom_type_name } = res.data || {};
          if (res.message === "Unauthorized. Incorrect password.") {
            this.setState({
              errors: { password: res.message },
              isSubmit: false,
            });
          } else {
            this.setState({
              custom_type_name: custom_type_name || "************",
              isSubmit: false,
              errors: {},
              isPasswordModal: false,
              passwordType: "text",
            });
          }
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors });
          }
        }
      } else {
        this.validator.showMessages();
      }
    } catch (err) {
      this.setState({
        errors: err.response?.data?.error || "An error occurred",
        isSubmit: false,
        custom_type_name: "************",
      });
    }
    this.setState({ isSubmit: false });
  };

  validateFields = () => {
    const errors = {};

    if (!this.state.custom_key_name) {
      errors.custom_key_name = "Account is required";
    }

    if (!this.state.custom_value_name) {
      errors.custom_value_name = "Username is required";
    }

    if (this.state.slug === undefined && !this.state.custom_type_name) {
      errors.custom_type_name = "Password is required";
    }

    // Ensure `selectedRoleList` has at least one valid entry
    if (!this.state.selectedRoleList || !this.state.selectedRoleList.length) {
      errors.module = "Type is required";
    }

    if (this.state.notes && this.state.notes.length > 10000) {
      errors.notes = "Notes character limit has been exceeded";
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,

        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container pr-0 over-fl container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 ">
              <div className="col-md-12">
                <div className="scrolling-carousel drop_user">
                  <Container>
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Credentials</h3>
                          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                            {this.state.slug !== undefined ? (
                              <>
                                {global.userPermissions.checkPermission(
                                  "credentials-delete"
                                ) && (
                                  <span
                                    onClick={() => {
                                      this.openDeleteNearByAttractionModal(
                                        this.state.slug
                                      );
                                    }}
                                    className="deleteicon mg-r-20"
                                  >
                                    <img src={deleteicon} />
                                  </span>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <button
                              type="button"
                              className="mx-auto btn btn-outline-light btn-block ml-3 mt-0"
                              onClick={() => this.redirectToUserPage()}
                            >
                              Cancel
                            </button>

                            {global.userPermissions.checkPermission(
                              "credentials-update"
                            ) && (
                              <Button
                                className="btn-success ml-3"
                                onClick={this.userData}
                                disabled={this.state.isSubmit ? true : false}
                              >
                                {this.state.isSubmit
                                  ? global.loader
                                  : this.state.slug !== undefined
                                  ? "Save"
                                  : "Save"}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0 mt-3">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                    </div>
                  </Container>
                  <Container>
                    <div className="scrolling-carousel pd-l-15 w-100"></div>

                    <div className="scrolling-carousel pd-lg-l-0 pd-xs-l-15 scroll-slide">
                      <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                        <form id="units_form">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="form-group mg-t-30">
                              <div className="row  pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-15 pd-lg-r-15">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Account{" "}
                                  </label>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="custom_key_name"
                                    value={this.state.custom_key_name}
                                    maxLength="50"
                                  />
                                  {this.state.errors.custom_key_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.custom_key_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "account",
                                      this.state.custom_key_name,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Username{" "}
                                  </label>
                                  <div className="remove-read-only">
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="custom_value_name"
                                      value={this.state.custom_value_name}
                                      maxLength="50"
                                      ref={(input) => (this.emailInput = input)}
                                      onFocus={this.handleEmailInputFocus}
                                      readOnly
                                    />
                                  </div>
                                  {this.state.errors.custom_value_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.custom_value_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "username",
                                      this.state.custom_value_name,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Password
                                  </label>
                                  <div className="passwod_input remove-read-only">
                                    <Form.Control
                                      type={this.state.passwordType}
                                      onChange={this.changeHandler}
                                      name="custom_type_name"
                                      value={this.state.custom_type_name}
                                      maxLength="50"
                                      ref={(input) =>
                                        (this.passwordInput = input)
                                      }
                                      onFocus={this.handlePasswordInputFocus}
                                      readOnly
                                    />
                                    <div className="input-group-append">
                                      {this.state.passwordType ===
                                      "password" ? (
                                        <span
                                          className="eye-icon"
                                          onClick={this.togglePassword}
                                        >
                                          <img src={openeye} alt="" />
                                        </span>
                                      ) : (
                                        <span
                                          className="eye-icon"
                                          onClick={this.togglePasswordHide}
                                        >
                                          {" "}
                                          <img src={closeeye} alt="" />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.errors.custom_type_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.custom_type_name}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "password",
                                      this.state.custom_type_name,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    URL
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="web_url"
                                    value={this.state.web_url}
                                    maxLength="50"
                                  />
                                  {this.state.errors.web_url ? (
                                    <div className="text-danger">
                                      {this.state.errors.web_url}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Type
                                  </label>
                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    menuPlacement="auto"
                                    value={this.state.selectedRoleList}
                                    options={this.state.Moduledata}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeRoleList(value)
                                    }
                                    defaultValue={this.state.selectedRoleList}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />

                                  {this.state.errors.module ? (
                                    <div className="text-danger">
                                      {this.state.errors.module}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "module",
                                      this.state.selectedRoleList,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                {this.state.role_id == 5 ||
                                this.state.ref_table_id == 5 ? (
                                  <>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Units
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        isClearable={true}
                                        className="multiselect"
                                        classNamePrefix={
                                          "my-custom-react-select"
                                        }
                                        menuPlacement="auto"
                                        value={this.state.selectedUnitList}
                                        options={this.state.newUnitList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(value) =>
                                          this.handleChangeUnitList(value)
                                        }
                                        defaultValue={
                                          this.state.selectedUnitList
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />

                                      {this.state.errors.Unit ? (
                                        <div className="text-danger">
                                          {this.state.errors.Unit}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "Properties",
                                          this.state.selectedUnitList,
                                          "required",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                {this.state.role_id == 3 ||
                                this.state.ref_table_id == 3 ? (
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Properties
                                    </label>
                                    <Select
                                      styles={customStyles}
                                      isClearable={true}
                                      className="multiselect"
                                      classNamePrefix={"my-custom-react-select"}
                                      menuPlacement="auto"
                                      value={this.state.selectedPropertyList}
                                      options={this.state.newPropertyList}
                                      getOptionValue={(option) =>
                                        `${option.label}`
                                      }
                                      onChange={(value) =>
                                        this.handleChangePropertyList(value)
                                      }
                                      defaultValue={
                                        this.state.selectedPropertyList
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />

                                    {this.state.role_title !== "Admin" &&
                                    this.state.errors.Properties ? (
                                      <div className="text-danger">
                                        {this.state.errors.Properties}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "Properties",
                                        this.state.selectedPropertyList,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {this.state.role_id == 2 ||
                                this.state.ref_table_id == 2 ? (
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Brands
                                    </label>
                                    <Select
                                      styles={customStyles}
                                      isClearable={true}
                                      className="multiselect"
                                      classNamePrefix={"my-custom-react-select"}
                                      menuPlacement="auto"
                                      value={this.state.selectedBrandsList}
                                      options={this.state.newBrandsList}
                                      getOptionValue={(option) =>
                                        `${option.label}`
                                      }
                                      onChange={(value) =>
                                        this.handleChangeBrandsList(value)
                                      }
                                      defaultValue={
                                        this.state.selectedBrandsList
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />

                                    {/* {this.state.role_title !== "Admin" &&
                                        this.state.errors.Properties ? (
                                          <div className="text-danger">
                                            {this.state.errors.Properties}
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "Brands",
                                            this.state.selectedPropertyList,
                                            "required",
                                            { className: "text-danger" }
                                          )
                                        )} */}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {this.state.role_id == 26 ||
                                this.state.ref_table_id == 26 ? (
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Clients
                                    </label>
                                    <Select
                                      styles={customStyles}
                                      isClearable={true}
                                      className="multiselect"
                                      classNamePrefix={"my-custom-react-select"}
                                      menuPlacement="auto"
                                      value={this.state.selectedClientList}
                                      options={this.state.newClientList}
                                      getOptionValue={(option) =>
                                        `${option.label}`
                                      }
                                      onChange={(value) =>
                                        this.handleChangeClientList(value)
                                      }
                                      defaultValue={
                                        this.state.selectedClientList
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />

                                    {/* {this.state.role_title !== "Admin" &&
                                        this.state.errors.Properties ? (
                                          <div className="text-danger">
                                            {this.state.errors.Properties}
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "Properties",
                                            this.state.selectedPropertyList,
                                            "required",
                                            { className: "text-danger" }
                                          )
                                        )} */}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="col-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5 w-100">
                                    Notes
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="notes"
                                    value={this.state.notes}
                                    className="form-control max_width_100"
                                    id="notes"
                                    as="textarea"
                                    rows={4}
                                  />

                                  {this.state.errors.notes ? (
                                    <div className="text-danger">
                                      {this.state.errors.notes}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "notes",
                                      this.state.notes,
                                      "max:10000",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* </Tab.Pane> */}
                      {/* <Tab.Pane eventKey="user-activity">
                            {this.state.total_record > 0 ||
                            this.state.filter_value !== "" ? (
                              <div className="mg-t-20 brandList">
                                <ReactDatatable
                                  className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                  config={
                                    this.state.total_record > global.page_size
                                      ? this.state.config
                                      : this.state.config
                                  }
                                  records={this.state.records}
                                  columns={this.state.columns}
                                  extraButtons={this.state.extraButtons}
                                  dynamic={true}
                                  // loading={this.state.loading}
                                  total_record={this.state.total_record}
                                  onChange={this.tableChangeHandler}
                                  onRowClicked={this.rowClickedHandler}
                                />
                              </div>
                            ) : (
                              <NoData msg="inactive users" />
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container> */}
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deleteNearByAttractionModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAttrcationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCredential(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.isPasswordModal}
          centered
          className="modal-xs agreementdocModal"
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div>
              <span
                className="welcomeClose"
                onClick={() => this.showPassword()}
              >
                {global.closee}
              </span>
              <h3 className="text-center">Password</h3>
              <div>
                {!this.state.custom_type_name ||
                this.state.custom_type_name === "************" ? (
                  <>
                    <div>
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.changeHandler}
                        required
                      />
                      {this.state.errors.password ? (
                        <div className="text-danger">
                          {this.state.errors.password}
                        </div>
                      ) : (
                        this.validator.message(
                          "password",
                          this.state.password,
                          "password|required",
                          { className: "text-danger" }
                        )
                      )}
                    </div>
                    <Button
                      className="m-0 btn btn btn-success btn-block btn-pass"
                      onClick={this.handleSubmit}
                      disabled={this.state.isSubmit}
                    >
                      Verify Password
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default CredentailsFrom;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: CrmListView.jsx                                  //
//  Application: CRM                                   //
//  Option: to view ListMember Setup data                   //
//  Developer: Raj Kumar 			                  //
//  Date: 2023-04-05                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import deleteicon from "../../assets/images/delete.svg";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import CrmListService from "../../services/CrmListService";
import Alerts from "../common/Alerts";
import NoData from "../common/NoData";
import { Container } from "react-bootstrap";
import CrmSettingSidebar from "../common/CrmSettingSidebar";
import ContactSidebar from "../common/ContactSidebar";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import BrandSidebar from "../common/BrandSidebar";
import ClientSidebar from "../common/ClientSidebar";

export class CrmListView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const pathStr = props.match.path;
    const pathArray = pathStr.split(":");
    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      showSpinner: false,
      toggleSwitchDark2: true,
      status_id: true,
      slug: this.props.match.params.crmSlug ?? "",
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : "",
      brand_slug:
        pathArray[0] === "/brand/view/" ? this.props.match.params.slug : "",
      client_slug:
        pathArray[0] === "/client/view/" ? this.props.match.params.slug : "",
      tableQueryString: "",
      memberList: [],
      memberListTotal: 0,
      deleteMemberModal: false,
      name: "",
      email: "",
      list_name: "",
      list_type: "",
      no_of_members: "",
      parameters: "",
      properties: [],
      residents: [],
      guests: [],
      staffs: [],
      applicants: [],
      contactStaffTypes: [],
      isFollowers: "",
      isContactUs: "",
      isListType: "",
      listType: "",
      records: [],
      onboardingSteps: [],
      config: {
        sort: {
          column: "",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns1: [
        {
          key: "first_name",
          text: "First Name",
          className: "name hand-cursor",
          cell: (record) => {
            return record.first_name ? record.first_name.split(" ")[0] : "";
          },
        },

        {
          key: "last_name",
          text: "Last Name",
          className: "name hand-cursor",
          cell: (record) => {
            return record.last_name
              ? record.last_name
              : record.first_name
              ? record.first_name.split(" ")[1]
              : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor text-left",
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "phone_number",
          text: "Phone Number",
          className: "phone_number hand-cursor text-left",
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber = record.phone_number
              ? record.phone_number.replace(/[\s-]/g, "")
              : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits = record.phone_number ? record.phone_number : [];
            }

            var number = record.phone_number
              ? lastTenDigits.trim().replace(/[^0-9]/g, "")
              : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.phone_number ? finalnumber : "";
          },
        },
      ],
      columns2: [
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor text-left",
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
      ],

      columns3: [
        {
          key: "first_name",
          text: "First Name",
          className: "name hand-cursor",
          cell: (record) => {
            return record.first_name ? record.first_name : "";
          },
        },

        {
          key: "last_name",
          text: "Last Name",
          className: "name hand-cursor",
          cell: (record) => {
            return record.last_name ? record.last_name : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor text-left",
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "phone_number",
          text: "Phone Number",
          className: "phone_number hand-cursor text-left",
          cell: (record) => {
            return record.phone_number ? record.phone_number : "";
          },
        },
        {
          key: "action",
          text: "Action",
          className: "contact_phone hand-cursor text-left",
          cell: (record) => {
            return (
              <div className="btn-icon-list d-block text-left">
                <img
                  src={deleteicon}
                  alt="Delete"
                  onClick={() => this.openDeleteMemberModal(record.slug)}
                  className="hand-cursor"
                />
              </div>
            );
          },
        },
      ],
    };

    this.getMemberData = this.getMemberData.bind(this);
    this.deleteCrmList = this.deleteCrmList.bind(this);
    this.opendeleteCrmListModal = this.opendeleteCrmListModal.bind(this);
    this.closedeleteCrmListModal = this.closedeleteCrmListModal.bind(this);
    this.openDeleteMemberModal = this.openDeleteMemberModal.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
  }

  componentDidMount() {
    this.getMemberData();
  }
  async getMemberData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var memberList = [];
    var memberListTotal = 0;
    var list = [];
    var list_name = "";
    var description = "";
    var properties = [];
    var residents = [];
    var guests = [];
    var staffs = [];
    var applicants = [];
    var isFollowers = "";
    var isContactUs = "";
    var isListType = "";
    var listType = "";
    var unsubscribeMail = "";
    var contactStaffTypes = [];
    var onboardingSteps = [];
    let res = await CrmListService.getCrmMembersList(
      this.props.match.params.crmSlug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      list_name = res.data ? res.data.list_name : "";
      description = res.data ? res.data.description : "";
      memberList = res.data.members.data ? res.data.members.data : [];
      list = res.data.members.data ? res.data.members.data : [];
      memberListTotal = res.data.members ? res.data.members.total : 0;
      properties = res.data.propertyData ? res.data.propertyData : [];
      residents = res.data.residentData ? res.data.residentData : [];
      guests = res.data.guestData ? res.data.guestData : [];
      staffs = res.data.staffData ? res.data.staffData : [];
      applicants = res.data.applicantData ? res.data.applicantData : [];
      var brands = res.data.brands ? res.data.brands : "";
      var client = res.data.client ? res.data.client : "";
      contactStaffTypes = res.data.contactTypeData
        ? res.data.contactTypeData
        : [];
      onboardingSteps = res.data.onboardingSteps
        ? res.data.onboardingSteps
        : [];
      isFollowers = res.data.isFollowers ? res.data.isFollowers : "";
      isContactUs = res.data.isContactUs ? res.data.isContactUs : "";
      unsubscribeMail = res.data ? res.data.unsubscrbeMails : "";
      isListType =
        res.data.list_type === 1
          ? "Auto List"
          : res.data.list_type === 2
          ? "Manual List"
          : res.data.list_type === 3
          ? "CSV"
          : "";
      listType = res.data.list_type;
    }

    this.setState({
      memberList: memberList,
      records: list,
      memberListTotal: memberListTotal,
      list_name: list_name,
      description: description,
      properties: properties,
      residents: residents,
      guests: guests,
      staffs: staffs,
      applicants: applicants,
      isFollowers: isFollowers,
      isContactUs: isContactUs,
      isListType: isListType,
      listType: listType,
      unsubscribeMail: unsubscribeMail,
      contactStaffTypes: contactStaffTypes,
      onboardingSteps: onboardingSteps,
      brands: brands,
      client: client,
      showSpinner: false,
      loading: false,
    });
  }

  async deleteMember(slug) {
    this.setState({ showSpinner: true });
    let res = await CrmListService.deleteCrmMember(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteMemberModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getMemberData();
    } else {
      this.closeDeleteMemberModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteMemberModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  opendeleteCrmListModal(slug) {
    this.setState({ deleteCrmListModal: true, slug: slug });
  }

  closedeleteCrmListModal() {
    this.setState({ deleteCrmListModal: false, slug: "" });
  }

  async deleteCrmList(slug) {
    this.setState({ showSpinner: true });
    let res = await CrmListService.deleteCrmList(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteCrmListModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.state.property_slug
              ? "/properties/view/" + this.state.property_slug + "/crm-list"
              : this.state.brand_slug
              ? "/brand/view/" + this.state.brand_slug + "/crm-list"
              : this.state.client_slug
              ? "/client/view/" + this.state.client_slug + "/crm-list"
              : "/crm-list"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteCrmListModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getMemberData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getMemberData(queryString);
    }
  };

  openDeleteMemberModal(slug) {
    this.setState({ deleteMemberModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteMemberModal() {
    this.setState({ deleteMemberModal: false });
    this.setState({ slug: "" });
  }
  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Lists";
  }

  render() {
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      {this.state.property_slug ? (
                        <ContactSidebar
                          property_slug={this.state.property_slug}
                        />
                      ) : this.state.brand_slug ? (
                        <BrandSidebar brand_slug={this.state.brand_slug} />
                      ) : this.state.client_slug ? (
                        <ClientSidebar clientSlug={this.state.client_slug} />
                      ) : (
                        <CrmSettingSidebar />
                      )}
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="scrolling-carousel">
                    <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                      <div className="row row-xs">
                        <div className="col-md-12 mg-t-10 mg-b-10"></div>
                        <div className="col-md-12">
                          <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                            <h3 className="brnd_heading mb-0">
                              {this.getTitle()}
                            </h3>
                            <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                              {this.props.match.params.crmSlug !== undefined ? (
                                <>
                                  {global.userPermissions.checkPermission(
                                    "crm-lists-delete"
                                  ) && (
                                    <span
                                      onClick={() => {
                                        this.opendeleteCrmListModal(
                                          this.props.match.params.crmSlug
                                        );
                                      }}
                                      className="deleteicon ml-3"
                                    >
                                      <img src={deleteicon} alt="" />
                                    </span>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              <Link
                                to={
                                  this.state.property_slug
                                    ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/crm-list"
                                    : this.state.brand_slug
                                    ? "/brand/view/" +
                                      this.state.brand_slug +
                                      "/crm-list"
                                    : this.state.client_slug
                                    ? "/client/view/" +
                                      this.state.client_slug +
                                      "/crm-list"
                                    : "/crm-list"
                                }
                              >
                                <button className="btn-success-outline ml-3">
                                  Cancel
                                </button>
                              </Link>

                              {global.userPermissions.checkPermission(
                                "crm-lists-update"
                              ) &&
                                (this.state.listType === 1 ? (
                                  <Link
                                    to={
                                      this.state.property_slug
                                        ? "/properties/view/" +
                                          this.state.property_slug +
                                          "/crm-list/update-auto/" +
                                          this.props.match.params.crmSlug
                                        : this.state.brand_slug
                                        ? "/brand/view/" +
                                          this.state.brand_slug +
                                          "/crm-list/update-auto/" +
                                          this.props.match.params.crmSlug
                                        : this.state.client_slug
                                        ? "/client/view/" +
                                          this.state.client_slug +
                                          "/crm-list/update-auto/" +
                                          this.props.match.params.crmSlug
                                        : "/crm-list/update-auto/" +
                                          this.props.match.params.crmSlug
                                    }
                                    className=""
                                  >
                                    <button className="btn-success-outline-small ml-3">
                                      <img src={editIcon} alt="Edit" />
                                      Edit
                                    </button>
                                  </Link>
                                ) : this.state.listType === 2 ? (
                                  <Link
                                    to={
                                      this.state.property_slug
                                        ? "/properties/view/" +
                                          this.state.property_slug +
                                          "/crm-list/update-manual/" +
                                          this.props.match.params.crmSlug
                                        : this.state.brand_slug
                                        ? "/brand/view/" +
                                          this.state.brand_slug +
                                          "/crm-list/update-manual/" +
                                          this.props.match.params.crmSlug
                                        : this.state.client_slug
                                        ? "/client/view/" +
                                          this.state.client_slug +
                                          "/crm-list/update-manual/" +
                                          this.props.match.params.crmSlug
                                        : "/crm-list/update-manual/" +
                                          this.props.match.params.crmSlug
                                    }
                                    className=""
                                  >
                                    <button className="btn-success-outline-small ml-3">
                                      <img src={editIcon} alt="Edit" />
                                      Edit
                                    </button>
                                  </Link>
                                ) : this.state.listType === 3 ? (
                                  <Link
                                    to={
                                      this.state.property_slug
                                        ? "/properties/view/" +
                                          this.state.property_slug +
                                          "/crm-list/update-csv/" +
                                          this.props.match.params.crmSlug
                                        : this.state.brand_slug
                                        ? "/brand/view/" +
                                          this.state.brand_slug +
                                          "/crm-list/update-csv/" +
                                          this.props.match.params.crmSlug
                                        : this.state.client_slug
                                        ? "/client/view/" +
                                          this.state.client_slug +
                                          "/crm-list/update-csv/" +
                                          this.props.match.params.crmSlug
                                        : "/crm-list/update-csv/" +
                                          this.props.match.params.crmSlug
                                    }
                                    className=""
                                  >
                                    <button className="btn-success-outline-small ml-3">
                                      <img src={editIcon} alt="Edit" />
                                      Edit
                                    </button>
                                  </Link>
                                ) : (
                                  ""
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Container className="p-0 container">
                      <div className="pd-lg-l-15 pd-xs-l-15  tbl_lst_th_left">
                        <div className="scrolling-carousel scroll-slide">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>
                        <div className="row align-items-center col-md-12 mg-t-30 mg-b-0 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-xs-l-15 ">
                          {this.state.list_name ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0 mb-0">
                                List Name
                              </p>
                              <p className="media-body campng_text">
                                {this.state.list_name
                                  ? this.state.list_name
                                  : ""}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.description ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  List Description
                                </p>
                                <p className="media-body campng_text">
                                  {this.state.description}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.isListType ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4  mg-b-15">
                              <p className="az-profile-name-text pl-0 mb-0">
                                List Type
                              </p>
                              <p className="media-body campng_text">
                                {this.state.isListType}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.memberListTotal ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4  mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Total Recipients
                                </p>
                                <p className="media-body campng_text">
                                  {this.state.memberListTotal}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.properties.length > 0 ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Properties
                                </p>
                                {this.state.properties.map((item, i) => {
                                  return (
                                    <span
                                      className="media-body campng_text"
                                      key={i}
                                    >
                                      {item.property_name}
                                      {i === this.state.properties.length - 1
                                        ? ""
                                        : ", "}
                                    </span>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.residents.length > 0 ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Residents
                                </p>
                                {this.state.residents.map((item, i) => {
                                  return (
                                    <span
                                      className="media-body campng_text"
                                      key={i}
                                    >
                                      {item.segment_name}
                                      {i === this.state.residents.length - 1
                                        ? ""
                                        : ", "}
                                    </span>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.guests.length > 0 ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Guests
                                </p>
                                {this.state.guests.map((item, i) => {
                                  return (
                                    <span
                                      className="media-body campng_text"
                                      key={i}
                                    >
                                      {item.segment_name}
                                      {i === this.state.guests.length - 1
                                        ? ""
                                        : ", "}
                                    </span>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.staffs.length > 0 ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Staff Roles
                                </p>
                                {this.state.staffs.map((item, i) => {
                                  return (
                                    <span
                                      className="media-body campng_text"
                                      key={i}
                                    >
                                      {item.role_title}
                                      {i === this.state.staffs.length - 1
                                        ? ""
                                        : ", "}
                                    </span>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.applicants.length > 0 ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Applicant Status
                                </p>
                                {this.state.applicants.map((item, i) => {
                                  return (
                                    <span
                                      className="media-body campng_text"
                                      key={i}
                                    >
                                      {item.name}
                                      {i === this.state.applicants.length - 1
                                        ? ""
                                        : ", "}
                                    </span>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.contactStaffTypes.length > 0 ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Property Contact (Staff Type)
                                </p>
                                {this.state.contactStaffTypes.map((item, i) => {
                                  return (
                                    <span
                                      className="media-body campng_text"
                                      key={i}
                                    >
                                      {item.staff_contact_type_name}
                                      {i ===
                                      this.state.contactStaffTypes.length - 1
                                        ? ""
                                        : ", "}
                                    </span>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.onboardingSteps.length > 0 ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Onboarding Steps
                                </p>
                                {this.state.onboardingSteps.map((item, i) => {
                                  return (
                                    <span
                                      className="media-body campng_text"
                                      key={i}
                                    >
                                      {item.segment_name}
                                      {i ===
                                      this.state.onboardingSteps.length - 1
                                        ? ""
                                        : ", "}
                                    </span>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.isFollowers ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4  mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Followers
                                </p>
                                <p className="media-body campng_text">
                                  {this.state.isFollowers}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.isContactUs ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4  mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Inquiry
                                </p>
                                <p className="media-body campng_text">
                                  {this.state.isContactUs}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.client ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Client{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.client}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.brands ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Brands{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.brands}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.unsubscribeMail ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4  mg-b-15">
                                <p className="az-profile-name-text pl-0 mb-0">
                                  Unsubscribed Mail
                                </p>
                                <p className="media-body campng_text">
                                  {this.state.unsubscribeMail}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-t-15">
                          <h3 className="mg-l-5  background-head d-flex  align-items-center justify-content-start mb-0">
                            Recipients List
                          </h3>
                        </div>
                        {this.state.memberListTotal > 0 ? (
                          <div className="mg-t-18 brandList">
                            <div className="pd-lg-l-0 pd-xs-l-15">
                              <ReactDatatable
                                className="table no-wrap mg-b-30 pd-b-2 brandtable text-align-left"
                                config={
                                  this.state.memberListTotal > global.page_size
                                    ? this.state.config
                                    : this.state.config
                                }
                                records={this.state.records}
                                columns={
                                  this.state.listType === 1
                                    ? this.state.columns1
                                    : this.state.listType === 2
                                    ? this.state.columns2
                                    : this.state.listType === 3
                                    ? this.state.columns3
                                    : ""
                                }
                                extraButtons={this.state.extraButtons}
                                dynamic={true}
                                total_record={this.state.memberListTotal}
                                onChange={this.tableChangeHandler}
                              />
                            </div>
                          </div>
                        ) : (
                          <NoData msg="recipients" />
                        )}
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        <Modal show={this.state.deleteCrmListModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteCrmListModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteCrmListModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCrmList(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteMemberModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closeDeleteMemberModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeDeleteMemberModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteMemberModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteMember(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default CrmListView;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ApplicantView.jsx                                //
//  Application:  Applicant                                   //
//  Option: For view  Applicant details                      //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Container, Modal } from "react-bootstrap";
import ApplicantsService from "../../services/ApplicantsService";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import LeasingSidebar from "../common/LeasingSidebar";
import Moment from "moment";
import deleteicon from "../../assets/images/delete.svg";

export class ApplicantView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      applicantDetailsTotal: 0,
      pathname: window.location.pathname,
      deletedFileList: [],
      descShow: 0,
      descShowPricing: 0,
      phoneNumber: "",
    };
    this.fullText = this.fullText.bind(this);
    this.fullTextPricing = this.fullTextPricing.bind(this);
    this.deleteApplicant = this.deleteApplicant.bind(this);
    this.opendeleteApplicantModal = this.opendeleteApplicantModal.bind(this);
    this.closedeleteApplicantModal = this.closedeleteApplicantModal.bind(this);
  }

  opendeleteApplicantModal(slug) {
    this.setState({ deleteApplicantModal: true, slug: slug });
  }

  closedeleteApplicantModal() {
    this.setState({ deleteApplicantModal: false, slug: "" });
  }

  async deleteApplicant(slug) {
    this.setState({ showSpinner: true });
    let res = await ApplicantsService.deleteApplicants(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteApplicantModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" + this.state.property_slug + "/applicants"
              : "/applicants"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteApplicantModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      var applicantDetailsTotal = 0;
      let res = await ApplicantsService.getApplicantsBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.applicant_details = res.data ? res.data.applicant_details : [];
        if (res.data && res.data.phone_number) {
          this.phoneNumberAutoFormat(res.data.phone_number);
        }
        applicantDetailsTotal = res.data.applicant_details
          ? res.data.applicant_details.length
          : 0;
      }
      this.setState(resData);
      this.setState({
        input: resData,
        applicantDetailsTotal: applicantDetailsTotal,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ phoneNumber: finalnumber });
  };

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  getTitle() {
    return "Applicants";
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextPricing() {
    if (this.state.descShowPricing === 0) {
      this.setState({ descShowPricing: 1 });
    } else {
      this.setState({ descShowPricing: 0 });
    }
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <LeasingSidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                          {global.userPermissions.checkPermission(
                            "applicants-delete"
                          ) && (
                            <span
                              onClick={() => {
                                this.opendeleteApplicantModal(this.state.slug);
                              }}
                              className="deleteicon mg-r-20"
                            >
                              <img src={deleteicon} alt="" />
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {global.userPermissions.checkPermission(
                        "applicants-update"
                      ) && (
                        <Link
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/applicants/update/" +
                                this.state.slug
                              : "/applicants/update/" +
                                this.state.property_slug +
                                "/" +
                                this.state.slug
                          }
                          className="mg-l-2"
                        >
                          <button className="btn-success-outline-small">
                            <img src={editIcon} alt="Edit" />
                            Edit
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="m-0">
                      <div className="row align-items-basline pd-b-0 border-bottom-0 pd-lg-l-0 pd-xs-l-15 pd-r-15 mg-t-30">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                          <p className="az-profile-name-text pl-0">
                            First Name
                          </p>
                          <p className="media-body">
                            {this.state.first_name ? this.state.first_name : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                          <p className="az-profile-name-text pl-0">Last Name</p>
                          <p className="media-body">
                            {this.state.last_name ? this.state.last_name : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                          <p className="az-profile-name-text pl-0">Email</p>
                          <p className="media-body">
                            {this.state.email ? this.state.email : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30s">
                          <p className="az-profile-name-text pl-0">Phone</p>
                          <p className="media-body">
                            {this.state.phoneNumber
                              ? this.state.phoneNumber
                              : ""}
                          </p>
                        </div>

                        <div className="row col-md-12 mg-t-40 mg-md-t-0 pd-xs-l-0 pd-lg-l-15">
                          <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0">
                            <p className="az-profile-name-text pl-0">Notes</p>
                            <div className="para">
                              {this.state.notes ? (
                                <p className="p_wrap1">
                                  {this.state.descShow
                                    ? this.state.notes.slice(
                                        0,
                                        global.showMoreLength
                                      )
                                    : this.state.notes}

                                  {this.state.notes.length >
                                    global.showMoreLength && (
                                    <span onClick={this.fullText}>
                                      {this.state.descShow ? (
                                        <p className="hand-cursor showmore">
                                          ...Show more{" "}
                                        </p>
                                      ) : (
                                        <p className="hand-cursor showmore">
                                          Show Less{" "}
                                        </p>
                                      )}
                                    </span>
                                  )}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mg-t-18 brandList pl-0 pr-0">
                      <div className="row col-md-12 mg-t-40 mg-md-t-0 ml-0 mr-0 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                        <h3 className="mg-l-0 background-head">
                          Applicant Details
                        </h3>
                      </div>
                      <div className="pd-lg-l-0 pd-xs-l-15">
                        <div
                          className="as-react-table m-0 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15"
                          id="as-react-datatable-container"
                        >
                          <div
                            className="row table-body asrt-table-body m-0"
                            id="as-react-datatable-table-body"
                          >
                            <div className="col-md-12">
                              <table
                                className="table table-hoverno-wrap mg-b-30 pd-b-2"
                                id="as-react-datatable"
                              >
                                <thead>
                                  <tr>
                                    <th className=" text-" width="">
                                      Move In Date
                                    </th>
                                    <th className=" text-" width="">
                                      Tour Date Time
                                    </th>
                                    {/* <th className=" text-" width="">
                                    Price Range
                                  </th> */}
                                    <th className="text-left" width="">
                                      Bedroom
                                    </th>
                                    {/* <th className=" text-" width="">
                                    Comment
                                  </th>
                                  <th className=" text-" width="">
                                    Notes
                                  </th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.applicantDetailsTotal > 0 ? (
                                    this.state.applicant_details.map(
                                      (option, i) => (
                                        <tr key={i}>
                                          <td>
                                            {option.desired_move_in_date
                                              ? Moment(
                                                  option.desired_move_in_date
                                                ).format(global.dateFormat)
                                              : ""}
                                          </td>
                                          <td>
                                            {option.tour_date_time
                                              ? Moment(
                                                  option.tour_date_time
                                                ).format(global.dateFormat)
                                              : ""}
                                          </td>
                                          {/* <td>
                                          {option.price_info ? (option.price_info.min != 0 ? ("$" + global.onKeyPressEvent.numberWithCommasForDecimal(option.price_info.min) + " - " + "$" + global.onKeyPressEvent.numberWithCommasForDecimal(option.price_info.max)) : ">$" + global.onKeyPressEvent.numberWithCommasForDecimal(option.price_info.max)) : ""}
                                        </td> */}
                                          <td className="text-left">
                                            {option.bedroom_number
                                              ? option.bedroom_number
                                              : ""}
                                          </td>
                                          {/*<td>
                                          {option.comment ? option.comment : ""}
                                        </td> <td>
                                          {option.notes ? option.notes : ""}
                                        </td> */}
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="9" align="center">
                                        There are no fees added in the system.
                                        You may add them by clicking Edit
                                        button.
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteApplicantModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteApplicantModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteApplicantModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteApplicant(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default ApplicantView;

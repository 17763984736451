import React from "react";
import { Line } from "react-chartjs-2";
import LeasingService from "../services/LeasingService";

const Chart = (props) => {
  const [monthLabels, setMonthData] = React.useState([]);
  const [rentAmount, setRentAmountData] = React.useState([]);
  const [homeSharingAmount, setHomeSharingAmountData] = React.useState([]);
  const [currentMonthRevenueAmount, setcurrentMonthRevenueAmount] =
    React.useState(0);
  const [last12MonthRevenue, setlast12MonthRevenue] = React.useState(0);
  const [netRevenue, setnetRevenue] = React.useState(0);
  const propertySlug = props.propertySlug ? props.propertySlug : "";

  React.useEffect(() => {
    const PropertyRentdata = async () => {
      let res = await LeasingService.getPropertyRent(
        "property_slug=" + (propertySlug ? propertySlug : "")
      );
      if (global.successStatus.includes(res.status)) {
        setRentAmountData(res.data.rentAmount ? res.data.rentAmount : []);
        setHomeSharingAmountData(
          res.data.homeSharingAmount ? res.data.homeSharingAmount : []
        );
        setMonthData(res.data.monthName ? res.data.monthName : []);
        setcurrentMonthRevenueAmount(
          res.data.currentMonthRevenueAmount
            ? res.data.currentMonthRevenueAmount
            : 0
        );
        setlast12MonthRevenue(
          res.data.last12MonthRevenue ? res.data.last12MonthRevenue : 0
        );
        setnetRevenue(res.data.netRevenue ? res.data.netRevenue : 0);
      }
    };
    PropertyRentdata();
  }, [propertySlug]);

  const lineChartData = {
    labels: monthLabels,
    datasets: [
      {
        label: "Collected Rent",
        data: rentAmount,
        tension: 0.1,
        backgroundColor: "#2c504661",
        borderColor: "#2c5046",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#2c5046",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#2c5046",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        borderWidth: 3,
      },
      {
        label: "Collected Rent + Home Sharing Income",
        data: homeSharingAmount,
        borderWidth: 3,
        fill: true,
        borderColor: "#ea3e91",
        backgroundColor: "#ea3e911a",
      },
    ],
  };

  return (
    <div>
      <div className="container p-0">
        <div className="az-content-body p-0">
          <div className="media media-dashboard-one">
            <div className="media-body m-0">
              <div className="card card-minimal-two">
                <div className="card-body dashborad_chart">
                  <div className="flot-wrapper ml-0 pd-lg-r-20 pd-lg-l-20">
                    <div className="d-flex justify-content-between mg-b-0">
                      <div className="card-header overview_heading border-0 mb-0">
                        <h4 className="mb-0">Property Rent collection</h4>
                      </div>
                      <div className="chart-legend">
                        <div>
                          <span className="bg-chat-pink"></span> Collected Rent
                          + Home Sharing Income
                        </div>
                        <div>
                          <span className="bg-chart-green"></span> Collected
                          Rent
                        </div>
                      </div>
                    </div>
                    <div className="chart_box_fix">
                      <Line
                        options={{
                          maintainAspectRatio: false,
                          axisY: {
                            title: "Bounce Rate",
                            suffix: "%",
                          },
                          axisX: {
                            title: "Week of Year",
                            prefix: "W",
                            interval: 2,
                          },
                          scales: {
                            yAxes: [
                              {
                                display: true,
                                ticks: {
                                  // Include a dollar sign in the ticks
                                  callback: function (value, index, ticks) {
                                    return value
                                      ? "$" +
                                          global.onKeyPressEvent.numberWithCommasForDecimal(
                                            parseFloat(value)
                                              .toFixed(2)
                                              .replace(/\.00$/, "")
                                          )
                                      : 0;
                                  },
                                  minor: {
                                    fontSize: 16,
                                    fontFamily: "Lato-Regular",
                                  },
                                },
                              },
                            ],
                            xAxes: [
                              {
                                display: true,
                                position: "bottom",
                                ticks: {
                                  minor: {
                                    fontSize: 16,
                                    fontFamily: "Lato-Regular",
                                  },
                                },
                              },
                            ],
                          },
                          legend: {
                            display: false,
                            position: "top",
                          },
                          elements: {
                            point: {
                              radius: 0,
                            },
                            line: {
                              tension: 0,
                            },
                          },
                          tooltips: {
                            callbacks: {
                              label: function (tooltipItem, data) {
                                const datasetLabel =
                                  data.datasets[tooltipItem.datasetIndex].label;
                                return (
                                  datasetLabel +
                                  ": $" +
                                  global.onKeyPressEvent.numberWithCommasForDecimal(
                                    tooltipItem.yLabel
                                  )
                                );
                              },
                            },
                          },
                        }}
                        data={lineChartData}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="media">
                    <div className="media-body">
                      <div className="row row-sm">
                        <div className="col">
                          <label>Current Month Revenue</label>
                          <p>
                            {currentMonthRevenueAmount
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(currentMonthRevenueAmount)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                        <div className="col">
                          <label>last 12 Month Revenue</label>
                          <p>
                            {last12MonthRevenue
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(last12MonthRevenue)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                        <div className="col">
                          <label>LifeTime Revenue</label>
                          <p>
                            {netRevenue
                              ? "$" +
                                global.onKeyPressEvent.numberWithCommasForDecimal(
                                  parseFloat(netRevenue)
                                    .toFixed(2)
                                    .replace(/\.00$/, "")
                                )
                              : 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Chart;

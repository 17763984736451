////////////////////////////////////////////////////////////
//     							                          //
//  Program: AttractionType.jsx                           //
//  Application: Misc                                   //
//  Option: to view Attraction Type data                  //
//  Developer: Ashish Kumar                               //
//  Date: 2022-01-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import {
  Button,
  Table,
  Modal,
  Form,
} from "react-bootstrap";
import Alerts from "../common/Alerts";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import AttractionTypeService from "../../services/AttractionTypeService";
import pluswIcon  from './../../assets/images/plus.svg'
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";

export class AttractionType extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for Attraction type
      addAttractionTypeModal: false,
      editAttractionTypeModal: false,
      deleteAttractionTypeModal: false,
      attractionTypeList: [],
      attractionTypeListTotal: 0,
      attraction_type_name: "",
      sort_order: "",
    };
    this.changeHandler = this.changeHandler.bind(this);

    /* for Attraction Type */
    this.openAddAttractionTypeModal =
      this.openAddAttractionTypeModal.bind(this);
    this.openEditAttractionTypeModal =
      this.openEditAttractionTypeModal.bind(this);
    this.openDeleteAttractionTypeModal =
      this.openDeleteAttractionTypeModal.bind(this);
    this.saveAttractionType = this.saveAttractionType.bind(this);
    this.getAttractionTypeDetails = this.getAttractionTypeDetails.bind(this);
    this.updateAttractionType = this.updateAttractionType.bind(this);
    this.deleteAttractionType = this.deleteAttractionType.bind(this);
  }

  componentDidMount() {
    this.getAttractionTypeData();
  }

  /* To get attraction type list data */
  async getAttractionTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var roomCategoryList = [];
    var roomCategoryListTotal = 0;

    let res = await AttractionTypeService.getAttractionType(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      roomCategoryList = res.data.data ? res.data.data : [];
      roomCategoryListTotal = roomCategoryList ? roomCategoryList.length : 0;
    }
    this.setState({
      roomCategoryList: roomCategoryList,
      roomCategoryListTotal: roomCategoryListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To save attraction type data */
  saveAttractionType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.attraction_type_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        attraction_type_name: this.state.attraction_type_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await AttractionTypeService.createAttractionType(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddAttractionTypeModal();
        this.setState({
          attraction_type_name: "",
          sort_order: "",
          status_id: true,
        });
        this.getAttractionTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get attraction type details data  */
  async getAttractionTypeDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await AttractionTypeService.getAttractionTypeBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.attraction_type_name = res.data.attraction_type_name
          ? res.data.attraction_type_name
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update attraction type data */
  updateAttractionType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.attraction_type_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        attraction_type_name: this.state.attraction_type_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await AttractionTypeService.updateAttractionType(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditAttractionTypeModal();
        this.setState({
          attraction_type_name: "",
          sort_order: "",
          status_id: true,
        });
        this.getAttractionTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete attraction type data */
  async deleteAttractionType(slug) {
    this.setState({ showSpinner: true });
    let res = await AttractionTypeService.deleteAttractionType(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAttractionTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      
      this.getAttractionTypeData();
    } else {
      this.closeDeleteAttractionTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* Attraction Type Modals */
  openAddAttractionTypeModal() {
    this.setState({ addAttractionTypeModal: true });
  }

  closeAddAttractionTypeModal() {
    this.setState({ addAttractionTypeModal: false });
    this.setState({
      attraction_type_name: "",
      sort_order: "",
      errors: "",
      status_id: true,
    });
    this.validator.hideMessages();
  }

  openEditAttractionTypeModal(slug) {
    this.getAttractionTypeDetails(slug);
    this.setState({ editAttractionTypeModal: true });
    this.setState({ slug: slug });
  }

  closeEditAttractionTypeModal() {
    this.setState({ editAttractionTypeModal: false });
    this.setState({
      slug: "",
      attraction_type_name: "",
      sort_order: "",
      errors: "",
      status_id: true,
    });
    this.validator.hideMessages();
  }

  openDeleteAttractionTypeModal(slug) {
    this.setState({ deleteAttractionTypeModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAttractionTypeModal() {
    this.setState({ deleteAttractionTypeModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
  }

  render() {
    return (
      <>
        {/* Attraction Type card */}
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="3"
              id="3"
              onClick={(e) => this.setOpen(!this.state.open, e)}
            >
              Neighborhood Guide Item Type
              <i className="mdi mdi-view-headline"> </i>
              {this.state.open && this.state.eventKey === "3" ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                <Button
                  variant="outline-dark btn-block btn-with-icon"
                  onClick={this.openAddAttractionTypeModal}
                >
                  <img src={pluswIcon}/> Add
                </Button>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-50">Attraction Type Name</th>
                      <th className="w-25">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.roomCategoryListTotal > 0 ? (
                      this.state.roomCategoryList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="hand-cursor" onClick={() =>
                                    this.openEditAttractionTypeModal(item.slug)
                                  }>{item.attraction_type_name}</td>
                            <td>
                              {item.status_id === 1 ? (
                                <b className="active"></b>
                              ) : (
                                <b></b>
                              )}
                            </td>
                            <td>
                              <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.openDeleteAttractionTypeModal(
                                      item.slug
                                    )
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          No rows found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}
<main>
<Container className="innter-container">
<div className="d-flex flex-column">
  <div className="row row-xs mg-t-0 pd-t-0">
      <div className="col-md-2 left">
          <div className="child-menu-section">
              <aside>
                  <MasterSidebar />
              </aside>
          </div>
      </div>
      <div className="col-md-10 right">
        <div className="master">
        <div className="table-responsive">
          <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
            <Button
              variant="outline-dark btn-block btn-with-icon"
              onClick={this.openAddAttractionTypeModal}
            >
              <img src={pluswIcon}/> Add
            </Button>
          </div>
          <div className="col-md-12 pl-0 pr-0">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>
          <Table hover className="mg-b-0">
            <thead>
              <tr>
                <th className="w-50">Attraction Type Name</th>
                <th className="w-25">Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.roomCategoryListTotal > 0 ? (
                this.state.roomCategoryList.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td className="hand-cursor" onClick={() =>
                              this.openEditAttractionTypeModal(item.slug)
                            }>{item.attraction_type_name}</td>
                      <td>
                        {item.status_id === 1 ? (
                          <b className="active"></b>
                        ) : (
                          <b></b>
                        )}
                      </td>
                      <td>
                        <div className="btn-icon-list d-block text-center">
                          <img
                            src={deleteicon}
                            alt="Delete"
                            onClick={() =>
                              this.openDeleteAttractionTypeModal(
                                item.slug
                              )
                            }
                            className="hand-cursor"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="9" align="center">
                  There are no attraction type added in the system. You may add them by clicking Add button.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        </div>
      </div>
      </div>
  </div>
</Container>
</main>

        {/* add Attraction Type popup modal */}
        <Modal
          show={this.state.addAttractionTypeModal}
          onHide={() => {
            if (
              window.confirm("Do you want to exit without saving?")
            )
              this.closeAddAttractionTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
          <span
                className="welcomeClose"
                onClick={() => this.closeAddAttractionTypeModal()}
              >
                {global.closee}
              </span>
            <h3 className="text-center">Add Attraction Type</h3>
            <label>
              Attraction Type Name 
            </label>
            <Form.Control
              onChange={this.changeHandler}
              name="attraction_type_name"
              value={this.state.attraction_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Attraction Type Name"
            />
            {this.state.errors.attraction_type_name ? (
              <div className="text-danger">
                {this.state.errors.attraction_type_name}
              </div>
            ) : (
              this.validator.message(
                "attraction_type_name",
                this.state.attraction_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3 row">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddAttractionTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="mx-auto m-0 btn btn btn-success btn-block"
                onClick={this.saveAttractionType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Attraction Type popup modal */}
        <Modal
          show={this.state.editAttractionTypeModal}
          onHide={() => {
            if (
              window.confirm("Do you want to exit without saving?")
            )
              this.closeEditAttractionTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
          <span
                className="welcomeClose"
                onClick={() => this.closeEditAttractionTypeModal()}
              >
                {global.closee}
              </span>
            <h3 className="text-center">Update Attraction Type</h3>
            <label>
              Attraction Type Name 
            </label>
            <Form.Control
              onChange={this.changeHandler}
              name="attraction_type_name"
              value={this.state.attraction_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Attraction Type Name"
            />
            {this.state.errors.attraction_type_name ? (
              <div className="text-danger">
                {this.state.errors.attraction_type_name}
              </div>
            ) : (
              this.validator.message(
                "attraction_type_name",
                this.state.attraction_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditAttractionTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="mx-auto m-0 btn btn btn-success btn-block"
                onClick={this.updateAttractionType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete Attraction Type popup modal */}
        <Modal
          show={this.state.deleteAttractionTypeModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteAttractionTypeModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
            <span
                className="welcomeClose"
                onClick={() => this.closeDeleteAttractionTypeModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAttractionTypeModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteAttractionType(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default AttractionType;

///////////////////////////////////////////////
//     							             //
//  Program: LatchService.js                 //
//  Application: Services                    //
//  Option: Used for Latch Module              //
//  Developer: Anoop                //
//  Date: 2023-03-27                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class LatchService {
  getLatchDoors(queryString) {
    let endPoint = "get-latch-doors" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.getCall(inputData);
    return response;
  }
  /* Get Latch Instruction --------------------------- */
  getLatchInstruction(queryString) {
    let endPoint = "get-latch-instruction" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.getCall(inputData);
    return response;
  }
  /* Get Latch Instruction --------------------------- */
  updateLatchInstruction(data) {
    let endPoint = "update-latch-instruction";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {endPoint, addditionalHeaderData, postData};
    let response = ApiService.postCall(inputData);
    return response;
  }
  /* Mapping Latch doors with Units --------------------------- */
  mappingLatchUnits(data) {
    let endPoint = "update-latch-doors";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {endPoint, addditionalHeaderData, postData};
    let response = ApiService.postCall(inputData);
    return response;
  }
  /* Get Latch Access Events --------------------------- */
  getLatchAccessEvents(queryString) {
    let endPoint = "get-latch-access-events" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.getCall(inputData);
    return response;
  }
  /* Get Latch Access schedules for backend --------------------------- */
  getLatchAccessSchedules(queryString) {
    let endPoint = "get-latch-access" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.getCall(inputData);
    return response;
  }
  /* Get Latch door list dropdown for backend --------------------------- */
  getDoorDropdownList(queryString) {
    let endPoint = "get-doors-dropdown-list" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.getCall(inputData);
    return response;
  }
  /* Get Latch door list dropdown for backend --------------------------- */
  getLatchAccessById(queryString) {
    let endPoint = "get-latch-access-by-id" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.getCall(inputData);
    return response;
  }
  /* Get Cleaner Latch Access list for backend --------------------------- */
  getCleanerLatchAccessList(queryString) {
    let endPoint = "latch-cleaner-access" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.postCall(inputData);
    return response;
  }
  /* manual invite user --------------------------- */
  manualInviteUser(data) {
    let endPoint = "latch-invite-manual";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.postCall(inputData);
    return response;
  }
  /* manual verify OTP --------------------------- */
  manualVeriftyOTP(data) {
    let endPoint = "latch-verify-otp-manual";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {endPoint,addditionalHeaderData, postData};
    let response = ApiService.postCall(inputData);
    return response;
  }
  /* get mapped unit number in latch --------------------------- */
  getLatchMappedUnits(queryString) {
    let endPoint = "get-latch-mapped-units" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  /* get access unit number in latch --------------------------- */
  createlatchmanualaccess(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "create-manual-latch-access";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  /* delete latch access  --------------------------- */
  deleteLatchaccess(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "delete-latch-access";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}
export default new LatchService();

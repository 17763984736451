////////////////////////////////////////////////////////////
//     							                          //
//  Program: NearByAttractionService.js                   //
//  Application: Misc                                     //
//  Option: Used for manage near by attraction            //
//  Developer: Ashish Kumar          					  //
//  Date: 2021-01-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class NearByAttractionService {

  getNearByAttraction(queryString) {
    let endPoint = "near-by-attraction"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getNearByAttractionForCity(slug) {
    let endPoint = "get-near-by-attraction/"+ slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createNearByAttraction(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "near-by-attraction";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getNearByAttractionBySlug(slug) {
    let endPoint = "near-by-attraction/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateNearByAttraction(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-near-by-attraction/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteNearByAttraction(slug) {
    let endPoint = "near-by-attraction/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadNearByAttractionEventImage(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'upload-near-by-attraction-image';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
    let response = ApiService.postCall(inputData);
    return response;
  }

  //for property 
  getPropertyNearByAttraction(slug) {
    let endPoint = "get-property-near-by-attraction/"+ slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  attachPropertyNearByAttraction(slug,data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "attach-property-near-by-attraction/"+slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  detachPropertyNearByAttraction(slug,data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "detach-property-near-by-attraction/"+slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new NearByAttractionService();

////////////////////////////////////////////////////////////
//     							                                       //
//  Program: CompanyOverview.jsx                          //
//  Application: Dashboard                                //
//  Option: to view Company Information                   //
//  Developer: NP  						                            //
//  Date: 2022-04-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

// import Moment from "moment";
import React, { Component } from "react"
import { Container } from 'react-bootstrap'
import CompanyService from '../../services/CompanyService'
import companyLogo from './../../assets/images/company_placeholder.png'
import PhoneNumberFormat from "../common/PhoneNumberFormat";

class CompanyOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: '',
      alertModalTitle: '',
      alertModalMessage: '',
      slug: '',
      total_client_count:"",
      checkInList:[],
      checkOutList:[]
    }
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};

    let res = await CompanyService.getCompany();

    if ((global.successStatus).includes(res.status)) {
      // resData = res.data ? res.data : {}
      resData.slug = res.data.slug ? res.data.slug : ''
      resData.company_name = res.data.company_name ? res.data.company_name : ''
      resData.company_logo_thumb_url = res.data.company_logo_thumb_url ? res.data.company_logo_thumb_url : ''
      resData.aka_name = res.data.aka_name ? res.data.aka_name : ''
      resData.legal_entity_name = res.data.legal_entity_name ? res.data.legal_entity_name : ''
      resData.tax_id = res.data.tax_id ? res.data.tax_id : ''
      resData.contact_name = res.data.contact_name ? res.data.contact_name : ''
      resData.phone_number = res.data.phone_number ? res.data.phone_number : ''
      resData.email = res.data.email ? res.data.email : ''
      resData.website_cookie = res.data.website_cookie ? res.data.website_cookie : ''
      resData.term_condition = res.data.term_condition ? res.data.term_condition : ''

      resData.orion_haus_account_manager_name = res.data && res.data.orion_haus_account_manager && res.data.orion_haus_account_manager.first_name ? res.data.orion_haus_account_manager.first_name : ""
      resData.orion_haus_email = res.data && res.data.orion_haus_account_manager && res.data.orion_haus_account_manager.email ? res.data.orion_haus_account_manager.email : ""
      resData.orion_haus_phone_number = res.data && res.data.orion_haus_account_manager && res.data.orion_haus_account_manager.phone_number ? res.data.orion_haus_account_manager.phone_number : ""
      resData.client_contact_name = res.data && res.data.client_info && res.data.client_info.first_name ? res.data.client_info.first_name : ""
      resData.client_contact_email = res.data && res.data.client_info && res.data.client_info.email ? res.data.client_info.email : ""
      resData.client_contact_phone_number = res.data && res.data.client_info && res.data.client_info.phone_number ? res.data.client_info.phone_number : ""
      
      resData.total_brand_count = res.data.total_brand_count ? res.data.total_brand_count : ''
      resData.total_property_count = res.data.total_property_count ? res.data.total_property_count : ''
      resData.total_unit_count = res.data.total_unit_count ? res.data.total_unit_count : ''
      resData.total_client_count = res.data.total_client_count ? res.data.total_client_count : ''
      resData.total_listing_count = res.data.total_listing_count ? res.data.total_listing_count : ''
      resData.total_reservation_count = res.data.total_reservation_count ? res.data.total_reservation_count : ''
      resData.total_resident_count = res.data.total_resident_count ? res.data.total_resident_count : ''
      resData.total_guest_count = res.data.total_guest_count ? res.data.total_guest_count : ''
      resData.total_night_count = res.data.total_night_count ? res.data.total_night_count : ''
      // resData.checkInList = res.data.check_in_list ? res.data.check_in_list : []
      // resData.checkOutList = res.data.check_out_list ? res.data.check_out_list : []
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  render() {
    return (
      <div>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}

          <Container>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              </div>
              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                  <h3>Overview</h3>
                  {/* <div className="d-flex">
                    <Link to={'/company'} ><button className="btn-success-outline mg-r-20">View</button></Link>
                  </div> */}
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="row row-xs mg-t-30 pd-t-2 cardsection">
              <div className="col-md-4 col-lg-3 mg-b-30 cardLeft">
                <div className="dashboardLeftcard az-profile-overview">
                  <div className="az-img-user ml-auto mr-auto align-items-center mt-0">
                    <figure>
                      <img
                        src={
                          this.state.company_logo_thumb_url
                            ? this.state.company_logo_thumb_url
                            : companyLogo
                        }
                        alt=""
                      />
                    </figure>
                  </div>
                  {/* az-img-user */}

                  <div className="d-flex justify-content-center text-center mg-b-20 mg-t-20">
                    <div>
                      <p className="az-profile-name-text">DBA</p>
                      <p className="media-body">
                        {this.state.company_name
                          ? this.state.company_name
                          : ""}
                      </p>

                      <p className="az-profile-name-text">
                        Company SKU
                      </p>
                      <p className="media-body">
                        {this.state.aka_name ? this.state.aka_name : ""}
                      </p>

                      <p className="az-profile-name-text">
                        Legal Entity
                      </p>
                      <p className="media-body">
                        {this.state.legal_entity_name
                          ? this.state.legal_entity_name
                          : ""}
                      </p>

                      <p className="az-profile-name-text">Tax ID</p>
                      <p className="media-body">
                        {this.state.tax_id ? this.state.tax_id : ""}
                      </p>

                      {/* manager info start */}

                      <p className="az-profile-name-text">Client Contact</p>

                      <p className="media-body p-0 m-0">
                        {this.state.client_contact_name ? this.state.client_contact_name : ""}
                      </p>
                      <p className="media-body p-0 m-0">
                        {this.state.client_contact_email ? this.state.client_contact_email : ""}
                      </p>
                      <p className="media-body pd-b-1 mg-b-15">
                        {this.state.client_contact_phone_number ? (
                          <PhoneNumberFormat
                            phone_number={this.state.client_contact_phone_number}
                          />
                        ) : (
                          ""
                        )}
                      </p>


                      <p className="az-profile-name-text">Orion Haus Account Manager</p>

                      <p className="media-body p-0 m-0">
                        {this.state.orion_haus_account_manager_name ? this.state.orion_haus_account_manager_name : ""}
                      </p>
                      <p className="media-body p-0 m-0">
                        {this.state.orion_haus_email ? this.state.orion_haus_email : ""}
                      </p>
                      <p className="media-body p-0 m-0">
                        {this.state.orion_haus_phone_number ? (
                          <PhoneNumberFormat
                            phone_number={this.state.orion_haus_phone_number}
                          />
                        ) : (
                          ""
                        )}
                      </p>
                      {/* manager info end */}

                    </div>
                  </div>
                </div>
                {/* az-profile-overview */}
              </div>

              <div className="col-md-8 col-lg-9 mg-b-30 cardRight">
                <div className="dashboardRightcard overviewSection overview p-0">
                  
                  <div className="az-content-body">
                    <div className="row row-sm pd-t-20">
                      {/* start of count boxes */}
                      <div className="col-sm-6 col-xl-3">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-purple"><i className="typcn typcn-chart-line-outline"></i></div>
                            <div className="media-body">
                              <h6>{this.state.total_client_count ? this.state.total_client_count : "0"}</h6>
                              <span> {this.state.total_client_count === 0 || this.state.total_client_count === 1 ? "Client" : "Clients"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-sm-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-primary"><i className="typcn typcn-chart-line-outline"></i></div>
                            <div className="media-body">
                              <h6>{this.state.total_brand_count ? this.state.total_brand_count : "0"}</h6>
                              <span> {this.state.total_brand_count === 0 || this.state.total_brand_count === 1 ? "Brand" : "Brands"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-pink"><i className="typcn typcn-chart-line-outline"></i></div>
                            <div className="media-body">
                              <h6>{this.state.total_property_count ? this.state.total_property_count : "0"}</h6>
                              <span> {this.state.total_property_count === 0 || this.state.total_property_count === 1 ? "Property" : "Properties"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-teal"><i className="typcn typcn-chart-line-outline"></i></div>
                            <div className="media-body">
                              <h6>{this.state.total_unit_count ? this.state.total_unit_count : "0"}</h6>
                              <span> {this.state.total_unit_count === 0 || this.state.total_unit_count === 1 ? "Unit" : "Units"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row-sm mg-t-20">
                      <div className="col-sm-6 col-xl-3">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-purple"><i className="typcn typcn-chart-line-outline"></i></div>
                            <div className="media-body">
                              <h6>{this.state.total_listing_count ? this.state.total_listing_count : "0"}</h6>
                              <span> {this.state.total_listing_count === 0 || this.state.total_listing_count === 1 ? "Listing" : "Listings"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-sm-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-primary"><i className="typcn typcn-chart-line-outline"></i></div>
                            <div className="media-body">
                              <h6>{this.state.total_reservation_count ? this.state.total_reservation_count : "0"}</h6>
                              <span> {this.state.total_reservation_count === 0 || this.state.total_reservation_count === 1 ? "Reservation" : "Reservations"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-pink"><i className="typcn typcn-chart-line-outline"></i></div>
                            <div className="media-body">
                              <h6>{this.state.total_resident_count ? this.state.total_resident_count : "0"}</h6>
                              <span> {this.state.total_resident_count === 0 || this.state.total_resident_count === 1 ? "Resident" : "Residents"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                        <div className="card card-dashboard-twentytwo">
                          <div className="media">
                            <div className="media-icon bg-teal"><i className="typcn typcn-chart-line-outline"></i></div>
                            <div className="media-body">
                              <h6>{this.state.total_guest_count ? this.state.total_guest_count : "0"}</h6>
                              <span> {this.state.total_guest_count === 0 || this.state.total_guest_count === 1 ? "Guest" : "Guests"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>

        </main>
      </div>
    );
  }
}
export default CompanyOverview
////////////////////////////////////////////////////////////
//     							                          //
//  Program: AffordableTypeService.js                     //
//  Application: Affordable Type                          //
//  Option: Used for manage Affordable type               //
//  Developer: Ashish Kumar  						      //
//  Date: 2022-01-06                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AffordableTypeService {
  getAffordableType(queryString) {
    let endPoint = "affordable-type"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createAffordableType(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "affordable-type";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getAffordableTypeBySlug(slug) {
    let endPoint = "affordable-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateAffordableType(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "affordable-type/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteAffordableType(slug) {
    let endPoint = "affordable-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new AffordableTypeService();

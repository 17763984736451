////////////////////////////////////////////////////////////
//     							                          //
//  Program: AuthenticationService.js                     //
//  Application: Services                                 //
//  Option: Used for authentication components            //
//  Developer: CV  						                  //
//  Date: 2021-02-01                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AuthenticationService {
  signin(data) {
    let endPoint = "login";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  forgotPass(data) {
    let endPoint = "forgot-password";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  resetPassword(data) {
    let endPoint = "reset-forgot-password";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  changePassword(data) {
    let endPoint = "change-password";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getProfile() {
    let endPoint = "get-profile";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateProfile(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-profile";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  uploadProfileImage(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "upload-profile-image";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getSetting() {
    let endPoint = "setting";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getSettingData() {
    let endPoint = "get-setting-data";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  logout() {
    let endPoint = "logout";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  //for user list as per role group
  getUserDetails(slug, queryString) {
    let endPoint =
      "get-user-details/" + slug + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  //for user list as per role group
  getUserList(queryString, slug) {
    if (slug != null) {
      let endPoint =
        "get-user-list/" + slug + (queryString ? "?" + queryString : "");
      let addditionalHeaderData = {};
      let postData = {};

      let inputData = {
        endPoint: endPoint,
        addditionalHeaderData: addditionalHeaderData,
        postData: postData,
      };
      let response = ApiService.getCall(inputData);
      return response;
    } else {
      let endPoint = "get-user-list" + (queryString ? "?" + queryString : "");
      let addditionalHeaderData = {};
      let postData = {};

      let inputData = {
        endPoint: endPoint,
        addditionalHeaderData: addditionalHeaderData,
        postData: postData,
      };
      let response = ApiService.getCall(inputData);
      return response;
    }
  }

  updateUserData(slug, data) {
    let endPoint = "update-user-details/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  saveUserData(data) {
    let endPoint = "add-user-details";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateUserSetting(data, slug) {
    let endPoint = "set-user-setting/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getUserSetting(slug) {
    let endPoint = "get-user-setting/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateUserRoleProperties(data, slug) {
    let endPoint = 'update-user-properties-relation/' + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getUserClintBrandData(slug) {
    let endPoint = "get-user-properties-relation/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

}

export default new AuthenticationService();

////////////////////////////////////////////////////////////
//     							                          //
//  Program: CountyService.js                             //
//  Application: Services                                 //
//  Option: Used for County list                          //
//  Developer: NP 						                  //
//  Date: 2022-01-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class CountyService {

    getCounty(queryString) {
        let endPoint = 'county' + (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    createCounty(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'county';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    getCountyBySlug(slug) {
        let endPoint = 'county/' + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateCounty(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'county/' + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.putCall(inputData);
        return response;
    }

    deleteCounty(slug) {
        let endPoint = 'county/' + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.deleteCall(inputData);
        return response;
    }
    
}

export default new CountyService()

/////////////////////////////////////////////////////
//     							                   //
//  Program: Neighborhood.jsx                             //
//  Application: Property                          //
//  Option: List all Property Neighborhood info           //
//  Developer: Ashish Kumar					   //
//  Date: 2022-04-04                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import { Container } from "react-bootstrap";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import MetroService from "../../services/MetroService";
import SubMetroService from "../../services/SubMetroService";
import Alerts from "../common/Alerts";
import MarketingSidebar from "../common/MarketingSidebar";

export class Neighborhood extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      property_slug: this.props.match.params.propertySlug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      metroList: [],
      selectedMetroList: [],
      newMetroList: [],
      subMetroList: [],
      newSubMetroList: [],
      selectedSubMetroList: [],
      confirm_back: 0,
      subMetroListTotal: 0,
      metroListTotal: 0,
    };
    this.changeView = this.changeView.bind(this);
    this.saveOrUpdateProperty = this.saveOrUpdateProperty.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 1 });
    this.getData();
    this.setState({ showSpinner: false, loading: false });
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data ? res.data : {}
        //for view
        resData.property_name = res.data.property_name ? res.data.property_name : "";
        resData.metro = res.data.metro ? res.data.metro.metro_name : "";
        resData.sub_metro = res.data.sub_metro
          ? res.data.sub_metro.sub_metro_name
          : "";
        //for edit
        resData.metro_id = res.data.metro ? res.data.metro.slug : "";
        resData.sub_metro_id = res.data.sub_metro
          ? res.data.sub_metro.slug
          : "";
        if (this.state.viewMode === 1) {
          var selectedMetroList = [];
          selectedMetroList = res.data.metro
            ? [
              {
                value: res.data.metro.slug,
                label: res.data.metro.metro_name,
              },
            ]
            : "";
          var selectedSubMetroList = [];
          selectedSubMetroList = res.data.sub_metro
            ? [
              {
                value: res.data.sub_metro.slug,
                label: res.data.sub_metro.sub_metro_name,
              },
            ]
            : "";
        }
      }
      this.setState(resData);
      this.setState({
        selectedMetroList: selectedMetroList,
        selectedSubMetroList: selectedSubMetroList,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  async getMetroData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var metroList = [];
    var metroListTotal = 0;
    let res = await MetroService.getMetro(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      metroList = res.data ? res.data : [];
      metroListTotal = metroList ? metroList.length : 0;
    }
    const newMetroList = metroListTotal > 0 ? metroList.map(({ slug, metro_name }) => ({
      value: slug,
      label: metro_name,
    })) : [];
    this.setState({ metroList: metroList, newMetroList: newMetroList, metroListTotal: metroListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSubMetroData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var subMetroList = [];
    var subMetroListTotal = 0;
    let res = await SubMetroService.getSubMetro(
      "is_dropdown=1&metro_slug=" +
      value +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      subMetroList = res.data ? res.data : [];
      subMetroListTotal = subMetroList ? subMetroList.length : 0;
    }
    const newSubMetroList = subMetroListTotal > 0 ? subMetroList.map(({ slug, sub_metro_name }) => ({
      value: slug,
      label: sub_metro_name,
    })) : [];
    this.setState({
      subMetroList: subMetroList,
      newSubMetroList: newSubMetroList,
      subMetroListTotal: subMetroListTotal
    });
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateProperty = async (e) => {
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        tab: 2,
        property_name: this.state.property_name,
        metro_slug: this.state.metro_id,
        sub_metro_slug: this.state.sub_metro_id,
      };

      if (this.state.property_slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.state.property_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.changeView();
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.getData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.getData();
      this.getMetroData();
      this.getSubMetroData();
    }
  }

  handleChangeMetroList(value) {
    let metro_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        metro_id.push(item.value);
      });
    }
    const metro_id_value = value !== null ? value.value : [];
    this.setState({
      selectedMetroList: value,
      metro_id: metro_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeSubMetroList(value) {
    let sub_metro_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        sub_metro_id.push(item.value);
      });
    }
    const sub_metro_id_value = value !== null ? value.value : [];
    this.setState({
      selectedSubMetroList: value,
      sub_metro_id: sub_metro_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  getTitle() {
    return "Near By";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <MarketingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </aside>
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="scrolling-carousel pd-l-15">
                  <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2 mg-t-20">
                    <h3>{this.getTitle()}</h3>
                  </div>
                  <div className="dashboardRightcard">
                    <div className="row- d-flex justify-content-end">
                      {this.state.viewMode === 0 ? (
                        <button
                          className="btn-success-outline-small"
                          onClick={this.changeView}
                        >
                          <img src={editIcon} alt="Edit" />
                          Edit
                        </button>
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.changeView();
                                else return false;
                              else this.changeView();
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn-success ml-3"
                            onClick={this.saveOrUpdateProperty}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 pl-0 pr-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    {/* view mode */}
                    {this.state.viewMode === 0 ? (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                              <div className="col-md-4">
                                <p className="az-profile-name-text pl-0">
                                  Metro
                                </p>
                                <p className="media-body">
                                  {this.state.metro ? this.state.metro : ""}
                                </p>
                              </div>

                              <div className="col-md-4">
                                <p className="az-profile-name-text pl-0">
                                  Sub Metro
                                </p>
                                <p className="media-body">
                                  {this.state.sub_metro
                                    ? this.state.sub_metro
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {/* edit mode */}
                    {this.state.viewMode === 1 ? (
                      <div className="m-0">
                        <div className="row align-items-center pd-t-30 pd-b-30 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Metro{" "}<span className="optional">(Optional)</span>
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedMetroList}
                              options={this.state.newMetroList}
                              getOptionValue={option => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeMetroList(value)
                              }
                              defaultValue={this.state.selectedMetroList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />

                            {this.state.errors.metro_id ? (
                              <div className="text-danger">
                                {this.state.errors.metro_id}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Sub Metro{" "}<span className="optional">(Optional)</span>

                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedSubMetroList}
                              options={this.state.newSubMetroList}
                              getOptionValue={option => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeSubMetroList(value)
                              }
                              defaultValue={this.state.selectedSubMetroList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />

                            {this.state.errors.sub_metro_id ? (
                              <div className="text-danger">
                                {this.state.errors.sub_metro_id}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default Neighborhood;

/////////////////////////////////////////////////////////////////
//  Program: Users.jsx                                         //
//  Application: users                                         //
//  Option: Users Listing                                      //
//  Developer: Mitesh Prajapati 		                           //
//  Date: 2022-08-10                                           //
//                                                             //
/////////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Container, Modal, InputGroup, Form, Button } from "react-bootstrap";
import NoData from "../common/NoData";
import Select from "react-select";
import { Link } from "react-router-dom";
import CredentailService from "../../services/CredentailService";
import RolePermissionService from "../../services/RolePermissionService";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Moment from "moment";
import DatePicker from "react-datepicker";
import SimpleReactValidator from "simple-react-validator";

class Credentails extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      pathname: window.location.pathname,
      showSpinner: false,
      loading: false,
      total_record: 0,
      records: [],
      groupList: [],
      selectedRoleList: [],
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      RoleList: [],
      SelectedRoleList: [],
      SelectedRoleGroupList: [],
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      isSearch: global.isSearch,
      filter_value: "",
      isPasswordModal: false,
      password: "",
      reloadSection: true,
      errors: {},
      Moduledata: [
        { value: "5", label: "Units" },
        { value: "3", label: "Properties" },
        { value: "2", label: "Brands" },
        { value: "1", label: "Company" },
        { value: "26", label: "Clients" },
      ],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "custom_key_name",
          text: "Account",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.custom_key_name ? (
              <Link
                to={
                  global.userPermissions.checkPermission(
                    "credentials-update"
                  ) && "credentials/update/" + record.slug
                }
                className=""
              >
                {record.custom_key_name}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "web_url",
          text: "URL",
          className: "",
          sortable: false,
          cell: (record) => {
            return record.web_url ? (
              <p className="m-0">{record.web_url}</p>
            ) : (
              ""
            );
          },
        },
        {
          key: "custom_value_name",
          text: "Username",
          className: "",
          sortable: true,
          cell: (record) => {
            return record.custom_value_name ? (
              <p className="m-0">{record.custom_value_name}</p>
            ) : (
              ""
            );
          },
        },
        {
          key: "custom_type_name",
          text: "Password",
          className: "password_popup pass-show",
          sortable: true,
          cell: (record, index) => {
            return record.custom_type_name ? (
              <>
                <span
                  className={
                    record.custom_type_name !== "************"
                      ? "m-0 pass-show"
                      : "m-0"
                  }
                >
                  {record.custom_type_name}
                </span>
                {record.custom_type_name !== "************" ? (
                  <i
                    onClick={(e) => {
                      this.hidePassword(e, index);
                    }}
                    className="fa fa-eye-slash hand-cursor"
                  ></i>
                ) : (
                  <i
                    onClick={(e) => {
                      this.showPassword(e, record.slug, index);
                    }}
                    className="fa fa-eye hand-cursor"
                  ></i>
                )}
              </>
            ) : (
              ""
            );
          },
        },
        {
          key: "ref_table_id",
          text: "Type",
          className: "hand-cursor word_no_wrap",
          sortable: true,
          cell: (record) => {
            return record.ref_table_id ? (
              <Link
                to={
                  global.userPermissions.checkPermission(
                    "credentials-update"
                  ) && "credentials/update/" + record.slug
                }
                className=""
              >
                {record.ref_table_id === 3
                  ? "Properties"
                  : record.ref_table_id === 2
                  ? "Brands"
                  : record.ref_table_id === 1
                  ? "Company"
                  : record.ref_table_id === 26
                  ? "Clients"
                  : "Units"}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "ref_id",
          text: "Type Name",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.ref_id ? (
              <Link
                to={
                  global.userPermissions.checkPermission(
                    "credentials-update"
                  ) && "credentials/update/" + record.slug
                }
                className=""
              >
                {record && record.Typename ? record.Typename : ""}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "updated_at",
          text: "Last Updated On",
          className: "hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            var dateTimeConversion = Moment(
              Moment.utc(record.updated_at).toDate()
            )
              .local()
              .format(global.dateTimeFormat);
            var dateTime =
              record && record.updated_at ? dateTimeConversion : "";
            return record.updated_at ? (
              <Link
                to={
                  global.userPermissions.checkPermission(
                    "credentials-update"
                  ) && "credentials/update/" + record.slug
                }
                className=""
              >
                {record && record.updated_at ? dateTime : ""}
              </Link>
            ) : (
              ""
            );
          },
        },
      ],
    };
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.handleChangeRoleList = this.handleChangeRoleList.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getRoles();
  }

  handlePasswordInputFocus = () => {
    this.passwordInput.removeAttribute("readonly");
  };

  hidePassword = (e, index) => {
    this.setState({ reloadSection: false });
    const records = [...this.state.records];
    records[index].custom_type_name = "************";
    this.setState({ records, reloadSection: true });
  };

  showPassword = (e, slug, index) => {
    const { isPasswordModal } = this.state;

    if (isPasswordModal) {
      this.setState({
        isPasswordModal: false,
        custom_type_name: "************",
        errors: {},
      });
    } else {
      this.setState({
        slug,
        index,
        isPasswordModal: true,
        password: "",
        errors: {},
      });
    }
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  };

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var groupList = [];
    var totalRecords = 0;
    let res = await CredentailService.getAllCredential(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      groupList = res.data ? res.data.groupList : [];
      totalRecords = list ? res.data.total : 0;
    }

    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
      groupList: groupList,
    });
  }

  async getRoles(queryString = "") {
    this.setState({ showSpinner: true, loading: true });

    var RoleList = [];
    var RoleListTotal = 0;

    let res = await RolePermissionService.getRoles(queryString);
    if (global.successStatus.includes(res.status)) {
      RoleList = res.data ? res.data.data.data : [];
      RoleListTotal = RoleList ? RoleList.length : 0;
    }
    const newRoleList =
      RoleListTotal > 0
        ? RoleList.map((items) => ({
            value: items.slug,
            label: items.role_title,
          }))
        : [];

    this.setState({
      RoleList: newRoleList,
    });
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let StartDate = this.state.start_date
      ? Moment(this.state.start_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "custom_key_name=" +
      (this.state.custom_key_name ? this.state.custom_key_name : "") +
      "&custom_value_name=" +
      (this.state.custom_value_name ? this.state.custom_value_name : "") +
      "&custom_type_name=" +
      (this.state.custom_type_name ? this.state.custom_type_name : "") +
      "&type=" +
      (this.state.role_id ? this.state.role_id : "") +
      "&type_name=" +
      (this.state.type_name ? this.state.type_name : "") +
      "&created_at=" +
      StartDate +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.custom_key_name
      ? "Account - " + this.state.custom_key_name
      : "";
    moduleSearchString = this.state.custom_value_name
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Username - " +
          this.state.custom_value_name
        : " Username - " + this.state.custom_value_name
      : moduleSearchString;
    moduleSearchString = this.state.custom_type_name
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Password - " +
          this.state.custom_type_name
        : " Password - " + this.state.custom_type_name
      : moduleSearchString;
    moduleSearchString = this.state.role_id
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Type - " +
          this.state.selectedRoleList.label
        : " Type - " + this.state.selectedRoleList.label
      : moduleSearchString;
    moduleSearchString = this.state.type_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Type Name - " + this.state.type_name
        : " Type Name - " + this.state.type_name
      : moduleSearchString;
    moduleSearchString = this.state.start_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Date - " +
          Moment(this.state.start_date).format(global.dateFormat)
        : " Date - " + Moment(this.state.start_date).format(global.dateFormat)
      : moduleSearchString;

    if (
      this.state.custom_type_name ||
      this.state.custom_key_name ||
      this.state.custom_value_name ||
      this.state.role_id ||
      this.state.type_name ||
      this.state.start_date
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(queryString);
    }
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  handleChangeRoleList(value) {
    let role_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        role_id.push(item.value);
      });
    }
    this.setState({
      selectedRoleList: value,
      role_id: value.value,
    });
  }

  clearFilter() {
    this.setState({ is_search: 0 });
    this.setState({
      custom_type_name: "",
      custom_key_name: "",
      custom_value_name: "",
      selectedRoleList: "",
      role_id: "",
      type_name: "",
      start_date: "",
    });
    this.setState({ module_search: "" });
    this.getData();
  }

  rowClickedHandler = (event, data, rowIndex) => {
    global.userPermissions.checkPermission("credentials-update") &&
      this.props.history.push("/credentials/update/" + data.slug);
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input, errors: {} });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmit: true, reloadSection: false });
    try {
      const { slug, password, index, records } = this.state;
      const inputData = { slug, password };
      if (this.validator.allValid()) {
        const res = await CredentailService.fetchPass(inputData);

        if (global.successStatus.includes(res.status)) {
          const { custom_type_name } = res.data || {};
          if (res.message === "Unauthorized. Incorrect password.") {
            this.setState({
              errors: { password: res.message },
              isSubmit: false,
            });
          } else {
            const updatedRecords = [...records];
            updatedRecords[index].custom_type_name =
              custom_type_name || "************";
            this.setState({
              records: updatedRecords,
              isSubmit: false,
              errors: {},
              isPasswordModal: false,
              reloadSection: true,
            });
          }
        } else {
          if (
            res.data.result.errorDetail !== null &&
            res.data.result.errorDetail !== undefined
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        this.validator.showMessages();
      }
    } catch (err) {
      this.setState({
        errors: err.response?.data?.error || "An error occurred",
        isSubmit: false,
        custom_type_name: "************",
      });
    }
    this.setState({ isSubmit: false, reloadSection: true });
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,

        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}

        <Container className="innter-container pr-0 over-fl">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-12">
                <div className="scrolling-carousel">
                  <Container>
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Credentials</h3>
                          <div className="d-flex">
                            {global.userPermissions.checkPermission(
                              "credentials-add"
                            ) && (
                              <Link
                                to={"credentials/add"}
                                className="w-100 d-flex justify-content-end"
                              >
                                <button className="btn-success-outline-small ">
                                  Add
                                </button>
                              </Link>
                            )}
                            <button
                              onClick={this.searchToggle}
                              className="btn-success-outline-small ml-3"
                            >
                              <img src={filterIcon} alt="filterIcon" />{" "}
                              {this.state.isSearch === 1 ? "Search" : "Search"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                  <Container>
                    {this.state.is_search === 1 ? (
                      <>
                        <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel mg-t-20">
                          <div className="custm-filter pd-lg-t-0 pd-xs-t-15">
                            <div>
                              {" "}
                              <label className="form-label text-left pd-b-2 mb-0">
                                Search Applied On
                              </label>
                            </div>
                            <div className="filter_bg_cust">
                              {this.state.module_search.map((item, i) => {
                                return <span key={i}>{item}</span>;
                              })}
                              <button
                                onClick={this.clearFilter}
                                className="btn-fliter-clear"
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      {this.state.total_record > 0 ||
                      this.state.filter_value !== "" ? (
                        <div className="mg-t-20 brandList">
                          <ReactDatatable
                            className="table no-wrap mg-b-30 pd-b-2 brandtable querySupporttable table_last_row link_td_click_tbl"
                            config={
                              this.state.total_record > global.page_size
                                ? this.state.config
                                : this.state.config
                            }
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            // loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            // onRowClicked={this.rowClickedHandler}
                          />
                        </div>
                      ) : (
                        <NoData msg="credentials" />
                      )}
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Support</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Account</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="custom_key_name"
                      value={this.state.custom_key_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="start_date"
                      selected={this.state.start_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "start_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Username</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="custom_value_name"
                      value={this.state.custom_value_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
                {/* <InputGroup className="mt-3">
                  <label>Password</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="custom_type_name"
                      value={this.state.custom_type_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup> */}
                <InputGroup className="mt-3">
                  <label>Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedRoleList}
                      options={this.state.Moduledata}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeRoleList(value)}
                      defaultValue={this.state.selectedRoleList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.role_id == 3 ||
                this.state.role_id == 5 ||
                this.state.role_id == 2 ||
                this.state.role_id == 1 ||
                this.state.role_id == 26 ? (
                  <InputGroup className="mt-3">
                    <label>Type Name</label>
                    <div className="col-md-12 p-0">
                      <Form.Control
                        onChange={this.changeHandler}
                        name="type_name"
                        value={this.state.type_name}
                        className="form-control max_width_100"
                        id="inlineFormInput"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                        // placeholder="Amenity Name"
                      />
                    </div>
                  </InputGroup>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.isPasswordModal}
          centered
          className="modal-xs agreementdocModal"
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div>
              <span
                className="welcomeClose"
                onClick={() => this.showPassword()}
              >
                {global.closee}
              </span>
              <h3 className="text-center">Password</h3>
              <div>
                {!this.state.custom_type_name ||
                this.state.custom_type_name === "************" ? (
                  <>
                    <div className="remove-read-only">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.changeHandler}
                        required
                        ref={(input) => (this.passwordInput = input)}
                        onFocus={this.handlePasswordInputFocus}
                        readOnly
                      />
                      {this.state.errors.password ? (
                        <div className="text-danger">
                          {this.state.errors.password}
                        </div>
                      ) : (
                        this.validator.message(
                          "password",
                          this.state.password,
                          "password|required",
                          { className: "text-danger" }
                        )
                      )}
                    </div>
                    <Button
                      className="m-0 btn btn btn-success btn-block btn-pass"
                      onClick={this.handleSubmit}
                      disabled={this.state.isSubmit}
                    >
                      Verify Password
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default Credentails;

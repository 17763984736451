////////////////////////////////////////////////////////////
//     							                                      //
//  Program: EventsView.jsx                                //
//  Application:  Events                                   //
//  Option: For view  Events details                      //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Moment from "moment";
import { Container, Modal } from "react-bootstrap";
import EventsService from "../../services/EventsService";
import eventImage from "./../../assets/images/eventimg_placeholder.png";
// import renderHTML from "react-render-html";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import PropertySidebar from "../common/PropertySidebar";
// import ShowMoreText from "react-show-more-text";
export class EventsView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      event_name: "",
      event_location: "",
      event_date: "",
      start_time: "",
      end_time: "",
      description: "",
      pricing_information: "",
      sort_order: "",
      pathname: window.location.pathname,
      deletedFileList: [],
      event_image: [],
      eventTypeList: [],
      imageUrl: eventImage,
      descShow: 0,
      descShowPricing: 0,
    };
    this.eventImage = React.createRef();
    this.fullText = this.fullText.bind(this);
    this.fullTextPricing = this.fullTextPricing.bind(this);
    this.deleteEvents = this.deleteEvents.bind(this);
    this.opendeleteEventsModal = this.opendeleteEventsModal.bind(this);
    this.closedeleteEventsModal = this.closedeleteEventsModal.bind(this);
  }

  opendeleteEventsModal(slug) {
    this.setState({ deleteEventsModal: true, slug: slug });
  }

  closedeleteEventsModal() {
    this.setState({ deleteEventsModal: false, slug: "" });
  }

  async deleteEvents(slug) {
    this.setState({ showSpinner: true });
    let res = await EventsService.deleteEvents(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteEventsModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/events"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteEventsModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await EventsService.getEventsBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.event_name = res.data.event_name ? res.data.event_name : "";
        resData.event_type_slug = res.data.event_type.slug
          ? res.data.event_type.slug
          : "";
        resData.event_location = res.data.event_location
          ? res.data.event_location
          : "";
        resData.event_date = res.data.event_date
          ? Moment(res.data.event_date, "MM/DD/YYYY").format(global.dateFormat)
          : null;
        resData.property = res.data.property
          ? res.data.property.property_name
          : "";
        resData.start_time = res.data.start_time ? res.data.start_time : "";
        resData.end_time = res.data.end_time ? res.data.end_time : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.event_image = res.data.event_image ? res.data.event_image : "";
        resData.imageUrl = res.data.event_image_thumb_url
          ? res.data.event_image_thumb_url
          : eventImage;
        resData.pricing_information = res.data.pricing_information
          ? res.data.pricing_information
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data ? res.data.status_id : "";
      }
      this.setState(resData);
      this.setState({ input: resData });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  getTitle() {
    return "Events";
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextPricing() {
    if (this.state.descShowPricing === 0) {
      this.setState({ descShowPricing: 1 });
    } else {
      this.setState({ descShowPricing: 0 });
    }
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                          {/* <button
                                        type="button"
                                        onClick={() => {
                                          this.opendeleteEventsModal(this.state.slug);
                                        }}
                                        className="btn-danger-outline mg-r-20"
                                      >
                                        <img src={deleteicon} alt="Delete" />
                                        Delete
                                      </button>{" "} */}
                          {global.userPermissions.checkPermission(
                            "events-delete"
                          ) && (
                            <span
                              onClick={() => {
                                this.opendeleteEventsModal(this.state.slug);
                              }}
                              className="deleteicon mg-r-20"
                            >
                              <img src={deleteicon} />
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {global.userPermissions.checkPermission(
                        "events-update"
                      ) && (
                        <Link
                          to={
                            "/properties/view/" +
                            this.state.property_slug +
                            "/events/update/" +
                            this.state.slug
                          }
                          className="mg-l-2"
                        >
                          <button className="btn-success-outline-small">
                            <img src={editIcon} alt="Edit" />
                            Edit
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                      <h3 className="mg-l-0 background-head">Basic</h3>
                    </div>
                    <div className="row align-items-start col-md-12- mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 mg-b-20 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15">
                      <div className="col-12 col-md-2">
                        <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                          <div className="az-img-user avatar-md online mg-b-8">
                            <figure>
                              <b
                                className={
                                  this.state.status_id === 1
                                    ? "status-active"
                                    : "status-inactive"
                                }
                              ></b>
                              <img
                                src={
                                  this.state.imageUrl
                                    ? this.state.imageUrl
                                    : eventImage
                                }
                                alt="Basic Event Image"
                                className="rounded-circle"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-10">
                        <div className="row col-md-12- mg-t-40 mg-md-t-0 mg-xs-t-20">
                          <div className="col-md-6">
                            <p className="az-profile-name-text pl-0">Name</p>
                            <p className="media-body">
                              {this.state.event_name
                                ? this.state.event_name
                                : ""}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p className="az-profile-name-text pl-0">
                              Property
                            </p>
                            <p className="media-body">
                              {this.state.property ? this.state.property : ""}
                            </p>
                          </div>

                          <div className="col-md-6">
                            <p className="az-profile-name-text pl-0">
                              Event Location
                            </p>
                            <p className="media-body">
                              {this.state.event_location
                                ? this.state.event_location
                                : ""}
                            </p>
                          </div>

                          <div className="col-md-6">
                            <p className="az-profile-name-text pl-0">
                              Event Date
                            </p>
                            <p className="media-body">
                              {this.state.event_date
                                ? this.state.event_date
                                : ""}
                            </p>
                          </div>

                          <div className="col-md-6">
                            <p className="az-profile-name-text pl-0">
                              Start Time
                            </p>
                            <p className="media-body">
                              {this.state.start_time
                                ? this.state.start_time
                                : ""}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p className="az-profile-name-text pl-0">
                              End Time
                            </p>
                            <p className="media-body">
                              {this.state.end_time ? this.state.end_time : ""}
                            </p>
                          </div>

                          <div className="col-md-12">
                            <p className="az-profile-name-text pl-0">
                              Description
                            </p>
                            <div className="para mt-0">
                              {this.state.description ? (
                                <p className="p_wrap1">
                                  {this.state.descShow
                                    ? this.state.description.slice(
                                        0,
                                        global.showMoreLength
                                      )
                                    : this.state.description}

                                  {this.state.description.length >
                                    global.showMoreLength && (
                                    <span onClick={this.fullText}>
                                      {this.state.descShow ? (
                                        <p className="hand-cursor showmore">
                                          ...Show more{" "}
                                        </p>
                                      ) : (
                                        <p className="hand-cursor showmore">
                                          Show Less{" "}
                                        </p>
                                      )}
                                    </span>
                                  )}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <p className="az-profile-name-text pl-0">Pricing</p>
                            <div className="para">
                              {this.state.pricing_information ? (
                                <p className="p_wrap1">
                                  {this.state.descShow
                                    ? this.state.pricing_information.slice(
                                        0,
                                        global.showMoreLength
                                      )
                                    : this.state.pricing_information}

                                  {this.state.pricing_information.length >
                                    global.showMoreLength && (
                                    <span onClick={this.fullText}>
                                      {this.state.descShow ? (
                                        <p className="hand-cursor showmore">
                                          ...Show more{" "}
                                        </p>
                                      ) : (
                                        <p className="hand-cursor showmore">
                                          Show Less{" "}
                                        </p>
                                      )}
                                    </span>
                                  )}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteEventsModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteEventsModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteEventsModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteEvents(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default EventsView;

////////////////////////////////////////////////////////////
//                                                        //
//  Program: CorporateApp.jsx                             //
//  Application:  CorporateApp                            //
//  Option: listing CorporateApp                          //
//  Developer: Raj Kumar                                  //
//  Date: 2023-03-23                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import CorporateAppService from "../../services/CorporateAppService";
import { Link } from "react-router-dom";
import { Container, Nav, Tab, Modal, InputGroup, Form } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import NoData from "../common/NoData";
import filterIcon from "./../../assets/images/filter-gray.svg";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import CMSSidebar from "../common/CMSSidebar";
import Select from "react-select";

export class CorporateApp extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      module_search: "",
      is_search: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      searchCorporateAppModal: false,
      loading: false,
      showSpinner: false,
      total_record: 0,
      url: "",
      title: "",
      internal_notes: "",
      private_content: "",
      description: "",
      isSubmit: false,
      records: [],
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      privateContentOption: [
        { label: "Yes", value: 1 },
        { label: "No", value: 2 },
      ],
      selectedStatusList: [],
      selectedModulename: [{ label: "Miscellaneous", value: 2 }],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "title",
          text: "Title",
          className: "title hand-cursor",
          sortable: false,
          cell: (record) => {
            let return_url = this.rowClickedHandler(record);
            return record.title ? (
              <Link to={return_url} target="_blank">
                {record.title}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "article_category",
          text: "Article Category",
          className: "article_category hand-cursor",
          sortable: true,
          cell: (record) => {
            let return_url = this.rowClickedHandler(record);
            return record.kb_category !== null ? (
              <Link to={return_url} target="_blank">
                {record.kb_category.kb_category_name}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "property_name",
          text: "Property",
          className: "property_name hand-cursor",
          sortable: false,
          cell: (record) => {
            let return_url = this.rowClickedHandler(record);
            return record.property_name ? (
              <Link to={return_url} target="_blank">
                {record.property_name}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "url_title",
          text: "URL Title",
          className: "slug_title hand-cursor",
          sortable: true,
          cell: (record) => {
            let return_url = this.rowClickedHandler(record);
            return record.slug_title !== null ? (
              <Link to={return_url} target="_blank">
                {record.slug_title}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "module_type",
          text: "Module Name",
          className: "slug_title hand-cursor",
          sortable: true,
          cell: (record) => {
            let return_url = this.rowClickedHandler(record);
            let module_type_name = "";
            if (record.module_type !== null) {
              const module = global.cmsModuleName.find(
                (item) => item.value === record.module_type
              );
              if (module) {
                module_type_name = module.label;
              }
            }
            return record.module_type !== null ? (
              <Link to={return_url} target="_blank">
                {module_type_name}
              </Link>
            ) : (
              ""
            );
          },
        },

        {
          key: "status_id",
          text: "Status",
          className: "status_id",
          sortable: false,
          cell: (record) => {
            let return_url = this.rowClickedHandler(record);
            return record.status_id === 1 ? (
              <Link to={return_url} target="_blank">
                {<b className="status-active"></b>}
              </Link>
            ) : (
              <b className="status-inactive"></b>
            );
          },
        },
      ],
      tab: "active",
      clientTabData: localStorage.getItem("client_tab_data")
        ? localStorage.getItem("client_tab_data")
        : "",
      activeClass: "activeTab",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.opensearchCorporateAppModal =
      this.opensearchCorporateAppModal.bind(this);
    this.searchCorporateApp = this.searchCorporateApp.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  componentDidMount() {
    this.getArticleCategory();
    switch (this.state.clientTabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
  }

  //get article category
  async getArticleCategory() {
    let category = [];
    let categoryTotal = 0;
    let res = await CorporateAppService.getArticleCategory();

    if (global.successStatus.includes(res.status)) {
      category = res.data ? res.data : {};
      categoryTotal = category ? category.length : 0;
    }

    const kb_category_name = category.map((item) => ({
      label: item.kb_category_name,
      value: item.kb_category_id,
    }));

    this.setState({
      category: category,
      categoryTotal: categoryTotal,
      kb_category_name: kb_category_name,
    });
  }

  /*for corporate app search modal */
  opensearchCorporateAppModal() {
    this.setState({ searchCorporateAppModal: true });
  }

  closesearchCorporateAppModal() {
    this.setState({ searchCorporateAppModal: false });
    this.validator.hideMessages();
  }

  /* to search corporate data */
  searchCorporateApp = async (e) => {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ isSubmit: true });
    var amenityList = [];

    e.preventDefault();
    let inputData =
      "title=" +
      this.state.title +
      "&" +
      "internal_notes=" +
      this.state.internal_notes +
      "&" +
      "url=" +
      this.state.url +
      "&" +
      "private_content=" +
      (this.state.privateContentSlug ? this.state.privateContentSlug : "") +
      "&" +
      "category=" +
      (this.state.privateCategorySlug ? this.state.privateCategorySlug : "") +
      "&" +
      "module_name=" +
      (this.state.selectedModulename
        ? this.state.selectedModulename.value
        : "") +
      "&" +
      "tab=" +
      this.state.tab;

    let res = await CorporateAppService.getCorporateApp(inputData);
    let totalRecords = 0;
    var moduleSearchString = "";

    moduleSearchString = this.state.title
      ? "Title - " + this.state.title
      : moduleSearchString;

    moduleSearchString = this.state.internal_notes
      ? "Internal Notes - " + this.state.internal_notes
      : moduleSearchString;

    moduleSearchString = this.state.url
      ? moduleSearchString
        ? moduleSearchString + " ," + " URL - " + this.state.url
        : " URL - " + this.state.url
      : moduleSearchString;

    moduleSearchString =
      this.state.privateContentSlug && this.state.selectedPrivateContent.label
        ? moduleSearchString
          ? moduleSearchString +
            " ," +
            " Private Content - " +
            this.state.selectedPrivateContent.label
          : " Private Content - " + this.state.selectedPrivateContent.label
        : moduleSearchString;

    moduleSearchString =
      this.state.privateCategorySlug && this.state.selectedCategory.label
        ? moduleSearchString
          ? moduleSearchString +
            " ," +
            " Category - " +
            this.state.selectedCategory.label
          : " Category - " + this.state.selectedCategory.label
        : moduleSearchString;

    moduleSearchString =
      this.state.selectedModulename && this.state.selectedModulename.label
        ? moduleSearchString
          ? moduleSearchString +
            " ," +
            " Module Name - " +
            this.state.selectedModulename.label
          : " Module Name - " + this.state.selectedModulename.label
        : moduleSearchString;
    if (
      this.state.title ||
      this.state.internal_notes ||
      this.state.url ||
      this.state.privateContentSlug ||
      this.state.privateCategorySlug ||
      this.state.selectedModulename
    ) {
      this.setState({ module_search: moduleSearchString.split(",") });
    }

    if (global.successStatus.includes(res.status)) {
      amenityList = res.data.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      records: amenityList,
      total_record: totalRecords,
    });
    this.setState({ isSubmit: false });
    this.setState({ showSpinner: false, loading: false, is_search: 1 });
    this.setState({ searchCorporateAppModal: false });
  };

  clearFilter() {
    this.setState({
      module_search: "",
      is_search: 0,
      selectedPrivateContent: "",
      selectedCategory: "",
      title: "",
      url: "",
      internal_notes: "",
      privateContentSlug: "",
      privateCategorySlug: "",
      selectedModulename: [],
    });
    this.getData("tab=" + this.state.tab);
  }

  handleChangePrivateContent(value) {
    let privateContentSlug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        privateContentSlug.push(item.value);
      });
    }
    const privateContentSlugValue = value !== null ? value.value : [];
    this.setState({
      selectedPrivateContent: value,
      privateContentSlug: privateContentSlugValue,
    });
  }

  handleChangeCategory(value) {
    let privateCategorySlug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        privateCategorySlug.push(item.value);
      });
    }
    this.setState({
      selectedCategory: value,
      privateCategorySlug: value.value,
    });
  }

  handleModuleName(value) {
    let moduleName = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        moduleName.push(item.value);
      });
    }
    this.setState({
      selectedModulename: value,
      module_name: value.value,
    });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "client_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "client_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await CorporateAppService.getCorporateApp(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  rowClickedHandler = (data) => {
    return data.slug ? "/corporate-apps/view/" + data.slug : "/corporate-apps";
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <div>
          <main>
            <Container className="innter-container">
              <div className="d-flex flex-column">
                <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <aside>
                        <CMSSidebar />
                      </aside>
                    </div>
                  </div>
                  <div className="col-md-10 right">
                    <div className="scrolling-carousel">
                      <>
                        <div className="col-md-12 mg-t-20 mg-b-10"></div>
                        <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                          <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                            <h3 className="brnd_heading mb-0">
                              Corporate Apps
                            </h3>
                            {global.userPermissions.checkPermission(
                              "app-cms-add"
                            ) && (
                              <div className="d-flex flex-wrap">
                                <Link to={"/corporate-apps/add"}>
                                  <button className="btn-success-outline mg-r-20 mr-3">
                                    <img src={pluswIcon} alt="Add" /> Add
                                  </button>
                                </Link>
                                <button
                                  onClick={this.opensearchCorporateAppModal}
                                  className="btn-success-outline-small"
                                >
                                  <img src={filterIcon} alt="filterIcon" />{" "}
                                  {this.state.isSearch === 1
                                    ? "Search"
                                    : "Search"}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                              <div className="custm-filter pd-t-20">
                                <div>
                                  {" "}
                                  <label className="form-label text-left pd-b-2 mb-0">
                                    Search Applied On
                                  </label>
                                </div>
                                <div className="filter_bg_cust">
                                  {this.state.module_search.map((item, i) => {
                                    return <span key={i}>{item}</span>;
                                  })}
                                  <button
                                    onClick={this.clearFilter}
                                    className="btn-fliter-clear"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                      <>
                        <div className="scrolling-carousel pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-0 scroll-slide">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="all"
                            onSelect={(e) => this.getActiveClass(e)}
                            eventKey={this.state.activeClass}
                            className="pd-r-60"
                          >
                            <ScrollingCarousel
                              rightArrow={true}
                              leftArrow={true}
                              className="swipesection pd-t-20"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="activeTab"
                                  id="activeTab"
                                  className={
                                    this.state.activeClass === "activeTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Active
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item>
                                <Nav.Link
                                  eventKey="inactiveTab"
                                  id="inactiveTab"
                                  className={
                                    this.state.activeClass === "inactiveTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Inactive
                                </Nav.Link>
                              </Nav.Item>
                            </ScrollingCarousel>

                            <Tab.Content>
                              <Tab.Pane eventKey="activeTab">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-20 brandList">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable coporate_tbl_res"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        // loading={this.state.loading}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        // onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  ) : (
                                    <NoData msg="active corporate apps" />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="inactiveTab">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-20 brandList">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable coporate_tbl_res"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        // loading={this.state.loading}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        // onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  ) : (
                                    <NoData msg="inactive corporate apps" />
                                  )}
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </main>
        </div>
        {/* Module search popup modal */}
        <Modal
          className="modal-large serch_coperate_modal"
          show={this.state.searchCorporateAppModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without Searching?"))
              this.closesearchCorporateAppModal();
          }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closesearchCorporateAppModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Search Corporate Apps</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Title</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="title"
                    value={this.state.title}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    placeholder="Title"
                  />
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Internal Notes</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="internal_notes"
                    value={this.state.internal_notes}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    placeholder="Internal Notes"
                  />
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Private Content</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedPrivateContent}
                      options={this.state.privateContentOption}
                      onChange={(value) =>
                        this.handleChangePrivateContent(value)
                      }
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Category</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedCategory}
                      options={this.state.kb_category_name}
                      onChange={(value) => this.handleChangeCategory(value)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Module Name</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      options={global.cmsModuleName}
                      onChange={(value) => this.handleModuleName(value)}
                      value={this.state.selectedModulename}
                      defaultValue={this.state.selectedModulename}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>URL</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="url"
                      value={this.state.url}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      placeholder="Url"
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closesearchCorporateAppModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.searchCorporateApp}
                disabled={
                  this.state.isSubmit ||
                  (this.state.title === "" &&
                    this.state.internal_notes === "" &&
                    this.state.url === "" &&
                    !this.state.selectedPrivateContent &&
                    !this.state.selectedCategory &&
                    !this.state.selectedModulename)
                    ? true
                    : false
                }
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default CorporateApp;

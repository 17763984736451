////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ReservationForm.jsx                                //
//  Application:  Reservation                                   //
//  Option: For add or update Reservation                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-04-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";

import { Container, Form } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Alerts from "../../common/Alerts";
import Moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import LatchService from "../../../services/LatchService";
import ListingService from "../../../services/ListingService";
// import GuestService from "../../../services/GuestService";
import KeycafeService from "../../../services/KeycafeService";
import Select from "react-select";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
export class ActivityAddLatch extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      open: true,
      eventKey: "3",
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      latch_access_slug: this.props.match.params.slug,
      isSubmit: false,
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      lisitngList: [],
      newListingList: [],
      selectedListingList: [],
      status_id: [],
      set_channel: "",
      pathname: window.location.pathname,
      importModal: false,
      confirm_back: 0,
      activeClass: "basic",
      confirmation_code: "",
      unit_slug: [],
      guest_slug: [],
      guestList: [],
      newGuestList: [],
      selectedGuestList: [],
      adults: "",
      children: "",
      infants: "",
      pets: "",
      nightly_rate: "",
      total_stay_nights: 0,
      guest_paid: "",
      host_payout: "",
      pet_fee: "",
      security_deposite: "",
      isGuestExist: 0,
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      end_date: "",
      email: "",
      email_slug: "",
      checkin_time: "",
      checkout_time: "",
      unit_name: "",
      checkin_date: "",
      checkout_date: "",
      access_code: "",
      first_name: "",
      last_name: "",
      keycafe_key_id: "",
      key_type: "",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandler1 = this.changeHandler1.bind(this);
    // this.saveOrUpdateKey = this.saveOrUpdateKey.bind(this);
    // this.timeFormat = this.timeFormat.bind(this);
  }

  changeHandler = (event) => {
    let input = this.state.input;
    console.log("input------",input);

    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  changeHandler1 = (event) => {
    let input = this.state.input;
    // console.log("email", input.email);
    this.setState({ email: input.email });
    this.getEmailData(this.state.email);
  };

  async componentDidMount() {
    this.getEmailData();
    this.getLatchMappedUnits();
    this.getData();
  }

  /* to get slug  on behalf email ID */
  async getEmailData(email) {
    // this.setState({ showSpinner: true, loading: true });
    
    
    var emailList = [];
    var unitListTotal = 0;
    let res = await KeycafeService.UserEmaildetail(
      "email=" + email
      // queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      if (res.data) {
        emailList = res.data ? res.data : {};
        
        // emailList.first_name =  emailList.first_name ?  emailList.first_name : "";
        // console.log("emails", emailList.slug);
        unitListTotal = emailList ? emailList.length : 0;
        this.setState({
          email_slug: emailList.slug,
          unitListTotal: unitListTotal,
        });
        this.setState(emailList)
        
      }
    }

    // this.setState({ showSpinner: false, loading: false });
  }

  async getData(tab, queryString = "") {
    if (this.state.latch_access_slug !== undefined) {
      var resData = {};
      var totalRecords = 0;
      let res = await LatchService.getLatchAccessById(
        "property_slug=" +
          this.state.property_slug +
          "&latch_access_slug=" +
          this.state.latch_access_slug +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          (queryString ? "&" + queryString : "")
      );
      if (global.successStatus.includes(res.status)) {
        resData.unit_name = res.data ? res.data.unit_name : "";
        resData.unit_number = res.data ? res.data.unit_number : "";
        resData.unit_slug = res.data ? res.data.unit_slug : "";
        resData.checkin_date = res.data
          ? res.data.checkin_date !== null ? Moment(res.data.checkin_date).toDate(global.dateFormat) :""
          : "";
        resData.checkout_date = res.data
          ?  res.data.checkout_date !== null ? Moment(res.data.checkout_date).toDate(global.dateFormat) : ""
          : "";
        resData.email = res.data ? res.data.email : "";
        resData.access_code = res.data ? res.data.access_code : "";
        resData.first_name = res.data ? res.data.first_name : "";
        resData.last_name = res.data ? res.data.last_name : "";
       
        resData.checkin_time = res.data
          ? res.data.checkin_time !== null ? Moment(res.data.checkin_time).format("hh:mm A") : ""
          : "";
        resData.checkout_time = res.data
          ? res.data.checkout_time !== null ? Moment(res.data.checkout_time).format("hh:mm A") : ""
          : "";
        resData.key_type = res.data ? res.data.key_type : "";
        // resData.checkout_time = resData.checkout_time ? Moment(resData.checkout_time, "hh").format('LT') : "";
        var selectedUnitList = [];
        selectedUnitList = resData.unit_name
          ? [
              {
                value: resData.unit_slug ? resData.unit_slug : "",
                label: resData.unit_name
                  ? resData.unit_name
                  : resData.unit_number,
              },
            ]
          : "";
      }
      this.setState(resData);

      this.setState({
        // selectedUnitList: value,
        // unit_number: value.label,
        total_record: totalRecords,
        records: resData,
        isLoaded: true,
        unit_name: resData.unit_name ? resData.unit_name : "",
        checkin_date: resData.checkin_date
          ? resData.checkin_date
          : "",
        checkout_date: resData.checkout_date ? resData.checkout_date : "",
        email: resData.email ? resData.email : "",
        access_code: resData.access_code ? resData.access_code : "",
        first_name: resData.first_name ? resData.first_name : "",
        last_name: resData.last_name ? resData.last_name : "",
        keycafe_key_id: resData.keycafe_key_id ? resData.keycafe_key_id : "",
        fob: resData.fob ? resData.fob : "",
        checkin_time: resData.checkin_time
          ? resData.checkin_time
          : "",
        checkout_time: resData.checkout_time ? resData.checkout_time : "",
        selectedUnitList: selectedUnitList,
      });
    }
  }

  async timeFormat(time) {
    // let timess = '';
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
  }

  userData = async () => {
    if (this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        // checkin_date: this.state.checkin_date
        //   ? Moment(this.state.checkin_date).toDate(global.dateFormat)
        //   : "",
        // checkout_date: this.state.checkout_date
        //   ? Moment(this.state.checkout_date).toDate(global.dateFormat)
        //   : "",
        checkin_date: this.state.checkin_date
          ? Moment(this.state.checkin_date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : "",
        checkout_date: this.state.checkout_date
          ? Moment(this.state.checkout_date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : "",
        checkin_time: this.state.checkin_time !== null ? this.state.checkin_time : "" ,
        checkout_time: this.state.checkout_time!== null ? this.state.checkout_time : "",
        property_slug: this.state.property_slug,
        email: this.state.email ? this.state.email : "",
        unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
        // convertedTime : Moment(this.state.state.end_time, 'hh:mm A').format('HH:mm'),
        user_slug: this.state.email_slug ? this.state.email_slug : 0,
        first_name: this.state.first_name ? this.state.first_name : "",
        last_name: this.state.last_name ? this.state.last_name : "",
        from_where: 4,
        latch_access_slug:this.state.latch_access_slug,
      };
      if (this.state.latch_access_slug !== undefined) {
        let res = await LatchService.createlatchmanualaccess(
          inputData
          // this.state.latch_access_slug,
        );
        // this.state.slug,inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" + this.state.property_slug + "/keys"
                  : "/keys"
              ),
            global.redirect_time
          );
        } else {
          if (res.data) {
            var mesgs = res.data.message
              ? res.data.message
              : "Validation Failed";
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: "error",
              alertModalMessage: mesgs,
            });
          }
        }
      } else {
        let res = await LatchService.createlatchmanualaccess(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" + this.state.property_slug + "/keys"
                  : "/keys"
              ),
            global.redirect_time
          );
        } else {
          if (res.data) {
            var mesg = res.data.message
              ? res.data.message
              : "Validation Failed";
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: "error",
              alertModalMessage: mesg,
            });
          }
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get unit data list */
  async getLatchMappedUnits(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await LatchService.getLatchMappedUnits(
      "property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get listing data list */
  async getListingData(queryString = "") {
    var listingList = [];
    var listingListTotal = 0;

    let res = await ListingService.getListingList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      listingList = res.data ? res.data : [];
      listingListTotal = listingList ? listingList.length : 0;
    }
    const newListingList =
      listingListTotal > 0
        ? listingList.map(({ slug, listing_name, unit }) => ({
            value: slug,
            label: unit
              ? listing_name + (unit ? " (" + unit.unit_number + ") " : "")
              : listing_name,
          }))
        : [];
    this.setState({
      listingList: listingList,
      listingListTotal: listingListTotal,
      newListingList: newListingList,
    });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_number: value.label,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    const status_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: status_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeChannel(value) {
    let set_channel = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        set_channel.push(item.value);
      });
    }
    const set_channel_value = value !== null ? value.value : [];
    this.setState({
      selectedChannelList: value,
      set_channel: set_channel_value,
    });
    this.setState({ confirm_back: 1 });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.latch_access_slug !== undefined) {
      return "Update Access";
    } else {
      return "Add Access";
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    {console.log("time check ",this.state.checkout_time)}
                    <div className="d-flex justify-content-end">
                      {this.state.slug !== undefined ? (
                        <button
                          type="button"
                          className="btn-success-outline-small"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/keys"
                                    : "/keys/activity-view/" +
                                        this.state.property_slug +
                                        "/" +
                                        this.state.slug
                                );
                              else return false;
                            else
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/keys"
                                  : "/keys"
                              );
                          }}
                        >
                          Cancel
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn-success-outline-small"
                          onClick={() => {
                            if (this.state.confirm_back === 1)
                              if (
                                window.confirm(
                                  "Do you want to exit without saving?"
                                )
                              )
                                this.props.history.push(
                                  this.props.match.params.propertySlug !==
                                    undefined
                                    ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/keys"
                                    : "/keys" 
                                );
                              else return false;
                            else
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/keys"
                                  : "/keys"
                              );
                          }}
                        >
                          Cancel
                        </button>
                      )}

                      {
                        <button
                          type="button"
                          className="btn-success ml-3"
                          onClick={this.userData}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          {this.state.isSubmit
                            ? global.loader
                            : this.state.latch_access_slug !== undefined
                            ? "Update"
                            : "Save"}
                        </button>
                      }
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-lg-l-15 pd-sm-l-30 pd-sm-r-30 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="reservation_form">
                      <div className="m-0">
                        <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Unit
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedUnitList}
                              options={this.state.newUnitList}
                              onChange={(value) =>
                                this.handleChangeUnitList(value)
                              }
                              defaultValue={this.state.selectedUnitList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />

                              {this.state.errors.unit ? (
                                <div className="text-danger">
                                  {this.state.errors.unit}
                                </div>
                              ) : (
                                this.validator.message(
                                  "unit",
                                  this.state.selectedUnitList,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Start Time
                            </label>

                            <div className="col-6 col-md-8 col-lg-8 col-xl-8 col-xxl-8 p-0 float-left">
                              <DatePicker
                                className="form-control max_width_100"
                                disabledKeyboardNavigation
                                strictParsing
                                scrollableYearDropdown
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="checkin_date"
                                excludeDates={this.state.disabled_dates}
                                dateFormat="MM/dd/yy"                                
                                minDate={new Date()}
                                selected={this.state.checkin_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "checkin_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                              {/* {this.state.errors.checkin_date ? (
                                <div className="text-danger">
                                  {this.state.errors.checkin_date}
                                </div>
                              ) : (
                                this.validator.message(
                                  "start_date",
                                  this.state.checkin_date,
                                  "checkin_date|required",
                                  { className: "text-danger" }
                                )
                              )} */}
                              {this.state.errors.checkin_date || this.state.errors.checkin_time ? (
                                <div className="text-danger">
                                  {this.state.errors.checkin_date || "The start date"}{" "}
                                  {this.state.errors.checkin_time ? "and" : "field"}{" "}
                                  {this.state.errors.checkin_time || "the start time"} is required.
                                </div>
                              ) : (
                                this.validator.message(
                                  "start_date",
                                  this.state.checkin_date,
                                  "checkin_date|required",
                                  { className: "text-danger" }
                                ) || (
                                  this.validator.message(
                                    "start_time",
                                    this.state.checkin_time,
                                    "checkin_time|required",
                                    { className: "text-danger" }
                                  )
                                )
                              )}
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 p-0 float-left">
                              <InputMask
                                name="checkin_time"
                                onChange={this.changeHandler}
                                style={{
                                  textTransform: "uppercase",
                                  display: "inline",
                                }}
                                value={
                                  this.state.checkin_time !== ""
                                    ? this.state.checkin_time 
                                    : "00:00AM"
                                }
                                mask="99:99 aa"
                              />
                               {/* {this.state.errors.checkin_time ? (
                                <div className="text-danger">
                                  {this.state.errors.checkin_time}
                                </div>
                              ) : (
                                this.validator.message(
                                  "start_time",
                                  this.state.checkin_time,
                                  "checkin_time|required",
                                  { className: "text-danger" }
                                )
                              )} */}
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              End Time
                            </label>
                            <div className="col-6 col-md-8 col-lg-8 col-xl-8 col-xxl-8 p-0 float-left">
                              <DatePicker
                                className="form-control max_width_100"
                                disabledKeyboardNavigation
                                strictParsing
                                scrollableYearDropdown
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                // minDate={Moment().toDate()}
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                minDate={
                                  this.state.checkout_date
                                    ? this.state.checkout_date
                                      ? new Date(
                                          this.state.checkout_date
                                        ).setDate(
                                          new Date(
                                            this.state.checkout_date
                                          ).getDate()
                                        )
                                      : new Date()
                                    : new Date()
                                }
                                name="checkout_date"
                                excludeDates={this.state.disabled_dates}
                                dateFormat="MM/dd/yy"
                                selected={this.state.checkout_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "checkout_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                              {/* {this.state.errors.checkout_date ? (
                                <div className="text-danger">
                                  {this.state.errors.checkout_date}
                                </div>
                              ) : (
                                this.validator.message(
                                  "end_date",
                                  this.state.checkout_date,
                                  "checkout_date|required",
                                  { className: "text-danger" }
                                )
                              )} */}
                              {this.state.errors.checkout_date || this.state.errors.checkout_time ? (
                                <div className="text-danger">
                                  {this.state.errors.checkout_date || "The end date"}{" "}
                                  {this.state.errors.checkout_time ? "and" : "field"}{" "}
                                  {this.state.errors.checkout_time || "the end time"} is required.
                                </div>
                              ) : (
                                this.validator.message(
                                  "end_date",
                                  this.state.checkout_date,
                                  "checkout_date|required",
                                  { className: "text-danger" }
                                ) || (
                                  this.validator.message(
                                    "end_time",
                                    this.state.checkout_time,
                                    "checkout_time|required",
                                    { className: "text-danger" }
                                  )
                                )
                              )}
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 p-0 float-left">
                              <InputMask
                                name="checkout_time"
                                onChange={this.changeHandler}
                                style={{ textTransform: "uppercase" }}
                                value={
                                  this.state.checkout_time !== ""
                                    ? this.state.checkout_time 
                                    : "00:00AM"
                                }
                                mask="99:99 aa"
                              />
                              {/* {this.state.errors.checkout_time ? (
                                <div className="text-danger">
                                  {this.state.errors.checkout_time}
                                </div>
                              ) : (
                                this.validator.message(
                                  "end_time",
                                  this.state.checkout_time,
                                  "checkout_time|required",
                                  { className: "text-danger" }
                                )
                              )} */}
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Email
                            </label>

                            <Form.Control
                              type="email"
                              onBlur={this.changeHandler1}
                              onChange={this.changeHandler}
                              name="email"
                              value={this.state.email || ""}
                              maxLength="50"
                            />
                            {this.state.errors.first_name ? (
                                <div className="text-danger">
                                  {this.state.errors.email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "email",
                                  this.state.email,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                          </div>

                          {this.state.slug !== undefined ? (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                              First Name
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="first_name"
                                value={this.state.first_name || ""}
                                maxLength="50"
                              />
                              {this.state.errors.first_name ? (
                                <div className="text-danger">
                                  {this.state.errors.first_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "first_name",
                                  this.state.first_name,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          ) : (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                              First Name
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="first_name"
                                value={this.state.first_name || ""}
                                maxLength="50"
                              />
                              {this.state.errors.first_name ? (
                                <div className="text-danger">
                                  {this.state.errors.first_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "first_name",
                                  this.state.first_name,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          )}

                          {this.state.slug !== undefined ? (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Last Name
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="last_name"
                                value={this.state.last_name || ""}
                                maxLength="50"
                              />
                              {this.state.errors.last_name ? (
                                <div className="text-danger">
                                  {this.state.errors.last_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "last_name",
                                  this.state.last_name,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          ) : (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Last Name
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="last_name"
                                value={this.state.last_name || ""}
                                maxLength="50"
                              />
                              {this.state.errors.last_name ? (
                                <div className="text-danger">
                                  {this.state.errors.last_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "last_name",
                                  this.state.last_name,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default ActivityAddLatch;

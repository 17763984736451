////////////////////////////////////////////////////////////
//     							                                      //
//  Program: AutomationDataHubSeetings.jsx                //
//  Application: Master                                   //
//  Option: to view Property Setup data                   //
//  Developer: Anil KumarGupta		                        //
//  Date: 2023-01-23                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup } from "react-bootstrap";
import deleteicon from "../../assets/images/delete.svg";
import settings_img from "../../assets/images/settings.png";
import settings_img_two from "../../assets/images/settingsnew.png";
import automationicon from "./../../assets/images/automation.png";
import information_img from "./../../assets/images/information-button.png";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import DataHubAutomationService from "../../services/DataHubAutomationService";
import Alerts from "../common/Alerts";
import Select from "react-select";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import SettingsSidebar from "../common/SettingsSidebar";
import * as Vars from "./../../Vars";
import { Link } from "react-router-dom";
import Moment from "moment";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";

const AutomationModules = Vars.default.AutomationModules;
const ReadIntervalTypes = Vars.default.ReadIntervalTypes;
const ReadIntervalDurations = Vars.default.ReadIntervalDurations;
const DatabaseCols = Vars.default.DatabaseCols;
const DatabaseColMapping = Vars.default.DatabaseColMapping;
const EncriptionTypes = Vars.default.EncriptionTypes;
export class AutomationDataHubSeetings extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      addDataModal: false,
      searchDataModal: false,
      module_search: "",
      is_search: 0,
      editDataModal: false,
      deleteDataModal: false,
      AutomationModal: false,
      TestModal: false,
      AutomationSetting: [],
      AutomationSettingTotal: 0,
      AutomationModuleList: AutomationModules,
      selectedAutomationModule: [],
      ReadIntervalTypesList: ReadIntervalTypes,
      selectedReadIntervalTypes: [],

      ReadIntervalDurationsList: [],
      selectedReadIntervalDurations: [],
      module_name: "",
      mail_title_prefix: "",
      // mail_host: "",
      // mail_port: "",
      // username: "",
      // password: "",
      // read_interval_duration: "",
      // read_interval_type: "",
      // mail_encryption: "",
      attachment_name: "",
      selectedOption: null,
      pathname: window.location.pathname,
      property_slug: this.props.match.params.propertySlug,
      database_cols_mapping: [],
      DatabaseCols: [],
      selected_module: "",
      EncriptionTypes: EncriptionTypes,
      selectedEncriptionTypes: [],
      propertySlug: "",
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.opensearchModal = this.opensearchModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.runTestAutomation = this.runTestAutomation.bind(this);
    this.runTestMail = this.runTestMail.bind(this);
    this.openAutomationModal = this.openAutomationModal.bind(this);
    this.openTestMailModal = this.openTestMailModal.bind(this);
    this.saveData = this.saveData.bind(this);
    this.getDetails = this.getDetails.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      this.getAllAutomationSetting();
    }
  }

  handleChangeImportColsList(event, index) {
    let elements = [...this.state.database_cols_mapping];
    elements[index].file_column = event.target.value;
    this.setState({ elements });
  }

  /* to get  data */
  async getAllAutomationSetting() {
    this.setState({ showSpinner: true, loading: true });
    var AutomationSetting = [];
    var AutomationSettingTotal = 0;
    let inputData = {
      property_slug: this.state.property_slug ?? "",
    };
    let res = await DataHubAutomationService.getAllAutomationSetting(inputData);
    if (global.successStatus.includes(res.status)) {
      AutomationSetting = res.data.data ? res.data.data : [];
      AutomationSettingTotal = AutomationSetting ? AutomationSetting.length : 0;
    }
    this.setState({
      AutomationSetting: AutomationSetting,
      AutomationSettingTotal: AutomationSettingTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save data */
  saveData = async (e) => {
    e.preventDefault();
    // if (this.state.password.length < 8) {
    //   this.validator.showMessages();
    //   return true;
    // }
    if (
      this.customValidate() &&
      !this.validator.errorMessages.module_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        slug: "",
        // read_interval_type: this.state.read_interval_type,
        // read_interval_duration: this.state.read_interval_duration,
        property_slug: this.state.propertySlug
          ? this.state.propertySlug
          : this.state.property_slug
          ? this.state.property_slug
          : "",
        module_name: this.state.module_name,
        // mail_encryption: this.state.mail_encryption,
        // mail_port: this.state.mail_port,
        // mail_host: this.state.mail_host,
        // password: this.state.password,
        // username: this.state.username,
        attachment_name: this.state.attachment_name,
        mail_title_prefix: this.state.mail_title_prefix,
        // sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
        database_cols_mapping: this.state.database_cols_mapping,
      };

      let res = await DataHubAutomationService.addOrUpdateAutomationSetting(
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddModal();
        this.closesearchModal();
        this.setState({
          database_cols_mapping: [],
          DatabaseCols: [],
          selectedAutomationModule: [],
          selectedReadIntervalTypes: [],
          selectedReadIntervalDurations: [],
          module_name: "",
          mail_title_prefix: "",
          // mail_host: "",
          // mail_port: "",
          // username: "",
          // password: "",
          // read_interval_duration: "",
          // read_interval_type: "",
          // mail_encryption: "",
          attachment_name: "",
          // sort_order: "",
          status_id: true,
          slug: "",
          selectedEncriptionTypes: [],
        });
        this.getAllAutomationSetting();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get details */
  async getDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      var propertySlug = "";
      let res = await DataHubAutomationService.getAutomationSettingBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data ? res.data : {};
        propertySlug = res.data.property_slug ? res.data.property_slug : "";
        resData.mail_title_prefix = res.data.mail_title_prefix
          ? res.data.mail_title_prefix
          : "";
        resData.attachment_name = res.data.attachment_name
          ? res.data.attachment_name
          : "";
        resData.module_name = res.data.module_name ? res.data.module_name : "";
        resData.slug = res.data.slug ? res.data.slug : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;

        var selectedAutomationModule = AutomationModules.filter((obj) => {
          return obj.value == res.data.module_name;
        });

        var newSelectedEncriptionType = EncriptionTypes.filter((obj) => {
          return obj.value == res.data.mail_encryption;
        });

        let newReadIntervalDuration = ReadIntervalDurations.filter(function (
          item
        ) {
          return item.interval_type == res.data.read_interval_type;
        });

        var selectedReadIntervalType = ReadIntervalTypes.filter((object) => {
          return object.value == res.data.read_interval_type;
        });

        var selectedReadIntervalDuration = newReadIntervalDuration.filter(
          (object) => {
            return object.value == res.data.read_interval_duration;
          }
        );
        let NewUnitAvailabilityDatabaseColMapping = DatabaseColMapping.filter(
          (item) => {
            return item.module_name == res.data.module_name;
          }
        );
        if (res.data.database_cols_mapping.length > 0) {
          NewUnitAvailabilityDatabaseColMapping = DatabaseColMapping.filter(
            (item) => {
              return item.module_name == res.data.module_name;
            }
          ).map((col) => {
            var Obj = res.data.database_cols_mapping.find(
              (item) => item.db_column_name === col.database_column
            );
            if (Obj) {
              return {
                file_column: Obj.file_column_name,
                database_column: Obj.db_column_name,
                module_name: Obj.module_name,
              };
            }
          });
        }
        setTimeout(() => {
          this.setState({
            propertySlug: propertySlug,
            selectedAutomationModule: selectedAutomationModule,
            selectedEncriptionTypes: newSelectedEncriptionType,
            selectedReadIntervalTypes: selectedReadIntervalType,
            ReadIntervalDurationsList: newReadIntervalDuration,
            selectedReadIntervalDurations: selectedReadIntervalDuration,
            database_cols_mapping: NewUnitAvailabilityDatabaseColMapping,
            DatabaseCols: DatabaseCols.filter((item) => {
              return item.module_name == res.data.module_name;
            }),
          });
        }, 100);
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update data */
  updateData = async (e) => {
    e.preventDefault();
    // if (this.state.password.length < 8) {
    //   this.validator.showMessages();
    //   return true;
    // }
    if (this.customValidate() && !this.validator.errorMessages.module_name) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        slug: this.state.slug,
        // read_interval_type: this.state.read_interval_type,
        // read_interval_duration: this.state.read_interval_duration,
        property_slug: this.state.propertySlug
          ? this.state.propertySlug
          : this.state.property_slug
          ? this.state.property_slug
          : "",
        module_name: this.state.module_name,
        // mail_encryption: this.state.mail_encryption,
        // mail_port: this.state.mail_port,
        // mail_host: this.state.mail_host,
        // password: this.state.password,
        // username: this.state.username,
        attachment_name: this.state.attachment_name,
        mail_title_prefix: this.state.mail_title_prefix,
        // sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
        database_cols_mapping: this.state.database_cols_mapping,
      };
      let res = await DataHubAutomationService.addOrUpdateAutomationSetting(
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditModal();
        this.setState({
          database_cols_mapping: [],
          DatabaseCols: [],
          selectedAutomationModule: [],
          selectedReadIntervalTypes: [],
          selectedReadIntervalDurations: [],
          module_name: "",
          mail_title_prefix: "",
          // mail_host: "",
          // mail_port: "",
          // username: "",
          // password: "",
          // read_interval_duration: "",
          // read_interval_type: "",
          // mail_encryption: "",
          attachment_name: "",
          sort_order: "",
          status_id: true,
          selectedEncriptionTypes: [],
        });
        this.getAllAutomationSetting();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete data */
  async deleteData(slug) {
    this.setState({ showSpinner: true });
    let res = await DataHubAutomationService.deleteAutomationSetting(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAmenityModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getAllAutomationSetting();
    } else {
      this.closeDeleteAmenityModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteAmenityModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;

    this.setState({ [event.target.name]: value, input: input });
  };

  handleChangeDropdownList(value) {
    this.setState({
      module_name: value.value,
      selectedAutomationModule: [value],
      database_cols_mapping: DatabaseColMapping.filter((item) => {
        return item.module_name == value.value;
      }),
      DatabaseCols: DatabaseCols.filter((item) => {
        return item.module_name == value.value;
      }),
    });
  }

  handleChangeReadIntervalType(value) {
    let newReadIntervalDuration = ReadIntervalDurations.filter(function (item) {
      return item.interval_type == value.interval_type;
    });
    setTimeout(() => {
      this.setState({
        read_interval_type: value.value,
        selectedReadIntervalTypes: [value],
        ReadIntervalDurationsList: newReadIntervalDuration,
        selectedReadIntervalDurations: [],
      });
    }, 100);
  }

  handleChangeReadIntervalDuration(value) {
    this.setState({
      read_interval_duration: value.value,
      selectedReadIntervalDurations: [value],
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /*for modal */
  openAddModal() {
    var EmptyDbCols = this.state.database_cols_mapping.map((item) => {
      item.file_column = "";
      return item;
    });
    this.setState({ addDataModal: true, database_cols_mapping: EmptyDbCols });
  }

  /*for search modal */
  opensearchModal() {
    this.setState({ searchDataModal: true });
  }

  closeAddModal() {
    this.setState({ addDataModal: false });
    this.setState({
      database_cols_mapping: [],
      DatabaseCols: [],
      selectedAutomationModule: [],
      selectedReadIntervalTypes: [],
      selectedReadIntervalDurations: [],
      module_name: "",
      mail_title_prefix: "",
      // mail_host: "",
      // mail_port: "",
      // username: "",
      // password: "",
      // read_interval_duration: "",
      // read_interval_type: "",
      // mail_encryption: "",
      attachment_name: "",
      // sort_order: "",
      status_id: true,
      slug: "",
      selectedEncriptionTypes: [],
      errors: {},
    });
    this.validator.hideMessages();
  }

  closesearchModal() {
    this.setState({ searchDataModal: false });
    this.setState({
      database_cols_mapping: [],
      DatabaseCols: [],
      selectedAutomationModule: [],
      selectedReadIntervalTypes: [],
      selectedReadIntervalDurations: [],
      module_name: "",
      mail_title_prefix: "",
      // mail_host: "",
      // mail_port: "",
      // username: "",
      // password: "",
      // read_interval_duration: "",
      // read_interval_type: "",
      // mail_encryption: "",
      attachment_name: "",
      // sort_order: "",
      status_id: true,
      slug: "",
      selectedEncriptionTypes: [],
      errors: {},
    });
    this.validator.hideMessages();
  }

  openEditModal(slug) {
    this.getDetails(slug);
    this.setState({ editDataModal: true });
    this.setState({ slug: slug });
  }

  closeEditModal() {
    this.setState({ editDataModal: false });
    this.setState({
      database_cols_mapping: [],
      DatabaseCols: [],
      errors: "",
      selectedAutomationModule: [],
      selectedReadIntervalTypes: [],
      selectedReadIntervalDurations: [],
      module_name: "",
      mail_title_prefix: "",
      // mail_host: "",
      // mail_port: "",
      // username: "",
      // password: "",
      // read_interval_duration: "",
      // read_interval_type: "",
      // mail_encryption: "",
      attachment_name: "",
      // sort_order: "",
      status_id: true,
      slug: "",
      selectedEncriptionTypes: [],
    });
    this.validator.hideMessages();
  }

  openDeleteModal(slug) {
    this.setState({ deleteDataModal: true });
    this.setState({ slug: slug });
  }

  openAutomationModal(module) {
    this.setState({ AutomationModal: true });
    this.setState({ selected_module: module });
  }
  openTestMailModal(module) {
    this.setState({ TestModal: true });
    this.setState({ selected_module: module });
  }
  closeDeleteAmenityModal() {
    this.setState({ deleteDataModal: false });
    this.setState({ slug: "" });
  }

  closeAutomationModal() {
    this.setState({ AutomationModal: false });
    this.setState({ selected_module: "" });
  }
  closeMailModal() {
    this.setState({ TestModal: false });
    this.setState({ selected_module: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Data Import";
  }

  async runTestMail() {
    this.setState({ showSpinner: true, loading: true, isSubmit: true });
    let inputData = this.state.property_slug;
    let res = {};
    res = await DataHubAutomationService.TestMailAutomation(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({ showSpinner: false, loading: false, isSubmit: false });
      this.setState({
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        showAlertModal: true,
      });
      this.closeMailModal();
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  }

  async runTestAutomation() {
    this.setState({ showSpinner: true, loading: true, isSubmit: true });
    let inputData = this.state.property_slug;
    let res = {};
    if (this.state.selected_module == "unit_listing") {
      res = await DataHubAutomationService.TestUnitListImportAutomation(
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showSpinner: false,
          loading: false,
          selected_module: "",
          AutomationModal: false,
          isSubmit: false,
        });
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      }
    }
    if (this.state.selected_module == "unit_availability") {
      res = await DataHubAutomationService.TestUnitAvailabilityImportAutomation(
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showSpinner: false,
          loading: false,
          selected_module: "",
          AutomationModal: false,
          isSubmit: false,
        });
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.message ? res.message : "Error!",
        });
      }
    }
    if (this.state.selected_module == "leasing_listing") {
      res = await DataHubAutomationService.TestLeaseImportAutomation(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showSpinner: false,
          loading: false,
          selected_module: "",
          AutomationModal: false,
          isSubmit: false,
        });
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.message ? res.message : "Error!",
        });
      }
    }
    if (this.state.selected_module == "unit_rent") {
      res = await DataHubAutomationService.TestRentImportAutomation(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showSpinner: false,
          loading: false,
          selected_module: "",
          AutomationModal: false,
          isSubmit: false,
        });
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.message ? res.message : "Error!",
        });
      }
    }
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  handleChangeEncriptionType(value) {
    this.setState({
      mail_encryption: value.value,
      selectedEncriptionTypes: [value],
    });
  }

  handlePasswordInputFocus = () => {
    this.passwordInput.removeAttribute("readonly");
  };

  handleEmailInputFocus = () => {
    this.emailInput.removeAttribute("readonly");
  };

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getAllAutomationSetting();
    }, 100);
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <main>
          <Container className="innter-container auto_list_sec">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <aside>
                        <SettingsSidebar
                          property_slug={this.state.property_slug}
                        />
                      </aside>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "datahub-mapping-add"
                        ) &&
                          this.state.property_slug && (
                            <Button
                              variant="outline-dark btn-block btn-with-icon"
                              onClick={this.openAddModal}
                            >
                              <img src={pluswIcon} /> Add
                            </Button>
                          )}
                      </div>
                    </div>
                  </div>
                  {this.props.match.params.propertySlug === undefined ? (
                    <>
                      <div className="row pd-lg-l-30 pd-xs-l-15">
                        <div className="col-xxl-2 col-md-3 col-sm-4">
                          <OutSidePropertySelectBox
                            propertySlug={this.getPropertySlug.bind(this)}
                            outSidePropertySlug={this.state.outSidePropertySlug}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="master">
                    <div className="table-responsive">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <Table hover className="mg-b-0">
                        <thead>
                          <tr>
                            <th className="w-40">Module</th>
                            <th className="w-40">Mail Subject</th>
                            <th className="w-40">Last Sync Time</th>
                            <th className="text-center">Status</th>
                            {global.userPermissions.checkPermission(
                              "datahub-mapping-update"
                            ) && <th className="text-center">Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.AutomationSettingTotal > 0 ? (
                            this.state.AutomationSetting.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="hand-cursor"
                                    onClick={() => {
                                      global.userPermissions.checkPermission(
                                        "datahub-mapping-update"
                                      ) && this.openEditModal(item.slug);
                                    }}
                                  >
                                    {item.module_name == "unit_availability"
                                      ? "Unit Availability"
                                      : item.module_name == "unit_listing"
                                      ? "Units"
                                      : item.module_name == "leasing_listing"
                                      ? "Leases"
                                      : item.module_name == "unit_rent"
                                      ? "Rent"
                                      : ""}
                                  </td>
                                  <td>
                                    {item.mail_title_prefix
                                      ? item.mail_title_prefix
                                      : ""}
                                  </td>

                                  <td>
                                    {item.last_sync_at
                                      ? Moment(item.last_sync_at).format(
                                          global.dateFormat
                                        ) +
                                        " | " +
                                        Moment(item.last_sync_at).format(
                                          global.timeFormat
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center">
                                      {item.status_id === 1 ? (
                                        <b className="active m-0"></b>
                                      ) : (
                                        <b className="m-0"></b>
                                      )}
                                    </div>
                                  </td>
                                  {global.userPermissions.checkPermission(
                                    "datahub-mapping-delete"
                                  ) && (
                                    <td>
                                      <div className="d-flex justify-content-center">
                                        <div className="btn-icon-list d-block text-center">
                                          <div className="tooltip1">
                                            <img
                                              src={deleteicon}
                                              alt="Delete"
                                              onClick={() =>
                                                this.openDeleteModal(item.slug)
                                              }
                                              className="hand-cursor"
                                            />
                                            <span className="tooltiptext1 tooltiptext2">
                                              Delete
                                            </span>
                                          </div>
                                        </div>

                                        <div className="btn-icon-list d-block text-center autommation_icon ml-3">
                                          <div className="tooltip1">
                                            <img
                                              src={automationicon}
                                              alt="Automation"
                                              onClick={() =>
                                                this.openAutomationModal(
                                                  item.module_name
                                                )
                                              }
                                              className="hand-cursor"
                                            />
                                            <span className="tooltiptext1">
                                              Run Automation
                                            </span>
                                          </div>
                                        </div>

                                        <div className="btn-icon-list d-block text-center autommation_icon ml-3 tooltip1">
                                          <Link
                                            to={
                                              "/properties/view/" +
                                              this.state.property_slug +
                                              "/automation-processing-log-import/" +
                                              item.module_name
                                            }
                                          >
                                            <img
                                              src={information_img}
                                              alt="Automation Log"
                                              className="hand-cursor"
                                            />
                                          </Link>
                                          <span className="tooltiptext1">
                                            Automation Logs
                                          </span>
                                        </div>

                                        <div className="btn-icon-list d-block text-center autommation_icon ml-3 tooltip1">
                                          {/* <Link to={'/properties/view/'+this.state.property_slug+'/test-imap-connection/'+item.module_name}> */}
                                          <img
                                            src={settings_img}
                                            alt="Test Email"
                                            onClick={() =>
                                              this.openTestMailModal(
                                                item.module_name
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                          {/* </Link> */}
                                          <span className="tooltiptext1 tooltiptext3">
                                            Test Email
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                There are no data import added in the system.
                                You may add them by clicking Add button.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add popup modal */}
        <Modal
          show={this.state.addDataModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddModal();
          }}
          centered
          className="modal-large"
        >
          <Modal.Body className="width_402 p-4 border_radius_14 automation_pop_add">
            <span className="welcomeClose" onClick={() => this.closeAddModal()}>
              {global.closee}
            </span>
            <h3 className="text-center">Add</h3>

            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0 ">
              {/* <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Mail Host</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="mail_host"
                    value={this.state.mail_host}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                  />
                </InputGroup>
                {this.state.errors.mail_host ? (
                  <div className="text-danger">
                    {this.state.errors.mail_host}
                  </div>
                ) : (
                  this.validator.message(
                    "mail_host",
                    this.state.mail_host,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div> */}

              <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>CSV Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.AutomationModuleList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeDropdownList(value)}
                      defaultValue={this.state.selectedAutomationModule}
                      value={this.state.selectedAutomationModule}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.module_name ? (
                  <div className="text-danger">
                    {this.state.errors.module_name}
                  </div>
                ) : (
                  this.validator.message(
                    "module_name",
                    this.state.module_name,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              {/* <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Username</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="username"
                    value={this.state.username}
                    className="form-control max_width_100 read_only_bg"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    ref={(input) => (this.emailInput = input)}
                    onFocus={this.handleEmailInputFocus}
                    readOnly
                  />
                </InputGroup>
                {this.state.errors.username ? (
                  <div className="text-danger">
                    {this.state.errors.username}
                  </div>
                ) : (
                  this.validator.message(
                    "username",
                    this.state.username,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Password</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="password"
                    value={this.state.password}
                    className="form-control max_width_100 read_only_bg"
                    id="inlineFormInput"
                    type="password"
                    maxLength="16"
                    ref={(input) => (this.passwordInput = input)}
                    onFocus={this.handlePasswordInputFocus}
                    readOnly
                  />
                </InputGroup>
                {this.state.errors.password ? (
                  <div className="text-danger">
                    {this.state.errors.password}
                  </div>
                ) : (
                  this.validator.message(
                    "password",
                    this.state.password,
                    "required|min:8",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Mail Port</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="mail_port"
                    value={this.state.mail_port}
                    onKeyPress={global.onKeyPressEvent.numberValidation}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                  />
                </InputGroup>
                {this.state.errors.mail_port ? (
                  <div className="text-danger">
                    {this.state.errors.mail_port}
                  </div>
                ) : (
                  this.validator.message(
                    "mail_port",
                    this.state.mail_port,
                    "required|numeric|min:0,num",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                    <label>Mail Encryption</label>
                    <div className="col-md-12 p-0">
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        menuPlacement="auto"
                        className="multiselect"
                        options={this.state.EncriptionTypes}
                        getOptionValue={(option) => `${option.label}`}
                        onChange={(value) => this.handleChangeEncriptionType(value)}
                        defaultValue={this.state.selectedEncriptionTypes}
                        value={this.state.selectedEncriptionTypes}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>

                  </InputGroup>
                  {this.state.errors.mail_encryption ? (
                  <div className="text-danger">
                    {this.state.errors.mail_encryption}
                  </div>
                ) : (
                  this.validator.message(
                    "mail_encryption",
                    this.state.mail_encryption,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Read Interval</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.ReadIntervalTypesList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeReadIntervalType(value)
                      }
                      defaultValue={this.state.selectedReadIntervalTypes}
                      value={this.state.selectedReadIntervalTypes}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.read_interval_type ? (
                  <div className="text-danger">
                    {this.state.errors.read_interval_type}
                  </div>
                ) : (
                  this.validator.message(
                    "read_interval_type",
                    this.state.read_interval_type,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Read Interval Duration</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.ReadIntervalDurationsList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeReadIntervalDuration(value)
                      }
                      defaultValue={this.state.selectedReadIntervalDurations}
                      value={this.state.selectedReadIntervalDurations}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.read_interval_duration ? (
                  <div className="text-danger">
                    {this.state.errors.read_interval_duration}
                  </div>
                ) : (
                  this.validator.message(
                    "read_interval_duration",
                    this.state.read_interval_duration,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div> */}

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Mail Subject</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="mail_title_prefix"
                    value={this.state.mail_title_prefix}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="400"
                    autoComplete="off"
                  />
                </InputGroup>
                {this.state.errors.mail_title_prefix ? (
                  <div className="text-danger">
                    {this.state.errors.mail_title_prefix}
                  </div>
                ) : (
                  this.validator.message(
                    "mail_title_prefix",
                    this.state.mail_title_prefix,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>
                    Attachment Name{" "}
                    <span className="optional">(Separated by comma)</span>
                  </label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="attachment_name"
                    value={this.state.attachment_name}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="400"
                    autoComplete="off"
                  />
                </InputGroup>
                {this.state.errors.attachment_name ? (
                  <div className="text-danger">
                    {this.state.errors.attachment_name}
                  </div>
                ) : (
                  this.validator.message(
                    "attachment_name",
                    this.state.attachment_name,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              {this.state.DatabaseCols.length > 0 ? (
                <div className="col-lg-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0 mt-3">
                  <label>Field Mapping</label>
                  <div className="row desk_expo">
                    <div className="col-md-6 pr-0">
                      <div className="export_tbl_fst">
                        <p>OHAI Fields</p>
                      </div>
                    </div>
                    <div className="col-md-6 pl-0 ">
                      <div className="export_tbl_fst">
                        <p>Input Fields</p>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-12 ">
                      <div className="file_export_hgt">
                        <div className="col-md-6 p-0">
                          <div className="export_tbl_fst ">
                            <p className="mob-expo">OHAI Fields</p>
                            <ul>
                              {this.state.DatabaseCols.map((column, index) => {
                                return <li key={index}>{column.label}</li>;
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-6 p-0">
                          <div className="export_tbl_sec ">
                            <p className="mob-expo">Input Fields </p>
                            <ul>
                              {this.state.DatabaseCols.map((column, index) => {
                                return (
                                  <li key={index}>
                                    <InputGroup className="mt-0">
                                      <div className="col-md-12 p-0">
                                        <Form.Control
                                          className="form-control max_width_100"
                                          id=""
                                          type="text"
                                          maxLength="50"
                                          autoComplete="off"
                                          placeholder={column.label}
                                          onChange={(value) =>
                                            this.handleChangeImportColsList(
                                              value,
                                              index
                                            )
                                          }
                                          value={
                                            this.state.database_cols_mapping[
                                              index
                                            ].file_column
                                          }
                                        />
                                      </div>
                                    </InputGroup>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <div className="d-flex align-items-center justify-content-start mt-3">
                  <label className="form-label text-left mg-b-0 mg-r-20 status_automation_lable">
                    Status
                  </label>
                  <b className={this.state.status_id ? "active" : "inactive"}>
                    {this.state.status_id ? "active" : "inactive"}
                  </b>
                  <div
                    className={
                      this.state.status_id ? "az-toggle on" : "az-toggle"
                    }
                    onClick={() =>
                      this.setState({ status_id: !this.state.status_id })
                    }
                  >
                    <span></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit popup modal */}
        <Modal
          show={this.state.editDataModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditModal();
          }}
          centered
          className="modal-large"
        >
          <Modal.Body className="width_402 p-4 border_radius_14 automation_pop_drop ">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0 ">
              {/* <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Mail Host</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="mail_host"
                    value={this.state.mail_host}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                  />
                </InputGroup>
                {this.state.errors.mail_host ? (
                  <div className="text-danger">
                    {this.state.errors.mail_host}
                  </div>
                ) : (
                  this.validator.message(
                    "mail_host",
                    this.state.mail_host,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div> */}

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>CSV Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.AutomationModuleList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeDropdownList(value)}
                      defaultValue={this.state.selectedAutomationModule}
                      value={this.state.selectedAutomationModule}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                      isDisabled={true}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.module_name ? (
                  <div className="text-danger">
                    {this.state.errors.module_name}
                  </div>
                ) : (
                  this.validator.message(
                    "module_name",
                    this.state.module_name,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              {/* <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Username</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="username"
                    value={this.state.username}
                    className="form-control max_width_100 read_only_bg"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    ref={(input) => (this.emailInput = input)}
                    onFocus={this.handleEmailInputFocus}
                    readOnly
                  />
                </InputGroup>
                {this.state.errors.username ? (
                  <div className="text-danger">
                    {this.state.errors.username}
                  </div>
                ) : (
                  this.validator.message(
                    "username",
                    this.state.username,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Password</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="password"
                    value={this.state.password}
                    className="form-control max_width_100 read_only_bg"
                    id="inlineFormInput"
                    type="password"
                    maxLength="16"
                    ref={(input) => (this.passwordInput = input)}
                    onFocus={this.handlePasswordInputFocus}
                    readOnly
                  />
                </InputGroup>
                {this.state.errors.password ? (
                  <div className="text-danger">
                    {this.state.errors.password}
                  </div>
                ) : (
                  this.validator.message(
                    "password",
                    this.state.password,
                    "required|min:8",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Mail Port</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="mail_port"
                    value={this.state.mail_port}
                    onKeyPress={global.onKeyPressEvent.numberValidation}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                  />
                </InputGroup>
                {this.state.errors.mail_port ? (
                  <div className="text-danger">
                    {this.state.errors.mail_port}
                  </div>
                ) : (
                  this.validator.message(
                    "mail_port",
                    this.state.mail_port,
                    "numeric|min:0,num",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              <InputGroup className="mt-3">
                  <label>Mail Encryption</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.EncriptionTypes}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeEncriptionType(value)
                      }
                      defaultValue={this.state.selectedEncriptionTypes}
                      value={this.state.selectedEncriptionTypes}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.mail_encryption ? (
                  <div className="text-danger">
                    {this.state.errors.mail_encryption}
                  </div>
                ) : (
                  this.validator.message(
                    "mail_encryption",
                    this.state.mail_encryption,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Read Interval</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.ReadIntervalTypesList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeReadIntervalType(value)
                      }
                      defaultValue={this.state.selectedReadIntervalTypes}
                      value={this.state.selectedReadIntervalTypes}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.read_interval_type ? (
                  <div className="text-danger">
                    {this.state.errors.read_interval_type}
                  </div>
                ) : (
                  this.validator.message(
                    "read_interval_type",
                    this.state.read_interval_type,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Read Interval Duration</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.ReadIntervalDurationsList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeReadIntervalDuration(value)
                      }
                      defaultValue={this.state.selectedReadIntervalDurations}
                      value={this.state.selectedReadIntervalDurations}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.read_interval_duration ? (
                  <div className="text-danger">
                    {this.state.errors.read_interval_duration}
                  </div>
                ) : (
                  this.validator.message(
                    "read_interval_duration",
                    this.state.read_interval_duration,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div> */}

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Mail Subject</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="mail_title_prefix"
                    value={this.state.mail_title_prefix}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="400"
                    autoComplete="off"
                  />
                </InputGroup>
                {this.state.errors.mail_title_prefix ? (
                  <div className="text-danger">
                    {this.state.errors.mail_title_prefix}
                  </div>
                ) : (
                  this.validator.message(
                    "mail_title_prefix",
                    this.state.mail_title_prefix,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>
                    Attachment Name{" "}
                    <span className="optional">(Separated by comma)</span>
                  </label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="attachment_name"
                    value={this.state.attachment_name}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="400"
                    autoComplete="off"
                  />
                </InputGroup>
                {this.state.errors.attachment_name ? (
                  <div className="text-danger">
                    {this.state.errors.attachment_name}
                  </div>
                ) : (
                  this.validator.message(
                    "attachment_name",
                    this.state.attachment_name,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              {this.state.DatabaseCols.length > 0 ? (
                <div className="col-lg-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0 mt-3">
                  <label>Field Mapping</label>
                  <div className="row desk_expo">
                    <div className="col-md-6 pr-0">
                      <div className="export_tbl_fst">
                        <p>OHAI Fields</p>
                      </div>
                    </div>
                    <div className="col-md-6 pl-0 ">
                      <div className="export_tbl_fst">
                        <p>Input Fields</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 ">
                      <div className="file_export_hgt">
                        <div className="col-md-6 p-0">
                          <div className="export_tbl_fst">
                            <p className="mob-expo">OHAI Fields</p>
                            <ul>
                              {this.state.DatabaseCols.map((column, index) => {
                                return <li key={index}>{column.label}</li>;
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-6 p-0">
                          <div className="export_tbl_sec">
                            <p className="mob-expo">Input Fields </p>
                            <ul>
                              {this.state.DatabaseCols.map((column, index) => {
                                return (
                                  <li key={index}>
                                    <InputGroup className="mt-0">
                                      <div className="col-md-12 p-0">
                                        <Form.Control
                                          className="form-control max_width_100"
                                          id=""
                                          type="text"
                                          maxLength="50"
                                          autoComplete="off"
                                          placeholder={column.label}
                                          onChange={(value) =>
                                            this.handleChangeImportColsList(
                                              value,
                                              index
                                            )
                                          }
                                          value={
                                            this.state.database_cols_mapping[
                                              index
                                            ].file_column
                                          }
                                        />
                                      </div>
                                    </InputGroup>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <div className="d-flex align-items-center justify-content-start mt-3">
                  <label className="form-label text-left mg-b-0 mg-r-20 status_automation_lable">
                    Status
                  </label>
                  <b className={this.state.status_id ? "active" : "inactive"}>
                    {this.state.status_id ? "active" : "inactive"}
                  </b>
                  <div
                    className={
                      this.state.status_id ? "az-toggle on" : "az-toggle"
                    }
                    onClick={() =>
                      this.setState({ status_id: !this.state.status_id })
                    }
                  >
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Confirm Test mail popup modal */}

        <Modal
          show={this.state.TestModal}
          onHide={() => {
            if (
              window.confirm(
                "Are you sure to exit the pop up without run automation?"
              )
            )
              this.closeMailModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeMailModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={settings_img_two} alt="modal-delete-icon" />
              <h3>Test Email?</h3>
              <p>
                Do you really want to run this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeMailModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={this.runTestMail}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Test"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete popup modal */}
        <Modal
          show={this.state.deleteDataModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closeDeleteAmenityModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeDeleteAmenityModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAmenityModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteData(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Confirm Automation popup modal */}

        <Modal
          show={this.state.AutomationModal}
          onHide={() => {
            if (
              window.confirm(
                "Are you sure to exit the pop up without run automation?"
              )
            )
              this.closeAutomationModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAutomationModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={automationicon} alt="modal-delete-icon" />
              <h3>Run the Automation?</h3>
              <p>
                Do you really want to run this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeAutomationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={this.runTestAutomation}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Run"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default AutomationDataHubSeetings;

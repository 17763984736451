////////////////////////////////////////////////////////////
//     							                                      //
//  Program: BrandView.jsx                                //
//  Application: Brand                                    //
//  Option: For view Brand details                        //
//  Developer: Ashish Kumar ,NP				                    //
//  Date: 2021-12-28                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import BrandService from "../../services/BrandService";
import brandLogo from "./../../assets/images/brand_placeholder.png";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import modaldeleteicon from "./../../assets/images/delete.svg";
import BrandSidebar from "../common/BrandSidebar";

export class BrandView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: this.props.match.params.slug,
      showSpinner: false,
      loading: true,
      records: [],
      status_id: "",
    };
    this.closeModal = this.closeModal.bind(this);
    this.deleteBrand = this.deleteBrand.bind(this);
    this.opendeleteBrandModal = this.opendeleteBrandModal.bind(this);
    this.fullText = this.fullText.bind(this);
    this.fullText2 = this.fullText2.bind(this);
    this.openWebsite = this.openWebsite.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      var phoneNumber = "";
      var orionHausPhoneNumber = "";
      var assetManagerPhoneNumber = "";
      let res = await BrandService.getBrand(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        if (res.data && res.data.phone_number) {
          phoneNumber = await this.phoneNumberAutoFormat(res.data.phone_number);
        }
        if (
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.phone_number
        ) {
          orionHausPhoneNumber = await this.phoneNumberAutoFormat(
            res.data.orion_haus_account_manager.phone_number
          );
        }
        if (
          res.data &&
          res.data.asset_manager &&
          res.data.asset_manager.phone_number
        ) {
          assetManagerPhoneNumber = await this.phoneNumberAutoFormat(
            res.data.asset_manager.phone_number
          );
        }
        resData.term_condition = res.data ? res.data.term_condition : "";
        resData.website_cookie = res.data ? res.data.website_cookie : "";
        resData.country = res.data ? res.data.country_name : "";
        resData.county = res.data ? res.data.county_name : "";
        resData.state = res.data ? res.data.state_name : "";
        resData.city = res.data ? res.data.city_name : "";

        resData.orion_haus_account_manager_name =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.first_name
            ? res.data.orion_haus_account_manager.first_name
            : "";
        resData.orion_haus_email =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.email
            ? res.data.orion_haus_account_manager.email
            : "";
        resData.orion_haus_phone_number =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.phone_number
            ? res.data.orion_haus_account_manager.phone_number
            : "";
        resData.asset_manager_name =
          res.data &&
          res.data.asset_manager &&
          res.data.asset_manager.first_name
            ? res.data.asset_manager.first_name
            : "";
        resData.asset_manager_email =
          res.data && res.data.asset_manager && res.data.asset_manager.email
            ? res.data.asset_manager.email
            : "";
        resData.asset_manager_phone_number =
          res.data &&
          res.data.asset_manager &&
          res.data.asset_manager.phone_number
            ? res.data.asset_manager.phone_number
            : "";
      }
      this.setState(resData);
      this.setState({
        phoneNumber: phoneNumber,
        orionHausPhoneNumber: orionHausPhoneNumber,
        assetManagerPhoneNumber: assetManagerPhoneNumber,
      });
    }
    this.setState({ showSpinner: false });
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    return finalnumber;
  };

  opendeleteBrandModal(slug) {
    this.setState({ deleteBrandModal: true });
  }

  closedeleteBrandModal() {
    this.setState({ deleteBrandModal: false });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullText2() {
    if (this.state.descShow2 === 0) {
      this.setState({ descShow2: 1 });
    } else {
      this.setState({ descShow2: 0 });
    }
  }

  getTitle() {
    return "Brand Detail";
  }

  async deleteBrand(slug) {
    this.setState({ showSpinner: true });
    let res = await BrandService.deleteBrand(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteBrandModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        records: this.state.records.filter((list) => list.slug !== slug),
      });
      setTimeout(
        () => this.props.history.push("/brands"),
        global.redirect_time
      );
    } else {
      this.closedeleteBrandModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  openWebsite() {
    var url = this.state.brand_domain ? this.state.brand_domain : "";
    window.open(url, "_blank");
  }

  render() {
    return (
      <div>
        <main>
          <Container className="innter-container ">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <BrandSidebar brand_slug={this.state.slug} />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>

                      <div className="col-md-12 mg-xl-t-0 mg-xs-t-50">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3>
                            {this.state.brand_name ? this.state.brand_name : ""}
                          </h3>
                          <div className="d-flex flex-wrap- bothBtn align-items-center">
                            {global.userPermissions.checkPermission(
                              "brand-delete"
                            ) && (
                              <span
                                onClick={() => {
                                  this.opendeleteBrandModal(this.state.slug);
                                }}
                                className="deleteicon mg-r-20"
                              >
                                <img src={deleteicon} alt="" />
                              </span>
                            )}
                            {global.userPermissions.checkPermission(
                              "brand-update"
                            ) && (
                              <Link
                                to={"/brand/update/" + this.state.slug}
                                className="mg-l-2"
                              >
                                <button className="btn-success-outline">
                                  <img src={editIcon} alt="" />
                                  Edit
                                </button>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 pl-15 pr-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs mg-t-30 pd-t-2 cardsection">
                      <div className="col-md-5 col-lg-5 col-xl-3 mg-b-30 cardLeft">
                        <div className="dashboardLeftcard">
                          <div className="az-profile-overview">
                            <div className="az-img-user mg-l-auto mg-r-auto align-items-center mb-2 mt-0">
                              {this.state.status_id === 1 ? (
                                <b className="status-active"></b>
                              ) : (
                                ""
                              )}
                              {this.state.status_id === 2 ? (
                                <b className="status-inactive"></b>
                              ) : (
                                ""
                              )}
                              <img
                                src={
                                  this.state.brand_logo_url
                                    ? this.state.brand_logo_url
                                    : brandLogo
                                }
                                alt=""
                              />
                            </div>
                            {/* az-img-user */}
                            <div className="d-flex justify-content-center text-center mg-b-20 mg-t-20">
                              <div>
                                <p className="az-profile-name-text">DBA</p>
                                <p className="media-body">
                                  {this.state.brand_name
                                    ? this.state.brand_name
                                    : ""}
                                </p>

                                <p className="az-profile-name-text">
                                  Brand SKU
                                </p>
                                <p className="media-body">
                                  {this.state.aka_name
                                    ? this.state.aka_name
                                    : ""}
                                </p>

                                <p className="az-profile-name-text">
                                  Legal Entity
                                </p>
                                <p className="media-body">
                                  {this.state.legal_entity_name
                                    ? this.state.legal_entity_name
                                    : ""}
                                </p>

                                <p className="az-profile-name-text">Tax ID</p>
                                <p className="media-body">
                                  {this.state.tax_id ? this.state.tax_id : ""}
                                </p>

                                {/* manager info start */}
                                <div className="overview">
                                  <h3 className="mg-t-30 mb-2">
                                    Asset Manager
                                  </h3>
                                </div>
                                <p className="media-body p-0 m-0">
                                  {this.state.asset_manager_name
                                    ? this.state.asset_manager_name
                                    : ""}
                                </p>
                                <p className="media-body p-0 m-0">
                                  {this.state.asset_manager_email
                                    ? this.state.asset_manager_email
                                    : ""}
                                </p>
                                <p className="media-body p-0 m-0">
                                  {this.state.assetManagerPhoneNumber
                                    ? this.state.assetManagerPhoneNumber
                                    : ""}
                                </p>

                                <div className="overview">
                                  <h3 className="mg-t-30 mb-2">
                                    Orion Haus Account Manager
                                  </h3>
                                </div>
                                <p className="media-body p-0 m-0">
                                  {this.state.orion_haus_account_manager_name
                                    ? this.state.orion_haus_account_manager_name
                                    : ""}
                                </p>
                                <p className="media-body p-0 m-0">
                                  {this.state.orion_haus_email
                                    ? this.state.orion_haus_email
                                    : ""}
                                </p>
                                <p className="media-body p-0 m-0">
                                  {this.state.orionHausPhoneNumber
                                    ? this.state.orionHausPhoneNumber
                                    : ""}
                                </p>
                                {/* manager info end */}
                              </div>
                            </div>
                          </div>
                          {/* az-profile-overview */}
                        </div>
                      </div>

                      <div className="col-md-8 col-lg-7 col-xl-9 mg-b-30 cardRight">
                        <div className="dashboardRightcard overviewSection">
                          <div className="row m-0 mg-t-0 mg-md-t-0">
                            <div className="col-md-3">
                              <p className="az-profile-name-text pl-0">
                                Contact Name
                              </p>
                              <p className="media-body">
                                {this.state.contact_name
                                  ? this.state.contact_name
                                  : ""}
                              </p>
                            </div>

                            <div className="col-md-3">
                              <p className="az-profile-name-text pl-0">Phone</p>
                              <p className="media-body">
                                {this.state.phoneNumber
                                  ? this.state.phoneNumber
                                  : ""}
                              </p>
                            </div>

                            <div className="col-md-3">
                              <p className="az-profile-name-text pl-0">Email</p>
                              <p className="media-body">
                                {this.state.email ? this.state.email : ""}
                              </p>
                            </div>
                          </div>
                          <div className="row m-0 mg-t-0 mg-md-t-0">
                            <div className="col-md-3">
                              <p className="az-profile-name-text pl-0">
                                Domain Name
                              </p>
                              <p
                                className="media-body hand-cursor link-url"
                                onClick={this.openWebsite}
                              >
                                {this.state.brand_domain
                                  ? this.state.brand_domain
                                  : ""}
                              </p>
                            </div>
                          </div>

                          <hr className="mg-t-10 mg-b-30 pd-b-2" />

                          <div className="row m-0 mg-t-0 mg-md-t-0">
                            <div className="col-md-12">
                              <p className="az-profile-name-text pl-0">
                                Website Cookies
                              </p>
                              <div className="para">
                                {this.state.website_cookie ? (
                                  <p className="p_wrap1">
                                    {this.state.descShow
                                      ? this.state.website_cookie.slice(
                                          0,
                                          global.showMoreLength
                                        )
                                      : this.state.website_cookie}

                                    {this.state.website_cookie.length >
                                      global.showMoreLength && (
                                      <span onClick={this.fullText}>
                                        {this.state.descShow ? (
                                          <p className="hand-cursor showmore">
                                            ...Show more{" "}
                                          </p>
                                        ) : (
                                          <p className="hand-cursor showmore">
                                            Show Less{" "}
                                          </p>
                                        )}
                                      </span>
                                    )}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <p className="az-profile-name-text pl-0">
                                Terms & Conditions
                              </p>
                              <div className="para">
                                {this.state.term_condition //check if term_condition is exist
                                  ? this.state.term_condition.length >
                                    (
                                      <p className="p_wrap1">
                                        {this.state.descShow
                                          ? this.state.term_condition.slice(
                                              0,
                                              global.showMoreLength
                                            )
                                          : this.state.term_condition}

                                        {this.state.term_condition.length >
                                          global.showMoreLength && (
                                          <span onClick={this.fullText}>
                                            {this.state.descShow ? (
                                              <p className="hand-cursor showmore">
                                                ...Show more{" "}
                                              </p>
                                            ) : (
                                              <p className="hand-cursor showmore">
                                                Show Less{" "}
                                              </p>
                                            )}
                                          </span>
                                        )}
                                      </p>
                                    )
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        <Modal
          show={this.state.deleteBrandModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closedeleteBrandModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteBrandModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteBrand(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default BrandView;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: QuerySupportService.js                             //
//  Application: Services                                 //
//  Option: Used for City list                          //
//  Developer: Ashish Kumar						                  //
//  Date: 2022-01-31                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class QuerySupportService {
    getQuerySupport(queryString) {
        let endPoint = "list-query-support" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    createQuerySupport(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "query-support";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    getQuerySupportBySlug(slug) {
        let endPoint = "show-query-support/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }
}

export default new QuerySupportService();

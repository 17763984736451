////////////////////////////////////////////////////////////
//     							                          //
//  Program: NearByAttractionForm.jsx            //
//  Application:  Property                               //
//  Option: For add or update near by attraction         //
//  Developer: Ashish Kumar                                        //
//  Date: 2022-04-04                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, InputGroup, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import AttractionTypeService from "../../services/AttractionTypeService";
// import NearByAttractionService from "../../services/NearByAttractionService";
import NeighborhoodService from "../../services/NeighborhoodService";
import Select from "react-select";
import eventImage from "./../../assets/images/brand_placeholder.png";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import PropertySidebar from "../common/PropertySidebar";
// import PhoneInput from "react-phone-input-2";

export class NeighbourhoodForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      sort_order: "",
      pathname: window.location.pathname,
      attractionTypeList: [],
      newAttractionTypeList: [],
      selectedAttractionTypeList: [],
      nearby_attraction_name: "",
      attraction_type_slug: "",
      description: "",
      event_image: "",
      opening_hour: "",
      website: "",
      latitude: "",
      longitude: "",
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      street_1: "",
      street_2: "",
      country_slug: "",
      county_slug: "",
      state_slug: "",
      city_slug: "",
      zipcode: "",
      imageUrl: eventImage,
      confirm_back: 0,
      selectedLocationTypeList: [],
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      cityListTotal: 0,
      distance_value: "",
      distance_unit_slug: null,
      distanceUnitList: [],
      selectedDistanceUnitList: [],
      mapError: false,
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveNeighbourhood = this.saveNeighbourhood.bind(this);
    this.handleChangeAttractionTypeList =
      this.handleChangeAttractionTypeList.bind(this);
    this.openDeleteNearByAttractionModal =
      this.openDeleteNearByAttractionModal.bind(this);
    this.closeDeleteAttrcationModal =
      this.closeDeleteAttrcationModal.bind(this);
    this.deleteNeighbourhood = this.deleteNeighbourhood.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.setState({ showSpinner: true, loading: true });
    this.getAttractionTypeData();
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await NeighborhoodService.getNeighbourhoodBySlug(
        this.state.slug
      );
      if (global.successStatus.includes(res.status)) {
        resData.tag_line = res.data.tag_line ? res.data.tag_line : "";
        resData.attraction_type_slug = res.data.attraction_type_slug
          ? res.data.attraction_type_slug
          : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.image = res.data.image ? res.data.image : "";
        resData.imageUrl = res.data.image_url ? res.data.image_url : eventImage;

        resData.map = res.data.map ? res.data.map : "";
        resData.total_place = res.data.total_place ? res.data.total_place : "";
        var selectedAttractionTypeList = [];
        selectedAttractionTypeList = res.data.attraction_type
          ? [
              {
                value: res.data.attraction_type.slug,
                label: res.data.attraction_type.attraction_type_name,
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({
        selectedAttractionTypeList: selectedAttractionTypeList,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  openDeleteNearByAttractionModal(slug) {
    this.setState({ deleteNearByAttractionModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAttrcationModal() {
    this.setState({ deleteNearByAttractionModal: false });
    this.setState({ slug: "" });
  }

  async deleteNeighbourhood(slug) {
    this.setState({ showSpinner: true });
    let res = await NeighborhoodService.deleteNeighbourhood(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAttrcationModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/neighbourhood"
          ),
        global.redirect_time
      );
    } else {
      this.closeDeleteAttrcationModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  /* to get near by attractions data */
  async getPropertyNeighborhoodData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var nearByAttractionList = [];
    var nearByAttractionListTotal = 0;

    let res = await NeighborhoodService.getNeighborhood(
      "property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      nearByAttractionList = res.data.data ? res.data.data : [];
      nearByAttractionListTotal = nearByAttractionList
        ? nearByAttractionList.length
        : 0;
    }
    this.setState({
      nearByAttractionList: nearByAttractionList,
      nearByAttractionListTotal: nearByAttractionListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get attraction type list data */
  async getAttractionTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var attractionTypeList = [];
    var attractionTypeListTotal = 0;

    let res = await AttractionTypeService.getAttractionType(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      attractionTypeList = res.data.data ? res.data.data : [];
      attractionTypeListTotal = attractionTypeList
        ? attractionTypeList.length
        : 0;
    }
    const newAttractionTypeList =
      attractionTypeListTotal > 0
        ? attractionTypeList.map(({ slug, attraction_type_name }) => ({
            value: slug,
            label: attraction_type_name,
          }))
        : [];
    this.setState({
      attractionTypeList: attractionTypeList,
      attractionTypeListTotal: attractionTypeListTotal,
      newAttractionTypeList: newAttractionTypeList,
    });

    this.setState({ showSpinner: false, loading: false });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      );
      fileData.append("mediaType", "1");
      fileData.append("propertySlug", this.state.property_slug);
      let res = await NeighborhoodService.uploadNeighbourhoodImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          image: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  handleChangeAttractionTypeList(value) {
    let attraction_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        attraction_type_slug.push(item.value);
      });
    }
    const attraction_type_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedAttractionTypeList: value,
      attraction_type_slug: attraction_type_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  saveNeighbourhood = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        tag_line: this.state.tag_line ? this.state.tag_line : "",
        attraction_type_slug: this.state.attraction_type_slug
          ? this.state.attraction_type_slug
          : "",
        description: this.state.description ? this.state.description : "",
        image: this.state.image ? this.state.image : "",
        map: this.state.map ? this.state.map : "",
        total_place: this.state.total_place === 0 ? 1 : this.state.total_place,
      };

      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        let res = await NeighborhoodService.updateNeighbourhood(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/neighbourhood"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await NeighborhoodService.createNeighbourhood(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/neighbourhood"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    let errors = {};
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    if (event.target.name === "tax_type_slug") {
      // console.log(event.target);
    }

    if (event.target.name === "map") {
      const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'); 
      const isValid = regex.test(event.target.value);
      if (isValid === false) {
        this.setState({ mapError: true });
        setTimeout(() => {
            this.setState({ mapError: true });
            errors["map"] = "Please enter a valid url.";
          this.setState({ errors: errors });
        }, 100);
      } else {
        this.setState({ mapError: false });
      }
    }

    // Validate
    this.validator.showMessageFor(event.target.name);
    setTimeout(() => {
      this.customValidate();
    }, 200);
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.description !== undefined &&
      input.description !== "" &&
      input.description !== null &&
      input.description.length > 100000
    ) {
      isValid = false;
      errors["description"] =
        "The unit description character limit has been exceed.";
    } else if (input.total_place == 0) {
      isValid = false;
      errors["total_place"] = "The total place enter greater than 0.";
    }

    if (input.map && this.state.mapError === true) {
      isValid = false;
      errors["map"] = "Please enter a valid url.";
    }

    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Neighborhood";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  removePhoto() {
    this.setState({ image: "", imageUrl: "" });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                          <span
                            onClick={() => {
                              this.openDeleteNearByAttractionModal(
                                this.state.slug
                              );
                            }}
                            className="deleteicon mg-r-20"
                          >
                            <img src={deleteicon} />
                          </span>
                        </>
                      ) : (
                        ""
                      )}

                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                  this.state.property_slug +
                                  "/neighbourhood"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                                this.state.property_slug +
                                "/neighbourhood"
                            );
                        }}
                      >
                        Cancel
                      </button>

                      {this.state.slug !== undefined &&
                      global.userPermissions.checkPermission(
                        "neighbourhood-update"
                      ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveNeighbourhood}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : global.userPermissions.checkPermission(
                          "neighbourhood-add"
                        ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveNeighbourhood}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    <div className="m-0">
                      <div className="row align-items-center pd-lg-t-30 pd-lg-b-0 p border-bottom-0">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Tag Line{" "}
                          </label>

                          <InputGroup>
                            <div className="col-md-12 p-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="tag_line"
                                value={this.state.tag_line || ""}
                                className="form-control max_width_100"
                                type="text"
                                maxLength="50"
                                autoComplete="off"
                              />
                            </div>
                            {this.state.errors.tag_line ? (
                              <div className="text-danger">
                                {this.state.errors.tag_line}
                              </div>
                            ) : (
                              this.validator.message(
                                "tag_line",
                                this.state.tag_line,
                                "required|max:100",
                                { className: "text-danger" }
                              )
                            )}
                          </InputGroup>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Neighborhood Type{" "}
                          </label>

                          <Select
                            styles={customStyles}
                            isClearable={true}
                            menuPlacement="auto"
                            className="multiselect"
                            classNamePrefix={"my-custom-react-select"}
                            options={this.state.newAttractionTypeList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeAttractionTypeList(value)
                            }
                            defaultValue={this.state.selectedAttractionTypeList}
                            value={this.state.selectedAttractionTypeList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.attraction_type_slug ? (
                            <div className="text-danger">
                              {this.state.errors.attraction_type_slug}
                            </div>
                          ) : (
                            this.validator.message(
                              "neighborhood_type",
                              this.state.attraction_type_slug,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30  pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Map URL <span className="optional">(Optional)</span>
                          </label>

                          <Form.Control
                            onChange={this.changeHandler}
                            name="map"
                            value={this.state.map || ""}
                            className="form-control max_width_100"
                            type="text"
                            maxLength="500"
                            autoComplete="off"
                          />
                          {this.state.errors.map ? (
                            <div className="text-danger">
                              {this.state.errors.map}
                            </div>
                          ) : (
                            this.validator.message(
                              "map",
                              this.state.map,
                              "",
                              {
                                className: "text-danger",
                              }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Total Place{" "}
                          </label>

                          <InputGroup>
                            <div className="col-md-12 p-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="total_place"
                                value={this.state.total_place || ""}
                                className="form-control max_width_100"
                                type="number"
                                maxLength="10"
                                minLength="1"
                                autoComplete="off"
                              />
                            </div>
                            {this.state.errors.total_place ? (
                              <div className="text-danger">
                                {this.state.errors.total_place}
                              </div>
                            ) : (
                              this.validator.message(
                                "total_place",
                                this.state.total_place,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </InputGroup>
                        </div>
                      </div>
                      <div className="row align-items-center pd-b-30 border-bottom-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45  mg-b-15">
                          <InputGroup className="mt-0">
                            <label className="form-label text-left pd-b-5 w-100">
                              Description
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="description"
                              value={this.state.description}
                              className="form-control max_width_100"
                              id="description"
                              as="textarea"
                              rows={global.textareaRowLength}
                              // placeholder="Username"
                            />
                          </InputGroup>
                          {this.state.errors.description ? (
                            <div className="text-danger">
                              {this.state.errors.description}
                            </div>
                          ) : (
                            this.validator.message(
                              "description",
                              this.state.description,
                              "required|max:100000",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                      </div>

                      {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                        <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                      </div> */}
                      <div className="row align-items-center pd-b-0 border-bottom-0">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Neighborhood Image{" "}
                            <span className="optional">(Optional)</span>
                          </label>

                          <div className="fileUpload d-flex flex-wrap justify-content-between">
                            <span>
                              <label className="hand-cursor mb-0">
                                <input
                                  onChange={this.onImageChange}
                                  type="file"
                                  name="image"
                                  tabIndex="2"
                                />
                                {this.state.imageUrl ? (
                                  <img src={this.state.imageUrl} alt="Image" />
                                ) : (
                                  <img
                                  src={eventImage}
                                  alt="Logo"
                                />
                                )}
                              </label>
                            </span>

                            <ul className="ruLink">
                              <li>
                                <label>
                                  <input
                                    onChange={this.onImageChange}
                                    type="file"
                                    name="image"
                                    tabIndex="2"
                                  />
                                </label>
                              </li>
                            </ul>
                            <p className="hand-cursor remove-pic remove-pic-three m-0" onClick={this.removePhoto}></p>
                          </div>
                          {this.state.errors.image ? (
                            <div className="text-danger">
                              {this.state.errors.image}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* <div className="row align-items-center pd-lg-b-0 pd-xs-b-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0 pd-t-0">
                        <div className="col-md-3">
                          <p
                            className="hand-cursor text-danger"
                            onClick={this.removePhoto}
                          >
                            Remove
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteNearByAttractionModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAttrcationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteNeighbourhood(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default NeighbourhoodForm;

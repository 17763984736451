////////////////////////////////////////////////////////////
//     							                                      //
//  Program: AmenityTypeService.js                        //
//  Application: Amenity Type                             //
//  Option: Used for manage amenity type                  //
//  Developer: Ashish Kumar  						                  //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class SubscribersService {
    getSubscribers(queryString) {
    let endPoint = "get-subscriber" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
    getContactus(queryString) {
    let endPoint = "get-contactus" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  exportDataFilewisesSubscriber(data) {
    let endPoint = "subscriber-export";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
        endPoint: endPoint,
        addditionalHeaderData: addditionalHeaderData,
        postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  exportDataFilewiseContact(data) {
    let endPoint = "contact-export";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
        endPoint: endPoint,
        addditionalHeaderData: addditionalHeaderData,
        postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getUnsubscribers(queryString) {
    let endPoint = "crm-get-unsubscriber" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportDataFilewisesUnsubscriber(data) {
    let endPoint = "unsubscriber-export";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
        endPoint: endPoint,
        addditionalHeaderData: addditionalHeaderData,
        postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  
}

export default new SubscribersService();

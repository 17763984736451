////////////////////////////////////////////////////////////
//     							                          //
//  Program: AccessibilityFeature.jsx                                  //
//  Application: Master                                   //
//  Option: to view Property Setup data                   //
//  Developer: Ashish Kumar  			                  //
//  Date: 2022-05-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup } from "react-bootstrap";

import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import AccessibilityFeaturesService from "../../services/AccessibilityFeaturesService";
import AccessibilityFeatureTypeService from "../../services/AccessibilityFeatureTypeService";
import Alerts from "../common/Alerts";
import Select from "react-select";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class AccessibilityFeature extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for AccessibilityFeature
      addAccessibilityFeatureModal: false,
      editAccessibilityFeatureModal: false,
      deleteAccessibilityFeatureModal: false,
      accessibilityFeaturesList: [],
      accessibilityFeaturesListTotal: 0,
      accessibilityFeatureTypeList: [],
      accessibilityFeatureTypeListTotal: 0,
      newAccessibilityFeatureTypeList: [],
      accessibility_feature_name: "",
      tag_line: "",
      description: "",
      // available_level: [],
      accessibility_feature_type_slug: "",
      selectedOption: null,
      availableLevel: [],
      //selectedLevelList: [],
      // selectLevelList: [],
    };

    this.changeHandler = this.changeHandler.bind(this);
    // this.handleChangeNew = this.handleChangeNew.bind(this);

    /* for AccessibilityFeatures */
    this.openAddAccessibilityFeatureModal =
      this.openAddAccessibilityFeatureModal.bind(this);
    this.openEditAccessibilityFeatureModal =
      this.openEditAccessibilityFeatureModal.bind(this);
    this.openDeleteAccessibilityFeatureModal =
      this.openDeleteAccessibilityFeatureModal.bind(this);
    this.saveAccessibilityFeature = this.saveAccessibilityFeature.bind(this);
    this.getAccessibilityFeatureDetails =
      this.getAccessibilityFeatureDetails.bind(this);
    this.updateAccessibilityFeature =
      this.updateAccessibilityFeature.bind(this);
    this.deleteAccessibilityFeature =
      this.deleteAccessibilityFeature.bind(this);
  }

  componentDidMount() {
    this.getAccessibilityFeaturesData();
    // this.getLevelList();
  }

  /* to get AccessibilityFeature type data */
  async getAccessibilityFeatureTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var accessibilityFeatureTypeList = [];
    var accessibilityFeatureTypeListTotal = 0;

    let res = await AccessibilityFeatureTypeService.getAccessibilityFeatureType(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      accessibilityFeatureTypeList = res.data.data ? res.data.data : [];
      accessibilityFeatureTypeListTotal = accessibilityFeatureTypeList
        ? accessibilityFeatureTypeList.length
        : 0;
    }
    const newAccessibilityFeatureTypeList =
      accessibilityFeatureTypeListTotal > 0
        ? accessibilityFeatureTypeList.map(
            ({ slug, accessibility_feature_type_name }) => ({
              value: slug,
              label: accessibility_feature_type_name,
            })
          )
        : [];
    this.setState({
      accessibilityFeatureTypeList: accessibilityFeatureTypeList,
      accessibilityFeatureTypeListTotal: accessibilityFeatureTypeListTotal,
      newAccessibilityFeatureTypeList: newAccessibilityFeatureTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  // async getLevelList() {
  //   const selectLevelList = [
  //     { value: "1", label: "Property" },
  //     { value: "2", label: "Unit" },
  //     { value: "3", label: "Listing" },
  //   ];
  //   this.setState({ selectLevelList: selectLevelList });
  // }

  /* to get AccessibilityFeatures  data */
  async getAccessibilityFeaturesData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var accessibilityFeaturesList = [];
    var accessibilityFeaturesListTotal = 0;

    let res = await AccessibilityFeaturesService.getAccessibilityFeature(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      accessibilityFeaturesList = res.data.data ? res.data.data : [];
      accessibilityFeaturesListTotal = accessibilityFeaturesList
        ? accessibilityFeaturesList.length
        : 0;
    }
    this.setState({
      accessibilityFeaturesList: accessibilityFeaturesList,
      accessibilityFeaturesListTotal: accessibilityFeaturesListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save AccessibilityFeatures data */
  saveAccessibilityFeature = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.accessibility_feature_name &&
      !this.validator.errorMessages.accessibility_feature_type_slug &&
      !this.validator.errorMessages.sort_order &&
      !this.validator.errorMessages.tag_line &&
      !this.validator.errorMessages.description &&
      !this.validator.errorMessages.available_level
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      // let availableLevel = this.state.available_level
      //   ? this.state.available_level.join(",")
      //   : [];
      let inputData = {
        accessibility_feature_name: this.state.accessibility_feature_name,
        accessibility_feature_type_slug:
          this.state.accessibility_feature_type_slug,
        tag_line: this.state.tag_line,
        description: this.state.description,
        available_level: 1,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await AccessibilityFeaturesService.createAccessibilityFeature(
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddAccessibilityFeatureModal();
        this.setState({
          accessibility_feature_name: "",
          accessibility_feature_type_slug: "",
          sort_order: "",
          tag_line: "",
          description: "",
          // available_level: 1,
          status_id: true,
        });
        this.getAccessibilityFeaturesData();
      } else {
        //let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.result.errorDetail[0].errorMessage
        //     ? res.data.result.errorDetail[0].errorMessage
        //     : "Error!",
        //   alertModalMessage:
        //     alertMessage !== "" ? alertMessage : res.data.errorMessage,
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get Accessibility Feature details data */
  async getAccessibilityFeatureDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      // var selectedLevelList = [];
      let res =
        await AccessibilityFeaturesService.getAccessibilityFeatureBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.accessibility_feature_name = res.data.accessibility_feature_name
          ? res.data.accessibility_feature_name
          : "";
        resData.accessibility_feature_type_slug = res.data
          .accessibility_feature_type
          ? res.data.accessibility_feature_type.slug
          : "";
        resData.tag_line = res.data.tag_line ? res.data.tag_line : "";
        resData.description = res.data.description ? res.data.description : "";
        // resData.available_level = res.data.available_level
        //   ? res.data.available_level
        //   : 1;
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        // var selectLevelList = [];
        // res.data.available_level_on.forEach((item, i) => {
        //   selectedLevelList = [
        //     ...selectedLevelList,
        //     {
        //       value: item.id ? item.id : "",
        //       label: item.name ? item.name : "",
        //     },
        //   ];
        //   selectLevelList.push(item.id);
        // });
        var selectedAccessibilityFeatureTypeList = [];
        selectedAccessibilityFeatureTypeList = res.data
          .accessibility_feature_type
          ? [
              {
                value: res.data.accessibility_feature_type.slug,
                label:
                  res.data.accessibility_feature_type
                    .accessibility_feature_type_name,
              },
            ]
          : "";

        this.setState({
          // selectedLevelList: selectedLevelList,
          selectedAccessibilityFeatureTypeList:
            selectedAccessibilityFeatureTypeList,
        });
        // this.setState({ selectLevelList: selectLevelList });
        // this.setState({ available_level: selectLevelList });
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update AccessibilityFeature data */
  updateAccessibilityFeature = async (e) => {
    e.preventDefault();
    // let availableLevel = Array.isArray(this.state.available_level)
    //   ? this.state.available_level.join(",")
    //   : this.state.available_level;
    if (
      this.customValidate() &&
      !this.validator.errorMessages.accessibility_feature_name &&
      !this.validator.errorMessages.accessibility_feature_type_slug &&
      !this.validator.errorMessages.sort_order &&
      !this.validator.errorMessages.tag_line &&
      !this.validator.errorMessages.description &&
      !this.validator.errorMessages.available_level
    ) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        accessibility_feature_name: this.state.accessibility_feature_name,
        accessibility_feature_type_slug:
          this.state.accessibility_feature_type_slug,
        tag_line: this.state.tag_line,
        description: this.state.description,
        available_level: 1,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await AccessibilityFeaturesService.updateAccessibilityFeature(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditupdateAccessibilityFeatureModal();
        this.setState({
          accessibility_feature_name: "",
          accessibility_feature_type_slug: "",
          sort_order: "",
          tag_line: "",
          description: "",
          //available_level: [],
          status_id: true,
          // selectedLevelList: [],
        });
        this.getAccessibilityFeaturesData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete AccessibilityFeature data */
  async deleteAccessibilityFeature(slug) {
    this.setState({ showSpinner: true });
    let res = await AccessibilityFeaturesService.deleteAccessibilityFeature(
      slug
    );
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAccessibilityFeatureModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getAccessibilityFeaturesData();
    } else {
      this.closeDeleteAccessibilityFeatureModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteAccessibilityFeatureModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  handleChangeAccessibilityFeatureTypeList(value) {
    let accessibility_feature_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        accessibility_feature_type_slug.push(item.value);
      });
    }
    const accessibility_feature_type_slug_value =
      value !== null ? value.value : [];
    this.setState({
      selectedAccessibilityFeatureTypeList: value,
      accessibility_feature_type_slug: accessibility_feature_type_slug_value,
    });
  }

  // handleChangeNew(value) {
  //   let available_level = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       available_level.push(item.value);
  //     });
  //   }
  //   this.setState({
  //     selectedLevelList: value,
  //     available_level: available_level,
  //   });
  // }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /*for AccessibilityFeature modal */
  openAddAccessibilityFeatureModal() {
    this.getAccessibilityFeatureTypeData();
    this.setState({ addAccessibilityFeatureModal: true });
  }

  closeAddAccessibilityFeatureModal() {
    this.setState({ addAccessibilityFeatureModal: false });
    this.setState({
      accessibility_feature_name: "",
      accessibility_feature_type_slug: "",
      sort_order: "",
      tag_line: "",
      description: "",
      // available_level: [],
      errors: "",
      status_id: true,
      // selectedLevelList: [],
      selectedAccessibilityFeatureTypeList: [],
    });
    this.validator.hideMessages();
  }

  openEditAccessibilityFeatureModal(slug) {
    this.getAccessibilityFeatureTypeData();
    this.getAccessibilityFeatureDetails(slug);
    this.setState({ editAccessibilityFeatureModal: true });
    this.setState({ slug: slug });
  }

  closeEditupdateAccessibilityFeatureModal() {
    this.setState({ editAccessibilityFeatureModal: false });
    this.setState({
      accessibility_feature_name: "",
      accessibility_feature_type_slug: "",
      sort_order: "",
      tag_line: "",
      description: "",
      //available_level: [],
      errors: "",
      status_id: true,
      selectedLevelList: [],
      selectedAccessibilityFeatureTypeList: [],
    });
    this.validator.hideMessages();
  }

  openDeleteAccessibilityFeatureModal(slug) {
    this.setState({ deleteAccessibilityFeatureModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAccessibilityFeatureModal() {
    this.setState({ deleteAccessibilityFeatureModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Accessibility Features";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddAccessibilityFeatureModal}
                          >
                            <img src={pluswIcon} alt="" /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      {/* <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
              <Button
                variant="outline-dark btn-block btn-with-icon"
                onClick={this.openAddAccessibilityFeatureModal}
              >
                <img src={pluswIcon} alt=""/> Add
              </Button>
            </div> */}
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.accessibilityFeaturesListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-40">
                                Accessibility Feature Name
                              </th>
                              <th className="w-40">
                                Accessibility Feature Types
                              </th>
                              {/* <th className="w-40">Level</th> */}
                              <th className="w-15">Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-delete"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.accessibilityFeaturesListTotal > 0 ? (
                              this.state.accessibilityFeaturesList.map(
                                (item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td
                                        className="hand-cursor"
                                        onClick={() => {
                                          global.userPermissions.checkPermission(
                                            "master-data-update"
                                          ) &&
                                            this.openEditAccessibilityFeatureModal(
                                              item.slug
                                            );
                                        }}
                                      >
                                        {item.accessibility_feature_name}
                                      </td>
                                      <td>
                                        {item.accessibility_feature_type
                                          ? item.accessibility_feature_type
                                              .accessibility_feature_type_name
                                          : ""}
                                      </td>
                                      {/* <td>
                                      {item.available_at
                                        ? item.available_at
                                        : ""}
                                    </td> */}
                                      <td>
                                        {item.status_id === 1 ? (
                                          <b className="active"></b>
                                        ) : (
                                          <b></b>
                                        )}
                                      </td>
                                      {global.userPermissions.checkPermission(
                                        "master-data-delete"
                                      ) && (
                                        <td>
                                          <div className="btn-icon-list d-block text-center">
                                            <img
                                              src={deleteicon}
                                              alt="Delete"
                                              onClick={() =>
                                                this.openDeleteAccessibilityFeatureModal(
                                                  item.slug
                                                )
                                              }
                                              className="hand-cursor"
                                            />
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no accessibility features added in
                                  the system. You may add them by clicking Add
                                  button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage(
                            "ACCESSIBILITYFEATURE"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add AccessibilityFeature popup modal */}
        <Modal
          show={this.state.addAccessibilityFeatureModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddAccessibilityFeatureModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddAccessibilityFeatureModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Accessibility Feature</h3>
            <InputGroup className="mt-3">
              <label>Accessibility Feature Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="accessibility_feature_name"
                value={this.state.accessibility_feature_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                // placeholder="AccessibilityFeature Name"
              />
            </InputGroup>
            {this.state.errors.accessibility_feature_name ? (
              <div className="text-danger">
                {this.state.errors.accessibility_feature_name}
              </div>
            ) : (
              this.validator.message(
                "accessibility_feature_name",
                this.state.accessibility_feature_name,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>
                Level <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  className="multiselect"
                  options={this.state.selectLevelList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeNew(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup> */}

            <InputGroup className="mt-3">
              <label>Accessibility Feature Type</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  options={this.state.newAccessibilityFeatureTypeList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) =>
                    this.handleChangeAccessibilityFeatureTypeList(value)
                  }
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>
            {this.state.errors.accessibility_feature_type_slug ? (
              <div className="text-danger">
                {this.state.errors.accessibility_feature_type_slug}
              </div>
            ) : (
              this.validator.message(
                "accessibility_feature_type",
                this.state.accessibility_feature_type_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>
                Tagline <span className="optional">(Optional)</span>
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="tag_line"
                value={this.state.tag_line}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="AccessibilityFeature Tagline"
              />
            </InputGroup>
            {this.state.errors.tag_line ? (
              <div className="text-danger">{this.state.errors.tag_line}</div>
            ) : (
              this.validator.message(
                "tag_line",
                this.state.tag_line,
                "max:100",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddAccessibilityFeatureModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveAccessibilityFeature}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit AccessibilityFeature popup modal */}
        <Modal
          show={this.state.editAccessibilityFeatureModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditupdateAccessibilityFeatureModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditupdateAccessibilityFeatureModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Accessibility Feature</h3>
            <InputGroup className="mt-3">
              <label>Accessibility Feature Name</label>
              <Form.Control
                onChange={this.changeHandler}
                name="accessibility_feature_name"
                value={this.state.accessibility_feature_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="50"
                autoComplete="off"
                // placeholder="AccessibilityFeature Name"
              />
            </InputGroup>
            {this.state.errors.accessibility_feature_name ? (
              <div className="text-danger">
                {this.state.errors.accessibility_feature_name}
              </div>
            ) : (
              this.validator.message(
                "accessibility_feature_name",
                this.state.accessibility_feature_name,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>
                Level <span className="optional">(Optional)</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  isMulti
                  styles={customStyles}
                  menuPlacement="auto"
                  defaultValue={this.state.selectedLevelList}
                  value={this.state.selectedLevelList}
                  className="multiselect"
                  options={this.state.selectLevelList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) => this.handleChangeNew(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup> */}

            <InputGroup className="mt-3">
              <label>Accessibility Feature Type</label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  options={this.state.newAccessibilityFeatureTypeList}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) =>
                    this.handleChangeAccessibilityFeatureTypeList(value)
                  }
                  defaultValue={this.state.selectedAccessibilityFeatureTypeList}
                  value={this.state.selectedAccessibilityFeatureTypeList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            </InputGroup>
            {this.state.errors.accessibility_feature_type_slug ? (
              <div className="text-danger">
                {this.state.errors.accessibility_feature_type_slug}
              </div>
            ) : (
              this.validator.message(
                "accessibility_feature_type",
                this.state.accessibility_feature_type_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>
                Tagline <span className="optional">(Optional)</span>
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="tag_line"
                value={this.state.tag_line}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="AccessibilityFeature Tagline"
              />
            </InputGroup>
            {this.state.errors.tag_line ? (
              <div className="text-danger">{this.state.errors.tag_line}</div>
            ) : (
              this.validator.message(
                "tag_line",
                this.state.tag_line,
                "max:100",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditupdateAccessibilityFeatureModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.updateAccessibilityFeature}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete AccessibilityFeature popup modal */}
        <Modal
          show={this.state.deleteAccessibilityFeatureModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closeDeleteAccessibilityFeatureModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeDeleteAccessibilityFeatureModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAccessibilityFeatureModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteAccessibilityFeature(this.state.slug)
                  }
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default AccessibilityFeature;

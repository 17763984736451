//     							                               //
//  Program: Users.jsx                                         //
//  Application: users                                         //
//  Option: Users Listing                                      //
//  Developer: Mitesh Prajapati 		                       //
//  Date: 2022-08-10                                           //
//                                                             //
/////////////////////////////////////////////////////////     ///

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Container, Modal, InputGroup, Form, Nav, Tab } from "react-bootstrap";
import NoData from "../common/NoData";
import pluswIcon from "./../../assets/images/plus.svg";
import { Link } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";
import RolePermissionService from "../../services/RolePermissionService";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import queryString from "query-string";
import moment from "moment-timezone";
import UsersSidebar from "../common/UsersSidebar";
let queryParams = queryString.parse(window.location.search);

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      showSpinner: false,
      loading: false,
      total_record: 0,
      records: [],
      groupList: [],
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      RoleList: [],
      SelectedRoleList: [],
      newRoleGroupList: [],
      SelectedRoleGroupList: [],
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      isSearch: global.isSearch,
      filter_value: "",
      role_slug: "",
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "first_name",
          text: "Name",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.first_name + " " + record.last_name;
          },
        },
        {
          key: "email",
          text: "Email",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.email;
          },
        },
        {
          key: "phone_number",
          text: "Phone",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber = record.phone_number
              ? record.phone_number.replace(/[\s-]/g, "")
              : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits = record.phone_number ? record.phone_number : [];
            }

            var number = record.phone_number
              ? lastTenDigits.trim().replace(/[^0-9]/g, "")
              : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.phone_number ? finalnumber : "";
          },
        },
        {
          key: "role_title",
          text: "Role",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.role_title;
          },
        },
        {
          key: "role_group",
          text: "Role Group",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return this.state.groupList.map((dataList) => {
              if (dataList.value === record.role_group) {
                return dataList.key;
              }
            });
          },
        },
        {
          key: "last_login",
          text: "Last Login",
          className: "hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            if (record.last_login !== null) {
              return moment(moment.utc(record.last_login).toDate())
                .local()
                .format(global.dateTimeFormat);
            } else {
              return "N/A";
            }
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active">Active</b>
            ) : (
              <b className="status-inactive">InActive</b>
            );
          },
        },
      ],
      tab: "active",
      usertabData: localStorage.getItem("user_tab_data")
        ? localStorage.getItem("user_tab_data")
        : "",
      activeClass: "activeTab",
    };
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
  }

  componentDidMount() {
    switch (this.state.usertabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
    if (queryParams.isGlobal === "1") {
      this.getData(
        "is_search=1&tab=" +
          this.state.usertabData +
          "&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search
      );
    } else {
      // this.getData();
    }
    this.getRoles();
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "user_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "user_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var groupList = [];
    var totalRecords = 0;
    var tg = 0;
    let res = await AuthenticationService.getUserList(
      queryString ? queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.userData.data : [];
      groupList = res.data ? res.data.groupList : [];
      totalRecords = list ? res.data.userData.total : 0;
      tg = list ? groupList.length : 0;
      //totalRecords = res.data ? res.data.total : 0
    }

    const newRoleGroupList =
      tg > 0
        ? groupList.map((items) => ({
            value: items.value,
            label: items.key,
          }))
        : [];

    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
      groupList: groupList,
      newRoleGroupList: newRoleGroupList,
    });
  }

  // to get data of roles
  async getRoles(queryString = "") {
    this.setState({ showSpinner: true, loading: true });

    var RoleList = [];
    var RoleListTotal = 0;

    let res = await RolePermissionService.getRoles(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      RoleList = res.data ? res.data : [];
      RoleListTotal = RoleList ? RoleList.length : 0;
    }
    const newRoleList =
      RoleListTotal > 0
        ? RoleList.map((items) => ({
            value: items.slug,
            label: items.role_title,
          }))
        : [];

    this.setState({
      RoleList: newRoleList,
    });
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getData("tab=" + this.state.tab);
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let queryString =
      "first_name=" +
      (this.state.first_name ? this.state.first_name : "") +
      "&email=" +
      (this.state.email ? this.state.email : "") +
      "&phone_number=" +
      (this.state.phone_number ? this.state.phone_number : "") +
      "&role_group_name=" +
      (this.state.role_group_slug ? this.state.role_group_slug : "") +
      "&role_slug=" +
      (this.state.role_slug ? this.state.role_slug : "") +
      "&status_id=" +
      (this.state.status_id ? this.state.status_id : "") +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.first_name
      ? "First name - " + this.state.first_name
      : "";
    moduleSearchString = this.state.email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Email - " + this.state.email
        : " Email - " + this.state.email
      : moduleSearchString;
    moduleSearchString = this.state.phone_number
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Phone number - " +
          this.state.phone_number
        : " Phone number - " + this.state.phone_number
      : moduleSearchString;
    moduleSearchString = this.state.role_group_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Role Group - " +
          this.state.role_group_name
        : " Role Group - " + this.state.role_group_name
      : moduleSearchString;
    moduleSearchString = this.state.role_slug
      ? moduleSearchString
        ? moduleSearchString + " ," + " Role - " + this.state.role_slug
        : " Role - " + this.state.role_slug
      : moduleSearchString;
    moduleSearchString = this.state.status_id
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;

    if (
      this.state.first_name ||
      this.state.email ||
      this.state.phone_number ||
      this.state.role_group_slug ||
      this.state.role_slug ||
      this.state.status_id
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  handleChangeRoleList(value) {
    let role_slug = [];

    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        role_slug.push(item.value);
      });
    }
    this.setState({
      SelectedRoleList: value,
      role_slug: value.label,
      role_name: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  clearFilter() {
    this.setState({ is_search: 0 });
    this.setState({
      first_name: "",
      email: "",
      phone_number: "",
      status_id: "",
      role_group_slug: "",
      role_slug: "",
      role_name: "",
      role_group_name: "",
      SelectedRoleList: [],
      selectedStatusList: [],
      SelectedRoleGroupList: [],
      queryString: "",
      customQueryString: "",
    });
    this.setState({ module_search: "" });
    this.getData("tab=" + this.state.tab);
  }
  onChangePhone(value, country, e, formattedValue) {
    this.setState({ property_phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }
  handleChangeRoleGroupList(value) {
    let role_group_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        role_group_slug.push(item.value);
      });
    }
    this.setState({
      SelectedRoleGroupList: value,
      role_group_slug: value.value,
      role_group_name: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    global.userPermissions.checkPermission("users-update") &&
      this.props.history.push("/users/update/" + data.slug);
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}

        <Container className="innter-container pr-0 over-fl">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <UsersSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel">
                  <Container>
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Users</h3>
                          <div className="d-flex">
                            {global.userPermissions.checkPermission(
                              "users-add"
                            ) && (
                              <Link to={"/users/add"}>
                                <button className="btn-success-outline mr-3">
                                  <img src={pluswIcon} /> Add
                                </button>
                              </Link>
                            )}

                            <button
                              onClick={this.searchToggle}
                              className="btn-success-outline-small"
                            >
                              <img src={filterIcon} alt="filterIcon" />{" "}
                              {this.state.isSearch === 1 ? "Search" : "Search"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                  <Container>
                    {queryParams.isGlobal === "1" ? (
                      <>
                        <div className="col-md-12 pd-l-30 pd-r-30 mg-t-30 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                          {"Search applied on: " +
                            queryParams.global_col_label +
                            " - " +
                            queryParams.global_search}
                          <button
                            onClick={this.clearFilter}
                            className="mg-l-15 btn-success-outline-small"
                          >
                            Clear
                          </button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.is_search === 1 ? (
                      <>
                        <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel mg-t-20">
                          <div className="custm-filter">
                            <div>
                              {" "}
                              <label className="form-label text-left pd-b-2 mb-0 pd-lg-t-0 pd-xs-t-15">
                                Search Applied On
                              </label>
                            </div>
                            <div className="filter_bg_cust">
                              {this.state.module_search.map((item, i) => {
                                return <span key={i}>{item}</span>;
                              })}
                              <button
                                onClick={this.clearFilter}
                                className="btn-fliter-clear"
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="scrolling-carousel pd-lg-l-0 pd-xs-l-15 scroll-slide">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="all"
                        onSelect={(e) => this.getActiveClass(e)}
                        eventKey={this.state.activeClass}
                        className="pd-r-60"
                      >
                        <ScrollingCarousel
                          rightArrow={true}
                          leftArrow={true}
                          className="swipesection pd-t-20"
                        >
                          <Nav.Item>
                            <Nav.Link
                              eventKey="activeTab"
                              id="activeTab"
                              className={
                                this.state.activeClass === "activeTab"
                                  ? "active"
                                  : ""
                              }
                            >
                              Active
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link
                              eventKey="inactiveTab"
                              id="inactiveTab"
                              className={
                                this.state.activeClass === "inactiveTab"
                                  ? "active"
                                  : ""
                              }
                            >
                              Inactive
                            </Nav.Link>
                          </Nav.Item>
                        </ScrollingCarousel>
                        <Tab.Content>
                          <Tab.Pane eventKey="activeTab">
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              {this.state.total_record > 0 ||
                              this.state.filter_value !== "" ? (
                                <div className="mg-t-20 brandList">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    // loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              ) : (
                                <NoData
                                  msg={global.commonMessages.checkMessage(
                                    "USERS_ACTIVE"
                                  )}
                                />
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="inactiveTab">
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              {this.state.total_record > 0 ||
                              this.state.filter_value !== "" ? (
                                <div className="mg-t-20 brandList">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    // loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              ) : (
                                <NoData
                                  msg={global.commonMessages.checkMessage(
                                    "USERS_INACTIVE"
                                  )}
                                />
                              )}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Users</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="first_name"
                      value={this.state.first_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Email</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Phone</label>
                  <div className="col-md-12 p-0">
                    <PhoneInput
                      country={global.country}
                      disableSearchIcon
                      // autoFormat= "true"
                      enableSearch="true"
                      countryCodeEditable="true"
                      value={this.state.phone_number}
                      onChange={(phone_number) =>
                        this.setState({ phone_number })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Role</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.SelectedRoleList}
                      options={this.state.RoleList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeRoleList(value)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Role Group</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.SelectedRoleGroupList}
                      options={this.state.newRoleGroupList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeRoleGroupList(value)
                      }
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default Users;

//////////////////////////////////////////////////////////
//  Program: AllPropertyLisitingSidebar.jsx                        //
//  Application: Listing                                //
//  Option: common component Listing Sidebar            //
//  Developer: Ashish Kumar 		                        //
//  Date: 2023-03-17                                    //
//                                                      //
//////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from 'react-bootstrap';

class AllPropertyLisitingSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.remove("d-none");
        document.querySelector(".newSubmenu").classList.remove("d-none");
      } else {
        data.classList.replace("left", "hide");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.add("d-none");
        document.querySelector(".newSubmenu").classList.add("d-none");
      }
    }
  }

  toggleChildMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector(".newSubmenu").classList.toggle("d-none");
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
      } else {
        data.classList.replace("left", "hide");
      }
    }
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Listings
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  {/* <span>Listings</span>  */}
                  <ul>
                    {global.userPermissions.checkPermission(
                      "listing-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("list-calendar")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/global-list-calendar"}
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "single-unit-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("listing")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/listings"}
                        >
                          Listings
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "home-sharing-inquiries"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("/homesharing-inquiries")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/homesharing-inquiries"}
                        >
                          Homesharing Inquiries
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Listings
              </span>
              <ul>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "listing-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("list-calendar")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/list-calendar"}
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "single-unit-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("listing")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/listings"}
                        >
                          Listings
                        </Link>
                      </li>
                    )}
                     {global.userPermissions.checkPermission(
                      "home-sharing-inquiries"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("/homesharing-inquiries")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/homesharing-inquiries"}
                        >
                          Homesharing Inquiries
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>

            {/* <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span>Sub Menu</span>
                <i className="fas fa-chevron-down"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="tx-13">
                <aside>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <ul>
                      <li>

                        <ul>
                          {global.userPermissions.checkPermission('listing-calendar-list') &&
                            <li>
                              <Link
                                className={
                                  this.isPathActive("list-calendar")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/list-calendar"}
                              >
                                Calendar
                              </Link>
                            </li>
                          }
                          {global.userPermissions.checkPermission('single-unit-list') &&
                            <li>
                              <Link
                                className={
                                  this.isPathActive("listing")
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                data-toggle="tab"
                                to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/listing"}
                              >
                                Listings
                              </Link>
                            </li>
                          }

                        </ul>
                      </li>
                    </ul>
                  </nav>
                </aside>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        </div>
      </>
    );
  }
}
export default AllPropertyLisitingSidebar;

/////////////////////////////////////////////////////
//     							                   //
//  Program: Images.jsx                //
//  Application: Property                          //
//  Option: List all Property Images //
//  Developer: Ashish Kumar					   //
//  Date: 2022-04-04                               //
//                                                 //
/////////////////////////////////////////////////////
import React, { Component } from "react";
// import { Link } from "react-router-dom";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import dotIcon from "./../../assets/images/3dots.png";
import { Container, Carousel, Form, Modal, Tab, Nav } from "react-bootstrap";
import MultiImageService from "../../services/MultiImageService";
import PropertyService from "../../services/PropertyService";
import SimpleReactValidator from "simple-react-validator";
import FileWidget from "../fileUploader/FileWidget";
import Alerts from "../common/Alerts";
import deleteImg from "../../assets/images/delete.svg";
import close from "../../assets/images/close-wel.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import propertyImg from "./../../assets/images/PropPlaceMainImg.png";
// import cropImg from "./../../assets/images/cropImg.png";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import PropertySidebar from "../common/PropertySidebar";
import addImg from "./../../assets/images/add-img.png";
import NoData from "../common/NoData";
import Cropper from "react-easy-crop";
// import "../fileUploader/crop.css";
import getCroppedImg from "../fileUploader/cropImage";
import UploadCropedImage from "../fileUploader/UploadCropedImage";
import Slider from "@material-ui/core/Slider";
// import { Draggable } from "react-drag-reorder";
// import propsImg from "./../../assets/images/prop-img-listing.png";
// import {arrayMoveImmutable} from 'array-move';
import Gallery from "react-grid-gallery";
import zoomImg from "../../assets/images/zoom.svg";
// import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// import Photo from "./Photo";
import { Dropdown } from "react-bootstrap";
import mainImg from "./../../assets/images/main-img.svg";
import webImg from "./../../assets/images/web-img.svg";
import Download_img from "../../assets/images/download_svg.svg";
export class Images extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      property_slug: this.props.match.params.propertySlug,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      importModal: false,
      multiImageList: [],
      multiImageListTotal: 0,
      property_logo: "",
      property_main_image: "",
      viewMode: 0,
      newRelatedFilesDesc: [],
      newRelatedFilesMainImg: [],
      propertyLogoModal: false,
      propertyListImageModal: false,
      imageUrl: propertyLogo,
      imgPropUrl: propertyImg,
      unitImageListTotal: 0,
      imageLoop: 30,
      multiImageListEdit: [],
      sortOrderForImage: 1,
      isImageEdit: false,
      crop: { x: 0.1, y: 0.1 },
      zoom: 1,
      aspect: 4 / 3,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      croppedImageArr: undefined,
      display_file_name: "",
      currentFile: [],
      isCrop: false,
      reorderdImg: [],
      sortOrderImg: [],
      dragOff: 1,
      newMultiImageList: [],
      is_main_img: false,
      is_web_img: false,
    };
    this.propertyLogo = React.createRef();
    this.propertyImg = React.createRef();
    this.closeModal = this.closeModal.bind(this);
    // this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerDesc = this.changeHandlerDesc.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.changeView = this.changeView.bind(this);
    // this.saveOrImage = this.saveOrImage.bind(this);
    this.updateImgDesc = this.updateImgDesc.bind(this);
    this.deletePropImg = this.deletePropImg.bind(this);
    this.opendeletePropImgModal = this.opendeletePropImgModal.bind(this);
    this.openEditPropDescModal = this.openEditPropDescModal.bind(this);
    this.closeEditPropDescModal = this.closeEditPropDescModal.bind(this);
    this.openPropImgModal = this.openPropImgModal.bind(this);
    this.newRelatedFilesDescChange = this.newRelatedFilesDescChange.bind(this);
    this.newRelatedFilesMainImgChange =
      this.newRelatedFilesMainImgChange.bind(this);
    this.openPropertyLogoModal = this.openPropertyLogoModal.bind(this);
    this.closePropertyLogoModal = this.closePropertyLogoModal.bind(this);
    this.closePropImgModal = this.closePropImgModal.bind(this);
    this.submitModalImage = this.submitModalImage.bind(this);
    this.openPropertyListImageModal =
      this.openPropertyListImageModal.bind(this);
    this.closePropertyListImageModal =
      this.closePropertyListImageModal.bind(this);
    this.cropperSet = this.cropperSet.bind(this);
    this.EditImgSet = this.EditImgSet.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.cancelCrop = this.cancelCrop.bind(this);
    this.getChangedPos = this.getChangedPos.bind(this);
    this.getDownloadFile = this.getDownloadFile.bind(this);

    // this.onCropComplete = this.onCropComplete.bind(this);
  }

  async componentDidMount() {
    this.getMulitImagesData();
    // if (this.state.property_slug !== undefined) {
    //   var resData = {};
    //   let res = await PropertyService.getProperty(this.state.property_slug);
    //   if (global.successStatus.includes(res.status)) {
    //     resData.property_name = res.data.property_name
    //       ? res.data.property_name
    //       : "";
    //     resData.property_logo = res.data.property_logo
    //       ? res.data.property_logo
    //       : "";
    //     resData.imageUrl = res.data.property_logo_url
    //       ? res.data.property_logo_url
    //       : "";
    //     resData.property_main_image = res.data.property_main_image
    //       ? res.data.property_main_image
    //       : "";
    //     resData.imgPropUrl = res.data.property_main_image_url
    //       ? res.data.property_main_image_url
    //       : "";
    //   }
    //   this.setState(resData);
    // }
    // setTimeout(() => console.clear(), 5000);
  }

  cropperSet = () => {
    this.setState({ isImageEdit: true });
  };

  EditImgSet = () => {
    this.setState({ isImageEdit: false });
  };

  cancelCrop = () => {
    this.closeEditPropDescModal();
    this.setState({ isImageEdit: false, isCrop: false, zoom: 0.04 });
  };

  cropImage = async () => {
    await this.setState({ isCrop: true });
    await this.showCroppedImage();
    document.getElementById("left-tabs-example-tab-description").click();
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = async (croppedArea, croppedAreaPixels) => {
    await this.setState({ croppedAreaPixels });
    // await this.showCroppedImage();
    // this.setState({ isImageEdit: false})
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  showCroppedImage = async () => {
    if (this.state.isCrop) {
      const croppedImage = await getCroppedImg(
        this.state.editModeCropImg,
        // cropImg,
        this.state.croppedAreaPixels,
        this.state.rotation,
        this.state.display_file_name
      );
      this.setState({
        croppedImage: croppedImage.url,
        croppedImageArr: croppedImage.file,
      });
      this.setState({ isImageEdit: false });
    }
  };

  submitModalImage = async (event) => {
    this.setState({ showSpinner: true, isSubmit: true });
    const FileWidgetObj = new FileWidget();
    const output = await FileWidgetObj.fileUploadFun("register_from");
    this.closePropImgModal();
    let errorMessage = "";
    if (output.status !== false) {
      var isError = output.response[0].filesData.some((data) => {
          if (data.fileStatus === 500) {
              errorMessage = data.fileMessage;
          }
          return data.fileStatus === 500;
      })
  }
    if (output.status !== false) {
      if (!isError) {
        if (output.response[0].filesData) {
          if (output.response[0].filesData.length > 0) {
            let inputData = {
              availableFiles: output.response,
              deletedFiles: this.state.deletedFileList,
            };

            if (this.state.property_slug !== undefined) {
              let res = await MultiImageService.submitMultiImage(inputData);
              if (global.successStatus.includes(res.status)) {
                this.setState({
                  showAlertModal: true,
                  alertModalType: "success",
                  alertModalTitle: "Success",
                  alertModalMessage: res.message ? res.message : "Success",
                });
                this.setState({
                  description: "",
                  is_main_img: "",
                  is_web_img: "",
                });
                this.setState({
                  newRelatedFilesDesc: [],
                  dragOff: 0,
                });
                this.getMulitImagesData();
              } else {
                let alertMessage = "";
                if (
                  res.data.errorCode === "validationFailed" &&
                  res.data.errorDetail !== null
                ) {
                  let errors = {};
                  res.data.errorCode = "Validation Failed";
                  res.data.errorDetail.forEach((item) => {
                    errors[item.errorField] = item.errorMessage[0];
                    // alertMessage += item.errorMessage[0]+'\n';
                    res.data.errorMessage = "";
                  });
                  this.setState({ errors: errors });
                }
                this.setState({
                  showAlertModal: true,
                  deleteImgModal: false,
                  alertModalType: "error",
                  alertModalTitle: res.data.message
                    ? res.data.message
                    : "Error!",
                  alertModalMessage:
                    alertMessage !== "" ? alertMessage : res.data.message,
                });
              }
            }
          }
        }
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: output.response[0].filesData
            ? errorMessage
            : "Success",
        });
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: output.response[0].validationMessage[0][0],
      });
    }
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
    this.setState({ showSpinner: false, isSubmit: false });
  };

  openPropertyLogoModal() {
    document.body.className = "proplistimg";
    this.setState({ propertyLogoModal: true });
  }

  closePropertyLogoModal() {
    this.setState({ propertyLogoModal: false });
  }

  openPropertyListImageModal() {
    document.body.className = "proplistimg";
    this.setState({ propertyListImageModal: true });
  }

  closePropertyListImageModal() {
    this.setState({ propertyListImageModal: false });
  }

  newRelatedFilesDescChange(index, value) {
    let newRelatedFilesDesc = this.state.newRelatedFilesDesc;
    newRelatedFilesDesc[index] = value;
    this.setState({ newRelatedFilesDesc: newRelatedFilesDesc });
  }

  newRelatedFilesMainImgChange(index, value) {
    let newRelatedFilesMainImg = this.state.newRelatedFilesMainImg;
    newRelatedFilesMainImg[index] = value;
    this.setState({ newRelatedFilesMainImg: newRelatedFilesMainImg });
  }

  async getMulitImagesData(deletedImage = false) {
    if (this.state.property_slug !== undefined) {
      let inputData = {
        path: "properties",
        refTableId: 3,
        slug: this.state.property_slug,
        mediaType: 1,
      };
      let res = await MultiImageService.getImages(inputData);

      var multiImageList = [];
      var multiImageListEdit = [];
      var multiImageListTotal = 0;
      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotal = multiImageList.length;
        if (multiImageListTotal > 0) {
          multiImageList.forEach((item, i) => {
            var sort_order = multiImageList.length;
            multiImageListEdit[item.sort_order] = sort_order - i;
          });
        }
      }
      multiImageList.map((opt, i) => {
        var src = opt.src;
        var orgFile = opt.file_name;
        var mediumImg = opt.medium_img;
        opt.file_name = mediumImg;
        opt.medium_img = mediumImg;
        opt.editModeCropImg = opt.cropImgSrc; //cropImg;  //orgFile;
        multiImageList[i]["caption"] = opt.description ? opt.description : "";
        multiImageList[i]["src"] = orgFile;
        multiImageList[i]["thumbnail"] = mediumImg;
        // multiImageList[i]["thumbnailWidth"] = 1;
        // multiImageList[i]["thumbnailHeight"] = 1;
        // multiImageList[i]["rowHeight"] = 1;
        // multiImageList[i]["maxRows"] = 3;
        // multiImageList[i]["vwidth"] = 1;
        multiImageList[i]["main_image"] = orgFile;
        multiImageList[i]["tags"] = [
          {
            value: global.userPermissions.checkPermission(
              "property-gallery-delete"
            ) ? (
              <img
                src={deleteImg}
                alt=""
                onClick={() => {
                  global.userPermissions.checkPermission(
                    "property-gallery-delete"
                  ) && this.opendeletePropImgModal(opt.slug);
                }}
              />
            ) : (
              ""
            ),
            key: `delete image ${i}`,
            title: `delete image`,
          },
          {
            value: <img src={zoomImg} alt="" />,
            key: `zoom image ${i}`,
            title: `zoom image`,
          },
          {
            value: (
              <img
                src={Download_img}
                alt=""
                onClick={() => this.getDownloadFile(src, opt.display_file_name)}
              />
            ),
            key: `delete image ${i}`,
            title: `download image`,
          },
        ];
      });
      const newMultiImageList = multiImageList;
      setTimeout(() => console.log(newMultiImageList), 5000);

      let orderResponse = this.setImageOrder(multiImageList, deletedImage);

      if (orderResponse) {
        this.setState({
          multiImageList: multiImageList,
          multiImageListTotal: multiImageListTotal,
          multiImageListEdit: multiImageListEdit,
          newMultiImageList: newMultiImageList,
        });
      }
    }
  }

  setImageOrder = async (imageList, deletedImage) => {
    //check how many image has 0 index
    let zeroIndexImages = imageList.filter((data, index) => {
      return data.sort_order === 0;
    });

    // if any images has more then  2 indexes then we need to reorder the images in index sequesnce
    if (zeroIndexImages.length > 0 || deletedImage === true) {
      //set image sequnce from zero last index plus 1
      imageList.forEach((data, index) => {
        data.sort_order = index + 1;
      });

      let inputData = {
        path: "properties",
        refTableId: 3,
        slug: this.state.property_slug,
        mediaType: 1,
        reorder_images: imageList,
      };

      //call api to set new sort order that will save new order in data base.
      let res = await MultiImageService.reorderImages(inputData);
      //if response is 200 ,again call recall the componant to see corect data.
      if (res.status === 200) {
        this.getMulitImagesData();
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  /*file download */
  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  async reorderImageListing() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      let inputData = {
        path: "properties",
        refTableId: 3,
        slug: this.state.property_slug,
        mediaType: 1,
        // reorder_images: this.state.reorderdImg,
        reorder_images: this.state.sortOrderImg,
      };
      let res = await MultiImageService.reorderImages(inputData);
      if (global.successStatus.includes(res.status)) {
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "success",
        //   alertModalTitle: "Success",
        //   alertModalMessage: res.message ? res.message : "Success",
        // });
        // this.setState({
        //   multiImageList: [],
        //   multiImageListEdit: [],
        //   reorderdImg: [],
        // });
        //  this.setState({ dragOff: 0 });
        // this.getMulitImagesData();
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          deleteImgModal: false,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
    this.setState({ showSpinner: false, loading: false });
  }

  opendeletePropImgModal(slug) {
    this.closeEditPropDescModal();
    this.closeEditImagePopupModal();
    this.setState({ deletePropImgModal: true, slug: slug });
  }

  openEditPropDescModal(
    slug,
    img_src,
    editModeCropImg,
    description,
    is_main_img,
    is_web_img,
    display_file_name,
    currentFile
  ) {
    this.setState({
      openEditPropDescModal: true,
      slug: slug,
      img_src: img_src,
      editModeCropImg: editModeCropImg,
      description: description,
      is_main_img: is_main_img,
      is_web_img: is_web_img,
      display_file_name: display_file_name,
      currentFile: currentFile,
    });
  }

  openEditImagePopupModal(
    slug,
    img_src,
    editModeCropImg,
    description,
    is_main_img,
    is_web_img,
    display_file_name,
    currentFile
  ) {
    this.closeEditImagePopupModal();
    this.setState({
      editImagePopupModal: true,
      slug: slug,
      img_src: img_src,
      editModeCropImg: editModeCropImg,
      description: description,
      is_main_img: is_main_img,
      is_web_img: is_web_img,
      display_file_name: display_file_name,
      currentFile: currentFile,
    });
  }

  closeEditImagePopupModal() {
    this.setState({
      editImagePopupModal: false,
      slug: "",
      isImageEdit: false,
      description: "",
      is_main_img: "",
      is_web_img: "",
      img_src: "",
      croppedImage: "",
      currentFile: null,
      isCrop: false,
      display_file_name: "",
      zoom: 0.4,
    });
  }

  closeEditPropDescModal() {
    this.closeEditImagePopupModal();
    this.setState({
      openEditPropDescModal: false,
      slug: "",
      isImageEdit: false,
      description: "",
      is_main_img: "",
      is_web_img: "",
      img_src: "",
      editModeCropImg: "",
      croppedImage: "",
      currentFile: null,
      isCrop: false,
      display_file_name: "",
      zoom: 0.4,
    });
  }

  closedeletePropImgModal() {
    this.setState({
      deletePropImgModal: false,
      slug: "",
      description: "",
      is_main_img: "",
      is_web_img: "",
    });
  }

  openPropImgModal(j) {
    this.setState({ addImgModal: true, sortOrderForImage: j });
  }

  closePropImgModal() {
    this.setState({
      addImgModal: false,
      slug: "",
      description: "",
      is_main_img: "",
      is_web_img: "",
    });
  }

  async setCoverImg(slug, sortOrder, type) {
    let inputUpdateData = "";
    if (type === "Main") {
      inputUpdateData = {
        is_main_img: 1,
        refTableId: 3,
        propertySlug: this.state.property_slug,
        mediaType: 1,
        slug: slug ? slug : "",
        sortOrder: sortOrder,
      };
    } else {
      inputUpdateData = {
        is_web_img: 1,
        refTableId: 3,
        propertySlug: this.state.property_slug,
        mediaType: 1,
        slug: slug ? slug : "",
      };
    }

    let resMain;
    resMain = await MultiImageService.updateSingleImageDesc(inputUpdateData);
    if (global.successStatus.includes(resMain.status)) {
      // this.setState({
      //   showAlertModal: true,
      //   alertModalType: "success",
      //   alertModalTitle: "Success",
      //   alertModalMessage: resMain.message ? resMain.message : "Success",
      // });
      this.closeEditPropDescModal();
      this.setState({ propertyImageListTotal: 0, dragOff: 0 });
      this.getMulitImagesData();
    } else {
      let alertMessage = "";
      if (resMain.data.result.errorDetail !== null) {
        let errors = {};
        resMain.data.errorCode = "Validation Failed";
        resMain.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          // alertMessage += item.errorMessage[0]+'\n';
          resMain.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
      this.setState({
        showSpinner: false,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: resMain.data.errorCode
          ? resMain.data.errorCode
          : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : resMain.data.message,
      });
    }
  }

  async updateImgDesc() {
    let inputUpdateData = {
      slug: this.state.slug ? this.state.slug : "",
      description: this.state.description ? this.state.description : "",
      // is_main_img: this.state.is_main_img === true ? 1 : 0,
      // is_web_img: this.state.is_web_img === true ? 1 : 0,
      refTableId: 3,
      propertySlug: this.state.property_slug,
      mediaType: 1,
    };
    let resMain;
    if (this.state.isCrop) {
      this.deletePropImg(this.state.currentFile.slug);
      let inputData = {
        fieldName: "modal_name",
        uploadedTo: "s3",
        slug: this.state.property_slug,
        refTableId: 3,
        destinationFolder: "properties",
        displayFileName: "properties",
        documentTypeSlug: this.state.property_slug,
        description: this.state.description,
        is_main_img: this.state.is_main_img,
        is_web_img: this.state.is_web_img,
        // is_main_img: is_main_img,
        mediaType: 1,
        h1: "",
        h2: "",
        sortOrder: this.state.currentFile.sort_order,
        statusId: this.state.currentFile.status_id,
        checkValidation: true,
        validationRules: {
          allowedType: "image/jpeg,image/jpg,image/png,image/webp",
          maxFileSize: 1000,
          maxFileSizeType: "mb",
          maxFileAllowed: 10,
          fieldName: "modal_name",
        },
        resizeOptions:
          "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200",
        fileObject: this.state.croppedImageArr,
      };
      let UploadCropedImageObj = new UploadCropedImage();
      resMain = await UploadCropedImageObj.UploadCropedImageToServer(inputData);
      let inputData1 = {
        availableFiles: resMain.response,
        // deletedFiles: this.state.currentFile,
      };
      let res = await MultiImageService.submitMultiImage(inputData1);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({
          description: "",
          is_main_img: "",
          is_web_img: "",
          img_src: "",
          editModeCropImg: "",
          croppedImage: "",
          zoom: 0.4,
          currentFile: null,
          isCrop: false,
          display_file_name: "",
          slug: "",
          openEditPropDescModal: false,
          isImageEdit: false,
        });
        this.setState({ newRelatedFilesDesc: [], dragOff: 0 });
        this.getMulitImagesData();
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          deleteImgModal: false,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    } else {
      resMain = await MultiImageService.updateSingleImageDesc(inputUpdateData);

      if (global.successStatus.includes(resMain.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: resMain.message ? resMain.message : "Success",
        });
        this.closeEditPropDescModal();
        this.setState({ propertyImageListTotal: 0, dragOff: 0 });
        this.getMulitImagesData();
      } else {
        let alertMessage = "";
        if (resMain.data.result.errorDetail !== null) {
          let errors = {};
          resMain.data.errorCode = "Validation Failed";
          resMain.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            resMain.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: resMain.data.errorCode
            ? resMain.data.errorCode
            : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : resMain.data.message,
        });
      }
    }

    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
    this.getData();
  }

  async getData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData.imageUrl = res.data.property_logo_url
          ? res.data.property_logo_url
          : "";
        resData.imgPropUrl = res.data.property_main_image_url
          ? res.data.property_main_image_url
          : "";
      }
      this.setState(resData);
    }
  }

  async deletePropImg(slug) {
    this.setState({ showSpinner: true });
    let inputData = {
      path: "properties",
      refTableId: 3,
      slug: this.state.property_slug,
      propertySlug: this.state.property_slug,
      mediaType: 1,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closeEditPropDescModal();
      this.closeEditImagePopupModal();
      this.closedeletePropImgModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({
        multiImageListEdit: [],
        reorderdImg: [],
        dragOff: 0,
      });
      this.getMulitImagesData(true);
    } else {
      this.closeEditImagePopupModal();
      this.closedeletePropImgModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  confirmImportModal(i) {
    document.body.className = "proplistimg";
    this.setState({ importModal: true });
    this.setState({ carouselZoom: i });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value = event.target.value;

    let propertyImageList = [...this.state.multiImageList];
    var propertyImageListTotal = 0;

    if (event.target.name === "description") {
      propertyImageList[index][event.target.name] = value;
      propertyImageListTotal = propertyImageList.length;
      this.setState({
        propertyImageList: propertyImageList,
        propertyImageListTotal: propertyImageListTotal,
      });
    } else {
      input[event.target.name] = value;
      this.setState({ [event.target.name]: value, input: input });
    }
  };

  changeHandlerDesc = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    this.getMulitImagesData();
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
    } else {
      this.setState({ viewMode: 1 });
    }
  }

  getChangedPos = (currentPos, newPos) => {
    let imgUpdatedArray = [];
    [...Array(this.state.imageLoop)].map((e, j) => {
      if (this.state.multiImageListEdit[j + 1] !== undefined) {
        imgUpdatedArray.push(this.state.multiImageListEdit[j + 1]);
      } else {
        imgUpdatedArray.push({});
      }
    });
    let newimgArray = arrayMoveImmutable(imgUpdatedArray, currentPos, newPos);
    newimgArray.map((item, k) => {
      if (newimgArray[k].sort_order !== undefined) {
        newimgArray[k].sort_order = k + 1;
      }
    });

    this.setState({ reorderdImg: newimgArray });
    this.reorderImageListing();
    // setTimeout(() => {
    //   this.setState({ reorderdImg: newimgArray });
    //   this.reorderImageListing();
    // }, 2);

    // var sortOrder = [];
    //  this.state.multiImageList.map((opt) => {
    //   sortOrder.push({slug : opt.slug, sort_order :opt.sort_order});
    // });
    //   this.setState({currentPos:currentPos+1, newPos:newPos+1 });
    //   const array1 = arrayMoveImmutable(sortOrder, this.state.currentPos, this.state.newPos);
    // // this.setState(({sortOrder}) => ({
    // //   sortOrder: arrayMove(sortOrder, this.state.currentPos, this.state.newPos),
    // // }));
  };

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  getTitle() {
    return "Gallery";
  }

  // onSortEnd = ({oldIndex, newIndex}) => {
  //   this.setState(({newMultiImageList}) => ({
  //     items: arrayMoveImmutable(newMultiImageList, oldIndex, newIndex),
  //   }));
  // };

  render() {
    // const imgWithClick = { cursor: "pointer" };
    const SortableItem = SortableElement(({ value }) => (
      <div className="list-img">
        <div className="addImg uploaded">
          <div className="label-img">
            {value.is_main_img === 1 ? (
              <img src={mainImg} alt="" title="Main image" />
            ) : (
              ""
            )}
            {value.is_web_img === 1 ? (
              <img src={webImg} alt="" title="Web image" />
            ) : (
              ""
            )}
          </div>

          <Dropdown className="d-flex justify-content-end">
            <Dropdown.Toggle variant="secondary-" className="pd-0">
              <div className="navMob d-flex align-items-center">
                <img src={dotIcon} alt="" width="35px" title="Edit" />
              </div>
            </Dropdown.Toggle>
            {global.userPermissions.checkPermission(
              "property-gallery-delete"
            ) ||
            global.userPermissions.checkPermission(
              "property-gallery-update"
            ) ? (
              <Dropdown.Menu className="tx-13">
                {global.userPermissions.checkPermission(
                  "property-gallery-delete"
                ) ? (
                  <Dropdown.Item
                    onClick={() => {
                      this.openEditPropDescModal(
                        value.slug,
                        value.file_name,
                        value.editModeCropImg,
                        value.description,
                        value.is_main_img === 1 ? true : false,
                        value.is_web_img === 1 ? true : false,
                        value.display_file_name,
                        value.sort_order,
                        value
                      );
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                ) : (
                  ""
                )}
                {value.is_main_img === 0 &&
                global.userPermissions.checkPermission(
                  "property-gallery-update"
                ) ? (
                  <Dropdown.Item
                    onClick={() =>
                      this.setCoverImg(value.slug, value.sort_order, "Main")
                    }
                  >
                    Set as main image
                  </Dropdown.Item>
                ) : (
                  ""
                )}
                {value.is_web_img === 0 &&
                global.userPermissions.checkPermission(
                  "property-gallery-update"
                ) ? (
                  <Dropdown.Item
                    onClick={() => this.setCoverImg(value.slug, "web")}
                  >
                    Set as web image
                  </Dropdown.Item>
                ) : (
                  ""
                )}
                {global.userPermissions.checkPermission(
                  "property-gallery-delete"
                ) ? (
                  <Dropdown.Item
                    onClick={() => this.opendeletePropImgModal(value.slug)}
                  >
                    Delete
                  </Dropdown.Item>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            ) : (
              ""
            )}
          </Dropdown>
          <figure>
            <img src={value.medium_img} alt="" title="Drag" />
          </figure>
        </div>
      </div>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="list-section d-flex flex-wrap">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${value.slug}`}
              sortIndex={index}
              index={index}
              value={value}
            />
          ))}
          {global.userPermissions.checkPermission("property-gallery-add") && (
            <div
              className="addImg addNewimage"
              onClick={() => this.openPropImgModal()}
            >
              <figure className="d-flex justify-content-center align-items-center flex-column h-100">
                <img src={addImg} alt="" className="plus" />
                <span>Add more</span>
              </figure>
            </div>
          )}
        </div>
      );
    });

    const onSortEnd = (oldIndex, newIndex) => {
      var oldIndex1 = oldIndex.oldIndex;
      var newIndex1 = oldIndex.newIndex;

      let newimgArray = arrayMoveImmutable(
        this.state.multiImageList,
        oldIndex1,
        newIndex1
      );

      this.setState({ multiImageList: newimgArray, sortOrderImg: newimgArray });
      newimgArray.map((item, k) => {
        if (newimgArray[k].sort_order !== undefined) {
          newimgArray[k].sort_order = k + 1;
        }
      });
      setTimeout(() => {
        this.reorderImageListing();
      }, 100);
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.viewMode === 0 ? (
                        <>
                          {global.userPermissions.checkPermission(
                            "property-gallery-update"
                          ) && (
                            <button
                              className="btn-success-outline-small"
                              onClick={this.changeView}
                            >
                              <img src={editIcon} alt="Edit" />
                              Edit
                            </button>
                          )}
                        </>
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={this.changeView}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-15">
                  <div className="tab-content">
                    <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-70 pd-lg-r-0 swipersectionBtns">
                      <div className="col-md-12 pd-l-15 pd-r-15 mg-t-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      {this.state.viewMode === 0 ? (
                        <React.Fragment>
                          <div className="row row-xs align-items-center justify-content-center pd-t-30 pd-b-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-0 mg-l-0 addmultiImages">
                            {this.state.multiImageListTotal > 0 ? (
                              <React.Fragment>
                                <Gallery
                                  backdropClosesModal={false}
                                  preloadNextImage={true}
                                  images={
                                    this.state.newMultiImageList
                                      ? this.state.newMultiImageList
                                      : ""
                                  }
                                  enableImageSelection={false}
                                  showImageCount={false}
                                />
                                {/* <ul className="d-flex flex-wrap">
                                {this.state.multiImageListTotal > 0
                                  ? this.state.multiImageList.map(
                                    (option, i) => (
                                      <li key={i}>
                                        <div className="avatar-squre-listing mg-b-0">
                                          <figure>
                                            <img
                                              src={deleteImg}
                                              className="del-img"
                                              alt="delete image"
                                              onClick={() =>
                                                this.opendeletePropImgModal(
                                                  option.slug
                                                )
                                              }
                                            />
                                            <Link
                                              to={"#"}
                                              className="overlay"
                                              onClick={() =>
                                                this.confirmImportModal(i)
                                              }
                                            >
                                              <span></span>
                                              <img
                                                src={
                                                  option.medium_img
                                                    ? option.medium_img
                                                    : ""
                                                }
                                                alt="Other Images"
                                              />
                                            </Link>
                                          </figure>
                                        </div>
                                      </li>
                                    )
                                  )
                                  : ""}
                              </ul> */}
                              </React.Fragment>
                            ) : (
                              <NoData msg="images" action=" Edit " />
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}

                      {/* properties Property images modal */}
                      <Modal
                        show={this.state.importModal}
                        onHide={() => this.closeConfirmImportModal()}
                        centered
                        className="propertylistmodal"
                      >
                        <Modal.Body>
                          <span
                            className="welcomeClose"
                            onClick={() => this.closeConfirmImportModal()}
                          >
                            <img src={close} alt="" />
                          </span>
                          <Carousel
                            activeIndex={this.state.carouselZoom}
                            onSelect={this.confirmImportModal}
                            prevIcon={
                              this.state.multiImageListTotal === 1 ? (
                                false
                              ) : (
                                <span
                                  aria-hidden="true"
                                  className="carousel-control-prev-icon"
                                />
                              )
                            }
                            nextIcon={
                              this.state.multiImageListTotal === 1 ? (
                                false
                              ) : (
                                <span
                                  aria-hidden="true"
                                  className="carousel-control-next-icon"
                                />
                              )
                            }
                          >
                            {this.state.multiImageListTotal > 0
                              ? this.state.multiImageList.map((option, i) => (
                                  <Carousel.Item key={i}>
                                    <div className="d-flex- flex-column position-relative- captionImg-">
                                      <img
                                        src={
                                          option.file_name
                                            ? option.file_name
                                            : ""
                                        }
                                        className="d-block w-100 op-3"
                                        alt="..."
                                      />
                                      {option.description !== undefined &&
                                      option.description !== null &&
                                      option.description !== "" &&
                                      option.description !== "undefined" ? (
                                        <Carousel.Caption className="position-abosolute">
                                          <p>
                                            {option.description
                                              ? option.description
                                              : ""}
                                          </p>
                                        </Carousel.Caption>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </Carousel.Item>
                                ))
                              : ""}
                          </Carousel>
                        </Modal.Body>
                      </Modal>

                      {/* properties logo images modal */}
                      <Modal
                        show={this.state.propertyLogoModal}
                        onHide={() => this.closePropertyLogoModal()}
                        centered
                        className="singlemodal"
                      >
                        <Modal.Body>
                          <span
                            className="welcomeClose"
                            onClick={() => this.closePropertyLogoModal()}
                          >
                            <img src={close} alt="" />
                          </span>
                          <img
                            src={
                              this.state.imageUrl
                                ? this.state.imageUrl
                                : propertyLogo
                            }
                            alt="Property Listing"
                          />
                        </Modal.Body>
                      </Modal>

                      {/* properties Property image images modal */}
                      <Modal
                        show={this.state.propertyListImageModal}
                        onHide={() => this.closePropertyListImageModal()}
                        centered
                        className="propsinglemodal"
                      >
                        <Modal.Body>
                          <span
                            className="welcomeClose"
                            onClick={() => this.closePropertyListImageModal()}
                          >
                            <img src={close} alt="close" />
                          </span>
                          <img
                            src={
                              this.state.imgPropUrl
                                ? this.state.imgPropUrl
                                : propertyImg
                            }
                            alt="Property Logo"
                          />
                        </Modal.Body>
                      </Modal>

                      {this.state.viewMode === 1 ? (
                        <>
                          {/* new Design add images */}
                          <div className="row row-xs align-items-center pd-t-20 pd-b-30  pd-xs-l-15 pd-xs-r-0 pd-lg-l-8 pd-lg-r-0 addmultiImages">
                            <SortableList
                              items={this.state.multiImageList}
                              onSortEnd={onSortEnd}
                              axis={"xy"}
                              distance={1}
                              // useDragHandle="true"
                              //pressDelay={200}
                              pressThreshold={5}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deletePropImgModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Are you sure?</h3>
              <p>
                Do you really want to delete this image? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeletePropImgModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                  onClick={() => this.deletePropImg(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Add image popup */}

        <Modal show={this.state.addImgModal} centered>
          <Modal.Body className="width_402 border_radius_14">
            <form id="register_from" className="text-left">
              <div>
                <figure className="mg-auto m-auto text-center">
                  <FileWidget
                    fieldName="modal_name"
                    fieldTitle="Drop image here"
                    fieldLabel="Drop image here"
                    checkValidation="true"
                    allowedType="image/jpg,image/jpeg,image/png"
                    maxFileSize={2}
                    maxFileSizeType="mb"
                    maxFileAllowed={10}
                    uploadOn="s3"
                    slug={this.state.property_slug}
                    refTableId={3}
                    displayFileName="properties"
                    documentTypeSlug={this.state.property_slug}
                    description={this.state.description}
                    mediaType={1}
                    sortOrder={this.state.multiImageListTotal}
                    statusId={1}
                    helpText="to download sample file."
                    sampleFileUrl="/sample.jpg"
                    drag="true"
                    dragText="Drop image here"
                    inputClassName="form-control"
                    labelClassName="form-label"
                    formID="register_from"
                    uploadType="multiple"
                    resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
                    destinationFolder="properties"
                    propertySlug=""
                    uploadedFiles={this.state.profile_image2}
                    deletedFiles={this.state.deletedFileList}
                    acceptOnly="image/jpg,image/jpeg,image/png"
                    displayPreviewStyle="vertical"
                    deleteImgModal={this.state.addImgModal}
                    newRelatedFilesDescChange={this.newRelatedFilesDescChange}
                    newRelatedFilesMainImgChange={
                      this.newRelatedFilesMainImgChange
                    }
                    isModal="yes"
                    closePropImgModal={this.closePropImgModal}
                    dailyDelivery={0}
                    validationFire={0}
                  />
                </figure>
                <label className="text-left">Description</label>
                <Form.Control
                  onChange={this.changeHandlerDesc}
                  name="description"
                  value={this.state.description}
                  className="form-control max_width_100 crop-text"
                  type="text"
                  rows="4"
                  as="textarea"
                  autoComplete="off"
                  resize="resize"
                  maxLength="255"
                />
                <div className="clearfix"> </div>
                <div className="d-flex justify-content-between mt-3 row">
                  <button
                    type="button"
                    className="mx-auto m-0 btn btn-outline-light btn-block"
                    onClick={() => this.closePropImgModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="mx-auto m-0 btn btn btn-success btn-block"
                    onClick={(e) => this.submitModalImage(e)}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* New cropper modalbox */}

        <Modal
          show={this.state.openEditPropDescModal}
          onHide={this.closeEditPropDescModal}
          centered
          className="cropModal"
        >
          <Modal.Header>
            <h2>Edit Photo</h2>
            <img
              src={close}
              onClick={() => this.closeEditPropDescModal()}
              className="mb-0"
            />
          </Modal.Header>
          <Modal.Body className="width_402 p-2  pt-4  pb-4 border_radius_14 modal-body">
            <div className="col-md-7">
              {this.state.isImageEdit ? (
                <figure className="mg-auto m-auto text-center">
                  <Cropper
                    // minZoom={0.1}
                    image={this.state.editModeCropImg}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={this.state.aspect}
                    restrictPosition={true}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                    objectFit="auto-cover" //"horizontal-cover" //"vertical-cover" "contain" "auto-cover"
                  />
                </figure>
              ) : (
                <figure className="mg-auto m-auto text-center">
                  <img
                    src={
                      this.state.croppedImage
                        ? this.state.croppedImage
                        : this.state.img_src
                    }
                    alt=""
                    className="w-100"
                  />
                </figure>
              )}
            </div>
            <div className="col-md-5 pr-3">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="description"
              >
                <Nav variant="pills" className="flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey="description" onClick={this.EditImgSet}>
                      Description
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Edit" onClick={this.cropperSet}>
                      Edit
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="description">
                    <label className="form-label text-left pd-b-0 mb-0 mg-l-5">
                      Description
                    </label>
                    <Form.Control
                      onChange={this.changeHandlerDesc}
                      name="description"
                      value={this.state.description}
                      className="form-control max_width_100 crop-text"
                      type="text"
                      rows="2"
                      as="textarea"
                      autoComplete="off"
                      resize="resize"
                    />
                    {/* <div className="d-flex  align-items-center mg-t-20 mg-b-10">
                      <Form.Check
                        name="is_main_img"
                        type={"checkbox"}
                        id={`is_main_img`}
                        label={""}
                        className={"ckbox"}
                        onChange={this.changeHandlerDesc}
                        checked={this.state.is_main_img}
                      />
                      <label className="form-label text-left pd-b-0 mb-0 mg-l-5">
                        Main Image
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        name="is_web_img"
                        type={"checkbox"}
                        id={`is_web_img`}
                        label={""}
                        className={"ckbox"}
                        onChange={this.changeHandlerDesc}
                        checked={this.state.is_web_img}
                      />
                      <label className="form-label text-left pd-b-0 mb-0 mg-l-5">
                        Web Image
                      </label>
                    </div> */}
                  </Tab.Pane>
                  <Tab.Pane eventKey="Edit" className="d-flex">
                    <div className="controls d-flex w-100 flex-column">
                      <p className="az-profile-name-text pl-0">Zoom</p>
                      <Slider
                        value={this.state.zoom}
                        min={1}
                        max={10}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => this.onZoomChange(zoom)}
                        // classes={{ container: "slider" }}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between w-100 m-0 pl-2 pr-2  ">
              <img
                src={deleteImg}
                alt=""
                onClick={() => this.opendeletePropImgModal(this.state.slug)}
                className="removeFile btn-multilist-outline-danger w-auto mt-2 mb-0 hand-cursor"
              />
              {this.state.isImageEdit ? (
                <>
                  <button
                    type="button"
                    className="mx-auto m-0 btn btn-outline-light btn-block"
                    onClick={this.cancelCrop}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="m-0 btn col-md-3 btn btn-success btn-block"
                    onClick={this.cropImage}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Edit Image"}
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="mx-auto m-0 btn btn-outline-light btn-block"
                    onClick={() => this.closeEditPropDescModal()}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="m-0 btn btn btn-success btn-block"
                    onClick={() => this.updateImgDesc(this.state.slug)}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Save"}
                  </button>
                </>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.editImagePopupModal}
          onHide={() => {
            this.closeEditImagePopupModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <span
                className="welcomeClose"
                onClick={() => this.closeEditImagePopupModal()}
              >
                {global.closee}
              </span> */}
              <ul>
                <li
                  className="hand-cursor"
                  onClick={() => {
                    this.openEditPropDescModal(
                      this.state.slug,
                      this.state.img_src,
                      this.state.description,
                      this.state.is_main_img,
                      this.state.is_web_img,
                      this.state.display_file_name
                    );
                    // this.imgWithClick()
                  }}
                >
                  Edit
                </li>
                {this.state.is_main_img === false ? (
                  <li
                    className="hand-cursor"
                    onClick={() => this.setCoverImg(this.state.slug, "Main")}
                  >
                    Make Main Image
                  </li>
                ) : (
                  ""
                )}
                {this.state.is_web_img === false ? (
                  <li
                    className="hand-cursor"
                    onClick={() => this.setCoverImg(this.state.slug, "web")}
                  >
                    Make Web Image
                  </li>
                ) : (
                  ""
                )}
                <li
                  className="hand-cursor"
                  onClick={() => this.opendeletePropImgModal(this.state.slug)}
                >
                  Delete
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default Images;

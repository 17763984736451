/////////////////////////////////////////////////////
//     							                   //
//  Program: GuestManual.jsx                       //
//  Application: Property                          //
//  Option: List all Property Guest Manual info    //
//  Developer:NP					               //
//  Date: 2022-09-15                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import renderHTML from "react-render-html";
import { Container } from "react-bootstrap";
import JoditEditor from 'jodit-react';
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import PropertySidebar from "../common/PropertySidebar";
import NoData from "../common/NoData";
export class GuestManual extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      property_slug: this.props.match.params.propertySlug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      confirm_back: 0,
      descShow: 0,
      descShowRules: 0,
      descShowHealthSafety: 0,
      descShowGettingAround: 0,
    };
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.fullTextRules = this.fullTextRules.bind(this);
    this.setContent=this.setContent.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    this.getData();
    this.setState({ showSpinner: false, loading: false });
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data ? res.data : {};
        resData.slug = res.data && res.data.slug ? res.data.slug : ""
        resData.guest_manual = res.data && res.data.guest_manual ? res.data.guest_manual : ""
        resData.property_name = res.data && res.data.property_name ? res.data.property_name : ""
        resData.aka_name = res.data && res.data.aka_name ? res.data.aka_name : ""
      }

      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  // Custom validation rules
  customValidate() {
    let isValid = true;
    return isValid;
  }

  saveOrUpdateProperty = async (e) => {
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        tab: "guest-manual",
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        // description: this.state.description,
        guest_manual: this.state.guest_manual,
        // health_safety: this.state.health_safety,
        // getting_around: this.state.getting_around,
      };

      if (this.state.slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.setState({ viewMode: 0 });
        } else {
          //let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      } else {
        let res = await PropertyService.createProperty(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.setState({ viewMode: 0 });
        } else {
          // let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      }
    }
  };

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.getData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.getData();
    }
  }

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  getTitle() {
    return "House Manual";
  }

  setContent(value){
    this.setState({
      guest_manual:value
    })
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.viewMode === 0 ? (
                        global.userPermissions.checkPermission(
                          "guest-manual-update"
                        ) && (
                          <button
                            className="btn-success-outline-small"
                            onClick={this.changeView}
                          >
                            <img src={editIcon} alt="" />
                            Edit
                          </button>
                        )
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.changeView();
                                else return false;
                              else this.changeView();
                            }}
                          >
                            Cancel
                          </button>
                          {global.userPermissions.checkPermission(
                            "guest-manual-update"
                          ) && (
                              <button
                                className="btn-success ml-3"
                                onClick={this.saveOrUpdateProperty}
                              >
                                Save
                              </button>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                    <div className="col-md-12 pd-l-15 pd-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        // show="true"
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.viewMode === 0 ? (
                      <>
                      {this.state.guest_manual ?
                        <div className="row mg-b-15">
                          <div className="col-md-12 pd-l-30 pd-r-30 pd-lg-t-25">
                            <p className="az-profile-name-text pl-0">
                              House Manual:
                            </p>
                            <div className="para">
                              {this.state.guest_manual ? ( //check if guest_manual is exist
                                this.state.guest_manual.length >
                                global.showMoreLength ? ( //check if length is long
                                  this.state.descShowRules === 0 ? (
                                    <>
                                      <HTMLEllipsis
                                        unsafeHTML={
                                          this.state.guest_manual
                                            ? this.state.guest_manual
                                            : ""
                                        }
                                        maxLine="6"
                                        ellipsis=""
                                        trimright="true"
                                        basedOn="letters"
                                      />
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={this.fullTextRules}
                                      >
                                        Show More
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      {renderHTML("" + this.state.guest_manual)}
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={this.fullTextRules}
                                      >
                                        Show Less
                                      </p>
                                    </>
                                  )
                                ) : (
                                  renderHTML("" + this.state.guest_manual)
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        : <NoData
                        msg={global.commonMessages.checkMessage(
                          "GUESTMANNUAL"
                        )}
                          action=" Edit "
                        /> }
                      </>
                    ) : (
                      ""
                    )}

                    {this.state.viewMode === 1 ? (
                      <div className="m-0">
                        <div className="row align-items-center pd-t-25 pd-b-30 border-bottom-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15 mg-b-15 ">
                            <label className="form-label text-left pd-b-5">
                              House Manual{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            {/* <CKEditor
                              editor={ClassicEditor}
                              config={{
                                removePlugins: [
                                  "EasyImage",
                                  "ImageUpload",
                                  "MediaEmbed",
                                  "Table",
                                ],
                              }}
                              name="guest_manual"
                              id="guest_manual"
                              minHeight="50px"
                              data={this.state.guest_manual}
                              onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                  writer.setStyle(
                                    "height",
                                    "200px",
                                    editor.editing.view.document.getRoot()
                                  );
                                });
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                this.changeHandler({
                                  target: {
                                    type: "ckeditor",
                                    name: "guest_manual",
                                    value: data,
                                  },
                                });
                              }}
                            /> */}
                            
                            <JoditEditor
                              ref={null}
                              name="guest_manual"
                              id="guest_manual"
                              value={this.state.guest_manual}
                              // config={readonly:false}
                              // tabIndex={1} // tabIndex of textarea
                              onBlur={newContent => this.setContent(newContent)}
                              onChange={newContent => this.setContent(newContent)}
                            />

                            {this.state.errors.guest_manual ? (
                              <div className="text-danger">
                                {this.state.errors.guest_manual}
                              </div>
                            ) : (
                              this.validator.message(
                                "guest_manual",
                                this.state.guest_manual,
                                "max:100000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default GuestManual;
//     							                          //
//  Program: MasterSidebar.jsx                            //
//  Application: Master                                   //
//  Option: common component Master Sidebar               //
//  Developer: NP 		                                  //
//  Date: 2022-03-31                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";
class MasterSidebar extends Component {
  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.remove("d-none");
        document.querySelector(".newSubmenu").classList.remove("d-none");
      } else {
        data.classList.replace("left", "hide");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.add("d-none");
        document.querySelector(".newSubmenu").classList.add("d-none");
      }
    }
  }

  toggleChildMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector(".newSubmenu").classList.toggle("d-none");
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
      } else {
        data.classList.replace("left", "hide");
      }
    }
  }

  render() {
    return (
      <>
        <div className="mobilesidebarsettingNavbar">
          <div className="mobchildMenu">
            <div className="d-flex justify-content-between align-items-center submenu">
              {/* New submenu */}
              <div className="newSubmenu">
                {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Master Data</a> */}
                <span
                  className="back"
                  onClick={(event) => this.toggleHeaderMenu(event)}
                >
                  <i className="fas fa-chevron-left"></i>Back to Master Data
                </span>
                <ul>
                  <li>
                    <span>Property Attributes</span>
                    <ul>
                      <li>
                        <Link
                          to="/accessibility-feature-type"
                          className={
                            this.isPathActive("/accessibility-feature-type") ||
                            this.isPathActive("/master-setup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Accessibility Feature Types
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/accessibility-features"
                          className={
                            this.isPathActive("/accessibility-features")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Accessibility Features
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/affordable-type"
                          className={
                            this.isPathActive("/affordable-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Affordable Type
                        </Link>
                      </li>

                      <li>
                        <Link
                          data-toggle="tab"
                          to="/agency-category"
                          className={
                            this.isPathActive("/agency-category")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Agency Category
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/amenity-type"
                          className={
                            this.isPathActive("/amenity-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Amenity Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/amenities"
                          className={
                            this.isPathActive("/amenities")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Amenities
                        </Link>
                      </li>

                      {/* <li><Link data-toggle="tab" to="/attraction-type" className={this.isPathActive("/attraction-type") ? "nav-link active" : "nav-link"} >
                                          Attraction Type
                                        </Link></li> */}

                      {/* <li>
                        <Link
                          data-toggle="tab"
                          to="/templates"
                          className={
                            this.isPathActive("/templates")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Checklist Templates
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/channel"
                          className={
                            this.isPathActive("/channel")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Channels
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/class-type"
                          className={
                            this.isPathActive("/class-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Class Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/document-type"
                          className={
                            this.isPathActive("/document-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Document Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/event-type"
                          className={
                            this.isPathActive("/event-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Event Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/flooring-type"
                          className={
                            this.isPathActive("/flooring-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Flooring Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/heating-type"
                          className={
                            this.isPathActive("/heating-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Heating Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/faq-category"
                          className={
                            this.isPathActive("/faq-category")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          FAQ Category
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/faq-sub-category"
                          className={
                            this.isPathActive("/faq-sub-category")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          FAQ Sub Category
                        </Link>
                      </li>

                      <li>
                        <Link
                          data-toggle="tab"
                          to="/leed-type"
                          className={
                            this.isPathActive("/leed-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          LEED Certification Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/location-type"
                          className={
                            this.isPathActive("/location-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Location Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/mro-category"
                          className={
                            this.isPathActive("/mro-category")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          MRO Category
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/ownership-type"
                          className={
                            this.isPathActive("/ownership-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Ownership Type
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          data-toggle="tab"
                          to="/parking-type"
                          className={
                            this.isPathActive("/parking-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Parking Type
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/pet-type"
                          className={
                            this.isPathActive("/pet-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Pet Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/property-type"
                          className={
                            this.isPathActive("/property-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Property Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/room-category"
                          className={
                            this.isPathActive("/room-category")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Room Category
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/room-type"
                          className={
                            this.isPathActive("/room-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Room Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/smoking-type"
                          className={
                            this.isPathActive("/smoking-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Smoking Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/staff-type"
                          className={
                            this.isPathActive("/staff-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Staff Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/suitability"
                          className={
                            this.isPathActive("/suitability")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Suitability
                        </Link>
                      </li>

                      <li>
                        <Link
                          data-toggle="tab"
                          to="/timezone"
                          className={
                            this.isPathActive("/timezone")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Timezone
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>Financial Attributes</span>
                    <ul>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/cost-payable-at"
                          className={
                            this.isPathActive("/cost-payable-at")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Cost Payable At
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/cost-payable-by"
                          className={
                            this.isPathActive("/cost-payable-by")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Cost Payable By
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/cost-type"
                          className={
                            this.isPathActive("/cost-type")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Fee Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/taxes"
                          className={
                            this.isPathActive("/taxes")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Taxes
                        </Link>
                      </li>
                      {/* <li><Link data-toggle="tab" to="/tax-type" className={this.isPathActive("/tax-type") ? "nav-link active" : "nav-link"} >
                                          Tax Type
                                        </Link></li> */}
                    </ul>
                  </li>
                  <li>
                    <span>Location Attributes</span>
                    <ul>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/country"
                          className={
                            this.isPathActive("/country")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Country
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/state"
                          className={
                            this.isPathActive("/state")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          State
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/county"
                          className={
                            this.isPathActive("/county")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          County
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/city"
                          className={
                            this.isPathActive("/city")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          City
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/metro"
                          className={
                            this.isPathActive("/metro")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Metro
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/sub-metro"
                          className={
                            this.isPathActive("/sub-metro")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Sub Metro
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>RU Mapping Master</span>
                    <ul>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/floor-plans-lookup"
                          className={
                            this.isPathActive("/floor-plans-lookup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Floor Plan Mapping
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/amenity-lookup"
                          className={
                            this.isPathActive("/amenity-lookup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Amenities Mapping
                        </Link>
                      </li>
                      <li>
                        <Link
                          data-toggle="tab"
                          to="/near-by-lookup"
                          className={
                            this.isPathActive("/near-by-lookup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Near By Mapping
                        </Link>

                        <Link
                          data-toggle="tab"
                          to="/fee-lookup"
                          className={
                            this.isPathActive("/fee-lookup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Fees Mapping
                        </Link>
                        <Link
                          data-toggle="tab"
                          to="/master-tax-lookup"
                          className={
                            this.isPathActive("/master-tax-lookup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Master Taxes Mapping
                        </Link>
                        <Link
                          data-toggle="tab"
                          to="/taxes-lookup"
                          className={
                            this.isPathActive("/taxes-lookup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Taxes Mapping
                        </Link>
                        <Link
                          data-toggle="tab"
                          to="/countries-lookup"
                          className={
                            this.isPathActive("/countries-lookup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Countries Mapping
                        </Link>
                        <Link
                          data-toggle="tab"
                          to="/lookup-states"
                          className={
                            this.isPathActive("/lookup-states")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          States Mapping
                        </Link>
                        <Link
                          data-toggle="tab"
                          to="/cities-lookup"
                          className={
                            this.isPathActive("/cities-lookup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Cities Mapping
                        </Link>
                        <Link
                          data-toggle="tab"
                          to="/channels-lookup"
                          className={
                            this.isPathActive("/channels-lookup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Channels Mapping
                        </Link>
                        <Link
                          data-toggle="tab"
                          to="/properties-ota-lookup"
                          className={
                            this.isPathActive("/properties-ota-lookup")
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Properties Ota Mapping
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="desksidebarsettingNavbar">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i> Back to Master Data
          </div>
          <nav className="nav nav-pills flex-column pd-r-8">
            <ul>
              <li>
                <span>Property Attributes</span>
                <ul>
                  <li>
                    <Link
                      to="/accessibility-feature-type"
                      className={
                        this.isPathActive("/accessibility-feature-type") ||
                        this.isPathActive("/master-setup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Accessibility Feature Types
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/accessibility-features"
                      className={
                        this.isPathActive("/accessibility-features")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Accessibility Features
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/affordable-type"
                      className={
                        this.isPathActive("/affordable-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Affordable Type
                    </Link>
                  </li>

                  <li>
                    <Link
                      data-toggle="tab"
                      to="/agency-category"
                      className={
                        this.isPathActive("/agency-category")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Agency Category
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/amenity-type"
                      className={
                        this.isPathActive("/amenity-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Amenity Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/amenities"
                      className={
                        this.isPathActive("/amenities")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Amenities
                    </Link>
                  </li>

                  {/* <li><Link data-toggle="tab" to="/attraction-type" className={this.isPathActive("/attraction-type") ? "nav-link active" : "nav-link"} >
                            Attraction Type
                          </Link></li> */}

                  {/* <li>
                    <Link
                      data-toggle="tab"
                      to="/templates"
                      className={
                        this.isPathActive("/templates")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Checklist Templates
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/channel"
                      className={
                        this.isPathActive("/channel")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Channels
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/class-type"
                      className={
                        this.isPathActive("/class-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Class Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/document-type"
                      className={
                        this.isPathActive("/document-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Document Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/event-type"
                      className={
                        this.isPathActive("/event-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Event Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/flooring-type"
                      className={
                        this.isPathActive("/flooring-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Flooring Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/heating-type"
                      className={
                        this.isPathActive("/heating-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Heating Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/faq-category"
                      className={
                        this.isPathActive("/faq-category")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      FAQ Category
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/faq-sub-category"
                      className={
                        this.isPathActive("/faq-sub-category")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      FAQ Sub Category
                    </Link>
                  </li>

                  <li>
                    <Link
                      data-toggle="tab"
                      to="/leed-type"
                      className={
                        this.isPathActive("/leed-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      LEED Certification Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/location-type"
                      className={
                        this.isPathActive("/location-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Location Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/mro-category"
                      className={
                        this.isPathActive("/mro-category")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      MRO Category
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/ownership-type"
                      className={
                        this.isPathActive("/ownership-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Ownership Type
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      data-toggle="tab"
                      to="/parking-type"
                      className={
                        this.isPathActive("/parking-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Parking Type
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/pet-type"
                      className={
                        this.isPathActive("/pet-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Pet Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/property-type"
                      className={
                        this.isPathActive("/property-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Property Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/room-category"
                      className={
                        this.isPathActive("/room-category")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Room Category
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/room-type"
                      className={
                        this.isPathActive("/room-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Room Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/smoking-type"
                      className={
                        this.isPathActive("/smoking-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Smoking Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/staff-type"
                      className={
                        this.isPathActive("/staff-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Staff Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/suitability"
                      className={
                        this.isPathActive("/suitability")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Suitability
                    </Link>
                  </li>

                  <li>
                    <Link
                      data-toggle="tab"
                      to="/timezone"
                      className={
                        this.isPathActive("/timezone")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Timezone
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Financial Attributes</span>
                <ul>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/cost-payable-at"
                      className={
                        this.isPathActive("/cost-payable-at")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Cost Payable At
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/cost-payable-by"
                      className={
                        this.isPathActive("/cost-payable-by")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Cost Payable By
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/cost-type"
                      className={
                        this.isPathActive("/cost-type")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Fee Type
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/taxes"
                      className={
                        this.isPathActive("/taxes")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Taxes
                    </Link>
                  </li>
                  {/* <li><Link data-toggle="tab" to="/tax-type" className={this.isPathActive("/tax-type") ? "nav-link active" : "nav-link"} >
                            Tax Type
                          </Link></li> */}
                </ul>
              </li>
              <li>
                <span>Location Attributes</span>
                <ul>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/country"
                      className={
                        this.isPathActive("/country")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Country
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/state"
                      className={
                        this.isPathActive("/state")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      State
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/county"
                      className={
                        this.isPathActive("/county")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      County
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/city"
                      className={
                        this.isPathActive("/city")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      City
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/metro"
                      className={
                        this.isPathActive("/metro")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Metro
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/sub-metro"
                      className={
                        this.isPathActive("/sub-metro")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Sub Metro
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>RU Mapping Master</span>
                <ul>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/floor-plans-lookup"
                      className={
                        this.isPathActive("/floor-plans-lookup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Floor Plans Mapping
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/amenity-lookup"
                      className={
                        this.isPathActive("/amenity-lookup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Amenities Mapping
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-toggle="tab"
                      to="/near-by-lookup"
                      className={
                        this.isPathActive("/near-by-lookup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Near By Mapping
                    </Link>
                    <Link
                      data-toggle="tab"
                      to="/fee-lookup"
                      className={
                        this.isPathActive("/fee-lookup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Fees Mapping
                    </Link>
                    <Link
                      data-toggle="tab"
                      to="/master-tax-lookup"
                      className={
                        this.isPathActive("/master-tax-lookup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Master Taxes Mapping
                    </Link>
                    <Link
                      data-toggle="tab"
                      to="/taxes-lookup"
                      className={
                        this.isPathActive("/taxes-lookup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Taxes Mapping
                    </Link>
                    <Link
                      data-toggle="tab"
                      to="/countries-lookup"
                      className={
                        this.isPathActive("/countries-lookup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Countries Mapping
                    </Link>
                    <Link
                      data-toggle="tab"
                      to="/lookup-states"
                      className={
                        this.isPathActive("/lookup-states")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      States Mapping
                    </Link>
                    <Link
                      data-toggle="tab"
                      to="/cities-lookup"
                      className={
                        this.isPathActive("/cities-lookup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Cities Mapping
                    </Link>
                    <Link
                      data-toggle="tab"
                      to="/channels-lookup"
                      className={
                        this.isPathActive("/channels-lookup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Channels Mapping
                    </Link>
                    <Link
                      data-toggle="tab"
                      to="/properties-ota-lookup"
                      className={
                        this.isPathActive("/properties-ota-lookup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Properties Ota Mapping
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </>
    );
  }
}
export default MasterSidebar;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: InquiryService.js                            //
//  Application: Inquiry                                  //
//  Option: Used for manage Lead                          //
//  Developer: Ashish Kumar						                    //
//  Date: 2023-07-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class InquiryService {
  getInquiryList(queryString) {
    let endPoint = "inquiry" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getInquiry(slug) {
    let endPoint = "inquiry/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new InquiryService();

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: KeyView.jsx                                //
//  Application:  keycafe                                  //
//  Option: View key                       //
//  Developer: Ravi                          //
//  Date: 2023-03-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component} from "react";
// import { Modal} from "react-bootstrap";
import { Container } from "react-bootstrap";
import Alerts from "../../common/Alerts";
import SimpleReactValidator from "simple-react-validator";
import KeycafeService from "../../../services/KeycafeService";
import ReactDatatable from "@ashvin27/react-datatable";
// import information_img from "./../../../assets/images/information-button.png";
// import { Link } from "react-router-dom";
import Moment from "moment";

export class KeyLogs extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      input: {},
      errors: {},
      open: true,
      eventKey: "3",
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      unitList: [],

      pathname: window.location.pathname,

      email: "",
      email_slug: "",
      start_time: "00:00AM",
      end_time: "00:00AM",
      isLoaded: false,
      //   unit_name: "",
      access_start_date: "",
      access_end_date: "",
      access_code: "",
      key_name: "",
      keycafe_key_id: "",
      fob: "",
      acess_end_time: "",
      access_start_time: "",
      keycafe_access_id: "",
      key_type: "",
      
      columns: [
        {
            key: "created_at",
            text: "Updated On",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.created_at ? Moment(record.created_at).format(global.dateTimeFormat) : "";
            },
        },
        {
            key: "access_given_name",
            text: "Access Name",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.access_given_name ? record.access_given_name : "";
            },
        },
        {
            key: "access_id",
            text: "Access ID",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.access_id ? record.access_id : "";
            },
        },
        {
            key: "access_method",
            text: "Booking Method",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.access_method ? record.access_method : "";
            },
        },
        {
            key: "bin_no",
            text: "Bin",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.bin_no ? record.bin_no : "";
            },
        },
        {
            key: "box_id",
            text: "Box ID",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.box_id ? record.box_id : "";
            },
        },
        {
            key: "exchanger_name",
            text: "Exchanger Name",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.exchanger_name ? record.exchanger_name : "";
            },
        },
        {
            key: "key_id",
            text: "Key ID",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.key_id ? record.key_id : "";
            },
        },
        {
            key: "key_name",
            text: "Key Name",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.key_name ? record.key_name : "";
            },
        },
        {
            key: "serial_number",
            text: "Serial Name",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.serial_number ? record.serial_number : "";
            },
        },
        {
            key: "type",
            text: "Status",
            className: "available_from hand-cursor",
            sortable: false,
            cell: (record) => {
                return record.type ? record.type : "";
            },
        },
 
      ],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found.",
        },
      },
      
    };
  }
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () =>
            this.getDataa(queryString + "&tab=" + this.state.tab + "&is_list=1"),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getDataa(queryString + "&tab=" + this.state.tab + "&is_list=1");
    }
  };
 
  async getDataa(tab,queryString = "") {
    if (this.state.slug !== undefined) {
      var resData = {};
      var totalRecords = 0;
      let res = await KeycafeService.getKeycafeLogs(
        "property_slug=" +
          this.state.property_slug +
          "&key_slug=" +
          this.state.slug +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          (queryString ? "&" + queryString : "")
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data.data : {};
        totalRecords = res.data ? res.data.total : 0;
      }
      this.setState({
        total_record: totalRecords,
        records: resData,
        isLoaded: true,
        // unit_name: resData.unit_name ? resData.unit_name : "",
      });
    }
  }

  async componentDidMount() {
    this.getDataa();
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Keycafe Logs";
    } else {
      return "Keycafe Logs";
    }
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                  </div>
                </div>
                <div className="scrolling-carousel pd-lg-l-15 pd-sm-l-30 pd-sm-r-30 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                  </div>
                  <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
                    <div className="pd-lg-l-0 pd-xs-l-15">
                      <ReactDatatable
                        className="table no-wrap mg-b-30 pd-b-2 text-align-left table_last_row"
                        config={
                          this.state.total_record > global.page_size
                            ? this.state.config
                            : this.state.config
                        }
                        records={this.state.records}
                        columns={this.state.columns}
                        extraButtons={this.state.extraButtons}
                        dynamic={true}
                        total_record={this.state.total_record}
                        onChange={this.tableChangeHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
       
      </main>
    );
  }
}

export default KeyLogs;

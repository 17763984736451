////////////////////////////////////////////////////////////
//     							                          //
//  Program: EmailService.js                              //
//  Application: Email                                    //
//  Option: Used for manage Emails                        //
//  Developer: NP 						                  //
//  Date: 2022-12-15                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class EmailService {

  getEmailTemplateList(queryString) {
    let endPoint = "email-template" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getstaff(queryString) {
    let endPoint = "get-staff" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getEmailTemplate(slug) {
    let endPoint = "get-email-template/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createEmailTemplate(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "email-template-store";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  testSendEmail(data) {
    let endPoint = "test-send-mail";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateEmailTemplate(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "post-email-template/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteEmailTemplate(slug) {
    let endPoint = "email-template/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadEmailTemplateAttachment(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'upload-email-template-attachment';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      'endPoint': endPoint,
      'addditionalHeaderData': addditionalHeaderData,
      'postData': postData
    }
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new EmailService();

//     							                               //
//  Program: Users.jsx                                         //
//  Application: users                                         //
//  Option: Users Listing                                      //
//  Developer: Mitesh Prajapati 		                       //
//  Date: 2022-08-10                                           //
//                                                             //
/////////////////////////////////////////////////////////     ///

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Container, Modal, InputGroup, Form } from "react-bootstrap";
import NoData from "../common/NoData";
import DatePicker from "react-datepicker";
import QuerySupportService from "../../services/QuerySupportService";
import RolePermissionService from "../../services/RolePermissionService";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Moment from "moment";
import PhoneInput from "react-phone-input-2";

class QuerySupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      showSpinner: false,
      loading: false,
      total_record: 0,
      records: [],
      groupList: [],
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      RoleList: [],
      SelectedRoleList: [],
      newRoleGroupList: [],
      SelectedRoleGroupList: [],
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      isSearch: global.isSearch,
      filter_value: "",
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "first_name",
          text: "Name",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.first_name + " " + record.last_name;
          },
        },
        {
          key: "email",
          text: "Email",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.email;
          },
        },
        {
          key: "phone_number",
          text: "Phone",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber = record.phone_number
              ? record.phone_number.replace(/[\s-]/g, "")
              : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits = record.phone_number ? record.phone_number : [];
            }

            var number = record.phone_number
              ? lastTenDigits.trim().replace(/[^0-9]/g, "")
              : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.phone_number ? finalnumber : "";
          },
        },
        {
          key: "type",
          text: "Query Type",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.type;
          },
        },
        {
          key: "description",
          text: "Description",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.description;
          },
        },
        {
          key: "property_name",
          text: "Property Name",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.property_name;
          },
        },
        {
          key: "created_at",
          text: "Date",
          className: "hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.created_at
              ? Moment(record.created_at).format(global.dateTimeFormat)
              : "";
          },
        },
      ],
    };
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getRoles();
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var groupList = [];
    var totalRecords = 0;
    var tg = 0;
    let res = await QuerySupportService.getQuerySupport(
      queryString ? queryString : ""
    );
    console.log(res);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      groupList = res.data ? res.data.groupList : [];
      totalRecords = list ? res.data.total : 0;
      //totalRecords = res.data ? res.data.total : 0
    }

    const newRoleGroupList =
      tg > 0
        ? groupList.map((items) => ({
            value: items.key,
            label: items.value,
          }))
        : [];

    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
      groupList: groupList,
      newRoleGroupList: newRoleGroupList,
    });
  }

  // to get data of roles
  async getRoles(queryString = "") {
    this.setState({ showSpinner: true, loading: true });

    var RoleList = [];
    var RoleListTotal = 0;

    let res = await RolePermissionService.getRoles(queryString);
    if (global.successStatus.includes(res.status)) {
      RoleList = res.data ? res.data.data.data : [];
      RoleListTotal = RoleList ? RoleList.length : 0;
    }
    const newRoleList =
      RoleListTotal > 0
        ? RoleList.map((items) => ({
            value: items.slug,
            label: items.role_title,
          }))
        : [];

    this.setState({
      RoleList: newRoleList,
    });
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };
  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getData();
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let StartDate = this.state.start_date
      ? Moment(this.state.start_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "first_name=" +
      (this.state.first_name ? this.state.first_name : "") +
      "&email=" +
      (this.state.email ? this.state.email : "") +
      "&phone_number=" +
      (this.state.phone_number ? this.state.phone_number : "") +
      "&status_id=" +
      (this.state.status_id ? this.state.status_id : "") +
      "&querytype=" +
      (this.state.querytype ? this.state.querytype : "") +
      "&created_at=" +
      StartDate +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.first_name
      ? "First Name - " + this.state.first_name
      : "";
    moduleSearchString = this.state.email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Email - " + this.state.email
        : " Email - " + this.state.email
      : moduleSearchString;
    moduleSearchString = this.state.phone_number
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Phone Number - " +
          this.state.phone_number
        : " Phone Number - " + this.state.phone_number
      : moduleSearchString;
    moduleSearchString = this.state.start_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Date - " +
          Moment(this.state.start_date).format(global.dateFormat)
        : " Date - " + Moment(this.state.start_date).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.querytype
      ? moduleSearchString
        ? moduleSearchString + " ," + " QueryType - " + this.state.querytype
        : " QueryType - " + this.state.querytype
      : moduleSearchString;

    if (
      this.state.first_name ||
      this.state.email ||
      this.state.phone_number ||
      this.state.querytype ||
      this.state.status_id ||
      this.state.start_date
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(queryString);
    }
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  handleChangeRoleList(value) {
    let role_slug = [];

    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        role_slug.push(item.value);
      });
    }
    this.setState({
      SelectedRoleList: value,
      role_slug: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  clearFilter() {
    this.setState({ is_search: 0 });
    this.setState({
      first_name: "",
      email: "",
      phone_number: "",
      start_date: "",
      querytype: "",
    });
    this.setState({ module_search: "" });
    this.getData();
  }

  handleChangeRoleGroupList(value) {
    let role_group_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        role_group_slug.push(item.value);
      });
    }
    this.setState({
      SelectedRoleGroupList: value,
      role_group_slug: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };
  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}

        <Container className="innter-container pr-0 over-fl">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-12">
                <div className="scrolling-carousel">
                  <Container>
                    <div className="row row-xs">
                      <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Support</h3>
                          <div className="d-flex">
                            <button
                              onClick={this.searchToggle}
                              className="btn-success-outline-small"
                            >
                              <img src={filterIcon} alt="filterIcon" />{" "}
                              {this.state.isSearch === 1 ? "Search" : "Search"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                  <Container>
                    {this.state.is_search === 1 ? (
                      <>
                        <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel mg-t-20 ">
                          <div className="custm-filter pd-lg-t-0 pd-xs-t-15">
                            <div>
                              {" "}
                              <label className="form-label text-left pd-b-2 mb-0">
                                Search Applied On
                              </label>
                            </div>
                            <div className="filter_bg_cust">
                              {this.state.module_search.map((item, i) => {
                                return <span key={i}>{item}</span>;
                              })}
                              <button
                                onClick={this.clearFilter}
                                className="btn-fliter-clear"
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      {this.state.total_record > 0 ||
                      this.state.filter_value !== "" ? (
                        <div className="mg-t-20 brandList">
                          <ReactDatatable
                            className="table no-wrap mg-b-30 pd-b-2 brandtable querySupporttable table_last_row"
                            config={
                              this.state.total_record > global.page_size
                                ? this.state.config
                                : this.state.config
                            }
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            // loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            // onRowClicked={this.rowClickedHandler}
                          />
                        </div>
                      ) : (
                        <NoData msg="support" />
                      )}
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Support</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="first_name"
                      value={this.state.first_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Email</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Date</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="start_date"
                    selected={this.state.start_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "start_date",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Phone</label>
                  <div className="col-md-12 p-0">
                    <PhoneInput
                      country={global.country}
                      disableSearchIcon
                      // autoFormat= "true"
                      enableSearch="true"
                      countryCodeEditable="true"
                      value={this.state.property_phone_number}
                      onChange={(phone_number) =>
                        this.setState({ phone_number })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Query Type</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="querytype"
                      value={this.state.querytype}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default QuerySupport;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: ListingPetFee.jsx                  //
//  Application: ListingPetFee                  //
//  Option: For view ListingPetFee details      //
//  Developer: Ashish Kumar 				              //
//  Date: 2022-02-09                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import ListingService from "../../services/ListingService";
import Alerts from "../common/Alerts";
import SimpleReactValidator from "simple-react-validator";
import deleteicon from "../../assets/images/delete.svg";
import { Table, Modal, Form, InputGroup, Col } from "react-bootstrap";
import PetPolicyService from "../../services/PetPolicyService";
import PetTypeService from "../../services/PetTypeService";
import NumberFormat from "react-number-format";
import pluswIcon from "./../../assets/images/plus.svg";
import Select from "react-select";
import editIcon from "./../../assets/images/edit-pen-icon.svg";

export class ListingPetFee extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      confirm_back: 0,
      listing_slug: this.props.listing_slug,
      property_slug: this.props.property_slug,
      petPolicyTypeDataTotal: 1,
      petPolicyTypeData: [
        {
          pet_type_slug: "",
          allowed_pet_number: "",
          pet_count: "",
          // deposite_amount_per_pet: "",
          one_time_fee_per_type: "",
          // rent_per_type: "",
          weight_per_type: "",
          pet_weight: "",
          policy_slug: "",
        },
      ],
      pet_data: [],
      pet_type_slug: [],
      newPetTypeList: [],
      petTypeListDropdown: [],
      selectedPetTypeListDropdown: [],
      selectedPetTypeList: [],
      pet_policy_error: false,
      publishListingModal: false,
      publishMessage:""
    };
    this.changeView = this.changeView.bind(this);
    this.openPublishListingModal = this.openPublishListingModal.bind(this);
    this.closePublishListingModal = this.closePublishListingModal.bind(this);
    this.publishListing = this.publishListing.bind(this);
  }

  async componentDidMount() {
    this.getPetFeesData();
    this.getPetPolicyData();
    this.getDefaultPetData();
    this.getListingData();
  }

   /* to get pet type data list */
   async getPetTypeData(queryString = "") {
    // this.setState({ showSpinner: true, loading: true });
    var petTypeList = [];
    var petTypeListTotal = 0;

    let res = await PetTypeService.getPetType(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      petTypeList = res.data.data ? res.data.data : [];
      petTypeListTotal = petTypeList ? petTypeList.length : 0;
    }
    const newPetTypeList =
      petTypeListTotal > 0
        ? petTypeList.map(({ slug, pet_type_name }) => ({
            value: slug,
            label: pet_type_name,
          }))
        : [];
    this.setState({
      newPetTypeList: newPetTypeList,
      petTypeListDropdown: newPetTypeList,
      petTypeList: petTypeList,
      petTypeListTotal: petTypeListTotal,
    });

    //this.setState({ showSpinner: false, loading: false });
  }

  async getDefaultPetData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};
      var petPolicyTypeDataTotal = 1;
      let res = await PetPolicyService.getPetPolicy(
        this.state.listing_slug + "/10"
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.petPolicyDataLength = res.data.petPolicyData
          ? res.data.petPolicyData.length
          : 0;
        resData.petPolicyData = res.data.petPolicyData
          ? res.data.petPolicyData
          : [
              {
                pet_type_slug: "",
                allowed_pet_number: "",
                pet_count: "",
                // deposite_amount_per_pet: "",
                one_time_fee_per_type: "",
                // rent_per_type: "",
                weight_per_type: "",
                pet_weight: "",
                policy_slug: "",
              },
            ];

        const values = [];
        if (resData.petPolicyDataLength > 0) {
          resData.petPolicyData.forEach((item, i) => {
            values.push({
              pet_type_slug: item.pet_type.slug,
              allowed_pet_number: item.allowed_pet_number,
              one_time_fee_per_type: item.one_time_fee_per_type,
              pet_count: item.pet_count,
              // deposite_amount_per_pet: item.deposite_amount_per_pet,
              // rent_per_type: item.rent_per_type,
              weight_per_type: item.weight_per_type,
              pet_weight: item.pet_weight,
              policy_slug: item.slug,
              selected_pet_type: {
                value: item.pet_type.slug,
                label: item.pet_type.pet_type_name,
              },
            });
          });
        } else {
          resData.petPolicyTypeData = [
            {
              pet_type_slug: "",
              allowed_pet_number: "",
              pet_count: "",
              // deposite_amount_per_pet: "",
              one_time_fee_per_type: "",
              // rent_per_type: "",
              weight_per_type: "",
              pet_weight: "",
              policy_slug: "",
              // selected_pet_type: {
              //   value: "",
              //   label: "",
              // },
            },
          ];
        }
        petPolicyTypeDataTotal = values.length;
        this.setState({
          petPolicyTypeData: values,
          petPolicyTypeDataTotal: petPolicyTypeDataTotal,
        });
      }
      this.setState(resData);
    }
  }

  async getPetPolicyData() {
    //this.setState({ showSpinner: true, loading: true });
    var multiList = [];
    var multiListTotal = 0;

    var petPolicyList = [];
    var petPolicyListTotal = 0;
    let res = await PetPolicyService.getPetPolicy(
      this.props.property_slug + "/3"
    );

    if (global.successStatus.includes(res.status)) {
      petPolicyList = res.data.petPolicyData ? res.data.petPolicyData : [];
      petPolicyListTotal = petPolicyList ? petPolicyList.length : 0;
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    const newmultiListPetPolicyData =
      petPolicyListTotal > 0
        ? petPolicyList.map(({ pet_type }) => ({
            value: pet_type.slug,
            label: pet_type.pet_type_name,
          }))
        : [];
    this.setState({
      petPolicyList: newmultiListPetPolicyData,
      petPolicyListTotal: petPolicyListTotal,
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  async deletePetPolicy(slug) {
    this.setState({ showSpinner: true });
    if (slug) {
      let res = await PetPolicyService.deletePetPolicy(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
          selectedPetTypeList: [],
        });
        this.getDefaultPetData();
        this.getPetFeesData();
      } else {
        let alertMessage = "";
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false });
    // setTimeout(
    //   () => this.setState({ showAlertModal: false }),
    //   global.alert_close_time
    // );
  }

  async getPetFeesData() {
    this.setState({ showSpinner: true, loading: true });
    var petFeesList = [];
    var petFeesListTotal = 0;
    let res = await PetPolicyService.getPetPolicy(
      this.state.listing_slug + "/10"
    );
    if (global.successStatus.includes(res.status)) {
      petFeesList = res.data ? res.data.petPolicyData : [];
      petFeesListTotal = petFeesList ? petFeesList.length : 0;
    }

    this.setState({
      petFeesList: petFeesList,
      petFeesListTotal: petFeesListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getNewPetData(index, val) {
    const values = [...this.state.petPolicyTypeData];
    if (this.state.property_slug !== undefined) {
      var newPetPolicyDataList = [];
      let res = await PetPolicyService.getPetPolicy(
        this.props.property_slug + "/3"
      );
      if (global.successStatus.includes(res.status)) {
        newPetPolicyDataList = res.data.petPolicyData
          ? res.data.petPolicyData
          : [];

        newPetPolicyDataList.map((opt, i) => {
          if (opt.pet_type.slug === val.value) {
            values[index].allowed_pet_number = opt.allowed_pet_number;
            values[index].weight_per_type = opt.weight_per_type;
            values[index].pet_count = opt.allowed_pet_number;
            values[index].one_time_fee_per_type = opt.one_time_fee_per_type;
            values[index].pet_weight = opt.weight_per_type;
          }
        });
        this.setState({ petPolicyTypeData: values });
      }
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  handleChangeselect = (index, event) => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    values[index].pet_type_slug = event.value;
    values[index].selected_pet_type = event;
    // values[index].event.target.name = event.target.value;
    // this.setState({ petPolicyTypeData: values });
    this.setState({ pet_data: values });
    this.setState({ confirm_back: 1 });
    this.getNewPetData(index, event);
    //   setTimeout(() => {
    //     this.state.newPetPolicyDataList.map((opt, i) => {
    //       if(opt.pet_type.slug === event.value){
    //         values[index].allowed_pet_number = opt.allowed_pet_number;
    //         values[index].weight_per_type = opt.weight_per_type;
    //       }
    //       this.setState({ petPolicyTypeData: values });
    //   })
    // }, 1000);
  };

  handleChange = (index, event) => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];

    if (event.target.name === "pet_type_slug") {
      values[index].pet_type_slug = event.target.value;
      this.setState({ petPolicyTypeData: values });
    } else if (event.target.name === "allowed_pet_number") {
      values[index].allowed_pet_number = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
    } else if (event.target.name === "pet_count") {
      values[index].pet_count = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
      // if (values[index].allowed_pet_number < event.target.value) {
      //   values[index].pet_count_error =
      //     "Pet Count can't be greater than Max # Allowed";
      // } else if (event.target.value === "") {
      //   values[index].pet_count_error = "Pet Count field is required";
      // } else {
      //   values[index].pet_count_error = "";
      // }
    } else if (event.target.name === "one_time_fee_per_type") {
      values[index].one_time_fee_per_type = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
      if (event.target.value === "") {
        values[index].one_time_fee_per_type_error = "Fees field is required";
      } else {
        values[index].one_time_fee_per_type_error = "";
      }
    } else if (event.target.name === "weight_per_type") {
      values[index].weight_per_type = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
    } else if (event.target.name === "pet_weight") {
      values[index].pet_weight = event.target.value.replace(
        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
        ""
      );
      // if (values[index].weight_per_type < event.target.value) {
      //   values[index].pet_weight_error =
      //     "Weight can't be greater than Max # Weight";
      // } else {
      //   values[index].pet_weight_error = "";
      // }
    }
    this.setState({ pet_data: values });
    setTimeout(() => {
      this.state.pet_data.map((opt, i) => {
        if (opt.one_time_fee_per_type_error != "") {
          this.setState({ pet_policy_error: true });
        } else if (opt.one_time_fee_per_type_error == "") {
          this.setState({ pet_policy_error: false });
        } else {
          this.setState({ pet_policy_error: false });
        }
      }, 100);
    });
    this.setState({ confirm_back: 1 });
  };

  handleAddFields = () => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    var petPolicyTypeDataTotal = 1;
    values.push({
      pet_type_slug: "",
      allowed_pet_number: "",
      // pet_count: "",
      one_time_fee_per_type: "",
      // deposite_amount_per_pet: "",
      // rent_per_type: "",
      weight_per_type: "",
      // pet_weight: "",
      policy_slug: "",
    });
    petPolicyTypeDataTotal = values.length;

    this.setState({
      petPolicyTypeData: values,
      petPolicyTypeDataTotal: petPolicyTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFields = (i) => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    values.splice(i, 1);
    var petPolicyTypeDataTotal = 0;
    petPolicyTypeDataTotal = values.length;
    this.setState({ petPolicyTypeData: values, pet_data: values });
    this.setState({
      petPolicyTypeDataTotal: petPolicyTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  async getListingData() {
    if (this.props.listing_slug !== undefined) {
      var resData = {};
      let res = await ListingService.getListing(this.props.listing_slug);
      if (global.successStatus.includes(res.status)) {
        resData.listing_name = res.data.listing_name
        ? res.data.listing_name
        : "";
      resData.unit_slug = res.data.unit ? res.data.unit.slug : "";
        resData.property_pet_allowed = res.data.property
          ? res.data.property.pet_allowed
          : "";
        resData.listing_pet_allowed = res.data.listing_pet_allowed
          ? res.data.listing_pet_allowed
          : "";
        resData.pet_policy_restrictions = res.data.property
          ? res.data.property.pet_policy_restrictions
          : "";
      }
      this.setState(resData);
    }
  }

  saveOrUpdateFees = async (e) => {
    e.preventDefault();
    if (
      this.validator.allValid() &&
      this.customValidate() &&
      this.state.pet_policy_error === false
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      if (this.state.listing_slug !== undefined) {
        let inputData = {
          tab: "pet_fee",
          listing_name: this.state.listing_name,
          unit_slug: this.state.unit_slug,
          pet_data: this.state.pet_data,
        };
        let res = await ListingService.updateListing(
          this.state.listing_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "success",
          //   alertModalTitle: "Success",
          //   alertModalMessage: res.message ? res.message : "Success",
          // });
          // this.getListingData();
          this.changeView();
          this.openPublishListingModal(res.message);
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      // setTimeout(
      //   () => this.setState({ showAlertModal: false }),
      //   global.alert_close_time
      // );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({
        viewMode: 0,
        errors: {},
      });
      this.setState({ confirm_back: 0 });
      this.getPetFeesData();
      this.getDefaultPetData();
    } else {
      this.setState({
        viewMode: 1,
        errors: {},
      });
      this.setState({ confirm_back: 0 });
      this.getPetFeesData();
      this.getDefaultPetData();
    }
  }

  openPublishListingModal(message) {
    this.setState({ publishListingModal: true,publishMessage:message });
  }

  closePublishListingModal() {
    this.setState({ publishListingModal: false,publishMessage:"" });
  }

    async publishListing() {
    this.setState({ publishData: true });
    let inputData = {
      data : {
        listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
        property_slug: this.props.property_slug ? this.props.property_slug : "",
        unit_slug: this.props.unit_slug ? this.props.unit_slug : "",
        floor_plan_slug: this.props.floor_plan_slug ? this.props.floor_plan_slug : "",
      }
    };
    let res = await ListingService.publishListing(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );

    }
    this.closePublishListingModal();
    this.setState({ publishData: false });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns">
            {this.state.viewMode === 0 ? (
              <>
              <button
                  className="btn-success-outline-small mr-3"
                  onClick={this.changeView}
                >
                  <img src={editIcon} alt="" />
                  Edit
                </button>
              </>
            ) : (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn-success-outline-small mg-r-20"
                  onClick={this.changeView}
                >
                  Cancel
                </button>

                <button
                  className="btn-success-outline-small mr-3"
                  onClick={this.saveOrUpdateFees}
                >
                  Save{" "}
                </button>
              </div>
            )}
          </div>
          <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          {this.state.viewMode === 0 ? (
            <React.Fragment>
              <div className="m-0">
              <div className="col-lg-12 mg-lg-t-20 mg-xs-t-10 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">Pet Fees</h3>
              </div>
              <div className="table-responsive pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                {this.state.property_pet_allowed === 1 &&
                this.state.listing_pet_allowed === 1 ? (
                  <React.Fragment>
                   
                    <Table hover className="table_last_row">
                      <thead>
                        <tr>
                          <th className="w-20">Pet Type</th>
                          <th className="w-20">Max # Allowed</th>
                          <th className="w-20 text-left">Max # Weight</th>
                          {/* <th className="w-20">Pet Count</th> */}
                          {/* <th className="w-20">Deposit</th> */}
                          <th className="w-20">One-Time Fee</th>
                          {/* <th className="w-20">Monthly Rent</th> */}

                          {/* <th className="w-20 text-left">Weight</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.petFeesListTotal > 0 ? (
                          this.state.petFeesList.map((option, i) => (
                            <tr key={i}>
                              <td>
                                {option.pet_type
                                  ? option.pet_type.pet_type_name
                                  : ""}
                              </td>
                              <td>
                                {option.allowed_pet_number
                                  ? option.allowed_pet_number
                                  : ""}
                              </td>
                              <td className="text-left">
                                {option.weight_per_type
                                  ? parseFloat(option.weight_per_type)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                      .replace(
                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                        ""
                                      ) + " lbs"
                                  : ""}
                              </td>
                              {/* <td>{option.pet_count ? option.pet_count : ""}</td> */}
                              {/*  <td>
                            {option.deposite_amount_per_pet ? (
                              <NumberFormat
                                value={parseFloat(
                                  option.deposite_amount_per_pet
                                )
                                  .toFixed(2)
                                  .replace(/\.00$/, "")
                                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            ) : (
                              ""
                            )}
                          </td> */}
                              <td>
                                {option.one_time_fee_per_type ? (
                                  <NumberFormat
                                    value={parseFloat(
                                      option.one_time_fee_per_type
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                      .replace(
                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                        ""
                                      )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                ) : (
                                  ""
                                )}
                              </td>
                              {/*  <td>
                            {option.rent_per_type ? (
                              <NumberFormat
                                value={parseFloat(option.rent_per_type)
                                  .toFixed(2)
                                  .replace(/\.00$/, "")
                                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            ) : (
                              ""
                            )}
                          </td> */}

                              {/* <td className="text-left">
                            {option.pet_weight
                              ? parseFloat(option.pet_weight)
                                  .toFixed(2)
                                  .replace(/\.00$/, "")
                                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "") +
                                " lbs"
                              : ""}
                          </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="9" align="center">
                              There are no pet fees added in the system.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <div className="col-md-12 mg-t-40 mg-md-t-0 p-0">
                      <p
                        className="az-profile-name-text pl-0"
                        title="Below pets are prohibited at property"
                      >
                        Prohibited Pets
                      </p>
                      <p className="media-body">
                        {this.state.multiListTotal > 0
                          ? this.state.multiList.map(
                              (option, i) =>
                                option.pet_type_name +
                                (i < this.state.multiListTotal - 1
                                  ? ", "
                                  : "") +
                                ""
                            )
                          : ""}
                      </p>
                    </div>
                    <div className="col-md-12 mg-t-40 mg-md-t-0 p-0">
                      <div className="col-md-12 p-0">
                        <p className="az-profile-name-text pl-0">
                          Restrictions
                        </p>
                        <div className="para">
                          {this.state.pet_policy_restrictions ? (
                            <p className="p_wrap1">
                              {this.state.descShow
                                ? this.state.pet_policy_restrictions.slice(
                                    0,
                                    global.showMoreLength
                                  )
                                : this.state.pet_policy_restrictions}

                              {this.state.pet_policy_restrictions.length >
                                global.showMoreLength && (
                                <span onClick={this.fullText}>
                                  {this.state.descShow ? (
                                    <p className="hand-cursor showmore">
                                      ...Show more{" "}
                                    </p>
                                  ) : (
                                    <p className="hand-cursor showmore">
                                      Show Less{" "}
                                    </p>
                                  )}
                                </span>
                              )}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                    Since the listing is not pet friendly, you cannot select
                    pets in the listing.
                  </p>
                )}
              </div>
              </div>
            </React.Fragment>
          ) : (
            ""
          )}

          {this.state.viewMode === 1 ? (
            <div className="m-0">
            <div className="col-lg-12 mg-lg-t-20 mg-xs-t-10 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                <h3 className="mg-l-0 background-head">Pet Fees</h3>
              </div>
              <div className="row align-items-center border-bottom-0  pd-lg-r-15">
                {this.state.property_pet_allowed === 1 &&
                this.state.listing_pet_allowed === 1 ? (
                  <React.Fragment>
                  
                    <div className="pd-l-30 pd-r-30 pet-f pet w-100">
                      <div className="col-md-12 p-0 petFeeSection">
                        <table className="petForm">
                          <thead>
                            <tr>
                              <th className="w-1">
                                <label className="mb-1">Pet Type</label>
                              </th>
                              <th className="w-1">
                                <label className="mb-1">Max # Allowed</label>
                              </th>
                              <th className="w-2">
                                <label className="mb-1">Max # Weight</label>
                              </th>
                              {/* <th className="w-1">
                            <label className="mb-1">Pet Count</label>
                          </th> */}
                              {/* <th className="w-2">
                            <label className="mb-1">Deposit</label>
                          </th> */}
                              <th className="w-2">
                                <label className="mb-1">One-Time Fee</label>
                              </th>
                              {/* <th className="w-2">
                            <label className="mb-1">Monthly Rent</label>
                          </th> */}

                              {/* <th className="w-2">
                            <label className="mb-1">Weight</label>
                          </th> */}
                              <th className="w-3"></th>
                            </tr>
                          </thead>
                          <tbody className="table_cnt_top">
                            {this.state.petPolicyTypeData.map((data, i) => {
                              return (
                                <tr key={i}>
                                  <td className="w-1">
                                    <Col
                                      xs={12}
                                      className="pd-l-0 pd-r-2 colmn1"
                                    >
                                      <InputGroup className="mt-0 m-b-1">
                                        <div className="col-md-12 p-0">
                                          <Select
                                            styles={customStyles}
                                            className="multiselect"
                                            classNamePrefix={"my-custom-react-select"}
                                            menuPlacement="auto"
                                            onChange={(value) =>
                                              this.handleChangeselect(i, value)
                                            }
                                            // options={
                                            //   this.state.petPolicyListTotal > 0
                                            //     ? this.state.petPolicyList
                                            //     : []
                                            // }
                                            options={
                                              this.state.petPolicyListTotal > 0
                                                ? this.state.petPolicyList.filter(
                                                    (option) => {
                                                      const val1 = this.state
                                                        .petPolicyTypeData
                                                        ? this.state.petPolicyTypeData.find(
                                                            (opt) => {
                                                              if (
                                                                option.value ===
                                                                data.pet_type_slug
                                                              )
                                                                return false;
                                                              return (
                                                                opt.pet_type_slug ===
                                                                option.value
                                                              );
                                                            }
                                                          )
                                                        : "";
                                                      if (val1) return false;
                                                      else return true;
                                                    }
                                                  )
                                                : []
                                            }
                                            getOptionValue={(option) =>
                                              `${option.label}`
                                            }
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: 2,
                                              colors: {
                                                ...theme.colors,
                                                primary: "#fff",
                                                primary75: "#000",
                                                primary50: "#000",
                                                primary25: "#000",
                                              },
                                            })}
                                            value={
                                              data.selected_pet_type
                                                ? data.selected_pet_type
                                                : []
                                            }
                                            defaultValue={
                                              data.selected_pet_type
                                                ? data.selected_pet_type
                                                : []
                                            }
                                          />
                                        </div>
                                      </InputGroup>
                                    </Col>
                                  </td>
                                  <td className="w-1">
                                    <Col
                                      xs={12}
                                      className="pd-l-2 pd-r-2 colmn1"
                                    >
                                      <InputGroup className="mt-0 m-b-1">
                                        <Form.Control
                                          name="allowed_pet_number"
                                          maxLength="2"
                                          onKeyPress={
                                            global.onKeyPressEvent
                                              .numberValidation
                                          }
                                          value={
                                            data.allowed_pet_number
                                              ? data.allowed_pet_number || ""
                                              : ""
                                          }
                                          disabled={true}
                                          onChange={(event) =>
                                            this.handleChange(i, event)
                                          }
                                        />
                                      </InputGroup>
                                    </Col>
                                  </td>
                                  <td className="w-3">
                                    <Col
                                      xs={12}
                                      className="pd-l-2 pd-r-0 d-flex"
                                    >
                                      <InputGroup className="mt-0">
                                        <div className="input-group-prepend prefix-dollar">
                                          <Form.Control
                                            onKeyPress={
                                              global.onKeyPressEvent
                                                .floatValidation
                                            }
                                            name="weight_per_type"
                                            value={
                                              data.weight_per_type
                                                ? data.weight_per_type
                                                    .replace(/\.00$/, "")
                                                    .replace(
                                                      /[&#,+()$~%'":*?<>{}]/gi,
                                                      ""
                                                    ) || ""
                                                : ""
                                            }
                                            disabled={true}
                                            onChange={(event) =>
                                              this.handleChange(i, event)
                                            }
                                          />
                                          <span className="input-group-text">
                                            lbs
                                          </span>
                                        </div>
                                      </InputGroup>
                                    </Col>
                                  </td>
                                  {/* <td className="w-1">
                                <Col xs={12} className="pd-l-2 pd-r-2 colmn1">
                                  <InputGroup className="mt-0 m-b-1">
                                    <Form.Control
                                      name="pet_count"
                                      maxLength="2"
                                      onKeyPress={
                                        global.onKeyPressEvent.numberValidation
                                      }
                                      value={
                                        data.pet_count
                                          ? data.pet_count || ""
                                          : ""
                                      }
                                      onChange={(event) =>
                                        this.handleChange(i, event)
                                      }
                                    />
                                  </InputGroup>
                                </Col>
                                {data.pet_count_error ? (
                                  <div className="text-danger">
                                    {data.pet_count_error}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td> */}
                                  {/*   <td className="w-2">
                                <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                                  <InputGroup className="mt-0">
                                    <div className="input-group-prepend prefix-dollar w-100">
                                      <span className="input-group-text">
                                        $
                                      </span>
                                      <Form.Control
                                        onKeyPress={
                                          global.onKeyPressEvent
                                            .numberValidation
                                        }
                                        name="deposite_amount_per_pet"
                                        value={
                                          data.deposite_amount_per_pet
                                            ? data.deposite_amount_per_pet
                                                .replace(/\.00$/, "")
                                                .replace(
                                                  /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                  ""
                                                ) || ""
                                            : ""
                                        }
                                        onChange={(event) =>
                                          this.handleChange(i, event)
                                        }
                                      />
                                    </div>
                                  </InputGroup>
                                </Col>
                              </td> */}
                                  <td className="w-2">
                                    <Col
                                      xs={12}
                                      className="pd-l-2 pd-r-2 colmn3"
                                    >
                                      <InputGroup className="mt-0">
                                        <div className="input-group-prepend prefix-dollar w-100">
                                          <span className="input-group-text">
                                            $
                                          </span>
                                          <Form.Control
                                            name="one_time_fee_per_type"
                                            onKeyPress={
                                              global.onKeyPressEvent
                                                .numberValidation
                                            }
                                            value={
                                              data.one_time_fee_per_type
                                                ? data.one_time_fee_per_type
                                                    .replace(/\.00$/, "")
                                                    .replace(
                                                      /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                      ""
                                                    ) || ""
                                                : ""
                                            }
                                            onChange={(event) =>
                                              this.handleChange(i, event)
                                            }
                                          />
                                        </div>
                                      </InputGroup>
                                    </Col>
                                    {data.one_time_fee_per_type_error ? (
                                      <div className="text-danger">
                                        {data.one_time_fee_per_type_error}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  {/* <td className="w-2">
                                <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                                  <InputGroup className="mt-0">
                                    <div className="input-group-prepend prefix-dollar">
                                      <span className="input-group-text">
                                        $
                                      </span>
                                      <Form.Control
                                        onKeyPress={
                                          global.onKeyPressEvent
                                            .numberValidation
                                        }
                                        name="rent_per_type"
                                        value={
                                          data.rent_per_type
                                            ? data.rent_per_type
                                                .replace(/\.00$/, "")
                                                .replace(
                                                  /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                  ""
                                                ) || ""
                                            : ""
                                        }
                                        onChange={(event) =>
                                          this.handleChange(i, event)
                                        }
                                      />
                                    </div>
                                  </InputGroup>
                                </Col>
                              </td> */}

                                  {/* <td className="w-3">
                                <Col xs={12} className="pd-l-2 pd-r-0 d-flex">
                                  <InputGroup className="mt-0">
                                    <div className="input-group-prepend prefix-dollar">
                                      <Form.Control
                                        onKeyPress={
                                          global.onKeyPressEvent.floatValidation
                                        }
                                        name="pet_weight"
                                        value={
                                          data.pet_weight
                                            ? data.pet_weight
                                                .replace(/\.00$/, "")
                                                .replace(
                                                  /[&#,+()$~%'":*?<>{}]/gi,
                                                  ""
                                                ) || ""
                                            : ""
                                        }
                                        onChange={(event) =>
                                          this.handleChange(i, event)
                                        }
                                      />
                                      <span className="input-group-text">
                                        lbs
                                      </span>
                                    </div>
                                  </InputGroup>
                                </Col>
                                {data.pet_weight_error ? (
                                  <div className="text-danger">
                                    {data.pet_weight_error}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td> */}
                                  <td>
                                    <Col
                                      xs={12}
                                      className="pd-l-2 pd-t-5 pd-r-0 d-flex align-items-center add-delete"
                                    >
                                      {this.state.petPolicyTypeDataTotal ===
                                        1 && i === 0 ? (
                                        <>
                                          {data.policy_slug ? (
                                            <div className="d-flex  justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                              <button
                                                onClick={() => {
                                                  this.handleRemoveFields(i);

                                                  this.deletePetPolicy(
                                                    data.policy_slug
                                                  );
                                                }}
                                                className={
                                                  "btn-danger-outline-x-small"
                                                }
                                              >
                                                <img
                                                  src={deleteicon}
                                                  alt="Delete"
                                                />
                                              </button>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                            <button
                                              onClick={this.handleAddFields}
                                              className="btn-success-outline-x-small"
                                            >
                                              <img src={pluswIcon} alt="Add" />
                                            </button>
                                          </div>
                                        </>
                                      ) : i <
                                        this.state.petPolicyTypeDataTotal -
                                          1 ? (
                                        <>
                                          <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                            <button
                                              onClick={() => {
                                                this.handleRemoveFields(i);

                                                this.deletePetPolicy(
                                                  data.policy_slug
                                                );
                                              }}
                                              className={
                                                "btn-danger-outline-x-small"
                                              }
                                            >
                                              <img
                                                src={deleteicon}
                                                alt="Delete"
                                              />
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {this.state.petPolicyTypeDataTotal !==
                                          0 ? (
                                            <>
                                              <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                <button
                                                  onClick={() => {
                                                    this.handleRemoveFields(i);

                                                    this.deletePetPolicy(
                                                      data.policy_slug
                                                    );
                                                  }}
                                                  className={
                                                    "btn-danger-outline-x-small"
                                                  }
                                                >
                                                  <img
                                                    src={deleteicon}
                                                    alt="Delete"
                                                  />
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {i <
                                          this.state.petPolicyListTotal - 1 ? (
                                            <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                              <button
                                                onClick={this.handleAddFields}
                                                className="btn-success-outline-x-small"
                                              >
                                                <img
                                                  src={pluswIcon}
                                                  alt="Add"
                                                />
                                              </button>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </Col>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15">
                      <p
                        className="az-profile-name-text pl-0"
                        title="Below pets are prohibited at property"
                      >
                        Prohibited Pets
                      </p>
                      <p className="media-body">
                        {this.state.multiListTotal > 0
                          ? this.state.multiList.map(
                              (option, i) =>
                                option.pet_type_name +
                                (i < this.state.multiListTotal - 1
                                  ? ", "
                                  : "") +
                                ""
                            )
                          : ""}
                      </p>
                    </div>
                    <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15">
                      <div className="col-md-12 p-0">
                        <p className="az-profile-name-text pl-0">
                          Restrictions
                        </p>
                        <div className="para">
                          {this.state.pet_policy_restrictions ? (
                            <p className="p_wrap1">
                              {this.state.descShow
                                ? this.state.pet_policy_restrictions.slice(
                                    0,
                                    global.showMoreLength
                                  )
                                : this.state.pet_policy_restrictions}

                              {this.state.pet_policy_restrictions.length >
                                global.showMoreLength && (
                                <span onClick={this.fullText}>
                                  {this.state.descShow ? (
                                    <p className="hand-cursor showmore">
                                      ...Show more{" "}
                                    </p>
                                  ) : (
                                    <p className="hand-cursor showmore">
                                      Show Less{" "}
                                    </p>
                                  )}
                                </span>
                              )}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-30 pd-lg-b-20">
                    Since the listing is not pet friendly, you cannot select
                    pets in the listing.
                  </p>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          
        </div>
        <Modal
          show={this.state.deleteUnavailableDateModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closeDeleteSpecialPriceModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeDeleteSpecialPriceModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={deleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className=" m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteSpecialPriceModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteUnavailableDate(
                      this.state.fromDate,
                      this.state.toDate,
                      this.state.priceType
                    )
                  }
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.publishListingModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closedeleteListingModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closePublishListingModal()}
              >
                {global.closee}
              </span>
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              {/* <h3>Publish to OTA?</h3> */}
              <h3></h3>
              <p>
              {this.state.publishMessage ? this.state.publishMessage : "Listing is successfully updated! Your changes are not published to OTA yet, do you want to publish now ? You can still publish from Sync tab later."}
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block button-width"
                  onClick={() => this.closePublishListingModal()}
                >
                  Sync Later 
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block button-width"
                  onClick={this.publishListing}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Sync Now"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ListingPetFee;

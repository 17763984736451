////////////////////////////////////////////////////////////
//     							                          //
//  Program: ClientService.js                             //
//  Application: Client                                    //
//  Option: Used for manage Clients                       //
//  Developer: NP  						                  //
//  Date: 2022-11-15                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class ClientService {
  
  getClientList(queryString) {
    let endPoint = "client" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getClient(slug) {
    let endPoint = "client/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createClient(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "client";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateClient(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "client/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteClient(slug) {
    let endPoint = "client/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadClientLogo(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'upload-client-logo';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
    let response = ApiService.postCall(inputData);
    return response;
}
}

export default new ClientService();
//     							                          //
//  Program: Toast.jsx                                    //
//  Application: Notification                             //
//  Option: common component for Toast message            //
//  Developer: NP  						                  //
//  Date: 2021-01-11                                      //
//                                                        //
////////////////////////////////////////////////////////////
// Add Brands by clicking the
// Add button.
import React, { Component } from "react";
import Nodata from "../../assets/images/to-do.png";
import renderHTML from "react-render-html";

class NoData extends Component {
  render() {
    return (
      <div className="Nodatafound m-auto">
        <div className="col-md-12">
          <div className="row d-flex justify-content-center flex-column pd-l-0 pd-r-0 mg-b-15 text-center align-items-center">
            <img className="no-data-image" src={Nodata} alt="" />
            <p className="az-profile-name-text pl-0 pd-t-5 pd-b-10">
              {renderHTML(
                this.props.msg
                  ? (this.props.action ? this.props.action : " Add ") +
                      "<u> " +
                      this.props.msg +
                      "</u>  by clicking the" +
                      (this.props.action ? this.props.action : " Add ") +
                      "button."
                  : "No data available"
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default NoData;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: MetroService.js                              //
//  Application: Services                                 //
//  Option: Used for Metro list                           //
//  Developer: NP 						                  //
//  Date: 2022-01-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class MetroService {

    getMetro(queryString) {
        let endPoint = 'metro'+ (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    createMetro(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'metro';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    getMetroBySlug(slug) {
        let endPoint = 'metro/' + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateMetro(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'metro/' + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.putCall(inputData);
        return response;
    }

    deleteMetro(slug) {
        let endPoint = 'metro/' + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.deleteCall(inputData);
        return response;
    }
    
}

export default new MetroService()
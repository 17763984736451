//     							                          //
//  Program: CrmSettingSidebar.jsx                           //
//  Application: CRM                                 //
//  Option: common component Crm Setting Sidebar TAbs        //
//  Developer: Raj KUmar 		                                  //
//  Date: 2023-04-03                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";
class CrmSettingSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
    };
  }

  isPathActive = (path) => {
    if (window.location.pathname.includes(path)) {
      return true;
    } else if ("/" + path === window.location.pathname) {
      return true;
    } else {
      return window.location.pathname.includes(path);
    }
  };

  toggleHeaderMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.remove("d-none");
        document.querySelector(".newSubmenu").classList.remove("d-none");
      } else {
        data.classList.replace("left", "hide");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.add("d-none");
        document.querySelector(".newSubmenu").classList.add("d-none");
      }
    }
  }

  toggleChildMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector(".newSubmenu").classList.toggle("d-none");
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
      } else {
        data.classList.replace("left", "hide");
      }
    }
  }

  render() {
    return (
      <>
        <div className="desksidebarsettingNavbar">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i> Back to CRM
          </div>
          <nav className="nav nav-pills flex-column pd-r-8">
            <ul>
              <li>
                <ul>
                  {global.userPermissions.checkPermission(
                    "crm-campaigns-list"
                  ) && (
                    <li>
                      <Link
                        className={
                          this.isPathActive("crm-campaign")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/crm-campaign"}
                      >
                        Campaign
                      </Link>
                    </li>
                  )}
                  {global.userPermissions.checkPermission(
                    "crm-templates-list"
                  ) && (
                    <li>
                      <Link
                        className={
                          this.isPathActive("crm-templates") ||
                          this.isPathActive("crm-template")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/crm-templates"}
                      >
                        Templates
                      </Link>
                    </li>
                  )}
                  {global.userPermissions.checkPermission("crm-lists-list") && (
                    <li>
                      <Link
                        className={
                          this.isPathActive("crm-list")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/crm-list"}
                      >
                        Lists
                      </Link>
                    </li>
                  )}
                  {global.userPermissions.checkPermission(
                    "crm-subscribers-list"
                  ) && (
                    <li className="child-active">
                      <Link
                        className={
                          this.isPathActive("crm-subscribers")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/crm-subscribers"}
                      >
                        Subscribers
                      </Link>
                    </li>
                  )}
                  {global.userPermissions.checkPermission(
                    "crm-website-inquiry-list"
                  ) && (
                    <li className="child-active">
                      <Link
                        className={
                          this.isPathActive("crm-contact")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/crm-contact"}
                      >
                        Website Inquiry
                      </Link>
                    </li>
                  )}
                  {global.userPermissions.checkPermission(
                    "crm-unsubscribe-list"
                  ) && (
                    <li className="child-active">
                      <Link
                        className={
                          this.isPathActive("crm-unsubscribers")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/crm-unsubscribers"}
                      >
                        Unsubscribers
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Settings
              </span>
              <ul>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "crm-campaigns-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-campaign")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/crm-campaign"}
                        >
                          Campaign
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-templates-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-templates") ||
                            this.isPathActive("crm-template")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/crm-templates"}
                        >
                          Templates
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-lists-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/crm-list"}
                        >
                          Lists
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-subscribers-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-subscribers")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/crm-subscribers"}
                        >
                          Subscribers
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-website-inquiry-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-contact")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/crm-contact"}
                        >
                          Website Inquiry
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-unsubscribe-list"
                    ) && (
                      <li className="child-active">
                        <Link
                          className={
                            this.isPathActive("crm-unsubscribers")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/crm-unsubscribers"}
                        >
                          Unsubscribers
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CrmSettingSidebar;

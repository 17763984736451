////////////////////////////////////////////////////////////
//     							                                        //
//  Program: PropertyDashboardOverview.jsx                 //
//  Application: Dashboard                                //
//  Option: to view Property Information                   //
//  Developer: NP  						                            //
//  Date: 2022-04-11                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";
import ListingService from "../../services/ListingService";
import Alerts from "../common/Alerts";
// import PropertySidebar from "../common/PropertySidebar"
import propertyLogo from "./../../assets/images/property_placeholder.png";
import propertyImg from "./../../assets/images/overview-placeholder.jpg";
import MultiImageService from "../../services/MultiImageService";
import PhoneNumberFormat from "../common/PhoneNumberFormat";
// import { Breadcrumb } from "react-bootstrap";
import WidgetService from "../../services/WidgetService";
import companyLogo from "./../../assets/images/company_placeholder.png";
import CompanyService from "../../services/CompanyService";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Chart from "../Chart";
import StarRatings from "react-star-ratings";
class PropertyDashboardOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      property_slug: this.props.match.params.propertySlug,
      property_main_image_url: propertyImg,
      property_logo_url: propertyLogo,
      image_url: "",
      showSpinner: false,
      no_of_units_count: 0,
      no_of_building_count: 0,
      total_guest_paid_past_count: 0,
      total_guest_paid_past_future_count: 0,
      amount_paid_out_to_property_count: 0,
      future_earning_to_property_count: 0,
      amount_paid_out_to_resident_count: 0,
      future_erning_to_resident_count: 0,
      top_performer_guest_paid_past_count: 0,
      top_performer_night_stay_past_count: 0,
      top_performer_night_stay_past_future_count: 0,
      top_performer_guest_paid_past_future_count: 0,
      top_performer_past_unit_name: "",
      top_performer_future_unit_name: "",
      taxes_collected_count: 0,
      taxes_remitted_count: 0,
      units_allow_to_homeshare_count: 0,
      units_homesharing_count: 0,
      units_participating_count: 0,
      nights_hosted_past_count: 0,
      nights_hosted_past_future_count: 0,
      adr_past_count: 0,
      adr_past_future_count: 0,
      occupancy_past_count: 0,
      occupancy_past_future_count: 0,
      revervation_hosted_past_count: 0,
      revervation_hosted_past_future_count: 0,
      average_length_of_stay_past_count: 0,
      average_length_of_stay_future_count: 0,
      booking_lead_time_past_count: 0,
      booking_lead_time_past_future_count: 0,
      // ru_inquiry_count: 0,
      property_rating: 0,
    };
    this.publishListing = this.publishListing.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getMulitImagesData();
    this.getWidgetData();
    this.getCompanyData();
  }

  async getMulitImagesData() {
    if (this.state.property_slug !== undefined) {
      let inputData = {
        path: "properties",
        refTableId: 3,
        slug: this.state.property_slug,
        mediaType: 1,
      };
      var multiImageList = [];
      var multiImageListTotal = 0;
      var image_url = "";
      let res = await MultiImageService.getImages(inputData);

      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotal = multiImageList.length;
      }
      if (multiImageListTotal > 0) {
        multiImageList.map((opt, i) => {
          if (opt.is_main_img === 1) {
            image_url = opt.file_name;
          }
        });
      }
      this.setState({
        multiImageList: multiImageList,
        multiImageListTotal: multiImageListTotal,
        image_url: image_url,
      });
      // console.log("image_url", this.state.image_url);
    }
  }

  async getCompanyData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};

    let res = await CompanyService.getCompanyBySlug(this.state.property_slug);

    if (global.successStatus.includes(res.status)) {
      resData.slug = res.data.slug ? res.data.slug : "";

      resData.total_brand_count = res.data.total_brand_count
        ? res.data.total_brand_count
        : 0;
      resData.total_property_count = res.data.total_property_count
        ? res.data.total_property_count
        : 0;
      resData.total_client_count = res.data.total_client_count
        ? res.data.total_client_count
        : 0;
      resData.total_listing_count = res.data.total_listing_count
        ? res.data.total_listing_count
        : "";
      resData.total_reservation_count = res.data.total_reservation_count
        ? res.data.total_reservation_count
        : "";
      resData.total_resident_count = res.data.total_resident_count
        ? res.data.total_resident_count
        : "";
      resData.total_guest_count = res.data.total_guest_count
        ? res.data.total_guest_count
        : "";
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);

      //console.log(res.slug);
      if (global.successStatus.includes(res.status)) {
        sessionStorage.setItem(
          "property_slug",
          this.state.property_slug ? this.state.property_slug : ""
        );
        sessionStorage.setItem(
          "property_name",
          res.data.property_name ? res.data.property_name : ""
        );
        sessionStorage.setItem(
          "property_type",
          res.data.property_type
            ? res.data.property_type.property_type_name
            : ""
        );
        sessionStorage.setItem(
          "year_value",
          res.data.year_value ? res.data.year_value : ""
        );
        sessionStorage.setItem(
          "property_logo_url",
          res.data.property_logo_url ? res.data.property_logo_url : propertyLogo
        );
        sessionStorage.setItem(
          "property_logo_thumb_url",
          res.data.property_logo_thumb_url
            ? res.data.property_logo_thumb_url
            : ""
        );

        localStorage.setItem("property_slug", this.state.property_slug);
        localStorage.setItem("property_name", res.data.property_name);
        localStorage.setItem(
          "property_type",
          res.data.property_type.property_type_name
        );
        localStorage.setItem("year_value", res.data.year_value);
        localStorage.setItem("property_logo_url", res.data.property_logo_url);
        localStorage.setItem(
          "property_logo_thumb_url",
          res.data.property_logo_thumb_url
        );

        if (
          res.data &&
          res.data.ownership_type &&
          res.data.ownership_type.slug
        ) {
          localStorage.setItem("ownership", res.data.ownership_type.slug);
        }
        this.props.propertyName(res.data.property_name);
        this.props.propertySlug(res.data.slug);

        resData.property_name = res.data.property_name
          ? res.data.property_name
          : "";
        resData.total_unit_count = res.data.total_unit_count
          ? res.data.total_unit_count
          : 0;
        resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        // resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resData.country_name = res.data.country
          ? res.data.country.country_name
          : "";
        // resData.county_name = res.data.county ? res.data.county.county_name : "";
        resData.state_name = res.data.state ? res.data.state.state_name : "";
        resData.city_name = res.data.city ? res.data.city.city_name : "";
        resData.zip_code = res.data.zip_code ? res.data.zip_code : "";

        resData.asset_managers = res.data.asset_managers
          ? res.data.asset_managers
          : "";
        resData.property_email = res.data.property_email
          ? res.data.property_email
          : "";
        resData.property_phone_number = res.data.property_phone_number
          ? res.data.property_phone_number
          : "";
        resData.property_logo_url = res.data.property_logo_thumb_url
          ? res.data.property_logo_thumb_url
          : "";
        resData.property_main_image_url = res.data.property_main_image_url
          ? res.data.property_main_image_url
          : propertyImg;

        resData.orion_haus_account_manager_name =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.first_name
            ? res.data.orion_haus_account_manager.first_name
            : "";
        resData.orion_haus_email =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.email
            ? res.data.orion_haus_account_manager.email
            : "";
        resData.orion_haus_phone_number =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.phone_number
            ? res.data.orion_haus_account_manager.phone_number
            : "";
        resData.property_manager_name =
          res.data &&
          res.data.property_manager_info &&
          res.data.property_manager_info.first_name
            ? res.data.property_manager_info.first_name
            : "";
        resData.property_manager_email =
          res.data &&
          res.data.property_manager_info &&
          res.data.property_manager_info.email
            ? res.data.property_manager_info.email
            : "";
        resData.property_manager_phone_number =
          res.data &&
          res.data.property_manager_info &&
          res.data.property_manager_info.phone_number
            ? res.data.property_manager_info.phone_number
            : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  async publishListing() {
    let inputData = {
      property_slug: this.state.property_slug ? this.state.property_slug : "",
      property_name: this.state.property_name ? this.state.property_name : "",
    };
    let res = await ListingService.publishProperty(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        // alertModalMessage: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          res.data && res.data.result && res.data.result.error_list
            ? res.data.result.error_list[0]
            : "Error!",
        // errorList:
        //   res.data && res.data.result && res.data.result.error_list
        //     ? res.data.result.error_list
        //     : [],
      });
    }
  }
  async getWidgetData() {
    this.setState({ spinnerLoader: true, loading: true });
    var resData = {};
    var unitCount = 0;
    var BuildingCount = 0;
    var OhTotalPastRevenue = 0;
    var OhTotalPastFutureRevenue = 0;
    var TotalAmountPaidOutToProperty = 0;
    var TotalAmountFutureEarningToProperty = 0;
    var TotalAmountPaidOutToResident = 0;
    var TotalAmountFutureEarningToResident = 0;
    var TotalAmountTaxCollected = 0;
    var TotalAmountTaxRemitted = 0;
    var TotalUnitsHomesharing = 0;
    var TotalUnitsAllowedHomesharing = 0;
    var TotalUnitsAllowedHomesharingData = 0;
    var TotalAvgLenOfStayPast = 0;
    var TotalAvgLenOfStayPastFuture = 0;
    var TotalNightHostedPast = 0;
    var TotalNightHostedPastFuture = 0;
    var TotalAdrPast = 0;
    var TotalAdrPastFuture = 0;
    var TotalOccupancyPast = 0;
    var TotalOccupancyPastFuture = 0;
    var TotalReservationHostedPast = 0;
    var TotalReservationHostedPastFuture = 0;
    var TotalBookingLeadTimePAST = 0;
    var TotalBookingLeadTimePastFuture = 0;
    var TotalTopPerformaerGuestPaidPast = 0;
    var TotalTopPerformaerGuestPaidPastFuture = 0;
    var TotalTopPerformaerNightBookedPast = 0;
    var TotalTopPerformaerNightBookedPastFuture = 0;
    var TopPerformerPastUnitName = "";
    var TopPerformerFutureUnitName = "";
    var floorplanCount = 0;
    var guestReviewCount = 0;
    // var ruInquiryCount = 0;
    var propertyRating = 0;

    let res = await WidgetService.getWidgetListCountBySlug(
      this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      unitCount = resData.units_count ? resData.units_count : 0;
      BuildingCount = resData.building_count ? resData.building_count : 0;
      OhTotalPastRevenue = resData.total_oh_past_revenue
        ? resData.total_oh_past_revenue
        : 0;
      OhTotalPastFutureRevenue = resData.total_oh_past_future_revenue
        ? resData.total_oh_past_future_revenue
        : 0;
      TotalAmountPaidOutToProperty = resData.total_amount_paid_out_to_property
        ? resData.total_amount_paid_out_to_property
        : 0;
      TotalAmountFutureEarningToProperty =
        resData.total_amount_future_earning_to_property
          ? resData.total_amount_future_earning_to_property
          : 0;
      TotalAmountPaidOutToResident = resData.total_amount_paid_out_to_resident
        ? resData.total_amount_paid_out_to_resident
        : 0;
      TotalAmountFutureEarningToResident =
        resData.total_amount_future_earning_to_resident
          ? resData.total_amount_future_earning_to_resident
          : 0;
      TotalAmountTaxCollected = resData.total_amount_tax_collected
        ? resData.total_amount_tax_collected
        : 0;
      TotalTopPerformaerGuestPaidPast =
        resData.top_performer_guest_paid_past_count_data
          ? resData.top_performer_guest_paid_past_count_data
          : 0;
      TotalTopPerformaerGuestPaidPastFuture =
        resData.top_performer_guest_paid_past_future_count_data
          ? resData.top_performer_guest_paid_past_future_count_data
          : 0;
      TopPerformerPastUnitName = resData.top_performer_past_unit_name
        ? resData.top_performer_past_unit_name
        : "";
      TopPerformerFutureUnitName = resData.top_performer_future_unit_name
        ? resData.top_performer_future_unit_name
        : "";

      TotalTopPerformaerNightBookedPast =
        resData.top_performer_nights_booked_past_count_data
          ? resData.top_performer_nights_booked_past_count_data
          : 0;
      TotalTopPerformaerNightBookedPastFuture =
        resData.top_performer_nights_booked_past_future_count_data
          ? resData.top_performer_nights_booked_past_future_count_data
          : 0;
      TotalAmountTaxRemitted = resData.total_amount_tax_remitted
        ? resData.total_amount_tax_remitted
        : 0;
      TotalUnitsAllowedHomesharingData = resData.total_units_allowed_homesharing
        ? resData.total_units_allowed_homesharing
        : 0;
      TotalUnitsAllowedHomesharing = Number.isInteger(
        TotalUnitsAllowedHomesharingData
      );
      TotalUnitsHomesharing = resData.total_units_homesharing
        ? resData.total_units_homesharing
        : 0;
      TotalAvgLenOfStayPast = resData.total_avg_len_of_stay_past
        ? resData.total_avg_len_of_stay_past
        : 0;
      TotalAvgLenOfStayPastFuture = resData.total_avg_len_of_stay_past_future
        ? resData.total_avg_len_of_stay_past_future
        : 0;
      TotalNightHostedPast = resData.total_night_hosted_past
        ? resData.total_night_hosted_past
        : 0;
      TotalNightHostedPastFuture = resData.total_night_hosted_past_future
        ? resData.total_night_hosted_past_future
        : 0;
      TotalAdrPast = resData.total_adr_past ? resData.total_adr_past : 0;
      TotalAdrPastFuture = resData.total_adr_past_future
        ? resData.total_adr_past_future
        : 0;
      TotalOccupancyPast = resData.total_occupancy_past
        ? resData.total_occupancy_past
        : 0;
      TotalOccupancyPastFuture = resData.total_occupancy_past_future
        ? resData.total_occupancy_past_future
        : 0;
      TotalReservationHostedPast = resData.total_reservation_hosted_past
        ? resData.total_reservation_hosted_past
        : 0;
      TotalReservationHostedPastFuture =
        resData.total_reservation_hosted_past_future
          ? resData.total_reservation_hosted_past_future
          : 0;
      TotalBookingLeadTimePAST = resData.total_booking_lead_time_past
        ? resData.total_booking_lead_time_past
        : 0;
      TotalBookingLeadTimePastFuture =
        resData.total_booking_lead_time_past_future
          ? resData.total_booking_lead_time_past_future
          : 0;
      floorplanCount = resData.floorplan_count ? resData.floorplan_count : 0;
      guestReviewCount = resData.guest_review_count
        ? resData.guest_review_count
        : 0;
      // ruInquiryCount = resData.ru_inquiry_count ? resData.ru_inquiry_count : 0;
      propertyRating = resData.property_rating ? resData.property_rating : 0;
    }

    this.setState({
      resData: resData,
      no_of_units_count: unitCount,
      no_of_building_count: BuildingCount,
      total_guest_paid_past_count: OhTotalPastRevenue,
      total_guest_paid_past_future_count: OhTotalPastFutureRevenue,
      amount_paid_out_to_property_count: TotalAmountPaidOutToProperty,
      future_earning_to_property_count: TotalAmountFutureEarningToProperty,
      amount_paid_out_to_resident_count: TotalAmountPaidOutToResident,
      future_erning_to_resident_count: TotalAmountFutureEarningToResident,
      taxes_collected_count: TotalAmountTaxCollected,
      top_performer_guest_paid_past_count: TotalTopPerformaerGuestPaidPast,
      top_performer_guest_paid_past_future_count:
        TotalTopPerformaerGuestPaidPastFuture,
      top_performer_past_unit_name: TopPerformerPastUnitName,
      top_performer_future_unit_name: TopPerformerFutureUnitName,
      top_performer_night_stay_past_count: TotalTopPerformaerNightBookedPast,
      top_performer_night_stay_past_future_count:
        TotalTopPerformaerNightBookedPastFuture,
      taxes_remitted_count: TotalAmountTaxRemitted,
      units_allow_to_homeshare_count: TotalUnitsAllowedHomesharing,
      units_allow_to_homeshare_count_data: TotalUnitsAllowedHomesharingData,
      units_homesharing_count: TotalUnitsHomesharing,
      average_length_of_stay_past_count: TotalAvgLenOfStayPast,
      average_length_of_stay_future_count: TotalAvgLenOfStayPastFuture,
      nights_hosted_past_count: TotalNightHostedPast,
      nights_hosted_past_future_count: TotalNightHostedPastFuture,
      adr_past_count: TotalAdrPast,
      adr_past_future_count: TotalAdrPastFuture,
      occupancy_past_count: TotalOccupancyPast,
      occupancy_past_future_count: TotalOccupancyPastFuture,
      revervation_hosted_past_count: TotalReservationHostedPast,
      revervation_hosted_past_future_count: TotalReservationHostedPastFuture,
      booking_lead_time_past_count: TotalBookingLeadTimePAST,
      booking_lead_time_past_future_count: TotalBookingLeadTimePastFuture,
      floorplan_count: floorplanCount,
      guest_review_count: guestReviewCount,
      // ru_inquiry_count: ruInquiryCount,
      property_rating: propertyRating,
    });
    this.setState({ spinnerLoader: false, loading: false });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""}
        <div className="propertyNamemobview">
          <h4>
            {this.props.property_name
              ? this.props.property_name
              : this.state.property_name
                ? this.state.property_name
                : "Property"}
          </h4>
        </div> */}
        <Container>
          <div className="row row-xs">
            {/* <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="d-flex breadcrumbssection">
                <Breadcrumb className="breadcrumb-style2">
                  <Breadcrumb.Item href="/properties">
                    Properties
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Overview</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div> */}
            <div className="propertyNamemobview">
              <h4>
                {this.props.property_name
                  ? this.props.property_name
                  : this.state.property_name
                  ? this.state.property_name
                  : "Property"}
              </h4>
            </div>
            <div className="col-md-12">
              <div className="dashbaord-pageHeadertitle d-flex flex-wrap- w-100 justify-content-between bothBtn align-items-center mg-t-30 prop-overivew">
                <h3>Dashboard</h3>
              </div>
              <div className="col-md-12 mg-l-0 mg-r-0 mg-t-0">
                <Alerts
                  show={this.state.showAlertModal}
                  // show="true"
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className="row mg-t-30 pd-t-2 cardsection overview_sec">
            <div className="col-md-4 col-lg-3 mg-b-30 cardLeft d-none">
              <div className="dashboardLeftcard az-profile-overview">
                <div className="az-img-user ml-auto mr-auto align-items-center mt-0">
                  <figure>
                    <img
                      src={
                        this.state.company_logo_thumb_url
                          ? this.state.company_logo_thumb_url
                          : companyLogo
                      }
                      alt=""
                    />
                  </figure>
                </div>
                {/* az-img-user */}

                <div className="d-flex justify-content-center text-center mg-b-20 mg-t-20">
                  <div>
                    <p className="az-profile-name-text">DBA</p>
                    <p className="media-body">
                      {this.state.company_name ? this.state.company_name : ""}
                    </p>

                    <p className="az-profile-name-text">Company SKU</p>
                    <p className="media-body">
                      {this.state.aka_name ? this.state.aka_name : ""}
                    </p>

                    <p className="az-profile-name-text">Legal Entity</p>
                    <p className="media-body">
                      {this.state.legal_entity_name
                        ? this.state.legal_entity_name
                        : ""}
                    </p>

                    <p className="az-profile-name-text">Tax ID</p>
                    <p className="media-body">
                      {this.state.tax_id ? this.state.tax_id : ""}
                    </p>

                    {/* manager info start */}

                    <p className="az-profile-name-text">Client Contact</p>

                    <p className="media-body p-0 m-0">
                      {this.state.client_contact_name
                        ? this.state.client_contact_name
                        : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.client_contact_email
                        ? this.state.client_contact_email
                        : ""}
                    </p>
                    <p className="media-body pd-b-1 mg-b-15">
                      {this.state.client_contact_phone_number ? (
                        <PhoneNumberFormat
                          phone_number={this.state.client_contact_phone_number}
                        />
                      ) : (
                        ""
                      )}
                    </p>

                    <p className="az-profile-name-text">
                      Orion Haus Account Manager
                    </p>

                    <p className="media-body p-0 m-0">
                      {this.state.orion_haus_account_manager_name
                        ? this.state.orion_haus_account_manager_name
                        : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.orion_haus_email
                        ? this.state.orion_haus_email
                        : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.orion_haus_phone_number ? (
                        <PhoneNumberFormat
                          phone_number={this.state.orion_haus_phone_number}
                        />
                      ) : (
                        ""
                      )}
                    </p>
                    {/* manager info end */}
                  </div>
                </div>
              </div>
              {/* az-profile-overview */}
            </div>

            <div className="col-md-8 col-lg-9 mg-b-30 cardRight d-none">
              <div className="dashboardRightcard overviewSection overview p-0">
                <div className="az-content-body">
                  <div className="row row-sm pd-t-20">
                    {/* start of count boxes */}
                    <div className="col-sm-6 col-xl-3 mg-t-20 mg-sm-t-0">
                      <div className="card card-dashboard-twentytwo">
                        <div className="media">
                          <div className="media-icon bg-primary">
                            <i className="typcn typcn-chart-line-outline"></i>
                          </div>
                          <div className="media-body">
                            <h6>
                              {this.state.total_brand_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(this.state.total_brand_count)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </h6>
                            <span>
                              {" "}
                              {this.state.total_brand_count === 0 ||
                              this.state.total_brand_count === 1
                                ? "Brand"
                                : "Brands"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                      <div className="card card-dashboard-twentytwo">
                        <div className="media">
                          <div className="media-icon bg-purple">
                            <i className="typcn typcn-chart-line-outline"></i>
                          </div>
                          <div className="media-body">
                            <h6>
                              {this.state.total_client_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(this.state.total_client_count)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </h6>
                            <span>
                              {" "}
                              {this.state.total_client_count === 0 ||
                              this.state.total_client_count === 1
                                ? "Client"
                                : "Clients"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                      <div className="card card-dashboard-twentytwo">
                        <div className="media">
                          <div className="media-icon bg-pink">
                            <i className="typcn typcn-chart-line-outline"></i>
                          </div>
                          <div className="media-body">
                            <h6>
                              {this.state.total_property_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(this.state.total_property_count)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </h6>
                            <span>
                              {" "}
                              {this.state.total_property_count === 0 ||
                              this.state.total_property_count === 1
                                ? "Property"
                                : "Properties"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-xl-3 mg-t-20 mg-sm-t-0">
                      <div className="card card-dashboard-twentytwo">
                        <div className="media">
                          <div className="media-icon bg-primary">
                            <i className="typcn typcn-chart-line-outline"></i>
                          </div>
                          <div className="media-body">
                            <h6>
                              {this.state.total_unit_count
                                ? this.state.total_unit_count
                                : "0"}
                            </h6>
                            <span>
                              {" "}
                              {this.state.total_unit_count === 0 ||
                              this.state.total_unit_count === 1
                                ? "Unit"
                                : "Units"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                      <div className="card card-dashboard-twentytwo">
                        <div className="media">
                          <div className="media-icon bg-purple">
                            <i className="typcn typcn-chart-line-outline"></i>
                          </div>
                          <div className="media-body">
                            <h6>
                              {this.state.total_listing_count
                                ? this.state.total_listing_count
                                : "0"}
                            </h6>
                            <span>
                              {" "}
                              {this.state.total_listing_count === 0 ||
                              this.state.total_listing_count === 1
                                ? "Listing"
                                : "Listings"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3 mg-t-20 mg-sm-t-0">
                      <div className="card card-dashboard-twentytwo">
                        <div className="media">
                          <div className="media-icon bg-primary">
                            <i className="typcn typcn-chart-line-outline"></i>
                          </div>
                          <div className="media-body">
                            <h6>
                              {this.state.total_reservation_count
                                ? this.state.total_reservation_count
                                : "0"}
                            </h6>
                            <span>
                              {" "}
                              {this.state.total_reservation_count === 0 ||
                              this.state.total_reservation_count === 1
                                ? "Reservation"
                                : "Reservations"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                      <div className="card card-dashboard-twentytwo">
                        <div className="media">
                          <div className="media-icon bg-pink">
                            <i className="typcn typcn-chart-line-outline"></i>
                          </div>
                          <div className="media-body">
                            <h6>
                              {this.state.total_resident_count
                                ? this.state.total_resident_count
                                : "0"}
                            </h6>
                            <span>
                              {" "}
                              {this.state.total_resident_count === 0 ||
                              this.state.total_resident_count === 1
                                ? "Resident"
                                : "Residents"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3 mg-t-20 mg-xl-t-0">
                      <div className="card card-dashboard-twentytwo">
                        <div className="media">
                          <div className="media-icon bg-teal">
                            <i className="typcn typcn-chart-line-outline"></i>
                          </div>
                          <div className="media-body">
                            <h6>
                              {this.state.total_guest_count
                                ? this.state.total_guest_count
                                : "0"}
                            </h6>
                            <span>
                              {" "}
                              {this.state.total_guest_count === 0 ||
                              this.state.total_guest_count === 1
                                ? "Guest"
                                : "Guests"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row overview_fst">
                <div className="overview_cnt">
                  <h4>
                    {this.state.total_brand_count
                      ? global.onKeyPressEvent.numberWithCommasForDecimal(
                          parseFloat(this.state.total_brand_count)
                            .toFixed(2)
                            .replace(/\.00$/, "")
                        )
                      : 0}
                  </h4>
                  <p>
                    {this.state.total_brand_count === 0 ||
                    this.state.total_brand_count === 1
                      ? "Brand"
                      : "Brands"}
                  </p>
                </div>
                <div className="overview_cnt">
                  <h4>
                    {this.state.total_client_count
                      ? global.onKeyPressEvent.numberWithCommasForDecimal(
                          parseFloat(this.state.total_client_count)
                            .toFixed(2)
                            .replace(/\.00$/, "")
                        )
                      : 0}
                  </h4>
                  <p>
                    {this.state.total_client_count === 0 ||
                    this.state.total_client_count === 1
                      ? "Client"
                      : "Clients"}
                  </p>
                </div>
                <div className="overview_cnt">
                  <h4>
                    {this.state.total_property_count
                      ? global.onKeyPressEvent.numberWithCommasForDecimal(
                          parseFloat(this.state.total_property_count)
                            .toFixed(2)
                            .replace(/\.00$/, "")
                        )
                      : 0}
                  </h4>
                  <p>
                    {this.state.total_property_count === 0 ||
                    this.state.total_property_count === 1
                      ? "Property"
                      : "Properties"}
                  </p>
                </div>
                <div className="overview_cnt">
                  <h4>
                    {this.state.total_unit_count
                      ? this.state.total_unit_count
                      : "0"}
                  </h4>
                  <p>
                    {this.state.total_unit_count === 0 ||
                    this.state.total_unit_count === 1
                      ? "Unit"
                      : "Units"}
                  </p>
                </div>

                <div className="overview_cnt">
                  <h4>
                    {this.state.total_listing_count
                      ? this.state.total_listing_count
                      : "0"}
                  </h4>
                  <p>
                    {" "}
                    {this.state.total_listing_count === 0 ||
                    this.state.total_listing_count === 1
                      ? "Listing"
                      : "Listings"}
                  </p>
                </div>
                <div className="overview_cnt">
                  <h4>
                    {this.state.total_reservation_count
                      ? this.state.total_reservation_count
                      : "0"}
                  </h4>
                  <p>
                    {" "}
                    {this.state.total_reservation_count === 0 ||
                    this.state.total_reservation_count === 1
                      ? "Reservation"
                      : "Reservations"}
                  </p>
                </div>
                <div className="overview_cnt">
                  <h4>
                    {this.state.total_resident_count
                      ? this.state.total_resident_count
                      : "0"}
                  </h4>
                  <p>
                    {" "}
                    {this.state.total_resident_count === 0 ||
                    this.state.total_resident_count === 1
                      ? "Resident"
                      : "Residents"}
                  </p>
                </div>
                <div className="overview_cnt">
                  <h4>
                    {this.state.total_guest_count
                      ? this.state.total_guest_count
                      : "0"}
                  </h4>
                  <p>
                    {" "}
                    {this.state.total_guest_count === 0 ||
                    this.state.total_guest_count === 1
                      ? "Guest"
                      : "Guests"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-8 property_chat">
              <Chart propertySlug={this.state.property_slug}/>
            </div>
            <div className="col-md-4 dashborad_two_box">
            <div className="dash-dual">
              <div className="overviewLeft">
                <div className="row">
                  <div className="col-md-12">
                    <div className="overview_heading">
                      <h4>Earnings</h4>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="overview_info">
                      <label>GUEST PAID past</label>
                      <p>
                        {this.state.total_guest_paid_past_count
                          ? "$" +
                            global.onKeyPressEvent.numberWithCommasForDecimal(
                              parseFloat(this.state.total_guest_paid_past_count)
                                .toFixed(2)
                                .replace(/\.00$/, "")
                            )
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="overview_info">
                      <label className="dash-label-max">
                        GUEST PAID past & future
                      </label>
                      <p>
                        {this.state.total_guest_paid_past_future_count
                          ? "$" +
                            global.onKeyPressEvent.numberWithCommasForDecimal(
                              parseFloat(
                                this.state.total_guest_paid_past_future_count
                              )
                                .toFixed(2)
                                .replace(/\.00$/, "")
                            )
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="overview_info">
                      <label>PAID OUT TO PROPERTY</label>
                      <p>
                        {this.state.amount_paid_out_to_property_count
                          ? "$" +
                            global.onKeyPressEvent.numberWithCommasForDecimal(
                              parseFloat(
                                this.state.amount_paid_out_to_property_count
                              )
                                .toFixed(2)
                                .replace(/\.00$/, "")
                            )
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="overview_info">
                      <label>FUTURE EARNINGS TO PROPERTY</label>
                      <p>
                        {this.state.future_earning_to_property_count
                          ? "$" +
                            global.onKeyPressEvent.numberWithCommasForDecimal(
                              parseFloat(
                                this.state.future_earning_to_property_count
                              )
                                .toFixed(2)
                                .replace(/\.00$/, "")
                            )
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="overview_info">
                      <label>PAID OUT TO RESIDENTS</label>
                      <p>
                        {this.state.amount_paid_out_to_resident_count
                          ? "$" +
                            global.onKeyPressEvent.numberWithCommasForDecimal(
                              parseFloat(
                                this.state.amount_paid_out_to_resident_count
                              )
                                .toFixed(2)
                                .replace(/\.00$/, "")
                            )
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="overview_info">
                      <label>FUTURE EARNINGS TO RESIDENTS</label>
                      <p>
                        {this.state.future_erning_to_resident_count
                          ? "$" +
                            global.onKeyPressEvent.numberWithCommasForDecimal(
                              parseFloat(
                                this.state.future_erning_to_resident_count
                              )
                                .toFixed(2)
                                .replace(/\.00$/, "")
                            )
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="dash-dual">                  
              <div className="overviewLeft pb-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="overview_heading">
                      <h4>TOP PERFORMER</h4>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="overview_info">
                      <label>
                        GUEST PAID PAST{" "}
                        <span>
                          {this.state.top_performer_past_unit_name
                            ? "(" +
                              this.state.top_performer_past_unit_name +
                              ")"
                            : ""}
                        </span>
                      </label>
                      <p>
                        {this.state.top_performer_guest_paid_past_count
                          ? "$" +
                            global.onKeyPressEvent.numberWithCommasForDecimal(
                              parseFloat(
                                this.state.top_performer_guest_paid_past_count
                              )
                                .toFixed(2)
                                .replace(/\.00$/, "")
                            )
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="overview_info">
                      <label>
                        GUEST PAID PAST & FUTURE{" "}
                        <span>
                          {this.state.top_performer_future_unit_name
                            ? "(" +
                              this.state.top_performer_future_unit_name +
                              ")"
                            : ""}
                        </span>
                      </label>
                      <p>
                        {this.state.top_performer_guest_paid_past_future_count
                          ? "$" +
                            global.onKeyPressEvent.numberWithCommasForDecimal(
                              parseFloat(
                                this.state
                                  .top_performer_guest_paid_past_future_count
                              )
                                .toFixed(2)
                                .replace(/\.00$/, "")
                            )
                          : 0}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="overview_info">
                      <label>
                        Nights Booked PAST{" "}
                        <span>
                          {this.state.top_performer_past_unit_name
                            ? "(" +
                              this.state.top_performer_past_unit_name +
                              ")"
                            : ""}
                        </span>
                      </label>
                      <p>
                        {this.state.top_performer_night_stay_past_count
                          ? this.state.top_performer_night_stay_past_count
                          : 0}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="overview_info">
                      <label>
                        Nights Booked PAST & FUTURE{" "}
                        <span>
                          {this.state.top_performer_future_unit_name
                            ? "(" +
                              this.state.top_performer_future_unit_name +
                              ")"
                            : ""}
                        </span>
                      </label>
                      <p>
                        {this.state.top_performer_night_stay_past_future_count
                          ? this.state
                              .top_performer_night_stay_past_future_count
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>            
            </div>
            <div className="col-md-12">
              <div className="overviewRight">
                <div className="row">
                  <div className="col-md-3">
                    <div className="overview_shd">
                      <div className="overview_heading">
                        <h4>Reservations Hosted</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label>Past</label>
                            <p>
                              {" "}
                              {this.state.revervation_hosted_past_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(
                                      this.state.revervation_hosted_past_count
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label className="dash-label-max">
                              PAST & FUTURE
                            </label>
                            <p>
                              {this.state.revervation_hosted_past_future_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(
                                      this.state
                                        .revervation_hosted_past_future_count
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="overview_shd">
                      <div className="overview_heading">
                        <h4>Nights Hosted</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label>Past</label>
                            <p>
                              {this.state.nights_hosted_past_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(
                                      this.state.nights_hosted_past_count
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label className="dash-label-max">
                              PAST & FUTURE
                            </label>
                            <p>
                              {this.state.nights_hosted_past_future_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(
                                      this.state.nights_hosted_past_future_count
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="overview_shd">
                      <div className="overview_heading">
                        <h4>Avg Length of Stay</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label>Past</label>
                            <p>
                              {this.state.average_length_of_stay_past_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(
                                      this.state
                                        .average_length_of_stay_past_count
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label className="dash-label-max">
                              PAST & FUTURE
                            </label>
                            <p>
                              {this.state.average_length_of_stay_future_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(
                                      this.state
                                        .average_length_of_stay_future_count
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="overview_shd">
                      <div className="overview_heading">
                        <h4>Occupancy</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label>Past</label>
                            <p>{this.state.occupancy_past_count + "%"}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label className="dash-label-max">
                              PAST & FUTURE
                            </label>
                            <p>
                              {this.state.occupancy_past_future_count + "%"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="overview_shd">
                      <div className="overview_heading">
                        <h4>ADR</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label>Past</label>
                            <p>
                              {this.state.adr_past_count
                                ? "$" +
                                  global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(this.state.adr_past_count)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label className="dash-label-max">
                              PAST & FUTURE
                            </label>
                            <p>
                              {this.state.adr_past_future_count
                                ? "$" +
                                  global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(this.state.adr_past_future_count)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="overview_shd">
                      <div className="overview_heading">
                        <h4>Booking Lead Time</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label>Past</label>
                            <p>
                              {" "}
                              {this.state.booking_lead_time_past_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(
                                      this.state.booking_lead_time_past_count
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label className="dash-label-max">
                              PAST & FUTURE
                            </label>
                            <p>
                              {this.state.booking_lead_time_past_future_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(
                                      this.state
                                        .booking_lead_time_past_future_count
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="overview_shd">
                      <div className="overview_heading">
                        <h4>Taxes</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label>COLLECTED</label>
                            <p>
                              {this.state.taxes_collected_count
                                ? "$" +
                                  global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(this.state.taxes_collected_count)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label>REMITTED</label>
                            <p>
                              {this.state.taxes_remitted_count
                                ? "$" +
                                  global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(this.state.taxes_remitted_count)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="overview_shd">
                      <div className="overview_heading">
                        <h4>Homesharing</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="overview_info">
                            <label>Units Allowed</label>
                            <p>
                              {" "}
                              {this.state.units_allow_to_homeshare_count_data}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 pr-0">
                          <div className="overview_info">
                            <label>Units Homesharing</label>
                            <p>
                              {this.state.units_homesharing_count
                                ? global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(
                                      this.state.units_homesharing_count
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <Link
                      to={
                        "/properties/view/" +
                        this.state.property_slug +
                        "/applicant-detail"
                      }
                    >
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>Lease Inquiry</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="overview_info">
                              <label>Total Inquiries</label>
                              <p>{this.state.floorplan_count}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {/* <div className="col-md-4 ">
                    <Link
                      to={
                        "/properties/view/" +
                        this.state.property_slug +
                        "/inquiry"
                      }
                    >
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>Reservation Inquiry</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="overview_info">
                              <label className="hand-cursor">
                                Total Reviews{" "}
                              </label>
                              <p>{this.state.ru_inquiry_count}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div> */}
                  {/* <div className="col-md-4 ">
                    <Link
                      to={
                        "/properties/view/" +
                        this.state.property_slug +
                        "/reviews"
                      }
                    >
                      <div className="overview_shd">
                        <div className="overview_heading">
                          <h4>Guest Reviews</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="overview_info">
                              <label>Total Reviews </label>
                              <p>{this.state.guest_review_count}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div> */}
                  <div className="col-md-3 star_box_new">
                    <div className="overview_shd">
                      <div className="overview_heading">
                        <h4>Property Reviews</h4>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="overview_info">
                            <div className="d-flex align-items-end star_box_rate">
                              <span className="pd-r-3 dashborad_star">
                                {" "}
                                {this.state.property_rating &&
                                this.state.property_rating.rating
                                  ? this.state.property_rating.rating
                                  : 0}{" "}
                              </span>
                              <StarRatings
                                rating={
                                  this.state.property_rating &&
                                  this.state.property_rating.rating
                                    ? parseFloat(
                                        this.state.property_rating.rating
                                      )
                                    : 0
                                }
                                starRatedColor="rgb(255,165,52)"
                                numberOfStars={5}
                                starDimension="20px"
                              />
                              <Link
                                to={
                                  "/properties/view/" +
                                  this.state.property_slug +
                                  "/reviews"
                                }
                              >
                                <span className="pd-l-3 dashborad_star">
                                  {this.state.property_rating &&
                                  this.state.property_rating.total_count
                                    ? this.state.property_rating.total_count
                                    : 0}{" "}
                                  Guest Reviews
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default PropertyDashboardOverview;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import { Carousel, Form, Modal } from "react-bootstrap";
import MultiImageService from "../../services/MultiImageService";
import PropertyService from "../../services/PropertyService";
import SimpleReactValidator from "simple-react-validator";
import FileWidget from "../fileUploader/FileWidget";
import Alerts from "../common/Alerts";
import deleteImg from "../../assets/images/delete.svg";
import close from "../../assets/images/close-wel.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
// import infosize from "../../assets/images/Info.svg";
import propertyImg from "./../../assets/images/PropPlaceMainImg.png";
import propertyLogo from "./../../assets/images/property_placeholder.png";
export class PropertiesImages extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      importModal: false,
      multiImageList: [],
      multiImageListTotal: 0,
      property_logo: "",
      property_main_image: "",
      viewMode: 0,
      newRelatedFilesDesc: [],
      propertyLogoModal: false,
      propertyListImageModal: false,
      imageUrl: propertyLogo,
      imgPropUrl: propertyImg,
      unitImageListTotal: 0,
    };
    this.propertyLogo = React.createRef();
    this.propertyImg = React.createRef();
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.changeView = this.changeView.bind(this);
    this.changeView2 = this.changeView2.bind(this);
    this.saveOrImage = this.saveOrImage.bind(this);
    this.deletePropImg = this.deletePropImg.bind(this);
    this.opendeletePropImgModal = this.opendeletePropImgModal.bind(this);
    this.newRelatedFilesDescChange = this.newRelatedFilesDescChange.bind(this);
    this.openPropertyLogoModal = this.openPropertyLogoModal.bind(this);
    this.closePropertyLogoModal = this.closePropertyLogoModal.bind(this);
    this.openPropertyListImageModal =
      this.openPropertyListImageModal.bind(this);
    this.closePropertyListImageModal =
      this.closePropertyListImageModal.bind(this);
  }

  onMainImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "property_main_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("resizeOptions","width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200");
      fileData.append("mediaType", "1");
      let res = await PropertyService.uploadPropertyMainImg(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          property_main_image: res.data.fileName,
          imgPropUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  onLogoImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "property_logo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("resizeOptions","width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200");
      fileData.append("mediaType", "1");
      let res = await PropertyService.uploadPropertyLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          property_logo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        // this.props.imageUploadLogo(res.data.filePath);
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  openPropertyLogoModal() {
    document.body.className = "proplistimg";
    this.setState({ propertyLogoModal: true });
  }

  closePropertyLogoModal() {
    this.setState({ propertyLogoModal: false });
  }

  openPropertyListImageModal() {
    document.body.className = "proplistimg";
    this.setState({ propertyListImageModal: true });
  }

  closePropertyListImageModal() {
    this.setState({ propertyListImageModal: false });
  }

  newRelatedFilesDescChange(index, value) {
    let newRelatedFilesDesc = this.state.newRelatedFilesDesc;
    newRelatedFilesDesc[index] = value;
    this.setState({ newRelatedFilesDesc: newRelatedFilesDesc });
  }

  async getMulitImagesData() {
    if (this.props.property_slug !== undefined) {
      let inputData = {
        path: "properties",
        refTableId: 3,
        slug: this.props.property_slug,
        mediaType: 1,
      };
      let res = await MultiImageService.getImages(inputData);
      var multiImageList = [];
      var multiImageListTotal = 0;
      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotal = multiImageList.length;
      }
      this.setState({
        multiImageList: multiImageList,
        multiImageListTotal: multiImageListTotal,
      });
    }
  }

  opendeletePropImgModal(slug) {
    this.setState({ deletePropImgModal: true, slug: slug });
  }

  closedeletePropImgModal() {
    this.setState({ deletePropImgModal: false, slug: "" });
  }

  async componentDidMount() {
    this.getMulitImagesData();
    if (this.props.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.props.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData.property_logo = res.data.property_logo
          ? res.data.property_logo
          : "";
        resData.imageUrl = res.data.property_logo_url
          ? res.data.property_logo_url
          : '';
        resData.property_main_image = res.data.property_main_image
          ? res.data.property_main_image
          : "";
        resData.imgPropUrl = res.data.property_main_image_url
          ? res.data.property_main_image_url
          : '';
      }
      this.setState(resData);
    }
  }

  // updateImgDec = async (e) => {
  //   e.preventDefault();
  //   this.setState({ showSpinner: true, isSubmit: true });
  //   let inputData = {
  //     image_data: this.state.propertyImageList
  //       ? this.state.propertyImageList
  //       : [],
  //   };

  //   let res = await MultiImageService.updateMultiImage(inputData);
  //   if (global.successStatus.includes(res.status)) {
  //     this.setState({
  //       showAlertModal: true,
  //       alertModalType: "success",
  //       alertModalTitle: "Success",
  //       alertModalMessage: res.message ? res.message : "Success",
  //     });
  //     this.setState({ viewMode: 0 });
  //     this.getMulitImagesData();
  //   } else {
  //     let alertMessage = "";
  //     if (res.data.result.errorDetail !== null) {
  //       let errors = {};
  //       res.data.errorCode = "Validation Failed";
  //       res.data.result.errorDetail.forEach((item) => {
  //         errors[item.errorField] = item.errorMessage[0];
  //         // alertMessage += item.errorMessage[0]+'\n';
  //         res.data.errorMessage = "";
  //       });
  //       this.setState({ errors: errors });
  //     }
  //     this.setState({
  //       showSpinner: false,
  //       showAlertModal: true,
  //       alertModalType: "error",
  //       alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
  //       alertModalMessage:
  //         alertMessage !== "" ? alertMessage : res.data.message,
  //     });
  //   }
  //   this.setState({ showSpinner: false, isSubmit: false });
  //   setTimeout(
  //     () => this.setState({ showAlertModal: false }),
  //     global.alert_close_time
  //   );
  // };

  saveOrImage = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true, isSubmit: true });
    if (this.state.property_logo || this.state.property_main_image) {
      let inputImgData = {
        tab: 8,
        property_name: this.props.property_name,
        property_logo: this.state.property_logo,
        property_main_image: this.state.property_main_image,
      };

      if (this.props.property_slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.props.property_slug,
          inputImgData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }

      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }

    const FileWidgetObj = new FileWidget();

    const output = await FileWidgetObj.fileUploadFun(
      "register_from",
      this.state.newRelatedFilesDesc
    );
    if (output.status !== false) {
      if (output.response[0].filesData) {
        if (output.response[0].filesData.length > 0) {
          let inputData = {
            availableFiles: output.response,
            deletedFiles: this.state.deletedFileList,
          };

          if (this.props.property_slug !== undefined) {
            let res = await MultiImageService.submitMultiImage(inputData);
            if (global.successStatus.includes(res.status)) {
              this.setState({
                showAlertModal: true,
                alertModalType: "success",
                alertModalTitle: "Success",
                alertModalMessage: res.message ? res.message : "Success",
              });
              this.setState({ viewMode: 0 });
              this.setState({ newRelatedFilesDesc: [] });
              this.getMulitImagesData();
            } else {
              let alertMessage = "";
              if (
                res.data.errorCode === "validationFailed" &&
                res.data.errorDetail !== null
              ) {
                let errors = {};
                res.data.errorCode = "Validation Failed";
                res.data.errorDetail.forEach((item) => {
                  errors[item.errorField] = item.errorMessage[0];
                  // alertMessage += item.errorMessage[0]+'\n';
                  res.data.errorMessage = "";
                });
                this.setState({ errors: errors });
              }
              this.setState({
                showAlertModal: true,
                alertModalType: "error",
                alertModalTitle: res.data.message ? res.data.message : "Error!",
                alertModalMessage:
                  alertMessage !== "" ? alertMessage : res.data.message,
              });
            }
          }
        }
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: "The file should be png or jpg.",
      });
    }

    if (this.state.propertyImageListTotal > 0) {
      let inputUpdateData = {
        image_data: this.state.propertyImageList
          ? this.state.propertyImageList
          : [],
      };

      let res = await MultiImageService.updateMultiImage(inputUpdateData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ viewMode: 0, propertyImageListTotal: 0 });
        this.getMulitImagesData();
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
    this.getData();
    this.setState({ viewMode: 0 });
  };

  async getData() {
    if (this.props.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.props.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData.imageUrl = res.data.property_logo_url
          ? res.data.property_logo_url
          : '';
        resData.imgPropUrl = res.data.property_main_image_url
          ? res.data.property_main_image_url
          : '';
      }
      this.setState(resData);
      this.props.imgFile(resData.imageUrl);
      this.props.imgFileProp(resData.imgPropUrl);
    }
  }

  async deletePropImg(slug) {
    this.setState({ showSpinner: true });
    let inputData = {
      path: "properties",
      refTableId: 3,
      slug: this.props.property_slug,
      mediaType: 1,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closedeletePropImgModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ viewMode: 0 });
      this.getMulitImagesData();
    } else {
      this.closedeletePropImgModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  confirmImportModal(i) {
    document.body.className = "proplistimg";
    this.setState({ importModal: true });
    this.setState({ carouselZoom: i });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value = event.target.value;

    let propertyImageList = [...this.state.multiImageList];
    var propertyImageListTotal = 0;

    if (event.target.name === "description") {
      propertyImageList[index][event.target.name] = value;
      propertyImageListTotal = propertyImageList.length;
      this.setState({
        propertyImageList: propertyImageList,
        propertyImageListTotal: propertyImageListTotal,
      });
    } else {
      input[event.target.name] = value;
      this.setState({ [event.target.name]: value, input: input });
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
    } else {
      this.setState({ viewMode: 1 });
    }
  }

  changeView2() {
    if (this.state.viewMode === 2) {
      this.setState({ viewMode: 0 });
    } else {
      this.setState({ viewMode: 2 });
    }
  }

  render() {
    return (
      <div className="dashboardRightcard">
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <div className="row- d-flex justify-content-end">
          {/* <Link
            to={
              "/properties/update/" +
              this.props.property_slug +
              "/property-images"
            }
          >
            <button className="btn-success-outline-small">
              <img src={editIcon} />
              Edit
            </button>
          </Link> */}
          {this.state.viewMode === 0 ? (
            <button
              className="btn-success-outline-small"
              onClick={this.changeView}
            >
              <img src={editIcon} />
              Edit
            </button>
          ) : (
            <div className="d-flex">
              <button
                type="button"
                className="btn-success-outline-small"
                onClick={
                  this.state.viewMode === 2 ? this.changeView2 : this.changeView
                }
              >
                Cancel
              </button>
              <button
                className="btn-success ml-3"
                onClick={
                  this.state.viewMode === 2
                    ? this.updateImgDec
                    : this.saveOrImage
                }
              >
                {this.state.viewMode === 2 ? "Save" : "Save"}{" "}
              </button>
            </div>
          )}
        </div>

        <div className="col-md-12 pl-0 pr-0 mg-t-15">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>

        {this.state.viewMode === 0 ? (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className="demo-avatar-group d-flex justify-content-start flex-row">
                  <div className="avatar-round mg-b-0">
                    <p className="az-profile-name-text pl-0 pd-b-20 pd-b-2">
                      Property Logo
                    </p>
                    {/* <figure>
                      <img
                        src={
                          this.state.imageUrl
                            ? this.state.imageUrl
                            : propertyLogo
                        }
                        alt="Property Logo"
                        className="rounded-circle"
                      />
                    </figure> */}

                    <div className="thmbImg rounded-circle">
                      {this.state.imageUrl ? (
                        <Link
                          to={"#"}
                          className="overlay"
                          onClick={this.openPropertyLogoModal}
                        >
                          <span></span>
                          <img
                            src={this.state.imageUrl ? this.state.imageUrl : ""}
                            alt="Property Logo"
                            className="rounded-circle"
                          />
                        </Link>
                      ) : (
                        <img
                          src={propertyLogo}
                          alt="Property Logo"
                          className="rounded-circle"
                        />
                      )}
                    </div>
                  </div>

                  <div className="avatar-squre mg-b-0">
                    <p className="az-profile-name-text pl-0 pd-b-20 pd-b-2">
                      Property Image
                    </p>
                    {/* <figure>
                      <img
                        src={
                          this.state.imgPropUrl
                            ? this.state.imgPropUrl
                            : propertyImg
                        }
                        alt="Property Image"
                      />
                    </figure> */}

                    <div className="thmbImg">
                      {this.state.imgPropUrl ? (
                        <Link
                          to={"#"}
                          className="overlay"
                          onClick={this.openPropertyListImageModal}
                        >
                          <span></span>
                          <img
                            src={
                              this.state.imgPropUrl ? this.state.imgPropUrl : ""
                            }
                            alt="Property Image"
                          />
                        </Link>
                      ) : (
                        <img src={propertyImg} alt="Property Image" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mg-t-30 pd-t-2 mg-b-25 pd-b-0 pd-l-15 pd-r-15" />
            <div className="row">
              <div className="col-md-12 pd-l-0 pd-r-0">
                <p className="az-profile-name-text pd-l-30 pd-r-30 pd-b-20 pd-b-">
                  Other Images
                </p>
                {/* <div className="col-md-12 d-flex justify-content-end pd-l-0 pd-r-0 mg-b-15">
                  {this.state.viewMode === 0 ? (
                    <button
                      className="btn-success-outline-small"
                      onClick={this.changeView2}
                    >
                      <img src={editIcon} />
                      Edit
                    </button>
                  ) : (
                    ""
                  )}
                </div> */}
                <div className="row pd-l-30 pd-r-30">
                  {this.state.multiImageListTotal > 0
                    ? this.state.multiImageList.map((option, i) => (
                      <div
                        className="col-md-4 pd-l-7 pd-r-7 mg-b-6 mg-t-6"
                        key={i}
                      >
                        <div className="avatar-squre-listing mg-b-0">
                          <figure>
                            <img
                              src={deleteImg}
                              className="del-img"
                              alt="delete image"
                              onClick={() =>
                                this.opendeletePropImgModal(option.slug)
                              }
                            />
                            <Link
                              to={"#"}
                              className="overlay"
                              onClick={() => this.confirmImportModal(i)}
                            >
                              <span>
                                <img
                                  src={deleteImg}
                                  alt="delete image"
                                  onClick={() => {
                                    this.opendeletePropImgModal(option.slug);
                                  }}
                                />
                              </span>
                              <img
                                src={option.file_name ? option.file_name : ""}
                                alt="Other Images"
                              />
                            </Link>
                          </figure>
                        </div>
                      </div>
                    ))
                    : "There are no images added in the system. You may add them by clicking Add button."}

                  {/* <div className="noImage w-100">

                      </div>               */}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {/* properties Property images modal */}
        <Modal
          show={this.state.importModal}
          // onHide={() => this.closeConfirmImportModal()}
          centered
          className="propertylistmodal"
        >
          <Modal.Body>
            <span
              className="welcomeClose"
              onClick={() => this.closeConfirmImportModal()}
            >
              <img src={close} />
            </span>
            <Carousel
              activeIndex={this.state.carouselZoom}
              onSelect={this.confirmImportModal}
              prevIcon={
                this.state.multiImageListTotal === 1 ? (
                  false
                ) : (
                  <span
                    aria-hidden="true"
                    className="carousel-control-prev-icon"
                  />
                )
              }
              nextIcon={
                this.state.multiImageListTotal === 1 ? (
                  false
                ) : (
                  <span
                    aria-hidden="true"
                    className="carousel-control-next-icon"
                  />
                )
              }
            >
              {this.state.multiImageListTotal > 0
                ? this.state.multiImageList.map((option, i) => (
                  <Carousel.Item key={i}>
                    <img
                      src={option.file_name ? option.file_name : ""}
                      className="d-block w-100 op-3"
                      alt="..."
                    />
                    {option.description !== undefined &&
                      option.description !== null &&
                      option.description !== "" &&
                      option.description !== "undefined" ? (
                      <Carousel.Caption>
                        <p>{option.description ? option.description : ""}</p>
                      </Carousel.Caption>
                    ) : (
                      ""
                    )}
                  </Carousel.Item>
                ))
                : ""}
            </Carousel>
          </Modal.Body>
        </Modal>

        {/* properties logo images modal */}
        <Modal
          show={this.state.propertyLogoModal}
          onHide={() => this.closePropertyLogoModal()}
          centered
          className="singlemodal"
        >
          <Modal.Body>
            <span
              className="welcomeClose"
              onClick={() => this.closePropertyLogoModal()}
            >
              <img src={close} />
            </span>
            <img
              src={this.state.imageUrl ? this.state.imageUrl : propertyLogo}
              alt="Property Listing"
            />
          </Modal.Body>
        </Modal>

        {/* properties Property image images modal */}
        <Modal
          show={this.state.propertyListImageModal}
          onHide={() => this.closePropertyListImageModal()}
          centered
          className="propsinglemodal"
        >
          <Modal.Body>
            <span
              className="welcomeClose"
              onClick={() => this.closePropertyListImageModal()}
            >
              <img src={close} />
            </span>
            <img
              src={this.state.imgPropUrl ? this.state.imgPropUrl : propertyImg}
              alt="Property Logo"
            />
          </Modal.Body>
        </Modal>

        {this.state.viewMode === 1 ? (
          <div className="dashboardRightcard pl-0 pr-0">
            <div className="m-0">
              <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                <div className="col-md-3 pl-0">
                  <label className="form-label text-left mg-b-0">
                    Property Logo
                  </label>
                </div>

                <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                  <div className="fileUpload d-flex flex-wrap justify-content-between mg-xs-l-auto mg-xs-r-auto">
                    <span>
                      <label className="hand-cursor">
                        <input
                          onChange={this.onLogoImageChange}
                          type="file"
                          name="property_logo"
                        />
                        {this.state.imageUrl ? (
                          <img src={this.state.imageUrl} alt="Logo" />
                        ) : (
                          <img src={propertyLogo} alt="Logo" />
                        )}
                      </label>
                    </span>

                    <ul className="ruLink">
                      {/* <li>
                                  <Link onClick={this.removeLogo}>Remove</Link>
                                </li> */}
                      <li>
                        <label>
                          <input
                            onChange={this.onLogoImageChange}
                            type="file"
                            name="property_logo"
                          />
                        </label>
                        Change Logo
                      </li>
                    </ul>
                  </div>
                  {/* {this.state.errors.property_logo ? (
                    <div className="text-danger">
                      {this.state.errors.property_logo}
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* <span className="d-inline-flex align-items-start justify-content-start infosize">
                    <img src={infosize} className="mg-r-5" />
                    <p>
                      Recommended size - 512px by 512px (1:1 ratio). Max file
                      size - 5 MB
                    </p>
                  </span> */}
                </div>
              </div>
              <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                <div className="col-md-3 pl-0">
                  <label className="form-label text-left mg-b-0">
                    Property Image
                  </label>
                </div>

                <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                  <div className="fileUpload d-flex flex-wrap justify-content-between">
                    <span>
                      <label className="hand-cursor">
                        <input
                          onChange={this.onMainImageChange}
                          type="file"
                          name="property_main_image"
                        />
                        {this.state.imgPropUrl ? (
                          <img src={this.state.imgPropUrl} alt="Main Image" />
                        ) : (
                          <img src={propertyImg} alt="Main Image" />
                        )}
                      </label>
                    </span>

                    <ul className="ruLink">
                      {/* <li>
                                  <Link onClick={this.removeLogo}>Remove</Link>
                                </li> */}
                      <li>
                        <label>
                          <input
                            onChange={this.onMainImageChange}
                            type="file"
                            name="property_main_image"
                          />
                        </label>
                        Change Main Image
                      </li>
                    </ul>
                  </div>
                  {/* {this.state.errors.property_main_image ? (
                    <div className="text-danger">
                      {this.state.errors.property_main_image}
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* <span className="d-inline-flex align-items-start justify-content-start infosize">
                    <img src={infosize} className="mg-r-5" />
                    <p>
                      Recommended size - 1024px by 683px (3:2 ratio). Max file
                      size - 5 MB
                    </p>
                  </span> */}
                </div>
              </div>
              <form id="register_from">
                <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                  <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                    <FileWidget
                      fieldName="file_name"
                      fieldTitle="Property Images"
                      fieldLabel="Property Images"
                      checkValidation="true"
                      allowedType="image/jpg,image/png"
                      maxFileSize={1000}
                      maxFileSizeType="mb"
                      maxFileAllowed={10}
                      uploadOn="s3"
                      slug={this.props.property_slug}
                      refTableId={3}
                      displayFileName=""
                      documentTypeSlug=""
                      description=""
                      mediaType={1}
                      sortOrder=""
                      statusId=""
                      helpText="to download sample file."
                      sampleFileUrl="/sample.jpg"
                      drag="false"
                      dragText="Add image (Multiple)"
                      inputClassName="form-control"
                      labelClassName="form-label"
                      formID="register_from"
                      uploadType="multiple"
                      resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
                      destinationFolder="properties"
                      propertySlug = {''}
                      uploadedFiles={this.state.profile_image2}
                      deletedFiles={this.state.deletedFileList}
                      acceptOnly="image/jpg,image/png"
                      displayPreviewStyle="vertical"
                      newRelatedFilesDescChange={this.newRelatedFilesDescChange}
                    />

                    {/* { (this.state.errors.aka_name) ? <div className="text-danger">{this.state.errors.aka_name}</div> : this.validator.message('aka_name', this.state.aka_name, 'required|max:50', { className: 'text-danger' })} */}
                  </div>
                </div>
              </form>
            </div>
            <div className="m-0">
              <div className="row row-xs align-items-center pd-t-0 pd-b-30">
                <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                  <div className="form-group mt-1 mb-0">
                    <hr className="mg-t-10 row border-top-0 mg-b-50 pd-b-2 pl-0 pr-0 w-100 mw-100" />
                    <label className="form-label az-profile-name-text pl-0 pd-b-0 text-left">
                      Uploaded Images
                    </label>
                    <ul className="multiImgsection p-0">
                      {this.state.multiImageListTotal > 0
                        ? this.state.multiImageList.map((option, i) => (
                          <>
                            <li className="list-group-item" key={i}>
                              <div className="row pd-b-20 pr-3 ml-0">
                                <div className="col-md-12  pl-0 pr-0 d-flex">
                                  <figure className="mg-r-15">
                                    <img
                                      src={
                                        option.file_name
                                          ? option.file_name
                                          : ""
                                      }
                                      alt=""
                                    />
                                  </figure>
                                  <Form.Control
                                    onChange={(e) => this.changeHandler(e, i)}
                                    name="description"
                                    value={option.description}
                                    className="form-control max_width_100"
                                    type="text"
                                    rows="4"
                                    as="textarea"
                                    autoComplete="off"
                                    // placeholder="Description"
                                    resize="resize"
                                  />
                                  <div className="mg-l-15 mg-r-0 text-center">
                                    <button
                                      onClick={() =>
                                        this.opendeletePropImgModal(
                                          option.slug
                                        )
                                      }
                                      type="button"
                                      className="btn-multilist-outline-danger"
                                    >
                                      <img src={deleteImg} alt="" />
                                    </button>
                                    <span className="danger">
                                      Remove <b>image</b>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </>
                        ))
                        : "There are no images added in the system. You may add them by clicking Add button."}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* {this.state.viewMode === 2 ? (
          <div className="m-0">
            <div className="row row-xs align-items-center pd-t-0 pd-b-0 ">
              <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0 pr-0">
                <div className="multiImgsection">
                  {this.state.multiImageListTotal > 0
                    ? this.state.multiImageList.map((option, i) => (
                        <>
                          <div className="row pd-b-20" key={i}>
                            <div className="col-md-2">
                              <figure>
                                <img
                                  src={option.file_name ? option.file_name : ""}
                                  alt=""
                                />
                              </figure>
                            </div>
                            <div className="col-md-7  pl-0 pr-0">
                              <Form.Control
                                onChange={(e) => this.changeHandler(e, i)}
                                name="description"
                                value={option.description}
                                className="form-control max_width_100"
                                type="text"
                                rows="4"
                                as="textarea"
                                autoComplete="off"
                                // placeholder="Description"
                                resize="resize"
                              />
                            </div>
                            <div className="col-md-3 text-center d-flex justify-content-start">
                              <div className="mg-l-9 mg-r-5">
                                <button
                                  onClick={() =>
                                    this.opendeletePropImgModal(option.slug)
                                  }
                                  type="button"
                                  className="btn-multilist-outline-danger"
                                >
                                  <img src={deleteImg} alt="" />
                                </button>
                                <span className="danger">
                                  Remove <b>image</b>
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    : "No Image Found"}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )} */}
        <Modal
          show={this.state.deletePropImgModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closedeletePropImgModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeletePropImgModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deletePropImg(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default PropertiesImages;

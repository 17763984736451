////////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertyNearByAttraction.jsx                 //
//  Application: Propert                                  //
//  Option: List all Property Near B yAttraction          //
//  Developer: NP						                              //
//  Date: 2022-03-17                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import NearByAttractionService from "../../services/NearByAttractionService";
import { Link } from "react-router-dom";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import Alerts from "../common/Alerts";
// import deleteicon from "../../assets/images/delete.svg";
import BackButton from "../../assets/images/back-button.svg";
// import modaldeleteicon from "./../../assets/images/delete.svg";
import NoData from "../common/NoData";

export class PropertyNearByAttraction extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      deleteNearByAttractionModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "nearby_attraction_name",
          text: "Near By Attraction Name",
          className: "nearby_attraction_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.slug
              ? record.nearby_attraction_name
              : record.nearby_attraction_name;
          },
        },
        {
          key: "attraction_type",
          text: "Attraction Type",
          className: "attraction_type hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.attraction_type
              ? record.attraction_type.attraction_type_name
              : "";
          },
        },
        // {
        //   key: "contact_name",
        //   text: "Contact Name",
        //   className: "contact_name",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.contact_name ? record.contact_name : "";
        //   },
        // },
        // {
        //   key: "contact_phone",
        //   text: "Contact",
        //   className: "contact_phone",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.contact_phone ? record.contact_phone : "";
        //   },
        // },
        // {
        //   key: "action",
        //   text: "Action",
        //   className: "action",
        //   sortable: false,
        //   cell: (record) => {
        //     return record.slug ? (
        //       <Link
        //         to="#"
        //         onClick={() =>
        //           this.openDeleteNearByAttractionModal(record.slug)
        //         }
        //       >
        //         <img src={deleteicon}  alt="Delete"/>
        //       </Link>
        //     ) : (
        //       ""
        //     );
        //   },
        // },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    // this.openDeleteNearByAttractionModal = this.openDeleteNearByAttractionModal.bind(this);
    // this.closeDeleteAttrcationModal = this.closeDeleteAttrcationModal.bind(this);
    // this.deleteAttraction = this.deleteAttraction.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await NearByAttractionService.getNearByAttraction(
      "property_slug=" +
      this.state.property_slug +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  // openDeleteNearByAttractionModal(slug) {
  //   this.setState({ deleteNearByAttractionModal: true });
  //   this.setState({ slug: slug });
  // }

  // closeDeleteAttrcationModal() {
  //   this.setState({ deleteNearByAttractionModal: false });
  //   this.setState({ slug: "" });
  // }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = 'asc';
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Near By Attractions";
  }

  // async deleteAttraction(slug) {
  //   this.setState({ showSpinner: true });
  //   let res = await NearByAttractionService.deleteNearByAttraction(slug);
  //   if (global.successStatus.includes(res.status)) {
  //     this.setState({
  //       showAlertModal: true,
  //       alertModalType: "success",
  //       alertModalTitle: "Success",
  //       alertModalMessage: res.message ? res.message : "Success",
  //     });
  //     this.closeDeleteAttrcationModal();
  //     this.getData();
  //   } else {
  //     let alertMessage = "";
  //     this.setState({
  //       showAlertModal: true,
  //       alertModalType: "error",
  //       alertModalTitle: res.data.message ? res.data.message : "Error!",
  //       alertModalMessage:
  //         alertMessage !== "" ? alertMessage : res.data.message,
  //     });
  //   }
  //   this.setState({ showSpinner: false });
  //   setTimeout(
  //     () => this.setState({ showAlertModal: false }),
  //     global.alert_close_time
  //   );
  // }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("nearby-attractions/update/" + data.slug);
  };

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          <div className="row row-xs">
            <div className="col-md-10 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link to="/properties">Properties</Link>
                <span> / </span>
                <span>{this.getTitle()}</span>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <p className="d-flex justify-content-end">
                <span className="back-parent d-flex align-items-center">
                  <Link to="/properties">
                    <img src={BackButton} alt="" className="back-button" />
                  </Link>
                  <Link to="/properties">Back</Link>
                </span>
              </p>
            </div>
          </div>
        </Container>

        <Container>
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-15 pd-t-4">
              <div className="col-md-3">
                <aside>
                  <div className="topHead">
                    <div className="demo-avatar-group">
                      <div className="az-img-user avatar-md online">
                        <img
                          src={
                            sessionStorage.getItem("property_logo_url")
                              ? sessionStorage.getItem("property_logo_url")
                              : propertyLogo
                          }
                          assName="rounded-circle"
                          alt=""
                        />
                      </div>
                      <div className="propname">
                        <h5>
                          {" "}
                          {sessionStorage.getItem("property_name")
                            ? sessionStorage.getItem("property_name")
                            : "Property"}{" "}
                        </h5>
                        <ul>
                          <li>
                            {" "}
                            {sessionStorage.getItem("property_type")
                              ? sessionStorage.getItem("property_type")
                              : ""}{" "}
                          </li>
                          {sessionStorage.getItem("year_value") ? (
                            <li></li>
                          ) : (
                            ""
                          )}
                          <li>
                            {sessionStorage.getItem("year_value")
                              ? "Year " + sessionStorage.getItem("year_value")
                              : ""}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "0")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Property Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "0" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse
                          eventKey="0"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/basic")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic"
                              }
                            >
                              Property
                            </Link>

                            <Link
                              className={`${this.state.pathname.match("/tower")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              }
                            >
                              Towers
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/floors")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floors"
                              }
                            >
                              Floors
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/amenitites")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/amenitites"
                              }
                            >
                              Common Amenities
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/pet-policy")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                              }
                            >
                              Pet Policy
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/staff")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                            >
                              Staff
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/documents")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/documents"
                              }
                            >
                              Documents & Leases
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/property-level-tax")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              }
                            >
                              Taxes
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/nearby-attractions")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/nearby-attractions"
                              }
                            >
                              Near By Attractions
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/events")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                              }
                            >
                              Events
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card onClick={() => this.setOpen(!this.state.open, "3")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Units Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "3" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="3"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/floor-plans")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floor-plans"
                              }
                            >
                              Floor Plans
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/units")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units"
                              }
                            >
                              Units
                            </Link>
                            {/* <Link
                              className={`${
                                this.state.pathname.match("/rooms")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/rooms"
                              }
                            >
                              Rooms
                            </Link> */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </nav>
                </aside>
              </div>
              <div className="col-md-9 ">
                <div className="scrolling-carousel pd-l-15">
                  <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2">
                    <h3>{this.getTitle()}</h3>
                  </div>
                  <div className="dashboardRightcard">
                    <Link
                      to={"nearby-attractions/add"}
                      className="w-100 d-flex justify-content-end"
                    >
                      <button className="btn-success-outline-small ">
                        <img src={pluswIcon} alt="" /> Add
                      </button>
                    </Link>
                    <div className="col-md-12 pl-0 pr-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.showFilter ? (
                      <Row className="align-items-center order_form">
                        <Col md={3} lg={5} xl={5} className="mb-md-0 mb-3 pl-0">
                          <input
                            name="filter_value"
                            className="form-control"
                            autoComplete="off"
                            // placeholder="Filter Value"
                            value={this.state.filter_value}
                            onChange={this.changeHandler}
                            maxLength="30"
                          />
                        </Col>
                        <Col md={5} lg={5} xl={5}>
                          <Row>
                            <button
                              onClick={this.customFilter}
                              className="btn-success mg-l-30 mg-r-20"
                            >
                              Search
                            </button>
                            <button
                              onClick={this.resetDate}
                              className="btn-success-outline"
                            >
                              Reset
                            </button>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    {this.state.total_record !== 0 || this.state.filter_value !== "" ? (
                      <div className="mg-t-18 brandList">
                        <ReactDatatable
                          className="table no-wrap mg-b-30 pd-b-2"
                          config={this.state.total_record > global.page_size ? this.state.config : this.state.config}
                          records={this.state.records}
                          columns={this.state.columns}
                          extraButtons={this.state.extraButtons}
                          dynamic={true}
                          // loading={this.state.loading}
                          total_record={this.state.total_record}
                          onChange={this.tableChangeHandler}
                          onRowClicked={this.rowClickedHandler}
                        />
                      </div>
                    ) : (
                      <NoData msg="neighborhood" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="ht-50"></div>
          </div>
        </Container>

        {/* delete tax popup modal */}
        {/* <Modal
          show={this.state.deleteNearByAttractionModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteAmenityModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-between mt-3 row">
                <button
                  type="button"
                  className="mx-auto m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAttrcationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="mx-auto m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteAttraction(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal> */}
      </main>
    );
  }
}

export default PropertyNearByAttraction;

/*-------------------------------------------------------
||Program           : CompanyOverview.jsx
||Application       : Dashboard
||Option            : To view Company Information  
||Developer         : NP
||Date              : 2022-04-11 
||Status            : In Use
----------------------------------------------------------*/
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import CheckinToday from "./CheckinToday";
import CheckinTomorrow from "./CheckinTomorrow";
import CheckoutToday from "./CheckOutToday";
import CheckOutTomorrow from "./CheckOutTomorrow";
import CleaningToday from "./CleaningToday";
import CleaningTomorrow from "./CleaningTomorrow";
import GuestCurrentlyStaying from "./GuestCurrentlyStaying";
import NewBookingsList from "./NewBookings";
import NewlySignedLeases from "./NewlySignedLeases";
import OccupancyList from "./OccupancyList";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
// import companyLogo from './../../assets/images/company_placeholder.png'
import OnboardingProcessActivityList from "./OnboardingProcessActivityList";
import OnboardingCompletedActivityList from "./OnboardingCompletedActivityList";
import ReservationList from "./ReservationList";
import GuestReview from "./GuestReview";
import LeaseInquiry from "./LeaseInquiry";

// import RUInquiry from "./RUInquiry";

class SnapshotOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      errors: {},
      outSide: 0,
    };
  }

  getPropertySlug(property_slug) {
    this.setState({ outSide: 0 });
    setTimeout(() => {
      this.getSnapData(property_slug);
    }, 1000);
  }

  getSnapData(property_slug) {
    this.setState({
      property_slug: property_slug ? property_slug : "ALL",
      outSide: 1,
    });
  }

  // componentDidMount() {
  //     this.getData();

  // }

  render() {
    return (
      <div>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}

          <Container>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                  <h3>Snapshot</h3>
                  {/* <div className="d-flex">
                    <Link to={'/company'} ><button className="btn-success-outline mg-r-20">View</button></Link>
                  </div> */}
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="row row-xs mg-t-30 pd-t-2 cardsection snap_section">
              <div className="col-md-12 col-lg-12 mg-b-0  ">
                <div className="dashboardRightcard pt-0 pb-0">
                  {this.props.match.params.propertySlug === undefined ? (
                    <div className="col-xxl-2 col-sm-3 pd-r-0 pd-lg-l-22 pd-xs-l-20">
                      <OutSidePropertySelectBox
                        propertySlug={this.getPropertySlug.bind(this)}
                        outSidePropertySlug={this.state.outSidePropertySlug}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="az-content-body pd-xs-l-0 pd-xs-r-0  pd-lg-l-20 pd-lg-r-20">
                    {/* end of count boxes */}
                    {/* start of checouts boxes */}
                    <div className="row row-sm">
                      {this.state.outSide === 1 ? (
                        <GuestCurrentlyStaying
                          propertySlug={this.state.property_slug}
                        />
                      ) : (
                        <GuestCurrentlyStaying />
                      )}

                      <div className="col-xxl-6 col-md-6 col-sm-12 col-12 mg-b-20">
                        {/* <div className="pd-lg-b-15 snap-heading">
                                                    <h3 className="text-uppercase ">Tomorrow's List</h3>
                                                </div> */}
                        {this.state.outSide === 1 ? (
                          <NewBookingsList
                            propertySlug={this.state.property_slug}
                          />
                        ) : (
                          <NewBookingsList />
                        )}
                      </div>
                    </div>
                    <div className="row row-sm">
                      {this.state.outSide === 1 ? (
                        <CheckoutToday
                          propertySlug={this.state.property_slug}
                        />
                      ) : (
                        <CheckoutToday />
                      )}
                      {this.state.outSide === 1 ? (
                        <CleaningToday
                          propertySlug={this.state.property_slug}
                        />
                      ) : (
                        <CleaningToday />
                      )}

                      {/* end of checouts boxes */}
                      {/* start of checkins boxes */}

                      {this.state.outSide === 1 ? (
                        <CheckinToday propertySlug={this.state.property_slug} />
                      ) : (
                        <CheckinToday />
                      )}
                      {this.state.outSide === 1 ? (
                        <CheckinTomorrow
                          propertySlug={this.state.property_slug}
                        />
                      ) : (
                        <CheckinTomorrow />
                      )}
                      {this.state.outSide === 1 ? (
                        <CheckOutTomorrow
                          propertySlug={this.state.property_slug}
                        />
                      ) : (
                        <CheckOutTomorrow />
                      )}
                      {this.state.outSide === 1 ? (
                        <CleaningTomorrow
                          propertySlug={this.state.property_slug}
                        />
                      ) : (
                        <CleaningTomorrow />
                      )}

                      {/* end of checkins boxes */}
                    </div>

                    {/* <hr className="border_hr" /> */}

                    <div className="row row-sm">
                      {this.state.outSide === 1 ? (
                        <NewlySignedLeases
                          propertySlug={this.state.property_slug}
                        />
                      ) : (
                        <NewlySignedLeases />
                      )}
                      {this.state.outSide === 1 ? (
                        <OccupancyList
                          propertySlug={this.state.property_slug}
                        />
                      ) : (
                        <OccupancyList />
                      )}
                    </div>
                    <div className="row row-sm">
                      <OnboardingProcessActivityList
                        propertySlug={this.state.property_slug}
                      />
                      <OnboardingCompletedActivityList
                        propertySlug={this.state.property_slug}
                      />
                    </div>
                    <div className="row row-sm">
                      <ReservationList
                        propertySlug={this.state.property_slug}
                      />
                      {this.state.outSide === 1 ? (
                        <GuestReview propertySlug={this.state.property_slug} />
                      ) : (
                        <GuestReview />
                      )}
                    </div>
                    <div className="row row-sm">
                      {this.state.outSide === 1 ? (
                        <LeaseInquiry propertySlug={this.state.property_slug} />
                      ) : (
                        <LeaseInquiry />
                      )}
                      {/* <RUInquiry propertySlug={this.state.property_slug} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
      </div>
    );
  }
}
export default SnapshotOverview;

//     							                          //
//  Program: CRMSidebar.jsx                               //
//  Application: Property                                 //
//  Option: common component Property Sidebar TAbs        //
//  Developer: NP 		                                  //
//  Date: 2022-08-29                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";

class CRMSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug") ? sessionStorage.getItem("property_slug") : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to CRM
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <ul>
                  {global.userPermissions.checkPermission('residents-list') || global.userPermissions.checkPermission('vendors-list') || global.userPermissions.checkPermission('staff-list') ?
                    <li>
                      <Link
                        className={
                          this.isPathActive("contact-lists")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/contact-lists"}
                      >
                        Contacts
                      </Link>
                    </li>
                  : ''}
                  {/* {global.userPermissions.checkPermission('crm-lists-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("contact-lists")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/contact-list"}
                      >
                        Lists
                      </Link>
                    </li>
                  }*/}
                  {global.userPermissions.checkPermission('segments-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("segments")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/segments"}
                      >
                        Segments
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission('campaigns-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("campaigns")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/campaigns"}
                      >
                        Campaigns
                      </Link>
                    </li>
                  }
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
             {/* New submenu */}
            <div className="newSubmenu">
            {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
            <span
              className="back"
              onClick={(event) => this.toggleHeaderMenu(event)}
            >
              <i className="fas fa-chevron-left"></i> Back to CRM
            </span>
              <ul>
                <li>
                  <ul>
                  {global.userPermissions.checkPermission("crm-lists-list") ?
                    <li>
                      <Link
                        className={
                          this.isPathActive("contact-lists")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/contact-lists"}
                      >
                        Contacts
                      </Link>
                    </li>
                  : ''}
                  {/* {global.userPermissions.checkPermission('crm-lists-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("contact-lists")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/contact-list"}
                      >
                        Lists
                      </Link>
                    </li>
                  }*/}
                  {global.userPermissions.checkPermission('segments-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("segments")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/segments"}
                      >
                        Segments
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission('campaigns-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("campaigns")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/campaigns"}
                      >
                        Campaigns
                      </Link>
                    </li>
                  }
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CRMSidebar;
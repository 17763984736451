import React, { Component } from "react";
import deleteicon from "../../assets/images/delete.svg";
// import pluswIcon from "./../../assets/images/plus.svg";
import { Form } from "react-bootstrap";
import pdfIcon from "../../assets/images/pdf.png";
import wordIcon from "../../assets/images/word.png";
import excelIcon from "../../assets/images/excel.png";
import formatNotSupported from "../../assets/images/not-supported.png";
import fontIcon from "../../assets/images/font-file.png";
import textIcon from "../../assets/images/text-file.png";
class HtmlTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedFiles: "",
      updateFileList: this.props.uploadedFiles,
      newRelatedFiles: this.props.previewFiles ? this.props.previewFiles : [],
      deletedFileList: [],
    };
    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    this.removeNewSelectedFile = this.removeNewSelectedFile.bind(this);
    this.removeUpdatedFile = this.removeUpdatedFile.bind(this);
    this.setState({
      newRelatedFiles: this.props.previewFiles
        ? this.props.previewFiles
        : this.state.newRelatedFiles
        ? this.state.newRelatedFiles
        : [],
    });
  }

  //@logic New preview file array set here.
  // previewFileList = async (event) => {
  //   const allFiles = event.target.files;
  //   /* //@logic delete old single file when selecting new single file */
  //   if (this.props.uploadType === "single") {
  //     // let deletedFileList = this.state.deletedFileList;
  //     // console.log(deletedFileList.length, deletedFileList.length <= 1);
  //     //commented on 08/02/22 by NP & GP for underfined errror
  //     // if (deletedFileList.length === 0) {
  //     //     this.removeUpdatedFile(0);
  //     // }
  //   }

  //   let newRelatedFiles = [];
  //   for (let i = 0; i < allFiles.length; i++) {
  //     let fileObject = allFiles[i];
  //     //Display preview if it's image
  //     if (fileObject.name.match(/.(jpg|jpeg|png|gif)$/i)) {
  //       allFiles[i]["src"] = URL.createObjectURL(fileObject);
  //     } else {
  //       allFiles[i]["src"] = "";
  //     }
  //     // document.getElementById("display_file_name").value = fileObject.name.split(".")[0];
  //     // var selected_file_name = fileObject.name.split(".")[0];
  //     // this.props.selected_file_name(selected_file_name);
  //     // this.setState({selected_file_name:selected_file_name })
  //     newRelatedFiles.push(allFiles[i]);
  //   }
  //   this.setState({
  //     newRelatedFiles: newRelatedFiles,
  //   });
  // };
  previewFileList = async (event) => {
    const allFiles = event.target.files;
    const { validationFire, uploadType, onValueChange } = this.props;
    const { newRelatedFilesPreview } = this.props;

    if (
      validationFire === "unit_import" ||
      validationFire === "current_leases" ||
      validationFire === "rent_collection"
    ) {
      if (allFiles.length > 0) {
        onValueChange(`${validationFire}_validation`);
      }
    }

    if (uploadType === "single") {
      // Logic for single file upload
    }

    let newRelatedFiles = [];
    if (validationFire === 0) {
      for (let i = 0; i < allFiles.length; i++) {
        let fileObject = allFiles[i];
        // Display preview if it's an image
        if (fileObject.name.match(/.(jpg|jpeg|png|gif)$/i)) {
          fileObject["src"] = URL.createObjectURL(fileObject);
        } else {
          fileObject["src"] = "";
        }
        newRelatedFiles.push(fileObject);
      }
    }
    this.setState({
      newRelatedFiles: newRelatedFiles,
    });
    setTimeout(() => {
      if (typeof newRelatedFilesPreview === "function") {
        newRelatedFilesPreview(newRelatedFiles);
      }
    }, 50);
  };

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value = event.target.value;

    let newRelatedFiles = this.state.newRelatedFiles;
    if (event.target.name === "description") {
      newRelatedFiles[index][event.target.name] = value;
      this.setState({ newRelatedFiles: newRelatedFiles });
      if (this.props.uploadType === "multiple") {
        this.props.newRelatedFilesDescChange(index, value);
      }
    } else {
      input[event.target.name] = value;
      this.setState({ [event.target.name]: value, input: input });
    }
  };

  //@logic New file remove manage here
  removeNewSelectedFile = async (key) => {
    let newFileList = this.state.newRelatedFiles;
    delete newFileList[key];
    this.setState({
      newRelatedFiles: newFileList,
    });
  };

  //@logic updated file remove manage here.
  removeUpdatedFile = async (key) => {
    // console.log(key);
    let updateFileList = this.state.updateFileList;
    // console.log("updateFileList", updateFileList);
    let deletedFileList = this.state.deletedFileList;
    // console.log("deletedFileList", deletedFileList);
    // let tempArr = updateFileList[key];
    let tempArr = {
      fieldName: this.props.fieldName,
      uploadedTo: this.props.uploadOn,
      fileDestination: this.props.destinationFolder,
      ...updateFileList[key],
    };
    deletedFileList.push(tempArr);
    this.props.deletedFiles.push(tempArr);
    delete updateFileList[key];
    this.setState({
      updateFileList: updateFileList,
      deletedFileList: deletedFileList,
    });
    // this.props.onRemovedFiles(deletedFileList);
  };

  render() {
    const allowMultiple = this.props.uploadType === "multiple" ? true : false;
    const dragClassName =
      this.props.drag === "true"
        ? "image-upload-wrap"
        : "multiImgBuuton file_widget_class";
    const inputDragClassName =
      this.props.drag === "true" ? "file-upload-input" : "file_widget_class";
    return (
      <React.Fragment>
        <div className="form-group mt-1 mb-0">
          {allowMultiple === true ? (
            /* <div className="row border-none align-items-center">
        <div className="col-md-3 pl-0">
          <label
            title={this.props.fieldTitle ? this.props.fieldTitle : ''}
            className={this.props.labelClassName ? this.props.labelClassName+" form-label text-left mg-b-0" : 'az-profile-name-text pl-0 pd-b-0'}
          >
            {this.props.fieldLabel ? this.props.fieldLabel : 'Image:'}
          </label>
        </div>


      <div className="col-md-7">
            <div className={dragClassName + "pd-l-30 pd-r-30 "} >
            <label
              htmlFor="contained-button-file">
              <div
                variant="file"
                className="btn-multiupload-outline d-flex flex-column text-center file_widget_class"
              >
                <img src={pluswIcon} alt="" />
                <span className="add">
                  <b>{this.props.dragText}</b>
                </span>
              </div>
            </label>
              <input
              style={{ display: "none" }}
              id="contained-button-file"
                type="file"
                multiple={allowMultiple}
                name={this.props.fieldName}
                title={this.props.fieldTitle}
                className={
                  this.props.inputClassName +
                  " file_widget_class " +
                  inputDragClassName
                }
                allowedtype={this.props.allowedType}
                maxfilesize={this.props.maxFileSize}
                maxfilesizetype={this.props.maxFileSizeType}
                maxfileallowed={this.props.maxFileAllowed}
                uploadon={this.props.uploadOn}
                slug={this.props.slug}
                reftableid={this.props.refTableId}
                displayfilename={this.props.displayFileName}
                documenttypeslug={this.props.documentTypeSlug}
                description={this.props.description}
                mediatype={this.props.mediaType}
                h1={this.props.h1}
                h2={this.props.h2}
                sortorder={this.props.sortOrder}
                statusid={this.props.statusId}
                checkvalidation={this.props.checkValidation}
                resizeoptions={this.props.resizeOptions}
                destinationfolder={this.props.destinationFolder}
                onChange={this.previewFileList}
                uploadedfiles={this.props.uploadedFiles}
                // onchange="readURL(this);"
                accept={this.props.accept}
              />
              {this.props.drag === "true" && (
                <div className="drag-text">
                  <h5>{this.props.dragText}</h5>
                </div>
              )}
            </div>
      </div>
      </div> */

            <div className={dragClassName}>
              <input
                // style={{ display: "none" }}
                // id="contained-button-file"
                type="file"
                multiple={allowMultiple}
                name={this.props.fieldName}
                title={this.props.fieldTitle}
                className={
                  this.props.inputClassName +
                  " file_widget_class " +
                  inputDragClassName
                }
                allowedtype={this.props.allowedType}
                maxfilesize={this.props.maxFileSize}
                maxfilesizetype={this.props.maxFileSizeType}
                maxfileallowed={this.props.maxFileAllowed}
                uploadon={this.props.uploadOn}
                slug={this.props.slug}
                reftableid={this.props.refTableId}
                displayfilename={this.props.displayFileName}
                documenttypeslug={this.props.documentTypeSlug}
                description={this.props.description}
                mediatype={this.props.mediaType}
                dailyDelivery={this.props.dailyDelivery}
                validationFire={this.props.validationFire}
                propertySlug={this.props.propertySlug}
                h1={this.props.h1}
                h2={this.props.h2}
                sortorder={this.props.sortOrder}
                statusid={this.props.statusId}
                checkvalidation={this.props.checkValidation}
                resizeoptions={this.props.resizeOptions}
                destinationfolder={this.props.destinationFolder}
                onChange={this.previewFileList}
                uploadedfiles={this.props.uploadedFiles}
                // onchange="readURL(this);"
                accept={this.props.accept}
                previewFiles={this.props.previewFiles}
              />
              {this.props.drag === "true" && (
                <div className="drag-text">
                  <h5>{this.props.dragText}</h5>
                </div>
              )}
            </div>
          ) : (
            <div className={dragClassName}>
              <input
                type="file"
                multiple={allowMultiple}
                name={this.props.fieldName}
                title={this.props.fieldTitle}
                className={
                  this.props.inputClassName +
                  " file_widget_class " +
                  inputDragClassName
                }
                allowedtype={this.props.allowedType}
                maxfilesize={this.props.maxFileSize}
                maxfilesizetype={this.props.maxFileSizeType}
                maxfileallowed={this.props.maxFileAllowed}
                uploadon={this.props.uploadOn}
                slug={this.props.slug}
                reftableid={this.props.refTableId}
                displayfilename={this.props.displayFileName}
                documenttypeslug={this.props.documentTypeSlug}
                description={this.props.description}
                mediatype={this.props.mediaType}
                dailyDelivery={this.props.dailyDelivery}
                validationFire={this.props.validationFire}
                propertySlug={this.props.propertySlug}
                h1={this.props.h1}
                h2={this.props.h2}
                sortorder={this.props.sortOrder}
                statusid={this.props.statusId}
                checkvalidation={this.props.checkValidation}
                resizeoptions={this.props.resizeOptions}
                destinationfolder={this.props.destinationFolder}
                onChange={this.previewFileList}
                uploadedfiles={this.props.uploadedFiles}
                // onchange="readURL(this);"
                accept={this.props.accept}
              />
              {this.props.drag === "true" && (
                <div className="drag-text">
                  <h5>{this.props.dragText}</h5>
                </div>
              )}
            </div>
          )}

          {/* <small className="form-text text-muted">
                    <a
                        href={this.props.sampleFileUrl}
                        target="_blank"
                        rel="noreferrer"
                        download>Click Here </a>
                    {this.props.helpText}
                </small> */}

          {/* //@logic  when updated file array need to preview files. */}
          {this.props.displayPreviewStyle === "vertical" ? (
            <ul
              className="fileList list-group list-group-flush pd-t-30"
              id={"old_" + this.props.fieldName}
            >
              {this.state.updateFileList !== undefined &&
                this.state.updateFileList.map((val, key) => {
                  return (
                    <React.Fragment key={key}>
                      <li className="list-group-item">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            {val.orgFileName.match(/.(jpg|jpeg|png|gif)$/i) &&
                            val.fileUrl !== "" ? (
                              <img
                                src={val.fileUrl}
                                alt=""
                                width="30"
                                height="30"
                              />
                            ) : (
                              <span
                                style={{ marginLeft: "32px" }}
                                width="30"
                                height="30"
                              ></span>
                            )}
                            <strong> {val.orgFileName} </strong> -{" "}
                            {val.fileSize} bytes.
                          </div>

                          <button
                            className="removeFile btn h3 float-end"
                            type="button"
                            data-fileid={key}
                            onClick={() => this.removeUpdatedFile(key)}
                          >
                            <span className="h3" aria-hidden="true">
                              <img src={deleteicon} alt="" />
                            </span>
                          </button>
                        </div>
                      </li>
                    </React.Fragment>
                  );
                })}
            </ul>
          ) : (
            this.state.updateFileList !== undefined && (
              <div className="container mt-3">
                <div className="">
                  {this.state.updateFileList.map((val, key) => {
                    return (
                      <React.Fragment key={key}>
                        <div className="">
                          <div className="d-flex align-items-center">
                            {val.orgFileName.match(/.(jpg|jpeg|png|gif)$/i) &&
                            val.fileUrl !== "" ? (
                              <img
                                className="onbord_file_preview_icon mg-r-5"
                                src={val.fileUrl}
                                alt=""
                              />
                            ) : (
                              <span className="onbord_file_preview_icon mg-r-5"></span>
                            )}
                            <p className="title m-0 w-100">
                              <strong> {val.orgFileName} </strong> <br />
                              <strong> {val.fileSize} </strong>{" "}
                              <small> bytes</small>
                            </p>
                            <div
                              className="button"
                              data-fileid={key}
                              onClick={() => this.removeUpdatedFile(key)}
                            >
                              <span aria-hidden="true">
                                <img
                                  src={deleteicon}
                                  className="hand-cursor"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            )
          )}

          {/* //@logic  when new file array need to preview files. */}
          {this.props.displayPreviewStyle === "vertical" ? (
            <ul
              className="multiImgsection fileList list-group list-group-flush"
              id={this.props.fieldName}
            >
              {this.state.newRelatedFiles.length > 0 &&
                this.state.newRelatedFiles.map((val, key) => {
                  return (
                    <React.Fragment key={key}>
                      <li className="list-group-item">
                        <div className="row pd-b-20 ml-0">
                          {/* <div>
                      {val.name.match(/.(jpg|jpeg|png|gif)$/i) &&
                      val.src !== "" ? (
                        <img src={val.src} width="30" height="30" />
                      ) : (
                        <span
                          style={{ marginLeft: "32px" }}
                          width="30"
                          height="30"
                        />
                      )}
                      <strong> {val.name} </strong> - {val.size} bytes C.
                      </div> */}
                          <div className="col-md-12  pl-0 pr-15 d-flex">
                            <figure className="mg-r-15">
                              <img src={val.src} alt="" />
                            </figure>
                            <Form.Control
                              onChange={(e) => this.changeHandler(e, key)}
                              name="description"
                              value={this.state.description}
                              className="form-control max_width_100"
                              type="text"
                              rows="4"
                              as="textarea"
                              autoComplete="off"
                              // placeholder="Description"
                              resize="resize"
                            />

                            <div className="mg-l-15 mg-r-0 text-center">
                              <button
                                type="button"
                                className="removeFile btn-multilist-outline-danger"
                                onClick={() => this.removeNewSelectedFile(key)}
                              >
                                <img src={deleteicon} alt="" />
                              </button>
                              <span className="danger">
                                Remove <b>image</b>
                              </span>
                            </div>
                          </div>

                          {/* <div className="col-md-2 text-center d-flex justify-content-start">

                      </div> */}
                        </div>
                        {/* //@logic progress bar */}
                        <span>
                          <div
                            className="progress hidden"
                            id={"progress" + this.props.fieldName + key}
                          >
                            <div
                              id={"progressBar" + this.props.fieldName + key}
                              className=" progress-bar "
                              role="progressbar"
                              aria-valuenow="0"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </span>
                      </li>
                    </React.Fragment>
                  );
                })}
            </ul>
          ) : (
            !this.props.previewFiles &&
            this.state.newRelatedFiles !== undefined &&
            this.state.newRelatedFiles &&
            this.state.newRelatedFiles.length > 0 && (
              <div className="container mt-3">
                <div className="">
                  {this.props.validationFire === 0 &&
                    this.state.newRelatedFiles.map((val, key) => {
                      return (
                        <React.Fragment key={key}>
                          <div className="">
                            <div
                              className={
                                this.props.uploadType === "single"
                                  ? "d-flex align-items-center"
                                  : "d-flex align-items-center"
                              }
                            >
                              <img
                                className="onbord_file_preview_icon mg-r-5"
                                src={
                                  val.name.match(/.(jpg|jpeg|png|gif)$/i)
                                    ? val.src
                                    : val.name.match(/.(pdf)$/i)
                                    ? pdfIcon
                                    : val.name.match(/.(docx|doc)$/i)
                                    ? wordIcon
                                    : val.name.match(/.(xlsx|csv|xls)$/i)
                                    ? excelIcon
                                    : val.name.match(/.(woff|woff2|ttf|otf)$/i)
                                    ? fontIcon
                                    : val.name.match(/.(txt)$/i)
                                    ? textIcon
                                    : formatNotSupported
                                }
                                alt=""
                              />

                              <p
                                className={
                                  this.props.uploadType === "single"
                                    ? "title d-block m-0 w-100"
                                    : "title m-0 w-100"
                                }
                              >
                                <strong> {val.name} </strong>
                              </p>
                              <div
                                className="button"
                                data-fileid={key}
                                onClick={() => this.removeNewSelectedFile(key)}
                              >
                                <span aria-hidden="true">
                                  <img
                                    src={deleteicon}
                                    className="hand-cursor"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>

                              {/* //@logic progress bar */}
                              <span>
                                <div
                                  className="progress hidden hpreview"
                                  id={"progress" + this.props.fieldName + key}
                                >
                                  <div
                                    id={
                                      "progressBar" + this.props.fieldName + key
                                    }
                                    className=" progress-bar "
                                    role="progressbar"
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            )
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default HtmlTemplate;

/*-------------------------------------------------------
||Program           : LeaseInquiry.jsx
||Application       : Snapshot
||Option            : Snapshot - Lease inquiry needs to display a widget in snapshot section
||Developer         : Chandra Shekhar
||Date              : 12-July-2023
||Status            : In Use
----------------------------------------------------------*/
import React, { Component } from "react";
import { withRouter } from "react-router";
import CompanyService from "../../services/CompanyService";
import NoBooking from "../../assets/images/snapshoticons/Bookings.svg";
// import PhoneNumberFormat from "../common/PhoneNumberFormat";
import Moment from "moment";
import { Link } from "react-router-dom";

const formatPhoneNumber = (phoneNumber) => {
  var lastTenDigits = "";
  const cleanedNumber = phoneNumber.replace(/[\s-]/g, "");
  if (cleanedNumber.length > 10) {
    // If it's longer, keep only the last 10 characters
    lastTenDigits = cleanedNumber.slice(-10);
  } else {
    // If it's 10 or less characters, set it as is
    lastTenDigits = phoneNumber;
  }

  var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
  var finalnumber = "";
  if (number.length < 4) {
    finalnumber = number;
  } else if (number.length < 7) {
    finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
  } else if (number.length < 11) {
    finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  } else {
    finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  return finalnumber;
};

class LeaseInquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertySlug: this.props.propertySlug,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      listCount: 0,
      lists: [],
    };
  }

  componentDidMount() {
    this.setState({ spinnerLoader: true, loading: true });
    this.setState({ listCount: 0 });
    if (this.state.propertySlug) {
      this.getData(this.state.propertySlug);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ spinnerLoader: true, loading: true });
    this.setState({ listCount: 0 });
    if (nextProps.propertySlug) {
      this.getData(nextProps.propertySlug);
    }
  }

  async getData(propertySlug) {
    this.setState({ spinnerLoader: true, loading: true });
    var resData = {};
    let res = await CompanyService.getLeaseInquiry(propertySlug);
    if (global.successStatus.includes(res.status)) {
      resData.lists = res.data ? res.data : [];
      this.setState({
        resData: resData,
        listCount: res.data.length,
      });
    }
    this.setState({ spinnerLoader: false, loading: false });
  }

  handleClickCheckinList(property_slug, slug) {
    if (slug) {
      const win = window.open(
        "/applicant-detail/view/" + property_slug + "/" + slug,
        "_blank"
      );
      win.focus();
    } else {
      return false;
    }
  }

  render() {
    return (
      <div className="col-xxl-6 col-md-6 col-sm-12 col-12 mg-b-20">
        <div className="card card-table-two">
          <div className=" d-flex justify-content-between">
            <div className="">
              <h6 className="card-title">Lease Inquiry</h6>
              <span className="d-block mg-b-0">Recent Lease Inquiries</span>
            </div>
            <Link className="btn-snap-view" to={"/applicant-detail"}>
              View all
            </Link>
          </div>
          {this.state.listCount > 0 ? (
            <div className="table-responsive">
              <table className="table table-dashboard-two mg-b-0">
                <thead>
                  <tr>
                    <th className="wd-lg-25p tx-left">S.No</th>
                    <th className="wd-lg-25p tx-left">Name</th>
                    <th className="wd-lg-25p text-left">Email</th>
                    <th className="wd-lg-25p text-left">Number</th>
                    <th className="wd-lg-25p text-left">Date & Time</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.resData.lists.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        value={item}
                        onClick={() =>
                          this.handleClickCheckinList(
                            item.property_slug,
                            item.applicant_tour_details_slug
                          )
                        }
                      >
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {i + 1}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.first_name ? item.first_name : ""}{" "}
                          {item.last_name ? item.last_name : ""}
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.email ? item.email : ""}
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.phone_number
                            ? formatPhoneNumber(item.phone_number)
                            : ""}
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                          {item.created_at
                            ? Moment(item.created_at).format(
                                global.dateTimeFormat
                              )
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="table-responsive overflow-hidden">
                {this.state.spinnerLoader ? (
                  global.snapshotloader
                ) : (
                  <div className="text-center">
                    <img src={NoBooking} alt="" />
                    <p>No Inquiry list.</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(LeaseInquiry);

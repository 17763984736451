////////////////////////////////////////////////////////////
//     							                          //
//  Program: EmailService.js                              //
//  Application: Email                                    //
//  Option: Used for manage Emails                        //
//  Developer: NP 						                  //
//  Date: 2022-12-15                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AllTemplateService {
  
  getTemplateList(queryString) {
    let endPoint = "templates" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getTemplate(slug) {
    let endPoint = "get-template/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createTemplate(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "template-store";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateTemplate(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "post-template/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateTemplatecheckbox(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "post-template-checkbox/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteTemplate(slug) {
    let endPoint = "delete-template/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadEmailTemplateAttachment(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'upload-email-template-attachment';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
    let response = ApiService.postCall(inputData);
    return response;
  }
  
  copyTemplateList(slug) {
    let endPoint = "copy-templates/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

}

export default new AllTemplateService();

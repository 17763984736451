////////////////////////////////////////////////////////////
//  Program: Template List.jsx                            //
//  Application:  Template  List                          //
//  Option: List of Template                              //
//  Developer: Ashish Kumar                               //
//  Date: 2022-05-17                                      //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
// import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import ChecklistService from "../../services/ChecklistService";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
// import pluswIcon from "./../../assets/images/plus.svg";
import { Container, Modal, Table } from "react-bootstrap";
import NoData from "../common/NoData";
import SettingSidebar from "../common/SettingSidebar";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";

export class Templates extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      property_slug: this.props.match.params.propertySlug,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      total_record: 0,
      records: [],
      // config: {
      //   sort: {
      //     column: "checklist_name",
      //     order: "asc",
      //   },
      //   page_size: global.page_size,
      //   length_menu: global.page_length_menu,
      //   show_length_menu: true,
      //   show_filter: global.show_filter,
      //   show_pagination: global.show_pagination,
      //   pagination: "advance",
      //   button: {
      //     excel: global.excel_button,
      //     print: global.print_button,
      //     extra: false,
      //   },
      //   language: {
      //     no_data_text: "No record found",
      //   },
      // },

      // extraButtons: [],
      // columns: [
      //   {
      //     key: "checklist_name",
      //     text: "Templates",
      //     className: "template_name hand-cursor",
      //     sortable: true,
      //     cell: (record) => {
      //       return record.checklist_name ? record.checklist_name : "";
      //     },
      //   },

      //   {
      //     key: "zone_type",
      //     text: "Zone Type",
      //     className: "zone_type hand-cursor",
      //     sortable: true,
      //     cell: (record) => {
      //       return record.zone_type ? record.zone_type.zone_type_name : "";
      //     },
      //   },

      //   {
      //     key: "status_id",
      //     text: "Status",
      //     className: "status_id hand-cursor",
      //     sortable: true,
      //     cell: (record) => {
      //       return record.status_id === 1 ? (
      //         <b className="status-active">Active</b>
      //       ) : (
      //         <b className="status-inactive">InActive</b>
      //       );
      //     },
      //   },
      // ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.openDeleteChecklistModal = this.openDeleteChecklistModal.bind(this);
    this.closeDeleteChecklistModal = this.closeDeleteChecklistModal.bind(this);
  }

  componentDidMount() {
    this.getData();
  }
  async getData(queryString = "") {
    var list = [];
    var total_record = 0;

    let res = await ChecklistService.getTemplateList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      total_record = list ? list.length : 0
    }
    this.setState({
      records: list,
      total_record: total_record
    });
  }

  customFilter() {
    let queryString =
      "country_slug=" +
      this.state.country_id +
      "&city_slug=" +
      this.state.city_id +
      "&state_slug=" +
      this.state.state_id;
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetData() {
    this.setState({ country_slug: "" });
    this.setState({ city_slug: "" });
    this.setState({ state_slug: "" });
    this.getData();
  }

  openDeleteChecklistModal(slug) {
    this.setState({ deleteChecklistModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteChecklistModal() {
    this.setState({ deleteChecklistModal: false });
    this.setState({ slug: "" });
  }

  async deleteChecklist(slug) {
    this.setState({ showSpinner: true });
    let res = await ChecklistService.deleteCheckList(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteChecklistModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getData();
    } else {
      this.closeDeleteChecklistModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  // tableChangeHandler = (data) => {
  //   let queryString = Object.keys(data)
  //     .map((key) => {
  //       if (key === "sort_order" && data[key]) {
  //         return (
  //           encodeURIComponent("sort_order") +
  //           "=" +
  //           encodeURIComponent(data[key].order) +
  //           "&" +
  //           encodeURIComponent("sort_column") +
  //           "=" +
  //           encodeURIComponent(data[key].column)
  //         );
  //       } else {
  //         let newKey = key;
  //         if (key === "page_number") {
  //           newKey = "page";
  //         } else if (key === "sort_order") {
  //           data[key] = "asc";
  //         }
  //         return (
  //           encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
  //         );
  //       }
  //     })
  //     .join("&");

  //   if (data.filter_value !== "") {
  //     this.setState({ tableQueryString: queryString });
  //   } else {
  //     queryString += "&" + this.state.customQueryString;
  //     this.setState({ tableQueryString: queryString });
  //   }

  //   if (data.filter_value !== this.state.filter_value) {
  //     clearTimeout(this.state.filter_time);
  //     this.setState({
  //       filter_time: setTimeout(
  //         () => this.getData(queryString),
  //         global.filter_time
  //       ),
  //       filter_value: data.filter_value,
  //     });
  //   } else {
  //     this.getData(queryString);
  //   }
  // };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  rowClickedHandler(slug) {
    global.userPermissions.checkPermission("checklist-templates-update") &&
      this.props.history.push("/templates/" + slug);
  }

  getTitle() {
    return "Cleaning Checklists";
  }
  render() {
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <SettingSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {/* <Button
                variant="outline-dark btn-block btn-with-icon"
                onClick={this.openAddRoomCategoryModal}
              >
                <img src={pluswIcon} /> Add
              </Button> */}
                        {global.userPermissions.checkPermission(
                          "checklist-templates-add"
                        ) && (
                          <Link to={"/templates/add"}>
                            <button className="outline-dark btn-block btn-with-icon">
                              {/* <img src={pluswIcon} alt="" /> */}
                              Add
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.total_record > 0 ?
                      <Table hover className="mg-b-0">
                        <thead>
                          <tr>
                            <th className="w-30">Template Name</th>
                            <th className="w-30">Applicable</th>
                            <th className="w-35">Status</th>
                            {global.userPermissions.checkPermission(
                              "checklist-template-delete"
                            ) && <th className="text-center">Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.total_record > 0 ? (
                            this.state.records.map((item, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td
                                      className="hand-cursor"
                                      onClick={() =>
                                        global.userPermissions.checkPermission(
                                          "checklist-templates-update"
                                        ) && this.rowClickedHandler(item.slug)
                                      }
                                    >
                                      {item.checklist_name
                                        ? item.checklist_name
                                        : ""}
                                    </td>
                                    <td
                                      className="hand-cursor"
                                      onClick={() =>
                                        global.userPermissions.checkPermission(
                                          "checklist-templates-update"
                                        ) && this.rowClickedHandler(item.slug)
                                      }
                                    >
                                      {item.zone_type
                                        ? item.zone_type.zone_type_name
                                        : ""}
                                    </td>
                                    <td
                                      className="hand-cursor"
                                      onClick={() =>
                                        global.userPermissions.checkPermission(
                                          "checklist-templates-update"
                                        ) && this.rowClickedHandler(item.slug)
                                      }
                                    >
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {global.userPermissions.checkPermission(
                                      "checklist-template-delete"
                                    ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt=""
                                            onClick={() =>
                                              this.openDeleteChecklistModal(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                There are no checklist templates added in the
                                system. You may add them by clicking Add button.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      : (
                        <NoData msg={global.commonMessages.checkMessage("CHECKLIST_TEMPLATE")} />
                      )}
                    </div>
                  </div>
                  {/* <div className="scrolling-carousel pd-l-15 pd-r-15">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      {this.state.total_record !== 0 ||
                      this.state.filter_value !== "" ? (
                        <div className="mg-t-18 brandList p-0">
                          <ReactDatatable
                            className="table mg-b-30 pd-b-2 add-no-wrap"
                            config={
                              this.state.total_record > global.page_size
                                ? this.state.config
                                : this.state.config
                            }
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            onRowClicked={this.rowClickedHandler}
                          />
                        </div>
                      ) : (
                        <NoData msg="templates" />
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </Container>
          <Modal
            show={this.state.deleteChecklistModal}
            // onHide={() => {
            //   if (
            //     window.confirm("Are you sure to exit the pop up without delete?")
            //   )
            //     this.closeDeleteChecklistModal();
            // }}
            centered
          >
            <Modal.Body className="width_402 p-4 border_radius_14">
              <div className="text-center">
                <span
                  className="welcomeClose"
                  onClick={() => this.closeDeleteChecklistModal()}
                >
                  {global.closee}
                </span>
                <img src={modaldeleteicon} alt="modal-delete-icon" />
                <h3>Delete the Option?</h3>
                <p>
                  Do you really want to delete this option? This process cannot
                  be undone.
                </p>
                <div className="d-flex justify-content-center mt-3 row">
                  <button
                    type="button"
                    className="m-0 btn btn-outline-light btn-block"
                    onClick={() => this.closeDeleteChecklistModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="m-0 btn btn btn-danger btn-block"
                    onClick={() => this.deleteChecklist(this.state.slug)}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Delete"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </main>
      </>
    );
  }
}

export default Templates;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: AutomationSettingService.js                  //
//  Application: AutomationSetting                        //
//  Option: Used for manage amenity                       //
//  Developer: Anil kumar gupta 					      //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class DataHubAutomationService {
  getAllAutomationSetting(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "get-automation-data-hub-settings";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  createAutomationSetting(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "amenity";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getAutomationSettingBySlug(slug) {
    let endPoint = "get-automation-setting/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  addOrUpdateAutomationSetting(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "add-udpate-automation-setting";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  ExportAddOrUpdateAutomationSetting(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "export-add-udpate-automation-setting";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getExportAllAutomationSetting(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "get-export-automation-data-hub-settings";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getExportAutomationSettingBySlug(data) {
    let endPoint = "get-export-automation-setting";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteExportAutomationSetting(slug) {
    let endPoint = "export-delete-automation-setting/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  deleteAutomationSetting(slug) {
    let endPoint = "delete-automation-setting/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  /** Get Pms Floor Plans Mapping List */
  GetPmsFloorPlansList(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "get-pms-floor-plans";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  setMapping(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "udpate-pms-floor-plans";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  TestUnitListImportAutomation(property_slug) {
    let endPoint = "unit-import-automation?property_slug=" + property_slug;
    let addditionalHeaderData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: {},
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  TestUnitListExportAutomation(property_slug, exportAutomationSlug) {
    let endPoint =
      "unit-export-automation?property_slug=" +
      property_slug +
      "&export_automation_slug=" +
      exportAutomationSlug;
    let addditionalHeaderData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: {},
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  TestReservationListExportAutomation(property_slug, exportAutomationSlug) {
    let endPoint =
      "reservation-export-automation?property_slug=" +
      property_slug +
      "&export_automation_slug=" +
      exportAutomationSlug;
    let addditionalHeaderData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: {},
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  TestUnitAvailabilityImportAutomation(property_slug) {
    let endPoint = "unit-availabilty-automation?property_slug=" + property_slug;
    let addditionalHeaderData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: {},
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  TestLeaseImportAutomation(property_slug) {
    let endPoint = "lease-import-automation?property_slug=" + property_slug;
    let addditionalHeaderData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: {},
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  TestLeaseExportAutomation(property_slug, exportAutomationSlug) {
    let endPoint =
      "lease-export-automation?property_slug=" +
      property_slug +
      "&export_automation_slug=" +
      exportAutomationSlug;
    let addditionalHeaderData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: {},
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  GetAutomationProcessingLogs(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "get-automation-processing-logs";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  UnitAvaibilityimportPreview(data) {
    let endPoint = "unit-availability-import-preview";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };

    let response = ApiService.uploadFileCall(inputData);
    return response;
  }

  UnitimportPreview(data) {
    let endPoint = "unit-import-preview";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.uploadFileCall(inputData);
    return response;
  }

  LeaseimportPreview(data) {
    let endPoint = "lease-import-preview";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.uploadFileCall(inputData);
    return response;
  }

  RentimportPreview(data) {
    let endPoint = "rent-import-preview";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.uploadFileCall(inputData);
    return response;
  }

  TestMailAutomation(property_slug) {
    let endPoint = "test-mail-automation?property_slug=" + property_slug;
    let addditionalHeaderData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: {},
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  DeletedUpdatedUnit(slug, property_slug) {
    let endPoint = "get-delete-update-units/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  ExportTestMailAutomation(property_slug, exportAutomationSlug) {
    let endPoint =
      "test-export-mail-automation?property_slug=" +
      property_slug +
      "&export_automation_slug=" +
      exportAutomationSlug;
    let addditionalHeaderData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: {},
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  TestRentImportAutomation(property_slug) {
    let endPoint = "rent-import-automation?property_slug=" + property_slug;
    let addditionalHeaderData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: {},
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new DataHubAutomationService();

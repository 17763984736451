import React from "react";
import { useCallback, useState } from "react";
import CalendarService from "../../services/CalendarService";
import {
  Eventcalendar,
  setOptions,
  Popup,
  CalendarNav,
  SegmentedGroup,
  SegmentedItem,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
} from "@mobiscroll/react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
// import staffImg from "./../../assets/images/profile-nogender.png";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertiesCleaningSidebar from "../common/AllPropertiesCleaningSidebar";

setOptions({
  theme: "ios",
  themeVariant: "light",
  dateFormatLong: "MM/DD/YY",
});

const HouseKeepingCalendar = (props) => {
  const [event, setEvent] = useState({});
  const [events, myEvents] = useState([]);
  const [resources, myResources] = useState([]);
  const [isPopupOpen, setOpen] = useState(false);
  // const property_slug = props.match.params.propertySlug;
  const [property_slug, setPropertySlug] = useState(
    props.match.params.propertySlug !== undefined
    ? props.match.params.propertySlug
    : localStorage.getItem("outSidePorpertySlug")
    ? localStorage.getItem("outSidePorpertySlug")
    : ""
  );
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [view, setView] = React.useState("day");
  const [calView, setCalView] = React.useState({
    timeline: {
      type: "day",
    },
  });

  // const [unitList, setUnitList] = useState(0);

  const onPageLoading = React.useCallback((event, inst) => {
    const year = event.month.getFullYear();
    const month = event.month.getMonth();

    if (props.match.params.propertySlug !== undefined) {
      setPropertySlug(props.match.params.propertySlug);
      setTimeout(() => {
        getReservationdata(props.match.params.propertySlug, year, month);
      }, 200);
    } else {
      const propSlug = localStorage.getItem("outSidePorpertySlug")
        ? localStorage.getItem("outSidePorpertySlug")
        : "";
      setPropertySlug(propSlug);
      setTimeout(() => {
        getReservationdata(
          localStorage.getItem("outSidePorpertySlug")
            ? localStorage.getItem("outSidePorpertySlug")
            : "",
          year,
          month
        );
      }, 200);
    }
  }, []);

  const getReservationdata = async (property_slug, year, month) => {
    setPropertySlug(property_slug);
    setYear(year);
    setMonth(month);
    let res = await CalendarService.getChecklists(
      "year=" +
        year +
        "&month=" +
        (month + 1) +
        "&property_slug=" +
        (property_slug ? property_slug : "")
    );

    if (global.successStatus.includes(res.status)) {
      myEvents(res.data.checklistData ? res.data.checklistData : []);
      myResources(res.data.unitData ? res.data.unitData : []);
    }
  };

  //event pills
  const myScheduleEvent = React.useCallback((data) => {
    const ev = data.original;
    const color = data.original.color;
    // const color = "#2c5046";
    return (
      <div
        className="md-timeline-template-event"
        style={{ borderColor: color, background: color }}
      >
        <div className="md-timeline-template-event-cont">
          {/* <span className={"mbsc-icon mbsc-font-icon mbsc-icon-airplane"} style={{ background: color }}><img src={ev.img} alt="logo" /></span> */}
          <span className="md-timeline-template-title">
            {ev.staff_name + " - " + ev.status}
          </span>
        </div>
      </div>
    );
  }, []);

  const openPopup = useCallback((event) => {
    setOpen(true);
    setEvent(event);
  }, []);

  const closePopup = useCallback(() => {
    setOpen(false);
  }, []);

  const renderMyHeader = () => {
    return (
      <React.Fragment>
        <CalendarPrev className="md-event-listing-prev" />
        <CalendarNav className="md-event-listing-nav" />
        <CalendarNext className="md-event-listing-next" />
        <div className="md-event-listing-picker">
          <SegmentedGroup value={view} onChange={changeView}>
            <SegmentedItem value="day">Day</SegmentedItem>
            <SegmentedItem value="week">Week</SegmentedItem>
            {/* <SegmentedItem value="month">Month</SegmentedItem> */}
          </SegmentedGroup>
        </div>
        {/* <CalendarPrev className="md-event-listing-prev" /> */}
        <CalendarToday className="md-event-listing-today pr-4" />
        {/* <CalendarNext className="md-event-listing-next" /> */}
      </React.Fragment>
    );
  };

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "day":
        calView = {
          timeline: {
            type: "day",
          },
        };
        break;
      case "week":
        calView = {
          timeline: {
            type: "week",
            eventList: true,
          },
        };
        break;
      case "month":
      default:
        calView = {
          timeline: {
            type: "month",
          },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };

  const renderCustomResource = (resource) => {
    return (
      <div className="md-resource-header-template-cont" title={resource.name}>
        <div className="md-resource-header-template-name">
          {resource.unit_name ? resource.unit_name : resource.unit_number}
        </div>
        <div className="md-resource-header-template-name">
          {resource.resident_name}
        </div>
        <div className="md-resource-header-template-name">
          {resource.listing_name}
        </div>
      </div>
    );
  };

  const getPropertySlugData = async (property_slug) => {
    setPropertySlug(property_slug);
    getReservationdata(property_slug, year, month);
  };

  return (
    <>
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <HouseKeepingSidebar property_slug={property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertiesCleaningSidebar
                      property_slug={property_slug}
                    />
                  </div>
                </div>
              )}
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3 className="mb-0">Cleaning Calendar</h3>
                    {/* <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                                    <button
                                        onClick={this.filterToggle}
                                        className="btn-success-outline-small mr-3"
                                    >
                                        <img src={filterIcon} alt="filterIcon" />{" "}
                                        {this.state.showFilter === 1 ? "Filter" : "Filter"}
                                    </button>
                                    </div> */}
                  </div>
                </div>
                {props.match.params.propertySlug === undefined ? (
                  <div className="col-xxl-2 col-sm-3 pd-r-0 pd-lg-l-45 pd-xs-l-15  mg-b-45">
                    <OutSidePropertySelectBox
                      propertySlug={getPropertySlugData}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-45 pd-lg-r-30 mg-t-30">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="calendar-section pd-lg-l-0 pd-xs-l-15">
                      <Eventcalendar
                        theme="ios"
                        themeVariant="light"
                        clickToCreate={false}
                        dragToCreate={false}
                        dragToMove={false}
                        dragToResize={false}
                        view={calView}
                        data={events}
                        resources={resources}
                        renderScheduleEvent={myScheduleEvent}
                        cssClass="md-timeline-template md-event-listing"
                        onEventClick={(event) => openPopup(event)}
                        showEventTooltip={false}
                        // onEventHoverIn={onEventHoverIn}
                        // onEventHoverOut={onEventHoverOut}
                        renderResource={renderCustomResource}
                        renderHeader={renderMyHeader}
                        onPageLoading={onPageLoading}
                      />

                      <Popup
                        display="center"
                        // fullScreen={true}
                        contentPadding={true}
                        headerText={
                          event && event.event && event.event.title
                            ? event.event.title
                            : ""
                        }
                        isOpen={isPopupOpen}
                        onClose={closePopup}
                      >
                        <div className="text-center popup">
                          <span
                            className="popupClose hand-cursor"
                            onClick={closePopup}
                          >
                            {global.closee}
                          </span>
                          <p className="az-profile-name-text pl-0">
                            Cleaning date & time{" "}
                          </p>{" "}
                          <p className="media-body">
                            {event && event.event && event.event.view_start
                              ? event.event.view_start
                              : ""}
                          </p>
                          {/* <p>Check-out date & time : {event && event.event && event.event.view_end ? event.event.view_end : ''}</p> */}
                          <p className="az-profile-name-text pl-0">
                            Guest name{" "}
                          </p>{" "}
                          <p className="media-body">
                            {event && event.event && event.event.guest_name
                              ? event.event.guest_name
                              : ""}
                          </p>
                          <p className="az-profile-name-text pl-0">
                            Staff name{" "}
                          </p>{" "}
                          <p className="media-body">
                            {event && event.event && event.event.staff_name
                              ? event.event.staff_name
                              : ""}
                          </p>
                          <p className="az-profile-name-text pl-0">Status </p>{" "}
                          <p className="media-body">
                            {" "}
                            {event && event.event && event.event.status
                              ? event.event.status
                              : "Pending"}
                          </p>
                          {event &&
                          event.event &&
                          event.event.reservation_slug ? (
                            <Link
                              to={
                                event &&
                                event.event &&
                                event.event.reservation_slug
                                  ? "/properties/view/" +
                                    property_slug +
                                    "/reservations/view/" +
                                    event.event.reservation_slug
                                  : ""
                              }
                            >
                              <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                                View
                              </button>
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </Popup>
                    </div>
                  </div>
                </div>
                {/* ) : (
                  <NoData />
                )} */}
              </div>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};
export default HouseKeepingCalendar;

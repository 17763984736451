import React, { Component } from "react";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import SimpleReactValidator from "simple-react-validator";
import { Form } from "react-bootstrap";
import Alerts from "../common/Alerts";

export class PropertyAffordableView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      // propertySlug: this.props.property_slug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      confirm_back: 0,

    };
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.saveOrUpdateAffordableComponent =
      this.saveOrUpdateAffordableComponent.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    // this.getData();
    this.getPropertyAffordable();
  }

  // async getData() {
  //     if (this.props.property_slug !== undefined) {
  //       var resData = {};
  //       let res = await PropertyService.getProperty(this.props.property_slug);
  //       if (global.successStatus.includes(res.status)) {
  //         resData = res.data ? res.data : {}
  //       }
  //       this.setState(resData);
  //     }
  // }

  // get affordable
  async getPropertyAffordable(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var unitTypeData = [];
    var unitTypeDataTotal = 0;
    let res = await PropertyService.getPropertyAffordable(
      this.props.property_slug
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.day = res.data.affordable_financed === 1 ? true : false;
      resData.total_affordable_unit = res.data.total_affordable_unit
        ? res.data.total_affordable_unit
        : "";
      resData.affordable_percent_complete = res.data.affordable_percent_complete
        ? res.data.affordable_percent_complete
        : "";
      resData.affordable_program_income_tiers = res.data
        .affordable_program_income_tiers
        ? res.data.affordable_program_income_tiers
        : "";
      resData.affordable_component_check = res.data.affordable_component_check ? res.data.affordable_component_check : 0;
      unitTypeData = res.data.unitTypeData ? res.data.unitTypeData : [];
      unitTypeDataTotal = unitTypeData ? unitTypeData.length : 0;
    }
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
      unitTypeDataTotal: unitTypeDataTotal,
      unitTypeData: unitTypeData,
    });
    // this.defaultData = resData;
  }

  saveOrUpdateAffordableComponent = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.props.property_slug
          ? this.props.property_slug
          : this.state.property_slug,
        affordable_financed: this.state.affordable_financed === true ? 1 : 2,
        total_affordable_unit: this.state.total_affordable_unit
          ? this.state.total_affordable_unit
          : "",
        affordable_percent_complete: this.state.affordable_percent_complete
          ? this.state.affordable_percent_complete
          : "",
        affordable_program_income_tiers: this.state
          .affordable_program_income_tiers
          ? this.state.affordable_program_income_tiers
          : "",
      };

      let res = await PropertyService.createPropertyAffordable(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ viewMode: 0 });
        // this.getData();
        this.getPropertyAffordable();
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
          //change tab logic
          if (
            this.state.errors.day ||
            this.state.errors.start_time ||
            this.state.errors.end_time
          ) {
            // jump to tab 6
            document
              .getElementById(
                "uncontrolled-tab-example-tab-Affordable Component"
              )
              .click();
          } else {
            // jump to tab 1
            document
              .getElementById("uncontrolled-tab-example-tab-Basic Information")
              .click();
          }
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.affordable_program_income_tiers !== undefined &&
      input.affordable_program_income_tiers !== "" &&
      input.affordable_program_income_tiers !== null &&
      input.affordable_program_income_tiers.length > 1000
    ) {
      isValid = false;
      errors["affordable_program_income_tiers"] = "The affordable program income tiers text too long.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  changeView() {
    var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
      this.getPropertyAffordable();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  };

  render() {
    return (
      <div className="dashboardRightcard">
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <div className="row d-flex justify-content-end">
          {/* <Link to={'/properties/update/'+this.props.property_slug+"/property-affordable"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
          {this.state.viewMode === 0 && this.state.affordable_component_check === 1 ? (
            <button
              className="btn-success-outline-small"
              onClick={this.changeView}
            >
              <img src={editIcon} />
              Edit
            </button>
          ) : (
            <div className="d-flex">
              <button
                type="button"
                className="btn-success-outline-small"
                onClick={() => {
                  if (this.state.confirm_back === 1)
                    if (
                      window.confirm(
                        "Do you want to exit without saving?"
                      )
                    )
                      this.changeView();
                    else return false;
                  else this.changeView();
                }}
              >
                Cancel
              </button>
              <button
                className="btn-success ml-3"
                onClick={this.saveOrUpdateAffordableComponent}
              >

                Save
              </button>
            </div>
          )}
        </div>

        <div className="col-md-12 pl-0 pr-0 mg-t-15">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>

        {this.state.viewMode === 0 && this.state.affordable_component_check === 1 ? (
          <>
            <div className="col-12-">
              <div className="row mg-t-40 mg-md-t-0">
                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">
                    Affordable Financed
                  </p>
                  <p className="media-body">
                    {this.state.affordable_financed === true ? "Yes " : "No"}
                  </p>
                </div>

                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">
                    Total Affordable Units
                  </p>
                  <p className="media-body">
                    {this.state.total_affordable_unit
                      ? this.state.total_affordable_unit
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12-">
              <div className="row">
                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">
                    Affordable % Complete
                  </p>
                  <p className="media-body">
                    {this.state.affordable_percent_complete
                      ? this.state.affordable_percent_complete + '%'
                      : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="az-profile-name-text pl-0">
                    Affordable Program/Income Tiers
                  </p>
                  <p className="media-body">
                    {this.state.affordable_program_income_tiers
                      ? this.state.affordable_program_income_tiers
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <hr className="mg-t-10 pd-t-2 mg-b-30 pd-b-0 pd-l-15 pd-r-15" />
            <div className="row">
              <div className="col-md-12 Affordable-table">
                <label>Affordable Type / Unit Nos.</label>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Affordable Type</th>
                      <th scope="col">No. of Units</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.unitTypeDataTotal > 0 ? (
                      this.state.unitTypeData.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.affordable_type_name}</td>
                            <td>{item.unit_count}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          There are no affordable added in the system. You may add them by clicking Add button.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {this.state.viewMode === 1 ? (
          <div className="m-0">
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                {" "}
                <label className="form-label text-left mg-b-0">
                  Affordable Financed
                </label>
              </div>
              <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                <Form.Check
                  name="affordable_financed"
                  type={"checkbox"}
                  id={`affordable_financed`}
                  label=""
                  className={"ckbox"}
                  onChange={this.changeHandler}
                  checked={this.state.affordable_financed}
                />
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Total Affordable Units
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="total_affordable_unit"
                  value={this.state.total_affordable_unit}
                  maxLength="50"
                // placeholder="Total Affordable Unit"
                />
                {this.state.errors.total_affordable_unit ? (
                  <div className="text-danger">
                    {this.state.errors.total_affordable_unit}
                  </div>
                ) : (
                  this.validator.message(
                    "total_affordable_unit",
                    this.state.total_affordable_unit,
                    "numeric|min:0,num",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Affordable % Complete
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  type="text"
                  onChange={this.changeHandler}
                  name="affordable_percent_complete"
                  value={this.state.affordable_percent_complete}
                  maxLength="50"
                // placeholder="Affordable % Complete"
                />
                {this.state.errors.affordable_percent_complete ? (
                  <div className="text-danger">
                    {this.state.errors.affordable_percent_complete}
                  </div>
                ) : (
                  this.validator.message(
                    "affordable_percent_complete",
                    this.state.affordable_percent_complete,
                    "numeric|min:0,num|max:100,num",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
            <div className="row row-xs align-items-center pd-t-30 pd-b-30">
              <div className="col-md-3 pl-0">
                <label className="form-label text-left mg-b-0">
                  Affordable Program/Income Tiers
                </label>
              </div>
              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                <Form.Control
                  // type="text"
                  as="textarea"
                  onChange={this.changeHandler}
                  name="affordable_program_income_tiers"
                  value={this.state.affordable_program_income_tiers}
                  maxLength="1000"
                // placeholder="Affordable Program/Income Tiers"
                />
                {this.state.errors.affordable_program_income_tiers ? (
                  <div className="text-danger">
                    {this.state.errors.affordable_program_income_tiers}
                  </div>
                ) : (
                  this.validator.message(
                    "affordable_program_income_tiers",
                    this.state.affordable_program_income_tiers,
                    "",
                    { className: "text-danger" }
                  )
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default PropertyAffordableView;

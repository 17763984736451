////////////////////////////////////////////////////////////
//  Program: QuickReplyAdd.jsx                            //
//  Application: messages                                 //
//  Option: Home Sharing Listing                          //
//  Developer:  Kiran                                     //
//  Date: 2022-11-02                                      //
////////////////////////////////////////////////////////////

import SettingSidebar from "../common/SettingSidebar";
import Alerts from "../common/Alerts";
import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import RUmessageService from "../../services/RUmessageService";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";

class QuickyReplyAdd extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      slug: props.match.params.slug,
      confirm_back: 0,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      deleteTowerModal: false,
      selectedRoleList: [],
      roleList: global.RoleListForQuickReply
        ? global.RoleListForQuickReply
        : [],
      role_value: "",
      template_desc_info: {
        guest:
          "Message (Available Merge Fields: [Guest Name] [Confirmation Code] [Check-In] [Check-Out] [Address] [Unit No] [Listing] [Parking Space] [Wifi-Name] [Wifi-Password] [Key-Cafe-Code] [Key-Instruction] [Arrival-Instruction])",
        staff: "Message(Available Merge Fields: [Staff Name] [Unit No])",
        resident: "Message (Available Merge Fields: [Resident Name] [Unit No])",
      },
    };
    this.saveOrUpdateQuickReply = this.saveOrUpdateQuickReply.bind(this);
  }

  componentDidMount() {
    this.getDataFromSlug();
  }

  saveOrUpdateQuickReply = async (e) => {
    e.preventDefault();
    this.setState({
      alertModalMessage: "",
    });
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true });
      let inputData = {
        role: this.state.role_value,
        title: this.state.title,
        message: this.state.message,
      };
      if (this.state.slug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await RUmessageService.updateQuickReplyTemplate(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/quick-replies"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        this.setState({ loading: true, isSubmit: true });
        let res = await RUmessageService.createQuickReplyTemplate(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/quick-replies"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isSubmit: false });
      this.validator.showMessages();
    }
  };
  async getDataFromSlug() {
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await RUmessageService.getTemplateBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.title = resData.title ? resData.title : "";
        resData.message = resData.message ? resData.message : "";
        resData.role_value = resData.role_group
          ? resData.role_group.role_group
          : "";
        var role_type = resData.role_group
          ? parseInt(resData.role_group.role_group)
          : "";
        var selectedRoleList = [];
        global.RoleListForQuickReply.forEach((item, i) => {
          if (parseInt(item.value) === role_type) {
            selectedRoleList = [
              {
                value: item.value,
                label: item.label,
              },
            ];
            return false;
          }
        });
      }
      this.setState({
        selectedRoleList: selectedRoleList,
      });
      this.setState(resData);
    }
    this.setState({
      roleList: selectedRoleList,
    });
  }
  handleChangeRole(value) {
    let role_value = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        role_value.push(item.value);
      });
    }
    this.setState({
      selectedRoleList: value,
      role_value: value.value,
      confirm_back: 1,
    });
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  customValidate() {
    let isValid = true;
    return isValid;
  }
  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container over-fl ">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <SettingSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs ">
                      <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Quick Replies</h3>

                          <div className="d-flex justify-content-end">
                            <Link to={"/quick-replies"}>
                              <button className="btn-success-outline mg-r-20">
                                Cancel
                              </button>
                            </Link>
                          {global.userPermissions.checkPermission("quick-replies-update") &&
                            <button
                              type="button"
                              className="btn-success-outline-small"
                              onClick={this.saveOrUpdateQuickReply}
                              disabled={this.state.isSubmit ? true : false}>
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug
                                ? "Update"
                                : "Save"}
                            </button>
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pd-lg-l-0 pd-lg-t-20 ">
                    <div className="table-responsive roles   ">
                      <div className="col-md-12 pl-0 pr-0  ">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      <div className="mg-t-18 brandList table_issue_roles">
                        <div className="pd-lg-r-0 pd-lg-l-0 pd-xs-l-15 pd-xs-r-0">
                          <div className="row align-items-center border-bottom-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30  pd-xs-l-15 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Quicky reply for{" "}
                              </label>

                              <Select
                                className="multiselect"
                                classNamePrefix={"my-custom-react-select"}
                                isClearable={true}
                                menuPlacement="auto"
                                value={this.state.selectedRoleList ?? []}
                                options={global.RoleListForQuickReply}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeRole(value)
                                }
                                defaultValue={this.state.selectedRoleList}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                              {this.state.errors.role_value ? (
                                <div className="text-danger">
                                  {this.state.errors.role_value}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Role",
                                  this.state.role_value,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row align-items-center border-bottom-0">
                            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Name{" "}
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="title"
                                value={this.state.title}
                                maxLength="50"
                                //tabIndex="1"
                                autoComplete="off"
                              />
                              {this.state.errors.title ? (
                                <div className="text-danger">
                                  {this.state.errors.title}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Name",
                                  this.state.title,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row align-items-center border-bottom-0">
                            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                {this.state.role_value === 5
                                  ? this.state.template_desc_info.guest
                                  : ""}
                                {this.state.role_value === 4
                                  ? this.state.template_desc_info.resident
                                  : ""}
                                {this.state.role_value === 3
                                  ? this.state.template_desc_info.staff
                                  : ""}
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="message"
                                value={this.state.message}
                                className="form-control max_width_100"
                                type="text"
                                rows="4"
                                as="textarea"
                                autoComplete="off"
                              />
                              {this.state.errors.message ? (
                                <div className="text-danger">
                                  {this.state.errors.message}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Message",
                                  this.state.message,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default QuickyReplyAdd;

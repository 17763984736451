////////////////////////////////////////////////////////////
//     							                                      //
//  Program: EcommerceTypeService.js                      //
//  Application: Ecommerce Type                           //
//  Option: Used for manage ecommerce type                //
//  Developer: Prashant Gupta  						                //
//  Date: 2023-01-27                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class EcommerceTypeService {
  getEcommerceType() {
    let endPoint = "get-ecommerce-type";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

}

export default new EcommerceTypeService();

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: AmenityTypeService.js                        //
//  Application: Amenity Type                             //
//  Option: Used for manage amenity type                  //
//  Developer: Ashish Kumar  						                  //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AmenityTypeService {
  getAmenityType(queryString) {
    let endPoint = "amenity-type" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createAmenityType(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "amenity-type";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getAmenityTypeBySlug(slug) {
    let endPoint = "amenity-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateAmenityType(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "amenity-type/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteAmenityType(slug) {
    let endPoint = "amenity-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new AmenityTypeService();

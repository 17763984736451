////////////////////////////////////////////////////////////
//     							                          //
//  Program: StaffContactTypeService.js                   //
//  Application: Services                                 //
//  Option: Used for StaffContactType list                //
//  Developer: Ashish Kumar 						      //
//  Date: 2022-01-13                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class StaffContactTypeService {

    getStaffContactType() {
        let endPoint = 'staff-contact-type';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    createStaffContactType(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'staff-contact-type';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    getStaffContactTypeBySlug(slug) {
        let endPoint = 'staff-contact-type/' + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateStaffContactType(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'staff-contact-type/' + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.putCall(inputData);
        return response;
    }

    deleteStaffContactType(slug) {
        let endPoint = 'staff-contact-type/' + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.deleteCall(inputData);
        return response;
    }
    
}

export default new StaffContactTypeService()
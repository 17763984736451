import ApiService from "./ApiService";

class StripePayoutService {
  getpayment(queryString) {
    let endPoint = "payout-total-value";
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getstripebalance() {
    let endPoint = "stripe-balance";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  gettotalpayout(slug, queryString) {
    let endPoint =
      "stripe-transfer?property_slug=" +
      slug +
      (queryString !== "" ? "&" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  gettotalpayment(slug, queryString) {
    let endPoint =
      "resident-payout-summary?property_slug=" +
      slug +
      (queryString !== "" ? "&" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  stripepayoutsummary(slug) {
    let endPoint = "stripe-payout-summary" + slug;
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  payouttotalbill(data) {
    // Delete data which we don't want to send
    delete data.errors;
    //delete data.input;
    let endPoint = "payout-total-value";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  SendTopUpAmount(data) {
    // Delete data which we don't want to send
    //delete data.input;
    let endPoint = "stripe-topUp-custom";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  createPropertyStripeAccount(data, queryString) {
    // Delete data which we don't want to send
    delete data.errors;
    //delete data.input;
    let endPoint =
      "create-property-estripe" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getPropertyStripeStatus(data) {
    // Delete data which we don't want to send
    delete data.errors;
    //delete data.input;
    let endPoint = "get-property-stripe-status/" + data;
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getPropertyStripeW9Info(data) {
    delete data.errors;
    let endPoint = "get-property-stripe-w9-info/" + data;
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new StripePayoutService();

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: TaxService.js                                //
//  Application: Property                                 //
//  Option: Used for manage Taxs                          //
//  Developer: NP           						                  //
//  Date: 2022-02-12                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class TaxService {

  getTax(queryString) {
    let endPoint = "property-level-tax" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createTax(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "property-level-tax";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getTaxBySlug(slug) {
    let endPoint = "property-level-tax/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateTax(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "property-level-tax/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteTax(slug) {
    let endPoint = "property-level-tax/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new TaxService();

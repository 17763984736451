//     							                          //
//  Program: UsersSidebar.jsx                           //
//  Application: Property                                 //
//  Option: common component UsersSidebar        //
//  Developer: NP 		                                  //
//  Date: 2022-03-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";
class UsersSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
    };
  }

  isPathActive = (path) => {
    if (window.location.pathname.includes(path)) {
      return true;
    } else if ("/" + path === window.location.pathname) {
      return true;
    } else {
      return window.location.pathname.includes(path);
    }
  };

  toggleHeaderMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.remove("d-none");
        document.querySelector(".newSubmenu").classList.remove("d-none");
      } else {
        data.classList.replace("left", "hide");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.add("d-none");
        document.querySelector(".newSubmenu").classList.add("d-none");
      }
    }
  }

  toggleChildMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector(".newSubmenu").classList.toggle("d-none");
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
      } else {
        data.classList.replace("left", "hide");
      }
    }
  }

  render() {
    return (
      <>
        <div className="desksidebarsettingNavbar">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i> Back to Users
          </div>
          <nav className="nav nav-pills flex-column pd-r-8">
            <ul>
              <li>
                <ul>
                  {global.userPermissions.checkPermission("users-list") && (
                    <li>
                      <Link
                        className={
                          this.isPathActive("users")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/users"}
                      >
                        Users
                      </Link>
                    </li>
                  )}
                  {global.userPermissions.checkPermission("role-list") && (
                    <li>
                      <Link
                        className={
                          this.isPathActive("roles") ||
                          this.isPathActive("role-permission") ||
                          this.isPathActive("role")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/roles"}
                      >
                        Roles
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Users
              </span>
              <ul>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission("role-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("users") ||
                            this.isPathActive("role-permission") ||
                            this.isPathActive("role")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/users"}
                        >
                          Users
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("role-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("roles") ||
                            this.isPathActive("role-permission") ||
                            this.isPathActive("role")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/roles"}
                        >
                          Roles
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default UsersSidebar;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: MultiImageService.js                          //
//  Application: MultiImage                                //
//  Option: Used for get & upload multi Images                   //
//  Developer: Ashish Kumar  						                  //
//  Date: 2022-02-19                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class MultiImageService {
  getRef() {
    let endPoint = "get-ref-table-list";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getActiveRefTables() {
    let endPoint = "get-ref-table-active-list";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getImages(data, queryString) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "get-multiple-files" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getDocuments(data, queryString) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "get-multiple-documents" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getPropertyDocumentsBySlug(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "get-image-docs";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  uploadMultiImage(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "upload";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  submitMultiImage(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "submit";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  uploadDocuments(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-docs";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateSingleImageDesc(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-single-image-description";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateMultiImage(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-image-description";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  //for download documents
  downloadFile(data,queryString) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    // let endPoint = "download-files-from-s3"+ (queryString ? '?' + queryString : '');
    // let addditionalHeaderData = {};
    // let postData = data;

    // let inputData = {
    //   endPoint: endPoint,
    //   addditionalHeaderData: addditionalHeaderData,
    //   postData: postData,
    // };
    // let response = ApiService.postCall(inputData);
    // return response;

    let endPoint = "download-files-from-s3"+ (queryString ? '?' + queryString : '');
    window.location.href = (process.env.REACT_APP_API_ENDPOINT+endPoint);
  }

  reorderImages(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "reorder-images";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getSearchFilterData(queryString) {

    let endPoint = "get-search-data" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new MultiImageService();

//////////////////////////////////////////////////////////////
//     							                                        //
//  Program: AccessibilityFeaturesService.js        //
//  Application: AccessibilityFeaturesService                               //
//  Option: Used for manage Accessibility Features Location //
//  Developer: Ashish Kumar  						                    //
//  Date: 2022-05-11                                        //
//                                                          //
//////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AccessibilityFeaturesService {
  getAccessibilityFeature(queryString) {
    let endPoint = "accessibility-feature"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createAccessibilityFeature(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "accessibility-feature";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getAccessibilityFeatureBySlug(slug) {
    let endPoint = "accessibility-feature/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateAccessibilityFeature(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "accessibility-feature/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteAccessibilityFeature(slug) {
    let endPoint = "accessibility-feature/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  //for properties
  addOrUpdateAccessibility(slug, data){
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "udpate-accessibility/"+slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
 }

 getPropertyAccessibility(slug) {
  let endPoint = "get-property-accessibility/"+ slug;
  let addditionalHeaderData = {};
  let postData = {};

  let inputData = {
    endPoint: endPoint,
    addditionalHeaderData: addditionalHeaderData,
    postData: postData,
  };
  let response = ApiService.getCall(inputData);
  return response;
}
}

export default new AccessibilityFeaturesService();

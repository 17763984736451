////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PropertyDocuments.jsx                        //
//  Application: PropertyDocuments                        //
//  Option: List all PropertyDocuments                    //
//  Developer: Ashish Kumar 						                  //
//  Date: 2022-02-07                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import { Container, Row, Col, Modal } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import Alerts from "../common/Alerts";
import MultiImageService from "../../services/MultiImageService";
import fileDownload from "js-file-download";
import axios from "axios";
import NoData from "../common/NoData";
import PropertySidebar from "../common/PropertySidebar";
import Select from "react-select";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import pdfIcon from "../../assets/images/pdf.png";
import wordIcon from "../../assets/images/word.png";
import excelIcon from "../../assets/images/excel.png";
import fontIcon from "../../assets/images/font-file.png";
import textIcon from "../../assets/images/text-file.png";
import imgIcon from "../../assets/images/imgIcon.png";
import { Popover, OverlayTrigger } from "react-bootstrap";
import copyIcon from "./../../assets/images/copy.png";
import Moment from "moment";
import propDoc from "../../assets/images/doc_placeholder.png";
import FileWidget from "../fileUploader/FileWidget";
import DocumentTypeService from "../../services/DocumentTypeService";
import formatNotSupported from "../../assets/images/not-supported.png";

export class PropertyDocuments extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.property_slug
        ? this.props.property_slug
        : this.props.match.params.propertySlug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "document_category_name",
          text: "File Type",
          className: "document_category_name hand-cursor td_clickable",
          sortable: true,
          cell: (records) => {
            return records.document_category_name
              ? records.document_category_name
              : "";
          },
        },
        {
          key: "file_name",
          text: "File",
          className: "file_name text-left",
          sortable: false,
          cell: (records) => {
            var docExt = records.file_name.split(".").pop();
            return (
              <React.Fragment>
                <div className="viewfile-section">
                  <span className="viewfile d-flex align-items-center">
                    {docExt === "pdf" ? (
                      <>
                        <img
                          src={pdfIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    ) : docExt === "doc" || docExt === "docx" ? (
                      <>
                        <img
                          src={wordIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    ) : docExt === "xlsx" ||
                      docExt === "csv" ||
                      docExt === "xls" ? (
                      <>
                        <img
                          src={excelIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    ) : docExt === "woff" ||
                      docExt === "woff2" ||
                      docExt === "ttf" ||
                      docExt === "otf" ? (
                      <>
                        <img
                          src={fontIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    ) : docExt === "txt" ? (
                      <>
                        <img
                          src={textIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    ) : (
                      <>
                        <img
                          src={imgIcon}
                          alt=""
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        />
                        <p
                          className="mg-r-5 hand-cursor"
                          onClick={() => {
                            this.getDownloadFile(
                              records.file_name,
                              records.display_file_name
                            );
                          }}
                        >
                          {records.display_file_name}
                        </p>
                      </>
                    )}
                  </span>
                </div>
              </React.Fragment>
            );
          },
        },
        {
          key: "created_at",
          text: "Upload Date",
          className: "created_at text-left date_tbl_formate",
          sortable: false,
          cell: (records) => {
            return (
              <span>
                {records.updated_at
                  ? Moment(records.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                      global.dateTimeFormat
                    )
                  : Moment(records.created_at, "YYYY-MM-DD HH:mm:ss").format(
                      global.dateTimeFormat
                    )}
              </span>
            );
          },
        },
        {
          key: "display_file_name",
          text: "Copy Path",
          className: "display_file_name text-left",
          sortable: false,
          cell: (records) => {
            return (
              <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={
                  <Popover
                    id="popover-basic"
                    className="popover-primary copide_box"
                  >
                    <Popover.Title as="h3" className="p-0">
                      Copied
                    </Popover.Title>
                  </Popover>
                }
              >
                <img
                  src={copyIcon}
                  alt="Copy"
                  onClick={() => this.copyPropertyDocsFile(records.file_name)}
                  className="hand-cursor copyClass dashboardRightcard "
                />
              </OverlayTrigger>
            );
          },
        },
        {
          key: "slug",
          text: "Action",
          className: "slug text-left",
          sortable: false,
          cell: (records) => {
            return (
              <img
                src={deleteicon}
                className="hand-cursor"
                alt=""
                onClick={() => this.openDeleteDocModal(records.slug)}
              />
            );
          },
        },
      ],
      documentTypeList: [],
      newDocumentTypeList: [],
      selectedDocumentTypeList: [],
      documentTypeListTotal: 0,
      document_type_slug: "",
      display_file_name: "",
      media_type: "",
      file_name: "",
      description: "",
      sort_order: "",
      imgUrl: propDoc,
      displayFileName: "",
      deletedFiles: [],
      files_data: [],
      doc_cat_slug: "",
      viewMode: 0,
      buildingOnboardingTab: this.props.buildingOnboardingTab
        ? this.props.buildingOnboardingTab
        : 0,
      unit_import_data: [],
      current_lease_data: [],
      rent_collection_data: [],
      previewFiles: [],
    };
    this.propDoc = React.createRef();
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.getDownloadFile = this.getDownloadFile.bind(this);
    this.saveOrUpdatePropertyDocuments =
      this.saveOrUpdatePropertyDocuments.bind(this);
    this.deletePropDoc = this.deletePropDoc.bind(this);
    this.opendeletePropDocModal = this.opendeletePropDocModal.bind(this);
    this.closedeletePropDocModal = this.closedeletePropDocModal.bind(this);
    this.changeView = this.changeView.bind(this);
    this.handleChangeDocumentTypeList =
      this.handleChangeDocumentTypeList.bind(this);
    this.rowClickedHandler = this.rowClickedHandler.bind(this);
    this.getPropDocData = this.getPropDocData.bind(this);
  }

  opendeletePropDocModal(slug) {
    this.setState({ deletePropDocModal: true, slug: slug });
  }

  closedeletePropDocModal() {
    this.setState({ deletePropDocModal: false, slug: "" });
  }

  previewFilesInParent = (value) => {
    setTimeout(() => {
      this.setState({ previewFiles: value });
      this.setState({ errors: {} });
    }, 100);
  };

  async deletePropDoc(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    let inputData = {
      path: "property_document_form",
      refTableId: 3,
      slug: this.state.property_slug,
      mediaType: 2,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closedeletePropDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getData();
    } else {
      this.closedeletePropDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  saveOrUpdatePropertyDocuments = async (e) => {
    e.preventDefault();
    let errors = {};
    //var displayFileName = "";
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun(
        "property_document_form"
      );

      if (output.status !== false) {
        let inputData = {
          display_file_name: this.state.display_file_name
            ? this.state.display_file_name
            : "",
          document_type_slug: this.state.document_type_slug
            ? this.state.document_type_slug
            : "",
          file_name: this.state.file_name ? this.state.file_name : "",
          description: this.state.description ? this.state.description : "",
          media_type: 2,
          ref_id: this.state.property_slug ? this.state.property_slug : "",
          sort_order: this.state.sort_order ? this.state.sort_order : "",
          status_id: this.state.status_id === true ? 1 : 2,
          availableFiles: output.response,
          deletedFiles: this.state.deletedFileList,
          docsSlug: this.state.prop_doc_slug ? this.state.prop_doc_slug : "",
        };
        if (this.state.prop_doc_slug !== undefined) {
          // For active status 1:Active, 2:Inactive
          inputData.status_id = this.state.status_id ? this.state.status_id : 2;

          let res = await MultiImageService.submitMultiImage(inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            this.setState({ viewMode: 0 });
            if (this.props.buildingOnboardingTab === 1) {
              this.props.propertyDocView(0);
            }
            this.getData();
          } else {
            if (res.data.result.errorDetail) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
          }
        } else {
          if (output.response[0].filesData) {
            if (output.response[0].filesData.length > 0) {
              let res = await MultiImageService.submitMultiImage(inputData);
              if (global.successStatus.includes(res.status)) {
                this.setState({
                  showAlertModal: true,
                  alertModalType: "success",
                  alertModalTitle: "Success",
                  alertModalMessage: res.message ? res.message : "Success",
                });
                this.setState({ viewMode: 0, previewFiles: [] });
                if (this.props.buildingOnboardingTab === 1) {
                  this.props.propertyDocView(0);
                }
                this.getData();
              } else {
                if (res.data.result.errorDetail) {
                  let errors = {};
                  res.data.errorCode = "Validation Failed";
                  res.data.result.errorDetail.forEach((item) => {
                    errors[item.errorField] = item.errorMessage[0];
                    res.data.errorMessage = "";
                  });
                  this.setState({ errors: errors });
                }
              }
            } else {
              errors["document"] = "Please select recommended file size.";
              this.setState({ errors: errors });
            }
          }
        }
      } else {
        let checkError = output.response[0].validationMessage[0];
        if (checkError[0].includes("30 mb") === true) {
          errors["document"] = "The file should not be greater than 30 mb.";
          this.setState({ errors: errors });
        } else {
          errors["document"] =
            "The file should be pdf, doc, docx, xls, xlsx, csv, png, jpg, txt, woff, ttf.";
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    this.setState({ selected_file_name: "" });
  };

  handleDownload = (url, file_name) => {
    this.setState({ showSpinner: true, isSubmit: true });
    let filePath = url;
    axios
      .get(`${filePath}`, {
        responseType: "blob",
        mode: "cors",
        cache: "no-cache",
      })
      .then((res) => {
        let fileExtension;
        fileExtension = filePath.split(".");
        fileExtension = fileExtension[fileExtension.length - 1];
        fileDownload(res.data, `${file_name}.${fileExtension}`);
      });
    this.setState({ showSpinner: false, isSubmit: false });
  };

  changeView() {
    if (this.state.viewMode === 1) {
      if (this.props.buildingOnboardingTab === 1) {
        this.props.propertyDocView(0);
      }
      this.getData();
      this.setState({
        viewMode: 0,
        display_file_name: "",
        document_type_slug: "",
        file_name: "",
        description: "",
        slug: "",
        doc_cat_slug: "",
        files_data: [],
        doc_slug: "",
        confirm_back: 0,
        selectedDocumentTypeList: [],
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
    } else {
      if (this.props.buildingOnboardingTab === 1) {
        this.props.propertyDocView(1);
      }
      this.setState({ viewMode: 1 });
      this.setState({
        display_file_name: "",
        document_type_slug: "",
        file_name: "",
        description: "",
        errors: "",
        slug: "",
        selectedDocumentTypeList: [],
      });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.validator.hideMessages();
    }
  }

  removeNewSelectedFile = async (key) => {
    let newFileList = this.state.previewFiles;
    delete newFileList[key];
    this.setState({
      previewFiles: newFileList,
    });
  };

  handleChangeDocumentTypeList(value) {
    let document_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        document_type_slug.push(item.value);
      });
    }
    const document_type_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedDocumentTypeList: value,
      document_type_slug: document_type_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  async getDocumentTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    const res = await DocumentTypeService.getDocumentType(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      const documentTypeList = res.data || [];
      var filteredDocumentTypeList = documentTypeList;
      if (this.props.buildingOnboardingTab === 0) {
        // Define values to remove
        const valuesToRemove = [];
        if (
          this.state.unit_import_data &&
          this.state.unit_import_data.length > 0
        ) {
          valuesToRemove.push("I9L2XNG8BQ37");
        }
        if (
          this.state.current_lease_data &&
          this.state.current_lease_data.length > 0
        ) {
          valuesToRemove.push("W5FYC0RBJXL1");
        }
        if (
          this.state.rent_collection_data &&
          this.state.rent_collection_data.length > 0
        ) {
          valuesToRemove.push("VKJL2ZSQ7F0U");
        }

        // Filter out items with values to remove
        filteredDocumentTypeList = documentTypeList.filter(
          (item) => !valuesToRemove.includes(item.slug)
        );
      }
      if (this.props.buildingOnboardingTab === 1) {
        // Filter out items with values to remove
        filteredDocumentTypeList = documentTypeList.filter((item) => {
          if (item.is_onboarding === 1) {
            return true;
          } else {
            return false;
          }
        });
      }
      // Map filtered document types
      const newDocumentTypeList = filteredDocumentTypeList.map(
        ({ slug, document_category_name }) => ({
          value: slug,
          label: document_category_name,
        })
      );

      // Set state with the new data
      this.setState({
        documentTypeList: filteredDocumentTypeList,
        documentTypeListTotal: filteredDocumentTypeList.length,
        newDocumentTypeList,
        showSpinner: false,
        loading: false,
      });
    } else {
      // Handle unsuccessful response here if needed
    }
  }

  componentDidMount() {
    this.getData();
    this.getDocumentTypeData();
  }

  async getData(queryString = "") {
    let inputData = {
      path: "property_document_form",
      refTableId: 3,
      slug: this.state.property_slug,
      mediaType: 2,
      docList: 1,
      propertySlug: this.state.property_slug,
    };

    var list = [];
    var totalRecords = 0;
    let unit_import_data = [];
    let current_lease_data = [];
    let rent_collection_data = [];
    let res = await MultiImageService.getDocuments(inputData, queryString);

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      if (this.props.buildingOnboardingTab === 1) {
        // Filter out items with values to remove
        list =
          list &&
          list.length > 0 &&
          list.filter((item) => {
            if (item.is_onboarding === 1) {
              return true;
            } else {
              return false;
            }
          });
      }
      if (list && list.length > 0) {
        for (const opt of list) {
          if (opt.document_slug === "I9L2XNG8BQ37") {
            unit_import_data.push({
              slug: opt.slug,
            });
          } else if (opt.document_slug === "W5FYC0RBJXL1") {
            current_lease_data.push({
              slug: opt.slug,
            });
          } else if (opt.document_slug === "VKJL2ZSQ7F0U") {
            rent_collection_data.push({
              slug: opt.slug,
            });
          }
        }
      }

      totalRecords = list && list.length > 0 ? list.length : 0;
    }
    this.setState({
      unit_import_data,
      current_lease_data,
      rent_collection_data,
      total_record: totalRecords,
      records: list,
    });
    this.setState({ showSpinner: false, loading: false });
    this.getDocumentTypeData();
  }

  /*file download */
  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  openDeleteDocModal(slug) {
    this.setState({ deleteDocModal: true, delete_slug: slug });
  }

  closeDeleteDocModal() {
    this.setState({ deleteDocModal: false });
  }

  async deleteDoc(slug) {
    this.setState({ showSpinner: true });
    let inputData = {
      path: "property_document_form",
      refTableId: 3,
      slug: this.state.property_slug,
      mediaType: 2,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getData();
    } else {
      this.closeDeleteDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
  }

  async copyPropertyDocsFile(fileUrl) {
    let message = "File copied to clipboard";
    navigator.clipboard.writeText(fileUrl).then(
      setTimeout(
        window.location.reload(),
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: message ? message : "Success",
        }),
        global.redirect_time
      )
    );
  }

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.description !== undefined &&
      input.description !== "" &&
      input.description !== null &&
      input.description.length > 100000
    ) {
      isValid = false;
      errors["description"] =
        "The document description character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  getTitle() {
    return "Files";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  async getPropDocData(slug) {
    this.setState({ prop_doc_slug: slug, viewMode: 1 });
    if (this.props.buildingOnboardingTab === 1) {
      this.props.propertyDocView(1);
    }
    if (this.state.viewMode !== 1) {
      this.changeView();
    }
    setTimeout(async () => {
      if (this.state.prop_doc_slug !== undefined) {
        var resData = {};
        var files_data = [];
        let inputData = {
          path: "property_document_form",
          refTableId: 3,
          slug: this.state.property_slug,
          mediaType: 2,
          docsSlug: this.state.prop_doc_slug,
          propertySlug: this.state.property_slug,
        };
        let res = await MultiImageService.getPropertyDocumentsBySlug(inputData);
        if (global.successStatus.includes(res.status)) {
          resData = res.data ? res.data : {};
          resData.display_file_name = res.data.display_file_name
            ? res.data.display_file_name
            : "";
          resData.document_type_slug = res.data.document_type_data
            ? res.data.document_type_data.slug
            : "";
          resData.file_name = res.data.file_name ? res.data.file_name : "";
          var selectedDocumentTypeList = [];
          selectedDocumentTypeList = res.data.document_type_data
            ? [
                {
                  value: res.data.document_type_data.slug,
                  label: res.data.document_type_data.document_category_name,
                },
              ]
            : "";
          files_data = res.data.files_data ? res.data.files_data : [];
        }
        var fileExt = resData.file_name.split(".").pop();
        this.setState(resData);
        this.setState({
          selectedDocumentTypeList: selectedDocumentTypeList,
          fileExt: fileExt,
          files_data: files_data,
        });
      }
      if (this.state.files_data.length === 0) {
        this.changeView();
      }
    }, 100);
    this.setState({ showSpinner: false, loading: false });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.getPropDocData(data.document_slug);
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.state.buildingOnboardingTab === 0 ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div
                  className={
                    this.state.buildingOnboardingTab === 1
                      ? "col-md-12 mg-t-0 pd-t-0 pd-l-15 pd-r-0"
                      : "col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0"
                  }
                >
                  <div
                    className={
                      this.state.buildingOnboardingTab === 0
                        ? "dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0"
                        : " align-items-center flex-wrap mg-b-0"
                    }
                  >
                    {this.state.buildingOnboardingTab === 0 ? (
                      <h3>{this.getTitle()}</h3>
                    ) : (
                      ""
                    )}
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.buildingOnboardingTab === 0 ? (
                        <>
                          {this.state.viewMode === 0 &&
                          global.userPermissions.checkPermission(
                            "property-documents-add"
                          ) ? (
                            <button
                              className="btn-success-outline-small mr-3"
                              onClick={this.changeView}
                            >
                              <img src={pluswIcon} alt="Add" />
                              Add
                            </button>
                          ) : (
                            <div className="d-flex">
                              <button
                                type="button"
                                className="btn-success-outline-small mr-3"
                                onClick={() => {
                                  if (this.state.confirm_back === 1) {
                                    if (
                                      window.confirm(
                                        "Do you want to exit without saving?"
                                      )
                                    ) {
                                      this.changeView();
                                    } else {
                                      return false;
                                    }
                                  } else {
                                    this.changeView();
                                  }
                                }}
                              >
                                Cancel
                              </button>
                              {global.userPermissions.checkPermission(
                                "property-documents-update"
                              ) && (
                                <button
                                  type="button"
                                  className="btn-success mr-3"
                                  onClick={this.saveOrUpdatePropertyDocuments}
                                  disabled={this.state.isSubmit ? true : false}
                                >
                                  {this.state.doc_slug === undefined ||
                                  this.state.doc_slug === ""
                                    ? this.state.isSubmit
                                      ? global.loader
                                      : "Save"
                                    : this.state.isSubmit
                                    ? global.loader
                                    : "Save"}
                                </button>
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.viewMode === 0 ? (
                            <button
                              className="btn-snap-view btn-cust-small"
                              onClick={this.changeView}
                            >
                              {/* <img src={pluswIcon} alt="Add" /> */}
                              Add
                            </button>
                          ) : (
                            <div className="d-flex">
                              <button
                                type="button"
                                className="btn-success-outline-small mr-3"
                                onClick={() => {
                                  if (this.state.confirm_back === 1) {
                                    if (
                                      window.confirm(
                                        "Do you want to exit without saving?"
                                      )
                                    ) {
                                      this.changeView();
                                    } else {
                                      return false;
                                    }
                                  } else {
                                    this.changeView();
                                  }
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className={
                                  this.state.buildingOnboardingTab === 0
                                    ? "btn-success mr-3 "
                                    : "btn-snap-view onboard_file_save_btn btn-cust-small"
                                }
                                onClick={this.saveOrUpdatePropertyDocuments}
                                disabled={this.state.isSubmit ? true : false}
                              >
                                {this.state.doc_slug === undefined ||
                                this.state.doc_slug === ""
                                  ? this.state.isSubmit
                                    ? global.loader
                                    : "Save"
                                  : this.state.isSubmit
                                  ? global.loader
                                  : "Save"}
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-0 pr-0">
                    <div className="col-md-12 pl-0 pr-0">
                      {this.props.buildingOnboardingTab === 0 && (
                        <Alerts
                          show={
                            this.props.show
                              ? this.props.show
                              : this.state.showAlertModal
                          }
                          type={
                            this.props.type
                              ? this.props.type
                              : this.state.alertModalType
                          }
                          title={
                            this.props.title
                              ? this.props.title
                              : this.state.alertModalTitle
                          }
                          message={
                            this.props.message
                              ? this.props.message
                              : this.state.alertModalMessage
                          }
                        />
                      )}
                    </div>
                    {this.state.viewMode === 0 ? (
                      <>
                        {this.state.showFilter ? (
                          <Row className="align-items-center order_form">
                            <Col
                              md={3}
                              lg={5}
                              xl={5}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <input
                                name="filter_value"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.filter_value}
                                onChange={this.changeHandler}
                                maxLength="30"
                              />
                            </Col>
                            <Col md={5} lg={5} xl={5}>
                              <Row>
                                <button
                                  onClick={this.customFilter}
                                  className="btn-success mg-l-30 mg-r-20"
                                >
                                  Search
                                </button>
                                <button
                                  onClick={this.resetDate}
                                  className="btn-success-outline"
                                >
                                  Reset
                                </button>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        {this.state.buildingOnboardingTab === 1 ? (
                          <div className="col-md-12 pd-lg-l-5 pd-lg-r-0">
                            <div className="onborad_review">
                              <h3 className="mg-l-0 background-head">Files</h3>
                              <p>
                                Orion Haus needs creative files to accurately
                                represent your brand on the web and in the app
                                that residents, guests, and staff will use.
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.total_record > 0 ||
                        this.state.filter_value !== "" ? (
                          <div className="mg-t-18 brandList onbord_tbl_file pd-l-15 d-block">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                            />
                          </div>
                        ) : (
                          <NoData
                            msg={global.commonMessages.checkMessage(
                              "PROPERTY_DOCUMENTS"
                            )}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {this.state.viewMode === 1 ? (
                      <>
                        <form id="property_document_form">
                          <div className="m-0">
                            <div className="row align-items-center pd-t-0 pd-b-0 border-bottom-0 documnet-section">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-lg-l-5 pd-lg-r-20 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  File Type{" "}
                                </label>

                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  className="multiselect"
                                  menuPlacement="auto"
                                  value={this.state.selectedDocumentTypeList}
                                  options={this.state.newDocumentTypeList}
                                  getOptionValue={(option) => `${option.label}`}
                                  onChange={(value) =>
                                    this.handleChangeDocumentTypeList(value)
                                  }
                                  defaultValue={
                                    this.state.selectedDocumentTypeList
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />

                                {this.state.errors.document_type_slug ? (
                                  <div className="text-danger">
                                    {this.state.errors.document_type_slug}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "file_type",
                                    this.state.document_type_slug,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}
                              </div>
                            </div>

                            <div className="row align-items-baseline- pd-t-0 pd-b-0 border-bottom-0 h-100 documnet-section-upload">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-lg-l-5 pd-lg-r-20 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Upload File{" "}
                                </label>

                                <div className="d-flex flex-row align-items-center upload upload-files-section">
                                  <FileWidget
                                    fieldName="file_name"
                                    fieldTitle="Select File"
                                    checkValidation="true"
                                    allowedType="image/png,image/jpg,text/csv,application/*,doc,docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel,application/font-woff,application/font-woff2,font/ttf,font/opentype,application/octet-stream,text/plain"
                                    maxFileSize={30}
                                    maxFileSizeType="mb"
                                    maxFileAllowed={10}
                                    uploadOn="s3"
                                    slug={this.state.property_slug}
                                    refTableId="3"
                                    displayFileName={
                                      this.state.display_file_name
                                    }
                                    documentTypeSlug={
                                      this.state.document_type_slug
                                    }
                                    mediaType="2"
                                    sortOrder={
                                      this.state.sort_order !== ""
                                        ? this.state.sort_order
                                        : 0
                                    }
                                    statusId={
                                      this.state.status_id === true ? 1 : 2
                                    }
                                    helpText="to download sample file."
                                    sampleFileUrl="/sample.jpg"
                                    drag="true"
                                    dragText="Select or drop file size upto 30 MB"
                                    inputClassName="form-control"
                                    labelClassName="form-label"
                                    formID="property_document_form"
                                    uploadType={
                                      this.props.buildingOnboardingTab === 1
                                        ? "single"
                                        : "multiple"
                                    }
                                    resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                                    propertySlug={this.state.property_slug}
                                    destinationFolder="property_document_form"
                                    uploadedFiles={this.state.profile_image2}
                                    deletedFiles={this.state.deletedFileList}
                                    acceptOnly="image/png,image/jpg,text/csv,application/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel,application/font-woff,application/font-woff2,font/ttf,font/opentype,application/octet-stream,text/plain"
                                    displayPreviewStyle="horizontal"
                                    validationFire={0}
                                    previewFilesInParent={
                                      this.previewFilesInParent
                                    }
                                    previewFiles={this.state.previewFiles}
                                  />
                                </div>

                                {this.state.errors.document ? (
                                  <div className="text-danger">
                                    {this.state.errors.document}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              {this.state.previewFiles !== undefined &&
                                this.state.previewFiles &&
                                this.state.previewFiles.length > 0 && (
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-15 pd-xs-r-15 mg-b-15 uploadSection">
                                    <label className="form-label text-left pd-b-5">
                                      Preview File{" "}
                                    </label>

                                    {this.state.previewFiles.map((val, key) => {
                                      return (
                                        <React.Fragment key={key}>
                                          <div className="viewfile-section">
                                            <span className="viewfile d-flex align-items-center">
                                              <img
                                                className="mg-r-5 hand-cursor"
                                                src={
                                                  val.name.match(
                                                    /.(jpg|jpeg|png|gif)$/i
                                                  )
                                                    ? val.src
                                                    : val.name.match(/.(pdf)$/i)
                                                    ? pdfIcon
                                                    : val.name.match(
                                                        /.(docx|doc)$/i
                                                      )
                                                    ? wordIcon
                                                    : val.name.match(
                                                        /.(xlsx|csv|xls)$/i
                                                      )
                                                    ? excelIcon
                                                    : val.name.match(
                                                        /.(woff|woff2|ttf|otf)$/i
                                                      )
                                                    ? fontIcon
                                                    : val.name.match(/.(txt)$/i)
                                                    ? textIcon
                                                    : formatNotSupported
                                                }
                                                alt=""
                                              />
                                              <p className="mg-r-5 hand-cursor">
                                                {val.name}
                                              </p>
                                            </span>
                                            <img
                                              src={deleteicon}
                                              className="hand-cursor"
                                              onClick={() =>
                                                this.removeNewSelectedFile(key)
                                              }
                                            />
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                )}
                            </div>
                          </div>
                        </form>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deletePropDocModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeletePropDocModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeletePropDocModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deletePropDoc(this.state.prop_doc_slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteDocModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteDocModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteDocModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteDoc(this.state.delete_slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default PropertyDocuments;

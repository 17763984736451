//////////////////////////////////////////////////////////////
//     							                                        //
//  Program: BuildingOnboardingService.js                   //
//  Application: BuildingOnboardingService                  //
//  Option: Used for manage Building Onboarding Home Tab    //
//  Developer: Ashish Kumar  						                    //
//  Date: 2024-03-21                                        //
//                                                          //
//////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class BuildingOnboardingService {
  getHomeTabData(queryString) {
    let endPoint = "get-home-tab-data" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  saveOrUpdateHomeTabs(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "insert-or-update-home-tab-data/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getCmsContent(slug) {
    let endPoint = "get-cms-content-building-onboarding/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getOnboardingStepsCount(slug) {
    let endPoint = "get-onboarding-setps-count/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new BuildingOnboardingService();

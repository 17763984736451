////////////////////////////////////////////////////////////
//     							                          //
//  Program: CrmTemplateService.js                              //
//  Application: Crm Template                                    //
//  Option: Used for manage Crm Templates                        //
//  Developer: Raj Kumar 						                  //
//  Date: 2022-04-03                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class CrmTemplateService {
  
  getCrmTemplateList(queryString) {
    let endPoint = "crm-template" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getCrmTemplate(slug) {
    let endPoint = "get-crm-template/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createCrmTemplate(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "crm-template-store";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateCrmTemplate(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "crm-template-update/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateCrmTemplatecheckbox(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "post-crm-template-checkbox/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteCrmTemplate(slug) {
    let endPoint = "delete-crm-template/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

}

export default new CrmTemplateService();

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Guest.jsx                                    //
//  Application: Guests                                   //
//  Option: List all Guests                               //
//  Developer: NP				                                  //
//  Date: 2022-04-18                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import GuestService from "../../services/GuestService";
import { Container, Modal, InputGroup, Form } from "react-bootstrap";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Alerts from "../common/Alerts";
import NoDataSearch from "../common/NoDataSearch";
import CalendarSidebar from "../common/CalendarSidebar";
import repeatGuest from "./../../assets/images/guest-repeat-blue.svg";
import queryString from "query-string";
import PhoneInput from "react-phone-input-2";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyReservationSidebar from "../common/AllPropertyReservationSidebar";

let queryParams = queryString.parse(window.location.search);

export class Guest extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      tableQueryString: "",
      customQueryString: "",
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      filter_value: "",
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "first_name",
          text: "First Name",
          className: "first_name",
          sortable: true,
          cell: (record) => {
            return record.first_name ? record.first_name : "";
          },
        },
        {
          key: "last_name",
          text: "Last Name",
          className: "last_name",
          sortable: true,
          cell: (record) => {
            return record.last_name ? record.last_name : "";
          },
        },
        global.userPermissions.checkPermission("guests-personal-data-view") && {
          key: "email",
          text: "Email",
          className: "email",
          sortable: true,
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        global.userPermissions.checkPermission("guests-personal-data-view") && {
          key: "phone_number",
          text: "Phone",
          className: "phone_number text-left",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber = record.phone_number
              ? record.phone_number.replace(/[\s-]/g, "")
              : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits = record.phone_number ? record.phone_number : [];
            }

            var number = record.phone_number
              ? lastTenDigits.trim().replace(/[^0-9]/g, "")
              : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.phone_number ? finalnumber : "";
          },
        },
        global.userPermissions.checkPermission("guests-personal-data-view") && {
          key: "city_name",
          text: "City",
          className: "city_name text-left",
          sortable: true,
          cell: (record) => {
            return record.city
              ? record.city.city_name
              : record.city_name
              ? record.city_name
              : "";
          },
        },
        {
          key: "is_id_verified",
          text: "Repeat Guest",
          className: "is_id_verified text-left",
          sortable: true,
          cell: (record) => {
            return record.get_reservation &&
              record.get_reservation.is_repeat === 1 ? (
              <img src={repeatGuest} alt="" title="Repeat Guest" />
            ) : (
              ""
            );
          },
        },
      ],
      module_search: "",
      is_search: 0,
      isSearch: global.isSearch,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      if (queryParams.isGlobal === "1") {
        this.getData(
          "is_search=1&" +
            queryParams.global_col_name +
            "=" +
            queryParams.global_search
        );
      } else {
        this.getData();
      }
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      if (queryParams.isGlobal === "1") {
        this.getData(
          "is_search=1&" +
            queryParams.global_col_name +
            "=" +
            queryParams.global_search
        );
      } else {
        this.getData();
      }
    }, 200);
    this.setState({
      module_search: [],
      is_search: 0,
    });
  }

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    let res = await GuestService.getGuestList(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list.length ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      guest_name: "",
      email: "",
      city_name: "",
      state_name: "",
      country_name: "",
      zip_code: "",
    });
    this.setState({ module_search: "" });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let queryString =
      "guest_name=" +
      (this.state.guest_name ? this.state.guest_name : "") +
      "&email=" +
      (this.state.email ? this.state.email : "") +
      "&city_name=" +
      (this.state.city_name ? this.state.city_name : "") +
      "&state_name=" +
      (this.state.state_name ? this.state.state_name : "") +
      "&country_name=" +
      (this.state.country_name ? this.state.country_name : "") +
      "&zip_code=" +
      (this.state.zip_code ? this.state.zip_code : "") +
      "&phone_number=" +
      (this.state.phone_number ? this.state.phone_number : "") +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.guest_name
      ? "Guest Name - " + this.state.guest_name
      : "";
    moduleSearchString = this.state.phone_number
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Phone Number - " +
          this.state.phone_number
        : " Phone Number - " + this.state.phone_number
      : moduleSearchString;
    moduleSearchString = this.state.email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Email - " + this.state.email
        : " Email - " + this.state.email
      : moduleSearchString;
    moduleSearchString = this.state.city_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " City - " + this.state.city_name
        : " City - " + this.state.city_name
      : moduleSearchString;
    moduleSearchString = this.state.state_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " State - " + this.state.state_name
        : " State - " + this.state.state_name
      : moduleSearchString;
    moduleSearchString = this.state.country_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Country - " + this.state.country_name
        : " Country - " + this.state.country_name
      : moduleSearchString;
    moduleSearchString = this.state.zip_code
      ? moduleSearchString
        ? moduleSearchString + " ," + " Zipcode - " + this.state.zip_code
        : " Zipcode - " + this.state.zip_code
      : moduleSearchString;

    if (
      this.state.guest_name ||
      this.state.email ||
      this.state.phone_number ||
      this.state.city_name ||
      this.state.state_name ||
      this.state.country_name ||
      this.state.zip_code
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(queryString);
    }
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  onChangePhone(value, country, e, formattedValue) {
    //console.log(value, country, e, formattedValue);
    this.setState({ phone: value });
    this.setState({ dial_code: country.dialCode });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Guests";
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "guest/view/" + data.slug
        : this.state.property_slug
        ? "/guest/view/" + this.state.property_slug + "/" + data.slug
        : "/guest/view/" + data.property_slug + "/" + data.slug
    );
  };

  render() {
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <CalendarSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyReservationSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                      </div>
                    </div>
                  </div>

                  {this.props.match.params.propertySlug === undefined ? (
                    <>
                      <div className="row pd-lg-l-15 pd-xs-l-15">
                        <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                          <OutSidePropertySelectBox
                            propertySlug={this.getPropertySlug.bind(this)}
                            outSidePropertySlug={this.state.outSidePropertySlug}
                          />
                        </div>
                        <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                          {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-lg-l-30 pd-xs-l-15 pd-lg-r-15 mg-t-0 d-inline-flex align-items-center justify-content-between ">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.is_search === 1 ? (
                            <>
                              <div className="col-xxl-12 col-sm-12 pd-lg-r-30 pd-lg-l-15 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                                <div className="custm-filter">
                                  <div>
                                    {" "}
                                    <label className="form-label text-left pd-b-2 mb-0">
                                      Search Applied On
                                    </label>
                                  </div>
                                  <div className="filter_bg_cust">
                                    {this.state.module_search.map((item, i) => {
                                      return <span key={i}>{item}</span>;
                                    })}
                                    <button
                                      onClick={this.clearFilter}
                                      className="btn-fliter-clear"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-12">
                      {this.state.is_search === 1 ? (
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-lg-r-30 pd-lg-l-15 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter">
                              <div>
                                {" "}
                                <label className="form-label text-left pd-b-2 mb-0">
                                  Search Applied On
                                </label>
                              </div>
                              <div className="filter_bg_cust">
                                {this.state.module_search.map((item, i) => {
                                  return <span key={i}>{item}</span>;
                                })}
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <div className="scrolling-carousel pd-l-15 pd-r-15">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-l-30 pd-r-30">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      {this.state.total_record > 0 ||
                      this.state.filter_value !== "" ||
                      this.state.is_search === 1 ? (
                        <div className="row d-block mg-t-18 brandList ml-0 p-l-4 pd-r-0">
                          <div className="pd-lg-l-0 pd-xs-l-15 pd-lg-r-0 pd-xs-r-15">
                            <ReactDatatable
                              className={
                                global.userPermissions.checkPermission(
                                  "guests-update"
                                )
                                  ? "table no-wrap mg-b-30 pd-b-2 text-align-left hand-cursor"
                                  : "table no-wrap mg-b-30 pd-b-2 text-align-left"
                              }
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              // loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              onRowClicked={
                                global.userPermissions.checkPermission(
                                  "guests-update"
                                ) && this.rowClickedHandler
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <NoDataSearch
                          msg={global.commonMessages.checkMessage(
                            "SEARCHGUEST"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Guests</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Guest Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="guest_name"
                      value={this.state.guest_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                {/* <InputGroup className="mt-3">
                <label>
                  Last Name
                </label>
                <div className="col-md-12 p-0">
                <Form.Control
                  onChange={this.changeHandler}
                  name="last_name"
                  value={this.state.last_name}
                  className="form-control max_width_100"
                  id="inlineFormInput"
                  type="text"
                  maxLength="50"
                  autoComplete="off"
                  // placeholder="Amenity Name"
                />
                </div>
              </InputGroup> */}

                <InputGroup className="mt-3">
                  <label>City</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="city_name"
                      value={this.state.city_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Country</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="country_name"
                      value={this.state.country_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Phone</label>
                  <div className="col-md-12 p-0">
                    <PhoneInput
                      country={global.country}
                      disableSearchIcon
                      // autoFormat= "true"
                      enableSearch="true"
                      countryCodeEditable="true"
                      value={this.state.phone_number}
                      onChange={(phone_number) =>
                        this.setState({ phone_number })
                      }
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Email</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>State</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="state_name"
                      value={this.state.state_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Zipcode</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="zip_code"
                      value={this.state.zip_code}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Guest;

//     							                          //
//  Program: Request.jsx                                       //
//  Application: Guest                                         //
//  Option: Request Listing                                    //
//  Developer: NP 		                                       //
//  Date: 2022-04-04                                           //
//                                                             //
/////////////////////////////////////////////////////////     ///

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import CalendarSidebar from "../common/CalendarSidebar";
import NoData from "../common/NoData";
import { Dropdown } from 'react-bootstrap';
class Request extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: this.props.match.params.propertySlug
    };
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <div className="deskchildMenu">
                    <aside>
                      {/* <PropertyViewSidebar /> */}
                      <CalendarSidebar property_slug={this.state.property_slug} />
                    </aside>
                  </div>
                  <div className="mobchildMenu">
                    <div className="d-flex justify-content-between align-items-center submenu">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <span>Sub Menu</span>
                          <i className="fas fa-chevron-down"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="tx-13">
                          <aside>
                            {/* <PropertyViewSidebar /> */}
                            <CalendarSidebar property_slug={this.state.property_slug} />
                          </aside>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                  </div>

                </div>
              </div>
              <div className="col-md-10 right">
                <div className="dashboardRightcard mg-t-15">
                  <NoData />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default Request
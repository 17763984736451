////////////////////////////////////////////////////////////
//     							                          //
//  Program: ResidentService.js                              //
//  Application: Resident                                    //
//  Option: Used for manage Guest                       //
//  Developer: NP						                     //
//  Date: 2022-05-20                                        //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class ResidentService {
  
  getResidentList(queryString) {
    let endPoint = "resident-user" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getResident(slug) {
    let endPoint = "resident-user/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getResidentByPhone(phone) {
    let endPoint = "get-resident/" + phone;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createResident(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "resident-user";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateResident(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "resident-user/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteResident(slug) {
    let endPoint = "resident-user/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  importData(data) {
    let endPoint = "lease-import";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };

    let response = ApiService.uploadFileCall(inputData);
    return response;
  }

  uploadResidentLogo(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'upload-resident-photo';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
    let response = ApiService.postCall(inputData);
    return response;
  }

  getResidentPropertyCleanerListDetails(queryString) {
    let endPoint = "resident-property-cleaner-list-details"+ (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

}

export default new ResidentService();

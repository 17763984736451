////////////////////////////////////////////////////////////
//     							                          //
//  Program: EmailService.js                              //
//  Application: Email                                    //
//  Option: Used for manage Emails                        //
//  Developer: NP 						                  //
//  Date: 2022-12-15                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class SchedulerService {
  
  getSchedulerList(queryString) {
    let endPoint = "schedulers" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getScheduler(slug) {
    let endPoint = "get-scheduler/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createScheduler(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "scheduler-store";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateScheduler(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "post-schaduler/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteScheduler(slug) {
    let endPoint = "scheduler-delete/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new SchedulerService();

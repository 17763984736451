import React from "react";
import { Container, Button } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
// import { PhotoshopPicker } from "react-color";
import ThemeService from "../../services/ThemeServices";
import PropertyService from "../../services/PropertyService";
import Select from "react-select";
import Alerts from "../common/Alerts";

class CommonThemeSetting extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      property_slug: this.props.propertySlug,
      brandslug:this.props.brandSlug,
      themeSlug: "",
      theme_name: "",
      color_name: "#d23876",
      heading_color: "#000",
      sort_order: "",
      pathname: window.location.pathname,
      template_list: {},
      isColorPicker: false,
      isHeadingColorPicker: false,
      heading_cms: [],
      select_theme_name: "",
      selectThemeList: [
        {
          label: "OH",
          value: "oh",
        },
        {
          label: "KINI",
          value: "kini",
        }
      ],
      config: {
        name: "body",
        backgroundColor: "#d23876",
        fontFamily: "Lato-Regular",
        fontSize: "14px",
        fontColor: ""
      },
      property_style: [],
      cms_style_slug:""
    };
    this.changeHeadingHandler = this.changeHeadingHandler.bind(this);
    this.changeFontfamilyHandler = this.changeFontfamilyHandler.bind(this);
    this.changeFontcolorHandler = this.changeFontcolorHandler.bind(this);
    this.changeFontsizeHandler = this.changeFontsizeHandler.bind(this);
    this.setColorPicker = this.setColorPicker.bind(this);
    this.setHeadingColorPicker = this.setHeadingColorPicker.bind(this);
    this.handleChangeThemeList = this.handleChangeThemeList.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleColorSelection = this.handleColorSelection.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.saveOrUpdateTheme = this.saveOrUpdateTheme.bind(this);
    this.changeHandlerNew = this.changeHandlerNew.bind(this);
    this.getStyleData = this.getStyleData.bind(this);
  }

  componentDidMount() {
    // this.getPropertyData();
    this.getStyleData();
  }

  async getPropertyData() {
    if (this.state.property_slug) {
      var select_theme_name = "";
      var selectedThemeList = [];

      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        select_theme_name = res.data ? res.data.theme : ''
      }
      selectedThemeList = res.data.theme === "oh" ?
        [{
          label: "OH",
          value: "oh",
        }]
        :
        [{
          label: "KINI",
          value: "kini",
        }]
      this.setState({ select_theme_name: select_theme_name, selectedThemeList: selectedThemeList });
    }
  }

   getStyleData = async () => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = "";
    if(this.props.level === 3) {
      res = await ThemeService.getPropertyStyleData(this.props.propertySlug,this.props.level);
     } else if(this.props.level === 2){
      res = await ThemeService.getPropertyStyleData(this.props.brandSlug,this.props.level);
     }else {
      res = await ThemeService.getPropertyStyleData(1,this.props.level);
     }

    if (global.successStatus.includes(res.status)) {
      resData.themeSlug = res.data ? res.data.slug : ''
      resData.property_style = res.data ? res.data.property_style : []
      resData.cms_style_slug = res.data ? res.data.slug : ""
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateTheme = async (e) => {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let inputData = "";

     inputData = {
      level:this.props.level,
      property_style: this.state.property_style
    };

    let res = await ThemeService.savePropertyStyledata(this.state.cms_style_slug, inputData);
    if (global.successStatus.includes(res.status)) {
      this.getStyleData();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({
        showSpinner: false,
        loading: false,
        total_record: totalRecords,
        FloorPlanListing: list,
      });
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
    }
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  handleColorSelection = () => {
    this.setState({ isColorPicker: false });
  };
  setColorPicker = () => {
    this.setState({ isColorPicker: true });
  };
  setHeadingColorPicker = () => {
    this.setState({ isHeadingColorPicker: true });
  };
  handleChangeComplete = (color) => {
    this.setState({ color_name: color.hex });
  };
  changeHeadingHandler = (event, name) => {
    let input = this.state.input;
    let value = event.value;
    this.setState({ currentHeading: value, input: input });
  };
  changeFontfamilyHandler = (event, name) => {
    let value = event.value;
    if (name.name === "h1") {
      let h1 = this.state.h1;
      h1.fontFamily = value;
      this.setState({ h1: h1 });
    }
    if (name.name === "h2") {
      let h2 = this.state.h2;
      h2.fontFamily = value;
      this.setState({ h2: h2 });
    }
    if (name.name === "h3") {
      let h3 = this.state.h3;
      h3.fontFamily = value;
      this.setState({ h3: h3 });
    }
  };
  changeFontcolorHandler = (color, name) => {
    if (name === "h1") {
      let h1 = this.state.h1;
      h1.fontColor = color.hex;
      this.setState({ h1: h1 });
    }
    if (name === "h2") {
      let h2 = this.state.h2;
      h2.fontColor = color.hex;
      this.setState({ h2: h2 });
    }
    if (name === "h3") {
      let h3 = this.state.h3;
      h3.fontColor = color.hex;
      this.setState({ h3: h3 });
    }
    this.setState({ heading_color: color.hex });
  };
  changeFontsizeHandler = (event, name) => {
    let value = event.value + "px";
    if (name.name === "h1") {
      let h1 = this.state.h1;
      h1.fontSize = value;
      this.setState({ h1: h1 });
    }
    if (name.name === "h2") {
      let h2 = this.state.h2;
      h2.fontSize = value;
      this.setState({ h2: h2 });
    }
    if (name.name === "h3") {
      let h3 = this.state.h3;
      h3.fontSize = value;
      this.setState({ h3: h3 });
    }
  };
  changeHandler = (event, name) => {
    let value = event.value;
    let config = this.state.config;
    config.fontFamily = value;
    // config.backgroundColor = this.state.color_name;
    this.setState({ config: config });
  };
  changeHandlerNew = (event, i) => {
    let property_style1 = this.state.property_style;

    if (event.target) {
      if (event.target.name === "fontColor") {
        property_style1[i][event.target.name] = event.target.value;
      }
    }
    else {
      property_style1[i]["fontFamily"] = event.value;
      property_style1[i].selected_font_family = {
        label: event.value,
        value: event.value,
      };
    }

    this.setState({ property_style: property_style1 });
  };
  handleChangeThemeList(value) {
    let select_theme_name = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        select_theme_name.push(item.value);
      });
    }
    const theme_value = value !== null ? value.value : [];
    this.setState({
      selectedThemeList: value,
      select_theme_name: theme_value,
    });
    this.setState({ confirm_back: 1 });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container pr-0">
            {/* new layout start */}
            <div className="row dashboardRightcard background-none row-xs mg-t-15 pd-t-0 swipersectionBtns">
              <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
              {global.userPermissions.checkPermission('brand-cms-update') &&
                <Button
                  variant="outline-dark btn-block btn-with-icon mr-3"
                  onClick={this.saveOrUpdateTheme}
                >
                  Update
                </Button>
              }
              </div>
              <div className="col-md-12">
              <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-r-0 mg-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              </div>
              {/* <div className="m-0 w-100">
                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <h3 className="mg-l-5 background-head">Basic Theme Settings</h3>
                </div>
                <div className="row align-items-basline pd-b-30 border-bottom-0 pd-r-15">
                </div>
              </div> */}
                  <div className="m-0 w-100">
                  <div className="row col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
              {this.state.property_style && this.state.property_style.length > 0 ? <>
                <h3 className="col-4  pd-b-5 background-head mg-t-10">Attributes</h3>
                <h3 className="col-4  pd-b-5 background-head mg-t-10">Font Family</h3>
                <h3 className="col-4  pd-b-5 background-head mg-t-10">Font Color</h3>
                {/* <div className="col-4"><p className="az-profile-name-text pl-0 pd-b-5">Style</p></div> */}
                {/* <div className="col-4"><p className="az-profile-name-text pl-0 pd-b-5">Font Family</p></div> */}
                {/* <div className="col-4"><p className="az-profile-name-text pl-0 pd-b-5">Font Color</p></div> */}
              </> : ''}
              {this.state.property_style && this.state.property_style.length > 0 ? (
                this.state.property_style.map((item, i) => {
                  return (
                    <>
                      <div className="col-4 pd-b-10 pd-lg-l-0 pd-xs-l-0" key={i}>
                        {item.label}
                      </div>
                      <div className="col-4 pd-b-10 pd-lg-l-0 pd-xs-l-0">
                        <Select
                          styles={customStyles}
                          isClearable={false}
                          className="multiselect"
                          classNamePrefix={"my-custom-react-select"}
                          menuPlacement="auto"
                          name="fontFamily"
                          value={item.fontFamily ? { label: item.fontFamily, value: item.fontFamily } : ""}
                          options={global.fontFamily}
                          // getOptionValue={(option) => `${option.theme_name}`}
                          onChange={(event) => this.changeHandlerNew(event, i)}
                          defaultValue={item.fontFamily ? { label: item.fontFamily, value: item.fontFamily } : ""}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 2,
                            colors: {
                              ...theme.colors,
                              primary: "#fff",
                              primary75: "#000",
                              primary50: "#000",
                              primary25: "#000",
                            },
                          })}
                        />
                      </div>
                      <div className="col-4 pd-b-10 pd-lg-l-0 pd-xs-r-0">
                        <input type="color" id="fontColor" name="fontColor" value={item.fontColor} onChange={(event) => this.changeHandlerNew(event, i)} />
                      </div>
                    </>
                  );
                })
              ) : ''}
              </div>
              </div>
              {/* <div
                className="col-12 mg-t-30 text-center"
                style={{ backgroundColor: this.state.color_name,fontFamily:this.state.config.fontFamily }}
              >
                 <h3 className="mg-l-5 background-head">Live Preview</h3>
                <h1 style={{fontFamily:this.state.h1.fontFamily,color:this.state.h1.fontColor,fontSize:this.state.h1.fontSize}}>h1. heading</h1>
                <h2  style={{fontFamily:this.state.h2.fontFamily,color:this.state.h2.fontColor,fontSize:this.state.h2.fontSize}}>h2. heading</h2>
                <h2  style={{fontFamily:this.state.h3.fontFamily,color:this.state.h3.fontColor,fontSize:this.state.h3.fontSize}}>h3. heading</h2>
                <p>p. heading</p>
                <label>Label. heading</label>
              </div> */}
            </div>
            {/* new layout end */}

          </Container>
        </main>
      </>
    );
  }
}

export default CommonThemeSetting;

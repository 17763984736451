////////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertyVendors.jsx                          //
//  Application: Property                                 //
//  Option: to view all Property Vendors                  //
//  Developer: NP  						                  //
//  Date: 2022-02-07                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Table, InputGroup, Modal, Form } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import pluswIcon from "./../../assets/images/plus.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import Select from "react-select";

export class PropertyOwnerView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      // propertySlug: this.props.property_slug,
      viewMode: 1,
      ownerList: [],
      ownerListTotal: 0,
      addOwnerModal: false,
      editOwnerModal: false,
      deleteOwnerModal: false,
      isSubmit: false,
      input: {},
      errors: {},
      confirm_back: 0,
      ownerTypeList: [],
      newOwnerTypeList: [],
      selectedOwnerTypeList: [],
    };
    this.changeView = this.changeView.bind(this);
    this.openaddOwnerModal = this.openaddOwnerModal.bind(this);
    this.openeditOwnerModal = this.openeditOwnerModal.bind(this);
    this.opendeleteOwnerModal = this.opendeleteOwnerModal.bind(this);
    this.saveOwner = this.saveOwner.bind(this);
    this.getOwnerDetails = this.getOwnerDetails.bind(this);
    this.updateOwner = this.updateOwner.bind(this);
    this.deleteOwner = this.deleteOwner.bind(this);
    this.getOwnerData = this.getOwnerData.bind(this);
  }

  componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getOwnerData();
    this.getOwnerTypeList();
  }

  async getOwnerTypeList() {
    const ownerTypeList = [
      { value: "1", label: "GP" },
      { value: "2", label: "LP" },
    ];
    this.setState({ ownerTypeList: ownerTypeList });
  }

  async getOwnerData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var ownerList = [];
    var ownerListTotal = 0;

    let res = await PropertyService.getPropertyOwner(
      "property_slug=" + this.props.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      ownerList = res.data.data ? res.data.data : [];
      ownerListTotal = ownerList ? ownerList.length : 0;
    }
    this.setState({
      ownerList: ownerList,
      ownerListTotal: ownerListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save ad agency data */
  saveOwner = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.owner_type &&
      !this.validator.errorMessages.owner_name
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.props.property_slug,
        owner_type: this.state.owner_type,
        owner_name: this.state.owner_name,
      };

      let res = await PropertyService.createPropertyOwner(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeaddOwnerModal();
        this.setState({
          owner_type: "",
          owner_name: "",
        });
        this.getOwnerData();
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.result.errorDetail[0].errorMessage
            ? res.data.result.errorDetail[0].errorMessage
            : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.errorMessage,
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get ad agency details data */
  async getOwnerDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await PropertyService.getOwnerInfoBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData.owner_type = res.data.owner_type ? res.data.owner_type : "";
        var selectedOwnerTypeList = [];
        selectedOwnerTypeList = res.data.owner_type === 1 ? ([
          {
            value: 1,
            label: "GP",
          },
        ]) : [
          {
            value: 2,
            label: "LP",
          },
        ];
        resData.owner_name = res.data.owner_name ? res.data.owner_name : "";
      }
      this.setState(resData);
      this.setState({ selectedOwnerTypeList: selectedOwnerTypeList })
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update ad agency data */
  updateOwner = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.owner_type &&
      !this.validator.errorMessages.owner_name
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.props.property_slug,
        owner_type: this.state.owner_type,
        owner_name: this.state.owner_name,
      };

      let res = await PropertyService.updatePropertyOwner(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeeditOwnerModal();
        this.setState({
          owner_type: "",
          owner_name: "",
        });
        this.getOwnerData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.result.errorDetail[0].errorMessage
            ? res.data.result.errorDetail[0].errorMessage
            : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete ad agency data */
  async deleteOwner(slug) {
    this.setState({ showSpinner: true });
    let res = await PropertyService.deletePropertyOwner(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.closedeleteOwnerModal();
      this.getOwnerData();
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closedeleteOwnerModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  openaddOwnerModal() {
    this.setState({ addOwnerModal: true });
    this.setState({ confirm_back: 0 });
  }

  closeaddOwnerModal() {
    this.setState({ addOwnerModal: false });
    this.setState({
      owner_type: "",
      owner_name: "",
    });
    this.validator.hideMessages();
    this.setState({ confirm_back: 0 });
  }

  openeditOwnerModal(slug) {
    this.getOwnerDetails(slug);
    this.setState({ editOwnerModal: true });
    this.setState({ slug: slug });
    this.setState({ confirm_back: 0 });
  }

  closeeditOwnerModal() {
    this.setState({ editOwnerModal: false });
    this.setState({
      owner_type: "",
      owner_name: "",
    });
    this.validator.hideMessages();
    this.setState({ confirm_back: 0 });
  }

  opendeleteOwnerModal(slug) {
    this.setState({ deleteOwnerModal: true });
    this.setState({ slug: slug });
    this.setState({ confirm_back: 0 });
  }

  closedeleteOwnerModal() {
    this.setState({ deleteOwnerModal: false });
    this.setState({ slug: "" });
    this.setState({ confirm_back: 0 });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  };

  handleChangeOwnerType(value) {
    let owner_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        owner_type.push(item.value);
      });
    }
    const owner_type_value = value !== null ? value.value : [];
    this.setState({
      selectedOwnerTypeList: value,
      owner_type: owner_type_value,
    });
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
      this.getOwnerData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
      this.getOwnerData();
    }
  }

  render() {

    const customStyles = {

      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),   
        color: (state.isSelected && state.isFocused) ? "white" : (state.isSelected ? "black" : state.isFocused && "white"),
        backgroundColor: (state.isSelected && state.isFocused) ? 'black' : (state.isFocused && "black"),
      })
    }


    return (
      <div className="dashboardRightcard">
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <div className="row- d-flex justify-content-end">
          {/* <Link to={'/properties/update/'+this.props.property_slug+"/property-owners"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
          {this.state.viewMode === 0 ? <button className="btn-success-outline-small" onClick={this.changeView}><img src={editIcon} />Edit</button>
            :
            <div className="d-flex">
              {/* <button type="button" className="btn-success-outline-small" onClick={this.changeView}>Cancel</button> */}
              <button className="btn-success-outline-small ml-3" onClick={this.openaddOwnerModal}><img src={pluswIcon} /> Add</button>
            </div>
          }
        </div>

        <div className="col-md-12 pl-0 pr-0 mg-t-15">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>

        {this.state.viewMode === 0 ? (
          <div className="row">
            <div className="table-responsive">
              <Table hover className="mg-b-0">
                <thead>
                  <tr>
                    <th className="w-30">Owner Name</th>
                    <th className="w-30">Owner Type</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.ownerListTotal > 0 ? (
                    this.state.ownerList.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.owner_name ? item.owner_name : ""}</td>
                          <td>
                            {item.owner_type === 1 ? "GP" : "LP"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="9" align="center">
                        There are no owners added in the system. You may add them by clicking Add button.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.viewMode === 1 ? (
          <div className="table-responsive">
            <Table hover className="mg-b-0">
              <thead>
                <tr>
                  <th className="w-30">Owner Name</th>
                  <th className="w-30">Owner Type</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.ownerListTotal > 0 ? (
                  this.state.ownerList.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td
                          className="hand-cursor"
                          onClick={() => this.openeditOwnerModal(item.slug)}
                        >
                          {item.owner_name ? item.owner_name : ""}
                        </td>
                        <td>
                          {item.owner_type === 1 ? "GP" : "LP"}
                        </td>
                        <td>
                          <div className="btn-icon-list d-block text-center">
                            <img
                              src={deleteicon}
                              alt="Delete"
                              onClick={() =>
                                this.opendeleteOwnerModal(item.slug)
                              }
                              className="hand-cursor"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="9" align="center">
                      There are no owners added in the system. You may add them by clicking Add button.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        ) : (
          ""
        )}

        {/* add wifi info popup modal */}
        <Modal
          show={this.state.addOwnerModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without saving?")
          //   )
          //     this.closeaddOwnerModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeaddOwnerModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Owner Info</h3>
            <InputGroup className="mt-3">
              <label>
                Owner Type <span className="text-danger">*</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  options={this.state.ownerTypeList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeOwnerType(value)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="owner_type"
                value={this.state.owner_type}
              >
                <option key="" value="">
                  Select Owner Type
                </option>
                <option className="form-control" key="1" value="1">
                  GP
                </option>
                <option className="form-control" key="2" value="2">
                  LP
                </option>
              </select> */}
              </div>
            </InputGroup>
            {this.state.errors.owner_type ? (
              <div className="text-danger">{this.state.errors.owner_type}</div>
            ) : (
              this.validator.message(
                "owner_type",
                this.state.owner_type,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>
                Owner Name <span className="text-danger">*</span>
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="owner_name"
                value={this.state.owner_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              // placeholder="Owner Name"
              />
            </InputGroup>
            {this.state.errors.owner_name ? (
              <div className="text-danger">{this.state.errors.owner_name}</div>
            ) : (
              this.validator.message(
                "owner_name",
                this.state.owner_name,
                "max:20|required",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex justify-content-between mt-3 row">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => {
                  if (this.state.confirm_back === 1)
                    if (window.confirm("Do you want to exit without saving?"))
                      this.closeaddOwnerModal();
                    else return false;
                  else this.closeaddOwnerModal();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="mx-auto m-0 btn btn btn-success btn-block"
                onClick={this.saveOwner}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit wifi info popup modal */}
        <Modal
          show={this.state.editOwnerModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without saving?")
          //   )
          //     this.closeeditOwnerModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeeditOwnerModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Owner Info</h3>
            <InputGroup className="mt-3">
              <label>
                Owner Type <span className="text-danger">*</span>
              </label>
              <div className="col-md-12 p-0">
                <Select
                  styles={customStyles}
                  isClearable={true}
                  menuPlacement="auto"
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  options={this.state.ownerTypeList}
                  getOptionValue={option => `${option.label}`}
                  onChange={(value) => this.handleChangeOwnerType(value)}
                  defaultValue={this.state.selectedOwnerTypeList}
                  value={this.state.selectedOwnerTypeList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: '#fff',
                      primary75: '#000',
                      primary50: '#000',
                      primary25: '#000',
                    }
                  })}
                />
                {/* <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="owner_type"
                value={this.state.owner_type}
              >
                <option key="" value="">
                  Select Owner Type
                </option>
                <option className="form-control" key="1" value="1">
                  GP
                </option>
                <option className="form-control" key="2" value="2">
                  LP
                </option>
              </select> */}
              </div>
            </InputGroup>
            {this.state.errors.owner_type ? (
              <div className="text-danger">{this.state.errors.owner_type}</div>
            ) : (
              this.validator.message(
                "owner_type",
                this.state.owner_type,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>
                Owner Name <span className="text-danger">*</span>
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="owner_name"
                value={this.state.owner_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              // placeholder="Owner Name"
              />
            </InputGroup>
            {this.state.errors.owner_name ? (
              <div className="text-danger">{this.state.errors.owner_name}</div>
            ) : (
              this.validator.message(
                "owner_name",
                this.state.owner_name,
                "max:20|required",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex justify-content-between mt-3">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => {
                  if (this.state.confirm_back === 1)
                    if (window.confirm("Do you want to exit without saving?"))
                      this.closeeditOwnerModal();
                    else return false;
                  else this.closeeditOwnerModal();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="mx-auto m-0 btn btn btn-success btn-block"
                onClick={this.updateOwner}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete wifi info popup modal */}
        <Modal
          show={this.state.deleteOwnerModal}
          // onHide={ () => { if(window.confirm('Are you sure to exit the pop up without saving?')) this.closedeleteOwnerModal()} }
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteOwnerModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteOwnerModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteOwner(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default PropertyOwnerView;

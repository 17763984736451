////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PropertyPriceService.js                      //
//  Application: Property                                 //
//  Option: Used for manage property price                //
//  Developer: NP           						      //
//  Date: 2022-02-12                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class PropertyPriceService {

  getPrice(queryString) {
    let endPoint = "property-cost" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createPrice(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "property-cost";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getPriceBySlug(slug) {
    let endPoint = "property-cost/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updatePrice(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "property-cost/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deletePrice(slug) {
    let endPoint = "property-cost/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new PropertyPriceService();

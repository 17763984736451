////////////////////////////////////////////////////////////
//     							                          //
//  Program: CompanyService.js                            //
//  Application: Services                                 //
//  Option: Used for Company list                         //
//  Developer: NP 						                  //
//  Date: 2022-01-05                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class CompanyService {
  getCompany() {
    let endPoint = "get-company-info";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getCompanyBySlug(slug) {
    let endPoint = "get-company-info/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // get snapshotdetails
  getSnapshotDetails() {
    let endPoint = "get-snapshot-info";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // get newly signed leases
  getNewlySignedLeases(property_slug) {
    let endPoint = "get-newly-signed-leases/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // get newly bookings
  getNewlyBookings(property_slug) {
    let endPoint = "get-new-bookings/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // get today checkin list
  getCheckinTodayList(property_slug) {
    let endPoint = "get-checkin-today-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  // get tomorrow checkin list
  getCheckinTomList(property_slug) {
    let endPoint = "get-checkin-tom-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  // get today checkout list
  getCheckoutTodayList(property_slug) {
    let endPoint = "get-checkout-today-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  // get tomorrow checkout list
  getCheckoutTomList(property_slug) {
    let endPoint = "get-checkout-tom-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  // get today cleaning scheduled
  getCleaningScheduledToday(property_slug) {
    let endPoint = "get-cleaning-scheduled-today/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  // get tomorrow cleaning scheduled
  getCleaningScheduledTom(property_slug) {
    let endPoint = "get-cleaning-scheduled-tom/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  // get guest currently staying
  getGuestCurrentlyStaying(property_slug) {
    let endPoint = "get-guest-currently-staying/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  // get occupancy unit list
  getOccupancyUnitList(property_slug) {
    let endPoint = "get-occupancy-unit-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateCompany(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-company-info/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  uploadCompanyLogo(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "upload-company-logo";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  // get onboarding process activity list
  getOnboardingProcessActivityList(property_slug) {
    let endPoint = "get-onboarding-process-activity-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // get onboarding completed activity list
  getOnboardingCompletedActivityList(property_slug) {
    let endPoint = "get-onboarding-completed-activity-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getAllCompanyData(queryString) {
    let endPoint = "get-company-data" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // get reservation list
  getReservationList(property_slug) {
    let endPoint = "get-reservation-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };

    let response = ApiService.getCall(inputData);
    return response;
  }

  getGuestReviw(property_slug) {
    let endPoint = "get-guest-review-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getLeaseInquiry(property_slug) {
    let endPoint = "get-lease-inquiry-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getRUInquiry(property_slug) {
    let endPoint = "get-ru-inquiry-list/" + property_slug;
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new CompanyService();

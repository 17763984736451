////////////////////////////////////////////////////////////
//     							                           //
//  Program: UploadedVideoView.jsx                         //
//  Application:  CorporateApp                             //
//  Option: Show the detail of uploaded video              //
//  Developer: Devendra Kumar                             //
//  Date: 15-06-2023                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Container, Modal } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import Alerts from "../common/Alerts";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import CorporateAppService from "../../services/CorporateAppService";
import CMSSidebar from "../common/CMSSidebar";
import Moment from "moment";

// import ShowMoreText from "react-show-more-text";

export class CorporateAppView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      errors: {},
      isSubmit: false,
      publishData: false,
      description: "",
      title: "",
      videoData: {},
      slug: this.props.match.params.slug,
      content_type: "",
      sort_order: "",
      confirm_back: 0,
    };
    this.opendeleteVideoModal = this.opendeleteVideoModal.bind(this);
  }
  async componentDidMount() {
    this.setState({ publishData: true });
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};

      let res = await CorporateAppService.getVideoDataBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
      }
      this.setState({ videoData: resData });
    }
    this.setState({ showSpinner: false, loading: false });
    this.setState({ publishData: false });
  }
  opendeleteVideoModal(slug, videofile) {
    this.setState({ deleteVideoModal: true, slug: slug, videofile: videofile });
  }
  async deleteVideo(slug, videofile) {
    this.setState({ isSubmit: true });
    let video_file = this.state.videofile;
    let res = await CorporateAppService.deleteVideo(slug, video_file);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteVideoModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.props.history.push("/upload-video"),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteVideoModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ isSubmit: true });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  closedeleteVideoModal() {
    this.setState({ deleteVideoModal: false, slug: "" });
  }

  getTitle() {
    return "Video Upload";
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <CMSSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-12">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                    <span
                        onClick={() => {
                          this.opendeleteVideoModal(this.state.slug);
                        }}
                        className="deleteicon mg-r-20"
                      >
                        <img src={deleteicon} alt="" />
                      </span>
                      <Link
                        to={"/upload-video/update/" + this.state.slug}
                        className="mg-l-2"
                      >
                        <button className="btn-success-outline-small">
                          <img src={editIcon} alt="Edit" />
                          Edit
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="row align-items-center col-md-12 mg-t-30 mg-b-20 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-xs-l-15">
                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-b-15">
                          <p className="az-profile-name-text pl-0 ">Title</p>
                          <p className="media-body pb-0">
                            {this.state.videoData
                              ? this.state.videoData.video_title
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-b-15">
                          <p className="az-profile-name-text pl-0 ">
                            Description
                          </p>
                          <p className="media-body pb-0">
                            {this.state.videoData
                              ? this.state.videoData.description
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-b-15">
                          <p className="az-profile-name-text pl-0 ">
                            Stream Url
                          </p>
                          <a
                            href={
                              this.state.videoData
                                ? this.state.videoData.stream_url
                                : ""
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <p className="media-body pb-0">
                              {this.state.videoData
                                ? this.state.videoData.stream_url
                                : ""}
                            </p>
                          </a>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-b-15">
                          <p className="az-profile-name-text pl-0 ">
                            Uploaded Date
                          </p>
                          <p className="media-body pb-0">
                            {this.state.videoData
                              ? Moment(this.state.videoData.upload_date_time).format("MM/DD/YY | hh:mm A")
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteVideoModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteVideoModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Video?</h3>
              <p>
                Do you really want to delete this video? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteVideoModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteVideo(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default CorporateAppView;

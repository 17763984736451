//     							                          //
//  Program: Toast.jsx                                    //
//  Application: Notification                             //
//  Option: common component for Toast message            //
//  Developer: NP  						                  //
//  Date: 2021-01-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import Nodata from "../../assets/images/no-data.svg";
// import { Link } from "react-router-dom";
// import pluswIcon from "./../../assets/images/plus.svg";

class NoDataActivity extends Component {
  render() {
    return (
      <div className="Nodatafound m-auto">
        <div className="col-md-12">
          <div className="row d-flex justify-content-center flex-column pd-l-0 pd-r-0 mg-b-15 text-center align-items-center">
            <img src={Nodata} alt="" />
            <p className="az-profile-name-text pl-0 pd-t-5 pd-b-10">
              
                {"There is no activity stream for this " + (this.props.action )}
               
            </p>

            {/* <Link to={"pet-policy/add"}>
                        <button className="btn-success-outline-small ">
                        <img src={pluswIcon} alt="" /> Add
                        </button>
                    </Link> */}
          </div>
        </div>
      </div>
    );
  }
}
export default NoDataActivity;
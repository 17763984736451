///////////////////////////////////////////////////////////
//     							                                     //
//  Program: LeadTrackingContacts.jsx                    //
//  Application: Property                                //
//  Option: List all Property Lead Tracking Contacts info//
//  Developer: Ashish Kumar					                     //
//  Date: 2022-04-04                                     //
//                                                       //
///////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import PropertySidebar from "../common/PropertySidebar";
import PhoneInput from "react-phone-input-2";

export class LeadTrackingContacts extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      property_slug: this.props.match.params.propertySlug,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      confirm_back: 0,
    };
    this.changeView = this.changeView.bind(this);
    this.saveOrUpdateProperty = this.saveOrUpdateProperty.bind(this);
    this.openWebsite = this.openWebsite.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    this.getData();
    this.setState({ showSpinner: false, loading: false });
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      var listingPhone = "";
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        if (res.data && res.data.listing_phone) {
          listingPhone = await this.phoneNumberAutoFormat(
            res.data.listing_phone
          );
        }
      }
      this.setState(resData);
      this.setState({
        listingPhone: listingPhone,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    return finalnumber;
  };

  saveOrUpdateProperty = async (e) => {
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        tab: "leed-tracking",
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        listing_phone: this.state.listing_phone,
        dial_code: this.state.dial_code,
        property_distribution_email: this.state.property_distribution_email,
        property_website_link: this.state.property_website_link,
      };

      if (this.state.property_slug !== undefined) {
        let res = await PropertyService.updateProperty(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData();
          this.setState({ viewMode: 0 });
        } else {
          //  let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.message ? res.data.message : "Error!",
          //   alertModalMessage:
          //     alertMessage !== "" ? alertMessage : res.data.message,
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  onChangePhone(value, country, e, formattedValue) {
    this.setState({ listing_phone: value });
    this.setState({ dial_code: country.dialCode });
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
    }
  }

  getTitle() {
    return "Lead Tracking Contacts";
  }

  openWebsite() {
    var url = this.state.property_website_link
      ? this.state.property_website_link
      : "";
    window.open(url, "_blank");
  }

  render() {
    const url = this.state.property_website_link
      ? this.state.property_website_link
      : "";
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.viewMode === 0 ? (
                        <>
                          {global.userPermissions.checkPermission(
                            "lead-tracking-update"
                          ) && (
                            <button
                              className="btn-success-outline-small"
                              onClick={this.changeView}
                            >
                              <img src={editIcon} />
                              Edit
                            </button>
                          )}
                        </>
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.changeView();
                                else return false;
                              else this.changeView();
                            }}
                          >
                            Cancel
                          </button>
                          {global.userPermissions.checkPermission(
                            "lead-tracking-update"
                          ) && (
                            <button
                              className="btn-success ml-3"
                              onClick={this.saveOrUpdateProperty}
                            >
                              {" "}
                              Save
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel">
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                    <div className="col-md-12 pd-lg-l-45 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    {this.state.viewMode === 0 ? (
                      <div className="row pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                        <div className="row col-md-12 mg-t-0 mg-md-t-0 mg-l-0 pd-lg-l-15 pd-xs-l-0 pd-t-30">
                          <div className="col-md-6">
                            <p className="az-profile-name-text pl-0">
                              Marketing Phone
                            </p>
                            <p className="media-body">
                              {this.state.listingPhone
                                ? this.state.listingPhone
                                : ""}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p className="az-profile-name-text pl-0">
                              Marketing Email
                            </p>
                            <p className="media-body">
                              {this.state.property_distribution_email
                                ? this.state.property_distribution_email
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="row col-md-12 mg-t-0 mg-md-t-0 mg-l-0 pd-lg-l-15 pd-xs-l-0">
                          <div className="col-md-12">
                            <p className="az-profile-name-text pl-0">Website</p>
                            <p
                              className="hand-cursor link-url"
                              onClick={this.openWebsite}
                            >
                              {url}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.viewMode === 1 ? (
                      <div className="m-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-0">
                        <div className="row align-items-center pd-t-30 pd-b-30 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Marketing Phone{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <PhoneInput
                              country={global.country}
                              disableSearchIcon
                              // autoFormat= "true"
                              enableSearch="true"
                              countryCodeEditable="true"
                              value={this.state.listing_phone}
                              onChange={this.onChangePhone}
                            />
                            {this.state.errors.listing_phone ? (
                              <div className="text-danger">
                                {this.state.errors.listing_phone}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Marketing Email{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              type="text"
                              name="property_distribution_email"
                              value={this.state.property_distribution_email}
                            />
                            {this.state.errors.property_distribution_email ? (
                              <div className="text-danger">
                                {this.state.errors.property_distribution_email}
                              </div>
                            ) : (
                              this.validator.message(
                                "Marketing Email",
                                this.state.property_distribution_email,
                                "email",
                                { className: "text-danger" }
                              )
                            )}
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Website{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              type="text"
                              name="property_website_link"
                              value={this.state.property_website_link}
                            />
                            {this.state.errors.property_website_link ? (
                              <div className="text-danger">
                                {this.state.errors.property_website_link}
                              </div>
                            ) : (
                              this.validator.message(
                                "Website",
                                this.state.property_website_link,
                                "url|max:200",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default LeadTrackingContacts;

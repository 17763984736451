////////////////////////////////////////////////////////////
//     							                                      //
//  Program: TaxType.jsx                           //
//  Application: Financial                                   //
//  Option: to view TaxType data                  //
//  Developer: Ashish Kumar                               //
//  Date: 2022-01-10                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import {
  Table,
  Modal,
  Form,
} from "react-bootstrap";
import Alerts from "../common/Alerts";
// import deleteicon from "../../assets/images/delete.svg";
// import editicon from "../../assets/images/edit.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import TaxTypeService from "../../services/TaxTypeService";
// import pluswIcon  from './../../assets/images/plus.svg'
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";

export class TaxType extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for Tax type
      addTaxTypeModal: false,
      editTaxTypeModal: false,
      deleteTaxTypeModal: false,
      taxTypeList: [],
      taxTypeListTotal: 0,
      tax_type_name: "",
      sort_order: "",
    };
    this.changeHandler = this.changeHandler.bind(this);

    /* for Tax Type */
    this.openAddTaxTypeModal = this.openAddTaxTypeModal.bind(this);
    this.openEditTaxTypeModal = this.openEditTaxTypeModal.bind(this);
    this.openDeleteTaxTypeModal = this.openDeleteTaxTypeModal.bind(this);
    this.saveTaxType = this.saveTaxType.bind(this);
    this.getTaxTypeDetails = this.getTaxTypeDetails.bind(this);
    this.updateTaxType = this.updateTaxType.bind(this);
    this.deleteTaxType = this.deleteTaxType.bind(this);
  }

  componentDidMount() {
    this.getTaxTypeData();
  }

  /* To get Tax Type list data */
  async getTaxTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var taxTypeList = [];
    var taxTypeListTotal = 0;

    let res = await TaxTypeService.getTaxType(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      taxTypeList = res.data.data ? res.data.data : [];
      taxTypeListTotal = taxTypeList ? taxTypeList.length : 0;
    }
    this.setState({
      taxTypeList: taxTypeList,
      taxTypeListTotal: taxTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To save Tax Type data */
  saveTaxType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.tax_type_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        tax_type_name: this.state.tax_type_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await TaxTypeService.createTaxType(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddTaxTypeModal();
        this.setState({
          tax_type_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getTaxTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get tax type details data  */
  async getTaxTypeDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await TaxTypeService.getTaxTypeBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.tax_type_name = res.data.tax_type_name
          ? res.data.tax_type_name
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update tax type data */
  updateTaxType = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.tax_type_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        tax_type_name: this.state.tax_type_name,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await TaxTypeService.updateTaxType(this.state.slug, inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditTaxTypeModal();
        this.setState({
          tax_type_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getTaxTypeData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete tax type at data */
  async deleteTaxType(slug) {
    this.setState({ showSpinner: true });
    let res = await TaxTypeService.deleteTaxType(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteTaxTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getTaxTypeData();
    } else {
      this.closeDeleteTaxTypeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* Tax Type Modals */
  openAddTaxTypeModal() {
    this.setState({ addTaxTypeModal: true });
  }

  closeAddTaxTypeModal() {
    this.setState({ addTaxTypeModal: false });
    this.setState({
      tax_type_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openEditTaxTypeModal(slug) {
    this.getTaxTypeDetails(slug);
    this.setState({ editTaxTypeModal: true });
    this.setState({ slug: slug });
  }

  closeEditTaxTypeModal() {
    this.setState({ editTaxTypeModal: false });
    this.setState({
      slug: "",
      tax_type_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openDeleteTaxTypeModal(slug) {
    this.setState({ deleteTaxTypeModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteTaxTypeModal() {
    this.setState({ deleteTaxTypeModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
  }

  render() {
    return (
      <>
        {/* Tax Type card */}
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="26"
              id="26"
              onClick={(e) => this.setOpen(!this.state.open, e)}
            >
              Tax Type
              <i className="mdi mdi-view-headline"> </i>
              {this.state.open && this.state.eventKey === "26" ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="26">
            <Card.Body>
              <div
                className="row d-flex justify-content-end mg-r-0 mg-b-15"
                
              >
                <Button variant="outline-dark btn-block btn-with-icon" onClick={this.openAddTaxTypeModal}>
                  <i className="fas fa-plus"></i> Add
                </Button>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-50">Tax Type Name</th>
                      <th className="w-25">Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.taxTypeListTotal > 0 ? (
                      this.state.taxTypeList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.tax_type_name}</td>
                            <td>
                              {item.status_id === 1 ? (
                                <b className="active"></b>
                              ) : (
                                <b></b>
                              )}
                            </td>
                            <td>
                              <div className="btn-icon-list">
                                <img
                                  src={editicon}
                                  alt="Edit"
                                  onClick={() =>
                                    this.openEditTaxTypeModal(item.slug)
                                  }
                                />
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.openDeleteTaxTypeModal(item.slug)
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          No rows found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="master">
                    <div className="table-responsive">
                      {/* <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
              <Button
                variant="outline-dark btn-block btn-with-icon"
                onClick={this.openAddTaxTypeModal}
              >
                <img src={pluswIcon} /> Add
              </Button>
            </div> */}
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <Table hover className="mg-b-0">
                        <thead>
                          <tr>
                            <th className="w-50">Tax Type Name</th>
                            <th className="w-25">Status</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.taxTypeListTotal > 0 ? (
                            this.state.taxTypeList.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.tax_type_name}</td>
                                  <td>
                                    {item.status_id === 1 ? (
                                      <b className="active"></b>
                                    ) : (
                                      <b></b>
                                    )}
                                  </td>
                                  {/* <td>
                          <div className="btn-icon-list">
                            <img
                              src={editicon}
                              alt="Edit"
                              onClick={() =>
                                this.openEditTaxTypeModal(item.slug)
                              }
                            />
                            <img
                              src={deleteicon}
                              alt="Delete"
                              onClick={() =>
                                this.openDeleteTaxTypeModal(item.slug)
                              }
                            />
                          </div>
                        </td> */}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                There are no tax type added in the system. You may add them by clicking Add button.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add Tax Type popup modal */}
        <Modal
          show={this.state.addTaxTypeModal}
          onHide={() => {
            if (
              window.confirm("Do you want to exit without saving?")
            )
              this.closeAddTaxTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddTaxTypeModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Tax Type</h3>
            <label>
              Tax Type Name <span className="text-danger">*</span>
            </label>
            <Form.Control
              onChange={this.changeHandler}
              name="tax_type_name"
              value={this.state.tax_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
            //placeholder="Tax Type Name"
            />
            {this.state.errors.tax_type_name ? (
              <div className="text-danger">
                {this.state.errors.tax_type_name}
              </div>
            ) : (
              this.validator.message(
                "tax_type_name",
                this.state.tax_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
               // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddTaxTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveTaxType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Tax Type popup modal */}
        <Modal
          show={this.state.editTaxTypeModal}
          onHide={() => {
            if (
              window.confirm("Do you want to exit without saving?")
            )
              this.closeEditTaxTypeModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditTaxTypeModal()}
            >
              {global.closee}
            </span>
            <h3>Edit Tax Type</h3>
            <label>
              Tax Type Name <span className="text-danger">*</span>
            </label>
            <Form.Control
              onChange={this.changeHandler}
              name="tax_type_name"
              value={this.state.tax_type_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
            //  placeholder="Tax Type Name"
            />
            {this.state.errors.tax_type_name ? (
              <div className="text-danger">
                {this.state.errors.tax_type_name}
              </div>
            ) : (
              this.validator.message(
                "tax_type_name",
                this.state.tax_type_name,
                "required",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              //  placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditTaxTypeModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="mx-auto m-0 btn btn btn-success btn-block"
                onClick={this.updateTaxType}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete Tax Type popup modal */}
        <Modal
          show={this.state.deleteTaxTypeModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteTaxTypeModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteTaxTypeModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteTaxTypeModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteTaxType(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default TaxType;

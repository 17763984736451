import React, { Component } from "react";
import { Container } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import GalleryProperty from "react-grid-gallery";
import NoData from "../common/NoData";
import ProblemService from "../../services/ProblemService";
import Moment from "moment";
import DownloadImg from "../../assets/images/download_svg.svg";
import MultiImageService from "../../services/MultiImageService";
import { Link } from "react-router-dom";
import AllPropertiesCleaningSidebar from "../common/AllPropertiesCleaningSidebar";
export class ProblemForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      slug: this.props.match.params.slug,
      isSubmit: false,
      sort_order: "",
      title: "",
      note: "",
      property_slug: this.props.match.params.outsidePropertySlug
        ? this.props.match.params.outsidePropertySlug
        : this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : "",
      due_date: "",
      activeClass: "info",
      isRefresh: false,
      mroNumber: "",
      createdBy: "",
      createdAt: "",
      inspectionNo: "",
      updatedBy: "",
      updatedAt: "",
      imageData: [],
      mroImages: [],
      deletedFileList: [],
      mroCategoryListTotal: 0,
      mroList: [],
      editChat: 0,
      chatUpdate: "",
      setRadioValue: 2,
      files_data: [],
      gallery_img: [],
      unitName: "",
      unitMappedId: "",
      resultData: "",
      reservationDetailUrl: "",
      residentDetailUrl: "",
      cleaningDetailUrl: "",
      confirmation_code: "",
      cleaning_type: "",
    };
    this.initialState = this.state;
    this.defaultData = {};
  }

  componentDidMount() {
    this.getMROData();
  }

  getActiveClass(e) {
    if (e === "notes") {
      this.setState({ isRefresh: true });
    } else {
      this.setState({ isRefresh: false });
    }
    this.setState({ activeClass: e });
  }

  // async getMROData() {
  //   this.setState({ loading: true });
  //   if (this.state.slug !== undefined) {
  //     var resData = {};
  //     var queryString = "is_request=1";
  //     let res = await ProblemService.getBySlug(this.state.slug, queryString);
  //     var multiImageList = [];
  //     var multiImageListTotalPro = 0;
  //     if (global.successStatus.includes(res.status)) {
  //       resData.mroSlug = res.data ? res.data.slug : "";
  //       resData.title = res.data ? res.data.title : "";
  //       resData.note = res.data ? res.data.note : "";
  //       // if (res.data) {
  //       this.setState({ resultData: res.data });
  //       if (
  //         this.state.resultData &&
  //         this.state.resultData.tbnb_project &&
  //         this.state.resultData.tbnb_project.reservation
  //       ) {
  //         var resUrl =
  //           "/properties/view/" +
  //           this.state.property_slug +
  //           "/reservations/view/" +
  //           this.state.resultData.tbnb_project.reservation.slug;
  //         var confirmationCode =
  //           this.state.resultData.tbnb_project.reservation.confirmation_code;
  //         this.setState({
  //           reservationDetailUrl: resUrl,
  //           confirmation_code: confirmationCode,
  //         });
  //       }
  //       // }
  //       var unit_name;
  //       if (res.data.unit) {
  //         unit_name = res.data.unit.unit_name
  //           ? res.data.unit.unit_name
  //           : res.data.unit.unit_number;
  //       }

  //       var mapped_unit;
  //       if (res.data.mapped_unit) {
  //         mapped_unit = res.data.mapped_unit ? res.data.mapped_unit.key_id : "";
  //       }
  //       resData.unit = res.data.unit ? res.data.unit : "";
  //       resData.gallery_img = res.data.images ? res.data.images : "";

  //       multiImageList = res.data.images ? res.data.images : [];
  //       multiImageListTotalPro = multiImageList.length;
  //     }
  //     multiImageList.map((opt, i) => {
  //       var orgFile = opt.uri;
  //       var mediumImg = opt.uri;
  //       var fileName = "problem_image_" + i;
  //       multiImageList[i]["src"] = orgFile;
  //       multiImageList[i]["thumbnail"] = mediumImg;
  //       multiImageList[i]["main_image"] = orgFile;
  //       multiImageList[i]["tags"] = [
  //         {
  //           value: (
  //             <img
  //               src={DownloadImg}
  //               alt=""
  //               className="problem_img_new"
  //               onClick={() => this.getDownloadFile(orgFile, fileName)}
  //             />
  //           ),
  //           key: `download image ${i}`,
  //           title: `Download Image`,
  //         },
  //       ];
  //     });
  //     const newMultiImageListPro = multiImageList;
  //     this.setState({
  //       multiImageList: multiImageList,
  //       multiImageListTotalPro: multiImageListTotalPro,
  //       newMultiImageListPro: newMultiImageListPro,
  //       unitName: unit_name,
  //       unitMappedId: mapped_unit,
  //     });
  //     this.setState(resData);
  //     this.setState({ input: resData });
  //     this.defaultData = resData;
  //   }
  //   this.setState({ loading: false });
  // }
  async getMROData() {
    this.setState({ loading: true });
    if (this.state.slug) {
      let res = await ProblemService.getBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          tbnb_problems: res.data.result,
          tbnb_problems_length: res.data.result.length,
        });
      }

      if (
        res.data.other_data &&
        res.data.other_data.reservation &&
        res.data.other_data.ref_table_id == 12
      ) {
        var resUrl =
          this.props.match.params.propertySlug !== undefined
            ? "/properties/view/" +
              this.state.property_slug +
              "/reservations/view/" +
              res.data.other_data.reservation.slug
            : "/reservations/view/" +
              this.state.property_slug +
              "/" +
              res.data.other_data.reservation.slug;
        var confirmationCode =
          res.data.other_data.reservation.confirmation_code;
        var cleaning_type = res.data.other_data.cleaning_type;
        this.setState({
          reservationDetailUrl: resUrl,
          confirmation_code: confirmationCode,
          cleaning_type: cleaning_type,
        });
      } else if (
        res.data.other_data &&
        res.data.other_data.reservation &&
        res.data.other_data.ref_table_id == 10
      ) {
        var residentUrl =
          this.props.match.params.propertySlug !== undefined
            ? "/properties/view/" +
              this.state.property_slug +
              "/listing/" +
              (res.data.other_data.listing_unavailable &&
              res.data.other_data.listing_unavailable.listing &&
              res.data.other_data.listing_unavailable.listing.slug
                ? res.data.other_data.listing_unavailable.listing.slug +
                  "/info?request_from=cleaning"
                : "")
            : "/listing/" +
              (res.data.other_data.listing_unavailable &&
              res.data.other_data.listing_unavailable.listing &&
              res.data.other_data.listing_unavailable.listing.slug
                ? res.data.other_data.listing_unavailable.listing.slug +
                  "/info?request_from=cleaning"
                : "");
        var cleaningTypeData =
          res.data.other_data && res.data.other_data.cleaning_type
            ? res.data.other_data.cleaning_type
            : "";
        this.setState({
          residentDetailUrl: residentUrl,
          cleaning_type: cleaningTypeData,
        });
      } else {
        var cleaningUrl = "";
        var cleaningtypeValue =
          res.data.other_data && res.data.other_data.cleaning_type
            ? res.data.other_data.cleaning_type
            : "";
        this.setState({
          cleaningDetailUrl: cleaningUrl,
          cleaning_type: cleaningtypeValue,
        });
      }

      this.setState({ loading: false });
    }
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({
      [event.target.name]: value,
      input: input,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    if (event.target.name === "comment") {
      this.setState({ errors: {} });
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Problem";
  }
  /*file download */
  async getDownloadFile(url, name) {
    let file_name = name;
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }
  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <HouseKeepingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <AllPropertiesCleaningSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          this.props.history.push(
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                  this.state.property_slug +
                                  "/problems"
                              : "/problems"
                          );
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="m-0 row">
                      {this.state.reservationDetailUrl &&
                      this.state.cleaning_type == 1 ? (
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                          <p className="az-profile-name-text pl-0">
                            Reservation Confirmation Code{" "}
                          </p>
                          <Link
                            to={this.state.reservationDetailUrl}
                            target="_blank"
                            className="hand-cursor showmore"
                          >
                            {this.state.confirmation_code}
                          </Link>
                        </div>
                      ) : this.state.cleaning_type == 2 ? (
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail">
                          <p className="az-profile-name-text pl-0">
                            Resident Stay{" "}
                          </p>
                          <Link
                            to={this.state.residentDetailUrl}
                            target="_blank"
                            className="hand-cursor showmore"
                          >
                            Show Details
                          </Link>
                        </div>
                      ) : (
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-30 pd-lg-r-30 pd-xs-r-0 mg-b-15 listing_detail"></div>
                      )}

                      {this.state.tbnb_problems_length > 0 ? (
                        this.state.tbnb_problems.map((items, index) => {
                          var multiImageList = items.images ? items.images : [];
                          return (
                            <>
                              <div className="problemsection">
                                <div className="row mg-back-30">
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                    <p className="az-profile-name-text pl-0">
                                      Issue{" "}
                                    </p>
                                    <p className="media-body">
                                      {items.title ? items.title : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                    <p className="az-profile-name-text pl-0">
                                      Description{" "}
                                    </p>
                                    <p className="media-body">
                                      {items.note ? items.note : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                    <p className="az-profile-name-text pl-0">
                                      Status{" "}
                                    </p>
                                    <p className="media-body">
                                      {items.status_display
                                        ? items.status_display
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                    <p className="az-profile-name-text pl-0">
                                      Reported By{" "}
                                    </p>
                                    <p className="media-body">
                                      {items &&
                                      items.tbnb_project &&
                                      items.tbnb_project
                                        .contractor_first_name !== null &&
                                      items.tbnb_project
                                        .contractor_last_name !== null
                                        ? items.tbnb_project
                                            .contractor_first_name +
                                          " " +
                                          items.tbnb_project
                                            .contractor_last_name
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                    <p className="az-profile-name-text pl-0">
                                      Create Date{" "}
                                    </p>
                                    <p className="media-body">
                                      {items.created_at &&
                                      items.created_at !== null
                                        ? Moment(items.created_at).format(
                                            global.dateFormat
                                          )
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                    <p className="az-profile-name-text pl-0">
                                      Last Update{" "}
                                    </p>
                                    <p className="media-body">
                                      {items.updated_at &&
                                      items.updated_at !== null
                                        ? Moment(items.updated_at).format(
                                            global.dateFormat
                                          )
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-0 pd-xs-l-0 pd-lg-r-0 pd-xs-r-0 mg-b-15 ">
                                    <p className="az-profile-name-text problemlable">
                                      Problem Images{" "}
                                    </p>
                                    {multiImageList.map((opt, i) => {
                                      var orgFile = opt.uri;
                                      var mediumImg = opt.uri;
                                      var fileName = "problem_image_" + i;
                                      multiImageList[i]["src"] = orgFile;
                                      multiImageList[i]["thumbnail"] =
                                        mediumImg;
                                      multiImageList[i]["main_image"] = orgFile;
                                      multiImageList[i]["tags"] = [
                                        {
                                          value: (
                                            <img
                                              src={DownloadImg}
                                              alt=""
                                              className="problem_img_new"
                                              onClick={() =>
                                                this.getDownloadFile(
                                                  orgFile,
                                                  fileName
                                                )
                                              }
                                            />
                                          ),
                                          key: `download image ${i}`,
                                          title: `Download Image`,
                                        },
                                      ];
                                    })}
                                    <React.Fragment>
                                      <div className="row row-xs align-items-center justify-content-center pd-t-30 pd-b-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-0 mg-l-5 addmultiImages ml-0- col-12 cleaingimage">
                                        <React.Fragment>
                                          <GalleryProperty
                                            backdropClosesModal={false}
                                            preloadNextImage={true}
                                            images={
                                              multiImageList
                                                ? multiImageList
                                                : ""
                                            }
                                            enableImageSelection={false}
                                            showImageCount={false}
                                          />
                                        </React.Fragment>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <NoData />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default ProblemForm;

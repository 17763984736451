////////////////////////////////////////////////////////////
//     							                                      //
//  Program: FloorPlanForm.jsx                            //
//  Application:  FloorPlan                               //
//  Option: For add or update Floor Plan                  //
//  Developer: Ashish Kumar                               //
//  Date: 2021-12-28                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import FloorPlanService from "./../../services/FloorPlanService";
import MultiImageService from "./../../services/MultiImageService";
import FileWidget from "../fileUploader/FileWidget";
import Moment from "moment";
import floorPlanImage from "./../../assets/images/floorplan_placeholder.png";
import FloorPlanImages from "./FloorPlanImages";
import Select from "react-select";
import FlooringService from "../../services/FlooringService";
import HeatingService from "../../services/HeatingService";

import { Container, Nav, Tab, Form, InputGroup } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Alerts from "../common/Alerts";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TaxService from "../../services/TaxService";
import PropertySidebar from "../common/PropertySidebar";
import FloorPlanCost from "./FloorPlanCost";
import FloorPlanAmenities from "./FloorPlanAmenities";
import ListingService from "../../services/ListingService";

export class FloorPlanForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "3",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      property_floor_plan_name: "",
      bedroom: "",
      bath: "",
      rent: "",
      square_foot: "",
      deposit: "",
      balcony: "",
      interior: "",
      leasing_window_day: "",
      main_image: "",
      imageUrl: floorPlanImage,
      sort_order: "",
      pathname: window.location.pathname,
      deletedFileList: [],
      unitTypeList: [],
      file_name: [],
      activeClass: "basic",
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
      taxes_slug: [],
      taxesList: [],
      taxesListTotal: 0,
      newTaxesList: [],
      selectedTaxesList: [],
      heatingList: [],
      newHeatingList: [],
      selectedHeatingList: [],
      flooringList: [],
      newFlooringList: [],
      selectedFlooringList: [],
      adr_value: "",
      video_url: "",
      cleaning_fee: "",
      pms_floor_plan_name: "",
      listingListTotal: 0,
      listingList: [],
      newListingList: [],
      selectedListingList: [],
      listing_slug: "",
    };
    this.floorPlanImage = React.createRef();
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateFloorPlan = this.saveOrUpdateFloorPlan.bind(this);
    this.saveOrImage = this.saveOrImage.bind(this);
    this.handleChangeNew = this.handleChangeNew.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
  }

  /* to get ref data */
  async getRefData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await MultiImageService.getRef(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getTaxData();
    this.getHeatingData();
    this.getAllListingData();
    this.getFlooringData();
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      var selectedTaxesList = this.state.selectedTaxesList;
      let res = await FloorPlanService.getFloorPlanBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data;
        resData.property_floor_plan_name = res.data
          ? res.data.property_floor_plan_name
          : "";
        resData.bedroom = res.data ? res.data.bedroom : "";
        resData.bath = res.data ? res.data.bath : "";
        resData.rent = res.data ? res.data.rent : "";
        resData.square_foot = res.data
          ? res.data.square_foot
            ? res.data.square_foot.replace(/\.00$/, "")
            : ""
          : "";
        resData.balcony = res.data
          ? res.data.balcony
            ? res.data.balcony.replace(/\.00$/, "")
            : ""
          : "";
        resData.interior = res.data
          ? res.data.interior
            ? res.data.interior.replace(/\.00$/, "")
            : ""
          : "";
        resData.deposit = res.data ? res.data.deposit : "";
        resData.leasing_window_day = res.data
          ? res.data.leasing_window_day
          : "";
        resData.marketing_description = res.data.marketing_description
          ? res.data.marketing_description
          : "";
        resData.cleaning_fee = res.data ? res.data.cleaning_fee : "";
        resData.adr_value = res.data ? res.data.adr_value : "";
        resData.video_url = res.data ? res.data.video_url : "";
        resData.main_image = res.data.main_image ? res.data.main_image : "";
        resData.imageUrl = res.data.main_image_url
          ? res.data.main_image_url
          : floorPlanImage;
        resData.sort_order = res.data ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        res.data.taxesData.forEach((item, i) => {
          selectedTaxesList = [
            ...selectedTaxesList,
            {
              value: item.slug,
              label: item.property_level_tax_name,
            },
          ];
        });
        resData.flooring = res.data.flooring ? res.data.flooring.slug : "";

        var selectedFlooringList = [];
        selectedFlooringList = res.data.flooring
          ? [
              {
                value: res.data.flooring.slug,
                label: res.data.flooring.flooring_name,
              },
            ]
          : "";
        resData.heating = res.data.heating ? res.data.heating.slug : "";
        var selectedHeatingList = [];
        selectedHeatingList = res.data.heating
          ? [
              {
                value: res.data.heating.slug,
                label: res.data.heating.heating_name,
              },
            ]
          : "";
        resData.pms_floor_plan_name = res.data
          ? res.data.pms_floor_plan_name
          : "";
        resData.listing_slug =
          res.data && res.data.listing_details && res.data.listing_details.slug
            ? res.data.listing_details.slug
            : "";
        var selectedListingList = [];
        selectedListingList = res.data.listing_slug
          ? [
              {
                value: res.data.listing_details.slug,
                label: res.data.listing_details.listing_name,
              },
            ]
          : "";
      }
      this.setState({
        selectedTaxesList: selectedTaxesList,
        taxes_slug: selectedTaxesList,
        selectedFlooringList: selectedFlooringList,
        selectedHeatingList: selectedHeatingList,
        selectedListingList: selectedListingList,
      });
      this.setState(resData);
      this.setState({ input: resData });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  async getTaxData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var taxesList = [];
    var taxesListTotal = 0;

    let res = await TaxService.getTax(
      "property_slug=" + this.state.property_slug + "&is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      taxesList = res.data ? res.data : [];
      taxesListTotal = taxesList ? taxesList.length : 0;
    }
    const newTaxesList =
      taxesListTotal > 0
        ? taxesList.map(({ slug, property_level_tax_name }) => ({
            value: slug,
            label: property_level_tax_name,
          }))
        : [];
    this.setState({
      showSpinner: false,
      loading: false,
      newTaxesList: newTaxesList,
      taxesListTotal: taxesListTotal,
    });
  }

  /* to get flooring data list */
  async getFlooringData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var flooringList = [];
    var flooringListTotal = 0;

    let res = await FlooringService.getFlooring(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      flooringList = res.data ? res.data : [];
      flooringListTotal = flooringList ? flooringList.length : 0;
    }
    const newFlooringList =
      flooringListTotal > 0
        ? flooringList.map(({ slug, flooring_name }) => ({
            value: slug,
            label: flooring_name,
          }))
        : [];
    this.setState({
      flooringList: flooringList,
      flooringListTotal: flooringListTotal,
      newFlooringList: newFlooringList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get Heating room data */
  async getHeatingData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var heatingList = [];
    var heatingListTotal = 0;

    let res = await HeatingService.getHeating(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      heatingList = res.data ? res.data : [];
      heatingListTotal = heatingList ? heatingList.length : 0;
    }
    const newHeatingList =
      heatingListTotal > 0
        ? heatingList.map(({ slug, heating_name }) => ({
            value: slug,
            label: heating_name,
          }))
        : [];
    this.setState({
      heatingList: heatingList,
      heatingListTotal: heatingListTotal,
      newHeatingList: newHeatingList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
  }

  saveOrImage = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun("floor_plan_form");
      let inputData = {
        availableFiles: output.response,
        deletedFiles: this.state.deletedFileList,
      };

      if (this.state.slug !== undefined) {
        let res = await MultiImageService.submitMultiImage(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/floor-plans"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  saveOrUpdateFloorPlan = async (e) => {
    this.state.taxes_slug = this.state.selectedTaxesList
      ? this.state.selectedTaxesList.map(function (obj) {
          return obj.value;
        })
      : "";
    let multiTaxString = this.state.taxes_slug
      ? this.state.taxes_slug.join(",")
      : [];

    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        tab: 1,
        property_slug: this.state.property_slug,
        property_floor_plan_name: this.state.property_floor_plan_name,
        bedroom: this.state.bedroom,
        bath: this.state.bath,
        rent: this.state.rent,
        taxes_slug: multiTaxString ? multiTaxString : "",
        square_foot: this.state.square_foot,
        balcony: this.state.balcony,
        interior: this.state.interior,
        leasing_window_day: parseInt(this.state.leasing_window_day),
        deposit: this.state.deposit,
        marketing_description: this.state.marketing_description,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
        main_image: this.state.main_image,
        flooring_slug: this.state.flooring ? this.state.flooring : "",
        heating_slug: this.state.heating ? this.state.heating : "",
        adr_value: this.state.adr_value,
        video_url: this.state.video_url,
        cleaning_fee: this.state.cleaning_fee,
        pms_floor_plan_name: this.state.pms_floor_plan_name,
        listing_slug: this.state.listing_slug,
      };

      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await FloorPlanService.updateFloorPlan(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/floor-plans/view/" +
                  this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await FloorPlanService.createFloorPlan(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          var last_insert_slug = res.data.last_insert_slug;
          this.setState({ slug: last_insert_slug });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/floor-plans/view/" +
                  this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (event.target.type === "date" && event.target.value !== null) {
      event.target.value = Moment(event.target.value, "MM-DD-YYYY").toDate();
    }
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  handleChangeNew(value) {
    let taxes_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        taxes_slug.push(item.value);
      });
    }

    this.setState({
      selectedTaxesList: value,
      taxes_slug: taxes_slug,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeFlooringList(value) {
    let flooring = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        flooring.push(item.value);
      });
    }
    const flooring_value = value !== null ? value.value : [];
    this.setState({
      selectedFlooringList: value,
      flooring: flooring_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeHeatingList(value) {
    let heating = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        heating.push(item.value);
      });
    }
    const heating_value = value !== null ? value.value : [];
    this.setState({
      selectedHeatingList: value,
      heating: heating_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.marketing_description !== undefined &&
      input.marketing_description !== "" &&
      input.marketing_description !== null &&
      input.marketing_description.length > 100000
    ) {
      isValid = false;
      errors["marketing_description"] =
        "The marketing description character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return (
        "Floor Plans" +
        (this.state.property_floor_plan_name
          ? " : " + this.state.property_floor_plan_name
          : "")
      );
    } else {
      return "Floor Plans";
    }
  }
  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  handleChangeListingList(value) {
    let listing_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        listing_slug.push(item.value);
      });
    }
    const listing_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedListingList: value,
      listing_slug: listing_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  /* to get Heating room data */
  async getAllListingData(queryString = "") {
    var listingList = [];
    var listingListTotal = 0;

    let res = await ListingService.getAllListingList(
      "is_dropdown=unitBasedListing&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      listingList = res.data ? res.data : [];
      listingListTotal = listingList ? listingList.length : 0;
    }
    const newListingList =
      listingListTotal > 0
        ? listingList.map(({ slug, listing_name, unit }) => ({
            value: slug,
            label: unit
              ? listing_name + (unit ? " (" + unit.unit_number + ") " : "")
              : listing_name,
          }))
        : [];
    this.setState({
      listingList: listingList,
      listingListTotal: listingListTotal,
      newListingList: newListingList,
    });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 info_box_model">
                    <h3>{this.getTitle()}</h3>
                    {this.state.slug !== undefined ? (
                      ""
                    ) : (
                      <div className="d-flex justify-content-end">
                        {this.state.slug !== undefined ? (
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.props.history.push(
                                    "/properties/view/" +
                                      this.state.property_slug +
                                      "/floor-plans/view/" +
                                      this.state.slug
                                  );
                                else return false;
                              else
                                this.props.history.push(
                                  "/properties/view/" +
                                    this.state.property_slug +
                                    "/floor-plans/view/" +
                                    this.state.slug
                                );
                            }}
                          >
                            Cancel
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn-success-outline-small "
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.props.history.push(
                                    "/properties/view/" +
                                      this.state.property_slug +
                                      "/floor-plans"
                                  );
                                else return false;
                              else
                                this.props.history.push(
                                  "/properties/view/" +
                                    this.state.property_slug +
                                    "/floor-plans"
                                );
                            }}
                          >
                            Cancel
                          </button>
                        )}

                        {this.state.slug !== undefined &&
                        global.userPermissions.checkPermission(
                          "floor-plans-update"
                        ) ? (
                          <button
                            className="btn-success ml-3 "
                            type="button"
                            disabled={this.state.isSubmit ? true : false}
                            onClick={this.saveOrUpdateFloorPlan}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        ) : global.userPermissions.checkPermission(
                            "floor-plans-update"
                          ) ? (
                          <button
                            className="btn-success ml-3"
                            type="button"
                            disabled={this.state.isSubmit ? true : false}
                            onClick={this.saveOrUpdateFloorPlan}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="floor_plan_form">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="basic"
                      onSelect={(e) => this.getActiveClass(e)}
                      eventKey={this.state.activeClass}
                    >
                      {this.state.slug !== undefined ? (
                        <ScrollingCarousel
                          rightArrow={true}
                          leftArrow={true}
                          className="swipesection scroll_nav_mob"
                        >
                          <Nav.Item>
                            <Nav.Link
                              eventKey="basic"
                              className={
                                this.state.activeClass === "basic"
                                  ? "active"
                                  : ""
                              }
                            >
                              Basic
                            </Nav.Link>
                          </Nav.Item>
                          {this.state.slug !== undefined ? (
                            <>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="floorPlanImages"
                                  className={
                                    this.state.activeClass === "floorPlanImages"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Images
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="floorPlanAmenities"
                                  className={
                                    this.state.activeClass ===
                                    "floorPlanAmenities"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Amenities
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="floorPlanCost"
                                  className={
                                    this.state.activeClass === "floorPlanCost"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Fees
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          ) : (
                            ""
                          )}
                        </ScrollingCarousel>
                      ) : (
                        ""
                      )}

                      <Tab.Content
                        className={this.state.slug !== undefined ? "" : "mt-0"}
                      >
                        <Tab.Pane eventKey="basic">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                            {this.state.slug !== undefined ? (
                              <div className="d-flex justify-content-end swiperBtns">
                                {this.state.slug !== undefined ? (
                                  <Link
                                    onClick={() => {
                                      if (this.state.confirm_back === 1)
                                        if (
                                          window.confirm(
                                            "Do you want to exit without saving?"
                                          )
                                        )
                                          this.props.history.push(
                                            "/properties/view/" +
                                              this.state.property_slug +
                                              "/floor-plans/view/" +
                                              this.state.slug
                                          );
                                        else return false;
                                      else
                                        this.props.history.push(
                                          "/properties/view/" +
                                            this.state.property_slug +
                                            "/floor-plans/view/" +
                                            this.state.slug
                                        );
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-success-outline-small"
                                    >
                                      Cancel
                                    </button>
                                  </Link>
                                ) : (
                                  <Link
                                    onClick={() => {
                                      if (this.state.confirm_back === 1)
                                        if (
                                          window.confirm(
                                            "Do you want to exit without saving?"
                                          )
                                        )
                                          this.props.history.push(
                                            "/properties/view/" +
                                              this.state.property_slug +
                                              "/floor-plans"
                                          );
                                        else return false;
                                      else
                                        this.props.history.push(
                                          "/properties/view/" +
                                            this.state.property_slug +
                                            "/floor-plans"
                                        );
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-success-outline-small mr-3"
                                    >
                                      Cancel
                                    </button>
                                  </Link>
                                )}
                                <button
                                  type="button"
                                  className="btn-success ml-3 mr-3"
                                  onClick={this.saveOrUpdateFloorPlan}
                                  disabled={this.state.isSubmit ? true : false}
                                >
                                  {this.state.isSubmit
                                    ? global.loader
                                    : this.state.slug !== undefined
                                    ? "Save"
                                    : "Save"}
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>
                            <div className="m-0">
                              <div className="row align-items-center pd-l-0 pd-r-15 pd-t-10 pd-b-0 border-bottom-0">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Name
                                  </label>

                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="property_floor_plan_name"
                                    value={this.state.property_floor_plan_name}
                                    className="form-control max_width_100"
                                    type="text"
                                  />
                                  {this.state.errors
                                    .property_floor_plan_name ? (
                                    <div className="text-danger">
                                      {
                                        this.state.errors
                                          .property_floor_plan_name
                                      }
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "name",
                                      this.state.property_floor_plan_name,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Bedrooms{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="bedroom"
                                    value={this.state.bedroom}
                                    className="form-control max_width_100"
                                    type="text"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                    maxLength="10"
                                    autoComplete="off"
                                  />
                                  {this.state.errors.bedroom ? (
                                    <div className="text-danger">
                                      {this.state.errors.bedroom}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "bedrooms",
                                      this.state.bedroom,
                                      "numeric|min:1,num",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Bathrooms{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="bath"
                                    value={this.state.bath}
                                    className="form-control max_width_100"
                                    type="text"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                    maxLength="10"
                                    autoComplete="off"
                                  />
                                  {this.state.errors.bath ? (
                                    <div className="text-danger">
                                      {this.state.errors.bath}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "bathrooms",
                                      this.state.bath,
                                      "numeric|min:1,num",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Flooring{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    menuPlacement="auto"
                                    value={this.state.selectedFlooringList}
                                    options={this.state.newFlooringList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeFlooringList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedFlooringList
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />

                                  {this.state.errors.flooring ? (
                                    <div className="text-danger">
                                      {this.state.errors.flooring}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Heating{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    menuPlacement="auto"
                                    value={this.state.selectedHeatingList}
                                    options={this.state.newHeatingList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeHeatingList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedHeatingList
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />

                                  {this.state.errors.heating ? (
                                    <div className="text-danger">
                                      {this.state.errors.heating}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Square Foot
                                  </label>

                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="square_foot"
                                    value={this.state.square_foot}
                                    className="form-control max_width_100"
                                    type="text"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    maxLength="10"
                                    autoComplete="off"
                                  />
                                  {this.state.errors.square_foot ? (
                                    <div className="text-danger">
                                      {this.state.errors.square_foot}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "square_foot",
                                      this.state.square_foot,
                                      "required|numeric|min:200,num",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Taxes{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Select
                                    isMulti
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    value={this.state.selectedTaxesList}
                                    options={this.state.newTaxesList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeNew(value)
                                    }
                                    defaultValue={this.state.selectedTaxesList}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Balcony{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="balcony"
                                    value={this.state.balcony}
                                    className="form-control max_width_100"
                                    type="text"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    maxLength="10"
                                    autoComplete="off"
                                  />
                                  {this.state.errors.balcony ? (
                                    <div className="text-danger">
                                      {this.state.errors.balcony}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "balcony",
                                      this.state.balcony,
                                      "numeric|min:0,num",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Interior{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="interior"
                                    value={this.state.interior}
                                    className="form-control max_width_100"
                                    type="text"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    maxLength="10"
                                    autoComplete="off"
                                  />
                                  {this.state.errors.interior ? (
                                    <div className="text-danger">
                                      {this.state.errors.interior}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "interior",
                                      this.state.interior,
                                      "numeric|min:0,num",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Leasing Turnover Days
                                    <span className="optional">(Optional)</span>
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <input
                                    type="text"
                                    name="leasing_window_day"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                    onChange={this.changeHandler}
                                    value={this.state.leasing_window_day}
                                    maxLength="2"
                                  />
                                  {this.state.errors.leasing_window_day ? (
                                    <div className="text-danger">
                                      {this.state.errors.leasing_window_day}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    ADR
                                    <span className="optional">(Optional)</span>
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <input
                                    type="text"
                                    name="adr_value"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    onChange={this.changeHandler}
                                    value={this.state.adr_value}
                                    maxLength="10"
                                  />
                                  {this.state.errors.adr_value ? (
                                    <div className="text-danger">
                                      {this.state.errors.adr_value}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    360 video URL
                                    <span className="optional">(Optional)</span>
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                  <input
                                    type="text"
                                    name="video_url"
                                    onChange={this.changeHandler}
                                    value={this.state.video_url}
                                    maxLength="500"
                                  />
                                  {this.state.errors.video_url ? (
                                    <div className="text-danger">
                                      {this.state.errors.video_url}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                                <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                              </div> */}
                              <div className="row align-items-center pd-t-5 pd-b-0 border-bottom-0">
                                <div className="col-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-45 pd-xs-r-15 mg-b-15">
                                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15"> */}
                                  <label className="form-label text-left pd-b-5 w-100">
                                    Floor Plan Description{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <InputGroup>
                                    <Form.Control
                                      onChange={this.changeHandler}
                                      name="marketing_description"
                                      value={this.state.marketing_description}
                                      className="form-control max_width_100"
                                      id="marketing_description"
                                      as="textarea"
                                      rows={global.textareaRowLength}
                                      // placeholder="Username"
                                    />
                                  </InputGroup>
                                  {this.state.errors.marketing_description ? (
                                    <div className="text-danger">
                                      {this.state.errors.marketing_description}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "marketing_description",
                                      this.state.marketing_description,
                                      "max:100000",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="row align-items-center pd-t-5 pd-b-0 border-bottom-0">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Cleaning Fee{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <div className="input-group-prepend prefix-dollar">
                                    <span className="input-group-text">$</span>
                                    <Form.Control
                                      onChange={this.changeHandler}
                                      name="cleaning_fee"
                                      value={
                                        this.state.cleaning_fee
                                          ? this.state.cleaning_fee.replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                          : "" || ""
                                      }
                                      className="form-control max_width_100"
                                      type="text"
                                      onKeyPress={
                                        global.onKeyPressEvent.floatValidation
                                      }
                                      maxLength="10"
                                      autoComplete="off"
                                    />
                                  </div>
                                  {this.state.errors.cleaning_fee ? (
                                    <div className="text-danger">
                                      {this.state.errors.cleaning_fee}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    PMS Floor Plan Name
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="pms_floor_plan_name"
                                    value={this.state.pms_floor_plan_name}
                                    className="form-control max_width_100"
                                    type="text"
                                  />
                                  {this.state.errors.pms_floor_plan_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.pms_floor_plan_name}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Listing Template{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Select
                                    styles={customStyles}
                                    menuPlacement="auto"
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    value={this.state.selectedListingList}
                                    options={this.state.newListingList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeListingList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedListingList
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                </div>
                              </div>
                              {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                                <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                              </div> */}

                              {/* <div className="row align-items-center pd-t-30 pd-b-30 pd-l-30 pd-r-30 border-bottom-0">
                                <div className="col-md-1 pl-0">
                                  <label className="form-label text-left pd-b-5">
                                    Status
                                  </label>
                                </div>

                                <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                  <b
                                    className={
                                      this.state.status_id
                                        ? "active"
                                        : "inactive"
                                    }
                                  ></b>
                                  <div
                                    className={
                                      this.state.status_id
                                        ? "az-toggle on"
                                        : "az-toggle"
                                    }
                                    onClick={() =>
                                      this.setState({
                                        status_id: !this.state.status_id,
                                      })
                                    }
                                  >
                                    <span></span>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </Tab.Pane>
                        {this.state.slug !== undefined ? (
                          <>
                            <Tab.Pane eventKey="floorPlanImages">
                              <FloorPlanImages
                                floor_plan_slug={this.state.slug}
                                propertySlug={this.state.property_slug}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="floorPlanAmenities">
                              <FloorPlanAmenities
                                floor_plan_slug={this.state.slug}
                                property_slug={this.state.property_slug}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="floorPlanCost">
                              <FloorPlanCost
                                floor_plan_slug={this.state.slug}
                                property_slug={this.state.property_slug}
                              />
                            </Tab.Pane>
                          </>
                        ) : (
                          ""
                        )}
                      </Tab.Content>
                    </Tab.Container>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default FloorPlanForm;

///////////////////////////////////////////////
//     							             //
//  Program: NotificationService.js          //
//  Application: Services                    //
//  Option: Used for all notification Module //
//  Developer: kiran singh                   //
//  Date: 2022-10-07                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class NotificationService {
  NotificationList(queryString) {
    let endPoint = "notification" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  NotificationCount(queryString) {
    let endPoint = "notification-count" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  NotificationReadAll(queryString) {
    let endPoint = "notification-read" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  notificationClearAll(queryString) {
    let endPoint = "notification-clear-all" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }


}

export default new NotificationService();

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PropertyPriceForm.jsx                        //
//  Application:  Property                                //
//  Option: For add or update Property Price              //
//  Developer: Np                                         //
//  Date: 2022-02-12                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import {
  Container,
  Form,
  Card,
  Accordion,
  Button,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import PropertyCostService from "../../services/PropertyCostService";
import CostTypeService from "../../services/CostTypeService";
import CostPayableByService from "../../services/CostPayableByService";
import CostPayableAtService from "../../services/CostPayableAtService";
import propertyLogo from "./../../assets/images/property_placeholder.png"
import Alerts from "../common/Alerts";

export class PropertyCostForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      cost_name: "",
      estimated: false,
      mandatory: false,
      refundable: false,
      sort_order: "",
      pathname: window.location.pathname,
      costTypeList: [],
      costPayableAtList: [],
      costPayableByList: [],
      property_logo_url: sessionStorage.getItem('property_logo_url'),
      property_type: sessionStorage.getItem('property_type'),
      property_name: sessionStorage.getItem('property_name'),
      year_value: sessionStorage.getItem('year_value') ? sessionStorage.getItem('year_value') : ''
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateCost = this.saveOrUpdateCost.bind(this);
  }

  async componentDidMount() {
    this.getCostTypeData();
    this.getCostPayableAtData();
    this.getCostPayableByData();
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await PropertyCostService.getCostBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.cost_name = res.data.cost_name ? res.data.cost_name : "";
        resData.cost_type_slug = res.data.cost_type ? res.data.cost_type.slug : "";
        resData.cost_payable_at_slug = res.data.cost_payable_at ? res.data.cost_payable_at.slug : "";
        resData.cost_payable_by_slug = res.data.cost_payable_by ? res.data.cost_payable_by.slug : "";
        resData.estimated = res.data.estimated === 1 ? true : false;
        resData.mandatory = res.data.mandatory === 1 ? true : false;
        resData.refundable = res.data.refundable === 1 ? true : false;
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Type list data */
  async getCostTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costTypeList = [];

    let res = await CostTypeService.getCostType("is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costTypeList = res.data ? res.data : [];
    }
    this.setState({
      costTypeList: costTypeList
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable At list data */
  async getCostPayableAtData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costPayableAtList = [];

    let res = await CostPayableAtService.getCostPayableAt("is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableAtList = res.data ? res.data : [];
    }
    this.setState({
      costPayableAtList: costPayableAtList
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable by list data */
  async getCostPayableByData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var costPayableByList = [];

    let res = await CostPayableByService.getCostPayableBy("is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableByList = res.data ? res.data : [];
    }
    this.setState({
      costPayableByList: costPayableByList
    });
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateCost = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug,
        cost_type_slug: this.state.cost_type_slug,
        cost_payable_at_slug: this.state.cost_payable_at_slug,
        cost_payable_by_slug: this.state.cost_payable_by_slug,
        cost_name: this.state.cost_name,
        estimated: this.state.estimated === true ? 1 : 2,
        mandatory: this.state.mandatory === true ? 1 : 2,
        refundable: this.state.refundable === true ? 1 : 2,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await PropertyCostService.updateCost(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/property-cost"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await PropertyCostService.createCost(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/property-cost"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.type === "checkbox" ? (event.target.checked) : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update Cost";
    } else {
      return "Add Cost";
    }
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          <div className="row row-xs">
            <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link to="/properties">Properties</Link>
                <span> / </span>
                <Link
                  to={"/properties/view/" + this.state.property_slug + "/property-cost"}
                >
                  Cost
                </Link>
                <span> / </span>
                <span>{this.getTitle()}</span>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-15 pd-t-4">
              <div className="col-md-3">
                <aside>
                  <div className="topHead">
                    <div className="demo-avatar-group">
                      <div className="az-img-user avatar-md online">
                        <img src={sessionStorage.getItem('property_logo_url') ? sessionStorage.getItem('property_logo_url') : propertyLogo} assName="rounded-circle" alt="" />
                      </div>
                      <div className="propname">
                        <h5> {sessionStorage.getItem('property_name') ? sessionStorage.getItem('property_name') : "Property"} </h5>
                        <ul>
                          <li> {sessionStorage.getItem('property_type') ? sessionStorage.getItem('property_type') : ""} </li>
                          {sessionStorage.getItem('year_value') ? <li></li> : ''}
                          <li>{sessionStorage.getItem('year_value') ? "Year " + sessionStorage.getItem('year_value') : ''}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <Accordion defaultActiveKey="0">
                      <Card onClick={(e) => this.setOpen(!this.state.open, "0")} >
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Property Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "0" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse
                          eventKey="0"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/basic")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic"
                              }
                            >
                              Property
                            </Link>

                            <Link
                              className={`${this.state.pathname.match("/tower")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              }
                            >
                              Towers
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/floors")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floors"
                              }
                            >
                              Floors
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/amenitites")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/amenitites"
                              }
                            >
                              Common Amenities
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/pet-policy")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                              }
                            >
                              Pet Policy
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/staff")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                            >
                              Staff
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/documents")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/documents"
                              }
                            >
                              Documents & Leases
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/property-level-tax")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              }
                            >
                              Taxes
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/nearby-attractions")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/nearby-attractions"
                              }
                            >
                              Near By Attractions
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/events")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                              }
                            >
                              Events
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "3")} >
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Units Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "3" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="3"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/floor-plans")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floor-plans"
                              }
                            >
                              Floor Plans
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/units")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units"
                              }
                            >
                              Units
                            </Link>
                            {/* <Link
                              className={`${
                                this.state.pathname.match("/rooms")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/rooms"
                              }
                            >
                              Rooms
                            </Link> */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </nav>
                </aside>
              </div>
              <div className="col-md-9 ">
                <div className="propSection pd-l-15">
                  <h3>{this.getTitle()}</h3>

                  <div className="dashboardRightcard">
                    <div className="col-md-12 pl-0 pr-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="m-0">
                      <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-0">
                            Cost Type <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <InputGroup>
                            <select
                              className="h-100 d-inline-block"
                              onChange={this.changeHandler}
                              name="cost_type_slug"
                              value={this.state.cost_type_slug}
                              tabIndex="10"
                            >
                              <option key="" value="">
                                Select Cost Type
                              </option>
                              {this.state.costTypeList.map((option) => (
                                <option key={option.slug} value={option.slug}>
                                  {option.cost_type_name}
                                </option>
                              ))}
                            </select>
                            {this.state.errors.cost_type_slug ? (
                              <div className="text-danger">
                                {this.state.errors.cost_type_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "cost_type",
                                this.state.cost_type_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </InputGroup>
                        </div>
                      </div>

                      <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-0">
                            Cost Payable At <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <InputGroup>
                            <select
                              className="h-100 d-inline-block"
                              onChange={this.changeHandler}
                              name="cost_payable_at_slug"
                              value={this.state.cost_payable_at_slug}
                              tabIndex="10"
                            >
                              <option key="" value="">
                                Select Cost Payable At
                              </option>
                              {this.state.costPayableAtList.map((option) => (
                                <option key={option.slug} value={option.slug}>
                                  {option.cost_payable_at_name}
                                </option>
                              ))}
                            </select>
                            {this.state.errors.cost_payable_at_slug ? (
                              <div className="text-danger">
                                {this.state.errors.cost_payable_at_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "cost_payable_at",
                                this.state.cost_payable_at_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </InputGroup>
                        </div>
                      </div>

                      <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-0">
                            Cost Payable By <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <InputGroup>
                            <select
                              className="h-100 d-inline-block"
                              onChange={this.changeHandler}
                              name="cost_payable_by_slug"
                              value={this.state.cost_payable_by_slug}
                              tabIndex="10"
                            >
                              <option key="" value="">
                                Select Cost Payable By
                              </option>
                              {this.state.costPayableByList.map((option) => (
                                <option key={option.slug} value={option.slug}>
                                  {option.cost_payable_by_name}
                                </option>
                              ))}
                            </select>
                            {this.state.errors.cost_payable_by_slug ? (
                              <div className="text-danger">
                                {this.state.errors.cost_payable_by_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "cost_payable_by",
                                this.state.cost_payable_by_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </InputGroup>
                        </div>
                      </div>

                      <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-0">
                            Cost <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <InputGroup>
                            <Form.Control
                              onChange={this.changeHandler}
                              name="cost_name"
                              value={this.state.cost_name}
                              className="form-control max_width_100"
                              type="text"
                              // placeholder="Cost"
                              onKeyPress={global.onKeyPressEvent.floatValidation}
                            />
                            {this.state.errors.cost_name ? (
                              <div className="text-danger">
                                {this.state.errors.cost_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "cost_name",
                                this.state.cost_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </InputGroup>
                        </div>
                      </div>
                      <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-0">
                            Refundable
                          </label>
                        </div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <Form.Check
                            name="refundable"
                            type={"checkbox"}
                            id={`refundable`}
                            label={""}
                            className={"ckbox"}
                            onChange={this.changeHandler}
                            checked={this.state.refundable}
                          />
                        </div>
                      </div>

                      <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-0">
                            Estimated
                          </label>
                        </div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <Form.Check
                            name="estimated"
                            type={"checkbox"}
                            id={`estimated`}
                            label={""}
                            className={"ckbox"}
                            onChange={this.changeHandler}
                            checked={this.state.estimated}
                          />
                        </div>
                      </div>

                      <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-0">
                            Mandatory
                          </label>
                        </div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <Form.Check
                            name="mandatory"
                            type={"checkbox"}
                            id={`mandatory`}
                            label={""}
                            className={"ckbox"}
                            onChange={this.changeHandler}
                            checked={this.state.mandatory}
                          />
                        </div>
                      </div>

                      {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                              <div className="col-md-3 pl-0">
                                <label className="form-label text-left mg-b-0">
                                    Sort Order
                                </label>
                              </div>
                              <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <InputGroup>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="sort_order"
                                    value={this.state.sort_order}
                                    onKeyPress={global.onKeyPressEvent.numberValidation}
                                    className="form-control max_width_100"
                                    type="text"
                                    // placeholder="Sort Order"
                                  />
                                  {this.state.errors.sort_order ? (
                                    <div className="text-danger">
                                      {this.state.errors.sort_order}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "sort_order",
                                      this.state.sort_order,
                                      "numeric|min:0,num",
                                      { className: "text-danger" }
                                    )
                                  )}
                              </InputGroup>
                              </div>
                          </div> */}
                      <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-0">
                            Status
                          </label>
                        </div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <div className="d-flex align-items-center justify-content-start mt-3">
                            <b className={
                              this.state.status_id ? "active" : "inactive"
                            }
                            ></b>
                            <div
                              className={
                                this.state.status_id ? "az-toggle on" : "az-toggle"
                              }
                              onClick={() =>
                                this.setState({ status_id: !this.state.status_id })
                              }
                            >
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-3 row">
                      <Link
                        to={
                          "/properties/view/" +
                          this.state.property_slug +
                          "/property-cost"
                        }
                      >
                        <button
                          type="button"
                          className="btn-success-outline-small"
                        >
                          Cancel
                        </button>
                      </Link>

                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={this.saveOrUpdateCost}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                            ? "Save"
                            : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ht-50"></div>
          </div>
        </Container>
      </main>
    );
  }
}

export default PropertyCostForm;
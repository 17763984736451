////////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertyVendors.jsx                          //
//  Application: Property                                 //
//  Option: to view all Property Vendors                  //
//  Developer: NP  						                  //
//  Date: 2022-02-07                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import {
  Table,
  InputGroup,
  Modal,
  Form,
} from "react-bootstrap";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import PropertyService from "../../services/PropertyService";
import WifiProviderService from "../../services/WifiProviderService";
import pluswIcon  from './../../assets/images/plus.svg'
// import { Link } from "react-router-dom";
import eyeicon from "./../../assets/images/eye.svg"
import eyeoff from "./../../assets/images/eye-off.svg"

export class PropertyWiFiInfo extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isSubmit:false,
      input: {},
      errors: {},
      slug: "",
      addWiFiInfoModal: false,
      editWiFiInfoModal: false,
      deleteWiFiInfoModal: false,
      wifiProviderList: [],
      wifi_provider_slug:"",
      username: "",
      password: "",
      wifiList:[],
      wifiListTotal:0
    };
    this.changeHandler = this.changeHandler.bind(this);
    //for ad agency
    this.openaddWiFiInfoModal = this.openaddWiFiInfoModal.bind(this);
    this.openeditWiFiInfoModal = this.openeditWiFiInfoModal.bind(this);
    this.opendeleteWiFiInfoModal = this.opendeleteWiFiInfoModal.bind(this);
    this.saveWiFiInfo = this.saveWiFiInfo.bind(this);
    this.getWiFiInfoDetails = this.getWiFiInfoDetails.bind(this);
    this.updateWiFiInfo = this.updateWiFiInfo.bind(this);
    this.deleteWiFiInfo = this.deleteWiFiInfo.bind(this);
    this.getWiFiInfoData = this.getWiFiInfoData.bind(this);
    this.getWiFiProviderList = this.getWiFiProviderList.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.getWiFiInfoData();
  }

  async getWiFiInfoData(queryString=""){
    this.setState({ showSpinner: true, loading: true });
    var wifiList = [];
    var wifiListTotal = 0;

    let res = await PropertyService.getPropertyWiFi("property_slug="+ this.props.propertySlug ,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      wifiList = res.data.data ? res.data.data : [];
      wifiListTotal = wifiList ? wifiList.length : 0;
    }
    this.setState({
      wifiList: wifiList,
      wifiListTotal: wifiListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getWiFiProviderList(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var wifiProviderList = [];

    let res = await WifiProviderService.getWifiProvider(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      wifiProviderList = res.data ? res.data : [];
    }
    this.setState({ wifiProviderList: wifiProviderList });
    this.setState({ showSpinner: false, loading: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  /* to save ad agency data */
  saveWiFiInfo = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.wifi_provider_slug &&
      !this.validator.errorMessages.username
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug : this.props.propertySlug,
        wifi_provider_slug:this.state.wifi_provider_slug,
        username: this.state.username,
        password:this.state.password
      };

      let res = await PropertyService.createPropertyWiFi(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeaddWiFiInfoModal();
        this.setState({
          wifi_provider_slug: "",
          username:"",
          password: ""
        });
        this.getWiFiInfoData();
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.result.errorDetail[0].errorMessage
            ? res.data.result.errorDetail[0].errorMessage
            : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.errorMessage,
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get ad agency details data */
  async getWiFiInfoDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await PropertyService.getWiFiInfoBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData.wifi_provider_slug = res.data.wifi_provider ? res.data.wifi_provider.slug : "";
        resData.username = res.data.username ? res.data.username : "";
        resData.password = res.data.password ? res.data.password : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update ad agency data */
  updateWiFiInfo = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.wifi_provider_slug &&
      !this.validator.errorMessages.username
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug : this.props.propertySlug,
        wifi_provider_slug:this.state.wifi_provider_slug,
        username: this.state.username,
        password:this.state.password
      };

      let res = await PropertyService.updatePropertyWiFi(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeeditWiFiInfoModal();
        this.setState({
            wifi_provider_slug: "",
            username:"",
            password: ""
          });
        this.getWiFiInfoData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.result.errorDetail[0].errorMessage
            ? res.data.result.errorDetail[0].errorMessage
            : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete ad agency data */
  async deleteWiFiInfo(slug) {
    this.setState({ showSpinner: true });
    let res = await PropertyService.deletePropertyWiFi(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.closedeleteWiFiInfoModal();
      this.getWiFiInfoData();
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closedeleteWiFiInfoModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  openaddWiFiInfoModal() {
    this.getWiFiProviderList();
    this.setState({ addWiFiInfoModal: true });
  }

  closeaddWiFiInfoModal() {
    this.setState({ addWiFiInfoModal: false });
    this.setState({
      third_party_agency_slug: "",
      street_1: "",
      street_2: ""
    });
    this.validator.hideMessages();
  }

  openeditWiFiInfoModal(slug) {
    this.getWiFiProviderList();
    this.getWiFiInfoDetails(slug);
    this.setState({ editWiFiInfoModal: true });
    this.setState({ slug: slug });
  }

  closeeditWiFiInfoModal() {
    this.setState({ editWiFiInfoModal: false });
    this.setState({
      slug: "",
      third_party_agency_slug: "",
      street_1: ""
    });
    this.validator.hideMessages();
  }

  opendeleteWiFiInfoModal(slug) {
    this.setState({ deleteWiFiInfoModal: true });
    this.setState({ slug: slug });
  }

  closedeleteWiFiInfoModal() {
    this.setState({ deleteWiFiInfoModal: false });
    this.setState({ slug: "" });
  }

  handleClick = () => this.setState(({type,src}) => ({
    type: type === 'text' ? 'hide' : 'text',
    src: src === eyeoff ? eyeicon : eyeoff
  }))

  render() {
    return (
      <div>
          <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
            <button className="btn-success-outline mr-3 mg-t-15 mg-b-15"  onClick={this.openaddWiFiInfoModal}><img src={pluswIcon}/> Add</button>
          </div>
          <div className="col-md-12 pl-0 pr-0">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>

            <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-30">Wi-Fi Provider Name</th>
                      <th className="w-30">Username</th>
                      <th className="w-30">Password</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.wifiListTotal > 0 ? (
                      this.state.wifiList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="hand-cursor" onClick={() =>
                                    this.openeditWiFiInfoModal(item.slug)
                                  }>{item.wifi_provider ? item.wifi_provider.wi_fi_provider_name : '' }</td>
                            <td>{item.username ? item.username : ""}</td>
                            {item.password ? <td>
                              <div className="d-flex align-items-center justify-content-center"><span>{this.state.type === "text" ? item.password : "****"}</span> <img src={this.state.src ? this.state.src : eyeicon} className="ml-3 d-block img-fluid" alt="Eye Icon" onClick={this.handleClick} /></div></td> : <td>-</td> }
                            <td>
                            <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.opendeleteWiFiInfoModal(item.slug)
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                        There are no wifi info added in the system. You may add them by clicking Add button.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
            </div>

        {/* add wifi info popup modal */}
        <Modal
          show={this.state.addWiFiInfoModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without saving?")
          //   )
          //     this.closeaddWiFiInfoModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <h3 className="text-center">Add Provider Info</h3>
            <InputGroup className="mt-3">
              <label>
                Wi-Fi Provider Name <span className="text-danger">*</span>
              </label>
              <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="wifi_provider_slug"
                value={this.state.wifi_provider_slug}
              >
                <option key="" value="">
                Select Wi-Fi Provider
                </option>
                {this.state.wifiProviderList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.wi_fi_provider_name}
                  </option>
                ))}
              </select>
            </InputGroup>
            {this.state.errors.wifi_provider_slug ? (
              <div className="text-danger">
                {this.state.errors.wifi_provider_slug}
              </div>
            ) : (
              this.validator.message(
                "wifi_provider_slug",
                this.state.wifi_provider_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Username <span className="text-danger">*</span></label>
              <Form.Control
                onChange={this.changeHandler}
                name="username"
                value={this.state.username}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Username"
              />
            </InputGroup>
            {this.state.errors.username ? (
              <div className="text-danger">{this.state.errors.username}</div>
            ) : (
              this.validator.message(
                "username",
                this.state.username,
                "max:20|required",
                { className: "text-danger" }
              )
            )}
            <InputGroup className="mt-3">
              <label>Password</label>
              <Form.Control
                onChange={this.changeHandler}
                name="password"
                value={this.state.password}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Password"
              />
            </InputGroup>
            {this.state.errors.password ? (
              <div className="text-danger">{this.state.errors.password}</div>
            ) : (
              this.validator.message(
                "password",
                this.state.password,
                "max:20",
                { className: "text-danger" }
              )
            )}

            
            <div className="d-flex justify-content-between mt-3 row">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeaddWiFiInfoModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="mx-auto m-0 btn btn btn-success btn-block"
                onClick={this.saveWiFiInfo}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit wifi info popup modal */}
        <Modal
          show={this.state.editWiFiInfoModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without saving?")
          //   )
          //     this.closeeditWiFiInfoModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <h3 className="text-center">Add Provider Info</h3>
            <InputGroup className="mt-3">
              <label>
                Wi-Fi Provider Name <span className="text-danger">*</span>
              </label>
              <select
                // className="form-control max_width_100"
                onChange={this.changeHandler}
                name="wifi_provider_slug"
                value={this.state.wifi_provider_slug}
              >
                <option key="" value="">
                Select Wi-Fi Provider
                </option>
                {this.state.wifiProviderList.map((option) => (
                  <option
                    className="form-control"
                    key={option.slug}
                    value={option.slug}
                  >
                    {option.wi_fi_provider_name}
                  </option>
                ))}
              </select>
            </InputGroup>
            {this.state.errors.wifi_provider_slug ? (
              <div className="text-danger">
                {this.state.errors.wifi_provider_slug}
              </div>
            ) : (
              this.validator.message(
                "wifi_provider_slug",
                this.state.wifi_provider_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            <InputGroup className="mt-3">
              <label>Username <span className="text-danger">*</span></label>
              <Form.Control
                onChange={this.changeHandler}
                name="username"
                value={this.state.username}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Username"
              />
            </InputGroup>
            {this.state.errors.username ? (
              <div className="text-danger">{this.state.errors.username}</div>
            ) : (
              this.validator.message(
                "username",
                this.state.username,
                "max:20|required",
                { className: "text-danger" }
              )
            )}
            <InputGroup className="mt-3">
              <label>Password</label>
              <Form.Control
                onChange={this.changeHandler}
                name="password"
                value={this.state.password}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Password"
              />
            </InputGroup>
            {this.state.errors.password ? (
              <div className="text-danger">{this.state.errors.password}</div>
            ) : (
              this.validator.message(
                "password",
                this.state.password,
                "max:20",
                { className: "text-danger" }
              )
            )}
            
            <div className="d-flex justify-content-between mt-3">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeeditWiFiInfoModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="mx-auto m-0 btn btn btn-success btn-block"
                onClick={this.updateWiFiInfo}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete wifi info popup modal */}
        <Modal
          show={this.state.deleteWiFiInfoModal}
          // onHide={ () => { if(window.confirm('Are you sure to exit the pop up without saving?')) this.closedeleteWiFiInfoModal()} }
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteWiFiInfoModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteWiFiInfo(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default PropertyWiFiInfo

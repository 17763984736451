////////////////////////////////////////////////////////////
//     							                          //
//  Program: RentalService.js                             //
//  Application: Rental                                   //
//  Option: Used for Rental United Data                   //
//  Developer: NP						                  //
//  Date: 2022-09-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class RentalService {
  
  createPropertyData(data) {
    let endPoint = "property-ru-insert";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  createUnitData(data) {
    let endPoint = "unit-ru-insert";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  createListingData(data) {
    let endPoint = "listing-ru-insert";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  createListingLicenseData(data) {
    let endPoint = "listing-licenses-ru-insert";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getRentalActivity(queryString) {
    let endPoint = "get-activity" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

}

export default new RentalService();

/////////////////////////////////////////////////////////////
//  Program: messages.jsx                                  //
//  Application: messages                                  //
//  Option: Home Sharing Listing                           //
//  Developer: PS, Kiran                                   //
//  Date: 2022-09-07                                       //
///////////////////////////////////////////////////////// ///

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import guestimg from "./../../assets/images/profile-nogender.png";
import RUmessageService from "../../services/RUmessageService";
import closeIcon from "./../../assets/images/close-wel.svg";
import NoData from "../common/NoData";
import Moment from "moment";
import search from "./../../assets/images/Search.svg";
import ohp from "./../../assets/images/logo.jpg";
import { Form } from "react-bootstrap";

class SelectRecipient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showquickreplyModal: false,
      loading: false,
      showSpinner: false,
      // property_slug: localStorage.getItem("message_page") == 1 ? "" : sessionStorage.getItem("property_slug"),
      property_slug: this.props.propertySlug,
      roleGroup: global.currerntRoleGroup ? global.currerntRoleGroup : null,
      role_slug: global.currerntRoleSlug ? global.currerntRoleSlug : null,
      loginUser: global.userData.slug,
      isNewModelOpen: this.props.isNewModelOpen,
      apiRes:false,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.getNewMessageList = this.getNewMessageList.bind(this);
  }
  componentDidMount() {
    this.getNewMessageList();
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  openModal() {
    this.setState({
      showaddContactModal: true,
    });
  }
  closeModal() {
    this.setState({ showaddContactModal: false, newlist: this.state.list });
    this.props.isNewModelOpenUpdate(0);
  }
 /* New Message List */
  async getNewMessageList(queryString = "") {
  let list = [];
  let queryStringForOther =
    "property_slug=" +
    this.state.property_slug +
    "&roleGroup=" +
    this.state.roleGroup +
    "&role_slug=" +
    this.state.role_slug;
  let res = await RUmessageService.getNewMessageList(
    queryString ? queryString : queryStringForOther
  );
  if (global.successStatus.includes(res.status)) {
    list = res.data ? res.data : [];
    this.props.newMessagAapiReasponce(false)
  }
  this.setState({
    showaddContactModal: true,
    list: list,
    apiRes: false,
    searchList:list,
  });
}
   /* Post thread */
   async postThread(record) {
    let reservation_slug = null;
    let is_support = null;
    let property_slug = null;
    let thread_type = null;
    let user_slug = null;
    if (record.role_group == global.RoleGroup.Guest) {
      reservation_slug = record.slug;
      property_slug = record.property_slug;
      thread_type = global.threadType.Guest;
    }
    if (record.role_group == global.RoleGroup.Staff) {
      user_slug = record.slug;
      property_slug = record.property_slug;
      thread_type = global.threadType.Staff;
    }
    if (record.role_group == global.RoleGroup.Resident) {
      user_slug = record.slug;
      property_slug = record.property_slug;
      thread_type = global.threadType.Resident;
    }
    if (record == global.is_support) {
      is_support = global.is_support;
      thread_type = global.threadType.Staff;
      user_slug = this.state.loginUser;
    }
    let inputData = {
      property_slug: property_slug,
      role_slug: this.state.role_slug,
      reservation_slug: reservation_slug,
      user_slug: user_slug,
      is_support: is_support ? is_support : null,
      thread_type: thread_type,
      resident_id: record.user_id ? record.user_id : "",
    };
    let thread_slug = null;
    let res = await RUmessageService.createThread(inputData);
    if (global.successStatus.includes(res.status)) {
      thread_slug = res.data ? res.data.slug : [];
     // var isNewMessage = res.data ? res.data.is_new : "";
      this.closeModal();
      const win = window.open("/properties/view/" + property_slug + "/messages/" + thread_slug, "_blank");
      win.focus();
    }
    this.setState({
      thread_slug: thread_slug,
      pageLoad: 0,
    });
  }
  searchForNewchangeHandler = (event) => {
    let value = event.target.value;
    let userList = this.state.list.length > 0 ? this.state.list : [];
    let searchList = this.state.searchList.length > 0 ? this.state.searchList : [];
    if (value == "") {
      this.getNewMessageList();
    } else {
      userList = searchList.filter((item) =>
        item.first_name
          ? item.first_name
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.aka_name
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.role_title
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          (item.unit_number !== undefined
            ? item.unit_number
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
            : "")
          : ""
      );
    }
    this.setState({ list: userList, searchList:searchList});
  };

  render() {
    return (
      <Modal
      show={this.state.showaddContactModal}
      onHide={() => this.closeModal("basic")}
      className="addContacts lg message_popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <div className="d-flex w-100 mb-2">
          <div className="message w-100">
            <div className="d-flex justify-content-between w-100">
              <h4>Select Recipient</h4>
              <span variant="outline-light" className="hand-cursor">
                <img
                  src={closeIcon}
                  className="closeicon m-0"
                  onClick={() => this.closeModal()}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="addContactsList">
          <div className="header">
            <div className="searchSection mt-2 mb-2">
              <Form.Control
                id="search"
                type="text"
                placeholder="Search here..."
                onChange={this.searchForNewchangeHandler}
                name="search"
                value={this.state.searchNew}
              />
              <span className="hand-cursor">
                <img src={search} />
              </span>
            </div>
          </div>
          {/* dropdown search user */}
          <div className="listSetction">
            <>
              <ul>
                <li onClick={() => this.postThread(this.state.is_support)}>
                  <a href="#">
                    <div className="profile_prop">
                      <figure>
                        <img src={ohp} />
                      </figure>
                      <p>OH</p>
                    </div>

                    <div className="content">
                      <div className="d-flex flex-column">
                        <b> Concierge</b>
                        <span> We are here to help you</span>
                      </div>
                    </div>
                  </a>
                </li>
               {this.state.list != undefined ? (
                  this.state.list.map((lists, i) => {
                    return (
                      <li key={i} onClick={() => this.postThread(lists)}>
                        <a href="#">
                          <div className="profile_prop">
                            <figure>
                              <img
                                src={
                                  lists.role_group != 4
                                    ? lists.property_logo
                                      ? lists.image_path +
                                      lists.property_logo
                                      : guestimg
                                    : lists.property_logo
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = guestimg;
                                }}
                              />
                            </figure>
                            <p>{lists.aka_name ? lists.aka_name : ""}</p>
                          </div>
                          <div className="content">
                            <div className="d-flex flex-column">
                              <b>
                                {lists.first_name ? lists.first_name : ""}
                                {lists.last_name
                                  ? " " + lists.last_name
                                  : ""}
                              </b>
                              <span>
                                {lists.role_group == global.RoleGroup.Guest
                                  ? global.RoleGroupName.Guest + " | " + lists.unit_name
                                  : ""}
                                {lists.role_group == global.RoleGroup.Staff
                                  ? global.RoleGroupName.Staff +
                                  " | " +
                                  lists.role_title
                                  : ""}
                                {lists.role_group ==
                                  global.RoleGroup.Resident
                                  ? global.RoleGroupName.Resident +
                                  (lists.unit_number
                                    ? " | " + lists.unit_number
                                    : "")
                                  : ""}

                                <span>
                                  {lists.checkin_date
                                    ? " | " +
                                    Moment(lists.checkin_date).format(
                                      global.dateFormat
                                    )
                                    : ""}
                                  {lists.checkout_date
                                    ? " - " +
                                    Moment(lists.checkout_date).format(
                                      global.dateFormat
                                    )
                                    : ""}
                                </span>
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })
                ) : (
                  <NoData />
                )}
              </ul>
            </>
          </div>
          {/* End dropdown search user */}
        </div>
      </Modal.Body>
    </Modal>
    );
  }
}
export default SelectRecipient;

import React, { Component } from "react";
import UnitsService from "../../services/UnitsService";
// import renderHTML from "react-render-html";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import unitsImage from "./../../assets/images/property_placeholder.png";
import { Link } from "react-router-dom";
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import { Modal } from "react-bootstrap";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import Moment from "moment";
import { Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import PropertyService from "../../services/PropertyService";

export class Basic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: false,
      loading: false,
      toggleSwitchDark2: true,
      slug: this.props.slug,
      property_slug: this.props.property_slug,
      renting_type_name: "",
      kitchen_name: "",
      living_room_name: "",
      flooring_name: "",
      heating_name: "",
      unit_description: "",
      descShow: 0,
      descShowRules: 0,
      status_id: "",
      // earningConfigurationData: [
      //   {
      //     fee_type: 1,
      //     fee_type_name: "Total Nightly Rate",
      //     payout_oh: "",
      //     payout_resident: "",
      //     payout_property: "",
      //     slug: "",
      //   },
      //   {
      //     fee_type: 2,
      //     fee_type_name: "Cleaning Fees",
      //     payout_oh: "",
      //     payout_resident: "",
      //     payout_property: "",
      //     slug: "",
      //   },
      //   {
      //     fee_type: 3,
      //     fee_type_name: "Channel Fees",
      //     payout_oh: "",
      //     payout_resident: "",
      //     payout_property: "",
      //     slug: "",
      //   },
      // ],
      // earning_configuration: [],
    };
    this.fullText = this.fullText.bind(this);
    this.fullTextRules = this.fullTextRules.bind(this);
    this.deleteUnits = this.deleteUnits.bind(this);
    this.opendeleteUnitsModal = this.opendeleteUnitsModal.bind(this);
    this.closedeleteUnitsModal = this.closedeleteUnitsModal.bind(this);
  }

  opendeleteUnitsModal(slug) {
    this.setState({ deleteUnitsModal: true, slug: slug });
  }

  closedeleteUnitsModal() {
    this.setState({ deleteUnitsModal: false, slug: "" });
  }

  async componentDidMount() {
    this.getUnitEarningConfigurationData();
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await UnitsService.getUnits(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};

        resData.tower_slug = res.data.tower ? res.data.tower.slug : "";
        resData.airbnb_notes = res.data.airbnb_notes
          ? res.data.airbnb_notes
          : "";
        resData.property_slug = res.data.property ? res.data.property.slug : "";
        resData.floor_slug = res.data.floor ? res.data.floor.slug : "";
        resData.affordable_type_slug = res.data.affordable_type
          ? res.data.affordable_type.slug
          : "";
        resData.affordable_type_name = res.data.affordable_type
          ? res.data.affordable_type.affordable_type_name
          : "";
        resData.floor_plan_slug = res.data.floor_plan
          ? res.data.floor_plan.slug
          : "";
        resData.company_slug = res.data.company ? res.data.company.slug : "";
        resData.unit_number = res.data.unit_number ? res.data.unit_number : "";
        resData.pms_unit_number = res.data.pms_unit_number ? res.data.pms_unit_number : "";
        resData.unit_name = res.data.unit_name ? res.data.unit_name : "";
        // resData.unit_description = res.data.unit_description
        //   ? res.data.unit_description
        //   : "";
        resData.unit_code = res.data.unit_code ? res.data.unit_code : "";

        // resData.house_rules = res.data.house_rules ? res.data.house_rules : "";

        // resData.move_in_window = res.data.move_in_window
        //   ? res.data.move_in_window
        //   : "";
        resData.base_price = res.data.base_price
          ? parseFloat(res.data.base_price)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.deposit = res.data.deposit
          ? parseFloat(res.data.deposit)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.cleaning_fee = res.data.cleaning_fee
          ? parseFloat(res.data.cleaning_fee)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.available = res.data.available ? res.data.available : "";
        resData.available_from = res.data.available_from;

        resData.available_from = res.data.available_from
          ? Moment(res.data.available_from).format(global.dateFormat)
          : null;
        resData.maximum_number_of_guests = res.data.maximum_number_of_guests
          ? res.data.maximum_number_of_guests
          : "";
        resData.total_size = res.data.floor_plan
          ? res.data.floor_plan.square_foot
            ? res.data.floor_plan.square_foot.replace(/\.00$/, "")
            : ""
          : "";
        resData.unit_main_image = res.data.unit_main_image
          ? res.data.unit_main_image
          : unitsImage;
        resData.imageUrl = res.data.unit_main_image_url
          ? res.data.unit_main_image_url
          : unitsImage;
        resData.sort_order =
          res.data.sort_order === 0 ? "" : res.data.sort_order;
        resData.status_id = res.data.status_id === 1 ? true : false;

        //for rental
        resData.is_managed_by_private_person = res.data.ruUnitData
          ? res.data.ruUnitData.is_managed_by_private_person === 1
            ? "Yes"
            : "No"
          : "No";
        resData.is_managed_by_owner = res.data.ruUnitData
          ? res.data.ruUnitData.is_managed_by_owner === 1
            ? "Yes"
            : "No"
          : "No";
        resData.security_deposit = res.data.ruUnitData
          ? res.data.ruUnitData.security_deposit
          : "";
        resData.location_description = res.data.ruUnitData
          ? res.data.ruUnitData.location_description
          : "";

        res.data.masterData.map((item) => {
          if (resData.renting_type === item.slug) {
            const renting_type = item.name;
            this.setState({ renting_type_name: renting_type });
          }

          // if (resData.kitchen === item.slug) {
          //   const kitchen = item.name;
          //   this.setState({ kitchen_name: kitchen });
          // }
          // if (resData.living_room === item.slug) {
          //   const living_room = item.name;
          //   this.setState({ living_room_name: living_room });
          // }

          if (resData.furnished === item.slug) {
            const furnished = item.name;
            this.setState({ furnished_name: furnished });
          }
        });
        resData.noiseaware_property_id = res.data.noiseaware_property_id;
        sessionStorage.setItem("unit_name", res.data.unit_name);
        localStorage.setItem("unit_name", res.data.unit_name);
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  async getUnitEarningConfigurationData() {
    if (this.state.slug !== undefined) {
      var resData = {};

      let res = await UnitsService.getUnitEarningConfigurationData(
        "unitSlug=" + this.state.slug + "&reference=units" + "&list=" + 5
      );

      if (global.successStatus.includes(res.status)) {
        resData.earningConfigurationDataLength = res.data ? res.data.length : 0;
        resData.earningConfigurationData = res.data
          ? res.data
          : [
              {
                fee_type: 1,
                fee_type_name: "Total Nightly Rate",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 2,
                fee_type_name: "Cleaning Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 3,
                fee_type_name: "Channel Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
            ];
        const values = [];
        if (resData.earningConfigurationDataLength > 0) {
          resData.earningConfigurationData.forEach((item, i) => {
            values.push({
              fee_type: item.fee_type,
              fee_type_name: item.fee_type_name,
              payout_oh: item.payout_oh,
              payout_resident: item.payout_resident,
              payout_property: item.payout_property,
              slug: item.slug,
            });
          });
        } else {
          resData.earningConfigurationData = [
            {
              fee_type: 1,
              fee_type_name: "Total Nightly Rate",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 2,
              fee_type_name: "Cleaning Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 3,
              fee_type_name: "Channel Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
          ];
        }
        if (resData.earningConfigurationDataLength === 0) {
          this.getPropertyEarningConfigurationData();
        }
        this.setState({
          earning_configuration: values,
          earningConfigurationData: values,
        });
        this.setState(resData);
      }
    }
  }

  async getPropertyEarningConfigurationData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};

      let res = await PropertyService.getPropertyEarningConfiguration(
        "slug=" +
          this.state.property_slug +
          "&reference=properties" +
          "&list=" +
          3
      );

      if (global.successStatus.includes(res.status)) {
        resData.earningConfigurationDataLength = res.data ? res.data.length : 0;
        resData.earningConfigurationData = res.data
          ? res.data
          : [
              {
                fee_type: 1,
                fee_type_name: "Total Nightly Rate",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 2,
                fee_type_name: "Cleaning Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 3,
                fee_type_name: "Channel Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
            ];
        const values = [];
        if (resData.earningConfigurationDataLength > 0) {
          resData.earningConfigurationData.forEach((item, i) => {
            values.push({
              fee_type: item.fee_type,
              fee_type_name: item.fee_type_name,
              payout_oh: item.payout_oh,
              payout_resident: item.payout_resident,
              payout_property: item.payout_property,
              slug: item.slug,
            });
          });
        } else {
          resData.earningConfigurationData = [
            {
              fee_type: 1,
              fee_type_name: "Total Nightly Rate",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 2,
              fee_type_name: "Cleaning Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 3,
              fee_type_name: "Channel Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
          ];
        }
        this.setState({
          earning_configuration: values,
          earningConfigurationData: values,
        });
        this.setState(resData);
      }
    }
  }

  async deleteUnits(slug) {
    this.setState({ showSpinner: true, loading: true });
    let res = await UnitsService.deleteUnits(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteUnitsModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      setTimeout(
        () =>
          (window.location =
            "/properties/view/" + this.state.property_slug + "/units"),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteUnitsModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false, loading: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  getTitle() {
    return "Unit";
  }

  render() {
    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle-  background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns ">
            {this.state.slug !== undefined ? (
              <>
                {global.userPermissions.checkPermission("units-delete") && (
                  <span
                    onClick={() => {
                      this.opendeleteUnitsModal(this.state.slug);
                    }}
                    className="deleteicon mg-r-20 hand-cursor"
                  >
                    <img src={deleteicon} />
                  </span>
                )}
              </>
            ) : (
              ""
            )}
            {global.userPermissions.checkPermission("units-update") && (
              <Link
                to={
                  "/properties/view/" +
                  this.state.property_slug +
                  "/units/update/" +
                  this.state.slug
                }
                className="mg-l-2"
              >
                <button className="btn-success-outline-small mr-3">
                  <img src={editIcon} />
                  Edit
                </button>
              </Link>
            )}
          </div>
          <div className="col-md-12 pd-l-30 pd-r-30 mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          <div className="m-0 listing_detail">
            <div className="row align-items-center pd-t-10 pd-b-0 border-bottom-0 pd-r-15">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Unit</p>
                <p className="media-body">
                  {this.state.unit_name
                    ? this.state.unit_name
                    : this.state.unit_number}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">PMS Unit Number</p>
                <p className="media-body">
                  {this.state.pms_unit_number
                    ? this.state.pms_unit_number
                    : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Unit SKU</p>
                <p className="media-body">
                  {this.state.unit_code ? this.state.unit_code : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Property</p>
                <p className="media-body">
                  {this.state.property ? this.state.property.property_name : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Tower</p>
                <p className="media-body">
                  {this.state.tower ? this.state.tower.tower_name : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Floor</p>
                <p className="media-body">
                  {this.state.floor ? this.state.floor.floor_name : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Floor Plan</p>
                <p className="media-body">
                  {this.state.floor_plan
                    ? this.state.floor_plan.property_floor_plan_name
                    : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Available</p>
                <p className="media-body">
                  {this.state.available === 1 ? "Yes" : "No"}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Available From</p>
                <p className="media-body">
                  {this.state.available_from ? this.state.available_from : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Affordable Type</p>
                <p className="media-body">
                  {this.state.affordable_type_name
                    ? this.state.affordable_type_name
                    : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Rent</p>
                <p className="media-body">
                  {this.state.base_price
                    ? "$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        this.state.base_price
                      )
                    : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Deposit</p>
                <p className="media-body">
                  {this.state.deposit
                    ? "$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        this.state.deposit
                      )
                    : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Cleaning Fee</p>
                <p className="media-body">
                  {this.state.cleaning_fee
                    ? "$" +
                      global.onKeyPressEvent.numberWithCommasForDecimal(
                        this.state.cleaning_fee
                      )
                    : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Total Size (Sq. ft)</p>
                <p className="media-body">
                  {this.state.total_size ? this.state.total_size : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Guest Count</p>
                <p className="media-body">
                  {this.state.maximum_number_of_guests
                    ? this.state.maximum_number_of_guests
                    : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Furnished</p>
                <p className="media-body">
                  {this.state.furnished_name ? this.state.furnished_name : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                <p className="az-profile-name-text pl-0">Internal Notes</p>
                <p className="media-body">
                  {this.state.airbnb_notes ? this.state.airbnb_notes : ""}
                </p>
              </div>
            </div>
            <div className="col-lg-12 pd-lg-l-30 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0  mg-t-15">
              <h3 className="mg-l-5 background-head">Payout Configuration</h3>
            </div>

            <div className="row align-items-center pd-t-10 pd-b-0 border-bottom-0 pd-r-15">
              <div className="col-md-12">
                <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-15 pd-xs-l-0 pd-sm-l-45 pd-sm-r-15  pd-lg-r-0 pd-xs-r-0 mg-r-0">
                  <div className="table-responsive">
                    <Table hover className="payout_table_w">
                      <thead>
                        <tr>
                          <th className="w-20">Type</th>
                          <th className="w-20">OH %</th>
                          <th className="w-20">Resident %</th>
                          <th className="w-20 text-left">Property %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.earningConfigurationData &&
                        this.state.earningConfigurationData.length > 0 ? (
                          this.state.earningConfigurationData.map(
                            (option, i) => (
                              <tr key={i}>
                                <td>
                                  {option.fee_type_name
                                    ? option.fee_type_name
                                    : ""}
                                </td>
                                <td>
                                  {option.payout_oh ? (
                                    <NumberFormat
                                      value={parseFloat(option.payout_oh)
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                        .replace(
                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                          ""
                                        )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix={"%"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  {option.payout_resident ? (
                                    <NumberFormat
                                      value={parseFloat(option.payout_resident)
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                        .replace(
                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                          ""
                                        )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix={"%"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td className="text-left">
                                  {option.payout_property ? (
                                    <NumberFormat
                                      value={parseFloat(option.payout_property)
                                        .toFixed(2)
                                        .replace(/\.00$/, "")
                                        .replace(
                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                          ""
                                        )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix={"%"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan="9" align="center">
                              There are no earning configuration added in the
                              system.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-12 pd-lg-l-45 pd-lg-r-45 pd-xs-l-30 pd-xs-r-15">
              <h3 className="mg-l-0 mg-t-15 background-head">Channel Manager</h3>
            </div>
            <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-45 pd-lg-r-45 pd-xs-l-30 pd-xs-r-15">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <p className="az-profile-name-text pl-0">Security Deposit</p>
                <p className="media-body">
                  {this.state.security_deposit ? "$"+this.state.security_deposit : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <p className="az-profile-name-text pl-0">Managed by Private</p>
                <p className="media-body">
                  {this.state.is_managed_by_private_person ? this.state.is_managed_by_private_person : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <p className="az-profile-name-text pl-0">Managed by Owner</p>
                <p className="media-body">
                  {this.state.is_managed_by_owner ? this.state.is_managed_by_owner : ""}
                </p>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                <p className="az-profile-name-text pl-0">Location Description</p>
                <p className="media-body">
                  {this.state.location_description ? this.state.location_description : ""}
                </p>
              </div>
            </div> */}
          </div>
        </div>

        <Modal show={this.state.deleteUnitsModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteUnitsModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteUnitsModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteUnits(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
      // </main>
    );
  }
}

export default Basic;

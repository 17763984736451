////////////////////////////////////////////////////////////
//     							                                      //
//  Program: FloorService.js                              //
//  Application: Property Type                            //
//  Option: Used for manage Floors                        //
//  Developer: NP, Ashish Kumar           						      //
//  Date: 2022-01-18                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class FloorService {

  getFloor(queryString) {
    let endPoint = "floor" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createFloor(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "floor";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getFloorBySlug(slug) {
    let endPoint = "floor/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateFloor(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "floor/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteFloor(slug) {
    let endPoint = "floor/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new FloorService();

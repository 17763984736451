////////////////////////////////////////////////////////////
//     							                                      //
//  Program: AllAgencyService.js                          //
//  Application: Third party                              //
//  Option: Used for manage all agency                    //
//  Developer: NP           						      //
//  Date: 2022-02-05                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AllAgencyService {

  getOtherAgency(queryString) {
    let endPoint = "third-party-agency"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createOtherAgency(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "third-party-agency";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getOtherAgencyBySlug(slug) {
    let endPoint = "third-party-agency/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateOtherAgency(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "third-party-agency/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteOtherAgency(slug) {
    let endPoint = "third-party-agency/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new AllAgencyService();

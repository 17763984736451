////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PetPolicyForm.jsx                                //
//  Application:  PetPolicy                                   //
//  Option: For add or update PetPolicy                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, InputGroup, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import PropertyService from "../../services/PropertyService";
import PetPolicyService from "../../services/PetPolicyService";
import CostTypeService from "../../services/CostTypeService";
import CostPayableByService from "../../services/CostPayableByService";
import CostPayableAtService from "../../services/CostPayableAtService";
import PropertyCostService from "../../services/PropertyCostService";
import PropertySidebar from "../common/PropertySidebar";
import Alerts from "../common/Alerts";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PetTypeService from "../../services/PetTypeService";
import Select from "react-select";
import pluswIcon from "./../../assets/images/plus.svg";
import deleteicon from "../../assets/images/delete.svg";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
// import NoData from "../common/NoData";
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import NumberFormat from "react-number-format";
// import renderHTML from "react-render-html";
// import ShowMoreText from "react-show-more-text";
export class PropertyFees extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      confirm_back: 0,
      property_slug: this.props.match.params.propertySlug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      // slug: this.props.match.params.slug,
      isSubmit: false,
      sort_order: "",
      petTypeList: [],
      pet_type_not_allowed: [],
      pet_data: [],
      pet_type_slug: [],
      newPetTypeList: [],
      petTypeListDropdown: [],
      selectedPetTypeListDropdown: [],
      selectedPetTypeList: [],
      pathname: window.location.pathname,
      petPolicyTypeDataTotal: 1,
      petPolicyTypeData: [
        {
          pet_type_slug: "",
          allowed_pet_number: "",
          deposite_amount_per_pet: "",
          one_time_fee_per_type: "",
          rent_per_type: "",
          weight_per_type: "",
          policy_slug: "",
        },
      ],
      is_error: [
        {
          pet_type_slug: "",
          allowed_pet_number: "",
          deposite_amount_per_pet: "",
          one_time_fee_per_type: "",
          rent_per_type: "",
          weight_per_type: "",
        },
      ],
      cost_data: [],
      FeeTypeDataTotal: 1,
      FeeTypeData: [
        {
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: 0,
          estimated: 0,
          mandatory: 0,
        },
      ],
      is_fee_error: [
        {
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: "",
          estimated: "",
          mandatory: "",
        },
      ],
      costTypeList: [],
      newCostTypeList: [],
      selectedCostTypeList: [],
      costPayableAtList: [],
      newCostPayableAtList: [],
      selectedCostPayableAtList: [],
      costPayableByList: [],
      newCostPayableByList: [],
      selectedCostPayableByList: [],
      records: [],
      resData: [],
      multiList: [],
      multiListTotal: 0,
      descShow: 0,
      descShowRules: 0,
      // pet_fee_for_guest: "",
      total_record: 0,
      fees_total_record: 0,
      fees_records: [],
      isFeeChange: 0,
      isPetChange: 0,
      isStrChange: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.changeHandlerStr = this.changeHandlerStr.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangeMulti = this.handleChangeMulti.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdatePetPolicy = this.saveOrUpdatePetPolicy.bind(this);
    this.changeView = this.changeView.bind(this);
    this.deleteCost = this.deleteCost.bind(this);
    this.fullText = this.fullText.bind(this);
    this.fullTextRules = this.fullTextRules.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getCost();
    this.getPetPolicyData();
    this.getPropertyData();
    // this.getSTRData();
  }

  async getPropertyData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};

      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData.pet_allowed = res.data.pet_allowed === 1 ? 1 : 0;
        // resData.parking_fee = res.data
        //   ? res.data.parking_fee.replace(/\.00$/, "")
        //   : "";
      }
      this.setState(resData);
    }
  }

  async getPetPolicyData(queryString = "") {
    //this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    var resData = [];
    var multiList = [];
    var multiListTotal = 0;
    let res = await PetPolicyService.getPetPolicy(
      this.state.property_slug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data.petPolicyData ? res.data.petPolicyData : [];
      totalRecords = list ? list.length : 0;
      resData.pet_policy_restrictions = res.data.propertyData[0]
        ? res.data.propertyData[0].pet_policy_restrictions
        : null;
      resData.custom_pet_policy = res.data.propertyData[0]
        ? res.data.propertyData[0].custom_pet_policy
        : null;
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    this.setState(resData);
    this.setState({
      total_record: totalRecords,
      records: list,
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  async getCost(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    var FeeTypeDataTotal = 0;
    let res = await PropertyCostService.getAllCosts(
      this.state.property_slug + "/1"
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = list ? list.length : 0;

      const values = [];
      if (totalRecords > 0) {
        list.forEach((item, i) => {
          values.push({
            slug: item ? item.slug : "",
            cost_type_slug: item.cost_type ? item.cost_type.slug : "",
            cost_payable_by_slug: item.cost_payable_by
              ? item.cost_payable_by.slug
              : "",
            cost_payable_at_slug: item.cost_payable_at
              ? item.cost_payable_at.slug
              : "",
            cost_name: item.cost_name
              ? parseFloat(item.cost_name)
                  .toFixed(2)
                  .replace(/\.00$/, "")
                  .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
              : "",
            refundable: item.refundable ? item.refundable : 0,
            estimated: item.estimated ? item.estimated : 0,
            mandatory: item.mandatory ? item.mandatory : 0,
            selected_cost_type: {
              value: item.cost_type ? item.cost_type.slug : "",
              label: item.cost_type ? item.cost_type.cost_type_name : "",
            },
            selected_cost_by: {
              value: item.cost_payable_by ? item.cost_payable_by.slug : "",
              label: item.cost_payable_by
                ? item.cost_payable_by.cost_payable_by_name
                : "",
            },
            selected_cost_at: {
              value: item.cost_payable_at ? item.cost_payable_at.slug : "",
              label: item.cost_payable_at
                ? item.cost_payable_at.cost_payable_at_name
                : "",
            },
          });
        });
      } else {
        values.push({
          cost_type_slug: "",
          cost_payable_by_slug: "",
          cost_payable_at_slug: "",
          cost_name: "",
          refundable: 0,
          estimated: 0,
          mandatory: 0,
        });
      }

      FeeTypeDataTotal = values.length;
      this.setState({
        FeeTypeData: values,
        FeeTypeDataTotal: FeeTypeDataTotal,
      });
    }
    this.setState({
      fees_total_record: totalRecords,
      fees_records: list,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  /* to get pet type data list */
  async getPetTypeData(queryString = "") {
    // this.setState({ showSpinner: true, loading: true });
    var petTypeList = [];
    var petTypeListTotal = 0;

    let res = await PetTypeService.getPetType(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      petTypeList = res.data.data ? res.data.data : [];
      petTypeListTotal = petTypeList ? petTypeList.length : 0;
    }
    const newPetTypeList =
      petTypeListTotal > 0
        ? petTypeList.map(({ slug, pet_type_name }) => ({
            value: slug,
            label: pet_type_name,
          }))
        : [];
    this.setState({
      newPetTypeList: newPetTypeList,
      petTypeListDropdown: newPetTypeList,
      petTypeList: petTypeList,
      petTypeListTotal: petTypeListTotal,
    });

    //this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Type list data */
  async getCostTypeData(queryString = "") {
    //   this.setState({ showSpinner: true, loading: true });
    var costTypeList = [];
    var costTypeListTotal = 0;

    let res = await CostTypeService.getCostType(
      "is_dropdown=1&cost_type=1", //1 for property
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costTypeList = res.data ? res.data : [];
      costTypeListTotal = costTypeList ? costTypeList.length : 0;
    }
    const newCostTypeList =
      costTypeListTotal > 0
        ? costTypeList.map(({ slug, cost_type_name }) => ({
            value: slug,
            label: cost_type_name,
          }))
        : [];
    this.setState({
      costTypeList: costTypeList,
      newCostTypeList: newCostTypeList,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  async getSelectedCostType() {
    //  this.setState({ showSpinner: true, loading: true });
    var selectedCostTypeList = [];

    let res = await CostTypeService.getSelectedCostType(
      this.state.property_slug + "/1"
    );

    if (global.successStatus.includes(res.status)) {
      selectedCostTypeList = res.data ? res.data : [];
    }
    // const alreadySelectedCostTypeList = selectedCostTypeList.map(
    //   ({ slug, cost_type_name }) => ({
    //     value: slug,
    //     label: cost_type_name,
    //   })
    // );
    this.setState({
      selectedCostTypeList: selectedCostTypeList,
      // alreadySelectedCostTypeList: alreadySelectedCostTypeList,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable At list data */
  async getCostPayableAtData(queryString = "") {
    //  this.setState({ showSpinner: true, loading: true });
    var costPayableAtList = [];
    var costPayableAtListTotal = 0;

    let res = await CostPayableAtService.getCostPayableAt(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableAtList = res.data ? res.data : [];
      costPayableAtListTotal = costPayableAtList ? costPayableAtList.length : 0;
    }
    const newCostPayableAtList =
      costPayableAtListTotal > 0
        ? costPayableAtList.map(({ slug, cost_payable_at_name }) => ({
            value: slug,
            label: cost_payable_at_name,
          }))
        : [];
    this.setState({
      costPayableAtList: costPayableAtList,
      newCostPayableAtList: newCostPayableAtList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  /* To get Cost Payable By list data */
  async getCostPayableByData(queryString = "") {
    //this.setState({ showSpinner: true, loading: true });
    var costPayableByList = [];
    var costPayableByListTotal = 0;

    let res = await CostPayableByService.getCostPayableBy(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      costPayableByList = res.data ? res.data : [];
      costPayableByListTotal = costPayableByList ? costPayableByList.length : 0;
    }
    const newCostPayableByList =
      costPayableByListTotal > 0
        ? costPayableByList.map(({ slug, cost_payable_by_name }) => ({
            value: slug,
            label: cost_payable_by_name,
          }))
        : [];
    this.setState({
      costPayableByList: costPayableByList,
      newCostPayableByList: newCostPayableByList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  async deletePetPolicy(slug) {
    this.setState({ showSpinner: true });
    if (slug) {
      let res = await PetPolicyService.deletePetPolicy(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
          selectedPetTypeList: [],
        });
        this.getDefaultPetData();
      } else {
        let alertMessage = "";
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async deleteCost(slug) {
    if (slug) {
      this.setState({ showSpinner: true });
      let res = await PropertyCostService.deleteCost(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({
          selectedCostPayableAtList: [],
          selectedCostPayableByList: [],
          selectedCostTypeList: [],
        });
        this.getCost();
      } else {
        let alertMessage = "";
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      this.setState({ showSpinner: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  }

  async getDefaultPetData() {
    //  this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      var selectedPetTypeList = [];
      var petPolicyTypeDataTotal = 1;
      let res = await PetPolicyService.getPetPolicyBySlug(
        this.state.property_slug + "/3"
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.petPolicyDataLength = res.data.petPolicyData
          ? res.data.petPolicyData.length
          : 0;
        resData.petPolicyData = res.data.petPolicyData
          ? res.data.petPolicyData
          : [
              {
                pet_type_slug: "",
                allowed_pet_number: "",
                deposite_amount_per_pet: "",
                one_time_fee_per_type: "",
                rent_per_type: "",
                weight_per_type: "",
                policy_slug: "",
              },
            ];

        const values = [];
        if (resData.petPolicyDataLength > 0) {
          resData.petPolicyData.forEach((item, i) => {
            values.push({
              pet_type_slug: item.pet_type.slug,
              allowed_pet_number: item.allowed_pet_number,
              one_time_fee_per_type: item.one_time_fee_per_type,
              deposite_amount_per_pet: item.deposite_amount_per_pet,
              rent_per_type: item.rent_per_type,
              weight_per_type: item.weight_per_type,
              policy_slug: item.slug,
              selected_pet_type: {
                value: item.pet_type.slug,
                label: item.pet_type.pet_type_name,
              },
            });
          });
        } else {
          resData.petPolicyTypeData = [
            {
              pet_type_slug: "",
              allowed_pet_number: "",
              deposite_amount_per_pet: "",
              one_time_fee_per_type: "",
              rent_per_type: "",
              weight_per_type: "",
              policy_slug: "",
              // selected_pet_type: {
              //   value: "",
              //   label: "",
              // },
            },
          ];
        }
        petPolicyTypeDataTotal = values.length;
        this.setState({
          petPolicyTypeData: values,
          petPolicyTypeDataTotal: petPolicyTypeDataTotal,
        });
        resData.pet_policy_restrictions = res.data.propertyData[0]
          ? res.data.propertyData[0].pet_policy_restrictions
          : "";
        resData.custom_pet_policy = res.data.propertyData[0]
          ? res.data.propertyData[0].custom_pet_policy
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        res.data.petPolicyRestrictions.forEach((item, i) => {
          selectedPetTypeList = [
            ...selectedPetTypeList,
            {
              value: item.slug,
              label: item.pet_type_name,
            },
          ];
        });
      }
      this.setState(resData);
      this.setState({
        selectedPetTypeList: selectedPetTypeList,
      });
      this.setState({ pet_type_not_allowed: selectedPetTypeList });
    }
    //  this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdatePetPolicy = async (e) => {
    this.state.pet_type_not_allowed = this.state.selectedPetTypeList
      ? this.state.selectedPetTypeList.map(function (obj) {
          return obj.value;
        })
      : "";
    let multiPetNotAllowed = this.state.pet_type_not_allowed
      ? this.state.pet_type_not_allowed.join(",")
      : [];
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        pet_data: this.state.pet_data ? this.state.pet_data : [],
        pet_type_not_allowed: multiPetNotAllowed,
        pet_policy_restrictions: this.state.pet_policy_restrictions,
        custom_pet_policy: this.state.custom_pet_policy,
        property_slug: this.state.property_slug,
        dataSlug: this.state.property_slug,
        // parking_fee: this.state.parking_fee,
        ref_table_id: 3, //3-property
        // sort_order: this.state.sort_order,
        // status_id: this.state.status_id === true ? 1 : 2,
      };
      if (
        this.state.property_slug !== undefined &&
        this.state.isPetChange === 1
      ) {
        //pet policy api call
        let res = await PetPolicyService.updatePetPolicy(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          // this.changeView();
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
        this.setState({ pet_data: [] });
      }

      // var valueArr = this.state.FeeTypeData;
      // valueArr.map(function(item,i){
      //   if(item[i].cost_type_slug && item[i].cost_payable_at_slug && item[i].cost_payable_by_slug){
      //   }
      //   // valueArr[index]['cost_type_slug'] = item.cost_type_slug;
      //   // valueArr[index]['cost_payable_at_slug'] = item.cost_payable_at_slug;
      //   // valueArr[index]['cost_payable_by_slug'] = item.cost_payable_by_slug;
      // });

      // var isDuplicate = valueArr.some(function(item, id){
      //     return valueArr.indexOf(item) != id
      // });

      let feeData = {
        level: 1,
        slug: this.state.property_slug,
        cost_data: this.state.FeeTypeData,
      };
      if (
        this.state.property_slug !== undefined &&
        this.state.isFeeChange === 1
      ) {
        //update cost api call
        let res = await PropertyCostService.updateCosts(feeData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          // this.changeView();
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      // let strFeeData = {
      //   slug: this.state.property_slug,
      //   ref_table_id: 3, //property table
      //   fee_type: 1, //pet fee for property
      //   fee_value: this.state.pet_fee_for_guest
      //     ? this.state.pet_fee_for_guest
      //     : 0, //pet fee per day
      // };
      // if (
      //   this.state.property_slug !== undefined &&
      //   this.state.isStrChange === 1
      // ) {
      //   //update str fees api call
      //   let res = await PropertyCostService.updateStrFees(strFeeData);
      //   if (global.successStatus.includes(res.status)) {
      //     this.setState({
      //       showAlertModal: true,
      //       alertModalType: "success",
      //       alertModalTitle: "Success",
      //       alertModalMessage: res.message ? res.message : "Success",
      //     });
      //   } else {
      //     let alertMessage = "";
      //     if (
      //       res.data.errorCode === "validationFailed" &&
      //       res.data.errorDetail !== null
      //     ) {
      //       let errors = {};
      //       res.data.errorCode = "Validation Failed";
      //       res.data.errorDetail.forEach((item) => {
      //         errors[item.errorField] = item.errorMessage[0];
      //         // alertMessage += item.errorMessage[0]+'\n';
      //         res.data.errorMessage = "";
      //       });
      //       this.setState({ errors: errors });
      //     }
      //     this.setState({
      //       showAlertModal: true,
      //       alertModalType: "error",
      //       alertModalTitle: res.data.message ? res.data.message : "Error!",
      //       alertModalMessage:
      //         alertMessage !== "" ? alertMessage : res.data.message,
      //     });
      //   }
      // }
      this.changeView();
      this.getPropertyData();
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    this.setState({ isPetChange: 1 });
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  changeHandlerStr = (event) => {
    this.setState({ isStrChange: 1 });
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
  };

  handleChangeselect = (index, event) => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    values[index].pet_type_slug = event.value;
    values[index].selected_pet_type = event;
    // values[index].event.target.name = event.target.value;
    this.setState({ petPolicyTypeData: values });
    this.setState({ pet_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChange = (index, event) => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];

    if (event.target.name === "pet_type_slug") {
      values[index].pet_type_slug = event.target.value;
      this.setState({ petPolicyTypeData: values });
    } else if (event.target.name === "allowed_pet_number") {
      values[index].allowed_pet_number = event.target.value
        .replace(/\.00$/, "")
        .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "");
    } else if (event.target.name === "deposite_amount_per_pet") {
      values[index].deposite_amount_per_pet = event.target.value
        .replace(/\.00$/, "")
        .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "");
    } else if (event.target.name === "one_time_fee_per_type") {
      values[index].one_time_fee_per_type = event.target.value
        .replace(/\.00$/, "")
        .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "");
    } else if (event.target.name === "rent_per_type") {
      values[index].rent_per_type = event.target.value
        .replace(/\.00$/, "")
        .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "");
    } else if (event.target.name === "weight_per_type") {
      values[index].weight_per_type = event.target.value
        .replace(/\.00$/, "")
        .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "");
    }
    this.setState({ pet_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeMulti(value) {
    this.setState({ isPetChange: 1 });
    let pet_type_not_allowed = [];
    var selectedPetTypeListTotal = 0;
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        pet_type_not_allowed.push(item.value);
      });
    }
    selectedPetTypeListTotal = pet_type_not_allowed.length;
    this.setState({
      selectedPetTypeList: value,
      pet_type_not_allowed: pet_type_not_allowed,
      selectedPetTypeListTotal: selectedPetTypeListTotal,
    });
    this.setState({ confirm_back: 1 });
  }

  handleAddFields = () => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    var petPolicyTypeDataTotal = 1;
    values.push({
      pet_type_slug: "",
      allowed_pet_number: "",
      one_time_fee_per_type: "",
      deposite_amount_per_pet: "",
      rent_per_type: "",
      weight_per_type: "",
      policy_slug: "",
    });
    petPolicyTypeDataTotal = values.length;

    this.setState({
      petPolicyTypeData: values,
      petPolicyTypeDataTotal: petPolicyTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFields = (i) => {
    this.setState({ isPetChange: 1 });
    const values = [...this.state.petPolicyTypeData];
    values.splice(i, 1);
    var petPolicyTypeDataTotal = 0;
    petPolicyTypeDataTotal = values.length;
    this.setState({ petPolicyTypeData: values, pet_data: values });
    this.setState({
      petPolicyTypeDataTotal: petPolicyTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  //for cost
  handleAddFieldsCost = () => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];
    var FeeTypeDataTotal = 1;
    values.push({
      cost_type_slug: "",
      cost_payable_by_slug: "",
      cost_payable_at_slug: "",
      cost_name: "",
      refundable: 0,
      estimated: 0,
      mandatory: 0,
    });
    FeeTypeDataTotal = values.length;

    this.setState({
      FeeTypeData: values,
      FeeTypeDataTotal: FeeTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFieldsCost = (i) => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];
    values.splice(i, 1);
    var FeeTypeDataTotal = 0;
    FeeTypeDataTotal = values.length;
    this.setState({ FeeTypeData: values, cost_data: values });
    this.setState({
      FeeTypeDataTotal: FeeTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectOne = (index, event) => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];

    values[index].cost_type_slug = event.value;
    values[index].selected_cost_type = {
      label: event.label,
      value: event.value,
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectTwo = (index, event) => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];
    values[index].cost_payable_at_slug = event.value;
    values[index].selected_cost_at = {
      label: event.label,
      value: event.value,
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeSelectThree = (index, event) => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];
    values[index].cost_payable_by_slug = event.value;
    values[index].selected_cost_by = {
      label: event.label,
      value: event.value,
    };

    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeCost = (index, event) => {
    this.setState({ isFeeChange: 1 });
    const values = [...this.state.FeeTypeData];

    if (event.target.name === "cost_name") {
      values[index].cost_name = event.target.value
        .replace(/\.00$/, "")
        .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "");
    }
    if (event.target.name === "refundable") {
      values[index].refundable = event.target.checked ? 1 : 0;
    }
    if (event.target.name === "estimated") {
      values[index].estimated = event.target.checked ? 1 : 0;
    }
    if (event.target.name === "mandatory") {
      values[index].mandatory = event.target.checked ? 1 : 0;
    }
    this.setState({ FeeTypeData: values });
    this.setState({ cost_data: values });
    this.setState({ confirm_back: 1 });
  };

  // async getSTRData() {
  //   // this.setState({ showSpinner: true, loading: true });
  //   var resData = {};

  //   let res = await PropertyCostService.getSTRFees(
  //     "slug=" + this.state.property_slug + "&ref_table_id=3"
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     resData.pet_fee_for_guest = res.data ? res.data.fee_value : "";
  //   }
  //   this.setState(resData);
  //   //   this.setState({ showSpinner: false, loading: false });
  // }

  customValidate() {
    this.setState({ isPetChange: 1 });
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.custom_pet_policy !== undefined &&
      input.custom_pet_policy !== "" &&
      input.custom_pet_policy !== null &&
      input.custom_pet_policy.length > 100000
    ) {
      isValid = false;
      errors["custom_pet_policy"] =
        "The custom pet policy character limit has been exceed.";
    }
    if (
      input.pet_policy_restrictions !== undefined &&
      input.pet_policy_restrictions !== "" &&
      input.pet_policy_restrictions !== null &&
      input.pet_policy_restrictions.length > 100000
    ) {
      isValid = false;
      errors["pet_policy_restrictions"] =
        "The pet policy restrictions character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.viewMode !== 0) {
      return "Fees";
    } else {
      return "Fees";
    }
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.getCost();
      this.getPetPolicyData();
      // this.getSTRData();
      this.setState({ isFeeChange: 0, isPetChange: 0, isStrChange: 0 });
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ isFeeChange: 0, isPetChange: 0, isStrChange: 0 });
      this.getCost();
      this.getPetPolicyData();
      // this.getSTRData();
      this.getPetTypeData();
      this.getDefaultPetData();
      this.getCostTypeData();
      // this.getSelectedCostType();
      this.getCostPayableAtData();
      this.getCostPayableByData();
    }
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  {/* <PropertyViewSidebar /> */}
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      {this.state.viewMode === 0 ? (
                        <>
                          {global.userPermissions.checkPermission(
                            "property-fees-update"
                          ) ? (
                            <button
                              className="btn-success-outline-small"
                              onClick={this.changeView}
                            >
                              <img src={editIcon} alt="edit" /> Edit
                            </button>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={this.changeView}
                          >
                            Cancel
                          </button>
                          {global.userPermissions.checkPermission(
                            "property-fees-update"
                          ) ? (
                            <button
                              className="btn-success ml-3"
                              type="button"
                              onClick={this.saveOrUpdatePetPolicy}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit ? global.loader : "Save"}
                            </button>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-0 propfees ">
                    {/* view mode  */}
                    {this.state.viewMode === 0 ? (
                      <div className="col-md-12 pl-0 pr-0">
                        {/* {this.state.total_record > 0 || this.state.FeeTypeDataTotal > 0 ? (
                      <> */}
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>
                        {/* general fees view */}
                        <div className="mg-t-18 brandList p-0">
                          <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-t-15">
                            <h3 className="mg-l-0 background-head">General</h3>
                          </div>
                          <div className="pd-lg-l-0 pd-xs-l-10">
                            <div
                              className="as-react-table pd-l-30 pd-r-30 m-0"
                              id="as-react-datatable-container"
                            >
                              <div
                                className="row table-body asrt-table-body m-0"
                                id="as-react-datatable-table-body"
                              >
                                <div className="col-md-12 petFeeSection">
                                  <table
                                    className="table table-hoverno-wrap mg-b-30 pd-b-2 pet_detail_mb"
                                    id="as-react-datatable"
                                  >
                                    <thead>
                                      <tr>
                                        <th className=" text-" width="">
                                          Fee Type
                                        </th>
                                        <th className=" text-" width="">
                                          Payable By
                                        </th>
                                        <th className=" text-" width="">
                                          Payable At
                                        </th>
                                        <th className=" text-" width="">
                                          Fee
                                        </th>
                                        <th className=" text-" width="">
                                          Refundable
                                        </th>
                                        <th className=" text-" width="">
                                          Estimated
                                        </th>
                                        <th
                                          className=" text- text-left"
                                          width=""
                                        >
                                          Mandatory
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.fees_total_record > 0 ? (
                                        this.state.fees_records.map(
                                          (option, i) => (
                                            <tr key={i}>
                                              <td>
                                                {option.cost_type
                                                  ? option.cost_type
                                                      .cost_type_name
                                                  : ""}
                                              </td>
                                              <td>
                                                {option.cost_payable_by
                                                  ? option.cost_payable_by
                                                      .cost_payable_by_name
                                                  : ""}
                                              </td>
                                              <td>
                                                {option.cost_payable_at
                                                  ? option.cost_payable_at
                                                      .cost_payable_at_name
                                                  : ""}
                                              </td>
                                              <td>
                                                {option.cost_name
                                                  ? "$" +
                                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                                      parseFloat(
                                                        option.cost_name
                                                      )
                                                        .toFixed(2)
                                                        .replace(/\.00$/, "")
                                                        .replace(
                                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                          ""
                                                        )
                                                    )
                                                  : ""}
                                              </td>
                                              <td>
                                                {option.refundable === 1
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                              <td>
                                                {option.estimated === 1
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                              <td className="text-left">
                                                {option.mandatory === 1
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="9" align="center">
                                            There are no fees added in the
                                            system. You may add them by clicking
                                            Edit button.
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-t-15">
                          <h3 className="mg-l-0 background-head">
                            Parking Fee
                          </h3>
                        </div>
                        <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                          <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                            <p className="az-profile-name-text pl-0">
                              Parking Fee Per Night
                            </p>
                            <p className="media-body">
                              {this.state.parking_fee ? (
                                <NumberFormat
                                  value={global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(this.state.parking_fee)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                      .replace(
                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                        ""
                                      )
                                  )}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div> */}
                        {this.state.pet_allowed === 1 ? (
                          <>
                            {/* pet fees view */}
                            <div className="mg-t-18 brandList p-0">
                              {/* <hr className="mg-b-30 pd-b-2" /> */}
                              <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                                <h3 className="mg-l-0 background-head">Pet</h3>
                              </div>
                              <div className="pd-lg-l-0 pd-xs-l-10">
                                <div
                                  className="as-react-table pd-lg-l-30 pd-lg-r-30"
                                  id="as-react-datatable-container"
                                >
                                  <div
                                    className="row table-body asrt-table-body m-0"
                                    id="as-react-datatable-table-body"
                                  >
                                    <div className="col-md-12 petFeeSection">
                                      <table
                                        className="table table-hoverno-wrap mg-b-15 pd-b-2 pet_detail_mb"
                                        id="as-react-datatable"
                                      >
                                        <thead>
                                          <tr>
                                            <th className=" text-" width="">
                                              Pet Type
                                            </th>
                                            <th className=" text-" width="">
                                              Max # Allowed
                                            </th>
                                            <th className=" text-" width="">
                                              Deposit
                                            </th>
                                            <th className=" text-" width="">
                                              One-Time Fee
                                            </th>
                                            <th className=" text-" width="">
                                              Monthly Rent
                                            </th>
                                            <th
                                              className=" text- text-left"
                                              width=""
                                            >
                                              Max # Weight
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.total_record > 0 ? (
                                            this.state.records.map(
                                              (option, i) => (
                                                <tr key={i}>
                                                  <td>
                                                    {option.pet_type
                                                      ? option.pet_type
                                                          .pet_type_name
                                                      : ""}
                                                  </td>
                                                  <td>
                                                    {option.allowed_pet_number
                                                      ? option.allowed_pet_number
                                                      : ""}
                                                  </td>
                                                  <td>
                                                    {option.deposite_amount_per_pet ? (
                                                      <NumberFormat
                                                        value={global.onKeyPressEvent.numberWithCommasForDecimal(
                                                          parseFloat(
                                                            option.deposite_amount_per_pet
                                                          )
                                                            .toFixed(2)
                                                            .replace(
                                                              /\.00$/,
                                                              ""
                                                            )
                                                            .replace(
                                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                              ""
                                                            )
                                                        )}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        prefix={"$"}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                  <td>
                                                    {option.one_time_fee_per_type ? (
                                                      <NumberFormat
                                                        value={global.onKeyPressEvent.numberWithCommasForDecimal(
                                                          parseFloat(
                                                            option.one_time_fee_per_type
                                                          )
                                                            .toFixed(2)
                                                            .replace(
                                                              /\.00$/,
                                                              ""
                                                            )
                                                            .replace(
                                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                              ""
                                                            )
                                                        )}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        prefix={"$"}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                  <td>
                                                    {option.rent_per_type ? (
                                                      <NumberFormat
                                                        value={global.onKeyPressEvent.numberWithCommasForDecimal(
                                                          parseFloat(
                                                            option.rent_per_type
                                                          )
                                                            .toFixed(2)
                                                            .replace(
                                                              /\.00$/,
                                                              ""
                                                            )
                                                            .replace(
                                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                              ""
                                                            )
                                                        )}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        prefix={"$"}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                  <td className="text-left">
                                                    {option.weight_per_type
                                                      ? parseFloat(
                                                          option.weight_per_type
                                                        )
                                                          .toFixed(2)
                                                          .replace(/\.00$/, "")
                                                          .replace(
                                                            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                            ""
                                                          ) + " lbs"
                                                      : ""}
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td colSpan="9" align="center">
                                                There are no fees added in the
                                                system. You may add them by
                                                clicking Edit button.
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                              <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                                <p className="az-profile-name-text pl-0">
                                  Prohibited Pet Types
                                </p>
                                <p className="media-body">
                                  {this.state.multiListTotal > 0
                                    ? this.state.multiList.map(
                                        (option, i) =>
                                          option.pet_type_name +
                                          (i < this.state.multiListTotal - 1
                                            ? ", "
                                            : "") +
                                          ""
                                      )
                                    : ""}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                              <div className="col-md-12  pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                                <p className="az-profile-name-text pl-0">
                                  Restrictions
                                </p>
                                <div className="para">
                                  {this.state.pet_policy_restrictions ? (
                                    <p className="p_wrap1">
                                      {this.state.descShow
                                        ? this.state.pet_policy_restrictions.slice(
                                            0,
                                            global.showMoreLength
                                          )
                                        : this.state.pet_policy_restrictions}

                                      {this.state.pet_policy_restrictions
                                        .length > global.showMoreLength && (
                                        <span onClick={this.fullText}>
                                          {this.state.descShow ? (
                                            <p className="hand-cursor showmore">
                                              ...Show more{" "}
                                            </p>
                                          ) : (
                                            <p className="hand-cursor showmore">
                                              Show Less{" "}
                                            </p>
                                          )}
                                        </span>
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                              <div className="col-md-12  pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                                <p className="az-profile-name-text pl-0">
                                  Pet Policy
                                </p>
                                <div className="para">
                                  {this.state.custom_pet_policy ? (
                                    <p className="p_wrap1">
                                      {this.state.descShow
                                        ? this.state.custom_pet_policy.slice(
                                            0,
                                            global.showMoreLength
                                          )
                                        : this.state.custom_pet_policy}

                                      {this.state.custom_pet_policy.length >
                                        global.showMoreLength && (
                                        <span onClick={this.fullText}>
                                          {this.state.descShow ? (
                                            <p className="hand-cursor showmore">
                                              ...Show more{" "}
                                            </p>
                                          ) : (
                                            <p className="hand-cursor showmore">
                                              Show Less{" "}
                                            </p>
                                          )}
                                        </span>
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <>
                        {/* edit mode  */}

                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>
                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 pet-f pd-lg-t-15">
                          <h3 className="mg-t-0 mg-l-0 background-head">
                            General
                          </h3>
                          <div className="pet-f mg-b-30 pd-l-0 pd-r-0">
                            <div className="col-md-12 p-0 petFeeSection">
                              <table className="petForm">
                                <thead>
                                  <tr>
                                    <th className="w-1">
                                      <label className="mb-1">Fee Type</label>
                                    </th>
                                    <th className="w-1">
                                      <label className="mb-1">Payable By</label>
                                    </th>
                                    <th className="w-1">
                                      <label className="mb-1">Payable At</label>
                                    </th>
                                    <th className="W-2">
                                      <label className="mb-1">Fee</label>
                                    </th>
                                    <th className="w-3">
                                      <label className="mb-1">Refundable</label>
                                    </th>
                                    <th className="w-3">
                                      <label className="mb-1">Estimated</label>
                                    </th>
                                    <th className="w-3">
                                      <label className="mb-1">Mandatory</label>
                                    </th>
                                    <th className="w-3"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.FeeTypeData.map((data, i) => {
                                    return (
                                      <tr key={i}>
                                        <td className="w-1">
                                          {" "}
                                          <Col
                                            xs={12}
                                            className="pd-l-0 pd-r-2 colmn1"
                                          >
                                            <InputGroup className="mt-0 m-b-1">
                                              {/* <label>Pet Type</label> */}
                                              <div className="col-md-12 p-0">
                                                <Select
                                                  styles={customStyles}
                                                  className="multiselect"
                                                  classNamePrefix={
                                                    "my-custom-react-select"
                                                  }
                                                  menuPlacement="auto"
                                                  onChange={(value) =>
                                                    this.handleChangeSelectOne(
                                                      i,
                                                      value
                                                    )
                                                  }
                                                  options={
                                                    this.state.newCostTypeList
                                                  }
                                                  //   this.state.newCostTypeList.length > 0
                                                  //     ? this.state.newCostTypeList
                                                  //     .filter(
                                                  //         (option) => {
                                                  //           const val2 = this.state
                                                  //             .selectedCostTypeList
                                                  //             ? this.state.selectedCostTypeList.find(
                                                  //                 (selOpt) => {
                                                  //                   if (
                                                  //                     selOpt ===
                                                  //                     data.cost_type_slug
                                                  //                   )
                                                  //                     return false;
                                                  //                   return (
                                                  //                     selOpt ===
                                                  //                     option.value
                                                  //                   );
                                                  //                 }
                                                  //               )
                                                  //             : "";
                                                  //           if (val2) return false;
                                                  //           else return true;
                                                  //         }
                                                  //       )
                                                  //     : []
                                                  // }
                                                  theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 2,
                                                    colors: {
                                                      ...theme.colors,
                                                      primary: "#fff",
                                                      primary75: "#000",
                                                      primary50: "#000",
                                                      primary25: "#000",
                                                    },
                                                  })}
                                                  value={
                                                    data.selected_cost_type
                                                  }
                                                  defaultValue={
                                                    data.selected_cost_type
                                                  }
                                                />
                                              </div>
                                            </InputGroup>
                                          </Col>
                                        </td>
                                        <td className="w-1">
                                          <Col
                                            xs={12}
                                            className="pd-l-2 pd-r-2 colmn1"
                                          >
                                            <Select
                                              styles={customStyles}
                                              menuPlacement="auto"
                                              className="multiselect"
                                              classNamePrefix={
                                                "my-custom-react-select"
                                              }
                                              name="cost_payable_by_slug"
                                              value={data.selected_cost_by}
                                              options={
                                                this.state.newCostPayableByList
                                              }
                                              defaultValue={
                                                data.selected_cost_by
                                              }
                                              onChange={(value) =>
                                                this.handleChangeSelectThree(
                                                  i,
                                                  value
                                                )
                                              }
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 2,
                                                colors: {
                                                  ...theme.colors,
                                                  primary: "#fff",
                                                  primary75: "#000",
                                                  primary50: "#000",
                                                  primary25: "#000",
                                                },
                                              })}
                                            />
                                          </Col>
                                        </td>
                                        <td className="w-1">
                                          <Col
                                            xs={12}
                                            className="pd-l-2 pd-r-2 colmn1"
                                          >
                                            <Select
                                              styles={customStyles}
                                              menuPlacement="auto"
                                              className="multiselect"
                                              classNamePrefix={
                                                "my-custom-react-select"
                                              }
                                              name="cost_payable_at_slug"
                                              value={data.selected_cost_at}
                                              options={
                                                this.state.newCostPayableAtList
                                              }
                                              defaultValue={
                                                data.selected_cost_at
                                              }
                                              onChange={(value) =>
                                                this.handleChangeSelectTwo(
                                                  i,
                                                  value
                                                )
                                              }
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 2,
                                                colors: {
                                                  ...theme.colors,
                                                  primary: "#fff",
                                                  primary75: "#000",
                                                  primary50: "#000",
                                                  primary25: "#000",
                                                },
                                              })}
                                            />
                                          </Col>
                                        </td>
                                        <td className="w-2">
                                          {" "}
                                          <Col
                                            xs={12}
                                            className="pd-l-2 pd-r-2 colmn3"
                                          >
                                            <InputGroup className="mt-0 m-b-1">
                                              <div className="input-group-prepend prefix-dollar">
                                                <span className="input-group-text">
                                                  $
                                                </span>
                                                <Form.Control
                                                  // placeholder="Fee ($)"
                                                  name="cost_name"
                                                  onKeyPress={
                                                    global.onKeyPressEvent
                                                      .floatValidation
                                                  }
                                                  value={
                                                    data.cost_name
                                                      ? data.cost_name
                                                          .replace(/\.00$/, "")
                                                          .replace(
                                                            /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                            ""
                                                          ) || ""
                                                      : ""
                                                  }
                                                  onChange={(event) =>
                                                    this.handleChangeCost(
                                                      i,
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            </InputGroup>
                                          </Col>
                                        </td>
                                        <td className="w-3">
                                          {" "}
                                          <Col
                                            xs={12}
                                            className="pd-l-5 pd-r-5 pd-t-2 align-items-center colmn2"
                                          >
                                            {/* <div className="ckbox form-check"> */}
                                            {/* <input
                                              type="checkbox"
                                              id={`refundable`}
                                              name="refundable"
                                              className="form-check-input ml-0 w-auto"
                                              onChange={(event) =>
                                                this.handleChangeCost(i, event)
                                              }
                                              checked={data.refundable}
                                            />
                                            <label
                                              htmlFor={`refundable`}
                                              className="form-check-label"
                                            ></label> */}

                                            <div className="ckbox form-check">
                                              <input
                                                type="checkbox"
                                                id={`refundable_` + i}
                                                name="refundable"
                                                className="form-check-input ml-0 w-auto"
                                                onChange={(event) =>
                                                  this.handleChangeCost(
                                                    i,
                                                    event
                                                  )
                                                }
                                                checked={data.refundable}
                                              />
                                              <label
                                                title=""
                                                htmlFor={`refundable_` + i}
                                                className="form-check-label"
                                              ></label>
                                            </div>

                                            {/* </div> */}
                                          </Col>
                                        </td>
                                        <td className="w-3">
                                          {" "}
                                          <Col
                                            xs={12}
                                            className="pd-l-5 pd-r-5 pd-t-2 colmn2"
                                          >
                                            <div className="ckbox form-check">
                                              <input
                                                type="checkbox"
                                                id={`estimated_` + i}
                                                name="estimated"
                                                className="form-check-input ml-0 w-auto"
                                                onChange={(event) =>
                                                  this.handleChangeCost(
                                                    i,
                                                    event
                                                  )
                                                }
                                                checked={data.estimated}
                                              />
                                              <label
                                                htmlFor={`estimated_` + i}
                                                className="form-check-label"
                                              ></label>
                                            </div>
                                          </Col>
                                        </td>
                                        <td className="w-3">
                                          {" "}
                                          <Col
                                            xs={12}
                                            className="pd-l-2 pd-r-2 pd-t-2 colmn2"
                                          >
                                            <div className="ckbox form-check">
                                              <input
                                                type="checkbox"
                                                id={`mandatory_` + i}
                                                name="mandatory"
                                                className="form-check-input ml-0 w-auto"
                                                onChange={(event) =>
                                                  this.handleChangeCost(
                                                    i,
                                                    event
                                                  )
                                                }
                                                checked={data.mandatory}
                                              />
                                              <label
                                                htmlFor={`mandatory_` + i}
                                                className="form-check-label"
                                              ></label>
                                            </div>
                                          </Col>
                                        </td>
                                        <td className="w-3">
                                          <Col
                                            xs={12}
                                            className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                                          >
                                            {this.state.FeeTypeDataTotal ===
                                              1 && i === 0 ? (
                                              <>
                                                {data.slug ? (
                                                  <div className="d-flex  justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                                    <button
                                                      onClick={() => {
                                                        this.handleRemoveFieldsCost(
                                                          i
                                                        );

                                                        this.deleteCost(
                                                          data.slug
                                                        );
                                                      }}
                                                      className={
                                                        "btn-danger-outline-x-small"
                                                      }
                                                    >
                                                      <img
                                                        src={deleteicon}
                                                        alt="Delete"
                                                      />
                                                    </button>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                  <button
                                                    onClick={
                                                      this.handleAddFieldsCost
                                                    }
                                                    className="btn-success-outline-x-small"
                                                  >
                                                    <img
                                                      src={pluswIcon}
                                                      alt="Add"
                                                    />
                                                  </button>
                                                </div>
                                              </>
                                            ) : i <
                                              this.state.FeeTypeDataTotal -
                                                1 ? (
                                              <>
                                                <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                  <button
                                                    onClick={() => {
                                                      this.handleRemoveFieldsCost(
                                                        i
                                                      );

                                                      this.deleteCost(
                                                        data.slug
                                                      );
                                                    }}
                                                    className={
                                                      "btn-danger-outline-x-small"
                                                    }
                                                  >
                                                    <img
                                                      src={deleteicon}
                                                      alt="Delete"
                                                    />
                                                  </button>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {this.state.FeeTypeDataTotal !==
                                                0 ? (
                                                  <>
                                                    <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                      <button
                                                        onClick={() => {
                                                          this.handleRemoveFieldsCost(
                                                            i
                                                          );

                                                          this.deleteCost(
                                                            data.slug
                                                          );
                                                        }}
                                                        className={
                                                          "btn-danger-outline-x-small"
                                                        }
                                                      >
                                                        <img
                                                          src={deleteicon}
                                                          alt="Delete"
                                                        />
                                                      </button>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                  <button
                                                    onClick={
                                                      this.handleAddFieldsCost
                                                    }
                                                    className="btn-success-outline-x-small"
                                                  >
                                                    <img
                                                      src={pluswIcon}
                                                      alt="Add"
                                                    />
                                                  </button>
                                                </div>
                                              </>
                                            )}
                                          </Col>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          {/* <h3 className="mg-l-0 background-head">
                            Parking Fee
                          </h3>

                          <div className="col-md-12 mg-t-40 mg-md-t-0 pd-l-0 pd-lg-r-15 ">
                            <div className="col-md-3 col-lg-3 mg-t-5 mg-md-t-0 pl-0">
                              <InputGroup className="mt-3">
                                <label className="form-label text-left pd-b-5 w-100">
                                  Parking Fee Per Night
                                </label>
                                <div className="input-group-prepend prefix-dollar w-100">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="parking_fee"
                                    value={this.state.parking_fee}
                                    className="form-control max_width_100 w-100"
                                    id="parking_fee"
                                  />
                                </div>
                              </InputGroup>
                              {this.state.errors.parking_fee ? (
                                <div className="text-danger">
                                  {this.state.errors.parking_fee}
                                </div>
                              ) : (
                                this.validator.message(
                                  "parking_fee",
                                  this.state.parking_fee,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div> */}

                          {this.state.pet_allowed === 1 ? (
                            <>
                              <h3 className="mg-t-0 mg-l-0 background-head">
                                Pet
                              </h3>
                              <div className="pd-l-0 pd-r-0 pet-f mg-b-30 pet">
                                <div className="col-md-12 p-0 petFeeSection">
                                  <table className="petForm">
                                    <thead>
                                      <tr>
                                        <th className="w-1">
                                          <label className="mb-1">
                                            Pet Type
                                          </label>
                                        </th>
                                        <th className="w-1">
                                          <label className="mb-1">
                                            Max # Allowed
                                          </label>
                                        </th>
                                        <th className="w-2">
                                          <label className="mb-1">
                                            Deposit
                                          </label>
                                        </th>
                                        <th className="w-2">
                                          <label className="mb-1">
                                            One-Time Fee
                                          </label>
                                        </th>
                                        <th className="w-2">
                                          <label className="mb-1">
                                            Monthly Rent
                                          </label>
                                        </th>
                                        <th className="w-2">
                                          <label className="mb-1">
                                            Max # Weight
                                          </label>
                                        </th>
                                        <th className="w-3"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.petPolicyTypeData.map(
                                        (data, i) => {
                                          return (
                                            <tr key={i}>
                                              <td className="w-1">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-2 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                    {/* <label>Pet Type</label> */}
                                                    <div className="col-md-12 p-0">
                                                      <Select
                                                        styles={customStyles}
                                                        className="multiselect"
                                                        classNamePrefix={
                                                          "my-custom-react-select"
                                                        }
                                                        menuPlacement="auto"
                                                        onChange={(value) =>
                                                          this.handleChangeselect(
                                                            i,
                                                            value
                                                          )
                                                        }
                                                        options={
                                                          this.state
                                                            .petTypeListTotal >
                                                          0
                                                            ? this.state.petTypeListDropdown
                                                                .filter(
                                                                  (option) => {
                                                                    const val1 =
                                                                      this.state
                                                                        .petPolicyTypeData
                                                                        ? this.state.petPolicyTypeData.find(
                                                                            (
                                                                              opt
                                                                            ) => {
                                                                              if (
                                                                                option.value ===
                                                                                data.pet_type_slug
                                                                              )
                                                                                return false;
                                                                              return (
                                                                                opt.pet_type_slug ===
                                                                                option.value
                                                                              );
                                                                            }
                                                                          )
                                                                        : "";
                                                                    if (val1)
                                                                      return false;
                                                                    else
                                                                      return true;
                                                                  }
                                                                )
                                                                .filter(
                                                                  (option) => {
                                                                    const val2 =
                                                                      this.state
                                                                        .selectedPetTypeList
                                                                        ? this.state.selectedPetTypeList.find(
                                                                            (
                                                                              selOpt
                                                                            ) => {
                                                                              if (
                                                                                selOpt.value ===
                                                                                data.pet_type_slug
                                                                              )
                                                                                return false;
                                                                              return (
                                                                                selOpt.value ===
                                                                                option.value
                                                                              );
                                                                            }
                                                                          )
                                                                        : "";
                                                                    if (val2)
                                                                      return false;
                                                                    else
                                                                      return true;
                                                                  }
                                                                )
                                                            : []
                                                        }
                                                        getOptionValue={(
                                                          option
                                                        ) => `${option.label}`}
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                        value={
                                                          data.selected_pet_type
                                                            ? data.selected_pet_type
                                                            : []
                                                        }
                                                        defaultValue={
                                                          data.selected_pet_type
                                                            ? data.selected_pet_type
                                                            : []
                                                        }
                                                      />
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-1">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-2 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                    {/* <label>Maxs. Pets Allowed</label> */}
                                                    <Form.Control
                                                      // placeholder="Max # Allowed"
                                                      name="allowed_pet_number"
                                                      maxLength="2"
                                                      onKeyPress={
                                                        global.onKeyPressEvent
                                                          .numberValidation
                                                      }
                                                      value={
                                                        data.allowed_pet_number
                                                          ? data.allowed_pet_number ||
                                                            ""
                                                          : ""
                                                      }
                                                      onChange={(event) =>
                                                        this.handleChange(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-2">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-2 colmn3"
                                                >
                                                  <InputGroup className="mt-0">
                                                    {/* <label>Deposit Amount ($)</label> */}
                                                    <div className="input-group-prepend prefix-dollar w-100">
                                                      <span className="input-group-text">
                                                        $
                                                      </span>
                                                      <Form.Control
                                                        // placeholder="Deposit ($)"
                                                        onKeyPress={
                                                          global.onKeyPressEvent
                                                            .numberValidation
                                                        }
                                                        name="deposite_amount_per_pet"
                                                        value={
                                                          data.deposite_amount_per_pet
                                                            ? data.deposite_amount_per_pet
                                                                .replace(
                                                                  /\.00$/,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                                  ""
                                                                ) || ""
                                                            : ""
                                                        }
                                                        onChange={(event) =>
                                                          this.handleChange(
                                                            i,
                                                            event
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-2">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-2 colmn3"
                                                >
                                                  <InputGroup className="mt-0">
                                                    {/* <label>One Time Fee ($)</label> */}
                                                    <div className="input-group-prepend prefix-dollar w-100">
                                                      <span className="input-group-text">
                                                        $
                                                      </span>
                                                      <Form.Control
                                                        // placeholder="One-Time Fee ($)"
                                                        name="one_time_fee_per_type"
                                                        onKeyPress={
                                                          global.onKeyPressEvent
                                                            .numberValidation
                                                        }
                                                        value={
                                                          data.one_time_fee_per_type
                                                            ? data.one_time_fee_per_type
                                                                .replace(
                                                                  /\.00$/,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                                  ""
                                                                ) || ""
                                                            : ""
                                                        }
                                                        onChange={(event) =>
                                                          this.handleChange(
                                                            i,
                                                            event
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-2">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-2 colmn3"
                                                >
                                                  <InputGroup className="mt-0">
                                                    {/* <label>Rent ($)</label> */}
                                                    <div className="input-group-prepend prefix-dollar">
                                                      <span className="input-group-text">
                                                        $
                                                      </span>
                                                      <Form.Control
                                                        // placeholder="Monthly Rent ($)"
                                                        onKeyPress={
                                                          global.onKeyPressEvent
                                                            .numberValidation
                                                        }
                                                        name="rent_per_type"
                                                        value={
                                                          data.rent_per_type
                                                            ? data.rent_per_type
                                                                .replace(
                                                                  /\.00$/,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                                  ""
                                                                ) || ""
                                                            : ""
                                                        }
                                                        onChange={(event) =>
                                                          this.handleChange(
                                                            i,
                                                            event
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-3">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-0 d-flex"
                                                >
                                                  <InputGroup className="mt-0">
                                                    {/* <label>Weight (lbs)</label> */}
                                                    <div className="input-group-prepend prefix-dollar percanteg-prefix">
                                                      <Form.Control
                                                        // placeholder="Weight (lbs)"
                                                        onKeyPress={
                                                          global.onKeyPressEvent
                                                            .floatValidation
                                                        }
                                                        name="weight_per_type"
                                                        value={
                                                          data.weight_per_type
                                                            ? data.weight_per_type
                                                                .replace(
                                                                  /\.00$/,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                                  ""
                                                                ) || ""
                                                            : ""
                                                        }
                                                        onChange={(event) =>
                                                          this.handleChange(
                                                            i,
                                                            event
                                                          )
                                                        }
                                                      />
                                                      <span className="input-group-text percanteg_symbl">
                                                        lbs
                                                      </span>
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td>
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                                                >
                                                  {this.state
                                                    .petPolicyTypeDataTotal ===
                                                    1 && i === 0 ? (
                                                    <>
                                                      {data.policy_slug ? (
                                                        <div className="d-flex  justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                          <button
                                                            onClick={() => {
                                                              this.handleRemoveFields(
                                                                i
                                                              );

                                                              this.deletePetPolicy(
                                                                data.policy_slug
                                                              );
                                                            }}
                                                            className={
                                                              "btn-danger-outline-x-small"
                                                            }
                                                          >
                                                            <img
                                                              src={deleteicon}
                                                              alt="Delete"
                                                            />
                                                          </button>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                        <button
                                                          onClick={
                                                            this.handleAddFields
                                                          }
                                                          className="btn-success-outline-x-small"
                                                        >
                                                          <img
                                                            src={pluswIcon}
                                                            alt="Add"
                                                          />
                                                        </button>
                                                      </div>
                                                    </>
                                                  ) : i <
                                                    this.state
                                                      .petPolicyTypeDataTotal -
                                                      1 ? (
                                                    <>
                                                      <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                        <button
                                                          onClick={() => {
                                                            this.handleRemoveFields(
                                                              i
                                                            );

                                                            this.deletePetPolicy(
                                                              data.policy_slug
                                                            );
                                                          }}
                                                          className={
                                                            "btn-danger-outline-x-small"
                                                          }
                                                        >
                                                          <img
                                                            src={deleteicon}
                                                            alt="Delete"
                                                          />
                                                        </button>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {this.state
                                                        .petPolicyTypeDataTotal !==
                                                      0 ? (
                                                        <>
                                                          <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                            <button
                                                              onClick={() => {
                                                                this.handleRemoveFields(
                                                                  i
                                                                );

                                                                this.deletePetPolicy(
                                                                  data.policy_slug
                                                                );
                                                              }}
                                                              className={
                                                                "btn-danger-outline-x-small"
                                                              }
                                                            >
                                                              <img
                                                                src={deleteicon}
                                                                alt="Delete"
                                                              />
                                                            </button>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                        <button
                                                          onClick={
                                                            this.handleAddFields
                                                          }
                                                          className="btn-success-outline-x-small"
                                                        >
                                                          <img
                                                            src={pluswIcon}
                                                            alt="Add"
                                                          />
                                                        </button>
                                                      </div>
                                                    </>
                                                  )}
                                                </Col>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>

                                <div className="m-0 mt-5 petFeeSection">
                                  <div className="row row-xs align-items-center pd-b-15 border-bottom-0">
                                    <div className="col-md-4 col-lg-4 mg-t-5 mg-md-t-0 pl-0">
                                      <label className="form-label text-left pd-b-5">
                                        Prohibited Pet Types
                                      </label>
                                      <Select
                                        isMulti
                                        styles={customStyles}
                                        menuPlacement="auto"
                                        className="multiselect"
                                        classNamePrefix={
                                          "my-custom-react-select"
                                        }
                                        name="pet_type_not_allowed"
                                        value={this.state.selectedPetTypeList}
                                        defaultValue={
                                          this.state.selectedPetTypeList
                                        }
                                        onChange={(value) =>
                                          this.handleChangeMulti(value)
                                        }
                                        options={
                                          this.state.petTypeList
                                            ? Array.isArray(
                                                this.state.newPetTypeList
                                              ) &&
                                              this.state.newPetTypeList.filter(
                                                (opt) => {
                                                  const slug = opt.value;
                                                  const selectedPetType =
                                                    this.state.petPolicyTypeData.map(
                                                      (p) => p.pet_type_slug
                                                    );
                                                  if (
                                                    selectedPetType.indexOf(
                                                      slug
                                                    ) !== -1
                                                  )
                                                    return false;
                                                  return true;
                                                }
                                              )
                                            : ""
                                        }
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="row row-xs align-items-baseline pd-b-15 border-bottom-0">
                                    <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                                      <InputGroup className="mt-3">
                                        <label className="form-label text-left pd-b-5 w-100">
                                          Restrictions
                                        </label>

                                        <Form.Control
                                          onChange={this.changeHandler}
                                          name="pet_policy_restrictions"
                                          value={
                                            this.state.pet_policy_restrictions
                                          }
                                          className="form-control max_width_100 w-100"
                                          id="pet_policy_restrictions"
                                          as="textarea"
                                          rows={global.textareaRowLength}
                                          // placeholder="Username"
                                        />
                                      </InputGroup>
                                      {this.state.errors
                                        .pet_policy_restrictions ? (
                                        <div className="text-danger">
                                          {
                                            this.state.errors
                                              .pet_policy_restrictions
                                          }
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "pet_policy_restrictions",
                                          this.state.pet_policy_restrictions,
                                          "max:100000",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <div className="row row-xs align-items-baseline pd-b-15 border-bottom-0">
                                    <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                                      <InputGroup className="mt-3">
                                        <label className="form-label text-left pd-b-5 w-100">
                                          Pet Policy
                                        </label>
                                        <Form.Control
                                          onChange={this.changeHandler}
                                          name="custom_pet_policy"
                                          value={this.state.custom_pet_policy}
                                          className="form-control max_width_100 w-100"
                                          id="custom_pet_policy"
                                          as="textarea"
                                          rows={global.textareaRowLength}
                                          // placeholder="Username"
                                        />
                                      </InputGroup>
                                      {this.state.errors.custom_pet_policy ? (
                                        <div className="text-danger">
                                          {this.state.errors.custom_pet_policy}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "custom_pet_policy",
                                          this.state.custom_pet_policy,
                                          "max:100000",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default PropertyFees;

///////////////////////////////////////////////
//     							             //
//  Program: StaffService.js                 //
//  Application: Services                    //
//  Option: Used for staff  data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-02-13                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class StaffService {
  getStaff(queryString) {
    let endPoint =
      "staff-contact-info" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createStaff(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "staff-contact-info";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getStaffBySlug(slug) {
    let endPoint = "staff-contact-info/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateStaff(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-staff-contact-info/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteStaff(slug) {
    let endPoint = "staff-contact-info/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadStaffImage(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "upload-staff-contact-image";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  

}

export default new StaffService();

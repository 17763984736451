////////////////////////////////////////////////////////////
//     							                          //
//  Program: MasterDataService.js                            //
//  Application: Master Data                               //
//  Option: Used for Master Data                           //
//  Developer: Ashish Kumar  						      //
//  Date: 2022-01-06                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class MasterDataService {
  getMasterData(queryString) {
    let endPoint = "get-master-data"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getMasterDataBySlug(slug) {
    let endPoint = "get-master-data/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

}

export default new MasterDataService();

//     							                          //
//  Program: UnitSidebar.jsx                           //
//  Application: Unit                                 //
//  Option: common component Unit Sidebar Tabs        //
//  Developer: Ashish Kumar 		                                  //
//  Date: 2022-04-02                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";

class UnitSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  render() {
    return (
      <nav className="nav nav-pills flex-column pd-r-8">
        <ul>
          <li>
            <span>Information</span>
            <ul>
              <li>
                <Link
                  className={
                    this.isPathActive("unit-basic") ||
                    this.isPathActive("add") ||
                    this.isPathActive("update")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  data-toggle="tab"
                  to={
                    "/properties/view/" +
                    (this.props.property_slug
                      ? this.props.property_slug
                      : this.state.property_slug) +
                    "/units/" +
                    this.props.unit_slug +
                    "/unit-basic"
                  }
                >
                  Basics
                </Link>
              </li>
              {this.props.unit_slug !== undefined ? (
                <>
                  <li>
                    <Link
                      className={
                        this.isPathActive("unit-cost")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      to={
                        "/properties/view/" +
                        this.props.property_slug +
                        "/units/" +
                        this.props.unit_slug +
                        "/unit-cost"
                      }
                    >
                      Fees
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        this.isPathActive("unit-documents")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="tab"
                      to={
                        "/properties/view/" +
                        (this.props.property_slug
                          ? this.props.property_slug
                          : this.state.property_slug) +
                        "/units/" +
                        this.props.unit_slug +
                        "/unit-documents"
                      }
                    >
                      Documents
                    </Link>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
          </li>
        </ul>
      </nav>
    );
  }
}
export default UnitSidebar;

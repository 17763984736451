////////////////////////////////////////////////////////////
//     							                          //
//  Program: Resident.jsx                                 //
//  Application: Residents                                //
//  Option: List all Residents                            //
//  Developer: NP				                          //
//  Date: 2022-05-20                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import ResidentService from "../../services/ResidentService";
import UnitsService from "../../services/UnitsService";
import { Container, Tab, Nav, Modal, InputGroup, Form } from "react-bootstrap";
import filterIcon from "./../../assets/images/filter-gray.svg";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Alerts from "../common/Alerts";
import DatePicker from "react-datepicker";
import LeasingSidebar from "../common/LeasingSidebar";
import queryString from "query-string";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { FileUploader } from "react-drag-drop-files";
import NoDataSearch from "../common/NoDataSearch";
import Moment from "moment";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";

let queryParams = queryString.parse(window.location.search);

export class Resident extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      errors: {},
      importModal: false,
      file_url: "",
      file_name: "",
      tableQueryString: "",
      customQueryString: "",
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      filter_value: "",
      newUnitList: [],
      selectedUnitList: [],
      module_search: "",
      is_search: 0,
      isSearch: global.isSearch,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      tab: 1,
      residentTabNumber: localStorage.getItem("resident_tab_number")
        ? parseInt(localStorage.getItem("resident_tab_number"))
        : 1,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_number",
          text: "Unit",
          className: "unit_number hand-cursor",
          sortable: true,
          cell: (record) => {
            return record && record.unit_name
              ? record.unit_name
              : record && record.unit_number
              ? record.unit_number
              : "";
          },
        },
        {
          key: "first_name",
          text: "First Name",
          className: "first_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.first_name ? record.first_name : "";
          },
        },
        {
          key: "last_name",
          text: "Last Name",
          className: "last_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.last_name ? record.last_name : "";
          },
        },
        {
          key: "phone_number",
          text: "Phone",
          className: "phone_number hand-cursor",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber = record.phone_number
              ? record.phone_number.replace(/[\s-]/g, "")
              : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits = record.phone_number ? record.phone_number : [];
            }

            var number = record.phone_number
              ? lastTenDigits.trim().replace(/[^0-9]/g, "")
              : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.phone_number ? finalnumber : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "lease_start_date",
          text: "Lease Start Date",
          className: "lease_start_date hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.lease_start_date
              ? Moment(record.lease_start_date).format(global.dateFormat)
              : "";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.confirmImport = this.confirmImport.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      // document.getElementById('current').click();
      switch (this.state.residentTabNumber) {
        case 2:
          document.getElementById("upcoming").click();
          break;
        case 3:
          document.getElementById("past").click();
          break;
        case 4:
          document.getElementById("all").click();
          break;
        default:
          document.getElementById("current").click();
      }
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      switch (this.state.residentTabNumber) {
        case 2:
          document.getElementById("upcoming").click();
          break;
        case 3:
          document.getElementById("past").click();
          break;
        case 4:
          document.getElementById("all").click();
          break;
        default:
          document.getElementById("current").click();
      }
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    this.setState({ is_search: 0 });
    // this.setState({first_name:"",last_name:"",email:"",phone_number:""});
    this.setState({ module_search: "" });
    if (e === "current") {
      this.setState({ tab: 1 });
      this.getData(1);
      setTimeout(() => {
        localStorage.setItem(
          "resident_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "upcoming") {
      this.setState({ tab: 2 });
      this.getData(2);
      setTimeout(() => {
        localStorage.setItem(
          "resident_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "past") {
      this.setState({ tab: 3 });
      this.getData(3);
      setTimeout(() => {
        localStorage.setItem(
          "resident_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "all") {
      this.setState({ tab: 4 });
      this.getData(4);
      setTimeout(() => {
        localStorage.setItem(
          "resident_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else {
      this.setState({ tab: 1 });
      this.getData(1);
      setTimeout(() => {
        localStorage.setItem(
          "resident_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      lease_start_date: "",
      resident_name: "",
      email: "",
      phone_number: "",
      city_name: "",
      state_name: "",
      country_name: "",
      zip_code: "",
      selectedUnitList: [],
    });
    this.setState({ module_search: "" });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let leaseStart = this.state.lease_start_date
      ? Moment(this.state.lease_start_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&resident_name=" +
      (this.state.resident_name ? this.state.resident_name : "") +
      // "&last_name=" +
      // (this.state.last_name ? this.state.last_name : '') +
      "&email=" +
      (this.state.email ? this.state.email : "") +
      "&city_name=" +
      (this.state.city_name ? this.state.city_name : "") +
      "&state_name=" +
      (this.state.state_name ? this.state.state_name : "") +
      "&country_name=" +
      (this.state.country_name ? this.state.country_name : "") +
      "&zip_code=" +
      (this.state.zip_code ? this.state.zip_code : "") +
      "&phone_number=" +
      (this.state.phone_number ? this.state.phone_number : "") +
      "&lease_start_date=" +
      leaseStart +
      "&is_search=1";
    // let moduleSearchString =
    //   (this.state.resident_name ? "resident name - " + this.state.resident_name : '') +
    //   // (this.state.last_name ? " & last name - " + this.state.last_name : '') +
    //   (this.state.phone_number ? " & phone number - " + this.state.phone_number : '') +
    //   (this.state.email ? " & email - " + this.state.email : '') +
    //   (this.state.city_name ? " & city - " + this.state.city_name : '') +
    //   (this.state.state_name ? " & state - " + this.state.state_name : '') +
    //   (this.state.country_name ? " & country - " + this.state.country_name : '') +
    //   (this.state.zip_code ? " & zipcode - " + this.state.zip_code : '');

    let moduleSearchString = "";
    moduleSearchString = this.state.resident_name
      ? "Resident Name - " + this.state.resident_name
      : "";
    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Unit - " +
          this.state.selectedUnitList.label
        : " Unit - " + this.state.selectedUnitList.label
      : moduleSearchString;
    moduleSearchString = this.state.city_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " City  - " + this.state.city_name
        : " City  - " + this.state.city_name
      : moduleSearchString;
    moduleSearchString = this.state.email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Email - " + this.state.email
        : " Email - " + this.state.email
      : moduleSearchString;
    moduleSearchString = this.state.phone_number
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Phone Number - " +
          this.state.phone_number
        : " Phone Number - " + this.state.phone_number
      : moduleSearchString;
    moduleSearchString = this.state.state_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " State - " + this.state.state_name
        : " State - " + this.state.state_name
      : moduleSearchString;
    moduleSearchString = this.state.country_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Country - " + this.state.country_name
        : " Country - " + this.state.country_name
      : moduleSearchString;
    moduleSearchString = this.state.zip_code
      ? moduleSearchString
        ? moduleSearchString + " ," + " Zipcode - " + this.state.zip_code
        : " Zipcode - " + this.state.zip_code
      : moduleSearchString;
    moduleSearchString = this.state.lease_start_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Lease Start Date - " +
          Moment(this.state.lease_start_date).format(global.dateFormat)
        : " Lease Start Date - " +
          Moment(this.state.lease_start_date).format(global.dateFormat)
      : moduleSearchString;

    if (
      this.state.resident_name ||
      this.state.email ||
      this.state.phone_number ||
      this.state.city_name ||
      this.state.state_name ||
      this.state.country_name ||
      this.state.zip_code ||
      this.state.lease_start_date ||
      this.state.unit_slug
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(this.state.tab, queryString);
    }
  }

  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getData(tab, queryString = "") {
    var list = [];
    var totalRecords = 0;
    if (queryParams.isGlobal === "1") {
      document.getElementById("all").click();
      var res = await ResidentService.getResidentList(
        "property_slug=" +
          (this.state.property_slug ? this.state.property_slug : "") +
          "&tab=4&is_search=1&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search +
          (queryString ? "&" + queryString : "")
      );
    } else {
      res = await ResidentService.getResidentList(
        "property_slug=" +
          (this.state.property_slug ? this.state.property_slug : "") +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          (queryString ? "&" + queryString : "")
      );
    }
    if (global.successStatus.includes(res.status)) {
      this.setState({ records: [] });
      list = res.data ? res.data.data : [];
      // totalRecords = list.length ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(this.state.tab, queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(this.state.tab, queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  onChangePhone(value, country, e, formattedValue) {
    //console.log(value, country, e, formattedValue);
    this.setState({ phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }

  getTitle() {
    return "Residents";
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "residents/view/" + data.slug
        : this.state.property_slug
        ? "/residents/view/" + this.state.property_slug + "/" + data.slug
        : "/residents/view/" + data.property_slug + "/" + data.slug
    );
  };

  handleChange = (file) => {
    this.setState({ file_name: file.name });
    this.setState({ file_url: file });
  };

  async confirmImport() {
    if (this.state.file_url) {
      this.setState({ showSpinner: true, isSubmit: true });
      const fileData = new FormData();
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append("property_slug", this.state.property_slug);

      let res = await ResidentService.importData(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({ importModal: false });
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ file_name: "", file_url: "" });
        document.getElementById("all").click();
        this.getData(4);
      } else {
        this.setState({ importModal: false });
        if (
          res.data.result.errorDetail !== null &&
          res.data.result.errorDetail !== undefined
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false, show: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  }

  confirmImportModal() {
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
    this.validator.hideMessages();
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <LeasingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyLeasingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}

                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small mr-0"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.props.match.params.propertySlug === undefined ? (
                    <>
                      <div className="row pd-lg-l-15 pd-xs-l-15">
                        <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                          <OutSidePropertySelectBox
                            propertySlug={this.getPropertySlug.bind(this)}
                            outSidePropertySlug={this.state.outSidePropertySlug}
                          />
                        </div>
                        <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                          {this.state.is_search === 1 ? (
                            <>
                              <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                                <div className="custm-filter">
                                  <div>
                                    {" "}
                                    <label className="form-label text-left pd-b-2 mb-0">
                                      Search Applied On
                                    </label>
                                  </div>
                                  <div className="filter_bg_cust">
                                    {this.state.module_search.map((item, i) => {
                                      return <span key={i}>{item}</span>;
                                    })}
                                    <button
                                      onClick={this.clearFilter}
                                      className="btn-fliter-clear"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-xxl-12 pd-r-15 pd-l-15">
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                              <div className="custm-filter">
                                <div>
                                  {" "}
                                  <label className="form-label text-left pd-b-2 mb-0">
                                    Search Applied On
                                  </label>
                                </div>
                                <div className="filter_bg_cust">
                                  {this.state.module_search.map((item, i) => {
                                    return <span key={i}>{item}</span>;
                                  })}
                                  <button
                                    onClick={this.clearFilter}
                                    className="btn-fliter-clear"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                  <div className="scrolling-carousel pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-0 scroll-slide">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="current"
                      onSelect={(e) => this.getActiveClass(e)}
                      eventKey={this.state.activeClass}
                      className="pd-r-60"
                    >
                      <ScrollingCarousel
                        rightArrow={true}
                        leftArrow={true}
                        className="swipesection scroll_nav_mob"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="current"
                            id="current"
                            className={
                              this.state.activeClass === "current"
                                ? "active"
                                : ""
                            }
                          >
                            Current
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="upcoming"
                            id="upcoming"
                            className={
                              this.state.activeClass === "upcoming"
                                ? "active"
                                : ""
                            }
                          >
                            Upcoming
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="past"
                            id="past"
                            className={
                              this.state.activeClass === "past" ? "active" : ""
                            }
                          >
                            Past
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="all"
                            id="all"
                            className={
                              this.state.activeClass === "all" ? "active" : ""
                            }
                          >
                            All
                          </Nav.Link>
                        </Nav.Item>
                      </ScrollingCarousel>
                      <Tab.Content>
                        <Tab.Pane eventKey="current">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="col-md-12 pl-0 pr-0">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>

                            {queryParams.isGlobal === "1" ? (
                              <>
                                <div className="col-md-12 pd-l-30 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                  {"Search applied on: " +
                                    queryParams.global_col_label +
                                    " - " +
                                    queryParams.global_search}
                                  <button
                                    onClick={this.clearFilter}
                                    className="mg-l-15 btn-success-outline-small"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {this.state.total_record !== 0 ||
                            this.state.is_search === 1 ? (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                <div className="pd-lg-l-0 pd-xs-l-10">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    // loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              </div>
                            ) : (
                              <NoDataSearch
                                msg={global.commonMessages.checkMessage(
                                  "RESIDENT"
                                )}
                              />
                            )}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="upcoming">
                          {this.state.tab === 2 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                              {this.state.total_record !== 0 ||
                              this.state.is_search === 1 ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  <div className="pd-lg-l-0 pd-xs-l-15">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList mg-b-100"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <NoDataSearch
                                  msg={global.commonMessages.checkMessage(
                                    "RESIDENT"
                                  )}
                                />
                              )}
                            </div>
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="past">
                          {this.state.tab === 3 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                              {this.state.total_record !== 0 ||
                              this.state.is_search === 1 ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  <div className="pd-lg-l-0 pd-xs-l-15">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList mg-b-100"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <NoDataSearch
                                  msg={global.commonMessages.checkMessage(
                                    "RESIDENT"
                                  )}
                                />
                              )}
                            </div>
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="all">
                          {this.state.tab === 4 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <div className="col-md-12 pl-0 pr-0">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>
                              {this.state.total_record !== 0 ||
                              this.state.is_search === 1 ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  <div className="pd-lg-l-0 pd-xs-l-15">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <NoDataSearch
                                  msg={global.commonMessages.checkMessage(
                                    "RESIDENT"
                                  )}
                                />
                              )}
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Residents</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Resident Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="resident_name"
                      value={this.state.resident_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>City</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="city_name"
                      value={this.state.city_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Country</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="country_name"
                      value={this.state.country_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Phone</label>
                  <div className="col-md-12 p-0">
                    <PhoneInput
                      country={global.country}
                      disableSearchIcon
                      // autoFormat= "true"
                      enableSearch="true"
                      countryCodeEditable="true"
                      value={this.state.phone_number}
                      onChange={this.onChangePhone}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Email</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>State</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="state_name"
                      value={this.state.state_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Zipcode</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="zip_code"
                      value={this.state.zip_code}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Lease Start Date</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="lease_start_date"
                    selected={this.state.lease_start_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "lease_start_date",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* import data modal */}
        <Modal show={this.state.importModal} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeConfirmImportModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Import</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-between mt-0 row">
                  <form name="myFile">
                    <FileUploader
                      onChange={this.changeHandler}
                      handleChange={this.handleChange}
                      name="file_name"
                      types={["csv", "xlsx", "xls", "ods"]}
                      label="Select or drop file size upto 30 MB"
                    />

                    {this.state.errors.file_name ? (
                      <div className="text-danger">
                        {this.state.errors.file_name}
                      </div>
                    ) : (
                      this.validator.message(
                        "import_file",
                        this.state.file_name,
                        "required",
                        { className: "text-danger" }
                      )
                    )}

                    <div className="d-flex justify-content-between mt-3 ">
                      <button
                        type="button"
                        className="mx-auto m-0 btn btn-outline-light btn-block"
                        onClick={() => this.closeConfirmImportModal()}
                      >
                        Cancel
                      </button>

                      <button
                        type="button"
                        className="mx-auto m-0 btn btn btn-success btn-block"
                        onClick={this.confirmImport}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit ? global.loader : "Import"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Resident;

//     							                          //
//  Program: LeasingSidebar.jsx                           //
//  Application: Property                                 //
//  Option: common component Leasing Sidebar              //
//  Developer: NP 		                                  //
//  Date: 2022-04-02                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";

class LeasingSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug") ? sessionStorage.getItem("property_slug") : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Leasing
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <span>Leasing</span>
                  <ul>
                  {global.userPermissions.checkPermission('lease-calendar-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("lease-avail-calendar")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/lease-avail-calendar"}
                      >
                        Calendar
                      </Link>
                    </li>
                  }
                   {global.userPermissions.checkPermission('availability-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("availability")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/availability"}
                      >
                        Availability
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission('leases-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("leasing")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/leasing"}
                      >
                        Leases
                      </Link>
                    </li>
                  }
                    {/* {global.userPermissions.checkPermission('towers-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("leasing-checklists")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/leasing-checklists"}
                      >
                        Checklists
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <span>Applications</span>
                  <ul>
                  {global.userPermissions.checkPermission('applicants-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("applicants")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/applicants"}
                      >
                        Applicants
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission('inquiry-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("applicant-detail")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/applicant-detail"}
                      >
                        Inquiry
                      </Link>
                    </li>
                  }
                  </ul>
                </li>
                <li>
                  <span>Residents</span>
                  <ul>
                  {global.userPermissions.checkPermission('residents-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("residents")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/residents"}
                      >
                        Residents
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission('rent-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("rent")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/rent"}
                      >
                        Rent
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission('payout-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("resident-payouts")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/resident-payouts"}
                      >
                        Payouts
                      </Link>
                    </li>
                  }
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
             {/* New submenu */}
              <div className="newSubmenu">
                {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
                <span
                  className="back"
                  onClick={(event) => this.toggleHeaderMenu(event)}
                >
                  <i className="fas fa-chevron-left"></i> Back to Leasing
                </span>

                <ul>
                  <li>
                    <span>Leasing</span>
                    <ul>
                    {global.userPermissions.checkPermission('lease-calendar-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("lease-avail-calendar")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/lease-avail-calendar"}
                        >
                          Calendar
                        </Link>
                      </li>
                    }
                    {global.userPermissions.checkPermission('availability-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("availability")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/availability"}
                        >
                          Availability
                        </Link>
                      </li>
                    }
                    {global.userPermissions.checkPermission('leases-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("leasing")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/leasing"}
                        >
                          Leases
                        </Link>
                      </li>
                    }
                      {/* {global.userPermissions.checkPermission('towers-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("leasing-checklists")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/leasing-checklists"}
                        >
                          Checklists
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  <li>
                    <span>Applications</span>
                    <ul>
                    {global.userPermissions.checkPermission('applicants-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("applicants")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/applicants"}
                        >
                          Applicants
                        </Link>
                      </li>
                    }
                    {global.userPermissions.checkPermission('inquiry-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("applicant-detail")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/applicant-detail"}
                        >
                          Inquiry
                        </Link>
                      </li>
                    }
                    </ul>
                  </li>
                  <li>
                    <span>Residents</span>
                    <ul>
                    {global.userPermissions.checkPermission('residents-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("residents")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/residents"}
                        >
                          Residents
                        </Link>
                      </li>
                    }
                    {global.userPermissions.checkPermission('rent-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("rent")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/rent"}
                        >
                          Rent
                        </Link>
                      </li>
                    }
                    {global.userPermissions.checkPermission('payout-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("resident-payouts")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/resident-payouts"}
                        >
                          Payouts
                        </Link>
                      </li>
                    }
                    </ul>
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </>
    );
  }
}
export default LeasingSidebar;
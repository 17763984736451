////////////////////////////////////////////////////////////
//     							                                      //
//  Program: UnitsForm.jsx                                //
//  Application:  Units                                   //
//  Option: For add or update Units                       //
//  Developer: Ashish Kumar,NP                             //
//  Date: 2022-02-03                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import SimpleReactValidator from "simple-react-validator";
import UnitsService from "../../services/UnitsService";
import Select from "react-select";
import unitsImage from "./../../assets/images/units_placeholder.png";
import TowerService from "../../services/TowerService";
import FloorService from "../../services/FloorService";
import FloorPlanService from "../../services/FloorPlanService";
import MasterDataService from "../../services/MasterDataService";
import AmenityService from "../../services/AmenityService";
import RentalService from "../../services/RentalService";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Modal } from "react-bootstrap";
import AffordableTypeService from "../../services/AffordableTypeService";
import DatePicker from "react-datepicker";
import Moment from "moment";
import PropertySidebar from "../common/PropertySidebar";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import UnitCost from "./UnitCost";
import UnitDocuments from "./UnitDocuments";
import UnitCredential from "./UnitCredential";
import UnitAmenities from "./UnitAmenities";
import { Col, InputGroup } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";

export class UnitsForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      propertyTypeList: [],
      towerList: [],
      newTowerList: [],
      selectedTowerList: [],
      floorList: [],
      newFloorList: [],
      selectedFloorList: [],
      floorPlanList: [],
      newFloorPlanList: [],
      selectedFloorPlanList: [],
      kitchenList: [],
      newKitchenList: [],
      selectedKitchenList: [],
      livingRoomList: [],
      newLivingRoomList: [],
      selectedLivingRoomList: [],
      furnishedList: [],
      newFurnishedList: [],
      selectedFurnishedList: [],
      rentingTypeList: [],
      newRentingTypeList: [],
      selectedRentingTypeList: [],
      affordableTypeList: [],
      newAffordableTypeList: [],
      selectedAffordableTypeList: [],
      errors: {},
      open: true,
      eventKey: "3",
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      unit_main_image: [],
      imageUrl: unitsImage,
      status_id: true,
      sort_order: "",
      pathname: window.location.pathname,
      importModal: false,
      amenitiesList: [],
      propertyAmenitiesList: [],
      slugList: [],
      slugUncheckList: [],
      affordable_type_slug: "",
      confirm_back: 0,
      activeClass: "basic",
      unit_number: "",
      pms_unit_number: "",
      airbnb_notes: "",
      maximum_number_of_guests: "",
      //for rental
      is_managed_by_private_person: false,
      is_managed_by_owner: false,
      security_deposit: "",
      location_description: "",
      earningConfigurationData: [
        {
          fee_type: 1,
          fee_type_name: "Total Nightly Rate",
          payout_oh: "",
          payout_resident: "",
          payout_property: "",
          slug: "",
        },
        {
          fee_type: 2,
          fee_type_name: "Cleaning Fees",
          payout_oh: "",
          payout_resident: "",
          payout_property: "",
          slug: "",
        },
        {
          fee_type: 3,
          fee_type_name: "Channel Fees",
          payout_oh: "",
          payout_resident: "",
          payout_property: "",
          slug: "",
        },
      ],
      earning_configuration: [],
    };
    this.unitsImage = React.createRef();
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateUnits = this.saveOrUpdateUnits.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.getAmenitiesDetails = this.getAmenitiesDetails.bind(this);
    this.addAmenities = this.addAmenities.bind(this);
    this.discardAmenities = this.discardAmenities.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleUnCheckboxChange = this.handleUnCheckboxChange.bind(this);
    this.getConfirmStatus = this.getConfirmStatus.bind(this);
    this.getImgStatus = this.getImgStatus.bind(this);
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    this.getFloorData();
    this.getFloorPlanData();
    // this.getKitchenData();
    // this.getLivingRoomData();
    this.getFurnishedData();
    this.getRentingTypeData();
    this.getTowerData();
    this.getAffordableTypeData();
    this.getUnitEarningConfigurationData();
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      this.getAmenitiesData();
      this.getPropertyAmenitiesData();
      //api call in only update form

      var resData = {};
      let res = await UnitsService.getUnits(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.renting_type = res.data.renting_type
          ? res.data.renting_type
          : "";
        resData.tower_slug = res.data.tower ? res.data.tower.slug : "";
        resData.floor_slug = res.data.floor ? res.data.floor.slug : "";
        resData.affordable_type_slug = res.data.affordable_type
          ? res.data.affordable_type.slug
          : "";
        resData.floor_plan_slug = res.data.floor_plan
          ? res.data.floor_plan.slug
          : "";
        resData.company_slug = res.data.company ? res.data.company.slug : "";
        resData.unit_number = res.data.unit_number ? res.data.unit_number : "";
        resData.pms_unit_number = res.data.pms_unit_number ? res.data.pms_unit_number : "";
        resData.maximum_number_of_guests = res.data.maximum_number_of_guests
          ? res.data.maximum_number_of_guests
          : "";
        // resData.unit_name = res.data.unit_name ? res.data.unit_name : "";
        // resData.unit_description = res.data.unit_description
        //   ? res.data.unit_description
        //   : "";
        resData.unit_code = res.data.unit_code ? res.data.unit_code : "";
        // resData.house_rules = res.data.house_rules ? res.data.house_rules : "";
        // resData.move_in_window = res.data.move_in_window
        //   ? res.data.move_in_window
        //   : "";
        resData.rent = res.data.base_price
          ? parseFloat(res.data.base_price)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.deposit = res.data.deposit
          ? parseFloat(res.data.deposit)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.cleaning_fee = res.data.cleaning_fee
          ? parseFloat(res.data.cleaning_fee)
              .toFixed(2)
              .replace(/\.00$/, "")
              .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
        resData.available = res.data.available ? res.data.available : "";
        resData.airbnb_notes = res.data.airbnb_notes
          ? res.data.airbnb_notes
          : "";
        resData.available_from = res.data.available_from
          ? Moment(res.data.available_from).toDate("MM/dd/YY")
          : null;
        // resData.kitchen = res.data.kitchen ? res.data.kitchen : "";
        // resData.living_room = res.data.living_room ? res.data.living_room : "";
        resData.furnished = res.data.furnished ? res.data.furnished : "";
        resData.unit_main_image = res.data.unit_main_image
          ? res.data.unit_main_image
          : "";
        resData.imageUrl = res.data.unit_main_image_url
          ? res.data.unit_main_image_url
          : unitsImage;
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";

        resData.status_id = res.data.status_id === 1 ? true : false;

        //for rental
        resData.is_managed_by_private_person = res.data.ruUnitData
          ? res.data.ruUnitData.is_managed_by_private_person === 1
            ? true
            : false
          : false;
        resData.is_managed_by_owner = res.data.ruUnitData
          ? res.data.ruUnitData.is_managed_by_owner === 1
            ? true
            : false
          : false;
        resData.security_deposit = res.data.ruUnitData
          ? res.data.ruUnitData.security_deposit
          : "";
        resData.location_description = res.data.ruUnitData
          ? res.data.ruUnitData.location_description
          : "";

        var selectedRentingTypeList = [];
        // var selectedKitchenList = [];
        // var selectedLivingRoomList = [];
        var selectedFurnishedList = [];

        res.data.masterData.map((item) => {
          if (resData.renting_type === item.slug) {
            selectedRentingTypeList = res.data.masterData
              ? [
                  {
                    value: item.slug,
                    label: item.name,
                  },
                ]
              : "";
          }
          // if (resData.kitchen === item.slug) {
          //   selectedKitchenList = res.data.masterData
          //     ? [
          //         {
          //           value: item.slug,
          //           label: item.name,
          //         },
          //       ]
          //     : "";
          // }
          // if (resData.living_room === item.slug) {
          //   selectedLivingRoomList = res.data.masterData
          //     ? [
          //         {
          //           value: item.slug,
          //           label: item.name,
          //         },
          //       ]
          //     : "";
          // }

          if (resData.furnished === item.slug) {
            selectedFurnishedList = res.data.masterData
              ? [
                  {
                    value: item.slug,
                    label: item.name,
                  },
                ]
              : "";
          }
        });

        var selectedTowerList = [];
        selectedTowerList = res.data.tower
          ? [
              {
                value: res.data.tower.slug,
                label: res.data.tower.tower_name,
              },
            ]
          : "";

        var selectedFloorList = [];
        selectedFloorList = res.data.floor
          ? [
              {
                value: res.data.floor.slug,
                label: res.data.floor.floor_name,
              },
            ]
          : "";

        var selectedAffordableTypeList = [];
        selectedAffordableTypeList = res.data.affordable_type
          ? [
              {
                value: res.data.affordable_type.slug,
                label: res.data.affordable_type.affordable_type_name,
              },
            ]
          : "";

        var selectedFloorPlanList = [];
        selectedFloorPlanList = res.data.floor_plan
          ? [
              {
                value: res.data.floor_plan.slug,
                label:
                  res.data.floor_plan.property_floor_plan_name +
                  (res.data.floor_plan.square_foot
                    ? " (" +
                      res.data.floor_plan.square_foot.replace(/\.00$/, "") +
                      "  Sq. ft)"
                    : ""),
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({
        selectedTowerList: selectedTowerList,
        selectedFloorList: selectedFloorList,
        selectedRentingTypeList: selectedRentingTypeList,
        selectedAffordableTypeList: selectedAffordableTypeList,
        selectedFloorPlanList: selectedFloorPlanList,
        // selectedKitchenList: selectedKitchenList,
        // selectedLivingRoomList: selectedLivingRoomList,
        selectedFurnishedList: selectedFurnishedList,
      });
      this.defaultData = resData;
    }
    this.setState({ showSpinner: false, loading: false });
    //api call in add/update both form
  }

  async getUnitEarningConfigurationData() {
    if (this.state.slug !== undefined) {
      var resData = {};

      let res = await UnitsService.getUnitEarningConfigurationData(
        "unitSlug=" + this.state.slug + "&reference=units" + "&list=" + 5
      );

      if (global.successStatus.includes(res.status)) {
        resData.earningConfigurationDataLength = res.data ? res.data.length : 0;
        resData.earningConfigurationData = res.data
          ? res.data
          : [
              {
                fee_type: 1,
                fee_type_name: "Total Nightly Rate",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 2,
                fee_type_name: "Cleaning Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 3,
                fee_type_name: "Channel Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
            ];
        const values = [];
        if (resData.earningConfigurationDataLength > 0) {
          resData.earningConfigurationData.forEach((item, i) => {
            values.push({
              fee_type: item.fee_type,
              fee_type_name: item.fee_type_name,
              payout_oh: item.payout_oh,
              payout_resident: item.payout_resident,
              payout_property: item.payout_property,
              slug: item.slug,
            });
          });
        } else {
          resData.earningConfigurationData = [
            {
              fee_type: 1,
              fee_type_name: "Total Nightly Rate",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 2,
              fee_type_name: "Cleaning Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 3,
              fee_type_name: "Channel Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
          ];
        }
        if (resData.earningConfigurationDataLength === 0) {
          this.getPropertyEarningConfigurationData();
        }
        this.setState({
          earning_configuration: values,
          earningConfigurationData: values,
        });
        this.setState(resData);
      }
    }
  }

  async getPropertyEarningConfigurationData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};

      let res = await PropertyService.getPropertyEarningConfiguration(
        "slug=" +
          this.state.property_slug +
          "&reference=properties" +
          "&list=" +
          3
      );

      if (global.successStatus.includes(res.status)) {
        resData.earningConfigurationDataLength = res.data ? res.data.length : 0;
        resData.earningConfigurationData = res.data
          ? res.data
          : [
              {
                fee_type: 1,
                fee_type_name: "Total Nightly Rate",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 2,
                fee_type_name: "Cleaning Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 3,
                fee_type_name: "Channel Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
            ];
        const values = [];
        if (resData.earningConfigurationDataLength > 0) {
          resData.earningConfigurationData.forEach((item, i) => {
            values.push({
              fee_type: item.fee_type,
              fee_type_name: item.fee_type_name,
              payout_oh: item.payout_oh,
              payout_resident: item.payout_resident,
              payout_property: item.payout_property,
              slug: item.slug,
            });
          });
        } else {
          resData.earningConfigurationData = [
            {
              fee_type: 1,
              fee_type_name: "Total Nightly Rate",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 2,
              fee_type_name: "Cleaning Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 3,
              fee_type_name: "Channel Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
          ];
        }
        this.setState({
          // earning_configuration: values,
          earningConfigurationData: values,
        });
        this.setState(resData);
      }
    }
  }

  /* to get tower type data */
  async getTowerData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var towerList = [];
    var towerListTotal = 0;

    let res = await TowerService.getTower(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      towerList = res.data ? res.data : [];
      towerListTotal = towerList ? towerList.length : 0;
    }
    const newTowerList =
      towerListTotal > 0
        ? towerList.map(({ slug, tower_name }) => ({
            value: slug,
            label: tower_name,
          }))
        : [];
    this.setState({
      towerList: towerList,
      towerListTotal: towerListTotal,
      newTowerList: newTowerList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get Affordable Type data */
  async getAffordableTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var affordableTypeList = [];
    var affordableTypeListTotal = 0;

    let res = await AffordableTypeService.getAffordableType(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      affordableTypeList = res.data ? res.data : [];
      affordableTypeListTotal = affordableTypeList
        ? affordableTypeList.length
        : 0;
    }
    const newAffordableTypeList =
      affordableTypeListTotal > 0
        ? affordableTypeList.map(({ slug, affordable_type_name }) => ({
            value: slug,
            label: affordable_type_name,
          }))
        : [];
    this.setState({
      affordableTypeList: affordableTypeList,
      affordableTypeListTotal: affordableTypeListTotal,
      newAffordableTypeList: newAffordableTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get floor data list */
  async getFloorData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var floorList = [];
    var floorListTotal = 0;

    let res = await FloorService.getFloor(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      floorList = res.data ? res.data : [];
      floorListTotal = floorList ? floorList.length : 0;
    }
    const newFloorList =
      floorListTotal > 0
        ? floorList.map(({ slug, floor_name }) => ({
            value: slug,
            label: floor_name,
          }))
        : [];
    this.setState({
      floorList: floorList,
      floorListTotal: floorListTotal,
      newFloorList: newFloorList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get floor plan data list */
  async getFloorPlanData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var floorPlanList = [];
    var floorPlanListTotal = 0;

    let res = await FloorPlanService.getFloorPlan(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      floorPlanList = res.data ? res.data : [];
      floorPlanListTotal = floorPlanList ? floorPlanList.length : 0;
    }
    const newFloorPlanList =
      floorPlanListTotal > 0
        ? floorPlanList.map(
            ({ slug, property_floor_plan_name, square_foot }) => ({
              value: slug,
              label:
                property_floor_plan_name +
                (square_foot
                  ? " (" + square_foot.replace(/\.00$/, "") + " Sq. ft)"
                  : ""),
            })
          )
        : [];
    this.setState({
      floorPlanList: floorPlanList,
      floorPlanListTotal: floorPlanListTotal,
      newFloorPlanList: newFloorPlanList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  getImgStatus(imgFile) {
    this.setState({ imageUrl: imgFile });
  }

  /* to get renting type data */
  async getRentingTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var rentingTypeList = [];
    var rentingTypeListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=renting_type",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      rentingTypeList = res.data ? res.data : [];
      rentingTypeListTotal = rentingTypeList ? rentingTypeList.length : 0;
    }
    const newRentingTypeList =
      rentingTypeListTotal > 0
        ? rentingTypeList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : "";
    this.setState({
      rentingTypeList: rentingTypeList,
      rentingTypeListTotal: rentingTypeListTotal,
      newRentingTypeList: newRentingTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get kitchen data */
  // async getKitchenData(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var kitchenList = [];
  //   var kitchenListTotal = 0;

  //   let res = await MasterDataService.getMasterData(
  //     "is_dropdown=1&title=kitchen",
  //     queryString ? "&" + queryString : ""
  //   );

  //   if (global.successStatus.includes(res.status)) {
  //     kitchenList = res.data ? res.data : [];
  //     kitchenListTotal = kitchenList ? kitchenList.length : 0;
  //   }
  //   const newKitchenList = kitchenList.map(({ slug, name }) => ({
  //     value: slug,
  //     label: name,
  //   }));
  //   this.setState({
  //     kitchenList: kitchenList,
  //     kitchenListTotal: kitchenListTotal,
  //     newKitchenList: newKitchenList,
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  /* to get living room data */
  // async getLivingRoomData(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var livingRoomList = [];
  //   var livingRoomListTotal = 0;

  //   let res = await MasterDataService.getMasterData(
  //     "is_dropdown=1&title=living_room",
  //     queryString ? "&" + queryString : ""
  //   );

  //   if (global.successStatus.includes(res.status)) {
  //     livingRoomList = res.data ? res.data : [];
  //     livingRoomListTotal = livingRoomList ? livingRoomList.length : 0;
  //   }
  //   const newLivingRoomList = livingRoomList.map(({ slug, name }) => ({
  //     value: slug,
  //     label: name,
  //   }));
  //   this.setState({
  //     livingRoomList: livingRoomList,
  //     livingRoomListTotal: livingRoomListTotal,
  //     newLivingRoomList: newLivingRoomList,
  //   });
  //   this.setState({ showSpinner: false, loading: false });
  // }

  /* to get furnished room data */
  async getFurnishedData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var furnishedList = [];
    var furnishedListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=furnished",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      furnishedList = res.data ? res.data : [];
      furnishedListTotal = furnishedList ? furnishedList.length : 0;
    }
    const newFurnishedList =
      furnishedListTotal > 0
        ? furnishedList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      furnishedList: furnishedList,
      furnishedListTotal: furnishedListTotal,
      newFurnishedList: newFurnishedList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  /* to amenities data */
  async getAmenitiesData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var amenitiesList = [];

    let res = await AmenityService.getAmenity(
      "level=2",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      amenitiesList = res.data ? res.data.data : [];
    }
    this.setState({ amenitiesList: amenitiesList });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get property amenities data */
  async getPropertyAmenitiesData() {
    this.setState({ showSpinner: true, loading: true });
    var propertyAmenitiesList = [];

    let res = await AmenityService.getPropertyAmenities(this.state.slug + "/2");
    if (global.successStatus.includes(res.status)) {
      propertyAmenitiesList = res.data ? res.data : [];
    }
    this.setState({ propertyAmenitiesList: propertyAmenitiesList });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save amenities data */
  addAmenities = async (e) => {
    e.preventDefault();
    if (this.state.slugList.length > 0) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        amenitiesData: this.state.slugList,
      };

      let res = await AmenityService.attachPropertyAmenities(
        this.state.slug + "/2",
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ slugList: [] });
        this.getPropertyAmenitiesData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      alert("Select atleast one attraction");
    }
  };

  /* to discard amenities data */
  discardAmenities = async (e) => {
    e.preventDefault();
    if (this.state.slugUncheckList.length > 0) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        amenitiesData: this.state.slugUncheckList,
      };

      let res = await AmenityService.detachPropertyAmenities(
        this.state.slug + "/2",
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ slugUncheckList: [] });
        this.getPropertyAmenitiesData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      alert("Select atleast one attraction");
    }
  };

  /* To amenities details data */
  async getAmenitiesDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await AmenityService.getAmenityBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.amenity_name = res.data.amenity_name
          ? res.data.amenity_name
          : "";
        resData.amenity_type_name = res.data.amenity_type
          ? res.data.amenity_type.amenity_type_name
          : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.tag_line = res.data.tag_line ? res.data.tag_line : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  confirmImportModal(slug) {
    this.getAmenitiesDetails(slug);
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "unit_main_image",
        event.target.files[0],
        event.target.files[0].name
      );
      let res = await UnitsService.uploadUnitsLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          unit_main_image: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  saveOrUpdateUnits = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        //first tab data
        tab: 1,
        renting_type: this.state.renting_type ? this.state.renting_type : "",
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        tower_slug: this.state.tower_slug ? this.state.tower_slug : "",
        floor_slug: this.state.floor_slug ? this.state.floor_slug : "",
        affordable_type_slug: this.state.affordable_type_slug
          ? this.state.affordable_type_slug
          : "",
        floor_plan_slug: this.state.floor_plan_slug
          ? this.state.floor_plan_slug
          : "",
        company_slug: this.state.company_slug ? this.state.company_slug : "",
        unit_number: this.state.unit_number ? this.state.unit_number : "",
        pms_unit_number: this.state.pms_unit_number ? this.state.pms_unit_number : "",
        maximum_number_of_guests: this.state.maximum_number_of_guests
          ? this.state.maximum_number_of_guests
          : "",
        // unit_name: this.state.unit_name ? this.state.unit_name : "",
        // unit_description: this.state.unit_description
        //   ? this.state.unit_description
        //   : "",
        unit_code: this.state.unit_code ? this.state.unit_code : "",
        // house_rules: this.state.house_rules ? this.state.house_rules : "",
        // move_in_window: this.state.move_in_window
        //   ? this.state.move_in_window
        //   : "",
        base_price: this.state.rent ? this.state.rent : "",
        airbnb_notes: this.state.airbnb_notes ? this.state.airbnb_notes : "",
        deposit: this.state.deposit ? this.state.deposit : "",
        cleaning_fee: this.state.cleaning_fee ? this.state.cleaning_fee : "",
        available: this.state.available ? this.state.available : "",
        available_from: this.state.available_from
          ? Moment(this.state.available_from).format("YYYY-MM-DD")
          : null,
        kitchen: this.state.kitchen ? this.state.kitchen : "",
        living_room: this.state.living_room ? this.state.living_room : "",
        furnished: this.state.furnished ? this.state.furnished : "",
        sort_order: this.state.sort_order ? this.state.sort_order : "",
        status_id: this.state.status_id === true ? 1 : 2,
        earning_configuration: this.state.earning_configuration,
        refTableId: 5,
      };

      //for rental
      let inputData2 = {
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        unit_slug: this.state.slug ? this.state.slug : "",
        is_managed_by_private_person:
          this.state.is_managed_by_private_person === true ? 1 : 0,
        is_managed_by_owner: this.state.is_managed_by_owner === true ? 1 : 0,
        security_deposit: this.state.security_deposit
          ? this.state.security_deposit
          : "",
        location_description: this.state.location_description
          ? this.state.location_description
          : "",
      };

      if (this.state.slug !== undefined) {
        let res = await UnitsService.updateUnits(this.state.slug, inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/units/" +
                  this.state.slug +
                  "/unit-basic"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }

        let res2 = await RentalService.createUnitData(inputData2);
        if (global.successStatus.includes(res2.status)) {
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "success",
          //   alertModalTitle: "Success",
          //   alertModalMessage: res.message ? res.message : "Success",
          // });
        } else {
          if (res2.data.result.errorDetail) {
            let errors = {};
            res2.data.errorCode = "Validation Failed";
            res2.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res2.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res2.data.errorCode
              ? res2.data.errorCode
              : "Error!",
            alertModalMessage: res2.data.message ? res2.data.message : "Error!",
          });
        }
      } else {
        let res = await UnitsService.createUnits(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          var last_insert_slug = res.data.last_insert_slug;
          this.setState({ slug: last_insert_slug });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/units/" +
                  this.state.slug +
                  "/unit-basic"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    if (event.target.name === "rent") {
      let rent =
        event.target.name === "rent"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.rent
          ? this.state.rent.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
      this.setState({ rent: rent });
    }
    if (event.target.name === "deposit") {
      let deposit =
        event.target.name === "deposit"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.deposit
          ? this.state.deposit.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
      this.setState({ deposit: deposit });
    }
    if (event.target.name === "cleaning_fee") {
      let cleaning_fee =
        event.target.name === "cleaning_fee"
          ? event.target.value !== ""
            ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
            : 0
          : this.state.cleaning_fee
          ? this.state.cleaning_fee.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
      this.setState({ cleaning_fee: cleaning_fee });
    }
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  handleCheckboxChange = (event) => {
    let newArray = [...this.state.slugList, event.target.id];
    if (this.state.slugList.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    this.setState({ slugList: newArray });
    this.setState({ confirm_back: 1 });
  };

  handleUnCheckboxChange = (event) => {
    let newArray = [...this.state.slugUncheckList, event.target.id];
    if (this.state.slugUncheckList.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    this.setState({ slugUncheckList: newArray });
    this.setState({ confirm_back: 1 });
  };

  handleChangeRentingTypeList(value) {
    let renting_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        renting_type.push(item.value);
      });
    }
    const renting_type_value = value !== null ? value.value : [];
    this.setState({
      selectedRentingTypeList: value,
      renting_type: renting_type_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeAffordableTypeList(value) {
    let affordable_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        affordable_type_slug.push(item.value);
      });
    }
    const affordable_type_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedAffordableTypeList: value,
      affordable_type_slug: affordable_type_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeTowerList(value) {
    let tower_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        tower_slug.push(item.value);
      });
    }
    const tower_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedTowerList: value,
      tower_slug: tower_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeFloorList(value) {
    let floor_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        floor_slug.push(item.value);
      });
    }
    const floor_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedFloorList: value,
      floor_slug: floor_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeFloorPlanList(value) {
    let floor_plan_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        floor_plan_slug.push(item.value);
      });
    }
    const floor_plan_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedFloorPlanList: value,
      floor_plan_slug: floor_plan_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeKitchenList(value) {
    let kitchen = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        kitchen.push(item.value);
      });
    }
    const kitchen_value = value !== null ? value.value : [];
    this.setState({
      selectedKitchenList: value,
      kitchen: kitchen_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeLivingRoomList(value) {
    let living_room = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        living_room.push(item.value);
      });
    }
    const living_room_value = value !== null ? value.value : [];
    this.setState({
      selectedLivingRoomList: value,
      living_room: living_room_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeFurnishedList(value) {
    let furnished = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        furnished.push(item.value);
      });
    }
    const furnished_value = value !== null ? value.value : [];
    this.setState({
      selectedFurnishedList: value,
      furnished: furnished_value,
    });
    this.setState({ confirm_back: 1 });
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("unit_name");
    }
  }

  handleChangeEarningConfiguration = (index, event) => {
    const values = [...this.state.earningConfigurationData];
    if (event.target.name === "payout_oh") {
      if (
        parseFloat(event.target.value) +
          (parseFloat(values[index].payout_property)
            ? parseFloat(values[index].payout_property)
            : 0) +
          (parseFloat(values[index].payout_resident)
            ? parseFloat(values[index].payout_resident)
            : 0) >
        100
      ) {
        values[index].payout_oh = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        values[index].to_oh_error =
          "OH % + Resident % + Property % total can't be greater than 100";
      } else {
        values[index].payout_oh = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        if (values[index].payout_oh > 100) {
          values[index].to_oh_error = "OH % can't be greater than 100";
        } else {
          values[index].to_oh_error = "";
        }
      }
    } else if (event.target.name === "payout_property") {
      if (
        parseFloat(event.target.value) +
          (parseFloat(values[index].payout_oh)
            ? parseFloat(values[index].payout_oh)
            : 0) +
          (parseFloat(values[index].payout_resident)
            ? parseFloat(values[index].payout_resident)
            : 0) >
        100
      ) {
        values[index].payout_property = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        values[index].to_property_error =
          "OH % + Resident % + Property % total can't be greater than 100";
      } else {
        values[index].payout_property = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        if (values[index].payout_property > 100) {
          values[index].to_property_error =
            "Property % can't be greater than 100";
        } else {
          values[index].to_property_error = "";
        }
      }
    } else if (event.target.name === "payout_resident") {
      if (
        parseFloat(event.target.value) +
          (parseFloat(values[index].payout_oh)
            ? parseFloat(values[index].payout_oh)
            : 0) +
          (parseFloat(values[index].to_building)
            ? parseFloat(values[index].to_building)
            : 0) >
        100
      ) {
        values[index].payout_resident = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        values[index].to_resident_error =
          "OH % + Resident % + Property % total can't be greater than 100";
      } else {
        values[index].payout_resident = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        if (values[index].payout_resident > 100) {
          values[index].to_resident_error =
            "Resident % can't be greater than 100";
        } else {
          values[index].to_resident_error = "";
        }
      }
    }

    this.setState({ earning_configuration: values, confirm_back: 1 });
  };

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.unit_description !== undefined &&
      input.unit_description !== "" &&
      input.unit_description !== null &&
      input.unit_description.length > 100000
    ) {
      isValid = false;
      errors["unit_description"] =
        "The unit description character limit has been exceed.";
    }
    if (
      input.house_rules !== undefined &&
      input.house_rules !== "" &&
      input.house_rules !== null &&
      input.house_rules.length > 100000
    ) {
      isValid = false;
      errors["house_rules"] =
        "The house rules character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return (
        "Units" + (this.state.unit_number ? " : " + this.state.unit_number : "")
      );
    } else {
      return "Units";
    }
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar
                      property_slug={this.state.property_slug}
                      unit_slug={this.state.slug}
                    />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 info_box_model">
                    <h3>{this.getTitle()}</h3>
                    {this.state.slug !== undefined ? (
                      ""
                    ) : (
                      <div className="d-flex justify-content-end">
                        {this.state.slug !== undefined ? (
                          <Link
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.props.history.push(
                                    "/properties/view/" +
                                      this.state.property_slug +
                                      "/units/" +
                                      this.state.slug +
                                      "/unit-basic"
                                  );
                                else return false;
                              else
                                this.props.history.push(
                                  "/properties/view/" +
                                    this.state.property_slug +
                                    "/units/" +
                                    this.state.slug +
                                    "/unit-basic"
                                );
                            }}
                          >
                            <button
                              type="button"
                              className="btn-success-outline-small"
                            >
                              Cancel
                            </button>
                          </Link>
                        ) : (
                          <Link
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.props.history.push(
                                    "/properties/view/" +
                                      this.state.property_slug +
                                      "/units"
                                  );
                                else return false;
                              else
                                this.props.history.push(
                                  "/properties/view/" +
                                    this.state.property_slug +
                                    "/units"
                                );
                            }}
                          >
                            <button
                              type="button"
                              className="btn-success-outline-small"
                            >
                              Cancel
                            </button>
                          </Link>
                        )}

                        {this.state.slug !== undefined &&
                        global.userPermissions.checkPermission(
                          "units-update"
                        ) ? (
                          <button
                            className="btn-success mr-3 "
                            type="button"
                            disabled={this.state.isSubmit ? true : false}
                            onClick={this.saveOrUpdateUnits}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        ) : global.userPermissions.checkPermission(
                            "units-add"
                          ) ? (
                          <button
                            className="btn-success mr-3"
                            type="button"
                            disabled={this.state.isSubmit ? true : false}
                            onClick={this.saveOrUpdateUnits}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="basic"
                    onSelect={(e) => this.getActiveClass(e)}
                    eventKey={this.state.activeClass}
                  >
                    {this.state.slug !== undefined ? (
                      <ScrollingCarousel
                        rightArrow={true}
                        leftArrow={true}
                        className="swipesection scroll_nav_mob"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="basic"
                            className={
                              this.state.activeClass === "basic" ? "active" : ""
                            }
                          >
                            Basic
                          </Nav.Link>
                        </Nav.Item>
                      {global.userPermissions.checkPermission("units-update") ? <>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="unitsAmenities"
                            className={
                              this.state.activeClass === "unitsAmenities"
                                ? "active"
                                : ""
                            }
                          >
                            Amenities
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            eventKey="unitsFees"
                            className={
                              this.state.activeClass === "unitsFees"
                                ? "active"
                                : ""
                            }
                          >
                            Fees
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            eventKey="unitsDocuments"
                            className={
                              this.state.activeClass === "unitsDocuments"
                                ? "active"
                                : ""
                            }
                          >
                            Documents & Leases
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="unitscredential"
                            className={
                              this.state.activeClass === "unitscredential"
                                ? "active"
                                : ""
                            }
                          >
                            Credentials
                          </Nav.Link>
                        </Nav.Item>
                       </> :""}
                      </ScrollingCarousel>
                    ) : (
                      ""
                    )}
                    <Tab.Content>
                      <Tab.Pane eventKey="basic">
                        <form id="units_form">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                            {this.state.slug !== undefined ? (
                              <div className="d-flex justify-content-end swiperBtns">
                                {this.state.slug !== undefined ? (
                                  <Link
                                    onClick={() => {
                                      if (this.state.confirm_back === 1)
                                        if (
                                          window.confirm(
                                            "Do you want to exit without saving?"
                                          )
                                        )
                                          this.props.history.push(
                                            "/properties/view/" +
                                              this.state.property_slug +
                                              "/units/" +
                                              this.state.slug +
                                              "/unit-basic"
                                          );
                                        else return false;
                                      else
                                        this.props.history.push(
                                          "/properties/view/" +
                                            this.state.property_slug +
                                            "/units/" +
                                            this.state.slug +
                                            "/unit-basic"
                                        );
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-success-outline-small"
                                    >
                                      Cancel
                                    </button>
                                  </Link>
                                ) : (
                                  <Link
                                    onClick={() => {
                                      if (this.state.confirm_back === 1)
                                        if (
                                          window.confirm(
                                            "Do you want to exit without saving?"
                                          )
                                        )
                                          this.props.history.push(
                                            "/properties/view/" +
                                              this.state.property_slug +
                                              "/units"
                                          );
                                        else return false;
                                      else
                                        this.props.history.push(
                                          "/properties/view/" +
                                            this.state.property_slug +
                                            "/units"
                                        );
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-success-outline-small  mr-3"
                                    >
                                      Cancel
                                    </button>
                                  </Link>
                                )}
                                <button
                                  type="button"
                                  className="btn-success mr-3"
                                  onClick={this.saveOrUpdateUnits}
                                  disabled={this.state.isSubmit ? true : false}
                                >
                                  {this.state.isSubmit
                                    ? global.loader
                                    : this.state.slug !== undefined
                                    ? global.userPermissions.checkPermission(
                                        "units-update"
                                      ) && "Save"
                                    : global.userPermissions.checkPermission(
                                        "unit-add"
                                      ) && "Save"}
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>
                            <div className="m-0">
                              <div className="row align-items-center pd-t-10 pd-b-0 border-bottom-0 pd-r-15">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Unit{" "}
                                  </label>

                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="unit_number"
                                    value={this.state.unit_number}
                                    maxLength="50"
                                  />
                                  {this.state.errors.unit_number ? (
                                    <div className="text-danger">
                                      {this.state.errors.unit_number}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "unit",
                                      this.state.unit_number,
                                      "required|max:50",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    PMS Unit Number
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="pms_unit_number"
                                    value={this.state.pms_unit_number}
                                    maxLength="50"
                                  />
                                  {this.state.errors.pms_unit_number ? (
                                    <div className="text-danger">
                                      {this.state.errors.pms_unit_number}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "pms unit number",
                                      this.state.pms_unit_number,
                                      "max:50",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>

                                {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Listing Name{" "}
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              id="unit_name"
                              name="unit_name"
                              value={this.state.unit_name}
                              maxLength="50"
                            />
                            {this.state.errors.unit_name ? (
                              <div className="text-danger">
                                {this.state.errors.unit_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "listing_name",
                                this.state.unit_name,
                                "required|max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div> */}

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Tower
                                  </label>

                                  <Select
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    isClearable={true}
                                    menuPlacement="auto"
                                    value={this.state.selectedTowerList}
                                    options={this.state.newTowerList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeTowerList(value)
                                    }
                                    defaultValue={this.state.selectedTowerList}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary: "black",
                                      },
                                    })}
                                  />

                                  {this.state.errors.tower_slug ? (
                                    <div className="text-danger">
                                      {this.state.errors.tower_slug}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "tower",
                                      this.state.tower_slug,
                                      "required",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Floor{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Select
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    isClearable={true}
                                    menuPlacement="auto"
                                    value={this.state.selectedFloorList}
                                    options={this.state.newFloorList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeFloorList(value)
                                    }
                                    defaultValue={this.state.selectedFloorList}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary: "black",
                                      },
                                    })}
                                  />

                                  {this.state.errors.floor_slug ? (
                                    <div className="text-danger">
                                      {this.state.errors.floor_slug}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Floor Plan{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Select
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    isClearable={true}
                                    menuPlacement="auto"
                                    value={this.state.selectedFloorPlanList}
                                    options={this.state.newFloorPlanList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeFloorPlanList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedFloorPlanList
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary: "black",
                                      },
                                    })}
                                  />

                                  {this.state.errors.floor_plan_slug ? (
                                    <div className="text-danger">
                                      {this.state.errors.floor_plan_slug}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Guest Count{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="maximum_number_of_guests"
                                    value={this.state.maximum_number_of_guests}
                                    maxLength="2"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                  />
                                  {this.state.errors
                                    .maximum_number_of_guests ? (
                                    <div className="text-danger">
                                      {
                                        this.state.errors
                                          .maximum_number_of_guests
                                      }
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "maximum_number_of_guests",
                                      this.state.maximum_number_of_guests,
                                      "max:2",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Rent{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <div className="input-group-prepend prefix-dollar">
                                    <span className="input-group-text">$</span>
                                    <Form.Control
                                      onChange={this.changeHandler}
                                      name="rent"
                                      value={
                                        this.state.rent
                                          ? this.state.rent.replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                          : "" || ""
                                      }
                                      className="form-control max_width_100"
                                      type="text"
                                      onKeyPress={
                                        global.onKeyPressEvent.floatValidation
                                      }
                                      maxLength="10"
                                      autoComplete="off"
                                    />
                                  </div>
                                  {this.state.errors.rent ? (
                                    <div className="text-danger">
                                      {this.state.errors.rent}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Deposit{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <div className="input-group-prepend prefix-dollar">
                                    <span className="input-group-text">$</span>
                                    <Form.Control
                                      onChange={this.changeHandler}
                                      name="deposit"
                                      value={
                                        this.state.deposit
                                          ? this.state.deposit.replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                          : "" || ""
                                      }
                                      className="form-control max_width_100"
                                      type="text"
                                      onKeyPress={
                                        global.onKeyPressEvent.floatValidation
                                      }
                                      maxLength="10"
                                      autoComplete="off"
                                    />
                                  </div>
                                  {this.state.errors.deposit ? (
                                    <div className="text-danger">
                                      {this.state.errors.deposit}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Cleaning Fee{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <div className="input-group-prepend prefix-dollar">
                                    <span className="input-group-text">$</span>
                                    <Form.Control
                                      onChange={this.changeHandler}
                                      name="cleaning_fee"
                                      value={
                                        this.state.cleaning_fee
                                          ? this.state.cleaning_fee.replace(
                                              /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                              ""
                                            )
                                          : "" || ""
                                      }
                                      className="form-control max_width_100"
                                      type="text"
                                      onKeyPress={
                                        global.onKeyPressEvent.floatValidation
                                      }
                                      maxLength="10"
                                      autoComplete="off"
                                    />
                                  </div>
                                  {this.state.errors.cleaning_fee ? (
                                    <div className="text-danger">
                                      {this.state.errors.cleaning_fee}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Affordable Type{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Select
                                    styles={customStyles}
                                    isClearable={true}
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    menuPlacement="auto"
                                    value={
                                      this.state.selectedAffordableTypeList
                                    }
                                    options={this.state.newAffordableTypeList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeAffordableTypeList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedAffordableTypeList
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />

                                  {this.state.errors.affordable_type_slug ? (
                                    <div className="text-danger">
                                      {this.state.errors.affordable_type_slug}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Furnished{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <Select
                                    className="multiselect"
                                    classNamePrefix={"my-custom-react-select"}
                                    isClearable={true}
                                    menuPlacement="auto"
                                    value={this.state.selectedFurnishedList}
                                    options={this.state.newFurnishedList}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeFurnishedList(value)
                                    }
                                    defaultValue={
                                      this.state.selectedFurnishedList
                                    }
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary: "black",
                                      },
                                    })}
                                  />

                                  {this.state.errors.furnished ? (
                                    <div className="text-danger">
                                      {this.state.errors.furnished}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}
                              <div className="row align-items-center pd-b-10 pd-l-30 pd-r-30 border-bottom-0 pd-r-15">
                                <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                                  <ul className="formcheck-section mb-0">
                                    <li>
                                      <label htmlFor="available" className="form-label text-left pd-b-5 mg-b-0 hand-cursor">
                                        Available
                                      </label>
                                      <Form.Check
                                        name="available"
                                        type={"checkbox"}
                                        id={`available`}
                                        label=""
                                        className={"ckbox"}
                                        onChange={this.changeHandler}
                                        checked={this.state.available}
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="row align-items-center pd-b-0 border-bottom-0 pd-r-15">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Available From
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <DatePicker
                                    className="form-control max_width_100"
                                    scrollableYearDropdown
                                    autoComplete="off"
                                    yearDropdownItemNumber={
                                      global.yearDropdownItemNumber
                                    }
                                    showMonthDropdown
                                    showYearDropdown
                                    // minDate={new Date()}
                                    name="available_from"
                                    dateFormat="MM/dd/yy"
                                    selected={this.state.available_from}
                                    onChange={(data) =>
                                      this.changeHandler({
                                        target: {
                                          type: "date",
                                          name: "available_from",
                                          value: data,
                                        },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}

                              <div className="row align-items-center pd-b-0 border-bottom-0 pd-r-15">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Internal Notes{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    // type="text"
                                    as="textarea"
                                    onChange={this.changeHandler}
                                    name="airbnb_notes"
                                    value={this.state.airbnb_notes}
                                    maxLength="5000"
                                  />
                                  {this.state.errors.airbnb_notes ? (
                                    <div className="text-danger">
                                      {this.state.errors.airbnb_notes}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12 pd-lg-l-30 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0  mg-t-15">
                                <h3 className="mg-l-5 background-head">
                                  Payout Configuration
                                </h3>
                              </div>
                              <div className="row align-items-center pd-b-0 border-bottom-0 pd-r-15">
                                <div className="col-12  pd-l-15 pd-r-15 align-items-center pd-t-0 border-bottom-0">
                                  <div className="pd-lg-l-15 pd-lg-r-30 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0  pet-f mg-b-30 pet w-100">
                                    <div className="col-md-12 p-0 petFeeSection">
                                      <table className="petForm payout_table_w">
                                        <thead>
                                          <tr>
                                            <th className="w-20">
                                              <label className="mb-1">
                                                Type
                                              </label>
                                            </th>

                                            <th className="w-20">
                                              <label className="mb-1">
                                                OH %
                                              </label>
                                            </th>
                                            <th className="w-20">
                                              <label className="mb-1">
                                                Resident %
                                              </label>
                                            </th>
                                            <th className="w-20 text-left">
                                              <label className="mb-1">
                                                Property %
                                              </label>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="table_cnt_top pay-out-tbl">
                                          {this.state.earningConfigurationData.map(
                                            (data, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td
                                                    className=""
                                                    vertical-align="middle"
                                                  >
                                                    <Col
                                                      xs={12}
                                                      className="pd-l-0 pd-r-2 colmn1"
                                                    >
                                                      <span>
                                                        {data.fee_type_name}
                                                      </span>
                                                    </Col>
                                                  </td>

                                                  <td
                                                    className=""
                                                    align="middle"
                                                  >
                                                    <Col
                                                      xs={12}
                                                      className="pd-l-2 pd-r-0 d-flex"
                                                    >
                                                      <InputGroup className="mt-0">
                                                        <div className="input-group-prepend prefix-dollar percanteg-prefix">
                                                          <Form.Control
                                                            onKeyPress={
                                                              global
                                                                .onKeyPressEvent
                                                                .floatValidation
                                                            }
                                                            name="payout_oh"
                                                            maxLength="5"
                                                            value={
                                                              data.payout_oh
                                                                ? data.payout_oh
                                                                    .replace(
                                                                      /\.00$/,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /[&#,+()$~%'":*?<>{}]/gi,
                                                                      ""
                                                                    ) || ""
                                                                : ""
                                                            }
                                                            onChange={(event) =>
                                                              this.handleChangeEarningConfiguration(
                                                                i,
                                                                event
                                                              )
                                                            }
                                                          />
                                                          <span className="input-group-text percanteg_symbl">
                                                            %
                                                          </span>
                                                        </div>
                                                      </InputGroup>
                                                    </Col>
                                                    {data.to_oh_error ? (
                                                      <div className="text-danger">
                                                        {data.to_oh_error}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                  <td className="">
                                                    <Col
                                                      xs={12}
                                                      className="pd-l-2 pd-r-0 d-flex"
                                                    >
                                                      <InputGroup className="mt-0">
                                                        <div className="input-group-prepend prefix-dollar percanteg-prefix">
                                                          <Form.Control
                                                            onKeyPress={
                                                              global
                                                                .onKeyPressEvent
                                                                .floatValidation
                                                            }
                                                            name="payout_resident"
                                                            maxLength="5"
                                                            max="100"
                                                            value={
                                                              data.payout_resident
                                                                ? data.payout_resident
                                                                    .replace(
                                                                      /\.00$/,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /[&#,+()$~%'":*?<>{}]/gi,
                                                                      ""
                                                                    ) || ""
                                                                : ""
                                                            }
                                                            onChange={(event) =>
                                                              this.handleChangeEarningConfiguration(
                                                                i,
                                                                event
                                                              )
                                                            }
                                                          />
                                                          <span className="input-group-text percanteg_symbl">
                                                            %
                                                          </span>
                                                        </div>
                                                      </InputGroup>
                                                    </Col>
                                                    {data.to_resident_error ? (
                                                      <div className="text-danger">
                                                        {data.to_resident_error}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                  <td className=" text-left">
                                                    <Col
                                                      xs={12}
                                                      className="pd-l-2 pd-r-2 colmn1"
                                                    >
                                                      <InputGroup className="mt-0">
                                                        <div className="input-group-prepend prefix-dollar percanteg-prefix">
                                                          <Form.Control
                                                            onKeyPress={
                                                              global
                                                                .onKeyPressEvent
                                                                .floatValidation
                                                            }
                                                            name="payout_property"
                                                            maxLength="5"
                                                            max="100"
                                                            value={
                                                              data.payout_property
                                                                ? data.payout_property
                                                                    .replace(
                                                                      /\.00$/,
                                                                      ""
                                                                    )
                                                                    .replace(
                                                                      /[&#,+()$~%'":*?<>{}]/gi,
                                                                      ""
                                                                    ) || ""
                                                                : ""
                                                            }
                                                            onChange={(event) =>
                                                              this.handleChangeEarningConfiguration(
                                                                i,
                                                                event
                                                              )
                                                            }
                                                          />
                                                          <span className="input-group-text percanteg_symbl">
                                                            %
                                                          </span>
                                                        </div>
                                                      </InputGroup>
                                                    </Col>
                                                    {data.to_property_error ? (
                                                      <div className="text-danger">
                                                        {data.to_property_error}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* rental fields start */}
                              {/* {this.state.property_slug && this.state.slug ? <>
                                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                                  <h3 className="mg-l-5 background-head">Channel Manager</h3>
                                </div>
                                <div className="row align-items-center pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-0 pd-lg-r-15">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Security Deposit{" "}
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="security_deposit"
                                      value={this.state.security_deposit}
                                      maxLength="50"
                                      onKeyPress={
                                        global.onKeyPressEvent.floatValidation
                                      }
                                    />
                                    {this.state.errors.security_deposit ? (
                                      <div className="text-danger">
                                        {this.state.errors.security_deposit}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "security_deposit",
                                        this.state.security_deposit,
                                        "max:50",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Managed by Private{" "}
                                    </label>
                                    <Form.Check
                                      name="is_managed_by_private_person"
                                      type={"checkbox"}
                                      id={`is_managed_by_private_person`}
                                      label=""
                                      className={"ckbox"}
                                      onChange={this.changeHandler}
                                      checked={this.state.is_managed_by_private_person}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Managed by Owner
                                    </label>
                                    <Form.Check
                                      name="is_managed_by_owner"
                                      type={"checkbox"}
                                      id={`is_managed_by_owner`}
                                      label=""
                                      className={"ckbox"}
                                      onChange={this.changeHandler}
                                      checked={this.state.is_managed_by_owner}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Location Description{" "}
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="location_description"
                                      value={this.state.location_description}
                                      maxLength="1000"
                                      as="textarea"
                                    />
                                    {this.state.errors.location_description ? (
                                      <div className="text-danger">
                                        {this.state.errors.location_description}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "location_description",
                                        this.state.location_description,
                                        "max:1000",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                </div>
                              </>: ''} */}
                              {/* rental fields end */}

                              {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}

                              {/* <div className="row align-items-center pd-t-30 pd-b-30 pd-l-30 pd-r-30 border-bottom-0">
                          <div className="col-md-1 pl-0">
                            <label className="form-label text-left pd-b-5">
                              Status
                            </label>
                          </div>

                          <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                            <b
                              className={
                                this.state.status_id ? "active" : "inactive"
                              }
                            ></b>
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() => this.changeStatus()}
                            >
                              <span></span>
                            </div>
                          </div>
                        </div> */}
                            </div>
                          </div>
                        </form>
                      </Tab.Pane>

                      <Tab.Pane eventKey="unitsAmenities">
                        <UnitAmenities
                          unit_slug={this.state.slug}
                          property_slug={this.state.property_slug}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="unitsFees">
                        <UnitCost
                          unit_slug={this.state.slug}
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="unitsDocuments">
                        <UnitDocuments
                          unit_slug={this.state.slug}
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}
                        />
                      </Tab.Pane>
                      {global.userPermissions.checkPermission('units-update') &&
                      <Tab.Pane eventKey="unitscredential">
                        <UnitCredential
                          unit_slug={this.state.slug}
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}
                        />
                      </Tab.Pane>
                      }
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={this.state.importModal}
          onHide={() => this.closeConfirmImportModal()}
          centered
          className="infomodal"
        >
          <Modal.Body className="p-4 border_radius_14 modal-lg">
            <h3 className="text-left pd-l-15 pd-r-15">Amenity Details</h3>
            <div className="row col-md-12 mg-t-40 mg-md-t-0">
              <div className="col-md-6">
                <p className="az-profile-name-text pl-0"> Name:</p>
                <p className="media-body">
                  {this.state.amenity_name ? this.state.amenity_name : ""}
                </p>

                <p className="az-profile-name-text pl-0">Tagline:</p>
                <p className="media-body">
                  {this.state.tag_line ? this.state.tag_line : ""}
                </p>
              </div>

              <div className="col-md-6">
                <p className="az-profile-name-text pl-0">Category:</p>
                <p className="media-body">
                  {this.state.amenity_type_name
                    ? this.state.amenity_type_name
                    : ""}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default UnitsForm;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: FloorPlanImages.jsx                            //
//  Application:  FloorPlan                               //
//  Option: For view & update Floor Plan Images                //
//  Developer: Ashish Kumar                               //
//  Date: 2022-02-25                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Carousel, Form, Modal, Tab, Nav } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import SimpleReactValidator from "simple-react-validator";
// import FloorPlanService from "../../services/FloorPlanService";
import MultiImageService from "../../services/MultiImageService";
import FileWidget from "../fileUploader/FileWidget";
// import floorPlanImage from "./../../assets/images/PropPlaceMainImg.png";
import close from "../../assets/images/close-wel.svg";
import Alerts from "../common/Alerts";
import deleteImg from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
// import infosize from "../../assets/images/Info.svg";
// import pluswIcon from "./../../assets/images/plus.svg";
import Cropper from "react-easy-crop";
// import "../fileUploader/crop.css";
import getCroppedImg from "../fileUploader/cropImage";
import UploadCropedImage from "../fileUploader/UploadCropedImage";
import Slider from "@material-ui/core/Slider";
// import { Draggable } from "react-drag-reorder";
import Gallery from "react-grid-gallery";
import zoomImg from "../../assets/images/zoom.svg";
import addImg from "./../../assets/images/add-img.png";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Dropdown } from "react-bootstrap";
import mainImg from "./../../assets/images/main-img.svg";
import dotIcon from "./../../assets/images/3dots.png";
import Download_img from "../../assets/images/download_svg.svg";

export class FloorPlanImages extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      importModal: false,
      newRelatedFilesDesc: [],
      property_slug: "",
      main_image: "",
      imageUrl: "",
      multiImageList: [],
      multiImageListTotal: 0,
      floorPlanImageListTotal: 0,
      imageLoop: 10,
      multiImageListEdit: [],
      sortOrderForImage: 1,
      isImageEdit: false,
      crop: { x: 0.1, y: 0.1 },
      zoom: 1,
      aspect: 4 / 3,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
      croppedImageArr: undefined,
      display_file_name: "",
      currentFile: [],
      isCrop: false,
      reorderdImg: [],
      sortOrderImg: [],
      dragOff: 1,
      newMultiImageList: [],
      is_main_img: false,
      is_web_img: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeView = this.changeView.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.deleteFloorPlanImg = this.deleteFloorPlanImg.bind(this);
    this.opendeleteFloorPlanImgModal =
      this.opendeleteFloorPlanImgModal.bind(this);
    this.newRelatedFilesDescChange = this.newRelatedFilesDescChange.bind(this);
    this.openFloorPlanListImageModal =
      this.openFloorPlanListImageModal.bind(this);
    this.closeFloorPlanListImageModal =
      this.closeFloorPlanListImageModal.bind(this);
    this.openFloorPlanImgModal = this.openFloorPlanImgModal.bind(this);
    this.closeFloorPlanImgModal = this.closeFloorPlanImgModal.bind(this);
    this.openEditFloorPlanDescModal =
      this.openEditFloorPlanDescModal.bind(this);
    this.closeEditFloorPlanDescModal =
      this.closeEditFloorPlanDescModal.bind(this);
    this.submitModalImage = this.submitModalImage.bind(this);
    this.changeHandlerDesc = this.changeHandlerDesc.bind(this);
    this.cropperSet = this.cropperSet.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.cancelCrop = this.cancelCrop.bind(this);
    this.getChangedPos = this.getChangedPos.bind(this);
    this.EditImgSet = this.EditImgSet.bind(this);
    this.getDownloadFile = this.getDownloadFile.bind(this);
  }

  cropperSet = () => {
    this.setState({ isImageEdit: true });
  };

  EditImgSet = () => {
    this.setState({ isImageEdit: false });
  };

  cancelCrop = () => {
    this.closeEditFloorPlanDescModal();
    this.setState({ isImageEdit: false, isCrop: false, zoom: 0.04 });
  };
  /*file download */
  async getDownloadFile(url, name) {
    // let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  cropImage = async () => {
    await this.setState({ isCrop: true });
    await this.showCroppedImage();
    document.getElementById("left-tabs-example-tab-description").click();
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = async (croppedArea, croppedAreaPixels) => {
    await this.setState({ croppedAreaPixels });
    // await this.showCroppedImage();
    // this.setState({ isImageEdit: false})
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  showCroppedImage = async () => {
    if (this.state.isCrop) {
      const croppedImage = await getCroppedImg(
        this.state.editModeCropImg,
        this.state.croppedAreaPixels,
        this.state.rotation,
        this.state.display_file_name
      );
      this.setState({
        croppedImage: croppedImage.url,
        croppedImageArr: croppedImage.file,
      });
      // console.log(this.state.croppedImageArr, croppedImage, "1");
      this.setState({ isImageEdit: false });
    }
  };
  async componentDidMount() {
    this.getMulitImagesData();
    setTimeout(() => console.clear(), 5000);
  }

  async getMulitImagesData() {
    if (this.props.floor_plan_slug !== undefined) {
      let inputData = {
        path: "floorplan",
        refTableId: 4,
        slug: this.props.floor_plan_slug,
        mediaType: 1,
        property_slug: this.props.property_slug,
      };
      let res = await MultiImageService.getImages(inputData);
      var multiImageList = [];
      var multiImageListEdit = [];
      var multiImageListTotal = 0;
      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotal = multiImageList.length;
        if (multiImageListTotal > 0) {
          multiImageList.forEach((item, i) => {
            multiImageListEdit[item.sort_order] = item;
          });
        }
      }
      // const newMultiImageList =
      //   multiImageListTotal > 0
      //     ? multiImageList.map((opt, i) => ({
      //       src: opt.file_name,
      //       thumbnail: opt.mediumImg,
      //       caption: opt.description,
      //       tags: [{
      //         value: <img src={deleteImg} alt="" onClick={() =>
      //           this.opendeleteFloorPlanImgModal(opt.slug)
      //         } />, key: i
      //       }, { value: <img src={zoomImg} alt="" />, key: i }],
      //     }))
      //     : [];
      multiImageList.map((opt, i) => {
        var src = opt.src;
        var orgFile = opt.file_name;
        var mediumImg = opt.medium_img;
        opt.file_name = mediumImg;
        opt.medium_img = mediumImg;
        opt.editModeCropImg = opt.cropImgSrc; //orgFile;
        multiImageList[i]["caption"] = opt.description ? opt.description : "";
        multiImageList[i]["src"] = orgFile;
        multiImageList[i]["thumbnail"] = mediumImg;
        // multiImageList[i]["thumbnailWidth"] = 1;
        // multiImageList[i]["thumbnailHeight"] = 1;
        // multiImageList[i]["rowHeight"] = 1;
        // multiImageList[i]["maxRows"] = 3;
        // multiImageList[i]["vwidth"] = 1;
        multiImageList[i]["main_image"] = orgFile;
        multiImageList[i]["tags"] = [
          {
            value: (
              <img
                src={deleteImg}
                alt=""
                onClick={() =>
                  {
                   global.userPermissions.checkPermission(
                     "floor-plans-update"
                   ) && (this.opendeleteFloorPlanImgModal(opt.slug))
                 }}
              />
            ),
            key: `delete image ${i}`,
            title: `delete image`,
          },
          {
            value: <img src={zoomImg} alt="" />,
            key: `zoom image ${i}`,
            title: `zoom image`,
          },
          {
            value: (
              <img
                src={Download_img}
                alt=""
                download={true}
                onClick={() => this.getDownloadFile(src, opt.display_file_name)}
              />
            ),
            key: `download image ${i}`,
            title: `download image`,
          },
        ];
      });
      const newMultiImageList = multiImageList;
      this.setState({
        multiImageList: multiImageList,
        multiImageListTotal: multiImageListTotal,
        multiImageListEdit: multiImageListEdit,
        newMultiImageList: newMultiImageList,
      });
    }
  }

  newRelatedFilesDescChange(index, value) {
    let newRelatedFilesDesc = this.state.newRelatedFilesDesc;
    newRelatedFilesDesc[index] = value;
    this.setState({ newRelatedFilesDesc: newRelatedFilesDesc });
  }

  openFloorPlanListImageModal() {
    this.setState({ floorPlanListImageModal: true });
  }

  closeFloorPlanListImageModal() {
    this.setState({ floorPlanListImageModal: false });
  }

  openEditFloorPlanDescModal(
    slug,
    img_src,
    editModeCropImg,
    description,
    display_file_name,
    is_main_img,
    is_web_img,
    currentFile
  ) {
    this.setState({
      openEditFloorPlanDescModal: true,
      slug: slug,
      img_src: img_src,
      editModeCropImg: editModeCropImg,
      description: description,
      display_file_name: display_file_name,
      is_main_img: is_main_img,
      is_web_img: is_web_img,
      currentFile: currentFile,
    });
  }

  closeEditFloorPlanDescModal() {
    this.setState({
      openEditFloorPlanDescModal: false,
      slug: "",
      description: "",
      isImageEdit: false,
      img_src: "",
      editModeCropImg: "",
      croppedImage: "",
      currentFile: null,
      isCrop: false,
      display_file_name: "",
      zoom: 0.4,
    });
  }

  openFloorPlanImgModal(j) {
    this.setState({ addImgModal: true, sortOrderForImage: j });
  }

  closeFloorPlanImgModal() {
    this.setState({ addImgModal: false, slug: "", description: "" });
  }

  opendeleteFloorPlanImgModal(slug) {
    this.closeEditFloorPlanDescModal();
    this.setState({ deleteFloorPlanImgModal: true, slug: slug });
  }

  closedeleteFloorPlanImgModal() {
    this.setState({
      deleteFloorPlanImgModal: false,
      slug: "",
      description: "",
    });
  }

  submitModalImage = async (event) => {
    this.setState({ showSpinner: true, isSubmit: true });
    const FileWidgetObj = new FileWidget();
    const output = await FileWidgetObj.fileUploadFun(
      "floor_plan_form"
      // this.state.newRelatedFilesDesc
    );
    this.closeFloorPlanImgModal();
    if (output.status !== false) {
      if (output.response[0].filesData) {
        if (output.response[0].filesData.length > 0) {
          let inputData = {
            availableFiles: output.response,
            deletedFiles: this.state.deletedFileList,
          };

          if (this.props.floor_plan_slug !== undefined) {
            let res = await MultiImageService.submitMultiImage(inputData);
            if (global.successStatus.includes(res.status)) {
              this.setState({
                showAlertModal: true,
                alertModalType: "success",
                alertModalTitle: "Success",
                alertModalMessage: res.message ? res.message : "Success",
              });
              this.setState({ description: "", is_main_img: "" });
              this.setState({ newRelatedFilesDesc: [], dragOff: 0 });
              this.getMulitImagesData();
            } else {
              let alertMessage = "";
              if (
                res.data.errorCode === "validationFailed" &&
                res.data.errorDetail !== null
              ) {
                let errors = {};
                res.data.errorCode = "Validation Failed";
                res.data.errorDetail.forEach((item) => {
                  errors[item.errorField] = item.errorMessage[0];
                  // alertMessage += item.errorMessage[0]+'\n';
                  res.data.errorMessage = "";
                });
                this.setState({ errors: errors });
              }
              this.setState({
                showAlertModal: true,
                deleteImgModal: false,
                alertModalType: "error",
                alertModalTitle: res.data.message ? res.data.message : "Error!",
                alertModalMessage:
                  alertMessage !== "" ? alertMessage : res.data.message,
              });
            }
          }
        }
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: "The file should be png or jpg.",
      });
    }
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
    this.setState({ showSpinner: false, isSubmit: false });
  };

  async reorderImageListing() {
    this.setState({ showSpinner: true, loading: true });
    if (this.props.floor_plan_slug !== undefined) {
      let inputData = {
        path: "floorplan",
        refTableId: 4,
        slug: this.props.floor_plan_slug,
        mediaType: 1,
        // reorder_images: this.state.reorderdImg,
        reorder_images: this.state.sortOrderImg,
      };
      let res = await MultiImageService.reorderImages(inputData);
      if (global.successStatus.includes(res.status)) {
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "success",
        //   alertModalTitle: "Success",
        //   alertModalMessage: res.message ? res.message : "Success",
        // });
        // this.setState({ multiImageListEdit: [], reorderdImg: [], dragOff: 0 });
        // this.getMulitImagesData();
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          deleteImgModal: false,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
    this.setState({ showSpinner: false, loading: false });
  }

  async setCoverImg(slug, type) {
    let inputUpdateData = "";
    if (type === "Main") {
      inputUpdateData = {
        is_main_img: 1,
        refTableId: 4,
        propertySlug: this.props.floor_plan_slug,
        mediaType: 1,
        slug: slug ? slug : "",
      };
    } else {
      inputUpdateData = {
        is_web_img: 1,
        refTableId: 4,
        propertySlug: this.props.floor_plan_slug,
        mediaType: 1,
        slug: slug ? slug : "",
      };
    }

    let resMain;
    resMain = await MultiImageService.updateSingleImageDesc(inputUpdateData);
    if (global.successStatus.includes(resMain.status)) {
      // this.setState({
      //   showAlertModal: true,
      //   alertModalType: "success",
      //   alertModalTitle: "Success",
      //   alertModalMessage: resMain.message ? resMain.message : "Success",
      // });

      this.setState({ propertyImageListTotal: 0, dragOff: 0 });
      this.getMulitImagesData();
    } else {
      let alertMessage = "";
      if (resMain.data.result.errorDetail !== null) {
        let errors = {};
        resMain.data.errorCode = "Validation Failed";
        resMain.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          // alertMessage += item.errorMessage[0]+'\n';
          resMain.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
      this.setState({
        showSpinner: false,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: resMain.data.errorCode
          ? resMain.data.errorCode
          : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : resMain.data.message,
      });
    }
  }

  async updateImgDesc() {
    let inputUpdateData = {
      slug: this.state.slug ? this.state.slug : "",
      description: this.state.description ? this.state.description : "",
      // is_main_img: this.state.is_main_img === true ? 1 : 0,
      // is_web_img: this.state.is_web_img === true ? 1 : 0,
      refTableId: 4,
      propertySlug: this.props.floor_plan_slug,
      mediaType: 1,
    };
    let resMain;
    if (this.state.isCrop) {
      this.deleteFloorPlanImg(this.state.currentFile.slug);
      // console.log("delete", deleteVar);
      let inputData = {
        fieldName: "modal_name",
        uploadedTo: "s3",
        slug: this.props.floor_plan_slug,
        refTableId: 4,
        destinationFolder: "floorplan",
        displayFileName: "floorplan",
        documentTypeSlug: this.props.floor_plan_slug,
        description: this.state.description,
        // is_main_img: is_main_img,
        mediaType: 1,
        h1: "",
        h2: "",
        sortOrder: this.state.currentFile.sort_order,
        statusId: this.state.currentFile.status_id,
        propertySlug: this.props.property_slug,
        checkValidation: true,
        validationRules: {
          allowedType: "image/jpeg,image/jpg,image/png,image/webp",
          maxFileSize: 1000,
          maxFileSizeType: "mb",
          maxFileAllowed: 10,
          fieldName: "modal_name",
        },
        resizeOptions:
          "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200",
        fileObject: this.state.croppedImageArr,
      };
      // console.log(inputData, "res id");
      let UploadCropedImageObj = new UploadCropedImage();
      resMain = await UploadCropedImageObj.UploadCropedImageToServer(inputData);
      // console.log("res", resMain);
      let inputData1 = {
        availableFiles: resMain.response,
        // deletedFiles: this.state.currentFile,
      };
      // console.log("inputData1", inputData1);
      let res = await MultiImageService.submitMultiImage(inputData1);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({
          description: "",
          img_src: "",
          editModeCropImg: "",
          croppedImage: "",
          zoom: 0.4,
          currentFile: null,
          isCrop: false,
          display_file_name: "",
          slug: "",
          openEditPropDescModal: false,
          isImageEdit: false,
        });
        this.setState({ newRelatedFilesDesc: [], dragOff: 0 });
        this.getMulitImagesData();
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          deleteImgModal: false,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    } else {
      let resMain = await MultiImageService.updateSingleImageDesc(
        inputUpdateData
      );
      if (global.successStatus.includes(resMain.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: resMain.message ? resMain.message : "Success",
        });
        this.closeEditFloorPlanDescModal();
        this.setState({ propertyImageListTotal: 0, dragOff: 0 });
        this.getMulitImagesData();
      } else {
        let alertMessage = "";
        if (resMain.data.result.errorDetail !== null) {
          let errors = {};
          resMain.data.errorCode = "Validation Failed";
          resMain.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            resMain.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: resMain.data.errorCode
            ? resMain.data.errorCode
            : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : resMain.data.message,
        });
      }
    }

    this.setState({ showSpinner: false, isSubmit: false, dragOff: 1 });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
    // this.getData();
  }

  // onImageChange = async (event) => {
  //   if (event.target.files[0] !== undefined) {
  //     this.setState({ showSpinner: true });
  //     const fileData = new FormData();
  //     fileData.append(
  //       "main_image",
  //       event.target.files[0],
  //       event.target.files[0].name
  //     );
  //     let res = await FloorPlanService.uploadFloorPlanImage(fileData);
  //     if (global.successStatus.includes(res.status)) {
  //       this.setState({
  //         main_image: res.data.fileName,
  //         imageUrl: res.data.filePath,
  //       });
  //       this.setState({ showSpinner: false });
  //     } else if (res.status === 413) {
  //       this.setState({
  //         showSpinner: false,
  //         showAlertModal: true,
  //         alertModalType: "error",
  //         alertModalTitle: "Error!",
  //         alertModalMessage: "Content Too Large",
  //       });
  //     } else {
  //       let alertMessage = "";
  //       if (res.data.result.errorDetail !== null) {
  //         let errors = {};
  //         res.data.errorCode = "Validation Failed";
  //         res.data.result.errorDetail.forEach((item) => {
  //           errors[item.errorField] = item.errorMessage[0];
  //           // alertMessage += item.errorMessage[0]+'\n';
  //           res.data.errorMessage = "";
  //         });
  //         this.setState({ errors: errors });
  //       }
  //       this.setState({
  //         showSpinner: false,
  //         showAlertModal: true,
  //         alertModalType: "error",
  //         alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
  //         alertModalMessage:
  //           alertMessage !== "" ? alertMessage : res.data.message,
  //       });
  //     }
  //     setTimeout(
  //       () => this.setState({ showAlertModal: false }),
  //       global.alert_close_time
  //     );
  //   }
  // };

  // async getImgData() {
  //   var resData = {};
  //   let res = await FloorPlanService.getFloorPlanBySlug(this.props.floor_plan_slug);
  //   if (global.successStatus.includes(res.status)) {
  //     resData.imageUrl = res.data.main_image_url
  //       ? res.data.main_image_url
  //       : '';
  //   }
  //   this.setState(resData);
  //   this.props.imgFile(resData.imageUrl);
  // }

  // saveOrImage = async (e) => {
  //   e.preventDefault();
  //   this.setState({ showSpinner: true, isSubmit: true });
  //   if (this.state.main_image) {
  //     let inputImgData = {
  //       tab: 2,
  //       property_slug: this.props.property_slug,
  //       property_floor_plan_name: this.props.floor_plan_name,
  //       main_image: this.state.main_image,
  //     };

  //     if (this.props.floor_plan_slug !== undefined) {
  //       let res = await FloorPlanService.updateFloorPlan(
  //         this.props.floor_plan_slug,
  //         inputImgData
  //       );
  //       if (global.successStatus.includes(res.status)) {
  //         this.setState({
  //           showAlertModal: true,
  //           alertModalType: "success",
  //           alertModalTitle: "Success",
  //           alertModalMessage: res.message ? res.message : "Success",
  //         });
  //       } else {
  //         let alertMessage = "";
  //         if (res.data.result.errorDetail !== null) {
  //           let errors = {};
  //           res.data.errorCode = "Validation Failed";
  //           res.data.result.errorDetail.forEach((item) => {
  //             errors[item.errorField] = item.errorMessage[0];
  //             // alertMessage += item.errorMessage[0]+'\n';
  //             res.data.errorMessage = "";
  //           });
  //           this.setState({ errors: errors });
  //         }
  //         this.setState({
  //           showAlertModal: true,
  //           alertModalType: "error",
  //           alertModalTitle: res.data.message ? res.data.message : "Error!",
  //           alertModalMessage:
  //             alertMessage !== "" ? alertMessage : res.data.message,
  //         });
  //       }
  //     }

  //     setTimeout(
  //       () => this.setState({ showAlertModal: false }),
  //       global.alert_close_time
  //     );
  //   }

  //   const FileWidgetObj = new FileWidget();
  //   const output = await FileWidgetObj.fileUploadFun(
  //     "floor_plan_form",
  //     this.state.newRelatedFilesDesc
  //   );
  //   if (output.status !== false) {
  //     if (output.response[0].filesData) {
  //       if (output.response[0].filesData.length > 0) {
  //         let inputData = {
  //           availableFiles: output.response,
  //           deletedFiles: this.state.deletedFileList,
  //         };

  //         if (this.props.floor_plan_slug !== undefined) {
  //           let res = await MultiImageService.submitMultiImage(inputData);
  //           if (global.successStatus.includes(res.status)) {
  //             this.setState({
  //               showAlertModal: true,
  //               alertModalType: "success",
  //               alertModalTitle: "Success",
  //               alertModalMessage: res.message ? res.message : "Success",
  //             });
  //             this.setState({ viewMode: 0 });
  //             this.setState({ newRelatedFilesDesc: [] });
  //             this.getMulitImagesData();
  //           } else {
  //             let alertMessage = "";
  //             if (res.data.result.errorDetail !== null) {
  //               let errors = {};
  //               res.data.errorCode = "Validation Failed";
  //               res.data.result.errorDetail.forEach((item) => {
  //                 errors[item.errorField] = item.errorMessage[0];
  //                 // alertMessage += item.errorMessage[0]+'\n';
  //                 res.data.errorMessage = "";
  //               });
  //               this.setState({ errors: errors });
  //             }
  //             this.setState({
  //               showSpinner: false,
  //               showAlertModal: true,
  //               alertModalType: "error",
  //               alertModalTitle: res.data.errorCode
  //                 ? res.data.errorCode
  //                 : "Error!",
  //               alertModalMessage:
  //                 alertMessage !== "" ? alertMessage : res.data.message,
  //             });
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     this.setState({
  //       showAlertModal: true,
  //       alertModalType: "error",
  //       alertModalTitle: "Error!",
  //       alertModalMessage: "The file should be png or jpg.",
  //     });
  //   }
  //   if (this.state.floorPlanImageListTotal > 0) {
  //     let inputUpdateData = {
  //       image_data: this.state.floorPlanImageList
  //         ? this.state.floorPlanImageList
  //         : [],
  //     };

  //     let res = await MultiImageService.updateMultiImage(inputUpdateData);
  //     if (global.successStatus.includes(res.status)) {
  //       this.setState({
  //         showAlertModal: true,
  //         alertModalType: "success",
  //         alertModalTitle: "Success",
  //         alertModalMessage: res.message ? res.message : "Success",
  //       });
  //       this.setState({ viewMode: 0, floorPlanImageListTotal: 0 });
  //       this.getMulitImagesData();
  //     } else {
  //       let alertMessage = "";
  //       if (res.data.result.errorDetail !== null) {
  //         let errors = {};
  //         res.data.errorCode = "Validation Failed";
  //         res.data.result.errorDetail.forEach((item) => {
  //           errors[item.errorField] = item.errorMessage[0];
  //           // alertMessage += item.errorMessage[0]+'\n';
  //           res.data.errorMessage = "";
  //         });
  //         this.setState({ errors: errors });
  //       }
  //       this.setState({
  //         showSpinner: false,
  //         showAlertModal: true,
  //         alertModalType: "error",
  //         alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
  //         alertModalMessage:
  //           alertMessage !== "" ? alertMessage : res.data.message,
  //       });
  //     }
  //   }
  //   this.setState({ showSpinner: false, isSubmit: false });
  //   setTimeout(
  //     () => this.setState({ showAlertModal: false }),
  //     global.alert_close_time
  //   );
  //   this.setState({ viewMode: 0 });
  //   this.getImgData();
  // };

  async deleteFloorPlanImg(slug) {
    this.setState({ showSpinner: true });
    let inputData = {
      path: "floorplan",
      refTableId: 4,
      slug: this.props.floor_plan_slug,
      mediaType: 1,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closeEditFloorPlanDescModal();
      this.closedeleteFloorPlanImgModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ multiImageListEdit: [], reorderdImg: [], dragOff: 0 });
      this.getMulitImagesData();
    } else {
      this.closedeleteFloorPlanImgModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  confirmImportModal(i) {
    this.setState({ importModal: true });
    this.setState({ carouselZoom: i });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value = event.target.value;
    let floorPlanImageList = [...this.state.multiImageList];
    var floorPlanImageListTotal = 0;

    if (event.target.name === "description") {
      floorPlanImageList[index][event.target.name] = value;
      floorPlanImageListTotal = floorPlanImageList.length;
      this.setState({
        floorPlanImageList: floorPlanImageList,
        floorPlanImageListTotal: floorPlanImageListTotal,
      });
    } else {
      input[event.target.name] = value;
      this.setState({ [event.target.name]: value, input: input });
    }
  };

  changeHandlerDesc = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    this.getMulitImagesData();
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
    } else {
      this.setState({ viewMode: 1 });
    }
  }

  getChangedPos = (currentPos, newPos) => {
    let imgUpdatedArray = [];
    [...Array(this.state.imageLoop)].map((e, j) => {
      if (this.state.multiImageListEdit[j + 1] !== undefined) {
        imgUpdatedArray.push(this.state.multiImageListEdit[j + 1]);
      } else {
        imgUpdatedArray.push({});
      }
    });
    let newimgArray = this.array_move(imgUpdatedArray, currentPos, newPos);

    newimgArray.map((item, k) => {
      if (newimgArray[k].sort_order !== undefined) {
        newimgArray[k].sort_order = k + 1;
      }
    });
    setTimeout(() => {
      this.setState({ reorderdImg: newimgArray });
      this.reorderImageListing();
    }, 10);
  };

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  render() {
    const SortableItem = SortableElement(({ value }) => (
      <div className="list-img">
        <div className="addImg uploaded">
          <div className="label-img">
            {value.is_main_img === 1 ? (
              <img src={mainImg} alt="" title="Main image" />
            ) : (
              ""
            )}
          </div>

          <Dropdown className="d-flex justify-content-end">
            <Dropdown.Toggle variant="secondary-" className="pd-0">
              <div className="navMob d-flex align-items-center">
                <img src={dotIcon} alt="" width="35px" title="Edit" />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tx-13">
              <Dropdown.Item
                onClick={() => {
                  this.openEditFloorPlanDescModal(
                    value.slug,
                    value.file_name,
                    value.editModeCropImg,
                    value.description,
                    value.is_main_img === 1 ? true : false,
                    value.is_web_img === 1 ? true : false,
                    value.display_file_name,
                    value
                  );
                }}
              >
                Edit
              </Dropdown.Item>
              {value.is_main_img === 0 ? (
                <Dropdown.Item
                  onClick={() => this.setCoverImg(value.slug, "Main")}
                >
                  Set as main image
                </Dropdown.Item>
              ) : (
                ""
              )}

              <Dropdown.Item
                onClick={() => this.opendeleteFloorPlanImgModal(value.slug)}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <figure>
            <img src={value.medium_img} alt="" title="Drag" />
          </figure>
        </div>
      </div>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="list-section d-flex flex-wrap">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${value.slug}`}
              sortIndex={index}
              index={index}
              value={value}
            />
          ))}
          <div
            className="addImg addNewimage- addfloorplangNewimage"
            onClick={() => this.openFloorPlanImgModal()}
          >
            <figure className="d-flex justify-content-center align-items-center flex-column h-100">
              <img src={addImg} alt="" className="plus" />
              <span>Add more</span>
            </figure>
          </div>
        </div>
      );
    });

    const onSortEnd = (oldIndex, newIndex) => {
      var oldIndex1 = oldIndex.oldIndex;
      var newIndex1 = oldIndex.newIndex;

      let newimgArray = arrayMoveImmutable(
        this.state.multiImageList,
        oldIndex1,
        newIndex1
      );
      this.setState({ multiImageList: newimgArray, sortOrderImg: newimgArray });
      newimgArray.map((item, k) => {
        if (newimgArray[k].sort_order !== undefined) {
          newimgArray[k].sort_order = k + 1;
        }
      });
      setTimeout(() => {
        this.reorderImageListing();
      }, 100);
    };
    console.clear();
    return (
      <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15 swipersectionBtns">
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-15 swiperBtns">
          {this.state.viewMode === 0 && global.userPermissions.checkPermission('floor-plans-update')? (
            <button
              className="btn-success-outline-small mr-3"
              onClick={this.changeView}
            >
              <img src={editIcon} alt="Edit" />
              Edit
            </button>
          ) : (
            <div className="d-flex">
              <button
                type="button"
                className="btn-success-outline-small mr-3"
                onClick={this.changeView}
              >
                Cancel
              </button>
              {/* <button
                type="button"
                className="btn-success ml-3"
                onClick={
                  this.state.viewMode === 2
                    ? this.updateImgDec
                    : this.saveOrImage
                }
                disabled={this.state.isSubmit ? true : false}
              >
                {" "}
                {this.state.viewMode === 2 ? "Save" : "Save"}{" "}
              </button> */}
            </div>
          )}
        </div>
        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>
        {this.state.viewMode === 0 ? (
          <React.Fragment>
            <div className="row row-xs align-items-center justify-content-center pd-t-10 pd-b-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-20 mg-l-2 addmultiImages- floorplanImagsection">
              {this.state.multiImageListTotal > 0 ? (
                <>
                  <Gallery
                    images={
                      this.state.newMultiImageList
                        ? this.state.newMultiImageList
                        : ""
                    }
                    enableImageSelection={false}
                    showImageCount={false}
                  />
                  {/* <ul className="d-flex flex-wrap">
                {this.state.multiImageListTotal > 0
                  ? this.state.multiImageList.map((option, i) => (
                    <li key={i}>
                      <div className="avatar-squre-listing mg-b-0">
                        <figure>
                          <img
                            src={deleteImg}
                            className="del-img"
                            alt="delete image"
                            onClick={() =>
                              this.opendeleteFloorPlanImgModal(option.slug)
                            }
                          />
                          <Link
                            to={"#"}
                            className="overlay"
                            onClick={() => this.confirmImportModal(i)}
                          >
                            <span>
                              <img
                                src={deleteImg}
                                alt="delete image"
                                onClick={() => {
                                  this.opendeleteFloorPlanImgModal(
                                    option.slug
                                  );
                                }}
                              />
                            </Link>
                          </figure>
                        </div>
                      </li>
                    ))
                  : "There are no images added in the system. You may add them by clicking Edit button."}
              </ul> */}
                </>
              ) : (
                "There are no images added in the system. You may add them by clicking Edit button."
              )}
            </div>

            {/* Floor Plan listing images modal */}
            <Modal
              show={this.state.importModal}
              onHide={() => this.closeConfirmImportModal()}
              centered
              className="propertylistmodal"
            >
              <Modal.Body>
                <span
                  className="welcomeClose"
                  onClick={() => this.closeConfirmImportModal()}
                >
                  <img src={close} alt="" />
                </span>
                <Carousel
                  activeIndex={this.state.carouselZoom}
                  onSelect={this.confirmImportModal}
                  prevIcon={
                    this.state.multiImageListTotal === 1 ? (
                      false
                    ) : (
                      <span
                        aria-hidden="true"
                        className="carousel-control-prev-icon"
                      />
                    )
                  }
                  nextIcon={
                    this.state.multiImageListTotal === 1 ? (
                      false
                    ) : (
                      <span
                        aria-hidden="true"
                        className="carousel-control-next-icon"
                      />
                    )
                  }
                >
                  {this.state.multiImageListTotal > 0
                    ? this.state.multiImageList.map((option, i) => (
                        <Carousel.Item key={i}>
                          <img
                            src={option.file_name}
                            className="d-block w-100 op-3"
                            alt="..."
                          />
                          {option.description !== undefined &&
                          option.description !== null &&
                          option.description !== "" &&
                          option.description !== "undefined" ? (
                            <Carousel.Caption>
                              <p>
                                {option.description ? option.description : ""}
                              </p>
                            </Carousel.Caption>
                          ) : (
                            ""
                          )}
                        </Carousel.Item>
                      ))
                    : ""}
                </Carousel>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        ) : (
          ""
        )}
        {this.state.viewMode === 1 ? (
          <>
            <div className="dashboardRightcard- background-none p-0 floorplanImage">
              <div className="m-0">
                {/* new Design add images */}
                <div className="row row-xs align-items-center pd-t-0 pd-b-30 addmultiImages- floorplanImagviewsection pd-l-10 pd-r-20 ">
                  <SortableList
                    items={this.state.multiImageList}
                    onSortEnd={onSortEnd}
                    axis={"xy"}
                    distance={1}
                    // useDragHandle="true"
                    //pressDelay={200}
                    pressThreshold={5}
                  />

                  {/* <div className="addImg addNewimage" onClick={() => this.openFloorPlanImgModal()}>
                              <figure className="d-flex justify-content-center align-items-center flex-column h-100">
                                <img
                                  src={addImg}
                                  alt=""
                                  className="plus"
                                />
                                <span>Add more</span>
                              </figure>
                            </div> */}
                  {/* <ul className="d-flex flex-wrap">
                    {this.state.dragOff === 1 && this.state.multiImageListTotal > 0 ? (
                      <>
                        <Draggable onPosChange={this.getChangedPos}>
                          {[...Array(this.state.imageLoop)].map((e, j) => {
                            return (
                              <React.Fragment key={j}>
                                {this.state.multiImageListEdit[j + 1] !==
                                  undefined ? (
                                  <li>
                                    <div className="addImg uploaded">
                                      <figure>
                                        <img
                                          src={
                                            this.state.multiImageListEdit[j + 1]
                                              .medium_img
                                              ? this.state.multiImageListEdit[
                                                j + 1
                                              ].medium_img
                                              : ""
                                          }
                                          alt=""
                                          onClick={() => {
                                            this.openEditFloorPlanDescModal(
                                              this.state.multiImageListEdit[
                                                j + 1
                                              ].slug,
                                              this.state.multiImageListEdit[
                                                j + 1
                                              ].file_name,
                                              this.state.multiImageListEdit[
                                                j + 1
                                              ].description,
                                              this.state.multiImageListEdit[
                                                j + 1
                                              ].display_file_name,
                                              this.state.multiImageListEdit[
                                              j + 1
                                              ]
                                            );
                                          }}
                                        />
                                        <img
                                          src={pluswIcon}
                                          alt=""
                                          className="plus"
                                        />
                                      </figure>
                                      {j === 0 ? <h5>Main Image</h5> : ""}
                                    </div>
                                  </li>
                                ) : (
                                  <li key={j}>
                                    <div className="addImg">
                                      <figure
                                        onClick={() =>
                                          this.openFloorPlanImgModal(j + 1)
                                        }
                                      >
                                        <img
                                          src={pluswIcon}
                                          alt=""
                                          className="plus"
                                        />
                                      </figure>
                                      {j === 0 ? <h5>Main Image</h5> : ""}
                                    </div>
                                  </li>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </Draggable>
                      </>
                    ) : (
                      <>
                        {[...Array(this.state.imageLoop)].map((e, j) => {
                          return (
                            <React.Fragment key={j}>
                              {this.state.multiImageListEdit[j + 1] !==
                                undefined ? (
                                <li>
                                  <div className="addImg uploaded">
                                    <figure>
                                      <img
                                        src={
                                          this.state.multiImageListEdit[j + 1]
                                            .file_name
                                            ? this.state.multiImageListEdit[
                                              j + 1
                                            ].file_name
                                            : ""
                                        }
                                        alt=""
                                        onClick={() => {
                                          this.openEditFloorPlanDescModal(
                                            this.state.multiImageListEdit[j + 1]
                                              .slug,
                                            this.state.multiImageListEdit[j + 1]
                                              .file_name,
                                            this.state.multiImageListEdit[j + 1]
                                              .description,
                                            this.state.multiImageListEdit[j + 1]
                                              .display_file_name,
                                            this.state.multiImageListEdit[j + 1]
                                          );
                                        }}
                                      />
                                      <img
                                        src={pluswIcon}
                                        alt=""
                                        className="plus"
                                      />
                                    </figure>
                                    {j === 0 ? <h5>Main Image</h5> : ""}
                                  </div>
                                </li>
                              ) : (
                                <li key={j}>
                                  <div className="addImg">
                                    <figure
                                      onClick={() =>
                                        this.openFloorPlanImgModal(j + 1)
                                      }
                                    >
                                      <img
                                        src={pluswIcon}
                                        alt=""
                                        className="plus"
                                      />
                                    </figure>
                                    {j === 0 ? <h5>Main Image</h5> : ""}
                                  </div>
                                </li>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </>
                    )}
                  </ul> */}
                </div>

                {/* end new Design add images */}
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <Modal show={this.state.deleteFloorPlanImgModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Are you sure?</h3>
              <p>
                Do you really want to delete this image? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteFloorPlanImgModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteFloorPlanImg(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Add image popup */}

        <Modal show={this.state.addImgModal} centered>
          <Modal.Body className="width_402 border_radius_14">
            <form id="floor_plan_form" className="text-left">
              <div>
                <figure className="mg-auto m-auto text-center">
                  <FileWidget
                    fieldName="modal_name"
                    fieldTitle="Drop image here"
                    fieldLabel="Drop image here"
                    checkValidation="true"
                    allowedType="image/jpg,image/jpeg,image/png"
                    maxFileSize={1000}
                    maxFileSizeType="mb"
                    maxFileAllowed={10}
                    uploadOn="s3"
                    slug={this.props.floor_plan_slug}
                    propertySlug={this.props.property_slug}
                    refTableId={4}
                    displayFileName="floorplan"
                    documentTypeSlug={this.props.floor_plan_slug}
                    description={this.state.description}
                    mediaType={1}
                    sortOrder={this.state.sortOrderForImage}
                    statusId={1}
                    helpText="to download sample file."
                    sampleFileUrl="/sample.jpg"
                    drag="true"
                    dragText="Drop image here"
                    inputClassName="form-control"
                    labelClassName="form-label"
                    formID="floor_plan_form"
                    uploadType="single"
                    resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
                    destinationFolder="floorplan"
                    uploadedFiles={this.state.profile_image2}
                    deletedFiles={this.state.deletedFileList}
                    acceptOnly="image/jpg,image/jpeg,image/png"
                    displayPreviewStyle="vertical"
                    deleteImgModal={this.state.addImgModal}
                    newRelatedFilesDescChange={this.newRelatedFilesDescChange}
                    isModal="yes"
                    closeFloorPlanImgModal={this.closeFloorPlanImgModal}
                  />
                </figure>
                <label className="text-left">Description</label>
                <Form.Control
                  onChange={this.changeHandlerDesc}
                  name="description"
                  value={this.state.description}
                  className="form-control max_width_100 crop-text"
                  type="text"
                  rows="4"
                  as="textarea"
                  autoComplete="off"
                  resize="resize"
                  maxLength="255"
                />
                <div className="clearfix"> </div>
                <div className="d-flex justify-content-between mt-3 row">
                  <button
                    type="button"
                    className="mx-auto m-0 btn btn-outline-light btn-block"
                    onClick={() => this.closeFloorPlanImgModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="mx-auto m-0 btn btn btn-success btn-block"
                    onClick={(e) => this.submitModalImage(e)}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* New cropper modalbox */}

        <Modal
          show={this.state.openEditFloorPlanDescModal}
          onHide={this.closeEditFloorPlanDescModal}
          centered
          className="cropModal"
        >
          <Modal.Header>
            <h2>Edit Photo</h2>
            <img
              src={close}
              onClick={() => this.closeEditFloorPlanDescModal()}
              className="mb-0"
            />
          </Modal.Header>
          <Modal.Body className="width_402 p-2  pt-4  pb-4 border_radius_14 modal-body">
            <div className="col-md-7">
              {this.state.isImageEdit ? (
                <figure className="mg-auto m-auto text-center">
                  <Cropper
                    minZoom={0.1}
                    image={this.state.editModeCropImg}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={this.state.aspect}
                    restrictPosition={false}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                    objectFit="horizontal-cover" //"vertical-cover" "contain" "auto-cover"
                  />
                </figure>
              ) : (
                <figure className="mg-auto m-auto text-center">
                  <img
                    src={
                      this.state.croppedImage
                        ? this.state.croppedImage
                        : this.state.img_src
                    }
                    alt=""
                    className="w-100"
                  />
                </figure>
              )}
            </div>
            <div className="col-md-5 pr-3">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="description"
              >
                <Nav variant="pills" className="flex-row">
                  <Nav.Item>
                    <Nav.Link eventKey="description" onClick={this.EditImgSet}>
                      Description
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Edit" onClick={this.cropperSet}>
                      Edit
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="description">
                    <label className="form-label text-left pd-b-5">
                      Description
                    </label>
                    <Form.Control
                      onChange={this.changeHandlerDesc}
                      name="description"
                      value={this.state.description}
                      className="form-control max_width_100 crop-text"
                      type="text"
                      rows="2"
                      as="textarea"
                      autoComplete="off"
                      resize="resize"
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Edit" className="d-flex">
                    <div className="controls d-flex w-100 flex-column">
                      <p className="az-profile-name-text pl-0">Zoom</p>
                      <Slider
                        value={this.state.zoom}
                        min={1}
                        max={10}
                        step={1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => this.onZoomChange(zoom)}
                        // classes={{ container: "slider" }}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between w-100 m-0 pl-2 pr-2  ">
              <img
                src={deleteImg}
                alt=""
                onClick={() =>
                  this.opendeleteFloorPlanImgModal(this.state.slug)
                }
                className="removeFile btn-multilist-outline-danger w-auto mt-2 mb-0 hand-cursor"
              />
              {this.state.isImageEdit ? (
                <>
                  <button
                    type="button"
                    className="mx-auto m-0 btn btn-outline-light btn-block"
                    onClick={this.cancelCrop}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="m-0 btn col-md-3 btn btn-success btn-block"
                    onClick={this.cropImage}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Edit Image"}
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="mx-auto m-0 btn btn-outline-light btn-block"
                    onClick={() => this.closeEditFloorPlanDescModal()}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="m-0 btn btn btn-success btn-block"
                    onClick={() => this.updateImgDesc(this.state.slug)}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Save"}
                  </button>
                </>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default FloorPlanImages;

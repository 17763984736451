////////////////////////////////////////////////////////////
//     							                          //
//  Program: HomeSharingInquiryView.jsx                   //
//  Application:  Home Sharing Inquiry View               //
//  Option: Show the detail of Home Sharing Inquiry       //
//  Developer: Devendra Kumar                             //
//  Date: 20-06-2023                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Container, Modal } from "react-bootstrap";
import modaldeleteicon from "./../../assets/images/delete.svg";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import Alerts from "../common/Alerts";
import ListingService from "../../services/ListingService";
import LisitingSidebar from "../common/LisitingSidebar";
import deleteicon from "../../assets/images/delete.svg";

export class HomeSharingInquiryView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      errors: {},
      isSubmit: false,
      publishData: false,
      description: "",
      title: "",
      inquiryData: {},
      upload_date_time: "",
      slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug,
      content_type: "",
      sort_order: "",
      confirm_back: 0,
    };
    this.openDeleteInquiryModal = this.openDeleteInquiryModal.bind(this);
    this.closedeleteInquiryModal = this.closedeleteInquiryModal.bind(this);
  }
  async componentDidMount() {
    this.setState({ publishData: true });
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};

      let res = await ListingService.getResidentDataBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
      }
      this.setState({ inquiryData: resData });
    }
    this.setState({ showSpinner: false, loading: false });
    this.setState({ publishData: false });
  }
  openDeleteInquiryModal(slug) {
    this.setState({ deleteInquiryModal: true, slug: slug });
  }
  closedeleteInquiryModal() {
    this.setState({ deleteInquiryModal: false, slug: "" });
  }
  async deleteHomeSharingInquiry(slug) {
    this.setState({ isSubmit: true });
    let res = await ListingService.deleteHomeSharingInquiry(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" +
              this.state.property_slug +
              "/homesharing-inquiries"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <LisitingSidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-12">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.state.inquiryData.property_name}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      <span
                        onClick={() => {
                          this.openDeleteInquiryModal(this.state.slug);
                        }}
                        className="deleteicon mg-r-20"
                      >
                        <img src={deleteicon} alt="" />
                      </span>
                      <Link
                        to={
                          window.location.pathname.includes("/info")
                            ? "/homesharing-inquiries/" +
                              this.state.slug +
                              "/" +
                              this.state.property_slug +
                              "/update"
                            : "/properties/view/" +
                              this.state.slug +
                              "/" +
                              this.state.property_slug +
                              "/homesharing-inquiries/update"
                        }
                        className="mg-l-2"
                      >
                        <button className="btn-success-outline-small">
                          <img src={editIcon} alt="Edit" />
                          Edit
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="row align-items- pd-b-0 border-bottom-0 mg-t-20 pd-xs-l-0 pd-xs-r-0  pd-lg-l-15 pd-lg-r-15">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                        <p className="az-profile-name-text pl-0 ">
                          Property Name
                        </p>
                        <p className="media-body pb-0">
                          {this.state.inquiryData
                            ? this.state.inquiryData.property_name
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                        <p className="az-profile-name-text pl-0 ">Unit Name</p>
                        <p className="media-body pb-0">
                          {this.state.inquiryData
                            ? this.state.inquiryData.unit_name
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                        <p className="az-profile-name-text pl-0 ">Name</p>
                        <p className="media-body pb-0">
                          {this.state.inquiryData
                            ? this.state.inquiryData.first_name !== null
                              ? this.state.inquiryData.first_name
                              : "" + " " + this.state.inquiryData.last_name !==
                                null
                              ? this.state.inquiryData.last_name
                              : ""
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                        <p className="az-profile-name-text pl-0 ">Status</p>
                        <p className="media-body pb-0">
                          {this.state.inquiryData
                            ? this.state.inquiryData.inquiries_status
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                        <p className="az-profile-name-text pl-0 ">Notes</p>
                        <p className="media-body pb-0">
                          {this.state.inquiryData
                            ? this.state.inquiryData.notes
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteInquiryModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteInquiryModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this Inquiry? This process cannot
                be undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteInquiryModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteHomeSharingInquiry(this.state.slug)}
                  disabled={this.state.publishData ? true : false}
                >
                  {this.state.publishData ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default HomeSharingInquiryView;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Inquiry.jsx                                  //
//  Application: Inquiry                                  //
//  Option: List all Inquiry                              //
//  Developer: Ashish Kumar					                      //
//  Date: 2023-07-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import InquiryService from "../../services/InquiryService";
import UnitsService from "../../services/UnitsService";
import ChannelService from "../../services/ChannelService";
import { Container, Modal, InputGroup, Form, Tab, Nav } from "react-bootstrap";
import Alerts from "../common/Alerts";
import Nodata from "../../assets/images/to-do.png";
import CalendarSidebar from "../common/CalendarSidebar";
import Moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import queryString from "query-string";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyReservationSidebar from "../common/AllPropertyReservationSidebar";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";

let queryParams = queryString.parse(window.location.search);

export class Inquiry extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      errors: {},
      importModal: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      module_search: "",
      is_search: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      unitsFilter: [],
      filter_value: "",
      selectChannelList: [],
      selectedChannelList: [],
      newChannelList: [],
      guestList: [],
      newGuestList: [],
      selectedGuestList: [],
      tab: "active",
      inquiryTabData: localStorage.getItem("inquiry_tab_data")
        ? localStorage.getItem("inquiry_tab_data")
        : "",
      activeClass: "activeTab",
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "from_date",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config2: {
        sort: {
          column: "from_date",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config3: {
        sort: {
          column: "created_at",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "from_date",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_name",
          text: "Unit",
          className: "unit_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record && record.unit_name ? record.unit_name : "";
          },
        },
        {
          key: "guest_name",
          text: "Guest",
          className: "guest_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record && record.guest_name ? record.guest_name : "";
          },
        },
        {
          key: "from_date",
          text: "Check-In",
          className: "from_date hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.from_date
              ? Moment(record.from_date).format(global.dateFormat)
              : "";
          },
        },
        {
          key: "to_date",
          text: "Check-Out",
          className: "to_date hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.to_date
              ? Moment(record.to_date).format(global.dateFormat)
              : "";
          },
        },
        {
          key: "created_at",
          text: "Date Entered",
          className: "created_at hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.created_at
              ? Moment(record.created_at).format(global.dateTimeFormat)
              : "";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      switch (this.state.inquiryTabData) {
        case "active":
          document.getElementById("activeTab").click();
          break;
        case "inactive":
          document.getElementById("inactiveTab").click();
          break;
        default:
          document.getElementById("activeTab").click();
      }
    }
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "inquiry_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "inquiry_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      switch (this.state.inquiryTabData) {
        case "active":
          document.getElementById("activeTab").click();
          break;
        case "inactive":
          document.getElementById("inactiveTab").click();
          break;
        default:
          document.getElementById("activeTab").click();
      }
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    var res = await InquiryService.getInquiryList(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get channel data list */
  async getChannelList() {
    this.setState({ showSpinner: true, loading: true });
    var selectChannelList = [];
    var selectChannelListTotal = 0;

    let res = await ChannelService.getChannel("is_dropdown=1");
    if (global.successStatus.includes(res.status)) {
      selectChannelList = res.data ? res.data : [];
      selectChannelListTotal = selectChannelList ? selectChannelList.length : 0;
    }
    const newChannelList =
      selectChannelListTotal > 0
        ? selectChannelList.map(({ slug, channel_name }) => ({
            value: slug,
            label: channel_name,
          }))
        : [
            { value: "1", label: "Property Website" },
            { value: "2", label: "Airbnb" },
            { value: "3", label: "OTA" },
          ];
    this.setState({
      selectChannelList: selectChannelList,
      selectChannelListTotal: selectChannelListTotal,
      newChannelList: newChannelList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      this.getChannelList();
      this.setState({ isSearch: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeChannel(value) {
    let set_channel = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        set_channel.push(item.value);
      });
    }
    const set_channel_value = value !== null ? value.value : [];
    this.setState({
      selectedChannelList: value,
      set_channel: set_channel_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customFilter() {
    let avaliableFrom = this.state.from_date
      ? Moment(this.state.from_date).format("YYYY-MM-DD")
      : "";
    let avaliableTo = this.state.to_date
      ? Moment(this.state.to_date).format("YYYY-MM-DD")
      : "";
    let filterValue = this.state.filter_value ? this.state.filter_value : "";
    let queryString =
      "filter_value=" +
      filterValue +
      "&checkin_date=" +
      avaliableFrom +
      "&checkout_date=" +
      avaliableTo +
      "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      checkin_date: "",
      checkout_date: "",
      booked_date_to: "",
      booked_date: "",
      unit_slug: "",
      guest_name: "",
      selectedUnitList: [],
      selectedResidentList: [],
      confirmation_code: "",
      guest_paid: "",
      set_channel: "",
      selectedChannelList: [],
    });
    this.setState({ module_search: "" });
  }

  filterData() {
    let checkIn = this.state.checkin_date
      ? Moment(this.state.checkin_date).format("YYYY-MM-DD")
      : "";
    let checkInTo = this.state.checkin_date_to
      ? Moment(this.state.checkin_date_to).format("YYYY-MM-DD")
      : "";
    let checkOut = this.state.checkout_date
      ? Moment(this.state.checkout_date).format("YYYY-MM-DD")
      : "";
    let checkOutTo = this.state.checkout_date_to
      ? Moment(this.state.checkout_date_to).format("YYYY-MM-DD")
      : "";
    let booked = this.state.booked_date
      ? Moment(this.state.booked_date).format("YYYY-MM-DD")
      : "";
    let bookedTo = this.state.booked_date_to
      ? Moment(this.state.booked_date_to).format("YYYY-MM-DD")
      : "";
    let queryString =
      "checkin_date=" +
      checkIn +
      "&checkin_date_to=" +
      checkInTo +
      "&checkout_date=" +
      checkOut +
      "&checkout_date_to=" +
      checkOutTo +
      "&booked_date=" +
      booked +
      "&booked_date_to=" +
      bookedTo +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&listing_name=" +
      (this.state.listing_name ? this.state.listing_name : "") +
      "&guest_name=" +
      (this.state.guest_name ? this.state.guest_name : "") +
      "&confirmation_code=" +
      (this.state.confirmation_code ? this.state.confirmation_code : "") +
      "&channel_slug=" +
      (this.state.set_channel ? this.state.set_channel : "") +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.checkin_date
      ? "Check-in Date From - " +
        Moment(this.state.checkin_date).format(global.dateFormat)
      : "";
    moduleSearchString = this.state.checkin_date_to
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Check-in Date To - " +
          Moment(this.state.checkin_date_to).format(global.dateFormat)
        : " Check-in Date To - " +
          Moment(this.state.checkin_date_to).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.checkout_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Check-out Date From - " +
          Moment(this.state.checkout_date).format(global.dateFormat)
        : " Check-out Date From - " +
          Moment(this.state.checkout_date).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.checkout_date_to
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Check-out Date To - " +
          Moment(this.state.checkout_date_to).format(global.dateFormat)
        : " Check-out Date To - " +
          Moment(this.state.checkout_date_to).format(global.dateFormat)
      : moduleSearchString;

    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Unit - " +
          this.state.selectedUnitList.label
        : " Unit - " + this.state.selectedUnitList.label
      : moduleSearchString;
    moduleSearchString = this.state.guest_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Guest - " + this.state.guest_name
        : " Guest - " + this.state.guest_name
      : moduleSearchString;
    moduleSearchString = this.state.listing_name
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Listing Name - " +
          this.state.listing_name
        : " Listing Name - " + this.state.listing_name
      : moduleSearchString;
    moduleSearchString = this.state.set_channel
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Channel - " +
          this.state.selectedChannelList.label
        : " Channel - " + this.state.selectedChannelList.label
      : moduleSearchString;
    moduleSearchString = this.state.status_id
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;
    if (
      this.state.checkin_date ||
      this.state.checkin_date_to ||
      this.state.checkout_date ||
      this.state.checkout_date_to ||
      this.state.booked_date ||
      this.state.unit_slug ||
      this.state.listing_name ||
      this.state.guest_name ||
      this.state.confirmation_code ||
      this.state.set_channel ||
      this.state.guest_paid ||
      this.state.status_id ||
      this.state.booked_date_to
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(queryString);
    }
  }

  exportToggle = () => {
    this.setState({ isSubmit: false });
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
    } else {
      this.setState({ isModel: 1 });
    }
  };

  resetDate() {
    this.setState({ filter_value: "", from_date: "", to_date: "" });
    this.getData("is_list=1");
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () =>
            this.getData(queryString + "&tab=" + this.state.tab + "&is_list=1"),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString + "&tab=" + this.state.tab + "&is_list=1");
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Inquiry";
  }

  rowClickedHandler = (event, data, rowIndex) => {
    return this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "/properties/view/" +
            data.property_slug +
            "/inquiry/view/" +
            data.slug
        : "/inquiry/view/" + data.property_slug + "/" + data.slug
    );
  };

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  handleChange = (file) => {
    this.setState({ file_name: file.name });
    this.setState({ file_url: file });
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <CalendarSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyReservationSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                    </div>
                  </div>
                  {this.props.match.params.propertySlug === undefined ? (
                    <>
                      <div className="row pd-lg-l-15 pd-xs-l-15">
                        <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                          <OutSidePropertySelectBox
                            propertySlug={this.getPropertySlug.bind(this)}
                            outSidePropertySlug={this.state.outSidePropertySlug}
                          />
                        </div>
                        <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                          {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.is_search === 1 ? (
                            <>
                              <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                <div className="custm-filter">
                                  <div>
                                    {" "}
                                    <label className="form-label text-left pd-b-2 mb-0">
                                      Search Applied On
                                    </label>
                                  </div>
                                  <div className="filter_bg_cust">
                                    {this.state.module_search.map((item, i) => {
                                      return <span key={i}>{item}</span>;
                                    })}
                                    <button
                                      onClick={this.clearFilter}
                                      className="btn-fliter-clear"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-xxl-12 pd-r-15 pd-l-15">
                      {queryParams.isGlobal === "1" ? (
                        <>
                          <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between justify_mob_filter scrolling-carousel">
                            {"Search applied on: " +
                              queryParams.global_col_label +
                              " - " +
                              queryParams.global_search}
                            <button
                              onClick={this.clearFilter}
                              className="mg-l-15 btn-success-outline-small"
                            >
                              Clear
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.is_search === 1 ? (
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter">
                              <div>
                                {" "}
                                <label className="form-label text-left pd-b-2 mb-0">
                                  Search Applied On
                                </label>
                              </div>
                              <div className="filter_bg_cust">
                                {this.state.module_search.map((item, i) => {
                                  return <span key={i}>{item}</span>;
                                })}
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <div className="scrolling-carousel pd-l-15 scroll-slide">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15 pd-xs-l-15 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="scrolling-carousel pd-l-0 scroll-slide">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="all"
                          onSelect={(e) => this.getActiveClass(e)}
                          eventKey={this.state.activeClass}
                          className="pd-r-60"
                        >
                          <ScrollingCarousel
                            rightArrow={true}
                            leftArrow={true}
                            className="swipesection scroll_nav_mob"
                          >
                            <Nav.Item>
                              <Nav.Link
                                eventKey="activeTab"
                                id="activeTab"
                                className={
                                  this.state.activeClass === "activeTab"
                                    ? "active"
                                    : ""
                                }
                              >
                                Active
                              </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                              <Nav.Link
                                eventKey="inactiveTab"
                                id="inactiveTab"
                                className={
                                  this.state.activeClass === "inactiveTab"
                                    ? "active"
                                    : ""
                                }
                              >
                                Inactive
                              </Nav.Link>
                            </Nav.Item>
                          </ScrollingCarousel>
                          <Tab.Content>
                            <Tab.Pane eventKey="activeTab">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                {this.state.total_record > 0 ||
                                this.state.filter_value !== "" ? (
                                  <div className="mg-t-18 brandList">
                                    <div className="pd-lg-l-0 pd-xs-l-15">
                                      <ReactDatatable
                                        className="table mg-b-30 pd-b-2 add-no-wrap reservationTable table_last_row"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        loading={this.state.loading}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="Nodatafound m-auto">
                                    <div className="col-md-12">
                                      <div className="row d-flex justify-content-center flex-column pd-l-0 pd-r-0 mg-b-15 text-center align-items-center">
                                        <img
                                          className="no-data-image"
                                          src={Nodata}
                                          alt=""
                                        />
                                        <p className="az-profile-name-text pl-0 pd-t-5 pd-b-10">
                                          There are no active inquiries added in
                                          the system.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="inactiveTab">
                              <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                {this.state.total_record > 0 ||
                                this.state.filter_value !== "" ? (
                                  <div className="mg-t-18 brandList">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap reservationTable table_last_row"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                ) : (
                                  <div className="Nodatafound m-auto">
                                    <div className="col-md-12">
                                      <div className="row d-flex justify-content-center flex-column pd-l-0 pd-r-0 mg-b-15 text-center align-items-center">
                                        <img
                                          className="no-data-image"
                                          src={Nodata}
                                          alt=""
                                        />
                                        <p className="az-profile-name-text pl-0 pd-t-5 pd-b-10">
                                          There are no inactive inquiries added
                                          in the system.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                      {/* {this.state.total_record !== 0 ||
                      this.state.filter_value !== "" ||
                      this.state.is_search === 1 ||
                      queryParams.isGlobal === "1" ? (
                        <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                          <div className="pd-lg-l-0 pd-xs-l-15">
                            <ReactDatatable
                              className="table mg-b-30 pd-b-2 add-no-wrap reservationTable table_last_row"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              onRowClicked={this.rowClickedHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="Nodatafound m-auto">
                          <div className="col-md-12">
                            <div className="row d-flex justify-content-center flex-column pd-l-0 pd-r-0 mg-b-15 text-center align-items-center">
                              <img
                                className="no-data-image"
                                src={Nodata}
                                alt=""
                              />
                              <p className="az-profile-name-text pl-0 pd-t-5 pd-b-10">
                                There are no inquiries added in the
                                system.
                              </p>
                            </div>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Inquiry</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Listing Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="listing_name"
                      value={this.state.listing_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Check-In Date from</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="checkin_date"
                    selected={this.state.checkin_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "checkin_date",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Check-Out Date from</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="checkout_date"
                      selected={this.state.checkout_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "checkout_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Booked Date from</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="booked_date"
                      selected={this.state.booked_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "booked_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Channel</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.newChannelList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeChannel(value)}
                      value={this.state.selectedChannelList}
                      defaultValue={this.state.selectedChannelList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Guest</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="guest_name"
                      value={this.state.guest_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Check-In Date to</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="checkin_date_to"
                    selected={this.state.checkin_date_to}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "checkin_date_to",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Check-Out Date to</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="checkout_date_to"
                      selected={this.state.checkout_date_to}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "checkout_date_to",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Booked Date to</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="booked_date_to"
                      selected={this.state.booked_date_to}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "booked_date_to",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Inquiry;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Logout.jsx                                   //
//  Application: authentication                           //
//  Option: For logout                                    //
//  Developer: NP  						                            //
//  Date: 2021-12-22                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import AuthenticationService from '../../services/AuthenticationService'

export class Logout extends Component {
  constructor(props) {
      super(props)

      this.logout = this.logout.bind(this);
  }

  componentDidMount() {
      this.logout();
  }

  logout = async (e) => {
      if(global.headerToken){
          await AuthenticationService.logout();
          localStorage.clear();
          sessionStorage.clear();
          this.props.history.push('/signin');
      }else{
        localStorage.clear();
        sessionStorage.clear();
        this.props.history.push('/signin');
      }
  }

  render() {
    return (
      <div></div>
    )
  }

}

export default Logout

///////////////////////////////////////////////
//     							             //
//  Program: ThemeService.js                 //
//  Application: Services                    //
//  Option: Used for theme data              //
//  Developer: JJ                  //
//  Date: 2022-03-30                         //
//                                           //
///////////////////////////////////////////////

import CmsService from "./CmsService";

class ThemeService {
  getTheme(queryString) {
    let endPoint = "get-cms-property-template-setting" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCall(inputData);
    return response;
  }

  getPropertyStyleData(slug,refTableId) {

    let endPoint = "get-cms-property-style/" + slug + "/" + refTableId;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  savePropertyStyledata(slug,data) {
    // Delete data which we don't want to send
    let endPoint = "update-cms-property-style/"+slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.putCMSCall(inputData);
    return response;
  }

  getThemeBySlug(slug) {
    let endPoint = "theme/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCall(inputData);
    return response;
  }

  updateTheme(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "theme/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.putCall(inputData);
    return response;
  }

  deleteTheme(slug) {
    let endPoint = "theme/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.deleteCall(inputData);
    return response;
  }

  //for cms page NP
  getcmsPagesNames() {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-cms-pages";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  createcmsPage(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "get-cms-pages";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.postCMSCall(inputData);
    return response;
  }

  getcmsPageBySlug(slug) {
    let endPoint = "get-cms-pages/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  updatecmsPage(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "get-cms-pages/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.putCMSCall(inputData);
    return response;
  }

  deletecmsPage(slug) {
    let endPoint = "get-cms-pages/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.deleteCMSCall(inputData);
    return response;
  }

  //for cms page data -NP
  getcmsPagesNamesData(queryString) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-cms-page-sections"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  createcmsPageData(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "get-cms-page-sections";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.postCMSCall(inputData);
    return response;
  }

  getcmsPageDataBySlug(slug) {
    let endPoint = "get-cms-page-sections/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  updatecmsPageData(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "get-cms-page-sections/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.putCMSCall(inputData);
    return response;
  }

  deletecmsPageData(slug) {
    let endPoint = "get-cms-page-sections/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.deleteCMSCall(inputData);
    return response;
  }

   //for cms page section data -NP
   getcmsPageSectionData(queryString) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "cms-manage-page-section-details"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

   //for cms page section data -NP
   getcmsPageSeoData(queryString) {
    //  let endPoint = "get-cms-page-meta/" + slug;
    let endPoint = "get-cms-page-meta"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  getCmsGeneralData(queryString) {
    //  let endPoint = "get-cms-page-meta/" + slug;
    let endPoint = "get-cms-general-data"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  savecmsPageSectionData(slug,data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "cms-manage-page-section-details/"+slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.putCMSCall(inputData);
    return response;
  }

  updateCmsSectionDetails(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-cms-section-details";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.putCMSCall(inputData);
    return response;
  }

  updateCmsGeneralDetails(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-cms-general-details";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.putCMSCall(inputData);
    return response;
  }

   //for cms page section data for dropdown -NP
   getcmsPageDropdown() {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-cms-pages-list";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

   //for cms page section data -NP
   getcmsPageSectionDropdown(slug,level=3) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-cms-page-sections-list/"+ slug + "/" + level;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  //for image upload
  uploadCmsImage(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "upload-cms-image";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.postCMSCall(inputData);
    return response;
  }
}

export default new ThemeService();

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: KBCategoryService.js                        //
//  Application: KBCategory                             //
//  Option: Used for manage KBCategory                  //
//  Developer: Saurabh Shukla  						                  //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class KBCategoryService {
  getKBCategory(queryString) {
    let endPoint = "kb-category" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createKBCategory(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "kb-category";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getKBCategoryBySlug(slug) {
    let endPoint = "kb-category/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateKBCategory(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "kb-category/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteKBCategory(slug) {
    let endPoint = "kb-category/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new KBCategoryService();

////////////////////////////////////////////////////////////
//     							                          //
//  Program: PetPolicy.jsx                                    //
//  Application: PetPolicy                                    //
//  Option: List all PetPolicy                                //
//  Developer: Ashish Kumar 						              //
//  Date: 2022-01-25                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";

import SimpleReactValidator from "simple-react-validator";
import PetPolicyService from "../../services/PetPolicyService";
import { Link } from "react-router-dom";
import { Container, Accordion, Card, Button } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import Alerts from "../common/Alerts";
// import renderHTML from "react-render-html";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import BackButton from "../../assets/images/back-button.svg";
import Nodata from "../../assets/images/no-data.svg";
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import NumberFormat from "react-number-format";
// import ShowMoreText from "react-show-more-text";
export class PetPolicy extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      is_inactive: false,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      resData: [],
      multiList: [],
      multiListTotal: 0,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      descShow: 0,
      descShowRules: 0,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.fullText = this.fullText.bind(this);
    this.fullTextRules = this.fullTextRules.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    var resData = [];
    var multiList = [];
    var multiListTotal = 0;
    let res = await PetPolicyService.getPetPolicy(
      this.state.property_slug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data.petPolicyData ? res.data.petPolicyData : [];
      totalRecords = list ? list.length : 0;
      resData.pet_policy_restrictions = res.data.propertyData[0]
        .pet_policy_restrictions
        ? res.data.propertyData[0].pet_policy_restrictions
        : null;
      resData.custom_pet_policy = res.data.propertyData[0].custom_pet_policy
        ? res.data.propertyData[0].custom_pet_policy
        : null;
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  getTitle() {
    return "Pet Policy";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          <div className="row row-xs">
            <div className="col-md-10 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link to="/properties">Properties</Link>
                <span> / </span>
                <span>{this.getTitle()}</span>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <p className="d-flex justify-content-end">
                <span className="back-parent d-flex align-items-center">
                  <Link to="/properties">
                    <img src={BackButton} alt="" className="back-button" />
                  </Link>
                  <Link to="/properties">Back</Link>
                </span>
              </p>
            </div>
          </div>
        </Container>

        <Container>
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-15 pd-t-4">
              <div className="col-md-3">
                <aside>
                  <div className="topHead">
                    <div className="demo-avatar-group">
                      <div className="az-img-user avatar-md online">
                        <img
                          src={
                            sessionStorage.getItem("property_logo_url")
                              ? sessionStorage.getItem("property_logo_url")
                              : propertyLogo
                          }
                          className="rounded-circle"
                          alt=""
                        />
                      </div>
                      <div className="propname">
                        <h5>
                          {sessionStorage.getItem("property_name")
                            ? sessionStorage.getItem("property_name")
                            : "Property"}
                        </h5>
                        <ul>
                          <li>
                            {sessionStorage.getItem("property_type")
                              ? sessionStorage.getItem("property_type")
                              : ""}
                          </li>
                          {sessionStorage.getItem("year_value") ? (
                            <li></li>
                          ) : (
                            ""
                          )}
                          <li>
                            {sessionStorage.getItem("year_value")
                              ? "Year " + sessionStorage.getItem("year_value")
                              : ""}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "0")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Property Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                              this.state.eventKey === "0" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse
                          eventKey="0"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${
                                this.state.pathname.match("/basic")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic"
                              }
                            >
                              Property
                            </Link>

                            <Link
                              className={`${
                                this.state.pathname.match("/tower")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              }
                            >
                              Towers
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/floors")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floors"
                              }
                            >
                              Floors
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/amenitites")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/amenitites"
                              }
                            >
                              Common Amenities
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/pet-policy")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                              }
                            >
                              Pet Policy
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/staff")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                            >
                              Staff
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/documents")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/documents"
                              }
                            >
                              Documents & Leases
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/property-level-tax")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              }
                            >
                              Taxes
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/nearby-attractions")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/nearby-attractions"
                              }
                            >
                              Near By Attractions
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/events")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                              }
                            >
                              Events
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card onClick={() => this.setOpen(!this.state.open, "3")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Units Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                              this.state.eventKey === "3" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="3"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${
                                this.state.pathname.match("/floor-plans")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floor-plans"
                              }
                            >
                              Floor Plans
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/units")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units"
                              }
                            >
                              Units
                            </Link>
                            {/* <Link
                              className={`${
                                this.state.pathname.match("/rooms")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/rooms"
                              }
                            >
                              Rooms
                            </Link> */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </nav>
                </aside>
              </div>

              {this.state.total_record === 0 &&
              this.state.pet_policy_restrictions === null &&
              this.state.custom_pet_policy === null &&
              this.state.multiListTotal === 0 ? (
                <div className="col-md-9">
                  <div className="scrolling-carousel pd-l-15">
                    <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2">
                      <h3>{this.getTitle()}</h3>
                    </div>
                    <div className="dashboardRightcard">
                      {/* No data found section */}
                      <div className="Nodatafound">
                        <div className="col-md-12">
                          <div className="row d-flex justify-content-center flex-column pd-l-0 pd-r-0 mg-b-15 text-center align-items-center">
                            <img src={Nodata} alt="" />
                            <p className="az-profile-name-text pl-0 pd-t-5 pd-b-10">
                              No data available
                            </p>

                            <Link to={"pet-policy/add"}>
                              <button className="btn-success-outline-small ">
                                <img src={pluswIcon} alt="" /> Add
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* End No data found section */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-9 ">
                  <div className="scrolling-carousel pd-l-15">
                    <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2">
                      <h3>{this.getTitle()}</h3>
                    </div>
                    <div className="dashboardRightcard">
                      <div className="d-flex justify-content-end">
                        {this.state.total_record > 0 ||
                        this.state.pet_policy_restrictions !== null ||
                        this.state.custom_pet_policy !== null ||
                        this.state.multiListTotal > 0 ? (
                          <div className="col-md-12 d-flex justify-content-end pd-l-0 pd-r-0 mg-b-15">
                            <Link
                              to={
                                "pet-policy/update/" + this.state.property_slug
                              }
                            >
                              <button className="btn-success-outline-small ">
                                <img src={editIcon} alt="" /> Edit
                              </button>
                            </Link>
                          </div>
                        ) : (
                          <div className="col-md-12 d-flex justify-content-end pd-l-0 pd-r-0 mg-b-15">
                            <Link to={"pet-policy/add"}>
                              <button className="btn-success-outline-small ">
                                Add
                              </button>
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>

                      <div className="mg-t-18 brandList">
                        <div className="row col-md-12 mg-t-40 mg-md-t-0">
                          <div className="col-md-12 pd-l-30 pd-r-30 pl-0">
                            <p className="az-profile-name-text pl-0">
                              Basic Information
                            </p>
                          </div>
                        </div>
                        {this.state.total_record > 0 ? (
                          <div
                            className="as-react-table"
                            id="as-react-datatable-container"
                          >
                            <div
                              className="row table-body asrt-table-body"
                              id="as-react-datatable-table-body"
                            >
                              <div className="col-md-12">
                                <table
                                  className="table table-hoverno-wrap mg-b-30 pd-b-2"
                                  id="as-react-datatable"
                                >
                                  <thead>
                                    <tr>
                                      <th className=" text-" width="">
                                        Pet Type
                                      </th>
                                      <th className=" text-" width="">
                                        Max # Allowed
                                      </th>
                                      <th className=" text-" width="">
                                        Deposit ($)
                                      </th>
                                      <th className=" text-" width="">
                                        One-Time Fee ($)
                                      </th>
                                      <th className=" text-" width="">
                                        Monthly Rent ($)
                                      </th>
                                      <th className=" text-" width="">
                                        Weight (lbs)
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.records.map((option, i) => (
                                      <tr key={i}>
                                        <td>
                                          {option.pet_type
                                            ? option.pet_type.pet_type_name
                                            : ""}
                                        </td>
                                        <td>
                                          {option.allowed_pet_number
                                            ? option.allowed_pet_number
                                            : ""}
                                        </td>
                                        <td>
                                          {option.deposite_amount_per_pet ? (
                                            <NumberFormat
                                              value={
                                                option.deposite_amount_per_pet
                                              }
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"$"}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td>
                                          {option.one_time_fee_per_type ? (
                                            <NumberFormat
                                              value={
                                                option.one_time_fee_per_type
                                              }
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"$"}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td>
                                          {option.rent_per_type ? (
                                            <NumberFormat
                                              value={option.rent_per_type}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"$"}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td>
                                          {option.weight_per_type
                                            ? option.weight_per_type + " lbs"
                                            : ""}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row col-md-12 mg-t-40 mg-md-t-0">
                        <div className="col-md-12 pd-l-30 pd-r-30 pl-0">
                          <p className="az-profile-name-text pl-0">
                            Prohibited Pet Types
                          </p>
                          <p className="media-body">
                            {this.state.multiListTotal > 0
                              ? this.state.multiList.map(
                                  (option, i) =>
                                    option.pet_type_name +
                                    (i < this.state.multiListTotal - 1
                                      ? ", "
                                      : "") +
                                    ""
                                )
                              : ""}
                          </p>
                        </div>
                      </div>
                      <hr className="mg-t-30 mg-b-30 pd-b-2" />
                      <div className="row col-md-12 mg-t-40 mg-md-t-0">
                        <div className="col-md-12 pd-l-30 pd-r-30 pl-0">
                          <p className="az-profile-name-text pl-0">
                            Restrictions
                          </p>
                          <p className="para">
                            {this.state.pet_policy_restrictions ? (
                              <p className="p_wrap1">
                              {this.state.descShow
                                ? this.state.pet_policy_restrictions.slice(
                                    0,
                                    global.showMoreLength
                                  )
                                : this.state.pet_policy_restrictions}

                              {this.state.pet_policy_restrictions.length >
                                global.showMoreLength && (
                                <span onClick={this.fullText}>
                                  {this.state.descShow ? (
                                    <p className="hand-cursor showmore">
                                      ...Show more{" "}
                                    </p>
                                  ) : (
                                    <p className="hand-cursor showmore">
                                      Show Less{" "}
                                    </p>
                                  )}
                                </span>
                              )}
                              </p>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                      <hr className="mg-t-30 mg-b-30 pd-b-2" />
                      <div className="row col-md-12 mg-t-40 mg-md-t-0">
                        <div className="col-md-12 pd-l-30 pd-r-30 pl-0">
                          <p className="az-profile-name-text pl-0">
                            Custom Pet Policy Content
                          </p>
                          <p className="para">
                            {this.state.custom_pet_policy ? (
                              <p className="p_wrap1">
                              {this.state.descShow
                                ? this.state.custom_pet_policy.slice(
                                    0,
                                    global.showMoreLength
                                  )
                                : this.state.custom_pet_policy}

                              {this.state.custom_pet_policy.length >
                                global.showMoreLength && (
                                <span onClick={this.fullText}>
                                  {this.state.descShow ? (
                                    <p className="hand-cursor showmore">
                                      ...Show more{" "}
                                    </p>
                                  ) : (
                                    <p className="hand-cursor showmore">
                                      Show Less{" "}
                                    </p>
                                  )}
                                </span>
                              )}
                              </p>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="ht-50"></div>
          </div>
        </Container>
      </main>
    );
  }
}

export default PetPolicy;

//     							                          //
//  Program: ClientSidebar.jsx                            //
//  Application: Client                                   //
//  Option: common component client Sidebar TAbs          //
//  Developer: NP 		                                  //
//  Date: 2022-11-22                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";

class ClientSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
    };
  }

  isPathActive(path) {
    var a = document.getElementsByClassName("nav-link active");
    if (a && a[0]) {
      a[0].scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <Link to="/clients"><div
            className="backtosubMenu"
            // onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Clients
          </div></Link>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission("clients-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("client-info")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/client/view/" +
                            (this.props.clientSlug
                              ? this.props.clientSlug
                              : this.state.clientSlug) +
                            "/client-info"
                          }
                        >
                          Overview
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>CRM</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "crm-campaigns-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-campaign")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/client/view/" +
                            (this.props.clientSlug
                              ? this.props.clientSlug
                              : this.state.clientSlug) +
                            "/crm-campaign"
                          }
                        >
                          Campaign
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-templates-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-template") ||
                            this.isPathActive("crm-templates")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/client/view/" +
                            (this.props.clientSlug
                              ? this.props.clientSlug
                              : this.state.clientSlug) +
                            "/crm-templates"
                          }
                        >
                          Templates
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-lists-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/client/view/" +
                            (this.props.clientSlug
                              ? this.props.clientSlug
                              : this.state.clientSlug) +
                            "/crm-list"
                          }
                        >
                          Lists
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            <div className="newSubmenu">
              <Link to="/clients"><span
                className="back"
                // onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Clients
              </span></Link>
              <ul>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission("clients-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("client-info")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/client/view/" +
                            (this.props.clientSlug
                              ? this.props.clientSlug
                              : this.state.clientSlug) +
                            "/client-info"
                          }
                        >
                          Overview
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span>CRM</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "crm-campaigns-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-campaign")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/client/view/" +
                            (this.props.clientSlug
                              ? this.props.clientSlug
                              : this.state.clientSlug) +
                            "/crm-campaign"
                          }
                        >
                          Campaign
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-templates-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-template") ||
                            this.isPathActive("crm-templates")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/client/view/" +
                            (this.props.clientSlug
                              ? this.props.clientSlug
                              : this.state.clientSlug) +
                            "/crm-templates"
                          }
                        >
                          Templates
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-lists-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/client/view/" +
                            (this.props.clientSlug
                              ? this.props.clientSlug
                              : this.state.clientSlug) +
                            "/crm-list"
                          }
                        >
                          Lists
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ClientSidebar;

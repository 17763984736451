///////////////////////////////////////////////
//     							             //
//  Program: PetPolicyService.js                 //
//  Application: Services                    //
//  Option: Used for pet-policy data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-02-10                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class CredentailService {
  getCredential(slug) {
    let endPoint = "get-list-custom-key-value/" + slug + "/" + 3;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getUnitCredential(slug) {
    let endPoint = "get-list-custom-key-value/" + slug + "/" + 5;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getAllCredential(queryString) {
    let endPoint = "all-credentails" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createallCredential(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "insert-custom-key-value";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  UpdateallCredential(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-custom-key-value/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  GetOneCredential(slug) {
    let endPoint = "show-custom-key-value-for-all/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createCredential(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-custom-key-value";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteCredential(slug) {
    let endPoint = "delete-custom-key-value/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  fetchPass(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "verify-password-and-fetch-data";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new CredentailService();

//     							                          //
//  Program: Propertyidebar.jsx                           //
//  Application: Property                                 //
//  Option: common component Property Sidebar TAbs        //
//  Developer: NP, Ashish Kumar 		                                  //
//  Date: 2022-04-01                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";
class PropertySidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
    };
  }

  isPathActive(path) {
    var a = document.getElementsByClassName("nav-link active");
    if (a && a[0]) {
      a[0].scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Property
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <span>Information</span>
                  <ul>
                    {/*  {global.userPermissions.checkPermission('property-list') &&
                     <li>
                      <Link
                      className={
                          this.isPathActive("property-overview")
                          ? "nav-link active"
                          : "nav-link"
                      }

                          data-toggle="tab"
                          to={"/properties/view/"+ (this.props.property_slug ? this.props.property_slug : this.state.property_slug) +"/property-overview"}
                      >
                          Overview
                      </Link>
                      </li>
                  } */}
                    {global.userPermissions.checkPermission(
                      "property-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("basic-new")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/basic-new"
                          }
                        >
                          Basics
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "legal-finance-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-payout-configuration")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-payout-configuration"
                          }
                        >
                          Payout Configuration
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "legal-finance-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("legal-finance")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/legal-finance"
                          }
                        >
                          Legal & Finance
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("owners-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("owners")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/owners"
                          }
                        >
                          Owners
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("taxes-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-level-tax")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-level-tax"
                          }
                        >
                          Taxes
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-documents-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-documents")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-documents"
                          }
                        >
                          Files
                        </Link>
                      </li>
                    )}

                    {global.userPermissions.checkPermission(
                      "property-fees-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-fees")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-fees"
                          }
                        >
                          Fees
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-parking-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-parking")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-parking"
                          }
                        >
                          Parking
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-amenities-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-amenities")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-amenities"
                          }
                        >
                          Amenities
                        </Link>
                      </li>
                    )}
                    {/*
                    {global.userPermissions.checkPermission('master-data-list') && <li>
                      <Link
                        className={
                          this.isPathActive("property-accessibility")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={
                          "/properties/view/" +
                          (this.props.property_slug ? this.props.property_slug : this.state.property_slug) +
                          "/property-accessibility"
                        }
                      >
                        Accessibility Features
                      </Link>
                    </li>
                      }*/}
                    {global.userPermissions.checkPermission(
                      "property-credentials-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-credential")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-credential"
                          }
                        >
                          Credentials
                        </Link>
                      </li>
                    )}

                    {global.userPermissions.checkPermission(
                      "property-credentials-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("compliance")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/compliance"
                          }
                        >
                          Compliance
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span> Structure</span>
                  <ul>
                    {global.userPermissions.checkPermission("towers-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("tower")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/tower"
                          }
                        >
                          Towers
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("floors-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("floors")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/floors"
                          }
                        >
                          Floors
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "floor-plans-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("floor-plans")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/floor-plans"
                          }
                        >
                          Floor Plans
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("units-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("units")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            this.props.property_slug +
                            "/units"
                          }
                        >
                          Units
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span> Marketing</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "property-gallery-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("images")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/images"
                          }
                        >
                          Gallery
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("near-by-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("near-by-attractions")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/near-by-attractions"
                          }
                        >
                          Near By
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "neighbourhood-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("neighbourhood")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/neighbourhood"
                          }
                        >
                          Neighborhood
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "ecommerce-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ecommerce")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/ecommerce"
                          }
                        >
                          E-commerce
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("events-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("events")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/events"
                          }
                        >
                          Events
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "influencers-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("influencers")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/influencers"
                          }
                        >
                          Influencers
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "ambassadors-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ambassadors")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/ambassadors"
                          }
                        >
                          Ambassadors
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "lead-tracking-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("lead-tracking-contacts")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/lead-tracking-contacts"
                          }
                        >
                          Lead Tracking
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "marketing-copy-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("marketing-copy")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/marketing-copy"
                          }
                        >
                          Marketing Copy
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "refundable-policy-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("refundable-policy")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/refundable-policy"
                          }
                        >
                          Cancellation Policy
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-website-cookies"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("website-cookies")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/website-cookies"
                          }
                        >
                          Website Cookies
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-terms-and-conditions"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("terms-conditions")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/terms-conditions"
                          }
                        >
                          Terms & Conditions
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "house-rules-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("house-rules")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/house-rules"
                          }
                        >
                          House Rules
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "house-rules-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("arrival-instructions")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/arrival-instructions"
                          }
                        >
                          Arrival Instructions
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "house-rules-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checkout-instructions")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/checkout-instructions"
                          }
                        >
                          Checkout Instructions
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "guest-manual-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("house-manual")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/house-manual"
                          }
                        >
                          House Manual
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "health-safety-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("health-safety")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/health-safety"
                          }
                        >
                          Health & Safety
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "getting-around-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("getting-around")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/getting-around"
                          }
                        >
                          Getting Around
                        </Link>
                      </li>
                    )}
                    {/*  {global.userPermissions.checkPermission('noiseware-list') &&
                     <li>
                      <Link
                        className={
                          this.isPathActive("noiseaware-template")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={
                          "/properties/view/" +
                          (this.props.property_slug ? this.props.property_slug : this.state.property_slug) +
                          "/noiseaware-template"
                        }
                      >
                        NoiseAware Template
                      </Link>
                    </li>
                  }*/}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Property
              </span>
              <ul>
                <li>
                  <span>Information</span>
                  <ul>
                    {/*  {global.userPermissions.checkPermission('property-list') &&
                     <li>
                      <Link
                      className={
                          this.isPathActive("property-overview")
                          ? "nav-link active"
                          : "nav-link"
                      }

                          data-toggle="tab"
                          to={"/properties/view/"+ (this.props.property_slug ? this.props.property_slug : this.state.property_slug) +"/property-overview"}
                      >
                          Overview
                      </Link>
                      </li>
                  } */}
                    {global.userPermissions.checkPermission(
                      "property-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("basic-new")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/basic-new"
                          }
                        >
                          Basics
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "legal-finance-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-payout-configuration")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-payout-configuration"
                          }
                        >
                          Payout Configuration
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "legal-finance-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("legal-finance")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/legal-finance"
                          }
                        >
                          Legal & Finance
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("owners-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("owners")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/owners"
                          }
                        >
                          Owners
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("taxes-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-level-tax")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-level-tax"
                          }
                        >
                          Taxes
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-documents-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-documents")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-documents"
                          }
                        >
                          Documents
                        </Link>
                      </li>
                    )}

                    {global.userPermissions.checkPermission(
                      "property-fees-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-fees")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-fees"
                          }
                        >
                          Fees
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-parking-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-parking")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-parking"
                          }
                        >
                          Parking
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-amenities-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-amenities")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-amenities"
                          }
                        >
                          Amenities
                        </Link>
                      </li>
                    )}
                    {/*
                          {global.userPermissions.checkPermission('master-data-list') && <li>
                            <Link
                              className={
                                this.isPathActive("property-accessibility")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                (this.props.property_slug ? this.props.property_slug : this.state.property_slug) +
                                "/property-accessibility"
                              }
                            >
                              Accessibility Features
                            </Link>
                          </li>
                            }*/}
                    {global.userPermissions.checkPermission(
                      "property-credentials-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("property-credential")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/property-credential"
                          }
                        >
                          Credentials
                        </Link>
                      </li>
                    )}

                    {global.userPermissions.checkPermission(
                      "property-credentials-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("compliance")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/compliance"
                          }
                        >
                          Compliance
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span> Structure</span>
                  <ul>
                    {global.userPermissions.checkPermission("towers-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("tower")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/tower"
                          }
                        >
                          Towers
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("floors-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("floors")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/floors"
                          }
                        >
                          Floors
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "floor-plans-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("floor-plans")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/floor-plans"
                          }
                        >
                          Floor Plans
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("units-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("units")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            this.props.property_slug +
                            "/units"
                          }
                        >
                          Units
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <span> Marketing</span>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "property-gallery-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("images")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/images"
                          }
                        >
                          Gallery
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("near-by-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("near-by-attractions")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/near-by-attractions"
                          }
                        >
                          Near By
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "neighbourhood-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("neighbourhood")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/neighbourhood"
                          }
                        >
                          Neighborhood
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "ecommerce-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ecommerce")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/ecommerce"
                          }
                        >
                          E-commerce
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("events-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("events")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/events"
                          }
                        >
                          Events
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "influencers-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("influencers")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/influencers"
                          }
                        >
                          Influencers
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "ambassadors-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("ambassadors")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/ambassadors"
                          }
                        >
                          Ambassadors
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "lead-tracking-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("lead-tracking-contacts")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/lead-tracking-contacts"
                          }
                        >
                          Lead Tracking
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "marketing-copy-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("marketing-copy")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/marketing-copy"
                          }
                        >
                          Marketing Copy
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "refundable-policy-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("refundable-policy")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/refundable-policy"
                          }
                        >
                          Cancellation Policy
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-website-cookies"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("website-cookies")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/website-cookies"
                          }
                        >
                          Website Cookies
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "property-terms-and-conditions"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("terms-conditions")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/terms-conditions"
                          }
                        >
                          Terms & Conditions
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "house-rules-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("house-rules")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/house-rules"
                          }
                        >
                          House Rules
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "house-rules-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("arrival-instructions")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/arrival-instructions"
                          }
                        >
                          Arrival Instructions
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "house-rules-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checkout-instructions")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/checkout-instructions"
                          }
                        >
                          Checkout Instructions
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "guest-manual-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("house-manual")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/house-manual"
                          }
                        >
                          House Manual
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "health-safety-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("health-safety")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/health-safety"
                          }
                        >
                          Health & Safety
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "getting-around-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("getting-around")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/getting-around"
                          }
                        >
                          Getting Around
                        </Link>
                      </li>
                    )}
                    {/*  {global.userPermissions.checkPermission('noiseware-list') &&
                     <li>
                      <Link
                        className={
                          this.isPathActive("noiseaware-template")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={
                          "/properties/view/" +
                          (this.props.property_slug ? this.props.property_slug : this.state.property_slug) +
                          "/noiseaware-template"
                        }
                      >
                        NoiseAware Template
                      </Link>
                    </li>
                  }*/}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default PropertySidebar;

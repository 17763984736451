////////////////////////////////////////////////////////////
//     							                                      //
//  Program: UnitDocuments.jsx                            //
//  Application: UnitDocuments                            //
//  Option: add  UnitDocuments                            //
//  Developer: Ashish Kumar					                      //
//  Date: 2022-02-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Col, InputGroup, Form, Table, Modal } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import Alerts from "../common/Alerts";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import deleteicon from "../../assets/images/delete.svg";
import CredentailService from "../../services/CredentailService";
import openeye from "../../assets/images/openeye.png";
import closeeye from "../../assets/images/closeeye.png";

export class UnitCredential extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message, className) => (
        <div className={className} key={message}>
          {message}
        </div>
      ),
      validators: {
        passwordRequired: {
          message: "The :attribute field is required.",
          rule: (val, params, validator) => {
            return this.state.isPasswordModal
              ? validator.helpers.testRegex(val, /.+/)
              : true;
          },
          required: true,
        },
      },
    });

    this.state = {
      viewMode: 0,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      status_id: true,
      confirm_back: 0,
      crdentialDataTotal: 0,
      total_record: 0,
      property_slug: this.props.property_slug,
      unit_slug: this.props.unit_slug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      isSubmit: false,
      passwordType: "password",
      records: [
        {
          custom_key_name: "",
          custom_key_slug: "",
          custom_type_name: "",
          custom_value_name: "",
          web_url: "",
          notes: "",
          passwordType: "password",
        },
      ],
      isPasswordModal: false,
      password: "",
      currentRecordIndex: null,
    };

    this.saveOrUpdateCredential = this.saveOrUpdateCredential.bind(this);
    this.changeView = this.changeView.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddFields = this.handleAddFields.bind(this);
    this.handleRemoveFields = this.handleRemoveFields.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
  }

  async componentDidMount() {
    this.getCredentialData();
  }

  hidePassword(index) {
    const records = [...this.state.records];
    records[index].passwordType = "password";
    records[index].custom_type_name = "************";
    this.setState({ records });
  }

  async togglePassword(index) {
    const { isPasswordModal, records } = this.state;

    if (isPasswordModal) {
      this.setState({
        isPasswordModal: false,
        password: "",
        currentRecordIndex: null,
        custom_type_name: "************",
        errors: {},
      });
    } else {
      this.setState({
        currentRecordIndex: index,
        isPasswordModal: true,
        password: "",
        custom_type_name: records[index].custom_type_name || "************",
        errors: {},
      });
    }
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  togglePasswordHide = (index) => {
    if (index >= 0 && index < this.state.records.length) {
      const records = [...this.state.records];
      records[index].passwordType = "password";
      records[index].custom_type_name = "************";
      this.setState({ records });
    }
  };

  async handlePasswordSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmit: true });
    const { currentRecordIndex, password, records } = this.state;

    if (this.validator.allValid()) {
      try {
        const slug = records[currentRecordIndex].slug;
        const res = await CredentailService.fetchPass({ slug, password });

        if (global.successStatus.includes(res.status)) {
          const { custom_type_name } = res.data || {};

          if (res.message === "Unauthorized. Incorrect password.") {
            this.setState({
              errors: { password: res.message },
              isSubmit: false,
            });
          } else {
            const updatedRecords = records.map((record, index) =>
              index === currentRecordIndex
                ? { ...record, custom_type_name, passwordType: "text" }
                : record
            );
            this.setState({
              records: updatedRecords,
              isSubmit: false,
              errors: {},
              isPasswordModal: false,
              custom_type_name: custom_type_name || "************",
            });
          }
        } else {
          const errors = res.data.result.errorDetail.reduce((acc, item) => {
            acc[item.errorField] = item.errorMessage[0];
            return acc;
          }, {});
          this.setState({ errors, isSubmit: false });
        }
      } catch (err) {
        this.setState({
          errors: err.response?.data?.error || "An error occurred",
          isSubmit: false,
          custom_type_name: "************",
        });
      }
    } else {
      this.validator.showMessages();
    }
    this.setState({ isSubmit: false });
  }

  async getCredentialData(queryString = "") {
    let list = [];
    let totalRecords = 0;

    let res = await CredentailService.getUnitCredential(this.state.unit_slug);
    if (global.successStatus.includes(res.status)) {
      list = res.data || [];
      totalRecords = list.length;
    }

    const records = list.map((item) => ({
      custom_key_name: item.custom_key_name || "",
      custom_key_slug: item.slug || "",
      slug: item.slug || "",
      custom_type_name: item.custom_type_name || "",
      custom_value_name: item.custom_value_name || "",
      web_url: item.web_url || "",
      notes: item.notes || "",
      passwordType: "password",
    }));

    this.setState({
      records: records.length ? records : [this.getEmptyRecord()],
      total_record: totalRecords,
    });
  }

  getEmptyRecord() {
    return {
      custom_key_name: "",
      custom_key_slug: "",
      slug: "",
      custom_type_name: "",
      custom_value_name: "",
      web_url: "",
      notes: "",
      passwordType: "password",
    };
  }

  async saveOrUpdateCredential(e) {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      const inputData = {
        cost_data: this.state.records,
        ref_slug: this.state.unit_slug,
        ref_table_id: 5,
      };
      const res = await CredentailService.createCredential(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message || "Success",
        });
        setTimeout(() => this.changeView(), global.redirect_time);
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  }

  async deleteCredential(slug) {
    this.setState({ showSpinner: true });
    if (slug) {
      let res = await CredentailService.deleteCredential(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message || "Success",
        });
        this.getCredentialData();
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message || "Error!",
          alertModalMessage: res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  handleChange(index, event) {
    if (index >= 0 && index < this.state.records.length) {
      const records = [...this.state.records];
      records[index][event.target.name] = event.target.value;
      this.setState({ records });
    }
  }

  handleAddFields = () => {
    const values = [...this.state.records];
    values.push(this.getEmptyRecord());
    this.setState({ records: values });
  };

  handleRemoveFields = (index) => {
    const values = [...this.state.records];
    values.splice(index, 1);
    this.setState({ records: values });
  };

  customValidate() {
    const errors = {};
    let isValid = true;

    this.state.records.forEach((record, index) => {
      if (!record.custom_key_name) {
        isValid = false;
        errors[`custom_key_name_${index}`] =
          "The custom_key_name cannot be blank";
      }
      if (!record.custom_type_name) {
        isValid = false;
        errors[`custom_type_name_${index}`] =
          "The custom type name cannot be blank.";
      }
    });

    this.setState({ errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Credentials";
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.getCredentialData();
      this.setState({ viewMode: 0, records: [] });
    } else {
      this.getCredentialData();
      this.setState({ viewMode: 1, records: [] });
    }
  }

  render() {
    const { isPasswordModal, custom_type_name, isSubmit, errors } = this.state;

    return (
      <>
        <main>
          <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns mg-t-15">
            <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
              {this.state.viewMode === 0 ? (
                <button
                  className="btn-success-outline-small mr-3"
                  onClick={this.changeView}
                >
                  <img src={editIcon} alt="edit" /> Edit
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn-success-outline-small mr-3"
                    onClick={this.changeView}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-success mr-3"
                    type="button"
                    onClick={this.saveOrUpdateCredential}
                    disabled={isSubmit}
                  >
                    {isSubmit ? global.loader : "Save"}
                  </button>{" "}
                </>
              )}
            </div>
            {this.state.viewMode === 0 ? (
              <div className="row m-0">
                <div className="table-responsive pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                  <Table hover className="mg-b-0">
                    <thead>
                      <tr>
                        <th className="w-20">Account</th>
                        <th className="w-20">URL</th>
                        <th className="w-20">Username</th>
                        <th className="w-20">Password</th>
                        <th className="w-20">Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.total_record > 0 ? (
                        this.state.records.map((option, i) => (
                          <tr key={i}>
                            <td className="text-left">
                              {option.custom_key_name || ""}
                            </td>
                            <td className="text-left">
                              {option.web_url || ""}
                            </td>
                            <td className="text-left">
                              {option.custom_value_name || ""}
                            </td>
                            <td className="password_popup text-">
                              <span
                                className={
                                  option.custom_type_name === "************"
                                    ? "hand-cursor"
                                    : "hand-cursor pass-show"
                                }
                              >
                                {option.custom_type_name || ""}
                              </span>
                              {option.custom_type_name !== "************" ? (
                                <i
                                  onClick={() => {
                                    this.hidePassword(i);
                                  }}
                                  className="fa fa-eye-slash hand-cursor"
                                ></i>
                              ) : (
                                <i
                                  onClick={() => {
                                    this.togglePassword(i);
                                  }}
                                  className="fa fa-eye hand-cursor"
                                ></i>
                              )}
                            </td>
                            <td className="text-left">{option.notes || ""}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9" align="center">
                            There are no credentials added in the system. You
                            may add them by clicking Edit button.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            ) : (
              <>
                <div className="col-md-12 pl-0 pr-0">
                  <Alerts
                    show={this.state.showAlertModal}
                    type={this.state.alertModalType}
                    title={this.state.alertModalTitle}
                    message={this.state.alertModalMessage}
                  />
                </div>
                <div className="pd-l-0 pd-r-0 pet-f">
                  <div className="pd-lg-l-30 pd-lg-r-30 mg-lg-l-0 mg-xs-l-15 pet-f mg-b-30 pet">
                    <div className="col-md-12 p-0 petFeeSection">
                      <table className="petForm">
                        <thead>
                          <tr>
                            <th className="w-2">
                              <label className="mb-1">Account</label>
                            </th>
                            <th className="w-2">
                              <label className="mb-1">URL</label>
                            </th>
                            <th className="w-2">
                              <label className="mb-1">Username</label>
                            </th>
                            <th className="w-2">
                              <label className="mb-1">Password</label>
                            </th>
                            <th className="w-2">
                              <label className="mb-1">Notes</label>
                            </th>
                            <th className="w-3"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.records.map((data, i) => (
                            <tr key={i}>
                              <td className="w-1">
                                <Col xs={12} className="pd-l-2 pd-r-2 colmn1">
                                  <InputGroup className="mt-0 m-b-1">
                                    <div className="input-group-prepend prefix-dollar w-100">
                                      <Form.Control
                                        name="custom_key_name"
                                        type="text"
                                        autoComplete="off"
                                        value={data.custom_key_name || ""}
                                        onChange={(event) =>
                                          this.handleChange(i, event)
                                        }
                                      />
                                    </div>
                                  </InputGroup>
                                </Col>
                              </td>
                              <td className="w-2">
                                <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                                  <InputGroup className="mt-0">
                                    <div className="input-group-prepend prefix-dollar w-100">
                                      <Form.Control
                                        name="web_url"
                                        autoComplete="off"
                                        value={data.web_url || ""}
                                        onChange={(event) =>
                                          this.handleChange(i, event)
                                        }
                                      />
                                    </div>
                                  </InputGroup>
                                </Col>
                              </td>
                              <td className="w-2">
                                <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                                  <InputGroup className="mt-0">
                                    <div className="input-group-prepend prefix-dollar w-100">
                                      <Form.Control
                                        name="custom_value_name"
                                        autoComplete="off"
                                        value={data.custom_value_name || ""}
                                        onChange={(event) =>
                                          this.handleChange(i, event)
                                        }
                                      />
                                    </div>
                                  </InputGroup>
                                </Col>
                              </td>
                              <td className="w-2">
                                <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                                  <InputGroup className="mt-0">
                                    <div className="input-group-prepend w-100">
                                      <div className="passwod_input w-100">
                                        <Form.Control
                                          type={data.passwordType}
                                          name="custom_type_name"
                                          autoComplete="off"
                                          value={data.custom_type_name || ""}
                                          onChange={(event) =>
                                            this.handleChange(i, event)
                                          }
                                        />
                                        <div className="input-group-append">
                                          {data.passwordType === "password" ? (
                                            <span
                                              className="eye-icon"
                                              onClick={() =>
                                                this.togglePassword(i)
                                              }
                                            >
                                              <img src={openeye} alt="" />
                                            </span>
                                          ) : (
                                            <span
                                              className="eye-icon"
                                              onClick={() =>
                                                this.togglePasswordHide(i)
                                              }
                                            >
                                              <img src={closeeye} alt="" />
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </InputGroup>
                                </Col>
                              </td>
                              <td className="w-2">
                                <Col xs={12} className="pd-l-2 pd-r-2 colmn3">
                                  <InputGroup className="mt-0">
                                    <div className="input-group-prepend prefix-dollar w-100">
                                      <Form.Control
                                        name="notes"
                                        autoComplete="off"
                                        value={data.notes || ""}
                                        onChange={(event) =>
                                          this.handleChange(i, event)
                                        }
                                      />
                                    </div>
                                  </InputGroup>
                                </Col>
                              </td>
                              <td>
                                <Col
                                  xs={12}
                                  className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                                >
                                  {this.state.records.length === 1 &&
                                  i === 0 ? (
                                    <>
                                      {data.slug ? (
                                        <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                          <button
                                            onClick={() => {
                                              this.handleRemoveFields(i);
                                              this.deleteCredential(data.slug);
                                            }}
                                            className="btn-danger-outline-x-small"
                                          >
                                            <img
                                              src={deleteicon}
                                              alt="Delete"
                                            />
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                        <button
                                          onClick={this.handleAddFields}
                                          className="btn-success-outline-x-small"
                                        >
                                          <img src={pluswIcon} alt="Add" />
                                        </button>
                                      </div>
                                    </>
                                  ) : i < this.state.records.length - 1 ? (
                                    <div className="d-flex justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                      <button
                                        onClick={() => {
                                          this.handleRemoveFields(i);
                                          this.deleteCredential(data.slug);
                                        }}
                                        className="btn-danger-outline-x-small"
                                      >
                                        <img src={deleteicon} alt="Delete" />
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      {this.state.records.length !== 0 ? (
                                        <div className="d-flex justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                          <button
                                            onClick={() => {
                                              this.handleRemoveFields(i);
                                              this.deleteCredential(data.slug);
                                            }}
                                            className="btn-danger-outline-x-small"
                                          >
                                            <img
                                              src={deleteicon}
                                              alt="Delete"
                                            />
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                        <button
                                          onClick={this.handleAddFields}
                                          className="btn-success-outline-x-small"
                                        >
                                          <img src={pluswIcon} alt="Add" />
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </Col>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </main>
        <Modal
          show={isPasswordModal}
          centered
          className="modal-xs agreementdocModal"
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div>
              <span className="welcomeClose" onClick={this.togglePassword}>
                {global.closee}
              </span>
              <h3 className="text-center">Password</h3>
              <div>
                {!custom_type_name === "" ||
                custom_type_name === "************" ? (
                  <>
                    <div>
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={(event) =>
                          this.setState({
                            password: event.target.value,
                            errors: {},
                          })
                        }
                        required
                      />
                      {errors.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : (
                        this.validator.message(
                          "password",
                          this.state.password,
                          "passwordRequired",
                          { className: "text-danger" }
                        )
                      )}
                    </div>
                    <button
                      className="m-0 btn btn btn-success btn-block btn-pass"
                      onClick={this.handlePasswordSubmit}
                      disabled={isSubmit}
                    >
                      Verify Password
                    </button>
                  </>
                ) : (
                  ""
                  // <div>
                  //   <p className="text-center">{custom_type_name}</p>
                  // </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default UnitCredential;

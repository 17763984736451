////////////////////////////////////////////////////////////
//     							                          //
//  Program: NearByAttractionForm.jsx            //
//  Application:  Property                               //
//  Option: For add or update near by attraction         //
//  Developer: Ashish Kumar                                        //
//  Date: 2022-04-04                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, InputGroup, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import AttractionTypeService from "../../services/AttractionTypeService";
import NearByAttractionService from "../../services/NearByAttractionService";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import CityService from "../../services/CityService";
import Select from "react-select";
import eventImage from "./../../assets/images/brand_placeholder.png";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import PropertySidebar from "../common/PropertySidebar";
import PhoneInput from "react-phone-input-2";

export class NearByAttractionForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      sort_order: "",
      pathname: window.location.pathname,
      attractionTypeList: [],
      newAttractionTypeList: [],
      selectedAttractionTypeList: [],
      nearby_attraction_name: "",
      attraction_type_slug: "",
      description: "",
      event_image: "",
      opening_hour: "",
      website: "",
      latitude: "",
      longitude: "",
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      street_1: "",
      street_2: "",
      country_slug: "",
      county_slug: "",
      state_slug: "",
      city_slug: "",
      zipcode: "",
      imageUrl: eventImage,
      confirm_back: 0,
      selectedLocationTypeList: [],
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      cityListTotal: 0,
      distance_value: "",
      distance_unit_slug: null,
      distanceUnitList: [],
      selectedDistanceUnitList: [],
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveNearByAttraction = this.saveNearByAttraction.bind(this);
    this.handleChangeAttractionTypeList =
      this.handleChangeAttractionTypeList.bind(this);
    this.openDeleteNearByAttractionModal =
      this.openDeleteNearByAttractionModal.bind(this);
    this.closeDeleteAttrcationModal =
      this.closeDeleteAttrcationModal.bind(this);
    this.deleteAttraction = this.deleteAttraction.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.setState({ showSpinner: true, loading: true });
    this.getCityData();
    this.getAttractionTypeData();
    this.getDistanceUnitData();
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await NearByAttractionService.getNearByAttractionBySlug(
        this.state.slug
      );
      if (global.successStatus.includes(res.status)) {
        resData.nearby_attraction_name = res.data.nearby_attraction_name
          ? res.data.nearby_attraction_name
          : "";
        resData.attraction_type_slug = res.data.attraction_type_slug
          ? res.data.attraction_type_slug
          : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.event_image = res.data.event_image ? res.data.event_image : "";
        resData.imageUrl = res.data.event_image_url
          ? res.data.event_image_url
          : eventImage;
        resData.opening_hour = res.data.opening_hour
          ? res.data.opening_hour
          : "";
        resData.website = res.data.website ? res.data.website : "";
        resData.latitude = res.data.latitude ? res.data.latitude : "";
        resData.longitude = res.data.longitude ? res.data.longitude : "";
        resData.contact_name = res.data.contact_name
          ? res.data.contact_name
          : "";
        resData.contact_email = res.data.contact_email
          ? res.data.contact_email
          : "";
        resData.contact_phone = res.data.contact_phone
          ? res.data.contact_phone
          : "";
        resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resData.city_slug = res.data.city_slug ? res.data.city_slug : "";
        resData.country_slug = res.data.country_slug
          ? res.data.country_slug
          : "";
        resData.county_slug = res.data.county_slug ? res.data.county_slug : "";
        resData.state_slug = res.data.state_slug ? res.data.state_slug : "";
        resData.zipcode = res.data.zipcode ? res.data.zipcode : "";
        resData.country_name = res.data.country
          ? res.data.country.country_name
          : "";
        resData.state_name = res.data.state ? res.data.state.state_name : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        resData.distance_value = res.data.distance_value
          ? res.data.distance_value
          : "";
        resData.distance_unit_slug = res.data.distanceUnitData
          ? res.data.distanceUnitData.slug
          : "";

        var selectedAttractionTypeList = [];
        selectedAttractionTypeList = res.data.attraction_type
          ? [
              {
                value: res.data.attraction_type.slug,
                label: res.data.attraction_type.attraction_type_name,
              },
            ]
          : "";

        var selectedCityList = [];
        selectedCityList = res.data.city
          ? [
              {
                value: res.data.city.slug,
                label: res.data.city.city_name,
              },
            ]
          : "";
        var selectedStateList = [];
        selectedStateList = res.data.state
          ? [
              {
                value: res.data.state.slug,
                label: res.data.state.state_name,
              },
            ]
          : "";
        var selectedCountyList = [];
        selectedCountyList = res.data.county
          ? [
              {
                value: res.data.county.slug,
                label: res.data.county.county_name,
              },
            ]
          : "";
        var selectedCountryList = [];
        selectedCountryList = res.data.country
          ? [
              {
                value: res.data.country.slug,
                label: res.data.country.country_name,
              },
            ]
          : "";
        var selectedDistanceUnitList = [];
        selectedDistanceUnitList = res.data.distanceUnitData
          ? [
              {
                value: res.data.distanceUnitData.slug,
                label: res.data.distanceUnitData.distance_unit_name,
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({
        selectedCityList: selectedCityList,
        selectedStateList: selectedStateList,
        selectedCountyList: selectedCountyList,
        selectedCountryList: selectedCountryList,
        selectedAttractionTypeList: selectedAttractionTypeList,
        selectedDistanceUnitList: selectedDistanceUnitList,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  openDeleteNearByAttractionModal(slug) {
    this.setState({ deleteNearByAttractionModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteAttrcationModal() {
    this.setState({ deleteNearByAttractionModal: false });
    this.setState({ slug: "" });
  }

  async deleteAttraction(slug) {
    this.setState({ showSpinner: true });
    let res = await NearByAttractionService.deleteNearByAttraction(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAttrcationModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" +
              this.state.property_slug +
              "/near-by-attractions"
          ),
        global.redirect_time
      );
    } else {
      this.closeDeleteAttrcationModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  /* to get near by attractions data */
  async getPropertyNearByAttractionData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var nearByAttractionList = [];
    var nearByAttractionListTotal = 0;

    let res = await NearByAttractionService.getNearByAttraction(
      "property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      nearByAttractionList = res.data.data ? res.data.data : [];
      nearByAttractionListTotal = nearByAttractionList
        ? nearByAttractionList.length
        : 0;
    }
    this.setState({
      nearByAttractionList: nearByAttractionList,
      nearByAttractionListTotal: nearByAttractionListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get attraction type list data */
  async getAttractionTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var attractionTypeList = [];
    var attractionTypeListTotal = 0;

    let res = await AttractionTypeService.getAttractionType(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      attractionTypeList = res.data.data ? res.data.data : [];
      attractionTypeListTotal = attractionTypeList
        ? attractionTypeList.length
        : 0;
    }
    const newAttractionTypeList =
      attractionTypeListTotal > 0
        ? attractionTypeList.map(({ slug, attraction_type_name }) => ({
            value: slug,
            label: attraction_type_name,
          }))
        : [];
    this.setState({
      attractionTypeList: attractionTypeList,
      attractionTypeListTotal: attractionTypeListTotal,
      newAttractionTypeList: newAttractionTypeList,
    });

    this.setState({ showSpinner: false, loading: false });
  }

  async getDistanceUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var distanceUnitList = [];

    let res = await AttractionTypeService.getDistanceUnit(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      distanceUnitList = res.data ? res.data : [];
    }
    const newDistanceUnitList = distanceUnitList.map(
      ({ slug, distance_unit_name }) => ({
        value: slug,
        label: distance_unit_name,
      })
    );
    this.setState({
      distanceUnitList: distanceUnitList,
      newDistanceUnitList: newDistanceUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCountryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];

    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
    }
    const newCountryList = countryList.map(({ slug, country_name }) => ({
      value: slug,
      label: country_name,
    }));
    this.setState({ countryList: countryList, newCountryList: newCountryList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCountyData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countyList = [];
    var countyListTotal = 0;
    let res = await CountyService.getCounty(
      "is_dropdown=1&country_slug=" +
        value +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    const newCountyList =
      countyListTotal > 0
        ? countyList.map(({ slug, county_name }) => ({
            value: slug,
            label: county_name,
          }))
        : [];
    this.setState({
      countyList: countyList,
      newCountyList: newCountyList,
      countyListTotal: countyListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }
  onChangePhone(value, country, e, formattedValue){
    //console.log(value, country, e, formattedValue);
    this.setState({contact_phone:value});
    this.setState({dial_code:country.dialCode});
}

  async getStateData(countryValue, countyValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var stateList = [];

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
        countryValue +
        "&county_slug=" +
        countyValue +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    const newStateList = stateList.map(({ slug, state_name }) => ({
      value: slug,
      label: state_name,
    }));
    this.setState({ stateList: stateList, newStateList: newStateList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCityData(stateValue, countyValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;

    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
            value: slug,
            label: city_name,
          }))
        : [];
    this.setState({
      cityList: cityList,
      newCityList: newCityList,
      cityListTotal: cityListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getAllRealetedData(slug, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.country_slug = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country
        ? [
            {
              value: res.data.countryData.country
                ? res.data.countryData.country.slug
                : "",
              label: res.data.countryData.country
                ? res.data.countryData.country.country_name
                : "",
            },
          ]
        : "";
      resData.county_slug = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
            {
              value: res.data.county.slug ? res.data.county.slug : "",
              label: res.data.county.county_name
                ? res.data.county.county_name
                : "",
            },
          ]
        : "";
      resData.state_slug = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
            {
              value: res.data.state.slug ? res.data.state.slug : "",
              label: res.data.state.state_name ? res.data.state.state_name : "",
            },
          ]
        : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "event_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      );
      fileData.append("mediaType", "1");
      fileData.append("propertySlug", this.state.property_slug);
      let res = await NearByAttractionService.uploadNearByAttractionEventImage(
        fileData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          event_image: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  handleChangeAttractionTypeList(value) {
    let attraction_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        attraction_type_slug.push(item.value);
      });
    }
    const attraction_type_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedAttractionTypeList: value,
      attraction_type_slug: attraction_type_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCityList(value) {
    let city_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_slug.push(item.value);
      });
    }
    if (value !== null) {
      this.getAllRealetedData(value.value);
    }
    const city_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCityList: value,
      city_slug: city_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStateList(value) {
    let state_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_slug.push(item.value);
      });
    }
    const state_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedStateList: value,
      state_slug: state_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountyList(value) {
    let county_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        county_slug.push(item.value);
      });
    }
    const county_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCountyList: value,
      county_slug: county_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCountryList(value) {
    let country_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_slug.push(item.value);
      });
    }
    const country_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCountryList: value,
      country_slug: country_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeDistanceUnitList(value) {
    let distance_unit = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        distance_unit.push(item.value);
      });
    }
    const distance_unit_value = value !== null ? value.value : [];
    this.setState({
      selectedDistanceUnitList: value,
      distance_unit_slug: distance_unit_value,
    });
    this.setState({ confirm_back: 1 });
  }

  saveNearByAttraction = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        nearby_attraction_name: this.state.nearby_attraction_name
          ? this.state.nearby_attraction_name
          : "",
        attraction_type_slug: this.state.attraction_type_slug
          ? this.state.attraction_type_slug
          : "",
        description: this.state.description ? this.state.description : "",
        event_image: this.state.event_image ? this.state.event_image : "",
        opening_hour: this.state.opening_hour ? this.state.opening_hour : "",
        website: this.state.website ? this.state.website : "",
        latitude: this.state.latitude ? this.state.latitude : "",
        longitude: this.state.longitude ? this.state.longitude : "",
        contact_name: this.state.contact_name ? this.state.contact_name : "",
        contact_email: this.state.contact_email ? this.state.contact_email : "",
        contact_phone: this.state.contact_phone ? this.state.contact_phone : "",
        dial_code : this.state.dial_code ? this.state.dial_code : "",
        street_1: this.state.street_1 ? this.state.street_1 : "",
        street_2: this.state.street_2 ? this.state.street_2 : "",
        city_slug: this.state.city_slug ? this.state.city_slug : 0,
        country_slug: this.state.country_slug ? this.state.country_slug : 0,
        county_slug: this.state.county_slug ? this.state.county_slug : 0,
        state_slug: this.state.state_slug ? this.state.state_slug : 0,
        zipcode: this.state.zipcode ? this.state.zipcode : "",
        sort_order: this.state.sort_order ? this.state.sort_order : "",
        status_id: this.state.status_id === true ? 1 : 2,
        distance_value: this.state.distance_value
          ? parseFloat(this.state.distance_value)
          : null,
        distance_unit_slug: this.state.distance_unit_slug
          ? this.state.distance_unit_slug
          : "",
      };

      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await NearByAttractionService.updateNearByAttraction(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/near-by-attractions"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await NearByAttractionService.createNearByAttraction(
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/near-by-attractions"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    if (event.target.name === "tax_type_slug") {
      // console.log(event.target);
    }
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.description !== undefined &&
      input.description !== "" &&
      input.description !== null &&
      input.description.length > 100000
    ) {
      isValid = false;
      errors["description"] =
        "The unit description character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Near By";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  removePhoto(){
    this.setState({event_image:"",imageUrl:""});
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                          <span
                            onClick={() => {
                              this.openDeleteNearByAttractionModal(
                                this.state.slug
                              );
                            }}
                            className="deleteicon mg-r-20"
                          >
                            <img src={deleteicon} />
                          </span>
                        </>
                      ) : (
                        ""
                      )}

                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                  this.state.property_slug +
                                  "/near-by-attractions"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                                this.state.property_slug +
                                "/near-by-attractions"
                            );
                        }}
                      >
                        Cancel
                      </button>

                      {this.state.slug !== undefined &&
                      global.userPermissions.checkPermission(
                        "near-by-update"
                      ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveNearByAttraction}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : global.userPermissions.checkPermission(
                          "near-by-add"
                        ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveNearByAttraction}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    <div className="m-0">
                      <div className="row align-items-center pd-lg-t-30 pd-lg-b-30 p border-bottom-0">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Near By Name{" "}
                          </label>

                          <InputGroup>
                            <div className="col-md-12 p-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="nearby_attraction_name"
                                value={this.state.nearby_attraction_name || ""}
                                className="form-control max_width_100"
                                type="text"
                                maxLength="50"
                                autoComplete="off"
                              />
                            </div>
                            {this.state.errors.nearby_attraction_name ? (
                              <div className="text-danger">
                                {this.state.errors.nearby_attraction_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "neighborhood_name",
                                this.state.nearby_attraction_name,
                                "required|max:100",
                                { className: "text-danger" }
                              )
                            )}
                          </InputGroup>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Near By Type{" "}
                          </label>

                          <Select
                            styles={customStyles}
                            isClearable={true}
                            menuPlacement="auto"
                            className="multiselect"
                            classNamePrefix={"my-custom-react-select"}
                            options={this.state.newAttractionTypeList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeAttractionTypeList(value)
                            }
                            defaultValue={this.state.selectedAttractionTypeList}
                            value={this.state.selectedAttractionTypeList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.attraction_type_slug ? (
                            <div className="text-danger">
                              {this.state.errors.attraction_type_slug}
                            </div>
                          ) : (
                            this.validator.message(
                              "neighborhood_type",
                              this.state.attraction_type_slug,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                      </div>

                      {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                        <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                      </div> */}
                      <div className="col-lg-12 pd-xs-l-20 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 ">
                        <h3 className="mg-l-5 background-head">Location</h3>
                      </div>
                      <div className="row align-items-center pd-lg-b-30 border-bottom-0">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Street 1
                          </label>

                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="street_1"
                            value={this.state.street_1 || ""}
                            autoComplete="off"
                          />
                          {this.state.errors.street_1 ? (
                            <div className="text-danger">
                              {this.state.errors.street_1}
                            </div>
                          ) : (
                            this.validator.message(
                              "street_1",
                              this.state.street_1,
                              "required|max:100",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30  pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Street 2{" "}
                            <span className="optional">(Optional)</span>
                          </label>

                          <Form.Control
                            type="text"
                            onChange={this.changeHandler}
                            name="street_2"
                            value={this.state.street_2 || ""}
                          />
                          {this.state.errors.street_2 ? (
                            <div className="text-danger">
                              {this.state.errors.street_2}
                            </div>
                          ) : (
                            this.validator.message(
                              "street_2",
                              this.state.street_2,
                              "max:100",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30  pd-lg-r-45 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            City
                          </label>

                          <Select
                            styles={customStyles}
                            isClearable={true}
                            className="multiselect"
                            classNamePrefix={"my-custom-react-select"}
                            menuPlacement="auto"
                            value={this.state.selectedCityList}
                            options={this.state.newCityList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeCityList(value)
                            }
                            defaultValue={this.state.selectedCityList}
                            autoComplete="off"
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.city_slug ? (
                            <div className="text-danger">
                              {this.state.errors.city_slug}
                            </div>
                          ) : (
                            this.validator.message(
                              "city",
                              this.state.city_slug,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30  pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            State
                          </label>

                          <Form.Control
                            type="text"
                            className="custom-disable"
                            value={this.state.state_name || ""}
                            disabled={true}
                          />
                          {this.state.errors.state_slug ? (
                            <div className="text-danger">
                              {this.state.errors.state_slug}
                            </div>
                          ) : (
                            this.validator.message(
                              "state",
                              this.state.state_slug,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30  pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Zip Code
                          </label>

                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="zipcode"
                            value={this.state.zipcode || ""}
                            maxLength="5"
                            onKeyPress={global.onKeyPressEvent.numberValidation}
                          />
                          {this.state.errors.zipcode ? (
                            <div className="text-danger">
                              {this.state.errors.zipcode}
                            </div>
                          ) : (
                            this.validator.message(
                              "zipcode",
                              this.state.zipcode,
                              "required|max:5",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30  pd-lg-r-45 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            County <span className="optional">(Optional)</span>
                          </label>

                          <Select
                            styles={customStyles}
                            isClearable={true}
                            className="multiselect"
                            classNamePrefix={"my-custom-react-select"}
                            menuPlacement="auto"
                            value={this.state.selectedCountyList}
                            options={this.state.newCountyList.filter(
                              (option) => {
                                const val1 = this.state.county_slug
                                  ? this.state.county_slug
                                  : "";
                                if (val1 === option.slug) return true;
                              }
                            )}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeCountyList(value)
                            }
                            defaultValue={this.state.selectedCountyList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.county_slug ? (
                            <div className="text-danger">
                              {this.state.errors.county_slug}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30  pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Country
                          </label>

                          <Form.Control
                            type="text"
                            className="custom-disable"
                            value={this.state.country_name || ""}
                            disabled={true}
                          />
                          {this.state.errors.country_slug ? (
                            <div className="text-danger">
                              {this.state.errors.country_slug}
                            </div>
                          ) : (
                            this.validator.message(
                              "country",
                              this.state.country_slug,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 0 pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Latitude
                          </label>

                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="latitude"
                            value={this.state.latitude || ""}
                          />
                          {this.state.errors.latitude ? (
                            <div className="text-danger">
                              {this.state.errors.latitude}
                            </div>
                          ) : (
                            this.validator.message(
                              "latitude",
                              this.state.latitude,
                              "required|max:50",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30  pd-lg-r-45 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Longitude
                          </label>

                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="longitude"
                            value={this.state.longitude || ""}
                          />
                          {this.state.errors.longitude ? (
                            <div className="text-danger">
                              {this.state.errors.longitude}
                            </div>
                          ) : (
                            this.validator.message(
                              "longitude",
                              this.state.longitude,
                              "required|max:50",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30  pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Opening Hours
                          </label>

                          <Form.Control
                            onChange={this.changeHandler}
                            name="opening_hour"
                            value={this.state.opening_hour || ""}
                            className="form-control max_width_100"
                            rows="1"
                            as="textarea"
                            maxLength="5000"
                            autoComplete="off"
                          />
                          {this.state.errors.opening_hour ? (
                            <div className="text-danger">
                              {this.state.errors.opening_hour}
                            </div>
                          ) : (
                            this.validator.message(
                              "opening_hours",
                              this.state.opening_hour,
                              "required|max:500",
                              {
                                className: "text-danger",
                              }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30  pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Map URL <span className="optional">(Optional)</span>
                          </label>

                          <Form.Control
                            onChange={this.changeHandler}
                            name="website"
                            value={this.state.website || ""}
                            className="form-control max_width_100"
                            type="text"
                            maxLength="500"
                            autoComplete="off"
                          />
                          {this.state.errors.website ? (
                            <div className="text-danger">
                              {this.state.errors.website}
                            </div>
                          ) : (
                            this.validator.message(
                              "website",
                              this.state.website,
                              "url|max:500",
                              {
                                className: "text-danger",
                              }
                            )
                          )}
                        </div>
                        <div className="row col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-0 pd-lg-r-35">
                          <div className="col-md-6 col-xs-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-10 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Distance
                            </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              name="distance_value"
                              value={this.state.distance_value || ""}
                              className="form-control max_width_100"
                              type="text"
                              maxLength="50"
                              autoComplete="off"
                              onKeyPress={
                                global.onKeyPressEvent.floatValidation
                              }
                            />
                            {this.state.errors.distance_value ? (
                              <div className="text-danger">
                                {this.state.errors.distance_value}
                              </div>
                            ) : (
                              this.validator.message(
                                "distance",
                                this.state.distance_value,
                                "required|max:50",
                                {
                                  className: "text-danger",
                                }
                              )
                            )}
                          </div>
                          <div className="col-md-6 col-xs-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-10 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Distance Unit
                            </label>
                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedDistanceUnitList}
                              options={this.state.newDistanceUnitList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeDistanceUnitList(value)
                              }
                              defaultValue={this.state.selectedDistanceUnitList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                            {this.state.errors.distance_unit_slug ? (
                              <div className="text-danger">
                                {this.state.errors.distance_unit_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "distance_unit",
                                this.state.distance_unit_slug,
                                "required",
                                {
                                  className: "text-danger",
                                }
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 pd-xs-l-20 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 ">
                        <h3 className="mg-l-5 background-head">Contact</h3>
                      </div>
                      <div className="row align-items-center pd-b-0 border-bottom-0">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Contact Name
                          </label>

                          <Form.Control
                            onChange={this.changeHandler}
                            name="contact_name"
                            value={this.state.contact_name || ""}
                            className="form-control max_width_100"
                            type="text"
                            maxLength="50"
                            autoComplete="off"
                          />
                          {this.state.errors.contact_name ? (
                            <div className="text-danger">
                              {this.state.errors.contact_name}
                            </div>
                          ) : (
                            this.validator.message(
                              "contact_name",
                              this.state.contact_name,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Phone
                          </label>

                          <PhoneInput
                            country={global.country}
                            disableSearchIcon
                            // autoFormat= "true"
                            enableSearch="true"
                            countryCodeEditable="true"
                            value={this.state.contact_phone}
                            onChange={this.onChangePhone}
                          />
                          {this.state.errors.contact_phone ? (
                            <div className="text-danger">
                              {this.state.errors.contact_phone}
                            </div>
                          ) : (
                            this.validator.message(
                              "phone",
                              this.state.contact_phone,
                              "required",
                              {
                                className: "text-danger",
                              }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Email
                          </label>

                          <Form.Control
                            onChange={this.changeHandler}
                            name="contact_email"
                            value={this.state.contact_email || ""}
                            className="form-control max_width_100"
                            type="text"
                            maxLength="50"
                            autoComplete="off"
                          />
                          {this.state.errors.contact_email ? (
                            <div className="text-danger">
                              {this.state.errors.contact_email}
                            </div>
                          ) : (
                            this.validator.message(
                              "email",
                              this.state.contact_email,
                              "required|email",
                              {
                                className: "text-danger",
                              }
                            )
                          )}
                        </div>
                      </div>
                      {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                        <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                      </div> */}
                      <div className="row align-items-center pd-b-30 border-bottom-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45  mg-b-15">
                          <InputGroup className="mt-0">
                            <label className="form-label text-left pd-b-5 w-100">
                              Description
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="description"
                              value={this.state.description}
                              className="form-control max_width_100"
                              id="description"
                              as="textarea"
                              rows={global.textareaRowLength}
                              // placeholder="Username"
                            />
                          </InputGroup>
                          {this.state.errors.description ? (
                            <div className="text-danger">
                              {this.state.errors.description}
                            </div>
                          ) : (
                            this.validator.message(
                              "description",
                              this.state.description,
                              "required|max:100000",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                      </div>

                      {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                        <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                      </div> */}
                      <div className="row align-items-center pd-b-0 border-bottom-0">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Near By Image{" "}
                            <span className="optional">(Optional)</span>
                          </label>

                          <div className="fileUpload d-flex flex-wrap justify-content-between">
                            <span>
                              <label className="hand-cursor mb-0">
                                <input
                                  onChange={this.onImageChange}
                                  type="file"
                                  name="event_image"
                                  tabIndex="2"
                                />
                                {this.state.imageUrl ? (
                                  <img src={this.state.imageUrl} alt="Image" />
                                ) : (
                                  <img
                                  src={eventImage}
                                  alt="Logo"
                                />
                                )}
                              </label>
                            </span>
                            <ul className="ruLink">
                              <li>
                                <label>
                                  <input
                                    onChange={this.onImageChange}
                                    type="file"
                                    name="event_image"
                                    tabIndex="2"
                                  />
                                </label>
                              </li>
                            </ul>
                            <p className="hand-cursor remove-pic remove-pic-three m-0" onClick={this.removePhoto}></p>
                          </div>
                          {this.state.errors.event_image ? (
                            <div className="text-danger">
                              {this.state.errors.event_image}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* <div className="row align-items-center pd-lg-b-30 pd-xs-b-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0 pd-t-0">
                        <div className="col-md-3">
                          <p className="hand-cursor text-danger" onClick={this.removePhoto}>Remove</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteNearByAttractionModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAttrcationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteAttraction(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default NearByAttractionForm;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ReservationCalendar.jsx                            //
//  Application: Calendar                                 //
//  Option: List all reservations                         //
//  Developer: NP  						                  //
//  Date: 2022-07-29                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React from "react";
import { useCallback, useState } from 'react';
import CalendarService from "../../services/CalendarService";
// import { Eventcalendar, Popup, formatDate } from '@mobiscroll/react';
import { Eventcalendar,setOptions, CalendarNav, SegmentedGroup, SegmentedItem, CalendarPrev, CalendarToday, CalendarNext,Popup, formatDate } from '@mobiscroll/react';
// import { Link } from 'react-router-dom'
import { Container } from "react-bootstrap";

setOptions({
    theme: 'ios',
    themeVariant: 'light'
});

const ReservationCalendar = (props) => {
    // console.log(props);
    // const view = React.useMemo(() => {
    //     return {
    //         timeline: {
    //             type: 'month',
    //             size: "1",
    //             rowHeight:"equal",
    //         },
    //         // dateFormat:"MM/DD/YY"
    //         // calendar: {
    //         //     type: 'month',
    //         //     size: "1",
    //         //     scroll:"vertical",
    //         // }
    //     };
    // }, []);
    const [view, setView] = React.useState('month');
    const [calView, setCalView] = React.useState(
        {
            timeline: {
                type: 'month',
            }
        }
    );

    const [event, setEvent] = useState({});
    const [events, myEvents] = useState([]);
    const [resources, myResources] = useState([]);
    const [isPopupOpen, setOpen] = useState(false);
    const property_slug = props.match.params.propertySlug;

    // const timerRef = React.useRef(null);
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const [time, setTime] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [anchor, setAnchor] = React.useState(null);

    React.useEffect(() => {
        const getReservationdata = async () => {
            let res = await CalendarService.getReservations(property_slug, "all");

            if (global.successStatus.includes(res.status)) {
                myEvents(res.data.reservationData);
                myResources(res.data.unitData);
            }
        }
        getReservationdata();

        console.log(events);
    }, [property_slug]);

    //event pills
    const myScheduleEvent = React.useCallback((data) => {
        const ev = data.original;
        const color = data.original.color;
        // const color = "#2c5046";
        return <div className="md-timeline-template-event" style={{ borderColor: color, background: color }}>
            <div className="md-timeline-template-event-cont">
                {/* <span className={"mbsc-icon mbsc-font-icon mbsc-icon-airplane"} style={{ background: color }}><img src={ev.img} alt="logo" /></span> */}
                <span className="md-timeline-template-title">{ev.title}</span>
            </div>
        </div>
    }, []);

    //onclick of pill
    const openPopup = useCallback((event) => {
        setOpen(true);
        setEvent(event);
    }, []);

    const closePopup = useCallback(() => {
        setOpen(false);
    }, []);

    //pill hover
    const onEventHoverIn = React.useCallback((args) => {
        const event = args.event;
        const title = event.title;
        const time = formatDate('MM/DD/YY hh:mm A', new Date(event.start)) + ' - ' + formatDate('MM/DD/YY hh:mm A', new Date(event.end));

        setTime(time);
        setTitle(title);

        // if(timerRef.current) {
        //     clearTimeout(timerRef.current);
        // }

        setAnchor(args.domEvent.target);
        setTooltipOpen(true);
    }, []);

    const onEventHoverOut = React.useCallback(() => {
        setTooltipOpen(false);
        // timerRef.current =setTimeout(() => { setTooltipOpen(false); }, 200);
    }, []);

    const onMouseEnter = React.useCallback(() => {
        setTooltipOpen(true);
        // if (timerRef.current) {
        //     clearTimeout(timerRef.current);
        // }
    }, []);

    const onMouseLeave = React.useCallback(() => {
        setTooltipOpen(false);
        // timerRef.current =setTimeout(() => { setOpen(false); }, 200);
    }, []);

    //unit and listing name on left
    const renderCustomResource = (resource) => {
        return <div className="md-resource-header-template-cont" title={resource.name}>
            <div className="md-resource-header-template-name">{resource.unit_name ? resource.unit_name : resource.unit_number}</div>
            <div className="md-resource-header-template-name">{resource.resident_name}</div>
            {/* <div className="md-resource-header-template-name">{resource.listing_name}</div> */}
        </div>;
    }

    //day week month view
    const renderMyHeader = () => {
        return <React.Fragment>
            <CalendarPrev className="md-event-listing-prev" />
            <CalendarNav className="md-event-listing-nav" />
            <CalendarNext className="md-event-listing-next" />
            <div className="md-event-listing-picker">
                <SegmentedGroup value={view} onChange={changeView}>
                    <SegmentedItem value="day">
                        Day
                    </SegmentedItem>
                    <SegmentedItem value="week">
                        Week
                    </SegmentedItem>
                    <SegmentedItem value="month">
                        Month
                    </SegmentedItem>
                </SegmentedGroup>
            </div>
            {/* <CalendarPrev className="md-event-listing-prev" /> */}
            <CalendarToday className="md-event-listing-today mr-4" />
            {/* <CalendarNext className="md-event-listing-next" /> */}
        </React.Fragment>;
    }

    const changeView = (event) => {
        let calView;

        switch (event.target.value) {
            case 'day':
                calView = {
                    timeline: {
                        type: 'day',
                    }
                }
                break;
            case 'week':
                calView = {
                    timeline: {
                        type: 'week',
                    }
                }
                break;
            case 'month':
            default:
                calView = {
                    timeline: {
                        type: 'month',
                    }
                }
                break;
        }

        setView(event.target.value);
        setCalView(calView);
    }

    return (
        <>
        <main>
            <Container className="innter-container">
                <div className="pd-lg-l-0 pd-xs-l-15">
                    <Eventcalendar
                        theme="ios"
                        themeVariant="light"
                        clickToCreate={false}
                        dragToCreate={false}
                        dragToMove={false}
                        dragToResize={false}
                        view={calView}
                        data={events}
                        resources={resources}
                        renderScheduleEvent={myScheduleEvent}
                        cssClass="md-timeline-template md-event-listing"
                        onEventClick={(event) => openPopup(event)}
                        showEventTooltip={false}
                        onEventHoverIn={onEventHoverIn}
                        onEventHoverOut={onEventHoverOut}
                        renderResource={renderCustomResource}
                        renderHeader={renderMyHeader}
                    />
                    <Popup
                        display="center"
                        // fullScreen={true}
                        contentPadding={true}
                        headerText={event && event.event && event.event.title ? event.event.title : ''}
                        isOpen={isPopupOpen}
                        onClose={closePopup}
                    >
                        <div className="text-center popup">
                            <span
                                className="popupClose hand-cursor"
                                onClick={closePopup}
                            >
                                {global.closee}
                            </span>
                            {global.userType !== 4 ? <> <p className="az-profile-name-text pl-0">Reservation code </p> <p className="media-body"> {event && event.event && event.event.confirmation_code ? event.event.confirmation_code : ''}</p> </> : ''}
                            <p className="az-profile-name-text pl-0">Check-in date & time </p> <p className="media-body"> {event && event.event && event.event.view_start ? event.event.view_start : ''}</p>
                            <p className="az-profile-name-text pl-0">Check-out date & time </p><p className="media-body"> {event && event.event && event.event.view_end ? event.event.view_end : ''}</p>
                            <p className="az-profile-name-text pl-0">No. of guests</p> <p className="media-body"> {event && event.event && event.event.guest ? event.event.guest : ''}</p>
                            {/* sending only reservation slug for mobile  */}
                            <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode" id="view-button" data-id={(event && event.event && event.event.reservation_slug ? event.event.reservation_slug : '')}>View</button>
                        </div>
                    </Popup>
                    <Popup
                        display="anchored"
                        isOpen={isTooltipOpen}
                        anchor={anchor}
                        touchUi={false}
                        showOverlay={false}
                        contentPadding={false}
                        closeOnOverlayClick={false}
                        width={350}
                        cssClass="md-tooltip"
                    >
                        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            <div className="md-tooltip-header">
                                <span className="md-tooltip-time">{title} <br /> {time}</span>
                            </div>
                        </div>
                    </Popup>
                </div>
            </Container>
        </main>
        </>
    );
}
export default ReservationCalendar
import React, { Component } from "react";
import { Container, Col, InputGroup, Table, Modal } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import Alerts from "../common/Alerts";
import { Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import PropertySidebar from "../common/PropertySidebar";
import NumberFormat from "react-number-format";
import StripePayoutService from "../../services/StripePayoutService";
import Moment from "moment";

export class PropertyPayoutConfiguration extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      confirm_back: 0,
      property_slug: this.props.match.params.propertySlug,
      aka_name: "",
      property_name: sessionStorage.getItem("property_name")
        ? sessionStorage.getItem("property_name")
        : "",
      earningConfigurationData: [
        {
          fee_type: 1,
          fee_type_name: "Total Nightly Rate",
          payout_oh: "",
          payout_resident: "",
          payout_property: "",
          slug: "",
        },
        {
          fee_type: 2,
          fee_type_name: "Cleaning Fees",
          payout_oh: "",
          payout_resident: "",
          payout_property: "",
          slug: "",
        },
        {
          fee_type: 3,
          fee_type_name: "Channel Fees",
          payout_oh: "",
          payout_resident: "",
          payout_property: "",
          slug: "",
        },
        {
          fee_type: 4,
          fee_type_name: "Parking Fees",
          payout_oh: "",
          payout_resident: "",
          payout_property: "",
          slug: "",
        },
      ],
      earning_configuration: [],
      stripeStatus: 0,
      is_stripe_setup: false,
      stripeAccountLink: "",
      stripe_account_id: "",
    };
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangeEarningConfiguration =
      this.handleChangeEarningConfiguration.bind(this);
    this.saveOrUpdatePropertyEarningConfiguration =
      this.saveOrUpdatePropertyEarningConfiguration.bind(this);
    this.getPropertyStripeAccount = this.getPropertyStripeAccount.bind(this);
    this.createStripeAccount = this.createStripeAccount.bind(this);
    this.getPropertyStripeW9Info = this.getPropertyStripeW9Info.bind(this);
    this.phoneNumberAutoFormat = this.phoneNumberAutoFormat.bind(this);
  }

  componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getPropertyEarningConfigurationData();
    this.getPropertyStripeAccount();
    this.getPropertyStripeW9Info();
  }

  async getPropertyEarningConfigurationData() {
    if (this.state.property_slug !== undefined) {
      var earningConfigurationDataLength = 0;
      var earningConfigurationData = [...this.state.earningConfigurationData];
      const values = [];

      let res = await PropertyService.getPropertyEarningConfiguration(
        "slug=" +
          this.state.property_slug +
          "&reference=properties" +
          "&list=" +
          3
      );

      if (global.successStatus.includes(res.status)) {
        earningConfigurationDataLength = res.data ? res.data.length : 0;
        earningConfigurationData = res.data
          ? res.data
          : [
              {
                fee_type: 1,
                fee_type_name: "Total Nightly Rate",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 2,
                fee_type_name: "Cleaning Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 3,
                fee_type_name: "Channel Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 4,
                fee_type_name: "Parking Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
            ];

        if (earningConfigurationDataLength > 0) {
          if (earningConfigurationDataLength === 4) {
            earningConfigurationData.forEach((item, i) => {
              values.push({
                fee_type: item.fee_type,
                fee_type_name: item.fee_type_name,
                payout_oh: item.payout_oh,
                payout_resident: item.payout_resident,
                payout_property: item.payout_property,
                slug: item.slug,
              });
            });
          } else {
            earningConfigurationData.forEach((item, i) => {
              values.push({
                fee_type: item.fee_type,
                fee_type_name: item.fee_type_name,
                payout_oh: item.payout_oh,
                payout_resident: item.payout_resident,
                payout_property: item.payout_property,
                slug: item.slug,
              });
            });
            values.push({
              fee_type: 4,
              fee_type_name: "Parking Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            });
          }
        } else {
          earningConfigurationData = [
            {
              fee_type: 1,
              fee_type_name: "Total Nightly Rate",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 2,
              fee_type_name: "Cleaning Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 3,
              fee_type_name: "Channel Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 4,
              fee_type_name: "Parking Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
          ];
        }

        setTimeout(() => {
          if (values.length > 0) {
            this.setState({
              earning_configuration: values,
              earningConfigurationData: values,
            });
          } else {
            this.setState({
              earningConfigurationData: earningConfigurationData,
            });
          }
        }, 100);
      }
    }
  }

  saveOrUpdatePropertyEarningConfiguration = async (e) => {
    this.setState({ showSpinner: true, isSubmit: true });
    let inputData = {
      earning_configuration: this.state.earning_configuration,
      property_slug: this.state.property_slug,
      refTableId: 3,
    };
    let res = await PropertyService.createPropertyEarningConfiguration(
      inputData
    );
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ earning_configuration: [] });
      this.changeView();
    } else {
      if (res.data.result.errorDetail !== null) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
    }

    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  getPropertyStripeAccount = async () => {
    if (this.state.property_slug && this.state.property_slug !== undefined) {
      this.setState({ showSpinner: true, loading: true });
      let res = await StripePayoutService.getPropertyStripeStatus(
        this.state.property_slug
      );
      if (global.successStatus.includes(res.status)) {
        var stripeStatus = res.data ? res.data.property_stripe_status : 0;
        var stripeAccountLink = res.data ? res.data.property_stripe_link : 0;
      }
      this.setState({
        showSpinner: false,
        loading: false,
        stripeStatus: stripeStatus,
        stripeAccountLink: stripeAccountLink,
      });
    }
  };

  confirmStripeClose = () => {
    if (this.state.is_stripe_setup === true) {
      this.setState({ is_stripe_setup: false });
    } else {
      this.setState({ is_stripe_setup: true });
    }
  };

  createStripeAccount = async () => {
    let inputData = {
      property_slug: this.state.property_slug,
    };
    var queryString = "";
    if (this.props.match.params.propertySlug === undefined) {
      queryString = "is_global=1";
    }
    let res = await StripePayoutService.createPropertyStripeAccount(
      inputData,
      queryString
    );
    if (global.successStatus.includes(res.status)) {
      var stripe_url = res.data ? res.data.url : "";
      var stripe_account_id = res.data ? res.data.stripe_account_id : "";
      if (stripe_url) {
        if (stripe_account_id) {
          this.setState({
            stripeStatus: 1,
            stripe_account_id: stripe_account_id,
          });
        }
        setTimeout(() => window.open(stripe_url), global.redirect_time);
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ is_stripe_setup: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  stripeAccount = () => {
    if (this.state.is_stripe_setup === true) {
      this.setState({ is_stripe_setup: false });
    } else {
      this.setState({ is_stripe_setup: true });
    }
  };

  updateStripeAccount = () => {
    window.open(this.state.stripeAccountLink + this.state.stripe_account_id);
  };

  getPropertyStripeW9Info = async () => {
    if (this.state.property_slug && this.state.property_slug !== undefined) {
      var resData = {};
      let res = await StripePayoutService.getPropertyStripeW9Info(
        this.state.property_slug
      );

      if (global.successStatus.includes(res.status)) {
        resData.business_name = res.data ? res.data.w9_resident_name : "";
        resData.business_street_1 = res.data ? res.data.street_1 : "";
        resData.business_street_2 = res.data ? res.data.street_2 : "";
        resData.business_zipcode = res.data ? res.data.zipcode : "";
        resData.business_country_name = res.data ? res.data.country_name : "";
        resData.business_city_name = res.data ? res.data.city_name : "";
        resData.business_county_name = res.data ? res.data.county_name : "";
        resData.business_state_name = res.data ? res.data.state_name : "";
        resData.business_email = res.data ? res.data.email : "";
        resData.stripe_account_id = res.data ? res.data.stripe_account_id : "";
        resData.bank_name = res.data ? res.data.bank_name : "";
        resData.last4 = res.data ? res.data.last4 : "";
        resData.ssn_number = res.data ? res.data.ssn_number : "";
        resData.ein_number = res.data ? res.data.ein_number : "";
        resData.disgraded_entity_name = res.data
          ? res.data.disgraded_entity_name
          : "";
        resData.external_object = res.data ? res.data.external_object : "";
        resData.exp_month = res.data ? res.data.exp_month : "";
        resData.exp_year = res.data ? res.data.exp_year : "";
        resData.external_brand = res.data ? res.data.external_brand : "";
        resData.business_phone_number =
          res.data && res.data.business_phone_number
            ? await this.phoneNumberAutoFormat(res.data.business_phone_number)
            : "";
        resData.business_signature_name = res.data
          ? res.data.signature_name
          : "";
        resData.business_signature_date = res.data
          ? Moment(res.data.signature_date).format("MM/DD/YY")
          : "";
      }
      this.setState(resData);
    }
  };

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    return finalnumber;
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
    // var confirmBack = 1;
    // this.props.confirmBackStatus(confirmBack);
  };

  handleChangeEarningConfiguration = (index, event) => {
    const values = [...this.state.earningConfigurationData];
    if (event.target.name === "payout_oh") {
      if (
        parseFloat(event.target.value) +
          (parseFloat(values[index].payout_property)
            ? parseFloat(values[index].payout_property)
            : 0) +
          (parseFloat(values[index].payout_resident)
            ? parseFloat(values[index].payout_resident)
            : 0) >
        100
      ) {
        values[index].payout_oh = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        values[index].to_oh_error =
          "OH % + Resident % + Property % total can't be greater than 100";
      } else {
        values[index].payout_oh = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        if (values[index].payout_oh > 100) {
          values[index].to_oh_error = "OH % can't be greater than 100";
        } else {
          values[index].to_oh_error = "";
        }
      }
    } else if (event.target.name === "payout_property") {
      if (
        parseFloat(event.target.value) +
          (parseFloat(values[index].payout_oh)
            ? parseFloat(values[index].payout_oh)
            : 0) +
          (parseFloat(values[index].payout_resident)
            ? parseFloat(values[index].payout_resident)
            : 0) >
        100
      ) {
        values[index].payout_property = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        values[index].to_property_error =
          "OH % + Resident % + Property % total can't be greater than 100";
      } else {
        values[index].payout_property = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        if (values[index].payout_property > 100) {
          values[index].to_property_error =
            "Property % can't be greater than 100";
        } else {
          values[index].to_property_error = "";
        }
      }
    } else if (event.target.name === "payout_resident") {
      if (
        parseFloat(event.target.value) +
          (parseFloat(values[index].payout_oh)
            ? parseFloat(values[index].payout_oh)
            : 0) +
          (parseFloat(values[index].to_building)
            ? parseFloat(values[index].to_building)
            : 0) >
        100
      ) {
        values[index].payout_resident = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        values[index].to_resident_error =
          "OH % + Resident % + Property % total can't be greater than 100";
      } else {
        values[index].payout_resident = event.target.value.replace(
          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
          ""
        );
        if (values[index].payout_resident > 100) {
          values[index].to_resident_error =
            "Resident % can't be greater than 100";
        } else {
          values[index].to_resident_error = "";
        }
      }
    }

    this.setState({ earning_configuration: values, confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    // var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({
        viewMode: 0,
        confirm_back: 0,
        earning_configuration: [],
        earningConfigurationData: [],
      });
      setTimeout(() => {
        this.getPropertyEarningConfigurationData();
      }, 200);
    } else {
      this.setState({
        viewMode: 1,
        confirm_back: 0,
        earning_configuration: [],
        earningConfigurationData: [],
      });
      setTimeout(() => {
        this.getPropertyEarningConfigurationData();
      }, 200);
    }
  }

  getTitle() {
    return "Payout Configuration";
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>

                    <div className="row d-flex justify-content-end mr-0">
                      {this.state.viewMode === 0 ? (
                        <div>
                          {global.userPermissions.checkPermission(
                            "legal-finance-update"
                          ) ? (
                            <button
                              className="btn-success-outline-small"
                              onClick={this.changeView}
                            >
                              <img src={editIcon} alt="edit" />
                              Edit
                            </button>
                          ) : (
                            ""
                          )}{" "}
                        </div>
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.changeView();
                                else return false;
                              else this.changeView();
                            }}
                          >
                            Cancel
                          </button>
                          {global.userPermissions.checkPermission(
                            "legal-finance-update"
                          ) ? (
                            <button
                              className="btn-success ml-3"
                              onClick={
                                this.saveOrUpdatePropertyEarningConfiguration
                              }
                            >
                              Save
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    {this.state.viewMode === 0 ? (
                      <div>
                        <div className="row pd-l-30 pd-lg-r-30 pd-xs-r-0">
                          <div className="col-md-12">
                            <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-15 pd-xs-l-0 pd-sm-l-45 pd-sm-r-15  pd-lg-r-0 pd-xs-r-0 mg-r-0">
                              <div className="table-responsive">
                                <Table hover className="payout_table_w">
                                  <thead>
                                    <tr>
                                      <th className="w-20">Type</th>
                                      <th className="w-20">OH %</th>
                                      <th className="w-20">Resident %</th>
                                      <th className="w-20 text-left">
                                        Property %
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.earningConfigurationData &&
                                    this.state.earningConfigurationData.length >
                                      0 ? (
                                      this.state.earningConfigurationData.map(
                                        (option, i) => (
                                          <tr key={i}>
                                            <td>
                                              {option.fee_type_name
                                                ? option.fee_type_name
                                                : ""}
                                            </td>
                                            <td>
                                              {option.payout_oh ? (
                                                <NumberFormat
                                                  value={parseFloat(
                                                    option.payout_oh
                                                  )
                                                    .toFixed(2)
                                                    .replace(/\.00$/, "")
                                                    .replace(
                                                      /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                      ""
                                                    )}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  suffix={"%"}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td>
                                              {option.payout_resident ? (
                                                <NumberFormat
                                                  value={parseFloat(
                                                    option.payout_resident
                                                  )
                                                    .toFixed(2)
                                                    .replace(/\.00$/, "")
                                                    .replace(
                                                      /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                      ""
                                                    )}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  suffix={"%"}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td className="text-left">
                                              {option.payout_property ? (
                                                <NumberFormat
                                                  value={parseFloat(
                                                    option.payout_property
                                                  )
                                                    .toFixed(2)
                                                    .replace(/\.00$/, "")
                                                    .replace(
                                                      /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                      ""
                                                    )}
                                                  displayType={"text"}
                                                  thousandSeparator={true}
                                                  suffix={"%"}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="9" align="center">
                                          There are no earning configuration
                                          added in the system.
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.viewMode === 1 ? (
                      <div className="m-0">
                        <div className="row pd-l-15 pd-r-15 align-items-center pd-t-0 border-bottom-0">
                          <div className="pd-lg-l-15 pd-lg-r-30 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0  pet-f mg-b-30 pet w-100">
                            <div className="col-md-12 p-0 petFeeSection">
                              <table className="petForm payout_table_w">
                                <thead>
                                  <tr>
                                    <th className="w-20">
                                      <label className="mb-1">Type</label>
                                    </th>

                                    <th className="w-20">
                                      <label className="mb-1">OH %</label>
                                    </th>
                                    <th className="w-20">
                                      <label className="mb-1">Resident %</label>
                                    </th>
                                    <th className="w-20 text-left">
                                      <label className="mb-1">Property %</label>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="table_cnt_top pay-out-tbl">
                                  {this.state.earningConfigurationData.map(
                                    (data, i) => {
                                      return (
                                        <tr key={i}>
                                          <td
                                            className=""
                                            vertical-align="middle"
                                          >
                                            <Col
                                              xs={12}
                                              className="pd-l-0 pd-r-2 colmn1"
                                            >
                                              <span>{data.fee_type_name}</span>
                                            </Col>
                                          </td>

                                          <td className="" align="middle">
                                            <Col
                                              xs={12}
                                              className="pd-l-2 pd-r-0 d-flex"
                                            >
                                              <InputGroup className="mt-0">
                                                <div className="input-group-prepend prefix-dollar percanteg-prefix">
                                                  <Form.Control
                                                    onKeyPress={
                                                      global.onKeyPressEvent
                                                        .floatValidation
                                                    }
                                                    name="payout_oh"
                                                    maxLength="5"
                                                    value={
                                                      data.payout_oh
                                                        ? data.payout_oh
                                                            .replace(
                                                              /\.00$/,
                                                              ""
                                                            )
                                                            .replace(
                                                              /[&#,+()$~%'":*?<>{}]/gi,
                                                              ""
                                                            ) || ""
                                                        : ""
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangeEarningConfiguration(
                                                        i,
                                                        event
                                                      )
                                                    }
                                                  />
                                                  <span className="input-group-text percanteg_symbl">
                                                    %
                                                  </span>
                                                </div>
                                              </InputGroup>
                                            </Col>
                                            {data.to_oh_error ? (
                                              <div className="text-danger">
                                                {data.to_oh_error}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td className="">
                                            <Col
                                              xs={12}
                                              className="pd-l-2 pd-r-0 d-flex"
                                            >
                                              <InputGroup className="mt-0">
                                                <div className="input-group-prepend prefix-dollar percanteg-prefix">
                                                  <Form.Control
                                                    onKeyPress={
                                                      global.onKeyPressEvent
                                                        .floatValidation
                                                    }
                                                    name="payout_resident"
                                                    maxLength="5"
                                                    max="100"
                                                    value={
                                                      data.payout_resident
                                                        ? data.payout_resident
                                                            .replace(
                                                              /\.00$/,
                                                              ""
                                                            )
                                                            .replace(
                                                              /[&#,+()$~%'":*?<>{}]/gi,
                                                              ""
                                                            ) || ""
                                                        : ""
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangeEarningConfiguration(
                                                        i,
                                                        event
                                                      )
                                                    }
                                                  />
                                                  <span className="input-group-text percanteg_symbl">
                                                    %
                                                  </span>
                                                </div>
                                              </InputGroup>
                                            </Col>
                                            {data.to_resident_error ? (
                                              <div className="text-danger">
                                                {data.to_resident_error}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td className=" text-left">
                                            <Col
                                              xs={12}
                                              className="pd-l-2 pd-r-2 colmn1"
                                            >
                                              <InputGroup className="mt-0">
                                                <div className="input-group-prepend prefix-dollar percanteg-prefix">
                                                  <Form.Control
                                                    onKeyPress={
                                                      global.onKeyPressEvent
                                                        .floatValidation
                                                    }
                                                    name="payout_property"
                                                    maxLength="5"
                                                    max="100"
                                                    value={
                                                      data.payout_property
                                                        ? data.payout_property
                                                            .replace(
                                                              /\.00$/,
                                                              ""
                                                            )
                                                            .replace(
                                                              /[&#,+()$~%'":*?<>{}]/gi,
                                                              ""
                                                            ) || ""
                                                        : ""
                                                    }
                                                    onChange={(event) =>
                                                      this.handleChangeEarningConfiguration(
                                                        i,
                                                        event
                                                      )
                                                    }
                                                  />
                                                  <span className="input-group-text percanteg_symbl">
                                                    %
                                                  </span>
                                                </div>
                                              </InputGroup>
                                            </Col>
                                            {data.to_property_error ? (
                                              <div className="text-danger">
                                                {data.to_property_error}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {global.userPermissions.checkPermission(
                      "create-stripe-view"
                    ) ? (
                      <React.Fragment>
                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-t-15">
                          <h3 className="mg-l-0 background-head">
                            Banking Information
                          </h3>
                        </div>
                        {this.state.stripeStatus === 0 ? (
                          <React.Fragment>
                            <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                              <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                                {global.userPermissions.checkPermission(
                                  "create-stripe-add"
                                ) ? (
                                  <React.Fragment>
                                    <ul className="onbaord_bank_info list-unstyled p-0">
                                      <li
                                        className="hand-cursor link-url"
                                        onClick={this.stripeAccount}
                                      >
                                        Click here to set up your banking
                                        information on Stripe
                                      </li>
                                    </ul>

                                    <p>
                                      To receive payments from Orion Haus,
                                      please set up your Stripe account for
                                      payouts. Begin by creating an account on
                                      the Stripe website and completing the
                                      necessary verification steps. Once
                                      verified, access your payout settings
                                      within your Stripe dashboard and add your
                                      bank account details accurately. If
                                      required, complete any additional identity
                                      verification steps and submit a W-9 form
                                      for U.S. taxpayers. After reviewing and
                                      confirming all information, you'll be
                                      ready to start receiving payments directly
                                      into your bank account according to Orion
                                      Haus's payout schedule.
                                    </p>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        {this.state.stripeStatus === 1 ? (
                          <React.Fragment>
                            <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                              <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                                <p>Stripe account setup is in progress.</p>
                                <p>
                                  To receive payments from Orion Haus, please
                                  set up your Stripe account for payouts. Begin
                                  by creating an account on the Stripe website
                                  and completing the necessary verification
                                  steps. Once verified, access your payout
                                  settings within your Stripe dashboard and add
                                  your bank account details accurately. If
                                  required, complete any additional identity
                                  verification steps and submit a W-9 form for
                                  U.S. taxpayers. After reviewing and confirming
                                  all information, you'll be ready to start
                                  receiving payments directly into your bank
                                  account according to Orion Haus's payout
                                  schedule.
                                </p>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        {this.state.stripeStatus === 2 ? (
                          <React.Fragment>
                            <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                              <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                                {global.userPermissions.checkPermission(
                                  "create-stripe-update"
                                ) &&
                                this.state.stripe_account_id !== "" &&
                                this.state.stripeAccountLink ? (
                                  <ul className="onbaord_bank_info list-unstyled p-0">
                                    <li
                                      className="hand-cursor link-url"
                                      onClick={this.updateStripeAccount}
                                    >
                                      Click here to update your banking
                                      information on Stripe
                                    </li>
                                  </ul>
                                ) : (
                                  ""
                                )}

                                <div className="row pd-b-5 border-bottom-0 mg-t-15 px-0">
                                  {this.state.external_object ===
                                  "bank_account" ? (
                                    <React.Fragment>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                        <p className="az-profile-name-text pl-0">
                                          Bank Name
                                        </p>
                                        <p className="media-body">
                                          {this.state.bank_name
                                            ? this.state.bank_name
                                            : ""}
                                        </p>
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                        <p className="az-profile-name-text pl-0">
                                          Bank Account Number
                                        </p>
                                        <p className="media-body">
                                          {this.state.last4
                                            ? "************" + this.state.last4
                                            : ""}
                                        </p>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.external_object === "card" ? (
                                    <React.Fragment>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                        <p className="az-profile-name-text pl-0">
                                          Brand
                                        </p>
                                        <p className="media-body">
                                          {this.state.external_brand
                                            ? this.state.external_brand
                                            : ""}
                                        </p>
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                        <p className="az-profile-name-text pl-0">
                                          Card Number
                                        </p>
                                        <p className="media-body">
                                          {this.state.last4
                                            ? "**********" + this.state.last4
                                            : ""}
                                        </p>
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                        <p className="az-profile-name-text pl-0">
                                          Expiry Month
                                        </p>
                                        <p className="media-body">
                                          {this.state.exp_month
                                            ? this.state.exp_month
                                            : ""}
                                        </p>
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                        <p className="az-profile-name-text pl-0">
                                          Expiry Year
                                        </p>
                                        <p className="media-body">
                                          {this.state.exp_year
                                            ? this.state.exp_year
                                            : ""}
                                        </p>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Stripe Account ID
                                    </p>
                                    <p className="media-body">
                                      {this.state.stripe_account_id
                                        ? this.state.stripe_account_id
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-t-15">
                              <h3 className="mg-l-0 background-head">
                                W9 Info
                              </h3>
                            </div>
                            <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                              <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                                <div className="row pd-b-5 border-bottom-0 mg-t-15 px-0">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      <label className="form-label text-left pd-b-5">
                                        Name or Business Name
                                        <span className="optional">
                                          (if different)
                                        </span>
                                      </label>
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_name
                                        ? this.state.business_name
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      SSN
                                    </p>
                                    <p className="media-body">
                                      {this.state.ssn_number
                                        ? this.state.ssn_number
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      EIN
                                    </p>
                                    <p className="media-body">
                                      {this.state.ein_number
                                        ? this.state.ein_number
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Disregarded Entity Name
                                    </p>
                                    <p className="media-body">
                                      {this.state.disgraded_entity_name
                                        ? this.state.disgraded_entity_name
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Email
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_email
                                        ? this.state.business_email
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Phone number
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_phone_number
                                        ? this.state.business_phone_number
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Street Address
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_street_1
                                        ? this.state.business_street_1
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Apt. no./House no.
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_street_2
                                        ? this.state.business_street_2
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      City
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_city_name
                                        ? this.state.business_city_name
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Zip Code
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_zipcode
                                        ? this.state.business_zipcode
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      State
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_state_name
                                        ? this.state.business_state_name
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Country
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_country_name
                                        ? this.state.business_country_name
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Signed Date
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_signature_date
                                        ? this.state.business_signature_date
                                        : ""}
                                    </p>
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Your Signature
                                    </p>
                                    <p className="media-body">
                                      {this.state.business_signature_name
                                        ? this.state.business_signature_name
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}{" "}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={this.state.is_stripe_setup}
          className=" searchModalbox1 confirm_modal_box"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14 modal-body">
            <span
              className="welcomeClose"
              onClick={() => this.confirmStripeClose()}
            >
              {global.closee}
            </span>

            <h3 className="text-center mt-3">
              Are you sure you want to create stripe account?
            </h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-center mt-3 row">
                  <button
                    type="button"
                    className="m-0 btn btn btn-success btn-block"
                    onClick={() => this.createStripeAccount()}
                  >
                    {this.state.isSend ? global.loader : "Yes"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block ml-3"
                    onClick={() => this.confirmStripeClose()}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default PropertyPayoutConfiguration;

import React, { Component } from 'react'
import NoData from './common/NoData'
import { Container } from "react-bootstrap";

class Analytics extends Component {
  render() {
    return (
    <main>
      <Container>
        <div className="dashboardRightcard mg-t-15">
            <NoData />
        </div>
      </Container>
    </main>
    )
  }
}

export default Analytics

//////////////////////////////////////////////////////////////
//     							                                        //
//  Program: ChannelService.js                              //
//  Application: ChannelService                             //
//  Option: Used for manage Channel                         //
//  Developer: Saurabh Shukla  						                  //
//  Date: 2022-05-11                                        //
//                                                          //
//////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class ChannelService {
  getChannel(queryString) {
    let endPoint = "channel" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createChannel(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "channel";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getChannelBySlug(slug) {
    let endPoint = "channel/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateChannel(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "channel/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteChannel(slug) {
    let endPoint = "channel/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new ChannelService();

////////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertiesView.jsx                         //
//  Application: PropertiesView                             //
//  Option: For view Properties View details                //
//  Developer: Ashish Kumar,NP,PS 				              //
//  Date: 2022-01-17                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Container, Tab, Nav } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import Basic from "../property/Basic";
import Location from "../property/Location";
// import Contact from "../property/Contact";
import PropertyAttributesView from "../property/PropertyAttributesView";
import PropertyAffordableView from "../property/PropertyAffordableView";
import PropertyOwnerView from "../property/PropertyOwnerView";
import PropertyOfficeHoursView from "../property/PropertyOfficeHoursView";
// import PropertyWiFiInfoView from "../property/PropertyWiFiInfoView"
import PropertiesImages from "../property/PropertiesImages";
// import PropertyVendorsView from "../property/PropertyVendorsView";
// import PropertyBankingView from "../property/PropertyBankingView"
import PropertyLegalEntity from "../property/PropertyLegalEntity"
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
// import propheadnavArrow from "../../assets/images/prop-head-nav-arrow.svg";
// import {Dropdown} from 'react-bootstrap';
import Alerts from "../common/Alerts"
// import exportIcon from "./../../assets/images/export.svg"
// import BackButton from "../../assets/images/back-button.svg"
// import publishIcon  from "../../assets/images/publish-icon.svg"
import PropertySidebar from "../common/PropertySidebar";

export class PropertiesView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleSwitchDark2: true,
      addModal: false,
      editModal: false,
      deleteModal: false,
      status_id: "",
      open: true,
      eventKey: "0",
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      property_logo_url: "",
      property_type: "",
      property_name: "",
      year_value: "",
      confirm_back: 0,
      aff_status: 0,
      activeClass: "basic"
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.exportProperty = this.exportProperty.bind(this);
    this.publishProperty = this.publishProperty.bind(this);
    this.changeLogo = this.changeLogo.bind(this);
    this.getConfirmStatus = this.getConfirmStatus.bind(this);
    this.getAffordableStatus = this.getAffordableStatus.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.getImgStatus = this.getImgStatus.bind(this);
    this.getImgStatusProp = this.getImgStatusProp.bind(this);
  }

  async componentDidMount() {
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData.property_name = res.data.property_name
          ? res.data.property_name
          : "";
        resData.property_type = res.data.property_type
          ? res.data.property_type.property_type_name
          : "";
        resData.year_value = res.data.year_value ? res.data.year_value : "";
        resData.property_logo_url = res.data.property_logo_url
          ? res.data.property_logo_url
          : "";
        resData.imageUrl = res.data.property_logo_url
          ? res.data.property_logo_url
          : "";
        resData.aff_status = res.data.affordable_component_check ? res.data.affordable_component_check : ''
        resData.status_id = res.data.status_id ? res.data.status_id === 1 ? true : false : ""
        //set values in session storage
        sessionStorage.setItem(
          "property_slug",
          this.state.property_slug ? this.state.property_slug : ""
        );
        sessionStorage.setItem(
          "property_name",
          res.data.property_name ? res.data.property_name : ""
        );
        sessionStorage.setItem(
          "property_type",
          res.data.property_type
            ? res.data.property_type.property_type_name
            : ""
        );
        sessionStorage.setItem(
          "year_value",
          res.data.year_value ? res.data.year_value : ""
        );
        sessionStorage.setItem(
          "property_logo_url",
          res.data.property_logo_url ? res.data.property_logo_url : propertyLogo
        );
        sessionStorage.setItem(
          "property_logo_thumb_url",
          res.data.property_logo_thumb_url
            ? res.data.property_logo_thumb_url
            : ""
        );

        localStorage.setItem("property_slug",this.state.property_slug);
        localStorage.setItem("property_name",res.data.property_name);
        localStorage.setItem("property_type",res.data.property_type.property_type_name);
        localStorage.setItem("year_value",res.data.year_value);
        localStorage.setItem("property_logo_url",res.data.property_logo_url);
        localStorage.setItem("property_logo_thumb_url",res.data.property_logo_thumb_url);
      }
      this.setState(resData);
    }
  }

  async exportProperty() {
    this.setState({ showSpinner: true });
    let res = await PropertyService.exportProductData(
      "property_slug=" + this.state.property_slug + "&is_export=1"
    );
    if (global.successStatus.includes(res.status)) {
      if (res.data.filePath !== undefined) {
        let a = document.createElement("a");
        a.href = res.data.filePath;
        a.download = res.data.fileName;
        a.click();
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async publishProperty() {
    this.setState({ showSpinner: true });
    //
    this.setState({ showSpinner: false });
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  getAffordableStatus(affStatus) {
    this.setState({ aff_status: affStatus });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e })
  }

  getTitle() {
    return "Property Detail";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  openAddModal() {
    this.setState({ addModal: true });
  }

  closeAddModal() {
    this.setState({ addModal: false });
  }

  openEditModal() {
    this.setState({ editModal: true });
  }

  closeEditModal() {
    this.setState({ editModal: false });
  }

  openDeleteModal() {
    this.setState({ deleteModal: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModal: false });
  }

  getImgStatus(imgFile) {
    if (imgFile) {
      this.setState({ imageUrl: imgFile });
    }
  }
  getImgStatusProp(imgFileProp) {
    this.setState({ imgPropUrl: imgFileProp });
  }


  changeLogo(name, type, year, logo, slug) {
    this.setState({ property_name: name });
    this.setState({ property_type: type });
    this.setState({ year_value: year });
    this.setState({ property_logo_url: logo });
    this.setState({ property_slug: slug });

    //set values in session storage
    sessionStorage.setItem("property_name", name ? name : "");
    sessionStorage.setItem("property_slug", slug);
    sessionStorage.setItem("property_type", type);
    sessionStorage.setItem("year_value", year);
    sessionStorage.setItem("property_logo_url", logo);

    localStorage.setItem("property_slug",slug);
    localStorage.setItem("property_name",name);
    localStorage.setItem("property_type",type);
    localStorage.setItem("year_value",year);
    localStorage.setItem("property_logo_url",logo);
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          <div className="row row-xs">
            <div className="col-md-10 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                {/* <Link
                  onClick={() => {
                    if (this.state.confirm_back === 1)
                      if (
                        window.confirm("Do you want to exit without saving?")
                      )
                        this.props.history.push("/properties");
                      else return false;
                    else this.props.history.push("/properties");
                  }}
                >
                  Properties
                </Link>
                <span> / </span>
                <span>{this.getTitle()}</span> */}
              </div>
            </div>
            {/* <div className="col-md-2 mt-3">
              <p className="d-flex justify-content-end">
                <span className="back-parent d-flex align-items-center">
                  <Link
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.props.history.push("/properties");
                        else return false;
                      else this.props.history.push("/properties");
                    }}
                  >
                    <img src={BackButton} alt="" className="back-button" />
                  </Link>
                  <Link
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.props.history.push("/properties");
                        else return false;
                      else this.props.history.push("/properties");
                    }}
                  >
                    Back
                  </Link>
                </span>
              </p>
            </div> */}
          </div>
          <div className="col-md-12 pl-0 pr-0">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
        </Container>

        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                    {/* <div className="topHead">
                    <div className="demo-avatar-group">
                      <div className="az-img-user avatar-md online">
                        <img
                          src={
                            this.state.property_logo_url
                              ? this.state.property_logo_url
                              : propertyLogo
                          }
                          className="rounded-circle"
                          alt=""
                        />
                      </div>
                      <div className="propname">
                        <h5>
                          {" "}
                          {this.state.property_name
                            ? this.state.property_name
                            : ""}{" "}
                        </h5>
                        <ul>
                          <li>
                            {this.state.property_type
                              ? this.state.property_type
                              : ""}
                          </li>
                          {this.state.year_value ? <li></li> : ""}
                          <li>
                            {" "}
                            {this.state.year_value
                              ? "Year " + this.state.year_value
                              : ""}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "0")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Property Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "0" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse
                          eventKey="0"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/basic")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic"
                              }
                            >
                              Property
                            </Link>

                            <Link
                              className={`${this.state.pathname.match("/tower")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              }
                            >
                              Towers
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/floors")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floors"
                              }
                            >
                              Floors
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/amenitites")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/amenitites"
                              }
                            >
                              Common Amenities
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/pet-policy")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                              }
                            >
                              Pet Policy
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/staff")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                            >
                              Staff
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/documents")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/documents"
                              }
                            >
                              Documents & Leases
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/property-level-tax")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              }
                            >
                              Taxes
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/nearby-attractions")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/nearby-attractions"
                              }
                            >
                              Near By Attractions
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/events")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                              }
                            >
                              Events
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card onClick={() => this.setOpen(!this.state.open, "3")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Units Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "3" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="3"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/floor-plans")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floor-plans"
                              }
                            >
                              Floor Plans
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/units")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units"
                              }
                            >
                              Units
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </nav> */}
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel pd-l-15">
                  <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2">
                    {/* added */}
                    {/* <div className="mobView- col-4 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-left">
                          <div className="d-flex prop-logo-name">
                            <div className="az-img-user avatar-md">
                            <figure>
                                  {this.state.status_id === true ? <b className="status-active"></b> : ''}
                                  {this.state.status_id === false ? <b className="status-inactive"></b> : ''}
                                  <img src={this.props.imgFile ? this.props.imgFile : (this.state.imageUrl ? this.state.imageUrl : propertyLogo)}
                                  className="rounded-circle"
                                  alt=""
                                />
                            </figure>
                            </div>
                            <div>
                            <h4>{this.state.property_name ? this.state.property_name : "Property" }</h4>
                            </div>
                          </div>
                        </div> */}
                    {/* end added */}
                    <div className="d-flex">
                      {/* <Link to={'/properties/update/'+this.state.property_slug}><button className="btn-success-outline-small mr-3"><img src={editIcon}/>Edit</button></Link> */}
                      {/* <Link to={'/properties/update/'+this.state.property_slug}><button className="btn-success-outline-small mr-3"><img src={editIcon}/>Edit</button></Link> */}

                      {/* <button className="btn-success-outline-small mr-3" onClick={this.exportProperty} disabled><img src={exportIcon} alt="" />Export</button>
                                    <button className="btn-success-outline-small" onClick={this.publishProperty} disabled><img src={publishIcon} alt="" />Publish</button> */}
                    </div>
                  </div>
                  <Tab.Container id="left-tabs-example" defaultActiveKey="basic" onSelect={(e) => this.getActiveClass(e)} eventKey={this.state.activeClass}>
                    {/* <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        <img src={propheadnavArrow}/> 
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="tx-13">
                                        <Dropdown.Item href="#/action-1">Label One</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Label two</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                    <ScrollingCarousel rightArrow={true} leftArrow={true} className="swipesection">
                      {/* <div className="swipesection"> */}
                      {/* <Nav variant="pills" className=""> */}
                      <Nav.Item >
                        <Nav.Link eventKey="basic" className={this.state.activeClass === "basic" ? "active" : ""}>Basic</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="location" className={this.state.activeClass === "location" ? "active" : ""}>Location</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="contact" className={this.state.activeClass === "contact" ? "active" : ""}>Contact</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="property-attributes" className={this.state.activeClass === "property-attributes" ? "active" : ""}>
                          Property Attributes
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                            <Nav.Link eventKey="banking-details" className={ this.state.activeClass === "banking-details" ? "active" : ""}>
                              Banking Details
                            </Nav.Link>
                          </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="legal-entity" className={this.state.activeClass === "legal-entity" ? "active" : ""}>
                          Legal & Finance
                        </Nav.Link>
                      </Nav.Item>
                      {this.state.aff_status === 1 ?
                        <Nav.Item>
                          <Nav.Link eventKey="affordable-component" className={this.state.activeClass === "affordable-component" ? "active" : ""}>
                            Affordable Component
                          </Nav.Link>
                        </Nav.Item>
                        : ''}
                      <Nav.Item>
                        <Nav.Link eventKey="owner-information" className={this.state.activeClass === "owner-information" ? "active" : ""}>
                          Owner Information
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="office-hours" className={this.state.activeClass === "office-hours" ? "active" : ""}>
                          Office Hours
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                                        <Nav.Link eventKey="wi-fi-information">Wi-Fi Information</Nav.Link>
                                    </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="propertiesImages" className={this.state.activeClass === "propertiesImages" ? "active" : ""}>
                          Property Images
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="property-vendors" className={this.state.activeClass === "property-vendors" ? "active" : ""}>
                          Vendors
                        </Nav.Link>
                      </Nav.Item> */}
                      {/* </Nav> */}
                      {/* <span><img src={propheadnavArrow}/></span> */}
                      {/* </div> */}
                    </ScrollingCarousel>

                    <Tab.Content>
                      <Tab.Pane eventKey="basic">
                        <Basic
                          property_slug={this.state.property_slug}
                          logo={this.changeLogo}
                          confirmBackStatus={this.getConfirmStatus}
                          affordable={this.getAffordableStatus}
                          imgFile={this.state.imageUrl}
                          imgFileProp={this.state.imgPropUrl}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="location">
                        <Location
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}

                        />
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="contact">
                        <Contact
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}

                        />
                      </Tab.Pane> */}

                      <Tab.Pane eventKey="property-attributes">
                        <PropertyAttributesView
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}

                        />
                      </Tab.Pane>

                      {/* <Tab.Pane eventKey="banking-details">
                        <PropertyBankingView
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}
                          
                        />
                      </Tab.Pane> */}
                      <Tab.Pane eventKey="legal-entity">
                        <PropertyLegalEntity
                        // property_slug={this.state.property_slug}
                        // confirmBackStatus={this.getConfirmStatus}

                        />
                      </Tab.Pane>
                      {this.state.aff_status === 1 ?
                        <Tab.Pane eventKey="affordable-component">
                          <PropertyAffordableView
                            property_slug={this.state.property_slug}
                            confirmBackStatus={this.getConfirmStatus}

                          />
                        </Tab.Pane>
                        : ''}
                      <Tab.Pane eventKey="owner-information">
                        <PropertyOwnerView
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}

                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="office-hours">
                        <PropertyOfficeHoursView
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}

                        />
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="wi-fi-information">
                                    <PropertyWiFiInfoView property_slug={this.state.property_slug} />
                                </Tab.Pane> */}
                      <Tab.Pane eventKey="propertiesImages">
                        <PropertiesImages
                          property_slug={this.state.property_slug}
                          property_name={this.state.property_name}
                          imgFile={this.getImgStatus}
                          imgFileProp={this.getImgStatusProp}
                        />
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="property-vendors">
                        <PropertyVendorsView
                          property_slug={this.state.property_slug}
                          confirmBackStatus={this.getConfirmStatus}

                        />
                      </Tab.Pane> */}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
            <div className="ht-50"></div>
          </div>
        </Container>
      </main>
    );
  }
}

export default PropertiesView;

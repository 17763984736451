////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Parking.jsx                                  //
//  Application:  Parking                                 //
//  Option: For add or update Parking                     //
//  Developer: Ashish Kumar                               //
//  Date: 2024-03-13                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import PropertyService from "../../services/PropertyService";
import PropertySidebar from "../common/PropertySidebar";
import Alerts from "../common/Alerts";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import NumberFormat from "react-number-format";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import renderHTML from "react-render-html";
import NoData from "../common/NoData";
import JoditEditor from "jodit-react";
import FileUploadService from "../../services/FileUploadService";
import { Jodit } from "jodit";

export class Parking extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      confirm_back: 0,
      property_slug: this.props.match.params.propertySlug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      isSubmit: false,
      pathname: window.location.pathname,
      viewMode: 0,
      descShowRulesParkingInstruction: 0,
      descShowRulesParkingPayment: 0,
    };
    this.editorConfig = {
      extraButtons: ["uploadImage", "uploadImageVideo"],
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.saveOrUpdatePetPolicy = this.saveOrUpdatePetPolicy.bind(this);
    this.changeView = this.changeView.bind(this);
    this.fullTextRulesParkingInstruction =
      this.fullTextRulesParkingInstruction.bind(this);
    this.setContentParkingInstruction =
      this.setContentParkingInstruction.bind(this);
      this.fullTextRulesParkingPayment = this.fullTextRulesParkingPayment.bind(this);
      this.setContentParkingPayment = this.setContentParkingPayment.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getPropertyData();
  }

  fullTextRulesParkingInstruction() {
    if (this.state.descShowRulesParkingInstruction === 0) {
      this.setState({ descShowRulesParkingInstruction: 1 });
    } else {
      this.setState({ descShowRulesParkingInstruction: 0 });
    }
  }

  fullTextRulesParkingPayment() {
    if (this.state.descShowRulesParkingPayment === 0) {
      this.setState({ descShowRulesParkingPayment: 1 });
    } else {
      this.setState({ descShowRulesParkingPayment: 0 });
    }
  }

  setContentParkingPayment(value){
    this.setState({
      parking_payment_instructions:value
    })
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];
      console.log("imageFile", imageFile);

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", imageFile, imageFile.name);
      fileData.append("fileName", imageFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertImage(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  uploadVideoButton = () => {
    Jodit.defaultOptions.controls.uploadImageVideo = {
      name: "Upload video",
      // iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editor) => {
        await this.videoUpload(editor);
      },
    };
  };

  videoUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async function () {
      const videoFile = input.files[0];
      console.log("videoFile", videoFile);

      if (!videoFile) {
        return;
      }

      if (!videoFile.name.match(/\.(mp3|mp4|mov)$/)) {
        return;
      }
      let fileData = new FormData();

      fileData.append("file", videoFile, videoFile.name);
      fileData.append("fileName", videoFile.name);
      fileData.append("folderName", "jodit");
      const response = await FileUploadService.uploadFromEditor(fileData);
      if (global.successStatus.includes(response.status)) {
        this.insertVideo(editor, response.data.filePath);
      }
    }.bind(this);
  };

  insertVideo = (editor, url) => {
    const video = editor.selection.j.createInside.element("video");
    video.setAttribute("src", url);
    editor.selection.insertNode(video);
  };

  setContentParkingInstruction(value) {
    this.setState({
      parking_instructions: value,
    });
  }

  async getPropertyData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData.parking_fee = res.data
          ? res.data.parking_fee.replace(/\.00$/, "")
          : "";
        resData.property_name = res.data ? res.data.property_name : "";
        resData.aka_name = res.data ? res.data.aka_name : "";
        resData.parking_instructions =
          res.data && res.data.parking_instructions
            ? res.data.parking_instructions
            : "";
        resData.parking_payment_instructions =
          res.data && res.data.parking_payment_instructions
            ? res.data.parking_payment_instructions
            : "";
      }
      this.setState(resData);
    }
  }

  saveOrUpdatePetPolicy = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        tab: "parking-instructions",
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        parking_instructions: this.state.parking_instructions,
        parking_payment_instructions: this.state.parking_payment_instructions,
        parking_fee: this.state.parking_fee,
      };
      if (this.state.property_slug !== undefined) {
        //pet policy api call
        let res = await PropertyService.updateProperty(
          this.state.property_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          // this.changeView();
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
        this.setState({ pet_data: [] });
      }

      this.changeView();
      this.getPropertyData();
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    this.setState({ isPetChange: 1 });
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customValidate() {
    let errors = {};
    let isValid = true;
    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.viewMode !== 0) {
      return "Parking";
    } else {
      return "Parking";
    }
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
    } else {
      this.setState({ viewMode: 1 });
    }
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      {this.state.viewMode === 0 ? (
                        <>
                          {global.userPermissions.checkPermission(
                            "property-parking-update"
                          ) ? (
                            <button
                              className="btn-success-outline-small"
                              onClick={this.changeView}
                            >
                              <img src={editIcon} alt="edit" /> Edit
                            </button>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={this.changeView}
                          >
                            Cancel
                          </button>
                          {global.userPermissions.checkPermission(
                            "property-parking-update"
                          ) ? (
                            <button
                              className="btn-success ml-3"
                              type="button"
                              onClick={this.saveOrUpdatePetPolicy}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit ? global.loader : "Save"}
                            </button>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-0 propfees ">
                    {/* view mode  */}
                    {this.state.viewMode === 0 ? (
                      <div className="col-md-12 pl-0 pr-0">
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>

                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-t-15">
                          <h3 className="mg-l-0 background-head">
                            Parking Fee
                          </h3>
                        </div>
                        <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                          <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                            <p className="az-profile-name-text pl-0">
                              Parking Fee Per Night
                            </p>
                            <p className="media-body">
                              {this.state.parking_fee ? (
                                <NumberFormat
                                  value={global.onKeyPressEvent.numberWithCommasForDecimal(
                                    parseFloat(this.state.parking_fee)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                      .replace(
                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                        ""
                                      )
                                  )}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-t-15">
                          <h3 className="mg-l-0 background-head">
                            Parking Instructions
                          </h3>
                        </div>

                        <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                          <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                            <p className="az-profile-name-text pl-0">
                              Instructions:
                            </p>
                            <div className="para">
                              {this.state.parking_instructions ? ( //check if property_rules is exist
                                this.state.parking_instructions.length >
                                global.showMoreLength ? ( //check if length is long
                                  this.state.descShowRulesParkingInstruction ===
                                  0 ? (
                                    <>
                                      <HTMLEllipsis
                                        unsafeHTML={
                                          this.state.parking_instructions
                                            ? this.state.parking_instructions
                                            : ""
                                        }
                                        maxLine="6"
                                        ellipsis=""
                                        trimright="true"
                                        basedOn="letters"
                                      />
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={
                                          this.fullTextRulesParkingInstruction
                                        }
                                      >
                                        Show More
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      {renderHTML(
                                        "" + this.state.parking_instructions
                                      )}
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={
                                          this.fullTextRulesParkingInstruction
                                        }
                                      >
                                        Show Less
                                      </p>
                                    </>
                                  )
                                ) : (
                                  renderHTML(
                                    "" + this.state.parking_instructions
                                  )
                                )
                              ) : (
                                <NoData
                                  msg="Parking Instructions"
                                  action=" Edit "
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mg-t-40 mg-md-t-0 pd-lg-l-15 pd-lg-r-15 ">
                          <div className="col-md-12 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
                            <p className="az-profile-name-text pl-0">
                              Payment Instructions:
                            </p>
                            <div className="para">
                              {this.state.parking_payment_instructions ? ( //check if property_rules is exist
                                this.state.parking_payment_instructions.length >
                                global.showMoreLength ? ( //check if length is long
                                  this.state.descShowRulesParkingPayment ===
                                  0 ? (
                                    <>
                                      <HTMLEllipsis
                                        unsafeHTML={
                                          this.state
                                            .parking_payment_instructions
                                            ? this.state
                                                .parking_payment_instructions
                                            : ""
                                        }
                                        maxLine="6"
                                        ellipsis=""
                                        trimright="true"
                                        basedOn="letters"
                                      />
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={
                                          this.fullTextRulesParkingPayment
                                        }
                                      >
                                        Show More
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      {renderHTML(
                                        "" +
                                          this.state
                                            .parking_payment_instructions
                                      )}
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={
                                          this.fullTextRulesParkingPayment
                                        }
                                      >
                                        Show Less
                                      </p>
                                    </>
                                  )
                                ) : (
                                  renderHTML(
                                    "" + this.state.parking_payment_instructions
                                  )
                                )
                              ) : (
                                <NoData
                                  msg="Payment Instructions"
                                  action=" Edit "
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* edit mode  */}

                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                          <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                          />
                        </div>
                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 pet-f pd-lg-t-15">
                          <h3 className="mg-l-0 background-head">
                            Parking Fee
                          </h3>
                          <div className="col-md-12 mg-t-40 mg-md-t-0 pd-l-0 pd-lg-r-15 ">
                            <div className="col-md-3 col-lg-3 mg-t-5 mg-md-t-0 pl-0">
                              <InputGroup className="mt-3">
                                <label className="form-label text-left pd-b-5 w-100">
                                  Parking Fee Per Night
                                </label>
                                <div className="input-group-prepend prefix-dollar w-100">
                                  <span className="input-group-text">$</span>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="parking_fee"
                                    value={this.state.parking_fee}
                                    className="form-control max_width_100 w-100"
                                    id="parking_fee"
                                  />
                                </div>
                              </InputGroup>
                              {this.state.errors.parking_fee ? (
                                <div className="text-danger">
                                  {this.state.errors.parking_fee}
                                </div>
                              ) : (
                                this.validator.message(
                                  "parking_fee",
                                  this.state.parking_fee,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 pet-f pd-lg-t-15">
                          <h3 className="mg-l-0 background-head">
                            Parking Instructions
                          </h3>
                          <div className="col-md-12 mg-t-40 mg-md-t-0 pd-l-0 pd-lg-r-15 ">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-r-15 mg-b-15 ">
                              <label className="form-label text-left pd-b-5">
                                Instructions: [Unit-Number] [Parking-Spot]
                              </label>

                              <JoditEditor
                                ref={null}
                                config={this.editorConfig}
                                name="parking_instructions"
                                id="parking_instructions"
                                value={this.state.parking_instructions}
                                onBlur={(newContent) =>
                                  this.setContentParkingInstruction(newContent)
                                }
                                onChange={(newContent) =>
                                  this.setContentParkingInstruction(newContent)
                                }
                              />

                              {this.state.errors.parking_instructions ? (
                                <div className="text-danger">
                                  {this.state.errors.parking_instructions}
                                </div>
                              ) : (
                                this.validator.message(
                                  "parking_instructions",
                                  this.state.parking_instructions,
                                  "max:100000",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 mg-t-40 mg-md-t-0 pd-l-0 pd-lg-r-15 ">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-0 pd-lg-r-15 mg-b-15 ">
                              <label className="form-label text-left pd-b-5">
                                Payment Instructions:
                              </label>

                              <JoditEditor
                                ref={null}
                                config={this.editorConfig}
                                name="parking_payment_instructions"
                                id="parking_payment_instructions"
                                value={this.state.parking_payment_instructions}
                                onBlur={(newContent) =>
                                  this.setContentParkingPayment(newContent)
                                }
                                onChange={(newContent) =>
                                  this.setContentParkingPayment(newContent)
                                }
                              />

                              {this.state.errors
                                .parking_payment_instructions ? (
                                <div className="text-danger">
                                  {
                                    this.state.errors
                                      .parking_payment_instructions
                                  }
                                </div>
                              ) : (
                                this.validator.message(
                                  "parking_payment_instructions",
                                  this.state.parking_payment_instructions,
                                  "max:100000",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default Parking;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: CRMResidentFrom.jsx                                //
//  Application:  Staff                                   //
//  Option: For add or update Staff                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-13                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import CityService from "../../services/CityService";
import residentImg from "./../../assets/images/profile-nogender.png";
import Alerts from "../common/Alerts";
import Select from "react-select";
import ContactSidebar from "../common/ContactSidebar";
import ResidentService from "../../services/ResidentService";
import PhoneInput from 'react-phone-input-2'

export class CRMResidentFrom extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      countryListTotal: 0,
      countyListTotal: 0,
      stateListTotal: 0,
      cityListTotal: 0,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,

      imageUrl: residentImg,
      pathname: window.location.pathname,

      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
    };
    this.residentImg = React.createRef();
    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);

    this.saveOrUpdateResident = this.saveOrUpdateResident.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });

    this.getCityData();

    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await ResidentService.getResident(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData.first_name = res.data ? res.data.first_name : "";
        resData.last_name = res.data ? res.data.last_name : "";
        resData.email = res.data ? res.data.email : "";
        resData.phone_number = res.data ? res.data.phone_number : "";
        resData.resident_photo = res.data ? res.data.profile_thumb : "";
        resData.imageUrl = res.data ? res.data.resident_photo_thumb_url : "";
        resData.street_1 = res.data ? res.data.street_1 : "";
        resData.street_2 = res.data ? res.data.street_2 : "";
        resData.zipcode = res.data.zipcode ? res.data.zipcode : "";

        resData.country_slug = res.data.country ? res.data.country.slug : "";
        resData.county_slug = res.data.county ? res.data.county.slug : "";
        resData.state_slug = res.data.state ? res.data.state.slug : "";
        resData.city_slug = res.data.city ? res.data.city.slug : "";

        resData.country_name = res.data.country
          ? res.data.country.country_name
          : "";
        resData.county_name = res.data.county
          ? res.data.county.county_name
          : "";
        resData.state_name = res.data.state ? res.data.state.state_name : "";
        resData.city_name = res.data.city ? res.data.city.city_name : "";

        var selectedCityList = [];
        selectedCityList = res.data.city
          ? [
              {
                value: res.data.city.slug,
                label: res.data.city.city_name,
              },
            ]
          : "";
        var selectedStateList = [];
        selectedStateList = res.data.state
          ? [
              {
                value: res.data.state.slug,
                label: res.data.state.state_name,
              },
            ]
          : "";
        var selectedCountyList = [];
        selectedCountyList = res.data.county
          ? [
              {
                value: res.data.county.slug,
                label: res.data.county.county_name,
              },
            ]
          : "";
        var selectedCountryList = [];
        selectedCountryList = res.data.country
          ? [
              {
                value: res.data.country.slug,
                label: res.data.country.country_name,
              },
            ]
          : "";

        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }

      this.setState(resData);
      this.setState({
        selectedCityList: selectedCityList,
        selectedStateList: selectedStateList,
        selectedCountyList: selectedCountyList,
        selectedCountryList: selectedCountryList,
      });
    }
  }

  /* to get country list data */
  async getCountryDataForActive(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];
    var countryListTotal = 0;

    let res = await CountryService.getCountry(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
      countryListTotal = countryList ? countryList.length : 0;
    }
    const newCountryList =
      countryListTotal > 0
        ? countryList.map(({ slug, country_name }) => ({
            value: slug,
            label: country_name,
          }))
        : "";
    this.setState({
      countryList: countryList,
      countryListTotal: countryListTotal,
      newCountryList: newCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get county list data */
  async getCountyData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countyList = [];
    var countyListTotal = 0;

    let res = await CountyService.getCounty(
      "is_dropdown=1&state_slug=" + value,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    const newCountyList =
      countyListTotal > 0
        ? countyList.map(({ slug, county_name }) => ({
            value: slug,
            label: county_name,
          }))
        : [];
    this.setState({
      countyList: countyList,
      countyListTotal: countyListTotal,
      newCountyList: newCountyList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get state list data */
  async getStatesData(countryValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var stateList = [];
    var stateListTotal = 0;

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" + countryValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
      stateListTotal = stateList ? stateList.length : 0;
    }
    const newStateList =
      stateListTotal > 0
        ? stateList.map(({ slug, state_name }) => ({
            value: slug,
            label: state_name,
          }))
        : "";
    this.setState({
      stateList: stateList,
      stateListTotal: stateListTotal,
      newStateList: newStateList,
    });
    this.setState({ showSpinner: false, loading: false });
  }
  onChangePhone(value, country, e, formattedValue){
    //console.log(value, country, e, formattedValue);
    this.setState({phone_number:value});
    this.setState({dial_code:country.dialCode});
}
  /* to get city list data */
  async getCityData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;

    let res = await CityService.getCity(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
            value: slug,
            label: city_name,
          }))
        : [];
    this.setState({
      cityList: cityList,
      cityListTotal: cityListTotal,
      newCityList: newCityList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "profile_thumb",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("resizeOptions","width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium");
      fileData.append("mediaType", "1");
      let res = await ResidentService.uploadResidentLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          resident_photo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  saveOrUpdateResident = async (e) => {
    e.preventDefault();

    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        remove_redis: this.state.slug !== undefined ? "yes" : "",
        property_slug: this.state.property_slug
          ? this.state.property_slug
          : this.props.property_slug,
        resident_photo: this.state.resident_photo
          ? this.state.resident_photo
          : "",
        first_name: this.state.first_name ? this.state.first_name : "",
        last_name: this.state.last_name ? this.state.last_name : "",
        email: this.state.email ? this.state.email : "",
        phone_number: this.state.phone_number ? this.state.phone_number : "",
        dial_code : this.state.dial_code ? this.state.dial_code : "",
        street_1: this.state.street_1,
        street_2: this.state.street_2,
        city_slug: this.state.city_slug,
        zipcode: this.state.zipcode,
        country_slug: this.state.country_slug,
        county_slug: this.state.county_slug,
        state_slug: this.state.state_slug,

        sort_order: this.state.sort_order ? this.state.sort_order : "",
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await ResidentService.updateResident(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/crm-resident/first/" +
                  this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await ResidentService.createResident(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/crm-resident"
              ),
            global.redirect_time
          );
        } else {
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  async getAllRealetedData(slug, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.country_slug = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country
        ? [
            {
              value: res.data.countryData.country
                ? res.data.countryData.country.slug
                : "",
              label: res.data.countryData.country
                ? res.data.countryData.country.country_name
                : "",
            },
          ]
        : "";
      resData.county_slug = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
            {
              value: res.data.county.slug ? res.data.county.slug : "",
              label: res.data.county.county_name
                ? res.data.county.county_name
                : "",
            },
          ]
        : "";
      resData.state_slug = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
            {
              value: res.data.state.slug ? res.data.state.slug : "",
              label: res.data.state.state_name ? res.data.state.state_name : "",
            },
          ]
        : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });

    this.validator.showMessageFor(event.target.name);

    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  handleChangeCountryList(value) {
    let country_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_slug.push(item.value);
      });
    }
    const country_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCountryList: value,
      country_slug: country_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCityList(value) {
    let city_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_slug.push(item.value);
      });
    }
    if (value !== null) {
      this.getAllRealetedData(value.value);
    }
    const city_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedCityList: value,
      city_slug: city_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Resident";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  removePhoto(){
    this.setState({resident_photo:"",imageUrl:""});
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        <Container className="innter-container pr-4">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <ContactSidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-30 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                  this.state.property_slug +
                                  "/crm-resident"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                                this.state.property_slug +
                                "/crm-resident"
                            );
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={this.saveOrUpdateResident}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                          ? "Save"
                          : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pl-0 pr-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="staff_form">
                      <div className="m-0">
                        <div className="row align-items-center pd-t-30 pd-b-15 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              First Name
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="first_name"
                              value={this.state.first_name || ""}
                              maxLength="50"
                            />
                            {this.state.errors.first_name ? (
                              <div className="text-danger">
                                {this.state.errors.first_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "first_name",
                                this.state.first_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Last Name
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="last_name"
                              value={this.state.last_name || ""}
                              maxLength="50"
                            />
                            {this.state.errors.last_name ? (
                              <div className="text-danger">
                                {this.state.errors.last_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "last_name",
                                this.state.last_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Phone{" "}
                            </label>
                            <PhoneInput
                              country={global.country}
                              disableSearchIcon
                              // autoFormat= "true"
                              enableSearch= "true"
                              countryCodeEditable= "true"
                              value={this.state.phone_number}
                              onChange={this.onChangePhone}

                            />
                            {this.state.errors.phone_number ? (
                              <div className="text-danger">
                                {this.state.errors.phone_number}
                              </div>
                            ) : (
                              this.validator.message(
                                "phone",
                                this.state.phone_number,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Email
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="email"
                              value={this.state.email || ""}
                              maxLength="50"
                            />
                            {this.state.errors.email ? (
                              <div className="text-danger">
                                {this.state.errors.email}
                              </div>
                            ) : (
                              this.validator.message(
                                "email",
                                this.state.email,
                                "required|email",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Street 1
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              type="text"
                              name="street_1"
                              value={this.state.street_1}
                            />
                            {this.state.errors.street_1 ? (
                              <div className="text-danger">
                                {this.state.errors.street_1}
                              </div>
                            ) : (
                              this.validator.message(
                                "street_1",
                                this.state.street_1,
                                "required|max:100",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left  pd-b-5">
                              Street 2{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="street_2"
                              value={this.state.street_2}
                            />
                            {this.state.errors.street_2 ? (
                              <div className="text-danger">
                                {this.state.errors.street_2}
                              </div>
                            ) : (
                              this.validator.message(
                                "street_2",
                                this.state.street_2,
                                "max:100",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              City
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Select
                              styles={customStyles}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedCityList}
                              options={this.state.newCityList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeCityList(value)
                              }
                              defaultValue={this.state.selectedCityList}
                              autoComplete="off"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                            {this.state.errors.city_slug ? (
                              <div className="text-danger">
                                {this.state.errors.city_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "city",
                                this.state.city_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              State
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Form.Control
                              type="text"
                              className="custom-disable"
                              value={this.state.state_name}
                              // placeholder="State"
                              disabled={true}
                            />
                            {this.state.errors.state_slug ? (
                              <div className="text-danger">
                                {this.state.errors.state_slug}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Zip Code
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              type="text"
                              name="zipcode"
                              value={this.state.zipcode}
                              maxLength="5"
                              onKeyPress={
                                global.onKeyPressEvent.numberValidation
                              }
                            />
                            {this.state.errors.zipcode ? (
                              <div className="text-danger">
                                {this.state.errors.zipcode}
                              </div>
                            ) : (
                              this.validator.message(
                                "zipcode",
                                this.state.zipcode,
                                "required|max:5",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              County
                              <span className="optional">(Optional)</span>
                            </label>
                            <Select
                              styles={customStyles}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedCountyList}
                              options={this.state.newCountyList.filter(
                                (option) => {
                                  const val1 = this.state.county_slug
                                    ? this.state.county_slug
                                    : "";
                                  if (val1 === option.slug) return true;
                                }
                              )}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeCountyList(value)
                              }
                              defaultValue={this.state.selectedCountyList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />

                            {this.state.errors.county_slug ? (
                              <div className="text-danger">
                                {this.state.errors.county_slug}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Country
                            </label>
                            <Form.Control
                              type="text"
                              className="custom-disable"
                              value={this.state.country_name}
                              // placeholder="Country"
                              disabled={true}
                            />
                            {this.state.errors.country_slug ? (
                              <div className="text-danger">
                                {this.state.errors.country_slug}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Photo <span className="optional">(Optional)</span>
                            </label>

                            <div className="fileUpload d-flex flex-wrap justify-content-between">
                              <span>
                                <label className="hand-cursor">
                                  <input
                                    onChange={this.onImageChange}
                                    type="file"
                                    name="resident_photo"
                                  />
                                  {this.state.imageUrl ? (
                                    <img src={this.state.imageUrl} alt="" />
                                  ) : (
                                    <img src={residentImg} alt="" />
                                  )}
                                </label>
                              </span>
                              <p className="hand-cursor remove-pic remove-pic-three m-0" onClick={this.removePhoto}></p>
                            </div>
                            {/* <div className="row align-items-center pd-l-0 pd-t-20 border-bottom-0">
                              <div className="col-md-3">
                                <p className="hand-cursor text-danger" onClick={this.removePhoto}>Remove</p>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="row align-items-center pd-t-30 pd-b-30 pd-l-30 pd-r-30 border-bottom-0">
                          <div className="col-md-1 pl-0">
                            <label className="form-label text-left pd-b-5">
                              Status
                            </label>
                          </div>
                          <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() =>
                                this.setState({
                                  status_id: !this.state.status_id,
                                })
                              }
                            >
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default CRMResidentFrom;

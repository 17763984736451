////////////////////////////////////////////////////////////
//  Program: AllPropertyReservationSidebar.jsx            //
//  Application: Property                                 //
//  Option: common component Calendar Sidebar             //
//  Developer: NP 		                                    //
//  Date: 2022-04-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from 'react-bootstrap';
class AllPropertyReservationSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.remove("d-none");
        document.querySelector(".newSubmenu").classList.remove("d-none");
      } else {
        data.classList.replace("left", "hide");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.add("d-none");
        document.querySelector(".newSubmenu").classList.add("d-none");
      }
    }
  }

  toggleChildMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector(".newSubmenu").classList.toggle("d-none");
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
      } else {
        data.classList.replace("left", "hide");
      }
    }
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Reservations
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "reservation-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reservation-calendar")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/global-reservation-calendar"}
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "reservations-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reservations")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/reservations"}
                        >
                          Reservations
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("inquiry-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("inquiry")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/inquiry"}
                        >
                          Inquiry
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "check-ins-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checkin-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/global-checkin-calender"}
                        >
                          Check Ins
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "check-outs-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checkout-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/global-checkout-calender"}
                        >
                          Check Outs
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission("guests-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/guest"}
                        >
                          Guests
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("reservations-guest-reviews") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reviews")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/reviews"}
                        >
                          Guest Reviews
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              {/* <a href="/" className="back"> <i className="fas fa-chevron-left"></i> Reservations</a> */}
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Reservations
              </span>
              <ul>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission(
                      "reservation-calendar-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reservation-calendar")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/reservation-calendar"}
                        >
                          Calendar
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "reservations-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reservations")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/reservations"}
                        >
                          Reservations
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("inquiry-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("inquiry")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/inquiry"}
                        >
                          Inquiry
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "check-ins-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checkin-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/checkin-calender"}
                        >
                          Check Ins
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "check-outs-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("checkout-calender")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/checkout-calender"}
                        >
                          Check Outs
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li>
                  <ul>
                    {global.userPermissions.checkPermission("guests-list") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("guest")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/guest"}
                        >
                          Guests
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission("reservations-guest-reviews") && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("reviews")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/reviews"}
                        >
                          Guest Reviews
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AllPropertyReservationSidebar;

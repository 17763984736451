////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Ambassadors.jsx                               //
//  Application: Ambassadors                               //
//  Option: List all Ambassadors                           //
//  Developer: Sunny Raj 						                       //
//  Date: 2023-07-12  
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, InputGroup, Col } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import AmbassadorService from "../../services/AmbassadorService";
import Photo from "./../../assets/images/profile-nogender.png";
import PropertySidebar from "../common/PropertySidebar";
import pluswIcon from "./../../assets/images/plus.svg";
import deleteicon from "../../assets/images/delete.svg";
import Select from "react-select";

export class AmbassadorsForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug !== undefined
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      name: "",
      title : "",
      phone: "",
      about: "",
      email: "",
      sort_order: "",
      pathname: window.location.pathname,
      photo: [],
      socialMediaList: [],
      newSocialMediaList: [],
      socialMediaListTotal: 0,
      selectedsocialMediaList: [],
      photoUrl: Photo,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
      ambassadors_data: [],
      ambassadorsDataTotal: 1,
      social_media_slug: "",
      ambassadorsData: [
        {
          social_media_slug: "",
          ambassador_data_slug: "",
          link: "",
        },
      ],
    };
    this.photoUrl = React.createRef();
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangeselect = this.handleChangeselect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddFields = this.handleAddFields.bind(this);
    this.handleRemoveFields = this.handleRemoveFields.bind(this);
    this.multiDelete = this.multiDelete.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateambassadors = this.saveOrUpdateambassadors.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
  }

  /* To get SocialMedia list data */
  async getSocialMediaData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var socialMediaList = [];
    var socialMediaListTotal = 0;

    let res = await AmbassadorService.getSocialMedia(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      socialMediaList = res.data ? res.data : [];
      socialMediaListTotal = socialMediaList ? socialMediaList.length : 0;
    }
    const newSocialMediaList =
      socialMediaListTotal > 0
        ? socialMediaList.map(({ slug, social_media_name }) => ({
          value: slug,
          label: social_media_name,
        }))
        : [];
    this.setState({
      socialMediaList: socialMediaList,
      socialMediaListTotal: socialMediaListTotal,
      newSocialMediaList: newSocialMediaList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getSocialMediaData();

    if (this.state.slug !== undefined) {
      var resData = {};
      var ambassadorsDataTotal = 1;
      let res = await AmbassadorService.getAmbassadorBySlug(this.state.slug);

      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.name = res.data.name ? res.data.name : "";
        resData.bio = res.data.bio ? res.data.bio : "";
        resData.photo = res.data.photo ? res.data.photo : "";
        resData.photoUrl = res.data.photo_thumb_url ? res.data.photo_thumb_url : Photo;
        resData.socialMedia = res.data.socialMedia
          ? res.data.socialMedia
          : [
            {
              social_media_slug: "",
              link: "",
              tag_line: "",
              type: "",
              ambassador_data_slug: "",
              account_name: "",
            },
          ];
        const values = [];
        if (resData.socialMedia.length > 0) {
          resData.socialMedia.forEach((item, i) => {
            values.push({
              ambassador_data_slug: item.slug,
              link: item.link,
              tag_line: item.tag_line,
              type: item.type,
              account_name: item.account_name,
              social_media_slug: item.social_media.slug,
              selected_social_media: {
                value: item.social_media.slug,
                label: item.social_media.social_media_name,
              },
            });
          });
        } else {
          resData.ambassadorsData = [
            {
              social_media_slug: "",
              link: "",
              tag_line: "",
              type: "",
              ambassador_data_slug: "",
              account_name: "",
            },
          ];
        }
        ambassadorsDataTotal = values.length;
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        this.setState({
          ambassadors_data: values,
          ambassadorsData: values,
          ambassadorsDataTotal: ambassadorsDataTotal,
        });
      }

      this.setState(resData);
    }
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "photo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("h1", 235);
      fileData.append("h2", 250);
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      );
      fileData.append("mediaType", "1");
      let res = await AmbassadorService.uploadAmbassadorImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          photo: res.data.fileName,
          photoUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };
  onChangePhone(value, country, e, formattedValue) {
    if (value) {
      this.setState({ phone: value, formattedValue: formattedValue });
    } else {
      this.setState({ phone: value, formattedValue: "" });
    }
    this.setState({ dial_code: country.dialCode });
  }
  saveOrUpdateambassadors = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        name: this.state.name,
        title: this.state.title,
        property_slug: this.state.property_slug,
        email: this.state.email,
        phone: this.state.phone,
        dial_code: this.state.dial_code,
        photo: this.state.photo,
        about: this.state.about,
        ambassadors_data: this.state.ambassadors_data,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
        formattedValue: this.state.formattedValue,
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await AmbassadorService.updateAmbassadors(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                  this.state.property_slug +
                  "/ambassadors"
                  : "/ambassadors"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      } else {
        let res = await AmbassadorService.createAmbassadors(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                  this.state.property_slug +
                  "/ambassadors"
                  : "/ambassadors"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);

    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.bio !== undefined &&
      input.bio !== "" &&
      input.bio !== null &&
      input.bio.length > 100000
    ) {
      isValid = false;
      errors["bio"] = "The event bio character limit has been exceed.";
    }

    this.setState({ errors: errors });
    return isValid;
  }

  handleChangeselect = (index, event) => {
    const values = [...this.state.ambassadorsData];
    values[index].social_media_slug = event.value;
    values[index].selected_social_media = event;

    // values[index].event.target.name = event.target.value;
    this.setState({ ambassadorsData: values });
    this.setState({ ambassadors_data: values });

    this.setState({ confirm_back: 1 });
  };

  handleChange = (index, event) => {
    const values = [...this.state.ambassadorsData];

    if (event.target.name === "social_media_slug") {
      values[index].social_media_slug = event.target.value;
      this.setState({ ambassadorsData: values });
    } else if (event.target.name === "link") {
      values[index].link = event.target.value;
    } else if (event.target.name === "tag_line") {
      values[index].tag_line = event.target.value;
    } else if (event.target.name === "type") {
      values[index].type = event.target.value;
    } else if (event.target.name === "account_name") {
      values[index].account_name = event.target.value;
    }
    this.setState({ ambassadors_data: values, ambassadorsData: values });
    this.setState({ confirm_back: 1 });
  };

  handleAddFields = () => {
    const values = [...this.state.ambassadorsData];
    var ambassadorsDataTotal = 1;
    values.push({
      social_media_slug: "",
      ambassador_data_slug: "",
      link: "",
      tag_line: "",
      type: "",
      account_name: "",
    });
    ambassadorsDataTotal = values.length;

    this.setState({
      ambassadorsData: values,
      ambassadorsDataTotal: ambassadorsDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFields = (i) => {
    const values = [...this.state.ambassadorsData];
    values.splice(i, 1);
    var ambassadorsDataTotal = 0;
    ambassadorsDataTotal = values.length;
    // this.setState({ ambassadorsData: values, ambassadors_data: values, ambassadorsDataTotal: ambassadorsDataTotal, confirm_back: 1 });
    this.setState({ ambassadorsData: values, ambassadors_data: values });
    this.setState({
      ambassadorsDataTotal: ambassadorsDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  async multiDelete(slug) {
    this.setState({ showSpinner: true });
    if (slug) {
      let res = await AmbassadorService.multiDelete(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
          newSocialMediaList: [],
        });
      } else {
        let alertMessage = "";
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Ambassadors";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  removePhoto() {
    this.setState({ photo: "", photoUrl: "" });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {this.props.match.params.propertySlug !== undefined ?
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
                </div> : ""}
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                this.state.property_slug +
                                "/ambassadors"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              this.props.match.params.propertySlug !== undefined
                                ? "/properties/view/" +
                              this.state.property_slug +
                              "/ambassadors"
                                : "/ambassadors"
                            );
                        }}
                      >
                        Cancel
                      </button>


                      {this.state.slug !== undefined &&
                        global.userPermissions.checkPermission(
                          "ambassadors-update"
                        ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveOrUpdateambassadors}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : global.userPermissions.checkPermission(
                        "ambassadors-add"
                      ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveOrUpdateambassadors}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : (
                        ""
                      )}



                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="event_form">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0">
                        <div className="row align-items-center pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Name
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="name"
                              value={this.state.name}
                              className="form-control max_width_100"
                              type="text"
                            />
                            {this.state.errors.name ? (
                              <div className="text-danger">
                                {this.state.errors.name}
                              </div>
                            ) : (
                              this.validator.message(
                                "name",
                                this.state.name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Phone <span className="optional">(Optional)</span>
                            </label>
                            <PhoneInput
                              country={global.country}
                              disableSearchIcon
                              enableSearch="true"
                              countryCodeEditable="true"
                              value={this.state.phone || ""}
                              onChange={this.onChangePhone}
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Email
                            </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              name="email"
                              value={this.state.email || ""}
                              className="form-control overflow-hidden"
                              type="text"
                            />
                            {this.state.errors.email ? (
                              <div className="text-danger">
                                {this.state.errors.email}
                              </div>
                            ) : (
                              this.validator.message(
                                "email",
                                this.state.email,
                                "required|email",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Title
                            </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              name="title"
                              value={this.state.title || ""}
                              className="form-control overflow-hidden"
                              type="text"
                            />
                            {this.state.errors.title ? (
                              <div className="text-danger">
                                {this.state.errors.title}
                              </div>
                            ) : (
                              this.validator.message(
                                "title",
                                this.state.title,
                                "required|title",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              About
                            </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              name="about"
                              value={this.state.about || ""}
                              className="form-control overflow-hidden"
                              rows="5"
                              as="textarea"
                              maxLength="5000"
                              autoComplete="off"
                            />
                            {this.state.errors.about ? (
                              <div className="text-danger">
                                {this.state.errors.about}
                              </div>
                            ) : (
                              this.validator.message(
                                "about",
                                this.state.about,
                                "required|max:100000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                        {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}

                        {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}
                        <div className="row align-items-center pd-lg-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-40 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Photo <span className="optional">(Optional)</span>
                            </label>

                            <div className="fileUpload d-flex flex-wrap justify-content-between">
                              <span>
                                <label className="hand-cursor">
                                  <input
                                    onChange={this.onImageChange}
                                    type="file"
                                    name="photo"
                                    tabIndex="2"
                                  />
                                  {this.state.photoUrl ? (
                                    <img
                                      src={this.state.photoUrl}
                                      alt="Photo"
                                    />
                                  ) : (
                                    <img src={Photo} alt="" />
                                  )}
                                </label>
                              </span>
                              <p className="hand-cursor remove-pic remove-pic-three m-0" onClick={this.removePhoto}></p>
                            </div>
                            {this.state.errors.photo ? (
                              <div className="text-danger">
                                {this.state.errors.photo}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* <div className="row align-items-center pd-lg-b-0 pd-xs-b-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0 pd-t-0">
                          <div className="col-md-3">
                            <p className="hand-cursor text-danger" onClick={this.removePhoto}>Remove</p>
                          </div>
                        </div> */}
                        <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30 mg-t-30">
                          <h3 className="mg-t-0 mg-l-0 background-head">
                            Social Media
                          </h3>
                        </div>
                        <div className="pd-lg-l-30 pd-lg-r-30 pet-f  mg-lg-b-30 mg-xs-b-15 mg-lg-l-0 mg-xs-l-15 pet">
                          <div className="col-md-12 p-0 petFeeSection">
                            <table className="petForm">
                              <thead>
                                <tr>
                                  <th className="w-1">
                                    <label className="mb-1">Platform</label>
                                  </th>
                                  <th className="w-2">
                                    <label className="mb-1">Link</label>
                                  </th>
                                  <th className="w-2">
                                    <label className="mb-1">Tagline</label>
                                  </th>
                                  <th className="w-2">
                                    <label className="mb-1">Type</label>
                                  </th>
                                  <th className="w-2">
                                    <label className="mb-1">Account Name</label>
                                  </th>
                                  <th className="w-3"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.ambassadorsData.map((data, i) => {
                                  return (
                                    <tr key={i}>
                                      <td className="w-1">
                                        <Col
                                          xs={12}
                                          className="pd-l-0 pd-r-2 colmn1"
                                        >
                                          <InputGroup className="mt-0 m-b-1">
                                            <div className="col-md-12 p-0">
                                              <Select
                                                styles={customStyles}
                                                className="multiselect"
                                                classNamePrefix={"my-custom-react-select"}
                                                menuPlacement="auto"
                                                autoComplete="off"
                                                onChange={(value) =>
                                                  this.handleChangeselect(
                                                    i,
                                                    value
                                                  )
                                                }
                                                options={this.state.newSocialMediaList.filter(
                                                  (option) => {
                                                    const val2 = this.state
                                                      .ambassadors_data
                                                      ? this.state.ambassadors_data.find(
                                                        (selOpt) => {
                                                          if (
                                                            selOpt.social_media_slug ===
                                                            data.value
                                                          )
                                                            return false;
                                                          return (
                                                            selOpt.social_media_slug ===
                                                            option.value
                                                          );
                                                        }
                                                      )
                                                      : "";
                                                    if (val2) return false;
                                                    else return true;
                                                  }
                                                )}
                                                getOptionValue={(option) =>
                                                  `${option.label}`
                                                }
                                                theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: 2,
                                                  colors: {
                                                    ...theme.colors,
                                                    primary: "#fff",
                                                    primary75: "#000",
                                                    primary50: "#000",
                                                    primary25: "#000",
                                                  },
                                                })}
                                                value={
                                                  data.selected_social_media
                                                    ? data.selected_social_media
                                                    : []
                                                }
                                                defaultValue={
                                                  data.selected_social_media
                                                    ? data.selected_social_media
                                                    : []
                                                }
                                              />
                                            </div>
                                          </InputGroup>
                                        </Col>
                                      </td>
                                      <td className="w-1">
                                        <Col
                                          xs={12}
                                          className="pd-l-2 pd-r-2 colmn1"
                                        >
                                          <InputGroup className="mt-0 m-b-1">
                                            <Form.Control
                                              name="link"
                                              value={
                                                data.link ? data.link || "" : ""
                                              }
                                              autoComplete="off"
                                              onChange={(event) =>
                                                this.handleChange(i, event)
                                              }
                                            />
                                          </InputGroup>
                                        </Col>
                                      </td>
                                      <td className="w-1">
                                        <Col
                                          xs={12}
                                          className="pd-l-2 pd-r-2 colmn1"
                                        >
                                          <InputGroup className="mt-0 m-b-1">
                                            <Form.Control
                                              name="tag_line"
                                              value={
                                                data.tag_line
                                                  ? data.tag_line || ""
                                                  : ""
                                              }
                                              autoComplete="off"
                                              onChange={(event) =>
                                                this.handleChange(i, event)
                                              }
                                            />
                                          </InputGroup>
                                        </Col>
                                      </td>
                                      <td className="w-1">
                                        <Col
                                          xs={12}
                                          className="pd-l-2 pd-r-2 colmn1"
                                        >
                                          <InputGroup className="mt-0 m-b-1">
                                            <Form.Control
                                              name="type"
                                              value={
                                                data.type ? data.type || "" : ""
                                              }
                                              autoComplete="off"
                                              onChange={(event) =>
                                                this.handleChange(i, event)
                                              }
                                            />
                                          </InputGroup>
                                        </Col>
                                      </td>
                                      <td className="w-1">
                                        <Col
                                          xs={12}
                                          className="pd-l-2 pd-r-2 colmn1"
                                        >
                                          <InputGroup className="mt-0 m-b-1">
                                            <Form.Control
                                              name="account_name"
                                              value={
                                                data.account_name
                                                  ? data.account_name || ""
                                                  : ""
                                              }
                                              autoComplete="off"
                                              onChange={(event) =>
                                                this.handleChange(i, event)
                                              }
                                            />
                                          </InputGroup>
                                        </Col>
                                      </td>

                                      <td>
                                        <Col
                                          xs={12}
                                          className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                                        >
                                          {this.state.ambassadorsDataTotal ===
                                            1 && i === 0 ? (
                                            <>
                                              {data.ambassador_data_slug ? (
                                                <div className="d-flex  justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      this.handleRemoveFields(
                                                        i
                                                      );
                                                      this.multiDelete(
                                                        data.ambassador_data_slug
                                                      );
                                                    }}
                                                    className={
                                                      "btn-danger-outline-x-small"
                                                    }
                                                  >
                                                    <img
                                                      src={deleteicon}
                                                      alt="Delete"
                                                    />
                                                  </button>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                <button
                                                  type="button"
                                                  onClick={this.handleAddFields}
                                                  className="btn-success-outline-x-small"
                                                >
                                                  <img
                                                    src={pluswIcon}
                                                    alt="Add"
                                                  />
                                                </button>
                                              </div>
                                            </>
                                          ) : i <
                                            this.state.ambassadorsDataTotal -
                                            1 ? (
                                            <>
                                              <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    this.handleRemoveFields(i);
                                                    this.multiDelete(
                                                      data.ambassador_data_slug
                                                    );
                                                  }}
                                                  className={
                                                    "btn-danger-outline-x-small"
                                                  }
                                                >
                                                  <img
                                                    src={deleteicon}
                                                    alt="Delete"
                                                  />
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              {this.state
                                                .ambassadorsDataTotal !== 0 ? (
                                                <>
                                                  <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        this.handleRemoveFields(
                                                          i
                                                        );
                                                        this.multiDelete(
                                                          data.ambassador_data_slug
                                                        );
                                                      }}
                                                      className={
                                                        "btn-danger-outline-x-small"
                                                      }
                                                    >
                                                      <img
                                                        src={deleteicon}
                                                        alt="Delete"
                                                      />
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {i < 3 ? (
                                                <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                  <button
                                                    type="button"
                                                    onClick={
                                                      this.handleAddFields
                                                    }
                                                    className="btn-success-outline-x-small"
                                                  >
                                                    <img
                                                      src={pluswIcon}
                                                      alt="Add"
                                                    />
                                                  </button>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )}
                                        </Col>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="row align-items-center pd-b-30 pd-l-30 pd-r-30 border-bottom-0">
                          <div className="col-md-1 pl-0">
                            <label className="form-label text-left pd-b-5">
                              Status
                            </label>
                          </div>

                          <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                            <b
                              className={
                                this.state.status_id ? "active" : "inactive"
                              }
                            ></b>
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() =>
                                this.setState({
                                  status_id: !this.state.status_id,
                                })
                              }
                            >
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default AmbassadorsForm;

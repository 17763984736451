////////////////////////////////////////////////////////////
//     							                          //
//  Program: FloorPlanView.jsx                         //
//  Application: FloorPlan                             //
//  Option: For view floor plan details       //
//  Developer: Ashish Kumar 				              //
//  Date: 2022-02-09                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Tab, Nav, Modal } from "react-bootstrap";
import FloorPlanService from "./../../services/FloorPlanService";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Alerts from "../common/Alerts";
// import renderHTML from "react-render-html";
import floorPlanImage from "./../../assets/images/property_placeholder.png";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import MultiImageService from "./../../services/MultiImageService";
import ListingService from "../../services/ListingService";
import FloorPlanImages from "./FloorPlanImages";
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import PropertySidebar from "../common/PropertySidebar";
import FloorPlanCost from "./FloorPlanCost";
import FloorPlanAmenities from "./FloorPlanAmenities";
import FloorPlanAccessibility from "./FloorPlanAccessibility";
// import ShowMoreText from "react-show-more-text";

export class FloorPlanView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleSwitchDark2: true,
      addModal: false,
      editModal: false,
      deleteModal: false,
      property_name: "",
      status_id: "",
      open: false,
      publishData: false,
      eventKey: "3",
      floor_plan_slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      imageUrl: floorPlanImage,
      multiImageList: [],
      multiImageListTotal: 0,
      descShow: 0,
      activeClass: "basic",
      property_floor_plan_name: "",
    };
    this.floorPlanImage = React.createRef();
    this.changeHandler = this.changeHandler.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.fullText = this.fullText.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.getImgStatus = this.getImgStatus.bind(this);
    this.publishAllListing = this.publishAllListing.bind(this);
    this.deleteFloorPlan = this.deleteFloorPlan.bind(this);
    this.opendeleteFloorPlanModal = this.opendeleteFloorPlanModal.bind(this);
    this.closedeleteFloorPlanModal = this.closedeleteFloorPlanModal.bind(this);
  }

  opendeleteFloorPlanModal(slug) {
    this.setState({ deleteFloorPlanModal: true, slug: slug });
  }

  closedeleteFloorPlanModal() {
    this.setState({ deleteFloorPlanModal: false, slug: "" });
  }

  async deleteFloorPlan(slug) {
    this.setState({ showSpinner: true });
    let res = await FloorPlanService.deleteFloorPlan(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteFloorPlanModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/floor-plans"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteFloorPlanModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async getMulitImagesData() {
    if (this.state.floor_plan_slug !== undefined) {
      let inputData = {
        path: "floorplan",
        refTableId: 4,
        slug: this.state.floor_plan_slug,
        mediaType: 1,
      };
      let res = await MultiImageService.getImages(inputData);
      var multiImageList = [];
      var multiImageListTotal = 0;
      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotal = multiImageList.length;
      }
      this.setState({
        multiImageList: multiImageList,
        multiImageListTotal: multiImageListTotal,
      });
    }
  }

  async componentDidMount() {
    this.setState({ publishData: true });
    this.setState({ showSpinner: true });
    if (this.state.floor_plan_slug !== undefined) {
      var resData = {};
      var multiList = [];
      var multiListTotal = 0;
      let res = await FloorPlanService.getFloorPlanBySlug(
        this.state.floor_plan_slug
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.property_floor_plan_name = res.data
          ? res.data.property_floor_plan_name
          : "";
        resData.bedroom = res.data ? res.data.bedroom : "";
        resData.leasing_window_day = res.data
          ? res.data.leasing_window_day
          : "";
        resData.bath = res.data ? res.data.bath : "";
        resData.rent = res.data ? res.data.rent : "";
        resData.square_foot = res.data
          ? res.data.square_foot
            ? res.data.square_foot.replace(/\.00$/, "")
            : ""
          : "";
        resData.balcony = res.data
          ? res.data.balcony
            ? res.data.balcony.replace(/\.00$/, "")
            : ""
          : "";
        resData.interior = res.data
          ? res.data.interior
            ? res.data.interior.replace(/\.00$/, "")
            : ""
          : "";
        resData.main_image = res.data.main_image
          ? res.data.main_image
          : floorPlanImage;
        resData.imageUrl = res.data.main_image_url
          ? res.data.main_image_url
          : floorPlanImage;
        resData.deposit = res.data ? res.data.deposit : "";
        resData.flooring_name = res.data.flooring
          ? res.data.flooring.flooring_name
          : "";
        resData.heating_name = res.data.heating
          ? res.data.heating.heating_name
          : "";
        resData.automatic_rent_update =
          res.data.automatic_rent_update === 1 ? "Yes" : "No";
        resData.marketing_description = res.data
          ? res.data.marketing_description
          : "";
        resData.image_description = res.data ? res.data.image_description : "";
        resData.sort_order = res.data ? res.data.sort_order : "";
        resData.status_id = res.data ? res.data.status_id : "";
        multiList = res.data.taxesData ? res.data.taxesData : [];
        multiListTotal = multiList ? multiList.length : 0;
        resData.adr_value = res.data ? res.data.adr_value : "";
        resData.video_url = res.data ? res.data.video_url : "";
      }
      this.setState(resData);
      this.setState({
        multiList: multiList,
        multiListTotal: multiListTotal,
      });
    }
    // this.getMulitImagesData();
    this.setState({ showSpinner: false });
    this.setState({ publishData: false });
  }

  // =================[For Full sync (Madhav)]===================
  async publishAllListing() {
    this.setState({ publishData: true });
    let floor_plan_slug = {
      slug: this.state.floor_plan_slug ? this.state.floor_plan_slug : "",
      slug_name: "floorplan_level",
    };
    let res = await ListingService.publishAllListing(floor_plan_slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
      });
    }
    this.setState({ publishData: false });
    this.closeFullSyncModal();
  }

  openFullSyncModal() {
    this.setState({ xmlRoleModal: true });
  }

  closeFullSyncModal() {
    this.setState({ xmlRoleModal: false });
  }

  getTitle() {
    return (
      "Floor Plans" +
      (this.state.property_floor_plan_name
        ? " : " + this.state.property_floor_plan_name
        : "")
    );
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "floorPlanImages") {
      this.setState({ isRefresh: 2 });
    } else if (e === "floorPlanAmenities") {
      this.setState({ isRefresh: 3 });
    } else if (e === "floorPlanAccessibility") {
      this.setState({ isRefresh: 4 });
    } else if (e === "floorPlanCost") {
      this.setState({ isRefresh: 5 });
    } else {
      this.setState({ isRefresh: 0 });
    }
  }

  getImgStatus(imgFile) {
    this.setState({ imageUrl: imgFile });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  confirmImportModal() {
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 info_box_model">
                    <h3>{this.getTitle()}</h3>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="basic"
                    onSelect={(e) => this.getActiveClass(e)}
                    eventKey={this.state.activeClass}
                  >
                    <ScrollingCarousel
                      rightArrow={true}
                      leftArrow={true}
                      className="swipesection scroll_nav_mob"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="basic"
                          className={
                            this.state.activeClass === "basic" ? "active" : ""
                          }
                        >
                          Basic
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="floorPlanImages"
                          className={
                            this.state.activeClass === "floorPlanImages"
                              ? "active"
                              : ""
                          }
                        >
                          Images
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="floorPlanAmenities"
                          className={
                            this.state.activeClass === "floorPlanAmenities"
                              ? "active"
                              : ""
                          }
                        >
                          Amenities
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="floorPlanAccessibility"
                          className={
                            this.state.activeClass === "floorPlanAccessibility"
                              ? "active"
                              : ""
                          }
                        >
                          Accessibility Features
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="floorPlanCost"
                          className={
                            this.state.activeClass === "floorPlanCost"
                              ? "active"
                              : ""
                          }
                        >
                          Fees
                        </Nav.Link>
                      </Nav.Item>
                    </ScrollingCarousel>

                    <Tab.Content>
                      <Tab.Pane eventKey="basic">
                        <div className="dashboardRightcard dashbaord-pageHeadertitle-  background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                          <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns mr-3">
                            {this.state.floor_plan_slug !== undefined ? (
                              <>
                                {global.userPermissions.checkPermission(
                                  "single-unit-update"
                                ) && (
                                    <button
                                      className="btn-success-outline-small float-right syncBtn mr-3"
                                      onClick={() => this.openFullSyncModal()}
                                    >
                                      {this.state.publishData
                                        ? global.loader
                                        : "Full Sync"}
                                    </button>
                                  )}
                                {global.userPermissions.checkPermission(
                                  "floor-plans-delete"
                                ) && (
                                    <span
                                      onClick={() => {
                                        this.opendeleteFloorPlanModal(
                                          this.state.slug
                                        );
                                      }}
                                      className="deleteicon mr-3 hand-cursor"
                                    >
                                      <img src={deleteicon} alt="Delete" />
                                    </span>
                                  )}
                              </>
                            ) : (
                              ""
                            )}
                            {global.userPermissions.checkPermission(
                              "floor-plans-update"
                            ) && (
                                <Link
                                  to={
                                    "/properties/view/" +
                                    this.state.property_slug +
                                    "/floor-plans/update/" +
                                    this.state.floor_plan_slug
                                  }
                                  className="mg-l-2"
                                >
                                  <button className="btn-success-outline-small">
                                    <img src={editIcon} alt="Edit" />
                                    Edit
                                  </button>
                                </Link>
                              )}
                          </div>
                          <div className="col-md-12 p-0">
                            {Array.isArray(this.state.alertModalMessage) ? (
                              <>
                                {this.state.alertModalMessage ? (
                                  this.state.alertModalMessage.map(
                                    (opt, item) => {
                                      return (
                                        <div
                                          className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15"
                                          key={item}
                                        >
                                          <Alerts
                                            show={this.state.showAlertModal}
                                            // show="true"
                                            type={this.state.alertModalType}
                                            title={this.state.alertModalTitle}
                                            message={opt}
                                          />
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                                    <Alerts
                                      show={this.state.showAlertModal}
                                      type={this.state.alertModalType}
                                      title={this.state.alertModalTitle}
                                      message={this.state.alertModalMessage}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>
                            )}
                          </div>
                          <div className="m-0 listing_detail">
                            <div className="row align-items-center pd-l-0 pd-r-15 pd-t-10 pd-b-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Name
                                </p>
                                <p className="media-body">
                                  {this.state.property_floor_plan_name
                                    ? this.state.property_floor_plan_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Bedrooms
                                </p>
                                <p className="media-body">
                                  {this.state.bedroom ? this.state.bedroom : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Bathrooms
                                </p>
                                <p className="media-body">
                                  {this.state.bath ? this.state.bath : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Flooring{" "}
                                </p>
                                <p className="media-body">
                                  {this.state.flooring_name
                                    ? this.state.flooring_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Heating{" "}
                                </p>
                                <p className="media-body">
                                  {this.state.heating_name
                                    ? this.state.heating_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Square Foot
                                </p>
                                <p className="media-body">
                                  {this.state.square_foot
                                    ? this.state.square_foot
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Taxes{" "}
                                </p>
                                <p className="media-body">
                                  {this.state.multiListTotal > 0
                                    ? this.state.multiList.map(
                                      (option, i) =>
                                        option.property_level_tax_name +
                                        (i < this.state.multiListTotal - 1
                                          ? ", "
                                          : "") +
                                        ""
                                    )
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Balcony (Sq. ft){" "}
                                </p>
                                <p className="media-body">
                                  {this.state.balcony ? this.state.balcony : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Interior (Sq. ft){" "}
                                </p>
                                <p className="media-body">
                                  {this.state.interior
                                    ? this.state.interior
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Leasing Turnover Days
                                </p>
                                <p className="media-body">
                                  {this.state.leasing_window_day
                                    ? this.state.leasing_window_day
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">ADR</p>
                                <p className="media-body">
                                  {this.state.adr_value
                                    ? this.state.adr_value
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  360 Video URL
                                </p>
                                <p className="media-body text-break">
                                  {this.state.video_url
                                    ? this.state.video_url
                                    : ""}
                                </p>
                              </div>
                            </div>
                            <div className="row align-items-center pd-l-0 pd-r-15 pd-t-10 pd-b-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Cleaning Fee
                                </p>
                                <p className="media-body">
                                  {this.state.cleaning_fee
                                    ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.cleaning_fee
                                    )
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  PMS Floor Plan Name
                                </p>
                                <p className="media-body">
                                  {this.state.pms_floor_plan_name
                                    ? this.state.pms_floor_plan_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Listing Template
                                </p>
                                <p className="media-body">
                                  {this.state.listing_details
                                    ? this.state.listing_details.listing_name
                                    : ""}
                                </p>
                              </div>
                            </div>

                            <div className="row align-items-center pd-l-0 pd-r-15 pd-t-10 pd-b-0 border-bottom-0">
                              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Floor Plan Description
                                </p>
                                <div className="media-body">
                                  {this.state.marketing_description ? (
                                    <p className="p_wrap1">
                                      {this.state.descShow
                                        ? this.state.marketing_description.slice(
                                          0,
                                          global.showMoreLength
                                        )
                                        : this.state.marketing_description}

                                      {this.state.marketing_description.length >
                                        global.showMoreLength && (
                                          <span onClick={this.fullText}>
                                            {this.state.descShow ? (
                                              <p className="hand-cursor showmore">
                                                ...Show more{" "}
                                              </p>
                                            ) : (
                                              <p className="hand-cursor showmore">
                                                Show Less{" "}
                                              </p>
                                            )}
                                          </span>
                                        )}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="floorPlanImages">
                        {this.state.isRefresh === 2 && (
                          <FloorPlanImages
                            floor_plan_slug={this.state.floor_plan_slug}
                            property_slug={this.state.property_slug}
                            floor_plan_name={
                              this.state.property_floor_plan_name
                            }
                            imgFile={this.getImgStatus}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="floorPlanAmenities">
                        {this.state.isRefresh === 3 && (
                          <FloorPlanAmenities
                            floor_plan_slug={this.state.floor_plan_slug}
                            property_slug={this.state.property_slug}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="floorPlanAccessibility">
                        {this.state.isRefresh === 4 && (
                          <FloorPlanAccessibility
                            floor_plan_slug={this.state.floor_plan_slug}
                            property_slug={this.state.property_slug}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="floorPlanCost">
                        {this.state.isRefresh === 5 && (
                          <FloorPlanCost
                            floor_plan_slug={this.state.floor_plan_slug}
                            property_slug={this.state.property_slug}
                          />
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteFloorPlanModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteFloorPlanModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteFloorPlanModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteFloorPlan(this.state.floor_plan_slug)
                  }
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* popup for full sync button */}
        <Modal show={this.state.xmlRoleModal} centered>
              <Modal.Body className="width_402 p-4 border_radius_14">
              <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeFullSyncModal()}
              >
                {global.closee}
              </span>
              <h3>Sync the Option?</h3>
              <p>
                Do you really want to Sync this? This process cannot be
                undone.
              </p>
              
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeFullSyncModal()}
                >
                  Cancel
                </button>
                
                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={this.publishAllListing}
                >
                {this.state.publishData ? global.loader : "Full Sync"}
                </button> 
                
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default FloorPlanView;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Keycafe.jsx                                    //
//  Application: Keycafe                                    //
//  Option: List all keycafe                                //
//  Developer:Ravi        					                      //
//                                                       //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import KeycafeService from "../../../services/KeycafeService";
import PropertyService from "../../../services/PropertyService";
import { Link } from "react-router-dom";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { Container, Tab, Nav, InputGroup, Modal, Form } from "react-bootstrap";
import pluswIcon from "./../../../assets/images/plus.svg";
import UnitsService from "../../../services/UnitsService";
import Select from "react-select";
import Alerts from "../../common/Alerts";
import NoData from "../../common/NoData";
import filterIcon from "./../../../assets/images/filter-gray.svg";
import Moment from "moment";

import queryString from "query-string";
import Keys from "./Keys";
import ActivityHistory from "./ActivityHistory";
import OutSidePropertySelectBox from "../../outside-modules/OutSidePropertySelectBox";

let queryParams = queryString.parse(window.location.search);

export class Activity extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      errors: {},
      importModal: false,
      file_url: "",
      file_name: "",
      open: true,
      eventKey: "1",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      module_search: "",
      alertType: "xlsx",
      exportMesssage: "",
      exportFilePath: "",
      exportResult: "",
      is_search: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      is_available: "",
      available_from: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      unitsFilter: [],
      filter_value: "",
      selectChannelList: [],
      selectedChannelList: [],
      selectedUnitList: [],
      newChannelList: [],
      guestList: [],
      newGuestList: [],
      lockType: 1,
      selectedGuestList: [],
      activeClass: "all",
      tab: 1,
      isRefresh: 0,
      lock_type: localStorage.getItem("propertyLockType")
        ? parseInt(localStorage.getItem("propertyLockType"))
        : 1,
      keysTabNumber: localStorage.getItem("keys_activity_tab_number")
        ? parseInt(localStorage.getItem("keys_activity_tab_number"))
        : "",
      listing_slug: this.props.match.params.listingSlug,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "unit_name",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "access_start_date",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config2: {
        sort: {
          column: "access_end_date",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config3: {
        sort: {
          column: "email",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config4: {
        sort: {
          column: "access_code",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config5: {
        sort: {
          column: "key_name",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config6: {
        sort: {
          column: "keycafe_key_id",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config7: {
        sort: {
          column: "ref_table_id",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config8: {
        sort: {
          column: "status_time",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",

        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "unit_name",
          text: "Unit Name",
          className: "unit_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.unit_name ? record.unit_name : "";
          },
        },
        {
          key: "access_start_date",
          text: "Start Time",
          className: "access_start_date hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.access_start_date ? (
              <div className="">
                {Moment(record.access_start_date).format(global.dateFormat)}
                {record.access_start_time ? (
                  <p className="m-0">{record.access_start_time}</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            );
          },
        },
        {
          key: "access_end_date",
          text: "End Time",
          className: "access_end_date hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.access_end_date ? (
              <div className="">
                {Moment(record.access_end_date).format(global.dateFormat)}
                {record.acess_end_time ? (
                  <p className="m-0">{record.acess_end_time}</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            );
          },
        },
        {
          key: "booking_code",
          text: "Access Code",
          className: "booking_code hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.access_code ? record.access_code : "";
          },
        },
        {
          key: "ref_table_id",
          text: "Type",
          className: "ref_table_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.ref_table_id == 1
              ? "Guest"
              : record.ref_table_id == 2
              ? "Cleaner"
              : record.ref_table_id == 3
              ? "Resident"
              : record.ref_table_id == 4
              ? "Visitor"
              : "";
          },
        },
        {
          key: "key_name",
          text: "Exchanger Name",
          className: "key_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.key_name ? record.key_name : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "keycafe_key_id",
          text: "Key ID",
          className: "keycafe_key_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.keycafe_key_id ? record.keycafe_key_id : "";
          },
        },

        {
          key: "current_key_status",
          text: "Access Status",
          className: "current_key_status hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.ref_table_id != 0 &&
              record.current_key_status === "DROPOFF"
              ? "Key in Locker"
              : record.ref_table_id == 1 &&
                record.current_key_status != undefined
              ? "Key with Guest"
              : record.ref_table_id == 2 &&
                record.current_key_status != undefined
              ? "Key with Cleaner"
              : record.ref_table_id == 3 &&
                record.current_key_status != undefined
              ? "Key with Resident"
              : record.ref_table_id == 4 &&
                record.current_key_status != undefined
              ? "Key with Visitor"
              : "";
          },
        },
        {
          key: "status_time",
          text: "Created On",
          className: "status_time hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.status_time
              ? Moment(record.status_time).format(global.dateTimeFormat)
              : "";
          },
        },
      ],
      columns1: [
        {
          key: "unit_name",
          text: "Unit Name",
          className: "unit_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.unit_name ? record.unit_name : "";
          },
        },
        {
          key: "access_start_date",
          text: "Start Time",
          className: "access_start_date hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.access_start_date ? (
              <div className="">
                {Moment(record.access_start_date).format(global.dateFormat)}
                {record.access_start_time ? (
                  <p className="m-0">{record.access_start_time}</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            );
          },
        },
        {
          key: "access_end_date",
          text: "End Time",
          className: "access_end_date hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.access_end_date ? (
              <div className="">
                {Moment(record.access_end_date).format(global.dateFormat)}
                {record.acess_end_time ? (
                  <p className="m-0">{record.acess_end_time}</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            );
          },
        },
        {
          key: "access_code",
          text: "Door Name",
          className: "access_code hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.access_code ? record.access_code : "";
          },
        },
        {
          key: "ref_table_id",
          text: "Type",
          className: "ref_table_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.ref_table_id == 1
              ? "Guest"
              : record.ref_table_id == 2
              ? "Cleaner"
              : record.ref_table_id == 3
              ? "Resident"
              : record.ref_table_id == 4
              ? "Visitor"
              : "";
          },
        },
        {
          key: "key_name",
          text: "Exchanger Name",
          className: "key_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.key_name ? record.key_name : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "status_name",
          text: "Access Status",
          className: "status_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_name ? record.status_name : "";
          },
        },
        {
          key: "token_generated_at",
          text: "Created On",
          className: "token_generated_at hand-cursor date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.token_generated_at
              ? Moment(record.token_generated_at).format(global.dateTimeFormat)
              : "";
          },
        },
      ],
    };

    // this.confirmImport = this.confirmImport.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.filterData = this.filterData.bind(this);
    this.filterDataa = this.filterDataa.bind(this);
  }

  filterDataa() {
    var queryString =
      "unit_name=" +
      (this.state.unit_number ? this.state.unit_number : "") +
      "&is_search=1" +
      "&tab=" +
      this.state.tab;

    let moduleSearchString = "";

    moduleSearchString = this.state.unit_number
      ? moduleSearchString
        ? moduleSearchString + " &" + " unit - " + this.state.unit_number
        : " unit - " + this.state.unit_number
      : moduleSearchString;

    if (this.state.unit_number) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString });
    }
  }

  filterData() {
    let queryString =
      "unit_name=" +
      (this.state.unit_number ? this.state.unit_number : "") +
      "&email=" +
      (this.state.email ? this.state.email : "") +
      "&key_name=" +
      (this.state.key_name ? this.state.key_name : "") +
      // "&resident_slug=" +
      // (this.state.resident_slug ? this.state.resident_slug : "") +
      "&keycafe_key_id=" +
      (this.state.keycafe_key_id ? this.state.keycafe_key_id : "") +
      "&access_code=" +
      (this.state.access_code ? this.state.access_code : "") +
      "&access_start_date=" +
      (this.state.access_start_date
        ? Moment(this.state.access_start_date).format("YYYY-MM-DD")
        : "") +
      "&access_start_time=" +
      (this.state.access_start_time
        ? Moment(this.state.access_start_time, "hh:mm A").format("HH:mm")
        : "") +
      "&access_end_date=" +
      (this.state.access_end_date
        ? Moment(this.state.access_end_date).format("YYYY-MM-DD")
        : "") +
      "&acess_end_time=" +
      (this.state.access_end_date
        ? Moment(this.state.acess_end_time, "hh:mm A").format("HH:mm")
        : "") +
      "&lock_type=" +
      (this.state.lock_type ? this.state.lock_type : "") +
      "&tab=" +
      this.state.tab;
    // "&is_search=1";
    let moduleSearchString = "";
    moduleSearchString = this.state.unit_number
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Unit - " +
          this.state.selectedUnitList.label
        : " Unit Name- " + this.state.selectedUnitList.label
      : moduleSearchString;
    moduleSearchString = this.state.email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Email - " + this.state.email
        : " Email - " + this.state.email
      : moduleSearchString;
    moduleSearchString = this.state.key_name
      ? moduleSearchString
        ? moduleSearchString + " ," + "Exchanger Name - " + this.state.key_name
        : "Exchanger Name - " + this.state.key_name
      : moduleSearchString;
    moduleSearchString = this.state.keycafe_key_id
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Keycafe key Id - " +
          this.state.keycafe_key_id
        : "Keycafe Key Id - " + this.state.keycafe_key_id
      : moduleSearchString;
    if (this.state.access_code) {
      if (this.state.lock_type === 1) {
        moduleSearchString = moduleSearchString
          ? moduleSearchString + ", Access Code - " + this.state.access_code
          : "Access Code - " + this.state.access_code;
      } else if (this.state.lock_type === 2) {
        moduleSearchString = moduleSearchString
          ? moduleSearchString + ", Door Name - " + this.state.access_code
          : "Door Name - " + this.state.access_code;
      }
    }
    moduleSearchString = this.state.access_start_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Access Start Date - " +
          this.state.access_start_date
        : " Access Start Date - " + this.state.access_start_date
      : moduleSearchString;
    moduleSearchString = this.state.access_start_time
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Access Start Time - " +
          this.state.access_start_time
        : " Access Start Time - " + this.state.access_start_time
      : moduleSearchString;
    moduleSearchString = this.state.access_end_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Access End Date - " +
          this.state.access_end_date
        : " Access End Date - " + this.state.access_end_date
      : moduleSearchString;
    moduleSearchString = this.state.acess_end_time
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Acess End Time - " +
          this.state.acess_end_time
        : " Acess End Time - " + this.state.acess_end_time
      : moduleSearchString;

    if (
      this.state.unit_number ||
      this.state.email ||
      this.state.key_name ||
      this.state.keycafe_key_id ||
      this.state.access_code
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      unit_number: "",
      selectedUnitList: [],
      selectedResidentList: [],
      selectedChannelList: [],
    });
    this.setState({ module_search: "" });
  }

  async componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      switch (this.state.keysTabNumber) {
        case 1:
          document.getElementById("access").click();
          this.getData();
          break;
        case 2:
          document.getElementById("historys").click();
          break;
        case 3:
          var a = document.getElementById("keys");
          if (a && a[0]) {
            a[0].click();
            this.getData();
          }
          break;
        default:
          document.getElementById("access").click();
      }
      this.getUnitData();
      this.getPropertyData();
    }
  }

  async getPropertyData() {
    if (this.state.property_slug) {
      var lock_type = 1;
      let res = await PropertyService.getProperty(this.state.property_slug);

      if (global.successStatus.includes(res.status)) {
        lock_type = res.data ? res.data.lock_type : 1;
      }
      this.setState({ lock_type: lock_type });
    }
    if (lock_type === 1 && document.getElementById("keys")) {
      document.getElementById("keys").click();
    } else {
      document.getElementById("access").click();
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      switch (this.state.keysTabNumber) {
        case 1:
          document.getElementById("access").click();
          this.getData();
          break;
        case 2:
          document.getElementById("historys").click();
          this.getData();
          break;
        case 3:
          var a = document.getElementById("keys");
          if (a && a[0]) {
            a[0].click();
            this.getData();
          }
          break;
        default:
          document.getElementById("access").click();
          this.getData();
      }
      this.getUnitData();
      this.getPropertyData();
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_number: value.label,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  async getData(tab, queryString = "") {
    var resData = {};
    var totalRecords = 0;
    let res = await KeycafeService.getKeycafeAccessdetail(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        "&tab=" +
        (tab ? tab : this.state.tab) +
        "&lock_type=" +
        (this.state.lock_type ? this.state.lock_type : "") +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: resData,
      isLoaded: true,
    });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    this.setState({ is_search: 0 });
    this.setState({ module_search: "" });
    if (e === "access") {
      this.setState({ keysTabNumber: 1, isRefresh: 1 });
      setTimeout(() => {
        localStorage.setItem(
          "keys_activity_tab_number",
          this.state.keysTabNumber ? this.state.keysTabNumber : ""
        );
        this.getData();
      }, 5);
    } else if (e === "historys") {
      this.setState({ keysTabNumber: 2, isRefresh: 2 });
      setTimeout(() => {
        localStorage.setItem(
          "keys_activity_tab_number",
          this.state.keysTabNumber ? this.state.keysTabNumber : ""
        );
      }, 5);
    } else if (e === "keys") {
      this.setState({ keysTabNumber: 3, isRefresh: 3 });
      setTimeout(() => {
        localStorage.setItem(
          "keys_activity_tab_number",
          this.state.keysTabNumber ? this.state.keysTabNumber : ""
        );
      }, 5);
    }
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeChannel(value) {
    let set_channel = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        set_channel.push(item.value);
      });
    }
    const set_channel_value = value !== null ? value.value : [];
    this.setState({
      selectedChannelList: value,
      set_channel: set_channel_value,
    });
    this.setState({ confirm_back: 1 });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () =>
            this.getData(queryString + "&tab=" + this.state.tab + "&is_list=1"),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString + "&tab=" + this.state.tab + "&is_list=1");
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    if (this.state.activeClass === "access") {
      return "Access";
    }
    if (this.state.activeClass === "historys") {
      return "Activity";
    }
    if (this.state.activeClass === "keys") {
      return "Keys";
    }
  }

  rowClickedHandler = (event, data, rowIndex) => {
    if (queryParams.isGlobal === "1" || this.state.is_search === 1) {
      window.open(
        this.props.match.params.propertySlug !== undefined
          ? "/properties/view/" +
              this.state.property_slug +
              "/keys/activity-view/" +
              data.slug
          : this.state.property_slug
          ? "/keys/activity-view/" + this.state.property_slug + "/" + data.slug
          : "/keys/activity-view/" + data.property_slug + "/" + data.slug,
        "_blank"
      );
    } else {
      this.props.history.push(
        this.props.match.params.propertySlug !== undefined
          ? "/properties/view/" +
              this.state.property_slug +
              "/keys/activity-view/" +
              data.slug
          : this.state.property_slug
          ? "/keys/activity-view/" + this.state.property_slug + "/" + data.slug
          : "/keys/activity-view/" + data.property_slug + "/" + data.slug
      );
    }
  };

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  handleChange = (file) => {
    this.setState({ file_name: file.name });
    this.setState({ file_url: file });
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-10 right">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0 fout_btn_w">
                        {global.userPermissions.checkPermission(
                          "key-cafe-manual-edit"
                        ) &&
                        this.state.property_slug &&
                        this.state.lock_type == 1 ? (
                          <Link
                            to={
                              this.props.match.params.propertySlug !== undefined
                                ? "/properties/view/" +
                                  this.state.property_slug +
                                  "/keys/activity-add"
                                : "/keys/activity-add/" +
                                  this.state.property_slug
                            }
                          >
                            {this.state.activeClass === "access" ? (
                              <button className="btn-success-outline-small ">
                                <img src={pluswIcon} alt="" /> Add
                              </button>
                            ) : (
                              ""
                            )}
                          </Link>
                        ) : (
                          ""
                        )}
                        {global.userPermissions.checkPermission(
                          "key-cafe-manual-edit"
                        ) &&
                        this.state.property_slug &&
                        this.state.lock_type == 2 ? (
                          <Link
                            to={
                              this.props.match.params.propertySlug !== undefined
                                ? "/properties/view/" +
                                  this.state.property_slug +
                                  "/latches/activity-add"
                                : "/latches/activity-add/" +
                                  this.state.property_slug
                            }
                          >
                            {this.state.activeClass === "access" ? (
                              <button className="btn-success-outline-small ">
                                <img src={pluswIcon} alt="" /> Add
                              </button>
                            ) : (
                              ""
                            )}
                          </Link>
                        ) : (
                          ""
                        )}

                        {this.state.activeClass === "access" ? (
                          <button
                            onClick={this.searchToggle}
                            className="btn-success-outline-small ml-3"
                          >
                            <img src={filterIcon} alt="filterIcon" />{" "}
                            {this.state.isSearch === 1 ? "Search" : "Search"}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {this.props.match.params.propertySlug === undefined ? (
                    <>
                      <div className="row pd-lg-l-15 pd-xs-l-15">
                        <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                          <OutSidePropertySelectBox
                            propertySlug={this.getPropertySlug.bind(this)}
                            outSidePropertySlug={this.state.outSidePropertySlug}
                          />
                        </div>
                        <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                          {queryParams.isGlobal === "1" ? (
                            <>
                              <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                {"Search applied on: " +
                                  queryParams.global_col_label +
                                  " - " +
                                  queryParams.global_search}
                                <button
                                  onClick={this.clearFilter}
                                  className="mg-l-15 btn-success-outline-small"
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.is_search === 1 ? (
                            <>
                              <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                                <div className="custm-filter">
                                  <div>
                                    {" "}
                                    <label className="form-label text-left pd-b-2 mb-0">
                                      Search Applied On
                                    </label>
                                  </div>
                                  <div className="filter_bg_cust">
                                    {this.state.module_search.map((item, i) => {
                                      return <span key={i}>{item}</span>;
                                    })}
                                    <button
                                      onClick={this.clearFilter}
                                      className="btn-fliter-clear"
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-xxl-12 pd-r-15 pd-l-15">
                      {queryParams.isGlobal === "1" ? (
                        <>
                          <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                            {"Search applied on: " +
                              queryParams.global_col_label +
                              " - " +
                              queryParams.global_search}
                            <button
                              onClick={this.clearFilter}
                              className="mg-l-15 btn-success-outline-small"
                            >
                              Clear
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.is_search === 1 ? (
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter">
                              <div>
                                {" "}
                                <label className="form-label text-left pd-b-2 mb-0">
                                  Search Applied On
                                </label>
                              </div>
                              <div className="filter_bg_cust">
                                {this.state.module_search.map((item, i) => {
                                  return <span key={i}>{item}</span>;
                                })}
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <div className="scrolling-carousel pd-l-15 scroll-slide">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="all"
                      onSelect={(e) => this.getActiveClass(e)}
                      eventKey={this.state.activeClass}
                      className="pd-r-60"
                    >
                      <ScrollingCarousel
                        rightArrow={true}
                        leftArrow={true}
                        className="swipesection scroll_nav_mob"
                      >
                        {this.state.lock_type === 1 ? (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="keys"
                              id="keys"
                              className={
                                this.state.activeClass === "keys"
                                  ? "active"
                                  : ""
                              }
                            >
                              Keys
                            </Nav.Link>
                          </Nav.Item>
                        ) : (
                          ""
                        )}
                        <Nav.Item>
                          <Nav.Link
                            eventKey="access"
                            id="access"
                            className={
                              this.state.activeClass === "access"
                                ? "active"
                                : ""
                            }
                          >
                            All Access
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            eventKey="historys"
                            id="historys"
                            className={
                              this.state.activeClass === "historys"
                                ? "active"
                                : ""
                            }
                          >
                            All Activity
                          </Nav.Link>
                        </Nav.Item>
                      </ScrollingCarousel>
                      <Tab.Content>
                        <Tab.Pane eventKey="access">
                          {this.state.isRefresh === 1 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <div className="col-md-12 pd-lg-l-30 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15 pd-xs-l-15 pd-xs-r-0">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>

                              {this.state.total_record !== 0 ||
                              this.state.is_search === 1 ||
                              queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  <div className="pd-lg-l-0 pd-xs-l-15">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap reservationTable table_last_row"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={
                                        this.state.lock_type === 1
                                          ? this.state.columns
                                          : this.state.columns1
                                      }
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <NoData msg="keys" />
                              )}
                            </div>
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="historys">
                          {this.state.isRefresh === 2 && (
                            <ActivityHistory
                              property_slug={this.state.property_slug}
                              lock_type={this.state.lock_type}
                              // unit_slug={this.state.unit_slug}
                            />
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="keys">
                          {this.state.isRefresh === 3 &&
                          this.state.lock_type === 1 ? (
                            <Keys
                              {...this.props}
                              property_slug={this.state.property_slug}
                              slug={this.state.slug}
                            />
                          ) : (
                            ""
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Keys</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Exchanger Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="key_name"
                      value={this.state.key_name}
                      maxLength="50"
                    />
                  </div>
                </InputGroup>
                {this.state.lock_type === 1 && (
                  <InputGroup className="mt-3">
                    <label>Access Code</label>
                    <div className="col-md-12 p-0">
                      <Form.Control
                        type="number"
                        onChange={this.changeHandler}
                        name="access_code"
                        value={this.state.access_code}
                        maxLength="50"
                      />
                    </div>
                  </InputGroup>
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Email</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      type="email"
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      maxLength="50"
                    />
                  </div>
                </InputGroup>

                {this.state.lock_type === 1 && (
                  <InputGroup className="mt-3">
                    <label>Key ID</label>
                    <div className="col-md-12 p-0">
                      <Form.Control
                        type="number"
                        onChange={this.changeHandler}
                        name="keycafe_key_id"
                        value={this.state.keycafe_key_id}
                        maxLength="50"
                      />
                    </div>
                  </InputGroup>
                ) ? (
                  <InputGroup className="mt-3">
                    <label>Key ID</label>
                    <div className="col-md-12 p-0">
                      <Form.Control
                        type="number"
                        onChange={this.changeHandler}
                        name="keycafe_key_id"
                        value={this.state.keycafe_key_id}
                        maxLength="50"
                      />
                    </div>
                  </InputGroup>
                ) : (
                  <InputGroup className="mt-3">
                    <label>Door Name</label>
                    <div className="col-md-12 p-0">
                      <Form.Control
                        type="text"
                        onChange={this.changeHandler}
                        name="access_code"
                        value={this.state.access_code}
                        maxLength="50"
                      />
                    </div>
                  </InputGroup>
                )}
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>

              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Activity;

import React, { useState, useEffect } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { Form } from "react-bootstrap";
import NoData from "../../common/NoData";
import Alerts from "../../common/Alerts";
import SettingsSidebar from "../../common/SettingsSidebar";
import LatchService from "../../../services/LatchService";
import PropertyService from "../../../services/PropertyService";
import Instructions from "./Instructions";
import LatchCleanerAccess from "./LatchCleanerAccess";

const Mapping = (props) => {
  const [doors, setDoors] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [activeClass, setActiveClass] = useState("alerts");
  const [lockType, setLockType] = useState(0);
  const [isSubmit, setSubmit] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalType, setAlertModalType] = useState("");
  const [alertModalTitle, setAlertModalTitle] = useState("");
  const [alertModalMessage, setAlertModalMessage] = useState("");

  useEffect(() => { getDoorData(); getLockType(); }, []);
  function getActiveClass(e) {
    setActiveClass(e);
  }
  const getDoorData = async () => {
    let doors = [];
    let unitOptions = [];
    let res = await LatchService.getLatchDoors("property_slug=" + props.match.params.propertySlug);
    if (global.successStatus.includes(res.status)) {
      doors = res.data.doors || [];
      unitOptions = res.data.units.map(item => {
        return { value: item.unit_id, label: item.unit_name }
      })
    }
    setDoors(doors);
    setUnitOptions(unitOptions);
  }

  const getLockType = async () => {
    let lockType = 0;
    let res = await PropertyService.getProperty(props.match.params.propertySlug);
    if (global.successStatus.includes(res.status) && res.data && res.data.lock_type) {
      lockType = res.data.lock_type;
    }
    setLockType(lockType);
  }

  const saveOrUpdateDoors = async (event) => {
    event.preventDefault();
    setSubmit(true);
    let res = [];
    let inputData = { property_slug: props.match.params.propertySlug, selected_doors: doors };
    res = await LatchService.mappingLatchUnits(inputData);
    if (global.successStatus.includes(res.status)) {
      setSubmit(false);
      setShowAlertModal(true);
      setAlertModalType("success");
      setAlertModalTitle("Success");
      setAlertModalMessage(res.message ? res.message : "Success");
      setTimeout(() => setShowAlertModal(false), global.alert_close_time);
    }
  }

  function changeUnits(event, index) {
    let value = event.target.value;
    let mapData = [...doors];
    mapData[index].unit_id = value;
    setDoors(mapData);
  }
  function changeDoors(event, index) {
    let value = event.target.value;
    let mapData = [...doors];
    mapData[index].is_common = value;
    setDoors(mapData);
  }
  return (
    <main>
      <Container className="innter-container">
        <div className="d-flex flex-column">
          <div className="row row-xs mg-t-0 pd-t-0">
            <div className="col-md-2 left">
              <div className="child-menu-section">
                <aside><SettingsSidebar property_slug={props.match.params.propertySlug} /></aside>
              </div>
            </div>
            <div className="col-md-10 right">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0 keycafe-header-sticky-">
                <div className="dashbaord-pageHeadertitle dashboardRightcard d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns keycafemappingtitle">
                  <h3>Latch Mapping </h3>
                </div>
              </div>
              {
                lockType === 2
                  ?
                  <div className="scrolling-carousel pd-l-15">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="alerts" onSelect={(e) => getActiveClass(e)} eventKey={activeClass}>
                      <ScrollingCarousel rightArrow={true} leftArrow={true} className="swipesection scroll_nav_mob">
                        <Nav.Item>
                          <Nav.Link eventKey="alerts" id="infoForm" className={activeClass === "alerts" ? "active" : ""}>Unit Mapping</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="alert-activity" id="alert-activity" className={activeClass === "alert-activity" ? "active" : ""}>
                            Pick Up/Drop Off Instructions
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="alert-cleaner" id="alert-cleaner" className={activeClass === "alert-cleaner" ? "active" : ""}>
                            Cleaner Manual OTP Verification
                          </Nav.Link>
                        </Nav.Item>
                      </ScrollingCarousel>
                      <Tab.Content className="pd-l-15 pd-r-15">
                        <Tab.Pane eventKey="alerts">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                            <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns pt-3">
                              {
                                global.userPermissions.checkPermission('latch-mapping-edit')
                                &&
                                <button className="btn-success-outline-small ml-3 button-width" type="button" disabled={isSubmit ? true : false} onClick={saveOrUpdateDoors}>
                                  {isSubmit ? global.loader : 'Save'}
                                </button>
                              }
                            </div>
                            <div className="row align-items-center border-bottom-0 mapp_head_desk">
                              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mg-t-15 pd-l-30 pd-r-30 pd-r-30 mg-b-0">
                                <label className="form-label text-left mg-b-0">Door Name</label>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mg-t-15  pd-l-30 pd-r-30 pd-r-30 mg-b-0">
                                <label className="form-label text-left mg-b-0">Door Type</label>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mg-t-15  pd-l-30 pd-r-30 pd-r-30 mg-b-0">
                                <label className="form-label text-left mg-b-0">Common Door</label>
                              </div>
                              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mg-t-15  pd-l-30 pd-r-30 pd-r-30 mg-b-0">
                                <label className="form-label text-left mg-b-0">Units</label>
                              </div>
                            </div>
                            <div className="row align-items-center border-bottom-0">
                              <div className="col-md-12 pd-lg-r-30 pd-lg-l-30 pd-xs-l-15 pd-xs-r-15 mg-t-15">
                                <Alerts show={showAlertModal} type={alertModalType} title={alertModalTitle} message={alertModalMessage} />
                              </div>
                              {doors && doors.length > 0
                                ? doors.map(
                                  (item, index) => {
                                    return (
                                      <>
                                        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                          <label className="form-label text-left mg-b-0 mapp_head_mob">Door Name</label>
                                          <Form.Control
                                            name="door_name"
                                            value={item.door_name || ""}
                                            disabled={true}
                                            className="form-control max_width_100"
                                            type="text"
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                          <label className="form-label text-left mg-b-0 mapp_head_mob">Door Type</label>
                                          <Form.Control
                                            name="type"
                                            value={item.type || ""}
                                            disabled={true}
                                            className="form-control max_width_100"
                                            type="text"
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                          <label className="form-label text-left mg-b-0 mapp_head_mob">Common Door Type</label>
                                          {/* <Form.Control
                                            name="common_door"
                                            value={item.common_door || ""}
                                            disabled={true}
                                            className="form-control max_width_100"
                                            type="text"
                                          /> */}
                                          <select onChange={(e) => changeDoors(e, index)} value={item.is_common}>
                                            <option value={0}>No</option>
                                            <option value={1}>Yes</option>
                                          </select>
                                        </div>
                                        <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                          <label className="form-label text-left mg-b-0 mapp_head_mob">Unit</label>
                                          <select onChange={(e) => changeUnits(e, index)} value={item.unit_id} disabled={item.is_common === 1}>
                                            <option value="">Select Unit</option>
                                            {
                                              unitOptions.map((option, key) => <option key={key} value={option.value}>{option.label}</option>)
                                            }
                                          </select>
                                        </div>
                                      </>
                                    );
                                  })
                                : <NoData msg="Latches" />
                              }
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="alert-activity">
                          <Instructions propertySlug={props.match.params.propertySlug} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="alert-cleaner">
                          <LatchCleanerAccess propertySlug={props.match.params.propertySlug} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                  :
                  lockType === 1
                    ?
                    <div className="col-md-12 mg-t-0 mg-b-10 pd-t-15 pd-b-10 pd-lg-l-45 pd-xs-l-15 pd-r-0">
                      <p className="pets_no_text text-left font-italic">This property is using Key Cafe.</p>
                    </div>
                    : ""
              }
            </div>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Mapping;

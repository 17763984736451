////////////////////////////////////////////////////////////
//     							                                      //
//  Program: HeatingService.js                           //
//  Application: Heating Type                           //
//  Option: Used for manage Heating type                 //
//  Developer:NP  						                  //
//  Date: 2022-03-15                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class HeatingService {
  getHeating(queryString) {
    let endPoint = "heating"+ (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createHeating(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "heating";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getHeatingBySlug(slug) {
    let endPoint = "heating/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateHeating(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "heating/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteHeating(slug) {
    let endPoint = "heating/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new HeatingService();

///////////////////////////////////////////////
//     							             //
//  Program: ApplicantsService.js                 //
//  Application: Services                    //
//  Option: Used for Applicants data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-02-09                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class ApplicantsService {
    getApplicants(queryString) {
        let endPoint = "applicant" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    createApplicants(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "applicant";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    getApplicantsBySlug(slug) {
        let endPoint = "applicant/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateApplicants(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "applicant/" + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.putCall(inputData);
        return response;
    }

    deleteApplicants(slug) {
        let endPoint = "applicant/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
    }

    uploadApplicantsImage(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "upload-applicant-photo";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }
}

export default new ApplicantsService();

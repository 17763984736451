////////////////////////////////////////////////////////////
//     							                          //
//  Program: ApplicantsDetail.jsx                                    //
//  Application: ApplicantsDetail                                    //
//  Option: List all influencers                                //
//  Developer: Ashish Kumar 						              //
//  Date: 2022-02-09                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, InputGroup, Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import ApplicantsDetailService from "../../services/ApplicantsDetailService";
import NoData from "../common/NoData";
import Moment from "moment";
import LeasingSidebar from "../common/LeasingSidebar";
import filterIcon from "./../../assets/images/filter-gray.svg";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import queryString from "query-string";
import Select from "react-select";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";

let queryParams = queryString.parse(window.location.search);

export class ApplicantsDetail extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      start_date: "",
      end_date: "",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      input: {},
      isSearch: global.isSearch,
      showFilter: global.showFilter,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: -1,
      property_slug: this.props.match.params.propertySlug,
      StatusList: global.statuslist,
      aplicationdata: global.statuslist,
      selectedStatusList: [],
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "tour_date_time",
          text: "Application Date",
          className: "tour_date_time hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.tour_date_time
              ? Moment(record.tour_date_time).format(global.dateFormat)
              : "";
          },
        },
        {
          key: "first_name",
          text: "First Name",
          className: "first_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.applicant_info
              ? record.applicant_info.first_name
              : "";
          },
        },
        {
          key: "last_name",
          text: "Last Name",
          className: "last_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.applicant_info ? record.applicant_info.last_name : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.applicant_info ? record.applicant_info.email : "";
          },
        },
        {
          key: "phone_number",
          text: "Phone",
          className: "phone_number hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber =
              record.applicant_info && record.applicant_info.phone_number
                ? record.applicant_info.phone_number.replace(/[\s-]/g, "")
                : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits =
                record.applicant_info && record.applicant_info.phone_number
                  ? record.applicant_info.phone_number
                  : [];
            }

            var number =
              record.applicant_info && record.applicant_info.phone_number
                ? lastTenDigits.trim().replace(/[^0-9]/g, "")
                : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.applicant_info ? (
              <Link
                to={
                  "/properties/view/" +
                  this.state.property_slug +
                  "/applicants/view/" +
                  record.applicant_info.slug
                }
                className=""
              >
                {finalnumber}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "property_floor_plan_name",
          text: "Floor Plan Name",
          className: "property_floor_plan_id hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.property_floor_plan_name
              ? record.property_floor_plan_name
              : "";
          },
        },
        {
          key: "desired_move_in_date",
          text: "Desired Move In Date",
          className: "tour_date_time hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.desired_move_in_date
              ? Moment(record.desired_move_in_date).format(global.dateFormat)
              : "";
          },
        },
        {
          key: "move_in_cost_payment",
          text: "Move In Cost",
          className: "tour_date_time hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.move_in_cost_payment
              ? record.move_in_cost_payment
              : "";
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id td_clickable",
          sortable: false,
          cell: (record) => {
            if (record.status_id === 1) return "Open";
            else if (record.status_id === 2) return "Converted";
            else if (record.status_id === 3) return "Lost";
            else if (record.status_id === 4) return "Application Started";
            else if (record.status_id === 5) return "Application Not Started";
            else if (record.status_id === 6) return "Application Submitted";
            else if (record.status_id === 7) return "Application Not Submitted";
            else if (record.status_id === 8) return "Application Approved";
            else if (record.status_id === 9) return "Application Not Approved";
            else if (record.status_id === 10) return "Lease Created";
            else if (record.status_id === 11) return "Lease Not Created";
            else if (record.status_id === 12) return "Lease Signed";
            else if (record.status_id === 13) return "Lease Not Signed";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined) {
      this.setState({ showSpinner: true, loading: true });
      this.getData();
      this.setState({ showSpinner: false, loading: false });
    }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getData();
    }, 200);
    this.setState({
      module_search: [],
      is_search: 0,
    });
  }

  filterData() {
    let leaseStart = this.state.tour_date_time
      ? Moment(this.state.tour_date_time).format("YYYY-MM-DD")
      : "";
    let leaseEnd = this.state.desired_move_in_date
      ? Moment(this.state.desired_move_in_date).format("YYYY-MM-DD")
      : "";
    let phonenumber = this.state.phone_number ? this.state.phone_number : "";
    let queryString =
      "tour_date_time=" +
      leaseStart +
      "&desired_move_in_date=" +
      leaseEnd +
      "&phone_number=" +
      phonenumber +
      "&move_in_cost_payment=" +
      (this.state.move_in_cost_payment ? this.state.move_in_cost_payment : "") +
      "&status_id=" +
      (this.state.status_id ? this.state.status_id : "") +
      "&email=" +
      (this.state.email ? this.state.email : "") +
      "&is_search=1";
    let moduleSearchString = "";
    moduleSearchString = this.state.tour_date_time
      ? "Application date - " +
        Moment(this.state.tour_date_time).format(global.dateFormat)
      : "";
    moduleSearchString = this.state.desired_move_in_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Desired Move In Date - " +
          Moment(this.state.desired_move_in_date).format(global.dateFormat)
        : " Move In Date - " +
          Moment(this.state.desired_move_in_date).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.move_in_cost_payment
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Move in Cost - " +
          this.state.move_in_cost_payment
        : " Move in Cost - " + this.state.move_in_cost_payment
      : moduleSearchString;
    moduleSearchString = this.state.email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Email - " + this.state.email
        : " Email - " + this.state.email
      : moduleSearchString;
    moduleSearchString = this.state.phone_number
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Phone Number - " +
          this.state.phone_number
        : " Phone Number - " + this.state.phone_number
      : moduleSearchString;
    moduleSearchString = this.state.status_id
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;

    if (
      this.state.tour_date_time ||
      this.state.desired_move_in_date ||
      this.state.move_in_cost_payment ||
      this.state.email ||
      this.state.phone_number ||
      this.state.status_id
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(queryString);
    }
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await ApplicantsDetailService.getApplicantsDetail(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  customFilter() {
    let startDate = this.state.start_date
      ? Moment(this.state.start_date).format("YYYY-MM-DD")
      : "";
    let endDate = this.state.end_date
      ? Moment(this.state.end_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "start_date=" + startDate + "&end_date=" + endDate + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "", end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      // this.getData();
      // this.getResidentData();
      this.setState({ isSearch: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeStatusList(value) {
    let fee_level = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        fee_level.push(item.value);
      });
    }
    const fee_level_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: fee_level_value,
    });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Inquiry";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "/properties/view/" +
            this.state.property_slug +
            "/applicant-detail/view/" +
            data.slug
        : this.state.property_slug
        ? "/applicant-detail/view/" + this.state.property_slug + "/" + data.slug
        : "/applicant-detail/view/" + data.property_slug + "/" + data.slug
    );
  };

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      tour_date_time: "",
      desired_move_in_date: "",
      phonenumber: "",
      status: "",
      first_name: "",
      last_name: "",
      email: "",
    });
    this.setState({ module_search: "" });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <LeasingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyLeasingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}

                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex flex-wrap justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small ml-3"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.props.match.params.propertySlug === undefined ? (
                    <div className="row pd-lg-l-15 pd-xs-l-15">
                      <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                        <OutSidePropertySelectBox
                          propertySlug={this.getPropertySlug.bind(this)}
                          outSidePropertySlug={this.state.outSidePropertySlug}
                        />
                      </div>
                      <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                              <div className="custm-filter">
                                <div>
                                  {" "}
                                  <label className="form-label text-left pd-b-2 mb-0">
                                    Search Applied On
                                  </label>
                                </div>
                                <div className="filter_bg_cust">
                                  {this.state.module_search.map((item, i) => {
                                    return <span key={i}>{item}</span>;
                                  })}
                                  <button
                                    onClick={this.clearFilter}
                                    className="btn-fliter-clear"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="col-xxl-12 pd-r-15 pd-l-15">
                      {this.state.is_search === 1 ? (
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter">
                              <div>
                                {" "}
                                <label className="form-label text-left pd-b-2 mb-0">
                                  Search Applied On
                                </label>
                              </div>
                              <div className="filter_bg_cust">
                                {this.state.module_search.map((item, i) => {
                                  return <span key={i}>{item}</span>;
                                })}
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-15">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                      <div className="col-md-12pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.showFilter ? (
                        <>
                          <Row className="align-items-end order_form col-md-12 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15 mb-3 ml-0 mr-0">
                            <Col
                              md={4}
                              lg={4}
                              xl={4}
                              className="mb-md-0 mb-3 pl-3 pr-0"
                            >
                              <label className="form-label text-left pd-b-5">
                                From
                              </label>
                              <DatePicker
                                className="form-control max_width_100"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                disabledKeyboardNavigation
                                strictParsing
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="start_date"
                                minDate={this.state.from_date}
                                //placeholderText="Start Date"
                                dateFormat="MM/dd/yy"
                                selected={this.state.start_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "start_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                            </Col>
                            <Col
                              md={4}
                              lg={4}
                              xl={4}
                              className="mb-md-0 mb-3 pl-3 pr-0"
                            >
                              <label className="form-label text-left pd-b-5">
                                To
                              </label>
                              <DatePicker
                                className="form-control max_width_100"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                disabledKeyboardNavigation
                                strictParsing
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="end_date"
                                // placeholderText="End Date & Time"
                                dateFormat="MM/dd/yy"
                                selected={this.state.end_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "end_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                            </Col>

                            <Col md={4} lg={4} xl={4} className="mt-0">
                              <Row className="d-flex justify-content-end">
                                <button
                                  onClick={this.customFilter}
                                  className="btn-success mg-l-0 mg-r-15"
                                >
                                  Apply
                                </button>
                                <button
                                  onClick={this.resetDate}
                                  className="btn-success-outline"
                                >
                                  Reset
                                </button>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                      {queryParams.isGlobal === "1" ? (
                        <>
                          <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                            {"Search applied on: " +
                              queryParams.global_col_label +
                              " - " +
                              queryParams.global_search}
                            <button
                              onClick={this.clearFilter}
                              className="mg-l-15 btn-success-outline-small"
                            >
                              Clear
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {this.state.total_record > 0 ||
                      this.state.filter_value !== "" ? (
                        <div className="mg-t-18 brandList row d-block pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-0">
                          <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-30 pd-xs-r-15">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              // loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              onRowClicked={
                                global.userPermissions.checkPermission(
                                  "inquiry-update"
                                ) && this.rowClickedHandler
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage(
                            "APPLICATNSDETAILS"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Inquiry</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Date Time</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="tour_date_time"
                    selected={this.state.tour_date_time}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "tour_date_time",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Move In Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="desired_move_in_date"
                      selected={this.state.desired_move_in_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "desired_move_in_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Phone</label>
                  <div className="col-md-12 p-0">
                    <PhoneInput
                      country={global.country}
                      disableSearchIcon
                      // autoFormat= "true"
                      enableSearch="true"
                      countryCodeEditable="true"
                      value={this.state.phone_number}
                      onChange={(phone_number) =>
                        this.setState({ phone_number })
                      }
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Move In Cost</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="move_in_cost_payment"
                      value={this.state.move_in_cost_payment}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Email</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Status</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.StatusList}
                      value={this.state.selectedStatusList}
                      defaultValue={this.state.selectedStatusList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeStatusList(value)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ApplicantsDetail;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: OnboardingActivityList.jsx                  //
//  Application: Snapshot                                //
//  Option: to view most recent 10 onboarding activities  //
//  Developer: Kavita Singh  						      //
//  Date: 2023-06-06                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { withRouter } from "react-router";
import CompanyService from "../../services/CompanyService";
// import companyLogo from './../../assets/images/company_placeholder.png'
import NoOccupancy from "../../assets/images/snapshoticons/Occupancy.svg";
import Moment from "moment";

class OnboardingActivityList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertySlug: this.props.propertySlug,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: "",
      total_client_count: "",
      checkins_today: [],
      checkouts_today: [],
      checkins_tomorrow: [],
      checkouts_tomorrow: [],
      checkInList: [],
      checkOutList: [],
      cleaningscheduled_today: [],
      cleaningscheduled_tomorrow: [],
      occupiedunits: [],
      bookedunits: [],
      signed_leases: [],
      new_booking_list: [],
      avg_occupancy_total: 0,
      total_unit_booked: 0,
      total_unit_available: 0,
      total_resident_stays: 0,
      onboardingCount:0,
      onboardingListData:[],
    };
  }

  componentDidMount() {
    this.setState({ onboardingCount: 0, onboardingListData : [] });
    this.setState({ spinnerLoader: true, loading: true });
    if (this.state.propertySlug) {
      this.getData(this.state.propertySlug);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ onboardingCount: 0, onboardingListData : [] });
    this.setState({ spinnerLoader: true, loading: true });
    if (nextProps.propertySlug) {
      this.getData(nextProps.propertySlug);
    }
  }

  handleClickOnboardingProcessActivity(property_slug, listing_slug) {
    const win = window.open(
      "/listing/view/" +
        property_slug +
        "/" +
        listing_slug +
        "/info?request_from=activity_stream",
      "_blank"
    );
    win.focus();
  }
  async getData(propertySlug) {
    this.setState({ spinnerLoader: true, loading: true });
    var resData = {};

    let res = await CompanyService.getOnboardingProcessActivityList(
      propertySlug
    );
    if (global.successStatus.includes(res.status)) {
      resData.onboardingListData =
        res.data && res.data.onboardingListData
          ? res.data.onboardingListData
          : [];
      this.setState({
        resData: resData,
        onboardingCount: res.data.onboardingListData.length,
      });
    }
    this.setState({ spinnerLoader: false, loading: false });
  }

  render() {
    return (
      <div className="col-xxl-6 col-md-6 col-sm-12 col-12 mg-b-20">
        <div className="card card-table-two">
          <div className=" d-flex justify-content-between">
            <div className="">
              <h6 className="card-title">Onboarding Progress Activity</h6>
              <span className="d-block mg-b-0">
                Most recent onboarding progress activity
              </span>
            </div>
          </div>
          {this.state.onboardingCount > 0 ? (
            <div className="table-responsive">
              <table className="table table-dashboard-two mg-b-0">
                <thead>
                  <tr>
                    <th className="wd-lg-25p tx-left">S.No</th>
                    <th className="wd-lg-25p tx-left">Unit Name</th>
                    <th className="wd-lg-25p text-left">Resident Name</th>
                    <th className="wd-lg-25p tx-left">Step</th>
                    <th className="wd-lg-25p text-left">Updated on</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.resData.onboardingListData.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        value={item}
                        onClick={() =>
                          this.handleClickOnboardingProcessActivity(
                            item.property_slug,
                            item.listing_slug
                          )
                        }
                      >
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {i + 1}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.unit_name ? item.unit_name : ""}
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.resident_name ? item.resident_name : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.step ? item.step : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                          {item.updated_on
                            ? Moment(item.updated_on).format(
                                global.dateTimeFormat
                              )
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="table-responsive overflow-hidden">
                {this.state.spinnerLoader ? (
                  global.snapshotloader
                ) : (
                  <div className="text-center">
                    <img src={NoOccupancy} alt="" />
                    <p>No Onboarding Activity list.</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(OnboardingActivityList);

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import MasterDataService from "../../services/MasterDataService";
import MonthService from "../../services/MonthService";
import StateService from "../../services/StateService";
import Alerts from "../common/Alerts";
import { Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import PropertySidebar from "../common/PropertySidebar";
export class PropertyLegalEntity extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      confirm_back: 0,
      property_slug: this.props.match.params.propertySlug,
      //legal entity data
      property_legal_entity_data: [],
      property_legal_entity_data_total: 0,
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      entityTypeList: [
        { value: 1, label: "LLC" },
        { value: 2, label: "INC" },
      ],
      //month data
      monthList: [],
      newMonthList: [],
      selectedMonthList: [],
      //banking data
      deposit_account_number: "",
      deposit_account_bank: "",
      deposit_ach: "",
      operating_account_number: "",
      operating_account_bank: "",
      operating_ach: "",
      ownership : localStorage.getItem("ownership") ? localStorage.getItem("ownership") : ''
    };
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.legalChangeHandler = this.legalChangeHandler.bind(this);
    this.handleChangeMonthList = this.handleChangeMonthList.bind(this);
    this.handleChangeState = this.handleChangeState.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.saveOrUpdatePropertyLegalEntityData =
      this.saveOrUpdatePropertyLegalEntityData.bind(this);
  }

  componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getPropertyLegalEntityData();
    this.getBankingData();
  }

  async getBankingData() {
    if (this.state.property_slug !== undefined) {
      var resData = {};

      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData.property_name = res.data.property_name
          ? res.data.property_name
          : "";
        resData.aka_name = res.data.aka_name ? res.data.aka_name : "";
        resData.deposit_account_number = res.data.deposit_account_number
          ? res.data.deposit_account_number
          : "";
        resData.deposit_account_bank = res.data.deposit_account_bank
          ? res.data.deposit_account_bank
          : "";
        resData.deposit_ach = res.data.deposit_ach ? res.data.deposit_ach : "";
        resData.operating_account_number = res.data.operating_account_number
          ? res.data.operating_account_number
          : "";
        resData.operating_account_bank = res.data.operating_account_bank
          ? res.data.operating_account_bank
          : "";
        resData.operating_ach = res.data.operating_ach
          ? res.data.operating_ach
          : "";
        resData.month_name = res.data.month
          ? res.data.month.fiscal_month_value
          : "";
        resData.month_id = res.data.month ? res.data.month.fiscal_month_no : "";

        var selectedMonthList = [];
        selectedMonthList = res.data.month
          ? [
            {
              value: res.data.month.fiscal_month_no,
              label: res.data.month.fiscal_month_value,
            },
          ]
          : "";
      }
      this.setState(resData);
      this.setState({ selectedMonthList: selectedMonthList });
    }
  }

  async getFiscalMonthData(queryString = "") {
    //this.setState({ showSpinner: true, loading: true });
    var monthList = [];

    let res = await MonthService.getMonths(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      monthList = res.data ? res.data : [];
    }
    const newMonthList = monthList.map(
      ({ fiscal_month_no, fiscal_month_value }) => ({
        value: fiscal_month_no,
        label: fiscal_month_value,
      })
    );
    this.setState({ monthList: monthList, newMonthList: newMonthList });
    // this.setState({ showSpinner: false, loading: false });
  }

  async getEntityTypeData(queryString = "") {
    //this.setState({ showSpinner: true, loading: true });
    var property_legal_entity_data = [];

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=legel_entity",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      property_legal_entity_data = res.data ? res.data : [];
    }
    const property_legal_entity_data1 = property_legal_entity_data.map(
      ({ slug, name }) => ({
        legel_entity: slug,
        legel_entity_name: name,
        state_slug: "",
        entity_type: 0,
        name: "",
        tax_id: "",
      })
    );

    this.setState({
      property_legal_entity_data: property_legal_entity_data1,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  async getPropertyLegalEntityData() {
    //this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var property_legal_entity_data = [];
      var property_legal_entity_data_total = 0;

      let res = await PropertyService.getPropertyLegalEntity(
        this.state.property_slug
      );

      if (global.successStatus.includes(res.status)) {
        if (res.data.length > 0) {
          property_legal_entity_data = res.data ? res.data : [];
          property_legal_entity_data_total = property_legal_entity_data
            ? property_legal_entity_data.length
            : 0;

          property_legal_entity_data.map((item, i) => {
            property_legal_entity_data[i]["selected_state"] =
              item.state_slug !== null
                ? [{ value: item.state_slug, label: item.state_name }]
                : null;
            property_legal_entity_data[i]["selected_entity_type"] =
              item.entity_type !== 0
                ? [
                  {
                    value: item.entity_type,
                    label: item.entity_type === 1 ? "LLC" : "INC",
                  },
                ]
                : null;
          });

          this.setState({
            property_legal_entity_data: property_legal_entity_data,
            property_legal_entity_data_total: property_legal_entity_data_total,
          });
        }
      }

      if (this.state.property_legal_entity_data_total === 0) {
        this.getEntityTypeData();
      }
    }
    // this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdatePropertyLegalEntityData = async (e) => {
    this.setState({ showSpinner: true, isSubmit: true });

    let inputData = {
      property_slug: this.state.property_slug
        ? this.state.property_slug
        : this.state.property_slug,
      legel_data: this.state.property_legal_entity_data
        ? this.state.property_legal_entity_data
        : [],
    };

    if (this.state.property_slug !== undefined) {
      let res = await PropertyService.updatePropertyLegalEntity(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        // this.setState({ viewMode: 0 });
      } else {
        //  let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.message ? res.data.message : "Error!",
        //   alertModalMessage:
        //     alertMessage !== "" ? alertMessage : res.data.message,
        // });
      }
    }

    let inputData1 = {
      tab: "legal",
      property_name: this.state.property_name,
      aka_name: this.state.aka_name,
      deposit_account_number: this.state.deposit_account_number,
      deposit_account_bank: this.state.deposit_account_bank,
      deposit_ach: this.state.deposit_ach,
      operating_account_number: this.state.operating_account_number,
      operating_account_bank: this.state.operating_account_bank,
      operating_ach: this.state.operating_ach,
      month_id: this.state.month_id,
    };

    if (this.state.property_slug !== undefined) {
      let res = await PropertyService.updateProperty(
        this.state.property_slug,
        inputData1
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.changeView();
      } else {
        // let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.message ? res.data.message : "Error!",
        //   alertModalMessage:
        //     alertMessage !== "" ? alertMessage : res.data.message,
        // });
      }
    }

    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  handleChangeState = (event, index) => {
    const values = [...this.state.property_legal_entity_data];
    const state_value = event !== null ? event.value : "";
    values[index].state_slug = state_value;
    this.setState({ property_legal_entity_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChangeType = (event, index) => {
    const values = [...this.state.property_legal_entity_data];
    const type_value = event !== null ? event.value : "";
    values[index].entity_type = type_value;
    this.setState({ property_legal_entity_data: values });
    this.setState({ confirm_back: 1 });
  };

  legalChangeHandler = (event, index) => {
    let input = this.state.input;
    let value =
      event.target.type === "radio"
        ? parseInt(event.target.value)
        : event.target.value;

    let property_legal_entity_data1 = [
      ...this.state.property_legal_entity_data,
    ];
    property_legal_entity_data1[index][event.target.name] = value;
    this.setState({ property_legal_entity_data: property_legal_entity_data1 });

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    this.setState({ confirm_back: 1 });
    // var confirmBack = 1;
    // this.props.confirmBackStatus(confirmBack);
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
    // var confirmBack = 1;
    // this.props.confirmBackStatus(confirmBack);
  };

  handleChangeMonthList(value) {
    let month_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        month_id.push(item.value);
      });
    }
    const month_value = value !== null ? value.value : [];
    this.setState({
      selectedMonthList: value,
      month_id: month_value,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    // var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
      this.getPropertyLegalEntityData();
      this.getBankingData();
    } else {
      this.setState({ viewMode: 1 });
      this.getPropertyLegalEntityData();
      this.getBankingData();

      this.getStateData();
      this.getFiscalMonthData();
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
    }
  }

  async getStateData(countryValue, countyValue, queryString = "") {
    //this.setState({ showSpinner: true, loading: true });
    var stateList = [];

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
      countryValue +
      "&county_slug=" +
      countyValue +
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    const newStateList = stateList.map(({ slug, state_name }) => ({
      value: slug,
      label: state_name,
    }));
    // this.setState({ stateList: stateList });
    this.setState({ newStateList: newStateList });
    // this.setState({ showSpinner: false, loading: false });
  }

  getTitle() {
    if (this.state.viewMode === 0) return "Legal & Finance";
    else return "Legal & Finance";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  {/* <PropertyViewSidebar /> */}
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>

                    <div className="row d-flex justify-content-end mr-0">
                      {/* <Link to={'/properties/update/'+this.state.property_slug+"/property-office-hours"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
                      {this.state.viewMode === 0 ? ( <div>
                      {global.userPermissions.checkPermission('legal-finance-update') ?
                        <button
                          className="btn-success-outline-small"
                          onClick={this.changeView}
                        >
                          <img src={editIcon} alt="edit" />
                          Edit
                        </button>
                       : ''} </div>
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={() => {
                              if (this.state.confirm_back === 1)
                                if (
                                  window.confirm(
                                    "Do you want to exit without saving?"
                                  )
                                )
                                  this.changeView();
                                else return false;
                              else this.changeView();
                            }}
                          >
                            Cancel
                          </button>
                          {global.userPermissions.checkPermission('legal-finance-update') ?
                          <button
                            className="btn-success ml-3"
                            onClick={this.saveOrUpdatePropertyLegalEntityData}
                          >
                            Save
                          </button>
                          : ''}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    {this.state.viewMode === 0 ? (
                      <div className="m-0 listing_detail">
                        {/* fiscal mnth data */}
                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30pd-xs-l-15 pd-xs-r-0 pd-lg-t-0">
                          <h3 className="mg-l-0 background-head">
                            Banking Details
                          </h3>
                        </div>
                        <div className="row pd-l-15 pd-r-15 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Fiscal Year Start Month
                            </p>
                            <p className="media-body">
                              {this.state.month_name
                                ? this.state.month_name
                                : ""}
                            </p>
                          </div>

                          {/* <hr className="mg-t-10 pd-t-2 mg-b-30 pd-b-0 pd-l-15 pd-r-15"/> */}
                          {/* banking details data */}

                            <div className="row pd-l-15 col-12 align-items-center p-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Deposit Account Number
                                </p>
                                <p className="media-body">
                                  {this.state.deposit_account_number
                                    ? this.state.deposit_account_number
                                    : ""}
                                </p>
                              </div>

                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Deposit Account Bank
                                </p>
                                <p className="media-body">
                                  {this.state.deposit_account_bank
                                    ? this.state.deposit_account_bank
                                    : ""}
                                </p>
                              </div>

                              {/* <div className="col-md-4">
                                    <p className="az-profile-name-text pl-0">Deposit ACH</p>
                                    <p className="media-body">
                                      {this.state.deposit_ach ? this.state.deposit_ach : ""}
                                    </p>
                                  </div> */}
                            </div>

                            <div className="row pd-l-15 col-12 align-items-center p-0 border-bottom-0">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Operating Account Number
                                </p>
                                <p className="media-body">
                                  {this.state.operating_account_number
                                    ? this.state.operating_account_number
                                    : ""}
                                </p>
                              </div>

                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Operating Account Bank
                                </p>
                                <p className="media-body">
                                  {this.state.operating_account_bank
                                    ? this.state.operating_account_bank
                                    : ""}
                                </p>
                              </div>

                              {/* <div className="col-md-4">
                                    <p className="az-profile-name-text pl-0">Operating ACH</p>
                                    <p className="media-body">
                                      {this.state.operating_ach ? this.state.operating_ach : ""}
                                    </p>
                                  </div> */}
                            </div>
                        </div>


                        {this.state.property_legal_entity_data_total > 0
                          ? this.state.property_legal_entity_data.map(
                            (item, i) => {
                              if(this.state.ownership === '1'){
                                if(item.legel_entity_name === "Property Entity"){
                                return (
                                    <React.Fragment key={i}>
                                      <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30pd-xs-l-15 pd-xs-r-0">
                                        <h3 className="mg-l-0 background-head">
                                          {item.legel_entity_name}
                                        </h3>
                                      </div>

                                      <div className="row pd-l-15 pd-r-15 border-bottom-0">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15  pd-xs-l-0">
                                          <p className="az-profile-name-text pl-0">
                                            Name
                                          </p>
                                          <p className="media-body">
                                            {item.name ? item.name : ""}
                                          </p>
                                        </div>

                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15  pd-xs-l-0">
                                          <p className="az-profile-name-text pl-0">
                                            Tax ID
                                          </p>
                                          <p className="media-body">
                                            {item.tax_id ? item.tax_id : ""}
                                          </p>
                                        </div>

                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15  pd-xs-l-0">
                                          <p className="az-profile-name-text pl-0">
                                            Type
                                          </p>
                                          <p className="media-body">
                                            {item.entity_type
                                              ? item.entity_type === 1
                                                ? "LLC"
                                                : "INC"
                                              : ""}
                                          </p>
                                        </div>

                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15  pd-xs-l-0">
                                          <p className="az-profile-name-text pl-0">
                                            State
                                          </p>
                                          <p className="media-body">
                                            {item.state_name
                                              ? item.state_name
                                              : ""}
                                          </p>
                                        </div>
                                      </div>

                                    </React.Fragment>
                                );
                              }
                              }else{
                                return (
                                  <React.Fragment key={i}>
                                    <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30pd-xs-l-15 pd-xs-r-0">
                                      <h3 className="mg-l-0 background-head">
                                        {item.legel_entity_name}
                                      </h3>
                                    </div>

                                    <div className="row pd-l-15 pd-r-15 border-bottom-0">
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15  pd-xs-l-0">
                                        <p className="az-profile-name-text pl-0">
                                          Name
                                        </p>
                                        <p className="media-body">
                                          {item.name ? item.name : ""}
                                        </p>
                                      </div>

                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15  pd-xs-l-0">
                                        <p className="az-profile-name-text pl-0">
                                          Tax ID
                                        </p>
                                        <p className="media-body">
                                          {item.tax_id ? item.tax_id : ""}
                                        </p>
                                      </div>

                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15  pd-xs-l-0">
                                        <p className="az-profile-name-text pl-0">
                                          Type
                                        </p>
                                        <p className="media-body">
                                          {item.entity_type
                                            ? item.entity_type === 1
                                              ? "LLC"
                                              : "INC"
                                            : ""}
                                        </p>
                                      </div>

                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15  pd-xs-l-0">
                                        <p className="az-profile-name-text pl-0">
                                          State
                                        </p>
                                        <p className="media-body">
                                          {item.state_name
                                            ? item.state_name
                                            : ""}
                                        </p>
                                      </div>
                                    </div>

                                  </React.Fragment>
                                );
                              }
                            }
                          )
                        : ""}
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.viewMode === 1 ? (
                      <div className="m-0">
                        <div className="col-lg-12 pd-l-30 pd-r-30">
                          <h3 className="mg-l-0 background-head">
                            Banking Details
                          </h3>
                        </div>
                        <div className="row pd-l-15 pd-r-15 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Fiscal Year Start Month{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Select
                              styles={customStyles}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              isClearable={true}
                              value={this.state.selectedMonthList}
                              options={this.state.newMonthList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeMonthList(value)
                              }
                              defaultValue={this.state.selectedMonthList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                            {this.state.errors.month_id ? (
                              <div className="text-danger">
                                {this.state.errors.month_id}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* two column layout start */}
                        <div className="row pd-l-15 pd-r-15 align-items-center pd-t-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Deposit Account Number{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              type="text"
                              onChange={(e) => this.changeHandler(e)}
                              name="deposit_account_number"
                              value={this.state.deposit_account_number}
                              maxLength="50"
                              onKeyPress={
                                global.onKeyPressEvent.numberValidation
                              }
                            />
                            {this.state.errors.deposit_account_number ? (
                              <div className="text-danger">
                                {this.state.errors.deposit_account_number}
                              </div>
                            ) : (
                              this.validator.message(
                                "deposit_account_number",
                                this.state.deposit_account_number,
                                "max:15",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Deposit Account Bank{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              type="text"
                              onChange={(e) => this.changeHandler(e)}
                              name="deposit_account_bank"
                              value={this.state.deposit_account_bank}
                              maxLength="50"
                            />
                            {this.state.errors.deposit_account_bank ? (
                              <div className="text-danger">
                                {this.state.errors.deposit_account_bank}
                              </div>
                            ) : (
                              this.validator.message(
                                "deposit_account_bank",
                                this.state.deposit_account_bank,
                                "max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">Deposit ACH <span className="optional">(Optional)</span></label>
                        <Form.Control
                          type="text"
                          onChange={(e)=>this.changeHandler(e)}
                          name="deposit_ach"
                          value={this.state.deposit_ach}
                          maxLength="50"
                          />
                          {this.state.errors.deposit_ach ? (
                          <div className="text-danger">
                              {this.state.errors.deposit_ach}
                          </div>
                          ) : (
                          this.validator.message(
                              "deposit_ach",
                              this.state.deposit_ach,
                              "max:50",
                              { className: "text-danger" }
                          )
                          )}
                        </div> */}
                        </div>

                        <div className="row pd-l-15 pd-r-15 align-items-center pd-lg-b-30 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Operating Account Number{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              type="text"
                              onChange={(e) => this.changeHandler(e)}
                              name="operating_account_number"
                              value={this.state.operating_account_number}
                              maxLength="50"
                              onKeyPress={
                                global.onKeyPressEvent.numberValidation
                              }
                            />
                            {this.state.errors.operating_account_number ? (
                              <div className="text-danger">
                                {this.state.errors.operating_account_number}
                              </div>
                            ) : (
                              this.validator.message(
                                "operating_account_number",
                                this.state.operating_account_number,
                                "max:15",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Operating Account Bank{" "}
                              <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              type="text"
                              onChange={(e) => this.changeHandler(e)}
                              name="operating_account_bank"
                              value={this.state.operating_account_bank}
                              maxLength="50"
                            />
                            {this.state.errors.operating_account_bank ? (
                              <div className="text-danger">
                                {this.state.errors.operating_account_bank}
                              </div>
                            ) : (
                              this.validator.message(
                                "operating_account_bank",
                                this.state.operating_account_bank,
                                "max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">Operating ACH <span className="optional">(Optional)</span></label>
                          <Form.Control
                          type="text"
                          onChange={(e)=>this.changeHandler(e)}
                          name="operating_ach"
                          value={this.state.operating_ach}
                          maxLength="50"
                          />
                          {this.state.errors.operating_ach ? (
                          <div className="text-danger">
                              {this.state.errors.operating_ach}
                          </div>
                          ) : (
                          this.validator.message(
                              "operating_ach",
                              this.state.operating_ach,
                              "max:50",
                              { className: "text-danger" }
                          )
                          )}
                        </div> */}
                        </div>

                        {/* two column layout end */}

                        {this.state.property_legal_entity_data.map(
                          (item, index) => {
                            if(this.state.ownership === '1'){
                              if(item.legel_entity_name === "Property Entity"){
                                return(
                                  <React.Fragment key={index}>
                                  <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30pd-xs-l-15 pd-xs-r-0">
                                    <h3 className="mg-l-0 background-head">
                                      {item.legel_entity_name}
                                    </h3>
                                  </div>
                                  <div
                                    className="row  pd-l-15 pd-r-15 align-items-center pd-lg-b-30 border-bottom-0"
                                    key={index}
                                  >
                                    <input
                                      type="hidden"
                                      name="legel_entity"
                                      value={item.slug}
                                    />
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Name {" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Form.Control
                                        onChange={(e) =>
                                          this.legalChangeHandler(e, index)
                                        }
                                        name="name"
                                        value={item.name}
                                        className="form-control max_width_100"
                                        type="text"
                                        maxLength="50"
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Tax ID{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Form.Control
                                        onChange={(e) =>
                                          this.legalChangeHandler(e, index)
                                        }
                                        name="tax_id"
                                        value={item.tax_id}
                                        className="form-control max_width_100"
                                        type="text"
                                        maxLength="50"
                                      // onKeyPress={global.onKeyPressEvent.numSpaceDashValidation}
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Type{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        classNamePrefix={"my-custom-react-select"}
                                        menuPlacement="auto"
                                        isClearable={true}
                                        // value={item.entity_type}
                                        options={this.state.entityTypeList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(e) =>
                                          this.handleChangeType(e, index)
                                        }
                                        defaultValue={item.selected_entity_type}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        State{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>
                                      <Select
                                        styles={customStyles}
                                        className="multiselect"
                                        classNamePrefix={"my-custom-react-select"}
                                        menuPlacement="auto"
                                        isClearable={true}
                                        // value={item.selectedStateList}
                                        options={this.state.newStateList}
                                        getOptionValue={(option) =>
                                          `${option.label}`
                                        }
                                        onChange={(e) =>
                                          this.handleChangeState(e, index)
                                        }
                                        defaultValue={item.selected_state}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 2,
                                          colors: {
                                            ...theme.colors,
                                            primary: "#fff",
                                            primary75: "#000",
                                            primary50: "#000",
                                            primary25: "#000",
                                          },
                                        })}
                                      />
                                    </div>
                                  </div>
                                </React.Fragment>
                                );
                              }

                            }else{
                              return(
                                <React.Fragment key={index}>
                                <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30pd-xs-l-15 pd-xs-r-0">
                                  <h3 className="mg-l-0 background-head">
                                    {item.legel_entity_name}
                                  </h3>
                                </div>
                                <div
                                  className="row  pd-l-15 pd-r-15 align-items-center pd-lg-b-30 border-bottom-0"
                                  key={index}
                                >
                                  <input
                                    type="hidden"
                                    name="legel_entity"
                                    value={item.slug}
                                  />
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Name {" "}
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <Form.Control
                                      onChange={(e) =>
                                        this.legalChangeHandler(e, index)
                                      }
                                      name="name"
                                      value={item.name}
                                      className="form-control max_width_100"
                                      type="text"
                                      maxLength="50"
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Tax ID{" "}
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <Form.Control
                                      onChange={(e) =>
                                        this.legalChangeHandler(e, index)
                                      }
                                      name="tax_id"
                                      value={item.tax_id}
                                      className="form-control max_width_100"
                                      type="text"
                                      maxLength="50"
                                    // onKeyPress={global.onKeyPressEvent.numSpaceDashValidation}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Type{" "}
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <Select
                                      styles={customStyles}
                                      className="multiselect"
                                      classNamePrefix={"my-custom-react-select"}
                                      menuPlacement="auto"
                                      isClearable={true}
                                      // value={item.entity_type}
                                      options={this.state.entityTypeList}
                                      getOptionValue={(option) =>
                                        `${option.label}`
                                      }
                                      onChange={(e) =>
                                        this.handleChangeType(e, index)
                                      }
                                      defaultValue={item.selected_entity_type}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-xs-l-0 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      State{" "}
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <Select
                                      styles={customStyles}
                                      className="multiselect"
                                      classNamePrefix={"my-custom-react-select"}
                                      menuPlacement="auto"
                                      isClearable={true}
                                      // value={item.selectedStateList}
                                      options={this.state.newStateList}
                                      getOptionValue={(option) =>
                                        `${option.label}`
                                      }
                                      onChange={(e) =>
                                        this.handleChangeState(e, index)
                                      }
                                      defaultValue={item.selected_state}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                              );
                            }
                          }
                          )}


                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default PropertyLegalEntity;
////////////////////////////////////////////////////////////
//     							                                      //
//  Program: CrmListView.jsx                              //
//  Application: CRM                                      //
//  Option: to view ListMember Setup data                 //
//  Developer: Raj Kumar             		                  //
//  Date: 2023-04-05                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import CrmCampaignService from "../../services/CrmCampaignService";
import { Container, Modal } from "react-bootstrap";
import CrmSettingSidebar from "../common/CrmSettingSidebar";
import ContactSidebar from "../common/ContactSidebar";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import Moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import BrandSidebar from "../common/BrandSidebar";
import ClientSidebar from "../common/ClientSidebar";
export class CrmCampaignReportView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const pathStr = props.match.path;
    const pathArray = pathStr.split(":");
    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      showSpinner: false,
      toggleSwitchDark2: true,
      status_id: true,
      slug: this.props.match.params.crmSlug ?? "",
      tableQueryString: "",
      deleteMemberModal: false,
      lists: [],
      records: [],
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : "",
      brand_slug:
        pathArray[0] === "/brand/view/" ? this.props.match.params.slug : "",
      client_slug:
        pathArray[0] === "/client/view/" ? this.props.match.params.slug : "",
      config: {
        sort: {
          column: "",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "first_name",
          text: "First Name",
          className: "first_name",
          sortable: true,
          cell: (record) => {
            return record.first_name ? record.first_name : "";
          },
        },
        {
          key: "last_name",
          text: "Last Name",
          className: "last_name",
          sortable: true,
          cell: (record) => {
            return record.last_name ? record.last_name : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email",
          sortable: true,
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id text-left",
          sortable: true,
          cell: (record) => {
            return record.status_id === 1
              ? "Not Send"
              : record.status_id === 2
              ? "Running"
              : record.status_id === 3
              ? "Delivered"
              : record.status_id === 4
              ? "Reject"
              : record.status_id === 5
              ? "Failed"
              : "Failed";
          },
        },
        {
          key: "open_at",
          text: "Open at",
          className: "open_at text-left date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.openAtTime
              ? Moment(Moment.utc(record.openAtTime, "YYYY-MM-DDTHH:mm:ss"))
                  .local()
                  .format(global.dateTimeFormat)
              : "";
          },
        },
        {
          key: "link_click_at",
          text: "Link Clicked at",
          className: "link_click_at text-left date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.link_click_at
              ? Moment(Moment.utc(record.link_click_at, "YYYY-MM-DDTHH:mm:ss"))
                  .local()
                  .format(global.dateTimeFormat)
              : "";
          },
        },
        {
          key: "homesharing_inquiry",
          text: "Inquiry",
          className: "homesharing_inquiry text-left",
          sortable: true,
          cell: (record) => {
            return record.homesharing_inquiry === 2 ? "Submited" : "";
          },
        },
        {
          key: "marked_as",
          text: "Complaint",
          className: "marked_as text-left",
          sortable: true,
          cell: (record) => {
            return record.marked_as === 2 ? "Spam" : "";
          },
        },
      ],
    };

    /* for member */
    this.getCampaignReport = this.getCampaignReport.bind(this);
    this.openDeleteNearByAttractionModal =
      this.openDeleteNearByAttractionModal.bind(this);
    this.closeDeleteCampaignModal = this.closeDeleteCampaignModal.bind(this);
  }

  componentDidMount() {
    this.getCampaignReport();
  }
  /* to get member  data */
  async getCampaignReport(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await CrmCampaignService.getCampaignReportBySlug(
      this.props.match.params.crmSlug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      resData.slug = res.data.slug ? res.data.slug : "";
      resData.campaign_name = res.data.campaign_name
        ? res.data.campaign_name
        : "";
      resData.campaign_status =
        res.data.campaign_status === 1
          ? "Scheduled"
          : res.data.campaign_status === 2
          ? "Draft"
          : res.data.campaign_status === 3
          ? "Data Preparation"
          : res.data.campaign_status === 4
          ? "Schedule Locked"
          : res.data.campaign_status === 5 &&
            res.data.campaign_start_stop === null
          ? "Running"
          : res.data.campaign_status === 5 &&
            res.data.campaign_start_stop !== null
          ? "Stoped"
          : res.data.campaign_status === 6
          ? "Delivered"
          : res.data.campaign_status === 7
          ? "Failed"
          : "Failed";
      resData.campaignStatus = res.data.campaign_status
        ? res.data.campaign_status
        : 0;
      resData.optOut = res.data.opt_out === 2 ? "Unsubscribed" : "";
      resData.email_from = res.data.email_from ? res.data.email_from : "";
      resData.from_name = res.data.from_name ? res.data.from_name : "";
      resData.subject = res.data.subject ? res.data.subject : "";
      resData.total_recipient = res.data.total_recipient
        ? res.data.total_recipient
        : 0;
      resData.totalListDataCount = res.data.totalListDataCount
        ? res.data.totalListDataCount
        : 0;
      resData.recipientSent = res.data.recipientSent
        ? res.data.recipientSent
        : 0;
      resData.recipientQueue = res.data.recipientQueue
        ? res.data.recipientQueue
        : 0;
      resData.recipientFailed = res.data.recipientFailed
        ? res.data.recipientFailed
        : 0;
      resData.totalOpen = res.data.totalOpen ? res.data.totalOpen : 0;
      resData.openRate = res.data.openRate ? res.data.openRate : 0;
      resData.successRate = res.data.successDeliveryRate
        ? res.data.successDeliveryRate
        : 0;
      resData.failedRate = res.data.failedRate ? res.data.failedRate : 0;
      resData.totalSpam = res.data.totalSpam ? res.data.totalSpam : 0;
      resData.spamRate = res.data.spamRate ? res.data.spamRate : 0;
      resData.totalClicked = res.data.totalClicked ? res.data.totalClicked : 0;
      resData.totalInquiry = res.data.totalInquiry ? res.data.totalInquiry : 0;
      resData.clickedRate = res.data.clickedRate ? res.data.clickedRate : 0;
      resData.inquiryRate = res.data.inquiryRate ? res.data.inquiryRate : 0;
      resData.endDate = res.data.endDate
        ? Moment(res.data.endDate).format(global.dateFormat)
        : "";
      resData.endTime = res.data.endTime ? res.data.endTime : "";
      resData.startDate = res.data.startDate
        ? Moment(res.data.startDate).format(global.dateFormat)
        : "";
      resData.startTime = res.data.startTime ? res.data.startTime : "";
      resData.unsbscribeMails = res.data.unsbscribeMails
        ? res.data.unsbscribeMails
        : 0;
      resData.createdDate = res.data.createdDate
        ? Moment(res.data.createdDate).format(global.dateFormat)
        : "";
      resData.createdTime = res.data.createdTime ? res.data.createdTime : "";
      resData.subject = res.data.subject ? res.data.subject : "";
      resData.template_name = res.data.template_name
        ? res.data.template_name
        : "";
      resData.template_slug = res.data.templateSlug
        ? res.data.templateSlug
        : "";
      resData.lists = res.data.lists ? res.data.lists : [];

      resData.recipientList = res.data.recipientsList
        ? res.data.recipientsList.data
        : [];
      var recipientListData = res.data.recipientsList
        ? res.data.recipientsList.data
        : [];
      resData.recipientListTotal = res.data.recipientsList
        ? res.data.recipientsList.total
        : 0;
      resData.brands = res.data.brands ? res.data.brands : "";
      resData.client = res.data.client ? res.data.client : "";
    }
    this.setState(resData);
    this.setState({
      records: recipientListData,
      showSpinner: false,
      loading: false,
    });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getCampaignReport(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getCampaignReport(queryString);
    }
  };
  getTitle() {
    return "Campaign Report";
  }

  openDeleteNearByAttractionModal(slug) {
    this.setState({ deleteNearByAttractionModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteCampaignModal() {
    this.setState({ deleteNearByAttractionModal: false });
    this.setState({ slug: "" });
  }

  async deleteCampaign(slug) {
    this.setState({ showSpinner: true });
    let res = await CrmCampaignService.deleteCrmCampaign(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteCampaignModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      if (this.state.property_slug) {
        setTimeout(
          () =>
            this.props.history.push(
              this.state.property_slug
                ? "/properties/view/" +
                    this.state.property_slug +
                    "/crm-campaign"
                : this.state.brand_slug
                ? "/brand/view/" + this.state.brand_slug + "/crm-campaign"
                : this.state.client_slug
                ? "/client/view/" + this.state.client_slug + "/crm-campaign"
                : "/crm-campaign"
            ),
          global.redirect_time
        );
      } else {
        setTimeout(
          () => this.props.history.push("/crm-campaign"),
          global.redirect_time
        );
      }
    } else {
      this.closeDeleteCampaignModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  render() {
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      {this.state.property_slug ? (
                        <ContactSidebar
                          property_slug={this.state.property_slug}
                        />
                      ) : this.state.brand_slug ? (
                        <BrandSidebar brand_slug={this.state.brand_slug} />
                      ) : this.state.client_slug ? (
                        <ClientSidebar clientSlug={this.state.client_slug} />
                      ) : (
                        <CrmSettingSidebar />
                      )}
                    </aside>
                  </div>
                </div>

                <div className="col-md-10 right">
                  <div className="scrolling-carousel">
                    <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                      <div className="row row-xs">
                        <div className="col-md-12 mg-t-10 mg-b-10"></div>
                        <div className="col-md-12">
                          <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                            <h3 className="brnd_heading mb-0">Campaign</h3>
                            <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                              {this.props.match.params.crmSlug !== undefined ? (
                                <>
                                  {global.userPermissions.checkPermission(
                                    "crm-campaigns-delete"
                                  ) && (
                                    <span
                                      onClick={() => {
                                        this.openDeleteNearByAttractionModal(
                                          this.props.match.params.crmSlug
                                        );
                                      }}
                                      className="deleteicon mg-r-20"
                                    >
                                      <img src={deleteicon} alt="" />
                                    </span>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              <Link
                                to={
                                  this.state.property_slug
                                    ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/crm-campaign"
                                    : this.state.brand_slug
                                    ? "/brand/view/" +
                                      this.state.brand_slug +
                                      "/crm-campaign"
                                    : this.state.client_slug
                                    ? "/client/view/" +
                                      this.state.client_slug +
                                      "/crm-campaign"
                                    : "/crm-campaign"
                                }
                              >
                                <button className="btn-success-outline mg-r-20">
                                  Cancel
                                </button>
                              </Link>
                              {global.userPermissions.checkPermission(
                                "crm-campaigns-update"
                              ) &&
                                (this.state.campaignStatus === 1 ||
                                this.state.campaignStatus === 2 ? (
                                  <Link
                                    to={
                                      this.state.property_slug
                                        ? "/properties/view/" +
                                          this.state.property_slug +
                                          "/crm-campaign/update/" +
                                          this.props.match.params.crmSlug
                                        : this.state.brand_slug
                                        ? "/brand/view/" +
                                          this.state.brand_slug +
                                          "/crm-campaign/update/" +
                                          this.props.match.params.crmSlug
                                        : this.state.client_slug
                                        ? "/client/view/" +
                                          this.state.client_slug +
                                          "/crm-campaign/update/" +
                                          this.props.match.params.crmSlug
                                        : "/crm-campaign/update/" +
                                          this.props.match.params.crmSlug
                                    }
                                    className=""
                                  >
                                    <button className="btn-success-outline-small ml-3">
                                      <img src={editIcon} alt="Edit" />
                                      Edit
                                    </button>
                                  </Link>
                                ) : (
                                  ""
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Container className="p-0 container">
                      <div className="pd-lg-l-15 pd-xs-l-15  tbl_lst_th_left">
                        <div className="row align-items-center col-md-12 mg-t-30 mg-b-0 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-xs-l-15 ">
                          {this.state.campaign_name ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Campaign{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.campaign_name}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.startDate ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Scheduled On{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.startDate} | {this.state.startTime}{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.endDate ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Scheduled End{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.endDate} | {this.state.endTime}{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.campaign_status ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Status{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.campaign_status}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.createdDate ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Created At{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.createdDate} |{" "}
                                {this.state.createdTime}{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.email_from ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Sender Mail
                              </p>
                              <p className="media-body campng_text">
                                {this.state.email_from}{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.from_name ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Sender Name
                              </p>
                              <p className="media-body campng_text">
                                {this.state.from_name}{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.total_recipient ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Total Recipients{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.total_recipient}
                              </p>
                            </div>
                          ) : this.state.totalListDataCount ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Total Recipients{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.totalListDataCount}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.recipientSent ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Total Sent{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.recipientSent}{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.recipientFailed ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Total Failed{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.recipientFailed}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.totalSpam ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Total Spam{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.totalSpam}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.totalOpen ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Total Open{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.totalOpen}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.totalClicked ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Total Link Clicked{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.totalClicked}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.totalInquiry ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Inquiry Submited{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.totalInquiry}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.campaign_status === 6 ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                In Queue{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.recipientQueue}{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.unsbscribeMails ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Unsubscribed{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.unsbscribeMails}{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.successRate ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Success Rate{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.successRate}
                                {"%"}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.failedRate ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Failed Rate{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.failedRate}
                                {"%"}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.openRate ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Open Rate{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.openRate}
                                {"%"}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.spamRate ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Spam Rate{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.spamRate}
                                {"%"}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.clickedRate ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Link Click Rate{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.clickedRate}
                                {"%"}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.inquiryRate ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Inquiry Rate{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.inquiryRate}
                                {"%"}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.client ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Client{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.client}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.brands ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Brands{" "}
                              </p>
                              <p className="media-body campng_text">
                                {this.state.brands}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.template_name ? (
                            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                              <p className="az-profile-name-text pl-0">
                                Template{" "}
                              </p>
                              <p className="media-body campng_text">
                                <a
                                  href={
                                    this.state.property_slug
                                      ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/crm-template/update/" +
                                        this.state.template_slug
                                      : this.state.brand_slug
                                      ? "/brand/view/" +
                                        this.state.brand_slug +
                                        "/crm-template/update/" +
                                        this.state.template_slug
                                      : this.state.client_slug
                                      ? "/client/view/" +
                                        this.state.client_slug +
                                        "/crm-template/update/" +
                                        this.state.template_slug
                                      : "/crm-template/update/" +
                                        this.state.template_slug
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {this.state.template_name}
                                </a>{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.lists.length > 0 ? (
                            <>
                              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mg-b-15">
                                <p className="az-profile-name-text pl-0">
                                  Lists{" "}
                                </p>
                                <p className="media-body campng_text">
                                  {this.state.lists.map((item, i) => {
                                    return (
                                      <span
                                        className="media-body campng_text"
                                        key={i}
                                      >
                                        <a
                                          href={
                                            this.state.property_slug
                                              ? "/properties/view/" +
                                                this.state.property_slug +
                                                "/crm-list/view/" +
                                                item.slug
                                              : this.state.brand_slug
                                              ? "/brand/view/" +
                                                this.state.brand_slug +
                                                "/crm-list/view/" +
                                                item.slug
                                              : this.state.client_slug
                                              ? "/client/view/" +
                                                this.state.client_slug +
                                                "/crm-list/view/" +
                                                item.slug
                                              : "/crm-list/view/" + item.slug
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {item.list_name}{" "}
                                          <span className="CRMpreviewListCount">
                                            ({item.listCount})
                                          </span>
                                          {i === this.state.lists.length - 1
                                            ? ""
                                            : " | "}
                                        </a>
                                      </span>
                                    );
                                  })}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {this.state.recipientListTotal > 0 ? (
                          <>
                            <div className="col-lg-12 pd-xs-l-15 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-t-15">
                              <h3 className="mg-l-5  background-head d-flex  align-items-center justify-content-start mb-0">
                                Mail List{" "}
                              </h3>
                            </div>
                            <div className="mg-t-18 brandList">
                              <div className="pd-lg-l-0 pd-xs-l-15">
                                <ReactDatatable
                                  className="table no-wrap mg-b-30 pd-b-2 brandtable text-align-left"
                                  config={
                                    this.state.recipientListTotal >
                                    global.page_size
                                      ? this.state.config
                                      : this.state.config
                                  }
                                  records={this.state.records}
                                  columns={this.state.columns}
                                  extraButtons={this.state.extraButtons}
                                  dynamic={true}
                                  total_record={this.state.recipientListTotal}
                                  onChange={this.tableChangeHandler}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Modal show={this.state.deleteNearByAttractionModal} centered>
            <Modal.Body className="width_402 p-4 border_radius_14">
              <div className="text-center">
                <img src={modaldeleteicon} alt="modal-delete-icon" />
                <h3>Delete the Option?</h3>
                <p>
                  Do you really want to delete this option? This process cannot
                  be undone.
                </p>
                <div className="d-flex justify-content-center mt-3 row">
                  <button
                    type="button"
                    className="m-0 btn btn-outline-light btn-block"
                    onClick={() => this.closeDeleteCampaignModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="m-0 btn btn btn-danger btn-block"
                    onClick={() =>
                      this.deleteCampaign(this.props.match.params.crmSlug)
                    }
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Delete"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </main>
      </>
    );
  }
}

export default CrmCampaignReportView;

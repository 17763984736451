//     							                          //
//  Program: MaintenanceSidebar.jsx                       //
//  Application: Property                                 //
//  Option: common component House Keeping Sidebar        //
//  Developer: NP 		                                    //
//  Date: 2022-04-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';

class MaintenanceSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug") ? sessionStorage.getItem("property_slug") : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to Housekeeping
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <span>Maintenance</span> 
                  <ul>
                    <li>
                      <Link
                        className={
                          this.isPathActive("maintenance-calendar")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/maintenance-calendar"}
                      >
                        Calendar
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          this.isPathActive("mros")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/mros"}
                      >
                        Maintenance Requests
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className={
                          this.isPathActive("maintenance-checklists")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/maintenance-checklists"}
                      >
                        Checklists
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          this.isPathActive("maintenance-inbox")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/maintenance-inbox"}
                      >
                        Inbox
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <span>Vendors</span> 
                  <ul>
                     <li>
                      <Link
                        className={
                          this.isPathActive("work-order")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/work-order"}
                      >
                        Work Orders
                      </Link>
                    </li>
                     <li>
                      <Link
                        className={
                          this.isPathActive("housekeeping-vendoor-list")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/housekeeping-vendoor-list"}
                      >
                        Vendors
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span>Sub Menu</span>
                <i className="fas fa-chevron-down"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="tx-13">
              <aside>
                <nav className="nav nav-pills flex-column pd-r-8">
                  <ul>
                    <li>
                      <span>Maintenance</span> 
                      <ul>
                        <li>
                          <Link
                            className={
                              this.isPathActive("maintenance-calendar")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/maintenance-calendar"}
                          >
                            Calendar
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.isPathActive("mros")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/mros"}
                          >
                            Maintenance Requests
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            className={
                              this.isPathActive("maintenance-checklists")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/maintenance-checklists"}
                          >
                            Checklists
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.isPathActive("maintenance-inbox")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/maintenance-inbox"}
                          >
                            Inbox
                          </Link>
                        </li> */}
                      </ul>
                    </li>
                    <li>
                      <span>Vendors</span> 
                      <ul>
                        <li>
                          <Link
                            className={
                              this.isPathActive("work-order")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/work-order"}
                          >
                            Work Orders
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.isPathActive("housekeeping-vendoor-list")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/housekeeping-vendoor-list"}
                          >
                            Vendors
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </aside>
              </Dropdown.Menu>
            </Dropdown>
          </div>

        </div>
      </>
    );
  }
}
export default MaintenanceSidebar;
//                                                           //
//  Program: RentalLookups.jsx                               //
//  Application: Rental United                               //
//  Option: Rental Lookup tables                             //
//  Developer: NP 		                                     //
//  Date: 2022-09-13                                         //
//                                                           //
/////////////////////////////////////////////////////////   ///

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import RUSidebar from "../common/RUSidebar";
import NoData from "../common/NoData";

class RentalLookups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: this.props.match.params.propertySlug
    };
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <RUSidebar />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="dashboardRightcard mg-t-15">
                  <NoData />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default RentalLookups
////////////////////////////////////////////////////////////
//     							                          //
//  Program: Vendor.jsx                                    //
//  Application: Vendor                                    //
//  Option: List all Vendor                                //
//  Developer: Ashish Kumar,NP 						                 //
//  Date: 2022-03-30                                       //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import pluswIcon from "./../../assets/images/plus.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import deleteicon from "../../assets/images/delete.svg";
import NoData from "../common/NoData";
import PropertyVendorService from "../../services/PropertyVendorService";
import Alerts from "../common/Alerts";
import ContactSidebar from "../common/ContactSidebar";
import PhoneNumberFormat from "../common/PhoneNumberFormat";

export class Vendor extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      vendorEventKey: "",
      is_inactive: false,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      pathname: window.location.pathname.includes("first"),
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,

      propertyAgencyList: [],
      propertyAgencyListTotal: 0,
      staffTypeList: [],
      defaultStaff: "",
      selectedStaffTypeList: [],
      staffTypeListTotal: 0,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      staffData: 0,
    };
    this.setOpen = this.setOpen.bind(this);
    this.setVendorOpen = this.setVendorOpen.bind(this);
    this.opendeleteVendorModal = this.opendeleteVendorModal.bind(this);
    this.closedeleteVendorModal = this.closedeleteVendorModal.bind(this);
    this.deleteVendor = this.deleteVendor.bind(this);
  }

  componentDidMount() {
    this.setState({ staffData: 0 });
    this.getAllVendorList();
  }

  async getAllVendorList() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.pathname === true) {
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/vendor"
          ),
        global.redirect_time
      );
    }
    var defaultStaff = "";
    var propertyAgencyList = [];
    var propertyAgencyListTotal = 0;

    let res = await PropertyVendorService.getOtherAgency(
      "property_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      propertyAgencyList = res.data
        ? res.data.data.sort((a, b) =>
            a.third_party_agency_name.localeCompare(b.third_party_agency_name)
          )
        : [];
      propertyAgencyListTotal = propertyAgencyList
        ? propertyAgencyList.length
        : 0;
      defaultStaff =
        propertyAgencyListTotal > 0 ? propertyAgencyList[0].slug : "";
    }
    const groupedByLetter = propertyAgencyList.reduce((groups, contact) => {
      const letter = contact.third_party_agency_name[0].toUpperCase();
      const group = groups[letter] || [];
      group.push(contact);
      groups[letter] = group;
      return groups;
    }, {});
    const groupedpropertyAgencyList = Object.entries(groupedByLetter);
    this.setState({
      propertyAgencyList: propertyAgencyList,
      propertyAgencyListTotal: propertyAgencyListTotal,
      defaultStaff: defaultStaff,
      groupedpropertyAgencyList: groupedpropertyAgencyList,
    });
    this.setState({ showSpinner: false, loading: false });

    if (propertyAgencyListTotal > 0) {
      if (this.state.slug !== undefined && this.state.slug !== "") {
        this.getOtherAgencyDetails(this.state.slug);
      } else {
        this.getOtherAgencyDetails(this.state.defaultStaff);
      }

      this.setState({ staffData: 1 });
    }
  }

  opendeleteVendorModal(slug) {
    this.setState({ deleteVendorModal: true, slug: slug });
  }

  closedeleteVendorModal() {
    this.setState({ deleteVendorModal: false, slug: "" });
  }

  async getOtherAgencyDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });

    if (slug) {
      var resData = {};
      let res = await PropertyVendorService.getAgencyBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  selectStaff = (i, slug) => {
    this.getOtherAgencyDetails(slug);
    this.setState({ staffData: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Contacts";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  setVendorOpen(e) {
    this.setState({ vendorEventKey: e.target.id });
  }

  async deleteVendor(slug) {
    this.setState({ showSpinner: true });
    let res = await PropertyVendorService.deleteOtherAgency(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteVendorModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.setState({ staffData: 0, slug: "" });
      this.getAllVendorList();
    } else {
      this.closedeleteVendorModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <ContactSidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {/* {this.state.staffData === 1 ? (
                        <>
                          {" "}
                          <span
                            onClick={() => {
                              this.opendeleteVendorModal(this.state.slug);
                            }}
                            className="deleteicon mg-r-20"
                          >
                            <img src={deleteicon} alt="Delete" />
                          </span>
                          <Link
                            to={
                              "/properties/view/" +
                              this.state.property_slug +
                              "/vendor/update/" +
                              this.state.slug
                            }
                            className="mg-l-2"
                          >
                            <button className="btn-success-outline">
                              <img src={editIcon} alt="Edit" />
                              Edit
                            </button>
                          </Link>
                        </>
                      ) : (
                        ""
                      )} */}
                      {global.userPermissions.checkPermission(
                        "vendors-add"
                      ) && (
                      <Link to={"vendor/add"} className="mg-l-2">
                        <button className="btn-success-outline-small ">
                          <img src={pluswIcon} alt="" /> Add
                        </button>
                      </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-45 pd-lg-r-30">
                  <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 p-10 mg-t-20">
                    {/* {this.state.propertyAgencyListTotal > 0 ? ( */}
                    <div>
                      <div className="container row d-flex app-contacts az-content-app az-content-contacts p-0">
                        <div className="az-content-left az-content-left-contacts pd-lg-l-0 pd-xs-l-15 side_top_navtab">
                          {/* <div className="d-flex align-items-center justify-content-between mg-b-30">
                              <h2 className="az-content-title tx-24 mg-b-30">
                                Vendor
                              </h2>
                              <Link
                                to={"vendor/add"}
                                className="w-100 d-flex justify-content-end pd-l-30 pd-r-30 pd-t-0 pd-b-0 mg-t-0 mg-b-0"
                              >
                                <button className="btn-success-outline-small ">
                                  <img src={pluswIcon} alt="" /> Add
                                </button>
                              </Link>
                            </div> */}

                          <nav className="nav az-nav-line az-nav-line-chat">
                            <Link
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                              data-toggle="tab"
                              className="nav-link "
                            >
                              Staff
                            </Link>

                            <Link
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/vendor"
                              }
                              data-toggle="tab"
                              className="nav-link active"
                            >
                              Vendor
                            </Link>
                            <Link
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/crm-resident"
                              }
                              data-toggle="tab"
                              className="nav-link"
                            >
                              Resident
                            </Link>
                          </nav>

                          <PerfectScrollbar
                            id="azContactList"
                            className="az-contacts-list h-auto"
                          >
                            {this.state.propertyAgencyListTotal > 0
                              ? this.state.groupedpropertyAgencyList
                                  .sort()
                                  .map((item, i) => {
                                    return (
                                      <React.Fragment key={i}>
                                        <div className="az-contact-label">
                                          {item[0]}
                                        </div>
                                        {item[1]
                                          .sort((a, b) =>
                                            a.third_party_agency_name.localeCompare(
                                              b.third_party_agency_name
                                            )
                                          )
                                          .map((items, index) => {
                                            return (
                                              <React.Fragment key={index}>
                                                <div
                                                  className={
                                                    this.state.slug ===
                                                    items.slug
                                                      ? "az-contact-item selected"
                                                      : "az-contact-item  az-contact-item_two"
                                                  }
                                                  onClick={() =>
                                                    this.selectStaff(
                                                      index,
                                                      items.slug
                                                    )
                                                  }
                                                >
                                                  <div className="az-contact-body">
                                                    <h6>
                                                      {items.third_party_agency_name
                                                        ? items.third_party_agency_name
                                                        : ""}
                                                    </h6>
                                                    <p className="tx-xthin mg-b-0">
                                                      {items.agency_category
                                                        ? items.agency_category
                                                            .agency_category_name
                                                        : ""}
                                                    </p>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          })}
                                      </React.Fragment>
                                    );
                                  })
                              : ""}
                          </PerfectScrollbar>
                          {/* az-contacts-list */}
                        </div>
                        {/* az-content-left */}
                        {this.state.staffData === 1 ? (
                          <div className="az-content-body az-content-body-contacts nav_tab_cnt ">
                            <div className="az-contact-info-header mg-t-50 border-bottom-0 pd-xs-l-15">
                              <div className="media">
                                <div className="media-body ">
                                  <h4>
                                    {this.state.third_party_agency_name
                                      ? this.state.third_party_agency_name
                                      : ""}
                                  </h4>
                                  <p>
                                    {this.state.agency_category
                                      ? this.state.agency_category
                                          .agency_category_name
                                      : ""}
                                  </p>
                                </div>
                                {/* media-body */}
                              </div>
                              {/* media */}
                              <div className="az-contact-action">
                                <div className=" d-flex flex-wrap justify-content-end bothBtn align-items-center">
                                
                                {global.userPermissions.checkPermission(
                                  "vendors-delete"
                                ) && (
                                <span
                                    onClick={() => {
                                      this.opendeleteVendorModal(
                                        this.state.slug
                                      );
                                    }}
                                    className="deleteicon mg-r-20 hand-cursor"
                                  >
                                    <img src={deleteicon} alt="Delete" />
                                  </span> )}

                                  {global.userPermissions.checkPermission(
                                    "vendors-update"
                                  ) && (
                                    <Link
                                      to={
                                        "/properties/view/" +
                                        this.state.property_slug +
                                        "/vendor/update/" +
                                        this.state.slug
                                      }
                                      className="mg-l-2"
                                    >
                                      <button className="btn-success-outline">
                                        <img src={editIcon} alt="Edit" />
                                        Edit
                                      </button>
                                    </Link>
                                  )}
                                </div>
                              </div>
                              {/* az-contact-action */}
                            </div>
                            {/* az-contact-info-header */}

                            <div className="az-contact-info-body vendor_tab_body">
                              <PerfectScrollbar className="media-list pt-0">
                                <div className="media">
                                  <div className="media-body ">
                                    <div>
                                      <label>Contact Person Name</label>
                                      <span className="tx-medium">
                                        {this.state.contact_name
                                          ? this.state.contact_name
                                          : ""}
                                      </span>
                                    </div>
                                    <div>
                                      <label>Email</label>
                                      <span className="tx-medium">
                                        {this.state.email
                                          ? this.state.email
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>

                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>Phone</label>
                                      <span className="tx-medium">
                                        {this.state.phone_number ? (
                                          <PhoneNumberFormat
                                            phone_number={
                                              this.state.phone_number
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </div>
                                    <div>
                                      <label>Street 1</label>
                                      <span className="tx-medium">
                                        {this.state.street_1
                                          ? this.state.street_1
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>

                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>Street 2</label>
                                      <span className="tx-medium">
                                        {this.state.street_2
                                          ? this.state.street_2
                                          : ""}
                                      </span>
                                    </div>
                                    <div>
                                      <label>City</label>
                                      <span className="tx-medium">
                                        {this.state.city
                                          ? this.state.city.city_name
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>

                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>State</label>
                                      <span className="tx-medium">
                                        {this.state.state
                                          ? this.state.state.state_name
                                          : ""}
                                      </span>
                                    </div>
                                    <div>
                                      <label>County</label>
                                      <span className="tx-medium">
                                        {this.state.county
                                          ? this.state.county.county_name
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>

                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>Country</label>
                                      <span className="tx-medium">
                                        {this.state.country
                                          ? this.state.country.country_name
                                          : ""}
                                      </span>
                                    </div>
                                    <div>
                                      <label>Notes</label>
                                      <span className="tx-medium">
                                        {this.state.notes
                                          ? this.state.notes
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>
                              </PerfectScrollbar>
                              {/* media-list */}
                            </div>

                            {/* az-contact-info-body */}
                          </div>
                        ) : (
                          <div className="m-auto">
                            <NoData msg={global.commonMessages.checkMessage("CRM_VENDOR")} />
                          </div>
                        )}
                        {/* az-content-body */}
                      </div>
                    </div>
                    {/* ) : (
                      <>
                        <Link
                          to={"vendor/add"}
                          className="w-100 d-flex justify-content-end pd-l-30 pd-r-30 pd-t-20 pd-b-20 mg-t-4 mg-b-4"
                        >
                          <button className="btn-success-outline-small ">
                            <img src={pluswIcon} alt="" /> Add
                          </button>
                        </Link>
                        <NoData msg="vendors" />{" "}
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deleteVendorModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteVendorModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteVendor(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default Vendor;

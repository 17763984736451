////////////////////////////////////////////////////////////
//     							                          //
//  Program: CRMResident.jsx                                    //
//  Application: CRMResident                                    //
//  Option: List all CRMResident                                //
//  Developer: Ashish Kumar					                 //
//  Date: 2022-06-02                                       //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import pluswIcon from "./../../assets/images/plus.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import deleteicon from "../../assets/images/delete.svg";
import NoData from "../common/NoData";
import ResidentService from "../../services/ResidentService";
import Alerts from "../common/Alerts";
import ContactSidebar from "../common/ContactSidebar";
import residentImg from "./../../assets/images/profile-nogender.png";
import PhoneNumberFormat from "../common/PhoneNumberFormat";

export class CRMResident extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      ResidentEventKey: "",
      is_inactive: false,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      pathname: window.location.pathname.includes("first"),
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      residentData: 0,
    };
    this.setOpen = this.setOpen.bind(this);
    this.setResidentOpen = this.setResidentOpen.bind(this);
    this.opendeleteResidentModal = this.opendeleteResidentModal.bind(this);
    this.closedeleteResidentModal = this.closedeleteResidentModal.bind(this);
    this.deleteResident = this.deleteResident.bind(this);
  }

  componentDidMount() {
    this.setState({ residentData: 0 });
    this.getAllResidentList();
  }

  async getAllResidentList() {
    if (this.state.pathname === true) {
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/crm-resident"
          ),
        global.redirect_time
      );
    }

    var residentList = [];
    var residentListTotal = 0;
    var defaultResident = "";
    let res = await ResidentService.getResidentList(
      "property_slug=" + this.state.property_slug + "&crm=1&tab=1"
    );
    if (global.successStatus.includes(res.status)) {
      residentList = res.data ? res.data.data.sort((a, b) => a.first_name.localeCompare(b.first_name)) : [];
      residentListTotal = residentList.length;
      defaultResident = residentListTotal > 0 ? residentList[0].slug : "";
    }
    const groupedByLetter = residentList.reduce((groups, contact) => {
      const letter = contact.first_name[0].toUpperCase();
      const group = groups[letter] || [];
      group.push(contact);
      groups[letter] = group;
      return groups;
    }, {});
    const groupedresidentList = Object.entries(groupedByLetter);
    this.setState({
      residentList: residentList,
      residentListTotal: residentListTotal,
      defaultResident: defaultResident,
      groupedresidentList: groupedresidentList,
    });
    if (residentListTotal > 0) {
      if (this.state.slug !== undefined && this.state.slug !== "") {
        this.getResidentDetails(this.state.slug);
      } else {
        this.getResidentDetails(this.state.defaultResident);
      }
      this.setState({ residentData: 1 });
    }
  }

  async getResidentDetails(slug) {
    this.setState({ showSpinner: true, loading: true });

    var resData = {};
    var StaffDetailsTotal = [];
    let res = await ResidentService.getResident(slug);
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
    }
    this.setState(resData);
    this.setState({ StaffDetailsTotal: StaffDetailsTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  opendeleteResidentModal(slug) {
    this.setState({ deleteResidentModal: true, slug: slug });
  }

  closedeleteResidentModal() {
    this.setState({ deleteResidentModal: false, slug: "" });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  selectResident = (i, slug) => {
    this.getResidentDetails(slug);
    this.setState({ residentData: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Contacts";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  setResidentOpen(e) {
    this.setState({ ResidentEventKey: e.target.id });
  }

  async deleteResident(slug) {
    this.setState({ showSpinner: true });
    let res = await ResidentService.deleteOtherAgency(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteResidentModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.setState({ residentData: 0, slug: "" });
      this.getAllResidentList();
    } else {
      this.closedeleteResidentModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <ContactSidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {global.userPermissions.checkPermission(
                        "residents-add"
                      ) && (
                        <Link to={"crm-resident/add"} className="mg-l-2">
                          <button className="btn-success-outline-small ">
                            <img src={pluswIcon} alt="" /> Add
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-45 pd-lg-r-30">
                  <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 p-10 mg-t-20">
                    <div>
                      <div className="container row d-flex app-contacts az-content-app az-content-contacts p-0">
                        <div className="az-content-left az-content-left-contacts pd-lg-l-0 pd-xs-l-15 side_top_navtab">
                          <nav className="nav az-nav-line az-nav-line-chat">
                            <Link
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                              data-toggle="tab"
                              className="nav-link "
                            >
                              Staff
                            </Link>

                            <Link
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/vendor"
                              }
                              data-toggle="tab"
                              className="nav-link"
                            >
                              Vendor
                            </Link>
                            <Link
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/crm-resident"
                              }
                              data-toggle="tab"
                              className="nav-link active"
                            >
                              Resident
                            </Link>
                          </nav>

                          <PerfectScrollbar
                            id="azContactList"
                            className="az-contacts-list h-auto"
                          >
                            {this.state.residentListTotal > 0
                              ? this.state.groupedresidentList
                                  .sort()
                                  .map((items, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <div className="az-contact-label">
                                          {items[0]}
                                        </div>
                                        {items[1]
                                          .sort((a, b) =>
                                            a.first_name.localeCompare(
                                              b.first_name
                                            )
                                          )
                                          .map((opt, i) => {
                                            return (
                                              <React.Fragment key={i}>
                                                <div
                                                  className={
                                                    this.state.slug === opt.slug
                                                      ? "az-contact-item selected"
                                                      : "az-contact-item"
                                                  }
                                                  onClick={() =>
                                                    this.selectResident(
                                                      i,
                                                      opt.slug
                                                    )
                                                  }
                                                >
                                                  <div className="az-img-user online">
                                                    <img
                                                      src={
                                                        opt.resident_photo_thumb_url
                                                          ? opt.resident_photo_thumb_url
                                                          : residentImg
                                                      }
                                                      alt={
                                                        opt.first_name
                                                          ? opt.first_name
                                                          : "Resident Name"
                                                      }
                                                      onError={
                                                        "this.onerror=null;this.src='" +
                                                        residentImg +
                                                        "';"
                                                      }
                                                    />
                                                  </div>
                                                  <div className="az-contact-body">
                                                    <h6>
                                                      {opt.first_name
                                                        ? opt.first_name +
                                                          " " +
                                                          opt.last_name
                                                        : ""}
                                                    </h6>
                                                    <p className="tx-xthin mg-b-0">
                                                      {opt.get_lease &&
                                                      opt.get_lease.unit &&
                                                      opt.get_lease.unit
                                                        .unit_name
                                                        ? opt.get_lease.unit
                                                            .unit_name
                                                        : opt.get_lease &&
                                                          opt.get_lease.unit &&
                                                          opt.get_lease.unit
                                                            .unit_number
                                                        ? opt.get_lease.unit
                                                            .unit_number
                                                        : ""}
                                                    </p>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          })}
                                      </React.Fragment>
                                    );
                                  })
                              : ""}
                          </PerfectScrollbar>
                          {/* az-contacts-list */}
                        </div>
                        {/* az-content-left */}
                        {this.state.residentData === 1 ? (
                          <div className="az-content-body az-content-body-contacts nav_tab_cnt">
                            {/* az-contact-info-header */}
                            <div className="az-contact-info-header mg-t-50 border-bottom-0 pd-lg-l-30 pd-xs-l-15 ">
                              <div className="media">
                                <div className="az-img-user">
                                  <img
                                    src={
                                      this.state.resident_photo_thumb_url
                                        ? this.state.resident_photo_thumb_url
                                        : residentImg
                                    }
                                    alt={
                                      this.state.first_name
                                        ? this.state.first_name
                                        : "Resident Name"
                                    }
                                    onError={
                                      "this.onerror=null;this.src='" +
                                      residentImg +
                                      "';"
                                    }
                                  />
                                </div>
                                <div className="media-body pd-l-15 pd-lg-l-15 pd-xs-l-0">
                                  <h4>
                                    {this.state.first_name
                                      ? this.state.first_name +
                                        " " +
                                        this.state.last_name
                                      : ""}
                                  </h4>
                                  <p className="media-body mb-0">
                                    Unit :{" "}
                                    {this.state.get_lease &&
                                    this.state.get_lease.unit &&
                                    this.state.get_lease.unit.unit_name
                                      ? this.state.get_lease.unit.unit_name
                                      : this.state.get_lease &&
                                        this.state.get_lease.unit &&
                                        this.state.get_lease.unit.unit_number
                                      ? this.state.get_lease.unit.unit_number
                                      : ""}
                                  </p>
                                </div>

                                <div className="az-contact-action">
                                  <div className=" d-flex flex-wrap justify-content-end bothBtn align-items-center">
                                    {global.userPermissions.checkPermission(
                                      "residents-delete"
                                    ) && (
                                      <span
                                        onClick={() => {
                                          this.opendeleteResidentModal(
                                            this.state.slug
                                          );
                                        }}
                                        className="deleteicon mg-r-20 hand-cursor"
                                      >
                                        <img src={deleteicon} alt="Delete" />
                                      </span>
                                    )}
                                    {global.userPermissions.checkPermission(
                                      "residents-update"
                                    ) && (
                                      <Link
                                        to={
                                          "/properties/view/" +
                                          this.state.property_slug +
                                          "/crm-resident/update/" +
                                          this.state.slug
                                        }
                                        className="mg-l-2"
                                      >
                                        <button className="btn-success-outline">
                                          <img src={editIcon} alt="Edit" />
                                          Edit
                                        </button>
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* media */}

                              {/* az-contact-action */}
                            </div>
                            <div className="az-contact-info-body">
                              <PerfectScrollbar className="media-list ">
                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>Email</label>

                                      {this.state.email ? this.state.email : ""}
                                    </div>
                                    <div>
                                      <label>Phone</label>

                                      {this.state.phone_number ? (
                                        <PhoneNumberFormat
                                          phone_number={this.state.phone_number}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>
                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>Street 1</label>
                                      <span className="tx-medium">
                                        {this.state.street_1
                                          ? this.state.street_1
                                          : ""}
                                      </span>
                                    </div>
                                    <div>
                                      <label>Street 2</label>

                                      {this.state.street_2
                                        ? this.state.street_2
                                        : ""}
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>
                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>City</label>
                                      <span className="tx-medium">
                                        {this.state.city_name
                                          ? this.state.city_name
                                          : ""}
                                      </span>
                                    </div>
                                    <div>
                                      <label>State</label>

                                      {this.state.state_name
                                        ? this.state.state_name
                                        : ""}
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>

                                <div className="media">
                                  <div className="media-body">
                                    <div>
                                      <label>County</label>
                                      <span className="tx-medium">
                                        {this.state.county_name
                                          ? this.state.county_name
                                          : ""}
                                      </span>
                                    </div>
                                    <div>
                                      <label>Country</label>
                                      {this.state.country_name
                                        ? this.state.country_name
                                        : ""}
                                    </div>
                                  </div>
                                  {/* media-body */}
                                </div>
                              </PerfectScrollbar>
                              {/* media-list */}
                            </div>

                            {/* az-contact-info-body */}
                          </div>
                        ) : (
                          <div className="m-auto">
                            <NoData />
                          </div>
                        )}
                        {/* az-content-body */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deleteResidentModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteResidentModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteResident(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default CRMResident;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: AmenityService.js                            //
//  Application: Amenity                                  //
//  Option: Used for manage amenity                       //
//  Developer: Ashish Kumar  						      //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AmenityService {
  getAllAmenity(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "get-str-list-detail";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getAmenity(queryString) {
    let endPoint = "amenity"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createAmenity(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "amenity";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getAmenityBySlug(slug) {
    let endPoint = "amenity/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  addOrUpdateAmenity(slug, data){
     // Delete data which we don't want to send
     delete data.errors;
     delete data.input;
 
     let endPoint = "udpate-amenities/"+slug;
     let addditionalHeaderData = {};
     let postData = data;
 
     let inputData = {
       endPoint: endPoint,
       addditionalHeaderData: addditionalHeaderData,
       postData: postData,
     };
     let response = ApiService.postCall(inputData);
     return response;
  }

  updateAmenity(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "amenity/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteAmenity(slug) {
    let endPoint = "amenity/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadAmenityIcon(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "upload-amenity-icon";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  //for property 
  getPropertyAmenities(slug) {
    let endPoint = "get-property-amenities/"+ slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  attachPropertyAmenities(slug,data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "attach-property-amenities/"+slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  detachPropertyAmenities(slug,data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "detach-property-amenities/"+slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  addOrUpdateBeds(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "save-beds";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getBeds(slug) {
    let endPoint = "get-beds/"+ slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getMasterBeds() {
    let endPoint = "get-master-beds";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new AmenityService();

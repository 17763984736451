////////////////////////////////////////////////////////////
//     							                           //
//  Program: Brand.jsx                                    //
//  Application: Brand                                    //
//  Option: List all Brand                                //
//  Developer: Ashish Kumar,NP  						              //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import BrandService from "../../services/BrandService";
import { Link } from "react-router-dom";
import { Container, Modal, InputGroup, Form, Nav, Tab } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import brandLogo from "./../../assets/images/brand_placeholder.png";
import NoData from "../common/NoData";
import filterIcon from "./../../assets/images/filter-gray.svg";
import queryString from "query-string";
import PhoneInput from "react-phone-input-2";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";

let queryParams = queryString.parse(window.location.search);

export class Brand extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      module_search: "",
      is_search: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      brand_name: "",
      aka_name: "",
      email: "",
      phone_number: "",
      contact_name: "",
      records: [],
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "brand_logo",
          text: "Logo",
          className: "brand_logo hand-cursor",
          sortable: false,
          cell: (record) => {
            return (
              <Link to={"/brand/view/" + record.slug + "/brand-info"}>
                <img
                  src={
                    record.brand_logo_thumb_url
                      ? record.brand_logo_thumb_url
                      : brandLogo
                  }
                  className="brand_list_image"
                  alt="Brand Logo"
                />
              </Link>
            );
          },
        },
        {
          key: "brand_name",
          text: "DBA",
          className: "brand_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.slug ? (
              <Link to={"/brand/view/" + record.slug + "/brand-info"}>
                {record.brand_name}
              </Link>
            ) : (
              record.brand_name
            );
          },
        },
        {
          key: "total_property_count",
          text: "# of Properties",
          className: "total_property_count hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.total_property_count ? (
              <p
                className="m-0"
                onClick={() =>
                  this.redirectToProperty(record.slug, record.brand_name)
                }
              >
                {record.total_property_count}
              </p>
            ) : (
              "0"
            );
          },
        },
        {
          key: "contact_name",
          text: "Contact Name",
          className: "contact_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.contact_name ? (
              <Link to={"/brand/view/" + record.slug + "/brand-info"}>
                {record.contact_name}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "phone_number",
          text: "Phone",
          className: "phone_number hand-cursor",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber = record.phone_number
              ? record.phone_number.replace(/[\s-]/g, "")
              : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits = record.phone_number ? record.phone_number : [];
            }

            var number = record.phone_number
              ? lastTenDigits.trim().replace(/[^0-9]/g, "")
              : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }

            return (
              <Link to={"/brand/view/" + record.slug + "/brand-info"}>
                {finalnumber}
              </Link>
            );
          },
        },
        {
          key: "asset_manager",
          text: "Asset Manager",
          className: "asset_manager hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.asset_manager && record.asset_manager.first_name ? (
              <Link to={"/brand/view/" + record.slug + "/brand-info"}>
                {record.asset_manager.first_name}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "asset_manager_phone_number",
          text: "Asset Manager Phone",
          className: "asset_manager_phone_number hand-cursor",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber =
              record.asset_manager && record.asset_manager.phone_number
                ? record.asset_manager.phone_number.replace(/[\s-]/g, "")
                : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits =
                record.asset_manager && record.asset_manager.phone_number
                  ? record.asset_manager.phone_number
                  : [];
            }

            var number =
              record.asset_manager && record.asset_manager.phone_number
                ? lastTenDigits.trim().replace(/[^0-9]/g, "")
                : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.asset_manager && record.asset_manager.phone_number ? (
              <Link to={"/brand/view/" + record.slug + "/brand-info"}>
                {finalnumber}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "orion_haus_account_manager",
          text: "Orion Haus Manager",
          className: "orion_haus_account_manager hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.orion_haus_account_manager &&
              record.orion_haus_account_manager.first_name ? (
              <Link to={"/brand/view/" + record.slug + "/brand-info"}>
                {record.orion_haus_account_manager.first_name}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "orion_haus_account_manager_phone_number",
          text: "Orion Haus Manager Phone",
          className: "orion_haus_account_manager_phone_number hand-cursor",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber =
              record.orion_haus_account_manager &&
              record.orion_haus_account_manager.phone_number
                ? record.orion_haus_account_manager.phone_number.replace(
                    /[\s-]/g,
                    ""
                  )
                : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits =
                record.orion_haus_account_manager &&
                record.orion_haus_account_manager.phone_number
                  ? record.orion_haus_account_manager.phone_number
                  : [];
            }

            var number =
              record.orion_haus_account_manager &&
              record.orion_haus_account_manager.phone_number
                ? lastTenDigits.trim().replace(/[^0-9]/g, "")
                : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.orion_haus_account_manager &&
              record.orion_haus_account_manager.phone_number ? (
              <Link to={"/brand/view/" + record.slug + "/brand-info"}>
                {finalnumber}
              </Link>
            ) : (
              ""
            );
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active"></b>
            ) : (
              <b className="status-inactive"></b>
            );
          },
        },
      ],
      tab: "active",
      brandTabData: localStorage.getItem("brand_tab_data")
        ? localStorage.getItem("brand_tab_data")
        : "",
      activeClass: "activeTab",
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
  }

  componentDidMount() {
    if (queryParams.isGlobal === "1") {
      if (document.querySelector("#left-tabs-example-tabpane-activeTab")) {
        document
          .querySelector("#left-tabs-example-tabpane-activeTab")
          .classList.add("active");
        document
          .querySelector("#left-tabs-example-tabpane-activeTab")
          .classList.add("show");
      }
      this.getData(
        "is_search=1&tab=" +
          this.state.brandTabData +
          "&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search_value
      );
    } else {
      switch (this.state.brandTabData) {
        case "active":
          document.getElementById("activeTab").click();
          break;
        case "inactive":
          document.getElementById("inactiveTab").click();
          break;
        default:
          document.getElementById("activeTab").click();
      }
    }
  }
  onChangePhone(value, country, e, formattedValue) {
    //   console.log(value, country, e, formattedValue);
    this.setState({ phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "brand_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "brand_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await BrandService.getBrandList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list ? list.length : 0
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getData("tab=" + this.state.tab);
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let queryString =
      "brand_name=" +
      (this.state.brand_name ? this.state.brand_name : "") +
      "&aka_name=" +
      (this.state.aka_name ? this.state.aka_name : "") +
      "&email=" +
      (this.state.email ? this.state.email : "") +
      "&phone_number=" +
      (this.state.phone_number ? this.state.phone_number : "") +
      "&contact_name=" +
      (this.state.contact_name ? this.state.contact_name : "") +
      "&status_id=" +
      (this.state.status_id ? this.state.status_id : "") +
      "&is_search=1";

    // let moduleSearchString =
    //   (this.state.brand_name ? "brand name - " + this.state.brand_name : '') +
    //   (this.state.aka_name ? " & brand SKU - " + this.state.aka_name : '') +
    //   (this.state.email ? " & email - " + this.state.email : '') +
    //   (this.state.phone_number ? " & phone number - " + this.state.phone_number : '') +
    //   (this.state.contact_name ? " & brand - " + this.state.contact_name : '') +
    //   (this.state.status_id ? " & status - " + this.state.selectedStatusList.label : '');

    let moduleSearchString = "";
    moduleSearchString = this.state.brand_name
      ? "Brand Name - " + this.state.brand_name
      : "";
    moduleSearchString = this.state.aka_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Brand SKU - " + this.state.aka_name
        : " Brand SKU - " + this.state.aka_name
      : moduleSearchString;
    moduleSearchString = this.state.email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Email - " + this.state.email
        : " Email - " + this.state.email
      : moduleSearchString;
    moduleSearchString = this.state.phone_number
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Phone Number - " +
          this.state.phone_number
        : " Phone Number - " + this.state.phone_number
      : moduleSearchString;
    moduleSearchString = this.state.contact_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Brand - " + this.state.contact_name
        : " Brand - " + this.state.contact_name
      : moduleSearchString;
    moduleSearchString = this.state.status_id
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;

    if (
      this.state.brand_name ||
      this.state.aka_name ||
      this.state.email ||
      this.state.phone_number ||
      this.state.contact_name ||
      this.state.status_id
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";

    this.setState({ is_search: 0 });
    this.setState({
      brand_name: "",
      aka_name: "",
      email: "",
      phone_number: "",
      contact_name: "",
      status_id: "",
      selectedStatusList: [],
    });
    this.setState({ module_search: "" });
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData("tab=" + this.state.tabqueryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData("tab=" + this.state.tab);
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  redirectToProperty(slug, name) {
    window.location.href =
      "/properties?isGlobal=1&global_search_value=" +
      slug +
      "&global_col_name=brand_slug&global_col_label=brand&global_search=" +
      name;
  }

  // rowClickedHandler = (event, data, rowIndex) => {
  //   this.props.history.push("/brand/view/" + data.slug + "/brand-info");
  // };

  render() {
    // const customStyles = {
    //   option: (provided, state) => ({
    //     ...provided,
    //     // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
    //     color:
    //       state.isSelected && state.isFocused
    //         ? "white"
    //         : state.isSelected
    //         ? "black"
    //         : state.isFocused && "white",
    //     backgroundColor:
    //       state.isSelected && state.isFocused
    //         ? "black"
    //         : state.isFocused && "black",
    //   }),
    // };
    return (
      <>
        <div>
          <main>
            {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
            <Container className="innter-container pr-0 over-fl">
              <div className="d-flex flex-column">
                <div className="row row-xs mg-t-0 pd-t-0">
                  {/* <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <BrandSidebar />
                    </aside>
                  </div>
                </div> */}
                  {/* <div className="col-md-10 right settingsidebarNav"> */}
                  <div className="col-md-12">
                    <div className="scrolling-carousel">
                      <Container>
                        <div className="row row-xs">
                          <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                          <div className="col-md-12">
                            <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                              <h3 className="brnd_heading mb-0">Brands</h3>
                              <div className="d-flex flex-wrap">
                                {global.userPermissions.checkPermission(
                                  "brand-add"
                                ) && (
                                  <Link to={"/brand/add"}>
                                    <button className="btn-success-outline mg-r-20 mr-3">
                                      <img src={pluswIcon} alt="Add" /> Add
                                    </button>
                                  </Link>
                                )}
                                <button
                                  onClick={this.searchToggle}
                                  className="btn-success-outline-small"
                                >
                                  <img src={filterIcon} alt="filterIcon" />{" "}
                                  {this.state.isSearch === 1
                                    ? "Search"
                                    : "Search"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Container>
                      <Container>
                        {queryParams.isGlobal === "1" ? (
                          <>
                            <div className="col-md-12 pd-l-30 pd-r-30 mg-t-30 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                              {"Search applied on: " +
                                queryParams.global_col_label +
                                " - " +
                                queryParams.global_search}
                              <button
                                onClick={this.clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                              <div className="custm-filter pd-t-20">
                                <div>
                                  {" "}
                                  <label className="form-label text-left pd-b-2 mb-0">
                                    Search Applied On
                                  </label>
                                </div>
                                <div className="filter_bg_cust">
                                  {this.state.module_search.map((item, i) => {
                                    return <span key={i}>{item}</span>;
                                  })}
                                  <button
                                    onClick={this.clearFilter}
                                    className="btn-fliter-clear"
                                  >
                                    Clear
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="scrolling-carousel scroll-slide">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="all"
                            onSelect={(e) => this.getActiveClass(e)}
                            eventKey={this.state.activeClass}
                            className="pd-r-60"
                          >
                            <ScrollingCarousel
                              rightArrow={true}
                              leftArrow={true}
                              className="swipesection pd-t-20"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="activeTab"
                                  id="activeTab"
                                  className={
                                    this.state.activeClass === "activeTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Active
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item>
                                <Nav.Link
                                  eventKey="inactiveTab"
                                  id="inactiveTab"
                                  className={
                                    this.state.activeClass === "inactiveTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Inactive
                                </Nav.Link>
                              </Nav.Item>
                            </ScrollingCarousel>
                            <Tab.Content>
                              <Tab.Pane eventKey="activeTab">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-20 brandList">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        // loading={this.state.loading}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        // onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  ) : (
                                    <NoData
                                      msg={global.commonMessages.checkMessage(
                                        "BRAND_ACTIVE"
                                      )}
                                    />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="inactiveTab">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-20 brandList">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        // loading={this.state.loading}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        // onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  ) : (
                                    <NoData
                                      msg={global.commonMessages.checkMessage(
                                        "BRAND_INACTIVE"
                                      )}
                                    />
                                  )}
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </main>
        </div>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Brands</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>DBA</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="brand_name"
                      value={this.state.brand_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Brand SKU</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="aka_name"
                      value={this.state.aka_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Contact Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="contact_name"
                      value={this.state.contact_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder=" Contact Name"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Email</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Phone</label>
                  <div className="col-md-12 p-0">
                    <PhoneInput
                      country={global.country}
                      disableSearchIcon
                      // autoFormat= "true"
                      enableSearch="true"
                      countryCodeEditable="true"
                      value={this.state.phone_number}
                      onChange={this.onChangePhone}
                    />
                  </div>
                </InputGroup>

                {/*  <InputGroup className="mt-3">
                  <label>Status</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedStatusList}
                      options={this.state.newStatusList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeStatusList(value)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup> */}
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Brand;

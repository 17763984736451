////////////////////////////////////////////////////////////
//     							                          //
//  Program: KeyDataService.js                            //
//  Application: Third party                              //
//  Option: Used for manage key data dashbaord            //
//  Developer: NP           						      //
//  Date: 2023-06-08                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class KeyDataService {

  getKeyDataUrl(queryString) {
    let endPoint = "get-key-data-iframe-url"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new KeyDataService();

////////////////////////////////////////////////////////////
//     							                          //
//  Program: GuestService.js                              //
//  Application: Guest                                    //
//  Option: Used for manage Guest                       //
//  Developer: Ashish Kumar						                     //
//  Date: 2022-04-18                                        //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class GuestService {
  
  getGuestList(queryString) {
    let endPoint = "guest-user" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getGuest(slug) {
    let endPoint = "guest-user/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getGuestByPhone(phone) {
    let endPoint = "show-guest/" + phone;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getGuestByEmail(email) {
    let endPoint = "show-guest-email/" + email;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createGuest(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "guest-user";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateGuest(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "guest-user/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteGuest(slug) {
    let endPoint = "guest-user/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadGuestLogo(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'upload-guest-user-logo';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
    let response = ApiService.postCall(inputData);
    return response;
  }

  exportGuestData(queryString){
    let endPoint = "export-guest-user" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  //for avilability
  getGuestAval(queryString) {
    let endPoint = "guest-user-availability" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  addGuestAval(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "guest-user-availability";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getUnitAvalBySlug(slug) {
    let endPoint = 'guest-user-availability/' + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
        'endPoint': endPoint,
        'addditionalHeaderData': addditionalHeaderData,
        'postData': postData
    }
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateUnitAval(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'guest-user-availability/' + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
        'endPoint': endPoint,
        'addditionalHeaderData': addditionalHeaderData,
        'postData': postData
    }
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteUnitAval(slug) {
    let endPoint = 'guest-user-availability/' + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
        'endPoint': endPoint,
        'addditionalHeaderData': addditionalHeaderData,
        'postData': postData
    }
    let response = ApiService.deleteCall(inputData);
    return response;
}
}

export default new GuestService();

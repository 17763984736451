///////////////////////////////////////////////
//     							             //
//  Program: Ambassadors.js           //
//  Application: Services                    //
//  Option: Used for Ambassadors data        //
//  Developer: Sunny Raj                     //
//  Date: 2023-07-19                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class AmbassadorService {
    getAmbassador(queryString) {
        let endPoint = "ambassadors" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    createAmbassadors(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "ambassadors";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    getAmbassadorBySlug(slug) {
        let endPoint = "ambassadors/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateAmbassadors(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "ambassadors/" + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.putCall(inputData);
        return response;
    }

    deleteAmbassadors(slug) {
        let endPoint = "ambassadors/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
    }

    uploadAmbassadorImage(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "upload-ambassador-photo";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    getSocialMedia(queryString) {
        let endPoint = "social-media" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    multiDelete(slug) {
        let endPoint = "ambassador-link/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
    }
}

export default new AmbassadorService();

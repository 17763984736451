////////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertyVendors.jsx                          //
//  Application: Property                                 //
//  Option: to view all Property Vendors                  //
//  Developer: NP  						                  //
//  Date: 2022-02-07                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import {
  Table,
  InputGroup,
  Modal,
  Form,
} from "react-bootstrap";
import deleteicon from "../../assets/images/delete.svg";
// import editicon from "../../assets/images/edit.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import AgencyCategoryService from "../../services/AgencyCategoryService";
import PropertyVendorService from "../../services/PropertyVendorService";
import AllAgencyService from "../../services/AllAgencyService";
import CityService from "../../services/CityService";
import pluswIcon  from './../../assets/images/plus.svg'
import PhoneInput from 'react-phone-input-2'
import PhoneNumberFormat from "../common/PhoneNumberFormat";

export class PropertyVendors extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      // open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      sort_order: "",
      //for all agency
      addOtherAgencyModal: false,
      editOtherAgencyModal: false,
      deleteOtherAgencyModal: false,
      agencyList: [],
      agencyListTotal: 0,
      countryList: [],
      countyList: [],
      stateList: [],
      cityList: [],
      countryListTotal: 0,
      countyListTotal: 0,
      stateListTotal: 0,
      cityListTotal: 0,
      third_party_agency_slug: "",
      third_party_agency_name:"",
      street_1: "",
      street_2: "",
      country_slug: "",
      county_slug: "",
      state_slug: "",
      city_slug: "",
      zipcode: "",
      contact_name: "",
      phone_number: "",
      email: "",
      agencyCategoryList: [],
      agencyCategoryListTotal: 0,
      agency_category_slug:"",
      allAgencyCategoryList:[],
      allAgencyCategoryListTotal:0,
      categorySlug:""
    };
    this.changeHandler = this.changeHandler.bind(this);
    //for ad agency
    this.openaddOtherAgencyModal = this.openaddOtherAgencyModal.bind(this);
    this.openeditOtherAgencyModal = this.openeditOtherAgencyModal.bind(this);
    this.opendeleteOtherAgencyModal = this.opendeleteOtherAgencyModal.bind(this);
    this.saveOtherAgency = this.saveOtherAgency.bind(this);
    this.getOtherAgencyDetails = this.getOtherAgencyDetails.bind(this);
    this.getAgencyCategoryList = this.getAgencyCategoryList.bind(this);
    this.updateOtherAgency = this.updateOtherAgency.bind(this);
    this.deleteOtherAgency = this.deleteOtherAgency.bind(this);
    this.getOtherAgencyData = this.getOtherAgencyData.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);

  }

  componentDidMount() {
    this.getPropertyAgencyList();
  }

  async getAgencyCategoryList(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var allAgencyCategoryList = [];
    var allAgencyCategoryListTotal = 0;

    let res = await AgencyCategoryService.getAgencyCategory("is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      allAgencyCategoryList = res.data ? res.data : [];
      allAgencyCategoryListTotal = res.data ? res.data.length : 0;
    }
    this.setState({
      allAgencyCategoryList: allAgencyCategoryList,
      allAgencyCategoryListTotal: allAgencyCategoryListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getPropertyAgencyList(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var propertyAgencyList = [];
    var propertyAgencyListTotal = 0;

    let res = await PropertyVendorService.getOtherAgency("property_slug="+this.props.propertySlug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      propertyAgencyList = res.data ? res.data.data : [];
      propertyAgencyListTotal = propertyAgencyList ? propertyAgencyList.length : 0;
    }
    this.setState({
      propertyAgencyList: propertyAgencyList,
      propertyAgencyListTotal: propertyAgencyListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    if (event.target.name === "country_slug") {
      if (event.target.value === "") {
        this.setState({
          state_slug : "",
          county_slug : "",
          city_slug : "",
          stateList: [],
          countyList: [],
          cityList: []
        })
      } else {
        this.getStatesData(event.target.value);
        this.setState({
          county_slug : "",
          city_slug : "",
          countyList: [],
          cityList: []
        })
      }
    }
    if (event.target.name === "state_slug") {
      if (event.target.value === "") {
        this.setState({
          county_slug : "",
          city_slug : "",
          countyList: [],
          cityList: []
        })
      } else {
        this.getCountyData(event.target.value);
        this.getCityData(event.target.value, "");
      }
    }
    if (event.target.name === "county_slug") {
      if (event.target.value === "") {
        this.getCityData(input.state_slug, "");
      } else {
        this.getCityData(input.state_slug, event.target.value);
      }
    }
    if(event.target.name === "agency_category_slug"){
        this.getOtherAgencyData(event.target.value);
    }
    if(event.target.name === "third_party_agency_slug"){
      this.getOtherAgencyDetails(event.target.value);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* to get country list data */
  async getCountryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];
    var countryListTotal = 0;

    let res = await CountryService.getCountry(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data.data ? res.data.data : [];
      countryListTotal = countryList ? countryList.length : 0;
    }
    this.setState({
      countryList: countryList,
      countryListTotal: countryListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get country list data */
  async getCountryDataForActive(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];
    var countryListTotal = 0;

    let res = await CountryService.getCountry(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
      countryListTotal = countryList ? countryList.length : 0;
    }
    this.setState({
      countryList: countryList,
      countryListTotal: countryListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get county list data */
  async getCountyData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countyList = [];
    var countyListTotal = 0;

    let res = await CountyService.getCounty(
      "is_dropdown=1&state_slug=" + value,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
      countyListTotal = countyList ? countyList.length : 0;
    }
    this.setState({ countyList: countyList, countyListTotal: countyListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get state list data */
  async getStatesData(countryValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var stateList = [];
    var stateListTotal = 0;

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
        countryValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
      stateListTotal = stateList ? stateList.length : 0;
    }
    this.setState({ stateList: stateList, stateListTotal: stateListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get city list data */
  async getCityData(stateValue, countyValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;

    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    this.setState({ cityList: cityList, cityListTotal: cityListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get ad agency data */
  async getOtherAgencyData(categorySlug,queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var agencyList = [];
    var agencyListTotal = 0;

    let res = await AllAgencyService.getOtherAgency("agency_category_slug=" + categorySlug + "&is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      agencyList = res.data ? res.data : [];
      agencyListTotal = agencyList ? agencyList.length : 0;
    }
    this.setState({
      agencyList: agencyList,
      agencyListTotal: agencyListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save ad agency data */
  saveOtherAgency = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.third_party_agency_slug &&
      !this.validator.errorMessages.agency_category &&
      !this.validator.errorMessages.street_1 &&
      !this.validator.errorMessages.street_2 &&
      !this.validator.errorMessages.city_slug &&
      !this.validator.errorMessages.country_slug &&
      !this.validator.errorMessages.county_slug &&
      !this.validator.errorMessages.state_slug &&
      !this.validator.errorMessages.zipcode &&
      !this.validator.errorMessages.contact_name &&
      !this.validator.errorMessages.phone_number &&
      !this.validator.errorMessages.email &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug : this.props.propertySlug,
        third_party_agency_name: this.state.third_party_agency_name,
        third_party_agency_slug: this.state.third_party_agency_slug,
        agency_category_slug:this.state.agency_category_slug,
        set_custom_data : this.state.set_custom_data === true ? 1 : 0,
        street_1: this.state.street_1,
        street_2: this.state.street_2 ? this.state.street_2 : "",
        city_slug: this.state.city_slug ? this.state.city_slug : 0,
        country_slug: this.state.country_slug ? this.state.country_slug : 0,
        county_slug: this.state.county_slug ? this.state.county_slug : 0,
        state_slug: this.state.state_slug ? this.state.state_slug : 0,
        zipcode: this.state.zipcode,
        contact_name: this.state.contact_name,
        phone_number: this.state.phone_number,
        email: this.state.email,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await PropertyVendorService.createOtherAgency(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeaddOtherAgencyModal();
        this.setState({
          third_party_agency_slug: "",
          agency_category_slug:"",
          street_1: "",
          street_2: "",
          city_slug: "",
          country_slug: "",
          county_slug: "",
          state_slug: "",
          zipcode: "",
          contact_name: "",
          phone_number: "",
          email: "",
          sort_order: "",
          status_id:true
        });
        this.getPropertyAgencyList();
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.result.errorDetail[0].errorMessage
            ? res.data.result.errorDetail[0].errorMessage
            : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.errorMessage,
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get ad agency details data */
  async getOtherAgencyDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await PropertyVendorService.getOtherAgencyBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        // resData.third_party_agency_slug = res.data.third_party_agency_slug ? res.data.third_party_agency_slug : "";
        resData.agency_category_slug = res.data.agency_category ? res.data.agency_category.slug : ""
        resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resData.city_slug = res.data.city_slug ? res.data.city_slug : "";
        resData.country_slug = res.data.country_slug ? res.data.country_slug : "";
        resData.county_slug = res.data.county_slug ? res.data.county_slug : "";
        resData.state_slug = res.data.state_slug ? res.data.state_slug : "";
        resData.zipcode = res.data.zipcode ? res.data.zipcode : "";
        resData.contact_name = res.data.contact_name
          ? res.data.contact_name
          : "";
        resData.phone_number = res.data.phone_number
          ? res.data.phone_number
          : "";
        resData.email = res.data.email ? res.data.email : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
      this.getCountyData(resData.state_slug);
      this.getStatesData(resData.country_slug);
      this.getCityData(resData.state_slug, resData.county_slug);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* To get ad agency details data */
  async getAgencyDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await PropertyVendorService.getAgencyBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.third_party_agency_slug = res.data.third_party_agency ? res.data.third_party_agency.slug : "";
        resData.third_party_agency_name = res.data.third_party_agency ? res.data.third_party_agency.third_party_agency_name : "";
        resData.agency_category_slug = res.data.agency_category ? res.data.agency_category.slug : ""
        resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resData.city_slug = res.data.city_slug ? res.data.city_slug : "";
        resData.country_slug = res.data.country_slug ? res.data.country_slug : "";
        resData.county_slug = res.data.county_slug ? res.data.county_slug : "";
        resData.state_slug = res.data.state_slug ? res.data.state_slug : "";
        resData.zipcode = res.data.zipcode ? res.data.zipcode : "";
        resData.contact_name = res.data.contact_name
          ? res.data.contact_name
          : "";
        resData.phone_number = res.data.phone_number
          ? res.data.phone_number
          : "";
        resData.email = res.data.email ? res.data.email : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
      this.getOtherAgencyData(resData.agency_category_slug);
      this.getCountyData(resData.state_slug);
      this.getStatesData(resData.country_slug);
      this.getCityData(resData.state_slug, resData.county_slug);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update ad agency data */
  updateOtherAgency = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.third_party_agency_slug &&
      !this.validator.errorMessages.agency_category &&
      !this.validator.errorMessages.street_1 &&
      !this.validator.errorMessages.street_2 &&
      !this.validator.errorMessages.city_slug &&
      !this.validator.errorMessages.country_slug &&
      !this.validator.errorMessages.county_slug &&
      !this.validator.errorMessages.state_slug &&
      !this.validator.errorMessages.zipcode &&
      !this.validator.errorMessages.contact_name &&
      !this.validator.errorMessages.phone_number &&
      !this.validator.errorMessages.email &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug : this.props.propertySlug,
        third_party_agency_name: this.state.third_party_agency_name,
        third_party_agency_slug: this.state.third_party_agency_slug,
        agency_category_slug:this.state.agency_category_slug,
        set_custom_data : this.state.set_custom_data === true ? 1 : 0,
        street_1: this.state.street_1,
        street_2: this.state.street_2 ? this.state.street_2 : "",
        city_slug: this.state.city_slug ? this.state.city_slug : 0,
        country_slug: this.state.country_slug ? this.state.country_slug : 0,
        county_slug: this.state.county_slug ? this.state.county_slug : 0,
        state_slug: this.state.state_slug ? this.state.state_slug : 0,
        zipcode: this.state.zipcode,
        contact_name: this.state.contact_name,
        phone_number: this.state.phone_number,
        dial_code: this.state.dial_code,
        email: this.state.email,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await PropertyVendorService.updateOtherAgency(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeeditOtherAgencyModal();
        this.setState({
          third_party_agency_slug: "",
          agency_category_slug:"",
          street_1: "",
          street_2: "",
          city_slug: "",
          country_slug: "",
          county_slug: "",
          state_slug: "",
          zipcode: "",
          contact_name: "",
          phone_number: "",
          email: "",
          sort_order: "",
          status_id:true
        });
        this.getPropertyAgencyList();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.result.errorDetail[0].errorMessage
            ? res.data.result.errorDetail[0].errorMessage
            : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };
  onChangePhone(value, country, e, formattedValue){
    //console.log(value, country, e, formattedValue);
    this.setState({phone:value});
    this.setState({dial_code:country.dialCode});
}
  /* to delete ad agency data */
  async deleteOtherAgency(slug) {
    this.setState({ showSpinner: true });
    let res = await PropertyVendorService.deleteOtherAgency(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.closedeleteOtherAgencyModal();
      this.getPropertyAgencyList();
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closedeleteOtherAgencyModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  openaddOtherAgencyModal() {
    this.getAgencyCategoryList();
    this.getCountryDataForActive();
    this.setState({ addOtherAgencyModal: true });
  }

  closeaddOtherAgencyModal() {
    this.setState({ addOtherAgencyModal: false });
    this.setState({
      third_party_agency_slug: "",
      street_1: "",
      street_2: "",
      city_slug: "",
      country_slug: "",
      county_slug: "",
      state_slug: "",
      zipcode: "",
      contact_name: "",
      phone_number: "",
      email: "",
      sort_order: "",
      errors: "",
      status_id:true,
      stateList: [],
      countyList: [],
      cityList: []
    });
    this.validator.hideMessages();
  }

  openeditOtherAgencyModal(slug) {
    this.getAgencyCategoryList();
    this.getAgencyDetails(slug);
    // this.getCountryDataForActive();
    // this.getCountyData();
    // this.getStatesData();
    // this.getCityData();
    this.setState({ editOtherAgencyModal: true });
    this.setState({ slug: slug });
  }

  closeeditOtherAgencyModal() {
    this.setState({ editOtherAgencyModal: false });
    this.setState({
      slug: "",
      third_party_agency_slug: "",
      street_1: "",
      street_2: "",
      city_slug: "",
      country_slug: "",
      county_slug: "",
      state_slug: "",
      zipcode: "",
      contact_name: "",
      phone_number: "",
      email: "",
      sort_order: "",
      errors: "",
      status_id:true,
      stateList: [],
      countyList: [],
      cityList: []
    });
    this.validator.hideMessages();
  }

  opendeleteOtherAgencyModal(slug) {
    this.setState({ deleteOtherAgencyModal: true });
    this.setState({ slug: slug });
  }

  closedeleteOtherAgencyModal() {
    this.setState({ deleteOtherAgencyModal: false });
    this.setState({ slug: "" });
  }

  render() {
    return (
      <div>
          <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
            <button className="btn-success-outline mr-3 mg-t-15 mg-b-15"  onClick={this.openaddOtherAgencyModal}><img src={pluswIcon}/> Add</button>
          </div>
          <div className="col-md-12 pl-0 pr-0">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>

            <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-30">Agency Category</th>
                      <th className="w-30">Agency Name</th>
                      <th className="w-30">Contact Name</th>
                      <th className="w-30">Phone No.</th>
                      <th className="w-30">Email</th>
                      <th className="w-10">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.propertyAgencyListTotal > 0 ? (
                      this.state.propertyAgencyList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="hand-cursor" onClick={() =>
                                    this.openeditOtherAgencyModal(item.slug)
                                  }>{item.agency_category ? item.agency_category.agency_category_name : ''}</td>
                            <td>
                              {item.third_party_agency
                                ? item.third_party_agency.third_party_agency_name
                                : ""}
                            </td>
                            <td>{item.third_party_agency ? item.third_party_agency.contact_name : ''}</td>
                            <td>{item.third_party_agency.phone_number ? <PhoneNumberFormat key_no={item.slug} phone_number={item.third_party_agency.phone_number} /> : ''}</td>
                            <td>{item.third_party_agency.email ? item.third_party_agency.email : ''}</td>
                            <td>
                              {item.status_id === 1 ? (
                                <b className="status-active">Active</b>
                              ) : (
                                <b className="status-inactive">Inactive</b>
                              )}
                            </td>
                            <td className="hand-cursor">
                            <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.opendeleteOtherAgencyModal(item.slug)
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                        There are no vendors added in the system. You may add them by clicking Add button.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
            </div>



        {/* add agency popup modal */}
        <Modal
          className="modal-large"
          show={this.state.addOtherAgencyModal}
          // onHide={ () => { if(window.confirm('Are you sure to exit the pop up without saving?')) this.closeaddOtherAgencyModal()} }
          centered
        >
          <Modal.Body className="p-4 border_radius_14">
            <h3 className="text-center">Add Agency</h3>
             {/* dynamic name data */}
             <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>
                    Agency Category <span className="text-danger">*</span>
                  </label>
                  <select
                    // className="form-control max_width_100"
                    onChange={this.changeHandler}
                    name="agency_category_slug"
                    value={this.state.agency_category_slug}
                  >
                    <option key="" value="">
                      Select Agency Category
                    </option>
                    {this.state.allAgencyCategoryList.map((option) => (
                      <option
                        className="form-control"
                        key={option.slug}
                        value={option.slug}
                      >
                        {option.agency_category_name}
                      </option>
                    ))}
                  </select>
                </InputGroup>
                {this.state.errors.agency_category_slug ? (
                <div className="text-danger">
                  {this.state.errors.agency_category_slug}
                </div>
              ) : (
                this.validator.message(
                  "agency_category",
                  this.state.agency_category_slug,
                  "required",
                  { className: "text-danger" }
                )
              )}
              </div>
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>
                    Agency Name <span className="text-danger">*</span>
                  </label>
                  <select
                    // className="form-control max_width_100"
                    onChange={this.changeHandler}
                    name="third_party_agency_slug"
                    value={this.state.third_party_agency_slug}
                  >
                    <option key="" value="">
                      Select Agency Name
                    </option>
                    {this.state.agencyList.map((option) => (
                      <option
                        className="form-control"
                        key={option.slug}
                        value={option.slug}
                      >
                        {option.third_party_agency_name}
                      </option>
                    ))}
                  </select>
                </InputGroup>
                {this.state.errors.third_party_agency_slug ? (
                <div className="text-danger">
                  {this.state.errors.third_party_agency_slug}
                </div>
              ) : (
                this.validator.message(
                  "agency_name",
                  this.state.third_party_agency_slug,
                  "required",
                  { className: "text-danger" }
                )
              )}
              </div>

            </div>
            <div className="col-md-5 col-lg-5 mg-t-20 pd-l-2">
              <Form.Check
                name="set_custom_data"
                type={"checkbox"}
                id={"set_custom_data"}
                label={"Set Custom Data"}
                className={"ckbox"}
                onChange={this.changeHandler}
                checked={this.state.set_custom_data}
              />
            </div>
            <div className="row mg-l-3 mg-r-3">
                <InputGroup className="mt-3">
                  <label>
                    Custom Agency Name <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="third_party_agency_name"
                    value={this.state.third_party_agency_name}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    autoComplete="off"
                    // placeholder="Custom Agency Name"
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  />
                {this.state.errors.third_party_agency_name ? (
                <div className="text-danger">
                  {this.state.errors.third_party_agency_name}
                </div>
              ) : (
                this.validator.message(
                  "custom_agency_name",
                  this.state.third_party_agency_name,
                  "required",
                  { className: "text-danger" }
                )
              )}
              </InputGroup>
            </div>

            {/* common contact and location info */}
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>
                    Contact Name
                  </label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="contact_name"
                    value={this.state.contact_name}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    // placeholder="Contact Name"
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  />
                  {this.state.errors.contact_name ? (
                    <div className="text-danger">
                      {this.state.errors.contact_name}
                    </div>
                  ) : (
                    this.validator.message(
                      "contact_name",
                      this.state.contact_name,
                      "max:50",
                      {
                        className: "text-danger",
                      }
                    )
                  )}
                </InputGroup>
              </div>
              <div className="col-md-6">
                  <InputGroup className="mt-3">
                    <label>
                      Phone
                    </label>
                    <PhoneInput
                      country={global.country}
                      disableSearchIcon
                      // autoFormat= "true"
                      enableSearch= "true"
                      countryCodeEditable= "true"
                      value={this.state.phone_number}
                      onChange={this.onChangePhone}
                      disabled={!this.state.set_custom_data ? 'true' : ''}
                    />
                    {this.state.errors.phone_number ? (
                      <div className="text-danger">
                        {this.state.errors.phone_number}
                      </div>
                    ) : ''}
                  </InputGroup>
              </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                  <InputGroup className="mt-3">
                    <label>
                      Email
                    </label>
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Email"
                      disabled={!this.state.set_custom_data ? 'true' : ''}
                    />
                    {this.state.errors.email ? (
                      <div className="text-danger">{this.state.errors.email}</div>
                    ) : (
                      this.validator.message("email", this.state.email, "email", {
                        className: "text-danger",
                      })
                    )}
                  </InputGroup>
                </div>
                {/* <div className="col-md-6">
                  <InputGroup className="mt-3">
                    <label>Sort Order</label>
                    <Form.Control
                      onChange={this.changeHandler}
                      name="sort_order"
                      value={this.state.sort_order}
                      onKeyPress={global.onKeyPressEvent.numberValidation}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      // placeholder="Sort Order"
                      disabled={!this.state.set_custom_data ? 'true' : ''}
                    />
                    {this.state.errors.sort_order ? (
                      <div className="text-danger">{this.state.errors.sort_order}</div>
                    ) : (
                      this.validator.message(
                        "sort_order",
                        this.state.sort_order,
                        "numeric|min:0,num",
                        { className: "text-danger" }
                      )
                    )}
                  </InputGroup>
                </div> */}
            </div>
            <hr className="mg-t-35 mg-b-5 pd-b-2" />
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
                <label>
                  Street 1
                </label>
                <Form.Control
                  onChange={this.changeHandler}
                  name="street_1"
                  value={this.state.street_1}
                  className="form-control max_width_100"
                  id="inlineFormInput"
                  type="text"
                  maxLength="50"
                  autoComplete="off"
                  // placeholder="Street 1"
                  disabled={!this.state.set_custom_data ? 'true' : ''}
                />
                {this.state.errors.street_1 ? (
                  <div className="text-danger">{this.state.errors.street_1}</div>
                ) : (
                  this.validator.message(
                    "street_1",
                    this.state.street_1,
                    "max:100",
                    { className: "text-danger" }
                  )
                )}
                </InputGroup>
              </div>
              <div className="col-md-6">
                <InputGroup className="mt-3">
                <label>Street 2</label>
                <Form.Control
                  onChange={this.changeHandler}
                  name="street_2"
                  value={this.state.street_2}
                  className="form-control max_width_100"
                  id="inlineFormInput"
                  type="text"
                  maxLength="50"
                  autoComplete="off"
                  // placeholder="Street 2"
                  disabled={!this.state.set_custom_data ? 'true' : ''}
                />
                </InputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>Country</label>
                  <select
                    onChange={this.changeHandler}
                    name="country_slug"
                    value={this.state.country_slug}
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  >
                    <option key="" value="">
                      Select Country
                    </option>
                    {this.state.countryList
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((option) => (
                        <option key={option.slug} value={option.slug}>
                          {option.country_name}
                        </option>
                      ))}
                  </select>
                  {this.state.errors.country_slug ? (
                    <div className="text-danger">
                      {this.state.errors.country_slug}
                    </div>
                  ) : (
                    ""
                  )}
                </InputGroup>
              </div>
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>State</label>
                  <select
                    onChange={this.changeHandler}
                    name="state_slug"
                    value={this.state.state_slug}
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  >
                    <option key="" value="">
                      Select State
                    </option>
                    {this.state.stateList
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((option) => (
                        <option key={option.slug} value={option.slug}>
                          {option.state_name}
                        </option>
                      ))}
                  </select>
                  {this.state.errors.state_slug ? (
                    <div className="text-danger">
                      {this.state.errors.state_slug}
                    </div>
                  ) : (
                    ""
                  )}
                </InputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>County</label>
                  <select
                    onChange={this.changeHandler}
                    name="county_slug"
                    value={this.state.county_slug}
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  >
                    <option key="" value="">
                      Select County
                    </option>
                    {this.state.countyList
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((option) => (
                        <option key={option.slug} value={option.slug}>
                          {option.county_name}
                        </option>
                      ))}
                  </select>
                </InputGroup>
              </div>
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>City</label>
                  <select
                    onChange={this.changeHandler}
                    name="city_slug"
                    value={this.state.city_slug}
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  >
                    <option key="" value="">
                      Select City
                    </option>
                    {this.state.cityList
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((option) => (
                        <option key={option.slug} value={option.slug}>
                          {option.city_name}
                        </option>
                      ))}
                  </select>
                  {this.state.errors.city_slug ? (
                    <div className="text-danger">
                      {this.state.errors.city_slug}
                    </div>
                  ) : (
                    this.validator.message(
                      "city",
                      this.state.city_slug,
                      "max:50",
                      {
                        className: "text-danger",
                      }
                    )
                  )}
                </InputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
              <label>
                Zipcode
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="zipcode"
                onKeyPress={global.onKeyPressEvent.numberValidation}
                value={this.state.zipcode}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="5"
                autoComplete="off"
                // placeholder="Zipcode"
                disabled={!this.state.set_custom_data ? 'true' : ''}
              />
              {this.state.errors.zipcode ? (
                <div className="text-danger">{this.state.errors.zipcode}</div>
              ) : (
                this.validator.message(
                  "zipcode",
                  this.state.zipcode,
                  "max:5",
                  {
                    className: "text-danger",
                  }
                )
              )}
                </InputGroup>
              </div>
              <div className="col-md-6">
                {/* STATUS ID START */}
                <div className="d-flex align-items-center justify-content-end mg-t-50">
                    <b className={this.state.status_id ? "active" : "inactive"}>
                      {this.state.status_id ? "Active" : "Inactive"}
                    </b>
                    <div
                      className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                      onClick={() =>
                        this.setState({ status_id: !this.state.status_id })
                      }
                    >
                      <span></span>
                    </div>
                  </div>
                  {/* STATUS ID END */}
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="mr-2 mt-3 btn btn-outline-light btn-block"
                onClick={() => this.closeaddOtherAgencyModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="ml-2 mt-3 btn btn btn-success btn-block"
                onClick={this.saveOtherAgency}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit agency popup modal */}
        <Modal
          className="modal-large"
          show={this.state.editOtherAgencyModal}
          // onHide={ () => { if(window.confirm('Are you sure to exit the pop up without saving?')) this.closeeditOtherAgencyModal()} }
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <h3 className="text-center">Edit Agency</h3>
             {/* dynamic name data */}
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>
                    Agency Category <span className="text-danger">*</span>
                  </label>
                  <select
                    // className="form-control max_width_100"
                    onChange={this.changeHandler}
                    name="agency_category_slug"
                    value={this.state.agency_category_slug}
                  >
                    <option key="" value="">
                      Select Agency Category
                    </option>
                    {this.state.allAgencyCategoryList.map((option) => (
                      <option
                        className="form-control"
                        key={option.slug}
                        value={option.slug}
                      >
                        {option.agency_category_name}
                      </option>
                    ))}
                  </select>
                </InputGroup>
                {this.state.errors.agency_category_slug ? (
                <div className="text-danger">
                  {this.state.errors.agency_category_slug}
                </div>
              ) : (
                this.validator.message(
                  "agency_category",
                  this.state.agency_category_slug,
                  "required",
                  { className: "text-danger" }
                )
              )}
              </div>
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>
                    Agency Name <span className="text-danger">*</span>
                  </label>
                  <select
                    // className="form-control max_width_100"
                    onChange={this.changeHandler}
                    name="third_party_agency_slug"
                    value={this.state.third_party_agency_slug}
                  >
                    <option key="" value="">
                      Select Agency Name
                    </option>
                    {this.state.agencyList.map((option) => (
                      <option
                        className="form-control"
                        key={option.slug}
                        value={option.slug}
                      >
                        {option.third_party_agency_name}
                      </option>
                    ))}
                  </select>
                </InputGroup>
                {this.state.errors.third_party_agency_slug ? (
                <div className="text-danger">
                  {this.state.errors.third_party_agency_slug}
                </div>
              ) : (
                this.validator.message(
                  "agency_name",
                  this.state.third_party_agency_slug,
                  "required",
                  { className: "text-danger" }
                )
              )}
              </div>

            </div>
            <div className="col-md-5 col-lg-5 mg-t-20 pd-l-2">
              <Form.Check
                name="set_custom_data"
                type={"checkbox"}
                id={"set_custom_data"}
                label={"Set Custom Data"}
                className={"ckbox"}
                onChange={this.changeHandler}
                checked={this.state.set_custom_data}
              />
            </div>

            <div className="row mg-l-3 mg-r-3">
                <InputGroup className="mt-3">
                  <label>
                    Custom Agency Name <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="third_party_agency_name"
                    value={this.state.third_party_agency_name}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    autoComplete="off"
                    // placeholder="Custom Agency Name"
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  />
                {this.state.errors.third_party_agency_name ? (
                <div className="text-danger">
                  {this.state.errors.third_party_agency_name}
                </div>
              ) : (
                this.validator.message(
                  "third_party_agency_name",
                  this.state.third_party_agency_name,
                  "required",
                  { className: "text-danger" }
                )
              )}
              </InputGroup>
            </div>
            {/* common contact and location info */}
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>
                    Contact Name
                  </label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="contact_name"
                    value={this.state.contact_name}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    // placeholder="Contact Name"
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  />
                  {this.state.errors.contact_name ? (
                    <div className="text-danger">
                      {this.state.errors.contact_name}
                    </div>
                  ) : (
                    this.validator.message(
                      "contact_name",
                      this.state.contact_name,
                      "max:50",
                      {
                        className: "text-danger",
                      }
                    )
                  )}
                </InputGroup>
              </div>
              <div className="col-md-6">
                  <InputGroup className="mt-3">
                    <label>
                      Phone
                    </label>
                    <PhoneInput
                      country={global.country}
                      disableSearchIcon
                      // autoFormat= "true"
                      enableSearch= "true"
                      countryCodeEditable= "true"
                      value={this.state.phone_number}
                      onChange={this.onChangePhone}

                      disabled={!this.state.set_custom_data ? 'true' : ''}
                    />
                    {this.state.errors.phone_number ? (
                      <div className="text-danger">
                        {this.state.errors.phone_number}
                      </div>
                    ) : ''}
                  </InputGroup>
              </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                  <InputGroup className="mt-3">
                    <label>
                      Email
                    </label>
                    <Form.Control
                      onChange={this.changeHandler}
                      name="email"
                      value={this.state.email}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      // placeholder="Email"
                      disabled={!this.state.set_custom_data ? 'true' : ''}
                    />
                    {this.state.errors.email ? (
                      <div className="text-danger">{this.state.errors.email}</div>
                    ) : (
                      this.validator.message("email", this.state.email, "email", {
                        className: "text-danger",
                      })
                    )}
                  </InputGroup>
                </div>
                {/* <div className="col-md-6">
                  <InputGroup className="mt-3">
                    <label>Sort Order</label>
                    <Form.Control
                      onChange={this.changeHandler}
                      name="sort_order"
                      value={this.state.sort_order}
                      onKeyPress={global.onKeyPressEvent.numberValidation}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      // placeholder="Sort Order"
                      disabled={!this.state.set_custom_data ? 'true' : ''}
                    />
                    {this.state.errors.sort_order ? (
                      <div className="text-danger">{this.state.errors.sort_order}</div>
                    ) : (
                      this.validator.message(
                        "sort_order",
                        this.state.sort_order,
                        "numeric|min:0,num",
                        { className: "text-danger" }
                      )
                    )}
                  </InputGroup>
                </div> */}
            </div>
            <hr className="mg-t-35 mg-b-5 pd-b-2" />
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
                <label>
                  Street 1
                </label>
                <Form.Control
                  onChange={this.changeHandler}
                  name="street_1"
                  value={this.state.street_1}
                  className="form-control max_width_100"
                  id="inlineFormInput"
                  type="text"
                  maxLength="50"
                  autoComplete="off"
                  // placeholder="Street 1"
                  disabled={!this.state.set_custom_data ? 'true' : ''}
                />
                {this.state.errors.street_1 ? (
                  <div className="text-danger">{this.state.errors.street_1}</div>
                ) : (
                  this.validator.message(
                    "street_1",
                    this.state.street_1,
                    "max:100",
                    { className: "text-danger" }
                  )
                )}
                </InputGroup>
              </div>
              <div className="col-md-6">
                <InputGroup className="mt-3">
                <label>Street 2</label>
                <Form.Control
                  onChange={this.changeHandler}
                  name="street_2"
                  value={this.state.street_2}
                  className="form-control max_width_100"
                  id="inlineFormInput"
                  type="text"
                  maxLength="50"
                  autoComplete="off"
                  // placeholder="Street 2"
                  disabled={!this.state.set_custom_data ? 'true' : ''}
                />
                </InputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>Country</label>
                  <select
                    onChange={this.changeHandler}
                    name="country_slug"
                    value={this.state.country_slug}
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  >
                    <option key="" value="">
                      Select Country
                    </option>
                    {this.state.countryList
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((option) => (
                        <option key={option.slug} value={option.slug}>
                          {option.country_name}
                        </option>
                      ))}
                  </select>
                  {this.state.errors.country_slug ? (
                    <div className="text-danger">
                      {this.state.errors.country_slug}
                    </div>
                  ) : (
                    ""
                  )}
                </InputGroup>
              </div>
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>State</label>
                  <select
                    onChange={this.changeHandler}
                    name="state_slug"
                    value={this.state.state_slug}
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  >
                    <option key="" value="">
                      Select State
                    </option>
                    {this.state.stateList
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((option) => (
                        <option key={option.slug} value={option.slug}>
                          {option.state_name}
                        </option>
                      ))}
                  </select>
                  {this.state.errors.state_slug ? (
                    <div className="text-danger">
                      {this.state.errors.state_slug}
                    </div>
                  ) : (
                    ""
                  )}
                </InputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>County</label>
                  <select
                    onChange={this.changeHandler}
                    name="county_slug"
                    value={this.state.county_slug}
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  >
                    <option key="" value="">
                      Select County
                    </option>
                    {this.state.countyList
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((option) => (
                        <option key={option.slug} value={option.slug}>
                          {option.county_name}
                        </option>
                      ))}
                  </select>
                </InputGroup>
              </div>
              <div className="col-md-6">
                <InputGroup className="mt-3">
                  <label>City</label>
                  <select
                    onChange={this.changeHandler}
                    name="city_slug"
                    value={this.state.city_slug}
                    disabled={!this.state.set_custom_data ? 'true' : ''}
                  >
                    <option key="" value="">
                      Select City
                    </option>
                    {this.state.cityList
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((option) => (
                        <option key={option.slug} value={option.slug}>
                          {option.city_name}
                        </option>
                      ))}
                  </select>
                  {this.state.errors.city_slug ? (
                    <div className="text-danger">
                      {this.state.errors.city_slug}
                    </div>
                  ) : (
                    this.validator.message(
                      "city",
                      this.state.city_slug,
                      "max:50",
                      {
                        className: "text-danger",
                      }
                    )
                  )}
                </InputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <InputGroup className="mt-3">
              <label>
                Zipcode
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="zipcode"
                onKeyPress={global.onKeyPressEvent.numberValidation}
                value={this.state.zipcode}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="5"
                autoComplete="off"
                // placeholder="Zipcode"
                disabled={!this.state.set_custom_data ? 'true' : ''}
              />
              {this.state.errors.zipcode ? (
                <div className="text-danger">{this.state.errors.zipcode}</div>
              ) : (
                this.validator.message(
                  "zipcode",
                  this.state.zipcode,
                  "max:5",
                  {
                    className: "text-danger",
                  }
                )
              )}
                </InputGroup>
              </div>
              <div className="col-md-6">
                {/* STATUS ID START */}
                <div className="d-flex align-items-center justify-content-end mg-t-50">
                    <b className={this.state.status_id ? "active" : "inactive"}>
                      {this.state.status_id ? "Active" : "Inactive"}
                    </b>
                    <div
                      className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                      onClick={() =>
                        this.setState({ status_id: !this.state.status_id })
                      }
                    >
                      <span></span>
                    </div>
                  </div>
                  {/* STATUS ID END */}
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="mr-2 mt-3 btn btn-outline-light btn-block"
                onClick={() => this.closeeditOtherAgencyModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="ml-2 mt-3 btn btn btn-success btn-block"
                onClick={this.updateOtherAgency}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete agency popup modal */}
        <Modal
          show={this.state.deleteOtherAgencyModal}
          // onHide={ () => { if(window.confirm('Are you sure to exit the pop up without saving?')) this.closedeleteOtherAgencyModal()} }
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteOtherAgencyModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteOtherAgency(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default PropertyVendors

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ResidentView.jsx                             //
//  Application: Residents                                //
//  Option: For view Resident details                     //
//  Developer: NP				                                  //
//  Date: 2022-04-20                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import ResidentService from "../../services/ResidentService";
import LeasingSidebar from "../common/LeasingSidebar";
import Alerts from "../common/Alerts";
import { Link } from "react-router-dom";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import { Modal } from "react-bootstrap";
import hostImg from "./../../assets/images/profile-nogender.png";
import ReactDatatable from "@ashvin27/react-datatable";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Moment from "moment";
import NoData from "../common/NoData";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";
import copyIcon from "./../../assets/images/copy.png";

export class ResidentView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleSwitchDark2: true,
      addModal: false,
      showSpinner: false,
      loading: false,
      deleteGuestModal: false,
      status_id: 0,
      confirm_back: 0,
      resident_slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      pathname: window.location.pathname,
      imageUrl: "",
      city_name1: "",
      state_name1: "",
      county_name1: "",
      country_name1: "",
      cleanerListDetails1: [],
      webhookDetails1: [],
      cleanerListCount: 0,
      webhookCount: 0,
      copySuccess: "",
      street_22: "",
      street_12: "",
      zip_code: "",
      activeClass: "guest-info",
      recordsTotal: 0,
      access_token: "",
      accepting_turno_payment: false,
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_number",
          text: "Unit",
          className: "unit_id hand-cursor td_clickable",
          sortable: false,
          cell: (record) => {
            return record.unit && record.unit.unit_name
              ? record.unit.unit_name
              : record.unit.unit_number;
          },
        },
        {
          key: "type",
          text: "Type",
          className: "type hand-cursor td_clickable",
          sortable: false,
          cell: (record) => {
            return record.type === 1 ? "Month to Month" : "Fixed";
          },
        },
        {
          key: "lease_start_date",
          text: "Start Date",
          className: "lease_start_date hand-cursor td_clickable",
          sortable: false,
          cell: (record) => {
            return record.lease_start_date
              ? Moment(record.lease_start_date, "MM/DD/YYYY").format(
                  global.dateFormat
                )
              : "";
          },
        },
        {
          key: "lease_end_date",
          text: "End Date",
          className: "lease_end_date hand-cursor td_clickable",
          sortable: false,
          cell: (record) => {
            return record.lease_end_date
              ? Moment(record.lease_end_date, "MM/DD/YYYY").format(
                  global.dateFormat
                )
              : "";
          },
        },
        {
          key: "lease_signed_date",
          text: "Signed Date",
          className: "lease_signed_date hand-cursor td_clickable",
          sortable: false,
          cell: (record) => {
            return record.lease_signed_date
              ? Moment(record.lease_signed_date, "MM/DD/YYYY").format(
                  global.dateFormat
                )
              : "";
          },
        },
        {
          key: "monthly_rent",
          text: "Rent",
          className: "monthly_rent hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.monthly_rent
              ? "$" +
                  global.onKeyPressEvent.numberWithCommasForDecimal(
                    record.monthly_rent.replace(/\.00$/, "")
                  )
              : "";
          },
        },
        {
          key: "deposit",
          text: "Deposit",
          className: "deposit hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.deposit
              ? "$" +
                  global.onKeyPressEvent.numberWithCommasForDecimal(
                    record.deposit.replace(/\.00$/, "")
                  )
              : "";
          },
        },
      ],
    };
    this.opendeleteGuestModal = this.opendeleteGuestModal.bind(this);
    this.closedeleteGuestModal = this.closedeleteGuestModal.bind(this);
    this.deleteGuest = this.deleteGuest.bind(this);
    this.copyToken = this.copyToken.bind(this);
  }

  async componentDidMount() {
    if (this.state.resident_slug !== undefined) {
      var resData = {};
      var records = [];
      var recordsTotal = 0;
      let res = await ResidentService.getResident(this.state.resident_slug);
      if (global.successStatus.includes(res.status)) {
        resData.first_name = res.data ? res.data.first_name : "";
        resData.last_name = res.data ? res.data.last_name : "";
        resData.email = res.data ? res.data.email : "";
        if (res.data && res.data.phone_number) {
          this.phoneNumberAutoFormat(res.data.phone_number);
        }
        resData.imageUrl = res.data
          ? res.data.resident_photo_thumb_url
          : hostImg;
        resData.street_1 = res.data ? res.data.street_1 : "";
        resData.street_2 = res.data ? res.data.street_2 : "";
        resData.zipcode = res.data.zip_code ? res.data.zip_code : "";
        resData.country_name =
          res.data && res.data.country ? res.data.country.country_name : "";
        resData.city_name =
          res.data && res.data.city ? res.data.city.city_name : "";
        resData.county_name =
          res.data && res.data.county ? res.data.county.county_name : "";
        resData.state_name =
          res.data && res.data.state ? res.data.state.state_name : "";
        resData.business_name =
          res.data && res.data.w9info ? res.data.w9info.business_name : "";
        resData.bank_name =
          res.data && res.data.w9info ? res.data.w9info.bank_name : "";
        resData.last4 =
          res.data && res.data.w9info ? res.data.w9info.last4 : "";
        resData.external_object =
          res.data && res.data.w9info ? res.data.w9info.external_object : "";
        resData.external_brand =
          res.data && res.data.w9info ? res.data.w9info.external_brand : "";
        resData.exp_month =
          res.data && res.data.w9info ? res.data.w9info.exp_month : "";
        resData.exp_year =
          res.data && res.data.w9info ? res.data.w9info.exp_year : "";
        resData.stripe_account_id =
          res.data && res.data.w9info ? res.data.w9info.account_number : "";
        resData.paye_code =
          res.data && res.data.w9info ? res.data.w9info.paye_code : "";
        resData.fatca_code =
          res.data && res.data.w9info ? res.data.w9info.fatca_code : "";
        resData.account_number =
          res.data && res.data.w9info ? res.data.w9info.account_number : "";
        resData.ssn_number =
          res.data && res.data.w9info ? res.data.w9info.ssn_number : "";
        resData.ein_number =
          res.data && res.data.w9info ? res.data.w9info.ein_number : "";

        resData.is_llc =
          res.data && res.data.w9info ? res.data.w9info.is_llc : "";
        resData.is_c_corporation =
          res.data && res.data.w9info ? res.data.w9info.is_c_corporation : "";
        resData.is_s_corporation =
          res.data && res.data.w9info ? res.data.w9info.is_s_corporation : "";
        resData.is_partnership =
          res.data && res.data.w9info ? res.data.w9info.is_partnership : "";
        resData.is_trust =
          res.data && res.data.w9info ? res.data.w9info.is_trust : "";
        resData.is_new_llc =
          res.data && res.data.w9info ? res.data.w9info.is_new_llc : "";

        resData.is_new_llc_note =
          res.data && res.data.w9info ? res.data.w9info.is_new_llc_note : "";
        resData.is_other =
          res.data && res.data.w9info ? res.data.w9info.is_other : "";
        resData.is_other_note =
          res.data && res.data.w9info ? res.data.w9info.is_other_note : "";

        resData.signature_date = res.data.w9info
          ? Moment(res.data.w9info.signature_date).format("MM/DD/YY")
          : "";
        resData.signature_name = res.data.w9info
          ? res.data.w9info.signature_name
          : "";

        resData.street_12 = res.data.w9info ? res.data.w9info.street_1 : "";
        resData.street_22 = res.data.w9info ? res.data.w9info.street_2 : "";
        resData.zipcode1 = res.data.w9info ? res.data.w9info.zipcode : "";
        resData.country_name1 =
          res.data.w9info && res.data.w9info.country
            ? res.data.w9info.country.country_name
            : res.data.w9info
            ? res.data.w9info.country_name
            : "";
        resData.city_name1 =
          res.data.w9info && res.data.w9info.city
            ? res.data.w9info.city.city_name
            : res.data.w9info
            ? res.data.w9info.city_name
            : "";
        resData.county_name1 =
          res.data.w9info && res.data.w9info.county
            ? res.data.w9info.county.county_name
            : res.data.w9info
            ? res.data.w9info.county_name
            : "";
        resData.state_name1 =
          res.data.w9info && res.data.w9info.state
            ? res.data.w9info.state.state_name
            : res.data.w9info
            ? res.data.w9info.state_name
            : "";
        resData.disgraded_entity_name = res.data.w9info
          ? res.data.w9info.disgraded_entity_name
          : "";

        resData.delivery_to_mail = res.data.w9info
          ? res.data.w9info.delivery_to_mail
          : "";
        resData.delivery_electronically = res.data.w9info
          ? res.data.w9info.delivery_electronically
          : "";
        resData.accepting_turno_payment =
          res.data &&
          res.data.tbnbDetails &&
          res.data.tbnbDetails.is_payment_done &&
          res.data.tbnbDetails.is_payment_done === 1
            ? true
            : false;
        resData.access_token = res.data.tbnbDetails
          ? res.data.tbnbDetails.access_token
          : "";
        resData.created_at = res.data.tbnbDetails
          ? res.data.tbnbDetails.created_at
          : "";
        resData.tokenExpire = res.data.tbnbDetails
          ? res.data.tbnbDetails.tokenExpire
          : "";
        resData.status_id =
          res.data.tbnbDetails && res.data.tbnbDetails.status_id === 1
            ? "Success"
            : res.data.tbnbDetails && res.data.tbnbDetails.status_id === 2
            ? "Pending"
            : res.data.tbnbDetails && res.data.tbnbDetails.status_id === 3
            ? "Error"
            : "";
        var selectedUnitList = [];
        // var selectedStatusList = [];
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_llc &&
          res.data.w9info.is_llc === 1
        ) {
          selectedUnitList = "LLC";
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_c_corporation &&
          res.data.w9info.is_c_corporation === 1
        ) {
          selectedUnitList = "C Corporation";
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_s_corporation &&
          res.data.w9info.is_s_corporation === 1
        ) {
          selectedUnitList = "S Corporation";
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_partnership &&
          res.data.w9info.is_partnership === 1
        ) {
          selectedUnitList = "Partnership";
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_trust &&
          res.data.w9info.is_trust === 1
        ) {
          selectedUnitList = "Trust/Estate";
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_new_llc &&
          res.data.w9info.is_new_llc === 1
        ) {
          selectedUnitList = "Limited Liability Company";
        }
        if (
          res.data &&
          res.data.w9info &&
          res.data.w9info.is_other &&
          res.data.w9info.is_other === 1
        ) {
          selectedUnitList = "Other";
        }

        records =
          res.data && res.data.getall_lease ? res.data.getall_lease : [];
        recordsTotal = records && records.length ? records.length : 0;
      }
      this.setState(resData);
      this.setState({ records: records, recordsTotal: recordsTotal });
      this.setState({
        selectedUnitList: selectedUnitList,
      });
      this.getResidentPropertyCleanerListDetails();
    }
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ phone_number: finalnumber });
  };

  async copyToken(accessToken) {
    navigator.clipboard.writeText(accessToken).then(
      setTimeout(
        function () {
          this.setState({ copySuccess: "Copied!" });
        }.bind(this),
        1000
      )
    );
    const box = document.getElementById("copy_token");
    box.style.display = "block";
    setTimeout(() => {
      const box = document.getElementById("copy_token");
      box.style.display = "none";
    }, 5000);
  }

  opendeleteGuestModal(slug) {
    this.setState({ deleteGuestModal: true, slug: slug });
  }

  closedeleteGuestModal() {
    this.setState({ deleteGuestModal: false, slug: "" });
  }

  async deleteGuest(slug) {
    this.setState({ showSpinner: true });
    let res = await ResidentService.deleteResident(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteGuestModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          (window.location =
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" + this.state.property_slug + "/residents"
              : "/residents"),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteGuestModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  getTitle() {
    return "Residents";
  }

  async getResidentPropertyCleanerListDetails() {
    let res = await ResidentService.getResidentPropertyCleanerListDetails(
      "resident_slug=" + this.state.resident_slug
    );
    var cleanerListDetailsData = "";
    var webhookDetailsData = "";
    if (global.successStatus.includes(res.status)) {
      cleanerListDetailsData =
        res.data && res.data.cleanerListDetailsData
          ? res.data.cleanerListDetailsData
          : [];
      webhookDetailsData =
        res.data && res.data.webhookSubscriptionDetails
          ? res.data.webhookSubscriptionDetails
          : [];
      // console.log("cleaner :" +cleanerListDetailsData);
      this.setState({
        cleanerListDetails1: cleanerListDetailsData,
        webhookDetails1: webhookDetailsData,
        cleanerListCount: cleanerListDetailsData.length,
        webhookCount: webhookDetailsData.length,
      });
    }
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "/properties/view/" +
            this.state.property_slug +
            "/leasing/" +
            data.slug +
            "/info"
        : "/leasing/view/" + this.state.property_slug + "/" + data.slug
    );
  };

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LeasingSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLeasingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}

              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {global.userPermissions.checkPermission(
                        "residents-delete"
                      ) && (
                        <span
                          onClick={() => {
                            this.opendeleteGuestModal(this.state.resident_slug);
                          }}
                          className="deleteicon mg-r-20 hand-cursor"
                        >
                          <img src={deleteicon} alt="Delete" />
                        </span>
                      )}
                      {global.userPermissions.checkPermission(
                        "residents-update"
                      ) && (
                        <Link
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/residents/update/" +
                                this.state.resident_slug
                              : "/residents/update/" +
                                this.state.property_slug +
                                "/" +
                                this.state.resident_slug
                          }
                        >
                          <button
                            type="button"
                            className="btn-success-outline-small"
                          >
                            Edit
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15 mg-t-0">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="guest-info"
                    onSelect={(e) => this.getActiveClass(e)}
                    eventKey={this.state.activeClass}
                  >
                    <ScrollingCarousel
                      rightArrow={true}
                      leftArrow={true}
                      className="swipesection"
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="guest-info"
                          id="infoForm"
                          className={
                            this.state.activeClass === "guest-info"
                              ? "active"
                              : ""
                          }
                        >
                          Info
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="resident-leases"
                          id="resident-leases"
                          className={
                            this.state.activeClass === "resident-leases"
                              ? "active"
                              : ""
                          }
                        >
                          Leases
                        </Nav.Link>
                      </Nav.Item>
                    </ScrollingCarousel>
                    <Tab.Content>
                      <Tab.Pane eventKey="guest-info">
                        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                          <div className="col-md-12 pd-l-30 pd-r-30">
                            <Alerts
                              show={this.state.showAlertModal}
                              type={this.state.alertModalType}
                              title={this.state.alertModalTitle}
                              message={this.state.alertModalMessage}
                            />
                          </div>
                          <div className="m-0">
                            <div className="col-lg-12  pd-xs-r-0 pd-lg-r-30 pd-xs-l-30 pd-t-20">
                              <h3 className="mg-l-0 background-head">
                                Contact Info
                              </h3>
                            </div>
                            <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-r-15 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <div className="demo-avatar-group d-flex justify-content-left align-items-start flex-column">
                                  <div className="az-img-user avatar-md online mg-b-8">
                                    <figure>
                                      <img
                                        src={
                                          this.state.imageUrl
                                            ? this.state.imageUrl
                                            : hostImg
                                        }
                                        alt=""
                                        className="rounded-circle"
                                      />
                                    </figure>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Name
                                </p>
                                <p className="media-body">
                                  {this.state.first_name
                                    ? this.state.first_name +
                                      " " +
                                      this.state.last_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Phone
                                </p>
                                <p className="media-body">
                                  {this.state.phone_number
                                    ? this.state.phone_number
                                    : ""}{" "}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Email{" "}
                                </p>
                                <p className="media-body">
                                  {this.state.email ? this.state.email : ""}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-lg-r-15 pd-xs-l-15">
                              <h3 className="mg-l-0 background-head">
                                Address
                              </h3>
                            </div>
                            <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-r-15 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Street 1
                                </p>
                                <p className="media-body">
                                  {this.state.street_1
                                    ? this.state.street_1
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Street 2
                                </p>
                                <p className="media-body">
                                  {this.state.street_2
                                    ? this.state.street_2
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  City
                                </p>
                                <p className="media-body">
                                  {this.state.city_name
                                    ? this.state.city_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  State
                                </p>
                                <p className="media-body">
                                  {this.state.state_name
                                    ? this.state.state_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Zip Code
                                </p>
                                <p className="media-body">
                                  {this.state.zipcode ? this.state.zipcode : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  County
                                </p>
                                <p className="media-body">
                                  {this.state.county_name
                                    ? this.state.county_name
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Country
                                </p>
                                <p className="media-body">
                                  {this.state.country_name
                                    ? this.state.country_name
                                    : ""}
                                </p>
                              </div>
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-lg-12 pd-xs-r-0 pd-lg-r-30 pd-lg-r-15 pd-xs-l-15 mg-b-20">
                                  <h3 className="mg-l-0 background-head">
                                    Banking Information
                                  </h3>
                                </div>
                              )}
                              {this.state.external_object === "bank_account" &&
                                global.userPermissions.checkPermission(
                                  "residents-w9-view"
                                ) && (
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Bank Name
                                    </p>
                                    <p className="media-body">
                                      {this.state.bank_name
                                        ? this.state.bank_name
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              {this.state.external_object === "bank_account" &&
                                global.userPermissions.checkPermission(
                                  "residents-w9-view"
                                ) && (
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Bank Account Number
                                    </p>
                                    <p className="media-body">
                                      {this.state.last4
                                        ? "************" + this.state.last4
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    Stripe Account ID
                                  </p>
                                  <p className="media-body">
                                    {this.state.stripe_account_id
                                      ? this.state.stripe_account_id
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {this.state.external_object === "card" &&
                                global.userPermissions.checkPermission(
                                  "residents-w9-view"
                                ) && (
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Brand
                                    </p>
                                    <p className="media-body">
                                      {this.state.external_brand
                                        ? this.state.external_brand
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              {this.state.external_object === "card" &&
                                global.userPermissions.checkPermission(
                                  "residents-w9-view"
                                ) && (
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Card Number
                                    </p>
                                    <p className="media-body">
                                      {this.state.last4
                                        ? "**********" + this.state.last4
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              {this.state.external_object === "card" &&
                                global.userPermissions.checkPermission(
                                  "residents-w9-view"
                                ) && (
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Expiry Month
                                    </p>
                                    <p className="media-body">
                                      {this.state.exp_month
                                        ? this.state.exp_month
                                        : ""}
                                    </p>
                                  </div>
                                )}
                              {this.state.external_object === "card" &&
                                global.userPermissions.checkPermission(
                                  "residents-w9-view"
                                ) && (
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                    <p className="az-profile-name-text pl-0">
                                      Expiry Year
                                    </p>
                                    <p className="media-body">
                                      {this.state.exp_year
                                        ? this.state.exp_year
                                        : ""}
                                    </p>
                                  </div>
                                )}

                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-lg-12 pd-xs-r-0 pd-lg-r-30 pd-lg-r-15 pd-xs-l-15 mg-b-20">
                                  <h3 className="mg-l-0 background-head">
                                    W9 Info
                                  </h3>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    SSN
                                  </p>
                                  <p className="media-body">
                                    {this.state.ssn_number
                                      ? this.state.ssn_number
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    <label className="form-label text-left pd-b-5">
                                      Name or Business name
                                      <span className="optional">
                                        (if different)
                                      </span>
                                    </label>
                                  </p>
                                  <p className="media-body">
                                    {this.state.business_name
                                      ? this.state.business_name
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    EIN
                                  </p>
                                  <p className="media-body">
                                    {this.state.ein_number
                                      ? this.state.ein_number
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    Tax classification
                                  </p>
                                  <p className="media-body">
                                    {this.state.selectedUnitList
                                      ? this.state.selectedUnitList
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    Disregarded entity name
                                  </p>
                                  <p className="media-body">
                                    {this.state.disgraded_entity_name
                                      ? this.state.disgraded_entity_name
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    Street Address
                                  </p>
                                  <p className="media-body">
                                    {this.state.street_12
                                      ? this.state.street_12
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    Apt. no./House no.
                                  </p>
                                  <p className="media-body">
                                    {this.state.street_22
                                      ? this.state.street_22
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    City
                                  </p>
                                  <p className="media-body">
                                    {this.state.city_name1
                                      ? this.state.city_name1
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    Zip Code
                                  </p>
                                  <p className="media-body">
                                    {this.state.zipcode1
                                      ? this.state.zipcode1
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    State
                                  </p>
                                  <p className="media-body">
                                    {this.state.state_name1
                                      ? this.state.state_name1
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    Country
                                  </p>
                                  <p className="media-body">
                                    {this.state.country_name1
                                      ? this.state.country_name1
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    Signed Date{" "}
                                  </p>
                                  <p className="media-body">
                                    {this.state.signature_date
                                      ? this.state.signature_date
                                      : ""}
                                  </p>
                                </div>
                              )}
                              {global.userPermissions.checkPermission(
                                "residents-w9-view"
                              ) && (
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                  <p className="az-profile-name-text pl-0">
                                    {" "}
                                    Your Signature{" "}
                                  </p>
                                  <p className="media-body">
                                    {this.state.signature_name
                                      ? this.state.signature_name
                                      : ""}
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-lg-r-15 pd-xs-l-15">
                              <h3 className="mg-l-0 background-head">
                                TurnoverBnb Details
                              </h3>
                            </div>
                            <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-r-15 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15">
                              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <p className="az-profile-name-text pl-0">
                                  Access Token
                                </p>
                                {this.state.access_token !== undefined &&
                                this.state.access_token !== "" ? (
                                  <img
                                    src={copyIcon}
                                    alt="Copy"
                                    onClick={() =>
                                      this.copyToken(this.state.access_token)
                                    }
                                    className="hand-cursor copyClass dashboardRightcard"
                                  />
                                ) : (
                                  ""
                                )}
                                <p id="copy_token">{this.state.copySuccess}</p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Accepting Turno Payment
                                </p>
                                <p className="media-body ">
                                  {this.state.accepting_turno_payment === true
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </div>
                            <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-r-15 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15">
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Expire Time
                                </p>
                                <p className="media-body">
                                  {this.state.tokenExpire
                                    ? this.state.tokenExpire + " Days"
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Status
                                </p>
                                <p className="media-body">
                                  {this.state.status_id
                                    ? this.state.status_id
                                    : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Created Time
                                </p>
                                <p className="media-body">
                                  {this.state.created_at
                                    ? Moment(this.state.created_at).format(
                                        global.dateTimeFormat
                                      )
                                    : ""}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-lg-r-15 pd-xs-l-15">
                              <h3 className="mg-l-0 background-head">
                                Property And Cleaner Details
                              </h3>
                            </div>
                            <div
                              className="row align-items-center pd-b-0 border-bottom-0 pd-r-15 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15 mg-t-25
                             mapp_head_desk"
                            >
                              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 mg-b-0">
                                <p className="az-profile-name-text pl-0">
                                  Unit#
                                </p>
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 mg-b-0">
                                <p className="az-profile-name-text pl-0">
                                  TurnoverBnB Property ID
                                </p>
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 mg-b-0">
                                <p className="az-profile-name-text pl-0">
                                  TurnoverBnB Cleaner ID
                                </p>
                              </div>
                            </div>

                            {this.state.cleanerListCount > 0 ? (
                              this.state.cleanerListDetails1.map((item, i) => {
                                return (
                                  <div
                                    className="row align-items-center pd-b-5 border-bottom-0 mg-t-0 pd-r-15 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15"
                                    key={i}
                                    value={item}
                                  >
                                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-b-5">
                                      <p className="az-profile-name-text pl-0 mapp_head_mob">
                                        Unit#
                                      </p>
                                      <p className="media-body trun_text_p mb-0">
                                        {item.unit_name ? item.unit_name : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 mg-b-5">
                                      <p className="az-profile-name-text pl-0 mapp_head_mob">
                                        TurnoverBnB Property ID
                                      </p>
                                      <p className="media-body trun_text_p mb-0">
                                        {item.key_id ? item.key_id : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 mg-b-5">
                                      <p className="az-profile-name-text pl-0 mapp_head_mob">
                                        TurnoverBnB Cleaner ID
                                      </p>
                                      <p className="media-body trun_text_p mb-0">
                                        {item.tbnb_cleaner_id
                                          ? item.tbnb_cleaner_id
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="mg-t-30 text-center">
                                <p>No data found</p>
                              </div>
                            )}
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-lg-r-15 pd-xs-l-15">
                              <h3 className="mg-l-0 background-head">
                                Webhook Subscription Details
                              </h3>
                            </div>
                            <div
                              className="row align-items-center pd-b-0 border-bottom-0 pd-r-15 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15 mg-t-25
                              mapp_head_desk"
                            >
                              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 mg-b-0">
                                <p className="az-profile-name-text pl-0">
                                  Type
                                </p>
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 mg-b-0">
                                <p className="az-profile-name-text pl-0">
                                  Status
                                </p>
                              </div>
                            </div>

                            {this.state.webhookCount > 0 ? (
                              this.state.webhookDetails1.map((item, i) => {
                                return (
                                  <div
                                    className="row align-items-center pd-b-5 border-bottom-0 mg-t-0 pd-r-15 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15"
                                    key={i}
                                    value={item}
                                  >
                                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 mg-b-5">
                                      <p className="az-profile-name-text pl-0 mapp_head_mob">
                                        Type
                                      </p>
                                      <p className="media-body trun_text_p m-0">
                                        {item.subscription_type_id === 1
                                          ? "Project completions"
                                          : item.subscription_type_id === 2
                                          ? "Project assignment"
                                          : item.subscription_type_id === 3
                                          ? "Project started"
                                          : item.subscription_type_id === 5
                                          ? "Problem is created"
                                          : item.subscription_type_id === 6
                                          ? "Problem status updated"
                                          : item.subscription_type_id === 10
                                          ? "Add/Remove payment details"
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mg-t-5 mg-md-t-0 mg-b-5">
                                      <p className="az-profile-name-text pl-0 mapp_head_mob">
                                        Status
                                      </p>
                                      <p className="media-body trun_text_p m-0">
                                        {item.status_id === 1
                                          ? "Success"
                                          : item.status_id == 2
                                          ? "Failed"
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="mg-t-30 text-center">
                                <p>No data found</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="resident-leases">
                        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                          {this.state.recordsTotal > 0 ? (
                            <div className="mg-t-0 brandList">
                              <ReactDatatable
                                className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                config={
                                  this.state.recordsTotal > global.page_size
                                    ? this.state.config
                                    : this.state.config
                                }
                                records={this.state.records}
                                columns={this.state.columns}
                                extraButtons={this.state.extraButtons}
                                dynamic={true}
                                // loading={this.state.loading}
                                total_record={this.state.recordsTotal}
                                onChange={this.tableChangeHandler}
                                onRowClicked={this.rowClickedHandler}
                              />
                            </div>
                          ) : (
                            <NoData />
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>{" "}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteGuestModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteGuestModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteGuestModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteGuest(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default ResidentView;

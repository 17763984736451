////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PropertyPriceForm.jsx                        //
//  Application:  Property                                //
//  Option: For add or update Property Price              //
//  Developer: Np                                         //
//  Date: 2022-02-12                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import {
  Container,
  Form,
  Card,
  Accordion,
  Button,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import PropertyPriceService from "../../services/PropertyPriceService";
import propertyLogo from "./../../assets/images/property_placeholder.png"
import Alerts from "../common/Alerts";

export class PropertyPriceForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      property_logo_url: sessionStorage.getItem('property_logo_url'),
      property_type: sessionStorage.getItem('property_type'),
      property_name: sessionStorage.getItem('property_name'),
      year_value: sessionStorage.getItem('year_value') ? sessionStorage.getItem('year_value') : '',
      isSubmit: false,
      minimum_price: "",
      maximum_price: "",
      base_price_flat: "",
      currency_code: "",
      pricing_type_id: "",
      sort_order: "",
      pathname: window.location.pathname,
      currencyCode: global.currencyCode ? global.currencyCode : [],
      pricingType: global.pricingType ? global.pricingType : [],
      weekly_data: [
        {
          day: 1,
          label: "Monday",
          price: "",
        },
        {
          day: 2,
          label: "Tuesday",
          price: "",
        },
        {
          day: 3,
          label: "Wednesday",
          price: "",
        },
        {
          day: 4,
          label: "Thursday",
          price: "",
        },
        {
          day: 5,
          label: "Friday",
          price: "",
        },
        {
          day: 6,
          label: "Saturday",
          price: "",
        },
        {
          day: 7,
          label: "Sunday",
          price: "",
        },
      ],
      monthly_data: [
        {
          day: 1,
          label: "January",
          price: "",
        },
        {
          day: 2,
          label: "February",
          price: "",
        },
        {
          day: 3,
          label: "March",
          price: "",
        },
        {
          day: 4,
          label: "April",
          price: "",
        },
        {
          day: 5,
          label: "May",
          price: "",
        },
        {
          day: 6,
          label: "June",
          price: "",
        },
        {
          day: 7,
          label: "July",
          price: "",
        },
        {
          day: 8,
          label: "August",
          price: "",
        },
        {
          day: 9,
          label: "September",
          price: "",
        },
        {
          day: 10,
          label: "October",
          price: "",
        },
        {
          day: 11,
          label: "November",
          price: "",
        },
        {
          day: 12,
          label: "December",
          price: "",
        }
      ],
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdatePrice = this.saveOrUpdatePrice.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await PropertyPriceService.getPriceBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.minimum_price = res.data.minimum_price ? res.data.minimum_price : "";
        resData.maximum_price = res.data.maximum_price ? res.data.maximum_price : "";
        resData.currency_code = res.data.currency_code ? res.data.currency_code : "";
        resData.pricing_type_id = res.data.pricing_type_id ? res.data.pricing_type_id : "";
        resData.base_price_flat = res.data.base_price_flat ? res.data.base_price_flat : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdatePrice = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        minimum_price: this.state.minimum_price,
        maximum_price: this.state.maximum_price,
        currency_code: this.state.currency_code,
        base_price_flat: this.state.base_price_flat,
        base_price_weekly: this.state.weekly_data,
        base_price_monthly: this.state.monthly_data,
        pricing_type_id: this.state.pricing_type_id,
        property_slug: this.state.property_slug,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await PropertyPriceService.updatePrice(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/property-pricing"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await PropertyPriceService.createPrice(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/property-pricing"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    //for weekly data
    let weekly_data1 = this.state.weekly_data;
    if (event.target.name === "price") {
      weekly_data1[index][event.target.name] = value;
    }
    this.setState({ weekly_data: weekly_data1 });

    //for monthly data
    let monthly_data1 = this.state.monthly_data;
    if (event.target.name === "price") {
      monthly_data1[index][event.target.name] = value;
    }
    this.setState({ monthly_data: monthly_data1 });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update Pricing";
    } else {
      return "Add Pricing";
    }
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          <div className="row row-xs">
            <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link to="/properties">Properties</Link>
                <span> / </span>
                <Link
                  to={"/properties/view/" + this.state.property_slug + "/property-pricing"}
                >
                  Pricing
                </Link>
                <span> / </span>
                <span>{this.getTitle()}</span>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-15 pd-t-4">
              <div className="col-md-3">
                <aside>
                  <div className="topHead">
                    <div className="demo-avatar-group">
                      <div className="az-img-user avatar-md online">
                        <img src={sessionStorage.getItem('property_logo_url') ? sessionStorage.getItem('property_logo_url') : propertyLogo} assName="rounded-circle" alt="" />
                      </div>
                      <div className="propname">
                        <h5> {sessionStorage.getItem('property_name') ? sessionStorage.getItem('property_name') : "Property"} </h5>
                        <ul>
                          <li> {sessionStorage.getItem('property_type') ? sessionStorage.getItem('property_type') : ""} </li>
                          {sessionStorage.getItem('year_value') ? <li></li> : ''}
                          <li>{sessionStorage.getItem('year_value') ? "Year " + sessionStorage.getItem('year_value') : ''}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "0")} >
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Property Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "0" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse
                          eventKey="0"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/basic")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic"
                              }
                            >
                              Property
                            </Link>

                            <Link
                              className={`${this.state.pathname.match("/tower")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              }
                            >
                              Towers
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/floors")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floors"
                              }
                            >
                              Floors
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/amenitites")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/amenitites"
                              }
                            >
                              Common Amenities
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/pet-policy")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                              }
                            >
                              Pet Policy
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/staff")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                            >
                              Staff
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/documents")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/documents"
                              }
                            >
                              Documents & Leases
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/property-level-tax")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              }
                            >
                              Taxes
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/nearby-attractions")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/nearby-attractions"
                              }
                            >
                              Near By Attractions
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/events")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                              }
                            >
                              Events
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "3")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Units Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "3" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="3"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/floor-plans")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floor-plans"
                              }
                            >
                              Floor Plans
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/units")
                                  ? "nav-link active"
                                  : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units"
                              }
                            >
                              Units
                            </Link>
                            {/* <Link
                              className={`${
                                this.state.pathname.match("/rooms")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/rooms"
                              }
                            >
                              Rooms
                            </Link> */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </nav>
                </aside>
              </div>
              <div className="col-md-9 ">
                <div className="propSection pd-l-15">
                  <h3>{this.getTitle()}</h3>

                  <div className="dashboardRightcard">
                    <div className="col-md-12 pl-0 pr-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="pd-l-5 pd-r-5">
                      <InputGroup className="mt-3">
                        <label>
                          Currency Code <span className="text-danger">*</span>
                        </label>
                        <select
                          // className="form-control max_width_100"
                          onChange={this.changeHandler}
                          name="currency_code"
                          value={this.state.currency_code}
                        >
                          <option key="" value="">Select Currency Code</option>
                          {this.state.currencyCode.map((option) => (
                            <option key={option.key} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {this.state.errors.currency_code ? (
                          <div className="text-danger">
                            {this.state.errors.currency_code}
                          </div>
                        ) : (
                          this.validator.message(
                            "currency_code",
                            this.state.currency_code,
                            "required",
                            { className: "text-danger" }
                          )
                        )}
                      </InputGroup>

                      <InputGroup className="mt-3">
                        <label>
                          Minimum Price <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          onChange={this.changeHandler}
                          name="minimum_price"
                          value={this.state.minimum_price}
                          className="form-control max_width_100"
                          type="text"
                          // placeholder="Minimum Price"
                          onKeyPress={global.onKeyPressEvent.floatValidation}
                        />
                        {this.state.errors.minimum_price ? (
                          <div className="text-danger">
                            {this.state.errors.minimum_price}
                          </div>
                        ) : (
                          this.validator.message(
                            "minimum_price",
                            this.state.minimum_price,
                            "required",
                            { className: "text-danger" }
                          )
                        )}
                      </InputGroup>

                      <InputGroup className="mt-3">
                        <label>
                          Maximum Price <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          onChange={this.changeHandler}
                          name="maximum_price"
                          value={this.state.maximum_price}
                          className="form-control max_width_100"
                          type="text"
                          // placeholder="Maximum Price"
                          onKeyPress={global.onKeyPressEvent.floatValidation}
                        />
                        {this.state.errors.maximum_price ? (
                          <div className="text-danger">
                            {this.state.errors.maximum_price}
                          </div>
                        ) : (
                          this.validator.message(
                            "maximum_price",
                            this.state.maximum_price,
                            "required",
                            { className: "text-danger" }
                          )
                        )}
                      </InputGroup>

                      <InputGroup className="mt-3">
                        <label>
                          Pricing Type <span className="text-danger">*</span>
                        </label>
                        <select
                          // className="form-control max_width_100"
                          onChange={this.changeHandler}
                          name="pricing_type_id"
                          value={this.state.pricing_type_id}
                        >
                          <option key="" value="">Select Pricing Type</option>
                          {this.state.pricingType.map((option) => (
                            <option key={option.key} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {this.state.errors.pricing_type_id ? (
                          <div className="text-danger">
                            {this.state.errors.pricing_type_id}
                          </div>
                        ) : (
                          this.validator.message(
                            "pricing_type_id",
                            this.state.pricing_type_id,
                            "required",
                            { className: "text-danger" }
                          )
                        )}
                      </InputGroup>

                      {/* for flat price */}
                      {this.state.pricing_type_id === "1" ?
                        <InputGroup className="mt-3">
                          <label>
                            Base Price - Flat <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            name="base_price_flat"
                            value={this.state.base_price_flat}
                            className="form-control max_width_100"
                            type="text"
                          // placeholder="Base Price - Flat"
                          />
                          {this.state.errors.base_price_flat ? (
                            <div className="text-danger">
                              {this.state.errors.base_price_flat}
                            </div>
                          ) : (
                            this.validator.message(
                              "base_price_flat",
                              this.state.base_price_flat,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </InputGroup>
                        : ''}

                      {/* for weekly price */}
                      {this.state.pricing_type_id === "2" ?
                        <div className="row">
                          <InputGroup className="mt-3">
                            <label>
                              Base Price - Weekly <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-8">
                              {this.state.weekly_data.map((item, index) => (
                                <div
                                  className="row row-xs align-items-center pd-t-5 pd-b-5"
                                  key={item.index}
                                >
                                  <div className="col-md-4 pl-0">
                                    <label className="form-label mg-b-0">
                                      {item.label} :
                                    </label>
                                  </div>
                                  <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                                    <input type="text" name="price" value={item.price || ''} onChange={(e) => this.changeHandler(e, index)} />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </InputGroup>
                        </div>
                        : ''}

                      {/* for monthly price */}
                      {this.state.pricing_type_id === "3" ?
                        <div className="row">
                          <InputGroup className="mt-3">
                            <label>
                              Base Price - Monthly <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-8">
                              {this.state.monthly_data.map((item, index) => (
                                <div
                                  className="row row-xs align-items-center pd-t-5 pd-b-5"
                                  key={item.index}
                                >
                                  <div className="col-md-4 pl-0">
                                    <label className="form-label mg-b-0">
                                      {item.label} :
                                    </label>
                                  </div>
                                  <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                                    <input type="text" name="price" value={item.price || ''} onChange={(e) => this.changeHandler(e, index)} />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </InputGroup>
                        </div>
                        : ''}

                      {/* <InputGroup className="mt-3">
                        <label>Sort Order</label>
                        <Form.Control
                          onChange={this.changeHandler}
                          name="sort_order"
                          value={this.state.sort_order}
                          onKeyPress={global.onKeyPressEvent.numberValidation}
                          className="form-control max_width_100"
                          type="text"
                          // placeholder="Sort Order"
                        />
                        {this.state.errors.sort_order ? (
                          <div className="text-danger">
                            {this.state.errors.sort_order}
                          </div>
                        ) : (
                          this.validator.message(
                            "sort_order",
                            this.state.sort_order,
                            "numeric|min:0,num",
                            { className: "text-danger" }
                          )
                        )}
                      </InputGroup> */}

                      <div className="d-flex align-items-center justify-content-end mt-3">
                        <b className={
                          this.state.status_id ? "active" : "inactive"
                        }
                        ></b>
                        <div
                          className={
                            this.state.status_id ? "az-toggle on" : "az-toggle"
                          }
                          onClick={() =>
                            this.setState({ status_id: !this.state.status_id })
                          }
                        >
                          <span></span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-3 row">
                        <Link
                          to={
                            "/properties/view/" +
                            this.state.property_slug +
                            "/property-pricing"
                          }
                        >
                          <button
                            type="button"
                            className="btn-success-outline-small"
                          >
                            Cancel
                          </button>
                        </Link>

                        <button
                          className="btn-success ml-3"
                          type="button"
                          onClick={this.saveOrUpdatePrice}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          {this.state.isSubmit
                            ? global.loader
                            : this.state.slug !== undefined
                              ? "Save"
                              : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ht-50"></div>
          </div>
        </Container>
      </main>
    );
  }
}

export default PropertyPriceForm;
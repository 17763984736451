////////////////////////////////////////////////////////////
//     							                          //
//  Program: CrmCampaignService.js                              //
//  Application: Crm Campaign                                    //
//  Option: Used for manage Crm Campaign                        //
//  Developer: Raj Kumar 						                  //
//  Date: 2022-04-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class CrmCampaignService {

  getCampaignData(queryString) {
    let endPoint = "crm-campaign" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createCrmCampaign(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "crm-campaign-store";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  updateCrmCampaign(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "crm-campaign-update/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getCampaignBySlug(slug) {
    let endPoint = "get-crm-campaign/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getCrmListData(queryString) {
    let endPoint = "crm-list-data" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getCrmTemplateDatas(queryString) {
    let endPoint = "crm-template-data" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getCrmTemplateBySlug(slug) {
    let endPoint = "crm-template-data/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  deleteCrmCampaign(slug) {
    let endPoint = "delete-crm-campaign/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
        endPoint: endPoint,
        addditionalHeaderData: addditionalHeaderData,
        postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  getTimezoneDatas(queryString) {
    let endPoint = "timezone-list" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  testSendEmail(data) {
    let endPoint = "crm-test-mail-send";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getCampaignReportBySlug(slug, queryString) {
    let endPoint = "crm-campaign-report/" + slug + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  stopStartCampaignBySlug(slug) {
    let endPoint = "stop-start-campaign/" + slug;
    let addditionalHeaderData = {};
    let postData = {};
  
    let inputData = {
        endPoint: endPoint,
        addditionalHeaderData: addditionalHeaderData,
        postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}


export default new CrmCampaignService();
////////////////////////////////////////////////////////////
//     							                                      //
//  Program: WifiProviderService.js                    //
//  Application: Third party                           //
//  Option: Used for manage Wifi Provider              //
//  Developer: NP           						   //
//  Date: 2021-01-11                                   //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class WifiProviderService {

  getWifiProvider(queryString) {
    let endPoint = "wi-fi-provider"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createWifiProvider(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "wi-fi-provider";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getWifiProviderBySlug(slug) {
    let endPoint = "wi-fi-provider/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateWifiProvider(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "wi-fi-provider/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteWifiProvider(slug) {
    let endPoint = "wi-fi-provider/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new WifiProviderService();

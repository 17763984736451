import React, { Component } from "react";
// import adminLogo from './../../assets/images/logo-black.svg'
import defaultImage from "./../../assets/images/profile-nogender.png";
import { Navbar, Container } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import RUmessageService from "../../services/RUmessageService";
import ChatIcon from "./../../assets/images/chat_mini.svg";
import { withRouter } from "react-router-dom";

class HeaderTopTwo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerToken: localStorage.getItem("headerToken"),
      profileImage:
        localStorage.getItem("userData") &&
        JSON.parse(localStorage.getItem("userData")).profile_thumb_url !==
          null &&
        JSON.parse(localStorage.getItem("userData")).profile_thumb_url !==
          undefined &&
        JSON.parse(localStorage.getItem("userData")).profile_thumb_url !== ""
          ? JSON.parse(localStorage.getItem("userData")).profile_thumb_url
          : defaultImage,
      first_name: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).first_name
        : "",
      url_slug: window.location.pathname,
      msgCount: this.props.msgCount,
      role_slug: global.currerntRoleSlug ? global.currerntRoleSlug : null,
      msgCountGlobal: this.props.msgCountGlobal,
    };
    this.changePassword = this.changePassword.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    // Get user profile and set into localstorage and fresh the page
    // if(localStorage.getItem('isLogin') === '1'){
    //     localStorage.removeItem('isLogin');
    //     window.location.reload(false);
    // }
    if (localStorage.getItem("message_page") === "1") {
      this.messageCount();
    }
    this.setState({ showSpinner: false, loading: false, customSpinner: false });
    //localStorage.setItem("msgCount", 0);
    // if (window.location.pathname == "/messages") {
    //   localStorage.setItem("message_page", 1);
    //   this.setState({ messagePageActive: 1 });
    //   return 1;
    // } else {
    //   this.setState({ messagePageActive: 0 });
    //   localStorage.setItem("message_page", 0);
    //   return 0;
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("msg page value", localStorage.getItem("message_page"));
    if (localStorage.getItem("message_page") == 1) {
      this.messageCount();
    }

    // if (nextProps !== undefined) {
    //   this.setState({ messagePageActive: nextProps.messagePageActive });
    //   // if(nextProps.msgCountGlobal === 0){
    //   //   this.setState({ msgCountApi: 0 });
    //   // }
    // }
  }

  async messageCount() {
    this.setState({ showSpinner: true, loading: true, msgCountApi: 0 });
    var queryString =
      "property_slug=" +
      (this.props.property_slug
        ? this.props.property_slug
        : sessionStorage.getItem("property_slug")) +
      "&role_slug=" +
      this.state.role_slug;
    let res = await RUmessageService.messageCountPropertyWise(queryString);
    if (global.successStatus.includes(res.status)) {
      var msgCount = res.data && res.data[0] ? res.data[0].unread_count : 0;
    }
    this.setState({ msgCountApi: msgCount });
    ///// localStorage.setItem("messageCount", 0);
    this.setState({ showSpinner: false, loading: false });
  }

  closeMenu(e) {
    e.target.closest(".dropdown").classList.remove("show");
    e.target.closest(".dropdown .dropdown-menu").classList.remove("show");
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.querySelector("body").classList.remove("az-header-menu-show");
    }
  }

  removeMsgCount() {
    localStorage.setItem("messageCount", 0);
    this.setState({ msgCount: 0, msgCountGlobal: 0 });
    this.props.updatePropsGlobalmsg(0);
  }

  changePassword() {
    this.props.history.push("/change-password");
  }

  updatePassword() {
    this.props.history.push("/update-profile");
  }

  render() {
    var env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "";
    return (
      <>
        <div className="adminHeadertop">
          <Navbar expand="lg">
            <Container fluid className="d-flex justify-content-between pd-l-0">
              <div className="mobView col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-left pd-l-0">
                {/* <Navbar.Brand href='/dashboard' className='mg-r-0'> <img  src={adminLogo} alt="Logo"/></Navbar.Brand> */}
                <div className="searchboxai">
                  <Link to="/snapshot" className="logo-contnet">
                    {" "}
                    <h4>OHAI</h4>
                  </Link>
                  {env ? <h6>{env}</h6> : ""}
                </div>
              </div>
              <Container className="leftNavbar">
                <div className="oh-logo">
                  <h4>OHAI</h4>
                </div>
              </Container>

              <div className="rightNavbar col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Dropdown className="d-flex justify-content-end">
                  {/* <Dropdown.Toggle variant="secondary-" className='pd-0'>
                                <div className="navMob d-flex align-items-center">
                                    <span className='mr-3'>
                                    <i className='fas fa-gear'></i>
                                    </span>   
                                </div>
                          </Dropdown.Toggle> */}

                  {global.userPermissions.checkPermission(
                    "communication-message-module"
                  ) &&
                    (this.state.msgCountApi != undefined &&
                    this.state.msgCountApi > 0 &&
                    this.props.socketThread == undefined ? (
                      <div className="messges-alert d-flex align-items-center mg-r-15">
                        <Link to="/messages">
                          <img src={ChatIcon} alt="" />
                          <span className={"active-msg"}></span>
                        </Link>
                      </div>
                    ) : (
                      <div className="messges-alert d-flex align-items-center mg-r-15">
                        <Link to="/messages">
                          <img src={ChatIcon} alt="" />
                          {this.props.socketThread != undefined &&
                          localStorage.getItem("current_thread") !=
                            this.props.socketThread ? (
                            <span className={"active-msg"}></span>
                          ) : (
                            ""
                          )}
                          {/* {localStorage.getItem("message_page") == 1 &&
                      localStorage.getItem("current_thread") !=
                        this.props.socketThread ? (
                        <span className={"active-msg"}>{console.log("this.props.socketThread", this.props.socketThread)}</span>
                      ) : (
                        ""
                      )} */}
                        </Link>
                      </div>
                    ))}

                  <Dropdown.Toggle variant="secondary-" className="pd-0">
                    <div className="navMob d-flex align-items-center">
                      <figure>
                        {/* <img src={this.state.profileImage} alt="img"/> */}
                        <img
                          src={
                            this.props.image
                              ? this.props.image
                              : this.state.profileImage
                          }
                          alt="img"
                        />
                      </figure>
                      {/* <span className='profileContent d-flex flex-column text-left mg-l-5'>
                                          <b>{this.state.first_name}</b>
                                          <i className='tx-12'>Admin</i>
                                    </span> */}
                      {/* <span className='ml-3'>
                                        <i className='fas fa-chevron-down'></i>
                                    </span>    */}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="tx-13">
                    <Dropdown.Item
                      href="#/"
                      className="navMob d-flex align-items-center flex-row"
                    >
                      <figure>
                        {/* <img src={this.state.profileImage} alt="img"/>  */}
                        <img
                          src={
                            this.props.image
                              ? this.props.image
                              : this.state.profileImage
                          }
                          alt="img"
                        />
                      </figure>
                      {/* <span className='profileContent d-flex flex-column text-left mg-l-5'>
                                            <b>{this.state.first_name}</b>
                                            <i className='tx-12'>Admin</i>
                                      </span> */}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.updatePassword()}
                      // href="/update-profile"
                    >
                      Update Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.changePassword()}
                      // href="/change-password"
                    >
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Container>
          </Navbar>
        </div>
      </>
    );
  }
}

export default withRouter(HeaderTopTwo);

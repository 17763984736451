////////////////////////////////////////////////////////////
//     							                                      //
//  Program: StaffView.jsx                                //
//  Application:  Staff                                   //
//  Option: For view  Staff details                      //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Card, Accordion, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import PropertyStaffService from "../../services/PropertyStaffService";
import staffImg from "./../../assets/images/profile-nogender.png";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import BackButton from "../../assets/images/back-button.svg";
import PhoneNumberFormat from "../common/PhoneNumberFormat";

export class StaffView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      deletePropStaffModal: false,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      staff_contact_name: "",
      sort_order: "",
      staff_contact_email: "",
      staff_contact_number: "",
      pathname: window.location.pathname,
      deletedFileList: [],
      event_image: [],
      eventTypeList: [],
      multiStaffList: [],
      multiStaffListTotal: 0,
      imageUrl: staffImg,
    };
    this.opendeletePropStaffModal = this.opendeletePropStaffModal.bind(this);
    this.closedeletePropStaffModal = this.closedeletePropStaffModal.bind(this);
    this.deletePropStaff = this.deletePropStaff.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      var multiStaffList = [];
      var multiStaffListTotal = 0;
      let res = await PropertyStaffService.getPropertyStaffBySlug(
        this.state.slug
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.staff_contact_name = res.data.staff_contact_name
          ? res.data.staff_contact_name
          : "";
        resData.staff_contact_email = res.data.staff_contact_email
          ? res.data.staff_contact_email
          : "";
        resData.staff_contact_number = res.data.staff_contact_number
          ? res.data.staff_contact_number
          : "";
        resData.staff_contact_extension = res.data.staff_contact_extension
          ? res.data.staff_contact_extension
          : "";
        resData.notes = res.data.notes ? res.data.notes : "";
        resData.job_title = res.data.job_title ? res.data.job_title : "";
        resData.staff_contact_image = res.data.staff_contact_image
          ? res.data.staff_contact_image
          : staffImg;
        resData.imageUrl = res.data.staff_contact_image_url
          ? res.data.staff_contact_image_url
          : staffImg;
        resData.staff_contact_type_slug = res.data.staff_contact_type.slug
          ? res.data.staff_contact_type.slug
          : "";
        resData.reporting_manager_type_slug = res.data.reportingManagerType
          ? res.data.reportingManagerType.slug
          : "";
        resData.reporting_manager_type_slug = res.data.reportingManagerType
          ? res.data.reportingManagerType.staff_contact_name
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        multiStaffList = res.data.staffData ? res.data.staffData : [];
        multiStaffListTotal = multiStaffList ? multiStaffList.length : 0;
        resData.status_id = res.data.status_id ? res.data.status_id : "";
      }

      this.setState(resData);
      this.setState({
        multiStaffList: multiStaffList,
        multiStaffListTotal: multiStaffListTotal,
      });
      this.setState({ input: resData });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  async deletePropStaff() {
    this.setState({ showSpinner: true });
    let res = await PropertyStaffService.deletePropertyStaff(this.state.slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ deletePropStaffModal: false });
      // setTimeout(
      //   () =>
      this.props.history.push(
        "/properties/view/" + this.state.property_slug + "/staff"
      )
      //   global.redirect_time
      // );
    } else {
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  opendeletePropStaffModal(slug) {
    this.setState({ deletePropStaffModal: true, slug: slug });
  }

  closedeletePropStaffModal() {
    this.setState({ deletePropStaffModal: false, slug: "" });
  }

  getTitle() {
    return "Staff Details";
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          <div className="row row-xs">
            <div className="col-md-10 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link to="/properties">Properties</Link>
                <span> / </span>
                <Link
                  to={"/properties/view/" + this.state.property_slug + "/staff"}
                >
                  Staff
                </Link>
                <span> / </span>
                <span>{this.getTitle()}</span>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <p className="d-flex justify-content-end">
                <span className="back-parent d-flex align-items-center">
                  <Link
                    to={
                      "/properties/view/" + this.state.property_slug + "/staff"
                    }
                  >
                    <img src={BackButton} alt="" className="back-button" />
                  </Link>
                  <Link>Back</Link>
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-12 pl-0 pr-0">
            {/* <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            /> */}
          </div>
        </Container>

        <Container>
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-15 pd-t-4">
              <div className="col-md-3">
                <aside>
                  <div className="topHead">
                    <div className="demo-avatar-group">
                      <div className="az-img-user avatar-md online">
                        <img
                          src={this.state.imageUrl}
                          className="rounded-circle"
                          alt=""
                        />
                      </div>
                      <div className="propname">
                        <h5>
                          {this.state.staff_contact_name
                            ? this.state.staff_contact_name
                            : ""}
                        </h5>
                        <ul>
                          <li>
                            {this.state.property ? this.state.property : ""}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "0")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Properties Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "0" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse
                          eventKey="0"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/basic")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic"
                              }
                            >
                              Property
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/tower")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              }
                            >
                              Towers
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/floors")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floors"
                              }
                            >
                              Floors
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/amenitites")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/amenitites"
                              }
                            >
                              Common Amenities
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/pet-policy")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                              }
                            >
                              Pet Policy
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/staff")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                            >
                              Staff
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/documents")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/documents"
                              }
                            >
                              Documents & Leases
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/property-level-tax")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              }
                            >
                              Taxes
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/nearby-attractions")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/nearby-attractions"
                              }
                            >
                              Near By Attractions
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/events")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                              }
                            >
                              Events
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card onClick={() => this.setOpen(!this.state.open, "3")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Units Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "3" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="3"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/floor-plans")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floor-plans"
                              }
                            >
                              Floor Plans
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/units")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units"
                              }
                            >
                              Units
                            </Link>
                            {/* <Link
                              className={`${
                                this.state.pathname.match("/rooms")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/rooms"
                              }
                            >
                              Rooms
                            </Link> */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </nav>
                </aside>
              </div>

              <div className="col-md-9 ">
                <div className="scrolling-carousel pd-l-15">
                  <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2">
                    <h3>Staff Details</h3>
                  </div>

                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30">
                    <div className=" d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {/* <button
                      onClick={() => {
                        this.opendeletePropStaffModal(this.state.slug);
                      }}
                      className="btn-danger-outline mg-r-20"
                    >
                      <img src={deleteicon} />
                      Delete
                    </button> */}
                      <span
                        onClick={() => {
                          this.opendeletePropStaffModal(this.state.slug);
                        }}
                        className="deleteicon mg-r-20"
                      >
                        <img src={deleteicon} />
                      </span>
                      <Link
                        to={
                          "/properties/view/" +
                          this.state.property_slug +
                          "/staff/update/" +
                          this.state.slug
                        }
                        className="mg-l-2"
                      >
                        <button className="btn-success-outline">
                          <img src={editIcon} />
                          Edit
                        </button>
                      </Link>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="demo-avatar-group d-flex justify-content-center flex-column">
                          <div className="az-img-user avatar-md online mg-b-8">
                            <figure>
                              <b
                                className={
                                  this.state.status_id === 1 ? "active" : "inactive"
                                }
                              >
                                {/* {this.state.status_id === 1 ? "active" : "inactive"} */}
                              </b>
                              <img
                                src={
                                  this.state.imageUrl
                                    ? this.state.imageUrl
                                    : staffImg
                                }
                                alt="Staff Image"
                                className="rounded-circle"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="propname">
                          <h5>
                            {this.state.staff_contact_name
                              ? this.state.staff_contact_name
                              : ""}
                          </h5>
                        </div>
                        <div className="row col-md-12 mg-t-40 mg-md-t-0">
                          <div className="col-md-4">
                            <p className="az-profile-name-text pl-0">
                              Staff Type
                            </p>
                            <p className="media-body">
                              {this.state.staff_contact_type
                                ? this.state.staff_contact_type
                                  .staff_contact_type_name
                                : ""}
                            </p>
                            <p className="az-profile-name-text pl-0">
                              Extension
                            </p>
                            <p className="media-body">
                              {this.state.staff_contact_extension
                                ? this.state.staff_contact_extension
                                : ""}
                            </p>
                            {/* <p className="az-profile-name-text pl-0">
                              Manager Type
                            </p>
                            <p className="media-body">
                              {this.state.multiStaffListTotal > 0 ? this.state.multiStaffList.map(
                                (option, i) =>
                                  option.manager_type_name +
                                  (i < this.state.multiStaffListTotal - 1
                                    ? ","
                                    : "") +
                                  ""
                              ) : ""}
                            </p> */}
                          </div>

                          <div className="col-md-4">
                            <p className="az-profile-name-text pl-0">
                              Phone
                            </p>
                            <p className="media-body">
                              {this.state.staff_contact_number
                                ? <PhoneNumberFormat phone_number={this.state.staff_contact_number} />
                                : ""}
                            </p>
                            <p className="az-profile-name-text pl-0">
                              Job Title
                            </p>
                            <p className="media-body">
                              {this.state.job_title ? this.state.job_title : ""}
                            </p>

                          </div>

                          <div className="col-md-4">
                            <p className="az-profile-name-text pl-0">Email</p>
                            <p className="media-body">
                              {this.state.staff_contact_email
                                ? this.state.staff_contact_email
                                : ""}
                            </p>
                            {/* <p className="az-profile-name-text pl-0">Note</p>
                            <p className="media-body">
                              {this.state.notes ? this.state.notes : ""}
                            </p> */}
                            <p className="az-profile-name-text pl-0">
                              Reporting Manager
                            </p>
                            <p className="media-body">
                              {this.state.reporting_manager_type_slug ? this.state.reporting_manager_type_slug : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ht-50"></div>
          </div>
        </Container>

        <Modal
          show={this.state.deletePropStaffModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closedeletePropStaffModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeletePropStaffModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deletePropStaff(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default StaffView;

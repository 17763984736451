////////////////////////////////////////////////////////////
//     							                          //
//  Program: CompanyOverview.jsx                                //
//  Application: Dashboard                                //
//  Option: to view Company Information                   //
//  Developer: NP  						                            //
//  Date: 2022-04-11                                      //
//                                                        //
////////////////////////////////////////////////////////////
import Moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import CompanyService from "../../services/CompanyService";
// import companyLogo from './../../assets/images/company_placeholder.png'
import NoLeases from "../../assets/images/snapshoticons/Leases.svg";

class NewlySignedLeases extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertySlug: this.props.propertySlug,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: "",
      total_client_count: "",
      checkins_today: [],
      checkouts_today: [],
      checkins_tomorrow: [],
      checkouts_tomorrow: [],
      checkInList: [],
      checkOutList: [],
      cleaningscheduled_today: [],
      cleaningscheduled_tomorrow: [],
      occupiedunits: [],
      bookedunits: [],
      signed_leases: [],
      new_booking_list: [],
    };
  }

  componentDidMount() {
    this.setState({ signed_leases: [] });
    this.setState({ spinnerLoader: true, loading: true });
    if (this.state.propertySlug) {
      this.getData(this.state.propertySlug);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ signed_leases: [] });
    this.setState({ spinnerLoader: true, loading: true });
    if (nextProps.propertySlug) {
      this.getData(nextProps.propertySlug);
    }
  }

  // for lease info
  handleChangeLeaseList(property_slug, lease_slug) {
    const win = window.open(
      "/properties/view/" + property_slug + "/leasing/" + lease_slug + "/info",
      "_blank"
    );
    win.focus();
  }

  async getData(propertySlug) {
    this.setState({ spinnerLoader: true, loading: true });
    var resData = {};

    let res = await CompanyService.getNewlySignedLeases(propertySlug);

    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      resData.signed_leases = res.data.signed_leases
        ? res.data.signed_leases
        : [];
    }
    this.setState(resData);
    this.setState({ spinnerLoader: false, loading: false });
  }

  render() {
    return (
      <div className="col-xxl-6 col-md-6 col-sm-12 col-12 mg-b-20">
        {/* <div className="pd-lg-b-15 snap-heading">
                    <h3 className="text-uppercase">Today's List</h3>
                </div> */}
        <div className="card card-table-two">
          <div className=" d-flex justify-content-between">
            <div className="">
              <h6 className="card-title">NEWLY SIGNED LEASES</h6>
              <span className="d-block mg-b-0">
                Leases added to system in the past 24 hours
              </span>
            </div>
            <h6 className="card-title">
              Total Count: {this.state.signed_leases.length}
            </h6>
          </div>
          {this.state.signed_leases.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-dashboard-two mg-b-0">
                <thead>
                  <tr>
                    <th className="wd-lg-25p tx-left">S.No</th>
                    <th className="wd-lg-25p tx-left">Unit</th>
                    <th className="wd-lg-25p tx-left">Resident</th>
                    <th className="wd-lg-25p tx-left">Start Date</th>
                    <th className="wd-lg-25p tx-left">End Date</th>
                    <th className="wd-lg-25p tx-left">Type</th>
                    <th className="wd-lg-25p tx-left">Monthly Rent</th>
                    <th className="wd-lg-25p tx-left">Signed Date</th>
                    <th className="wd-lg-25p text-left">Email Address</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.signed_leases.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        value={item}
                        onClick={() =>
                          this.handleChangeLeaseList(
                            item.property_slug,
                            item.lease_slug
                          )
                        }
                      >
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {i + 1}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.unit_name ? item.unit_name : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.resident_first_name
                            ? item.resident_first_name
                            : "Orion"}
                          {item.resident_last_name
                            ? item.resident_last_name
                            : " Haus"}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                          {item.lease_start_date
                            ? Moment(item.lease_start_date).format(
                                global.dateFormat
                              )
                            : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                          {item.lease_end_date
                            ? Moment(item.lease_end_date).format(
                                global.dateFormat
                              )
                            : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.type ? item.type : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {"$"}
                          {item.monthly_rent ? item.monthly_rent : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                          {item.leased_date
                            ? Moment(item.leased_date).format(global.dateFormat)
                            : ""}
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.email ? item.email : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="table-responsive overflow-hidden">
                {this.state.spinnerLoader ? (
                  global.snapshotloader
                ) : (
                  <div className="text-center">
                    <img src={NoLeases} alt="" />
                    <p>No list of newly signed leases.</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(NewlySignedLeases);

import React, { useState, useEffect, useRef } from "react";
import JoditEditor from 'jodit-react';
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Alerts from "../../common/Alerts";
import KeycafeService from "../../../services/KeycafeService";
import SimpleReactValidator from "simple-react-validator";
// import { Form } from "react-bootstrap";

const Instructions = (props) => {
    const simpleValidator = useRef(new SimpleReactValidator());

    const [instruction, setInstruction] = useState("");
    const [isSubmit, setSubmit] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalType, setAlertModalType] = useState("");
    const [alertModalTitle, setAlertModalTitle] = useState("");
    const [alertModalMessage, setAlertModalMessage] = useState("");
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getKeycafeInstruction();
    }, []);

    const getKeycafeInstruction = async (queryString = "") => {
        var instruction = '';
        let res = await KeycafeService.getKeycafeInstruction(
            "property_slug=" + props.propertySlug);

        if (global.successStatus.includes(res.status)) {
            instruction = res.data && res.data.keycafe_description ? res.data.keycafe_description : '';
            setInstruction(instruction);
        }

    };

    const saveOrUpdateInstruction = async (event) => {
        event.preventDefault();
        setSubmit(true);

        let fields = simpleValidator.current.fields;
        let isError = 0;
        let isSubmit = true;
        let res = [];

        {
            Object.keys(fields).map((key) => {
                if (!fields[key]) {
                    isError = 1;
                }
            });
        }

        if (isError === 1) {
            isSubmit = false;
            setSubmit(isSubmit);
            setErrors(simpleValidator.current.errorMessages);
        } else {
            isSubmit = true;
            setSubmit(isSubmit);

            let inputData = {
                property_slug: props.propertySlug,
                instruction: instruction
            };
            console.log(inputData);
            res = await KeycafeService.updateKeycafeInstruction(inputData);
            if (global.successStatus.includes(res.status)) {
                setSubmit(false);
                setShowAlertModal(true);
                setAlertModalType("success");
                setAlertModalTitle("Success");
                setAlertModalMessage(res.message ? res.message : "Success");

                setTimeout(
                    () => setShowAlertModal(false),
                    global.alert_close_time
                );
            }
        }
    }

    // function changeHandler(event, index) {
    //     let value = event.target.value;
    //     setInstruction(value);
    // }

    return (
        <>
            <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                {/* <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns noisebutton keycafebtn instructbtn"> */}
                <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns">
                    {/* {showModel} */}
                    <button
                        className="btn-success-outline-small button-width"
                        type="button"
                        disabled={isSubmit ? true : false}
                        onClick={saveOrUpdateInstruction}
                    >
                        {isSubmit ? global.loader : 'Save'}
                    </button>

                </div>
                <div className="col-md-12 pd-lg-r-15 pd-lg-l-15 pd-xs-l-0 pd-xs-r-0 mg-t-15">
                    <Alerts
                        show={showAlertModal}
                        type={alertModalType}
                        title={alertModalTitle}
                        message={alertModalMessage}
                    />
                </div>
                <div className="mg-t-18 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0 d-block">
                    <div className="pd-l-0">
                        {/* <CKEditor
                            editor={ClassicEditor}
                            config={{
                                removePlugins: [
                                    "EasyImage",
                                    "ImageUpload",
                                    "MediaEmbed",
                                    "Table",
                                ],
                                // builtinPlugins: [
                                //     "CodeBlock"
                                // ],
                                // toolbar: [...'codeBlock'],
                                // codeBlock: {
                                //     languages: [
                                //         { language: 'css', label: 'CSS' },
                                //         { language: 'html', label: 'HTML' }
                                //     ]
                                // }
                            }}
                            name="comment"
                            minHeight="50px"
                            data={instruction}
                            onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                        "height",
                                        "300px",
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                changeHandler({
                                    target: {
                                        type: "ckeditor",
                                        name: "comment",
                                        value: data,
                                    },
                                });
                            }}
                        /> */}
                        {/* <Form.Control
                            onChange={changeHandler}
                            name="instruction"
                            value={instruction}
                            className="form-control max_width_100"
                            id="instruction"
                            as="textarea"
                            rows={10}
                        /> */}

                        <JoditEditor
                            ref={null}
                            value={instruction}
                            // config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setInstruction(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={newContent => {}}
                        />

                        {simpleValidator.current.message(
                            "description",
                            instruction,
                            "required"
                        )}
                        {errors.description !== "" ? (
                            <div className="text-danger">
                                {errors.description}{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

            </div>

        </>
    );
}
export default Instructions;
//                                                           //
//  Program: ResidentPayoutsReports.jsx                              //
//  Application: Report                                      //
//  Option: Property Reports Listing                         //
//  Developer: NP 		                                     //
//  Date: 2022-08-30                                         //
//                                                           //
/////////////////////////////////////////////////////////   ///

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  Container,
  Tab,
  Modal,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import PayoutSidebar from "../common/PayoutSidebar";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import ReportService from "../../services/ReportService";
import UnitsService from "../../services/UnitsService";
import StripePayoutService from "../../services/StripePayoutService";
import SuccessLogo from "./../../assets/images/successImage.svg";
import Moment from "moment";
import MultiImageService from "./../../services/MultiImageService";
import AllPropertyPayoutSidebar from "../common/AllPropertyPayoutSidebar";

class ResidentPayoutReportList extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      errors: {},
      importModal: false,
      file_url: "",
      file_name: "",
      amount: "",
      open: true,
      eventKey: "0",
      residentCount: "0",
      is_inactive: false,
      isSubmit: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      is_by_paid: 1,
      module_search: "",
      importResponseModel: false,
      module_search_display: "",
      exportMesssage: "",
      exportFilePath: "",
      exportResult: "",
      alertType: "xlsx",
      is_search: 0,
      isSearch: global.isSearch,
      is_total: false,
      is_topup: false,
      confirmPay: false,
      is_pay_out_modal: false,
      input: {},
      unitList: [],
      selectedUnitList: [],
      selectedStatusList: [],
      selectedPaymentStatusList: [],
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      totalPayOutList: {},
      topupamout1: "",
      description: "",
      search_description: "",
      deleteRoleModal: false,
      isSend: false,
      pay_status_slug: "",
      pay_status_lable: "",
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: true,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      configPay: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: true,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      newUnitList: [],
      statusList: [],
      paymentStatusList: [],
      payMethodList: [],
      extraButtons: [],
      columns: [
        {
          key: "amount",
          sortable: true,
          text: "Amount",
          className: "unit_number hand-cursor text-center td_clickable",
          cell: (record) => {
            return "$" + record.amount;
          },
        },
        {
          key: "bank_name",
          sortable: true,
          text: "Source",
          className: "unit_number hand-cursor text-center td_clickable",
          cell: (record) => {
            return record.bank_name;
          },
        },
        {
          key: "description",
          sortable: true,
          text: "Description",
          className: "unit_number hand-cursor text-center td_clickable",
          cell: (record) => {
            return record.description;
          },
        },
        {
          key: "initiate_date",
          sortable: true,
          text: "Initiated",
          className: "hand-cursor text-center td_clickable",
          cell: (record) => {
            return record.initiate_date
              ? Moment(record.initiate_date).format("MM/DD/YY")
              : "";
          },
        },
        {
          key: "available_date",
          sortable: true,
          text: "Available",
          className: "hand-cursor text-center td_clickable",
          cell: (record) => {
            return record.available_date
              ? Moment(record.available_date).format("MM/DD/YY")
              : "";
          },
        },
      ],
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.getTotalBill = this.getTotalBill.bind(this);
    this.getData = this.getData.bind(this);
  }

  closeimportResponseModel() {
    this.setState({ importResponseModel: false });
    this.validator.hideMessages();
  }

  componentDidMount() {
    this.getPayOutReports();
    this.getData();
  }

  SendSendTopUpAmount = async () => {
    let inputData = {
      topupamout: this.state.topupamout1,
      description: this.state.description,
    };
    let res = await StripePayoutService.SendTopUpAmount(inputData);
    this.setState({ isSend: true });
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ is_topup: 0 });
      this.setState({ importResponseModel: true });
      this.setState({ successMessage: res.message });
      this.setState({ topupamout1: "", description: "" });
      this.setState({ showSpinner: false, isSend: false });
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ is_topup: 0 });
        this.setState({ showSpinner: false, isSend: false });
        this.setState({ errors: errors });
      }
    }
    this.getPayOutReports();
  };

  async clearFilter() {
    var date = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.setState({ is_search: 0, is_by_paid: 1 });
    this.setState({
      create_date: "",
      available_date: "",
      checkout_date_to: lastDay,
      payout_duration_start_date: "",
      payout_duration_end_date: "",
      unit_slug: "",
      selectedUnitList: [],
      selectedStatusList: [],
      selectedPaymentStatusList: [],
      selectedMethodList: [],
      status_slug: "",
      unit_lable: "",
      status_lable: "",
      method_lable: "",
      pay_status_slug: "",
      pay_status_lable: "",
    });
    this.setState({
      module_search: "",
      statusList: "",
      moduleList: "",
      paymentStatusList: "",
    });
    this.getPayOutReports();
  }

  async ExportData() {
    this.setState({ showSpinner: true, isSubmit: true });
    let data = {
      property_slug: this.state.property_slug,
    };

    let checkOutFromDate = this.state.checkout_date_from
      ? Moment(this.state.checkout_date_from).format("YYYY-MM-DD")
      : "";
    let payoutDurationStartDate = this.state.payout_duration_start_date
      ? Moment(this.state.payout_duration_end_date).format("YYYY-MM-DD")
      : "";
    let payoutDurationEndDate = this.state.payout_duration_end_date
      ? Moment(this.state.payout_duration_end_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "is_export=1" +
      "&file_type=" +
      this.state.alertType +
      "&checkout_date_from=" +
      checkOutFromDate +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&payout_duration_start_date=" +
      payoutDurationStartDate +
      "&payout_duration_end_date=" +
      payoutDurationEndDate;

    let res = await ReportService.exportResidentPayOutReport(data, queryString);
    if (res.data.filePath) {
      this.getDownloadFile(res.data.filePath, res.data.fileName);
      this.setState({ exportMesssage: res.message });
      this.setState({ exportResult: data });
      this.setState({ exportFilePath: res.data.filePath });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  exportToggle = () => {
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
      this.setState({ showSpinner: false, isSubmit: false });
    } else {
      this.setState({ isModel: 1 });
    }
  };

  gerDefaultInOutDate() {
    this.setState({ payout_date: "" });
    this.setState({ payoutDurationEndDate: "" });
  }

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  payoutDataModalToggle = () => {
    if (this.state.is_pay_out_modal === 1) {
      this.setState({ is_pay_out_modal: 0 });
    } else {
      this.setState({ is_pay_out_modal: 1 });
    }
  };

  getPayOutReports = async (queryString) => {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let totalBill = 0;
    let totalBillTopUp = 0;
    let stripeActiveResidentCount = 0;
    let res = await ReportService.getResidentTopUpList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }

    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
      list: list,
      totalBill: totalBill,
      totalBillTopUp: totalBillTopUp,
      residentCount: stripeActiveResidentCount,
    });
  };

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    let Stripebalance = 0;
    let res = await StripePayoutService.getstripebalance();
    if (global.successStatus.includes(res.status)) {
      Stripebalance = res.data.Stripe_balance ? res.data.Stripe_balance : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      Stripebalance: Stripebalance,
    });
  }

  paymentpayout = async () => {
    let inputData = {
      result: this.state.list,
      other_data: this.state.totalBill,
    };
    let res = await StripePayoutService.payouttotalbill(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.props.history.push("/stripe-total-payout"),
        global.redirect_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.totalbill();
  };

  onChangetopup = (event) => {
    this.setState({ topupamout1: event.target.value });
  };

  onChangeDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  onChangeSearchDescription = (event) => {
    this.setState({ search_description: event.target.value });
  };

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_name, tower, total_tower_count }) => ({
            value: slug,
            label: unit_name,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getTotalBill() {
    var totalBill = 0;
    let res = await StripePayoutService.gettotalpayment();

    if (global.successStatus.includes(res.status)) {
      totalBill = res.other_data.final_total_payout
        ? res.other_data.final_total_payout
        : 0;
    }
    this.setState({ totalBill: totalBill });
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
      const paymentStatusList = [
        { value: "pending", label: "Pending" },
        { value: "success", label: "Success" },
        { value: "fail", label: "Fail" },
        { value: "cancel", label: "Cancel" },
        { value: "reverse", label: "REVERSE" },
      ];
      this.setState({
        paymentStatusList: paymentStatusList,
        selectedPaymentStatusList: "",
        create_date: "",
        available_date: "",
        amount: "",
      });
    }
  };

  totalbill = () => {
    if (this.state.is_total === true) {
      this.setState({ is_total: false });
    } else {
      this.setState({ is_total: true });
    }
  };

  totalTopup = () => {
    /* let topBalanceRequired = 0;
    if (this.state.totalBillTopUp > this.state.Stripebalance) {
      if (this.state.totalBillTopUp !== undefined && this.state.Stripebalance !== undefined) {
        topBalanceRequired = (parseFloat(this.state.totalBillTopUp) - parseFloat(this.state.Stripebalance));
      }
    }  */
    //this.setState({ top_up_amount: topBalanceRequired });
    if (this.state.is_topup === true) {
      this.setState({ is_topup: false });
    } else {
      this.setState({ is_topup: true });
    }
  };

  confirmPayClose = () => {
    if (this.state.confirmPay === true) {
      this.setState({ confirmPay: false });
    } else {
      this.setState({ confirmPay: true });
      this.setState({ is_total: false });
    }
  };

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    const selected_unit_number = value !== null ? value.label : [];
    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
      unit_lable: selected_unit_number,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStatusList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    const selected_unit_number = value !== null ? value.label : [];
    this.setState({
      selectedStatusList: value,
      status_slug: unit_slug_value,
      status_lable: selected_unit_number,
    });
  }

  handleChangePaymentStatusList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    const selected_unit_number = value !== null ? value.label : [];
    this.setState({ is_by_paid: 0 });
    this.setState({
      selectedPaymentStatusList: value,
      pay_status_slug: unit_slug_value,
      pay_status_lable: selected_unit_number,
    });
  }

  handleChangeMethodList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    const selected_unit_number = value !== null ? value.label : [];
    this.setState({
      selectedMethodList: value,
      method_slug: unit_slug_value,
      method_lable: selected_unit_number,
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeHandler = async (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  async filterData() {
    let initiate_date = this.state.create_date
      ? Moment(this.state.create_date).format("YYYY-MM-DD")
      : "";
    let available_date = this.state.available_date
      ? Moment(this.state.available_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "initiate_date=" +
      initiate_date +
      "&available_date=" +
      available_date +
      "&status=" +
      (this.state.pay_status_slug ? this.state.pay_status_slug : "") +
      "&amount=" +
      this.state.amount +
      "&description=" +
      this.state.search_description +
      "&is_search=1";
    let moduleSearchString = "";
    let searchDisplay = "";
    let comma = ",";
    if (this.state.status_lable) {
      searchDisplay =
        (searchDisplay !== "" ? searchDisplay + comma : searchDisplay) +
        " Status: " +
        this.state.status_lable;
    }
    if (this.state.create_date) {
      searchDisplay =
        (searchDisplay !== "" ? searchDisplay + comma : searchDisplay) +
        " Initiated Date: " +
        Moment(initiate_date).format("MM/DD/YY");
    }
    if (this.state.available_date) {
      searchDisplay =
        (searchDisplay !== "" ? searchDisplay + comma : searchDisplay) +
        " Available Date: " +
        Moment(available_date).format("MM/DD/YY");
    }
    if (this.state.amount) {
      searchDisplay =
        (searchDisplay !== "" ? searchDisplay + comma : searchDisplay) +
        " Amount: " +
        this.state.amount;
    }
    if (this.state.search_description) {
      searchDisplay =
        (searchDisplay !== "" ? searchDisplay + comma : searchDisplay) +
        " Description: " +
        this.state.search_description;
    }
    if (this.state.pay_status_lable.length > 0) {
      searchDisplay =
        (searchDisplay !== "" ? searchDisplay + comma : searchDisplay) +
        "Status: " +
        this.state.pay_status_lable;
    }
    if (
      this.state.available_date ||
      this.state.create_date ||
      this.state.amount ||
      this.state.search_description
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1, is_by_paid: 0 });
      this.setState({ module_search: moduleSearchString });
      this.setState({ module_search_display: searchDisplay.split(",") });
      this.getPayOutReports(queryString);
    }
  }

  /* table events */
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getPayOutReports(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getPayOutReports(queryString);
    }
  };

  rowClickedHandlerPay = (event, data, rowIndex) => {
    if (data.unit_slug) {
      this.showPayoutReport(data.unit_slug);
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {};

  render() {
    /* const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    }; */
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    {this.props.match.params.propertySlug !== undefined ? (
                      <PayoutSidebar property_slug={this.state.property_slug} />
                    ) : (
                      <AllPropertyPayoutSidebar
                        property_slug={this.state.property_slug}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 payable_head">
                      <h3>Top-ups</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        <button
                          className="btn-success-outline-small button-width ml-3"
                          onClick={this.searchToggle}
                        >
                          Search
                        </button>
                        {global.userPermissions.checkPermission(
                          "payout-payments-add"
                        ) && (
                          <button
                            className="btn-success-outline-small ml-3 button-width-cust"
                            onClick={this.totalTopup}
                          >
                            Add Funds
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {this.state.is_search === 1 ? (
                    <div className="col-xxl-12 col-sm-12 pd-lg-l-45 pd-lg-r-30 pd-xs-r-0 d-inline-flex align-items-start justify-content-end justify_mob_filter">
                      <div className="custm-filter">
                        <div>
                          {" "}
                          <label className="form-label text-left pd-b-2 mb-0">
                            Search Applied On
                          </label>
                        </div>
                        <div className="filter_bg_cust">
                          {this.state.module_search_display.map((item, i) => {
                            return <span key={i}>{item}</span>;
                          })}
                          <button
                            onClick={this.clearFilter}
                            className="btn-fliter-clear"
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-lg-l-0 pd-lg-r-15 pd-xs-r-0">
                    <div className="scrolling-carousel pd-l-15 scroll-slide">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="all"
                        onSelect={(e) => this.getActiveClass(e)}
                        eventKey={this.state.activeClass}
                        className="pd-r-60"
                      >
                        <Tab.Content className="m-0">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="mg-t-18 brandList pd-l-15 pd-r-15 Report_table earning-report-tbl ">
                              <div className=" pd-lg-l-0 pd-xs-l-0">
                                <div className="scroll-report-table">
                                  <ReactDatatable
                                    className="table no-wrap mg-b-30 pd-b-2  brandtable border earning_reports resident-payout-table "
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Top-ups</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Initiated</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="create_date"
                    selected={this.state.create_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "create_date",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Amount</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="amount"
                      value={this.state.amount}
                      className="form-control max_width_100"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                      placeholder="Amount"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Available</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="available_date"
                    selected={this.state.available_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "available_date",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>
                <InputGroup className="mt-3">
                  <label>Description</label>
                  <Form.Control
                    className="form-control max_width_100"
                    autoComplete="off"
                    type="text"
                    name="search_description"
                    placeholder="Description"
                    onChange={(e) => this.onChangeSearchDescription(e)}
                  />
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={() => this.filterData()}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal for Top-ups */}
        <Modal
          show={this.state.is_topup}
          className=" searchModalbox1 pay_out_add_pay"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14 modal-body">
            <span className="welcomeClose" onClick={() => this.totalTopup()}>
              {global.closee}
            </span>
            <h3 className="text-center mt-2 mb-3">
              Add Funds to Your Stripe Balance
            </h3>
            <div className="payout_modal_box mb-3">
              <p>
                Available stripe balance:{" "}
                <span>
                  $
                  {this.state.Stripebalance
                    ? global.onKeyPressEvent.numberWithCommasForDecimal(
                        this.state.Stripebalance.toFixed(2)
                      )
                    : 0}
                </span>
              </p>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <div className="col-12">
                <label className="mg-b-5">Amount</label>
                <Form.Control
                  type="text"
                  name="topupamout"
                  value={this.state.topupamout1}
                  id="topupamout"
                  onChange={(e) => this.onChangetopup(e)}
                  className="mb-3"
                />
                <label className="mg-b-5">Description</label>
                <Form.Control
                  type="text"
                  name="description"
                  value={this.state.description}
                  id="topupamout"
                  onChange={(e) => this.onChangeDescription(e)}
                  className="mb-3"
                />
                <div className="d-flex justify-content-center mt-3 row">
                  <div className="col-12">
                    <Button
                      type="button"
                      className="m-0 btn-success btn-block btn-add-strp-pay btn btn-primary"
                      onClick={() => this.SendSendTopUpAmount()}
                    >
                      {this.state.isSend
                        ? global.loader
                        : "Add to stripe balance "}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* show response model  */}
        <Modal
          show={this.state.importResponseModel}
          className="modal-xs recordDetailmodalbox"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeimportResponseModel()}
            >
              {global.closee}
            </span>
            {/* <h3 className="text-center">Record detail</h3> */}
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-between mt-0 row">
                  <div className="msg">
                    <img src={SuccessLogo} alt="filterIcon" />
                    <p>{this.state.successMessage}</p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default ResidentPayoutReportList;

import axios from "axios";
import  { Component } from "react";
class UploadCropedImage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.UploadCropedImageToServer = this.UploadCropedImageToServer.bind(this);
        this.parseResizeOptions = this.parseResizeOptions.bind(this);
    }

    UploadCropedImageToServer = async (filedataarr) => {
        let finalResponse = [];
        let inputData = [];
        // console.log(filedataarr, "array");
        const HEADER_TOKEN = localStorage.getItem("headerToken") ? localStorage.getItem("headerToken"): "";
        const DEVICE_TYPE = process.env.REACT_APP_DEVICE_TYPE ? process.env.REACT_APP_DEVICE_TYPE : (localStorage.getItem("deviceType") ? localStorage.getItem("deviceType"): "");
        const PLATFORM_KEY = process.env.REACT_APP_PLATFORM_KEY ? process.env.REACT_APP_PLATFORM_KEY : (localStorage.getItem("platformKey") ? localStorage.getItem("platformKey"): "");
        const ROLE = global.currerntRoleSlug ? global.currerntRoleSlug : (localStorage.getItem('currerntRoleSlug') ? localStorage.getItem('currerntRoleSlug') : '');
        // const HEADER_X_TOKEN = process.env.REACT_APP_X_AUTH_TOKEN;


        let axiosConfig = {
            headers: {
                'Content-Type' : "multipart/form-data",
                'Authorization' : "Bearer " + HEADER_TOKEN,
                // 'X-Authorization': HEADER_X_TOKEN,
                'device-type' : DEVICE_TYPE,
                'role' : ROLE,
                'platform-key' : PLATFORM_KEY
            }
        };
        let path = process.env.REACT_APP_API_ENDPOINT + "upload";
        let allFilesData = [];
        let fileData = new FormData();
        fileData.append(filedataarr.fieldName, filedataarr.fileObject, filedataarr.fileObject.name);
        fileData.append("orgFileDestinationFolder", filedataarr.destinationFolder);
        fileData.append("fieldName", filedataarr.fieldName);
        fileData.append("uploadOn", filedataarr.uploadedTo);
        fileData.append("slug", filedataarr.slug);
        fileData.append("refTableId", filedataarr.refTableId);
        fileData.append("displayFileName", filedataarr.displayFileName);
        fileData.append("documentTypeSlug", filedataarr.documentTypeSlug);
        // fileData.append("description", dec ? dec[j] : "");
        fileData.append("description", filedataarr.description);
        // fileData.append("is_main_img", mainImg ? mainImg[j] : false);
        fileData.append("mediaType", filedataarr.mediaType);
        fileData.append("h1", filedataarr.h1);
        fileData.append("h2", filedataarr.h2);
        fileData.append("sortOrder", filedataarr.sortOrder);
        fileData.append("statusId", filedataarr.statusId);
        fileData.append("propertySlug", filedataarr.propertySlug);
        fileData.append("resizeOptions", filedataarr.resizeOptions);
        await axios
            .post(path, fileData, axiosConfig)
            .then((res) => {
                let uploadResponse = [];
                uploadResponse = {
                    orgFileName: res.data.originalName,
                    newName: res.data.newName,
                    fileStatus: res.status,
                    fileDestination: filedataarr.displayFileName,
                    order: filedataarr.sortOrder,
                    description: res.data.description,
                    // is_main_img: res.data.is_main_img,
                };
                allFilesData.push(uploadResponse);
            })
            .catch((err) => {
                // console.log("err", err);
            });

        inputData = {
            fieldName: filedataarr.fieldName,
            uploadedTo: filedataarr.uploadedTo,
            slug: filedataarr.slug,
            refTableId: filedataarr.refTableId,
            displayFileName: filedataarr.displayFileName,
            documentTypeSlug: filedataarr.documentTypeSlug,
            description: filedataarr.description,
            // is_main_img: is_main_img,
            mediaType: filedataarr.mediaType,
            h1: filedataarr.h1,
            h2: filedataarr.h2,
            sortOrder: filedataarr.sortOrder,
            statusId: filedataarr.statusId,
            propertySlug: filedataarr.propertySlug,
            checkValidation: filedataarr.checkValidation,
            validationRules: filedataarr.validationRules,
            resizeOptions: this.parseResizeOptions(filedataarr.resizeOptions),
            filesData: allFilesData,
        };
        await finalResponse.push(inputData);
        let result = {
            response:finalResponse,
            status:true
        }
        return result;

    }

    parseResizeOptions = async (resizeOptionsList) => {
        let finalResizeOptions = [];
        let resizeOptions = resizeOptionsList.split("||");
        for (let m = 0; m < resizeOptions.length; m++) {
            let objectValue = {};
            let options = resizeOptions[m].split(",");
            for (let n = 0; n < options.length; n++) {
                let options2 = options[n].split(":");
                objectValue[options2[0]] = options2[1];
            }
            await finalResizeOptions.push(objectValue);
        }
        return finalResizeOptions;
    }

    render() {
        return ("");
    }
}
export default UploadCropedImage;
//     							                          //
//  Program: ShopSidebar.jsx                      //
//  Application: Property                                 //
//  Option: common component House Keeping Sidebar        //
//  Developer: NP 		                                  //
//  Date: 2022-04-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
class ShopSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug") ? sessionStorage.getItem("property_slug") : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <ul>
                  {global.userPermissions.checkPermission('store-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("items")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/items"}
                      >
                        Store
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission('sales-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("orders")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/orders"}
                      >
                        Sales
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission('purchases-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("inventory")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/inventory"}
                      >
                        Purchases
                      </Link>
                    </li>
                  }
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span>Sub Menu</span>
                <i className="fas fa-chevron-down"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu className="tx-13">
                <aside>
                <nav className="nav nav-pills flex-column pd-r-8">
                  <ul>
                    <li>
                      <ul>
                      {global.userPermissions.checkPermission('store-list') &&
                        <li>
                          <Link
                            className={
                              this.isPathActive("items")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/items"}
                          >
                            Store
                          </Link>
                        </li>
                      }
                      {global.userPermissions.checkPermission('sales-list') &&
                        <li>
                          <Link
                            className={
                              this.isPathActive("orders")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/orders"}
                          >
                            Sales
                          </Link>
                        </li>
                      }
                      {global.userPermissions.checkPermission('purchases-list') &&
                        <li>
                          <Link
                            className={
                              this.isPathActive("inventory")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/inventory"}
                          >
                            Purchases
                          </Link>
                        </li>
                      }
                      </ul>
                    </li>
                  </ul>
                </nav>
                </aside>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </>
    );
  }
}
export default ShopSidebar;
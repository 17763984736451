/////////////////////////////////////////////////////
//     							                               //
//  Program: MroForm.jsx                           //
//  Application: Property                          //
//  Option:  MroForm                               //
//  Developer: Ashish Kumar, Kiran, Chandra Shekhar //
//  Date: 2022-05-30                               //
//                                                 //
/////////////////////////////////////////////////////
import React, { Component } from "react";
import { Form, Container, Modal } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import MROCategoryService from "../../services/MROCategoryService";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import modaldeleteicon from "./../../assets/images/delete.svg";
import Select from "react-select";
import ChecklistService from "../../services/ChecklistService";
import MROService from "../../services/MROService";
import Moment from "moment";
import PropertyStaffService from "../../services/PropertyStaffService";
import DatePicker from "react-datepicker";
import UnitsService from "../../services/UnitsService";
import FileWidget from "../fileUploader/FileWidget";
import MultiImageService from "../../services/MultiImageService";
import imgIcon from "../../assets/images/imgIcon.png";
import AllPropertiesMaintenanceSidebar from "../common/AllPropertiesMaintenanceSidebar";
import MaintenanceRequestSidebar from "../common/MaintenanceRequestSidebar";
//// import Gallery from "react-grid-gallery";

export class MroForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      slug: this.props.match.params.slug,
      isSubmit: false,
      sort_order: "",
      title: "",
      note: "",
      mro_category_slug: "",
      mroCategory: [],
      selectedMroCategory: [],
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      propertyList: [],
      selectedPropertyList: [],
      zone_type: "",
      zoneTypeList: [],
      selectedZoneTypeList: [],
      location_slug: "",
      locationList: [],
      selecteLocationList: [],
      priority_type: "",
      priorityType: [],
      selectedPriorityType: [],
      status_id: [],
      statusList: [],
      userList: [],
      selectedUserList: [],
      technician: [],
      due_date: "",
      newRelatedFilesDesc: [],
      activeClass: "info",
      isRefresh: false,
      mroNumber: "",
      createdBy: "",
      createdAt: "",
      inspectionNo: "",
      updatedBy: "",
      updatedAt: "",
      imageData: [],
      mroImages: [],
      deletedFileList: [],
      mroCategoryListTotal: 0,
      newMroCategoryList: [],
      selectedPriorityList: [],
      selectPriorityList: [],
      allStaffList: [],
      allStaffListTotal: 0,
      selectedAllStaffList: [],
      selectedStatusList: [],
      selectStatusList: [],
      mroList: [],
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      residentList: [],
      newResidentList: [],
      selectedResidentList: [],
      editChat: 0,
      chatUpdate: "",
      setRadioValue: 2,
      files_data: [],
      gallery_img: [],
      mro_number: "",
    };
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateMRO = this.saveOrUpdateMRO.bind(this);
    this.deleteMRO = this.deleteMRO.bind(this);
    this.opendeleteMROModal = this.opendeleteMROModal.bind(this);
    this.opendeleteMROChatModal = this.opendeleteMROChatModal.bind(this);
    this.changeHandlerChat = this.changeHandlerChat.bind(this);
  }

  componentDidMount() {
    this.getResidentData();
    this.getUnitData();
    this.getMROData();
    this.getMROCategoryList();
    this.getZoneTypeData();
    this.getPriorityList();
    this.getStaffList();
    this.getStatusList();
  }

  getActiveClass(e) {
    if (e === "notes") {
      this.setState({ isRefresh: true });
    } else {
      this.setState({ isRefresh: false });
    }
    this.setState({ activeClass: e });
  }

  async getPriorityList() {
    const selectPriorityList = [
      { value: "1", label: "High" },
      { value: "2", label: "Medium" },
      { value: "3", label: "Low" },
    ];
    this.setState({ selectPriorityList: selectPriorityList });
  }

  async getStatusList() {
    const selectStatusList = [
      { value: "1", label: "Completed" },
      { value: "2", label: "In Progress" },
      { value: "3", label: "Pending" },
    ];
    this.setState({ selectStatusList: selectStatusList });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
  }

  async getResidentData(queryString = "") {
    var residentList = [];
    var residentListTotal = 0;

    let res = await MROService.getMROReporterList(
      "property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      residentList = res.data ? res.data : [];
      residentListTotal = residentList ? residentList.length : 0;
    }

    const newResidentList =
      residentListTotal > 0
        ? residentList.map(({ slug, first_name, last_name }) => ({
            value: slug,
            label: first_name + " " + last_name,
          }))
        : [];
    this.setState({
      residentList: residentList,
      residentListTotal: residentListTotal,
      newResidentList: newResidentList,
    });
  }

  // Get Maintenance Category List
  async getMROCategoryList(queryString = "") {
    this.setState({ loading: true });
    var mroCategoryList = [];
    var mroCategoryListTotal = 0;
    let res = await MROCategoryService.getMROCategory(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      mroCategoryList = res.data ? res.data : [];
      mroCategoryListTotal = mroCategoryList ? mroCategoryList.length : 0;
    }
    const newMroCategoryList =
      mroCategoryListTotal > 0
        ? mroCategoryList.map(({ slug, mro_category_name }) => ({
            value: slug,
            label: mro_category_name,
          }))
        : [];
    this.setState({
      mroCategoryList: mroCategoryList,
      mroCategoryListTotal: mroCategoryListTotal,
      newMroCategoryList: newMroCategoryList,
    });
    this.setState({ loading: false });
  }

  async getZoneTypeData(queryString) {
    this.setState({ showSpinner: true, loading: true });
    var zoneTypeList = [];
    var zoneTypeListTotal = 0;

    let res = await ChecklistService.getZoneTypeList(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      zoneTypeList = res.data ? res.data : [];
      zoneTypeListTotal = zoneTypeList ? zoneTypeList.length : 0;
    }
    const newZoneTypeList =
      zoneTypeListTotal > 0
        ? zoneTypeList.map(({ slug, zone_type_name }) => ({
            value: slug,
            label: zone_type_name,
          }))
        : [];
    this.setState({
      zoneTypeList: zoneTypeList,
      zoneTypeListTotal: zoneTypeListTotal,
      newZoneTypeList: newZoneTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getStaffList() {
    this.setState({ showSpinner: true, loading: true });
    var allStaffList = [];
    var allStaffListTotal = 0;

    let res = await PropertyStaffService.getPropertyStaff(
      "is_dropdown=1&" + "property_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      allStaffList = res.data ? res.data : [];
      allStaffListTotal = allStaffList ? allStaffList.length : 0;
    }
    const newAllStaffList =
      allStaffListTotal > 0
        ? allStaffList.map(({ slug, staff_contact_name }) => ({
            value: slug,
            label: staff_contact_name,
          }))
        : [];
    this.setState({
      allStaffList: allStaffList,
      allStaffListTotal: allStaffListTotal,
      newAllStaffList: newAllStaffList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getMROData() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await MROService.getMROBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData.mroSlug = res.data ? res.data.slug : "";
        resData.title = res.data ? res.data.title : "";
        resData.note = res.data ? res.data.note : "";
        resData.mro_category_slug = res.data.mro_category
          ? res.data.mro_category.slug
          : [];
        resData.zone_type = res.data.zone_type ? res.data.zone_type.slug : [];
        resData.priority_type = res.data ? res.data.priority_type : [];
        resData.due_date = res.data.due_date
          ? Moment(res.data.due_date).toDate("MM/dd/YY")
          : null;

        resData.technician = res.data.staff ? res.data.staff.slug : [];
        resData.status_id = res.data ? res.data.status_id : [];
        resData.unit_slug = res.data.unit ? res.data.unit.slug : "";
        resData.resident_slug = res.data.resident ? res.data.resident.slug : "";
        resData.gallery_img = res.data.images ? res.data.images : "";
        resData.setRadioValue = res.data.is_notification
          ? parseInt(res.data.is_notification)
          : "";
        resData.is_notification = res.data.is_notification
          ? res.data.is_notification
          : "";
        resData.detail_notfication = res.data.detail_notfication
          ? res.data.detail_notfication
          : "";
        var selectedResidentList = [];
        this.setState({ mro_number: res.data.mro_number });
        selectedResidentList = res.data.resident
          ? [
              {
                value: res.data.resident.slug,
                label:
                  res.data.resident.first_name +
                  " " +
                  res.data.resident.last_name,
              },
            ]
          : "";
        var selectedUnitList = [];
        selectedUnitList = res.data.unit
          ? [
              {
                value: res.data.unit.slug,
                label: res.data.unit.unit_name
                  ? res.data.unit.unit_name
                  : res.data.unit.unit_number,
              },
            ]
          : "";
        var selectedMroCategoryList = [];
        selectedMroCategoryList = res.data.mro_category
          ? [
              {
                value: res.data.mro_category.slug,
                label: res.data.mro_category.mro_category_name,
              },
            ]
          : [];
        var selectedZoneTypeList = [];
        selectedZoneTypeList = res.data.zone_type
          ? [
              {
                value: res.data.zone_type.slug,
                label: res.data.zone_type.zone_type_name,
              },
            ]
          : [];

        var selectedPriorityList = [];
        this.state.selectPriorityList.forEach((item, i) => {
          if (parseInt(item.value) == parseInt(res.data.priority_type)) {
            selectedPriorityList = [
              {
                value: item.value,
                label: item.label,
              },
            ];
            return false;
          }
        });

        var selectedStatusList = [];
        this.state.selectStatusList.forEach((item, i) => {
          if (parseInt(item.value) === parseInt(res.data.status_id)) {
            selectedStatusList = [
              {
                value: item.value,
                label: item.label,
              },
            ];
            return false;
          }
        });

        var selectedAllStaffList = [];
        selectedAllStaffList = res.data.staff
          ? [
              {
                value: res.data.staff.slug,
                label: res.data.staff.staff_contact_name,
              },
            ]
          : [];
      }
      this.setState(resData);

      this.setState({
        selectedMroCategoryList: selectedMroCategoryList,
        selectedZoneTypeList: selectedZoneTypeList,
        selectedPriorityList: selectedPriorityList,
        selectedStatusList: selectedStatusList,
        selectedAllStaffList: selectedAllStaffList,
        selectedUnitList: selectedUnitList,
        selectedResidentList: selectedResidentList,
        //imagesArray: selectedResidentList,
      });
      this.setState({ input: resData });
      this.defaultData = resData;
    }

    this.setState({ zoneTypeList: global.zoneTypeList });
    this.setState({ priorityType: global.priorityType });
    this.setState({ statusList: global.mroStatusList });
    this.setState({ loading: false });
  }

  // Save and Update Method Start
  saveOrUpdateMRO = async (e) => {
    e.preventDefault();
    let errors = {};
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true, images: [] });
      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun("mro_form");
      let inputFilesData = [];
      if (output.status !== false) {
        if (output.response[0].filesData) {
          if (output.response[0].filesData.length > 0) {
            inputFilesData = {
              availableFiles: output.response,
              deletedFiles: this.state.deletedFileList,
            };
          }
        }
        let inputData = {
          title: this.state.title,
          note: this.state.note,
          mro_category_slug: this.state.mro_category_slug,
          zone_type: this.state.zone_type,
          property_slug: this.state.property_slug,
          priority_type: this.state.priority_type,
          technician: this.state.technician,
          unit_slug: this.state.unit_slug,
          resident_slug: this.state.resident_slug,
          due_date: this.state.due_date
            ? Moment(this.state.due_date).format("YYYY-MM-DD")
            : null,
          status_id: this.state.status_id,
          // imagesData: newFileName,
          is_notification: this.state.is_notification,
          detail_notfication: this.state.detail_notfication,
          imageData: inputFilesData,
          deletedFiles: this.state.deletedFileList,
        };
        if (this.state.slug !== undefined) {
          let res = await MROService.updateMRO(this.state.slug, inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () =>
                this.props.history.push(
                  this.props.match.params.propertySlug !== undefined
                    ? "/properties/view/" + this.state.property_slug + "/mros"
                    : "/mros"
                ),
              global.redirect_time
            );
            this.getMROData();
          } else {
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
          }
        } else {
          let res = await MROService.createMRO(inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            this.setState({ slug: res.data.mroLastInsertSlug });
            setTimeout(
              () =>
                this.props.history.push(
                  this.props.match.params.propertySlug !== undefined
                    ? "/properties/view/" + this.state.property_slug + "/mros"
                    : "/mros"
                ),
              global.redirect_time
            );
            this.getMROData();
          } else {
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
          }
        }
      } else {
        let checkError = output.response[0].validationMessage[0];

        if (checkError[0].includes("30 mb") === true) {
          errors["document"] = "The file should not be greater than 30 mb.";
          this.setState({ errors: errors });
        } else {
          errors["document"] =
            "The file should be pdf, doc, docx, xls, xlsx, csv, png, jpg.";
          this.setState({ errors: errors });
        }
      }

      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };
  // Save and Update Method End

  opendeleteMROModal(slug) {
    this.setState({ deleteMROModal: true, slug: slug });
  }

  opendeleteMROChatModal(slug) {
    this.setState({ deleteMROChatModal: true, chatSlug: slug });
  }

  closedeleteMROModal() {
    this.setState({ deleteMROModal: false });
  }

  closedeleteMROChatModal() {
    this.setState({ deleteMROChatModal: false });
  }

  editChat(i) {
    this.validator = new SimpleReactValidator();
    this.setState({ editChat: 1, chatIndex: i, errors: {} });
  }

  closeEditChat(val = false) {
    this.validator = new SimpleReactValidator();
    this.setState({ editChat: val, errors: {} });
  }

  // Delete Method Start
  async deleteMRO(slug) {
    this.setState({ loading: true });
    let res = await MROService.deleteMRO(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteMROModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" + this.state.property_slug + "/mros"
              : "/mros"
          ),
        global.redirect_time
      );
    } else {
      this.closedeleteMROModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  // Delete Method End

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeResidentList(value) {
    let resident_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        resident_slug.push(item.value);
      });
    }
    const resident_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedResidentList: value,
      resident_slug: resident_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({
      [event.target.name]: value,
      input: input,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    if (event.target.name === "comment") {
      this.setState({ errors: {} });
    }
  };
  changeHandlerRadio = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    value = event.target.type === "radio" ? parseInt(value) : value;
    let setRadioValue = event.target.type === "radio" ? parseInt(value) : value;
    this.setState({
      [event.target.name]: value,
      input: input,
      setRadioValue: setRadioValue,
    });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    if (event.target.name === "comment") {
      this.setState({ errors: {} });
    }
  };

  changeHandlerChat = (index, event) => {
    const values = [...this.state.mroList];
    var chatUpdate = "";
    if (event.target.name === "chat") {
      values[index].chat = event.target.value;
      chatUpdate = event.target.value;
      this.setState({ chat: values, chatUpdate: chatUpdate });
    }
    this.setState({ errors: {}, confirm_back: 1 });
  };

  // Status Change Handler
  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeMroCategoryList(value) {
    let mro_category_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        mro_category_slug.push(item.value);
      });
    }
    const mro_category_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedMroCategoryList: value,
      mro_category_slug: mro_category_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStaffList(value) {
    let technician = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        technician.push(item.value);
      });
    }
    const technician_value = value !== null ? value.value : [];
    this.setState({
      selectedAllStaffList: value,
      technician: technician_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeZoneTypeList(value) {
    let zone_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        zone_type.push(item.value);
      });
    }
    const zone_type_value = value !== null ? value.value : [];
    this.setState({
      selectedZoneTypeList: value,
      zone_type: zone_type_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangePriorityList(value) {
    let priority_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        priority_type.push(item.value);
      });
    }
    const priority_type_value = value !== null ? value.value : [];
    this.setState({
      selectedPriorityList: value,
      priority_type: priority_type_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    const status_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: status_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Maintenance Requests";
  }
  /*file download */
  async getDownloadFile(url, name) {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    window.location.href = url;
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }
  openDeleteDocModal(slug) {
    this.setState({ deleteDocModal: true, slug: slug });
  }

  closeDeleteDocModal() {
    this.setState({ deleteDocModal: false, slug: "" });
  }
  async deleteDoc(slug) {
    this.setState({ showSpinner: true });
    let inputData = {
      path: "mros",
      refTableId: 13,
      slug: this.state.slug,
      mediaType: 1,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getMROData(this.state.mro_slug);
    } else {
      this.closeDeleteDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MaintenanceRequestSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <AllPropertiesMaintenanceSidebar
                      property_slug={this.state.property_slug}
                    />
                  </aside>
                </div>
              </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
                      {this.state.slug !== undefined
                        ? global.userPermissions.checkPermission(
                            "maintenance-requests-delete"
                          ) && (
                            <span
                              onClick={() => {
                                this.opendeleteMROModal(this.state.slug);
                              }}
                              className="deleteicon mg-r-20"
                            >
                              <img src={deleteicon} alt="" />
                            </span>
                          )
                        : ""}
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          this.props.history.push(
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                  this.state.property_slug +
                                  "/mros"
                              : "/mros"
                          );
                        }}
                      >
                        Cancel
                      </button>

                      {global.userPermissions.checkPermission(
                        "maintenance-requests-update"
                      ) && (
                        <button
                          type="button"
                          className="btn-success ml-3"
                          onClick={this.saveOrUpdateMRO}
                          disabled={this.state.isSubmit ? true : false}
                        >
                         {this.state.isSubmit
                            ? global.loader
                            : this.state.slug !== undefined
                            ? "Save"
                            : "Save"}

                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-l-30 pd-r-30 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    <form
                      id="mro_form"
                      onSubmit={this.onSubmitFun}
                      method="post"
                      encType="multipart/form-data"
                    >
                      <div className="m-0">
                        {/* listing form */}
                        <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                          <h3 className="mg-l-0 mg-t-10 background-head">
                            {this.state.slug !== undefined ? (
                              <>Request Number : {this.state.mro_number}</>
                            ) : (
                              "Basic"
                            )}
                          </h3>
                        </div>
                        <div className="row align-items-basline pd-b-0 border-bottom-0 pd-r-15">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Issue{" "}
                            </label>

                            <Form.Control
                              type="text"
                              onChange={this.changeHandler}
                              name="title"
                              as="textarea"
                              value={this.state.title}
                              maxLength="500"
                            />
                            {this.state.errors.title ? (
                              <div className="text-danger">
                                {this.state.errors.title}
                              </div>
                            ) : (
                              this.validator.message(
                                "Issue",
                                this.state.title,
                                "required|max:1000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Unit <span className="optional">(Optional)</span>
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedUnitList}
                              options={this.state.newUnitList}
                              onChange={(value) =>
                                this.handleChangeUnitList(value)
                              }
                              defaultValue={this.state.selectedUnitList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Category{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedMroCategoryList}
                              options={this.state.newMroCategoryList}
                              onChange={(value) =>
                                this.handleChangeMroCategoryList(value)
                              }
                              defaultValue={this.state.selectedMroCategoryList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Reported by{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedResidentList}
                              options={this.state.newResidentList}
                              onChange={(value) =>
                                this.handleChangeResidentList(value)
                              }
                              defaultValue={this.state.selectedResidentList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Priority{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedPriorityList}
                              options={this.state.selectPriorityList}
                              onChange={(value) =>
                                this.handleChangePriorityList(value)
                              }
                              defaultValue={this.state.selectedPriorityList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Assign to{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedAllStaffList}
                              options={this.state.newAllStaffList}
                              onChange={(value) =>
                                this.handleChangeStaffList(value)
                              }
                              defaultValue={this.state.selectedAllStaffList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Due Date{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <DatePicker
                              className="form-control max_width_100"
                              disabledKeyboardNavigation
                              showMonthDropdown
                              showYearDropdown
                              strictParsing
                              scrollableYearDropdown
                              minDate={new Date()}
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="due_date"
                              dateFormat="MM/dd/yy"
                              selected={this.state.due_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "due_date",
                                    value: data,
                                  },
                                })
                              }
                            />
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Status{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedStatusList}
                              options={this.state.selectStatusList}
                              onChange={(value) =>
                                this.handleChangeStatusList(value)
                              }
                              defaultValue={this.state.selectedStatusList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-xs-t-0 pd-l-0 pd-r-30 pd-r-40 mg-b-0  pd-xs-t-0 d-flex align-items-baseline">
                            <ul className="formcheck-section addcheckproperty mg-b-0 mro-checkbox">
                              <li>
                                <label className="form-label text-left pd-b-5 mg-xs-l-0 mg-lg-l-30">
                                  Is guest or resident notification of
                                  completion required?
                                  <span className="optional">(Optional)</span>
                                </label>
                                <div className="d-flex mg-l-30 mg-xs-l-0 mg-lg-l-30">
                                  <Form.Check
                                    onChange={this.changeHandlerRadio}
                                    name="is_notification"
                                    value="1"
                                    checked={
                                      this.state.setRadioValue === 1
                                        ? true
                                        : false
                                    }
                                    type={"radio"}
                                    id="1"
                                    label={"Yes"}
                                    className={"rdiobox mg-r-20"}
                                  />
                                  <Form.Check
                                    onChange={this.changeHandlerRadio}
                                    name="is_notification"
                                    value="2"
                                    checked={
                                      this.state.setRadioValue === 2
                                        ? true
                                        : false
                                    }
                                    type={"radio"}
                                    id="2"
                                    label={"No"}
                                    className={"rdiobox"}
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>

                          {this.state.setRadioValue == 1 ? (
                            <>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                                <label className="form-label text-left pd-b-5">
                                  Notification complete (If Necessary?){" "}
                                  <span className="optional">(Optional)</span>
                                </label>
                                <Form.Control
                                  type="text"
                                  onChange={this.changeHandler}
                                  name="detail_notfication"
                                  value={this.state.detail_notfication}
                                  maxLength="500"
                                />
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-8 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-0 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Notes <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              as="textarea"
                              onChange={this.changeHandler}
                              name="note"
                              value={this.state.note}
                              maxLength="500"
                            />
                          </div>
                        </div>
                        <div className="row align-items-baseline- pd-t-0 pd-b-0 border-bottom-0 h-100 documnet-section-upload">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Upload Photos{" "}
                            </label>
                            <div className="d-flex flex-row align-items-center upload upload-files-section">
                              <FileWidget
                                fieldName="file_name"
                                fieldTitle="Select File"
                                checkValidation="true"
                                allowedType="image/png,image/jpeg,image/jpg"
                                maxFileSize={1000}
                                maxFileSizeType="mb"
                                maxFileAllowed={10}
                                uploadOn="s3"
                                slug={this.state.slug}
                                refTableId="13"
                                displayFileName={this.state.display_file_name}
                                documentTypeSlug={this.state.document_type_slug}
                                description={this.state.description}
                                mediaType={1}
                                sortOrder={
                                  this.state.sort_order !== ""
                                    ? this.state.sort_order
                                    : 0
                                }
                                statusId={this.state.status_id === true ? 1 : 2}
                                helpText="to download sample file."
                                sampleFileUrl="/sample.jpg"
                                drag="true"
                                dragText="Select or drop file size upto 30 MB"
                                inputClassName="form-control"
                                labelClassName="form-label"
                                formID="mro_form"
                                uploadType="multiple"
                                resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                                //propertySlug={this.props.property_slug}
                                destinationFolder="mros"
                                uploadedFiles={this.state.profile_image2}
                                deletedFiles={this.state.deletedFileList}
                                acceptOnly="image/png,image/jpeg,image/jpg"
                                displayPreviewStyle="horizontal"
                              />
                            </div>
                            {this.state.errors.document ? (
                              <div className="text-danger">
                                {this.state.errors.document}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {(this.state.slug !== undefined ||
                            this.state.slug !== "") &&
                          this.state.gallery_img.length > 0 ? (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 uploadSection">
                              <label className="form-label text-left pd-b-5">
                                Uploaded Document{" "}
                              </label>
                              {this.state.slug !== undefined ? (
                                <>
                                  {this.state.gallery_img.length > 0
                                    ? this.state.gallery_img.map((opt, i) => {
                                        return (
                                          <React.Fragment key={i}>
                                            <div className="viewfile-section">
                                              <span className="viewfile d-flex align-items-center">
                                                <img
                                                  download
                                                  src={imgIcon}
                                                  alt=""
                                                  className="mg-r-5 hand-cursor"
                                                />
                                                <p
                                                  className="mg-r-5 hand-cursor"
                                                  onClick={() => {
                                                    this.getDownloadFile(
                                                      opt.uri,
                                                      opt.display_file_name
                                                    );
                                                  }}
                                                >
                                                  {opt.display_file_name}
                                                </p>
                                              </span>
                                              <span>
                                                {opt.updated_at
                                                  ? Moment(
                                                      opt.updated_at,
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    ).format(
                                                      global.dateFormat +
                                                        " HH:mm:ss"
                                                    )
                                                  : Moment(
                                                      opt.created_at,
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    ).format(
                                                      global.dateFormat +
                                                        " HH:mm:ss"
                                                    )}
                                              </span>
                                              <img
                                                src={deleteicon}
                                                className="hand-cursor"
                                                alt=""
                                                onClick={() =>
                                                  this.openDeleteDocModal(
                                                    opt.slug
                                                  )
                                                }
                                              />
                                            </div>
                                          </React.Fragment>
                                        );
                                      })
                                    : ""}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteMROModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteMROModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteMROModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteMRO(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteMROChatModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteMROChatModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteMROChatModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteMROChat(this.state.chatSlug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteDocModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteDocModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteDocModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteDoc(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default MroForm;

//     							                          //
//  Program: Propertyidebar.jsx                           //
//  Application: Property                                 //
//  Option: common component Property Sidebar TAbs        //
//  Developer: NP 		                                  //
//  Date: 2022-04-01                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
class PropertySidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug")
        ? sessionStorage.getItem("property_slug")
        : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  toggleChildMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".left");
    data.classList.replace("left", "hide");
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i>Back to CRM
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <ul>
                    {/* <li>
                                    <Link
                                        className={
                                          this.isPathActive("staff")
                                          ? "nav-link active"
                                          : "nav-link"
                                        }
                                        data-toggle="tab"
                                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/staff"}
                                    >
                                        Staff
                                    </Link>
                                    </li>
                                    <li>
                                    <Link
                                        className={
                                          this.isPathActive("vendor")
                                          ? "nav-link active"
                                          : "nav-link"
                                        }
                                        data-toggle="tab"
                                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/vendor"}
                                    >
                                        Vendors
                                    </Link>
                                    </li> */}
                    <li>
                      <Link
                        className={
                          this.isPathActive("staff") ||
                          this.isPathActive("vendor") ||
                          this.isPathActive("crm-resident")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={
                          "/properties/view/" +
                          (this.props.property_slug
                            ? this.props.property_slug
                            : this.state.property_slug) +
                          "/staff"
                        }
                      >
                        Contacts
                      </Link>
                    </li>
                    {global.userPermissions.checkPermission(
                      "crm-campaigns-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-campaign") ||
                            this.isPathActive("crm-campaigns")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/crm-campaign"
                          }
                        >
                          Campaign
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-templates-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-template") ||
                            this.isPathActive("crm-templates")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/crm-templates"
                          }
                        >
                          Templates
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-lists-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/crm-list"
                          }
                        >
                          Lists
                        </Link>
                      </li>
                    )}
                    {/* <li>
                      <Link
                        className={
                          this.isPathActive("leads")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/leads"}
                      >
                        Leads
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        className={
                          this.isPathActive("property-contact-lists")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/property-contact-lists"}
                      >
                        Lists
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          this.isPathActive("property-segments")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/property-segments"}
                      >
                        Segments
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          this.isPathActive("property-campaigns")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/property-campaigns"}
                      >
                        Campaigns
                      </Link>
                    </li> */}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to CRM
              </span>
              <ul>
                <li>
                  <ul>
                    {/* <li>
                                  <Link
                                      className={
                                        this.isPathActive("staff")
                                        ? "nav-link active"
                                        : "nav-link"
                                      }
                                      data-toggle="tab"
                                      to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/staff"}
                                  >
                                      Staff
                                  </Link>
                                  </li>
                                  <li>
                                  <Link
                                      className={
                                        this.isPathActive("vendor")
                                        ? "nav-link active"
                                        : "nav-link"
                                      }
                                      data-toggle="tab"
                                      to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug ) + "/vendor"}
                                  >
                                      Vendors
                                  </Link>
                                  </li> */}
                    <li>
                      <Link
                        className={
                          this.isPathActive("staff") ||
                          this.isPathActive("vendor") ||
                          this.isPathActive("crm-resident")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={
                          "/properties/view/" +
                          (this.props.property_slug
                            ? this.props.property_slug
                            : this.state.property_slug) +
                          "/staff"
                        }
                      >
                        Contacts
                      </Link>
                    </li>
                    {global.userPermissions.checkPermission(
                      "crm-campaigns-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-campaign") ||
                            this.isPathActive("crm-campaigns")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/crm-campaign"
                          }
                        >
                          Campaign
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-templates-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-template") ||
                            this.isPathActive("crm-templates")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/crm-templates"
                          }
                        >
                          Templates
                        </Link>
                      </li>
                    )}
                    {global.userPermissions.checkPermission(
                      "crm-lists-list"
                    ) && (
                      <li>
                        <Link
                          className={
                            this.isPathActive("crm-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={
                            "/properties/view/" +
                            (this.props.property_slug
                              ? this.props.property_slug
                              : this.state.property_slug) +
                            "/crm-list"
                          }
                        >
                          Lists
                        </Link>
                      </li>
                    )}
                    {/* <li>
                            <Link
                              className={
                                this.isPathActive("leads")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/leads"}
                            >
                              Leads
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                this.isPathActive("property-contact-lists")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/property-contact-lists"}
                            >
                              Lists
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                this.isPathActive("property-campaigns")
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/property-campaigns"}
                            >
                              Campaigns
                            </Link>
                          </li> */}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default PropertySidebar;

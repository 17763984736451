//     							                          //
//  Program: Checklist.jsx                                     //
//  Application: House keeping                                 //
//  Option: Home Sharing Listing                               //
//  Developer: NP 		                                       //
//  Date: 2022-08-30                                          //
//                                                             //
/////////////////////////////////////////////////////////     ///

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import AllPropertiesHouseKeepingSidebar from "../common/AllPropertiesMaintenanceSidebar";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import NoData from "../common/NoData";

class Vendors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: this.props.match.params.propertySlug,
    };
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <HouseKeepingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertiesHouseKeepingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="dashboardRightcard mg-t-15">
                  <NoData />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}
export default Vendors;

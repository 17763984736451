////////////////////////////////////////////////////////////
//     							                                      //
//  Program: StaffForm.jsx                                //
//  Application:  Staff                                   //
//  Option: For add or update Staff                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-13                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, InputGroup, Form } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import StaffService from "../../services/StaffService";
import PropertyStaffService from "../../services/PropertyStaffService";
import StaffTypeService from "../../services/StaffTypeService";
import staffImg from "./../../assets/images/profile-nogender.png";
import Alerts from "../common/Alerts";
import Select from "react-select";
// import pluswIcon from "./../../assets/images/plus.svg";
import ContactSidebar from "../common/ContactSidebar";
// import infosize from "../../assets/images/Info.svg";
import PhoneInput from "react-phone-input-2";

export class StaffForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      staffTypeList: [],
      newStaffTypeList: [],
      selectedStaffTypeList: [],
      staffList: [],
      newStaffList: [],
      selectedStaffList: [],
      managerList: [],
      manager_type_slug: [],
      newManagerList: [],
      selectedManagerList: [],
      allStaffList: [],
      newAllStaffList: [],
      selectedAllStaffList: [],
      allStaffListTotal: [],
      status_id: false,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      staff_contact_name: "",
      staff_contact_email: "",
      staff_contact_number: "",
      staff_contact_extension: "",
      staff_contact_image: "",
      job_title: "",
      create_new_staff: true,
      notes: "",
      sort_order: "",
      imageUrl: staffImg,
      pathname: window.location.pathname,
      staff_contact_info_slug: "",
      staff_contact_type_slug: "",
      reporting_manager_type_slug: "",
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
    };
    this.staffImg = React.createRef();
    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getStaffOnSelectData = this.getStaffOnSelectData.bind(this);
    this.saveOrUpdateStaff = this.saveOrUpdateStaff.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  async getStaffContactType() {
    this.setState({ showSpinner: true, loading: true });
    var staffTypeList = [];
    var staffTypeListTotal = 0;
    let res = await StaffTypeService.getStaffType("is_dropdown=1");
    if (global.successStatus.includes(res.status)) {
      staffTypeList = res.data ? res.data : [];
      staffTypeListTotal = staffTypeList ? staffTypeList.length : 0;
    }
    const newStaffTypeList =
      staffTypeListTotal > 0
        ? staffTypeList.map(({ slug, staff_contact_type_name }) => ({
            value: slug,
            label: staff_contact_type_name,
          }))
        : [];
    this.setState({
      showSpinner: false,
      loading: false,
      staffTypeListTotal: staffTypeListTotal,
      staffTypeList: staffTypeList,
      newStaffTypeList: newStaffTypeList,
    });
  }

  /* to get staff data */
  async getStaffData(slug, queryString = "") {
    var staffList = [];
    var staffListTotal = 0;

    let res = await StaffService.getStaff(
      "staff_contact_type_slug=" + slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      staffList = res.data.data ? res.data.data : [];
      staffListTotal = staffList ? staffList.length : 0;
    }
    const newStaffList =
      staffListTotal > 0
        ? staffList.map(({ slug, staff_contact_name }) => ({
            value: slug,
            label: staff_contact_name,
          }))
        : [];
    this.setState({
      staffList: staffList,
      staffListTotal: staffListTotal,
      newStaffList: newStaffList,
    });
  }

  /* to get staff data */
  async getAllReportingManager() {
    this.setState({ showSpinner: true, loading: true });
    var allStaffList = [];
    var allStaffListTotal = 0;

    let res = await PropertyStaffService.getPropertyStaff(
      "property_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      allStaffList = res.data.data ? res.data.data : [];
      allStaffListTotal = allStaffList ? allStaffList.length : 0;
    }
    const newAllStaffList =
      allStaffListTotal > 0
        ? allStaffList.map(({ slug, staff_contact_name }) => ({
            value: slug,
            label: staff_contact_name,
          }))
        : [];
    this.setState({
      allStaffList: allStaffList,
      allStaffListTotal: allStaffListTotal,
      newAllStaffList: newAllStaffList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get staff data */
  async getStaffOnSelectData(staffSlug, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await StaffService.getStaffBySlug(staffSlug);
    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.staff_contact_name = res.data.staff_contact_name
        ? res.data.staff_contact_name
        : "";
      resData.staff_contact_email = res.data.staff_contact_email
        ? res.data.staff_contact_email
        : "";
      resData.staff_contact_number = res.data.staff_contact_number
        ? res.data.staff_contact_number
        : "";
      resData.staff_contact_extension = res.data.staff_contact_extension
        ? res.data.staff_contact_extension
        : "";
      resData.job_title = res.data.job_title ? res.data.job_title : "";

      resData.imageUrl = res.data.staff_contact_image_url
        ? res.data.staff_contact_image_url
        : staffImg;
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getAllReportingManager();
    this.getStaffContactType();

    if (this.state.slug === undefined) {
      this.getStaffData();
    }
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await PropertyStaffService.getPropertyStaffBySlug(
        this.state.slug
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.staff_contact_name = res.data.staff_contact_name
          ? res.data.staff_contact_name
          : "";
        resData.staff_contact_email = res.data.staff_contact_email
          ? res.data.staff_contact_email
          : "";
        resData.staff_contact_number = res.data.staff_contact_number
          ? res.data.staff_contact_number
          : "";
        resData.staff_contact_extension = res.data.staff_contact_extension
          ? res.data.staff_contact_extension
          : "";
        resData.job_title = res.data.job_title ? res.data.job_title : "";
        resData.notes = res.data.notes ? res.data.notes : "";
        // resData.manager_type_slug = res.data.staffData
        //   ? res.data.staffData.slug
        //   : [];
        // res.data.staffData.forEach((item, i) => {
        //   selectedManagerList = [
        //     ...selectedManagerList,
        //     {
        //       value: item.slug,
        //       label: item.manager_type_name,
        //     },
        //   ];
        // });
        resData.imageUrl = res.data.staff_contact_image_url
          ? res.data.staff_contact_image_url
          : staffImg;
        resData.staff_contact_type_slug = res.data.staff_contact_type
          ? res.data.staff_contact_type.slug
          : "";
        resData.reporting_manager_type_slug = res.data.reportingManagerType
          ? res.data.reportingManagerType.slug
          : "";
        // resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        var selectedStaffTypeList = [];
        selectedStaffTypeList = res.data.staff_contact_type
          ? [
              {
                value: res.data.staff_contact_type.slug
                  ? res.data.staff_contact_type.slug
                  : "",
                label: res.data.staff_contact_type.staff_contact_type_name
                  ? res.data.staff_contact_type.staff_contact_type_name
                  : "",
              },
            ]
          : "";

        var selectedAllStaffList = [];
        selectedAllStaffList = res.data.reportingManagerType
          ? [
              {
                value: res.data.reportingManagerType.slug
                  ? res.data.reportingManagerType.slug
                  : "",
                label: res.data.reportingManagerType.staff_contact_name
                  ? res.data.reportingManagerType.staff_contact_name
                  : "",
              },
            ]
          : "";
      }
      // this.setState({ selectedManagerList: selectedManagerList });
      // this.setState({ manager_type_slug: selectedManagerList });

      this.setState(resData);
      this.setState({
        selectedStaffTypeList: selectedStaffTypeList,
        selectedAllStaffList: selectedAllStaffList,
      });
    }
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "staff_contact_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("propertySlug", this.state.property_slug);
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await StaffService.uploadStaffImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          staff_contact_image: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  saveOrUpdateStaff = async (e) => {
    e.preventDefault();
    this.state.manager_type_slug = this.state.selectedManagerList
      ? this.state.selectedManagerList.map(function (obj) {
          return obj.value;
        })
      : "";
    let multiManagerString = this.state.manager_type_slug
      ? this.state.manager_type_slug.join(",")
      : [];
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        staff_contact_info_slug: this.state.staff_contact_info_slug
          ? this.state.staff_contact_info_slug
          : "",
        staff_contact_name: this.state.staff_contact_name
          ? this.state.staff_contact_name
          : "",
        reporting_manager_type_slug: this.state.reporting_manager_type_slug,
        staff_contact_type_slug: this.state.staff_contact_type_slug,
        property_slug: this.state.property_slug,
        staff_contact_email: this.state.staff_contact_email,
        staff_contact_number: this.state.staff_contact_number,
        dial_code: this.state.dial_code ? this.state.dial_code : "",
        staff_contact_extension: this.state.staff_contact_extension,
        staff_contact_image: this.state.staff_contact_image,
        manager_type_slug: multiManagerString ? multiManagerString : [],
        job_title: this.state.job_title,
        notes: this.state.notes,
        // sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await PropertyStaffService.updatePropertyStaff(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                  this.state.property_slug +
                  "/staff/first/" +
                  this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await PropertyStaffService.createPropertyStaff(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/staff"
              ),
            global.redirect_time
          );
        } else {
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    if (event.target.name === "staff_contact_info_slug") {
      this.getStaffOnSelectData(event.target.value);
    }

    if (event.target.name === "staff_contact_type_slug") {
      this.getStaffData(event.target.value);
    }

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  changeHandlerNew = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  handleChangeStaffTypeList(value) {
    let staff_contact_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        staff_contact_type_slug.push(item.value);
      });
    }
    const staff_contact_type_slug_value = value !== null ? value.value : [];
    // this.getStaffData(value.value);
    this.setState({
      selectedStaffTypeList: value,
      staff_contact_type_slug: staff_contact_type_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeStaffList(value) {
    let staff_contact_info_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        staff_contact_info_slug.push(item.value);
      });
    }
    const staff_contact_info_slug_value = value !== null ? value.value : [];
    if (value !== null) {
      this.getStaffOnSelectData(value.value);
    }

    this.setState({
      selectedStaffList: value,
      staff_contact_info_slug: staff_contact_info_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeAllStaffList(value) {
    let reporting_manager_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        reporting_manager_type_slug.push(item.value);
      });
    }
    const reporting_manager_type_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedAllStaffList: value,
      reporting_manager_type_slug: reporting_manager_type_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeNew(value) {
    let manager_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        manager_type_slug.push(item.value);
      });
    }

    this.setState({
      selectedManagerList: value,
      manager_type_slug: manager_type_slug,
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Staff";
    } else {
      return "Staff";
    }
  }
  onChangePhone(value, country, e, formattedValue) {
    //console.log(value, country, e, formattedValue);
    this.setState({ staff_contact_number: value });
    this.setState({ dial_code: country.dialCode });
  }
  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  removePhoto(){
    this.setState({staff_contact_image:"",imageUrl:""});
  }
 
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <ContactSidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-lg-l-30 pd- pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                  this.state.property_slug +
                                  "/staff"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                            );
                        }}
                      >
                        Cancel
                      </button>

                      {global.userPermissions.checkPermission(
                        "staff-update"
                      ) && (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          onClick={this.saveOrUpdateStaff}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          {this.state.isSubmit
                            ? global.loader
                            : this.state.slug !== undefined
                            ? "Save"
                            : "Save"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-45 pd-lg-r-15 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="staff_form">
                      <div className="m-0">
                        <div className="row align-items-center pd-t-30 pd-lg-l-15 pd-xs-l-0 pd-b-15 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Staff Type
                            </label>

                            <InputGroup>
                              <div className="col-md-12 pl-0 pr-0">
                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  className="multiselect"
                                  classNamePrefix={"my-custom-react-select"}
                                  menuPlacement="auto"
                                  value={this.state.selectedStaffTypeList}
                                  options={this.state.newStaffTypeList}
                                  getOptionValue={(option) => `${option.label}`}
                                  onChange={(value) =>
                                    this.handleChangeStaffTypeList(value)
                                  }
                                  defaultValue={
                                    this.state.selectedStaffTypeList
                                  }
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                              </div>

                              {this.state.errors.staff_contact_type_slug ? (
                                <div className="text-danger">
                                  {this.state.errors.staff_contact_type_slug}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Staff Type",
                                  this.state.staff_contact_type_slug,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Staff Name
                            </label>

                            <InputGroup className="d-flex align-items-center">
                              <div
                                className={
                                  this.state.slug === undefined
                                    ? "input-group"
                                    : "input-group"
                                }
                              >
                                {this.state.create_new_staff === true ? (
                                  <>
                                    <Form.Control
                                      onChange={this.changeHandlerNew}
                                      name="staff_contact_name"
                                      value={this.state.staff_contact_name}
                                      className="form-control max_width_100"
                                      type="text"
                                    />
                                    <Form.Control
                                      onChange={this.changeHandlerNew}
                                      name="staff_contact_info_slug"
                                      type="hidden"
                                      value={this.state.staff_contact_name}
                                    />
                                    {this.state.errors.staff_contact_name ? (
                                      <div className="text-danger">
                                        {this.state.errors.staff_contact_name}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "Staff Name",
                                        this.state.staff_contact_name,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {this.state.slug !== undefined ? (
                                      <>
                                        <Form.Control
                                          onChange={this.changeHandlerNew}
                                          name="staff_contact_name"
                                          value={this.state.staff_contact_name}
                                          className="form-control max_width_100"
                                          type="text"
                                        />
                                        {this.state.errors
                                          .staff_contact_name ? (
                                          <div className="text-danger">
                                            {
                                              this.state.errors
                                                .staff_contact_name
                                            }
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "Staff Name",
                                            this.state.staff_contact_name,
                                            "required",
                                            { className: "text-danger" }
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <Select
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect"
                                          classNamePrefix={"my-custom-react-select"}
                                          menuPlacement="auto"
                                          value={this.state.selectedStaffList}
                                          options={this.state.newStaffList}
                                          getOptionValue={(option) =>
                                            `${option.label}`
                                          }
                                          onChange={(value) =>
                                            this.handleChangeStaffList(value)
                                          }
                                          defaultValue={
                                            this.state.selectedStaffList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />

                                        <Form.Control
                                          onChange={this.changeHandlerNew}
                                          name="staff_contact_name"
                                          type="hidden"
                                          value={this.state.staff_contact_name}
                                        />
                                        {this.state.create_new_staff ===
                                        false ? (
                                          this.state.errors
                                            .staff_contact_info_slug ? (
                                            <div className="text-danger">
                                              {
                                                this.state.errors
                                                  .staff_contact_info_slug
                                              }
                                            </div>
                                          ) : (
                                            this.validator.message(
                                              "Staff Name",
                                              this.state
                                                .staff_contact_info_slug,
                                              "required",
                                              { className: "text-danger" }
                                            )
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Job Title
                            </label>

                            <InputGroup>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="job_title"
                                value={this.state.job_title}
                                className="form-control max_width_100"
                                type="text"
                              />
                              {this.state.errors.job_title ? (
                                <div className="text-danger">
                                  {this.state.errors.job_title}
                                </div>
                              ) : (
                                this.validator.message(
                                  "job_title",
                                  this.state.job_title,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Email
                            </label>

                            <InputGroup className="mt-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="staff_contact_email"
                                value={this.state.staff_contact_email}
                                className="form-control max_width_100"
                                type="text"
                                readOnly={
                                  this.state.create_new_staff === true ||
                                  this.state.slug !== undefined
                                    ? false
                                    : true
                                }
                              />
                              {this.state.errors.staff_contact_email ? (
                                <div className="text-danger">
                                  {this.state.errors.staff_contact_email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Email",
                                  this.state.staff_contact_email,
                                  "required|email",
                                  { className: "text-danger" }
                                )
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Phone{" "}
                            </label>

                            <InputGroup className="mt-0">
                              {/* <Form.Control
                                onChange={this.changeHandler}
                                name="staff_contact_number"
                                // onPaste={(e) => {
                                //   e.preventDefault();
                                //   return false;
                                // }}
                                // onCopy={(e) => {
                                //   e.preventDefault();
                                //   return false;
                                // }}
                                onKeyPress={
                                  global.onKeyPressEvent.phoneValidation
                                }
                                value={this.state.staff_contact_number}
                                className="form-control max_width_100"
                                type="text"
                                readOnly={
                                  this.state.create_new_staff === true ||
                                    this.state.slug !== undefined
                                    ? false
                                    : true
                                }
                              /> */}
                              <PhoneInput
                                country={global.country}
                                disableSearchIcon
                                // autoFormat= "true"
                                enableSearch="true"
                                countryCodeEditable="true"
                                value={this.state.staff_contact_number}
                                onChange={this.onChangePhone}
                              />
                              {this.state.errors.staff_contact_number ? (
                                <div className="text-danger">
                                  {this.state.errors.staff_contact_number}
                                </div>
                              ) : (
                                this.validator.message(
                                  "phone",
                                  this.state.staff_contact_number,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Extension{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup className="mt-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="staff_contact_extension"
                                value={this.state.staff_contact_extension}
                                className="form-control max_width_100"
                                type="text"
                                maxLength="4"
                              />
                              {this.state.errors.staff_contact_extension ? (
                                <div className="text-danger">
                                  {this.state.errors.staff_contact_extension}
                                </div>
                              ) : (
                                this.validator.message(
                                  "staff_contact_extension",
                                  this.state.staff_contact_extension,
                                  "numeric|min:1,num",
                                  { className: "text-danger" }
                                )
                              )}
                            </InputGroup>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Reporting Manager{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <InputGroup>
                              <div className="col-md-12 pl-0 pr-0">
                                <Select
                                  styles={customStyles}
                                  isClearable={true}
                                  className="multiselect"
                                  classNamePrefix={"my-custom-react-select"}
                                  menuPlacement="auto"
                                  value={this.state.selectedAllStaffList}
                                  options={
                                    this.state.allStaffListTotal > 0
                                      ? this.state.newAllStaffList.filter(
                                          (opt, i) => {
                                            if (this.state.slug === opt.value)
                                              return false;
                                            else return true;
                                          }
                                        )
                                      : []
                                  }
                                  getOptionValue={(option) => `${option.label}`}
                                  onChange={(value) =>
                                    this.handleChangeAllStaffList(value)
                                  }
                                  defaultValue={this.state.selectedAllStaffList}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#fff",
                                      primary75: "#000",
                                      primary50: "#000",
                                      primary25: "#000",
                                    },
                                  })}
                                />
                              </div>
                              {this.state.errors.reporting_manager_type_slug ? (
                                <div className="text-danger">
                                  {
                                    this.state.errors
                                      .reporting_manager_type_slug
                                  }
                                </div>
                              ) : (
                                ""
                              )}
                            </InputGroup>
                          </div>
                        </div>

                        {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}
                        <div className="row align-items-center pd-b-0 border-bottom-0 pd-l-15">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Photo <span className="optional">(Optional)</span>
                            </label>

                            <div className="fileUpload d-flex flex-wrap justify-content-between">
                              <span>
                                <label className="hand-cursor">
                                  <input
                                    onChange={this.onImageChange}
                                    type="file"
                                    name="staff_contact_image"
                                  />
                                  {this.state.imageUrl ? (
                                    <img
                                      src={
                                        this.state.imageUrl
                                          ? this.state.imageUrl
                                          : staffImg
                                      }
                                      alt="Photo"
                                    />
                                  ) : (
                                    <img src={staffImg} alt="" />
                                  )}
                                </label>
                              </span>
                              <ul className="ruLink">
                                <li>
                                  <label>
                                    <input
                                      onChange={this.onImageChange}
                                      type="file"
                                      name="staff_contact_image"
                                    />
                                  </label>
                                </li>
                              </ul>
                              <p className="hand-cursor remove-pic remove-pic-three m-0" onClick={this.removePhoto}></p>
                            </div>
                            {this.state.errors.staff_contact_image ? (
                              <div className="text-danger">
                                {this.state.errors.staff_contact_image}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* <span className="d-inline-flex align-items-start justify-content-start infosize">
                              <img src={infosize} className="mg-r-5" />
                              <p>
                                Recommended size - 512px by 512px (1:1 ratio).
                                Max file size - 5 MB
                              </p>
                            </span> */}
                          </div>
                        </div>
                        {/* <div className="row align-items-center pd-b-0 border-bottom-0 pd-l-15">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-50 pd-lg-r-30 pd-xs-l-0 pd-xs-r-15 mg-b-15">
                            <p className="hand-cursor text-danger" onClick={this.removePhoto}>Remove</p>
                          </div>
                        </div> */}

                        {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}

                        <div className="row align-items-center pd-t-30 pd-b-30 pd-lg-l-45 pd-lg-r-30 pd-xs-l-15 border-bottom-0">
                          <div className="col-md-1 pl-0">
                            <label className="form-label text-left pd-b-5">
                              Status
                            </label>
                          </div>
                          <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() =>
                                this.setState({
                                  status_id: !this.state.status_id,
                                })
                              }
                            >
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default StaffForm;

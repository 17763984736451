////////////////////////////////////////////////////////////
//     							                                      //
//  Program: LeedBuildingService.js                        //
//  Application: Third party                           //
//  Option: Used for manage add agency                 //
//  Developer: NP           						               //
//  Date: 2021-01-11                                   //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class LeedBuildingService {

  getLeedBuilding(queryString) {
    let endPoint = "leed-building"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createLeedBuilding(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "leed-building";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getLeedBuildingBySlug(slug) {
    let endPoint = "leed-building/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateLeedBuilding(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "leed-building/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteLeedBuilding(slug) {
    let endPoint = "leed-building/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new LeedBuildingService();

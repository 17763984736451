////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PetPolicyForm.jsx                                //
//  Application:  PetPolicy                                   //
//  Option: For add or update PetPolicy                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import {
  Container,
  Form,
  Card,
  Accordion,
  Button,
  InputGroup,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import BackButton from "../../assets/images/back-button.svg";
import SimpleReactValidator from "simple-react-validator";
import PetPolicyService from "../../services/PetPolicyService";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import Alerts from "../common/Alerts";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PetTypeService from "../../services/PetTypeService";
import Select from "react-select";
import pluswIcon from "./../../assets/images/plus.svg";
import deleteicon from "../../assets/images/delete.svg";
// import Dynamicform from "./PetAllowed";

export class PetPolicyForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      confirm_back: 0,
      property_slug: this.props.match.params.propertySlug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      slug: this.props.match.params.slug,
      isSubmit: false,
      sort_order: "",
      petTypeList: [],
      pet_type_not_allowed: [],
      pet_data: [],
      pet_type_slug: [],
      newPetTypeList: [],
      petTypeListDropdown: [],
      selectedPetTypeListDropdown: [],
      selectedPetTypeList: [],
      pathname: window.location.pathname,
      petPolicyTypeDataTotal: 1,
      petPolicyTypeData: [
        {
          pet_type_slug: "",
          allowed_pet_number: "",
          deposite_amount_per_pet: "",
          one_time_fee_per_type: "",
          rent_per_type: "",
          weight_per_type: "",
          policy_slug: "",
        },
      ],
      is_error: [
        {
          pet_type_slug: "",
          allowed_pet_number: "",
          deposite_amount_per_pet: "",
          one_time_fee_per_type: "",
          rent_per_type: "",
          weight_per_type: "",
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangeMulti = this.handleChangeMulti.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdatePetPolicy = this.saveOrUpdatePetPolicy.bind(this);
  }

  /* to get pet type data list */
  async getPetTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var petTypeList = [];
    var petTypeListTotal = 0;

    let res = await PetTypeService.getPetType(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      petTypeList = res.data.data ? res.data.data : [];
      petTypeListTotal = petTypeList ? petTypeList.length : 0;
    }
    const newPetTypeList =
      petTypeListTotal > 0
        ? petTypeList.map(({ slug, pet_type_name }) => ({
            value: slug,
            label: pet_type_name,
          }))
        : [];
    this.setState({
      newPetTypeList: newPetTypeList,
      petTypeListDropdown: newPetTypeList,
      petTypeList: petTypeList,
      petTypeListTotal: petTypeListTotal,
    });

    this.setState({ showSpinner: false, loading: false });
  }

  async deletePetPolicy(slug) {
    this.setState({ showSpinner: true });
    if (slug) {
      let res = await PetPolicyService.deletePetPolicy(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
          selectedPetTypeList: "",
        });
        this.getDefaultPetData();
      } else {
        let alertMessage = "";
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getPetTypeData();
    this.getDefaultPetData();
  }
  async getDefaultPetData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      var selectedPetTypeList = this.state.selectedPetTypeList;
      var petPolicyTypeDataTotal = 1;
      let res = await PetPolicyService.getPetPolicyBySlug(
        this.state.slug + "/3"
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.petPolicyDataLength = res.data.petPolicyData
          ? res.data.petPolicyData.length
          : 0;
        resData.petPolicyData = res.data.petPolicyData
          ? res.data.petPolicyData
          : [
              {
                pet_type_slug: "",
                allowed_pet_number: "",
                deposite_amount_per_pet: "",
                one_time_fee_per_type: "",
                rent_per_type: "",
                weight_per_type: "",
                policy_slug: "",
              },
            ];

        const values = [];
        if (resData.petPolicyDataLength > 0) {
          resData.petPolicyData.forEach((item, i) => {
            values.push({
              pet_type_slug: item.pet_type.slug,
              allowed_pet_number: item.allowed_pet_number,
              one_time_fee_per_type: item.one_time_fee_per_type,
              deposite_amount_per_pet: item.deposite_amount_per_pet,
              rent_per_type: item.rent_per_type,
              weight_per_type: item.weight_per_type,
              policy_slug: item.slug,
              selected_pet_type: {
                value: item.pet_type.slug,
                label: item.pet_type.pet_type_name,
              },
            });
          });
        } else {
          resData.petPolicyTypeData = [
            {
              pet_type_slug: "",
              allowed_pet_number: "",
              deposite_amount_per_pet: "",
              one_time_fee_per_type: "",
              rent_per_type: "",
              weight_per_type: "",
              policy_slug: "",
              // selected_pet_type: {
              //   value: "",
              //   label: "",
              // },
            },
          ];
        }

        petPolicyTypeDataTotal = values.length;
        this.setState({
          petPolicyTypeData: values,
          petPolicyTypeDataTotal: petPolicyTypeDataTotal,
        });
        resData.pet_policy_restrictions = res.data.propertyData[0]
          .pet_policy_restrictions
          ? res.data.propertyData[0].pet_policy_restrictions
          : "";
        resData.custom_pet_policy = res.data.propertyData[0].custom_pet_policy
          ? res.data.propertyData[0].custom_pet_policy
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        res.data.petPolicyRestrictions.forEach((item, i) => {
          selectedPetTypeList = [
            ...selectedPetTypeList,
            {
              value: item.slug,
              label: item.pet_type_name,
            },
          ];
        });
      }
      this.setState(resData);
      this.setState({
        selectedPetTypeList: selectedPetTypeList,
      });
      this.setState({ pet_type_not_allowed: selectedPetTypeList });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdatePetPolicy = async (e) => {
    this.state.pet_type_not_allowed = this.state.selectedPetTypeList
      ? this.state.selectedPetTypeList.map(function (obj) {
          return obj.value;
        })
      : "";
    let multiPetNotAllowed = this.state.pet_type_not_allowed
      ? this.state.pet_type_not_allowed.join(",")
      : [];
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        pet_data: this.state.pet_data ? this.state.pet_data : [],
        pet_type_not_allowed: multiPetNotAllowed,
        pet_policy_restrictions: this.state.pet_policy_restrictions,
        custom_pet_policy: this.state.custom_pet_policy,
        property_slug: this.state.property_slug,
        // sort_order: this.state.sort_order,
        // status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await PetPolicyService.updatePetPolicy(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/pet-policy"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await PetPolicyService.createPetPolicy(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/pet-policy"
              ),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeselect = (index, event) => {
    const values = [...this.state.petPolicyTypeData];
    values[index].pet_type_slug = event.value;
    values[index].selected_pet_type = event;

    // values[index].event.target.name = event.target.value;
    this.setState({ petPolicyTypeData: values });
    this.setState({ pet_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleChange = (index, event) => {
    const values = [...this.state.petPolicyTypeData];

    if (event.target.name === "pet_type_slug") {
      values[index].pet_type_slug = event.target.value;
      this.setState({ petPolicyTypeData: values });
    } else if (event.target.name === "allowed_pet_number") {
      values[index].allowed_pet_number = event.target.value;
    } else if (event.target.name === "deposite_amount_per_pet") {
      values[index].deposite_amount_per_pet = event.target.value;
    } else if (event.target.name === "one_time_fee_per_type") {
      values[index].one_time_fee_per_type = event.target.value;
    } else if (event.target.name === "rent_per_type") {
      values[index].rent_per_type = event.target.value;
    } else if (event.target.name === "weight_per_type") {
      values[index].weight_per_type = event.target.value;
    }
    this.setState({ pet_data: values });
    this.setState({ confirm_back: 1 });
  };

  handleAddFields = () => {
    const values = [...this.state.petPolicyTypeData];
    var petPolicyTypeDataTotal = 1;
    values.push({
      pet_type_slug: "",
      allowed_pet_number: "",
      one_time_fee_per_type: "",
      deposite_amount_per_pet: "",
      rent_per_type: "",
      weight_per_type: "",
      policy_slug: "",
    });
    petPolicyTypeDataTotal = values.length;

    this.setState({
      petPolicyTypeData: values,
      petPolicyTypeDataTotal: petPolicyTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFields = (i) => {
    const values = [...this.state.petPolicyTypeData];
    values.splice(i, 1);
    var petPolicyTypeDataTotal = 0;
    petPolicyTypeDataTotal = values.length;
    this.setState({ petPolicyTypeData: values, pet_data: values });
    this.setState({
      petPolicyTypeDataTotal: petPolicyTypeDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleChangeMulti(value) {
    let pet_type_not_allowed = [];
    var selectedPetTypeListTotal = 0;
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        pet_type_not_allowed.push(item.value);
      });
    }
    selectedPetTypeListTotal = pet_type_not_allowed.length;
    this.setState({
      selectedPetTypeList: value,
      pet_type_not_allowed: pet_type_not_allowed,
      selectedPetTypeListTotal: selectedPetTypeListTotal,
    });
    this.setState({ confirm_back: 1 });
  }
  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.custom_pet_policy !== undefined &&
      input.custom_pet_policy !== "" &&
      input.custom_pet_policy !== null &&
      input.custom_pet_policy.length > 100000
    ) {
      isValid = false;
      errors["custom_pet_policy"] =
        "The custom pet policy character limit has been exceed.";
    }
    if (
      input.pet_policy_restrictions !== undefined &&
      input.pet_policy_restrictions !== "" &&
      input.pet_policy_restrictions !== null &&
      input.pet_policy_restrictions.length > 100000
    ) {
      isValid = false;
      errors["pet_policy_restrictions"] =
        "The pet policy restrictions character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update Pet Policy";
    } else {
      return "Add Pet Policy";
    }
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          <div className="row row-xs">
            <div className="col-md-10 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link
                  onClick={() => {
                    if (this.state.confirm_back === 1)
                      if (window.confirm("Do you want to exit without saving?"))
                        this.props.history.push("/properties");
                      else return false;
                    else this.props.history.push("/properties");
                  }}
                >
                  Properties
                </Link>
                <span> / </span>
                <Link
                  onClick={() => {
                    if (this.state.confirm_back === 1)
                      if (window.confirm("Do you want to exit without saving?"))
                        this.props.history.push(
                          "/properties/view/" +
                            this.state.property_slug +
                            "/pet-policy"
                        );
                      else return false;
                    else
                      this.props.history.push(
                        "/properties/view/" +
                          this.state.property_slug +
                          "/pet-policy"
                      );
                  }}
                >
                  Pet Policy
                </Link>
                <span> / </span>
                <span>{this.getTitle()}</span>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <p className="d-flex justify-content-end">
                <span className="back-parent d-flex align-items-center">
                  <Link
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.props.history.push(
                            "/properties/view/" +
                              this.state.property_slug +
                              "/pet-policy"
                          );
                        else return false;
                      else
                        this.props.history.push(
                          "/properties/view/" +
                            this.state.property_slug +
                            "/pet-policy"
                        );
                    }}
                  >
                    <img src={BackButton} alt="" className="back-button" />
                  </Link>
                  <Link
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.props.history.push(
                            "/properties/view/" +
                              this.state.property_slug +
                              "/pet-policy"
                          );
                        else return false;
                      else
                        this.props.history.push(
                          "/properties/view/" +
                            this.state.property_slug +
                            "/pet-policy"
                        );
                    }}
                  >
                    Back
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </Container>

        <Container>
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-15 pd-t-4">
              <div className="col-md-3">
                <aside>
                  <div className="topHead">
                    <div className="demo-avatar-group">
                      <div className="az-img-user avatar-md online">
                        <img
                          src={
                            sessionStorage.getItem("property_logo_url")
                              ? sessionStorage.getItem("property_logo_url")
                              : propertyLogo
                          }
                          className="rounded-circle"
                          alt=""
                        />
                      </div>
                      <div className="propname">
                        <h5>
                          {" "}
                          {sessionStorage.getItem("property_name")
                            ? sessionStorage.getItem("property_name")
                            : "Property"}{" "}
                        </h5>
                        <ul>
                          <li>
                            {" "}
                            {sessionStorage.getItem("property_type")
                              ? sessionStorage.getItem("property_type")
                              : ""}{" "}
                          </li>
                          {sessionStorage.getItem("year_value") ? (
                            <li></li>
                          ) : (
                            ""
                          )}
                          <li>
                            {sessionStorage.getItem("year_value")
                              ? "Year " + sessionStorage.getItem("year_value")
                              : ""}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "0")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Properties Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                              this.state.eventKey === "0" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse
                          eventKey="0"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${
                                this.state.pathname.match("/basic")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic"
                              }
                            >
                              Property
                            </Link>

                            <Link
                              className={`${
                                this.state.pathname.match("/tower")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              }
                            >
                              Towers
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/floors")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floors"
                              }
                            >
                              Floors
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/amenitites")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/amenitites"
                              }
                            >
                              Common Amenities
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/pet-policy")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                              }
                            >
                              Pet Policy
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/staff")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                            >
                              Staff
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/documents")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/documents"
                              }
                            >
                              Documents & Leases
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/property-level-tax")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              }
                            >
                              Taxes
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/nearby-attractions")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/nearby-attractions"
                              }
                            >
                              Near By Attractions
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/events")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                              }
                            >
                              Events
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      <Card onClick={() => this.setOpen(!this.state.open, "3")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Units Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                              this.state.eventKey === "3" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="3"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${
                                this.state.pathname.match("/floor-plans")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floor-plans"
                              }
                            >
                              Floor Plans
                            </Link>
                            <Link
                              className={`${
                                this.state.pathname.match("/units")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units"
                              }
                            >
                              Units
                            </Link>
                            {/* <Link
                              className={`${
                                this.state.pathname.match("/rooms")
                                  ? "nav-link active"
                                  : "nav-link"
                              }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/rooms"
                              }
                            >
                              Rooms
                            </Link> */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </nav>
                </aside>
              </div>
              <div className="col-md-9 ">
                <div className="scrolling-carousel pd-l-15">
                  <div className="row d-flex justify-content-between align-items-center pd-l-15 pd-r-15 mg-b-0 pd-b-2">
                    <h3>{this.getTitle()}</h3>
                  </div>

                  <div className="dashboardRightcard pet-policy">
                    <div className="d-flex justify-content-end">
                      <Link
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                  this.state.property_slug +
                                  "/pet-policy"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                            );
                        }}
                      >
                        <button
                          type="button"
                          className="btn-success-outline-small"
                        >
                          Cancel
                        </button>
                      </Link>

                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={this.saveOrUpdatePetPolicy}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                          ? "Save"
                          : "Save"}
                      </button>
                    </div>
                    <div className="col-md-12 pl-0 pr-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="pd-l-0 pd-r-0 pet-f">
                      <div className="col-md-12 p-0-">
                        <Row className="mt-3">
                          <Col xs={2} className="pd-l-0 pd-r-2">
                            <label>Pet Type</label>
                          </Col>
                          <Col xs={2} className="pd-l-2 pd-r-2">
                            {" "}
                            <label>Max # Allowed</label>
                          </Col>
                          <Col xs={2} className="pd-l-2 pd-r-2">
                            <label>Deposit ($)</label>
                          </Col>
                          <Col xs={2} className="pd-l-2 pd-r-2">
                            <label>One-Time Fee ($)</label>
                          </Col>
                          <Col xs={1} className="pd-l-2 pd-r-2">
                            <label>Monthly Rent ($)</label>
                          </Col>
                          <Col xs={3} className="pd-l-2 pd-r-2">
                            <label>Weight (lbs)</label>
                          </Col>
                        </Row>
                      </div>
                      <div className="col-md-12 p-0-">
                        {this.state.petPolicyTypeData.map((data, i) => {
                          return (
                            <Row className="mb-2" key={i}>
                              <Col xs={2} className="pd-l-0 pd-r-2">
                                <InputGroup className="mt-0 m-b-1">
                                  {/* <label>Pet Type</label> */}
                                  <div className="col-md-12 p-0">
                                    <Select
                                      styles={customStyles}
                                      className="multiselect"
                                      classNamePrefix={"my-custom-react-select"}
                                      menuPlacement="auto"
                                      onChange={(value) =>
                                        this.handleChangeselect(i, value)
                                      }
                                      options={this.state.petTypeListDropdown
                                        .filter((option) => {
                                          const val1 = this.state
                                            .petPolicyTypeData
                                            ? this.state.petPolicyTypeData.find(
                                                (opt) => {
                                                  if (
                                                    option.value ===
                                                    data.pet_type_slug
                                                  )
                                                    return false;
                                                  return (
                                                    opt.pet_type_slug ===
                                                    option.value
                                                  );
                                                }
                                              )
                                            : "";
                                          if (val1) return false;
                                          else return true;
                                        })
                                        .filter((option) => {
                                          const val2 = this.state
                                            .selectedPetTypeList
                                            ? this.state.selectedPetTypeList.find(
                                                (selOpt) => {
                                                  if (
                                                    selOpt.value ===
                                                    data.pet_type_slug
                                                  )
                                                    return false;
                                                  return (
                                                    selOpt.value ===
                                                    option.value
                                                  );
                                                }
                                              )
                                            : "";
                                          if (val2) return false;
                                          else return true;
                                        })}
                                      getOptionValue={(option) =>
                                        `${option.label}`
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                      value={
                                        data.selected_pet_type
                                          ? data.selected_pet_type
                                          : []
                                      }
                                      defaultValue={
                                        data.selected_pet_type
                                          ? data.selected_pet_type
                                          : []
                                      }
                                    />
                                  </div>
                                  {/* <select
                                    name="pet_type_slug"
                                    onChange={(event) =>
                                      this.handleChange(i, event)
                                    }
                                    value={data.pet_type_slug || ""}
                                    tabIndex="10"
                                  >
                                    <option key="" value="">
                                      Select Pet Type
                                    </option>
                                    {this.state.petTypeList
                                      .sort((a, b) =>
                                        a.label > b.label ? 1 : -1
                                      )
                                      .filter((option) => {
                                        const val1 = this.state
                                          .petPolicyTypeData
                                          ? this.state.petPolicyTypeData.find(
                                              (opt) => {
                                                if (
                                                  opt.pet_type_slug ===
                                                  data.pet_type_slug
                                                )
                                                  return false;
                                                return (
                                                  opt.pet_type_slug ===
                                                  option.slug
                                                );
                                              }
                                            )
                                          : "";
                                        if (val1) return false;
                                        else return true;
                                      })
                                      .filter((option) => {
                                        const val2 = this.state
                                          .selectedPetTypeList
                                          ? this.state.selectedPetTypeList.find(
                                              (selOpt) => {
                                                if (
                                                  selOpt.value ===
                                                  data.pet_type_slug
                                                )
                                                  return false;
                                                return (
                                                  selOpt.value === option.slug
                                                );
                                              }
                                            )
                                          : "";
                                        if (val2) return false;
                                        else return true;
                                      })
                                      .map((option) => (
                                        <option
                                          key={option.slug}
                                          value={option.slug}
                                        >
                                          {option.pet_type_name}
                                        </option>
                                      ))}
                                  </select> */}
                                </InputGroup>
                              </Col>
                              <Col xs={2} className="pd-l-2 pd-r-2">
                                <InputGroup className="mt-0 m-b-1">
                                  {/* <label>Maxs. Pets Allowed</label> */}
                                  <Form.Control
                                    placeholder="Max # Allowed"
                                    name="allowed_pet_number"
                                    maxLength="2"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                    value={data.allowed_pet_number || ""}
                                    onChange={(event) =>
                                      this.handleChange(i, event)
                                    }
                                  />
                                </InputGroup>
                              </Col>
                              <Col xs={2} className="pd-l-2 pd-r-2">
                                <InputGroup className="mt-0">
                                  {/* <label>Deposit Amount ($)</label> */}
                                  <Form.Control
                                    placeholder="Deposit ($)"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                    name="deposite_amount_per_pet"
                                    value={data.deposite_amount_per_pet || ""}
                                    onChange={(event) =>
                                      this.handleChange(i, event)
                                    }
                                  />
                                </InputGroup>
                              </Col>
                              <Col xs={2} className="pd-l-2 pd-r-2">
                                <InputGroup className="mt-0">
                                  {/* <label>One Time Fee ($)</label> */}
                                  <Form.Control
                                    placeholder="One-Time Fee ($)"
                                    name="one_time_fee_per_type"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                    value={data.one_time_fee_per_type || ""}
                                    onChange={(event) =>
                                      this.handleChange(i, event)
                                    }
                                  />
                                </InputGroup>
                              </Col>
                              <Col xs={1} className="pd-l-2 pd-r-2">
                                <InputGroup className="mt-0">
                                  {/* <label>Rent ($)</label> */}
                                  <Form.Control
                                    placeholder="Monthly Rent ($)"
                                    onKeyPress={
                                      global.onKeyPressEvent.numberValidation
                                    }
                                    name="rent_per_type"
                                    value={data.rent_per_type || ""}
                                    onChange={(event) =>
                                      this.handleChange(i, event)
                                    }
                                  />
                                </InputGroup>
                              </Col>
                              <Col xs={3} className="pd-l-2 pd-r-0 d-flex">
                                <InputGroup className="mt-0">
                                  {/* <label>Weight (lbs)</label> */}
                                  <Form.Control
                                    placeholder="Weight (lbs)"
                                    onKeyPress={
                                      global.onKeyPressEvent.floatValidation
                                    }
                                    name="weight_per_type"
                                    value={data.weight_per_type || ""}
                                    onChange={(event) =>
                                      this.handleChange(i, event)
                                    }
                                  />
                                </InputGroup>
                                {this.state.petPolicyTypeDataTotal === 1 &&
                                i === 0 ? (
                                  <>
                                    {data.policy_slug ? (
                                      <div className="d-flex  justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                        <button
                                          onClick={() => {
                                            this.handleRemoveFields(i);

                                            this.deletePetPolicy(
                                              data.policy_slug
                                            );
                                          }}
                                          className={
                                            "btn-danger-outline-x-small"
                                          }
                                        >
                                          <img src={deleteicon} alt="Delete" />
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="d-flex justify-content-end align-items-end h-100- pd-l-2 pd-r-0">
                                      <button
                                        onClick={this.handleAddFields}
                                        className="btn-success-outline-x-small"
                                      >
                                        <img src={pluswIcon} alt="Add" />
                                      </button>
                                    </div>
                                  </>
                                ) : i <
                                  this.state.petPolicyTypeDataTotal - 1 ? (
                                  <>
                                    <div className="d-flex  justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                      <button
                                        onClick={() => {
                                          this.handleRemoveFields(i);

                                          this.deletePetPolicy(
                                            data.policy_slug
                                          );
                                        }}
                                        className={"btn-danger-outline-x-small"}
                                      >
                                        <img src={deleteicon} alt="Delete" />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {this.state.petPolicyTypeDataTotal !== 0 ? (
                                      <>
                                        <div className="d-flex  justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                          <button
                                            onClick={() => {
                                              this.handleRemoveFields(i);

                                              this.deletePetPolicy(
                                                data.policy_slug
                                              );
                                            }}
                                            className={
                                              "btn-danger-outline-x-small"
                                            }
                                          >
                                            <img
                                              src={deleteicon}
                                              alt="Delete"
                                            />
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                      <button
                                        onClick={this.handleAddFields}
                                        className="btn-success-outline-x-small"
                                      >
                                        <img src={pluswIcon} alt="Add" />
                                      </button>
                                    </div>
                                  </>
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </div>
                    <div className="m-0 mt-5">
                      <div className="row row-xs align-items-center pd-t-30 pd-b-30 border-top">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-0">
                            Prohibited Pet Types
                          </label>
                        </div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <Select
                            isMulti
                            styles={customStyles}
                            menuPlacement="auto"
                            className="multiselect"
                            classNamePrefix={"my-custom-react-select"}
                            name="pet_type_not_allowed"
                            value={this.state.selectedPetTypeList}
                            defaultValue={this.state.selectedPetTypeList}
                            onChange={(value) => this.handleChangeMulti(value)}
                            options={
                              this.state.petTypeList
                                ? Array.isArray(this.state.newPetTypeList) &&
                                  this.state.newPetTypeList.filter((opt) => {
                                    const slug = opt.value;
                                    const selectedPetType =
                                      this.state.petPolicyTypeData.map(
                                        (p) => p.pet_type_slug
                                      );
                                    if (selectedPetType.indexOf(slug) !== -1)
                                      return false;
                                    return true;
                                  })
                                : ""
                            }
                            getOptionValue={(option) => `${option.label}`}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="row row-xs align-items-baseline pd-t-30 pd-b-30">
                        <div className="col-md-3 pl-0"></div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <InputGroup className="mt-3">
                            <label className="form-label text-left mg-b-0">
                              Restrictions
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="pet_policy_restrictions"
                              value={this.state.pet_policy_restrictions}
                              className="form-control max_width_100"
                              id="pet_policy_restrictions"
                              as="textarea"
                              rows={global.textareaRowLength}
                              // placeholder="Username"
                            />
                          </InputGroup>

                          {this.state.errors.pet_policy_restrictions ? (
                            <div className="text-danger">
                              {this.state.errors.pet_policy_restrictions}
                            </div>
                          ) : (
                            this.validator.message(
                              "pet_policy_restrictions",
                              this.state.pet_policy_restrictions,
                              "max:100000",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                      </div>

                      <div className="row row-xs align-items-baseline pd-t-30 pd-b-30">
                        <div className="col-md-3 pl-0"></div>
                        <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <InputGroup className="mt-3">
                            <label className="form-label text-left mg-b-0">
                              Custom Pet Policy Content
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="custom_pet_policy"
                              value={this.state.custom_pet_policy}
                              className="form-control max_width_100"
                              id="custom_pet_policy"
                              as="textarea"
                              rows={global.textareaRowLength}
                              // placeholder="Username"
                            />
                          </InputGroup>
                          {this.state.errors.custom_pet_policy ? (
                            <div className="text-danger">
                              {this.state.errors.custom_pet_policy}
                            </div>
                          ) : (
                            this.validator.message(
                              "custom_pet_policy",
                              this.state.custom_pet_policy,
                              "max:100000",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ht-50"></div>
          </div>
        </Container>
      </main>
    );
  }
}

export default PetPolicyForm;

import React, { useEffect, useRef, useReducer } from "react";
import { Form } from "react-bootstrap";
import Alerts from "../../common/Alerts";
import NoiseawareService from "../../../services/NoiseawareService";
import SimpleReactValidator from "simple-react-validator";

const initialState = {
  emailAlertType: "",
  SMSAlertType: "",
  pushAlertType: "",
  errors: {},
  showAlertModal: false,
  alertModalType: "",
  alertModalTitle: "",
  alertModalMessage: "",
  alertData: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case "alert":
      if (action.value === "alert-email") {
        if (state.emailAlertType === "alert-email") {
          return {
            ...state,
            emailAlertType: "",
          };
        } else {
          return {
            ...state,
            emailAlertType: action.value,
            errors: {}
          };
        }
      } else if (action.value === "alert-sms") {
        if (state.SMSAlertType === "alert-sms") {
          return {
            ...state,
            SMSAlertType: "",
          };
        } else {
          return {
            ...state,
            SMSAlertType: action.value,
            errors: {}
          };
        }
      } else if (action.value === "alert-push") {
        if (state.pushAlertType === "alert-push") {
          return {
            ...state,
            pushAlertType: "",
          };
        } else {
          return {
            ...state,
            pushAlertType: action.value,
            errors: {}
          };
        }

      } else {
        return state;
      }

    case "failure":
      return {
        ...state,
        errors: action.value,
      };
    case "success":
      return {
        ...state,
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "success",
        alertModalMessage: action.value,
      };
    case "change_text":
      return {
        ...state,
        errors: action.value,
      };
    case "edit_alert":
      return {
        ...state,
        emailAlertType: action.value.some(item => item === '1') ? 'alert-email' : '',
        SMSAlertType: action.value.some(item => item === '2') ? 'alert-sms' : '',
        pushAlertType: action.value.some(item => item === '3') ? 'alert-push' : '',
        alertData: action.value,
      };
    default:
      return state;
  }
};

const AlertSetting = (props) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  let count = useRef(0);
  const [state, dispatch] = useReducer(reducer, initialState);
 // const [submit, setSubmit] = useState(false);

  //console.log(state);

  useEffect(() => {
    if (state.SMSAlertType !== '' || state.SMSAlertType !== '' || state.SMSAlertType !== '') {
      simpleValidator.current.fields.alert_type = true;
    }
    if (state.errors.alert_type && count.current === 0) {
      count.current = count.current + 1;
      let test = {};
      test.alert_type = "Please select any one alert setting";
      dispatch({ type: "change_text", value: test });
    }
  }, [state.errors]);

  useEffect(() => {
    getSettingFromSlug();
  }, []);

  const getSettingFromSlug = async (event) => {
    let res = await NoiseawareService.getSettingFromSlug(
      "property_slug=" + props.propertySlug
    );

    if (global.successStatus.includes(res.status)) {
      if (res.data.alert_type) {
        var split_data = (res.data.alert_type !== '') ? res.data.alert_type.split(",") : '';
        dispatch({ type: "edit_alert", value: split_data });
      }
    }
  };

  function changeHandler(evt) {
    let value = evt.target.value;
    dispatch({ type: "alert", value: value });
    submitAlert(evt);
  }

  const submitAlert = async (event) => {
    let value = event.target.value;
    let status = event.target.checked;
    let finalData = [];
    if (value === 'alert-email') {
      if (status === true) {
        finalData.push(1);  
      }
      if (state.SMSAlertType !== '') {
        finalData.push(2);
      }
      if (state.pushAlertType !== '') {
        finalData.push(3);
      }
    }
    if (value === 'alert-sms') {
      if (status === true) {
        finalData.push(2);  
      }
      if (state.emailAlertType !== '') {
        finalData.push(1);
      }
      if (state.pushAlertType !== '') {
        finalData.push(3);
      }
    }
    if (value === 'alert-push') {
      if (status === true) {
        finalData.push(3);  
      }
      if (state.emailAlertType !== '') {
        finalData.push(1);
      }
      if (state.SMSAlertType !== '') {
        finalData.push(2);
      }
    }
   
    let inputData = {
      property_slug: props.propertySlug,
      alert_type: finalData.join(','),
    };
    await NoiseawareService.alertSetting(inputData);
  }



  {/*const saveOrUpdateUnits = async (event) => {
    event.preventDefault();
    if (state.SMSAlertType !== '' || state.emailAlertType !== '' || state.pushAlertType !== '') {
      simpleValidator.current.fields.alert_type = true;
    }
    let res = [];
    let fields = simpleValidator.current.fields;
    let isError = 0;
    //console.log(state);
    setSubmit(true);
    {
      Object.keys(fields).map((key) => {
        if (!fields[key]) {
          isError = 1;
        }
      });
    }
    if (isError === 1) {
      setSubmit(false);
      dispatch({
        type: "failure",
        value: simpleValidator.current.errorMessages,
      });
    } else {
      let finalData = [];
      if (state.emailAlertType !== '') {
        finalData.push(1);
      }
      if (state.SMSAlertType !== '') {
        finalData.push(2);
      }
      if (state.pushAlertType !== '') {
        finalData.push(3);
      }
      let inputData = {
        property_slug: props.propertySlug,
        alert_type: finalData.join(','),
      };
      res = await NoiseawareService.alertSetting(inputData);
      if (global.successStatus.includes(res.status)) {
        setSubmit(false);
        dispatch({
          type: "success",
          value: res.message ? res.message : "Success",
        });
      }
    }
  };*/}

  return (
    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
      <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns alertsettingBtn">
        {/* <button
          className="btn-success ml-3"
          type="button"
          disabled={submit ? true : false}
          onClick={saveOrUpdateUnits}
        >
          {submit ? global.loader : "Save"}
        </button> */}
      </div>
      {/* {state.alertData.map((item, index) => {
        return (<h1 key={index}>{item}</h1>)
      }
      )} */}
      <div className="col-md-12">
        <div className="col-md-12 pl-0 pr-0 mg-t-15">
          <Alerts
            show={state.showAlertModal}
            type={state.alertModalType}
            title={state.alertModalTitle}
            message={state.alertModalMessage}
          />
        </div>
        <div className="row align-items-center border-bottom-0">
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-r-30 pd-r-30 mg-b-15">
            <Form.Check
              onChange={changeHandler}
              name="alert-email"
              checked={state.emailAlertType === "alert-email" ? true : false}
              type={"checkbox"}
              id="alert-email"
              value="alert-email"
              label={"Email"}
              className={"ckbox mg-r-20"}
            />
            {simpleValidator.current.message(
              "alert_type",
              state.alertType,
              "required"
            )}
            {state.emailAlertType === "" && state.SMSAlertType === "" && state.pushAlertType === "" ? (
              <div className="text-danger">{state.errors.alert_type} </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-r-30 pd-r-30 mg-b-15">
            <Form.Check
              onChange={changeHandler}
              name="alert-sms"
              value="alert-sms"
              checked={state.SMSAlertType === "alert-sms" ? true : false}
              type={"checkbox"}
              id="alert-sms"
              label={"SMS"}
              className={"ckbox mg-r-20"}
            />
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3  mg-t-5 mg-md-t-0 pd-lg-l-30 pd-xs-l-15 pd-r-30 pd-r-30 mg-b-15">
            <Form.Check
              onChange={changeHandler}
              name="alert-push"
              value="alert-push"
              checked={state.pushAlertType === "alert-push" ? true : false}
              type={"checkbox"}
              id="alert-push"
              label={"Push Notification"}
              className={"ckbox mg-r-20"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertSetting;

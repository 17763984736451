////////////////////////////////////////////////////////////
//     							                          //
//  Program: CalendarService.js                           //
//  Application: Services                                 //
//  Option: Used for Calendars                            //
//  Developer: NP					                      //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class CalendarService {
  getUnifiedData(type, queryString) {
    let endPoint =
      "get-reservation-unified-calendar-data/" +
      type +
      (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // getReservations(slug, type, queryString) {
  //   let endPoint =
  //     "get-reservation-calendar-data/" +
  //     slug +
  //     "/" +
  //     type +
  //     (queryString ? "?" + queryString : "");
  //   let addditionalHeaderData = {};
  //   let postData = {};

  //   let inputData = {
  //     endPoint: endPoint,
  //     addditionalHeaderData: addditionalHeaderData,
  //     postData: postData,
  //   };
  //   let response = ApiService.getCall(inputData);
  //   return response;
  // }

  getReservations(type, queryString) {
    let endPoint =
      "get-reservation-calendar-data/" +
      type +
      (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // getChecklists(slug, queryString) {
  //   let endPoint =
  //     "get-cleaning-list-calender/" +
  //     slug +
  //     (queryString ? "?" + queryString : "");
  //   let addditionalHeaderData = {};
  //   let postData = {};

  //   let inputData = {
  //     endPoint: endPoint,
  //     addditionalHeaderData: addditionalHeaderData,
  //     postData: postData,
  //   };
  //   let response = ApiService.getCall(inputData);
  //   return response;
  // }

  getChecklists(queryString) {
    let endPoint = "get-cleaning-list-calender" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }


  // getLeasing(slug,type,queryString)
  // {
  //     let endPoint = "get-leasing-calendar-data/" + slug + "/" + type + (queryString ? '?' + queryString : '');
  //     let addditionalHeaderData = {};
  //     let postData = {};

  //     let inputData = {
  //       endPoint: endPoint,
  //       addditionalHeaderData: addditionalHeaderData,
  //       postData: postData,
  //     };
  //     let response = ApiService.getCall(inputData);
  //     return response;
  //   }

  getMROs(queryString) {
    let endPoint = "get-maintenance-list-calender" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }


  getLeasing(queryString) {
    let endPoint =
      "get-leasing-calendar-data" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new CalendarService();

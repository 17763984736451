////////////////////////////////////////////////////////////
//     							                          //
//  Program: Dashboard.jsx                                //
//  Application: Dashboard                                //
//  Option: to view Company Information                   //
//  Developer: NP  						                            //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////
import {
  Form,
  Container,
  Modal,
} from "react-bootstrap";
import React, { Component } from "react";
// import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import EmailService from "../../services/EmailService";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import SettingSidebar from "../common/SettingSidebar";
import Logo from "../../assets/images/logo_write.png";
import SimpleReactValidator from "simple-react-validator";

class EmailView extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      slug: props.match.params.slug,
      confirm_back: 0,
      input: {},
      errors: {},
      usertotype: '',
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      selectedTypeList: [],
      staffList: [],
      newStaffList: [],
      selectedStaffList: [],
      deleteTowerModal: false,
      status_id: false,
      typeList: [
        { key: 6, value: 6, label: 'User' },
        { key: 5, value: 5, label: 'Guest' },
        { key: 4, value: 4, label: 'Resident' },
        { key: 3, value: 3, label: 'Staff' }
      ],
      descShow: 0,
      moduleList: global.modulelist,
      moduleTypeList: global.moduleReferlist,
      template_desc_info: {
        guest:
          "Message ( Available Merge Fields : [Guest Name] [Confirmation Code] [Check-In] [Check-Out] [Addres] [Unit No] )",
        staff: "Message ( Available Merge Fields : [Staff Name] [Unit No] )",
        resident:
          "Message ( Available Merge Fields : [Resident Name] [Unit No] )",
      },
    };

    this.fullText = this.fullText.bind(this);
  }

  componentDidMount() {
    this.getDataFromSlug();
  }
  searchToggle = () => {
    if (this.state.isSearchModal === 1) {
      this.setState({ isSearchModal: 0 });
    } else {
      this.setState({ isSearchModal: 1 });
    }
  };
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  async getDataFromSlug() {
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await EmailService.getEmailTemplate(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.template_name = resData.template_name ? resData.template_name : "";
        resData.from = resData.from ? resData.from : "";
        resData.cc = resData.cc ? resData.cc : "";
        resData.to = resData.to
          ? resData.to
          : "";
        resData.description = resData.description
          ? resData.description
          : "";
        var ccdata = resData.cc.split(',');
        var selectedTypeList = [];
        selectedTypeList = res.data.to === 4 ? [{ key: 4, value: 4, label: 'Resident' }] : res.data.to === 6 ? [{ key: 6, value: 6, label: 'User' }] : res.data.to === 3 ? [{ key: 3, value: 3, label: 'Staff' }] : [{ key: 5, value: 5, label: 'Guest' }]

        var selectedStaffList = [];

        ccdata.length > 0 &&
          ccdata.forEach((item, i) => {
            selectedStaffList = [
              ...selectedStaffList,
              {
                value: item,
                label: item,
              },
            ];
          });
      }
      this.setState({
        selectedTypeList: selectedTypeList,
        selectedStaffList: selectedStaffList
      });
      this.setState(resData);
    }

  }
  async SendGenerateEmail() {
    if (this.validator.allValid() && this.customValidate()) {
      let inputData = {
        toemail: this.state.sendto,
        slug: this.state.slug
      };
      this.setState({ showSpinner: true, isSend: true });
      var res = await EmailService.testSendEmail(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ isSearchModal: 0 });
        this.setState({ showSpinner: false, isSend: false });
        // setTimeout(() => this.props.history.push("/users"), global.redirect_time);
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ isSearchModal: 0 });
          this.setState({ showSpinner: false, isSend: false });
          this.setState({ errors: errors });
        }
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ loading: false, isSubmit: false });
      this.validator.showMessages();
    }
    // setTimeout(() => this.getData(), global.alert_close_time);
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }
  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <SettingSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="prop-head pd-l-15">
                  <aside className="d-flex"> </aside>
                </div>

                <div className="col-md-12">
                  <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                    <h3 className="mb-0 brnd_heading">
                      Email Template
                    </h3>
                    <div className="d-flex">

                      {global.userPermissions.checkPermission(
                        "emails-update"
                      ) ? (<>
                        <button
                          type="button"
                          className=" m-0 btn-success btn-block"
                          onClick={() => this.searchToggle()}
                        >
                          {this.state.isSend
                            ? global.loader
                            : "Send Mail "}
                        </button>


                        <Link to={"/email/update/" + this.state.slug}>
                          <button className="btn-success-outline ml-3">
                            <img src={editIcon} alt="" />
                            Edit
                          </button>
                        </Link>
                      </>) : ''}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pl-3">
                  <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">

                    {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
                    <div className="d-flex justify-content-end">
                      {/* <Link to={'/properties/update/'+this.props.property_slug+"/basic"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
                    </div>

                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0 mt-3">
                      <Alerts
                        show={this.state.showAlertModal}
                        // show="true"
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    {/* {global.userPermissions.checkPermission(
                      "emails-view")
                    ? (
                      <> */}




                    <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">
                          Email Template Name
                        </p>
                        <p className="media-body">
                          {this.state.template_name
                            ? this.state.template_name
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">
                          Status
                        </p>
                        <p className="media-body">
                          {this.state.status_id === 1
                            ? "Active"
                            : "inactive"}
                        </p>
                      </div>
                      <div className="col-12">
                        <p className="az-profile-name-text pl-0">
                          Description
                        </p>
                        <p className="media-body">
                          {this.state.description
                            ? this.state.description
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">
                          From / to / cc
                        </p>
                        <p className="media-body">
                          {this.state.from
                            ? this.state.from
                            : ""} / {this.state.to === 4
                              ? "Resident" : this.state.to === 3
                                ? "Staff" : this.state.to === 6 ? "User"
                                  : this.state.to === 7 ? "custom" : "Guest"} / {this.state.cc
                                    ? this.state.cc
                                    : ""}
                        </p>
                      </div>

                      <div className="col-12">
                        <p className="az-profile-name-text pl-0">
                          Subject
                        </p>
                        <p className="media-body">
                          {this.state.subject
                            ? this.state.subject
                            : ""}
                        </p>
                      </div>
                      <div className="col-md-12 pd-lg-l-15 pd-xs-l-0">
                        <p className="az-profile-name-text pl-0">Body</p>
                        <div className="para editor_view_text">
                          {this.state.body ? ( //check if notes is exist
                            <p className="p_wrap1">
                              {this.state.descShow
                                ? this.state.body.slice(
                                  0,
                                  global.showMoreLength
                                )
                                : <div className="post__content" dangerouslySetInnerHTML={{ __html: this.state.body }}></div>}

                              {this.state.body.length >
                                global.showMoreLength && (
                                  <span onClick={this.fullText}>
                                    {this.state.descShow ? (
                                      <p className="hand-cursor showmore">
                                        ...Show more{" "}
                                      </p>
                                    ) : (
                                      <p className="hand-cursor showmore">
                                        Show Less{" "}
                                      </p>
                                    )}
                                  </span>
                                )}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                    </div>
                    {/* </>
                    ) : (
                      ""
                    )} */}


                  </div>
                </div>
              </div>
            </div>
            {/* <div className="ht-50"></div> */}
          </div>
          <Modal
            show={this.state.isSearchModal}
            className="modal-large setPasswordModal"
            centered
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                // this.filterData();
              }
            }}
          >
            <Modal.Body className="width_402 p-4 border_radius_14">
              <div className="Setpassword">
                <span
                  className="welcomeClose"
                  onClick={() => this.searchToggle()}
                >
                  {global.closee}
                </span>
                <h3 className="text-center">Send Email</h3>
                <hr></hr>
                <p className="d-flex align-items-center">
                  <b className="mr-2 d-block"> Subject:</b>
                  <strong>{this.state.subject}</strong>
                </p>
                <p className="d-flex align-items-center">
                  <b className="mr-2 d-block">To:</b>
                  <div className="col-6">
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="sendto"
                      value={this.state.sendto}
                      maxLength="100"
                      //tabIndex="1"
                      autoComplete="off"
                    />
                    {this.state.errors.sendto ? (
                      <div className="text-danger">
                        {this.state.errors.sendto}
                      </div>
                    ) : (
                      this.validator.message(
                        "email",
                        this.state.sendto,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                </p>
                <p className="d-flex align-items-center">
                  <b className="mr-2 d-block">From:</b>
                  {this.state.from}
                </p>
                <hr></hr>
                <p className="m-auto text-center mt-1">
                  <img src={Logo} alt="filterIcon" className="logo" />{" "}
                </p>

                <div className="post__content editor_view_text" dangerouslySetInnerHTML={{ __html: this.state.body }}></div>
                <div className="d-flex justify-content-center mt-3 row pt-3">
                  <button
                    type="button"
                    className=" m-0 btn btn-outline-light btn-block"
                    onClick={() => this.searchToggle()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className=" m-0 btn-success btn-block"
                    onClick={() => this.SendGenerateEmail()}
                  >
                    {this.state.isSubmit ? global.loader : "Send Mail "}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </main>
    );
  }
}
export default EmailView

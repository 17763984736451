////////////////////////////////////////////////////////////
//     							                                      //
//  Program: HomeSharingInquiries.jsx                     //
//  Application: Home Sharing                             //
//  Developer: Devendra Kumar					                    //
//  Date: 10/07/2023                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import ListingService from "../../services/ListingService";
import ErrorLogo from "./../../assets/images/errorImage.svg";
import SuccessLogo from "./../../assets/images/successImage.svg";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Modal,
  InputGroup,
  Form,
  Tab,
  Nav,
} from "react-bootstrap";
import LisitingSidebar from "../common/LisitingSidebar";
import pluswIcon from "./../../assets/images/plus.svg";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Alerts from "../common/Alerts";
import Moment from "moment";
import NoData from "../common/NoData";
import Select from "react-select";
import queryString from "query-string";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyLisitingSidebar from "../common/AllPropertyLisitingSidebar";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
let queryParams = queryString.parse(window.location.search);
export class HomeSharingInquiries extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      errors: {},
      file_url: "",
      file_name: "",
      open: true,
      eventKey: "1",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      isModel: global.isModel,
      alertType: "xlsx",
      module_search: "",
      exportMesssage: "",
      exportFilePath: "",
      exportResult: "",
      is_search: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      errorResponse: false,
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      residentList: [],
      newResidentList: [],
      selectedResidentList: [],
      rentingTypeList: [],
      newRentingTypeList: [],
      selectedRentingTypeList: [],
      lisitngList: [],
      newListingList: [],
      importResponseModel: false,
      selectedListingList: [],
      property_name: "",
      user_name: "",
      unit_name: "",
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList: [],
      records: [],
      tab: 1,
      inquiryTabNumber: localStorage.getItem("inquiry_tab_number")
        ? parseInt(localStorage.getItem("inquiry_tab_number"))
        : 1,
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "property_name",
          text: "Property Name",
          className: "listing_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.property_name ? record.property_name : "";
          },
        },
        {
          key: "unit_name",
          text: "Unit",
          className: "unit_id hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.unit_name ? record.unit_name : "";
          },
        },

        {
          key: "first_name",
          text: "First Name",
          className: "first_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.first_name ? record.first_name : "";
          },
        },
        {
          key: "last_name",
          text: "Last Name",
          className: "last_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.last_name ? record.last_name : "";
          },
        },
        {
          key: "email",
          text: "Email",
          className: "email hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.email ? record.email : "";
          },
        },
        {
          key: "created_at",
          text: "Inquiry at",
          className: "created_at hand-cursor td_clickable date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.created_at
              ? Moment(Moment.utc(record.created_at, "YYYY-MM-DDTHH:mm:ss"))
                  .local()
                  .format(global.dateTimeFormat)
              : "";
          },
        },
        {
          key: "status",
          text: "Status",
          className: "status_id hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.unit_name ? record.inquiries_status : "";
          },
        },
      ],
      columns1: [
        {
          key: "unit_name",
          text: "Unit",
          className: "unit_id hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.unit_name ? record.unit_name : "";
          },
        },
        {
          key: "first_name",
          text: "Name",
          className: "first_name hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            let full_name =
              record.first_name && record.first_name !== null
                ? record.first_name
                : "" + " " + record.last_name && record.last_name !== null
                ? record.last_name
                : "";
            return full_name;
          },
        },
        {
          key: "created_at",
          text: "Inquiry at",
          className: "created_at hand-cursor td_clickable date_tbl_formate",
          sortable: true,
          cell: (record) => {
            return record.created_at
              ? Moment(Moment.utc(record.created_at, "YYYY-MM-DDTHH:mm:ss"))
                  .local()
                  .format(global.dateTimeFormat)
              : "";
          },
        },
        {
          key: "status",
          text: "Status",
          className: "status_id hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            return record.unit_name ? record.inquiries_status : "";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined)
      switch (this.state.inquiryTabNumber) {
        case 2:
          document.getElementById("contacted").click();
          break;
        case 3:
          document.getElementById("made_contact").click();
          break;
        case 4:
          document.getElementById("need_to_permit").click();
          break;
        case 5:
          document.getElementById("in_permit_process").click();
          break;
        case 6:
          document.getElementById("approved").click();
          break;
        case 7:
          document.getElementById("all").click();
          break;
        default:
          document.getElementById("new").click();
      }
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      switch (this.state.inquiryTabNumber) {
        case 2:
          document.getElementById("contacted").click();
          break;
        case 3:
          document.getElementById("made_contact").click();
          break;
        case 4:
          document.getElementById("need_to_permit").click();
          break;
        case 5:
          document.getElementById("in_permit_process").click();
          break;
        case 6:
          document.getElementById("approved").click();
          break;
        case 7:
          document.getElementById("all").click();
          break;
        default:
          document.getElementById("new").click();
      }
    }, 200);
    this.setState({
      unit_number: "",
      module_search: [],
      is_search: 0,
    });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "new") {
      this.setState({ tab: 1 });
      this.getData(1);
      setTimeout(() => {
        localStorage.setItem(
          "inquiry_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "contacted") {
      this.setState({ tab: 2 });
      this.getData(2);
      setTimeout(() => {
        localStorage.setItem(
          "inquiry_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "made_contact") {
      this.setState({ tab: 3 });
      this.getData(3);
      setTimeout(() => {
        localStorage.setItem(
          "inquiry_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "need_to_permit") {
      this.setState({ tab: 4 });
      this.getData(4);
      setTimeout(() => {
        localStorage.setItem(
          "inquiry_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "in_permit_process") {
      this.setState({ tab: 5 });
      this.getData(5);
      setTimeout(() => {
        localStorage.setItem(
          "inquiry_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "approved") {
      this.setState({ tab: 6 });
      this.getData(6);
      setTimeout(() => {
        localStorage.setItem(
          "inquiry_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "all") {
      this.setState({ tab: 7 });
      this.getData(7);
      setTimeout(() => {
        localStorage.setItem(
          "inquiry_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else {
      this.setState({ tab: 1 });
      this.getData(1);
      setTimeout(() => {
        localStorage.setItem(
          "inquiry_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(tab, queryString = "") {
    console.log(tab);
    var list = [];
    var statusList = [];
    var totalRecords = 0;
    if (queryParams.isGlobal === "1") {
      var res = await ListingService.getHomeSharingInquiryList(
        "property_slug=" +
          (this.state.property_slug ? this.state.property_slug : "") +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          "&is_search=1&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search +
          (queryString ? "&" + queryString : "")
      );
    } else {
      res = await ListingService.getHomeSharingInquiryList(
        "property_slug=" +
          (this.state.property_slug ? this.state.property_slug : "") +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          (queryString ? "&" + queryString : "")
      );
    }
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.homesharinginquiry : [];
      statusList = res.data ? res.data.status : [];
      totalRecords = res.data ? res.data.homesharinginquiry.length : 0;
    }
    const newStatusList =
      totalRecords > 0
        ? statusList.map(({ status_id, inquiries_status }) => ({
            status_id: status_id,
            label: inquiries_status ? inquiries_status : "",
          }))
        : [];
    this.setState({
      total_record: totalRecords,
      records: list,
      newStatusList: newStatusList,
    });
  }

  async ExportData() {
    let selected_property = localStorage.getItem("selectedPropertyName");
    let selected_property_slug = localStorage.getItem("outSidePorpertySlug");
    let customQueryString = "";
    if (selected_property_slug !== "") {
      customQueryString =
        "?property_slug=" +
        this.state.property_slug +
        "&property_name=" +
        selected_property +
        "&unit_name=" +
        this.state.unit_name +
        "&user_name=" +
        this.state.user_name +
        "&email=" +
        this.state.email +
        "&status=" +
        (this.state.selectedStatusList.label
          ? this.state.selectedStatusList.label
          : "") +
        "&tab=" +
        this.state.tab +
        "&is_search=1";
    }
    if (window.location.pathname.includes("/properties/view/")) {
      customQueryString =
        "?property_slug=" +
        this.state.property_slug +
        "&property_name=" +
        this.state.records[0].property_name +
        "&unit_name=" +
        this.state.unit_name +
        "&user_name=" +
        this.state.user_name +
        "&email=" +
        this.state.email +
        "&status=" +
        (this.state.selectedStatusList.label
          ? this.state.selectedStatusList.label
          : "") +
        "&tab=" +
        this.state.tab +
        "&is_search=1";
    }
    let data = {
      property_slug: this.state.property_slug,
      file_type: this.state.alertType,
      where_column:
        this.state.customQueryString.length !== 0
          ? this.state.customQueryString
          : customQueryString
          ? customQueryString
          : "",
    };
    let res = await ListingService.exportInquiriesDataFilewise(data);
    this.setState({ exportMesssage: res.message });
    this.setState({ exportResult: data });
    this.setState({ exportFilePath: res.data.filePath });
  }

  handleChangeStatusList(value) {
    let status_list_id = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        status_list_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_list_id: value.status_id,
    });
  }

  filterData() {
    let queryString =
      "property_name=" +
      (this.state.property_name ? this.state.property_name : "") +
      "&unit_name=" +
      (this.state.unit_name ? this.state.unit_name : "") +
      "&user_name=" +
      (this.state.user_name ? this.state.user_name : "") +
      "&email=" +
      (this.state.email ? this.state.email : "") +
      "&status=" +
      (this.state.selectedStatusList.label
        ? this.state.selectedStatusList.label
        : "") +
      "&is_search=1";
    let moduleSearchString = "";
    moduleSearchString = this.state.property_name
      ? "Property Name - " + this.state.property_name
      : "";
    moduleSearchString = this.state.unit_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Unit Name - " + this.state.unit_name
        : " Unit Name - " + this.state.unit_name
      : moduleSearchString;
    moduleSearchString = this.state.user_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Person Name - " + this.state.user_name
        : " Person Name - " + this.state.user_name
      : moduleSearchString;
    moduleSearchString = this.state.email
      ? moduleSearchString
        ? moduleSearchString + " ," + " Person Name - " + this.state.email
        : " Email - " + this.state.email
      : moduleSearchString;
    moduleSearchString = this.state.selectedStatusList.label
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Status - " +
          this.state.selectedStatusList.label
        : " Status - " + this.state.selectedStatusList.label
      : moduleSearchString;
    if (
      this.state.property_name ||
      this.state.unit_name ||
      this.state.user_name ||
      this.state.selectedStatusList.label
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(this.state.tab, queryString);
    }
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData("tab=" + this.state.tab + "&" + queryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData("tab=" + this.state.tab);
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  exportToggle = () => {
    if (this.state.isModel === true) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: false });
    } else {
      this.setState({ isModel: true });
    }
  };

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      from_date: "",
      to_date: "",
      listing_slug: "",
      unit_slug: "",
      resident_name: "",
      nightly_rate: "",
      total_amount: "",
      furnished_slug: "",
      status_id: "",
      selectedListingList: [],
      selectedUnitList: [],
      selectedRentingTypeList: [],
      selectedResidentList: [],
      selectedStatusList: [],
    });
    this.setState({ module_search: "" });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(this.state.tab, queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(this.state.tab, queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Homesharing Inquiries";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }

  handleChange = (file) => {
    this.setState({ file_name: file.name });
    this.setState({ file_url: file });
  };

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "/properties/view/" +
            data.property_slug +
            "/homesharing-inquiries/view/" +
            data.slug
        : "/homesharing-inquiries/view/" +
            data.property_slug +
            "/" +
            data.slug +
            "/info"
    );
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container pl-3">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <LisitingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyLisitingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  {/* <div className="col-md-12"> */}

                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0 pd-lg-l-15">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex flex-wrap justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small ml-3"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                        {this.state.property_slug !== "" ||
                        this.props.match.params.propertySlug !== undefined ? (
                          <>
                            <Link
                              to={
                                this.props.match.params.propertySlug !==
                                undefined
                                  ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/homesharing-inquiries/add"
                                  : "/homesharing-inquiries/add/" +
                                    this.state.property_slug
                              }
                            >
                              <button className="btn-success-outline-small ml-3">
                                <img src={pluswIcon} alt="" /> Add
                              </button>
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                        {global.userPermissions.checkPermission(
                          "single-unit-export"
                        ) && (
                          <button
                            className="btn-success-outline-small ml-3"
                            onClick={this.exportToggle}
                          >
                            Export
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="scrolling-carousel pd-l-15 Listingview">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.showFilter ? (
                        <Row className="align-items-center order_form">
                          <Col
                            md={3}
                            lg={5}
                            xl={5}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <input
                              name="filter_value"
                              className="form-control"
                              autoComplete="off"
                              // placeholder="Filter Value"
                              value={this.state.filter_value}
                              onChange={this.changeHandler}
                              maxLength="30"
                            />{" "}
                          </Col>
                          <Col md={5} lg={5} xl={5}>
                            <Row>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-30 mg-r-20"
                              >
                                Search
                              </button>
                              <button
                                onClick={this.resetDate}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      {queryParams.isGlobal === "1" ? (
                        <>
                          <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between  scrolling-carousel">
                            {"Search applied on: " +
                              queryParams.global_col_label +
                              " - " +
                              (queryParams.global_col_name === "from_date" ||
                              queryParams.global_col_name === "to_date"
                                ? Moment(queryParams.global_search).format(
                                    global.dateFormat
                                  )
                                : queryParams.global_search)}
                            <button
                              onClick={this.clearFilter}
                              className="mg-l-15 btn-success-outline-small"
                            >
                              Clear
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {this.props.match.params.propertySlug === undefined ? (
                        <>
                          <div className="row pd-lg-l-30 pd-xs-l-15">
                            <div className="col-xxl-2 col-md-3 col-sm-4">
                              <OutSidePropertySelectBox
                                propertySlug={this.getPropertySlug.bind(this)}
                                outSidePropertySlug={
                                  this.state.outSidePropertySlug
                                }
                              />
                            </div>
                            <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                              {this.state.is_search === 1 ? (
                                <>
                                  <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-0 d-inline-flex align-items-start justify-content-end justify_mob_filter">
                                    <div className="custm-filter">
                                      <div>
                                        {" "}
                                        <label className="form-label text-left pd-b-3 mb-0">
                                          Search Applied On
                                        </label>
                                      </div>
                                      <div className="filter_bg_cust">
                                        {this.state.module_search.map(
                                          (item, i) => {
                                            return <span key={i}>{item}</span>;
                                          }
                                        )}
                                        <button
                                          onClick={this.clearFilter}
                                          className="btn-fliter-clear"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-xxl-12 pd-r-15 pd-lg-l-0 pd-xs-l-15 mb-2">
                            {this.state.is_search === 1 ? (
                              <>
                                <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end scrolling-carousel">
                                  <div className="custm-filter">
                                    <div>
                                      {" "}
                                      <label className="form-label text-left pd-b-3 mb-0">
                                        Search Applied On
                                      </label>
                                    </div>
                                    <div className="filter_bg_cust">
                                      {this.state.module_search.map(
                                        (item, i) => {
                                          return <span key={i}>{item}</span>;
                                        }
                                      )}
                                      <button
                                        onClick={this.clearFilter}
                                        className="btn-fliter-clear"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      )}
                      <div className="scrolling-carousel pd-l-0 scroll-slide">
                        <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="new"
                            onSelect={(e) => this.getActiveClass(e)}
                            eventKey={this.state.activeClass}
                            className="pd-r-60"
                          >
                            <ScrollingCarousel
                              rightArrow={true}
                              leftArrow={true}
                              className="swipesection scroll_nav_mob"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="new"
                                  id="new"
                                  className={
                                    this.state.activeClass === "new"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  New
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="contacted"
                                  id="contacted"
                                  className={
                                    this.state.activeClass === "contacted"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Contacted
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="made_contact"
                                  id="made_contact"
                                  className={
                                    this.state.activeClass === "made_contact"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Made Contact
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="need_to_permit"
                                  id="need_to_permit"
                                  className={
                                    this.state.activeClass === "need_to_permit"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Need to Permit
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="in_permit_process"
                                  id="in_permit_process"
                                  className={
                                    this.state.activeClass ===
                                    "in_permit_process"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  In Permit Process
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="approved"
                                  id="approved"
                                  className={
                                    this.state.activeClass === "approved"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Approved
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="all"
                                  id="all"
                                  className={
                                    this.state.activeClass === "all"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  All
                                </Nav.Link>
                              </Nav.Item>
                            </ScrollingCarousel>
                            <Tab.Content>
                              <Tab.Pane eventKey="new">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  <div className="col-md-12 pl-0 pr-0">
                                    <Alerts
                                      show={this.state.showAlertModal}
                                      type={this.state.alertModalType}
                                      title={this.state.alertModalTitle}
                                      message={this.state.alertModalMessage}
                                    />
                                  </div>
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-18 brandList">
                                      <div className="pd-lg-l-0 pd-xs-l-15">
                                        <ReactDatatable
                                          className="table mg-b-30 pd-b-2 add-no-wrap table_last_row"
                                          config={this.state.config}
                                          records={this.state.records}
                                          columns={
                                            this.state.property_slug
                                              ? this.state.columns1
                                              : this.state.columns
                                          }
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                          onRowClicked={this.rowClickedHandler}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <NoData msg="active homesharing inquiries" />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="contacted">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  <div className="col-md-12 pl-0 pr-0">
                                    <Alerts
                                      show={this.state.showAlertModal}
                                      type={this.state.alertModalType}
                                      title={this.state.alertModalTitle}
                                      message={this.state.alertModalMessage}
                                    />
                                  </div>
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-18 brandList">
                                      <div className="pd-lg-l-0 pd-xs-l-15">
                                        <ReactDatatable
                                          className="table mg-b-30 pd-b-2 add-no-wrap table_last_row"
                                          config={this.state.config}
                                          records={this.state.records}
                                          columns={
                                            this.state.property_slug
                                              ? this.state.columns1
                                              : this.state.columns
                                          }
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                          onRowClicked={this.rowClickedHandler}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <NoData msg="active homesharing inquiries" />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="made_contact">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  <div className="col-md-12 pl-0 pr-0">
                                    <Alerts
                                      show={this.state.showAlertModal}
                                      type={this.state.alertModalType}
                                      title={this.state.alertModalTitle}
                                      message={this.state.alertModalMessage}
                                    />
                                  </div>
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-18 brandList">
                                      <div className="pd-lg-l-0 pd-xs-l-15">
                                        <ReactDatatable
                                          className="table mg-b-30 pd-b-2 add-no-wrap table_last_row"
                                          config={this.state.config}
                                          records={this.state.records}
                                          columns={
                                            this.state.property_slug
                                              ? this.state.columns1
                                              : this.state.columns
                                          }
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                          onRowClicked={this.rowClickedHandler}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <NoData msg="active homesharing inquiries" />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="need_to_permit">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  <div className="col-md-12 pl-0 pr-0">
                                    <Alerts
                                      show={this.state.showAlertModal}
                                      type={this.state.alertModalType}
                                      title={this.state.alertModalTitle}
                                      message={this.state.alertModalMessage}
                                    />
                                  </div>
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-18 brandList">
                                      <div className="pd-lg-l-0 pd-xs-l-15">
                                        <ReactDatatable
                                          className="table mg-b-30 pd-b-2 add-no-wrap table_last_row"
                                          config={this.state.config}
                                          records={this.state.records}
                                          columns={
                                            this.state.property_slug
                                              ? this.state.columns1
                                              : this.state.columns
                                          }
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                          onRowClicked={this.rowClickedHandler}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <NoData msg="active homesharing inquiries" />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="in_permit_process">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  <div className="col-md-12 pl-0 pr-0">
                                    <Alerts
                                      show={this.state.showAlertModal}
                                      type={this.state.alertModalType}
                                      title={this.state.alertModalTitle}
                                      message={this.state.alertModalMessage}
                                    />
                                  </div>
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-18 brandList">
                                      <div className="pd-lg-l-0 pd-xs-l-15">
                                        <ReactDatatable
                                          className="table mg-b-30 pd-b-2 add-no-wrap table_last_row"
                                          config={this.state.config}
                                          records={this.state.records}
                                          columns={
                                            this.state.property_slug
                                              ? this.state.columns1
                                              : this.state.columns
                                          }
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                          onRowClicked={this.rowClickedHandler}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <NoData msg="active homesharing inquiries" />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="approved">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  <div className="col-md-12 pl-0 pr-0">
                                    <Alerts
                                      show={this.state.showAlertModal}
                                      type={this.state.alertModalType}
                                      title={this.state.alertModalTitle}
                                      message={this.state.alertModalMessage}
                                    />
                                  </div>
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-18 brandList">
                                      <div className="pd-lg-l-0 pd-xs-l-15">
                                        <ReactDatatable
                                          className="table mg-b-30 pd-b-2 add-no-wrap table_last_row"
                                          config={this.state.config}
                                          records={this.state.records}
                                          columns={
                                            this.state.property_slug
                                              ? this.state.columns1
                                              : this.state.columns
                                          }
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                          onRowClicked={this.rowClickedHandler}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <NoData msg="active homesharing inquiries" />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="all">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  <div className="col-md-12 pl-0 pr-0">
                                    <Alerts
                                      show={this.state.showAlertModal}
                                      type={this.state.alertModalType}
                                      title={this.state.alertModalTitle}
                                      message={this.state.alertModalMessage}
                                    />
                                  </div>
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-18 brandList">
                                      <div className="pd-lg-l-0 pd-xs-l-15">
                                        <ReactDatatable
                                          className="table mg-b-30 pd-b-2 add-no-wrap table_last_row"
                                          config={this.state.config}
                                          records={this.state.records}
                                          columns={
                                            this.state.property_slug
                                              ? this.state.columns1
                                              : this.state.columns
                                          }
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                          onRowClicked={this.rowClickedHandler}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <NoData msg="active homesharing inquiries" />
                                  )}
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Homesharing Inquiries</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              {!this.state.property_slug ? (
                <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                  <InputGroup className="mt-3">
                    <label>Property Name</label>
                    <div className="col-md-12 p-0">
                      <Form.Control
                        onChange={this.changeHandler}
                        name="property_name"
                        value={this.state.property_name}
                        className="form-control max_width_100"
                        id="inlineFormInput"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                      />
                    </div>
                  </InputGroup>
                </div>
              ) : (
                ""
              )}

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Name</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="user_name"
                      value={this.state.user_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="unit_name"
                      value={this.state.unit_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Status</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedStatusList}
                      options={this.state.newStatusList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeStatusList(value)}
                      defaultValue={this.state.selectedStatusList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
        <Modal show={this.state.isModel} className="modal-xs" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.exportToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Export</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <InputGroup className="mt-0">
                  <label>Select Export File Type : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="xlsx"
                        checked={this.state.alertType === "xlsx" ? true : false}
                        type={"radio"}
                        id="xlsx"
                        label={"XLSX"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="csv"
                        checked={this.state.alertType === "csv" ? true : false}
                        type={"radio"}
                        id="csv"
                        label={"CSV"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>

                {this.state.exportMesssage !== "" && (
                  <div>
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {this.state.exportFilePath !== undefined && (
                      <a href={this.state.exportFilePath}> Download File </a>
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                  >
                    {this.state.isSubmit ? global.loader : "Export"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block"
                    onClick={() => this.exportToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.importResponseModel}
          className="modal-xs recordDetailmodalbox"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeimportResponseModel()}
            >
              {global.closee}
            </span>
            {/* <h3 className="text-center">Record detail</h3> */}
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-between mt-0 row">
                  {this.state.errorResponse === true ? (
                    <div className="msg">
                      <img src={ErrorLogo} alt="filterIcon" />{" "}
                      <p>{this.state.errorMessage}</p>{" "}
                    </div>
                  ) : (
                    <div className="msg">
                      <img src={SuccessLogo} alt="filterIcon" />
                      <p>{this.state.successMessage}</p>{" "}
                    </div>
                  )}
                  {this.state.totalRecord ? (
                    <div className="moreMsg">
                      <div className="row flex-wrap m-0">
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Total Record
                          </p>
                          <p className="media-body">{this.state.totalRecord}</p>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Success Record
                          </p>
                          <p className="media-body">
                            {this.state.successRecord}
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Skip Record
                          </p>
                          <p className="media-body">{this.state.skipRecord}</p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <p className="az-profile-name-text pl-0">Skip Data</p>
                          <p
                            className="media-body pr-3"
                            dangerouslySetInnerHTML={{
                              __html: this.state.skipData
                                ? this.state.skipData
                                : "No any record skip",
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default HomeSharingInquiries;

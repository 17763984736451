//     							                          //
//  Program: Checklist.jsx                                     //
//  Application: House keeping                                 //
//  Option: Home Sharing Listing                               //
//  Developer: NP 		                                       //
//  Date: 2022-08-30                                          //
//                                                             //
/////////////////////////////////////////////////////////     ///

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import VendorsSidebar from "../common/VendorsSidebar";
import NoData from "../common/NoData";

class Bills extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug : this.props.match.params.propertySlug
    };
  }

  render() {
    return (
        <main>
        <Container className="innter-container">
            <div className="d-flex flex-column">
                <div className="row row-xs mg-t-0 pd-t-0">
                    <div className="col-md-2 left">
                        <div className="child-menu-section">
                            <aside>
                                <VendorsSidebar property_slug={this.state.property_slug} />
                            </aside>
                        </div>
                    </div>
                    <div className="col-md-10 right">
                      <div className="dashboardRightcard mg-t-15">
                        <NoData />
                      </div>
                    </div>
                </div>
            </div>
        </Container>
        </main>
    );
  }
}
export default Bills
/////////////////////////////////////////////////////
//     							                               //
//  Program: NearByAttraction.jsx                  //
//  Application: Property                          //
//  Option: List all Property NearByAttraction info//
//  Developer: Ashish Kumar					               //
//  Date: 2022-04-04                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import PropertySidebar from "../common/PropertySidebar";
import { Link } from "react-router-dom";
import NearByAttractionService from "../../services/NearByAttractionService";
import { Container, Row, Col } from "react-bootstrap";
import NoData from "../common/NoData";

export class NearByAttraction extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      deleteNearByAttractionModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      viewMode: 0,
      property_slug: this.props.match.params.propertySlug,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "nearby_attraction_name",
          text: "Name",
          className: "nearby_attraction_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.slug
              ? record.nearby_attraction_name
              : record.nearby_attraction_name;
          },
        },
        {
          key: "attraction_type_id",
          text: "Type",
          className: "attraction_type hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.attraction_type
              ? record.attraction_type.attraction_type_name
              : "";
          },
        },
        {
          key: "contact_name",
          text: "Contact Name",
          className: "contact_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.contact_name ? record.contact_name : "";
          },
        },
        {
          key: "contact_phone",
          text: "Phone",
          className: "contact_phone hand-cursor text-left",
          sortable: true,
          cell: (record) => {
            var lastTenDigits = "";
            const cleanedNumber = record.contact_phone
              ? record.contact_phone.replace(/[\s-]/g, "")
              : [];
            if (cleanedNumber.length > 10) {
              // If it's longer, keep only the last 10 characters
              lastTenDigits = cleanedNumber.slice(-10);
            } else {
              // If it's 10 or less characters, set it as is
              lastTenDigits = record.contact_phone ? record.contact_phone : [];
            }

            var number = record.contact_phone
              ? lastTenDigits.trim().replace(/[^0-9]/g, "")
              : "";
            var finalnumber = "";
            if (number.length < 4) {
              finalnumber = number;
            } else if (number.length < 7) {
              finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
            } else if (number.length < 11) {
              finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            } else {
              finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
            }
            return record.contact_phone ? finalnumber : "";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.getData();
    this.setState({ showSpinner: false, loading: true });
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await NearByAttractionService.getNearByAttraction(
      "property_slug=" +
        this.state.property_slug +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Near By";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("near-by-attractions/view/" + data.slug);
  };

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
    }
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {global.userPermissions.checkPermission(
                        "near-by-add"
                      ) && (
                        <Link
                          to={"near-by-attractions/add"}
                          className="w-100 d-flex justify-content-end"
                        >
                          <button className="btn-success-outline-small ">
                            Add
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    {this.state.showFilter ? (
                      <Row className="align-items-center order_form">
                        <Col md={3} lg={5} xl={5} className="mb-md-0 mb-3 pl-0">
                          <input
                            name="filter_value"
                            className="form-control"
                            autoComplete="off"
                            // placeholder="Filter Value"
                            value={this.state.filter_value}
                            onChange={this.changeHandler}
                            maxLength="30"
                          />
                        </Col>
                        <Col md={5} lg={5} xl={5}>
                          <Row>
                            <button
                              onClick={this.customFilter}
                              className="btn-success mg-l-30 mg-r-20"
                            >
                              Search
                            </button>
                            <button
                              onClick={this.resetDate}
                              className="btn-success-outline"
                            >
                              Reset
                            </button>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    {this.state.total_record > 0 ||
                    this.state.filter_value !== "" ? (
                      <div className="mg-t-18 brandList">
                        <div className="pd-lg-l-0 pd-xs-l-15">
                          <ReactDatatable
                            className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                            config={
                              this.state.total_record > global.page_size
                                ? this.state.config
                                : this.state.config
                            }
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            // loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            onRowClicked={this.rowClickedHandler}
                          />
                        </div>
                      </div>
                    ) : (
                      <NoData
                        msg={global.commonMessages.checkMessage(
                          "NEARBYATRACTION"
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default NearByAttraction;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: ListingAmenities.jsx                            //
//  Application: Listing                                     //
//  Option: List all Listing Amenities                       //
//  Developer: Ashish Kumar					                  //
//  Date: 2022-04-08                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
// import { Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Alerts from "../common/Alerts";
import AmenityService from "../../services/AmenityService";
import ListingService from "../../services/ListingService";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import NoData from "../common/NoData";
//import { Form } from "react-bootstrap";
import Select from "react-select";

export class ListingAmenities extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      propertyAmenityList: [],
      propertyAmenityTotal: 0,
      viewMode: 0,
      open: global.showOpen,
      eventKey: "",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      isSubmit: false,
      pathname: window.location.pathname,
      importModal: false,
      amenitiesList: [],
      amenitiesListTotal: [],
      propertyAmenitiesList: [],
      selectedAmenities: [],
      slugUncheckList: [],
      // confirm_back: 0,
      select_all: false,
      activeAmenities: 0,
      activeUnitAmenity: 0,
      activePopertyAmenity: 0,
      activeFloorPlanAmenity: 0,
      basicAmenityList: [],
      propertyAmenityListBasic: [],
      publishListingModal: false,
      publishMessage: "",
      /*bedroomOptions: [
        {
          value: "55",
          label: "baby bed",
        },
        {
          value: "61",
          label: "double bed",
        },
        {
          value: "200",
          label: "double sofa bed",
        },
        {
          value: "209",
          label: "Extra Bed",
        },
        {
          value: "237",
          label: "sofabed",
        },
        {
          value: "323",
          label: "single bed",
        },
        {
          value: "324",
          label: "king size bed",
        },
        {
          value: "444",
          label: "Bunk Bed",
        },
        {
          value: "485",
          label: "Queen size bed",
        },
        {
          value: "501",
          label: "day bed",
        },
        {
          value: "515",
          label: "Wallbed",
        },
        {
          value: "624",
          label: "Pull-Out Bed",
        },
        {
          value: "655",
          label: "Canopy / Poster Bed",
        },
        {
          value: "778",
          label: "Murphy Bed",
        },
        {
          value: "939",
          label: "French bed",
        },
        {
          value: "957",
          label: "Tribunk bed",
        },
        {
          value: "1210",
          label: "electric profiling bed",
        },
        {
          value: "1350",
          label: "accessible height bed",
        },

      ],*/
      selectedRoleList: [],
      role_id: "",
      bedroomList: [],
      bedroomOptions: []
    };

    this.closeModal = this.closeModal.bind(this);
    this.changeView = this.changeView.bind(this);
    this.getAmenitiesDetails = this.getAmenitiesDetails.bind(this);
    this.addAmenities = this.addAmenities.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleCheckboxChangeNew = this.handleCheckboxChangeNew.bind(this);
    this.handleCheckboxChangeSelectAll = this.handleCheckboxChangeSelectAll.bind(this);
    this.openPublishListingModal = this.openPublishListingModal.bind(this);
    this.closePublishListingModal = this.closePublishListingModal.bind(this);
    this.publishListing = this.publishListing.bind(this);
    this.addUpdateBeds = this.addUpdateBeds.bind(this);
  }

  async componentDidMount() {
    // setTimeout(() => {
    //   this.getPropertyAmenitiesData();
    // }, 100);
    this.AllAmenitiesData();
    // this.getAmenitiesData();
    // this.getPropertyLevelAmenitiesData();
    // this.getFloorPlanLevelAmenitiesData();
    // this.getUnitLevelAmenitiesData();
  }

  /* to get property level amenities data */
  async getPropertyLevelAmenitiesData() {
    var propertyLevelAmenitiesList = [];
    var propertyLevelAmenitiesListTotal = 0;
    let res = await AmenityService.getPropertyAmenities(
      this.props.property_slug + "/1"
    );
    if (global.successStatus.includes(res.status)) {
      propertyLevelAmenitiesList = res.data ? res.data : [];
      propertyLevelAmenitiesListTotal = propertyLevelAmenitiesList
        ? propertyLevelAmenitiesList.length
        : 0;
    }
    if (propertyLevelAmenitiesListTotal > 0) {
      propertyLevelAmenitiesList.map((opt, i) => {
        if (opt.status_id === 1) {
          this.setState({ activePopertyAmenity: 1 });
        }
      });
    }
    this.setState({
      propertyLevelAmenitiesList: propertyLevelAmenitiesList,
      propertyLevelAmenitiesListTotal: propertyLevelAmenitiesListTotal,
    });
  }

  /* to get floor plan level amenities data */
  async getFloorPlanLevelAmenitiesData() {
    var floorPlanLevelAmenitiesList = [];
    var floorPlanLevelAmenitiesListTotal = 0;
    let res = await AmenityService.getPropertyAmenities(
      this.props.floor_plan_slug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      floorPlanLevelAmenitiesList = res.data ? res.data : [];
      floorPlanLevelAmenitiesListTotal = floorPlanLevelAmenitiesList
        ? floorPlanLevelAmenitiesList.length
        : 0;
    }
    if (floorPlanLevelAmenitiesListTotal > 0) {
      floorPlanLevelAmenitiesList.map((opt, i) => {
        if (opt.status_id === 1) {
          this.setState({ activeFloorPlanAmenity: 1 });
        }
      });
    }
    this.setState({
      floorPlanLevelAmenitiesList: floorPlanLevelAmenitiesList,
      floorPlanLevelAmenitiesListTotal: floorPlanLevelAmenitiesListTotal,
    });
  }

  /* to get unit level amenities data */
  async getUnitLevelAmenitiesData() {
    var unitLevelAmenitiesList = [];
    var unitLevelAmenitiesListTotal = 0;
    let res = await AmenityService.getPropertyAmenities(
      this.props.unit_slug + "/4"
    );
    if (global.successStatus.includes(res.status)) {
      unitLevelAmenitiesList = res.data ? res.data : [];
      unitLevelAmenitiesListTotal = unitLevelAmenitiesList
        ? unitLevelAmenitiesList.length
        : 0;
    }
    if (unitLevelAmenitiesListTotal > 0) {
      unitLevelAmenitiesList.map((opt, i) => {
        if (opt.status_id === 1) {
          this.setState({ activeUnitAmenity: 1 });
        }
      });
    }
    this.setState({
      unitLevelAmenitiesList: unitLevelAmenitiesList,
      unitLevelAmenitiesListTotal: unitLevelAmenitiesListTotal,
    });
  }

  /* to amenities data */
  async getAmenitiesData(queryString = "") {
    var basicAmenityList = [];
    var amenitiesList = [];
    var amenitiesListTotal = 0;

    let res = await AmenityService.getAmenity(
      "level=2&is_mapped=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      basicAmenityList = res.data ? res.data.data : [];
      amenitiesListTotal = res.data && res.data.data ? res.data.data.length : 0;

      // res.data.data.map((index,value) => {
      //   if(amenitiesList[res.data.data[value]['amenity_type']['amenity_type_name']]!== undefined) {
      //     amenitiesList[res.data.data[value]['amenity_type']['amenity_type_name']].push({amenity_name:res.data.data[value]['amenity_name'],amenity_slug:res.data.data[value]['slug'],checked:false});
      //   } 
      //   else{
      //     amenitiesList[res.data.data[value]['amenity_type']['amenity_type_name']]=[{amenity_name:res.data.data[value]['amenity_name'],amenity_slug:res.data.data[value]['slug'],checked:false}];
      //   }
      // })

    }
    // if (amenitiesListTotal > 0) {
    //   amenitiesList.map((opt, i) => {
    //     if (opt.status_id === 1) {
    //       this.setState({ activeAmenities: 1 });
    //     }
    //   });
    // }
    this.setState({
      basicAmenityList: basicAmenityList,
      amenitiesList: amenitiesList,
      amenitiesListTotal: amenitiesListTotal,
    });

    this.getPropertyAmenitiesData();
  }

  /* to get property amenities data */
  async getPropertyAmenitiesData() {
    var propertyAmenitiesList = [];
    var propertyAmenitiesListTotal = 0;
    var selectedAmenities = [];
    var finalAmenityList = [];

    let basicAmenityList = this.state.basicAmenityList;

    let res = await AmenityService.getPropertyAmenities(
      this.props.listing_slug + "/2"
    );
    if (global.successStatus.includes(res.status)) {
      // propertyAmenitiesList = res.data ? res.data : [];
      propertyAmenitiesListTotal = propertyAmenitiesList
        ? propertyAmenitiesList.length
        : 0;
    }
    res.data.forEach((opt) => {
      selectedAmenities.push(opt.slug);
    });

    //logic for matching amenities
    basicAmenityList.map((item, value) => {
      res.data.map((item1, j) => {
        if (item.slug === item1.slug) {
          item.checked = true;
        } else {
          if (item.checked !== true) {
            item.checked = false;
          }
        }
      })
    })

    basicAmenityList.map((index, value) => {
      if (finalAmenityList[basicAmenityList[value]['amenity_type']['amenity_type_name']] !== undefined) {
        finalAmenityList[basicAmenityList[value]['amenity_type']['amenity_type_name']].push({ amenity_name: basicAmenityList[value]['amenity_name'], amenity_slug: basicAmenityList[value]['slug'], checked: basicAmenityList[value]['checked'] });
      }
      else {
        finalAmenityList[basicAmenityList[value]['amenity_type']['amenity_type_name']] = [{ amenity_name: basicAmenityList[value]['amenity_name'], amenity_slug: basicAmenityList[value]['slug'], checked: basicAmenityList[value]['checked'] }];
      }
    })

    this.setState({
      selectedAmenities: selectedAmenities,
      amenitiesList: finalAmenityList,
      propertyAmenitiesList: propertyAmenitiesList,
      propertyAmenitiesListTotal: propertyAmenitiesListTotal,
    });
    if (
      this.state.amenitiesListTotal === this.state.selectedAmenities.length
    ) {
      this.setState({ select_all: true });
    } else {
      this.setState({ select_all: false });
    }
    // if (this.state.viewMode === 1) {
    //   this.setState({ viewMode: 1 });
    // } else {
    //   if (this.state.propertyAmenitiesListTotal > 0) {
    //     this.setState({ viewMode: 0 });
    //   } else {
    //     this.setState({ viewMode: 1 });
    //   }
    // }
  }

  /* get all amenities data */
  async AllAmenitiesData(queryString = "") {
    var propertyAmenityList = [];
    var propertyAmenityListBasic = [];
    var propertyAmenityTotal = 0;
    var bedroom = 0;
    var bedroomList = [];

    var inputData = {
      slug: this.props.listing_slug
    }

    let res = await AmenityService.getAllAmenity(inputData);
    if (global.successStatus.includes(res.status)) {
      propertyAmenityListBasic = res.data && res.data.propertyAmenity ? res.data.propertyAmenity : []
      propertyAmenityTotal = res.data.propertyAmenity ? res.data.propertyAmenity.length : 0
      bedroom = res.data.unit.floor_plan ? res.data.unit.floor_plan.bedroom : 0

      res.data.propertyAmenity.map((index, value) => {
        if (propertyAmenityList[res.data.propertyAmenity[value]['amenity_type_name']] !== undefined) {
          propertyAmenityList[res.data.propertyAmenity[value]['amenity_type_name']].push({ amenity_name: res.data.propertyAmenity[value]['amenity_name'], amenity_slug: res.data.propertyAmenity[value]['amenity_slug'], checked: false });
        }
        else {
          propertyAmenityList[res.data.propertyAmenity[value]['amenity_type_name']] = [{ amenity_name: res.data.propertyAmenity[value]['amenity_name'], amenity_slug: res.data.propertyAmenity[value]['amenity_slug'], checked: false }];
        }
      })


      if (bedroom > 0) {
        for (let i = 0; i < bedroom; i++) {
          const temp = { "name": "Bedroom " + (i + 1), "selected": [] };
          bedroomList.push(temp);
        }
      }

    }

    this.setState({
      propertyAmenityList: propertyAmenityList,
      propertyAmenityListBasic: propertyAmenityListBasic,
      propertyAmenityTotal: propertyAmenityTotal,
      bedroom: bedroom,
      bedroomList: bedroomList
    });

    this.getMasterBeds();
    this.getBeds();



    // this.getPropertyAmenitiesData();
  }

  /* to save amenities data */
  addAmenities = async (e) => {
    e.preventDefault();

    if (this.state.listing_slug || this.props.listing_slug) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        amenitiesData:
          this.state.selectedAmenities.length > 0
            ? this.state.selectedAmenities
            : [],
      };

      let res = await AmenityService.addOrUpdateAmenity(
        this.props.listing_slug + "/2",
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "success",
        //   alertModalTitle: "Success",
        //   alertModalMessage: res.message ? res.message : "Success",
        // });
        // var check = document.getElementsByName("one");
        // for (var i = 0; i < check.length; i++) {
        //   document.getElementsByName("one")[i].checked = false;
        // }
        // this.setState({ selectedAmenities: [] });

        // this.getPropertyAmenitiesData();
        this.changeView();
        this.openPublishListingModal(res.message);
        this.addUpdateBeds();
        // setTimeout(
        //   () => document.getElementById("listingPrice").click(),
        //   global.redirect_time
        // );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      // setTimeout(
      //   () => this.setState({ showAlertModal: false }),
      //   global.alert_close_time
      // );
    } else {
      this.setState({
        showSpinner: false,
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: "Please create your listing first.",
      });
      document.getElementById("infoForm").click()
      // setTimeout(
      //   () => document.getElementById("infoForm").click(),
      //   global.alert_close_time
      // );
    }
  };

  /* To amenities details data */
  async getAmenitiesDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await AmenityService.getAmenityBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.amenity_name = res.data.amenity_name
          ? res.data.amenity_name
          : "";
        resData.amenity_type_name = res.data.amenity_type
          ? res.data.amenity_type.amenity_type_name
          : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.tag_line = res.data.tag_line ? res.data.tag_line : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  confirmImportModal(slug) {
    this.getAmenitiesDetails(slug);
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event) => {
    this.setState({ select_all: "" });
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    var amenitiesList = this.state.amenitiesList;
    let newArray = [...this.state.amenitiesList];
    if (event.target.name === "select_all") {
      if (event.target.checked === true) {
        amenitiesList.forEach((item, i) => {
          amenitiesList[i].checked = true;
        });
        newArray.forEach((item, i) => {
          newArray[i] = item.slug;
        });
      } else {
        amenitiesList.forEach((item, i) => {
          amenitiesList[i].checked = false;
        });
        newArray.forEach((item, i) => {
          newArray[i] = "";
        });
      }
    }
    this.setState({ selectedAmenities: newArray });
  };

  handleCheckboxChange = (event, index) => {
    this.setState({ select_all: "" });
    let newArray = [...this.state.selectedAmenities, event.target.id];
    if (this.state.selectedAmenities.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    var amenitiesList = this.state.amenitiesList;
    amenitiesList[index].checked = event.target.checked === true ? true : false;
    this.setState({ selectedAmenities: newArray });
  };

  //checkbox change handler
  handleCheckboxChangeNew = (event, index) => {
    let amenitiesList = this.state.amenitiesList;
    let newArray = [...this.state.selectedAmenities, event.target.id];
    if (this.state.selectedAmenities.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    Object.keys(amenitiesList).map((item, o) => {
      amenitiesList[item].map((options) => {
        if (event.target.id === options.amenity_slug) {
          options.checked = event.target.checked === true ? true : false;
        }
      });
    })
    this.setState({ amenitiesList: amenitiesList });
    this.setState({ selectedAmenities: newArray });
  }

  handleCheckboxChangeSelectAll = (event) => {
    if (event.target.name === "select_all") {
      if (event.target.checked === true) {
        this.setState({ select_all: true });
      } else {
        this.setState({ select_all: false });
      }
    }
    let amenitiesList = this.state.amenitiesList;
    let newArray = [...this.state.selectedAmenities];

    if (this.state.selectedAmenities.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }

    Object.keys(amenitiesList).map((item, o) => {
      amenitiesList[item].map((options) => {
        options.checked = event.target.checked === true ? true : false;
        if (event.target.name === "select_all") {
          if (event.target.checked === true) {
            newArray.push(options.amenity_slug);
          } else {
            newArray = [""];
          }
        }
      });
    })

    this.setState({ amenitiesList: amenitiesList });
    this.setState({ selectedAmenities: newArray });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }

  changeView() {
    // var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ selectedAmenities: [] });
      this.setState({ slugUncheckList: [] });
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
      // this.getPropertyAmenitiesData();
      this.AllAmenitiesData();
      this.getBeds();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
      this.getAmenitiesData();
      // this.AllAmenitiesData();
      // this.getPropertyAmenitiesData();
    }
  }

  openPublishListingModal(message) {
    this.setState({ publishListingModal: true, publishMessage: message });
  }

  closePublishListingModal() {
    this.setState({ publishListingModal: false, publishMessage: "" });
  }

  async publishListing() {
    this.setState({ publishData: true });
    let inputData = {
      data: {
        listing_slug: this.props.listing_slug ? this.props.listing_slug : "",
        property_slug: this.props.property_slug ? this.props.property_slug : "",
        unit_slug: this.props.unit_slug ? this.props.unit_slug : "",
        floor_plan_slug: this.props.floor_plan_slug ? this.props.floor_plan_slug : "",
      }
    };
    let res = await ListingService.publishListing(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );

    }
    this.closePublishListingModal();
    this.setState({ publishData: false });
  }

  handleChangeBedList(value, i) {
    let bedroomvalue = [...this.state.bedroomList];
    bedroomvalue[i]['selected'] = [];
    if (value !== null) {
      bedroomvalue[i]['selected'] = value;
    }
    this.setState({ bedroomList: bedroomvalue });
  }

  addUpdateBeds = async (e) => {
    if (this.state.listing_slug || this.props.listing_slug) {
      let inputData = {
        listing_slug: this.props.listing_slug,
        beds: this.state.bedroomList
      };

      await AmenityService.addOrUpdateBeds(
        inputData
      );
    }
  };

  async getBeds() {
    let res = await AmenityService.getBeds(this.props.listing_slug);
    let stateArray = this.state.bedroomList;

    if (global.successStatus.includes(res.status)) {
      if (res.data.length > 0) {
        for (let i = 0; i < res.data.length; i++) {
          var tempArray = [];
          var ids = res.data[i].amenity_ids;
          var names = res.data[i].amenity_name;
          var array = ids.split(',');
          var name_array = names.split(',');
          for (let j = 0; j < array.length; j++) {
            const temp = { "value": array[j], "label": name_array[j] };
            tempArray.push(temp);
          }
          var index = parseInt(res.data[i].bedroom_no) - 1;
          stateArray[index]['selected'] = tempArray;
        }
      }
    }

    this.setState({
      bedroomList: stateArray
    });

  }

  async getMasterBeds() {
    let res = await AmenityService.getMasterBeds();

    if (global.successStatus.includes(res.status)) {
      if (res.data.length > 0) {
        this.setState({
          bedroomOptions: res.data
        });
      }
    }


  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,

        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <div className="d-flex flex-wrap justify-content-end bothBtn swiperBtns">
            {this.state.viewMode === 0 && global.userPermissions.checkPermission('single-unit-update') ? (
              <button
                className="btn-success-outline-small mr-3"
                onClick={this.changeView}
              >
                <img src={editIcon} alt="Edit" />
                {this.state.propertyAmenitiesListTotal > 0 ? "Edit" : "Edit"}
              </button>
            ) : (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn-success-outline-small mg-r-20"
                  onClick={this.changeView}
                >
                  Cancel
                </button>
                {global.userPermissions.checkPermission('single-unit-update') &&
                  <button
                    className="btn-success-outline-small mr-3"
                    onClick={this.addAmenities}
                  >
                    Save{" "}
                  </button>
                }
              </div>
            )}
          </div>
          <div className="col-md-12 pd-l-30 pd-r-30 mg-t-15">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          {this.state.viewMode === 0 ?
            this.state.propertyAmenityTotal > 0 ?
              <React.Fragment>
                <div className="m-0">
                  {this.state.bedroomList && this.state.bedroomList.length > 0 ?
                    <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                      <h3 className="mg-l-0 mg-t-15 background-head d-flex justify-content-between align-items-center">
                        Beds
                      </h3>
                      <div className="aminityListView pd-l-10 pd-r-10 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-0">
                        <ul className="attraction">
                          {(this.state.bedroomList).map((k, v) => {
                            var str = '';
                            if (k.selected.length > 0) {
                              (k.selected).map((k1, v1) => {
                                str += str !== '' ? ', ' + k1.label : k1.label;
                              })
                            }
                            str = str === '' ? 'No Bed' : str;
                            return (
                              <li className="m-2" key={k}>{k.name} {'- ' + str}</li>
                            );
                          }
                          )}
                        </ul>
                      </div>
                    </div>
                    : ''}
                  {this.state.propertyAmenityTotal > 0
                    ?
                    Object.keys(this.state.propertyAmenityList).map(
                      (opt, i) => {
                        return (
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30" key={"amenity_type_" + i}>
                            <h3 className="mg-l-0 mg-t-15 background-head d-flex justify-content-between align-items-center">
                              {opt}
                            </h3>
                            <div className="aminityListView pd-l-10 pd-r-10 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-0">
                              <ul className="attraction">
                                {(this.state.propertyAmenityList[opt]).map((k, v) => {
                                  return (
                                    <li className="m-2" key={"amenity_" + v}>{k.amenity_name}</li>
                                  );
                                }
                                )}
                              </ul>
                            </div>
                          </div>
                        )
                      }
                    )
                    : ""}
                </div>
              </React.Fragment>
              :
              <NoData msg={global.commonMessages.checkMessage("LISTING_AMENITIES")} action=" Edit " />
            : ""}

          {this.state.viewMode === 1 ? (
            <div className="m-0">
              {/* For Beds Start */}

              {this.state.bedroomList.length > 0
                ?
                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                  <h3 className="mg-l-0 mg-t-15 background-head d-flex justify-content-between align-items-center">
                    Beds
                  </h3>
                  <div className="row align-items-center border-bottom-0">
                    {this.state.bedroomList.map((item, i) => {
                      return (
                        <>
                          {/* </div> */}
                          <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 mg-md-t-0 mg-b-15" key={i}>
                            <label className="form-label text-left pd-b-5 mg-r-10">{item.name}</label>
                            <Select
                              //isMulti
                              styles={customStyles}
                              //isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={
                                item.selected
                              }
                              options={this.state.bedroomOptions}
                              getOptionValue={(option) =>
                                `${option.label}`
                              }
                              onChange={(value) =>
                                this.handleChangeBedList(
                                  value, i
                                )
                              }
                              defaultValue={
                                item.selected
                              }
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>

                  <div className="background-head"></div>


                </div>

                : ""}

              {/* For Beds End*/}
              <div className="ckbox form-check mg-l-30 pd-b-15">
                <label htmlFor="select_all" className="form-label text-left pd-b-5 mg-l-20 mg-r-10">
                  <b>Select All</b>
                </label>
                <input
                  name="select_all"
                  type={"checkbox"}
                  id={`select_all`}
                  onChange={(e) => this.handleCheckboxChangeSelectAll(e)}
                  className="form-check-input"
                  checked={this.state.select_all}
                />
                <label
                  title=""
                  htmlFor="select_all"
                  className="form-check-label"
                ></label>
              </div>
              {this.state.amenitiesListTotal > 0
                ? Object.keys(this.state.amenitiesList).map((item, o) => {
                  // if (item.status_id === 1) {
                  return (
                    <>
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30" key={"amenity_type_" + o}>
                        <h3 className="mg-l-0 background-head d-flex justify-content-between align-items-center">
                          {item}
                        </h3>
                      </div>
                      <div className="row align-items-center pd-b-30 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-0 pd-r-0 pd-r-40 mg-b-0">
                          <div className="aminityList">
                            {this.state.amenitiesListTotal > 0 ? (
                              <ul className="formcheck-section mg-b-0 flex-wrap">
                                {this.state.amenitiesListTotal > 0
                                  ? this.state.amenitiesList[item].map((item1, i) => {
                                    var check_res = item1.checked === true ? "checked" : "";
                                    // if (item.status_id === 1) {
                                    return (
                                      <React.Fragment key={"amenity_" + item1 + "_" + i}>
                                        <li>
                                          <div className="ckbox form-check">
                                            <label htmlFor={item1.amenity_slug} className="form-label text-left mg-l-20 pd-b-5">
                                              {item1.amenity_name}
                                            </label>
                                            <input
                                              name="affordable_component_check"
                                              type="checkbox"
                                              id={item1.amenity_slug}
                                              className="form-check-input"
                                              onChange={(e) =>
                                                this.handleCheckboxChangeNew(e, item)
                                              }
                                              checked={check_res}
                                            />

                                            <label
                                              title=""
                                              htmlFor={item1.amenity_slug}
                                              className="form-check-label"
                                            ></label>
                                          </div>
                                        </li>
                                      </React.Fragment>
                                    );
                                    // }
                                  })
                                  : ""}
                              </ul>
                            ) : (
                              <h6 className="mg-t-20 d-block w-100 text-center">
                                There are no listing amenities added in the system.
                              </h6>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                  // }
                })
                : ""}
            </div>
          ) : (
            ""
          )}
        </div>

        <Modal
          show={this.state.publishListingModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closedeleteListingModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closePublishListingModal()}
              >
                {global.closee}
              </span>
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              {/* <h3>Publish to OTA?</h3> */}
              <h3></h3>
              <p>
                {this.state.publishMessage ? this.state.publishMessage : "Listing is successfully updated! Your changes are not published to OTA yet, do you want to publish now ? You can still publish from Sync tab later."}
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block button-width"
                  onClick={() => this.closePublishListingModal()}
                >
                  Sync Later
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block button-width"
                  onClick={this.publishListing}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Sync Now"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ListingAmenities;
////////////////////////////////////////////////////////////
//     							                                      //
//  Program: KBSubCategoryService.js                        //
//  Application: KBSubCategory                             //
//  Option: Used for manage KBSubCategory                  //
//  Developer: Saurabh Shukla  						                  //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class KBSubCategoryService {
  getKBSubCategory(queryString) {
    let endPoint = "kb-sub-category" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createKBSubCategory(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "kb-sub-category";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getKBSubCategoryBySlug(slug) {
    let endPoint = "kb-sub-category/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateKBSubCategory(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "kb-sub-category/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteKBSubCategory(slug) {
    let endPoint = "kb-sub-category/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new KBSubCategoryService();

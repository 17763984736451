//     							                          //
//  Program: RUSidebar.jsx                                //
//  Application: Property                                 //
//  Option: common component Rental United Sidebar TAbs   //
//  Developer: NP 		                                  //
//  Date: 2022-09-13                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";

class RUSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.remove("d-none");
        document.querySelector(".newSubmenu").classList.remove("d-none");
      } else {
        data.classList.replace("left", "hide");
        document.querySelector("body").classList.toggle("az-header-menu-show");
        document.querySelector(".mobchildMenu").classList.add("d-none");
        document.querySelector(".newSubmenu").classList.add("d-none");
      }
    }
  }

  toggleChildMenu(e) {
    e.preventDefault();
    document.querySelector(".mobchildMenu").classList.toggle("d-none");
    document.querySelector(".newSubmenu").classList.toggle("d-none");
    const data = document.querySelector(".col-md-2");
    if (data != null) {
      if (data.classList.contains("hide")) {
        data.classList.replace("hide", "left");
      } else {
        data.classList.replace("left", "hide");
      }
    }
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <div
            className="backtosubMenu"
            onClick={(event) => this.toggleChildMenu(event)}
          >
            <i className="fas fa-chevron-left"></i> Back to Integrations
          </div>
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              {/* <span className="mg-b-20 mg-l-15">Rental United</span> */}
              <ul>
                <li>
                  <span>Mapping Data</span>
                  <ul>
                    <li>
                      <Link
                        className={
                          this.isPathActive("ru-lookups")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/ru-lookups"}
                      >
                        Mapping
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li>
                    <span>Master Data</span>
                    <ul>
                    {global.userPermissions.checkPermission('ru-integration-list') ? <>
                        <li>
                        <Link
                            className={
                                this.isPathActive("ru-property-data")
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/ru-property-data"}
                        >
                            Property
                        </Link>
                        </li>
                        <li>
                        <Link
                            className={
                                this.isPathActive("ru-unit-data")
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/ru-unit-data"}
                        >
                            Unit
                        </Link>
                        </li>
                        <li>
                        <Link
                            className={
                                this.isPathActive("ru-listing-data")
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/ru-listing-data"}
                        >
                            Listing
                        </Link>
                        </li>
                        <li>
                        <Link
                            className={
                                this.isPathActive("ru-listing-license-data")
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/ru-listing-license-data"}
                        >
                            Listing License
                        </Link>
                        </li>
                    </> : '' }
                    </ul>
                  </li> */}

                {/* <li>
                    <span>Other Data</span>
                    <ul>
                        <li>
                        <Link
                            className={
                                this.isPathActive("ru-other-data")
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/ru-other-data"}
                        >
                            Others
                        </Link>
                        </li>
                    </ul>
                  </li> */}
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            {/* New submenu */}
            <div className="newSubmenu">
              {/* <a href="/" className="back subMenu"> <i className="fas fa-chevron-left"></i> Listings</a> */}
              <span
                className="back"
                onClick={(event) => this.toggleHeaderMenu(event)}
              >
                <i className="fas fa-chevron-left"></i> Back to Integrations
              </span>
              <ul>
                <li>
                  <span>Mapping Data</span>
                  <ul>
                    <li>
                      <Link
                        className={
                          this.isPathActive("ru-lookups")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/ru-lookups"}
                      >
                        Mapping
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li>
                    <span>Master Data</span>
                    <ul>
                    {global.userPermissions.checkPermission('ru-integration-list') ? <>
                        <li>
                        <Link
                            className={
                                this.isPathActive("ru-property-data")
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/ru-property-data"}
                        >
                            Property
                        </Link>
                        </li>
                        <li>
                        <Link
                            className={
                                this.isPathActive("ru-unit-data")
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/ru-unit-data"}
                        >
                            Unit
                        </Link>
                        </li>
                        <li>
                        <Link
                            className={
                                this.isPathActive("ru-listing-data")
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/ru-listing-data"}
                        >
                            Listing
                        </Link>
                        </li>
                        <li>
                        <Link
                            className={
                                this.isPathActive("ru-listing-license-data")
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/ru-listing-license-data"}
                        >
                            Listing License
                        </Link>
                        </li>
                    </> : '' }
                    </ul>
                  </li> */}

                {/* <li>
                    <span>Other Data</span>
                    <ul>
                        <li>
                        <Link
                            className={
                                this.isPathActive("ru-other-data")
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/ru-other-data"}
                        >
                            Others
                        </Link>
                        </li>
                    </ul>
                  </li> */}
              </ul>
            </div>

            {/* <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <span>Sub Menu</span>
                                <i className="fas fa-chevron-down"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="tx-13">
                                <aside>
                                    <nav className="nav nav-pills flex-column pd-r-8">

                                        <ul>
                                            <li>
                                                <span>Mapping Data</span>
                                                <ul>
                                                    <li>
                                                        <Link
                                                            className={
                                                                this.isPathActive("ru-lookups")
                                                                    ? "nav-link active"
                                                                    : "nav-link"
                                                            }
                                                            data-toggle="tab"
                                                            to={"/ru-lookups"}
                                                        >
                                                            Mapping
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li>
                            <span>Master Data</span>
                            <ul>
                            {global.userPermissions.checkPermission('ru-integration-list') ? <>
                                <li>
                                <Link
                                    className={
                                        this.isPathActive("ru-property-data")
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                    data-toggle="tab"
                                    to={"/ru-property-data"}
                                >
                                    Property
                                </Link>
                                </li>
                                <li>
                                <Link
                                    className={
                                        this.isPathActive("ru-unit-data")
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                    data-toggle="tab"
                                    to={"/ru-unit-data"}
                                >
                                    Unit
                                </Link>
                                </li>
                                <li>
                                <Link
                                    className={
                                        this.isPathActive("ru-listing-data")
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                    data-toggle="tab"
                                    to={"/ru-listing-data"}
                                >
                                    Listing
                                </Link>
                                </li>
                                <li>
                                <Link
                                    className={
                                        this.isPathActive("ru-listing-license-data")
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                    data-toggle="tab"
                                    to={"/ru-listing-license-data"}
                                >
                                    Listing License
                                </Link>
                                </li>
                            </> : '' }
                            </ul>
                        </li>

                                            <li>
                            <span>Other Data</span>
                            <ul>
                                <li>
                                <Link
                                    className={
                                        this.isPathActive("ru-other-data")
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                    data-toggle="tab"
                                    to={"/ru-other-data"}
                                >
                                    Others
                                </Link>
                                </li>
                            </ul>
                        </li>
                                        </ul>
                                    </nav>
                                </aside>
                            </Dropdown.Menu>
                        </Dropdown> */}
          </div>
        </div>
      </>
    );
  }
}
export default RUSidebar;
import { Component } from "react";

class FileValidations extends Component {
  checkAllowFiles = async (actualFileType, validationRules) => {
    let allowedFileType = validationRules.allowedType.split(",");
    if (!allowedFileType.includes(actualFileType)) {
      const response = {
        status: false,
        message:
          validationRules.fieldName +
          " should only be " +
          validationRules.allowedType +
          ".",
      };
      return response;
    } else {
      const response = {
        status: true,
        message: "",
      };
      return response;
    }
  };

  checkFileSize = async (actualFileSize, validationRules) => {
    const k = 1000;

    let fileSizeType = validationRules.maxFileSizeType;
    let allowedFileSize = validationRules.maxFileSize;
    fileSizeType = fileSizeType.toLowerCase();

    if (fileSizeType === "mb") {
      allowedFileSize = k * k * allowedFileSize;
    } else if (fileSizeType === "kb") {
      allowedFileSize = k * allowedFileSize;
    }

    if (actualFileSize > allowedFileSize) {
      const response = {
        status: false,
        message:
          "Image should not be greater than " +
          validationRules.maxFileSize +
          " " +
          fileSizeType +
          ".",
      };
      return response;
    } else {
      const response = {
        status: true,
        message: "",
      };
      return response;
    }
  };

  checkValidation = async (fileObject, validationRules) => {
    let finalResponse = [];
    let validationStatus = true;
    let validationMessage = [];

    const fileSizeResponse = await this.checkFileSize(
      fileObject.size,
      validationRules
    );
    if (!fileSizeResponse.status) {
      validationStatus = false;
      validationMessage.push(fileSizeResponse.message);
    }

    var type = fileObject.type
      ? fileObject.type
      : fileObject.name.split(".")[1];
    const allowFileResponse = await this.checkAllowFiles(type, validationRules);
    if (!allowFileResponse.status) {
      validationStatus = false;
      validationMessage.push(allowFileResponse.message);
    }

    finalResponse = {
      validationStatus: validationStatus,
      validationMessage: validationMessage,
    };
    return finalResponse;
  };
}

export default FileValidations;

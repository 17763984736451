////////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertyOwnerForm.jsx                        //
//  Application: Property                                 //
//  Option: to view all Property Owners                   //
//  Developer: NP  						                  //
//  Date: 2022-04-02                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Modal, Form, Container, InputGroup, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import editIcon from "./../../assets/images/edit-pen-icon.svg";
import PropertyService from "../../services/PropertyService";
import pluswIcon from "./../../assets/images/plus.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import Select from "react-select";
import PropertySidebar from "../common/PropertySidebar";
// import NumberFormat from "react-number-format";

export class PropertyOwnerForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      // propertySlug: this.state.property_slug,
      viewMode: 1,
      ownerList: [],
      ownerListTotal: 0,
      addOwnerModal: false,
      editOwnerModal: false,
      deleteOwnerModal: false,
      showFilter: 0,
      isSubmit: false,
      input: {},
      ismodal: false,
      errors: {},
      confirm_back: 0,
      ownerTypeList: [],
      newOwnerTypeList: [],
      ownerParentDataTotal: 1,
      selectedOwnerTypeList: [],
      Ownerlist: [],
      ownerParentData: [],
      // owner_parent_data: [],
      // ownerParentData: [
      //   {
      //     owner_name: "",
      //     amount: "",
      //     ownership: "",
      //     notes: "",
      //   },
      // ],
      property_slug: this.props.match.params.propertySlug,
      owner_slug: this.props.match.params.ownerSlug,
    };
    this.changeView = this.changeView.bind(this);
    this.openaddOwnerModal = this.openaddOwnerModal.bind(this);
    this.openeditOwnerModal = this.openeditOwnerModal.bind(this);
    this.opendeleteOwnerModal = this.opendeleteOwnerModal.bind(this);
    this.saveOrUpdateOwner = this.saveOrUpdateOwner.bind(this);
    this.getOwnerDetails = this.getOwnerDetails.bind(this);
    this.deleteOwner = this.deleteOwner.bind(this);
    this.getOwnerData = this.getOwnerData.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };
  componentDidMount() {
    if (this.state.owner_slug) {
      this.getOwnerDetails(this.state.owner_slug);
    }
    this.setState({ confirm_back: 0 });
    this.getOwnerTypeList();
  }

  async getOwnerTypeList() {
    const ownerTypeList = [
      { value: 1, label: "GP" },
      { value: 2, label: "LP" },
    ];
    this.setState({ ownerTypeList: ownerTypeList });
  }

  async getOwnerData(queryString = "") {
    //this.setState({ showSpinner: true, loading: true });
    var ownerList = [];
    var ownerListTotal = 0;

    let res = await PropertyService.getPropertyOwner(
      "property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      ownerList = res.data.data ? res.data.data : [];
      ownerListTotal = ownerList ? ownerList.length : 0;
    }
    this.setState({
      ownerList: ownerList,
      ownerListTotal: ownerListTotal,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  /* to save ad agency data */
  saveOrUpdateOwner = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug,
        owner_type: this.state.owner_type,
        owner_name: this.state.owner_name,
        ein_ssn: this.state.ein_ssn,
        amount: this.state.amount,
        ownership: this.state.ownership,
        email: this.state.email,
        phone: this.state.phone,
        notes: this.state.notes,
        // owner_parent_data: this.state.owner_parent_data,
        owner_parent_data: this.state.ownerParentData,
      };
      if (this.state.owner_slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await PropertyService.updatePropertyOwner(
          this.state.owner_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/owners"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await PropertyService.createPropertyOwner(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/owners"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get ad agency details data */
  async getOwnerDetails(slug) {
    //this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      var ownerParentDataTotal = 1;
      let res = await PropertyService.getOwnerInfoBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData.owner_type = res.data.owner_type ? res.data.owner_type : "";
        var selectedOwnerTypeList = [];
        selectedOwnerTypeList =
          res.data.owner_type === 1
            ? {
              value: 1,
              label: "GP",
            }
            : {
              value: 2,
              label: "LP",
            };
        resData.owner_name = res.data.owner_name ? res.data.owner_name : "";
        resData.ein_ssn = res.data.ein_ssn ? res.data.ein_ssn : "";
        resData.amount = res.data.amount ? res.data.amount : "";
        resData.ownership = res.data.ownership ? res.data.ownership : "";
        resData.email = res.data.email ? res.data.email : "";
        resData.phone = res.data.phone ? res.data.phone : "";
        resData.notes = res.data.notes ? res.data.notes : "";

        resData.ownerParentData = res.data.ownerParentData
          ? res.data.ownerParentData
          : '';
        const values = [];
        if (resData.ownerParentData.length > 0) {
          resData.ownerParentData.forEach((item, i) => {
            values.push({
              owner_name: item.owner_name,
              amount: item.amount,
              ownership: item.ownership,
              notes: item.notes,
              slug: item.slug ? item.slug : "",
              selected_owner_parent_data: {
                value: item.owner_type,
                label: item.owner_type === 1 ? "GP" : "LP",
              },
            });
          });
        }
        else {
          // values.push({
          //   owner_name: "",
          //   amount: "",
          //   ownership: "",
          //   notes: "",
          // });
          resData.ownerParentData = [
            {
              owner_name: "",
              amount: "",
              ownership: "",
              notes: "",
            },
          ];
        }

        ownerParentDataTotal = values.length;
        this.setState({ selectedOwnerTypeList: selectedOwnerTypeList });
        this.setState({
          // owner_parent_data: values,
          ownerParentData: values,
          ownerParentDataTotal: ownerParentDataTotal,
        });
      }
      this.setState(resData);
    }
    // this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to delete ad agency data */
  async deleteOwner(slug) {
    this.setState({ showSpinner: true });
    if (slug) {
      let res = await PropertyService.deletePropertyOwner(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closedeleteOwnerModal();
        this.getOwnerDetails(this.state.owner_slug);
        //  
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.errorMessage
            ? res.data.errorMessage
            : res.data.message,
        });
      }
      this.setState({ showSpinner: false });
      this.closedeleteOwnerModal();
    }
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  openaddOwnerModal() {
    this.setState({ addOwnerModal: true });
    this.setState({ confirm_back: 0 });
  }

  closeaddOwnerModal() {
    this.setState({ addOwnerModal: false });
    this.setState({
      owner_type: "",
      owner_name: "",
      ein_ssn: "",
      amount: "",
      ownership: "",
      email: "",
      phone: "",
      notes: "",
    });
    this.validator.hideMessages();
    this.setState({ confirm_back: 0 });
  }

  openeditOwnerModal(slug) {
    this.getOwnerDetails(slug);
    this.setState({ editOwnerModal: true });
    this.setState({ slug: slug });
    this.setState({ confirm_back: 0 });
  }

  closeeditOwnerModal() {
    this.setState({ editOwnerModal: false });
    this.setState({
      owner_type: "",
      owner_name: "",
      ein_ssn: "",
      amount: "",
      ownership: "",
      email: "",
      phone: "",
      notes: "",
    });
    this.validator.hideMessages();
    this.setState({ confirm_back: 0 });
  }

  opendeleteOwnerModal(slug) {
    this.setState({ deleteOwnerModal: true });
    this.setState({ slug: slug });
    this.setState({ confirm_back: 0 });
  }

  closedeleteOwnerModal() {
    this.setState({ deleteOwnerModal: false });
    this.setState({ slug: "" });
    this.setState({ confirm_back: 0 });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.setState({ confirm_back: 1 });
    // var confirmBack = 1;
    // this.props.confirmBackStatus(confirmBack);
  };

  handleChangeOwnerType(value) {
    let owner_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        owner_type.push(item.value);
      });
    }
    const owner_type_value = value !== null ? value.value : [];
    this.setState({
      selectedOwnerTypeList: value,
      owner_type: owner_type_value,
    });
    this.setState({ confirm_back: 1 });
    // var confirmBack = 1;
    // this.props.confirmBackStatus(confirmBack);
  }

  handleChangeselect = (index, event) => {
    const values = [...this.state.ownerParentData];
    values[index].selectedOwnerTypeList = event.value;
    values[index].owner_type = event.value;
    values[index].selected_owner_parent_data = event;

    // values[index].event.target.name = event.target.value;
    this.setState({ ownerParentData: values });
    // this.setState({ owner_parent_data: values });

    this.setState({ confirm_back: 1 });
  };

  handleChange = (index, event) => {
    const values = [...this.state.ownerParentData];

    if (event.target.name === "owner_name") {
      values[index].owner_name = event.target.value;
      this.setState({ ownerParentData: values });
    } else if (event.target.name === "amount") {
      values[index].amount = event.target.value;
    } else if (event.target.name === "ownership") {
      values[index].ownership = event.target.value;
    } else if (event.target.name === "notes") {
      values[index].notes = event.target.value;
    }
    this.setState({ ownerParentData: values });
    // this.setState({ owner_parent_data: values, ownerParentData: values });
    this.setState({ confirm_back: 1 });
  };

  handleAddFields = () => {
    const values = [...this.state.ownerParentData];
    var ownerParentDataTotal = 1;
    values.push({
      owner_type: "",
      owner_name: "",
      amount: "",
      ownership: "",
      notes: "",
    });
    ownerParentDataTotal = values.length;

    this.setState({
      ownerParentData: values,
      ownerParentDataTotal: ownerParentDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFields = (i) => {
    const values = [...this.state.ownerParentData];
    values.splice(i, 1);
    var ownerParentDataTotal = 0;
    ownerParentDataTotal = values.length;
    // this.setState({ ownerParentData: values, influencer_data: values, ownerParentDataTotal: ownerParentDataTotal, confirm_back: 1 });
    this.setState({ ownerParentData: values });
    // this.setState({ ownerParentData: values, owner_parent_data: values });
    this.setState({
      ownerParentDataTotal: ownerParentDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    // var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
      this.getOwnerData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      // this.props.confirmBackStatus(confirmBack);
      this.getOwnerData();
    }
  }

  getTitle() {
    if (this.state.owner_slug !== undefined) return "Owners";
    else return "Owners";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.owner_slug !== undefined ? (
                        <>
                         {global.userPermissions.checkPermission('owners-delete') ?
                          <span
                            onClick={() => {
                              this.opendeleteOwnerModal(this.state.slug);
                            }}
                            className="deleteicon mg-r-20"
                          >
                            <img src={deleteicon} />
                          </span>
                          :''}
                        </>
                      ) : (
                        ""
                      )}

                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                this.state.property_slug +
                                "/owners"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                              this.state.property_slug +
                              "/owners"
                            );
                        }}
                      >
                        Cancel
                      </button>
                      {global.userPermissions.checkPermission('owners-update') ?
                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={this.saveOrUpdateOwner}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {/* <img src={pluswIcon} alt="" /> */}
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.owner_slug !== undefined
                            ? "Save"
                            : "Save"}
                      </button>
                    : '' }
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard  dashbaord-pageHeadertitle- background-none pt-0 pl-30 pr-0">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="row align-items-center pd-l-15 pd-r-15 pd-lg-t-30 pd-xs-t-10 pd-b-30 border-bottom-0">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Owner Type
                        </label>

                        <Select
                          styles={customStyles}
                          isClearable={true}
                          menuPlacement="auto"
                          className="multiselect"
                          classNamePrefix={"my-custom-react-select"}
                          options={this.state.ownerTypeList}
                          getOptionValue={(option) => `${option.label}`}
                          onChange={(value) =>
                            this.handleChangeOwnerType(value)
                          }
                          name="owner_type"
                          defaultValue={this.state.selectedOwnerTypeList}
                          value={this.state.selectedOwnerTypeList}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 2,
                            colors: {
                              ...theme.colors,
                              primary: "#fff",
                              primary75: "#000",
                              primary50: "#000",
                              primary25: "#000",
                            },
                          })}
                        />

                        {this.state.errors.owner_type ? (
                          <div className="text-danger">
                            {this.state.errors.owner_type}
                          </div>
                        ) : (
                          this.validator.message(
                            "owner_type",
                            this.state.owner_type,
                            "required",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Owner Name
                        </label>

                        <Form.Control
                          onChange={this.changeHandler}
                          // onChange={(event) =>
                          //           this.handleChange(i, event)
                          //         }
                          name="owner_name"
                          value={this.state.owner_name || ""}
                          className="form-control max_width_100"
                          id="inlineFormInput"
                          type="text"
                          maxLength="100"
                        />
                        {this.state.errors.owner_name ? (
                          <div className="text-danger">
                            {this.state.errors.owner_name}
                          </div>
                        ) : (
                          this.validator.message(
                            "owner_name",
                            this.state.owner_name,
                            "max:100|required",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Ein/Ssn
                        </label>

                        <Form.Control
                          onChange={this.changeHandler}
                          name="ein_ssn"
                          value={this.state.ein_ssn || ""}
                          className="form-control max_width_100"
                          onKeyPress={global.onKeyPressEvent.numberValidation}
                          id="inlineFormInput"
                          type="text"
                          maxLength="10"
                        />
                        {this.state.errors.ein_ssn ? (
                          <div className="text-danger">
                            {this.state.errors.ein_ssn}
                          </div>
                        ) : (
                          this.validator.message(
                            "ein_ssn",
                            this.state.ein_ssn,
                            "max:100|required",
                            { className: "text-danger" }
                          )
                        )}
                      </div> */}
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Amount
                        </label>
                        <div className="input-group-prepend prefix-dollar">
                              <span className="input-group-text">$</span>
                        <Form.Control
                          onChange={this.changeHandler}
                          name="amount"
                          value={this.state.amount ? (this.state.amount.replace(/\.00$/, "").replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")) : "" || ""}
                          onKeyPress={global.onKeyPressEvent.floatValidation}
                          className="form-control max_width_100"
                          id="inlineFormInput"
                          type="text"
                          maxLength="10"
                        />
                          </div>
                        {this.state.errors.amount ? (
                          <div className="text-danger">
                            {this.state.errors.amount}
                          </div>
                        ) : (
                          this.validator.message(
                            "amount",
                            this.state.amount,
                            "max:100|required",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Ownership
                        </label>
                        <div className="input-group-prepend prefix-dollar percanteg-prefix">
                              <span className="input-group-text percanteg_symbl">%</span>
                        <Form.Control
                          onChange={this.changeHandler}
                          name="ownership"
                          value={this.state.ownership || ""}
                          onKeyPress={global.onKeyPressEvent.floatValidation}
                          className="form-control max_width_100"
                          id="inlineFormInput"
                          type="text"
                          maxLength="10"
                        />
                        </div>
                        {this.state.errors.ownership ? (
                          <div className="text-danger">
                            {this.state.errors.ownership}
                          </div>
                        ) : (
                          this.validator.message(
                            "ownership",
                            this.state.ownership,
                            "max:100|required",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Email
                        </label>

                        <Form.Control
                          onChange={this.changeHandler}
                          name="email"
                          value={this.state.email || ""}
                          className="form-control max_width_100"
                          id="inlineFormInput"
                          type="text"
                          maxLength="100"
                        />
                        {this.state.errors.email ? (
                          <div className="text-danger">
                            {this.state.errors.email}
                          </div>
                        ) : (
                          this.validator.message(
                            "email",
                            this.state.email,
                            "max:100|required",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Phone
                        </label>

                        <Form.Control
                          onChange={this.changeHandler}
                          name="phone"
                          value={this.state.phone || ""}
                          onKeyPress={global.onKeyPressEvent.numberValidation}
                          className="form-control max_width_100"
                          id="inlineFormInput"
                          type="text"
                          maxLength="11"
                        />
                        {this.state.errors.phone ? (
                          <div className="text-danger">
                            {this.state.errors.phone}
                          </div>
                        ) : (
                          this.validator.message(
                            "phone",
                            this.state.phone,
                            "max:100|required",
                            { className: "text-danger" }
                          )
                        )}
                      </div> */}
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Notes
                        <span className="optional">(Optional)</span>
                        </label>
                        <Form.Control
                          onChange={this.changeHandler}
                          name="notes"
                          value={this.state.notes || ""}
                          className="form-control max_width_100"
                          id="inlineFormInput"
                          type="text"
                          maxLength="100"
                        />
                        {/* {this.state.errors.notes ? (
                          <div className="text-danger">
                            {this.state.errors.notes}
                          </div>
                        ) : (
                          this.validator.message(
                            "notes",
                            this.state.notes,
                            "max:100|required",
                            { className: "text-danger" }
                          )
                        )} */}
                      </div>
                    </div>
                    {this.state.owner_slug !== undefined ? (
                      <>
                        <div className="col-lg-12 pd-lg-l-30 pd-lg-r-30pd-xs-l-15 pd-xs-r-0">
                          <h3 className="mg-t-0 mg-l-0 background-head"></h3>
                        </div>
                        <div className="m-0">
                          <div className="row align-items-center pd-l-15 pd-r-15  pd-b-20 border-bottom-0">
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-10">
                              <div className="pd-l-0 pd-r-0 pet-f mg-b-30 pet">
                                <div className="col-md-12 p-0 petFeeSection">
                                  <table className="petForm">
                                    <thead>
                                      <tr>
                                        <th className="w-1">
                                          <label className="mb-1">
                                            Owner Type
                                          </label>
                                        </th>
                                        <th className="w-2">
                                          <label className="mb-1">
                                            Owner Name
                                          </label>
                                        </th>
                                        <th className="w-2">
                                          <label className="mb-1">Amount</label>
                                        </th>
                                        <th className="w-2">
                                        
                                          <label className="mb-1">
                                            Ownership
                                          </label>
                                        </th>
                                        <th className="w-2">
                                          <label className="mb-1">Notes</label>
                                        </th>
                                        <th className="w-3"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.ownerParentData.map(
                                        (data, i) => {

                                          return (
                                            <tr key={i}>
                                              <td className="w-1">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-0 pd-r-2 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                    {/* <div className="col-md-12 p-0">
                                              <Select
                                                styles={customStyles}
                                                className="multiselect"
                                                menuPlacement="auto"
                                                autoComplete= "off"
                                                onChange={(value) =>
                                                  this.handleChangeselect(i, value)
                                                }
                                                options={this.state.newSocialMediaList.filter((option) => {
                                                  const val2 = this.state
                                                    .influencer_data
                                                    ? this.state.influencer_data.find(
                                                      (selOpt) => {
                                                        if (
                                                          selOpt.social_media_slug ===
                                                          data.value
                                                        )
                                                          return false;
                                                        return (
                                                          selOpt.social_media_slug === option.value
                                                        );
                                                      }
                                                    )
                                                    : "";
                                                  if (val2) return false;
                                                  else return true;
                                                })}

                                                getOptionValue={(option) =>
                                                  `${option.label}`
                                                }
                                                theme={(theme) => ({
                                                  ...theme,
                                                  borderRadius: 2,
                                                  colors: {
                                                    ...theme.colors,
                                                    primary: "#fff",
                                                    primary75: "#000",
                                                    primary50: "#000",
                                                    primary25: "#000",
                                                  },
                                                })}
                                                value={
                                                  data.selected_social_media
                                                    ? data.selected_social_media
                                                    : []
                                                }
                                                defaultValue={
                                                  data.selected_social_media
                                                    ? data.selected_social_media
                                                    : []
                                                }
                                              />
                                            </div> */}
                                                    <div className="col-md-12 p-0">
                                                      <Select
                                                        styles={customStyles}
                                                        isClearable={true}
                                                        menuPlacement="auto"
                                                        className="multiselect"
                                                        classNamePrefix={"my-custom-react-select"}
                                                        options={
                                                          this.state
                                                            .ownerTypeList
                                                        }
                                                        getOptionValue={(
                                                          option
                                                        ) => `${option.label}`}
                                                        onChange={(value) =>
                                                          this.handleChangeselect(
                                                            i,
                                                            value
                                                          )
                                                        }
                                                        defaultValue={
                                                          data.selected_owner_parent_data
                                                        }
                                                        value={
                                                          data.selected_owner_parent_data
                                                        }
                                                        theme={(theme) => ({
                                                          ...theme,
                                                          borderRadius: 2,
                                                          colors: {
                                                            ...theme.colors,
                                                            primary: "#fff",
                                                            primary75: "#000",
                                                            primary50: "#000",
                                                            primary25: "#000",
                                                          },
                                                        })}
                                                      />
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-1">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-2 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                    <Form.Control
                                                      name="owner_name"
                                                      title={
                                                        data.owner_name
                                                          ? data.owner_name ||
                                                          ""
                                                          : ""
                                                      }
                                                      value={
                                                        data.owner_name
                                                          ? data.owner_name ||
                                                          ""
                                                          : ""
                                                      }
                                                      autoComplete="off"
                                                      onChange={(event) =>
                                                        this.handleChange(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-1">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-2 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1 d-block">
                                                   <div className="input-group-prepend prefix-dollar w-100">
                                                  <span className="input-group-text">
                                                    $
                                                  </span>
                                                    <Form.Control
                                                      name="amount"
                                                      value={
                                                        data.amount
                                                          ? data.amount.replace(/\.00$/, "").replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "") || ""
                                                          : ""
                                                      }
                                                      autoComplete="off"
                                                      onChange={(event) =>
                                                        this.handleChange(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    />
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-1">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-2 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                  <div className="input-group-prepend percanteg-prefix w-100">
                                                 
                                                    <Form.Control
                                                      name="ownership"
                                                      value={
                                                        data.ownership
                                                          ? data.ownership || ""
                                                          : ""
                                                      }
                                                      autoComplete="off"
                                                      onChange={(event) =>
                                                        this.handleChange(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    />
                                                    
                                                    </div>
                                                  </InputGroup>
                                                </Col>
                                              </td>
                                              <td className="w-1">
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-2 colmn1"
                                                >
                                                  <InputGroup className="mt-0 m-b-1">
                                                    <Form.Control
                                                      name="notes"
                                                      value={
                                                        data.notes
                                                          ? data.notes || ""
                                                          : ""
                                                      }
                                                      autoComplete="off"
                                                      onChange={(event) =>
                                                        this.handleChange(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Col>
                                              </td>

                                              <td>
                                                <Col
                                                  xs={12}
                                                  className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                                                >
                                                  {this.state
                                                    .ownerParentDataTotal ===
                                                    1 && i === 0 ? (
                                                    <>
                                                      {data.slug ? (
                                                        <div className="d-flex  justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                          <button
                                                            type="button"
                                                            onClick={() => {
                                                              this.handleRemoveFields(
                                                                i
                                                              );
                                                              this.deleteOwner(
                                                                data.slug ? data.slug : "",
                                                              );
                                                            }}
                                                            className={
                                                              "btn-danger-outline-x-small"
                                                            }
                                                          >
                                                            <img
                                                              src={deleteicon}
                                                              alt="Delete"
                                                            />
                                                          </button>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                        <button
                                                          type="button"
                                                          onClick={
                                                            this.handleAddFields
                                                          }
                                                          className="btn-success-outline-x-small"
                                                        >
                                                          <img
                                                            src={pluswIcon}
                                                            alt="Add"
                                                          />
                                                        </button>
                                                      </div>
                                                    </>
                                                  ) : i <
                                                    this.state
                                                      .ownerParentDataTotal -
                                                    1 ? (
                                                    <>
                                                      <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                        <button
                                                          type="button"
                                                          onClick={() => {
                                                            this.handleRemoveFields(
                                                              i
                                                            );
                                                            this.deleteOwner(
                                                              data.slug ? data.slug : "",
                                                            );
                                                          }}
                                                          className={
                                                            "btn-danger-outline-x-small"
                                                          }
                                                        >
                                                          <img
                                                            src={deleteicon}
                                                            alt="Delete"
                                                          />
                                                        </button>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {this.state
                                                        .ownerParentDataTotal !==
                                                        0 ? (
                                                        <>
                                                          <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                            <button
                                                              type="button"
                                                              onClick={() => {
                                                                this.handleRemoveFields(
                                                                  i
                                                                );
                                                                this.deleteOwner(
                                                                  data.slug ? data.slug : "",
                                                                );
                                                              }}
                                                              className={
                                                                "btn-danger-outline-x-small"
                                                              }
                                                            >
                                                              <img
                                                                src={deleteicon}
                                                                alt="Delete"
                                                              />
                                                            </button>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                        <button
                                                          type="button"
                                                          onClick={
                                                            this.handleAddFields
                                                          }
                                                          className="btn-success-outline-x-small"
                                                        >
                                                          <img
                                                            src={pluswIcon}
                                                            alt="Add"
                                                          />
                                                        </button>
                                                      </div>
                                                    </>
                                                  )}
                                                </Col>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="col-md-12 petFeeSection">
                    <table
                      className="table table-hoverno-wrap mg-b-30 pd-b-2"
                      id="as-react-datatable"
                    >
                      <thead>
                        <tr>
                          <th className=" text-" width="">
                            Owner Type
                          </th>
                          <th className=" text-" width="">
                            Owner Name
                          </th>
                          <th className=" text-" width="">
                            Ein/Ssn
                          </th>
                          <th className=" text-" width="">
                            Amount
                          </th>
                          <th className=" text-" width="">
                            Ownership%
                          </th>
                          <th className=" text- text-left" width="">
                            Email
                          </th>
                          <th className=" text- text-left" width="">
                            Phone
                          </th>
                          <th className=" text- text-left" width="">
                            Notes
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ownerParentData.length > 0 ? (
                          this.state.ownerParentData.map((option, i) => (
                            <tr key={i}>
                              <td>{option.owner_type === 1 ? "GP" : "LP"}</td>
                              <td>
                                {option.owner_name ? option.owner_name : ""}
                              </td>
                              <td>{option.ein_ssn ? option.ein_ssn : ""}</td>
                              <td>{option.amount ? option.amount : ""}</td>
                              <td>
                                {option.ownership ? option.ownership : ""}
                              </td>
                              <td>{option.email ? option.email : ""}</td>
                              <td>{option.phone ? option.phone : ""}</td>
                              <td>{option.notes ? option.notes : ""}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="9" align="center">
                              There are no OwnersData added in the system. You
                              may add them by clicking Split button.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Container>

        {/* delete wifi info popup modal */}
        <Modal show={this.state.deleteOwnerModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteOwnerModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteOwnerModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteOwner(this.state.owner_slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default PropertyOwnerForm;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: TaxTypeService.js                            //
//  Application: Tax Type                                 //
//  Option: Used for manage tax type                      //
//  Developer: Ashish Kumar  						      //
//  Date: 2022-01-06                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class TaxTypeService {
  getTaxType(queryString) {
    let endPoint = "tax-type"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createTaxType(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "tax-type";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getTaxTypeBySlug(slug) {
    let endPoint = "tax-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateTaxType(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "tax-type/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteTaxType(slug) {
    let endPoint = "tax-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new TaxTypeService();

////////////////////////////////////////////////////////////
//     							                          //
//  Program: CountryService.js                            //
//  Application: Services                                 //
//  Option: Used for country list                         //
//  Developer: NP 						                  //
//  Date: 2022-01-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class CountryService {

    getCountry(queryString) {
        let endPoint = 'country' + (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    createCountry(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'country';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    getCountryBySlug(slug) {
        let endPoint = 'country/' + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateCountry(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'country/' + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.putCall(inputData);
        return response;
    }

    deleteCountry(slug) {
        let endPoint = 'country/' + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.deleteCall(inputData);
        return response;
    }

    //for google places API
    checkLocation(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'check-location';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }
    
}

export default new CountryService()

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PropertyDocumentsForm.jsx                   //
//  Application:  PropertyDocuments                      //
//  Option: For add or update PropertyDocuments         //
//  Developer: Ashish Kumar                            //
//  Date: 2022-01-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import MultiImageService from "../../services/MultiImageService";
import Alerts from "../common/Alerts";
import FileWidget from "../fileUploader/FileWidget";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DocumentTypeService from "../../services/DocumentTypeService";
import propDoc from "../../assets/images/doc_placeholder.png";
// import infosize from "../../assets/images/Info.svg";
import Select from "react-select";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import PropertySidebar from "../common/PropertySidebar";
import pdfIcon from "../../assets/images/pdf.png";
import wordIcon from "../../assets/images/word.png";
import excelIcon from "../../assets/images/excel.png";
import imgIcon from "../../assets/images/imgIcon.png";
import { Popover, OverlayTrigger } from "react-bootstrap";
import copyIcon from "./../../assets/images/copy.png";

import Moment from "moment";
export class PropertyDocumentsForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      prop_doc_slug: this.props.match.params.slug,
      isSubmit: false,
      documentTypeList: [],
      newDocumentTypeList: [],
      selectedDocumentTypeList: [],
      documentTypeListTotal: 0,
      document_type_slug: "",
      display_file_name: "",
      media_type: "",
      file_name: "",
      description: "",
      sort_order: "",
      open: true,
      eventKey: "0",
      imgUrl: propDoc,
      displayFileName: "",
      pathname: window.location.pathname,
      deletedFiles: [],
      files_data: [],
      doc_cat_slug: "",
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
    };
    this.propDoc = React.createRef();
    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdatePropertyDocuments =
      this.saveOrUpdatePropertyDocuments.bind(this);
    this.deletePropDoc = this.deletePropDoc.bind(this);
    this.opendeletePropDocModal = this.opendeletePropDocModal.bind(this);
    this.closedeletePropDocModal = this.closedeletePropDocModal.bind(this);
    // this.selected_display_file_name = this.selected_display_file_name.bind(this);
  }

  opendeletePropDocModal(slug) {
    this.setState({ deletePropDocModal: true, slug: slug });
  }

  closedeletePropDocModal() {
    this.setState({ deletePropDocModal: false, slug: "" });
  }

  async deletePropDoc(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    let inputData = {
      path: "property_document_form",
      refTableId: 3,
      slug: this.state.property_slug,
      mediaType: 2,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closedeletePropDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" +
            this.state.property_slug +
            "/property-documents"
          ),
        global.redirect_time
      );
    } else {
      this.closedeletePropDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getDocumentTypeData();
    // this.setState({ showSpinner: true, loading: true });
    this.getPropDocData();
    // this.setState({ showSpinner: false, loading: false });
  }

  async getPropDocData() {
    if (this.state.prop_doc_slug !== undefined) {
      var resData = {};
      var files_data = [];
      let inputData = {
        path: "property_document_form",
        refTableId: 3,
        slug: this.state.property_slug,
        mediaType: 2,
        docsSlug: this.state.prop_doc_slug,
        propertySlug: this.state.property_slug,
      };
      let res = await MultiImageService.getPropertyDocumentsBySlug(inputData);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.display_file_name = res.data.display_file_name
          ? res.data.display_file_name
          : "";
        resData.document_type_slug = res.data.document_type_data
          ? res.data.document_type_data.slug
          : "";
        resData.file_name = res.data.file_name ? res.data.file_name : "";
        // resData.description = res.data.description ? res.data.description : "";
        // resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        // resData.status_id = res.data.status_id === 1 ? true : false;
        var selectedDocumentTypeList = [];
        selectedDocumentTypeList = res.data.document_type_data
          ? [
            {
              value: res.data.document_type_data.slug,
              label: res.data.document_type_data.document_category_name,
            },
          ]
          : "";
        files_data = res.data.files_data ? res.data.files_data : [];
      }
      var fileExt = resData.file_name.split(".").pop();
      this.setState(resData);
      this.setState({
        selectedDocumentTypeList: selectedDocumentTypeList,
        fileExt: fileExt,
        files_data: files_data,
      });
    }
    if (this.state.files_data.length === 0 && this.state.prop_doc_slug !== undefined) {
      this.props.history.push(
        "/properties/view/" +
        this.state.property_slug +
        "/property-documents"
      );
    }
  }

  /* to get document type data */
  async getDocumentTypeData(queryString = "") {
    //   this.setState({ showSpinner: true, loading: true });
    var documentTypeList = [];
    var documentTypeListTotal = 0;

    let res = await DocumentTypeService.getDocumentType(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      documentTypeList = res.data ? res.data : [];
      documentTypeListTotal = documentTypeList ? documentTypeList.length : 0;
    }
    const newDocumentTypeList =
      documentTypeListTotal > 0
        ? documentTypeList.map(({ slug, document_category_name }) => ({
          value: slug,
          label: document_category_name,
        }))
        : [];
    this.setState({
      documentTypeList: documentTypeList,
      documentTypeListTotal: documentTypeListTotal,
      newDocumentTypeList: newDocumentTypeList,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  // selected_display_file_name(selected_display_file_name) {
  //   this.setState({ selected_file_name: selected_display_file_name });
  // }

  saveOrUpdatePropertyDocuments = async (e) => {
    e.preventDefault();
    let errors = this.state.errors;
    //var displayFileName = "";
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun(
        "property_document_form"
      );
      // if (output.response[0].filesData.length > 0) {
      //   displayFileName = output.response[0].filesData[0].orgFileName.split(".")[0];
      //   this.setState({ displayFileName: displayFileName });
      // } else {
      //   displayFileName = "";
      //   this.setState({ displayFileName: "" });
      // }
      if (output.status !== false) {
        let inputData = {
          // display_file_name: this.state.display_file_name
          // ? this.state.display_file_name
          // : this.state.displayFileName,
          display_file_name: this.state.display_file_name
            ? this.state.display_file_name
            : "",
          document_type_slug: this.state.document_type_slug
            ? this.state.document_type_slug
            : "",
          file_name: this.state.file_name ? this.state.file_name : "",
          description: this.state.description ? this.state.description : "",
          media_type: 2,
          ref_id: this.state.property_slug ? this.state.property_slug : "",
          sort_order: this.state.sort_order ? this.state.sort_order : "",
          status_id: this.state.status_id === true ? 1 : 2,
          availableFiles: output.response,
          deletedFiles: this.state.deletedFileList,
          docsSlug: this.state.prop_doc_slug ? this.state.prop_doc_slug : "",
        };
        if (this.state.prop_doc_slug !== undefined) {
          // For active status 1:Active, 2:Inactive
          inputData.status_id = this.state.status_id ? this.state.status_id : 2;

          let res = await MultiImageService.submitMultiImage(inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () =>
                this.props.history.push(
                  "/properties/view/" +
                  this.state.property_slug +
                  "/property-documents"
                ),
              global.redirect_time
            );
          } else {
            if (res.data.result.errorDetail) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
            // this.setState({
            //   showAlertModal: true,
            //   alertModalType: "error",
            //   alertModalTitle: res.data.errorCode
            //     ? res.data.errorCode
            //     : "Error!",
            //   alertModalMessage: res.data.message ? res.data.message : "Error!",
            // });
          }
        } else {
          if (output.response[0].filesData) {
            if (output.response[0].filesData.length > 0) {
              let res = await MultiImageService.submitMultiImage(inputData);
              if (global.successStatus.includes(res.status)) {
                this.setState({
                  showAlertModal: true,
                  alertModalType: "success",
                  alertModalTitle: "Success",
                  alertModalMessage: res.message ? res.message : "Success",
                });
                setTimeout(
                  () =>
                    this.props.history.push(
                      "/properties/view/" +
                      this.state.property_slug +
                      "/property-documents"
                    ),
                  global.redirect_time
                );
              } else {
                if (res.data.result.errorDetail) {
                  let errors = {};
                  res.data.errorCode = "Validation Failed";
                  res.data.result.errorDetail.forEach((item) => {
                    errors[item.errorField] = item.errorMessage[0];
                    res.data.errorMessage = "";
                  });
                  this.setState({ errors: errors });
                }
                // this.setState({
                //   showAlertModal: true,
                //   alertModalType: "error",
                //   alertModalTitle: res.data.errorCode
                //     ? res.data.errorCode
                //     : "Error!",
                //   alertModalMessage: res.data.message
                //     ? res.data.message
                //     : "Error!",
                // });
              }
            } else {
              errors["document"] = "Please select recommended file size.";
              this.setState({ errors: errors });
            }
          }
        }
      } else {
        let checkError = output.response[0].validationMessage[0];

        if (checkError[0].includes("30 mb") === true) {
          errors["document"] = "The file should not be greater than 30 mb.";
          this.setState({ errors: errors });
        } else {
          errors["document"] =
            "The file should be pdf, doc, docx, xls, xlsx, csv, png, jpg.";
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    this.setState({ selected_file_name: "" });
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  handleChangeDocumentTypeList(value) {
    let document_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        document_type_slug.push(item.value);
      });
    }
    const document_type_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedDocumentTypeList: value,
      document_type_slug: document_type_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  async getDownloadFile(url, name) {
    let file_name =  name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.description !== undefined &&
      input.description !== "" &&
      input.description !== null &&
      input.description.length > 100000
    ) {
      isValid = false;
      errors["description"] =
        "The document description character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  openDeleteDocModal(slug) {
    this.setState({ deleteDocModal: true, delete_slug: slug });
  }

  closeDeleteDocModal() {
    this.setState({ deleteDocModal: false });
  }

  async deleteDoc(slug) {
    this.setState({ showSpinner: true });
    let inputData = {
      path: "property_document_form",
      refTableId: 3,
      slug: this.state.property_slug,
      mediaType: 2,
      availableFiles: [{}],
      deletedFiles: [slug],
    };
    let res = await MultiImageService.submitMultiImage(inputData);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getPropDocData();
    } else {
      this.closeDeleteDocModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false, dragOff: 1 }),
      global.alert_close_time
    );
  }


  getTitle() {
    return "Files";

  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  async copyPropertyDocsFile(fileUrl) {
    let message = "File copied to clipboard";
      navigator.clipboard.writeText(fileUrl).then(
        setTimeout(
         window.location.reload(),
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: message ? message : "Success",
          }),
          global.redirect_time
        )
    );
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className=" d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {/* {this.state.prop_doc_slug !== undefined ? (
                        {global.userPermissions.checkPermission('property-documents-delete') ?
                        <span
                          onClick={() => {
                            this.opendeletePropDocModal(this.state.prop_doc_slug);
                          }}
                          className="deleteicon mg-r-20"
                        >
                          <img src={deleteicon} />
                        </span>
                        :''}
                      ) : (
                        ""
                      )} */}

                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-documents"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                              this.state.property_slug +
                              "/property-documents"
                            );
                        }}
                      >
                        Cancel
                      </button>
                      {global.userPermissions.checkPermission('property-documents-update') ?
                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={this.saveOrUpdatePropertyDocuments}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.prop_doc_slug !== undefined
                            ? "Save"
                            : "Save"}
                      </button>
                      :''}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="property_document_form">
                    <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pl-0 pr-0">
                      <div className="col-md-12 pl-0 pr-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0">
                        <div className="row align-items-center pd-t-0 pd-b-0 border-bottom-0 documnet-section">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              File Type{" "}
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedDocumentTypeList}
                              options={this.state.newDocumentTypeList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeDocumentTypeList(value)
                              }
                              defaultValue={this.state.selectedDocumentTypeList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />

                            {this.state.errors.document_type_slug ? (
                              <div className="text-danger">
                                {this.state.errors.document_type_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "file_type",
                                this.state.document_type_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>

                          {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-15 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Name <span className="optional">(Optional)</span>
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="display_file_name"
                              value={this.state.display_file_name ? this.state.display_file_name : this.state.selected_file_name}
                              className="form-control max_width_100"
                              type="text"
                            />
                            {this.state.errors.display_file_name ? (
                              <div className="text-danger">
                                {this.state.errors.display_file_name}
                              </div>
                            ) : ""}
                          </div> */}
                        </div>

                        <div className="row align-items-baseline- pd-t-0 pd-b-0 border-bottom-0 h-100 documnet-section-upload">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Upload File{" "}
                            </label>

                            <div className="d-flex flex-row align-items-center upload upload-files-section">
                              <FileWidget
                                fieldName="file_name"
                                fieldTitle="Select File"
                                checkValidation="true"
                                allowedType="image/png,image/jpg,text/csv,application/*,doc,docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                maxFileSize={30}
                                maxFileSizeType="mb"
                                maxFileAllowed={10}
                                uploadOn="s3"
                                slug={this.state.property_slug}
                                refTableId="3"
                                displayFileName={this.state.display_file_name}
                                documentTypeSlug={this.state.document_type_slug}
                                // description={this.state.description}
                                mediaType="2"
                                sortOrder={
                                  this.state.sort_order !== ""
                                    ? this.state.sort_order
                                    : 0
                                }
                                statusId={this.state.status_id === true ? 1 : 2}
                                helpText="to download sample file."
                                sampleFileUrl="/sample.jpg"
                                drag="true"
                                dragText="Select or drop file size upto 30 MB"
                                inputClassName="form-control"
                                labelClassName="form-label"
                                formID="property_document_form"
                                uploadType="multiple"
                                resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                                propertySlug = {this.state.property_slug}
                                destinationFolder="property_document_form"
                                uploadedFiles={this.state.profile_image2}
                                deletedFiles={this.state.deletedFileList}
                                acceptOnly="image/png,image/jpg,text/csv,application/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.ms-excel"
                                displayPreviewStyle="horizontal"
                              // selected_display_file_name={this.selected_display_file_name}
                              />

                              {/* {this.state.prop_doc_slug !== undefined ? (
                                <span className="viewfile">
                                  {this.state.fileExt === "pdf" ||
                                    this.state.fileExt === "jpg" ||
                                    this.state.fileExt === "jpeg" ||
                                    this.state.fileExt === "png" ? (
                                    <a
                                      href={this.state.file_name}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      title="View File"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </a>
                                  ) : (
                                    <i
                                      className="fa fa-eye hand-cursor"
                                      onClick={() => {
                                        this.getDownloadFile(
                                          this.state.file_name,
                                          this.state.display_file_name
                                        );
                                      }}
                                    ></i>
                                  )}
                                </span>
                              ) : (
                                ""
                              )} */}
                            </div>
                            {/* <div className="col-md-12 p-0">
                              <span className="d-inline-flex  align-items-start justify-content-start infosize w-100">
                                <img src={infosize} className="mg-r-5" />
                                <p> Max file size - 30 MB</p>
                              </span>
                            </div> */}
                            {this.state.errors.document ? (
                              <div className="text-danger">
                                {this.state.errors.document}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {(this.state.doc_cat_slug !== undefined ||
                            this.state.doc_cat_slug !== "") &&
                            this.state.files_data.length > 0 ? (
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-15 pd-xs-r-15 mg-b-15 uploadSection">
                              <label className="form-label text-left pd-b-5">
                                Uploaded File{" "}
                              </label>
                              {this.state.doc_cat_slug !== undefined ||
                                this.state.doc_cat_slug !== "" ? (
                                <>
                                  {this.state.files_data.length > 0 ? this.state.files_data.map((opt, i) => {
                                    var docExt = opt.file_name.split(".").pop();
                                    return (
                                      <React.Fragment key={i}>
                                        <div className="viewfile-section">
                                          <span className="viewfile d-flex align-items-center">
                                            {docExt === "pdf" ? (
                                              <>
                                                <img
                                                  src={pdfIcon}
                                                  alt=""
                                                  className="mg-r-5 hand-cursor"
                                                  onClick={() => {
                                                    this.getDownloadFile(
                                                      opt.file_name,
                                                      opt.display_file_name
                                                    );
                                                  }}
                                                />
                                                <p
                                                  className="mg-r-5 hand-cursor"
                                                  onClick={() => {
                                                    this.getDownloadFile(
                                                      opt.file_name,
                                                      opt.display_file_name
                                                    );
                                                  }}
                                                >
                                                  {opt.display_file_name}
                                                </p>
                                              </>
                                            ) : docExt === "doc" ||
                                              docExt === "docx" ? (
                                              <>
                                                <img
                                                  src={wordIcon}
                                                  alt=""
                                                  className="mg-r-5 hand-cursor"
                                                  onClick={() => {
                                                    this.getDownloadFile(
                                                      opt.file_name,
                                                      opt.display_file_name
                                                    );
                                                  }}
                                                />
                                                <p
                                                  className="mg-r-5 hand-cursor"
                                                  onClick={() => {
                                                    this.getDownloadFile(
                                                      opt.file_name,
                                                      opt.display_file_name
                                                    );
                                                  }}
                                                >
                                                  {opt.display_file_name}
                                                </p>
                                              </>
                                            ) : docExt === "xlsx" ||
                                              docExt === "csv" ||
                                              docExt === "xls" ? (
                                              <>
                                                <img
                                                  src={excelIcon}
                                                  alt=""
                                                  className="mg-r-5 hand-cursor"
                                                  onClick={() => {
                                                    this.getDownloadFile(
                                                      opt.file_name,
                                                      opt.display_file_name
                                                    );
                                                  }}
                                                />
                                                <p
                                                  className="mg-r-5 hand-cursor"
                                                  onClick={() => {
                                                    this.getDownloadFile(
                                                      opt.file_name,
                                                      opt.display_file_name
                                                    );
                                                  }}
                                                >
                                                  {opt.display_file_name}
                                                </p>
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={imgIcon}
                                                  alt=""
                                                  className="mg-r-5 hand-cursor"
                                                  onClick={() => {
                                                    this.getDownloadFile(
                                                      opt.file_name,
                                                      opt.display_file_name
                                                    );
                                                  }}
                                                />
                                                <p
                                                  className="mg-r-5 hand-cursor"
                                                  onClick={() => {
                                                    this.getDownloadFile(
                                                      opt.file_name,
                                                      opt.display_file_name
                                                    );
                                                  }}
                                                >
                                                  {opt.display_file_name}
                                                </p>
                                              </>
                                            )}
                                          </span>
                                          <span>
                                            {opt.updated_at
                                              ? Moment(
                                                opt.updated_at,
                                                "YYYY-MM-DD HH:mm:ss"
                                              ).format(global.dateFormat + " HH:mm:ss")
                                              : Moment(
                                                opt.created_at,
                                                "YYYY-MM-DD HH:mm:ss"
                                              ).format(global.dateFormat + " HH:mm:ss")}
                                          </span>
                                          <OverlayTrigger
                                                trigger="click"
                                                placement="top"
                                                overlay={
                                                  <Popover
                                                    id="popover-basic"
                                                    className="popover-primary copide_box"
                                                  >
                                                    <Popover.Title as="h3" className="p-0">
                                                      Copied
                                                    </Popover.Title>
                                                  </Popover>
                                                }
                                              >
                                              <img
                                                src={copyIcon}
                                                alt="Copy"
                                                onClick={() =>
                                                  this.copyPropertyDocsFile(opt.file_name)
                                                }
                                                className="hand-cursor copyClass dashboardRightcard "
                                              />
                                          </OverlayTrigger>
                                          {global.userPermissions.checkPermission('property-documents-delete') ?
                                          <img
                                            src={deleteicon}
                                            className="hand-cursor"
                                            alt=""
                                            onClick={() =>
                                              this.openDeleteDocModal(opt.slug)
                                            }
                                          />
                                          :''}
                                        </div>
                                      </React.Fragment>
                                    );
                                  }) : ""}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <div className="row align-items-center pd-l-15 pd-r-15 border-bottom-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-15 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Description{" "}
                              <span className="optional">(Optional)</span>
                            </label>

                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                removePlugins: [
                                  "EasyImage",
                                  "ImageUpload",
                                  "MediaEmbed",
                                  "Table",
                                ],
                              }}
                              name="description"
                              minHeight="50px"
                              data={this.state.description}
                              onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                  writer.setStyle(
                                    "height",
                                    "200px",
                                    editor.editing.view.document.getRoot()
                                  );
                                });
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                this.changeHandler({
                                  target: {
                                    type: "ckeditor",
                                    name: "description",
                                    value: data,
                                  },
                                });
                              }}
                            />
                            {this.state.errors.description ? (
                              <div className="text-danger">
                                {this.state.errors.description}
                              </div>
                            ) : (
                              this.validator.message(
                                "description",
                                this.state.description,
                                "max:100000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deletePropDocModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeletePropDocModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeletePropDocModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deletePropDoc(this.state.prop_doc_slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteDocModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteDocModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteDocModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteDoc(this.state.delete_slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default PropertyDocumentsForm;

//     							                          //
//  Program: VendorsSidebar.jsx                           //
//  Application: Property                                 //
//  Option: common component House Keeping Sidebar        //
//  Developer: NP 		                                  //
//  Date: 2022-08-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';

class VendorsSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug") ? sessionStorage.getItem("property_slug") : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <span>Accounting</span> 
                  <ul>
                    {/* <li>
                      <Link
                        className={
                          this.isPathActive("work-order")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/work-order"}
                      >
                        Work Orders
                      </Link>
                    </li> */}
                  {global.userPermissions.checkPermission('bill-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("bills")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/bills"}
                      >
                        Bills
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission('payments-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("payments")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/payments"}
                      >
                        Payments
                      </Link>
                    </li>
                  }
                  {global.userPermissions.checkPermission('invoices-list') &&
                    <li>
                      <Link
                        className={
                          this.isPathActive("invoices")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/invoices"}
                      >
                        Invoices
                      </Link>
                    </li>
                  }
                    {/* <li>
                      <Link
                        className={
                          this.isPathActive("housekeeping-vendoor-list")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/housekeeping-vendoor-list"}
                      >
                        Vendors
                      </Link>
                    </li> */}
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span>Sub Menu</span>
                <i className="fas fa-chevron-down"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="tx-13">
              <aside>
              <nav className="nav nav-pills flex-column pd-r-8">
                <ul>
                  <li>
                    <span>Accounting</span> 
                    <ul>
                      {/* <li>
                        <Link
                          className={
                            this.isPathActive("work-order")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/work-order"}
                        >
                          Work Orders
                        </Link>
                      </li> */}
                    {global.userPermissions.checkPermission('bill-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("bills")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/bills"}
                        >
                          Bills
                        </Link>
                      </li>
                    }
                    {global.userPermissions.checkPermission('payments-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("payments")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/payments"}
                        >
                          Payments
                        </Link>
                      </li>
                    }
                    {global.userPermissions.checkPermission('invoices-list') &&
                      <li>
                        <Link
                          className={
                            this.isPathActive("invoices")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/invoices"}
                        >
                          Invoices
                        </Link>
                      </li>
                    }
                      {/* <li>
                        <Link
                          className={
                            this.isPathActive("housekeeping-vendoor-list")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-toggle="tab"
                          to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/housekeeping-vendoor-list"}
                        >
                          Vendors
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                </ul>
              </nav>
              </aside>
              </Dropdown.Menu>
            </Dropdown>
          </div>

        </div>
      </>
    );
  }
}
export default VendorsSidebar;
////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ReservationForm.jsx                                //
//  Application:  Reservation                                   //
//  Option: For add or update Reservation                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-04-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";

import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
//
// import { Modal} from "react-bootstrap";
import editIcon from "./../../../assets/images/edit-pen-icon.svg";
import Alerts from "../../common/Alerts";
import modaldeleteicon from "./../../../assets/images/delete.svg";
import Moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import UnitsService from "../../../services/UnitsService";
import ListingService from "../../../services/ListingService";
import KeycafeService from "../../../services/KeycafeService";
import LatchService from "../../../services/LatchService";
import deleteicon from "../../../assets/images/delete.svg";
export class ActivityView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      open: true,
      eventKey: "3",
      deleteLatchModal:false,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      lock_type: localStorage.getItem("propertyLockType")
      ? parseInt(localStorage.getItem("propertyLockType"))
      : 1,
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      lisitngList: [],
      newListingList: [],
      selectedListingList: [],
      status_id: [],
      status_name:"",
      set_channel: "",
      pathname: window.location.pathname,
      importModal: false,
      confirm_back: 0,
      activeClass: "basic",
      confirmation_code: "",
      unit_slug: [],
      start_date: "",
      checkout_date: "",
      guest_slug: [],
      guestList: [],
      newGuestList: [],
      selectedGuestList: [],
      adults: "",
      children: "",
      infants: "",
      pets: "",
      nightly_rate: "",
      total_stay_nights: 0,
      guest_paid: "",
      host_payout: "",
      pet_fee: "",
      security_deposite: "",
      isGuestExist: 0,
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      end_date: "",
      email: "",
      email_slug: "",
      start_time: "00:00AM",
      end_time: "00:00AM",
      isLoaded: false,
      unit_name: "",
      access_start_date: "",
      access_end_date: "",
      access_code: "",
      key_name: "",
      keycafe_key_id: "",
      fob: "",
      acess_end_time: "",
      access_start_time: "",
      keycafe_access_id: "",
      key_type: "",
    };
    this.changeHandler = this.changeHandler.bind(this);
    // this.changeHandler1 = this.changeHandler1.bind(this);
    this.opendeleteLatchModal = this.opendeleteLatchModal.bind(this);
    this.opendeleteKeyModal = this.opendeleteKeyModal.bind(this);
    this.closedeleteKeyModal = this.closedeleteKeyModal.bind(this);
    this.closedeleteLatchModal = this.closedeleteLatchModal.bind(this);
  }
  opendeleteLatchModal(slug) {
    this.setState({ deleteLatchModal: true});
  }
  closedeleteLatchModal() {
    this.setState({ deleteLatchModal: false});
  }
  opendeleteKeyModal(slug) {
    this.setState({ deleteKeycafeModal: true, slug: slug });
  }

  closedeleteKeyModal() {
    this.setState({ deleteKeycafeModal: false, slug: "" });
  }

  async deleteKeyModal(slug) {
    this.setState({ showSpinner: true });
    let res = await KeycafeService.getKeycafeRevoke(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteKeyModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" + this.state.property_slug + "/keys"
              : "/keys"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteKeyModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  async getData(tab, queryString = "") {
    if (this.state.slug !== undefined) {
      var resData = {};
      var totalRecords = 0;
      let res = await KeycafeService.getKeycafeAccessdetail(
        "property_slug=" +
          this.state.property_slug +
          "&key_slug=" +
          this.state.slug +
          "&lock_type=" +	
          (this.state.lock_type ? this.state.lock_type : "") +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          (queryString ? "&" + queryString : "")
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data.data[0] : {};
        totalRecords = res.data ? res.data.total : 0;
        resData.unit_name = resData.unit_name ? resData.unit_name : "";
        resData.access_start_date = resData.access_start_date
          ? resData.access_start_date
          : "";
        resData.access_end_date = resData.access_end_date
          ? resData.access_end_date
          : "";
        resData.email = resData.email ? resData.email : "";
        resData.access_code = resData.access_code ? resData.access_code : "";
        resData.key_name = resData.key_name ? resData.key_name : "";
        resData.keycafe_key_id = resData.keycafe_key_id
          ? resData.keycafe_key_id
          : "";
        resData.fob = resData.fob ? resData.fob : "";
        resData.access_start_time = resData.access_start_time
          ? resData.access_start_time
          : "";
        resData.acess_end_time = resData.acess_end_time
          ? resData.acess_end_time
          : "";
        resData.keycafe_access_id = resData.keycafe_access_id
          ? resData.keycafe_access_id
          : "";
        resData.key_type = resData.key_type ? resData.key_type : "";
        resData.current_key_status = resData.current_key_status
          ? resData.current_key_status
          : "";
        resData.ref_table_id = resData.ref_table_id ? resData.ref_table_id : "";
        resData.ref_id = resData.ref_id ? resData.ref_id : "";
        resData.confirmation_code = resData.confirmation_code
          ? resData.confirmation_code
          : "";
          resData.lock_type = resData.lock_type
          ? resData.lock_type
          : "";
          resData.status_id = resData.status_id
          ? resData.status_id
          : "";
          resData.status_name = resData.status_name
          ? resData.status_name
          : "";
          resData.first_name = resData.first_name
          ? resData.first_name
          : "";
          resData.last_name = resData.last_name
          ? resData.last_name
          : "";
          resData.unit_slug = resData.unit_slug
          ? resData.unit_slug
          : "";
          resData.door_uu_ids = resData.door_uu_ids
          ? resData.door_uu_ids
          : "";
          resData.user_uu_id = resData.user_uu_id
          ? resData.user_uu_id
          : "";
          resData.reservation_id = resData.reservation_id
          ? resData.reservation_id
          : "";
      }
      this.setState({
        total_record: totalRecords,
        records: resData,
        isLoaded: true,
        unit_name: resData.unit_name ? resData.unit_name : "",
        access_start_date: resData.access_start_date
          ? resData.access_start_date
          : "",
        access_end_date: resData.access_end_date ? resData.access_end_date : "",
        email: resData.email ? resData.email : "",
        access_code: resData.access_code ? resData.access_code : "",
        key_name: resData.key_name ? resData.key_name : "",
        keycafe_key_id: resData.keycafe_key_id ? resData.keycafe_key_id : "",
        fob: resData.fob ? resData.fob : "",
        access_start_time: resData.access_start_time
          ? resData.access_start_time
          : "",
        acess_end_time: resData.acess_end_time ? resData.acess_end_time : "",
        keycafe_access_id: resData.keycafe_access_id
          ? resData.keycafe_access_id
          : "",
        key_type: resData.key_type ? resData.key_type : "",
        current_key_status: resData.current_key_status
          ? resData.current_key_status
          : "",
        ref_table_id: resData.ref_table_id ? resData.ref_table_id : "",
        ref_id: resData.ref_id ? resData.ref_id : "",
        confirmation_code: resData.confirmation_code
          ? resData.confirmation_code
          : "",
          lock_type: resData.lock_type
          ? resData.lock_type
          : "",
          status_id: resData.status_id
          ? resData.status_id
          : "",
          status_name: resData.status_name
          ? resData.status_name
          : "",
          first_name: resData.first_name
          ? resData.first_name
          : "",
          last_name: resData.last_name
          ? resData.last_name
          : "",
          unit_slug: resData.unit_slug
          ? resData.unit_slug
          : "",
          door_uu_ids:resData.door_uu_ids
          ? resData.door_uu_ids
          : "",
          user_uu_id:resData.user_uu_id
          ? resData.user_uu_id
          : "",
          reservation_id:resData.reservation_id
          ? resData.reservation_id
          : "",
      });
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;

    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  // changeHandler1 = (event) => {
  //   let input = this.state.input;
  //   this.setState({ email: input.email });
  //   // this.getEmailData(this.state.email);
  // };

  async componentDidMount() {
    // this.getEmailData();
    this.getUnitData();
    this.getData();

    this.getListingData();
  }

  /* to get slug  on behalf email ID */
  async getEmailData(email) {
    var emailList = [];
    var unitListTotal = 0;
    let res = await KeycafeService.UserEmaildetail("email=" + email);

    if (global.successStatus.includes(res.status)) {
      if (res.data) {
        emailList = res.data ? res.data : [];
        unitListTotal = emailList ? emailList.length : 0;
        this.setState({
          email_slug: emailList.slug,
          unitListTotal: unitListTotal,
        });
      }
    }
  }

  userData = async () => {
    if (this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        access_start_date: this.state.start_date
          ? Moment(this.state.start_date).format("YYYY-MM-DD")
          : "",
        access_end_date: this.state.end_date
          ? Moment(this.state.end_date).format("YYYY-MM-DD")
          : "",
        accessStartTime: this.state.start_time,
        accessEndTime: this.state.end_time,
        property_slug: this.state.property_slug,
        email: this.state.email ? this.state.email : "",
        unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
        // convertedTime : Moment(this.state.state.end_time, 'hh:mm A').format('HH:mm'),
        user_slug: this.state.email_slug ? this.state.email_slug : 0,
        key_name: this.state.key_name ? this.state.key_name : "",
        from_where: 4,
      };
      if (this.state.slug !== undefined) {
        let res = await KeycafeService.getkeycafeaccess(
          this.state.slug,
          inputData
        );

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.props.history.push("keys"),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        let res = await KeycafeService.getkeycafeaccess(inputData);

        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.props.history.push("keys"),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;
    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get listing data list */
  async getListingData(queryString = "") {
    var listingList = [];
    var listingListTotal = 0;

    let res = await ListingService.getListingList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      listingList = res.data ? res.data : [];
      listingListTotal = listingList ? listingList.length : 0;
    }
    const newListingList =
      listingListTotal > 0
        ? listingList.map(({ slug, listing_name, unit }) => ({
            value: slug,
            label: unit
              ? listing_name + (unit ? " (" + unit.unit_number + ") " : "")
              : listing_name,
          }))
        : [];
    this.setState({
      listingList: listingList,
      listingListTotal: listingListTotal,
      newListingList: newListingList,
    });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_number: value.label,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }


  customValidate() {
    let isValid = true;
    return isValid;
  }

  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    const status_id_value = value !== null ? value.value : [];
    this.setState({
      selectedStatusList: value,
      status_id: status_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  async deleteKeyModals(
    slug,
    access_start_date,
    acess_end_time,
    access_start_time,
    access_end_date,
    property_slug,
    email,
    unit_slug,
    email_slug,
    first_name,
    last_name
  ) {
    // this.setState({ showSpinner: true, isSubmit: true });
    let inputData = {
      checkin_date: access_start_date ? Moment(access_start_date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : "",
      checkout_date: access_end_date ? Moment(access_end_date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : "",
      checkin_time: access_start_time !== null ? access_start_time : "",
      checkout_time: acess_end_time !== null ? acess_end_time : "",
      property_slug: property_slug,
      email: email ? email : "",
      unit_slug: unit_slug ? unit_slug : "",
      user_slug: email_slug ? email_slug : 0,
      first_name: first_name ? first_name : "",
      last_name: last_name ? last_name : "",
      action_type: 3,
      latch_access_slug: slug,
    };
    if (this.state.slug !== undefined) {
      let res = await LatchService.createlatchmanualaccess(
        inputData
      );
    
    // let res = await LatchService.createlatchmanualaccess(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteKeyModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
        this.props.history.push(
          this.props.match.params.propertySlug !== undefined
            ? "/properties/view/" + this.state.property_slug + "/keys"
            : "/keys"
        ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteKeyModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
}

  handleChangeChannel(value) {
    let set_channel = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        set_channel.push(item.value);
      });
    }
    const set_channel_value = value !== null ? value.value : [];
    this.setState({
      selectedChannelList: value,
      set_channel: set_channel_value,
    });
    this.setState({ confirm_back: 1 });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Access";
    } else {
      return "Access";
    }
  }

  async deleteLatchAccess() {
    // this.setState({ showSpinner: true, isSubmit: true });
    let inputData = {
      ref_id: this.state.ref_id,
      reservation_id: this.state.reservation_id,
    };
    if (this.state.slug !== undefined) {
      let res = await LatchService.deleteLatchaccess(
        inputData
      );
    
    // let res = await LatchService.createlatchmanualaccess(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteLatchModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
        this.props.history.push(
          this.props.match.params.propertySlug !== undefined
            ? "/properties/view/" + this.state.property_slug + "/keys"
            : "/keys"
        ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteLatchModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
}

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
                      {global.userPermissions.checkPermission(
                        "key-cafe-manual-delete"
                      ) &&
                        this.state.key_type === "Manual" && (this.state.lock_type === 1 ||this.state.lock_type === 2) && (
                          <span
                            onClick={() => {
                              this.opendeleteKeyModal(this.state.slug);
                            }}
                            className="deleteicon mg-r-20 hand-cursor"
                          >
                            <img src={deleteicon} alt="Delete" />
                          </span>
                        )}

                      {global.userPermissions.checkPermission(
                        "key-cafe-manual-update"
                      ) && (this.state.lock_type === 1) && (
                        <Link
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/keys/activity-update/" +
                                this.state.slug
                              : "/keys/activity-update/" +
                                this.state.property_slug +
                                "/" +
                                this.state.slug
                          }
                        >
                          {this.state.key_type === "Manual" ? (
                            <button className="btn-success-outline ml-3">
                              <img src={editIcon} alt="" />
                              Edit
                            </button>
                          ) : (
                            ""
                          )}
                        </Link>
                      )}
                      {global.userPermissions.checkPermission(
                        "key-cafe-manual-update"
                      ) && (this.state.lock_type === 2) && (
                        <>
                        <span
                        onClick={() => {
                          this.opendeleteLatchModal();
                        }}
                        className="deleteicon mg-r-20 hand-cursor"
                      >
                        { this.state.door_uu_ids !== "" && this.state.user_uu_id !== '' && this.state.ref_table_id == 1 && this.state.status_id != 5 ? 
                        <img src={deleteicon} alt="Delete" />:
                        (
                          ""
                        )}
                      </span>
                      
                        <Link
                          // to={
                          //   this.props.match.params.propertySlug !== undefined
                          //     ? "/properties/view/" +
                          //       this.state.property_slug +
                          //       "/latches/activity-update/" +
                          //       this.state.slug
                          //     : "/latches/activity-update/" +
                          //       this.state.property_slug +
                          //       "/" +
                          //       this.state.slug
                          // }
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/latches/activity-update/" +
                                this.state.slug
                              : "/latches/activity-update/" +
                                this.state.property_slug +
                                "/" +
                                this.state.slug
                          }
                        >
                       
                          {this.state.key_type === "Manual" ? (
                            <button className="btn-success-outline ml-3">
                              <img src={editIcon} alt="" />
                              Edit
                            </button>
                          ) : (
                            ""
                          )}
                        </Link>
                        </>
                      )}
                      {/* 
                      {
                        <button
                          type="button"
                          className="btn-success ml-3"
                          onClick={this.userData}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          {this.state.isSubmit
                            ? global.loader
                            : this.state.slug !== undefined
                            ? "Edit"
                            : "Edit"}
                        </button>
                      } */}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-lg-l-15 pd-sm-l-30 pd-sm-r-30 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="key_form">
                      <div className="m-0">
                        <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Unit Name
                            </p>
                            <p className="media-body">
                              {this.state.unit_name ? this.state.unit_name : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Start Date
                            </p>
                            <p className="media-body">
                              {this.state.access_start_date
                                ? Moment(this.state.access_start_date).format(
                                    global.dateFormat
                                  ) +
                                  " | " +
                                  this.state.access_start_time
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              End Date
                            </p>
                            <p className="media-body">
                              {this.state.access_end_date
                                ? Moment(this.state.access_end_date).format(
                                    global.dateFormat
                                  ) +
                                  " | " +
                                  this.state.acess_end_time
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">Email</p>
                            <p className="media-body">
                              {this.state.email ? this.state.email : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Access Code
                            </p>
                            <p className="media-body">
                              {this.state.access_code
                                ? this.state.access_code
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Exchanger Name
                            </p>
                            <p className="media-body">
                              {this.state.key_name ? this.state.key_name : ""}
                            </p>
                          </div>
                          {this.state.lock_type === 1  ? (
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">Key ID</p>
                            <p className="media-body">
                              {this.state.keycafe_key_id
                                ? this.state.keycafe_key_id
                                : ""}
                            </p>
                          </div>
                          ):''}
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">Type</p>
                            <p className="media-body">
                              {this.state.ref_table_id == 1
                                ? "Guest"
                                : this.state.ref_table_id == 2
                                ? "Cleaner"
                                : this.state.ref_table_id == 3
                                ? "Resident"
                                : this.state.ref_table_id == 4
                                ? "Visitor"
                                : ""}
                            </p>
                          </div>
                          {this.state.lock_type == 1  ? (
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Access Status
                            </p>
                            <p className="media-body">
                              {(this.state.ref_table_id == 1 ||
                                this.state.ref_table_id == 2 ||
                                this.state.ref_table_id == 3 ||
                                this.state.ref_table_id == 4 ||
                                this.state.ref_table_id == 0) &&
                              this.state.current_key_status === "DROPOFF"
                                ? "Key in Locker"
                                : this.state.ref_table_id == 1 &&
                                  this.state.current_key_status != undefined &&
                                  this.state.current_key_status != ""
                                ? "Key with Guest"
                                : this.state.ref_table_id == 2 &&
                                  this.state.current_key_status != undefined &&
                                  this.state.current_key_status != ""
                                ? "Key with Cleaner"
                                : this.state.ref_table_id == 3 &&
                                  this.state.current_key_status != undefined &&
                                  this.state.current_key_status != ""
                                ? "Key with Resident"
                                : this.state.ref_table_id == 4 &&
                                  this.state.current_key_status != undefined &&
                                  this.state.current_key_status != ""
                                ? "Key with Vistor"
                                : ""}
                            </p>
                          </div>
                          ):''}
                          {this.state.lock_type == 2  ? (
                            
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Access Status
                            </p>
                            <p className="media-body">
                            {this.state.status_name ? this.state.status_name : ""}
                            </p>
                          </div>
                          ):''}
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                          {(this.state.ref_table_id === 1)  ? (
                          <p className="az-profile-name-text pl-0">
                              Reservation Confirmation Code
                            </p>
                            ):" "}
                            {(this.state.ref_table_id != 4) ? (
                            <p className="media-body">
                              <a
                                href={
                                  "/properties/view/" +
                                  this.state.property_slug +
                                  "/" +
                                  "reservations/view/" +
                                  this.state.ref_id
                                }
                              >
                                {this.state.confirmation_code}
                              </a>
                            </p>
                            ):" "}
                          </div>
                          
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteKeycafeModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteKeyModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteKeyModal()}
                >
                  Cancel
                </button>
                {this.state.lock_type === 1 ? (
            <button
              type="button"
              className="m-0 btn btn btn-danger btn-block"
              onClick={() => this.deleteKeyModal(this.state.keycafe_access_id)}
              disabled={this.state.isSubmit ? true : false}
            >
              {this.state.isSubmit ? global.loader : "Delete"}
            </button>
          ) : (
            <button
              type="button"
              className="m-0 btn btn btn-danger btn-block"
              onClick={() => this.deleteKeyModals(this.state.slug,
                              this.state.access_start_date,
                              this.state.acess_end_time,
                              this.state.access_start_time,
                              this.state.access_end_date,
                              this.state.property_slug,
                              this.state.email,
                              this.state.unit_slug,
                              this.state.email_slug,
                              this.state.first_name,
                              this.state.last_name)}
              disabled={this.state.isSubmit ? true : false}
            >
              {this.state.isSubmit ? global.loader : "Delete"}
            </button>
          )}
                {/* <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteKeyModal(this.state.keycafe_access_id)
                  }
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteLatchModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteLatchModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Access?</h3>
              <p>
                Do you really want to delete this access? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteLatchModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteLatchAccess(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default ActivityView;

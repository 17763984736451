///////////////////////////////////////////////
//     							             //
//  Program: PetPolicyService.js                 //
//  Application: Services                    //
//  Option: Used for pet-policy data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-02-10                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class PetPolicyService {
  getPetPolicy(slug) {
    let endPoint = "get-property-pet-policy/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createPetPolicy(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "insert-or-update-property-pet-policy";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getPetPolicyBySlug(slug) {
    let endPoint = "get-property-pet-policy/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updatePetPolicy(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "insert-or-update-property-pet-policy";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deletePetPolicy(slug) {
    let endPoint = "pet-policy/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new PetPolicyService();

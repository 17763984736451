////////////////////////////////////////////////////////////
//  Program: RUmessageService.js                          //
//  Application: RU GUEST COMMUNICATION                   //
//  Developer: Kiran Singh						                    //
//  Date: 2022-09-13                                      //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class RUmessageService {
  getThreadList(queryString) { //get thread list
    let endPoint = "get-thread-list" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  createThread(data) { //create thread
    delete data.errors;
    delete data.input;
    let endPoint = "post-messages-threads";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getMessageListByThread(queryString) { //get message list by thread
    let endPoint = "get-messages" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createMessage(data) { //create message by thread
    delete data.errors;
    delete data.input;
    let endPoint = "post-messages";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getMessageTemplateList(queryString) { // get message template
    let endPoint = "get-message-template" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  uploadImage(data) { //uploadImage
    delete data.errors;
    delete data.input;
    let endPoint = "msg-image-upload";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  messageCountPropertyWise(queryString) { //get thread list
    let endPoint = "unread-count-property-wise" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getUserDetail(slug) { //get user detail
    let endPoint = "get-user-detail/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  
  createQuickReplyTemplate(data) { //create quick reply template
    delete data.errors;
    delete data.input;
    let endPoint = "post-quick-reply-template";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getQuickReplyTemplate(queryString) {  //get quick reply template
    let endPoint = "get-quick-reply-template" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  } 
  getTemplateBySlug(slug) { //get quick reply template detail
    let endPoint = "get-quick-reply-template/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  updateQuickReplyTemplate(slug, data) { //update quick reply template
    delete data.errors;
    delete data.input;
    let endPoint = "get-quick-reply-template/" + slug;
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }
  getNewMessageList(queryString) { //get new message list
    let endPoint = "get-new-message-list" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  unreadCountUpdate(slug) { //unread count update single thread click
    let endPoint = "update-unread/" + slug;
    let addditionalHeaderData = {};
    let postData ={};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getThreadBySlug(slug) { //thread get by outside module by thread slug
    let endPoint = "get-thread-By-slug/" + slug;
    let addditionalHeaderData = {};
    let postData ={};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getThreadListFliter(queryString) { //get thread list
    let endPoint = "get-thread-list-filter" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  chatGpt(data) { //create message by thread
    delete data.errors;
    delete data.input;
    let endPoint = "enable-chat-gpt";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  

}

export default new RUmessageService();

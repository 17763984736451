////////////////////////////////////////////////////////////
//     							                          //
//  Program: AmentiyTypeLookup.jsx                         //
//  Application: AmentiyTypeLookup                         //
//  Developer: Bhavit				                          //
//  Date: 2022-06-20                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
// import LookUpMappingService from "../../services/LookUpMappingService";
import { Container, Button } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import Select from "react-select";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import RUMappingService from "../../services/RUMappingService";
import NoData from "../common/NoData";

// import deleteicon from "../../assets/images/delete.svg";

export class CountryLookup extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isPageLoaded: true,
      open: true,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      is_available: "",
      available_from: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      unitsFilter: [],
      mappingArray: [],
      newStatusList: [
        { label: "Pending", value: 2 },
        { label: "Completed", value: 1 },
      ],
      selectedStatusList: [],
      selectedCleaningList: [],
      tab: 6,
      filter_value: "",
      lastUpdatedSlug: "",
      // date: this.props.match.params.date,
      listing_slug: this.props.match.params.listingSlug,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      record1: [],
      config: {
        sort: {
          column: "scheduled_on",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "scheduled_on",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        // show_filter: global.show_filter,
        show_filter: false,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_number",
          text: "Country",
          className: "unit_number",
          sortable: true,
          cell: (record) => {
            return record.country_name;
          },
        },
        {
          key: "inspection_status",
          text: "RU Country",
          className: "inspection_status drop_cust_w",
          sortable: true,
          cell: (record) => {
            // return record.inspection_status === 1
            //   ? "COMPLETED"
            //   : "INCOMPLETE";
            const customStyles = {
              option: (provided, state) => ({
                ...provided,
                color:
                  state.isSelected && state.isFocused
                    ? "white"
                    : state.isSelected
                    ? "black"
                    : state.isFocused && "white",
                backgroundColor:
                  state.isSelected && state.isFocused
                    ? "black"
                    : state.isFocused && "black",
              }),
            };

            return (
              <div>
                <Select
                  styles={customStyles}
                  className="multiselect"
                  classNamePrefix={"my-custom-react-select"}
                  menuPlacement="auto"
                  value={
                    this.state.isPageLoaded
                      ? this.state.selectedCleaningList.filter(
                          (product) => product.value === record.country_id
                        )
                      : this.state.selectedCleaningList
                  }
                  defaultValue={this.state.selectedCleaningList}
                  options={this.state.cleaning_list}
                  getOptionValue={(option) => `${option.label}`}
                  onChange={(value) =>
                    this.handleChangeStatusList(value.value, record.country_id)
                  }
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary: "#fff",
                      primary75: "#000",
                      primary50: "#000",
                      primary25: "#000",
                    },
                  })}
                />
              </div>
            );
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.setMapping = this.setMapping.bind(this);
  }

  handleChangeStatusList(value, cleaning) {
    this.setState({ isPageLoaded: false });
    // console.log(cleaning);
    var state = this.state.mappingArray;
    var mapping = { country_id: cleaning, ru_location_id: value };
    state.push(mapping);
    this.setState({ mappingArray: state, selectedCleaningList: this.value });
    console.log("mapping", this.state.mappingArray);
  }

  async componentDidMount() {
    this.getData();
  }

  async getData(queryString = "") {
    var list = [];
    var list1 = [];
    var newList1 = [];
    var totalRecords = 0;
    var totalRecords1 = 0;
    let res = await RUMappingService.getCountryLookUpMappingList(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.countryData : [];
      list1 = res.data ? res.data.countryRUData : [];

      totalRecords = res.data.countryData ? res.data.countryData.total : 0;
      totalRecords1 = res.data.countryRUData
        ? res.data.countryRUData.length
        : 0;
      newList1 =
        totalRecords1 > 0
          ? list1.map(({ ru_location_id, ru_location_name }) => ({
              value: ru_location_id,
              label: ru_location_name,
            }))
          : [];
      // totalRecords = list ? list.length : 0;
    }

    var selectedCleaningList = [];

    res.data.countryData.length > 0 &&
      res.data.countryData.forEach((item, i) => {
        res.data.countryRUData.forEach((item2, i) => {
          if (item2.oh_country_id === item.country_id) {
            selectedCleaningList = [
              ...selectedCleaningList,
              {
                value: item2.oh_country_id,
                label: item2.ru_location_name,
              },
            ];
          }
        });
      });
    this.setState({
      total_record: totalRecords,
      records: list,
      total_record1: totalRecords1,
      cleaning_list: newList1,
      selectedCleaningList: selectedCleaningList,
    });
    // console.log(this.state.selectedCleaningList);
    console.log(res);
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  async setMapping() {
    let inputData = {
      mappingArray: this.state.mappingArray,
    };

    var res = await RUMappingService.setCountryMapping(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      window.location.reload();
      // setTimeout(() => this.props.history.push("/countries-lookup"), global.redirect_time);
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customFilter() {
    let queryString = "&status_id=" + this.state.status_id + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ filter_value: "", status_id: "", selectedStatusList: [] });
    this.getData();
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(this.state.tab, queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(this.state.tab, queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Countries LookUp";
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    // if(e === "allCleaning"){
    //   this.setState({ tab: 6 });      //for calendar
    // } else
    if (e === "today") {
      this.setState({ tab: 1 });
      this.getData(1);
    } else if (e === "tomorrow") {
      this.setState({ tab: 2 });
      this.getData(2);
    } else if (e === "completed") {
      this.setState({ tab: 3 });
      this.getData(3);
    } else if (e === "pending") {
      this.setState({ tab: 4 });
      this.getData(4);
    } else if (e === "all") {
      this.setState({ tab: 5 });
      this.getData(5);
    } else {
      this.setState({ tab: 6 });
    }
  }

  // rowClickedHandler = (event, data, rowIndex) => {
  //   this.props.history.push("reservations/view/" + data.slug);
  // };

  render() {
    // var date = Moment(new Date()).format(global.dateFormat);
    // var date1 = Moment(new Date()).add(1, 'days').format(global.dateFormat);
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <MasterSidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right settingsidebarNav">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                    {global.userPermissions.checkPermission("master-data-update") &&
                      <Button
                        variant="outline-dark btn-block btn-with-icon"
                        onClick={this.setMapping}
                      >
                        <img src={pluswIcon} alt="" /> Save
                      </Button>
                    } 
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="col-md-12 pl-0 pr-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>

                  {this.state.total_record !== 0 ? (
                    <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                      <div className="pd-lg-l-15 pd-xs-l-0 lookup_table">
                        <ReactDatatable
                          className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100"
                          config={
                            this.state.total_record > global.page_size
                              ? this.state.config
                              : this.state.config
                          }
                          records={this.state.records}
                          columns={this.state.columns}
                          extraButtons={this.state.extraButtons}
                          dynamic={true}
                          // loading={this.state.loading}
                          total_record={this.state.total_record}
                          onChange={this.tableChangeHandler}
                          onRowClicked={this.rowClickedHandler}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mg-t-30 text-center">
                      <NoData />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default CountryLookup;

//     							                                   //
//  Program: RolesForm.jsx                                         //
//  Application: roles add                                         //
//  Option: Role add                                               //
//  Developer: Mitesh Prajapati     		                       //
//  Date: 2022-09-02                                               //
//                                                                 //
/////////////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Modal } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import UsersSidebar from "../common/UsersSidebar";
import RolePermissionService from "../../services/RolePermissionService";
import Select from "react-select";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import modaldeleteicon from "./../../assets/images/delete.svg";

class RolesForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      selectedRoleGroupList: [],
      selectedCloneRoleList: [],
      clone_role: "",
      defaultLandingPageList: [
        { label: "Dashboard", value: 1 },
        { label: "Building Onboarding", value: 3 },
        { label: "Properties", value: 4 },
        { label: "Snapshot", value: 2 },
      ],
      selectedDefaultLandingPageList:[],
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangeRoleGroupList = this.handleChangeRoleGroupList.bind(this);
    this.handleChangeDefaultLandingPageList = this.handleChangeDefaultLandingPageList.bind(this);
    this.saveRoleData = this.saveRoleData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  // to get data of roles
  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    let roleGroupList = [];
    let res = await RolePermissionService.getRolesGroups();
    if (global.successStatus.includes(res.status)) {
      var count = 0;
      res.data.roleGroupList.map((item, i) => {
        var groupList = [];
        groupList.value = item.value;
        groupList.label = item.key;
        roleGroupList[count] = groupList;
        count++;
      });



      this.setState({
        showSpinner: false,
        loading: false,
        roleGroupList: roleGroupList,
      });
    }
  }



  getTitle() {
    if (this.state.slug !== undefined) {
      return "Roles";
    } else {
      return "Roles";
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ [event.target.name]: event.target.value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.setState({ errors: "" });
  };

  handleChangeRoleGroupList(value) {
    var selectedValue = [{ value: value.value, label: value.label }];
    this.setState({
      selectedRoleGroupList: selectedValue,
      role_group: value.value,
    });
  }

  handleChangeDefaultLandingPageList(value) {
    var selectedLandingPageValue = [{ value: value.value, label: value.label }];
    this.setState({
      selectedDefaultLandingPageList: selectedLandingPageValue,
      default_landing_page: value.value,
    });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  saveRoleData = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let inputData = {
        role_name: this.state.role_name,
        role_group: this.state.role_group,
        clone_role: this.state.clone_role,
        default_landing_page: this.state.default_landing_page,
      };
      let res = await RolePermissionService.saveRole(inputData);
      if (res.status === 200) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        setTimeout(
          () => this.props.history.push("/roles"),
          global.redirect_time
        );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container ">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <UsersSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      <Link to={"/roles"}>
                        <button type="button" className="btn-success-outline">
                          Cancel
                        </button>
                      </Link>
                      {global.userPermissions.checkPermission("role-add") && (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          onClick={this.saveRoleData}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          {this.state.isSubmit
                            ? global.loader
                            : this.state.slug !== undefined
                            ? "Save"
                            : "Save"}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0 mt-3">
                  <Alerts
                    show={this.state.showAlertModal}
                    type={this.state.alertModalType}
                    title={this.state.alertModalTitle}
                    message={this.state.alertModalMessage}
                  />
                </div>
                  <div className="scrolling-carousel pd-l0">
                    <form id="floor_form">
                      <div className="form-group mg-t-30 row ml-0 mr-0">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label pd-b-0">
                            Role Name <span className="text-danger">*</span>
                          </label>
                          <input
                            name="role_name"
                            className="form-control"
                            autoComplete="off"
                            placeholder="Role Name"
                            value={this.state.role_name}
                            onChange={this.changeHandler}
                            maxLength="75"
                            tabIndex="1"
                          />
                          {this.state.errors.role_name ? (
                            <div className="text-danger">
                              {this.state.errors.role_name}
                            </div>
                          ) : (
                            this.validator.message(
                              "role_name",
                              this.state.role_name,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label pd-b-0">
                            Role Group <span className="text-danger">*</span>
                          </label>
                          <Select
                            styles={customStyles}
                            isClearable={true}
                            className="multiselect"
                            classNamePrefix={"my-custom-react-select"}
                            menuPlacement="auto"
                            value={this.state.selectedRoleGroupList}
                            options={this.state.roleGroupList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeRoleGroupList(value)
                            }
                            defaultValue={this.state.selectedRoleGroupList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.role_group ? (
                            <div className="text-danger">
                              {this.state.errors.role_group}
                            </div>
                          ) : (
                            this.validator.message(
                              "role_group",
                              this.state.role_group,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label pd-b-0">
                            Default Landing Page
                          </label>
                          <Select
                            styles={customStyles}
                            isClearable={true}
                            className="multiselect"
                            classNamePrefix={"my-custom-react-select"}
                            menuPlacement="auto"
                            value={this.state.selectedDefaultLandingPageList}
                            options={this.state.defaultLandingPageList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeDefaultLandingPageList(value)
                            }
                            defaultValue={this.state.selectedDefaultLandingPageList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                         
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deleteFloorModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteFloorModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteFloorModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteFloor(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default RolesForm;

import React from "react";
import { Modal } from "react-bootstrap";
//import Alerts from "../common/Alerts";
import ReactDatatable from "@ashvin27/react-datatable";
//import { Link } from "react-router-dom";
//import NoData from "../../common/NoData";
//import SettingsSidebar from "../../common/SettingsSidebar";
//import pluswIcon from "./../../../assets/images/plus.svg";
import { useState, useEffect } from "react";
import NoiseawareService from "../../../services/NoiseawareService";
import Moment from "moment";
import NoData from "../../common/NoData";
//import { ScrollingCarousel } from "@trendyol-js/react-carousel";
//import UnitMapping from "./UnitMapping";
//import NoiseawareActivity from "./NoiseawareActivity";

const NoiseawareActivity = (props) => {
  const [state, setState] = useState({
    total_record: 0,
    records: [],
    extraButtons: [],
    tableQueryString: "",
    customQueryString: "",
    filter_value: "",
    property_slug: props.propertySlug,
    activeClass: "alert-activity",
    columns: [
      {
        key: "created_at",
        text: "Alert Time",
        className: "hand-cursor",
        width: "100px",
        sortable: true,
        cell: (record) => {
          return record.created_at
            ? Moment(record.created_at).format(global.dateTimeFormat)
            : "";
        },
      },
      {
        key: "unit_no",
        text: "Unit No",
        className: "hand-cursor",
        width: "100px",
        sortable: true,
        cell: (record) => {
          return record.unit_name ? record.unit_name : record.unit_no;
        },
      },

      {
        key: "template_name",
        text: "Alert Name",
        className: "hand-cursor",
        width: "100px",
        sortable: false,
        cell: (record) => {
          return record.template_name
            ? record.template_name.charAt(0).toUpperCase() +
                record.template_name
                  .replace(/([A-Z])/g, " $1")
                  .trim()
                  .slice(1)
            : "";
        },
      },
      {
        key: "sent_to_user_roles",
        text: "Sent To Roles",
        className: "hand-cursor",
        width: "200px",
        sortable: false,
        cell: (record) => {
          return record.sent_to_user_roles ? record.sent_to_user_roles : "-";
        },
      },
      {
        key: "sent_to_users",
        text: "Sent To Users",
        className: "hand-cursor",
        width: "200px",
        sortable: false,
        cell: (record) => {
          return record.sent_to_users ? record.sent_to_users : "-";
        },
      },
      {
        key: "message",
        text: "Delivery Message",
        className: "hand-cursor text-left",
        width: "200px",
        sortable: false,
        cell: (record) => {
          if (record.message.length > 30) {
            return record.message ? (
              <>
                {" "}
                <p className="hand-cursor">
                  {record.message.substring(0, 30) + "..."}{" "}
                  <span
                    onClick={() => showMoreData(record.message)}
                    className="showmore"
                  >
                    {" "}
                    Show More{" "}
                  </span>
                </p>{" "}
              </>
            ) : (
              "-"
            );
          } else {
            return record.message ? record.message : "-";
          }
        },
      },
    ],
    config: {
      sort: {
        column: "",
        order: "",
      },
      key_column: "slug",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: false,
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found.",
      },
    },
  });

  const [showModel, setShowModel] = useState(false);
  const [modalData, setModalData] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async (queryString = "") => {
    var list = [];
    var totalRecords = 0;
    let res = await NoiseawareService.getActivityList(
      "property_slug=" + state.property_slug + "&" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : {};
      totalRecords = res.data ? res.data.total : 0;
    }
    setState({
      ...state,
      total_record: totalRecords,
      records: list,
    });
  };

  function showMoreData(data) {
    setShowModel(!showModel);
    setModalData(data);
  }

  const tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      setState({ ...state, tableQueryString: queryString });
    } else {
      queryString += "&" + state.customQueryString;
      setState({ ...state, tableQueryString: queryString });
    }

    if (data.filter_value !== state.filter_value) {
      clearTimeout(state.filter_time);
      setState({
        ...state,
        filter_time: setTimeout(() => getData(queryString), global.filter_time),
        filter_value: data.filter_value,
      });
    } else {
      getData(queryString);
    }
  };

  return (
    <>
      <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
        <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns alertsettingBtn">
          {showModel}
        </div>
        <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
          <div className="pd-lg-l-0 pd-xs-l-15">
            {state.total_record !== 0 ? (
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                config={
                  state.total_record > global.page_size
                    ? state.config
                    : state.config
                }
                records={state.records}
                columns={state.columns}
                extraButtons={state.extraButtons}
                dynamic={true}
                total_record={state.total_record}
                onChange={tableChangeHandler}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>
      <Modal id="adas" show={showModel} centered className="noiseawareshowmore">
        <Modal.Body className="width_402 p-4 border_radius_14">
          <div className="text-center">
            <span className="welcomeClose" onClick={() => showMoreData("")}>
              {global.closee}
            </span>
            <p>{modalData}</p>
            <div className="d-flex justify-content-between mt-3 row"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NoiseawareActivity;

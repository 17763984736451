////////////////////////////////////////////////////////////
//     							                          //
//  Program: SubMetroService.js                           //
//  Application: Services                                 //
//  Option: Used for Sub Metro list                       //
//  Developer: NP 						                  //
//  Date: 2022-01-04                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class SubMetroService {

    getSubMetro(queryString) {
        let endPoint = 'sub-metro'+ (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    createSubMetro(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'sub-metro';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.postCall(inputData);
        return response;
    }

    getSubMetroBySlug(slug) {
        let endPoint = 'sub-metro/' + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateSubMetro(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'sub-metro/' + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.putCall(inputData);
        return response;
    }

    deleteSubMetro(slug) {
        let endPoint = 'sub-metro/' + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
        let response = ApiService.deleteCall(inputData);
        return response;
    }
    
}

export default new SubMetroService()
////////////////////////////////////////////////////////////
//     							                          //
//  Program: ReservationService.js                              //
//  Application: Reservation                                    //
//  Option: Used for manage Reservation                       //
//  Developer: Ashish Kumar						                     //
//  Date: 2022-04-15                                        //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class ReservationService {
  getReservationList(queryString) {
    let endPoint =
      "reservation-details" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getReservation(slug) {
    let endPoint = "reservation-details/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createReservation(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "reservation-details";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateReservation(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "reservation-details/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  StoreGuestform(data) {
    let endPoint = "store-guest-form";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteReservation(slug) {
    let endPoint = "reservation-details/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  checkReservationAvailabilty(slug, slug2) {
    let endPoint = "get-block-reservation-date/" + slug + "/" + slug2;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  /* checkReservationAvailabilty(slug, slug2) {
    let endPoint = "get-block-reservation-date/" + slug +"/"+slug2;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  } */

  checkReservationListingUnavailable(slug) {
    let endPoint = "get-block-reservation-listing-date/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  checkReservationAvailabiltyForSameSlug(slug, slug2) {
    let endPoint = "get-update-block-reservation-date/" + slug + "/" + slug2;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getReservationUpdateNextCheckInDate(slug, unitSlug, date) {
    let endPoint =
      "get-block-reservation-update-checkout-date/" +
      slug +
      "/" +
      unitSlug +
      "/" +
      date;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getReservationNextCheckInDate(slug, listingSlug, date) {
    let endPoint =
      "get-block-reservation-checkout-date/" +
      slug +
      "/" +
      listingSlug +
      "/" +
      date;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  //for schedule
  // API: get-cleaning-schedule-list

  getHousekeepingList(queryString) {
    let endPoint =
      "get-cleaning-schedule-list" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportDataFilewise(data) {
    let endPoint = "reservation-export";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  importData(data) {
    let endPoint = "reservation-import";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.uploadFileCall(inputData);
    return response;
  }

  sendAccess(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "send-keycafe-access";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  revokeAccess(accessId) {
    let endPoint = "revoke-keycafe-access/" + accessId;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getKeyCafeAccess(slug) {
    let endPoint = "get-keycafe-access/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getResidentKeyCafeAccess(queryString) {
    let endPoint =
      "get-keycafe-resident-access" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getReservationOccupancyTax(queryString) {
    let endPoint =
      "get-reservation-occupancy-taxes" +
      (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getReservationEarningConfiguration(queryString) {
    let endPoint =
      "reservation-earning-configuration" +
      (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createHouseCleaning(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "add-house-cleaning";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteHouseCleaning(slug) {
    let endPoint = "delete-house-cleaning/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
  checkCleaningAvailabilty(slug) {
    let endPoint = "get-block-cleaning-date/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  /* START: Get Latch Access schedules for backend --------------------------- */
  getLatchAccess(slug) {
    let endPoint = `get-latch-access-by-refrec-id?slug=${slug}`;
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = { endPoint, addditionalHeaderData, postData };
    let response = ApiService.getCall(inputData);
    return response;
  }
  /* END: Get Latch Access schedules for backend --------------------------- */
  /* START: Guest Reviews List --------------------------- */
  getReviews(queryString) {
    let endPoint = "get-review-list" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  /* END: Guest Reviews List --------------------------- */

  getPresignedGuestIds(queryString) {
    let endPoint =
      "presigned-guest-details" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new ReservationService();

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: AgencyCategoryService.js                     //
//  Application: Amenity Type                             //
//  Option: Used for custom agency category               //
//  Developer: NP  						                  //
//  Date: 2022-01-19                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AgencyCategoryService {
  getAgencyCategory(queryString) {
    let endPoint = "agency-category"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createAgencyCategory(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "agency-category";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getAgencyCategoryBySlug(slug) {
    let endPoint = "agency-category/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateAgencyCategory(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "agency-category/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteAgencyCategory(slug) {
    let endPoint = "agency-category/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new AgencyCategoryService();

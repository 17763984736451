////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ExportAutomationDataHubSeetings.jsx                //
//  Application: Master                                   //
//  Option: to view Property Setup data                   //
//  Developer: Anil KumarGupta		                        //
//  Date: 2023-06-20                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup } from "react-bootstrap";
import deleteicon from "../../assets/images/delete.svg";
//import settings_img from "../../assets/images/settings.png";
import settings_img_two from "../../assets/images/settingsnew.png";
import automationicon from "./../../assets/images/automation.png";
import information_img from "./../../assets/images/information-button.png";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import DataHubAutomationService from "../../services/DataHubAutomationService";
import Alerts from "../common/Alerts";
import Select from "react-select";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import SettingsSidebar from "../common/SettingsSidebar";
import * as Vars from "./../../Vars";
import { Link } from "react-router-dom";
import Moment from "moment";
import TransferExport from "./TransferExport";
import DatePicker from "react-datepicker";
import PropertyService from "../../services/PropertyService";
import ReportSidebar from "../common/ReportSidebar";
import AllPropertyReportSidebar from "../common/AllPropertyReportSidebar";
import JoditEditor from "jodit-react";

const AutomationModules = Vars.default.ExportAutomationModules;
const ReadIntervalTypes = Vars.default.ExportReadIntervalTypes;
const ReadIntervalDurations = Vars.default.ExportReadIntervalDurations;
const WeeklyIntervalList = Vars.default.ExportWeeklyInterval;
const MonthlyIntervalList = Vars.default.ExportMonthlyInterval;
const ExportMonthlyDayList = Vars.default.ExportMonthlyDayInterval;
//const DatabaseCols = Vars.default.DatabaseCols
// const DatabaseColMapping = Vars.default.DatabaseColMapping
const EncriptionTypes = Vars.default.EncriptionTypes;
const ExportSendType = Vars.default.ExportSendType;
const ExportExtentionType = Vars.default.ExportAttachmentExtention;
const ExportDownloadType = Vars.default.ExportDownloadType;
const mockData = Vars.default.mockData;
export class ExportAutomationDataHubSeetings extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      addDataModal: false,
      searchDataModal: false,
      module_search: "",
      is_search: 0,
      editDataModal: false,
      deleteDataModal: false,
      AutomationModal: false,
      TestModal: false,
      AutomationSetting: [],
      AutomationSettingTotal: 0,
      AutomationModuleList: AutomationModules,
      selectedAutomationModule: [],
      ReadIntervalTypesList: ReadIntervalTypes,
      selectedReadIntervalTypes: [],
      WeeklyIntervalList: WeeklyIntervalList,
      selectedWeeklyInterval: [],
      MonthlyIntervalList: MonthlyIntervalList,
      MonthlyDayIntervalList: ExportMonthlyDayList,
      selectedMonthlyInterval: [],
      ExportSendTypeList: ExportSendType,
      ExportExtentionTypeList: ExportExtentionType,
      ExportDownloadTypeList: ExportDownloadType,
      SelectedAttachmentType: [],
      SelectedExportDownloadType: [],
      SelectedExportSendType: [],
      ReadIntervalDurationsList: ReadIntervalDurations,
      selectedReadIntervalDurations: [],
      automation_name: "",
      module_name: "",
      send_type: "",
      attachment_extention: "",
      export_download_type: "",
      mail_title_prefix: "",
      read_interval_duration: "",
      read_interval_type: "",
      weekly_interval: "",
      monthly_interval: "",
      monthly_day_interval: "",
      mail_encryption: "",
      attachment_name: "",
      manualy_interval_duration: "",
      mail_from: "",
      mail_to: "",
      selectedOption: null,
      is_report_page: false,
      is_report_url: "",
      pathname: window.location.pathname,
      moduleClassName:
        "row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0",
      property_slug: this.props.match.params.propertySlug,
      database_cols_mapping: [],
      DatabaseCols: [],
      selected_module: "",
      EncriptionTypes: EncriptionTypes,
      selectedEncriptionTypes: [],
      ExportSendType: ExportSendType,
      listSelectedKeys: [],
      selectedPropertyList: [],
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.opensearchModal = this.opensearchModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.runTestAutomation = this.runTestAutomation.bind(this);
    this.runTestMail = this.runTestMail.bind(this);
    this.openAutomationModal = this.openAutomationModal.bind(this);
    this.openTestMailModal = this.openTestMailModal.bind(this);
    this.saveData = this.saveData.bind(this);
    this.getDetails = this.getDetails.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.setContent = this.setContent.bind(this);
    // column name for transfer
    this.leftTableColumns = [
      {
        dataIndex: "title",
        title: "Column Name",
      },
    ];
    this.rightTableColumns = [
      {
        dataIndex: "title",
        title: "Name",
      },
    ];
    this.editorConfig = {
      events: {
        afterInit: (instance) => {
          this.jodit = instance;
        },
      },
      height: "300",
    };
  }

  componentDidMount() {
    this.getAllAutomationSetting();
    this.getPropertyList();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    let getSearch = nextProps.location.pathname;
    if (getSearch.includes("report")) {
      await this.setState({
        is_report_page: true,
        is_report_url: "&is_report_page=1",
      });
    }
  }

  onChange = (nextTargetKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
    const databaseCols = mockData
      .filter((mData) => nextTargetKeys.includes(mData.key))
      .map(({ key, title, value, module_name }) => ({
        key,
        title,
        value,
        module_name,
      }));

    this.setState({ database_cols_mapping: databaseCols });
  };

  // handleChangeImportColsList(event, index) {
  //   let elements = [...this.state.database_cols_mapping];
  //   elements[index].file_column = event.target.value;
  //   this.setState({ elements });
  // }

  /* to get  data */
  async getAllAutomationSetting() {
    this.setState({ showSpinner: true, loading: true });
    var AutomationSetting = [];
    var AutomationSettingTotal = 0;
    let inputData = {
      property_slug: this.state.property_slug ?? "",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    let res = await DataHubAutomationService.getExportAllAutomationSetting(
      inputData
    );
    if (global.successStatus.includes(res.status)) {
      AutomationSetting = res.data.data ? res.data.data : [];
      AutomationSettingTotal = AutomationSetting ? AutomationSetting.length : 0;
    }
    this.setState({
      AutomationSetting: AutomationSetting,
      AutomationSettingTotal: AutomationSettingTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  // handleCallback = (data) => {
  //   var database_cols = [];
  //   mockData.forEach((mData) => {
  //     data.forEach((callbackCols) => {
  //       if (callbackCols === mData.key) {
  //         database_cols = [...database_cols, mData];
  //       }
  //     })
  //   })
  //   this.setState({ database_cols_mapping: database_cols });
  // }

  async getPropertyList(queryString = "") {
    var propertyList = [];
    var propertyListTotal = 0;
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;
    }
    var newPropertyList =
      propertyListTotal > 0
        ? propertyList.map(({ slug, property_name }) => ({
            value: slug,
            label: property_name,
          }))
        : [];

    this.setState({
      propertyList: propertyList,
      propertyListTotal: propertyListTotal,
      newPropertyList: newPropertyList,
    });
  }

  handleChangeProperty(value) {
    let user_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_id.push(item.value);
      });
    }

    this.setState({
      selectedPropertyList: value,
      user_id: user_id,
    });
  }

  /* to save data */
  saveData = async (e) => {
    e.preventDefault();
    var property = [];
    var propertydata;
    // if (this.validator.allValid()) {
    if (
      this.customValidate() &&
      !this.validator.errorMessages.module_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      if (this.state.selectedPropertyList != null) {
        this.state.selectedPropertyList.forEach((item) => {
          property = [...property, item.value];
        });
        propertydata = property.join(",");
      }

      //filter database columns mapping to selected automation module
      let selectedAMSelectedKey = this.state.database_cols_mapping.filter(
        (data) => data.module_name == this.state.module_name
      );

      let inputData = {
        slug: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        automation_name: this.state.automation_name,
        send_type: this.state.send_type,
        read_interval_type: this.state.read_interval_type,
        weekly_interval: this.state.weekly_interval,
        monthly_day_interval: this.state.monthly_day_interval,
        read_interval_duration: this.state.read_interval_duration,
        manualy_interval_duration: this.state.manualy_interval_duration
          ? Moment(this.state.manualy_interval_duration).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
        monthly_interval: this.state.monthly_interval
          ? Moment(this.state.monthly_interval).format("YYYY-MM-DD HH:mm:ss")
          : "",
        property_slug:
          this.state.selectedPropertyList &&
          this.state.selectedPropertyList.length > 0
            ? propertydata
            : this.state.property_slug,
        is_parent:
          this.state.selectedPropertyList &&
          this.state.selectedPropertyList.length > 0
            ? true
            : false,
        module_name: this.state.module_name,
        attachment_name: this.state.attachment_name,
        attachment_extention: this.state.attachment_extention,
        export_download_type: this.state.export_download_type,
        mail_from: this.state.mail_from,
        mail_to: this.state.mail_to,
        mail_title_prefix: this.state.mail_title_prefix,
        body: this.state.body ? this.state.body : "",
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
        database_cols_mapping: selectedAMSelectedKey,
      };
      let res =
        await DataHubAutomationService.ExportAddOrUpdateAutomationSetting(
          inputData
        );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddModal();
        this.closesearchModal();
        this.setState({
          database_cols_mapping: [],
          DatabaseCols: [],
          selectedAutomationModule: [],
          selectedReadIntervalTypes: [],
          selectedWeeklyInterval: [],
          selectedMonthlyInterval: [],
          selectedReadIntervalDurations: [],
          automation_name: "",
          send_type: "",
          module_name: "",
          mail_title_prefix: "",
          read_interval_duration: "",
          weekly_interval: "",
          monthly_interval: "",
          monthly_day_interval: "",
          read_interval_type: "",
          mail_from: "",
          mail_to: "",
          mailencryption: "",
          attachment_name: "",
          attachment_extention: "",
          export_download_type: "",
          manualy_interval_duration: "",
          sort_order: "",
          status_id: true,
          slug: "",
          selectedEncriptionTypes: [],
        });
        this.getAllAutomationSetting();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    // }
  };

  /* To get details */
  async getDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      var selectedAutomationModule = null;
      var selectedWeeklyInterval = null;
      var selectedReadIntervalType = null;
      var selectedMonthlyDayInterval = null;
      var selectedSendType = [];
      var selectedAttachmentType = [];
      var SelectedExportDownloadType = [];
      // var selectedProperty = [];
      let inputData = {
        slug: slug,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      let res = await DataHubAutomationService.getExportAutomationSettingBySlug(
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.module_name = res.data.module_name ? res.data.module_name : "";
        resData.slug = res.data.slug ? res.data.slug : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;

        resData.automation_name = res.data.automation_name
          ? res.data.automation_name
          : "";
        resData.mail_title_prefix = res.data.mail_title_prefix
          ? res.data.mail_title_prefix
          : "";
        resData.attachment_name = res.data.attachment_name
          ? res.data.attachment_name
          : "";
        resData.mail_from = res.data.mail_from ? res.data.mail_from : "";
        resData.mail_to = res.data.mail_to ? res.data.mail_to : "";
        resData.export_property_id =
          res.data.export_property_id && res.data.export_property_id.length > 0
            ? res.data.export_property_id
            : "";
        resData.send_type = res.data.send_type ? res.data.send_type : "";

        resData.manualy_interval_duration = res.data.manualy_interval_duration
          ? Moment(res.data.manualy_interval_duration).format("MM/DD/YY h:mm A")
          : "";
        resData.monthly_interval = res.data.monthly_interval
          ? Moment(res.data.monthly_interval).format("MM/DD/YY h:mm A")
          : "";

        selectedAutomationModule = AutomationModules.filter((obj) => {
          return obj.value === res.data.module_name;
        });

        selectedReadIntervalType = ReadIntervalTypes.filter((object) => {
          return object.value == res.data.read_interval_type;
        });

        selectedWeeklyInterval = WeeklyIntervalList.filter((object) => {
          return object.value == res.data.weekly_interval;
        });

        selectedMonthlyDayInterval = ExportMonthlyDayList.filter((object) => {
          return object.value == res.data.monthly_day_interval;
        });

        ExportSendType.forEach((sendType) => {
          if (sendType.value == res.data.send_type) {
            selectedSendType = [...selectedSendType, sendType];
          }
        });

        ExportExtentionType.forEach((attachmentType) => {
          if (attachmentType.value == res.data.attachment_extention) {
            selectedAttachmentType = [
              ...selectedAttachmentType,
              attachmentType,
            ];
          }
        });

        ExportDownloadType.forEach((exportDownloadType) => {
          if (exportDownloadType.value == res.data.export_download_type) {
            SelectedExportDownloadType = [
              ...SelectedExportDownloadType,
              exportDownloadType,
            ];
          }
        });

        var selectedReadIntervalDuration = ReadIntervalDurations.filter(
          (object) => {
            return object.value == res.data.read_interval_duration;
          }
        );

        if (res.data.database_cols_mapping.length > 0) {
          var selectedProperty = [];
          // Process database_cols_mapping
          const databaseColsMapping = mockData.filter((mData) =>
            res.data.database_cols_mapping.some(
              (callbackCols) =>
                callbackCols.export_column_name === mData.value &&
                callbackCols.module_name === mData.module_name
            )
          );

          // Process selectedProperty
          selectedProperty =
            res.data.export_property_id &&
            res.data.export_property_id.length > 0 &&
            res.data.property_id == null
              ? res.data.export_property_id.map((item) => ({
                  value: item.slug,
                  label: item.property_name,
                }))
              : [];

          // Process listSelectedKeys
          const listSelectedKeys = databaseColsMapping.map((data) => data.key);
          // Update state once with all changes
          this.setState({
            // database_cols_mapping: databaseColsMapping,
            targetKeys: listSelectedKeys,
            manualy_interval_duration: resData.manualy_interval_duration,
            selectedPropertyList: selectedProperty,
          });
        }

        setTimeout(() => {
          this.setState({
            selectedAutomationModule: selectedAutomationModule,
            selectedReadIntervalTypes: selectedReadIntervalType,
            selectedWeeklyInterval: selectedWeeklyInterval,
            selectedMonthlyDayInterval: selectedMonthlyDayInterval,
            //ReadIntervalDurationsList: newReadIntervalDuration,
            selectedReadIntervalDurations: selectedReadIntervalDuration,
            // database_cols_mapping: NewUnitAvailabilityDatabaseColMapping,
            automation_name: resData.automation_name,
            mail_title_prefix: resData.mail_title_prefix,
            attachment_name: resData.attachment_name,
            manualy_interval_duration: resData.manualy_interval_duration,
            mail_from: resData.mail_from,
            mail_to: resData.mail_to,
            SelectedExportSendType: selectedSendType,
            SelectedAttachmentType: selectedAttachmentType,
            SelectedExportDownloadType: SelectedExportDownloadType,
            DatabaseCols: mockData.filter((item) => {
              return item.module_name == res.data.module_name;
            }),
            // selectedPropertyList: selectedProperty,
          });
        }, 100);
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update data */
  updateData = async (e) => {
    e.preventDefault();
    var property = [];
    var propertydata;
    // if (this.validator.allValid()) {
    if (this.customValidate() && !this.validator.errorMessages.module_name) {
      this.setState({ showSpinner: true, isSubmit: true });
      if (this.state.selectedPropertyList != null) {
        this.state.selectedPropertyList.forEach((item) => {
          property = [...property, item.value];
        });
        propertydata = property.join(",");
      }

      //set database_col_mapping keys
      const databaseCols = mockData
        .filter((mData) => this.state.targetKeys.includes(mData.key))
        .map(({ key, title, value, module_name }) => ({
          key,
          title,
          value,
          module_name,
        }));

      //filter database columns mapping to selected automation module
      let selectedAMSelectedKey = databaseCols.filter(
        (data) => data.module_name === this.state.module_name
      );

      let inputData = {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        export_automation_slug: this.state.slug,
        automation_name: this.state.automation_name,
        send_type: this.state.send_type,
        read_interval_type: this.state.read_interval_type,
        weekly_interval: this.state.weekly_interval,
        monthly_interval: this.state.monthly_interval
          ? Moment(this.state.monthly_interval).format("YYYY-MM-DD HH:mm:ss")
          : "",
        monthly_day_interval: this.state.monthly_day_interval,
        read_interval_duration: this.state.read_interval_duration,
        manualy_interval_duration: this.state.manualy_interval_duration
          ? Moment(this.state.manualy_interval_duration).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
        property_slug:
          this.state.selectedPropertyList &&
          this.state.selectedPropertyList.length > 0
            ? propertydata
            : this.state.property_slug,
        is_parent:
          this.state.selectedPropertyList &&
          this.state.selectedPropertyList.length > 0
            ? true
            : false,
        module_name: this.state.module_name,
        attachment_name: this.state.attachment_name,
        attachment_extention: this.state.attachment_extention,
        export_download_type: this.state.export_download_type,
        mail_title_prefix: this.state.mail_title_prefix,
        mail_from: this.state.mail_from,
        mail_to: this.state.mail_to,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
        database_cols_mapping: selectedAMSelectedKey,
        body: this.state.body ? this.state.body : "",
      };

      let res =
        await DataHubAutomationService.ExportAddOrUpdateAutomationSetting(
          inputData
        );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditModal();
        this.setState({
          export_automation_slug: "",
          automation_name: "",
          send_type: "",
          weekly_interval: "",
          monthly_interval: "",
          monthly_day_interval: "",
          mail_from: "",
          mail_to: "",
          database_cols_mapping: [],
          DatabaseCols: [],
          selectedAutomationModule: [],
          selectedReadIntervalTypes: [],
          selectedWeeklyInterval: [],
          selectedMonthlyInterval: [],
          selectedReadIntervalDurations: [],
          module_name: "",
          mail_title_prefix: "",
          read_interval_duration: "",
          read_interval_type: "",
          mail_encryption: "",
          attachment_name: "",
          attachment_extention: "",
          export_download_type: "",
          manualy_interval_duration: "",
          sort_order: "",
          status_id: true,
          selectedEncriptionTypes: [],
        });
        this.getAllAutomationSetting();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
    // }
  };

  /* to delete data */
  async deleteData(slug) {
    this.setState({ showSpinner: true });
    let res = await DataHubAutomationService.deleteExportAutomationSetting(
      slug
    );
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteAmenityModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getAllAutomationSetting();
    } else {
      this.closeDeleteAmenityModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteAmenityModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  handleChangeDropdownList(value) {
    var selectedCols = mockData.filter((item) => {
      return item.module_name === value.value;
    });
    this.setState({
      module_name: value.value,
      selectedAutomationModule: [value],
      DatabaseCols: selectedCols,
      selectedKey: [],
      moduleClassName:
        "row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0 export_auto_scroll",
    });
  }

  handleChangeSendTypeList(value) {
    this.setState({
      send_type: value.value,
      SelectedExportSendType: [value],
    });
  }

  handleChangeExportTypeList(value) {
    this.setState({
      attachment_extention: value.value,
      SelectedAttachmentType: [value],
    });
  }

  handleChangeExportDownloadTypeList(value) {
    this.setState({
      export_download_type: value.value,
      SelectedExportDownloadType: [value],
    });
  }

  handleChangeReadIntervalType(value) {
    let newReadIntervalDuration = ReadIntervalDurations.filter(function (item) {
      return item.interval_type == value.interval_type;
    });
    setTimeout(() => {
      this.setState({
        read_interval_type: value.value,
        selectedReadIntervalTypes: [value],
        ReadIntervalDurationsList: newReadIntervalDuration,
        selectedReadIntervalDurations: [],
        read_interval_duration: "",
      });
    }, 100);
    if (this.state.read_interval_type != 2) {
      this.setState({
        weekly_interval: "",
        selectedWeeklyInterval: "",
      });
    }
    if (this.state.read_interval_type != 3) {
      this.setState({
        monthly_interval: "",
        monthly_day_interval: "",
        selectedMonthlyInterval: "",
      });
    }
    if (this.state.read_interval_type != 4) {
      this.setState({
        manualy_interval_duration: "",
      });
    }
  }

  handleChangeWeeklyIntervalType(value) {
    let newWeekIntervalDuration = WeeklyIntervalList.filter(function (item) {
      return item.interval_type == value.interval_type;
    });
    setTimeout(() => {
      this.setState({
        weekly_interval: value.value,
        selectedWeeklyInterval: [value],
        WeeklyIntervalList: newWeekIntervalDuration,
        selectedReadIntervalDurations: [],
      });
    }, 100);
  }

  handleChangeMonthlyIntervalType(value) {
    let newMonthIntervalDuration = MonthlyIntervalList.filter(function (item) {
      return item.interval_type == value.interval_type;
    });
    setTimeout(() => {
      this.setState({
        monthly_interval: value.value,
        selectedMonthlyInterval: [value],
        WeeklyIntervalList: newMonthIntervalDuration,
        selectedReadIntervalDurations: [],
      });
    }, 100);
  }

  handleChangeMonthlyDayIntervalType(value) {
    let newMonthIntervalDuration = ExportMonthlyDayList.filter(function (item) {
      return item.interval_type == value.interval_type;
    });
    setTimeout(() => {
      this.setState({
        monthly_day_interval: value.value,
        selectedMonthlyDayInterval: [value],
        MonthlyDayIntervalList: newMonthIntervalDuration,
        selectedReadIntervalDurations: [],
      });
    }, 100);
  }

  handleChangeReadIntervalDuration(value) {
    this.setState({
      read_interval_duration: value.value,
      selectedReadIntervalDurations: [value],
    });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /*for modal */
  openAddModal() {
    var EmptyDbCols = this.state.database_cols_mapping.map((item) => {
      item.file_column = "";
      return item;
    });
    this.setState({ addDataModal: true, database_cols_mapping: EmptyDbCols });
  }

  /*for search modal */
  opensearchModal() {
    this.setState({ searchDataModal: true });
  }

  closeAddModal() {
    this.setState({ addDataModal: false });
    this.setState({
      database_cols_mapping: [],
      DatabaseCols: [],
      selectedAutomationModule: [],
      selectedReadIntervalTypes: [],
      selectedWeeklyInterval: [],
      selectedReadIntervalDurations: [],
      module_name: "",
      automation_name: "",
      mail_title_prefix: "",
      read_interval_duration: "",
      read_interval_type: "",
      mail_encryption: "",
      attachment_name: "",
      attachment_extention: "",
      export_download_type: "",
      manualy_interval_duration: "",
      mail_from: "",
      mail_to: "",
      SelectedExportSendType: "",
      SelectedAttachmentType: "",
      SelectedExportDownloadType: "",
      send_type: "",
      sort_order: "",
      status_id: true,
      slug: "",
      selectedEncriptionTypes: [],
      errors: {},
      body: "",
    });
    this.validator.hideMessages();
  }

  closesearchModal() {
    this.setState({ searchDataModal: false });
    this.setState({
      database_cols_mapping: [],
      DatabaseCols: [],
      selectedAutomationModule: [],
      selectedReadIntervalTypes: [],
      selectedWeeklyInterval: [],
      selectedReadIntervalDurations: [],
      module_name: "",
      automation_name: "",
      mail_title_prefix: "",
      read_interval_duration: "",
      read_interval_type: "",
      mail_encryption: "",
      attachment_name: "",
      attachment_extention: "",
      export_download_type: "",
      manualy_interval_duration: "",
      mail_from: "",
      mail_to: "",
      SelectedExportSendType: "",
      SelectedAttachmentType: "",
      SelectedExportDownloadType: "",
      send_type: "",
      sort_order: "",
      status_id: true,
      slug: "",
      selectedEncriptionTypes: [],
      errors: {},
    });
    this.validator.hideMessages();
  }

  openEditModal(slug) {
    this.getDetails(slug);
    this.setState({ editDataModal: true });
    this.setState({ slug: slug });
  }

  closeEditModal() {
    this.setState({ editDataModal: false });
    this.setState({
      database_cols_mapping: [],
      DatabaseCols: [],
      errors: "",
      selectedAutomationModule: [],
      selectedReadIntervalTypes: [],
      selectedWeeklyInterval: [],
      selectedReadIntervalDurations: [],
      module_name: "",
      automation_name: "",
      mail_title_prefix: "",
      read_interval_duration: "",
      read_interval_type: "",
      mail_encryption: "",
      attachment_name: "",
      attachment_extention: "",
      export_download_type: "",
      manualy_interval_duration: "",
      mail_from: "",
      mail_to: "",
      SelectedExportSendType: "",
      SelectedAttachmentType: "",
      SelectedExportDownloadType: "",
      send_type: "",
      sort_order: "",
      status_id: true,
      slug: "",
      selectedEncriptionTypes: [],
      body: "",
    });
    this.validator.hideMessages();
  }

  openDeleteModal(slug) {
    this.setState({ deleteDataModal: true });
    this.setState({ slug: slug });
  }

  openAutomationModal(module, exportAutomationSlug) {
    this.setState({ AutomationModal: true });
    this.setState({ selected_module: module });
    this.setState({ selected_export_automation_slug: exportAutomationSlug });
  }
  openTestMailModal(module, exportAutomationSlug) {
    this.setState({ TestModal: true });
    this.setState({ selected_module: module });
    this.setState({ selected_export_automation_slug: exportAutomationSlug });
  }
  closeDeleteAmenityModal() {
    this.setState({ deleteDataModal: false });
    this.setState({ slug: "" });
  }

  closeAutomationModal() {
    this.setState({ AutomationModal: false });
    this.setState({ selected_module: "" });
    this.setState({ selected_export_automation_slug: "" });
  }
  closeMailModal() {
    this.setState({ TestModal: false });
    this.setState({ selected_module: "" });
    this.setState({ selected_export_automation_slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Data Export";
  }

  async runTestMail() {
    this.setState({ showSpinner: true, loading: true, isSubmit: true });
    let propertySlug = this.state.property_slug;
    let exportAutomationSlug = this.state.selected_export_automation_slug;
    let res = {};
    res = await DataHubAutomationService.ExportTestMailAutomation(
      propertySlug,
      exportAutomationSlug
    );
    if (global.successStatus.includes(res.status)) {
      this.setState({ showSpinner: false, loading: false, isSubmit: false });
      this.setState({
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        showAlertModal: true,
      });
      this.closeMailModal();
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  }

  async runTestAutomation() {
    this.setState({ showSpinner: true, loading: true, isSubmit: true });
    let inputData = this.state.property_slug;
    let propertySlug = this.state.property_slug;
    let exportAutomationSlug = this.state.selected_export_automation_slug;
    let res = {};
    if (this.state.selected_module === "unit_listing") {
      res = await DataHubAutomationService.TestUnitListExportAutomation(
        propertySlug,
        exportAutomationSlug
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showSpinner: false,
          loading: false,
          selected_module: "",
          AutomationModal: false,
          isSubmit: false,
        });
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      }
    }
    if (this.state.selected_module === "unit_availability") {
      res = await DataHubAutomationService.TestUnitAvailabilityImportAutomation(
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showSpinner: false,
          loading: false,
          selected_module: "",
          AutomationModal: false,
          isSubmit: false,
        });
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      }
    }
    if (this.state.selected_module === "leasing_listing") {
      res = await DataHubAutomationService.TestLeaseExportAutomation(
        propertySlug,
        exportAutomationSlug
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showSpinner: false,
          loading: false,
          selected_module: "",
          AutomationModal: false,
          isSubmit: false,
        });
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      }
    }
    if (this.state.selected_module === "reservation_listing") {
      res = await DataHubAutomationService.TestReservationListExportAutomation(
        propertySlug,
        exportAutomationSlug
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showSpinner: false,
          loading: false,
          selected_module: "",
          AutomationModal: false,
          isSubmit: false,
        });
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      }
    }
    this.getAllAutomationSetting();
  }

  handleChangeEncriptionType(value) {
    this.setState({
      mail_encryption: value.value,
      selectedEncriptionTypes: [value],
    });
  }

  setContent(value) {
    this.setState({
      body: value,
    });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <>
        <main>
          <Container className="innter-container auto_list_sec">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                {this.state.is_report_page ? (
                  this.state.property_slug !== undefined &&
                  this.state.property_slug !== "" ? (
                    <div className="col-md-2 left">
                      <div className="child-menu-section">
                        <aside>
                          <ReportSidebar
                            property_slug={this.state.property_slug}
                          />
                        </aside>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-2 left">
                      <div className="child-menu-section">
                        <AllPropertyReportSidebar
                          property_slug={this.state.property_slug}
                        />
                      </div>
                    </div>
                  )
                ) : this.state.property_slug !== undefined &&
                  this.state.property_slug !== "" ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <aside>
                        <SettingsSidebar
                          property_slug={this.state.property_slug}
                        />
                      </aside>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        <Button
                          variant="outline-dark btn-block btn-with-icon"
                          onClick={this.openAddModal}
                        >
                          <img src={pluswIcon} /> Add
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <Table hover className="mg-b-0">
                        <thead>
                          <tr>
                            <th className="w-40">Module</th>
                            <th className="w-40">Mail Subject</th>
                            <th className="w-40">Last Export Time</th>
                            <th className="text-center">Status</th>
                            {global.userPermissions.checkPermission(
                              "datahub-mapping-update"
                            ) && <th className="text-center">Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.AutomationSettingTotal > 0 ? (
                            this.state.AutomationSetting.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="hand-cursor"
                                    onClick={() => {
                                      global.userPermissions.checkPermission(
                                        "datahub-mapping-update"
                                      ) && this.openEditModal(item.slug);
                                    }}
                                  >
                                    {item.module_name == "unit_availability"
                                      ? "Unit Availability"
                                      : item.module_name == "unit_listing"
                                      ? "Units"
                                      : item.module_name == "leasing_listing"
                                      ? "Leases"
                                      : item.module_name ==
                                        "reservation_listing"
                                      ? "Reservation"
                                      : ""}
                                  </td>
                                  <td>
                                    {item.mail_title_prefix
                                      ? item.mail_title_prefix
                                      : ""}
                                  </td>

                                  <td className="date_tbl_formate">
                                    {item.last_sync_at
                                      ? Moment(item.last_sync_at).format(
                                          global.dateTimeFormat
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-center">
                                      {item.status_id === 1 ? (
                                        <b className="active m-0"></b>
                                      ) : (
                                        <b className="m-0"></b>
                                      )}
                                    </div>
                                  </td>
                                  {global.userPermissions.checkPermission(
                                    "datahub-mapping-delete"
                                  ) && (
                                    <td>
                                      <div className="d-flex justify-content-center">
                                        <div className="btn-icon-list d-block text-center">
                                          <div className="tooltip1">
                                            <img
                                              src={deleteicon}
                                              alt="Delete"
                                              onClick={() =>
                                                this.openDeleteModal(item.slug)
                                              }
                                              className="hand-cursor"
                                            />
                                            <span className="tooltiptext1 tooltiptext2">
                                              Delete
                                            </span>
                                          </div>
                                        </div>

                                        <div className="btn-icon-list d-block text-center autommation_icon ml-3">
                                          <div className="tooltip1">
                                            <img
                                              src={automationicon}
                                              alt="Automation"
                                              onClick={() =>
                                                this.openAutomationModal(
                                                  item.module_name,
                                                  item.slug
                                                )
                                              }
                                              className="hand-cursor"
                                            />
                                            <span className="tooltiptext1">
                                              Run Automation
                                            </span>
                                          </div>
                                        </div>

                                        <div className="btn-icon-list d-block text-center autommation_icon ml-3 tooltip1">
                                          <Link
                                            to={
                                              "/properties/view/" +
                                              this.state.property_slug +
                                              "/automation-processing-log-export/" +
                                              item.module_name +
                                              "?request_from=" +
                                              item.slug +
                                              this.state.is_report_url
                                            }
                                          >
                                            <img
                                              src={information_img}
                                              alt="Automation Log"
                                              className="hand-cursor"
                                            />
                                          </Link>
                                          <span className="tooltiptext1">
                                            Automation Logs
                                          </span>
                                        </div>

                                        {/* <div className="btn-icon-list d-block text-center autommation_icon ml-3 tooltip1">

                                          <img
                                            src={settings_img}
                                            alt="Test Email"
                                            onClick={() =>
                                              this.openTestMailModal(item.module_name, item.slug)
                                            }
                                            className="hand-cursor"
                                          />
                                          <span className="tooltiptext1 tooltiptext3">Test Email</span>
                                        </div> */}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                There are no data export added in the system.
                                You may add them by clicking Add button.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add popup modal */}
        <Modal
          show={this.state.addDataModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddModal();
          }}
          centered
          className="modal-large"
        >
          <Modal.Body className="width_402 border_radius_14 automation_pop_add automation_modal_hgt px-0">
            <span className="welcomeClose" onClick={() => this.closeAddModal()}>
              {global.closee}
            </span>
            <h3 className="text-center">Add</h3>

            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0  export_auto_scroll">
              {/* <div className={this.state.moduleClassName}> */}
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-0">
                  <label>Name</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="automation_name"
                    value={this.state.automation_name}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                  />
                </InputGroup>
                {this.state.errors.automation_name ? (
                  <div className="text-danger">
                    {this.state.errors.automation_name}
                  </div>
                ) : (
                  this.validator.message(
                    "name",
                    this.state.automation_name,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-0">
                  <label>Send Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      name="send_type"
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.ExportSendTypeList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeSendTypeList(value)}
                      defaultValue={this.state.ExportSendTypeList}
                      value={this.state.SelectedExportSendType}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.send_type ? (
                  <div className="text-danger">
                    {this.state.errors.send_type}
                  </div>
                ) : (
                  this.validator.message(
                    "send_type",
                    this.state.send_type,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>
              {this.state.send_type == 1 ? (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <InputGroup className="mt-3">
                      <label>Subject</label>
                      <Form.Control
                        onChange={this.changeHandler}
                        name="mail_title_prefix"
                        value={this.state.mail_title_prefix}
                        className="form-control max_width_100"
                        id="inlineFormInput"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                      />
                    </InputGroup>
                    {this.state.errors.mail_title_prefix ? (
                      <div className="text-danger">
                        {this.state.errors.mail_title_prefix}
                      </div>
                    ) : (
                      this.validator.message(
                        "subject_from",
                        this.state.mail_title_prefix,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <InputGroup className="mt-3">
                      <label>Mail From</label>
                      <Form.Control
                        onChange={this.changeHandler}
                        name="mail_from"
                        value={this.state.mail_from}
                        className="form-control max_width_100"
                        id="inlineFormInput"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                      />
                    </InputGroup>
                    {this.state.errors.mail_from ? (
                      <div className="text-danger">
                        {this.state.errors.mail_from}
                      </div>
                    ) : (
                      this.validator.message(
                        "mail_from",
                        this.state.mail_from,
                        "email|required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <InputGroup className="mt-3">
                      <label>Mail To</label>
                      <Form.Control
                        onChange={this.changeHandler}
                        name="mail_to"
                        value={this.state.mail_to}
                        className="form-control max_width_100"
                        id="inlineFormInput"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                      />
                    </InputGroup>
                    {this.state.errors.mail_to ? (
                      <div className="text-danger">
                        {this.state.errors.mail_to}
                      </div>
                    ) : (
                      this.validator.message(
                        "mail_to",
                        this.state.mail_to,
                        "email|required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Export File Name</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="attachment_name"
                    value={this.state.attachment_name}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                  />
                </InputGroup>
                {this.state.errors.attachment_name ? (
                  <div className="text-danger">
                    {this.state.errors.attachment_name}
                  </div>
                ) : (
                  this.validator.message(
                    "export_file_name",
                    this.state.attachment_name,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Extention</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      name="attachment_extention"
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.ExportExtentionTypeList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeExportTypeList(value)
                      }
                      defaultValue={this.state.SelectedAttachmentType}
                      value={this.state.SelectedAttachmentType}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.attachment_extention ? (
                  <div className="text-danger">
                    {this.state.errors.attachment_extention}
                  </div>
                ) : (
                  this.validator.message(
                    "extention",
                    this.state.attachment_extention,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Schedule Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      name="read_interval_type"
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.ReadIntervalTypesList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeReadIntervalType(value)
                      }
                      defaultValue={this.state.selectedReadIntervalTypes}
                      value={this.state.selectedReadIntervalTypes}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.read_interval_type ? (
                  <div className="text-danger">
                    {this.state.errors.read_interval_type}
                  </div>
                ) : (
                  this.validator.message(
                    "Schedule Type",
                    this.state.read_interval_type,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              {this.state.read_interval_type == 2 ? (
                <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                  <InputGroup className="mt-3">
                    <label>Day Selection</label>
                    <div className="col-md-12 p-0">
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        name="weekly_interval"
                        menuPlacement="auto"
                        className="multiselect"
                        classNamePrefix={"my-custom-react-select"}
                        options={this.state.WeeklyIntervalList}
                        getOptionValue={(option) => `${option.label}`}
                        onChange={(value) =>
                          this.handleChangeWeeklyIntervalType(value)
                        }
                        defaultValue={this.state.selectedWeeklyInterval}
                        value={this.state.selectedWeeklyInterval}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>
                  </InputGroup>
                  {this.state.errors.weekly_interval ? (
                    <div className="text-danger">
                      {this.state.errors.weekly_interval}
                    </div>
                  ) : (
                    this.validator.message(
                      "weekly_interval",
                      this.state.weekly_interval,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              ) : (
                ""
              )}

              {this.state.read_interval_type == 3 ? (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <InputGroup className="mt-3">
                      <label>Month Selection</label>
                      <DatePicker
                        className="form-control max_width_100"
                        name="monthly_interval"
                        disabledKeyboardNavigation
                        strictParsing
                        scrollableYearDropdown
                        timeInputLabel
                        showMonthDropdown
                        showTimeInput
                        showYearDropdown
                        autoComplete="off"
                        dateFormat="MM/dd/yy h:mm a"
                        selected={this.state.monthly_interval}
                        onChange={(data) =>
                          this.changeHandler({
                            target: {
                              type: "date",
                              name: "monthly_interval",
                              value: data,
                            },
                          })
                        }
                        minDate={new Date()}
                      />
                      {/* <div className="col-md-12 p-0">
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        menuPlacement="auto"
                        className="multiselect"
                        options={this.state.MonthlyIntervalList}
                        getOptionValue={(option) => `${option.label}`}
                        onChange={(value) => this.handleChangeMonthlyIntervalType(value)}
                        defaultValue={this.state.selectedMonthlyInterval}
                        value={this.state.selectedMonthlyInterval}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div> */}
                    </InputGroup>
                    {this.state.errors.monthly_interval ? (
                      <div className="text-danger">
                        {this.state.errors.monthly_interval}
                      </div>
                    ) : (
                      this.validator.message(
                        "monthly_interval",
                        this.state.monthly_interval,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                  <InputGroup className="mt-3">
                    <label>Monthly Day Interval</label>
                    <div className="col-md-12 p-0">
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        menuPlacement="auto"
                        className="multiselect"
                        options={this.state.MonthlyDayIntervalList}
                        getOptionValue={(option) => `${option.label}`}
                        onChange={(value) => this.handleChangeMonthlyDayIntervalType(value)}
                        defaultValue={this.state.selectedMonthlyDayInterval}
                        value={this.state.selectedMonthlyDayInterval}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>
                  </InputGroup>
                  {this.state.errors.monthly_day_interval ? (
                    <div className="text-danger">
                      {this.state.errors.monthly_day_interval}
                    </div>
                  ) : (
                    this.validator.message(
                      "monthly_day_interval",
                      this.state.monthly_day_interval,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div> */}
                </>
              ) : (
                ""
              )}

              {this.state.read_interval_type != 4 &&
              this.state.read_interval_type != 3 ? (
                <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                  <InputGroup className="mt-3">
                    <label>Schedule At</label>
                    <div className="col-md-12 p-0">
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        name="read_interval_duration"
                        menuPlacement="auto"
                        className="multiselect"
                        classNamePrefix={"my-custom-react-select"}
                        options={this.state.ReadIntervalDurationsList}
                        getOptionValue={(option) => `${option.label}`}
                        onChange={(value) =>
                          this.handleChangeReadIntervalDuration(value)
                        }
                        defaultValue={this.state.selectedReadIntervalDurations}
                        value={this.state.selectedReadIntervalDurations}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>
                  </InputGroup>
                  {this.state.errors.read_interval_duration ? (
                    <div className="text-danger">
                      {this.state.errors.read_interval_duration}
                    </div>
                  ) : (
                    this.validator.message(
                      "schedule_at",
                      this.state.read_interval_duration,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              ) : (
                ""
              )}

              {this.state.read_interval_type == 4 ? (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <label className="mt-3">Manually Duration</label>
                    <DatePicker
                      className="form-control max_width_100"
                      name="manualy_interval_duration"
                      disabledKeyboardNavigation
                      strictParsing
                      scrollableYearDropdown
                      timeInputLabel
                      showMonthDropdown
                      showTimeInput
                      showYearDropdown
                      autoComplete="off"
                      dateFormat="MM/dd/yy h:mm a"
                      selected={this.state.manualy_interval_duration}
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "manualy_interval_duration",
                            value: data,
                          },
                        })
                      }
                      minDate={new Date()}
                    />
                    {this.state.errors.manualy_interval_duration ? (
                      <div className="text-danger">
                        {this.state.errors.manualy_interval_duration}
                      </div>
                    ) : (
                      this.validator.message(
                        "manualy_interval_duration",
                        this.state.manualy_interval_duration,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Data Export Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      name="export_download_type"
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.ExportDownloadTypeList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeExportDownloadTypeList(value)
                      }
                      defaultValue={this.state.SelectedExportDownloadType}
                      value={this.state.SelectedExportDownloadType}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.export_download_type ? (
                  <div className="text-danger">
                    {this.state.errors.export_download_type}
                  </div>
                ) : (
                  this.validator.message(
                    "data_export_type",
                    this.state.export_download_type,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Module</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      name="module_name"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.AutomationModuleList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeDropdownList(value)}
                      defaultValue={this.state.selectedAutomationModule}
                      value={this.state.selectedAutomationModule}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.module_name ? (
                  <div className="text-danger">
                    {this.state.errors.module_name}
                  </div>
                ) : (
                  this.validator.message(
                    "module_name",
                    this.state.module_name,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              {this.state.property_slug == undefined ||
              this.state.property_slug == "" ? (
                <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                  <InputGroup className="mt-3">
                    <label>Properties</label>
                    <div className="col-md-12 p-0">
                      <Select
                        isMulti
                        styles={customStyles}
                        isClearable={true}
                        name="property_slug"
                        className="multiselect"
                        classNamePrefix={"my-custom-react-select"}
                        menuPlacement="auto"
                        value={this.state.selectedPropertyList}
                        options={this.state.newPropertyList}
                        onChange={(value) => this.handleChangeProperty(value)}
                        defaultValue={this.state.selectedPropertyList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>
                  </InputGroup>
                  {this.state.errors.property_slug ? (
                    <div className="text-danger">
                      {this.state.errors.property_slug}
                    </div>
                  ) : (
                    this.validator.message(
                      "property",
                      this.state.property_slug,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              ) : (
                ""
              )}

              {/* Add transfer */}
              {this.state.DatabaseCols.length > 0 ? (
                <div className="col-lg-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0 mt-3">
                  {/* <TransferExport selectedCols={this.handleCallback} colsList={this.state.DatabaseCols}  />  */}
                  <TransferExport
                    className="data_export_move table_export_move"
                    titles={["Columns", "Selected Columns"]}
                    dataSource={this.state.DatabaseCols}
                    targetKeys={this.state.targetKeys}
                    // disabled={disabled}
                    showSearch={true}
                    onChange={this.onChange}
                    filterOption={(inputValue, item) =>
                      item.title.indexOf(inputValue) !== -1
                    }
                    filteredItems={mockData}
                    leftColumns={this.leftTableColumns}
                    rightColumns={this.rightTableColumns}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="col-lg-12 col-md-12 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0 mg-t-30">
                <div className="d-flex flex-wrap- justify-content-between align-items-center">
                  <label className="form-label text-left">Email Body</label>
                </div>
                <JoditEditor
                  config={this.editorConfig}
                  name="body"
                  id="body"
                  value={this.state.body}
                  onBlur={(newContent) => this.setContent(newContent)}
                  onChange={(newContent) => this.setContent(newContent)}
                />
                {this.state.errors.body ? (
                  <div className="text-danger">{this.state.errors.body}</div>
                ) : (
                  this.validator.message("body", this.state.body, "required", {
                    className: "text-danger",
                  })
                )}
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <div className="d-flex align-items-center justify-content-start mt-4">
                  <label className="form-label text-left mg-b-0 mg-r-20 status_automation_lable">
                    Status
                  </label>
                  <b className={this.state.status_id ? "active" : "inactive"}>
                    {this.state.status_id ? "active" : "inactive"}
                  </b>
                  <div
                    className={
                      this.state.status_id ? "az-toggle on" : "az-toggle"
                    }
                    onClick={() =>
                      this.setState({ status_id: !this.state.status_id })
                    }
                  >
                    <span></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit Export popup modal */}
        <Modal
          show={this.state.editDataModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditModal();
          }}
          centered
          className="modal-large"
        >
          <Modal.Body className="width_402 border_radius_14 automation_pop_add automation_modal_hgt px-0">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0 export_auto_scroll">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-0">
                  <label>Name</label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="automation_name"
                    value={this.state.automation_name}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                  />
                </InputGroup>
                {this.state.errors.automation_name ? (
                  <div className="text-danger">
                    {this.state.errors.automation_name}
                  </div>
                ) : (
                  this.validator.message(
                    "name",
                    this.state.automation_name,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-0">
                  <label>Send Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      name="send_type"
                      options={this.state.ExportSendTypeList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeSendTypeList(value)}
                      defaultValue={this.state.SelectedExportSendType}
                      value={this.state.SelectedExportSendType}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.send_type ? (
                  <div className="text-danger">
                    {this.state.errors.send_type}
                  </div>
                ) : (
                  this.validator.message(
                    "send_type",
                    this.state.send_type,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              {this.state.send_type == 1 ? (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <InputGroup className="mt-3">
                      <label>Subject</label>
                      <Form.Control
                        onChange={this.changeHandler}
                        name="mail_title_prefix"
                        value={this.state.mail_title_prefix}
                        className="form-control max_width_100"
                        id="inlineFormInput"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                      />
                    </InputGroup>
                    {this.state.errors.mail_title_prefix ? (
                      <div className="text-danger">
                        {this.state.errors.mail_title_prefix}
                      </div>
                    ) : (
                      this.validator.message(
                        "subject_from",
                        this.state.mail_title_prefix,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <InputGroup className="mt-3">
                      <label>Mail From</label>
                      <Form.Control
                        onChange={this.changeHandler}
                        name="mail_from"
                        value={this.state.mail_from}
                        className="form-control max_width_100"
                        id="inlineFormInput"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                      />
                    </InputGroup>
                    {this.state.errors.mail_from ? (
                      <div className="text-danger">
                        {this.state.errors.mail_from}
                      </div>
                    ) : (
                      this.validator.message(
                        "mail_from",
                        this.state.mail_from,
                        "email|required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <InputGroup className="mt-3">
                      <label>Mail To</label>
                      <Form.Control
                        onChange={this.changeHandler}
                        name="mail_to"
                        value={this.state.mail_to}
                        className="form-control max_width_100"
                        id="inlineFormInput"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                      />
                    </InputGroup>
                    {this.state.errors.mail_to ? (
                      <div className="text-danger">
                        {this.state.errors.mail_to}
                      </div>
                    ) : (
                      this.validator.message(
                        "mail_to",
                        this.state.mail_to,
                        "email|required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <InputGroup className="mt-3">
                      <label>Export File Name</label>
                      <Form.Control
                        onChange={this.changeHandler}
                        name="attachment_name"
                        value={this.state.attachment_name}
                        className="form-control max_width_100"
                        id="inlineFormInput"
                        type="text"
                        maxLength="50"
                        autoComplete="off"
                      />
                    </InputGroup>
                    {this.state.errors.attachment_name ? (
                      <div className="text-danger">
                        {this.state.errors.attachment_name}
                      </div>
                    ) : (
                      this.validator.message(
                        "export_file_name",
                        this.state.attachment_name,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <InputGroup className="mt-3">
                      <label>Extention</label>
                      <div className="col-md-12 p-0">
                        <Select
                          styles={customStyles}
                          isClearable={true}
                          menuPlacement="auto"
                          name="attachment_extention"
                          className="multiselect"
                          classNamePrefix={"my-custom-react-select"}
                          options={this.state.ExportExtentionTypeList}
                          getOptionValue={(option) => `${option.label}`}
                          onChange={(value) =>
                            this.handleChangeExportTypeList(value)
                          }
                          defaultValue={this.state.SelectedAttachmentType}
                          value={this.state.SelectedAttachmentType}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 2,
                            colors: {
                              ...theme.colors,
                              primary: "#fff",
                              primary75: "#000",
                              primary50: "#000",
                              primary25: "#000",
                            },
                          })}
                        />
                      </div>
                    </InputGroup>
                    {this.state.errors.attachment_extention ? (
                      <div className="text-danger">
                        {this.state.errors.attachment_extention}
                      </div>
                    ) : (
                      this.validator.message(
                        "extention",
                        this.state.attachment_extention,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Schedule Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      name="read_interval_type"
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.ReadIntervalTypesList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeReadIntervalType(value)
                      }
                      defaultValue={this.state.selectedReadIntervalTypes}
                      value={this.state.selectedReadIntervalTypes}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.read_interval_type ? (
                  <div className="text-danger">
                    {this.state.errors.read_interval_type}
                  </div>
                ) : (
                  this.validator.message(
                    "schedule_type",
                    this.state.read_interval_type,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              {this.state.read_interval_type == 2 ? (
                <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                  <InputGroup className="mt-3">
                    <label>Day Selection</label>
                    <div className="col-md-12 p-0">
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        menuPlacement="auto"
                        className="multiselect"
                        classNamePrefix={"my-custom-react-select"}
                        name="weekly_interval"
                        options={this.state.WeeklyIntervalList}
                        getOptionValue={(option) => `${option.label}`}
                        onChange={(value) =>
                          this.handleChangeWeeklyIntervalType(value)
                        }
                        defaultValue={this.state.selectedWeeklyInterval}
                        value={this.state.selectedWeeklyInterval}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>
                  </InputGroup>
                  {this.state.errors.weekly_interval ? (
                    <div className="text-danger">
                      {this.state.errors.weekly_interval}
                    </div>
                  ) : (
                    this.validator.message(
                      "day_selection",
                      this.state.weekly_interval,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              ) : (
                ""
              )}

              {this.state.read_interval_type == 3 ? (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <InputGroup className="mt-3">
                      <label>Month Selection</label>
                      <DatePicker
                        className="form-control max_width_100"
                        name="monthly_interval"
                        disabledKeyboardNavigation
                        strictParsing
                        scrollableYearDropdown
                        timeInputLabel
                        showMonthDropdown
                        showTimeInput
                        showYearDropdown
                        autoComplete="off"
                        dateFormat="MM/dd/yy h:mm a"
                        selected={
                          this.state.monthly_interval
                            ? new Date(this.state.monthly_interval)
                            : ""
                        }
                        onChange={(data) =>
                          this.changeHandler({
                            target: {
                              type: "date",
                              name: "monthly_interval",
                              value: data,
                            },
                          })
                        }
                        minDate={new Date()}
                      />
                    </InputGroup>
                    {this.state.errors.monthly_interval ? (
                      <div className="text-danger">
                        {this.state.errors.monthly_interval}
                      </div>
                    ) : (
                      this.validator.message(
                        "month_selection",
                        this.state.monthly_interval,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {this.state.read_interval_type != 4 &&
              this.state.read_interval_type != 3 ? (
                <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                  <InputGroup className="mt-3">
                    <label>Schedule At</label>
                    <div className="col-md-12 p-0">
                      <Select
                        styles={customStyles}
                        isClearable={true}
                        menuPlacement="auto"
                        className="multiselect"
                        classNamePrefix={"my-custom-react-select"}
                        options={this.state.ReadIntervalDurationsList}
                        getOptionValue={(option) => `${option.label}`}
                        onChange={(value) =>
                          this.handleChangeReadIntervalDuration(value)
                        }
                        defaultValue={this.state.selectedReadIntervalDurations}
                        value={this.state.selectedReadIntervalDurations}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>
                  </InputGroup>
                  {this.state.errors.read_interval_duration ? (
                    <div className="text-danger">
                      {this.state.errors.read_interval_duration}
                    </div>
                  ) : (
                    this.validator.message(
                      "schedule_at",
                      this.state.read_interval_duration,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              ) : (
                ""
              )}

              {this.state.read_interval_type == 4 ? (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                    <label className="mt-3">Manually Duration</label>
                    <DatePicker
                      className="form-control max_width_100"
                      name="manualy_interval_duration"
                      disabledKeyboardNavigation
                      strictParsing
                      scrollableYearDropdown
                      timeInputLabel
                      showMonthDropdown
                      showTimeInput
                      showYearDropdown
                      autoComplete="off"
                      dateFormat="MM/dd/yy h:mm a"
                      selected={
                        this.state.manualy_interval_duration
                          ? new Date(this.state.manualy_interval_duration)
                          : ""
                      }
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "manualy_interval_duration",
                            value: data,
                          },
                        })
                      }
                      minDate={new Date()}
                    />
                    {this.state.errors.manualy_interval_duration ? (
                      <div className="text-danger">
                        {this.state.errors.manualy_interval_duration}
                      </div>
                    ) : (
                      this.validator.message(
                        "manually_duration",
                        this.state.manualy_interval_duration,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Data Export Type</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      name="export_download_type"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      options={this.state.ExportDownloadTypeList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) =>
                        this.handleChangeExportDownloadTypeList(value)
                      }
                      defaultValue={this.state.SelectedExportDownloadType}
                      value={this.state.SelectedExportDownloadType}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.export_download_type ? (
                  <div className="text-danger">
                    {this.state.errors.export_download_type}
                  </div>
                ) : (
                  this.validator.message(
                    "data_export_type",
                    this.state.export_download_type,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Module</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      menuPlacement="auto"
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      name="module_name"
                      options={this.state.AutomationModuleList}
                      getOptionValue={(option) => `${option.label}`}
                      onChange={(value) => this.handleChangeDropdownList(value)}
                      defaultValue={this.state.selectedAutomationModule}
                      value={this.state.selectedAutomationModule}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
                {this.state.errors.module_name ? (
                  <div className="text-danger">
                    {this.state.errors.module_name}
                  </div>
                ) : (
                  this.validator.message(
                    "module_name",
                    this.state.module_name,
                    "required",
                    { className: "text-danger" }
                  )
                )}
              </div>

              {this.state.property_slug == undefined ||
              this.state.property_slug == "" ? (
                <div className="col-lg-6 col-md-6 col-sm-6 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                  <InputGroup className="mt-3">
                    <label>Properties</label>
                    <div className="col-md-12 p-0">
                      <Select
                        isMulti
                        styles={customStyles}
                        isClearable={true}
                        className="multiselect"
                        classNamePrefix={"my-custom-react-select"}
                        name="property_slug"
                        menuPlacement="auto"
                        value={this.state.selectedPropertyList}
                        options={this.state.newPropertyList}
                        onChange={(value) => this.handleChangeProperty(value)}
                        defaultValue={this.state.selectedPropertyList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 2,
                          colors: {
                            ...theme.colors,
                            primary: "#fff",
                            primary75: "#000",
                            primary50: "#000",
                            primary25: "#000",
                          },
                        })}
                      />
                    </div>
                  </InputGroup>
                  {this.state.errors.property_slug ? (
                    <div className="text-danger">
                      {this.state.errors.property_slug}
                    </div>
                  ) : (
                    this.validator.message(
                      "property",
                      this.state.property_slug,
                      "required",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              ) : (
                ""
              )}

              {/* Edit Transfer */}
              {this.state.DatabaseCols.length > 0 ? (
                <div className="col-lg-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0 mt-3">
                  {/* <TransferExport selectedCols={this.handleCallback} colsList={this.state.DatabaseCols}
                    ={this.state.selectedKey} /> */}
                  <TransferExport
                    className="data_export_move table_export_move"
                    titles={["Columns", "Selected Columns"]}
                    dataSource={this.state.DatabaseCols}
                    targetKeys={this.state.targetKeys}
                    showSearch={true}
                    onChange={this.onChange}
                    filterOption={(inputValue, item) =>
                      item.title.indexOf(inputValue) !== -1
                    }
                    filteredItems={mockData}
                    leftColumns={this.leftTableColumns}
                    rightColumns={this.rightTableColumns}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="col-lg-12 col-md-12 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0 mg-t-30">
                <div className="d-flex flex-wrap- justify-content-between align-items-center">
                  <label className="form-label text-left">Email Body</label>
                </div>
                <JoditEditor
                  config={this.editorConfig}
                  name="body"
                  id="body"
                  value={this.state.body}
                  onBlur={(newContent) => this.setContent(newContent)}
                  onChange={(newContent) => this.setContent(newContent)}
                />
                {this.state.errors.body ? (
                  <div className="text-danger">{this.state.errors.body}</div>
                ) : (
                  this.validator.message("body", this.state.body, "required", {
                    className: "text-danger",
                  })
                )}
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <div className="d-flex align-items-center justify-content-start mt-4">
                  <label className="form-label text-left mg-b-0 mg-r-20 status_automation_lable">
                    Status
                  </label>
                  <b className={this.state.status_id ? "active" : "inactive"}>
                    {this.state.status_id ? "active" : "inactive"}
                  </b>
                  <div
                    className={
                      this.state.status_id ? "az-toggle on" : "az-toggle"
                    }
                    onClick={() =>
                      this.setState({ status_id: !this.state.status_id })
                    }
                  >
                    <span></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Confirm Test mail popup modal */}

        <Modal
          show={this.state.TestModal}
          onHide={() => {
            if (
              window.confirm(
                "Are you sure to exit the pop up without run automation?"
              )
            )
              this.closeMailModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeMailModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={settings_img_two} alt="modal-delete-icon" />
              <h3>Test Email?</h3>
              <p>
                Do you really want to run this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeMailModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={this.runTestMail}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Test"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete popup modal */}
        <Modal
          show={this.state.deleteDataModal}
          onHide={() => {
            if (
              window.confirm("Are you sure to exit the pop up without delete?")
            )
              this.closeDeleteAmenityModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeDeleteAmenityModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteAmenityModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteData(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Confirm Automation popup modal */}

        <Modal
          show={this.state.AutomationModal}
          onHide={() => {
            if (
              window.confirm(
                "Are you sure to exit the pop up without run automation?"
              )
            )
              this.closeAutomationModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAutomationModal()}
            >
              {global.closee}
            </span>
            <div className="text-center">
              <img src={automationicon} alt="modal-delete-icon" />
              <h3>Run the Automation?</h3>
              <p>
                Do you really want to run this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeAutomationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={this.runTestAutomation}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Run"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ExportAutomationDataHubSeetings;

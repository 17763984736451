import React from "react";
//import Alerts from "../common/Alerts";
import ReactDatatable from "@ashvin27/react-datatable";
import { Container, InputGroup, Form, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import KeycafeService from "../../../services/KeycafeService";
// import filterIcon from "./../../../assets/images/filter-gray.svg";
import Moment from "moment";

const ActivityHistory = (props) => {
  // const location = useLocation();
  // const [lockType, setLockType] = useState(1);
  const [state, setState] = useState({
    extraData: window.location.pathname.split("/")[4],
    total_record: 0,
    records: [],
    extraButtons: [],
    tableQueryString: "",
    customQueryString: "",
    filter_value: "",
    isSearch: global.isSearch,
    is_search: 0,
    tab: 1,
    unit_number: "",
    input: {},
    // property_slug: location.pathname.split('/')[4] === 'keys' ? props.match.params.propertySlug : props.property_slug,
    property_slug: props.property_slug ? props.property_slug : "",
    activeClass: "alert-activity",
    columns: [
      {
        key: "status_time",
        text: "Time",
        className: "available_from hand-cursor date_tbl_formate",
        sortable: true,
        cell: (record) => {
          return record.status_time
            ? Moment(record.status_time).format(global.dateTimeFormat)
            : "";
        },
      },
      {
        key: "type",
        text: "Activity",
        className: "tower_id hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.type ? record.type : "";
        },
      },

      {
        key: "exchanger_name",
        text: "Exchanger Name",
        className: "floor_id hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.exchanger_name ? record.exchanger_name : "";
        },
      },
      {
        key: "key_name",
        text: "Key Name",
        className: "unit_number hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.key_name ? record.key_name : "";
        },
      },
      {
        key: "serial_number",
        text: "Serial Number",
        className: "floor_plan_id hand-cursor text-left",
        sortable: false,
        cell: (record) => {
          return record.serial_number ? record.serial_number : "";
        },
      },

      /*{
                key: "access_method",
                text: "Access Method",
                className: "available hand-cursor",
                sortable: false,
                cell: (record) => {
                    return record.access_method ? record.access_method : "";
                },
            },
            {
                key: "access_given_name",
                text: "Access Given Name",
                className: "available_from hand-cursor",
                sortable: false,
                cell: (record) => {
                    return record.access_given_name
                        ? record.access_given_name
                        : "";
                },
            },*/
      {
        key: "bin_no",
        text: "Bin No",
        className: "available_from hand-cursor text-left",
        sortable: false,
        cell: (record) => {
          return record.bin_no ? record.bin_no : "";
        },
      },
    ],
    columns1: [
      {
        key: "status_time",
        text: "Time",
        className: "available_from hand-cursor date_tbl_formate",
        sortable: true,
        cell: (record) => {
          return record.status_time
            ? Moment(record.status_time).format(global.dateTimeFormat)
            : "";
        },
      },
      {
        key: "type",
        text: "Activity",
        className: "tower_id hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.type ? record.type : "";
        },
      },

      {
        key: "exchanger_name",
        text: "Exchanger Name",
        className: "floor_id hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.exchanger_name ? record.exchanger_name : "";
        },
      },
      {
        key: "key_name",
        text: "Unit Name",
        className: "unit_number hand-cursor",
        sortable: true,
        cell: (record) => {
          return record.key_name ? record.key_name : "";
        },
      },
      {
        key: "serial_number",
        text: "Door Name",
        className: "floor_plan_id hand-cursor text-left",
        sortable: false,
        cell: (record) => {
          return record.serial_number ? record.serial_number : "";
        },
      },
    ],
    config: {
      sort: {
        column: "status_time",
        order: "desc",
      },
      key_column: "slug",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: global.show_pagination,
      pagination: "advance",
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found.",
      },
    },
    config1: {
      sort: {
        column: "type",
        order: "desc",
      },
      key_column: "slug",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: global.show_pagination,
      pagination: "advance",
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found.",
      },
    },
    config2: {
      sort: {
        column: "exchanger_name",
        order: "desc",
      },
      key_column: "slug",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: global.show_pagination,
      pagination: "advance",
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found.",
      },
    },
    config3: {
      sort: {
        column: "key_name",
        order: "desc",
      },
      key_column: "slug",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: global.show_pagination,
      pagination: "advance",
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found.",
      },
    },
  });

  function searchToggle() {
    if (state.isSearch === 1) {
      setState({ ...state, isSearch: 0 });
    } else {
      setState({ ...state, isSearch: 1 });
    }
  }

  function changeHandler(event) {
    let input = state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    setState({ ...state, [event.target.name]: value, input: input });
  }

  const tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      setState({ ...state, tableQueryString: queryString });
    } else {
      queryString += "&" + state.customQueryString;
      setState({ ...state, tableQueryString: queryString });
    }

    if (data.filter_value !== state.filter_value) {
      clearTimeout(state.filter_time);
      setState({
        ...state,
        filter_time: setTimeout(() => getData(queryString), global.filter_time),
        filter_value: data.filter_value,
      });
    } else {
      getData("", queryString);
    }
  };

  function filterData() {
    let queryString =
      "unit_number=" +
      (state.unit_number ? state.unit_number : "") +
      "&is_search=1";

    let moduleSearchString = "";

    moduleSearchString = state.unit_number
      ? moduleSearchString
        ? moduleSearchString + " &" + " unit - " + state.unit_number
        : " unit - " + state.unit_number
      : moduleSearchString;

    if (state.unit_number) {
      setState({
        ...state,
        customQueryString: queryString,
        is_search: 1,
        module_search: moduleSearchString,
      });
      getData(queryString);
    }
  }

  function clearFilter() {
    setState({
      ...state,
      module_search: "",
      unit_number: "",
      is_search: 0,
    });
  }

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 200);
  }, [state.is_search, props.property_slug]);

  const getData = async (tab, queryString = "") => {
    var list = [];
    var totalRecords = 0;
    var unit_slug = props.unit_slug ? "&unit_slug=" + props.unit_slug : "";
    var unit_number = state.unit_number
      ? "&unit_number=" + state.unit_number
      : "";
    queryString = queryString + unit_slug + unit_number;
    queryString =
      queryString !== ""
        ? "property_slug=" +
          (props.property_slug ? props.property_slug : "") +
          (queryString ? "&" + queryString : "")
        : "property_slug=" +
          (props.property_slug
            ? props.property_slug
            : "" +
              "&tab=" +
              (tab ? tab : state.tab) +
              (queryString ? "&" + queryString : ""));
    let res = await KeycafeService.getKeycafeActivity(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : {};
      totalRecords = res.data ? res.data.total : 0;
    }
    // ... rest of the code
    // const isLetch = list.some((data) => {
    //   return data.lock_type === 2;
    // });
    // // set lock type
    // setLockType(isLetch ? 2 : 1);
    setState({
      ...state,
      total_record: totalRecords,
      records: list,
      isSearch: 0,
      is_search: state.unit_number === "" ? 0 : 1,
    });
  };

  return (
    <>
      {console.log(props.lock_type)}
      <main>
        {state.extraData === "keys" ? (
          <Container className="innter-container pr-0 over-fl">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-12">
                  <div className="scrolling-carousel">
                    <div>
                      <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                        {state.is_search === 1 ? (
                          <>
                            <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between  scrolling-carousel pd-t-30">
                              {"Search applied on: Unit Number" +
                                " - " +
                                state.unit_number}
                              <button
                                onClick={clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
                          <div className="pd-lg-l-0 pd-xs-l-15">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                              config={
                                state.total_record > global.page_size
                                  ? state.config
                                  : state.config
                              }
                              records={state.records}
                              columns={
                                props.lock_type === 1
                                  ? state.columns
                                  : state.columns1
                              }
                              extraButtons={state.extraButtons}
                              dynamic={true}
                              total_record={state.total_record}
                              onChange={tableChangeHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
            <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
              <div className="pd-lg-l-0 pd-xs-l-15">
                <ReactDatatable
                  className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                  config={
                    state.total_record > global.page_size
                      ? state.config
                      : state.config
                  }
                  records={state.records}
                  columns={
                    props.lock_type === 1 ? state.columns : state.columns1
                  }
                  extraButtons={state.extraButtons}
                  dynamic={true}
                  total_record={state.total_record}
                  onChange={tableChangeHandler}
                />
              </div>
            </div>
          </div>
        )}
      </main>
      <Modal
        show={state.isSearch}
        className="modal-large searchModalbox"
        // onHide={() => {
        //   if (window.confirm("Do you want to exit without saving?"))
        //     this.searchToggle();
        // }}
        centered
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            this.filterData();
          }
        }}
      >
        <Modal.Body className="width_402 p-4 border_radius_14">
          <span className="welcomeClose" onClick={() => searchToggle()}>
            {global.closee}
          </span>
          <h3 className="text-center">Search in Key History</h3>
          <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
            <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
              <InputGroup className="mt-3">
                <label>Unit</label>
                <div className="col-md-12 p-0">
                  <Form.Control
                    onChange={changeHandler}
                    name="unit_number"
                    value={state.unit_number}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    // placeholder="Amenity Name"
                  />
                </div>
              </InputGroup>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3 row">
            <button
              type="button"
              className="m-0 btn btn-outline-light btn-block"
              onClick={() => searchToggle()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="m-0 btn btn btn-success btn-block"
              onClick={filterData}
              disabled={state.isSubmit ? true : false}
            >
              {state.isSubmit ? global.loader : "Search"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActivityHistory;

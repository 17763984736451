////////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertyOverview.jsx                                //
//  Application: Dashboard                                //
//  Option: to view Property Information                   //
//  Developer: NP  						                            //
//  Date: 2022-04-11                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Modal } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";
import ListingService from "../../services/ListingService";
import Alerts from "../common/Alerts";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import propertyImg from "./../../assets/images/overview-placeholder.jpg";
import { Link } from "react-router-dom";
import MultiImageService from "../../services/MultiImageService";

class PropertyOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      publishData: false,
      property_slug: this.props.match.params.propertySlug,
      property_main_image_url: propertyImg,
      property_logo_url: propertyLogo,
      image_url: "",
      showSpinner: false,
      property_phone_number: "",
      orion_haus_phone_number: "",
      property_manager_phone_number: "",
    };
    this.publishListing = this.publishListing.bind(this);
  }

  componentDidMount() {
    this.setState({ publishData: true });
    this.getData();
    this.getMulitImagesData();
    this.setState({ publishData: false });
  }

  async getMulitImagesData() {
    if (this.state.property_slug !== undefined) {
      let inputData = {
        path: "properties",
        refTableId: 3,
        slug: this.state.property_slug,
        mediaType: 1,
      };
      var multiImageList = [];
      var multiImageListTotal = 0;
      var image_url = "";
      let res = await MultiImageService.getImages(inputData);

      if (global.successStatus.includes(res.status)) {
        multiImageList = res.data ? res.data : [];
        multiImageListTotal = multiImageList.length;
      }
      if (multiImageListTotal > 0) {
        multiImageList.map((opt, i) => {
          if (opt.is_main_img === 1) {
            image_url = opt.file_name;
          }
        });
      }
      this.setState({
        multiImageList: multiImageList,
        multiImageListTotal: multiImageListTotal,
        image_url: image_url,
      });
      // console.log("image_url", this.state.image_url);
    }
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      var property_phone_number = "";
      var property_manager_phone_number = "";
      var orion_haus_phone_number = "";
      let res = await PropertyService.getProperty(this.state.property_slug);

      //console.log(res.slug);
      if (global.successStatus.includes(res.status)) {
        sessionStorage.setItem(
          "property_slug",
          this.state.property_slug ? this.state.property_slug : ""
        );
        sessionStorage.setItem(
          "property_name",
          res.data.property_name ? res.data.property_name : ""
        );
        sessionStorage.setItem(
          "property_sku",
          res.data.aka_name ? res.data.aka_name : ""
        );
        sessionStorage.setItem(
          "property_type",
          res.data.property_type
            ? res.data.property_type.property_type_name
            : ""
        );
        sessionStorage.setItem(
          "year_value",
          res.data.year_value ? res.data.year_value : ""
        );
        sessionStorage.setItem(
          "property_logo_url",
          res.data.property_logo_url ? res.data.property_logo_url : propertyLogo
        );
        sessionStorage.setItem(
          "property_logo_thumb_url",
          res.data.property_logo_thumb_url
            ? res.data.property_logo_thumb_url
            : ""
        );

        localStorage.setItem("property_slug", this.state.property_slug);
        localStorage.setItem("property_name", res.data.property_name);
        localStorage.setItem("property_sku", res.data.aka_name);
        localStorage.setItem(
          "property_type",
          res.data.property_type.property_type_name
        );
        localStorage.setItem("year_value", res.data.year_value);
        localStorage.setItem("property_logo_url", res.data.property_logo_url);
        localStorage.setItem(
          "property_logo_thumb_url",
          res.data.property_logo_thumb_url
        );

        if (
          res.data &&
          res.data.ownership_type &&
          res.data.ownership_type.slug
        ) {
          localStorage.setItem("ownership", res.data.ownership_type.slug);
        }
        this.props.propertyName(res.data.property_name);
        this.props.propertySlug(res.data.slug);
        this.props.propertyLogo(res.data.property_logo_thumb_url);
        this.props.propertySKU(res.data.aka_name);

        resData.property_name = res.data.property_name
          ? res.data.property_name
          : "";
        resData.total_unit_count = res.data.total_unit_count
          ? res.data.total_unit_count
          : 0;
        resData.street_1 = res.data.street_1 ? res.data.street_1 : "";
        // resData.street_2 = res.data.street_2 ? res.data.street_2 : "";
        resData.country_name = res.data.country
          ? res.data.country.country_name
          : "";
        // resData.county_name = res.data.county ? res.data.county.county_name : "";
        resData.state_name = res.data.state ? res.data.state.state_name : "";
        resData.city_name = res.data.city ? res.data.city.city_name : "";
        resData.zip_code = res.data.zip_code ? res.data.zip_code : "";

        resData.asset_managers = res.data.asset_managers
          ? res.data.asset_managers
          : "";
        resData.property_email = res.data.property_email
          ? res.data.property_email
          : "";

        if (res.data && res.data.property_phone_number) {
          property_phone_number = await this.phoneNumberAutoFormat(
            res.data.property_phone_number
          );
        }
        resData.property_logo_url = res.data.property_logo_thumb_url
          ? res.data.property_logo_thumb_url
          : "";
        resData.property_main_image_url = res.data.property_main_image_url
          ? res.data.property_main_image_url
          : propertyImg;

        resData.orion_haus_account_manager_name =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.first_name
            ? res.data.orion_haus_account_manager.first_name
            : "";
        resData.orion_haus_email =
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.email
            ? res.data.orion_haus_account_manager.email
            : "";
        if (
          res.data &&
          res.data.orion_haus_account_manager &&
          res.data.orion_haus_account_manager.phone_number
        ) {
          orion_haus_phone_number = await this.phoneNumberAutoFormat(
            res.data.orion_haus_account_manager.phone_number
          );
        }

        resData.property_manager_name =
          res.data &&
          res.data.property_manager_info &&
          res.data.property_manager_info.first_name
            ? res.data.property_manager_info.first_name
            : "";
        resData.property_manager_email =
          res.data &&
          res.data.property_manager_info &&
          res.data.property_manager_info.email
            ? res.data.property_manager_info.email
            : "";
        if (
          res.data &&
          res.data.property_manager_info &&
          res.data.property_manager_info.phone_number
        ) {
          property_manager_phone_number = await this.phoneNumberAutoFormat(
            res.data.property_manager_info.phone_number
          );
        }

        localStorage.setItem(
          "propertyLockType",
          res.data.lock_type ? res.data.lock_type : 0
        );
      }
      this.setState({
        property_phone_number: property_phone_number,
        property_manager_phone_number: property_manager_phone_number,
        orion_haus_phone_number: orion_haus_phone_number,
      });
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    return finalnumber;
  };

  openPublishModal() {
    this.setState({ xmlRoleModal: true });
  }

  closePublishModal() {
    this.setState({ xmlRoleModal: false });
  }

  async publishListing() {
    this.setState({ publishData: true });
    let inputData = {
      property_slug: this.state.property_slug ? this.state.property_slug : "",
      property_name: this.state.property_name ? this.state.property_name : "",
    };
    let res = await ListingService.publishProperty(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        // alertModalMessage: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          res.data && res.data.result && res.data.result.error_list
            ? res.data.result.error_list[0]
            : "Error!",
        // errorList:
        //   res.data && res.data.result && res.data.result.error_list
        //     ? res.data.result.error_list
        //     : [],
      });
    }
    this.setState({ publishData: false });
    this.closePublishModal();
  }

  render() {
    return (
      <>
        <main>
          <Container>
            <div className="row row-xs">
              <div className="propertyNamemobview">
                <h4>
                  {this.props.property_name
                    ? this.props.property_name
                    : this.state.property_name
                    ? this.state.property_name
                    : "Property"}
                </h4>
              </div>
              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex flex-wrap- w-100 justify-content-between bothBtn align-items-center mg-t-30 prop-overivew">
                  <h3>Overview</h3>
                  <div className="d-flex">
                    <Link
                      to={
                        "/properties/view/" +
                        this.state.property_slug +
                        "/basic-new"
                      }
                    >
                      <button className="btn-success-outline mg-r-20">
                        View
                      </button>
                    </Link>

                    {global.userPermissions.checkPermission(
                      "property-publish"
                    ) && (
                      <button
                        className="btn-success-outline-small ml-3"
                        onClick={() => this.openPublishModal()}
                      >
                        Publish
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mg-l-0 mg-r-0 mg-t-30">
                  <Alerts
                    show={this.state.showAlertModal}
                    // show="true"
                    type={this.state.alertModalType}
                    title={this.state.alertModalTitle}
                    message={this.state.alertModalMessage}
                  />
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="row row-xs mg-t-0 pd-t-2 cardsection">
              <div className="col-md-5 col-lg-5 col-xl-3 mg-b-30 cardLeft">
                <div className="dashboardLeftcard az-profile-overview">
                  <div className="az-img-user avatar-md online mg-b-8 mt-0 overflow-hidden">
                    <figure>
                      {this.state.status_id === true ? (
                        <b className="status-active"></b>
                      ) : (
                        ""
                      )}
                      {this.state.status_id === false ? (
                        <b className="status-inactive"></b>
                      ) : (
                        ""
                      )}
                      <img
                        src={
                          this.props.imgFile
                            ? this.props.imgFile
                            : this.state.property_logo_url
                            ? this.state.property_logo_url
                            : propertyLogo
                        }
                        alt="Basic Property Photo"
                        className="rounded-circle"
                      />
                    </figure>
                  </div>
                  <div className="d-flex- justify-content-center- flex-wrap- flex-column- text-center mg-b-20 mg-t-20">
                    {/* <h4>{this.state.property_name ? this.state.property_name : ''}</h4> */}
                    <div className="overview">
                      <h3 className="mb-2">
                        {this.state.property_name
                          ? this.state.property_name
                          : ""}
                      </h3>
                    </div>
                    <p className="media-body p-0 m-0">
                      {this.state.street_1 ? this.state.street_1 : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.city_name ? this.state.city_name : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.zipcode ? this.state.zipcode : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.state_name ? this.state.state_name : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.country_name ? this.state.country_name : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.property_email
                        ? this.state.property_email
                        : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.property_phone_number
                        ? this.state.property_phone_number
                        : ""}
                    </p>

                    {/* manager info start */}
                    <div className="overview">
                      <h3 className="mg-t-30 mb-2">Property Manager</h3>
                    </div>
                    <p className="media-body p-0 m-0">
                      {this.state.property_manager_name
                        ? this.state.property_manager_name
                        : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.property_manager_email
                        ? this.state.property_manager_email
                        : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.property_manager_phone_number
                        ? this.state.property_manager_phone_number
                        : ""}
                    </p>

                    <div className="overview">
                      <h3 className="mg-t-30 mb-2">
                        Orion Haus Account Manager
                      </h3>
                    </div>
                    <p className="media-body p-0 m-0">
                      {this.state.orion_haus_account_manager_name
                        ? this.state.orion_haus_account_manager_name
                        : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.orion_haus_email
                        ? this.state.orion_haus_email
                        : ""}
                    </p>
                    <p className="media-body p-0 m-0">
                      {this.state.orion_haus_phone_number
                        ? this.state.orion_haus_phone_number
                        : ""}
                    </p>
                    {/* manager info end */}
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-7 col-xl-9 mg-b-30 cardRight">
                <div className="dashboardRightcard overview p-0">
                  <div className="row col-md-12 mg-t-0 mg-md-t-0 pd-20 pd-t-20 pd-b-20 ml-0 overview-section">
                    <div className="overview-image m-auto text-center">
                      <figure>
                        <img
                          src={
                            this.state.image_url
                              ? this.state.image_url
                              : this.state.property_main_image_url
                          }
                          alt="pro-image"
                          className="img-fluid"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        <Modal show={this.state.xmlRoleModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closePublishModal()}
              >
                {global.closee}
              </span>
              <h3>Publish the Option?</h3>
              <p>
                Do you really want to Publish this? This process cannot be
                undone.
              </p>

              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closePublishModal()}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={this.publishListing}
                >
                  {this.state.publishData ? global.loader : "Publish"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default PropertyOverview;

////////////////////////////////////////////////
//     							              //
//  Program: MonthService.js                  //
//  Application: common                       //
//  Option: Used formonths data               //
//  Developer: NP  						      //
//  Date: 2022-01-24                          //
//                                            //
////////////////////////////////////////////////

import ApiService from "./ApiService";

class MonthService {
  getMonths(queryString) {
    let endPoint = "fiscal-month"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new MonthService();

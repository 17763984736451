////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ApplicantsForm.jsx                                //
//  Application:  Events                                   //
//  Option: For add or update Events                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-09                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import ApplicantsService from "../../services/ApplicantsService";
import LeasingSidebar from "../common/LeasingSidebar";
import PhoneInput from "react-phone-input-2";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";

export class ApplicantsForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      sort_order: "",
      pathname: window.location.pathname,
      // photo: [],
      socialMediaList: [],
      newSocialMediaList: [],
      socialMediaListTotal: 0,
      selectedsocialMediaList: [],
      // photoUrl: Photo,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      notes: "",
    };
    // this.photoUrl = React.createRef();
    this.changeHandler = this.changeHandler.bind(this);
    // this.handleChangesocialMediaList = this.handleChangesocialMediaList.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.saveOrUpdateApplicants = this.saveOrUpdateApplicants.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      var applicantDetailsTotal = 0;
      let res = await ApplicantsService.getApplicantsBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.applicant_details = res.data ? res.data.applicant_details : [];
        applicantDetailsTotal = res.data.applicant_details
          ? res.data.applicant_details.length
          : 0;
      }
      this.setState(resData);
      this.setState({
        input: resData,
        applicantDetailsTotal: applicantDetailsTotal,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "applicant_photo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("h1", 235);
      fileData.append("h2", 250);
      let res = await ApplicantsService.uploadApplicantsImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          applicant_photo: res.data.fileName,
          applicant_photo_url: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  saveOrUpdateApplicants = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        property_slug: this.state.property_slug,
        email: this.state.email,
        phone_number: this.state.phone_number,
        dial_code : this.state.dial_code,
        applicant_photo: this.state.applicant_photo,
        notes: this.state.notes,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await ApplicantsService.updateApplicants(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/applicants/view/" +
                      this.state.slug
                  : "/applicants/view/" +
                      this.state.property_slug +
                      "/" +
                      this.state.slug
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      } else {
        let res = await ApplicantsService.createApplicants(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" +
                      this.state.property_slug +
                      "/applicants"
                  : "/applicants"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);

    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let errors = {};
    let isValid = true;
    this.setState({ errors: errors });
    return isValid;
  }

  async applicantsDelete(slug) {
    this.setState({ showSpinner: true });
    if (slug) {
      let res = await ApplicantsService.deleteApplicants(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
          newSocialMediaList: [],
        });
        setTimeout(
          () =>
            this.props.history.push(
              this.props.match.params.propertySlug !== undefined
                ? "/properties/view/" + this.state.property_slug + "/applicants"
                : "/applicants"
            ),
          global.redirect_time
        );
      } else {
        let alertMessage = "";
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Applicants";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LeasingSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLeasingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}

              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/applicants"
                                  : "/applicants/view/" +
                                      this.state.property_slug +
                                      "/" +
                                      this.state.slug
                              );
                            else return false;
                          else
                            this.props.history.push(
                              this.props.match.params.propertySlug !== undefined
                                ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/applicants"
                                : "/applicants/view/" +
                                    this.state.property_slug +
                                    "/" +
                                    this.state.slug
                            );
                        }}
                      >
                        Cancel
                      </button>

                      {this.state.slug !== undefined &&
                      global.userPermissions.checkPermission(
                        "applicants-update"
                      ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveOrUpdateApplicants}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : global.userPermissions.checkPermission(
                          "applicants-add"
                        ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveOrUpdateApplicants}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-15">
                  <form id="event_form">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                      <div className="col-md-12 pd-lg-l-30 pd-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0">
                        <div className="row align-items-center  pd-b-15 border-bottom-0 mg-t-30">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-15 pd-lg-r-30">
                            <label className="form-label text-left pd-b-5">
                              First Name
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="first_name"
                              value={this.state.first_name}
                              className="form-control max_width_100"
                              type="text"
                              // placeholder="Name"
                            />
                            {this.state.errors.first_name ? (
                              <div className="text-danger">
                                {this.state.errors.first_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "first_name",
                                this.state.first_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-15 pd-lg-r-30">
                            <label className="form-label text-left pd-b-5">
                              Last Name
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="last_name"
                              value={this.state.last_name}
                              className="form-control max_width_100"
                              type="text"
                              // placeholder="Name"
                            />
                            {this.state.errors.last_name ? (
                              <div className="text-danger">
                                {this.state.errors.last_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "last_name",
                                this.state.last_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-15 pd-lg-r-30">
                            <label className="form-label text-left pd-b-5">
                              Email
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="email"
                              value={this.state.email}
                              className="form-control max_width_100"
                              type="text"
                              // placeholder="Name"
                            />
                            {this.state.errors.email ? (
                              <div className="text-danger">
                                {this.state.errors.email}
                              </div>
                            ) : (
                              this.validator.message(
                                "email",
                                this.state.email,
                                "required|email",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-15 pd-lg-r-30">
                            <label className="form-label text-left pd-b-5">
                              Phone
                            </label>

                            <PhoneInput
                              country={global.country}
                              disableSearchIcon
                              // autoFormat= "true"
                              enableSearch="true"
                              countryCodeEditable="true"
                              value={this.state.phone_number}
                              onChange={(phone_number) =>
                                this.setState({ phone_number })
                              }
                            />
                            {this.state.errors.phone_number ? (
                              <div className="text-danger">
                                {this.state.errors.phone_number}
                              </div>
                            ) : (
                              this.validator.message(
                                "phone",
                                this.state.phone_number,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>

                        {/* <div className="row align-items-center pd-b-30 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                            <label className="form-label text-left pd-b-5">
                              Photo <span className="optional">(Optional)</span>
                            </label>

                            <div className="fileUpload d-flex flex-wrap justify-content-between">
                              <span>
                                <label className="hand-cursor">
                                  <input
                                    onChange={this.onImageChange}
                                    type="file"
                                    name="applicant_photo"
                                    tabIndex="2"
                                  />
                                  {this.state.applicant_photo_url ? (
                                    <img
                                      src={this.state.applicant_photo_url}
                                      alt="Photo"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </label>
                              </span>
                            </div>
                            {this.state.errors.applicant_photo ? (
                              <div className="text-danger">
                                {this.state.errors.applicant_photo}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div> */}

                        {/* <div className="row align-items-center pd-b-30 pd-l-30 pd-r-30 border-bottom-0">
                          <div className="col-md-1 pl-0">
                            <label className="form-label text-left pd-b-5">
                              Status
                            </label>
                          </div>

                          <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                            <b
                              className={
                                this.state.status_id ? "active" : "inactive"
                              }
                            ></b>
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() =>
                                this.setState({
                                  status_id: !this.state.status_id,
                                })
                              }
                            >
                              <span></span>
                            </div>
                          </div>
                        </div> */}

                        <div className="row align-items-center pd-b-30 border-bottom-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-15 pd-lg-l-30 pd-xs-r-15 pd-lg-r-30">
                            <label className="form-label text-left pd-b-5">
                              Notes <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                              // type="text"
                              as="textarea"
                              onChange={this.changeHandler}
                              name="notes"
                              value={this.state.notes}
                              maxLength="5000"
                            />
                            {this.state.errors.notes ? (
                              <div className="text-danger">
                                {this.state.errors.notes}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default ApplicantsForm;

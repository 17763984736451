//                                                           //
//  Program: CrmList.jsx                                 //
//  Application: Report                                      //
//  Option: CRM List Listing                         //
//  Developer: RK 		                                     //
//  Date: 2023-04-06                                         //
//                                                           //
/////////////////////////////////////////////////////////   ///

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import CrmListService from "../../services/CrmListService";
import { Link } from "react-router-dom";
import { Container, Nav, Tab } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import NoData from "../common/NoData";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import CrmSettingSidebar from "../common/CrmSettingSidebar";
import ContactSidebar from "../common/ContactSidebar";
import BrandSidebar from "../common/BrandSidebar";
import ClientSidebar from "../common/ClientSidebar";

export class CrmList extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    const pathStr = props.match.path;
    const pathArray = pathStr.split(":");
    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      module_search: "",
      is_search: 0,
      input: {},
      showAlertModal: false,
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : "",
      brand_slug:
        pathArray[0] === "/brand/view/" ? this.props.match.params.slug : "",
      client_slug:
        pathArray[0] === "/client/view/" ? this.props.match.params.slug : "",
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      list_name: "",
      description: "",
      no_of_members: "",
      records: [],
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "list_name",
          text: "List",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.list_name ? record.list_name : "";
          },
        },

        {
          key: "description",
          text: "Description",
          className: "description hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.description ? record.description : "";
          },
        },
        {
          key: "list_type",
          text: "List Type",
          className: "list_type hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.list_type === 1 ? (
              <p className="m-0">Auto</p>
            ) : record.list_type === 2 ? (
              <p className="m-0">Manual</p>
            ) : record.list_type === 3 ? (
              <p className="m-0">CSV</p>
            ) : (
              ""
            );
          },
        },
        {
          key: "total_recipients",
          text: "Recipients",
          className: "total_recipients hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.listCount ? record.listCount : "";
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id",
          sortable: true,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active"></b>
            ) : (
              <b className="status-inactive"></b>
            );
          },
        },
      ],
      tab: "active",
      clientTabData: localStorage.getItem("client_tab_data")
        ? localStorage.getItem("client_tab_data")
        : "",
      activeClass: "activeTab",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
  }

  componentDidMount() {
    switch (this.state.clientTabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "client_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "client_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await CrmListService.getCrmListData(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        "&brand_slug=" +
        (this.state.brand_slug ? this.state.brand_slug : "") +
        "&client_slug=" +
        (this.state.client_slug ? this.state.client_slug : "") +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  handleChangeStatusList(value) {
    let status_id = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData("tab=" + this.state.tab + "&" + queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData("tab=" + this.state.tab + "&" + queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.state.property_slug
        ? "/properties/view/" +
            this.state.property_slug +
            "/crm-list/view/" +
            data.slug
        : this.state.brand_slug
        ? "/brand/view/" + this.state.brand_slug + "/crm-list/view/" + data.slug
        : this.state.client_slug
        ? "/client/view/" +
          this.state.client_slug +
          "/crm-list/view/" +
          data.slug
        : "/crm-list/view/" + data.slug
    );
  };

  render() {
    return (
      <>
        <div>
          <main>
            <Container className="innter-container over-fl  container">
              <div className="d-flex flex-column">
                <div className="row row-xs mg-t-0 pd-t-0">
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <aside>
                        {this.state.property_slug ? (
                          <ContactSidebar
                            property_slug={this.state.property_slug}
                          />
                        ) : this.state.brand_slug ? (
                          <BrandSidebar brand_slug={this.state.brand_slug} />
                        ) : this.state.client_slug ? (
                          <ClientSidebar clientSlug={this.state.client_slug} />
                        ) : (
                          <CrmSettingSidebar />
                        )}
                      </aside>
                    </div>
                  </div>
                  <div className="col-md-10 right">
                    <div className="scrolling-carousel">
                      <div className="col-md-12 mg-t-0 mg-b-0 pd-t-0 pd-b-0 pd-l-15 pd-r-0">
                        <div className="row row-xs">
                          <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                          <div className="col-md-12">
                            <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center crm_list_header">
                              <h3 className="brnd_heading mb-0">Lists</h3>
                              {global.userPermissions.checkPermission(
                                "crm-lists-add"
                              ) && (
                                <div className="d-flex flex-wrap">
                                  <Link
                                    to={
                                      this.state.property_slug
                                        ? "/properties/view/" +
                                          this.state.property_slug +
                                          "/crm-list/add-auto"
                                        : this.state.brand_slug
                                        ? "/brand/view/" +
                                          this.state.brand_slug +
                                          "/crm-list/add-auto"
                                        : this.state.client_slug
                                        ? "/client/view/" +
                                          this.state.client_slug +
                                          "/crm-list/add-auto"
                                        : "/crm-list/add-auto"
                                    }
                                  >
                                    <button className="btn-success-outline ml-3">
                                      <img src={pluswIcon} alt="Auto Add" /> Add
                                      Auto List
                                    </button>
                                  </Link>
                                  <Link
                                    to={
                                      this.state.property_slug
                                        ? "/properties/view/" +
                                          this.state.property_slug +
                                          "/crm-list/add-manual"
                                        : this.state.brand_slug
                                        ? "/brand/view/" +
                                          this.state.brand_slug +
                                          "/crm-list/add-manual"
                                        : this.state.client_slug
                                        ? "/client/view/" +
                                          this.state.client_slug +
                                          "/crm-list/add-manual"
                                        : "/crm-list/add-manual"
                                    }
                                  >
                                    <button className="btn-success-outline ml-3">
                                      <img src={pluswIcon} alt="Manual Add" />{" "}
                                      Add Manual List
                                    </button>
                                  </Link>
                                  <Link
                                    to={
                                      this.state.property_slug
                                        ? "/properties/view/" +
                                          this.state.property_slug +
                                          "/crm-list/add-csv"
                                        : this.state.brand_slug
                                        ? "/brand/view/" +
                                          this.state.brand_slug +
                                          "/crm-list/add-csv"
                                        : this.state.client_slug
                                        ? "/client/view/" +
                                          this.state.client_slug +
                                          "/crm-list/add-csv"
                                        : "/crm-list/add-csv"
                                    }
                                  >
                                    <button className="btn-success-outline ml-3">
                                      <img src={pluswIcon} alt="CSV Add" /> Add
                                      CSV
                                    </button>
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Container className="p-0 container">
                        <div className="pd-lg-l-15 pd-xs-l-15">
                          <div className="scrolling-carousel scroll-slide">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="all"
                              onSelect={(e) => this.getActiveClass(e)}
                              eventKey={this.state.activeClass}
                              className="pd-r-60"
                            >
                              <ScrollingCarousel
                                rightArrow={true}
                                leftArrow={true}
                                className="swipesection pd-t-20"
                              >
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="activeTab"
                                    id="activeTab"
                                    className={
                                      this.state.activeClass === "activeTab"
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Active
                                  </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="inactiveTab"
                                    id="inactiveTab"
                                    className={
                                      this.state.activeClass === "inactiveTab"
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Inactive
                                  </Nav.Link>
                                </Nav.Item>
                              </ScrollingCarousel>
                              <Tab.Content>
                                <Tab.Pane eventKey="activeTab">
                                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                    {this.state.total_record > 0 ||
                                    this.state.filter_value !== "" ? (
                                      <div className="mg-t-20 brandList">
                                        <ReactDatatable
                                          className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                          config={
                                            this.state.total_record >
                                            global.page_size
                                              ? this.state.config
                                              : this.state.config
                                          }
                                          records={this.state.records}
                                          columns={this.state.columns}
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                          onRowClicked={this.rowClickedHandler}
                                        />
                                      </div>
                                    ) : (
                                      <NoData msg="active lists" />
                                    )}
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="inactiveTab">
                                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                    {this.state.total_record > 0 ||
                                    this.state.filter_value !== "" ? (
                                      <div className="mg-t-20 brandList">
                                        <ReactDatatable
                                          className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                          config={
                                            this.state.total_record >
                                            global.page_size
                                              ? this.state.config
                                              : this.state.config
                                          }
                                          records={this.state.records}
                                          columns={this.state.columns}
                                          extraButtons={this.state.extraButtons}
                                          dynamic={true}
                                          total_record={this.state.total_record}
                                          onChange={this.tableChangeHandler}
                                          onRowClicked={this.rowClickedHandler}
                                        />
                                      </div>
                                    ) : (
                                      <NoData msg="inactive lists" />
                                    )}
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </main>
        </div>
      </>
    );
  }
}
export default CrmList;

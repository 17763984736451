////////////////////////////////////////////////////////////
//     							                          //
//  Program: LookUpMappingService.js                              //
//  Application: LookUpMapping                                    //
//  Option: Used for manage LookUpMappings                        //
//  Developer: Ashish Kumar  						      //
//  Date: 2021-12-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class LookUpMappingService {
  
  getLookUpMappingList(queryString) {
    let endPoint = "get-ru-property-type-list" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getLookUpMapping(slug) {
    let endPoint = "LookUpMapping/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createLookUpMapping(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "LookUpMapping";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateLookUpMapping(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "LookUpMapping/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteLookUpMapping(slug) {
    let endPoint = "LookUpMapping/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

}

export default new LookUpMappingService();

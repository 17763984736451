///////////////////////////////////////////////
//     							             //
//  Program: TowerService.js                 //
//  Application: Services                    //
//  Option: Used for tower data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-01-24                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class RentService {
    getRent(queryString) {
        let endPoint = "unit-rent" + (queryString ? '?' + queryString : '');
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    createRent(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;
        let endPoint = "unit-rent";
        let addditionalHeaderData = {};
        let postData = data;
        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    getRentBySlug(slug) {
        let endPoint = "unit-rent/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    deleteRent(slug) {
        let endPoint = "unit-rent/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
    }

    exportDataFilewise(data) {
        let endPoint = "unit-rent-export";
        let addditionalHeaderData = {};
        let postData = data;
        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    importData(data) {
        let endPoint = "rent-import";
        let addditionalHeaderData = {};
        let postData = data;
        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.uploadFileCall(inputData);
        return response;
    }
}

export default new RentService();

////////////////////////////////////////////////////////////
//     							                          //
//  Program: PropertyLevelTaxForm.jsx                    //
//  Application:  Property                               //
//  Option: For add or update Tax         //
//  Developer: NP                                        //
//  Date: 2022-02-11                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import TaxService from "../../services/TaxService";
import TaxTypeService from "../../services/TaxTypeService";
import Select from "react-select";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import PropertySidebar from "../common/PropertySidebar";

export class PropertyLevelTaxForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      tax_type_slug: "",
      property_level_tax_name: "",
      // tax_calculation_type: "",
      property_amount: "",
      // floors_plan_slug: [],
      sort_order: "",
      pathname: window.location.pathname,
      // floorPlanList: [],
      // newFloorPlanList: [],
      // selectedFloorPlanList: [],
      taxTypeList: [],
      newTaxTypeList: [],
      selectedTaxTypeList: [],
      confirm_back: 0,
      taxTypeListTotal: 0,
      selected: 1,
    };

    this.changeHandler = this.changeHandler.bind(this);
    // this.handleChangeNew = this.handleChangeNew.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateTax = this.saveOrUpdateTax.bind(this);
    this.closeDeleteTaxModal = this.closeDeleteTaxModal.bind(this);
    this.deleteTax = this.deleteTax.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getTaxTypeData();
    // this.getFloorPlanData();
    //this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      // var selectedFloorPlanList = this.state.selectedFloorPlanList;
      let res = await TaxService.getTaxBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.property_level_tax_name = res.data.property_level_tax_name
          ? res.data.property_level_tax_name
          : "";
        // resData.floors_plan_slug = res.data.multiple_floor_plan
        //   ? res.data.multiple_floor_plan.slug
        //   : "";
        resData.property_amount = res.data.property_amount ? parseFloat(res.data.property_amount).toFixed(2).replace(/\.00$/, "").replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "") : "";
        resData.tax_type_slug = res.data.tax_type ? res.data.tax_type.slug : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        // res.data.floorPlanData.forEach((item, i) => {
        //   selectedFloorPlanList = [
        //     ...selectedFloorPlanList,
        //     {
        //       value: item.slug,
        //       label: item.property_floor_plan_name,
        //     },
        //   ];
        // })
      }
      // this.setState({ selectedFloorPlanList: selectedFloorPlanList });
      var selectedTaxTypeList = [];
      selectedTaxTypeList = res.data.tax_type
        ? [
          {
            value: res.data.tax_type.slug,
            label: res.data.tax_type.tax_type_name,
          },
        ]
        : "";
      if (res.data.tax_type.tax_type_name.includes("$")) {
        this.setState({ selected: 1 });
      } else {
        this.setState({ selected: 0 });
      }
      this.setState(resData);
      this.setState({ selectedTaxTypeList: selectedTaxTypeList });
    }
    //this.setState({ showSpinner: false, loading: false });
  }

  /* To get Tax Type list data */
  async getTaxTypeData(queryString = "") {
    //   this.setState({ showSpinner: true, loading: true });
    var taxTypeList = [];
    var taxTypeListTotal = 0;
    let res = await TaxTypeService.getTaxType(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      taxTypeList = res.data ? res.data : [];
      taxTypeListTotal = taxTypeList ? taxTypeList.length : 0;
    }
    const newTaxTypeList =
      taxTypeListTotal > 0
        ? taxTypeList.map(({ slug, tax_type_name }) => ({
          value: slug,
          label: tax_type_name,
        }))
        : [];
    this.setState({
      taxTypeList: taxTypeList,
      newTaxTypeList: newTaxTypeList,
      taxTypeListTotal: taxTypeListTotal,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  // async getFloorPlanData(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var FloorPlanList = [];

  //   let res = await FloorPlanService.getFloorPlan(
  //     "property_slug=" + this.state.property_slug + "&is_dropdown=1",
  //     queryString ? "&" + queryString : ""
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     FloorPlanList = res.data ? res.data : [];
  //   }
  //   const newFloorPlanList = FloorPlanList.map(
  //     ({ slug, property_floor_plan_name }) => ({
  //       value: slug,
  //       label: property_floor_plan_name,
  //     })
  //   );
  //   this.setState({
  //     showSpinner: false,
  //     loading: false,
  //     newFloorPlanList: newFloorPlanList,
  //   });
  // }

  saveOrUpdateTax = async (e) => {
    // let multiFloorPlanString = this.state.floors_plan_slug
    //   ? this.state.floors_plan_slug.join(",")
    //   : "";
    e.preventDefault();

    if (this.validate() && this.customValidate() && this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        tax_type_slug: this.state.tax_type_slug ? this.state.tax_type_slug : "",
        property_level_tax_name: this.state.property_level_tax_name
          ? this.state.property_level_tax_name
          : "",
        // tax_calculation_type: this.state.tax_calculation_type
        //   ? this.state.tax_calculation_type
        //   : "",
        property_amount: this.state.property_amount
          ? this.state.property_amount
          : "",
        // floors_plan_slug: multiFloorPlanString ? multiFloorPlanString : "",
        sort_order: this.state.sort_order ? this.state.sort_order : "",
        status_id: this.state.status_id === true ? 1 : 2,
      };

      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await TaxService.updateTax(this.state.slug, inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                this.state.property_slug +
                "/property-level-tax"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await TaxService.createTax(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" +
                this.state.property_slug +
                "/property-level-tax"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  openDeleteTaxModal(slug) {
    this.setState({ deleteTaxModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteTaxModal() {
    this.setState({ deleteTaxModal: false });
    this.setState({ slug: "" });
  }

  async deleteTax(slug) {
    this.setState({ showSpinner: true });
    let res = await TaxService.deleteTax(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteTaxModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" +
            this.state.property_slug +
            "/property-level-tax"
          ),
        global.redirect_time
      );
    } else {
      this.closeDeleteTaxModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    if (event.target.name === "tax_type_slug") {
      // console.log(event.target);
    }
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
    if (event.target.name === "property_amount") {
      let property_amount =
      event.target.name === "property_amount"
        ? event.target.value !== ""
          ? event.target.value.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : 0
        : this.state.property_amount
          ? this.state.property_amount.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
          : "";
          this.setState({property_amount: property_amount,  errors: {} })
    }
  };

  handleChangeTaxTypeList(value) {
    let tax_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        tax_type_slug.push(item.value);
      });
    }
    const tax_type_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedTaxTypeList: value,
      tax_type_slug: tax_type_slug_value,
    });
    if (value !== null) {
      var selected = value.label;
      if (selected) {
        if (selected.includes("$")) {
          this.setState({ selected: 1 });
        } else {
          this.setState({ selected: 2 });
        }
      }
    }
    this.setState({ confirm_back: 1 });
  }

  validate() {
    let errors = this.state.errors;
    if (!this.state.property_amount) {
      if (this.state.selected !== 1) {
        errors["property_amount"] = "The Value (%) field is required.";
      }
      if (this.state.selected === 1) {
        errors["property_amount"] = "The Value ($) field is required.";
      }
    }
    this.setState({ errors: errors });
    return true;
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  // handleChangeNew(value) {
  //   let floors_plan_slug = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       floors_plan_slug.push(item.value);
  //     });
  //   }

  //   this.setState({
  //     selectedFloorPlanList: value,
  //     floors_plan_slug: floors_plan_slug,
  //   });
  //   this.setState({ confirm_back: 1 });
  // }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Taxes";
    } else {
      return "Taxes";
    }
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.slug !== undefined ? (
                        <>
                         {global.userPermissions.checkPermission('taxes-delete') ?
                          <span
                            onClick={() => {
                              this.openDeleteTaxModal(this.state.slug);
                            }}
                            className="deleteicon mg-r-20"
                          >
                            <img src={deleteicon} alt="Delete" />
                          </span>
                          :''}
                        </>
                      ) : (
                        ""
                      )}

                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                              this.state.property_slug +
                              "/property-level-tax"
                            );
                        }}
                      >
                        Cancel
                      </button>
                      {global.userPermissions.checkPermission('taxes-update') ?
                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={this.saveOrUpdateTax}
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                            ? "Save"
                            : "Save"}
                      </button>
                      :''}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pl-0 pr-3">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15 ">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>

                    <div className="m-0">
                      <div className="row align-items-center pd-l-15 pd-r-15 pd-t-20 pd-b-30 border-bottom-0">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-15 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Tax Type
                          </label>

                          <Select
                            styles={customStyles}
                            isClearable={true}
                            className="multiselect"
                            classNamePrefix={"my-custom-react-select"}
                            menuPlacement="auto"
                            value={this.state.selectedTaxTypeList}
                            options={this.state.newTaxTypeList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeTaxTypeList(value)
                            }
                            defaultValue={this.state.selectedTaxTypeList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />

                          {this.state.errors.tax_type_slug ? (
                            <div className="text-danger">
                              {this.state.errors.tax_type_slug}
                            </div>
                          ) : (
                            this.validator.message(
                              "tax_type",
                              this.state.tax_type_slug,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Name
                          </label>

                          <Form.Control
                            onChange={this.changeHandler}
                            name="property_level_tax_name"
                            value={this.state.property_level_tax_name}
                            className="form-control max_width_100"
                            type="text"
                          />
                          {this.state.errors.property_level_tax_name ? (
                            <div className="text-danger">
                              {this.state.errors.property_level_tax_name}
                            </div>
                          ) : (
                            this.validator.message(
                              "name",
                              this.state.property_level_tax_name,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Value{" "}
                          </label>
                          <div className={this.state.selected === 1 ? "input-group-prepend prefix-dollar" : "input-group-prepend prefix-dollar percanteg-prefix"} >
                            {this.state.selected === 1 ? (
                              <span className="input-group-text">$</span>
                            ) : (
                              ""
                            )}
                            <Form.Control
                              onChange={this.changeHandler}
                              name="property_amount"
                              value={this.state.property_amount ? this.state.property_amount.replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "") : "" || ""}
                              className="form-control max_width_100"
                              type="text"
                              onKeyPress={
                                global.onKeyPressEvent.floatValidation
                              }
                            />
                            {this.state.selected !== 1 ? (
                              <span className="input-group-text percanteg_symbl2">%</span>
                            ) : (
                              ""
                            )}
                          </div>
                          {this.state.errors.property_amount ? (
                            <div className="text-danger">
                              {this.state.errors.property_amount}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row align-items-center pd-lg-l-30 pd-lg-r-30 pd-xs-l-20 pd-xs-r-0 border-bottom-0">
                        <div className="col-md-1 pl-0">
                          <label className="form-label text-left pd-b-5">
                            Status
                          </label>
                        </div>

                        <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                          <b
                            className={
                              this.state.status_id ? "active" : "inactive"
                            }
                          ></b>
                          <div
                            className={
                              this.state.status_id
                                ? "az-toggle on"
                                : "az-toggle"
                            }
                            onClick={() =>
                              this.setState({
                                status_id: !this.state.status_id,
                              })
                            }
                          >
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deleteTaxModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteTaxModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteTax(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default PropertyLevelTaxForm;

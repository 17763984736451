////////////////////////////////////////////////////////////
//     							                          //
//  Program: Tower.jsx                                    //
//  Application: Tower                                    //
//  Option: List all Tower                                //
//  Developer: Ashish Kumar 						              //
//  Date: 2022-01-25                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import TowerService from "../../services/TowerService";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, InputGroup, Form } from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import Alerts from "../common/Alerts";
import NoData from "../common/NoData";
import PropertySidebar from "../common/PropertySidebar";
import queryString from "query-string";
import filterIcon from "./../../assets/images/filter-gray.svg";

let queryParams = queryString.parse(window.location.search);

export class Tower extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      module_search: "",
      is_search: 0,
      isSearch: global.isSearch,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "tower_name",
          text: "Tower Name",
          className: "tower_name hand-cursor",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.slug ? record.tower_name : record.tower_name;
          },
        },
        {
          key: "aka_name",
          text: "Tower SKU",
          className: "aka_name hand-cursor",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.aka_name;
          },
        },
        {
          key: "total_floors_count",
          text: "Number of Floors",
          className: "total_floors_count hand-cursor text-left",
          sortable: true,
          width: 300,
          cell: (record) => {
            return record.total_floors_count;
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.searchToggle = this.searchToggle.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    if (queryParams.isGlobal === "1") {
      this.getData("is_search=1&" + queryParams.global_col_name + "=" + queryParams.global_search);
    } else {
      this.getData();
    }
  }

  async getData(queryString = "") {
    // this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await TowerService.getTower(
      "property_slug=" + this.state.property_slug + "&" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0
    }
    this.setState({

      total_record: totalRecords,
      records: list,
    });
    // this.setState({ showSpinner: false, loading: false });
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({ tower_name: "", aka_name: "" });
    this.setState({ module_search: "" });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  filterData() {
    let queryString =
      "tower_name=" +
      (this.state.tower_name ? this.state.tower_name : '') +
      "&aka_name=" +
      (this.state.aka_name ? this.state.aka_name : '') +
      "&is_search=1";
      
    // let moduleSearchString =
    //   (this.state.tower_name ? "tower name - " + this.state.tower_name : '') +
    //   (this.state.aka_name ? " & tower SKU - " + this.state.aka_name : '');

    let moduleSearchString = "";
    moduleSearchString = this.state.tower_name ? "Tower Name - " + this.state.tower_name : ''
    moduleSearchString = this.state.aka_name ? (moduleSearchString ? (moduleSearchString + " ," + " Tower SKU - " + this.state.aka_name) : (" Tower SKU - " + this.state.aka_name)) : moduleSearchString
      


    if (this.state.tower_name || this.state.aka_name) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(',') });
      this.getData(queryString);
    }
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = 'asc';
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Towers";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("tower/update/" + data.slug);
  };

  render() {
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    {/* <PropertyViewSidebar /> */}
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission('towers-add') &&
                          <Link to={"tower/add"}>
                            <button className="btn-success-outline-small mr-3">
                              <img src={pluswIcon} alt="" /> Add
                            </button>
                          </Link>
                        }
                        <button
                          onClick={this.searchToggle}
                          className="btn-success-outline-small"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.isSearch === 1 ? "Search" : "Search"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="scrolling-carousel pd-l-15">

                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">

                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.showFilter ? (
                        <Row className="align-items-center order_form">
                          <Col md={3} lg={5} xl={5} className="mb-md-0 mb-3 pl-0">
                            <input
                              name="filter_value"
                              className="form-control"
                              autoComplete="off"
                              // placeholder="Filter Value"
                              value={this.state.filter_value}
                              onChange={this.changeHandler}
                              maxLength="30"
                            />
                          </Col>
                          <Col md={5} lg={5} xl={5}>
                            <Row>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-30 mg-r-20"
                              >
                                Search
                              </button>
                              <button
                                onClick={this.resetDate}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      {queryParams.isGlobal === "1" ? <><div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">{"Search applied on: " + queryParams.global_col_label + " - " + queryParams.global_search}
                        <button onClick={this.clearFilter} className="mg-l-15 btn-success-outline-small">Clear</button></div>
                      </> : ""}
                      {this.state.is_search === 1 ?
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter pd-lg-l-0 pd-xs-l-15">
                              <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                              <div className="filter_bg_cust">
                                {
                                  this.state.module_search.map((item, i) => {
                                    return (
                                      <span key={i}>{item}</span>
                                    )
                                  })
                                }
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >Clear</button>
                              </div>
                            </div>
                          </div>
                        </>
                        : ''}
                      {this.state.total_record !== 0 || this.state.filter_value !== "" ? (
                        <div className="mg-t-18 brandList pd-l-15 d-block">
                          <div className="pd-lg-l-0 pd-xs-l-15">
                          <ReactDatatable
                            className="table no-wrap mg-b-30 pd-b-2 text-align-left"
                            config={this.state.total_record > global.page_size ? this.state.config : this.state.config}
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            // loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            // onRowClicked={this.rowClickedHandler}
                            onRowClicked={global.userPermissions.checkPermission("towers-update") && this.rowClickedHandler}
                          />
                          </div>
                        </div>
                      ) : (
                        <NoData msg={global.commonMessages.checkMessage("TOWERS")} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(
            e
          ) => {
            if (
              e.key ===
              "Enter"
            ) {
              this.filterData()
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.searchToggle()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Search in Towers</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>
                    Tower Name
                  </label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="tower_name"
                      value={this.state.tower_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>
                    Tower SKU
                  </label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="aka_name"
                      value={this.state.aka_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    // placeholder="Amenity Name"
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Tower;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: KBSubCategory.jsx                            //
//  Application: Property                                   //
//  Option: to view KB Sub Category data                   //
//  Developer: Saurabh Shukla 			                  //
//  Date: 2022-01-05                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Button, Table, Modal, Form, InputGroup } from "react-bootstrap";

import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import modaldeleteicon from "./../../assets/images/delete.svg";
import KBSubCategoryService from "../../services/KBSubCategoryService";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import Select from "react-select";
import NoData from "../common/NoData";
import FaqService from "../../services/FaqService";

export class KBSubCategory extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",

      //for KB Sub Category
      addKBSubCategoryModel: false,
      editKBSubCategoryModel: false,
      deleteKBSubCategoryModel: false,
      KBSubCategoryList: [],
      KBSubCategoryListTotal: 0,
      kb_sub_category_name: "",
      selectedkbCategoryList: [],
      kbCategoryList: [],
      newkbCategoryList: [],
      kb_category_id: "",
      kb_category_slug: "",
      kb_category_name: "",

    };
    this.changeHandler = this.changeHandler.bind(this);

    /*for KB Sub Category */
    this.openAddKBSubCategoryModel = this.openAddKBSubCategoryModel.bind(this);
    this.openEditKBSubCategoryModel = this.openEditKBSubCategoryModel.bind(this);
    this.openDeleteKBSubCategoryModel =
      this.openDeleteKBSubCategoryModel.bind(this);
    this.saveKBSubCategory = this.saveKBSubCategory.bind(this);
    this.getKBSubCategoryDetails = this.getKBSubCategoryDetails.bind(this);
    this.updateKBSubCategory = this.updateKBSubCategory.bind(this);
    this.deleteKBSubCategory = this.deleteKBSubCategory.bind(this);
    this.handleChangeKbCategoryList =
      this.handleChangeKbCategoryList.bind(this);
  }

  componentDidMount() {
    this.getKBSubCategoryData();
    this.getCategory();
  }


  /* start to get category data */
  async getCategory(queryString) {
    this.setState({ showSpinner: true, loading: true });
    var kbCategoryList = [];
    var kbCategoryListTotal = 0;
    let res = await FaqService.getKbCategory(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      kbCategoryList = res.data ? res.data : [];
      kbCategoryListTotal = kbCategoryList ? kbCategoryList.length : 0;
    }

    const newkbCategoryList =
      kbCategoryListTotal > 0
        ? kbCategoryList.map(({ kb_category_name, kb_category_id }) => ({
          value: kb_category_id,
          label: kb_category_name,

        }))
        : [];
    this.setState({
      kbCategoryList: kbCategoryList,
      kbCategoryListTotal: kbCategoryListTotal,
      newkbCategoryList: newkbCategoryList,
    });
    this.setState({ showSpinner: false, loading: false });

  }

  /* end */

  /* to get KB Sub Category data */
  async getKBSubCategoryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var KBSubCategoryList = [];
    var KBSubCategoryListTotal = 0;

    let res = await KBSubCategoryService.getKBSubCategory(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      KBSubCategoryList = res.data.data ? res.data.data : [];
      KBSubCategoryListTotal = KBSubCategoryList ? KBSubCategoryList.length : 0;
    }
    this.setState({
      KBSubCategoryList: KBSubCategoryList,
      KBSubCategoryListTotal: KBSubCategoryListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* start change handler for category */
  handleChangeKbCategoryList(value) {
    let kb_category_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        kb_category_slug.push(item.value);
      });
    }
    //  console.log(kb_category_ids);

    this.setState({
      selectedkbCategoryList: value,
      kb_category_id: value.value,

    });


  }
  /* end change handler for category*/



  /* to save KB Sub Category data */
  saveKBSubCategory = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.kb_sub_category_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        kb_sub_category_name: this.state.kb_sub_category_name,
        kb_category_id: this.state.kb_category_id,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await KBSubCategoryService.createKBSubCategory(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddKBSubCategoryModel();
        this.setState({
          kb_sub_category_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getKBSubCategoryData();
      } else {
        // let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get KB Sub Category details data */
  async getKBSubCategoryDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await KBSubCategoryService.getKBSubCategoryBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.kb_sub_category_name = res.data.kb_sub_category_name
          ? res.data.kb_sub_category_name
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        var selectedkbCategoryList = [];
        selectedkbCategoryList = res.data.kb_category_slug && res.data.kb_category_name
          ? [
            {
              value: res.data.kb_category_slug,
              label: res.data.kb_category_name,
            },
          ]
          : "";
      }

      this.setState(resData);
      this.setState({
        selectedkbCategoryList: selectedkbCategoryList,
      })

    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /* to update KB Sub Category data */
  updateKBSubCategory = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.kb_sub_category_name &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        kb_sub_category_name: this.state.kb_sub_category_name,
        kb_category_id: this.state.kb_category_id,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await KBSubCategoryService.updateKBSubCategory(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditKBSubCategoryModel();
        this.setState({
          kb_sub_category_name: "",
          status_id: true,
          sort_order: "",
        });
        this.getKBSubCategoryData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete KB Sub Category data */
  async deleteKBSubCategory(slug) {
    this.setState({ showSpinner: true });
    let res = await KBSubCategoryService.deleteKBSubCategory(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteKBSubCategoryModel();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getKBSubCategoryData();
    } else {
      this.closeDeleteKBSubCategoryModel();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* for KB Sub Category modal */
  openAddKBSubCategoryModel() {
    this.setState({ addKBSubCategoryModel: true });
  }

  closeAddKBSubCategoryModel() {
    this.setState({ addKBSubCategoryModel: false });
    this.setState({
      kb_sub_category_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openEditKBSubCategoryModel(slug) {
    this.getKBSubCategoryDetails(slug);
    this.setState({ editKBSubCategoryModel: true });
    this.setState({ slug: slug });
  }

  closeEditKBSubCategoryModel() {
    this.setState({ editKBSubCategoryModel: false });
    this.setState({
      slug: "",
      kb_sub_category_name: "",
      sort_order: "",
      status_id: true,
      errors: "",
    });
    this.validator.hideMessages();
  }

  openDeleteKBSubCategoryModel(slug) {
    this.setState({ deleteKBSubCategoryModel: true });
    this.setState({ slug: slug });
  }

  closeDeleteKBSubCategoryModel() {
    this.setState({ deleteKBSubCategoryModel: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "FAQ Sub Category";
  }

  render() {

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                            <Button
                              variant="outline-dark btn-block btn-with-icon"
                              onClick={this.openAddKBSubCategoryModel}
                            >
                              <img src={pluswIcon} /> Add
                            </Button>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <Table hover className="mg-b-0">
                        <thead>
                          <tr>
                            <th className="w-40">Sub Category</th>
                            <th className="w-40">Category</th>
                            <th className="w-25">Status</th>
                            {global.userPermissions.checkPermission(
                              "master-data-delete"
                            ) && <th className="text-center">Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.KBSubCategoryListTotal > 0 ? (
                            this.state.KBSubCategoryList.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="hand-cursor"
                                    onClick={() => {
                                      global.userPermissions.checkPermission(
                                        "master-data-delete"
                                      ) &&
                                        this.openEditKBSubCategoryModel(
                                          item.slug
                                        );
                                    }}
                                  >
                                    {item.kb_sub_category_name}
                                  </td>
                                  {<td>

                                    {item.kb_category_name}
                                  </td>}
                                  <td>
                                    {item.status_id === 1 ? (
                                      <b className="active"></b>
                                    ) : (
                                      <b></b>
                                    )}
                                  </td>
                                  {global.userPermissions.checkPermission(
                                    "master-data-delete"
                                  ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt="Delete"
                                            onClick={() =>
                                              this.openDeleteKBSubCategoryModel(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="9" align="center">
                                <NoData msg="active faq sub category" />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add KB Sub Category popup modal */}
        <Modal
          show={this.state.addKBSubCategoryModel}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddKBSubCategoryModel();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddKBSubCategoryModel()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">FAQ Sub Category</h3>

            <label>Sub Category</label>
            <Form.Control
              onChange={this.changeHandler}
              name="kb_sub_category_name"
              value={this.state.kb_sub_category_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
            // placeholder="KB Sub Category Name"
            />
            {this.state.errors.kb_sub_category_name ? (
              <div className="text-danger">
                {this.state.errors.kb_sub_category_name}
              </div>
            ) : (
              this.validator.message(
                "sub_category",
                this.state.kb_sub_category_name,
                "required",
                { className: "text-danger" }
              )
            )}
            <label className="mt-3">Category</label>
            <Select
              styles={customStyles}
              isClearable={true}
              className="multiselect"
              classNamePrefix={"my-custom-react-select"}
              menuPlacement="auto"
              // value={this.state.selectedkbCategoryList}
              options={this.state.newkbCategoryList}

              getOptionValue={(option) => `${option.label}`}
              onChange={(value) => {
                this.handleChangeKbCategoryList(value)
              }
              }
              // defaultValue={this.state.selectedkbCategoryList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                  ...theme.colors,
                  primary: "#fff",
                  primary75: "#000",
                  primary50: "#000",
                  primary25: "#000",
                },
              })}
            />
            <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddKBSubCategoryModel()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveKBSubCategory}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit KB Sub Category popup modal */}
        <Modal
          show={this.state.editKBSubCategoryModel}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditKBSubCategoryModel();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditKBSubCategoryModel()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update FAQ Sub Category</h3>
            <label>Sub Category</label>
            <Form.Control
              onChange={this.changeHandler}
              name="kb_sub_category_name"
              value={this.state.kb_sub_category_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
            // placeholder="KB Sub Category Name"
            />
            {this.state.errors.kb_sub_category_name ? (
              <div className="text-danger">
                {this.state.errors.kb_sub_category_name}
              </div>
            ) : (
              this.validator.message(
                "sub_category",
                this.state.kb_sub_category_name,
                "required",
                { className: "text-danger" }
              )
            )}
            <label className="mt-3">Category</label>
            <Select
              styles={customStyles}
              className="multiselect"
              classNamePrefix={"my-custom-react-select"}
              menuPlacement="auto"
              isClearable={true}
              value={this.state.selectedkbCategoryList}
              options={this.state.newkbCategoryList}
              getOptionValue={(option) => `${option.label}`}
              onChange={(value) => {
                this.handleChangeKbCategoryList(value)
              }
              }
              defaultValue={this.state.selectedkbCategoryList}
              theme={(theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                  ...theme.colors,
                  primary: "#fff",
                  primary75: "#000",
                  primary50: "#000",
                  primary25: "#000",
                },
              })}
            />
            <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
              // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "Active" : "Inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditKBSubCategoryModel()}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.updateKBSubCategory}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete KB Sub Category popup modal */}
        <Modal
          show={this.state.deleteKBSubCategoryModel}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closeDeleteKBSubCategoryModel();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteKBSubCategoryModel()}
              >
                {global.closee}
              </span>

              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteKBSubCategoryModel()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteKBSubCategory(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default KBSubCategory;

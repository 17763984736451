////////////////////////////////////////////////////////////
//     							                          //
//  Program: ForgotPassword.jsx                           //
//  Application: authentication                           //
//  Option: For ForgotPassword into project               //
//  Developer: NP  						                            //
//  Date: 2021-12-22                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import AuthenticationService from '../../services/AuthenticationService'
import SimpleReactValidator from 'simple-react-validator'
import { Redirect,Link } from 'react-router-dom'
import BackButton from "../../assets/images/back-button.svg"
import Alerts from '../common/Alerts' 

class ForgotPassword extends Component {
  constructor(props) {
      super(props)

      this.validator = new SimpleReactValidator({autoForceUpdate: this});

      this.state = {
            headerToken: localStorage.getItem('headerToken'),
            isSubmit: false,
            showAlertModal: false,
            alertModalType: '',
            alertModalTitle: '',
            alertModalMessage: '',
            email: '',
      }
      
      this.closeModal = this.closeModal.bind(this);
      this.changeHandler = this.changeHandler.bind(this);
      this.forgotPass = this.forgotPass.bind(this);
  }

  closeModal() {
      this.setState({ showAlertModal: false });
  }

  forgotPass = async (e) => {
      e.preventDefault();
      if(this.validator.allValid()){ // Validate all fields before data submission
          this.setState({isSubmit: true});
          let input = {
                email: this.state.email,          
          }
          let res = await AuthenticationService.forgotPass(input);
          if((global.successStatus).includes(res.status)) {
            localStorage.clear();
            this.setState({ showAlertModal: true, alertModalType: 'success', alertModalTitle: 'Success', alertModalMessage: res.message ? res.message :'Success' });
            setTimeout(() => this.props.history.push('/signin'), global.redirect_time);
          }else if(res.status === 400){
            this.setState({ showAlertModal: true, alertModalType: 'error', alertModalTitle: 'Error', alertModalMessage: res.data.message ? res.data.message : 'Error'}); 
          }else{
              this.setState({ showAlertModal: true, alertModalType: 'error', alertModalTitle: (res.data.errorCode) ? res.data.errorCode : 'Error!', alertModalMessage: (res.data.message) ? res.data.message : '' });
          }
          this.setState({isSubmit: false});
          setTimeout(() => this.setState({ showAlertModal: false }), global.alert_close_time);
      }else{
          this.validator.showMessages();
      }
  }

  changeHandler = (event) => {
      this.setState({[event.target.name]:event.target.value});

      // Validate
      this.validator.showMessageFor(event.target.name);
  }

  getTitle() {
      return process.env.REACT_APP_PROJECT_TITLE ? process.env.REACT_APP_PROJECT_TITLE : '';
  }

  render() {
    // Redirect to dashboard if logged in 
    if(this.state.headerToken) {
        return <Redirect to='/dashboard' />;
    }
    return (
      <div>
      <div className="az-signin-wrapper">
        <div className="az-card-signin">
          <div className="az-logo d-flex flex-column">
            <img src={require("../../assets/images/ohai-logo.svg").default} alt=""></img>
            <h2>Welcome To OHAI.</h2>
          </div>
          <div className="az-signin-header">
            <h3 className='text-center'>Forgot password?</h3>
            <p className='text-center p-dark forgot-text'>Enter your email below and we'll send you an email to get this sorted out.</p>
              <div className="col-md-12 pl-0 pr-0">
                  <Alerts show={this.state.showAlertModal} type={this.state.alertModalType} title={this.state.alertModalTitle} message={this.state.alertModalMessage} />
              </div>
            <form action="#/">
              <div className="form-group">
                <input name="email" className="form-control" placeholder="Email"
                    value={this.state.email} onChange={this.changeHandler} maxLength="50" />

                {this.validator.message('email', this.state.email, 'email|required', { className: 'text-danger' })}
              </div>
              <button className="btn btn-info btn-block button-green" onClick={this.forgotPass} disabled={ this.state.isSubmit ? true : false }>{ this.state.isSubmit ? global.loader : "Submit" }</button>

              <div className="az-signin-footer text-center mt-3">
                <p  className="d-flex justify-content-center align-items-center"><Link to="/signin"><img src={BackButton} alt="" className="back-button" /></Link><Link to="/signin">Back to Sign in</Link></p>
              </div>
            </form>
          </div>
        </div>
        <div className='az-copyright text-center'>
              <p>© {global.current_year} OHAI, Inc. All rights reserved.</p>
          </div>
      </div>
      </div>
    )
  }
}

export default ForgotPassword

/////////////////////////////////////////////////////
//     							                   //
//  Program: Amenities.jsx                             //
//  Application: Property                          //
//  Option: List all Property Amenities info           //
//  Developer: Ashish Kumar					   //
//  Date: 2022-04-04                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import PropertySidebar from "../common/PropertySidebar";
import AmenityService from "../../services/AmenityService";
import { Container } from "react-bootstrap";
import NoData from "../common/NoData";

export class Amenities extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      viewMode: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      importModal: false,
      property_slug: this.props.property_slug
        ? this.props.property_slug
        : this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      amenitiesList: [],
      amenitiesListTotal: 0,
      propertyAmenitiesList: [],
      propertyAmenitiesListTotal: 0,
      selectedAmenities: [],
      slugUncheckList: [],
      select_all: false,
      activeAmenities: 0,
      buildingOnboardingTab: this.props.buildingOnboardingTab
        ? this.props.buildingOnboardingTab
        : 0,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.handleCheckboxChangeNew = this.handleCheckboxChangeNew.bind(this);
    this.addAmenities = this.addAmenities.bind(this);
    this.getAmenitiesDetails = this.getAmenitiesDetails.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.changeView = this.changeView.bind(this);
  }

  async componentDidMount() {
    this.getAmenitiesData();
  }

  changeView() {
    if (this.state.viewMode === 1) {
      if (this.props.buildingOnboardingTab === 1) {
        this.props.propertyDocView(0);
      }
      this.setState({ viewMode: 0 });
      this.setState({ selectedAmenities: [] });
      this.setState({ slugUncheckList: [] });
      this.getAmenitiesData();
      // this.getPropertyAmenitiesData();
    } else {
      this.setState({ viewMode: 1 });
      if (this.props.buildingOnboardingTab === 1) {
        this.props.propertyDocView(1);
      }
      this.getAmenitiesData();
      // this.getPropertyAmenitiesData();
    }
  }

  /* to amenities data */
  async getAmenitiesData(queryString = "") {
    var amenitiesList = [];
    var amenitiesListTotal = 0;
    var basicAmenityList = [];
    var finalAmenityList = [];

    let res = await AmenityService.getAmenity(
      "level=1&is_mapped=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      amenitiesList = res.data ? res.data.data : [];
      basicAmenityList = res.data ? res.data.data : [];
      amenitiesListTotal = amenitiesList ? amenitiesList.length : 0;

      basicAmenityList.map((index, value) => {
        if (
          finalAmenityList[
            basicAmenityList[value]["amenity_type"]["amenity_type_name"]
          ] !== undefined
        ) {
          finalAmenityList[
            basicAmenityList[value]["amenity_type"]["amenity_type_name"]
          ].push({
            amenity_name: basicAmenityList[value]["amenity_name"],
            amenity_slug: basicAmenityList[value]["slug"],
            checked: false,
          });
        } else {
          finalAmenityList[
            basicAmenityList[value]["amenity_type"]["amenity_type_name"]
          ] = [
            {
              amenity_name: basicAmenityList[value]["amenity_name"],
              amenity_slug: basicAmenityList[value]["slug"],
              checked: false,
            },
          ];
        }
      });
    }
    if (amenitiesListTotal > 0) {
      amenitiesList.map((opt, i) => {
        if (opt.status_id === 1) {
          this.setState({ activeAmenities: 1 });
        }
      });
    }
    this.setState({
      amenitiesList: finalAmenityList,
      basicAmenityList: basicAmenityList,
      amenitiesListTotal: amenitiesListTotal,
    });

    this.getPropertyAmenitiesData();
  }

  /* to get property amenities data */
  async getPropertyAmenitiesData() {
    var propertyAmenitiesList = [];
    var selectedAmenities = [];
    var propertyAmenitiesListTotal = 0;

    let res = await AmenityService.getPropertyAmenities(
      this.state.property_slug + "/1"
    );
    if (global.successStatus.includes(res.status)) {
      // propertyAmenitiesList = res.data ? res.data : [];
      propertyAmenitiesListTotal = res.data ? res.data.length : 0;

      var basicAmenityList = [...this.state.basicAmenityList];
      basicAmenityList.map((item, i) => {
        res.data.map((options) => {
          if (item.slug === options.slug) {
            basicAmenityList[i].checked = true;
          } else {
            if (basicAmenityList[i].checked !== true) {
              basicAmenityList[i].checked = false;
            }
          }
        });
      });

      var amenitiesList = [];

      basicAmenityList.map((index, value) => {
        if (
          amenitiesList[
            basicAmenityList[value]["amenity_type"]["amenity_type_name"]
          ] !== undefined
        ) {
          amenitiesList[
            basicAmenityList[value]["amenity_type"]["amenity_type_name"]
          ].push({
            amenity_name: basicAmenityList[value]["amenity_name"],
            amenity_slug: basicAmenityList[value]["slug"],
            checked: basicAmenityList[value]["checked"],
          });
        } else {
          amenitiesList[
            basicAmenityList[value]["amenity_type"]["amenity_type_name"]
          ] = [
            {
              amenity_name: basicAmenityList[value]["amenity_name"],
              amenity_slug: basicAmenityList[value]["slug"],
              checked: basicAmenityList[value]["checked"],
            },
          ];
        }
      });

      res.data.map((index, value) => {
        if (
          propertyAmenitiesList[
            res.data[value]["amenity_type"]["amenity_type_name"]
          ] !== undefined
        ) {
          propertyAmenitiesList[
            res.data[value]["amenity_type"]["amenity_type_name"]
          ].push({
            amenity_name: res.data[value]["amenity_name"],
            amenity_slug: res.data[value]["amenity_slug"],
            checked: res.data[value]["checked"],
          });
        } else {
          propertyAmenitiesList[
            res.data[value]["amenity_type"]["amenity_type_name"]
          ] = [
            {
              amenity_name: res.data[value]["amenity_name"],
              amenity_slug: res.data[value]["amenity_slug"],
              checked: res.data[value]["checked"],
            },
          ];
        }
      });
    }
    if (res && res.data && res.data.length > 0) {
      res.data.forEach((opt) => {
        selectedAmenities.push(opt.slug);
      });
    }

    this.setState({
      selectedAmenities: selectedAmenities,
      amenitiesList: amenitiesList,
      propertyAmenitiesList: propertyAmenitiesList,
      propertyAmenitiesListTotal: propertyAmenitiesListTotal,
    });
    if (
      this.state.amenitiesListTotal === this.state.propertyAmenitiesListTotal
    ) {
      this.setState({ select_all: true });
    } else {
      this.setState({ select_all: false });
    }
  }

  /* to save amenities data */
  addAmenities = async (e) => {
    e.preventDefault();

    this.setState({ showSpinner: true, isSubmit: true });
    let inputData = {
      amenitiesData:
        this.state.selectedAmenities.length > 0
          ? this.state.selectedAmenities
          : [""],
    };

    let res = await AmenityService.addOrUpdateAmenity(
      this.state.property_slug + "/1",
      inputData
    );
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      var check = document.getElementsByName("one");
      for (var i = 0; i < check.length; i++) {
        document.getElementsByName("one")[i].checked = false;
      }
      this.setState({ selectedAmenities: [] });
      this.getPropertyAmenitiesData();
      this.setState({ viewMode: 0 });
      if (this.props.buildingOnboardingTab === 1) {
        this.props.propertyDocView(0);
      }
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
      });
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  /* To amenities details data */
  async getAmenitiesDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await AmenityService.getAmenityBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.amenity_name = res.data.amenity_name
          ? res.data.amenity_name
          : "";
        resData.amenity_type_name = res.data.amenity_type
          ? res.data.amenity_type.amenity_type_name
          : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.tag_line = res.data.tag_line ? res.data.tag_line : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  confirmImportModal(slug) {
    this.getAmenitiesDetails(slug);
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event) => {
    if (event.target.name === "select_all") {
      if (event.target.checked === true) {
        this.setState({ select_all: true });
      } else {
        this.setState({ select_all: false });
      }
    }
    let amenitiesList = this.state.amenitiesList;
    let newArray = [...this.state.selectedAmenities];

    if (this.state.selectedAmenities.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }

    Object.keys(amenitiesList).map((item, o) => {
      amenitiesList[item].map((options) => {
        options.checked = event.target.checked === true ? true : false;
        if (event.target.name === "select_all") {
          if (event.target.checked === true) {
            newArray.push(options.amenity_slug);
          } else {
            newArray = [""];
          }
        }
      });
    });

    this.setState({ amenitiesList: amenitiesList });
    this.setState({ selectedAmenities: newArray });
  };
  //checkbox change handler
  handleCheckboxChangeNew = (event, index) => {
    let amenitiesList = this.state.amenitiesList;
    let newArray = [...this.state.selectedAmenities, event.target.id];
    if (this.state.selectedAmenities.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    Object.keys(amenitiesList).map((item, o) => {
      amenitiesList[item].map((options) => {
        if (event.target.id === options.amenity_slug) {
          options.checked = event.target.checked === true ? true : false;
        }
      });
    });
    this.setState({ amenitiesList: amenitiesList });
    this.setState({ selectedAmenities: newArray });
  };

  handleCheckboxChange = (event, index) => {
    this.setState({ select_all: "" });
    var amenitiesList = this.state.amenitiesList;
    let newArray = [...this.state.selectedAmenities, event.target.id];
    if (this.state.selectedAmenities.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    amenitiesList[index].checked = event.target.checked === true ? true : false;
    this.setState({ selectedAmenities: newArray });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Amenities";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.state.buildingOnboardingTab === 0 ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>

                <div
                  className={
                    this.state.buildingOnboardingTab === 1
                      ? "col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-l-15 pd-r-0"
                      : "col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0"
                  }
                >
                  <div
                    className={
                      this.state.buildingOnboardingTab === 1
                        ? "align-items-right"
                        : "dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0"
                    }
                  >
                    {this.state.buildingOnboardingTab === 0 ? (
                      <h3>{this.getTitle()}</h3>
                    ) : (
                      ""
                    )}
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {this.state.viewMode === 0 ? (
                        <React.Fragment>
                          {this.state.propertyAmenitiesListTotal > 0 ? (
                            <React.Fragment>
                              {this.state.buildingOnboardingTab === 1 ? (
                                <button
                                  className="btn-snap-view btn-cust-small"
                                  onClick={this.changeView}
                                >
                                  Edit
                                </button>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {this.state.buildingOnboardingTab === 1 ? (
                                <button
                                  className="btn-success-outline-small"
                                  onClick={this.changeView}
                                >
                                  Add
                                </button>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          )}
                          {this.state.propertyAmenitiesListTotal > 0 &&
                          this.state.buildingOnboardingTab === 0 &&
                          global.userPermissions.checkPermission(
                            "property-amenities-update"
                          ) ? (
                            <button
                              className="btn btn-success-outline-small btn-cust-small"
                              onClick={this.changeView}
                            >
                              Edit
                            </button>
                          ) : global.userPermissions.checkPermission(
                              "property-amenities-add"
                            ) && this.state.buildingOnboardingTab === 0 ? (
                            <button
                              className="btn-success-outline-small"
                              onClick={this.changeView}
                            >
                              Add
                            </button>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        <div className="d-flex">
                          {this.state.buildingOnboardingTab === 1 ? (
                            <React.Fragment>
                              <button
                                type="button"
                                className="btn-success-outline-small"
                                onClick={this.changeView}
                              >
                                Cancel
                              </button>

                              <button
                                className="btn-snap-view btn-cust-small ml-3"
                                onClick={this.addAmenities}
                              >
                                Save
                              </button>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {(global.userPermissions.checkPermission(
                            "property-amenities-add"
                          ) ||
                            global.userPermissions.checkPermission(
                              "property-amenities-update"
                            )) &&
                          this.state.buildingOnboardingTab === 0 ? (
                            <React.Fragment>
                              <button
                                type="button"
                                className="btn-success-outline-small mg-r-20"
                                onClick={this.changeView}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn-success-outline-small"
                                onClick={this.addAmenities}
                              >
                                Save
                              </button>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div
                    className={
                      this.state.buildingOnboardingTab === 1
                        ? "onboarding_amnentity_sec"
                        : "aminitesSection"
                    }
                  >
                    <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                        {this.props.buildingOnboardingTab === 0 && (
                          <Alerts
                            show={
                              this.props.show
                                ? this.props.show
                                : this.state.showAlertModal
                            }
                            type={
                              this.props.type
                                ? this.props.type
                                : this.state.alertModalType
                            }
                            title={
                              this.props.title
                                ? this.props.title
                                : this.state.alertModalTitle
                            }
                            message={
                              this.props.message
                                ? this.props.message
                                : this.state.alertModalMessage
                            }
                          />
                        )}
                      </div>
                      {this.state.viewMode === 0 ? (
                        this.state.propertyAmenitiesListTotal > 0 ? (
                          <React.Fragment>
                            <div
                              className={
                                this.state.buildingOnboardingTab === 1
                                  ? "amenity_list_box"
                                  : "m-0"
                              }
                            >
                              {this.state.propertyAmenitiesListTotal > 0
                                ? Object.keys(
                                    this.state.propertyAmenitiesList
                                  ).map((opt, i) => {
                                    return (
                                      <div
                                        className={
                                          this.state.buildingOnboardingTab === 1
                                            ? "col-lg-12 pd-xs-l-15 pd-lg-l-15 pd-xs-r-0 pd-lg-r-0 amenity_col"
                                            : "col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30"
                                        }
                                        key={"amenity_type_" + i}
                                      >
                                        <h3
                                          className={
                                            this.state.buildingOnboardingTab ===
                                            1
                                              ? "mg-l-0 pt-0 background-head d-flex justify-content-between align-items-center onbording_heading"
                                              : "mg-l-0 mg-t-15 background-head d-flex justify-content-between align-items-center"
                                          }
                                        >
                                          {opt}
                                        </h3>
                                        <div className="aminityListView pd-l-10 pd-r-10 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-0">
                                          <ul className="attraction">
                                            {this.state.propertyAmenitiesList[
                                              opt
                                            ].map((k, v) => {
                                              return (
                                                <li
                                                  className="m-2"
                                                  key={"amenity_" + v}
                                                >
                                                  {k.amenity_name}
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </React.Fragment>
                        ) : (
                          <NoData msg="amenities" />
                        )
                      ) : (
                        <div className="m-0 pd-lg-l-0 pd-xs-l-20">
                          <div
                            className={
                              this.state.buildingOnboardingTab === 1
                                ? "col-lg-12 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-0"
                                : "col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-lg-t-15 pd-xs-r-0 pd-lg-r-30"
                            }
                          >
                            <h3
                              className={
                                this.state.buildingOnboardingTab === 1
                                  ? "mg-l-0 background-head pt-0"
                                  : "mg-l-0 background-head"
                              }
                            >
                              All Available Amenities
                            </h3>
                          </div>

                          <div
                            className={
                              this.state.buildingOnboardingTab === 1
                                ? "row align-items-center border-bottom-0 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15"
                                : "row align-items-center pd-b-30 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30"
                            }
                          >
                            <div
                              className={
                                this.state.buildingOnboardingTab === 1
                                  ? "col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-0 pd-r-0 mg-b-0"
                                  : "col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-0 pd-r-15 mg-b-0"
                              }
                            >
                              {this.state.activeAmenities === 1 ? (
                                <React.Fragment>
                                  <div className="select-all-checkbox d-block w-100 mg-b-20">
                                    <div className="ckbox form-check">
                                      <label
                                        htmlFor="select_all"
                                        className="form-label text-left pd-b-5 mg-l-20 mg-r-10"
                                      >
                                        <b>Select All</b>
                                      </label>
                                      <input
                                        name="select_all"
                                        type={"checkbox"}
                                        id={`select_all`}
                                        onChange={(e) => this.changeHandler(e)}
                                        className="form-check-input"
                                        checked={this.state.select_all}
                                      />
                                      <label
                                        title=""
                                        htmlFor="select_all"
                                        className="form-check-label"
                                      ></label>
                                    </div>
                                  </div>
                                  {this.state.amenitiesListTotal > 0
                                    ? Object.keys(this.state.amenitiesList).map(
                                        (item, o) => {
                                          return (
                                            <>
                                              <div
                                                className="col-lg-12 pd-l-0 pd-r-0"
                                                key={"amenity_type_" + o}
                                              >
                                                <h3 className="mg-l-0 background-head d-flex justify-content-between align-items-center">
                                                  {item}
                                                </h3>
                                              </div>
                                              <div className="row align-items-center pd-b-30 border-bottom-0 pd-l-0 pd-r-0 pd-r-0">
                                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-l-0 pd-r-0 pd-r-40 mg-b-0">
                                                  <div className="aminityList">
                                                    {this.state
                                                      .amenitiesListTotal >
                                                    0 ? (
                                                      <ul className="formcheck-section mg-b-0 flex-wrap">
                                                        {this.state
                                                          .amenitiesListTotal >
                                                        0
                                                          ? this.state.amenitiesList[
                                                              item
                                                            ].map(
                                                              (item1, i) => {
                                                                var check_res =
                                                                  item1.checked ===
                                                                  true
                                                                    ? "checked"
                                                                    : "";
                                                                return (
                                                                  <React.Fragment
                                                                    key={
                                                                      "amenity_" +
                                                                      item1 +
                                                                      "_" +
                                                                      i
                                                                    }
                                                                  >
                                                                    <li>
                                                                      <div className="ckbox form-check">
                                                                        <label
                                                                          htmlFor={
                                                                            item1.amenity_slug
                                                                          }
                                                                          className="form-label text-left mg-l-20 pd-b-5"
                                                                        >
                                                                          {
                                                                            item1.amenity_name
                                                                          }
                                                                        </label>
                                                                        <input
                                                                          name="affordable_component_check"
                                                                          type="checkbox"
                                                                          id={
                                                                            item1.amenity_slug
                                                                          }
                                                                          className="form-check-input"
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            this.handleCheckboxChangeNew(
                                                                              e,
                                                                              item
                                                                            )
                                                                          }
                                                                          checked={
                                                                            check_res
                                                                          }
                                                                        />
                                                                        <label
                                                                          title=""
                                                                          htmlFor={
                                                                            item1.amenity_slug
                                                                          }
                                                                          className="form-check-label"
                                                                        ></label>
                                                                      </div>
                                                                    </li>
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            )
                                                          : ""}
                                                      </ul>
                                                    ) : (
                                                      <h6 className="mg-t-20 d-block w-100 text-center">
                                                        There are no listing
                                                        amenities added in the
                                                        system.
                                                      </h6>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )
                                    : ""}
                                </React.Fragment>
                              ) : (
                                <h6 className="mg-t-20 text-center w-100">
                                  There are no amenities added in the system.
                                </h6>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default Amenities;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: UploadVideoAddForm.jsx                       //
//  Application:  CMS                                     //
//  Option: For add or update the video uploaad           //
//  Developer: Devendra Kumar                             //
//  Date: 07-06-2023                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import FileUploadService from "../../services/FileUploadService";
import CorporateAppService from "../../services/CorporateAppService";
import CMSSidebar from "../common/CMSSidebar";
import Moment from "moment";
export class UploadVideoAddForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: this.props.match.params.slug,
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      isSubmit: false,
      status_id: false,
      private_content_status: false,
      bodyPopup: false,
      description: "",
      uploaded_date: "",
      title: "",
      video_file: "",
      file_path: "",
      file_name: "",
      sort_order: "",
      category: "",
      videoData: {},
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
      content_type: 1,
    };
    this.editorConfig = {
      extraButtons: ["uploadImage", "uploadImageVideo"],
      width: "auto",
      height: "auto",
      minHeight: 500,
      events: {
        afterInit: (instance) => {
          this.jodit = instance;
        },
      },
      // enter: ""
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.privateContentStatus = this.privateContentStatus.bind(this);
  }

  async componentDidMount() {
    if (this.state.slug !== undefined) {
      let res = await CorporateAppService.getVideoDataBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          title: res.data.video_title,
          description: res.data.description,
          video_file: res.data.video_file,
          stream_url: res.data.stream_url,
          uploaded_date: res.data.upload_date_time,
        });
      }
    }
    if(this.state.slug !== undefined){
      document.getElementById("preview_video").classList.remove("d-none");
    }else{
      document.getElementById("preview_video").classList.add("d-none");
    }
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    this.setState({ showSpinner: false, loading: false });
  }
  videoUpload = async () => {
    if (this.validator.allValid()) {
      this.setState({ isSubmit: true });
      let input = document.getElementById("video_file");
      const videoFile = input.files[0];
      let videofilename = videoFile.name.toLowerCase();
      if (!videoFile) {
        return;
      }
      let filesizekb = input.files[0].size / 1024;
      let filesizemb = filesizekb / 1024;
      let errors = {};
      if (filesizemb > 10) {
        errors["video_file"] = "Please upload a video of less than 10 MB.";
        this.setState({ errors: errors });
        this.setState({ isSubmit: false });
        return;
      }
      if (!videofilename.match(/\.(mp4|flv|mpg|webm|mxf)$/)) {
        errors["video_file"] = "Invalid video format. Please use mp4, flv, mpg, webm, mxf file types only.";
        this.setState({ errors: errors });
        this.setState({ isSubmit: false });
        return;
      }
      if(videoFile){
        this.setState({errors: ""});
      }
      let fileData = new FormData();

      fileData.append("file", videoFile, videoFile.name);
      fileData.append("fileName", videoFile.name);
      let response = await FileUploadService.uploadCmsVideo(fileData);

      if (global.successStatus.includes(response.status)) {
        this.setState({
          file_path: response.data.filePath,
          file_name: response.data.fileName,
        });
        this.createCorporateApp();
      }
    } else {
      this.validator.showMessages();
    }
  };
  updateVideo = async () => {
    if (this.validator.allValid()) {
      this.setState({ isSubmit: true });
      let input = document.getElementById("video_file");
      let videoFile = input.files[0];
      let videofilename = "";
      if(videoFile!==undefined){
        videofilename = videoFile.name.toLowerCase();
      }
      if (videoFile !== undefined) {
        if (!videoFile) {
          return;
        }
        let filesizekb = input.files[0].size / 1024;
        let filesizemb = filesizekb / 1024;
        let errors = {};
        if (filesizemb > 10) {
          errors["video_file"] = "Please upload a video of less than 10 MB.";
          this.setState({ errors: errors });
          this.setState({ isSubmit: false });
          return;
        }
        if (!videofilename.match(/\.(mp4|flv|mpg|webm|mxf)$/)) {
          errors["video_file"] = "Invalid video format. Please use mp4, flv, mpg, webm, mxf file types only.";
          this.setState({ errors: errors });
          this.setState({ isSubmit: false });
          return;
        }
        if(videoFile){
          this.setState({errors: ""});
        }
        let fileData = new FormData();
        fileData.append("file", videoFile, videoFile.name);
        fileData.append("fileName", videoFile.name);
        let response = await FileUploadService.uploadCmsVideo(fileData);
        if (global.successStatus.includes(response.status)) {
          this.setState({
            file_path: response.data.filePath,
            file_name: response.data.fileName,
          });
          this.createCorporateApp();
        }
      } else {
        this.createCorporateApp();
      }
    } else {
      this.validator.showMessages();
    }
  };
  createCorporateApp = async () => {
    let inputData = {
      title: this.state.title,
      description: this.state.description,
      video_file: this.state.file_name,
      file_path: this.state.file_path,
      upload_date_time: this.state.slug === undefined ? Moment(new Date()).format("YYYY-MM-DD H:mm:ss") : this.state.uploaded_date,
    };
    if (this.state.slug !== undefined) {
      let res = await CorporateAppService.updateVideoUpload(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });

        setTimeout(
          () => this.props.history.push("/upload-video"),
          global.redirect_time
        );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
    } else {
      let res = await CorporateAppService.createVideoUpload(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        setTimeout(
          () => this.props.history.push("/upload-video"),
          global.redirect_time
        );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };
  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("client_name");
    }
  }

  privateContentStatus() {
    this.setState({
      private_content_status: !this.state.private_content_status,
    });
    this.validator.hideMessages();
    if (!this.state.private_content_status) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("client_name");
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.setState({errors: ""});
    this.validator.showMessageFor(event.target.name);
  };
  previewVideo = (e) => {
    const reader = new FileReader();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
    reader.onload = (readerEvent) => {
      this.setState({stream_url: readerEvent.target.result});
    }
    document.getElementById("preview_video").classList.remove("d-none");
  }
  getTitle() {
    return "Video Upload";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }
  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <CMSSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push("/upload-video");
                            else return false;
                          else this.props.history.push("/upload-video");
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="btn-success ml-3"
                        type="button"
                        onClick={
                          this.state.slug ? this.updateVideo : this.videoUpload
                        }
                        disabled={this.state.isSubmit ? true : false}
                      >
                        {this.state.isSubmit
                          ? global.loader
                          : this.state.slug !== undefined
                          ? "Save"
                          : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="event_form">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0">
                        <div className="row align-items-center col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-xs-l-0 pd-xs-r-0 pd-l-0 pd-r-0 border-bottom-0">
                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-r-15 pd-xs-r-0 border-0">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Title{" "}
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="title"
                                value={this.state.title || ""}
                                autoComplete="off"
                              />
                              {this.state.errors.title ? (
                                <div className="text-danger">
                                  {this.state.errors.title}
                                </div>
                              ) : (
                                this.validator.message(
                                  "title",
                                  this.state.title,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Description{" "}
                                <span className="optional">(Optional)</span>
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="description"
                                value={this.state.description || ""}
                                className="form-control max_width_100"
                                id="description"
                                as="textarea"
                                rows="5"
                              />
                              {this.state.errors.description ? (
                                <div className="text-danger">
                                  {this.state.errors.description}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pd-lg-l-15 pd-xs-l-0 pd-lg-r-15 pd-xs-r-0 mg-b-15 video_thumb_img">
                              <label className="form-label text-left pd-b-5">
                                Video File{" "}
                              </label>
                              <Form.Control
                                 onChange={(e) => {
                                  this.changeHandler(e);
                                  this.previewVideo(e);
                                }}
                                type="file"
                                accept="video/*"
                                name="video_file"
                                id="video_file"
                                autoComplete="off"
                              />
                              {this.state.errors.video_file ? (
                                <div className="text-danger">
                                  {this.state.errors.video_file}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Video File",
                                  this.state.video_file,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                                <video
                                  src={this.state.stream_url}
                                  alt=""
                                  controls="true"
                                  id="preview_video"
                                ></video>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default UploadVideoAddForm;

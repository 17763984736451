/**************************************************************
 * Program: TurnOverBnbService.js
 * Application: TurnOverBnbService
 * Option: Used for manage TurnOverBnbService
 * Developer: Chandra Shekhar
 * Date: 14-11-2022
 **************************************************************/

import ApiService from "./ApiService";

class TurnOverBnbService {
  getProjectDetails(queryString) {
    let endPoint =
      "project-detail-tbnb" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createProject(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "create-project-tbnb";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getUnitData(queryString) {
    let endPoint = "get-unit-tbnb" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };

    let response = ApiService.getCall(inputData);
    return response;
  }

  mappingUnits(data) {
    let endPoint = "map-unit-tbnb";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  mappUnits(data) {
    let endPoint = "create-tbnb-property";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getCleanerListDetails(queryString) {
    let endPoint = 'cleaner-list-details'+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
    let response = ApiService.getCall(inputData);
    return response;
}


}

export default new TurnOverBnbService();

import React from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { useState, useEffect } from "react";
import TurnOverBnbService from "../../../services/TurnOverBnbService";
import StarRatings from "react-star-ratings";

const TbnbCleanerList = (props) => {
  const [state, setState] = useState({
    extraData: window.location.pathname.split("/")[4],
    total_record: 0,
    records: [],
    extraButtons: [],
    tableQueryString: "",
    customQueryString: "",
    filter_value: "",
    isSearch: global.isSearch,
    is_search: 0,
    unit_number: "",
    input: {},
    property_slug: props.property_slug,
    activeClass: "alert-activity",
    columns: [
      {
        key: "unit_name",
        text: "Unit#",
        className: "unit_name hand-cursor",
        sortable: false,
        cell: (record) => {
          return record.unit_name ? record.unit_name : "";
        },
      },
      {
        key: "cleaner_id",
        text: "Cleaner Name",
        className: "cleaner_id hand-cursor text-left",
        sortable: false,
        cell: (record) => {
          return record.cleaner_name ? record.cleaner_name : "";
        },
      },
      {
        key: "rating",
        text: "Cleaner Rating",
        className: "rating text-left",
        sortable: false,
        cell: (record) => {
          return ratingStar(record.rating ? parseFloat(record.rating) : 0);
        },
      },
    ],
    config: {
      sort: {
        column: "",
        order: "",
      },
      key_column: "slug",
      page_size: global.page_size,
      length_menu: global.page_length_menu,
      show_length_menu: true,
      show_filter: global.show_filter,
      show_pagination: global.show_pagination,
      pagination: "advance",
      button: {
        excel: global.excel_button,
        print: global.print_button,
        extra: false,
      },
      language: {
        no_data_text: "No data found",
      },
    },
  });

  const ratingStar = (data) => {
    return (
      <StarRatings
        rating={data ? data : 0}
        starRatedColor="rgb(255,165,52)"
        numberOfStars={5}
        starDimension="20px"
      />
    );
  };

  const tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      setState({ ...state, tableQueryString: queryString });
    } else {
      queryString += "&" + state.customQueryString;
      setState({ ...state, tableQueryString: queryString });
    }

    if (data.filter_value !== state.filter_value) {
      clearTimeout(state.filter_time);
      setState({
        ...state,
        filter_time: setTimeout(() => getData(queryString), global.filter_time),
        filter_value: data.filter_value,
      });
    } else {
      getData(queryString);
    }
  };

  const getData = async (queryString = "") => {
    var list = [];
    queryString =
      queryString !== ""
        ? "property_slug=" +
          props.propertySlug +
          (queryString ? "&" + queryString : "")
        : "property_slug=" +
          props.propertySlug +
          (queryString ? "&" + queryString : "");
    let res = await TurnOverBnbService.getCleanerListDetails(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data && res.data.data ? res.data.data : {};
    }
    setState({
      ...state,
      records: list,
      isSearch: 0,
      total_record: res.data ? res.data.total : 0,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="dashboardRightcard dashbaord-pageHeadertitle- background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
      <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
        <div className="pd-lg-l-0 pd-xs-l-15">
          <ReactDatatable
            className="table no-wrap mg-b-30 pd-b-2 text-align-left"
            config={
              state.total_record > global.page_size
                ? state.config
                : state.config
            }
            records={state.records}
            total_record={state.total_record}
            columns={state.columns}
            extraButtons={state.extraButtons}
            dynamic={true}
            onChange={tableChangeHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default TbnbCleanerList;

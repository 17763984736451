////////////////////////////////////////////////////////////
//     							                                      //
//  Program: TowerForm.jsx                                //
//  Application:  Tower                                   //
//  Option: For add or update Tower                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-01-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Modal, Form, Container } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";
// import pluswIcon from "./../../assets/images/plus.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Moment from "moment";
// import InputMask from "react-input-mask";
import UnitsService from "../../services/UnitsService";
import LeasingService from "../../services/LeasingService";
import RentService from "../../services/RentService";
import LeasingSidebar from "../common/LeasingSidebar";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";
export class RentForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      viewMode: 1,
      ownerList: [],
      ownerListTotal: 0,
      addOwnerModal: false,
      editOwnerModal: false,
      deleteOwnerModal: false,
      showFilter: 0,
      isSubmit: false,
      input: {},
      ismodal: false,
      errors: {},
      confirm_back: 0,
      ownerTypeList: [],
      newOwnerTypeList: [],
      ownerParentDataTotal: 1,
      selectedOwnerTypeList: [],
      Ownerlist: [],
      ownerParentData: [],
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      unit_rent_slug: this.props.match.params.slug,
    };
    this.changeView = this.changeView.bind(this);
    this.openaddOwnerModal = this.openaddOwnerModal.bind(this);
    this.openeditOwnerModal = this.openeditOwnerModal.bind(this);
    this.opendeleteOwnerModal = this.opendeleteOwnerModal.bind(this);
    this.saveOrUpdateUnitRent = this.saveOrUpdateUnitRent.bind(this);
    this.deleteOwner = this.deleteOwner.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  componentDidMount() {
    this.getUnitRentData();
    this.setState({ confirm_back: 0 });
    this.getUnitList();
  }

  //get perticuler unit rent data
  async getUnitRentData() {
    var list = [];
    let res = await RentService.getRentBySlug(this.state.unit_rent_slug);

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
    }
    this.setState({
      amount: list.amount,
      // rent_date:list.rent_month,
      // rent_paid_date:list.rent_paid_date,
      paid_by: list.rent_paid_by,
    });
  }

  // get Unit List
  /* to get unit data list */
  async getUnitList(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });

    this.setState({ showSpinner: false, loading: false });
  }

  /* to save ad agency data */
  saveOrUpdateUnitRent = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug,
        unit_slug: this.state.unit_slug,
        amount: this.state.amount,
        rent_month: (this.state.rent_date  !== undefined ) ? Moment(this.state.rent_date).format("YYYY-MM-DD") : "",
        rent_paid_date: (this.state.rent_pay_date  !== undefined ) ? Moment(this.state.rent_pay_date).format("YYYY-MM-DD") : "",
        rent_paid_by: this.state.paid_by,
      };

      if (this.state.unit_rent_slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;
        let res = await LeasingService.updateUnitRent(
          inputData,
          this.state.unit_rent_slug
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" + this.state.property_slug + "/rent"
                  : "/rent"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      } else {
        let res = await RentService.createRent(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                this.props.match.params.propertySlug !== undefined
                  ? "/properties/view/" + this.state.property_slug + "/rent"
                  : "/rent"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* To get ad agency details data */
  // async getOwnerDetails(slug) {
  //   if (slug) {
  //     var resData = {};
  //     var ownerParentDataTotal = 1;
  //     let res = await PropertyService.getOwnerInfoBySlug(slug);
  //     if (global.successStatus.includes(res.status)) {
  //       resData.owner_type = res.data.owner_type ? res.data.owner_type : "";
  //       var selectedOwnerTypeList = [];
  //       selectedOwnerTypeList =
  //         res.data.owner_type === 1
  //           ? {
  //               value: 1,
  //               label: "GP",
  //             }
  //           : {
  //               value: 2,
  //               label: "LP",
  //             };
  //       resData.owner_name = res.data.owner_name ? res.data.owner_name : "";
  //       resData.ein_ssn = res.data.ein_ssn ? res.data.ein_ssn : "";
  //       resData.amount = res.data.amount ? res.data.amount : "";
  //       resData.ownership = res.data.ownership ? res.data.ownership : "";
  //       resData.email = res.data.email ? res.data.email : "";
  //       resData.phone = res.data.phone ? res.data.phone : "";
  //       resData.notes = res.data.notes ? res.data.notes : "";

  //       resData.ownerParentData = res.data.ownerParentData
  //         ? res.data.ownerParentData
  //         : "";
  //       const values = [];
  //       if (resData.ownerParentData.length > 0) {
  //         resData.ownerParentData.forEach((item, i) => {
  //           values.push({
  //             owner_name: item.owner_name,
  //             amount: item.amount,
  //             ownership: item.ownership,
  //             notes: item.notes,
  //             slug: item.slug ? item.slug : "",
  //             selected_owner_parent_data: {
  //               value: item.owner_type,
  //               label: item.owner_type === 1 ? "GP" : "LP",
  //             },
  //           });
  //         });
  //       } else {
  //         resData.ownerParentData = [
  //           {
  //             owner_name: "",
  //             amount: "",
  //             ownership: "",
  //             notes: "",
  //           },
  //         ];
  //       }

  //       ownerParentDataTotal = values.length;
  //       this.setState({ selectedOwnerTypeList: selectedOwnerTypeList });
  //       this.setState({
  //         // owner_parent_data: values,
  //         ownerParentData: values,
  //         ownerParentDataTotal: ownerParentDataTotal,
  //       });
  //     }
  //     this.setState(resData);
  //   }
  // }

  /* to delete ad agency data */
  async deleteOwner(slug) {
    this.setState({ showSpinner: true });
    if (slug) {
      let res = await PropertyService.deletePropertyOwner(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closedeleteOwnerModal();
        this.getOwnerDetails(this.state.owner_slug);
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.errorMessage
            ? res.data.errorMessage
            : res.data.message,
        });
      }
      this.setState({ showSpinner: false });
      this.closedeleteOwnerModal();
    }
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  openaddOwnerModal() {
    this.setState({ addOwnerModal: true });
    this.setState({ confirm_back: 0 });
  }

  closeaddOwnerModal() {
    this.setState({ addOwnerModal: false });
    this.setState({
      owner_type: "",
      owner_name: "",
      ein_ssn: "",
      amount: "",
      ownership: "",
      email: "",
      phone: "",
      notes: "",
    });
    this.validator.hideMessages();
    this.setState({ confirm_back: 0 });
  }

  openeditOwnerModal(slug) {
    this.getOwnerDetails(slug);
    this.setState({ editOwnerModal: true });
    this.setState({ slug: slug });
    this.setState({ confirm_back: 0 });
  }

  closeeditOwnerModal() {
    this.setState({ editOwnerModal: false });
    this.setState({
      owner_type: "",
      owner_name: "",
      ein_ssn: "",
      amount: "",
      ownership: "",
      email: "",
      phone: "",
      notes: "",
    });
    this.validator.hideMessages();
    this.setState({ confirm_back: 0 });
  }

  opendeleteOwnerModal(slug) {
    this.setState({ deleteOwnerModal: true });
    this.setState({ slug: slug });
    this.setState({ confirm_back: 0 });
  }

  closedeleteOwnerModal() {
    this.setState({ deleteOwnerModal: false });
    this.setState({ slug: "" });
    this.setState({ confirm_back: 0 });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.setState({ confirm_back: 1 });
  };

  handleChangeselect = (index, event) => {
    const values = [...this.state.ownerParentData];
    values[index].selectedOwnerTypeList = event.value;
    values[index].owner_type = event.value;
    values[index].selected_owner_parent_data = event;
    this.setState({ ownerParentData: values });
    this.setState({ confirm_back: 1 });
  };

  handleChange = (index, event) => {
    const values = [...this.state.ownerParentData];
    if (event.target.name === "owner_name") {
      values[index].owner_name = event.target.value;
      this.setState({ ownerParentData: values });
    } else if (event.target.name === "amount") {
      values[index].amount = event.target.value;
    } else if (event.target.name === "ownership") {
      values[index].ownership = event.target.value;
    } else if (event.target.name === "notes") {
      values[index].notes = event.target.value;
    }
    this.setState({ ownerParentData: values });
    this.setState({ confirm_back: 1 });
  };

  handleAddFields = () => {
    const values = [...this.state.ownerParentData];
    var ownerParentDataTotal = 1;
    values.push({
      owner_type: "",
      owner_name: "",
      amount: "",
      ownership: "",
      notes: "",
    });
    ownerParentDataTotal = values.length;
    this.setState({
      ownerParentData: values,
      ownerParentDataTotal: ownerParentDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  handleRemoveFields = (i) => {
    const values = [...this.state.ownerParentData];
    values.splice(i, 1);
    var ownerParentDataTotal = 0;
    ownerParentDataTotal = values.length;
    this.setState({ ownerParentData: values });
    this.setState({
      ownerParentDataTotal: ownerParentDataTotal,
    });
    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.getOwnerData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.getOwnerData();
    }
  }

  getTitle() {
    if (this.state.owner_slug !== undefined) return "Owners";
    else return "Rents";
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LeasingSidebar property_slug={this.state.property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLeasingSidebar
                      property_slug={this.state.property_slug}
                    />
                  </div>
                </div>
              )}
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {this.state.owner_slug !== undefined &&
                      global.userPermissions.checkPermission("rent-delete") ? (
                        <span
                          onClick={() => {
                            this.opendeleteOwnerModal(this.state.slug);
                          }}
                          className="deleteicon mg-r-20"
                        >
                          <img src={deleteicon} alt="deleteicon" />
                        </span>
                      ) : (
                        ""
                      )}

                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                this.props.match.params.propertySlug !==
                                  undefined
                                  ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/rent"
                                  : "/rent"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              this.props.match.params.propertySlug !== undefined
                                ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/rent"
                                : "/rent"
                            );
                        }}
                      >
                        Cancel
                      </button>
                      {global.userPermissions.checkPermission("rent-update") ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          onClick={this.saveOrUpdateUnitRent}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          {/* <img src={pluswIcon} alt="" /> */}
                          {this.state.isSubmit
                            ? global.loader
                            : this.state.owner_slug !== undefined
                            ? "Save"
                            : "Save"}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard  dashbaord-pageHeadertitle- background-none pt-0 pl-30 pr-0">
                    <div className="col-md-12 pd-sm-l-0 pd-sm-r-0 pd-lg-l-30 pd-lg-r-30 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="row align-items-center pd-l-15 pd-r-15 pd-t-30 pd-b-30 border-bottom-0">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Unit
                        </label>

                        <Select
                          styles={customStyles}
                          isClearable={true}
                          className="multiselect"
                          classNamePrefix={"my-custom-react-select"}
                          menuPlacement="auto"
                          value={this.state.selectedUnitList}
                          options={this.state.newUnitList}
                          onChange={(value) => this.handleChangeUnitList(value)}
                          defaultValue={this.state.selectedUnitList}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 2,
                            colors: {
                              ...theme.colors,
                              primary: "#fff",
                              primary75: "#000",
                              primary50: "#000",
                              primary25: "#000",
                            },
                          })}
                        />

                        {this.state.errors.selectedUnitList ? (
                          <div className="text-danger">
                            {this.state.errors.selectedUnitList}
                          </div>
                        ) : (
                          this.validator.message(
                            "unit",
                            this.state.selectedUnitList,
                            "required",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Rent Month
                        </label>
                        <div className="col-lg-12 p-0 float-left">
                          <DatePicker
                             className="form-control max_width_100"
                             selected={this.state.rent_date}
                             onChange={(data) =>
                               this.changeHandler({
                                 target: {
                                   type: "date",
                                   name: "rent_date",
                                   value: data,
                                 },
                               })
                             }
                             dateFormat="MM/yyyy"
                             showMonthYearPicker 
                          />
                          {this.state.errors.rent_date ? (
                            <div className="text-danger">
                              {this.state.errors.rent_date}
                            </div>
                          ) : (
                            this.validator.message(
                              "rent date",
                              this.state.rent_date,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Amount
                        </label>
                        <div className="input-group-prepend prefix-dollar">
                          <span className="input-group-text">$</span>
                          <Form.Control
                            onChange={this.changeHandler}
                            name="amount"
                            value={
                              this.state.amount
                                ? this.state.amount
                                    .replace(/\.00$/, "")
                                    .replace(/[&#,+()$~%'":*?<>{}A-Za-z]/gi, "")
                                : "" || ""
                            }
                            onKeyPress={global.onKeyPressEvent.floatValidation}
                            className="form-control max_width_100"
                            id="inlineFormInput"
                            type="text"
                            maxLength="10"
                          />
                        </div>
                        {this.state.errors.amount ? (
                          <div className="text-danger">
                            {this.state.errors.amount}
                          </div>
                        ) : (
                          this.validator.message(
                            "Amount",
                            this.state.amount,
                            "max:100|required",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Rent Pay Date
                          <span className="optional">(Optional)</span>
                        </label>
                        <div className="col-lg-12 p-0 float-left">
                          <DatePicker
                            className="form-control max_width_100"
                            disabledKeyboardNavigation
                            strictParsing
                            scrollableYearDropdown
                            showMonthDropdown
                            showYearDropdown
                            autoComplete="off"
                            yearDropdownItemNumber={
                              global.yearDropdownItemNumber
                            }
                            name="rent_pay_date"
                            dateFormat="MM/dd/yy"
                            // minDate={
                            //   this.state.checkin_date
                            //     ? this.state.checkin_date
                            //     : new Date()
                            // }
                            selected={this.state.rent_pay_date}
                            onChange={(data) =>
                              this.changeHandler({
                                target: {
                                  type: "date",
                                  name: "rent_pay_date",
                                  value: data,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                        <label className="form-label text-left pd-b-5">
                          Paid By
                          <span className="optional">(Optional)</span>
                        </label>
                        <Form.Control
                          onChange={this.changeHandler}
                          name="paid_by"
                          value={this.state.paid_by || ""}
                          className="form-control max_width_100"
                          id="inlineFormInput"
                          type="text"
                          maxLength="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* delete wifi info popup modal */}
        <Modal show={this.state.deleteOwnerModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteOwnerModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-between mt-3 row">
                <button
                  type="button"
                  className="mx-auto m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteOwnerModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="mx-auto m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteOwner(this.state.owner_slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default RentForm;

////////////////////////////////////////////////////////////
//  Program: ChecklistService.js                          //
//  Application: Checklist                                //
//  Option: Used for manage Template and Checklist        //
//  Developer: Ashish Kumar					                    //
//  Date: 2022-05-16                                     //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class ChecklistService {
  getCheckList(queryString) {
    let endPoint = "checklist" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getChecklistRowBySlug(slug) {
    let endPoint = "checklist/" + slug;
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createCheckList(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "checklist";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateCheckList(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "checklist/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteCheckList(slug) {
    let endPoint = "checklist/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  getTemplateList(queryString) {
    let endPoint = "template" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createImportedCheckList(slug, data) {
    delete data.errors;
    delete data.input;
    let endPoint = "importChecklist/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  checkListExist(data) {
    delete data.errors;
    delete data.input;
    let endPoint = "checklistExist";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getZoneTypeList(queryString) {
    let endPoint = "zone-type" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new ChecklistService();

//     							                          //
//  Program: Features.jsx                            //
//  Application: Property                                   //
//  Option: Home Sharing Listing                               //
//  Developer: NP 		                                         //
//  Date: 2022-04-13                                           //
//                                                             //
/////////////////////////////////////////////////////////     ///

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import MarketingSidebar from "../common/MarketingSidebar";
import NoData from "../common/NoData";

class Features extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug : this.props.match.params.propertySlug
    };
  }

  render() {
    return (
        <main>
        <Container className="innter-container">
            <div className="d-flex flex-column">
                <div className="row row-xs mg-t-0 pd-t-0">
                    <div className="col-md-2 left">
                        <div className="child-menu-section">
                            <aside>
                                <MarketingSidebar property_slug={this.state.property_slug} />
                            </aside>
                        </div>
                    </div>
                    <div className="col-md-10 right">
                       <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                       <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                         <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                           <h3>Features</h3>
                           <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                           </div>
                        </div>
                      </div>
                      <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                        <NoData />
                      </div>
                    </div>
                </div>
            </div>
        </Container>
        </main>
    );
  }
}
export default Features
////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Leasing.jsx                                  //
//  Application: Leasing                                  //
//  Option: List all Leasing                              //
//  Developer: NP				                                  //
//  Date: 2022-08-18                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import LeasingService from "../../services/LeasingService";
import UnitsService from "../../services/UnitsService";
import ResidentService from "../../services/ResidentService";
import ErrorLogo from "./../../assets/images/errorImage.svg";
import SuccessLogo from "./../../assets/images/successImage.svg";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Modal,
  InputGroup,
  Tab,
  Nav,
  Form,
} from "react-bootstrap";
import pluswIcon from "./../../assets/images/plus.svg";
import Alerts from "../common/Alerts";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
// import deleteicon from "../../assets/images/delete.svg";
import filterIcon from "./../../assets/images/filter-gray.svg";
import Moment from "moment";
import NoData from "../common/NoData";
import LeasingSidebar from "../common/LeasingSidebar";
import DatePicker from "react-datepicker";
import Select from "react-select";
import queryString from "query-string";
import exportIcon from "./../../assets/images/export.svg";
import { FileUploader } from "react-drag-drop-files";
import { read, utils } from "xlsx";
import * as Vars from "./../../Vars";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";
import DataHubAutomationService from "../../services/DataHubAutomationService";
import TransferExport from '../property/TransferExport';
import MultiImageService from "./../../services/MultiImageService";

const DatabaseCols = Vars.default.DatabaseCols.filter((item) => {
  return item.module_name == "leasing_listing";
});
const DatabaseColMapping = Vars.default.DatabaseColMapping.filter((item) => {
  return item.module_name == "leasing_listing";
});

const LeaseRequiredFields = Vars.default.LeaseRequiredFields;
const mockData = Vars.default.mockData
let queryParams = queryString.parse(window.location.search);

export class Leasing extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      errors: {},
      importModal: false,
      file_url: "",
      file_name: "",
      open: true,
      eventKey: "3",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      isSearch: global.isSearch,
      module_search: "",
      is_search: 0,
      isModel: global.isModel,
      alertType: "xlsx",
      exportMesssage: "",
      exportOptionIsError: false,
      exportOptionColErrorMessage: "",
      exportFilePath: "",
      exportResult: "",
      input: {},
      transferDataSource :[],
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.property_slug
        ? this.props.property_slug
        : this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      tab: 1,
      leaseTabNumber: localStorage.getItem("lease_tab_number")
        ? parseInt(localStorage.getItem("lease_tab_number"))
        : 1,
      records: [],
      config: {
        sort: {
          column: "lease_start_date",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "unit_number",
          text: "Unit",
          className: "unit_id hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.unit && record.unit.unit_name
              ? record.unit.unit_name
              : record.unit && record.unit.unit_number
              ? record.unit.unit_number
              : record.unit_number
              ? record.unit_number
              : "";
          },
        },
        {
          key: "resident_id",
          text: "Resident",
          className: "first_name hand-cursor text-left td_clickable",
          sortable: true,
          cell: (record) => {
            return record.resident_user
              ? record.resident_user.first_name +
                  " " +
                  record.resident_user.last_name
              : "";
            // return record.resident_user ? <Link to={"/properties/view/"+this.state.property_slug+"/resident/view/"+record.resident_user.slug}>{record.resident_user.first_name + " " + record.resident_user.last_name}</Link> : "";
          },
        },
        // {
        //   key: "type",
        //   text: "Type",
        //   className: "type hand-cursor td_clickable",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.type === 1 ? "Month to Month" : "Fixed";
        //   },
        // },
        {
          key: "lease_start_date",
          text: "Start Date",
          className: "lease_start_date hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.lease_start_date
              ? Moment(record.lease_start_date, "MM/DD/YYYY").format(
                  global.dateFormat
                )
              : "";
          },
        },
        {
          key: "lease_end_date",
          text: "End Date",
          className: "lease_end_date hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.lease_end_date
              ? Moment(record.lease_end_date, "MM/DD/YYYY").format(
                  global.dateFormat
                )
              : "";
          },
        },
        {
          key: "furnished",
          text: "Furnished",
          className: "furnished hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.masterName
              ? record.masterName
              : "";
          },
        },
        {
          key: "monthly_rent",
          text: "Current Rent",
          className: "monthly_rent hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.monthly_rent
              ? "$" +
                  global.onKeyPressEvent.numberWithCommasForDecimal(
                    record.monthly_rent.replace(/\.00$/, "")
                  )
              : "";
          },
        },
        {
          key: "deposit",
          text: "Market Rent",
          className: "deposit hand-cursor",
          sortable: true,
          cell: (record) => {
            var UnitDeposite = record && record.unit ? record.unit.deposit : "";
            return UnitDeposite 
              ? "$" +
                  global.onKeyPressEvent.numberWithCommasForDecimal(
                    UnitDeposite.replace(/\.00$/, "")
                  )
              : "";
          },
        },
        {
          key: "lease_signed_date",
          text: "Signed Date",
          className: "lease_signed_date hand-cursor text-left td_clickable text-left",
          sortable: true,
          cell: (record) => {
            return record.lease_signed_date
              ? Moment(record.lease_signed_date, "MM/DD/YYYY").format(
                  global.dateFormat
                )
              : "";
          },
        },
        // {
        //   key: "status_id",
        //   text: "Status",
        //   className: "status_id hand-cursor text-left",
        //   sortable: true,
        //   // cell: (record) => {
        //   //   // return record.status_id === 1 ? (
        //   //   //   <b className="status-active m-0">Active</b>
        //   //   // ) : (
        //   //   //   <b className="status-inactive">InActive</b>
        //   //   // );
        //   //   return record.status_id === 1 ? "Active" : record.status_id === 2 ? "Draft" : "Expired"
        //   // },
        //   cell: (record) => {
        //     const currentDate = new Date();

        //     var dateFrom = Moment(record.lease_start_date).format("MM/DD/YYYY");
        //     var dateTo = Moment(record.lease_end_date).format("MM/DD/YYYY");
        //     var dateCheck = Moment(currentDate).format("MM/DD/YYYY");

        //     var d1 = dateFrom.split("/");
        //     var d2 = dateTo.split("/");
        //     var c = dateCheck.split("/");

        //     // var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
        //     // var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
        //     // var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

        //     if (record.status_id === 1 && (c >= d1 && c <= d2)) {
        //       return "Active";
        //     } else if (record.status_id === 1 && c <= d1) {
        //       return "Active";
        //     } else if (record.status_id === 2) {
        //       return "Inactive";
        //     } else if (c >= d2) {
        //       return "Expired";
        //     }
        //   },
        // },
      ],
      // newStatusList: [
      //   { label: "Active", value: 1 },
      //   { label: "Draft", value: 2 },
      //   { label: "Expired", value: 3 },
      // ],
      selectedStatusList: [],
      typeList: [
        { key: 1, value: 1, label: "Month to Month" },
        { key: 2, value: 2, label: "Fixed" },
      ],
      newTypeList: [],
      selectedTypeList: [],
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      residentList: [],
      newResidentList: [],
      selectedResidentList: [],
      file_rows: [],
      file_columns: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
      export_col_send: "",
      ConfirmationModal: false,
      is_date_invalid: false,
      requiredColumnsErrMsg: "",
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.confirmImport = this.confirmImport.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.leftTableColumns = [
      {
        dataIndex: "title",
        title: "Name",
      },
    ];
    this.rightTableColumns = [
      {
        dataIndex: "title",
        title: "Name",
      },
    ];

  }

  async openConfirmationModal() {
    if (this.state.file_url) {
      const missingColumns = this.state.database_cols_mapping.filter((item) => {
        return (
          LeaseRequiredFields.includes(item.database_column) &&
          item.file_column === ""
        );
      });

      if (missingColumns.length > 0) {
        this.setState({
          requiredColumnsErrMsg: "Please select all required column.",
        });
        return; // return missingColumns if you want to return the array
      } else {
        this.setState({ requiredColumnsErrMsg: "" });
      }
      this.setState({
        showSpinner: true,
        isSubmit: true,
        ConfirmationModal: false,
      });
      const fileData = new FormData();
      fileData.append(
        "mapped_columns",
        JSON.stringify(this.state.database_cols_mapping)
      );
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append("property_slug", this.state.property_slug);
      fileData.append("import_type", this.state.import_type);
      let res = await DataHubAutomationService.LeaseimportPreview(fileData);
      if (global.successStatus.includes(res.status)) {
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const recordError = res.data.record_error_array;
          const FailedRecord = res.data.failed_record;
          const is_date_invalid = res.data.is_date_invalid;
          if (is_date_invalid > 0) {
            this.setState({
              is_date_invalid: true,
            });
          } else {
            this.setState({
              is_date_invalid: false,
            });
          }
          this.setState({
            totalRecord: totalRecord,
          });
          this.setState({
            successRecord: successRecord,
          });
          this.setState({
            skipRecord: skipRecord,
          });
          this.setState({
            recordError: recordError,
          });
          this.setState({
            FailedRecord: FailedRecord,
          });
        }
      } else {
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const recordError = res.data.record_error_array;
          const FailedRecord = res.data.failed_record;
          const is_date_invalid = res.data.is_date_invalid;
          if (is_date_invalid > 0) {
            this.setState({
              is_date_invalid: true,
            });
          } else {
            this.setState({
              is_date_invalid: false,
            });
          }
          if (successRecord !== undefined) {
            this.setState({
              totalRecord: totalRecord,
            });
            this.setState({
              successRecord: successRecord,
            });
            this.setState({
              skipRecord: skipRecord,
            });
            this.setState({
              recordError: recordError,
            });
            this.setState({
              FailedRecord: FailedRecord,
            });
          }
        }
      }
      setTimeout(() => {
        this.setState({ showSpinner: false, isSubmit: false, show: false });
        this.setState({ ConfirmationModal: true });
      }, 2000);
    } else {
      this.setState({ ConfirmationModal: false });
      this.validator.showMessages();
    }
  }

  closeConfirmationModal() {
    this.setState({ ConfirmationModal: false });
  }

  componentDidMount() {
    if (this.props.match.params.propertySlug !== undefined)
      switch (this.state.leaseTabNumber) {
        case 2:
          document.getElementById("upcoming").click();
          break;
        case 3:
          document.getElementById("past").click();
          break;
        case 4:
          document.getElementById("inactive").click();
          break;
        case 5:
          document.getElementById("all").click();
          break;
        case 6:
          document.getElementById("upcoming_renewals").click();
          break;
        default:
          document.getElementById("current").click();
      }
      this.getTransferOptionList();
  }

  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      switch (this.state.leaseTabNumber) {
        case 2:
          document.getElementById("upcoming").click();
          break;
        case 3:
          document.getElementById("past").click();
          break;
        case 4:
          document.getElementById("inactive").click();
          break;
        case 5:
          document.getElementById("all").click();
          break;
        case 6:
            document.getElementById("upcoming_renewals").click();
            break;
        default:
          document.getElementById("current").click();
      }
    }, 200);
    this.setState({
      selectedUnitList: [],
      module_search: [],
      is_search: 0,
    });
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "current") {
      this.setState({ tab: 1 });
      this.getData(1);
      setTimeout(() => {
        localStorage.setItem(
          "lease_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "upcoming") {
      this.setState({ tab: 2 });
      this.getData(2);
      setTimeout(() => {
        localStorage.setItem(
          "lease_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "past") {
      this.setState({ tab: 3 });
      this.getData(3);
      setTimeout(() => {
        localStorage.setItem(
          "lease_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactive") {
      this.setState({ tab: 4 });
      this.getData(4);
      setTimeout(() => {
        localStorage.setItem(
          "lease_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "all") {
      this.setState({ tab: 5 });
      this.getData(5);
      setTimeout(() => {
        localStorage.setItem(
          "lease_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "upcoming_renewals") {
      this.setState({ tab: 6 });
      this.getData(6);
      setTimeout(() => {
        localStorage.setItem(
          "lease_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 6);
    } else {
      this.setState({ tab: 1 });
      this.getData(1);
      setTimeout(() => {
        localStorage.setItem(
          "lease_tab_number",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } 
  }

  async getData(tab, queryString = "") {
    var list = [];
    var totalRecords = 0;
    if (queryParams.isGlobal === "1") {
      var res = await LeasingService.getLeasingList(
        "property_slug=" +
          (this.state.property_slug ? this.state.property_slug : "") +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          "&is_search=1&" +
          queryParams.global_col_name +
          "=" +
          queryParams.global_search +
          (queryString ? "&" + queryString : "")
      );
    } else {
      res = await LeasingService.getLeasingList(
        "property_slug=" +
          (this.state.property_slug ? this.state.property_slug : "") +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          (queryString ? "&" + queryString : "")
      );
    }
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      // totalRecords = list.length ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
            // total_tower_count > 1
            //   ? unit_number + " (" + (tower ? tower.aka_name : "") + ")"
            //   : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  //to get resident data for search filter
  async getResidentData(queryString = "") {
    var residentList = [];
    var residentListTotal = 0;

    let res = await ResidentService.getResidentList(
      "is_dropdown=1&property_slug=" +
        (this.state.property_slug ? this.state.property_slug : ""),
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      residentList = res.data ? res.data : [];
      residentListTotal = residentList ? residentList.length : 0;
    }
    const newResidentList =
      residentListTotal > 0
        ? residentList.map(({ slug, first_name, last_name }) => ({
            value: slug,
            label: first_name + " " + last_name,
          }))
        : [];
    this.setState({
      residentList: residentList,
      residentListTotal: residentListTotal,
      newResidentList: newResidentList,
    });
  }

  async ExportData() {
    this.setState({ isSubmit: true, showSpinner: true });
    let exportOptionIsError = false;
    this.setState({ exportOptionColErrorMessage: ""});
    if (this.state.export_col_send == "" || this.state.export_col_send == undefined) {
      exportOptionIsError = true;
    }
    let data = {
      property_slug: this.state.property_slug,
      file_type: this.state.alertType,
      where_column: this.state.customQueryString.length != 0 ? this.state.customQueryString : "tab=" + this.state.tab,
      columns: this.state.export_col_send,
    };
    if (!exportOptionIsError) {
        let res = await LeasingService.exportDataFilewise(data);
        if (res.data.filePath) {
          this.getDownloadFile(res.data.filePath, res.data.fileName);
          this.setState({ exportMesssage: res.message });
          this.setState({ exportResult: data });
          this.setState({ exportFilePath: res.data.filePath });
          this.setState({ exportOptionColErrorMessage: "" });
          this.setState({ exportOptionIsError: false });
        }
    } else {
      this.setState({ 
        exportOptionColErrorMessage: "Please select and transfer column",
        exportOptionIsError : true,
      });
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  async getDownloadFile(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }

  customFilter() {
    this.setState({ todayDateFilter: 0 });
    let avaliableFrom = this.state.from_date
      ? Moment(this.state.from_date).format("YYYY-MM-DD")
      : "";
    let avaliableTo = this.state.to_date
      ? Moment(this.state.to_date).format("YYYY-MM-DD")
      : "";
    let filterValue = this.state.filter_value ? this.state.filter_value : "";
    let queryString =
      "filter_value=" +
      filterValue +
      "&checkin_date=" +
      avaliableFrom +
      "&checkout_date=" +
      avaliableTo +
      "&status_id=" +
      this.state.status_id +
      "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  // @somin - transfer changes start
  onChange = (nextTargetKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
    var database_cols = [];
    mockData.forEach((mData) => {
      nextTargetKeys.forEach((callbackCols) => {
        if (callbackCols === mData.key) {
          database_cols = [...database_cols, mData.value];
        }
      });
    });

    this.setState({ export_col_send: database_cols });
  };
  // @somin Transfer changes added

  resetDate() {
    this.setState({
      from_date: "",
      to_date: "",
      status_id: "",
      selectedStatusList: [],
    });
    this.setState({
      file_rows: [],
      file_columns: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
      requiredColumnsErrMsg: "",
    });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.getUnitData();
      // this.getResidentData();
      this.setState({ isSearch: 1 });
    }
  };

  exportToggle = () => {
    if (this.state.isModel === 1) {
      this.setState({ exportMesssage: "" });
      this.setState({ isModel: 0 });
    } else {
      this.setState({ isModel: 1 });
    }
    this.setState({ 
      exportMesssage: "" ,
      export_col_send: "" ,
      exportOptionIsError: "" ,
      exportOptionColErrorMessage: "" ,
    });
    this.getTransferOptionList();
  };

  getTransferOptionList = () => {
    var selectedCols = mockData.filter((item) => { return item.module_name === "leasing_listing"});
    this.setState({
      transferDataSource : selectedCols,
    });
  }



  handleCallback =(data) => {
    var  database_cols = []; 
     mockData.forEach((mData)=> {
         data.forEach((callbackCols) => {
            if(callbackCols === mData.key) {
               database_cols = [...database_cols , mData.value];
            }
         })
     })
     if (database_cols == "") {
      this.setState({ exportOptionColErrorMessage : "Please select and transfer column" });   
      this.setState({ exportOptionIsError : true });
     } else {
      this.setState({ exportOptionColErrorMessage : "" });   
      this.setState({ exportOptionIsError : false });
     }
   this.setState({ export_col_send : database_cols });
 }

  closeModal() {
    this.setState({
      file_rows: [],
      file_columns: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
      requiredColumnsErrMsg: "",
    });
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  // handleChangeStatusList(value) {
  //   let status_id = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       status_id.push(item.value);
  //     });
  //   }
  //   this.setState({
  //     selectedStatusList: value,
  //     status_id: value.value,
  //   });
  // }

  handleChangeTypeList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];
    this.setState({
      selectedTypeList: value,
      type: type,
    });
    this.setState({ confirm_back: 1 });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(this.state.tab, queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(this.state.tab, queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Leases";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  changeExport(evt) {
    let value = evt.target.value;
    this.setState({ alertType: value });
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      this.props.match.params.propertySlug !== undefined
        ? "leasing/" + data.slug + "/info"
        : this.state.property_slug
        ? "/leasing/view/" + this.state.property_slug + "/" + data.slug
        : "/leasing/view/" + data.property_slug + "/" + data.slug
    );
  };

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({
      lease_start_date: "",
      lease_end_date: "",
      lease_signed_date: "",
      unit_slug: "",
      type: "",
      resident_name: "",
      selectedUnitList: [],
      selectedTypeList: [],
      selectedResidentList: [],
    });
    this.setState({ module_search: "" });
  }

  filterData() {
    let leaseStart = this.state.lease_start_date
      ? Moment(this.state.lease_start_date).format("YYYY-MM-DD")
      : "";
    let leaseEnd = this.state.lease_end_date
      ? Moment(this.state.lease_end_date).format("YYYY-MM-DD")
      : "";
    let leaseSigned = this.state.lease_signed_date
      ? Moment(this.state.lease_signed_date).format("YYYY-MM-DD")
      : "";
    let queryString =
      "lease_start_date=" +
      leaseStart +
      "&lease_end_date=" +
      leaseEnd +
      "&lease_signed_date=" +
      leaseSigned +
      "&unit_slug=" +
      (this.state.unit_slug ? this.state.unit_slug : "") +
      "&type=" +
      (this.state.type ? this.state.type : "") +
      "&resident_name=" +
      (this.state.resident_name ? this.state.resident_name : "") +
      "&tab=" +
      this.state.tab +
      "&is_search=1";

    // let moduleSearchString =
    //   (this.state.lease_start_date ? "lease start date - " + leaseStart : '') +
    //   (this.state.lease_end_date ? " & lease end date - " + leaseEnd : '') +
    //   (this.state.lease_signed_date ? " & lease signed date - " + leaseSigned : '') +
    //   (this.state.unit_slug ? " & unit - " + this.state.selectedUnitList.label : '') +
    //   (this.state.type ? " & type - " + this.state.selectedTypeList.label : '') +
    //   (this.state.resident_slug ? " & resident - " + this.state.selectedResidentList.label : '');

    let moduleSearchString = "";
    moduleSearchString = this.state.lease_start_date
      ? "Lease Start Date - " +
        Moment(this.state.lease_start_date).format(global.dateFormat)
      : "";
    moduleSearchString = this.state.lease_end_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Lease End Date - " +
          Moment(this.state.lease_end_date).format(global.dateFormat)
        : " Lease End Date - " +
          Moment(this.state.lease_end_date).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.lease_signed_date
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Lease Signed Date - " +
          Moment(this.state.lease_signed_date).format(global.dateFormat)
        : " Lease Signed Date - " +
          Moment(this.state.lease_signed_date).format(global.dateFormat)
      : moduleSearchString;
    moduleSearchString = this.state.unit_slug
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Unit - " +
          this.state.selectedUnitList.label
        : " Unit - " + this.state.selectedUnitList.label
      : moduleSearchString;
    moduleSearchString = this.state.type
      ? moduleSearchString
        ? moduleSearchString +
          " ," +
          " Type - " +
          (this.state.type === 1 ? "Month to Month" : "Fixed")
        : " Type - " + (this.state.type === 1 ? "Month to Month" : "Fixed")
      : moduleSearchString;
    moduleSearchString = this.state.resident_name
      ? moduleSearchString
        ? moduleSearchString + " ," + " Resident - " + this.state.resident_name
        : " Resident - " + this.state.resident_name
      : moduleSearchString;

    if (
      this.state.lease_start_date ||
      this.state.lease_end_date ||
      this.state.lease_signed_date ||
      this.state.unit_slug ||
      this.state.type ||
      this.state.resident_name
    ) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString.split(",") });
      this.getData(this.state.tab, queryString);
    }
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeResidentList(value) {
    let resident_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        resident_slug.push(item.value);
      });
    }
    const resident_slug_value = value !== null ? value.value : [];

    this.setState({
      selectedResidentList: value,
      resident_slug: resident_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChange = (file) => {
    this.setState({ database_cols_mapping: DatabaseColMapping });
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, { raw: true });
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            header: 0,
            defval: "",
          });
          if (rows.length > 0) {
            let columns = Object.keys(rows[0]);
            let available_columns = columns.map((col) => {
              let val = col
                .replace("-", "_")
                .replace(/[^\w ]/g, "")
                .replace(/^\s+|\s+$/gm, "")
                .split(" ")
                .join("_")
                .replace("-", "_")
                .toLowerCase();
              return {
                label: col,
                value: val,
              };
            });
            this.setState({
              file_rows: rows,
              file_columns: columns,
              available_file_columns: available_columns,
            });
          } else {
            this.setState({ file_name: "" });
            this.setState({ file_url: "" });

            return false;
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
    this.setState({ file_name: file.name });
    this.setState({ file_url: file });
  };

  async confirmImport() {
    if (this.state.file_url) {
      this.setState({
        showSpinner: true,
        isSubmit: true,
        ConfirmationModal: false,
      });
      const fileData = new FormData();
      fileData.append(
        "mapped_columns",
        JSON.stringify(this.state.database_cols_mapping)
      );
      fileData.append("import_file", this.state.file_url);
      fileData.append("file_name", this.state.file_name);
      fileData.append("property_slug", this.state.property_slug);
      let res = await LeasingService.importData(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({ importModal: false });
        this.setState({ importResponseModel: true });
        this.setState({ successResponse: true });
        this.setState({ successMessage: res.message });
        this.setState({
          file_rows: [],
          file_columns: [],
          available_file_columns: [],
          database_cols_mapping: DatabaseColMapping,
          requiredColumnsErrMsg: "",
          file_url: "",
          file_name: "",
          // property_slug: "",
          import_type: "",
        });
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const failedRecord = res.data.failed_record;
          const skipData = res.data.skip_data;
          this.setState({
            totalRecord: totalRecord,
          });
          this.setState({
            successRecord: successRecord,
          });
          this.setState({
            skipRecord: skipRecord,
          });
          this.setState({
            failedRecord: failedRecord,
          });
          this.setState({
            skipData: skipData,
          });
        }
        this.getData();
      } else {
        this.setState({ database_cols_mapping: DatabaseColMapping });
        this.setState({ importModal: false });
        this.setState({ importResponseModel: true });
        this.setState({ errorResponse: true });
        this.setState({ errorMessage: res.data.message });
        if (res.data) {
          const totalRecord = res.data.total_record;
          const successRecord = res.data.success_record;
          const skipRecord = res.data.skip_record;
          const failedRecord = res.data.failed_record;
          const skipData = res.data.skip_data;
          if (successRecord !== undefined) {
            this.setState({
              totalRecord: totalRecord,
            });
            this.setState({
              successRecord: successRecord,
            });
            this.setState({
              skipRecord: skipRecord,
            });
            this.setState({
              failedRecord: failedRecord,
            });
            this.setState({
              skipData: skipData,
            });
          }
        }
      }
      this.setState({ showSpinner: false, isSubmit: false, show: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ ConfirmationModal: false });
      this.validator.showMessages();
    }
  }

  confirmImportModal() {
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({
      file_rows: [],
      file_columns: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
      requiredColumnsErrMsg: "",
      file_url: "",
      file_name: "",
      property_slug: "",
      import_type: "",
      importModal: false,
    });
    this.validator.hideMessages();
    //need to reload page if file field is mapped
    for (let i = 0; i < DatabaseColMapping.length; i++) {
      if (DatabaseColMapping[i].file_column !== "") {
        window.location.reload();
      }
    }
  }

  closeimportResponseModel() {
    this.setState({
      file_rows: [],
      file_columns: [],
      available_file_columns: [],
      database_cols_mapping: DatabaseColMapping,
      requiredColumnsErrMsg: "",
      file_url: "",
      file_name: "",
      property_slug: "",
      import_type: "",
    });
    this.setState({ importResponseModel: false });
    this.validator.hideMessages();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
    window.location.reload();
  }

  handleChangeImportColsList(value, index) {
    let elements = [...this.state.database_cols_mapping];
    elements[index].file_column = value.value;
    this.setState({ elements });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container className="innter-container pl-3">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  !this.props.property_slug ? (
                    <>
                      <div className="col-md-2 left">
                        <div className="child-menu-section">
                          <LeasingSidebar
                            property_slug={this.state.property_slug}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyLeasingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? !this.props.property_slug
                        ? "col-md-10 right"
                        : "col-md-12"
                      : "col-md-12"
                  }
                >
                  {!this.props.property_slug ? (
                    <>
                      <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                      <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                        <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                          <h3>{this.getTitle()}</h3>
                          <div className="d-flex flex-wrap justify-content-end pd-l-0 pd-r-0 mg-b-0">
                            {global.userPermissions.checkPermission(
                              "leases-add"
                            ) &&
                              this.state.property_slug && (
                                <Link
                                  to={
                                    this.props.match.params.propertySlug !==
                                    undefined
                                      ? "leasing/add"
                                      : "/leasing/add/" +
                                        this.state.property_slug
                                  }
                                >
                                  <button className="btn-success-outline-small ml-3">
                                    <img src={pluswIcon} alt="" /> Add
                                  </button>
                                </Link>
                              )}
                            {/* <button
                          onClick={this.filterToggle}
                          className="btn-success-outline-small"
                        >
                          <img src={filterIcon} alt="filterIcon" />{" "}
                          {this.state.showFilter === 1 ? "Filter" : "Filter"}
                        </button> */}
                            <button
                              onClick={this.searchToggle}
                              className="btn-success-outline-small ml-3"
                            >
                              <img src={filterIcon} alt="filterIcon" />{" "}
                              {this.state.isSearch === 1 ? "Search" : "Search"}
                            </button>
                            {global.userPermissions.checkPermission(
                              "leases-export"
                            ) && (
                              <button
                                className="btn-success-outline-small ml-3"
                                onClick={this.exportToggle}
                              >
                                Export
                              </button>
                            )}
                            {global.userPermissions.checkPermission(
                              "leases-import"
                            ) && (
                              <button
                                type="button"
                                onClick={() => this.confirmImportModal()}
                                className="btn-success-outline ml-3"
                              >
                                {" "}
                                <img src={exportIcon} alt="" /> Import{" "}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {this.props.match.params.propertySlug === undefined ? (
                    <>
                    <div className="row pd-lg-l-15 pd-xs-l-15">
                    <div className="col-xxl-2 col-sm-3 pd-lg-r-0 pd-xs-r-15 pd-lg-l-45 pd-xs-l-15">
                      <OutSidePropertySelectBox
                        propertySlug={this.getPropertySlug.bind(this)}
                        outSidePropertySlug={this.state.outSidePropertySlug}
                      />
                    </div>
                    <div className="col-xxl-10 col-md-9 col-sm-8 pd-r-15 pd-l-15">
                      {this.state.is_search === 1 ? (
                                  <>
                                    <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                                      <div className="custm-filter">
                                        <div>
                                          {" "}
                                          <label className="form-label text-left pd-b-2 mb-0">
                                            Search Applied On
                                          </label>
                                        </div>
                                        <div className="filter_bg_cust">
                                          {this.state.module_search.map(
                                            (item, i) => {
                                              return (
                                                <span key={i}>{item}</span>
                                              );
                                            }
                                          )}
                                          <button
                                            onClick={this.clearFilter}
                                            className="btn-fliter-clear"
                                          >
                                            Clear
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                  ) : (
                    ""
                  )}
                    </div>
                    </div>
                    </>
                  ) : (
                    <>
                    <div className="col-xxl-12 pd-r-15 pd-l-15">
                      {this.state.is_search === 1 ? (
                        <>
                          <div className="col-xxl-12 col-sm-12 pd-lg-r-15 pd-lg-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                            <div className="custm-filter">
                              <div> <label className="form-label text-left pd-b-2 mb-0">Search Applied On</label></div>
                              <div className="filter_bg_cust">
                                {
                                  this.state.module_search.map((item, i) => {
                                    return (
                                      <span key={i}>{item}</span>
                                    )
                                  })
                                }
                                <button
                                  onClick={this.clearFilter}
                                  className="btn-fliter-clear"
                                >Clear</button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    </>
                  )}
                  <div className="scrolling-carousel allpanding">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-0 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.showFilter ? (
                        <>
                          <Row className="align-items-end order_form col-md-12 pl-0 pr-0 mb-3 m-0 mb-3">
                            <Col
                              md={4}
                              lg={4}
                              xl={4}
                              className="mb-md-0 mb-3 pl-3 pr-3"
                            >
                              <label className="form-label text-left pd-b-5">
                                From
                              </label>
                              <DatePicker
                                className="form-control max_width_100"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                disabledKeyboardNavigation
                                strictParsing
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="from_date"
                                maxDate={this.state.to_date}
                                //placeholderText="Start Date"
                                dateFormat="MM/dd/yy"
                                selected={this.state.from_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "from_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                            </Col>
                            <Col
                              md={4}
                              lg={4}
                              xl={4}
                              className="mb-md-0 mb-3 pl-3 pr-3"
                            >
                              <label className="form-label text-left pd-b-5">
                                To
                              </label>
                              <DatePicker
                                className="form-control max_width_100"
                                scrollableYearDropdown
                                disabledKeyboardNavigation
                                strictParsing
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="to_date"
                                minDate={this.state.from_date}
                                //placeholderText="Start Date"
                                dateFormat="MM/dd/yy"
                                selected={this.state.to_date}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "to_date",
                                      value: data,
                                    },
                                  })
                                }
                              />
                            </Col>
                            {/* <Col
                            md={3}
                            lg={3}
                            xl={3}
                            className="mb-md-0 mb-3 pl-3 pr-3"
                          >
                            <label className="form-label text-left pd-b-5">
                              Status
                            </label>
                            <Select
                              styles={customStyles}
                              className="multiselect"
                              menuPlacement="auto"
                              value={this.state.selectedStatusList}
                              options={this.state.newStatusList}
                              getOptionValue={option => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeStatusList(value)
                              }
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                          </Col> */}
                            <Col md={3} lg={3} xl={3} className="mt-0">
                              <Row className="d-flex justify-content-end m-0">
                                <button
                                  onClick={this.customFilter}
                                  className="btn-success mg-l-0 mg-r-15"
                                >
                                  Apply
                                </button>
                                <button
                                  onClick={this.resetDate}
                                  className="btn-success-outline"
                                >
                                  Reset
                                </button>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="scrolling-carousel pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-0 scroll-slide">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="current"
                      onSelect={(e) => this.getActiveClass(e)}
                      eventKey={this.state.activeClass}
                      className="pd-r-60"
                    >
                      <ScrollingCarousel
                        rightArrow={true}
                        leftArrow={true}
                        className="swipesection scroll_nav_mob"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="current"
                            id="current"
                            className={
                              this.state.activeClass === "current"
                                ? "active"
                                : ""
                            }
                          >
                            Current
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="upcoming_renewals"
                            id="upcoming_renewals"
                            className={
                              this.state.activeClass === "upcoming_renewals"
                                ? "active"
                                : ""
                            }
                          >
                            Upcoming Renewals
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="upcoming"
                            id="upcoming"
                            className={
                              this.state.activeClass === "upcoming"
                                ? "active"
                                : ""
                            }
                          >
                            Upcoming Moveouts
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="past"
                            id="past"
                            className={
                              this.state.activeClass === "past" ? "active" : ""
                            }
                          >
                            Past
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="inactive"
                            id="inactive"
                            className={
                              this.state.activeClass === "inactive"
                                ? "active"
                                : ""
                            }
                          >
                            Inactive
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="all"
                            id="all"
                            className={
                              this.state.activeClass === "all" ? "active" : ""
                            }
                          >
                            All
                          </Nav.Link>
                        </Nav.Item>
                      </ScrollingCarousel>
                      <Tab.Content>
                        <Tab.Pane eventKey="current">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                            <div className="col-md-12 pl-0 pr-0">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>

                            {this.state.total_record !== 0 ||
                            this.state.is_search === 1 ||
                            queryParams.isGlobal === "1" ? (
                              <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                {queryParams.isGlobal === "1" ? (
                                  <>
                                    <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                      {"Search applied on: " +
                                        queryParams.global_col_label +
                                        " - " +
                                        queryParams.global_search}
                                      <button
                                        onClick={this.clearFilter}
                                        className="mg-l-15 btn-success-outline-small"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                
                                <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                  <ReactDatatable
                                    className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100"
                                    config={
                                      this.state.total_record > global.page_size
                                        ? this.state.config
                                        : this.state.config
                                    }
                                    records={this.state.records}
                                    columns={this.state.columns}
                                    extraButtons={this.state.extraButtons}
                                    dynamic={true}
                                    // loading={this.state.loading}
                                    total_record={this.state.total_record}
                                    onChange={this.tableChangeHandler}
                                    onRowClicked={this.rowClickedHandler}
                                  />
                                </div>
                              </div>
                            ) : (
                              // <div className="mg-t-30 text-center">
                              //   <p>No data found</p>
                              // </div>
                              <NoData msg="leasing" />
                            )}
                          </div>
                        </Tab.Pane>

                        <Tab.Pane eventKey="upcoming">
                          {this.state.tab === 2 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />

                              {this.state.total_record !== 0 ||
                              this.state.is_search === 1 ||
                              queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {/* {this.state.is_search === 1 &&
                                  this.state.tab === 2 ? (
                                    <>
                                      <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                                        <div className="custm-filter">
                                          <div>
                                            {" "}
                                            <label className="form-label text-left pd-b-2 mb-0">
                                              Search Applied On
                                            </label>
                                          </div>
                                          <div className="filter_bg_cust">
                                            {this.state.module_search.map(
                                              (item, i) => {
                                                return (
                                                  <span key={i}>{item}</span>
                                                );
                                              }
                                            )}
                                            <button
                                              onClick={this.clearFilter}
                                              className="btn-fliter-clear"
                                            >
                                              Clear
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList mg-b-100"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                </div>
                              ) : (
                                // <div className="mg-t-30 text-center">
                                //   <p>No data found</p>
                                // </div>
                                <NoData msg="leasing" />
                              )}
                            </div>
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="past">
                          {this.state.tab === 3 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />

                              {this.state.total_record !== 0 ||
                              this.state.is_search === 1 ||
                              queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {/* {this.state.is_search === 1 &&
                                  this.state.tab === 3 ? (
                                    <>
                                      <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                                        <div className="custm-filter">
                                          <div>
                                            {" "}
                                            <label className="form-label text-left pd-b-2 mb-0">
                                              Search Applied On
                                            </label>
                                          </div>
                                          <div className="filter_bg_cust">
                                            {this.state.module_search.map(
                                              (item, i) => {
                                                return (
                                                  <span key={i}>{item}</span>
                                                );
                                              }
                                            )}
                                            <button
                                              onClick={this.clearFilter}
                                              className="btn-fliter-clear"
                                            >
                                              Clear
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList mg-b-100"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                </div>
                              ) : (
                                // <div className="mg-t-30 text-center">
                                //   <p>No data found</p>
                                // </div>
                                <NoData msg="leasing" />
                              )}
                            </div>
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="inactive">
                          {this.state.tab === 4 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <div className="col-md-12 pl-0 pr-0">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>
                              {this.state.total_record !== 0 ||
                              this.state.is_search === 1 ||
                              queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {/* {this.state.is_search === 1 &&
                                  this.state.tab === 4 ? (
                                    <>
                                      <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                                        <div className="custm-filter">
                                          <div>
                                            {" "}
                                            <label className="form-label text-left pd-b-2 mb-0">
                                              Search Applied On
                                            </label>
                                          </div>
                                          <div className="filter_bg_cust">
                                            {this.state.module_search.map(
                                              (item, i) => {
                                                return (
                                                  <span key={i}>{item}</span>
                                                );
                                              }
                                            )}
                                            <button
                                              onClick={this.clearFilter}
                                              className="btn-fliter-clear"
                                            >
                                              Clear
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      onRowClicked={this.rowClickedHandler}
                                    />
                                  </div>
                                </div>
                              ) : (
                                // <div className="mg-t-30 text-center">
                                //   <p>No data found</p>
                                // </div>
                                <NoData msg="leasing" />
                              )}
                            </div>
                          )}
                        </Tab.Pane>

                        <Tab.Pane eventKey="all">
                          {this.state.tab === 5 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <div className="col-md-12 pl-0 pr-0">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>
                              {this.state.total_record !== 0 ||
                              this.state.is_search === 1 ||
                              queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {/* {this.state.is_search === 1 &&
                                  this.state.tab === 5 ? (
                                    <>
                                      <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                                        <div className="custm-filter">
                                          <div>
                                            {" "}
                                            <label className="form-label text-left pd-b-2 mb-0">
                                              Search Applied On
                                            </label>
                                          </div>
                                          <div className="filter_bg_cust">
                                            {this.state.module_search.map(
                                              (item, i) => {
                                                return (
                                                  <span key={i}>{item}</span>
                                                );
                                              }
                                            )}
                                            <button
                                              onClick={this.clearFilter}
                                              className="btn-fliter-clear"
                                            >
                                              Clear
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      onRowClicked={
                                        global.userPermissions.checkPermission(
                                          "leases-update"
                                        ) && this.rowClickedHandler
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                // <div className="mg-t-30 text-center">
                                //   <p>No data found</p>
                                // </div>
                                <NoData msg="leasing" />
                              )}
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="upcoming_renewals">
                          {this.state.tab === 6 && (
                            <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                              <div className="col-md-12 pl-0 pr-0">
                                <Alerts
                                  show={this.state.showAlertModal}
                                  type={this.state.alertModalType}
                                  title={this.state.alertModalTitle}
                                  message={this.state.alertModalMessage}
                                />
                              </div>
                              {this.state.total_record !== 0 ||
                              this.state.is_search === 1 ||
                              queryParams.isGlobal === "1" ? (
                                <div className="mg-t-18 brandList pd-l-30 pd-r-30">
                                  {queryParams.isGlobal === "1" ? (
                                    <>
                                      <div className="col-md-12 pd-l-0 pd-r-30 mg-t-15 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                                        {"Search applied on: " +
                                          queryParams.global_col_label +
                                          " - " +
                                          queryParams.global_search}
                                        <button
                                          onClick={this.clearFilter}
                                          className="mg-l-15 btn-success-outline-small"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {/* {this.state.is_search === 1 &&
                                  this.state.tab === 5 ? (
                                    <>
                                      <div className="col-xxl-12 col-sm-12 pd-r-0 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                                        <div className="custm-filter">
                                          <div>
                                            {" "}
                                            <label className="form-label text-left pd-b-2 mb-0">
                                              Search Applied On
                                            </label>
                                          </div>
                                          <div className="filter_bg_cust">
                                            {this.state.module_search.map(
                                              (item, i) => {
                                                return (
                                                  <span key={i}>{item}</span>
                                                );
                                              }
                                            )}
                                            <button
                                              onClick={this.clearFilter}
                                              className="btn-fliter-clear"
                                            >
                                              Clear
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  <div className="pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                                    <ReactDatatable
                                      className="table mg-b-30 pd-b-2 add-no-wrap text-align-left housekeepingList  mg-b-100"
                                      config={
                                        this.state.total_record >
                                        global.page_size
                                          ? this.state.config
                                          : this.state.config
                                      }
                                      records={this.state.records}
                                      columns={this.state.columns}
                                      extraButtons={this.state.extraButtons}
                                      dynamic={true}
                                      // loading={this.state.loading}
                                      total_record={this.state.total_record}
                                      onChange={this.tableChangeHandler}
                                      onRowClicked={
                                        global.userPermissions.checkPermission(
                                          "leases-update"
                                        ) && this.rowClickedHandler
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                // <div className="mg-t-30 text-center">
                                //   <p>No data found</p>
                                // </div>
                                <NoData msg="leasing" />
                              )}
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* Module search popup modal */}
        <Modal
          show={this.state.isSearch}
          className="modal-large searchModalbox"
          // onHide={() => {
          //   if (window.confirm("Do you want to exit without saving?"))
          //     this.searchToggle();
          // }}
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Leases</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Unit</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedUnitList}
                      options={this.state.newUnitList}
                      onChange={(value) => this.handleChangeUnitList(value)}
                      defaultValue={this.state.selectedUnitList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>

                

                <InputGroup className="mt-3">
                  <label>Resident</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="resident_name"
                      value={this.state.resident_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                    {/* <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      menuPlacement="auto"
                      value={this.state.selectedResidentList}
                      options={this.state.newResidentList}
                      onChange={(value) =>
                        this.handleChangeResidentList(value)
                      }
                      defaultValue={this.state.selectedResidentList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    /> */}
                  </div>
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Lease Signed Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="lease_signed_date"
                      selected={this.state.lease_signed_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "lease_signed_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Lease Start Date</label>
                  <DatePicker
                    className="form-control max_width_100"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    disabledKeyboardNavigation
                    strictParsing
                    autoComplete="off"
                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                    name="lease_start_date"
                    selected={this.state.lease_start_date}
                    dateFormat="MM/dd/yy"
                    onChange={(data) =>
                      this.changeHandler({
                        target: {
                          type: "date",
                          name: "lease_start_date",
                          value: data,
                        },
                      })
                    }
                  />
                </InputGroup>

                <InputGroup className="mt-3">
                  <label>Lease End Date</label>
                  <div className="col-md-12 p-0">
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      disabledKeyboardNavigation
                      strictParsing
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="lease_end_date"
                      selected={this.state.lease_end_date}
                      dateFormat="MM/dd/yy"
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "lease_end_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </div>
                </InputGroup>

                
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Module Export  popup modal */}
        <Modal show={this.state.isModel} className="modal-large" centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.exportToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Export</h3>
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="col-lg-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0 mt-3">
                <TransferExport
                    className="data_export_move table_export_move"
                    titles={["Database Columns", "Selected Database Columns"]}
                    dataSource={this.state.transferDataSource}
                    targetKeys={this.state.targetKeys}
                    // disabled={disabled}
                    showSearch={true}
                    onChange={this.onChange}
                    filterOption={(inputValue, item) =>
                      item.title.indexOf(inputValue) !== -1
                    }
                    filteredItems={this.state.transferDataSource}
                    leftColumns={this.leftTableColumns}
                    rightColumns={this.rightTableColumns}
                  />
                  {this.state.exportOptionColErrorMessage !== "" && (
                    <div className="text-danger">
                      {this.state.exportOptionColErrorMessage}
                    </div>
                  )}

                </div>
                <InputGroup className="mt-3 pl-3">
                  <label>Select Export File Type : </label>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="xlsx"
                        checked={this.state.alertType === "xlsx" ? true : false}
                        type={"radio"}
                        id="xlsx"
                        label={"XLSX"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-3 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                      <Form.Check
                        onChange={(e) => this.changeExport(e)}
                        name="alert_type"
                        value="csv"
                        checked={this.state.alertType === "csv" ? true : false}
                        type={"radio"}
                        id="csv"
                        label={"CSV"}
                        className={"rdiobox mg-r-20"}
                      />
                    </div>
                  </div>
                </InputGroup>

                {this.state.exportMesssage !== "" && (
                  <div className="mt-3 pl-3">
                    {this.state.exportMesssage}
                    <br></br>
                    <br></br>
                    {/*{this.state.exportFilePath !== undefined && (
                      <a href={this.state.exportFilePath}> Download File </a>
                    )} */ }
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="mx-auto- mg-r-30 btn btn btn-success btn-block"
                    onClick={() => this.ExportData()}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Export"}
                  </button>
                  <button
                    type="button"
                    className="mx-auto- m-0  btn btn-outline-light btn-block"
                    onClick={() => this.exportToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* import data modal */}
        <Modal
          show={this.state.importModal}
          className="importModalbox import_model_big"
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="row">
              <div className=" col-md-12 m-0">
                {this.state.file_rows.length ? (
                  <div className="view_tbl_bdr">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive view_tbl_exp">
                          <table className="table file_preview_tbl">
                            <thead>
                              <tr>
                                <th scope="col">Id</th>
                                {this.state.file_columns.length
                                  ? this.state.file_columns.map(
                                      (cols, index) => (
                                        <th scope="col" key={index}>
                                          {cols}
                                        </th>
                                      )
                                    )
                                  : ""}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.file_rows.length
                                ? this.state.file_rows.map(
                                    (file_row, index) => {
                                      let colVals = Object.values(file_row);
                                      return (
                                        <tr key={index}>
                                          <th scope="row">{index + 1}</th>
                                          {colVals.map((colVal, idx) => {
                                            let trimmedValue = colVal;
                                            const stringValue = String(colVal); // Convert colVal to a string

                                            const phoneIndex =
                                              stringValue.search(/Phone\s*:/i);
                                            const mobileIndex =
                                              stringValue.search(/Mobile\s*:/i);

                                            if (phoneIndex !== -1) {
                                              trimmedValue =
                                                stringValue.replace(
                                                  /Phone\s*:/i,
                                                  ""
                                                );
                                            }

                                            if (mobileIndex !== -1) {
                                              trimmedValue =
                                                trimmedValue.replace(
                                                  /Mobile\s*:/i,
                                                  ""
                                                );
                                            }

                                            return (
                                              <td key={idx}>{trimmedValue}</td>
                                            );
                                          })}
                                        </tr>
                                      );
                                    }
                                  )
                                : ""}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row mt-2">
                  <div className="col-md-8">
                    <div className="row desk_expo">
                      <div className="col-md-6 pr-0">
                        <div className="export_tbl_fst">
                          <p>Database Columns</p>
                        </div>
                      </div>
                      <div className="col-md-6 pl-0 ">
                        <div className="export_tbl_fst">
                          <p>File Column</p>
                        </div>
                      </div>
                    </div>
                    <div className="file_export_hgt">
                      <div className="col-md-6 p-0">
                        <div className="export_tbl_fst">
                          <p className="mob-expo">Columns</p>
                          <ul>
                            {DatabaseCols.map((column, index) => {
                              return (
                                <li key={index}>
                                  {column.label}{" "}
                                  {LeaseRequiredFields.includes(
                                    column.value
                                  ) ? (
                                    <label className="text-danger m-0">*</label>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6 p-0">
                        <div className="export_tbl_sec">
                          <p className="mob-expo">Select Columns</p>
                          <ul>
                            {DatabaseCols.map((column, index) => {
                              return (
                                <li key={index}>
                                  <Select
                                    placeholder={"Select For " + column.label}
                                    styles={customStyles}
                                    className="multiselect exp_filter_slect"
                                    menuPlacement="bottom"
                                    menuPosition="fixed"
                                    options={this.state.available_file_columns}
                                    getOptionValue={(option) =>
                                      `${option.label}`
                                    }
                                    onChange={(value) =>
                                      this.handleChangeImportColsList(
                                        value,
                                        index
                                      )
                                    }
                                    defaultValue={[]}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 2,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fff",
                                        primary75: "#000",
                                        primary50: "#000",
                                        primary25: "#000",
                                      },
                                    })}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-4 pt-0 m-0 download-sample elx_pdf_epo">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                      <div className="">
                        <form name="myFile">
                          <FileUploader
                            onChange={this.changeHandler}
                            handleChange={this.handleChange}
                            name="file_name"
                            types={["csv", "xlsx", "xls", "ods"]}
                            label="Drop File Here Max Size 30 MB"
                          />

                          {this.state.errors.file_name ? (
                            <div className="text-danger">
                              {this.state.errors.file_name}
                            </div>
                          ) : (
                            this.validator.message(
                              "import_file",
                              this.state.file_name,
                              "required",
                              { className: "text-danger" }
                            )
                          )}

                          {this.state.requiredColumnsErrMsg !== "" ? (
                            <div className="text-danger">
                              {this.state.requiredColumnsErrMsg}
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="d-flex justify-content-center mt-3 ">
                            <button
                              type="button"
                              className=" m-0 btn btn-outline-light btn-block"
                              onClick={() => this.closeConfirmImportModal()}
                            >
                              Cancel
                            </button>

                            <button
                              type="button"
                              className="m m-0 btn btn btn-success btn-block"
                              onClick={() => this.openConfirmationModal()}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {this.state.isSubmit ? global.loader : "Import"}
                            </button>
                          </div>
                        </form>
                      </div>
                      <Link
                        to="/import_sample/lease.csv"
                        target="_blank"
                        download="lease.csv"
                        className="dwon_imp_file"
                      >
                        Download sample template for Import
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* import data modal */}
        <Modal
          show={this.state.importResponseModel}
          className="modal-xs"
          centered
        >
          <Modal.Body className="width_402 p-0 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeimportResponseModel()}
            >
              {global.closee}
            </span>
            {/* <h3 className="text-center">Record detail</h3> */}
            <div className="row col-12 m-0">
              <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                <div className="d-flex justify-content-between mt-0 row">
                  {this.state.errorResponse == true ? (
                    <div className="msg col-12 text-center mt-4 mb-4">
                      <img src={ErrorLogo} alt="filterIcon" className="mb-2" />{" "}
                      <p className="mb-0">{this.state.errorMessage}</p>{" "}
                    </div>
                  ) : (
                    <div className="msg col-12 text-center mt-4">
                      <img
                        src={SuccessLogo}
                        alt="filterIcon"
                        className="mb-2"
                      />
                      <p className="mb-0">{this.state.successMessage}</p>{" "}
                    </div>
                  )}
                  {this.state.totalRecord ? (
                    <div className="mt-0">
                      <div className="row flex-wrap m-0">
                        <div className="row p-4 pb-0-cust">
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Total Record
                            </p>
                            <p className="media-body">
                              {this.state.totalRecord}{" "}
                            </p>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Success Record
                            </p>
                            <p className="media-body">
                              {this.state.successRecord}{" "}
                            </p>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Skip Record
                            </p>
                            <p className="media-body">
                              {this.state.skipRecord}{" "}
                            </p>
                          </div>{" "}
                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <p className="az-profile-name-text pl-0">
                              Failed Record
                            </p>
                            <p className="media-body">
                              {this.state.FailedRecord}
                            </p>
                          </div>
                        </div>
                        <div className="row pl-rm-cust pb-0-cust w-100">
                          <div className="col-sm-12">
                            <p className="az-profile-name-text pl-0">
                              Log Informations
                            </p>
                          </div>
                        </div>
                        <div className="row information-logs-container w-100 mb-3">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
                            <p
                              className="media-body pr-3"
                              dangerouslySetInnerHTML={{
                                __html: this.state.recordError
                                  ? this.state.recordError
                                  : "No any record skipped.",
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Confirm popup modal */}

        <Modal
          show={this.state.ConfirmationModal}
          onHide={() => {
            if (
              window.confirm(
                "Are you sure to exit the pop up without run Confirmation?"
              )
            )
              this.closeConfirmationModal();
          }}
          centered
          className="exp_conf_modal"
        >
          <Modal.Body className="width_402 p-0 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeConfirmationModal()}
            >
              {global.closee}
            </span>
            {/* before import preview */}
            <div className="moreMsg mt-3">
              <div className="row flex-wrap m-0">
                <div className="row p-4 pb-0-cust">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Total Record</p>
                    <p className="media-body">{this.state.totalRecord} </p>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Success Record</p>
                    <p className="media-body">{this.state.successRecord} </p>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Skip Record</p>
                    <p className="media-body">{this.state.skipRecord} </p>
                  </div>{" "}
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <p className="az-profile-name-text pl-0">Failed Record</p>
                    <p className="media-body">{this.state.FailedRecord}</p>
                  </div>
                </div>
                <div className="row pl-rm-cust pb-0-cust w-100">
                  <div className="col-sm-12">
                    <p className="az-profile-name-text pl-0">
                      Log Informations
                    </p>
                  </div>
                </div>
                <div className="row information-logs-container w-100 mb-3">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
                    <p
                      className="media-body mb-0 pr-3"
                      dangerouslySetInnerHTML={{
                        __html: this.state.recordError
                          ? this.state.recordError
                          : "No any record skipped.",
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            {/* end code before import preview */}
            <div className="text-center p-4">
              <h3>Ready for import?</h3>
              <p>
                Do you really want to run this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeConfirmationModal()}
                >
                  Cancel
                </button>
                {this.state.is_date_invalid == false ? (
                  <button
                    type="button"
                    className="m-0 btn btn btn-danger btn-block"
                    disabled={this.state.isSubmit ? true : false}
                    onClick={this.confirmImport}
                  >
                    {this.state.isSubmit ? global.loader : "Yes"}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="m-0 btn btn btn-danger btn-block"
                    disabled={true}
                  >
                    {this.state.isSubmit ? global.loader : "Yes"}
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Leasing;

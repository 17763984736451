////////////////////////////////////////////////////////////
//     							                                      //
//  Program: CheckinCalendar.jsx                          //
//  Application: Reservations                             //
//  Option: List all todays checkins                      //
//  Developer: NP  						                            //
//  Date: 2022-07-29                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React from "react";
import { useCallback, useState } from "react";
import CalendarService from "../../services/CalendarService";
// import { Eventcalendar, Popup, Button } from '@mobiscroll/react';
import {
  Eventcalendar,
  setOptions,
  CalendarNav,
  SegmentedGroup,
  SegmentedItem,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
  Popup,
  Button,
} from "@mobiscroll/react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import staffImg from "./../../assets/images/profile-nogender.png";
import CalendarSidebar from "../common/CalendarSidebar";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";
import AllPropertyReservationSidebar from "../common/AllPropertyReservationSidebar";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const CheckinCalendar = (props) => {
  // const view = React.useMemo(() => {
  //   return {
  //     agenda: { type: 'week' },
  //     // calendar: { type: 'week' },
  //   };
  // }, []);

  const [view, setView] = React.useState("week");
  const [calView, setCalView] = React.useState({
    agenda: {
      type: "week",
    },
  });

  const [event, setEvent] = useState({});
  const [events, myEvents] = useState([]);
  const [resources, myResources] = useState([]);
  const [isPopupOpen, setOpen] = useState(false);
  // const property_slug = props.match.params.propertySlug;
  const [property_slug, setPropertySlug] = useState(
    props.match.params.propertySlug !== undefined
      ? props.match.params.propertySlug
      : localStorage.getItem("outSidePorpertySlug")
      ? localStorage.getItem("outSidePorpertySlug")
      : ""
  );
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  const onPageLoading = React.useCallback((event, inst) => {
    const year = event.month.getFullYear();
    const month = event.month.getMonth();
    if (props.match.params.propertySlug !== undefined) {
      setPropertySlug(props.match.params.propertySlug);
      setTimeout(() => {
        getReservationdata(props.match.params.propertySlug, year, month);
      }, 200);
    } else {
      const propSlug = localStorage.getItem("outSidePorpertySlug")
        ? localStorage.getItem("outSidePorpertySlug")
        : "";
      setPropertySlug(propSlug);
      setTimeout(() => {
        getReservationdata(
          localStorage.getItem("outSidePorpertySlug")
            ? localStorage.getItem("outSidePorpertySlug")
            : "",
          year,
          month
        );
      }, 200);
    }
  }, []);

  const getReservationdata = async (property_slug, year, month) => {
    setPropertySlug(property_slug);
    setYear(year);
    setMonth(month);
    let res = await CalendarService.getReservations(
      "checkin?calendar=reservation&year=" +
        year +
        "&month=" +
        (month + 1) +
        "&property_slug=" +
        (property_slug ? property_slug : "")
    );

    if (global.successStatus.includes(res.status)) {
      myEvents(res.data.reservationData ? res.data.reservationData : []);
      myResources(res.data.unitData ? res.data.unitData : []);
    }
  };

  const renderEventContent = React.useCallback(
    (data) => {
      return (
        <React.Fragment>
          <div>
            {" "}
            {data.original.unit_name_new
              ? data.original.unit_name_new
              : data.original.unit_name}
          </div>
          <div className="md-custom-event-cont">
            <img className="md-custom-event-img" src={staffImg} alt="" />
            <div className="mbsc-custom-event-name">
              {data.original.title} - {data.original.confirmation_code}
            </div>
            {global.userPermissions.checkPermission("check-ins-update") && (
              <Link
                to={
                  props.match.params.propertySlug !== undefined
                    ? "/properties/view/" +
                      data.original.property_slug +
                      "/reservations/view/" +
                      data.original.reservation_slug
                    : "/reservations/view/" +
                      data.original.property_slug +
                      "/" +
                      data.original.reservation_slug
                }
              >
                <Button
                  className="md-custom-event-btn"
                  color="secondary"
                  variant="outline"
                >
                  View Reservation
                </Button>
              </Link>
            )}
          </div>
        </React.Fragment>
      );
    },
    [property_slug]
  );

  const openPopup = useCallback((event) => {
    setOpen(true);
    setEvent(event);
  }, []);

  const closePopup = useCallback(() => {
    setOpen(false);
  }, []);

  const renderMyHeader = () => {
    return (
      <React.Fragment>
        <CalendarPrev className="md-event-listing-prev" />
        <CalendarNav className="md-event-listing-nav" />
        <CalendarNext className="md-event-listing-next" />
        <div className="md-event-listing-picker">
          <SegmentedGroup value={view} onChange={changeView}>
            <SegmentedItem value="day">Day</SegmentedItem>
            <SegmentedItem value="week">Week</SegmentedItem>
            <SegmentedItem value="month">Month</SegmentedItem>
          </SegmentedGroup>
        </div>
        <CalendarToday className="md-event-listing-today  pr-4" />
      </React.Fragment>
    );
  };

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "day":
        calView = {
          agenda: {
            type: "day",
          },
        };
        break;
      case "week":
        calView = {
          agenda: {
            type: "week",
          },
        };
        break;
      case "month":
      default:
        calView = {
          agenda: {
            type: "month",
          },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };

  const getPropertySlugData = async (property_slug) => {
    setPropertySlug(property_slug);
    getReservationdata(property_slug, year, month);
  };

  return (
    <>
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <CalendarSidebar property_slug={property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyReservationSidebar
                      property_slug={property_slug}
                    />
                  </div>
                </div>
              )}
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>Check Ins</h3>
                  </div>
                </div>
                {window.location.pathname.includes(
                  "global-checkin-calender"
                ) ? (
                  <div className="col-xxl-2 col-sm-3 pd-r-0 pd-lg-l-45 pd-xs-l-15">
                    <OutSidePropertySelectBox
                      propertySlug={getPropertySlugData}
                      // outSidePropertySlug={outSidePropertySlug}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="dashboardRightcard- background-none pt-0 pd-lg-l-45 pd-lg-r-30 mg-t-30">
                  <div className="calendar-section pd-lg-l-0 pd-xs-l-15">
                    <Eventcalendar
                      theme="ios"
                      themeVariant="light"
                      view={calView}
                      data={events}
                      resources={resources}
                      renderEventContent={renderEventContent}
                      cssClass="md-timeline-template md-event-listing"
                      onEventClick={(event) => openPopup(event)}
                      renderHeader={renderMyHeader}
                      onPageLoading={onPageLoading}
                    />
                    <Popup
                      display="center"
                      // fullScreen={true}
                      contentPadding={true}
                      headerText={
                        event && event.event && event.event.title
                          ? event.event.title
                          : ""
                      }
                      isOpen={isPopupOpen}
                      onClose={closePopup}
                    >
                      <div className="text-center popup">
                        <span
                          className="popupClose hand-cursor"
                          onClick={closePopup}
                        >
                          {global.closee}
                        </span>
                        <p className="az-profile-name-text pl-0">
                          Reservation code{" "}
                        </p>{" "}
                        <p className="media-body">
                          {" "}
                          {event && event.event && event.event.confirmation_code
                            ? event.event.confirmation_code
                            : ""}
                        </p>
                        <p className="az-profile-name-text pl-0">
                          Check-in date & time{" "}
                        </p>{" "}
                        <p className="media-body">
                          {" "}
                          {event && event.event && event.event.view_start
                            ? event.event.view_start
                            : ""}
                        </p>
                        <p className="az-profile-name-text pl-0">
                          Check-out date & time{" "}
                        </p>{" "}
                        <p className="media-body">
                          {" "}
                          {event && event.event && event.event.view_end
                            ? event.event.view_end
                            : ""}
                        </p>
                        <p className="az-profile-name-text pl-0">
                          No. of guests{" "}
                        </p>{" "}
                        <p className="media-body">
                          {event && event.event && event.event.guest
                            ? event.event.guest
                            : ""}
                        </p>
                        {global.userPermissions.checkPermission(
                          "check-ins-update"
                        ) && (
                          <Link
                            to={
                              window.location.pathname.includes(
                                "global-checkin-calender"
                              )
                                ? "/reservations/view/" +
                                  (property_slug
                                    ? property_slug
                                    : event &&
                                      event.event &&
                                      event.event.property_slug
                                    ? event.event.property_slug
                                    : "") +
                                  "/" +
                                  (event &&
                                  event.event &&
                                  event.event.reservation_slug
                                    ? event.event.reservation_slug
                                    : "")
                                : "/properties/view/" +
                                  (property_slug
                                    ? property_slug
                                    : event &&
                                      event.event &&
                                      event.event.property_slug
                                    ? event.event.property_slug
                                    : "") +
                                  "/reservations/view/" +
                                  (event &&
                                  event.event &&
                                  event.event.reservation_slug
                                    ? event.event.reservation_slug
                                    : "")
                            }
                          >
                            <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                              View
                            </button>
                          </Link>
                        )}
                      </div>
                    </Popup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};
export default CheckinCalendar;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: KDD.jsx                                      //
//  Application: Dashboard                                //
//  Option: to view key data dashboard                    //
//  Developer: NP  						                  //
//  Date: 2023-05-31                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import KeyDataService from '../../services/KeyDataService'

export class KDD extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showSpinner:false,
            url:""
        }
    }

    componentDidMount(){
        this.getData();
    }

    async getData(queryString = "") {
        this.setState({ showSpinner: true });
        var url = "";
        let res = await KeyDataService.getKeyDataUrl(queryString);
        if((global.successStatus).includes(res.status)) {
            url = res.data ? res.data : ""
        }
        this.setState({ showSpinner: false , url:url });
    }

    render() {
        return (
            this.state.showSpinner ? global.snapshotloader : (this.state.url ?
                <iframe title="key-data-dashbaord" className="kdd" src={this.state.url} ></iframe>
                : '')
            )
    }
}

export default KDD
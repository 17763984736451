////////////////////////////////////////////////////////////
//     							                                      //
//  Program: EventsForm.jsx                                //
//  Application:  Events                                   //
//  Option: For add or update Events                       //
//  Developer: Ashish Kumar                          //
//  Date: 2022-02-09                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form ,InputGroup } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import Moment from "moment";
import EventsService from "../../services/EventsService";
import EventTypeService from "../../services/EventTypeService";
import eventImage from "./../../assets/images/brand_placeholder.png";
import Select from "react-select";
import PropertySidebar from "../common/PropertySidebar";
// import { addDays,subDays } from 'date-fns';
import InputMask from "react-input-mask";

export class EventsForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      event_name: "",
      event_type_slug: [],
      event_location: "",
      event_date: "",
      start_time: "07:00 AM",
      end_time: "07:00 PM",
      description: "",
      pricing_information: "",
      sort_order: "",
      pathname: window.location.pathname,
      event_image: [],
      eventTypeList: [],
      newEventTypeList: [],
      selectedEventTypeList: [],
      imageUrl: eventImage,
      property_logo_url: sessionStorage.getItem("property_logo_url"),
      property_type: sessionStorage.getItem("property_type"),
      property_name: sessionStorage.getItem("property_name"),
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
    };
    this.eventImage = React.createRef();
    this.changeHandler = this.changeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateEvents = this.saveOrUpdateEvents.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  /* To get EventType list data */
  async getEventTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var eventTypeList = [];
    var eventTypeListTotal = 0;

    let res = await EventTypeService.getEventType(
      "is_dropdown=1",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      eventTypeList = res.data ? res.data : [];
      eventTypeListTotal = eventTypeList ? eventTypeList.length : 0;
    }
    const newEventTypeList =
      eventTypeListTotal > 0
        ? eventTypeList.map(({ slug, event_type_name }) => ({
            value: slug,
            label: event_type_name,
          }))
        : [];
    this.setState({
      eventTypeList: eventTypeList,
      eventTypeListTotal: eventTypeListTotal,
      newEventTypeList: newEventTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    this.setState({ confirm_back: 0 });
    this.getEventTypeData();

    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await EventsService.getEventsBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.event_name = res.data.event_name ? res.data.event_name : "";
        resData.event_location = res.data.event_location
          ? res.data.event_location
          : "";
        resData.event_type_slug = res.data.event_type.slug
          ? res.data.event_type.slug
          : "";
        resData.event_date = res.data.event_date
          ? Moment(res.data.event_date, "MM/DD/YYYY").toDate("MM/DD/YY")
          : null;

        resData.start_time = res.data.start_time ? res.data.start_time : "";
        resData.end_time = res.data.end_time ? res.data.end_time : "";
        resData.publish_date = res.data.publish_date
          ? Moment(res.data.publish_date, "MM/DD/YYYY").toDate("MM/DD/YY")
          : null;
        resData.description = res.data.description ? res.data.description : "";
        resData.event_image = res.data.event_image ? res.data.event_image : "";
        resData.event_image = res.data.event_image;
        resData.imageUrl = res.data.event_image_thumb_url
          ? res.data.event_image_thumb_url
          : eventImage;
        resData.pricing_information = res.data.pricing_information
          ? res.data.pricing_information
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
        var selectedEventTypeList = [];
        selectedEventTypeList = res.data.event_type
          ? [
              {
                value: res.data.event_type.slug,
                label: res.data.event_type.event_type_name,
              },
            ]
          : "";
      }
      this.setState(resData);

      this.setState({ selectedEventTypeList: selectedEventTypeList });
      this.setState({ input: resData });
      this.greaterThanValueCheck();
    }
    this.setState({ showSpinner: false, loading: false });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "event_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("h1", 235);
      fileData.append("h2", 250);
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
      );
      fileData.append("mediaType", "1");
      let res = await EventsService.uploadEventsImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          event_image: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  saveOrUpdateEvents = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        event_name: this.state.event_name,
        event_location: this.state.event_location,
        event_type_slug: this.state.event_type_slug,
        property_slug: this.state.property_slug,
        event_date: this.state.event_date
          ? Moment(this.state.event_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        publish_date: this.state.publish_date
          ? Moment(this.state.publish_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        description: this.state.description,
        event_image: this.state.event_image,
        pricing_information: this.state.pricing_information,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await EventsService.updateEvents(this.state.slug, inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/events"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      } else {
        let res = await EventsService.createEvents(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push(
                "/properties/view/" + this.state.property_slug + "/events"
              ),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "error",
          //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          //   alertModalMessage: res.data.message ? res.data.message : "Error!",
          // });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    if (
      event.target.name === "start_time" ||
      event.target.name === "end_time"
    ) {
      var datetime = value.split(":");
      var lengthdatetime = (datetime[0].match(/\d/g) || []).length;
      if (lengthdatetime === 1) {
        datetime[0] = datetime[0].replace("_", "0");
      }
      if (parseInt(datetime[0]) > 12) {
        datetime[0] = "12";
      }
      datetime[1] = datetime[1].replace("_", "0");
      var minute = datetime[1].split(" ");
      var lengthminute = (minute[0].match(/\d/g) || []).length;

      /* const minute = [
        '__',''

      ]; */
      if (parseInt(minute[0]) > 59 || lengthminute <= 0) {
        minute[0] = "00";
      }
      const meridian = ["AM", "PM", "am", "pm", "A_", "P_", "a_", "p_"];
      const result = meridian.includes(minute[1].toLowerCase());
      minute[1] = result ? minute[1] : "AM";

      value = datetime[0] + ":" + minute[0] + " " + minute[1].toUpperCase();
    }

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.greaterThanValueCheck();
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  handleChangeEventTypeList(value) {
    let event_type_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        event_type_slug.push(item.value);
      });
    }
    const event_type_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedEventTypeList: value,
      event_type_slug: event_type_slug_value,
    });
    this.setState({ confirm_back: 1 });
  }

  greaterThanValueCheck() {
    let errors = this.state.errors;
    // greater than validation check
    let startDate = Number(this.state.input.event_date);
    let endDate = Number(this.state.input.end_date);
    if (endDate < startDate) {
      errors["end_date"] = "Must be greater than or equal to Start Date";
    } else {
      errors["end_date"] = "";
    }
    this.setState({ errors: errors });
  }

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.description !== undefined &&
      input.description !== "" &&
      input.description !== null &&
      input.description.length > 100000
    ) {
      isValid = false;
      errors["description"] =
        "The event description character limit has been exceed.";
    }
    if (
      input.pricing_information !== undefined &&
      input.pricing_information !== "" &&
      input.pricing_information !== null &&
      input.pricing_information.length > 100000
    ) {
      isValid = false;
      errors["pricing_information"] =
        "The pricing information character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Events";
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  removePhoto(){
    this.setState({event_image:"",imageUrl:""});
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <PropertySidebar property_slug={this.state.property_slug} />
                  </aside>
                </div>
              </div>

              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-success-outline-small"
                        onClick={() => {
                          if (this.state.confirm_back === 1)
                            if (
                              window.confirm(
                                "Do you want to exit without saving?"
                              )
                            )
                              this.props.history.push(
                                "/properties/view/" +
                                  this.state.property_slug +
                                  "/events"
                              );
                            else return false;
                          else
                            this.props.history.push(
                              "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                            );
                        }}
                      >
                        Cancel
                      </button>

                      {this.state.slug !== undefined &&
                      global.userPermissions.checkPermission(
                        "events-update"
                      ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveOrUpdateEvents}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : global.userPermissions.checkPermission(
                          "events-add"
                        ) ? (
                        <button
                          className="btn-success ml-3"
                          type="button"
                          disabled={this.state.isSubmit ? true : false}
                          onClick={this.saveOrUpdateEvents}
                        >
                          {this.state.isSubmit ? global.loader : "Save"}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <form id="event_form">
                    <div className="dashboardRightcard background-none pt-0 pl-4 pd-r-15">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="m-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15">
                        <div className="row align-items-center pd-lg-t-0 pd-xs-t-15 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Name
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="event_name"
                              value={this.state.event_name}
                              className="form-control max_width_100"
                              type="text"
                              // placeholder="Name"
                            />
                            {this.state.errors.event_name ? (
                              <div className="text-danger">
                                {this.state.errors.event_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "name",
                                this.state.event_name,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Event Type
                            </label>

                            <Select
                              styles={customStyles}
                              isClearable={true}
                              className="multiselect"
                              classNamePrefix={"my-custom-react-select"}
                              menuPlacement="auto"
                              value={this.state.selectedEventTypeList}
                              options={this.state.newEventTypeList}
                              getOptionValue={(option) => `${option.label}`}
                              onChange={(value) =>
                                this.handleChangeEventTypeList(value)
                              }
                              defaultValue={this.state.selectedEventTypeList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 2,
                                colors: {
                                  ...theme.colors,
                                  primary: "#fff",
                                  primary75: "#000",
                                  primary50: "#000",
                                  primary25: "#000",
                                },
                              })}
                            />
                            {this.state.errors.event_type_slug ? (
                              <div className="text-danger">
                                {this.state.errors.event_type_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "event_type",
                                this.state.event_type_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>{" "}
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Event Location
                            </label>

                            <Form.Control
                              onChange={this.changeHandler}
                              name="event_location"
                              value={this.state.event_location}
                              className="form-control max_width_100"
                              type="text"
                              // placeholder="Name"
                            />
                            {this.state.errors.event_location ? (
                              <div className="text-danger">
                                {this.state.errors.event_location}
                              </div>
                            ) : (
                              this.validator.message(
                                "event_location",
                                this.state.event_location,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Event Date{" "}
                            </label>

                            <DatePicker
                              className="form-control max_width_100"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete="off"
                              minDate={new Date()}
                              disabledKeyboardNavigation
                              strictParsing
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="event_date"
                              dateFormat="MM/dd/yy"
                              selected={this.state.event_date}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "event_date",
                                    value: data,
                                  },
                                })
                              }
                            />
                            {this.state.errors.event_date ? (
                              <div className="text-danger">
                                {this.state.errors.event_date}
                              </div>
                            ) : (
                              this.validator.message(
                                "event_date",
                                this.state.event_date,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Start Time{" "}
                            </label>

                            <InputMask
                              name="start_time"
                              onChange={this.changeHandler}
                              value={
                                this.state.start_time !== ""
                                  ? this.state.start_time
                                  : "00:00AM"
                              }
                              mask="99:99 aa"
                            />
                            {this.state.errors.start_time ? (
                              <div className="text-danger">
                                {this.state.errors.start_time}
                              </div>
                            ) : (
                              this.validator.message(
                                "start_time",
                                this.state.start_time,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              End Time{" "}
                            </label>

                            <InputMask
                              name="end_time"
                              onChange={this.changeHandler}
                              value={
                                this.state.end_time !== ""
                                  ? this.state.end_time
                                  : "11:59PM"
                              }
                              mask="99:99 aa"
                            />
                            {this.state.errors.end_time ? (
                              <div className="text-danger">
                                {this.state.errors.end_time}
                              </div>
                            ) : (
                              this.validator.message(
                                "end_time",
                                this.state.end_time,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                        {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}
                        <div className="row align-items-center pd-lg-b-30 border-bottom-0">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                            <InputGroup className="">
                              <label className="form-label text-left pd-b-5 w-100">
                                Description
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                name="description"
                                value={this.state.description}
                                className="form-control max_width_100"
                                id="description"
                                as="textarea"
                                rows={global.textareaRowLength}
                                // placeholder="Username"
                              />
                            </InputGroup>
                            {this.state.errors.description ? (
                              <div className="text-danger">
                                {this.state.errors.description}
                              </div>
                            ) : (
                              this.validator.message(
                                "description",
                                this.state.description,
                                "required|max:100000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>{" "}
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                            <InputGroup className="">
                            <label className="form-label text-left pd-b-5 w-100">
                            Pricing{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                            <Form.Control
                              onChange={this.changeHandler}
                              name="pricing_information"
                              value={this.state.pricing_information}
                              className="form-control max_width_100"
                              id="pricing_information"
                              as="textarea"
                              rows={global.textareaRowLength}
                              // placeholder="Username"
                            />
                          </InputGroup>
                            {this.state.errors.pricing_information ? (
                              <div className="text-danger">
                                {this.state.errors.pricing_information}
                              </div>
                            ) : (
                              this.validator.message(
                                "pricing",
                                this.state.pricing_information,
                                "max:100000",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>

                        {/* <div className="row-xs border-bottom-0 pd-l-20 pd-r-20">
                          <hr className="mg-t-10 mg-b-10 pd-b-2 pd-l-0 pd-r-0" />
                        </div> */}
                        <div className="row align-items-center pd-lg-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Image <span className="optional">(Optional)</span>
                            </label>

                            <div className="fileUpload d-flex flex-wrap justify-content-between">
                              <span>
                                <label className="hand-cursor">
                                  <input
                                    onChange={this.onImageChange}
                                    type="file"
                                    name="event_image"
                                    tabIndex="2"
                                  />
                                  {this.state.imageUrl ? (
                                    <img
                                      src={this.state.imageUrl}
                                      alt="Event Image"
                                    />
                                  ) : (
                                    <img
                                    src={eventImage}
                                    alt="Logo"
                                  />
                                  )}
                                </label>
                              </span>
                              <ul className="ruLink">
                              <li>
                                <label>
                                  <input
                                    onChange={this.onImageChange}
                                    type="file"
                                    name="image"
                                    tabIndex="2"
                                  />
                                </label>
                              </li>
                            </ul>
                              <p className="hand-cursor remove-pic remove-pic-three m-0" onClick={this.removePhoto}></p>
                            </div>
                            {this.state.errors.event_image ? (
                              <div className="text-danger">
                                {this.state.errors.event_image}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* <div className="row align-items-center pd-lg-b-0 pd-xs-b-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0 pd-t-0">
                        <div className="col-md-3">
                          <p className="hand-cursor text-danger" onClick={this.removePhoto}>Remove</p>
                        </div>
                      </div> */}
                        <div className="row align-items-center pd-b-30 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 border-bottom-0 mg-t-30">
                          <div className="col-md-1 pl-0">
                            <label className="form-label text-left pd-b-5">
                              Status
                            </label>
                          </div>

                          <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                            <b
                              className={
                                this.state.status_id ? "active" : "inactive"
                              }
                            ></b>
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() =>
                                this.setState({
                                  status_id: !this.state.status_id,
                                })
                              }
                            >
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default EventsForm;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PropertyVendorService.js                     //
//  Application: Property                                 //
//  Option: Used for manage all vendors fro prperty       //
//  Developer: NP           						      //
//  Date: 2022-02-07                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class PropertyVendorService {

  getOtherAgency(queryString) {
    let endPoint = "property-third-party-vendor"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createOtherAgency(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "property-third-party-vendor";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getOtherAgencyBySlug(slug) {
    let endPoint = "third-party-agency/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getAgencyBySlug(slug){
    let endPoint = "property-third-party-vendor/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateOtherAgency(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "property-third-party-vendor/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteOtherAgency(slug) {
    let endPoint = "property-third-party-vendor/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  getSelectedAgencyCategory(slug) {
    let endPoint = "get-third-party-agency-selected/"+ slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new PropertyVendorService();
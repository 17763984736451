////////////////////////////////////////////////////////////
//     							                                      //
//  Program: KeyView.jsx                                //
//  Application:  keycafe                                  //
//  Option: View key                       //
//  Developer: Ravi                          //
//  Date: 2023-03-30                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Alerts from "../../common/Alerts";
import SimpleReactValidator from "simple-react-validator";
import KeycafeService from "../../../services/KeycafeService";
import ReactDatatable from "@ashvin27/react-datatable";
// import information_img from "./../../../assets/images/information-button.png";
// import { Link } from "react-router-dom";
import Moment from "moment";

export class KeyView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      input: {},
      errors: {},
      open: true,
      eventKey: "3",
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      unitList: [],

      pathname: window.location.pathname,

      email: "",
      email_slug: "",
      start_time: "00:00AM",
      end_time: "00:00AM",
      isLoaded: false,
      //   unit_name: "",
      access_start_date: "",
      access_end_date: "",
      access_code: "",
      key_name: "",
      keycafe_key_id: "",
      fob: "",
      acess_end_time: "",
      access_start_time: "",
      keycafe_access_id: "",
      key_type: "",
      tab: 1,
      columns: [
        {
          key: "status_time",
          text: "Updated On",
          className: "available_from hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.status_time
              ? Moment(record.status_time).format(global.dateTimeFormat)
              : "";
          },
        },
        {
          key: "access_id",
          text: "Access Code",
          className: "available_from hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.access_id ? record.access_id : "";
          },
        },
        {
          key: "exchanger_name",
          text: "Exchanger Name",
          className: "available_from hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.exchanger_name ? record.exchanger_name : "";
          },
        },
        {
          key: "bin_no",
          text: "Bin",
          className: "available_from hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.bin_no ? record.bin_no : "";
          },
        },
        {
          key: "box_id",
          text: "Box ID",
          className: "available_from hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.box_id ? record.box_id : "";
          },
        },
        {
          key: "key_id",
          text: "Key ID",
          className: "available_from hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.key_id ? record.key_id : "";
          },
        },
        {
          key: "key_name",
          text: "Key Name",
          className: "available_from hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.key_name ? record.key_name : "";
          },
        },
        {
          key: "serial_number",
          text: "Serial Name",
          className: "available_from hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.serial_number ? record.serial_number : "";
          },
        },
        {
          key: "type",
          text: "Activity",
          className: "available_from hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.type ? record.type : "";
          },
        },
      ],

      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found.",
        },
      },
    };
    this.openArrivalInstructionPopup =
      this.openArrivalInstructionPopup.bind(this);
  }
  openArrivalInstructionPopup() {
    this.setState({ arrivalinstructionPopup: true });
  }
  closeArrivalInstructionPopup() {
    this.setState({ arrivalinstructionPopup: false });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
            window.scrollTo(0, 0);
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () =>
            this.getDataa(
              queryString + "&tab=" + this.state.tab + "&is_list=1"
            ),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getDataa(queryString + "&tab=" + this.state.tab + "&is_list=1");
    }
  };

  async getData(queryString = "", tab) {
    if (this.state.slug !== undefined) {
      var resData = {};
      var totalRecords = 0;
      let res = await KeycafeService.getKeycafeStatus(
        "property_slug=" +
          this.state.property_slug +
          "&key_slug=" +
          this.state.slug +
          "&tab=" +
          (tab ? tab : this.state.tab) +
          (queryString ? "&" + queryString : "")
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        totalRecords = res.data ? res.data.total : 0;
        resData.unit_name = resData.unit_name ? resData.unit_name : "";
        resData.ref_table_id = resData.ref_table_id ? resData.ref_table_id : "";
        resData.key_status_updated_time = resData.key_status_updated_time
          ? Moment(resData.key_status_updated_time).format(
              global.dateTimeFormat
            )
          : "";
        resData.bin_no = resData.bin_no ? resData.bin_no : "";
        resData.current_key_status = resData.current_key_status
          ? resData.current_key_status
          : "";
        resData.keycafe_key_id = resData.keycafe_key_id
          ? resData.keycafe_key_id
          : "";
        resData.key_name = resData.key_name ? resData.key_name : "";
      }
      this.setState({
        total_record: totalRecords,
        records: resData,
        isLoaded: true,
        unit_name: resData.unit_name ? resData.unit_name : "",
        ref_table_id: resData.ref_table_id ? resData.ref_table_id : "",
        key_status_updated_time: resData.key_status_updated_time
          ? resData.key_status_updated_time
          : "",
        bin_no: resData.bin_no ? resData.bin_no : "",
        current_key_status: resData.current_key_status
          ? resData.current_key_status
          : "",
        keycafe_key_id: resData.keycafe_key_id ? resData.keycafe_key_id : "",
        key_name: resData.key_name ? resData.key_name : "",
      });
    }
  }
  async getDataa(queryString = "") {
    if (this.state.slug !== undefined) {
      var resData = {};
      var totalRecords = 0;
      let res = await KeycafeService.getKeycafeActivity(
        "property_slug=" +
          this.state.property_slug +
          "&key_slug=" +
          this.state.slug +
          (queryString ? "&" + queryString : "")
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data.data : {};
        totalRecords = res.data ? res.data.total : 0;
      }
      this.setState({
        total_record: totalRecords,
        records: resData,
        isLoaded: true,
        // unit_name: resData.unit_name ? resData.unit_name : "",
      });
    }
  }

  async componentDidMount() {
    this.getData();
    this.getDataa();
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Key";
    } else {
      return "Key";
    }
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                  </div>
                </div>
                <div className="scrolling-carousel pd-lg-l-15 pd-sm-l-30 pd-sm-r-30 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <form id="key_form">
                      <div className="m-0">
                        <div className="row align-items-center pd-r-15 pd-b-0 border-bottom-0">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">Key ID</p>
                            <p className="media-body">
                              {this.state.keycafe_key_id
                                ? this.state.keycafe_key_id
                                : ""}
                            </p>
                          </div>

                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Key Name
                            </p>
                            <p className="media-body">
                              {this.state.key_name ? this.state.key_name : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Unit Name
                            </p>
                            <p className="media-body">
                              <a
                                href={
                                  this.props.match.params.propertySlug !==
                                  undefined
                                    ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/" +
                                      "keys/activity-view/" +
                                      this.state.slug
                                    : "/keys/activity-view/" +
                                      this.state.property_slug +
                                      "/" +
                                      this.state.slug
                                }
                              >
                                {this.state.unit_name}
                              </a>
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Key Status
                            </p>
                            <p className="media-body">
                              {this.state.ref_table_id != 0 &&
                              this.state.current_key_status === "DROPOFF"
                                ? "Key in Locker"
                                : this.state.ref_table_id == 1 &&
                                  this.state.current_key_status != undefined &&
                                  this.state.current_key_status != ""
                                ? "Key with Guest"
                                : this.state.ref_table_id == 2 &&
                                  this.state.current_key_status != undefined &&
                                  this.state.current_key_status != ""
                                ? "Key with Cleaner"
                                : this.state.ref_table_id == 3 &&
                                  this.state.current_key_status != undefined &&
                                  this.state.current_key_status != ""
                                ? "Key with Resident"
                                : this.state.ref_table_id == 4 &&
                                  this.state.current_key_status != undefined &&
                                  this.state.current_key_status != ""
                                ? "Key with Visitor"
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">Bin</p>
                            <p className="media-body">
                              {this.state.bin_no ? this.state.bin_no : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Activity
                            </p>
                            <p className="media-body">
                              {this.state.current_key_status == "DROPOFF"
                                ? "Dropped off"
                                : this.state.current_key_status == "PICKUP"
                                ? "Picked up"
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">
                              Updated On{" "}
                            </p>
                            <p className="media-body">
                              {this.state.key_status_updated_time
                                ? this.state.key_status_updated_time
                                : ""}
                            </p>
                          </div>
                          {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-b-15">
                            <p className="az-profile-name-text pl-0">Type</p>
                            <p className="media-body">
                              {this.state.key_type ? this.state.key_type : ""}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="col-lg-12 pd-xs-r-15 pd-lg-r-30 pd-lg-l-30 pd-xs-l-15 mg-b-20">
                    <h3 className="mg-l-5 background-head">Key Activity</h3>
                  </div>
                  <div className="mg-t-18 brandList pd-l-15 pd-r-15 d-block">
                    <div className="pd-lg-l-0 pd-xs-l-15">
                      <ReactDatatable
                        className="table no-wrap mg-b-30 pd-b-2 "
                        config={
                          this.state.total_record > global.page_size
                            ? this.state.config
                            : this.state.config
                        }
                        records={this.state.records}
                        columns={this.state.columns}
                        extraButtons={this.state.extraButtons}
                        dynamic={true}
                        total_record={this.state.total_record}
                        onChange={this.tableChangeHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={this.state.arrivalinstructionPopup}
          centered
          className="modal-large arrivalinstructionmodalbox"
        >
          <Modal.Body className="width_402 p-4 border_radius_14 instruction_key_cafe">
            <div className="">
              <span
                className="welcomeClose"
                onClick={() => this.closeArrivalInstructionPopup()}
              >
                {global.closee}
              </span>
              <h3 className="text-center">Arrival Instructions</h3>
              <div className="instructionbody">
                {/* {this.state.arrival_instruction
                  ? renderHTML(this.state.arrival_instruction)
                  : ""} */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default KeyView;

import ApiService from "./ApiService";

class ProblemService {
  getList(queryString) {
    let endPoint = "problem-list" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getBySlug(slug) {
    let endPoint = "problem-details/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getCleaning(slug) {
    let endPoint = "get-cleaning-detail-view/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };

    let response = ApiService.getCall(inputData);
    return response;
  }

  getChecklist(slug) {
    let endPoint = "get-checklist/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };

    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new ProblemService();

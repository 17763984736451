////////////////////////////////////////////////////////////
//     							                          //
//  Program: Country.jsx                                  //
//  Application: Location                                 //
//  Option: to view Country data                          //
//  Developer: Ashish Kumar  						      //
//  Date: 2022-01-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import {
  Button,
  Table,
  Modal,
  Form,
  // InputGroup,
} from "react-bootstrap";
import Alerts from "../common/Alerts";
import deleteicon from "../../assets/images/delete.svg";
import SimpleReactValidator from "simple-react-validator";
import CountryService from "../../services/CountryService";
import modaldeleteicon from "./../../assets/images/delete.svg";
import pluswIcon from "./../../assets/images/plus.svg";
import { Container } from "react-bootstrap";
import MasterSidebar from "../common/MasterSidebar";
import NoData from "../common/NoData";

export class Country extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: global.showOpen,
      eventKey: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      status_id: true,
      slug: "",
      //for country
      addCountryModal: false,
      editCountryModal: false,
      deleteCountryModal: false,
      countryList: [],
      countryListTotal: 0,
      country_name: "",
      country_slug: "",
      currency_code: "",
      currency_name: "",
      currency_symbol: "",
      sort_order: "",
    };

    this.changeHandler = this.changeHandler.bind(this);

    /* for country */
    this.openAddCountryModal = this.openAddCountryModal.bind(this);
    this.openEditCountryModal = this.openEditCountryModal.bind(this);
    this.openDeleteCountryModal = this.openDeleteCountryModal.bind(this);
    this.saveCountry = this.saveCountry.bind(this);
    this.updateCountry = this.updateCountry.bind(this);
    this.getCountryDetails = this.getCountryDetails.bind(this);
    this.deleteCountry = this.deleteCountry.bind(this);
  }

  componentDidMount() {
    this.getCountryData();
  }

  /* to get country list data */
  async getCountryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];
    var countryListTotal = 0;

    let res = await CountryService.getCountry(
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data.data ? res.data.data : [];
      countryListTotal = countryList ? countryList.length : 0;
    }
    this.setState({
      countryList: countryList,
      countryListTotal: countryListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save country data */
  saveCountry = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.country_name &&
      !this.validator.errorMessages.currency_code &&
      !this.validator.errorMessages.currency_name &&
      !this.validator.errorMessages.currency_symbol &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        country_name: this.state.country_name,
        currency_code: this.state.currency_code,
        currency_name: this.state.currency_name,
        currency_symbol: this.state.currency_symbol,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await CountryService.createCountry(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeAddCountryModal();
        this.setState({
          country_name: "",
          currency_code: "",
          currency_name: "",
          currency_symbol: "",
          sort_order: "",
          status_id: true,
        });
        this.getCountryData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to get country details */
  async getCountryDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await CountryService.getCountryBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.country_name = res.data.country_name
          ? res.data.country_name
          : "";
        resData.currency_code = res.data.currency_code
          ? res.data.currency_code
          : "";
        resData.currency_name = res.data.currency_name
          ? res.data.currency_name
          : "";
        resData.currency_symbol = res.data.currency_symbol
          ? res.data.currency_symbol
          : "";
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  /*to update country data */
  updateCountry = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.country_name &&
      !this.validator.errorMessages.currency_code &&
      !this.validator.errorMessages.currency_name &&
      !this.validator.errorMessages.currency_symbol &&
      !this.validator.errorMessages.sort_order
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        country_name: this.state.country_name,
        currency_code: this.state.currency_code,
        currency_name: this.state.currency_name,
        currency_symbol: this.state.currency_symbol,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await CountryService.updateCountry(this.state.slug, inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.closeEditCountryModal();
        this.setState({
          country_name: "",
          currency_code: "",
          currency_name: "",
          currency_symbol: "",
          sort_order: "",
          status_id: true,
        });
        this.getCountryData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        //   alertModalMessage: res.data.message ? res.data.message : "Error!",
        // });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  /* to delete country data */
  async deleteCountry(slug) {
    this.setState({ showSpinner: true });
    let res = await CountryService.deleteCountry(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteCountryModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });

      this.getCountryData();
    } else {
      this.closeDeleteCountryModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    this.closeDeleteCountryModal();
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  /* Country Modals */
  openAddCountryModal() {
    this.setState({ addCountryModal: true });
  }

  closeAddCountryModal() {
    this.setState({ addCountryModal: false });
    this.setState({
      country_name: "",
      currency_code: "",
      currency_name: "",
      currency_symbol: "",
      sort_order: "",
      errors: "",
      status_id: true,
    });
    this.validator.hideMessages();
  }

  openEditCountryModal(slug) {
    this.getCountryDetails(slug);
    this.setState({ editCountryModal: true });
    this.setState({ slug: slug });
  }

  closeEditCountryModal() {
    this.setState({ editCountryModal: false });
    this.setState({
      slug: "",
      country_name: "",
      currency_code: "",
      currency_name: "",
      currency_symbol: "",
      sort_order: "",
      errors: "",
      status_id: true,
    });
    this.validator.hideMessages();
  }

  openDeleteCountryModal(slug) {
    this.setState({ deleteCountryModal: true });
    this.setState({ slug: slug });
  }

  closeDeleteCountryModal() {
    this.setState({ deleteCountryModal: false });
    this.setState({ slug: "" });
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.id });
    const scrollId = document.getElementById(e.target.id);
    scrollId.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  getTitle() {
    return "Country";
  }

  render() {
    return (
      <>
        {/* country card */}
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="30"
              id="30"
              onClick={(e) => this.setOpen(!this.state.open, e)}
            >
              Country
              <i className="mdi mdi-view-headline"> </i>
              {this.state.open && this.state.eventKey === "30" ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="30">
            <Card.Body>
              <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
                <Button
                  variant="outline-dark btn-block btn-with-icon"
                  onClick={this.openAddCountryModal}
                >
                  <img src={pluswIcon} /> Add
                </Button>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="table-responsive">
                <Table hover className="mg-b-0">
                  <thead>
                    <tr>
                      <th className="w-30">Country Name</th>
                      <th className="w-30">Currency Name</th>
                      <th className="w-30">Currency Code</th>
                      <th className="w-15">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.countryListTotal > 0 ? (
                      this.state.countryList.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="hand-cursor" onClick={() =>
                              this.openEditCountryModal(item.slug)
                            }>{item.country_name}</td>
                            <td>{item.currency_name}</td>
                            <td>
                              {item.currency_code} ({item.currency_symbol})
                            </td>

                            <td>
                              {item.status_id === 1 ? (
                                <b className="active"></b>
                              ) : (
                                <b></b>
                              )}
                            </td>
                            <td>
                              <div className="btn-icon-list d-block text-center">
                                <img
                                  src={deleteicon}
                                  alt="Delete"
                                  onClick={() =>
                                    this.openDeleteCountryModal(item.slug)
                                  }
                                  className="hand-cursor"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9" align="center">
                          No rows found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}

        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <MasterSidebar />
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right settingsidebarNav">
                  <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                      <h3>{this.getTitle()}</h3>
                      <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                        {global.userPermissions.checkPermission(
                          "master-data-add"
                        ) && (
                          <Button
                            variant="outline-dark btn-block btn-with-icon"
                            onClick={this.openAddCountryModal}
                          >
                            <img src={pluswIcon} alt="" /> Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="master">
                    <div className="table-responsive">
                      {/* <div className="row d-flex justify-content-end mg-r-0 mg-b-15">
              <Button
                variant="outline-dark btn-block btn-with-icon"
                onClick={this.openAddCountryModal}
              >
                <img src={pluswIcon} alt="" /> Add
              </Button>
            </div> */}
                      <div className="col-md-12 pl-0 pr-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.countryListTotal > 0 ? (
                        <Table hover className="mg-b-0">
                          <thead>
                            <tr>
                              <th className="w-30">Country Name</th>
                              {/* <th className="w-30">Currency Name</th>
                  <th className="w-30">Currency Code</th> */}
                              <th className="w-15">Status</th>
                              {global.userPermissions.checkPermission(
                                "master-data-delete"
                              ) && <th className="text-center">Action</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.countryListTotal > 0 ? (
                              this.state.countryList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="hand-cursor"
                                      onClick={() => {
                                        global.userPermissions.checkPermission(
                                          "master-data-update"
                                        ) &&
                                          this.openEditCountryModal(item.slug);
                                      }}
                                    >
                                      {item.country_name}
                                    </td>
                                    {/* <td>{item.currency_name}</td>
                        <td>
                          {item.currency_code} ({item.currency_symbol})
                        </td> */}
                                    <td>
                                      {item.status_id === 1 ? (
                                        <b className="active"></b>
                                      ) : (
                                        <b></b>
                                      )}
                                    </td>
                                    {global.userPermissions.checkPermission(
                                      "master-data-delete"
                                    ) && (
                                      <td>
                                        <div className="btn-icon-list d-block text-center">
                                          <img
                                            src={deleteicon}
                                            alt=""
                                            onClick={() =>
                                              this.openDeleteCountryModal(
                                                item.slug
                                              )
                                            }
                                            className="hand-cursor"
                                          />
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" align="center">
                                  There are no countries added in the system.
                                  You may add them by clicking Add button.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage("COUNTRY")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        {/* add country popup modal */}
        <Modal
          show={this.state.addCountryModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeAddCountryModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeAddCountryModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Add Country</h3>
            <label>Country Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="country_name"
              value={this.state.country_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              //   // placeholder="Country Name"
            />
            {this.state.errors.country_name ? (
              <div className="text-danger">
                {this.state.errors.country_name}
              </div>
            ) : (
              this.validator.message(
                "country_name",
                this.state.country_name,
                "required|max:50",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>
                Currency Code
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="currency_code"
                value={this.state.currency_code}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="10"
                autoComplete="off"
               // // placeholder="Currency Code"
              />
            </InputGroup>
            {this.state.errors.currency_code ? (
              <div className="text-danger">
                {this.state.errors.currency_code}
              </div>
            ) : (
              this.validator.message(
                "currency_code",
                this.state.currency_code,
                "required|max:10",
                { className: "text-danger" }
              )
            )} */}

            {/* <InputGroup className="mt-3">
              <label>
                Currency Name
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="currency_name"
                value={this.state.currency_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="25"
                autoComplete="off"
               // // placeholder="Currency Name"
              />
            </InputGroup>
            {this.state.errors.currency_name ? (
              <div className="text-danger">
                {this.state.errors.currency_name}
              </div>
            ) : (
              this.validator.message(
                "currency_name",
                this.state.currency_name,
                "required|max:25",
                { className: "text-danger" }
              )
            )} */}

            {/* <InputGroup className="mt-3">
              <label>
                Currency Symbol
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="currency_symbol"
                value={this.state.currency_symbol}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="5"
                autoComplete="off"
                // placeholder="Currency Symbol"
              />
            </InputGroup>
            {this.state.errors.currency_symbol ? (
              <div className="text-danger">
                {this.state.errors.currency_symbol}
              </div>
            ) : (
              this.validator.message(
                "currency_symbol",
                this.state.currency_symbol,
                "required|max:5",
                { className: "text-danger" }
              )
            )} */}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeAddCountryModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.saveCountry}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* edit country popup modal */}
        <Modal
          show={this.state.editCountryModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeEditCountryModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeEditCountryModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-center">Update Country</h3>
            <label>Country Name</label>
            <Form.Control
              onChange={this.changeHandler}
              name="country_name"
              value={this.state.country_name}
              className="form-control max_width_100"
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              // placeholder="Country Name"
            />
            {this.state.errors.country_name ? (
              <div className="text-danger">
                {this.state.errors.country_name}
              </div>
            ) : (
              this.validator.message(
                "country_name",
                this.state.country_name,
                "required|max:50",
                { className: "text-danger" }
              )
            )}

            {/* <InputGroup className="mt-3">
              <label>
                Currency Code
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="currency_code"
                value={this.state.currency_code}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="10"
                autoComplete="off"
                // placeholder="Currency Code"
              />
            </InputGroup>
            {this.state.errors.currency_code ? (
              <div className="text-danger">
                {this.state.errors.currency_code}
              </div>
            ) : (
              this.validator.message(
                "currency_code",
                this.state.currency_code,
                "required|max:10",
                { className: "text-danger" }
              )
            )} */}

            {/* <InputGroup className="mt-3">
              <label>
                Currency Name
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="currency_name"
                value={this.state.currency_name}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="25"
                autoComplete="off"
                // placeholder="Currency Name"
              />
            </InputGroup>
            {this.state.errors.currency_name ? (
              <div className="text-danger">
                {this.state.errors.currency_name}
              </div>
            ) : (
              this.validator.message(
                "currency_name",
                this.state.currency_name,
                "required|max:25",
                { className: "text-danger" }
              )
            )} */}

            {/* <InputGroup className="mt-3">
              <label>
                Currency Symbol
              </label>
              <Form.Control
                onChange={this.changeHandler}
                name="currency_symbol"
                value={this.state.currency_symbol}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                maxLength="5"
                autoComplete="off"
                // placeholder="Currency Symbol"
              />
            </InputGroup>
            {this.state.errors.currency_symbol ? (
              <div className="text-danger">
                {this.state.errors.currency_symbol}
              </div>
            ) : (
              this.validator.message(
                "currency_symbol",
                this.state.currency_symbol,
                "required|max:5",
                { className: "text-danger" }
              )
            )} */}

            {/* <InputGroup className="mt-3">
              <label>Sort Order</label>
              <Form.Control
                onChange={this.changeHandler}
                name="sort_order"
                value={this.state.sort_order}
                onKeyPress={global.onKeyPressEvent.numberValidation}
                className="form-control max_width_100"
                id="inlineFormInput"
                type="text"
                // placeholder="Sort Order"
              />
            </InputGroup>
            {this.state.errors.sort_order ? (
              <div className="text-danger">{this.state.errors.sort_order}</div>
            ) : (
              this.validator.message(
                "sort_order",
                this.state.sort_order,
                "numeric|min:0,num",
                { className: "text-danger" }
              )
            )} */}

            <div className="d-flex align-items-center justify-content-end mt-3">
              <b className={this.state.status_id ? "active" : "inactive"}>
                {this.state.status_id ? "active" : "inactive"}
              </b>
              <div
                className={this.state.status_id ? "az-toggle on" : "az-toggle"}
                onClick={() =>
                  this.setState({ status_id: !this.state.status_id })
                }
              >
                <span></span>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeEditCountryModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.updateCountry}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Save"}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* delete country popup modal */}
        <Modal
          show={this.state.deleteCountryModal}
          onHide={() => {
            if (window.confirm("Do you want to exit without saving?"))
              this.closeDeleteCountryModal();
          }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteCountryModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteCountryModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCountry(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Country;

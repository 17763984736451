import React, { Component } from 'react';
import UserActivityService from "../../services/UserActivityService";
import Moment from "moment";
import NoDataActivity from '../common/NoDataActivity';
export class ReservationActivity extends Component {
    constructor(props) {
      super(props)
      this.state = {
        refId:this.props.reservation_slug,
        refTableSlug: "XK17D6ILPUTO",
        showSpinner: false,
        loading: false,
        resData: [],
        rowsToDisplay: 2,
        isShowMore: false,
        reservation_slug: this.props.reservation_slug,
      property_slug: this.props.property_slug ? this.props.property_slug : this.props.outside_property_slug,
      outside_property_slug: this.props.outside_property_slug,
      }
      this.toggleReadMoreLess = this.toggleReadMoreLess.bind(this);
    }

    async componentDidMount() {
        this.setState({ showSpinner: true, loading: true});
        if (this.state.refId !== undefined) {
            var resData = [];
            let res = await UserActivityService.getUserActivity(this.state.refId, this.state.refTableSlug)
            if (global.successStatus.includes(res.status)) {
                resData = res.data ? res.data : [];
            }
            this.setState({ resData });
        }
       
    }
    toggleReadMoreLess = () => {
        this.state.rowsToDisplay === 2 ? (
          this.setState({ rowsToDisplay: this.state.resData.length, isShowMore: true})
        ) : (
          this.setState({ rowsToDisplay: 2, isShowMore: false })
        )
      }
    render() {
        return (
            <>
            { this.state.resData.length > 0 ?
            <section className='time_line_sec'>
              <div className="container-fluid">
                <div className="row example-centered">
                  <div className="col-md-8 offset-md-2">
                    <ul className="timeline timeline-centered">
      
                      {this.state.resData.slice(0, this.state.rowsToDisplay).map((element, i) => {
                        var changeDateFormat =  element.date ? Moment(element.date,'DD/MM/YYYY').format('MM/DD/YY') : "";
                        return (
      
                          <li className="timeline-item" key={i}>
                            <div className="timeline-info">
                              {element.date === Moment(new Date()).format("DD/MM/YY") ? <span>Today</span> : element.date === Moment(new Date()).add(-1, 'days').format("DD/MM/YY") ? <span>Yesterday</span> : <span>{changeDateFormat}</span>}
                            </div>
                            <div className="timeline-marker"></div>
                            <div className="timeline-content" >
                            {this.state.resData[i].activity.map((act, j) =>
                                <div key={j}>
                                  <h3 className="timeline-title">{act.activity_title}<span>{act.created_at}</span></h3>
                                 
                                  <p>{act.module_name}|{act.response_msg} <b>By</b> {act.device_type === 3 ? "User" : act.user_name!==" " ? act.user_name : "OHAI"}</p>
                                </div>
                            )}
                            </div>
                          </li>
      
                        )
                      })}
      
                    </ul>
                    {this.state.resData.length > 2 ?  
                    <div>
                      <button type="button" className="mt-5 btn btn btn-danger btn-block mx-auto time_line_btn" onClick={this.toggleReadMoreLess}> {this.state.isShowMore ? "Show Less" : "Show More"}</button>
                    </div>
                    : ""
                    }
                  </div>
                </div>
              </div>
            </section> 
            :(
              <NoDataActivity action ="reservation" />
            )
            }
            </>
                    
          )
        }
}
export default ReservationActivity;

///////////////////////////////////////////////
//     							             //
//  Program: ApplicantsDetailService.js                 //
//  Application: Services                    //
//  Option: Used for Applicants data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-02-09                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class ApplicantsDetailService {
    getApplicantsDetail(queryString) {
        let endPoint = "applicant-detail" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    getApplicantsDetailBySlug(slug) {
        let endPoint = "applicant-detail/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateApplicantsDetail(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "applicant-detail/" + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.putCall(inputData);
        return response;
    }

    deleteApplicantsDetail(slug) {
        let endPoint = "applicant-detail/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
    }
    getPriceRange(queryString) {
        let endPoint = "price-range" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

}

export default new ApplicantsDetailService();

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: InquiryView.jsx                              //
//  Application: Inquiry  View                            //
//  Option: For view Inquiry View details                 //
//  Developer: Ashish Kumar 				                      //
//  Date: 2023-07-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import InquiryService from "../../services/InquiryService";
import CalendarSidebar from "../common/CalendarSidebar";
import Alerts from "../common/Alerts";
import modaldeleteicon from "./../../assets/images/delete.svg";
import { Modal } from "react-bootstrap";
import Moment from "moment";
import AllPropertyReservationSidebar from "../common/AllPropertyReservationSidebar";

export class InquiryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      toggleSwitchDark2: true,
      addModal: false,
      showSpinner: false,
      loading: false,
      editModal: false,
      deleteModal: false,
      status_id: 1,
      open: true,
      eventKey: "3",
      confirm_back: 0,
      inquiry_status: "",
      inquiry_slug: this.props.match.params.inquirySlug,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      outside_property_slug: this.props.match.params.outsidePropertySlug,
      pathname: window.location.pathname,
      inspectionDataTotal: 0,
      UserFirstName: JSON.parse(localStorage.getItem("userData")).first_name,
      UserLastName: JSON.parse(localStorage.getItem("userData")).last_name,
      UserEmail: JSON.parse(localStorage.getItem("userData")).email,
      UserSlug: JSON.parse(localStorage.getItem("userData")).slug,
    };
    this.getInquiryDetails = this.getInquiryDetails.bind(this);
  }

  async componentDidMount() {
    this.getInquiryDetails();
  }

  async getInquiryDetails() {
    if (this.state.inquiry_slug !== undefined) {
      var resData = {};
      var inquiry_status = "";
      let res = await InquiryService.getInquiry(this.state.inquiry_slug);
      if (global.successStatus.includes(res.status)) {
        resData.guest_name = res.data ? res.data.guest_name : "";
        resData.unit_name = res.data ? res.data.unit_name : "";
        resData.listing_name = res.data ? res.data.listing_name : "";
        resData.number_of_guests = res.data ? res.data.number_of_guests : "";
        if (res.data && res.data.phone) {
          this.phoneNumberAutoFormat(res.data.phone);
        }
        resData.email = res.data ? res.data.email : "";
        resData.comments = res.data ? res.data.comments : "";
        resData.is_archived = res.data ? res.data.is_archived : 0;
        resData.set_channel = res.data.channel
          ? res.data.channel.channel_name
          : "";
        // resData.checkin_date = res.data.from_date
        //   ? Moment(res.data.from_date).format(global.dateFormat) +
        //     " | " +
        //     Moment(res.data.from_date).format(global.timeFormat)
        //   : "";
        // resData.checkout_date = res.data.to_date
        //   ? Moment(res.data.to_date).format(global.dateFormat) +
        //     " | " +
        //     Moment(res.data.to_date).format(global.timeFormat)
        //   : "";
        resData.checkin_date = res.data.from_date
          ? Moment(res.data.from_date).format(global.dateFormat)
          : "";
        resData.checkout_date = res.data.to_date
          ? Moment(res.data.to_date).format(global.dateFormat)
          : "";
        resData.created_at = res.data.created_at
          ? Moment(res.data.created_at).format(global.dateTimeFormat)
          : "";
      }
      setTimeout(() => {
        const currentDate = new Date();
        var dateFrom = Moment(resData.created_at, "MM/DD/YYYY HH:mm:ss").format(
          "MM/DD/YYYY HH:mm:ss"
        );
        const pastDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
        var dateCheck = Moment(pastDate).format("MM/DD/YYYY HH:mm:ss");
        var date1 = new Date(dateFrom);
        var current = new Date(dateCheck);
        if (current.getTime() < date1.getTime()) {
          inquiry_status = "Active";
        } else {
          inquiry_status = "Inactive";
        }
        this.setState(resData);
        this.setState({ inquiry_status: inquiry_status });
      }, 100);
    }
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ phone_number: finalnumber });
  };

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  getTitle() {
    return "Inquiry";
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <CalendarSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <AllPropertyReservationSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 reservationheadermobile">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center"></div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15 pd-r-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15 pd-xs-l-15 pd-xs-r-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-sm-l-30 pd-r-0 pd-xs-l-15">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Name</p>
                        <p className="media-body">
                          {this.state.guest_name ? this.state.guest_name : ""}
                        </p>
                      </div>

                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">
                          Listing Name (Unit)
                        </p>
                        <p className="media-body">
                          {this.state.listing_name ? (
                            <a
                              href={
                                this.props.match.params.outsidePropertySlug !==
                                undefined
                                  ? "/properties/view/" +
                                    this.state.property_slug +
                                    "/listing/" +
                                    this.state.listing_slug +
                                    "/info"
                                  : "/listing/view/" +
                                    this.state.property_slug +
                                    "/" +
                                    this.state.listing_slug +
                                    "/info"
                              }
                            >
                              {this.state.listing_name}
                            </a>
                          ) : (
                            ""
                          )}
                          {this.state.unit_name ? " (" : ""}

                          {this.state.unit_name && this.state.property_slug ? (
                            <a
                              href={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units/" +
                                this.state.unit_slug +
                                "/unit-basic"
                              }
                            >
                              {this.state.unit_name}
                            </a>
                          ) : (
                            ""
                          )}
                          {this.state.unit_name ? ")" : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Channel</p>
                        <p className="media-body">{this.state.set_channel}</p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Check-In</p>
                        <p className="media-body">
                          {this.state.checkin_date
                            ? this.state.checkin_date
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Check-Out</p>
                        <p className="media-body">
                          {this.state.checkout_date
                            ? this.state.checkout_date
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">
                          Date Entered
                        </p>
                        <p className="media-body">
                          {this.state.created_at ? this.state.created_at : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Email</p>
                        <p className="media-body">
                          {this.state.email ? this.state.email : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Phone</p>
                        <p className="media-body">
                          {this.state.phone_number
                            ? this.state.phone_number
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">
                          Number of Guests
                        </p>
                        <p className="media-body">
                          {this.state.number_of_guests
                            ? this.state.number_of_guests
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Status</p>
                        <p className="media-body">
                          {this.state.inquiry_status
                            ? this.state.inquiry_status
                            : ""}
                        </p>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                        <p className="az-profile-name-text pl-0">Comments</p>
                        <p className="media-body">
                          {this.state.comments ? this.state.comments : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteLeadModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteLeadModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteAirbnbInquiryModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteAirbnbInquiry(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default InquiryView;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PropertyTypeService.js                       //
//  Application: Property Type                            //
//  Option: Used for manage property suitability          //
//  Developer: NP           						                  //
//  Date: 2021-01-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class PropertySuitabilityService {

  getPropertySuitability(queryString) {
    let endPoint = "suitability" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createPropertySuitability(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "suitability";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getPropertySuitabilityBySlug(slug) {
    let endPoint = "suitability/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updatePropertySuitability(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "suitability/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deletePropertySuitability(slug) {
    let endPoint = "suitability/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new PropertySuitabilityService();

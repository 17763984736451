//     							                          //
//  Program: LeasingSidebar.jsx                           //
//  Application: Property                                 //
//  Option: common component Leasing Sidebar              //
//  Developer: NP 		                                  //
//  Date: 2022-04-02                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';

class ResidentSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      property_slug: sessionStorage.getItem("property_slug") ? sessionStorage.getItem("property_slug") : "",
    };
  }

  isPathActive(path) {
    return window.location.pathname.includes(path);
  }

  render() {
    return (
      <>
        <div className="deskchildMenu">
          <aside>
            <nav className="nav nav-pills flex-column pd-r-8">
              <ul>
                <li>
                  <ul>
                    <li>
                      <Link
                        className={
                          this.isPathActive("residents")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/residents"}
                      >
                        Residents
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          this.isPathActive("resident-payouts")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/resident-payouts"}
                      >
                        Payouts
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          this.isPathActive("rent")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/rent"}
                      >
                        Rent
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          this.isPathActive("resident-inbox")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/resident-inbox"}
                      >
                        Inbox
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          this.isPathActive("resident-checklists")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/resident-checklists"}
                      >
                        Checklists
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
        <div className="mobchildMenu">
          <div className="d-flex justify-content-between align-items-center submenu">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <span>Sub Menu</span>
                <i className="fas fa-chevron-down"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu className="tx-13">
                <aside>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <ul>
                      <li>
                        <ul>
                        <li>
                          <Link
                            className={
                              this.isPathActive("residents")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/residents"}
                          >
                            Residents
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.isPathActive("resident-payouts")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/resident-payouts"}
                          >
                            Payouts
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.isPathActive("rent")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/rent"}
                          >
                            Rent
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.isPathActive("resident-inbox")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/resident-inbox"}
                          >
                            Inbox
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.isPathActive("resident-checklists")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-toggle="tab"
                            to={"/properties/view/" + (this.props.property_slug ? this.props.property_slug : this.state.property_slug) + "/resident-checklists"}
                          >
                            Checklists
                          </Link>
                        </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </aside>
              </Dropdown.Menu>
            </Dropdown>
          </div>

        </div>
      </>
    );
  }
}
export default ResidentSidebar;
//     							                               //
//  Program: Roles.jsx                                         //
//  Application: roles                                         //
//  Option: Users Listing                                      //
//  Developer: Mitesh Prajapati 		                       //
//  Date: 2022-08-10                                           //
//                                                             //
/////////////////////////////////////////////////////////     ///

import React, { Component } from "react";
// import ReactDatatable from "@ashvin27/react-datatable";
import { Container, Modal } from "react-bootstrap";
// import SettingSidebar from "../common/SettingSidebar";
import ReactDatatable from "@ashvin27/react-datatable";
import NoData from "../common/NoData";
import RolePermissionService from "../../services/RolePermissionService";
// import NoData from "../common/NoData";
import modaldeleteicon from "./../../assets/images/delete.svg";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import deleteicon from "../../assets/images/delete.svg";
import pluswIcon from "./../../assets/images/plus.svg";
import AuthenticationService from "../../services/AuthenticationService";
import copyIcon from "./../../assets/images/copy.png";
import UsersSidebar from "../common/UsersSidebar";

class Roles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: window.location.pathname,
      showSpinner: false,
      loading: false,
      total_record: 0,
      records: [],
      userCount: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      deleteRoleModal: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "role_title",
          text: "Role",
          className: "role_title hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.role_title &&
              global.userPermissions.checkPermission("role-update") ? (
              <Link to={"/role-permission/" + record.slug} className="">
                {record.role_title}
              </Link>
            ) : (
              record.role_title
            );
          },
        },
        {
          key: "default_landing_page",
          text: "Default Landing Page",
          className: "default_landing_page hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.default_landing_page &&
              global.userPermissions.checkPermission("role-update") ? (
              <Link to={"/role-permission/" + record.slug} className="">
                {record.default_landing_page == 1
                  ? "Dashboard"
                  : record.default_landing_page == 2
                  ? "Snapshot"
                  : ""}
              </Link>
            ) : (
              record.default_landing_page
            );
          },
        },
        {
          key: "no_user",
          text: "# of Users",
          className: "no_user hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.no_user &&
              global.userPermissions.checkPermission("role-update") ? (
              <Link to={"/role-permission/" + record.slug} className="">
                {record.no_user}
              </Link>
            ) : (
              record.no_user
            );
          },
        },
        {
          key: "role_group",
          text: "Role Group",
          className: "role_group hand-cursor td_clickable",
          sortable: true,
          cell: (record) => {
            return record.role_group_name &&
              global.userPermissions.checkPermission("role-update") ? (
              <Link to={"/role-permission/" + record.slug} className="">
                {record.role_group_name}
              </Link>
            ) : (
              record.role_group_name
            );
          },
        },
        (global.userPermissions.checkPermission("role-add") ||
          global.userPermissions.checkPermission("role-delete")) && {
          key: "Action",
          text: "Action",
          className: "role_group hand-cursor td_clickable",
          sortable: false,
          cell: (record) => {
            return (
              <div>
                {global.userPermissions.checkPermission("role-add") && (
                  <img
                    src={copyIcon}
                    alt="Copy"
                    onClick={() =>
                      this.copyRole(
                        record.slug,
                        record.role_title,
                        record.role_group_name,
                        record.default_landing_page
                      )
                    }
                    className="hand-cursor copyClass dashboardRightcard "
                  />
                )}

                {global.userPermissions.checkPermission("role-delete") && (
                  <img
                    src={deleteicon}
                    alt=""
                    onClick={() => this.openDeleteRoleModal(record.slug)}
                    className="hand-cursor"
                  />
                )}
              </div>
            );
          },
        },
      ],
    };

    this.openDeleteRoleModal = this.openDeleteRoleModal.bind(this);
    this.copyRole = this.copyRole.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.userData();
  }

  // to get data of roles
  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await RolePermissionService.getRoles(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data.data : [];
      totalRecords = res.data ? res.data.data.total : 0;
    }

    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  async userData() {
    this.setState({ showSpinner: true, loading: true });
    let res = await AuthenticationService.getUserList();
    if (global.successStatus.includes(res.status)) {
      var list = res.data ? res.data.userData : [];
    }

    this.setState({
      userCount: list.length,
    });
  }

  async copyRole(slug, copyRoleTitle, copyRoleGroup,default_landing_page) {
    //set role Group id.
    copyRoleGroup === "Corporate" ? (copyRoleGroup = 1) : (copyRoleGroup = 3);

    let inputData = {
      role_title: copyRoleTitle,
      role_group: copyRoleGroup,
      clone_role: slug,
      default_landing_page: default_landing_page
      
    };

    let res = await RolePermissionService.copyRole(inputData);
    if (global.successStatus.includes(res.status)) {
      setTimeout(
        () => this.props.history.push("/roles"),
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        }),
        global.redirect_time
      );
      this.getData();
      this.setState({ showSpinner: false });
      setTimeout(
        () => this.setState({ showAlertModal: true }),
        global.alert_close_time
      );
    } else {
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  openDeleteRoleModal(slug) {
    this.setState({ deleteRoleModal: true });
    this.setState({ slug: slug });
  }

  async deleteRole(slug) {
    this.setState({ showSpinner: true });
    let res = await RolePermissionService.deleteRole(slug);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteRoleModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        records: this.state.records.filter((list) => list.slug !== slug),
      });
      setTimeout(() => this.props.history.push("/roles"), global.redirect_time);
    } else {
      this.closeDeleteRoleModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
    setTimeout(() => this.props.history.push("/roles"), global.redirect_time);
  }
  getActiveClass(e) {
    this.setState({ activeClass: e });
  }

  closeDeleteRoleModal() {
    this.setState({ deleteRoleModal: false });
    this.setState({ slug: "" });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  rowClickedHandler = (data) => {
    this.props.history.push("/role-permission/" + data);
  };

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ''} */}
        <Container className="innter-container over-fl ">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <UsersSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="scrolling-carousel">
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="row row-xs ">
                      <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                      <div className="col-md-12">
                        <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                          <h3 className="mb-0 brnd_heading">Roles</h3>
                          {global.userPermissions.checkPermission(
                            "role-add"
                          ) && (
                            <Link to={"/role/add"}>
                              <button className="btn-success-outline mg-r-20">
                                <img src={pluswIcon} alt="Add" /> Add
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pd-lg-l-30 ">
                    <div className="table-responsive roles   ">
                      <div className="col-md-12 pl-0 pr-0  ">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      {this.state.total_record > 0 ||
                      this.state.filter_value !== "" ? (
                        <div className="mg-t-18 brandList table_issue_roles copy_img">
                          <div className="pd-lg-r-0 pd-lg-l-0 pd-xs-l-15 pd-xs-r-0">
                            <ReactDatatable
                              className="table no-wrap mg-b-30 pd-b-2"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              // loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              //  onRowClicked={this.rowClickedHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        <NoData
                          msg={global.commonMessages.checkMessage("ROLE")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Modal show={this.state.deleteRoleModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closeDeleteRoleModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteRoleModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteRole(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default Roles;

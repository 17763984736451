////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Signin.jsx                                   //
//  Application: authentication                           //
//  Option: For sign in into project                      //
//  Developer: NP  						                            //
//  Date: 2021-12-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
// import { Link, Redirect } from "react-router-dom";
import Alerts from "../common/Alerts";
import BuildingOnboardingService from "../../services/BuildingOnboardingService";

class Signin extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isSubmit: false,
      showAlertModal: true,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      email: "",
      password: "",
      headerToken: localStorage.getItem("headerToken"),
      totalCount: 11,
      setpsCount: 0,
    };

    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.signin = this.signin.bind(this);
    this.getSettingData = this.getSettingData.bind(this);
    this.getOnboardingSetpsCount = this.getOnboardingSetpsCount.bind(this);
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  componentDidMount() {
    // this.getSettingData();
    // if(this.state.headerToken !== null || this.state.headerToken !== ''){
    //     localStorage.clear();
    //     sessionStorage.clear();
    // }
  }

  async getSettingData() {
    let res = await AuthenticationService.getSettingData();
    if (global.successStatus.includes(res.status)) {
      global.login_image = res.data ? res.data.static_images.login_image : "";
      global.welcome_image = res.data
        ? res.data.static_images.welcome_image
        : "";
    }
  }

  signin = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      // Validate all fields before data submission
      this.setState({ isSubmit: true });
      let input = {
        email: this.state.email,
        password: this.state.password,
      };
      let res = await AuthenticationService.signin(input);
      if (global.successStatus.includes(res.status)) {
        localStorage.setItem(
          "headerToken",
          res.data.headerToken ? res.data.headerToken : ""
        );
        global.headerToken = res.data.headerToken ? res.data.headerToken : "";
        global.currerntRoleSlug = res.data.currerntRoleSlug
          ? res.data.currerntRoleSlug
          : "";
        localStorage.setItem("currerntRoleSlug", res.data.currerntRoleSlug);
        global.currerntRoleSlug = res.data.currerntRoleSlug
          ? res.data.currerntRoleSlug
          : "";
        localStorage.setItem("currentRoleTitle", res.data.currerntRoleTitle);
        global.currentRoleTitle = res.data.currerntRoleTitle
          ? res.data.currerntRoleTitle
          : "";
        localStorage.setItem(
          "currerntRoleGroup",
          res.data.currerntRoleGroup ? res.data.currerntRoleGroup : ""
        );
        // localStorage.setItem('isLogin', 1);
        localStorage.setItem("isPopupShow", 1);
        localStorage.setItem(
          "userData",
          res.data.user_data ? JSON.stringify(res.data.user_data) : {}
        );
        localStorage.setItem(
          "permissions",
          res.data.permissionList ? res.data.permissionList : []
        );
        localStorage.setItem(
          "roleList",
          res.data.roleList ? JSON.stringify(res.data.roleList) : {}
        );
        // localStorage.setItem('user_type', res.data.currerntRoleGroup ? res.data.currerntRoleGroup : '');
        localStorage.setItem(
          "propertyList",
          res.data.propertyList ? JSON.stringify(res.data.propertyList) : []
        );
        localStorage.setItem(
          "brandList",
          res.data.brandList ? JSON.stringify(res.data.brandList) : []
        );
        localStorage.setItem("messageCount", 1);
        localStorage.setItem("notifyCount", 1);
        localStorage.setItem("message_page", 1);
        this.setState({ messagePageActive: 1 });
        localStorage.setItem(
          "currerntPropSlug",
          res.data.propertyList ? res.data.propertyList[0].slug : ""
        );
        sessionStorage.setItem(
          "currerntPropSlug",
          res.data.propertyList ? res.data.propertyList[0].slug : ""
        );
        if (
          res.data &&
          res.data.propertyList &&
          res.data.propertyList[0] &&
          res.data.propertyList[0].slug
        ) {
          await this.getOnboardingSetpsCount(res.data.propertyList[0].slug);
        }
        if (
          res.data.roleList &&
          res.data.roleList[0] &&
          res.data.roleList[0].defaultLandingPage === 3
        ) {
          if (
            ((res.data.roleList &&
              res.data.roleList[0] &&
              res.data.roleList[0].roleId === 1) ||
              (res.data.roleList &&
                res.data.roleList[0] &&
                res.data.roleList[0].roleId === 2)) &&
            this.state.totalCount !== this.state.setpsCount
          ) {
            window.location.href =
              "/properties/view/" +
              (res.data &&
              res.data.propertyList &&
              res.data.propertyList[0] &&
              res.data.propertyList[0].slug
                ? res.data.propertyList[0].slug
                : "") +
              "/building-onboarding";
          } else {
            window.location.href = "/properties";
          }
        } else if (
          res.data.roleList &&
          res.data.roleList[0] &&
          res.data.roleList[0].defaultLandingPage === 1
        ) {
          window.location.href = "/dashboard";
        } else if (
          res.data.roleList &&
          res.data.roleList[0] &&
          res.data.roleList[0].defaultLandingPage === 4
        ) {
          if (
            (res.data.roleList &&
              res.data.roleList[0] &&
              res.data.roleList[0].roleId === 1) ||
            (res.data.roleList &&
              res.data.roleList[0] &&
              res.data.roleList[0].roleId === 2 &&
              this.state.totalCount !== this.state.setpsCount)
          ) {
            window.location.href =
              "/properties/view/" +
              (res.data &&
              res.data.propertyList &&
              res.data.propertyList[0] &&
              res.data.propertyList[0].slug
                ? res.data.propertyList[0].slug
                : "") +
              "/building-onboarding";
          } else {
            window.location.href = "/properties";
          }
        } else if (
          res.data.roleList &&
          res.data.roleList[0] &&
          res.data.roleList[0].defaultLandingPage === 2
        ) {
          window.location.href = "/snapshot";
        } else {
          window.location.href = "/snapshot";
        }

        // //redirect logic as per user type - NP
        // if(res.data.user_type === 1){
        //   // usertype === 1 (admin)
        //   window.location.href = "/overview"
        // }else if(res.data.user_type === 2){
        //   // usertype === 2 (property manager)
        //   if(res.data.property_slug){
        //     window.location.href = "/properties/view/"+res.data.property_slug+"/property-overview"
        //   }else{
        //     window.location.href = "/properties"
        //   }
        // }else if(res.data.user_type === 3){
        //   // usertype === 3 (cleaning staff)
        //   if(res.data.property_slug){
        //     window.location.href = "/properties/view/"+res.data.property_slug+"/housekeeping-list"
        //   }else{
        //     window.location.href = "/properties"
        //   }
        // }else if(res.data.user_type === 4){
        //   // usertype === 3 (support team)
        //   if(res.data.property_slug){
        //     window.location.href = "/properties/view/"+res.data.property_slug+"/reservations"
        //   }else{
        //     window.location.href = "/properties"
        //   }
        // }else{
        //   //if user type is not specified
        //   window.location.href = "/overview"
        // }
      } else if (res.status === 401) {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      } else if (res.status === 422) {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      } else if (res.status === 400) {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      } else {
        if (
          res.data.result.errorDetail !== null &&
          res.data.result.errorDetail !== undefined
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
      this.setState({ isSubmit: false });
    } else {
      this.validator.showMessages();
    }
  };

  async getOnboardingSetpsCount(slug) {
    var totalCount = 0;
    var setpsCount = 0;

    let res = await BuildingOnboardingService.getOnboardingStepsCount(slug);
    if (global.successStatus.includes(res.status)) {
      totalCount = res.data ? res.data.totalCount : 0;
      setpsCount = res.data ? res.data.setpsCount : 0;
    }

    this.setState({
      totalCount: totalCount,
      setpsCount: setpsCount,
      menuPermissonLoad: true,
    });
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    // Validate
    this.validator.showMessageFor(event.target.name);
  };

  getTitle() {
    return process.env.REACT_APP_PROJECT_TITLE
      ? process.env.REACT_APP_PROJECT_TITLE
      : "";
  }

  render() {
    // Redirect to dashboard if logged in
    // if (this.state.headerToken !== null) {
    //   return <Redirect to="/dashboard" />;
    // }
    return (
      <div>
        <div className="az-signin-wrapper">
          <div className="az-card-signin">
            <div className="az-logo d-flex flex-column">
              <img
                src={require("../../assets/images/ohai-logo.svg").default}
                alt=""
              ></img>
              <h2>Welcome To OHAI.</h2>
            </div>
            <div className="az-signin-header">
              <h3 className="text-center">Sign in</h3>
              <p className="text-center p-dark">Please sign in to continue.</p>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <form action="#/">
                <div className="form-group">
                  <input
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.changeHandler}
                    maxLength="50"
                  />

                  {this.validator.message(
                    "email",
                    this.state.email,
                    "email|required",
                    { className: "text-danger" }
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.changeHandler}
                    maxLength="25"
                  />

                  {this.validator.message(
                    "password",
                    this.state.password,
                    "required",
                    { className: "text-danger" }
                  )}
                </div>
                <button
                  className="btn btn-info btn-block button-green"
                  onClick={this.signin}
                >
                  {this.state.isSubmit ? global.loader : "Let's go"}
                </button>

                <div className="az-signin-footer text-center mt-3">
                  <p>
                    <Link to="/forgot-password" className="tx-16">
                      Forgot password?
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="az-copyright text-center">
            <p>© {global.current_year} OHAI, Inc. All rights reserved.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;

//                                                           //
//  Program: RentalPropertyLookup.jsx                               //
//  Application: Rental United                               //
//  Option: Rental Lookup tables                             //
//  Developer: NP 		                                     //
//  Date: 2022-09-13                                         //
//                                                           //
/////////////////////////////////////////////////////////   ///

import React, { Component } from "react";
import RUSidebar from "../common/RUSidebar";
import Select from "react-select";
import { Container, Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import PropertyService from "../../services/PropertyService";
import RentalService from "../../services/RentalService";
import SimpleReactValidator from "simple-react-validator";
// import DatePicker from "react-datepicker";
// import Moment from "moment";
import InputMask from "react-input-mask";

class RentalPropertyLookup extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      input: {},
      errors: {},
      propertyList: [],
      newPropertyList:[],
      selectedPropertyList:[],
      property_slug: "",
      days_before_arrival : "",
      how_to_arrive : "",
      check_out_until : "",
      checkin_place : "",
      late_arrival_fee : "",
      late_arrival_fee_from : "",
      late_arrival_fee_to : "",
      early_departure_fee : "",
      early_departure_fee_from : "",
      early_departure_fee_to : "",
      // cancellation_validto : "",
      // cancellation_validfrom : "",
      // space : "",
      access : "",
      neighborhood : "",
      notes : ""
    };
    this.saveData = this.saveData.bind(this);
  }

  componentDidMount(){
    this.getPropertyData();
  }

  async getPropertyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var propertyList = [];
    var propertyListTotal = 0;
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0
    }
    const newPropertyList = propertyListTotal > 0 ? propertyList.map(({ slug, property_name }) => ({
      value: slug,
      label: property_name,
    })) : [];
    this.setState({ propertyList: propertyList, newPropertyList: newPropertyList, propertyListTotal: propertyListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  async getPropertyDetails(value) {
    if (value !== undefined) {
      var resData = {};

      let res = await PropertyService.getProperty(value);
      if (global.successStatus.includes(res.status)) {
        // resData = res.data ? res.data.ruPropertyData : {}
        resData.days_before_arrival = res.data.ruPropertyData ? res.data.ruPropertyData.days_before_arrival : ""
        resData.how_to_arrive = res.data.ruPropertyData ? res.data.ruPropertyData.how_to_arrive : ""
        resData.check_out_until = res.data.ruPropertyData ? res.data.ruPropertyData.check_out_until : ""
        resData.checkin_place = res.data.ruPropertyData ? res.data.ruPropertyData.checkin_place : ""
        resData.late_arrival_fee = res.data.ruPropertyData ? res.data.ruPropertyData.late_arrival_fee : ""
        resData.late_arrival_fee_from = res.data.ruPropertyData ? res.data.ruPropertyData.late_arrival_fee_from : ""
        resData.late_arrival_fee_to = res.data.ruPropertyData ? res.data.ruPropertyData.late_arrival_fee_to : ""
        resData.early_departure_fee = res.data.ruPropertyData ? res.data.ruPropertyData.early_departure_fee : ""
        resData.early_departure_fee_from = res.data.ruPropertyData ? res.data.ruPropertyData.early_departure_fee_from : ""
        resData.early_departure_fee_to = res.data.ruPropertyData ? res.data.ruPropertyData.early_departure_fee_to : ""
        // resData.cancellation_validto = res.data.ruPropertyData ? res.data.ruPropertyData.cancellation_validto : ""
        // resData.cancellation_validfrom = res.data.ruPropertyData ? res.data.ruPropertyData.cancellation_validfrom : ""
        // resData.space = res.data.ruPropertyData ? res.data.ruPropertyData.space : ""
        resData.access = res.data.ruPropertyData ? res.data.ruPropertyData.access : ""
        resData.neighborhood = res.data.ruPropertyData ? res.data.ruPropertyData.neighborhood : ""
        resData.notes = res.data.ruPropertyData ? res.data.ruPropertyData.notes : ""
      }
      this.setState(resData);
    }
  }

  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    if(value){
      this.getPropertyDetails(value.value);
    }
    this.setState({
      selectedPropertyList: value,
      property_slug: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();

    this.setState({ confirm_back: 1 });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  saveData = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        days_before_arrival: this.state.days_before_arrival ? this.state.days_before_arrival : "",
        how_to_arrive: this.state.how_to_arrive ? this.state.how_to_arrive : "",
        check_out_until: this.state.check_out_until ? this.state.check_out_until : "",
        checkin_place: this.state.checkin_place ? this.state.checkin_place : "",
        late_arrival_fee: this.state.late_arrival_fee ? this.state.late_arrival_fee : "",
        late_arrival_fee_from: this.state.late_arrival_fee_from ? this.state.late_arrival_fee_from : "",
        late_arrival_fee_to: this.state.late_arrival_fee_to ? this.state.late_arrival_fee_to : "",
        early_departure_fee: this.state.early_departure_fee ? this.state.early_departure_fee : "",
        early_departure_fee_from: this.state.early_departure_fee_from ? this.state.early_departure_fee_from : "",
        early_departure_fee_to: this.state.early_departure_fee_to ? this.state.early_departure_fee_to : "",
        // cancellation_validto: this.state.cancellation_validto ? this.state.cancellation_validto : "",
        // cancellation_validfrom: this.state.cancellation_validfrom ? this.state.cancellation_validfrom : "",
        // space: this.state.space ? this.state.space : "",
        access: this.state.access ? this.state.access : "",
        neighborhood: this.state.neighborhood ? this.state.neighborhood : "",
        notes: this.state.notes ? this.state.notes : ""
      };
     
      let res = await RentalService.createPropertyData(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        // setTimeout(
        //   () =>
        //     this.props.history.push(
        //       "/properties/view/" +
        //       this.state.property_slug +
        //       "/guest/view/" +
        //       this.state.slug
        //     ),
        //   global.redirect_time
        // );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
     
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  render() {
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
          color: (state.isSelected && state.isFocused) ? "white" : (state.isSelected ? "black" : state.isFocused && "white"),
          backgroundColor: (state.isSelected && state.isFocused) ? 'black' : (state.isFocused && "black"),
        })
      }
    return (
      <>
        <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
            <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                <div className="child-menu-section">
                    <aside>
                    <RUSidebar />
                    </aside>
                </div>
                </div>
                <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>Property</h3>
                    <div className="d-flex justify-content-end">
                      {global.userPermissions.checkPermission('ru-integration-update') &&
                        <button
                        type="button"
                        className="btn-success ml-3"
                        onClick={this.saveData}
                        disabled={this.state.isSubmit ? true : false}
                        >
                        {this.state.isSubmit
                            ? global.loader
                            : "Save"}
                        </button>
                      }
                    </div>
                    </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                    <form id="units_form">
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                        <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                        <Alerts
                            show={this.state.showAlertModal}
                            type={this.state.alertModalType}
                            title={this.state.alertModalTitle}
                            message={this.state.alertModalMessage}
                        />
                        </div>
                        <div className="m-0">
                        <div className="row align-items-center pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-0 pd-lg-r-15">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Property{" "}
                            </label>
                            <Select
                            styles={customStyles}
                            className="multiselect"
                            classNamePrefix={"my-custom-react-select"}
                            menuPlacement="auto"
                            value={this.state.selectedPropertyList}
                            options={this.state.newPropertyList}
                            getOptionValue={option => `${option.label}`}
                            onChange={(value) => this.handleChangePropertyList(value)}
                            defaultValue={this.state.selectedPropertyList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: '#fff',
                                primary75: '#000',
                                primary50: '#000',
                                primary25: '#000',
                              }
                            })}
                          />
                            {this.state.errors.property_slug ? (
                                <div className="text-danger">
                                {this.state.errors.property_slug}
                                </div>
                            ) : (
                                this.validator.message(
                                "property",
                                this.state.property_slug,
                                "required",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                        </div>
                        {this.state.property_slug &&
                        <div className="row align-items-center pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-0 pd-lg-r-15">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Days before arrival{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="days_before_arrival"
                                value={this.state.days_before_arrival}
                                maxLength="50"
                                onKeyPress={global.onKeyPressEvent.numberValidation}
                            />
                            {this.state.errors.days_before_arrival ? (
                                <div className="text-danger">
                                {this.state.errors.days_before_arrival}
                                </div>
                            ) : ''}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                How to arrive{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="how_to_arrive"
                                value={this.state.how_to_arrive}
                                maxLength="1000"
                                as="textarea"
                            />
                            {this.state.errors.how_to_arrive ? (
                                <div className="text-danger">
                                {this.state.errors.how_to_arrive}
                                </div>
                            ) : (
                                this.validator.message(
                                "how_to_arrive",
                                this.state.how_to_arrive,
                                "max:1000",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Checkout Until{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                             {/* <DatePicker
                              selected={this.state.check_out_until}
                              onChange={check_out_until => this.setState({ check_out_until })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm a"
                            /> */}
                            <InputMask
                                name="check_out_until"
                                onChange={this.changeHandler}
                                value={
                                  this.state.check_out_until !== ""
                                    ? this.state.check_out_until || ""
                                    : "00:00AM"
                                }
                                mask="99:99 aa"
                              />
                            {this.state.errors.check_out_until ? (
                                <div className="text-danger">
                                {this.state.errors.check_out_until}
                                </div>
                            ) : ''}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Checkin Place{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="checkin_place"
                                value={this.state.checkin_place}
                                maxLength="50"
                            />
                            {this.state.errors.checkin_place ? (
                                <div className="text-danger">
                                {this.state.errors.checkin_place}
                                </div>
                            ) : (
                                this.validator.message(
                                "checkin_place",
                                this.state.checkin_place,
                                "max:50",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Late Arrival Fee{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="late_arrival_fee"
                                value={this.state.late_arrival_fee}
                                maxLength="5"
                                onKeyPress={global.onKeyPressEvent.floatValidation}
                            />
                            {this.state.errors.late_arrival_fee ? (
                                <div className="text-danger">
                                {this.state.errors.late_arrival_fee}
                                </div>
                            ) : (
                                this.validator.message(
                                "late_arrival_fee",
                                this.state.late_arrival_fee,
                                "max:8",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Late Arrival Fee From{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <InputMask
                                name="late_arrival_fee_from"
                                onChange={this.changeHandler}
                                value={
                                  this.state.late_arrival_fee_from !== ""
                                    ? this.state.late_arrival_fee_from || ""
                                    : "00:00AM"
                                }
                                mask="99:99 aa"
                              />
                             {/* <DatePicker
                              selected={this.state.late_arrival_fee_from}
                              onChange={late_arrival_fee_from => this.setState({ late_arrival_fee_from })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm a"
                            /> */}
                            {this.state.errors.late_arrival_fee_from ? (
                                <div className="text-danger">
                                {this.state.errors.late_arrival_fee_from}
                                </div>
                            ) : ''}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Late Arrival Fee To{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <InputMask
                                name="late_arrival_fee_to"
                                onChange={this.changeHandler}
                                value={
                                  this.state.late_arrival_fee_to !== ""
                                    ? this.state.late_arrival_fee_to || ""
                                    : "00:00AM"
                                }
                                mask="99:99 aa"
                              />
                             {/* <DatePicker
                              selected={this.state.late_arrival_fee_to}
                              onChange={late_arrival_fee_to => this.setState({ late_arrival_fee_to })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm a"
                            /> */}
                            {this.state.errors.late_arrival_fee_to ? (
                                <div className="text-danger">
                                {this.state.errors.late_arrival_fee_to}
                                </div>
                            ) : ''}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                 Early Departure Fee{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="early_departure_fee"
                                value={this.state.early_departure_fee}
                                maxLength="5"
                                onKeyPress={global.onKeyPressEvent.floatValidation}
                            />
                            {this.state.errors.early_departure_fee ? (
                                <div className="text-danger">
                                {this.state.errors.early_departure_fee}
                                </div>
                            ) : (
                                this.validator.message(
                                "early_departure_fee",
                                this.state.early_departure_fee,
                                "max:8",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Early Departure Fee From{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <InputMask
                                name="early_departure_fee_from"
                                onChange={this.changeHandler}
                                value={
                                  this.state.early_departure_fee_from !== ""
                                    ? this.state.early_departure_fee_from || ""
                                    : "00:00AM"
                                }
                                mask="99:99 aa"
                              />
                             {/* <DatePicker
                              selected={this.state.early_departure_fee_from}
                              onChange={early_departure_fee_from => this.setState({ early_departure_fee_from })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm a"
                            /> */}
                            {this.state.errors.early_departure_fee_from ? (
                                <div className="text-danger">
                                {this.state.errors.early_departure_fee_from}
                                </div>
                            ) : ''}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                              Early Departure Fee To{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <InputMask
                                name="early_departure_fee_to"
                                onChange={this.changeHandler}
                                value={
                                  this.state.early_departure_fee_to !== ""
                                    ? this.state.early_departure_fee_to || ""
                                    : "00:00AM"
                                }
                                mask="99:99 aa"
                              />
                             {/* <DatePicker
                              selected={this.state.early_departure_fee_to}
                              onChange={early_departure_fee_to => this.setState({ early_departure_fee_to })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm a"
                            /> */}
                            {this.state.errors.early_departure_fee_to ? (
                                <div className="text-danger">
                                {this.state.errors.early_departure_fee_to}
                                </div>
                            ) : ''}
                            </div>
                            {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Space{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="space"
                                value={this.state.space}
                                maxLength="50"
                            />
                            {this.state.errors.space ? (
                                <div className="text-danger">
                                {this.state.errors.space}
                                </div>
                            ) : (
                                this.validator.message(
                                "space",
                                this.state.space,
                                "max:50",
                                { className: "text-danger" }
                                )
                            )}
                            </div> */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Access{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="access"
                                value={this.state.access}
                                maxLength="1000"
                                as="textarea"
                            />
                            {this.state.errors.access ? (
                                <div className="text-danger">
                                {this.state.errors.access}
                                </div>
                            ) : (
                                this.validator.message(
                                "access",
                                this.state.access,
                                "max:50",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                Notes{" "}
                                <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="notes"
                                value={this.state.notes}
                                maxLength="1000"
                                as="textarea"
                            />
                            {this.state.errors.notes ? (
                                <div className="text-danger">
                                {this.state.errors.notes}
                                </div>
                            ) : (
                                this.validator.message(
                                "notes",
                                this.state.notes,
                                "max:1000",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                            <label className="form-label text-left pd-b-5">
                                 Neighborhood{" "}
                                 <span className="optional">(Optional)</span>
                            </label>
                            <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="neighborhood"
                                value={this.state.neighborhood}
                                maxLength="1000"
                                as="textarea"
                            />
                            {this.state.errors.neighborhood ? (
                                <div className="text-danger">
                                {this.state.errors.neighborhood}
                                </div>
                            ) : (
                                this.validator.message(
                                "neighborhood",
                                this.state.neighborhood,
                                "max:1000",
                                { className: "text-danger" }
                                )
                            )}
                            </div>
                        </div>
                        }
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </Container>
        </main>
      </>
    );
  }
}
export default RentalPropertyLookup
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import infosize from "../../assets/images/Info.svg";
import Alerts from "../common/Alerts";
import InputMask from "react-input-mask";

export class PropertyOfficeHoursView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // propertySlug: this.props.property_slug,
      viewMode: 0,
      // property_office_data : [],
      // property_office_data_total : 0,
      dayList: global.dayList,
      confirm_back: 0,
      property_office_data: [
        {
          day: 1,
          label: "Monday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 2,
          label: "Tuesday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 3,
          label: "Wednesday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 4,
          label: "Thursday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 5,
          label: "Friday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 6,
          label: "Saturday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 7,
          label: "Sunday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
      ],
    };
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.saveOrUpdateOfficeHours = this.saveOrUpdateOfficeHours.bind(this);
  }

  componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getPropertyOfficeHoursData();
  }

  async getPropertyOfficeHoursData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.props.property_slug !== undefined) {
      var property_office_data = [];
      var property_office_data_total = 0;

      let res = await PropertyService.getPropertyOfficeHours(
        this.props.property_slug
      );

      if (global.successStatus.includes(res.status)) {
        if (res.data.length > 0) {
          property_office_data = res.data ? res.data : [];
          property_office_data_total = property_office_data
            ? property_office_data.length
            : 0;

          this.setState({
            property_office_data: property_office_data,
            property_office_data_total: property_office_data_total,
          });
        }
      }
    }
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateOfficeHours = async (e) => {
    this.setState({ showSpinner: true, isSubmit: true });

    let inputData = {
      //office hours tab data
      property_slug: this.props.property_slug
        ? this.props.property_slug
        : this.state.property_slug,
      property_office_data: this.state.property_office_data
        ? this.state.property_office_data
        : [],
    };

    if (this.props.property_slug !== undefined) {
      // For active status 1:Active, 2:Inactive
      // inputData.status_id = this.state.status_id ? this.state.status_id : 2;

      let res = await PropertyService.updatePropertyOfficeHours(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({ viewMode: 0 });
        this.getPropertyOfficeHoursData();
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
          ? 1
          : 0
        : event.target.value;

    if (event.target.name === "start_time" || event.target.name === "end_time") {
      var datetime = value.split(':');
      var lengthdatetime = (datetime[0].match(/\d/g) || []).length
      if (lengthdatetime === 1) {
        datetime[0] = datetime[0].replace("_", "0");
      }
      if (parseInt(datetime[0]) > 12) {
        datetime[0] = "12";
      }
      var minute = datetime[1].split(' ');
      var lengthminute = (minute[0].match(/\d/g) || []).length

      /* const minute = [
        '__',''
      
      ]; */
      if (parseInt(minute[0]) > 59 || lengthminute <= 0) {
        minute[0] = "00";
      }
      const meridian = [
        'AM', 'PM', 'am', 'pm', 'A_', 'P_', 'a_', 'p_'
      ];
      const result = meridian.includes(minute[1].toLowerCase());
      minute[1] = result ? minute[1] : 'AM';

      value = datetime[0] + ':' + minute[0] + ' ' + minute[1].toUpperCase();
    }

    // if (event.target.name === "start_time" || event.target.name === "end_time") {
    //  var regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    //   if(!regex.test(value)){
    //       event.preventDefault();
    //       return false;
    //   }
    // }

    let property_office_data1 = this.state.property_office_data;
    if (
      event.target.name === "start_time" ||
      event.target.name === "end_time" ||
      event.target.name === "closed"
    ) {
      // let k = event.target.getAttribute("data-key");
      property_office_data1[index][event.target.name] = value;
      this.setState({ property_office_data: property_office_data1 });
    } else {
      input[event.target.name] = value;
      this.setState({ [event.target.name]: value, input: input });
    }
    this.setState({ confirm_back: 1 });
    var confirmBack = 1;
    this.props.confirmBackStatus(confirmBack);
  };

  changeView() {
    var confirmBack = 0;
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
      this.getPropertyOfficeHoursData();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.props.confirmBackStatus(confirmBack);
    }
  }

  render() {
    // const hourFirst = /[0-2]/;
    // const hourSecond = /[0-9]/;
    // const minuteFirst = /[0-5]/;
    // const minuteSecond = /[0-9]/;
    // const mask = [hourFirst, hourSecond, ":", minuteFirst, minuteSecond];

    return (
      <div className="dashboardRightcard">
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <div className="row- d-flex justify-content-end">
          {/* <Link to={'/properties/update/'+this.props.property_slug+"/property-office-hours"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
          {this.state.viewMode === 0 ? (
            <button
              className="btn-success-outline-small"
              onClick={this.changeView}
            >
              <img src={editIcon} />
              Edit
            </button>
          ) : (
            <div className="d-flex">
              <button
                type="button"
                className="btn-success-outline-small"
                onClick={() => {
                  if (this.state.confirm_back === 1)
                    if (window.confirm("Do you want to exit without saving?"))
                      this.changeView();
                    else return false;
                  else this.changeView();
                }}
              >
                Cancel
              </button>
              <button
                className="btn-success ml-3"
                onClick={this.saveOrUpdateOfficeHours}
              >
                Save
              </button>
            </div>
          )}
        </div>

        <div className="col-md-12 pl-0 pr-0 mg-t-15">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>

        {this.state.viewMode === 0 ? (
          <div className="col-md-12 pl-0 pr-0">
            <div className="table-responsive">
              <Table hover className="mg-b-0">
                <thead>
                  <tr>
                    <th className="w-30">Day</th>
                    <th className="w-30">Start Time</th>
                    <th className="w-30">End Time</th>
                    <th className="w-10">Closed</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.property_office_data_total > 0
                    ? this.state.property_office_data.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.label ? item.label : ""}</td>
                          <td>{item.start_time ? item.start_time : ""}</td>
                          <td>{item.end_time ? item.end_time : ""}</td>
                          <td>{item.closed === 1 ? "Yes" : "No"}</td>
                        </tr>
                      );
                    })
                    : this.state.dayList.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.label ? item.label : ""}</td>
                          <td>00:00 AM</td>
                          <td>00:00 PM</td>
                          <td>No</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.viewMode === 1 ? (
          <div className="m-0">
            <span className="d-inline-flex align-items-start justify-content-start infosize">
              <img src={infosize} className="mg-r-5" />
              <p>Time format will be HH:MM AM/PM 12 hours. (i.e. 07:30 PM)</p>
            </span>
            {this.state.property_office_data.map((item, index) => (
              <div
                className="row row-xs align-items-center pd-t-30 pd-b-30"
                key={item.key}
              >
                <div className="col-md-2 pl-0">
                  <label className="form-label mg-b-0 text-left">
                    {item.label}
                  </label>
                </div>
                <div className="col-md-3 col-lg-3 mg-t-5 mg-md-t-0 pl-0 react-time-picker">
                  {/* <input
                    type="text"
                    onKeyPress={global.onKeyPressEvent.timeValidation}
                    maxLength="8"
                    name="start_time"
                    onChange={(e) => this.changeHandler(e, index)}
                    value={item.start_time}
                    disabled={item.closed === 1 ? true : false}
                  /> */}
                  <InputMask
                    name="start_time"
                    onChange={(e) => this.changeHandler(e, index)}
                    value={item.start_time}
                    disabled={item.closed === 1 ? true : false}
                    mask="99:99 aa"
                  />
                </div>
                <div className="col-md-1 col-md-1 mg-t-5 mg-md-t-0 pl-0 text-center">
                  To
                </div>
                <div className="col-md-3 col-lg-3 mg-t-5 mg-md-t-0 pl-0">
                  {/* <input
                    type="text"
                    onKeyPress={global.onKeyPressEvent.timeValidation}
                    maxLength="8"
                    name="end_time"
                    onChange={(e) => this.changeHandler(e, index)}
                    value={item.end_time}
                    disabled={item.closed === 1 ? true : false}
                  /> */}
                  <InputMask
                    name="end_time"
                    onChange={(e) => this.changeHandler(e, index)}
                    value={item.end_time}
                    disabled={item.closed === 1 ? true : false}
                    mask="99:99 aa"
                  />
                </div>
                <div className="row col-md-2 col-md-2 mg-t-5 mg-md-t-0 pl-0k">
                  <div className="ckbox form-check">
                    <input
                      type="checkbox"
                      id={index}
                      name="closed"
                      className="form-check-input"
                      onChange={(e) => this.changeHandler(e, index)}
                      checked={item.closed || ""}
                    />
                    <label htmlFor={index} className="form-check-label">
                      Closed
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default PropertyOfficeHoursView;

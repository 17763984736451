import React from 'react';
import "antd/dist/antd.css";
import { Transfer, Table } from 'antd';
import difference from "lodash/difference";


export default function TransferExport({ leftColumns, rightColumns,...restProps }) {

  return (
    <Transfer {...restProps} showSelectAll={false}>
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === "left" ? leftColumns : rightColumns;
        const rowSelection = {
          getCheckboxProps: (item) => ({
            disabled: listDisabled || item.disabled
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key, }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? "none" : null }}
            onRow={({ key }) => ({
              onClick: () => {
                onItemSelect(key, listSelectedKeys.includes(key)
                  ? listSelectedKeys.filter((k) => k !== key)
                  : [...listSelectedKeys, key]);
              }
            })}
            pagination={false}
          />
        );
      }}
    </Transfer>
  );
}
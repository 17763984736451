///////////////////////////////////////////////
//     							             //
//  Program: InfluencersService.js                 //
//  Application: Services                    //
//  Option: Used for Influencers data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-02-09                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class InfluencersService {
    getInfluencers(queryString) {
        let endPoint = "influencer" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    createInfluencers(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "influencer";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    getInfluencersBySlug(slug) {
        let endPoint = "influencer/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateInfluencers(slug, data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "influencer/" + slug;
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.putCall(inputData);
        return response;
    }

    deleteInfluencers(slug) {
        let endPoint = "influencer/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
    }

    uploadInfluencersImage(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "upload-influencer-photo";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
    }

    getSocialMedia(queryString) {
        let endPoint = "social-media" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    multiDelete(slug) {
        let endPoint = "influencer-link/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = ApiService.deleteCall(inputData);
        return response;
    }
}

export default new InfluencersService();

////////////////////////////////////////////////
//     							                          //
//  Program: Checklist.jsx                    //
//  Application: House keeping                //
//  Option: Home Sharing Listing              //
//  Developer: Ashish Kumar		                //
//  Date: 2022-05-16                          //
//                                            //
////////////////////////////////////////////////

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import NoData from "../common/NoData";
import { Link } from "react-router-dom";
import pluswIcon from "./../../assets/images/plus.svg";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import ChecklistService from "../../services/ChecklistService";
import { Modal } from "react-bootstrap";
import Select from "react-select";
class Checklist extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showBasicModal: false,
      open: true,
      eventKey: "3",
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      is_available: "",
      available_from: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      filter_value: "",
      templateList: [],
      templateListTotal: 0,
      selectedTemplateList: [],
      template_slug: [],
      // date: this.props.match.params.date,
      checklist_slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      records: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "checklist_name",
          text: "Checklist",
          className: "checklist_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.checklist_name ? record.checklist_name : "";
          },
        },

        {
          key: "zone_type",
          text: "Applicable",
          className: "zone_type hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.zone_type ? record.zone_type.zone_type_name : "";
          },
        },

        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active">Active</b>
            ) : (
              <b className="status-inactive">InActive</b>
            );
          },
        },
      ],
    };
    this.importTemplate = this.importTemplate.bind(this);
    this.tableChangeHandler = this.tableChangeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (this.state.checklist_slug) {
      var queryString =
        "checklist_slug=" + this.state.checklist_slug + "&is_custom=1";
      this.getData(queryString);
    } else {
      this.getData();
    }
  }

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    let res = await ChecklistService.getCheckList(
      "property_slug=" + this.state.property_slug + "&" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      totalRecords = list ? list.length : 0;
      //totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  async getTemplate(queryString = "") {
    this.setState({ loading: true });
    var templateList = [];
    var templateListTotal = 0;
    let res = await ChecklistService.getTemplateList(queryString);

    if (global.successStatus.includes(res.status)) {
      templateList = res.data.data ? res.data.data : [];
      templateListTotal = templateList ? templateList.length : 0;
    }
    const newTemplateList =
      templateListTotal > 0
        ? templateList.map(({ slug, checklist_name }) => ({
            value: slug,
            label: checklist_name,
          }))
        : [];

    this.setState({
      loading: false,
      templateList: templateList,
      templateListTotal: templateListTotal,
      newTemplateList: newTemplateList,
    });
  }

  closeModal(type) {
    if (type === "basic") {
      this.setState({ showBasicModal: false });
      this.setState({
        selectedTemplateList: [],
      });
    }

    this.setState({ showAlertModal: false });
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  importTemplate = async (e) => {
    e.preventDefault();

    if (this.validator.allValid()) {
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" +
              this.state.property_slug +
              "/checklists/import/" +
              this.state.template_slug
          ),
        global.redirect_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  checklistImportHandler(value) {
    let template_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        template_slug.push(item.value);
      });
    }
    const template_slug_value = value !== null ? value.value : [];
    this.setState({
      selectedTemplateList: value,
      template_slug: template_slug_value,
    });
  }

  getTitle() {
    return "Checklists";
  }

  rowClickedHandler = (event, data, rowIndex) => {
    global.userPermissions.checkPermission("checklists-update") &&
      this.props.history.push(
        "/properties/view/" +
          this.state.property_slug +
          "/checklists/" +
          data.slug
      );
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  {/* <PropertyViewSidebar /> */}
                  <HouseKeepingSidebar
                    property_slug={this.state.property_slug}
                  />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end pd-l-0 pd-r-0 mg-b-0">
                      {global.userPermissions.checkPermission(
                        "Checklists-add"
                      ) && (
                        <>
                          <Link
                            to={
                              "/properties/view/" +
                              this.state.property_slug +
                              "/checklists/add"
                            }
                          >
                            <button className="btn-success-outline-small mr-3">
                              <img src={pluswIcon} alt="" /> Add
                            </button>
                          </Link>
                          <button
                            className="btn-success-outline-small import-checklist-button"
                            onClick={() => {
                              this.getTemplate();
                              this.setState({ showBasicModal: true });
                            }}
                          >
                            <img src={pluswIcon} alt="" /> Add from Template
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-15 pd-lg-r-0">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pl-0 pr-0">
                      {this.state.total_record !== 0 ||
                      this.state.filter_value !== "" ? (
                        <div className="mg-t-18 brandList pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-0">
                          <div className="pd-lg-l-0 pd-xs-l-15">
                            <ReactDatatable
                              className="table mg-b-30 pd-b-2 add-no-wrap"
                              config={
                                this.state.total_record > global.page_size
                                  ? this.state.config
                                  : this.state.config
                              }
                              records={this.state.records}
                              columns={this.state.columns}
                              extraButtons={this.state.extraButtons}
                              dynamic={true}
                              // loading={this.state.loading}
                              total_record={this.state.total_record}
                              onChange={this.tableChangeHandler}
                              onRowClicked={this.rowClickedHandler}
                            />
                          </div>
                        </div>
                      ) : (
                        <NoData />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={this.state.showBasicModal}
          onHide={() => this.closeModal("basic")}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose"
              onClick={() => this.closeModal("basic")}
            >
              {global.closee}
            </span>
            <h3 className="text-center"> Add from Template</h3>
            <label>Template</label>
            <Select
              styles={customStyles}
              isClearable={true}
              className="multiselect"
              classNamePrefix={"my-custom-react-select"}
              options={this.state.newTemplateList}
              getOptionValue={(option) => `${option.label}`}
              value={this.state.selectedTemplateList}
              defaultValue={this.state.selectedTemplateList}
              onChange={(value) => this.checklistImportHandler(value)}
              theme={(theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                  ...theme.colors,
                  primary: "#fff",
                  primary75: "#000",
                  primary50: "#000",
                  primary25: "#000",
                },
              })}
            />
            {this.state.errors.template_slug ? (
              <div className="text-danger">
                {this.state.errors.template_slug}
              </div>
            ) : (
              this.validator.message(
                "template",
                this.state.template_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            <div className="d-flex justify-content-between mt-3 row">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeModal("basic")}
              >
                Cancel
              </button>
              <button
                type="button"
                className="mx-auto m-0 btn btn btn-success btn-block"
                onClick={this.importTemplate}
              >
                {this.state.isSubmit ? global.loader : "Import"}
              </button>{" "}
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default Checklist;

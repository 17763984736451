import ApiService from "./ApiService";
class UserActivity{
    getUserActivity(refId,refTableSlug) {
        let endPoint = 'get-activity-list/' + refId + '/'+refTableSlug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }
}
export default new UserActivity();
/////////////////////////////////////////////////////
//     							                               //
//  Program: PropertyAmenities.jsx                 //
//  Application: PropertyAmenities                 //
//  Option: List all Property Amenities            //
//  Developer: NP, Prakash 						             //
//  Date: 2022-02-10                               //
//                                                 //
/////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import AmenityService from "../../services/AmenityService";
import { Link } from "react-router-dom";
import { Container, Form, Accordion, Card, Button } from "react-bootstrap";
import propertyLogo from "./../../assets/images/property_placeholder.png"
import { Modal } from "react-bootstrap";
import infoBig from "../../assets/images/info-b.svg"
import forwardarrow from "../../assets/images/forward-arrow-ami.svg"
import backwardarrow from "../../assets/images/back-arrow-ami.svg"
import Alerts from "../common/Alerts"
// import editIcon from "./../../assets/images/edit-pen-icon.svg";

export class PropertyAmenities extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      viewMode: 0,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      importModal: false,
      property_slug: this.props.match.params.propertySlug,
      pathname: window.location.pathname,
      amenitiesList: [],
      amenitiesListTotal: 0,
      propertyAmenitiesList: [],
      propertyAmenitiesListTotal: 0,
      slugList: [],
      slugUncheckList: [],
      property_logo_url: sessionStorage.getItem('property_logo_url'),
      property_type: sessionStorage.getItem('property_type'),
      property_name: sessionStorage.getItem('property_name'),
      year_value: sessionStorage.getItem('year_value') ? sessionStorage.getItem('year_value') : ''
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.confirmImportModal = this.confirmImportModal.bind(this);
    this.closeConfirmImportModal = this.closeConfirmImportModal.bind(this);
    this.addAmenities = this.addAmenities.bind(this);
    this.discardAmenities = this.discardAmenities.bind(this);
    this.getAmenitiesDetails = this.getAmenitiesDetails.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleUnCheckboxChange = this.handleUnCheckboxChange.bind(this);
    this.changeView = this.changeView.bind(this);
  }

  componentDidMount() {
    this.getAmenitiesData();
    this.getPropertyAmenitiesData();
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ slugList: [] });
      this.setState({ slugUncheckList: [] });
      this.getPropertyAmenitiesData();
    } else {
      this.setState({ viewMode: 1 });
      this.getAmenitiesData();
      this.getPropertyAmenitiesData();
    }
  }

  /* to amenities data */
  async getAmenitiesData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var amenitiesList = [];
    var amenitiesListTotal = 0;

    let res = await AmenityService.getAmenity(
      "level=1&is_mapped=1",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      amenitiesList = res.data ? res.data.data : [];
      amenitiesListTotal = amenitiesList ? amenitiesList.length : 0
    }
    this.setState({ amenitiesList: amenitiesList, amenitiesListTotal: amenitiesListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to get property amenities data */
  async getPropertyAmenitiesData() {
    this.setState({ showSpinner: true, loading: true });
    var propertyAmenitiesList = [];
    var propertyAmenitiesListTotal = 0;

    let res = await AmenityService.getPropertyAmenities(
      this.state.property_slug + "/1"
    );
    if (global.successStatus.includes(res.status)) {
      propertyAmenitiesList = res.data ? res.data : [];
      propertyAmenitiesListTotal = propertyAmenitiesList.length > 0 ? propertyAmenitiesList.length : 0
    }
    this.setState({ propertyAmenitiesList: propertyAmenitiesList, propertyAmenitiesListTotal: propertyAmenitiesListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  /* to save amenities data */
  addAmenities = async (e) => {
    e.preventDefault();
    if (this.state.slugList.length > 0) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        amenitiesData: this.state.slugList
      };

      let res = await AmenityService.attachPropertyAmenities(this.state.property_slug + "/1", inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });

        var check = document.getElementsByName("one");
        for (var i = 0; i < check.length; i++) {
          document.getElementsByName("one")[i].checked = false;
        }
        this.setState({ slugList: [] });
        this.getPropertyAmenitiesData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      alert("Select atleast one amenity")
    }
  };

  /* to discard amenities data */
  discardAmenities = async (e) => {
    e.preventDefault();
    if (this.state.slugUncheckList.length > 0) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        amenitiesData: this.state.slugUncheckList
      };

      let res = await AmenityService.detachPropertyAmenities(this.state.property_slug + "/1", inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        var check = document.getElementsByName("one");
        for (var i = 0; i < check.length; i++) {
          document.getElementsByName("one")[i].checked = false;
        }
        this.setState({ slugUncheckList: [] });
        this.getPropertyAmenitiesData();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      alert("Select atleast one amenity")
    }
  };

  /* To amenities details data */
  async getAmenitiesDetails(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    if (slug) {
      var resData = {};
      let res = await AmenityService.getAmenityBySlug(slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.amenity_name = res.data.amenity_name ? res.data.amenity_name : "";
        resData.amenity_type_name = res.data.amenity_type ? res.data.amenity_type.amenity_type_name : "";
        resData.description = res.data.description ? res.data.description : "";
        resData.tag_line = res.data.tag_line ? res.data.tag_line : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, isSubmit: false });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  confirmImportModal(slug) {
    this.getAmenitiesDetails(slug);
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  }

  handleCheckboxChange = event => {
    let newArray = [...this.state.slugList, event.target.id];
    if (this.state.slugList.includes(event.target.id)) {
      newArray = newArray.filter(slug => slug !== event.target.id);
    }
    this.setState({ slugList: newArray });
  }

  handleUnCheckboxChange = event => {
    let newArray = [...this.state.slugUncheckList, event.target.id];
    if (this.state.slugUncheckList.includes(event.target.id)) {
      newArray = newArray.filter(slug => slug !== event.target.id);
    }
    this.setState({ slugUncheckList: newArray });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Common Amenities"
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          <div className="row row-xs">
            <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link to="/properties">Properties</Link>
                <span> / </span>
                <span>{this.getTitle()}</span>
              </div>
            </div>
          </div>
        </Container>

        <Container>


          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-15 pd-t-4">
              <div className="col-md-3">
                <aside>
                  <div className="topHead">
                    <div className="demo-avatar-group">
                      <div className="az-img-user avatar-md online">
                        <img src={sessionStorage.getItem('property_logo_url') ? sessionStorage.getItem('property_logo_url') : propertyLogo} assName="rounded-circle" alt="" />
                      </div>
                      <div className="propname">
                        <h5> {sessionStorage.getItem('property_name') ? sessionStorage.getItem('property_name') : "Property"} </h5>
                        <ul>
                          <li> {sessionStorage.getItem('property_type') ? sessionStorage.getItem('property_type') : ""} </li>
                          {sessionStorage.getItem('year_value') ? <li></li> : ''}
                          <li>{sessionStorage.getItem('year_value') ? "Year " + sessionStorage.getItem('year_value') : ''}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <nav className="nav nav-pills flex-column pd-r-8">
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "0")}>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Property Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "0" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse
                          eventKey="0"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/basic")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic"
                              }
                            >
                              Property
                            </Link>

                            <Link
                              className={`${this.state.pathname.match("/tower")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/tower"
                              }
                            >
                              Towers
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/floors")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floors"
                              }
                            >
                              Floors
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/amenitites")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/amenitites"
                              }
                            >
                              Common Amenities
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/pet-policy")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/pet-policy"
                              }
                            >
                              Pet Policy
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/staff")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/staff"
                              }
                            >
                              Staff
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/documents")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/documents"
                              }
                            >
                              Documents & Leases
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/property-level-tax")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/property-level-tax"
                              }
                            >
                              Taxes
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/nearby-attractions")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/nearby-attractions"
                              }
                            >
                              Near By Attractions
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/events")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/events"
                              }
                            >
                              Events
                            </Link>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                      <Card onClick={() => this.setOpen(!this.state.open, "0")} >
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="according-text"
                          >
                            <Link
                              to={"#"}
                              className="d-flex justify-content-between align-items-center w-100"
                            >
                              Units Information
                              <i className="mdi mdi-view-headline"> </i>
                              {this.state.open &&
                                this.state.eventKey === "3" ? (
                                <i className="fas fa-chevron-down"></i>
                              ) : (
                                <i className="fas fa-chevron-up"></i>
                              )}
                            </Link>
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse
                          eventKey="3"
                          className="bg-transparent shadow-none"
                        >
                          <Card.Body className="p-0 bg-none">
                            <Link
                              className={`${this.state.pathname.match("/floor-plans")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/floor-plans"
                              }
                            >
                              Floor Plans
                            </Link>
                            <Link
                              className={`${this.state.pathname.match("/units")
                                ? "nav-link active"
                                : "nav-link"
                                }`}
                              data-toggle="tab"
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/units"
                              }
                            >
                              Units
                            </Link>
                            {/* <Link
                                        className={`${
                                          this.state.pathname.match("/rooms")
                                            ? "nav-link active"
                                            : "nav-link"
                                        }`}
                                        data-toggle="tab"
                                        to={
                                          "/properties/view/" +
                                          this.state.property_slug +
                                          "/rooms"
                                        }
                                      >
                                        Rooms
                                      </Link> */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </nav>
                </aside>
              </div>
              <div className="col-md-9 ">
                <div className="propSection pd-l-15">
                  <h3>{this.getTitle()}</h3>

                  <div className="aminitesSection">
                    <div className="dashboardRightcard pd-l-6 pd-r-6">
                      <div className="col-md-12 d-flex justify-content-end pd-l-0 pd-r-0 mg-b-15">
                        {this.state.viewMode === 0 ? (
                          <button
                            className="btn-success-outline-small"
                            onClick={this.changeView}
                          > {this.state.propertyAmenitiesListTotal > 0 ?

                            "Save"
                            : "Save"}
                          </button>
                        ) : (
                          <div className="d-flex">
                            <button
                              type="button"
                              className="btn-success-outline-small"
                              onClick={this.changeView}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>
                      {/* Alert Model */}
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                      {this.state.viewMode === 0 ? (
                        <div className="m-0">
                          <div className="col-md-12 pl-0 pr-0"></div>
                          <h3>What this place offers</h3>
                          <ul className="attraction">
                            {this.state.propertyAmenitiesListTotal > 0 ? (
                              this.state.propertyAmenitiesList.map((item, i) => {
                                return (
                                  <li className="m-2" key={i}>
                                    {item.amenity_name ? item.amenity_name : ""}
                                  </li>
                                );
                              })
                            ) : (
                              <h6 className="mg-t-20">There are no amenities added in the system. You may add them by clicking Add button.</h6>
                            )}
                          </ul>
                        </div>
                      )
                        :
                        <div className="col-md-12">
                          <div className="row m-0 w-100">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0">
                              {/* <hr className="mg-t-0 mg-b-15 pd-b-1"/> */}
                              <h5>All Available Amenities</h5>
                              <hr className="mg-b-0 mg-t-15 pd-t-0" />
                              <div className="left">
                                <ul>
                                  <li>
                                    {(this.state.amenitiesListTotal) > 0 && this.state.amenitiesListTotal !== this.state.propertyAmenitiesListTotal ?
                                      this.state.amenitiesList
                                        .filter((option) => {
                                          const val1 =
                                            this.state.propertyAmenitiesList.find(
                                              (opt) => {
                                                return (
                                                  opt.slug === option.slug
                                                );
                                              }
                                            );
                                          if (val1) return false;
                                          else return true;
                                        })
                                        .map((item, i) => {
                                          return (
                                            <div className="selectcard" key={i}>
                                              <img src={infoBig} onClick={() => this.confirmImportModal(item.slug)} />
                                              <Form.Check
                                                name="one"
                                                type={'checkbox'}
                                                id={item.slug}
                                                label={item.amenity_name ? item.amenity_name : ''}
                                                className={'ckbox'}
                                                onChange={this.handleCheckboxChange}
                                              />
                                              <span>{item.amenity_type ? item.amenity_type.amenity_type_name : ''}</span>
                                            </div>
                                          );
                                        }) : <h6 className="mg-t-20">There are no amenities added in the system.</h6>}
                                  </li>
                                </ul>
                                {this.state.amenitiesList.length > 0 ?
                                  <div className="move-btn">
                                    <button className="green-btn" onClick={this.addAmenities} disabled={!this.state.slugList.length > 0 ? true : false}>
                                      <img src={forwardarrow} />
                                    </button>
                                    <button className="basic-btn" onClick={this.discardAmenities} disabled={!this.state.slugUncheckList.length > 0 ? true : false}>
                                      <img src={backwardarrow} />
                                    </button>
                                  </div> : ''}
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0">
                              {/* <hr className="mg-t-0 mg-b-15 pd-b-1"/> */}
                              <h5>Selected Amenities</h5>
                              <hr className="mg-b-0 mg-t-15 pd-t-0" />
                              <div className="right">
                                <ul>
                                  <li>
                                    {this.state.propertyAmenitiesListTotal > 0 ?
                                      this.state.propertyAmenitiesList
                                        .map((item, i) => {
                                          return (
                                            <div className="selectcard" key={i}>
                                              <img src={infoBig} onClick={() => this.confirmImportModal(item.slug)} />
                                              <Form.Check
                                                name="one"
                                                type={'checkbox'}
                                                id={item.slug}
                                                label={item.amenity_name ? item.amenity_name : ''}
                                                className={'ckbox'}
                                                onChange={this.handleUnCheckboxChange}
                                              />
                                              <span>{item.amenity_type ? item.amenity_type.amenity_type_name : ''}</span>
                                            </div>
                                          );
                                        }
                                        ) : <h6 className="mg-t-20">There are no amenities added in the system. You may add them by clicking Add button.</h6>}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>}
                    </div>

                  </div>
                </div>
              </div>


            </div>
            <div className="ht-50"></div>
          </div>
        </Container>

        {/* amenitites view modal */}
        <Modal show={this.state.importModal} onHide={() => this.closeConfirmImportModal()} centered className="infomodal">
          <Modal.Body className="p-4 border_radius_14 modal-lg">
            <span
              className="welcomeClose"
              onClick={() => this.closeConfirmImportModal()}
            >
              {global.closee}
            </span>
            <h3 className="text-left pd-l-15 pd-r-15">Amenity Details</h3>
            <div className="row col-md-12 mg-t-40 mg-md-t-0">
              <div className='col-md-6'>
                <p className="az-profile-name-text pl-0"> Name:</p>
                <p className="media-body">{this.state.amenity_name ? this.state.amenity_name : ''}</p>
              </div>
              <div className='col-md-6'>
                <p className="az-profile-name-text pl-0">Tagline:</p>
                <p className="media-body">{this.state.tag_line ? this.state.tag_line : ''}</p>
              </div>

              <div className='col-md-6'>
                <p className="az-profile-name-text pl-0">Category:</p>
                <p className="media-body">{this.state.amenity_type_name ? this.state.amenity_type_name : ''}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

      </main>
    );
  }
}

export default PropertyAmenities;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PropertiesForm.jsx                           //
//  Application:  Properties                              //
//  Option: For add or update Properties                  //
//  Developer: NP                                         //
//  Date: 2022-01-24                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Tabs, Tab, Form ,InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import CountryService from "../../services/CountryService";
import CountyService from "../../services/CountyService";
import StateService from "../../services/StateService";
import PropertyTypeService from "../../services/PropertyTypeService";
import TimezoneService from "../../services/TimezoneService";
import MonthService from "../../services/MonthService";
import MetroService from "../../services/MetroService";
import SubMetroService from "../../services/SubMetroService";
import PropertyService from "../../services/PropertyService";
import BrandService from "../../services/BrandService";
import PropertySuitabilityService from "../../services/PropertySuitabilityService";
import ParkingTypeService from "../../services/ParkingTypeService";
import LocationTypeService from "../../services/LocationTypeService";
import OwnershipTypeService from "../../services/OwnershipTypeService";
import CommServedService from "../../services/CommServedService";
import SmokingTypeService from "../../services/SmokingTypeService";
import LeedBuildingService from "../../services/LeedBuildingService";
import AffordableTypeService from "../../services/AffordableTypeService";
import WifiProviderService from "../../services/WifiProviderService";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import Alerts from "../common/Alerts";
import infosize from "../../assets/images/Info.svg";
import FileWidget from "../fileUploader/FileWidget";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import DatePicker from "react-datepicker";
import Moment from "moment";
import CityService from "../../services/CityService";
import PropertyVendors from "../property/PropertyVendors";
import PropertyWiFiInfo from "../property/PropertyWiFiInfo";
import PropertyOwner from "../property/PropertyOwner";
import Select from "react-select";
import MultiImageService from "./../../services/MultiImageService";
import propertyImg from "./../../assets/images/property_placeholder.png";
import PhoneInput from "react-phone-input-2";

export class PropertiesForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      deletedFileList: [],
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      unitTypeData: [],
      unitTypeDataTotal: 0,
      countryList: [],
      countyList: [],
      stateList: [],
      cityList: [],
      propertyList: [],
      timeZoneList: [],
      brandList: [],
      suitabilityList: [],
      suitabilityListTotal: 0,
      locationTypeListTotal: 0,
      commServedListTotal: 0,
      parkingTypeList: [],
      monthList: [],
      metroList: [],
      subMetroList: [],
      locationTypeList: [],
      ownershipTypeList: [],
      commServedList: [],
      smokingTypeList: [],
      leedBuildingList: [],
      affordableTypeList: [],

      wifiProviderList: [],
      officeHoursList: [],
      imgPropUrl: propertyImg,
      errors: {},
      slug: this.props.match.params.slug,
      property_slug: this.props.match.params.slug,
      view: this.props.match.params.view
        ? this.props.match.params.view
        : "Basic",
      isSubmit: false,
      property_logo: "",
      property_main_image: "",
      imageUrl: propertyLogo,
      status_id: true,
      sort_order: "",
      // checkbox
      observer_day_ligth_saving: false,
      international_property: false,
      // full_listing: false,
      insurance_required: false,
      affordable_financed: false,
      //radio
      ada_complaint: 2,
      //multi select
      suitability_id: [],
      newSuitabilityList: [],
      selectedSuitabilityList: [],
      location_type_id: [],
      newLocationTypeList: [],
      selectedLocationTypeList: [],
      communities_served_id: [],
      newCommServedList: [],
      selectedCommServedList: [],
      affordable_type_id: [],
      //global day
      dayList: global.dayList,
      property_office_data1: [],
      property_office_data: [
        {
          day: 1,
          label: "Monday",
          start_time: "00:00:00",
          end_time: "00:00:00",
          closed: 0,
        },
        {
          day: 2,
          label: "Tuesday",
          start_time: "00:00:00",
          end_time: "00:00:00",
          closed: 0,
        },
        {
          day: 3,
          label: "Wednesday",
          start_time: "00:00:00",
          end_time: "00:00:00",
          closed: 0,
        },
        {
          day: 4,
          label: "Thursday",
          start_time: "00:00:00",
          end_time: "00:00:00",
          closed: 0,
        },
        {
          day: 5,
          label: "Friday",
          start_time: "00:00:00",
          end_time: "00:00:00",
          closed: 0,
        },
        {
          day: 6,
          label: "Saturday",
          start_time: "00:00:00",
          end_time: "00:00:00",
          closed: 1,
        },
        {
          day: 7,
          label: "Sunday",
          start_time: "00:00:00",
          end_time: "00:00:00",
          closed: 1,
        },
      ],
    };
    this.propertyLogo = React.createRef();
    this.propertyImg = React.createRef();
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateProperty = this.saveOrUpdateProperty.bind(this);
    this.saveOrImage = this.saveOrImage.bind(this);
    this.saveOrUpdateAffordableComponent =
      this.saveOrUpdateAffordableComponent.bind(this);
    this.getPropertyOfficeHoursData =
      this.getPropertyOfficeHoursData.bind(this);
    this.onLogoImageChange = this.onLogoImageChange.bind(this);
    this.onMainImageChange = this.onMainImageChange.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);

  }

  async componentDidMount() {
    if (this.state.view === "basic") {
      document
        .getElementById("uncontrolled-tab-example-tab-Basic Information")
        .click();
    } else if (this.state.view === "location") {
      document
        .getElementById("uncontrolled-tab-example-tab-Location Information")
        .click();
    } else if (this.state.view === "contact") {
      document
        .getElementById("uncontrolled-tab-example-tab-Contact Information")
        .click();
    } else if (this.state.view === "property-attributes") {
      document
        .getElementById("uncontrolled-tab-example-tab-Property Attributes")
        .click();
    } else if (this.state.view === "property-affordbale") {
      document
        .getElementById("uncontrolled-tab-example-tab-Affordable Component")
        .click();
    } else if (this.state.view === "property-owners") {
      document
        .getElementById("uncontrolled-tab-example-tab-Owner Information")
        .click();
    } else if (this.state.view === "property-office-hours") {
      document
        .getElementById("uncontrolled-tab-example-tab-Office Hours")
        .click();
    } else if (this.state.view === "property-wifi") {
      document
        .getElementById("uncontrolled-tab-example-tab-Wi-Fi Information")
        .click();
    } else if (this.state.view === "property-images") {
      document
        .getElementById("uncontrolled-tab-example-tab-Property Images")
        .click();
    } else if (this.state.view === "property-vendors") {
      document
        .getElementById("uncontrolled-tab-example-tab-Third Party Vendors")
        .click();
    } else {
      document
        .getElementById("uncontrolled-tab-example-tab-Basic Information")
        .click();
    }
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      //api call in only update form
      this.getCountyData();
      this.getStateData();
      this.getCityData();
      this.getSubMetroData();
      var resData = {};
      var selectedSuitabilityList = this.state.selectedSuitabilityList;
      var selectedLocationTypeList = this.state.selectedLocationTypeList;
      var selectedCommServedList = this.state.selectedCommServedList;
      let res = await PropertyService.getProperty(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        //select list values get
        resData.property_type_id = res.data.property_type
          ? res.data.property_type.slug
          : "";
        resData.timezone_id = res.data.timezone ? res.data.timezone.slug : "";
        resData.brand_id = res.data.brand ? res.data.brand.slug : "";
        resData.suitability_id = res.data.suitabilityData
          ? res.data.suitabilityData.forEach((item, i) => {
              selectedSuitabilityList = [
                ...selectedSuitabilityList,
                {
                  value: item.slug,
                  label: item.suitability_name,
                },
              ];
            })
          : "";

        resData.parking_type_id = res.data.parking_type
          ? res.data.parking_type.slug
          : "";
        resData.country_id = res.data.country ? res.data.country.slug : "";
        resData.county_id = res.data.county ? res.data.county.slug : "";
        resData.state_id = res.data.state ? res.data.state.slug : "";
        resData.city_id = res.data.city ? res.data.city.slug : "";
        resData.metro_id = res.data.metro ? res.data.metro.slug : "";
        resData.sub_metro_id = res.data.sub_metro
          ? res.data.sub_metro.slug
          : "";
        resData.year_value = res.data.year_value ? res.data.year_value : "";
        // resData.location_type_id = res.data.location_type
        //   ? res.data.location_type.slug
        //   : "";
        resData.location_type_id = res.data.LocationData
          ? res.data.LocationData.forEach((item, i) => {
              selectedLocationTypeList = [
                ...selectedLocationTypeList,
                {
                  value: item.slug,
                  label: item.location_type_name,
                },
              ];
            })
          : "";
        resData.ownership_type_id = res.data.ownership_type
          ? res.data.ownership_type.slug
          : "";
        resData.third_party_concierge_id = res.data.third_party_concierge
          ? res.data.third_party_concierge.slug
          : "";
        resData.third_party_maintenance_id = res.data.third_party_maintenance
          ? res.data.third_party_maintenance.slug
          : "";
        resData.third_party_leasing_id = res.data.third_party_leasing
          ? res.data.third_party_leasing.slug
          : "";
        resData.month_id = res.data.month ? res.data.month.fiscal_month_no : "";
        resData.branding_agency_id = res.data.branding_agency
          ? res.data.branding_agency.slug
          : "";
        resData.marketing_agency_id = res.data.marketing_agency
          ? res.data.marketing_agency.slug
          : "";
        resData.ad_agency_id = res.data.ad_agency
          ? res.data.ad_agency.slug
          : "";
        // resData.communities_served_id = res.data.communities_served
        //   ? res.data.communities_served.slug
        //   : "";
        resData.communities_served_id = res.data.communitiesServedData
          ? res.data.communitiesServedData.forEach((item, i) => {
              selectedCommServedList = [
                ...selectedCommServedList,
                {
                  value: item.slug,
                  label: item.communities_served_name,
                },
              ];
            })
          : "";
        resData.smoking_type_id = res.data.smoking_type
          ? res.data.smoking_type.slug
          : "";
        resData.leed_building_id = res.data.leed_building
          ? res.data.leed_building.slug
          : "";
        // resData.affordable_type_id = res.data.affordable_type
        //   ? res.data.affordable_type.slug
        //   : "";
        // resData.affordable_type_id = res.data.affordable_type
        //   ? res.data.affordable_type.forEach((item, i) => {
        //       selectedAffordableTypeList = [
        //         ...selectedAffordableTypeList,
        //         {
        //           value: item.slug,
        //           label: item.affordable_type_name,
        //         },
        //       ];
        //     })
        //   : "";
        resData.day = res.data.day ? res.data.day : "";
        resData.wi_fi_provider_id = res.data.wi_fi_provider
          ? res.data.wi_fi_provider.slug
          : "";

        resData.zipcode = res.data.zip_code ? res.data.zip_code : "";
        resData.property_logo = res.data.property_logo
          ? res.data.property_logo
          : "";
        resData.start_time = res.data.start_time1
          ? Moment(res.data.start_time).format("HH:mm")
          : "";
        resData.end_time = res.data.end_time1
          ? Moment(res.data.end_time).format("HH:mm")
          : "";
        resData.imageUrl = res.data.property_logo_url
          ? res.data.property_logo_url
          : propertyLogo;
        resData.imgPropUrl = res.data.property_main_image_url
          ? res.data.property_main_image_url
          : propertyImg;
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
      this.setState({
        selectedSuitabilityList: selectedSuitabilityList,
        selectedLocationTypeList: selectedLocationTypeList,
        selectedCommServedList: selectedCommServedList,
      });
      this.defaultData = resData;
    }
    this.setState({ showSpinner: false, loading: false });
    //api call in add/update both form
    this.getCountryData();
    this.getPropertyData();
    this.getTimezoneData();
    this.getBrandData();
    this.getSuitabilityData();
    this.getParkingTypeData();
    this.getFiscalMonthData();
    this.getMetroData();
    this.getLocationTypeData();
    this.getOwnershipTypeData();
    this.getCommServedData();
    this.getSmokingTypeData();
    this.getLeedBuildingData();
    this.getAffordableTypeData();
    this.getWiFiProviderData();
    this.getPropertyAffordable();
    this.getPropertyOfficeHoursData();
  }

  onMainImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "property_main_image",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await PropertyService.uploadPropertyMainImg(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          property_main_image: res.data.fileName,
          imgPropUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  onLogoImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "property_logo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await PropertyService.uploadPropertyLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          property_logo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  async getCountryData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countryList = [];

    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
    }
    this.setState({ countryList: countryList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCountyData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var countyList = [];

    let res = await CountyService.getCounty(
      "is_dropdown=1&country_slug=" +
        value +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countyList = res.data ? res.data : [];
    }
    this.setState({ countyList: countyList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getStateData(countryValue, countyValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var stateList = [];

    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
        countryValue +
        "&county_slug=" +
        countyValue +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    this.setState({ stateList: stateList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCityData(stateValue, countyValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];

    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
    }
    this.setState({
      cityList: cityList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getPropertyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var propertyList = [];

    let res = await PropertyTypeService.getPropertyType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    this.setState({ propertyList: propertyList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getTimezoneData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var timeZoneList = [];

    let res = await TimezoneService.getTimezone(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      timeZoneList = res.data ? res.data : [];
    }
    this.setState({ timeZoneList: timeZoneList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getFiscalMonthData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var monthList = [];

    let res = await MonthService.getMonths(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      monthList = res.data ? res.data : [];
    }
    this.setState({ monthList: monthList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getMetroData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var metroList = [];

    let res = await MetroService.getMetro(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      metroList = res.data ? res.data : [];
    }
    this.setState({ metroList: metroList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSubMetroData(value, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var subMetroList = [];

    let res = await SubMetroService.getSubMetro(
      "is_dropdown=1&metro_slug=" +
        value +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      subMetroList = res.data ? res.data : [];
    }
    this.setState({ subMetroList: subMetroList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getBrandData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var brandList = [];

    let res = await BrandService.getBrandList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      brandList = res.data ? res.data : [];
    }
    this.setState({ brandList: brandList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSuitabilityData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var suitabilityList = [];
    var suitabilityListTotal = 0;
    let res = await PropertySuitabilityService.getPropertySuitability(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      suitabilityList = res.data ? res.data : [];
      suitabilityListTotal = suitabilityList ? suitabilityList.length : 0;
    }
    const newSuitabilityList =
      suitabilityListTotal > 0
        ? suitabilityList.map(({ slug, suitability_name }) => ({
            value: slug,
            label: suitability_name,
          }))
        : [];
    this.setState({
      suitabilityList: suitabilityList,
      newSuitabilityList: newSuitabilityList,
      suitabilityListTotal: suitabilityListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getParkingTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var parkingTypeList = [];

    let res = await ParkingTypeService.getParkingType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      parkingTypeList = res.data ? res.data : [];
    }
    this.setState({ parkingTypeList: parkingTypeList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getLocationTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var locationTypeList = [];
    var locationTypeListTotal = 0;
    let res = await LocationTypeService.getLocationType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationTypeList = res.data ? res.data : [];
      locationTypeListTotal = locationTypeList ? locationTypeList.length : 0;
    }
    const newLocationTypeList =
      locationTypeListTotal > 0
        ? locationTypeList.map(({ slug, location_type_name }) => ({
            value: slug,
            label: location_type_name,
          }))
        : [];
    this.setState({
      locationTypeList: locationTypeList,
      newLocationTypeList: newLocationTypeList,
      locationTypeListTotal: locationTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getOwnershipTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var ownershipTypeList = [];

    let res = await OwnershipTypeService.getOwnership(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      ownershipTypeList = res.data ? res.data : [];
    }
    this.setState({ ownershipTypeList: ownershipTypeList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCommServedData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var commServedList = [];
    var commServedListTotal = 0;
    let res = await CommServedService.getCommServed(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      commServedList = res.data ? res.data : [];
      commServedListTotal = commServedList ? commServedList.length : 0;
    }
    const newCommServedList =
      commServedListTotal > 0
        ? commServedList.map(({ slug, communities_served_name }) => ({
            value: slug,
            label: communities_served_name,
          }))
        : [];
    this.setState({
      commServedList: commServedList,
      newCommServedList: newCommServedList,
      commServedListTotal: commServedListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSmokingTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var smokingTypeList = [];

    let res = await SmokingTypeService.getSmokingType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      smokingTypeList = res.data ? res.data : [];
    }
    this.setState({ smokingTypeList: smokingTypeList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getLeedBuildingData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var leedBuildingList = [];

    let res = await LeedBuildingService.getLeedBuilding(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      leedBuildingList = res.data ? res.data : [];
    }
    this.setState({ leedBuildingList: leedBuildingList });
    this.setState({ showSpinner: false, loading: false });
  }

  async getAffordableTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var affordableTypeList = [];

    let res = await AffordableTypeService.getAffordableType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      affordableTypeList = res.data ? res.data : [];
    }
    this.setState({
      affordableTypeList: affordableTypeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getWiFiProviderData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var wifiProviderList = [];

    let res = await WifiProviderService.getWifiProvider(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      wifiProviderList = res.data ? res.data : [];
    }
    this.setState({ wifiProviderList: wifiProviderList });
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateProperty = async (e) => {
    e.preventDefault();
    // if (this.state.status_id === true ? this.validator.allValid() && this.customValidate() : !this.validator.errorMessages.property_name) {
    if (!this.validator.errorMessages.property_name) {
      this.setState({ showSpinner: true, isSubmit: true });

      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun("register_from");

      let multiSuitabilityString = this.state.suitability_id
        ? this.state.suitability_id.join(",")
        : "";
      let multiLocationTypeString = this.state.location_type_id
        ? this.state.location_type_id.join(",")
        : "";
      let multiCommServedString = this.state.communities_served_id
        ? this.state.communities_served_id.join(",")
        : "";
      let multiAffordableTypeString = this.state.affordable_type_id
        ? this.state.affordable_type_id.join(",")
        : "";

      let inputData = {
        //first tab data
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        legal_entity_name: this.state.legal_entity_name,
        tax_id: this.state.tax_id,
        property_type_slug: this.state.property_type_id,
        description: this.state.description,
        property_rules: this.state.property_rules,
        year_value: this.state.year_value,
        timezone_slug: this.state.timezone_id,
        observer_day_ligth_saving:
          this.state.observer_day_ligth_saving === true ? 1 : 2,
        international_property:
          this.state.international_property === true ? 1 : 2,
        brand_slug: this.state.brand_id,
        // full_listing: this.state.full_listing === true ? 1 : 2,
        // home_page_title: this.state.home_page_title,
        suitability_slug: multiSuitabilityString,
        parking_type_slug: this.state.parking_type_id,
        street_1: this.state.street_1,
        street_2: this.state.street_2,
        city_slug: this.state.city_id,
        zip_code: this.state.zipcode,
        country_slug: this.state.country_id,
        county_slug: this.state.county_id,
        state_slug: this.state.state_id,
        metro_slug: this.state.metro_id,
        sub_metro_slug: this.state.sub_metro_id,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        google_map_link: this.state.google_map_link,
        location_type_slug: multiLocationTypeString,
        ada_complaint: this.state.ada_complaint,
        // location_aka_name: this.state.location_aka_name,
        property_phone_number: this.state.property_phone_number,
        dial_code : this.state.dial_code,
        listing_phone: this.state.listing_phone,
        property_email: this.state.property_email,
        invoice_email: this.state.invoice_email,
        property_distribution_email: this.state.property_distribution_email,
        maintenance_email: this.state.maintenance_email,
        property_website_link: this.state.property_website_link,
        ownership_type_slug: this.state.ownership_type_id,
        third_party_concierge_slug: this.state.third_party_concierge_id,
        third_party_maintenance_slug: this.state.third_party_maintenance_id,
        third_party_leasing_slug: this.state.third_party_leasing_id,
        month_id: this.state.month_id,
        branding_agency_slug: this.state.branding_agency_id,
        marketing_agency_slug: this.state.marketing_agency_id,
        ad_agency_slug: this.state.ad_agency_id,
        communities_served_slug: multiCommServedString,
        smoking_type_slug: this.state.smoking_type_id,
        leed_building_slug: this.state.leed_building_id,
        insurance_required: this.state.insurance_required === true ? 1 : 2,
        affordable_financed: this.state.affordable_financed === true ? 1 : 2,
        total_affordable_units: this.state.total_affordable_units,
        affordable_percent_complete: this.state.affordable_percent_complete,
        affordable_program_income_tiers:
          this.state.affordable_program_income_tiers,
        affordable_type_slug: multiAffordableTypeString,
        unit_number_per_affordable_type:
          this.state.unit_number_per_affordable_type,
        majority_owner_name: this.state.majority_owner_name,
        minority_owner_name_1: this.state.minority_owner_name_1,
        minority_owner_name_2: this.state.minority_owner_name_2,
        minority_owner_name_3: this.state.minority_owner_name_3,
        minority_owner_name_4: this.state.minority_owner_name_4,
        asset_managers: this.state.asset_managers,
        day: this.state.day,
        start_time: this.state.start_time
          ? Moment(this.state.start_time).format("HH:mm")
          : "",
        end_time: this.state.end_time
          ? Moment(this.state.end_time).format("HH:mm")
          : "",
        wi_fi_provider_slug: this.state.wi_fi_provider_id,
        username: this.state.username,
        password: this.state.password,
        status_id: this.state.status_id === true ? 1 : 2,
        sort_order: this.state.sort_order,
        availableFiles: output.response,
        deletedFiles: this.state.deletedFileList,
      };

      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        // inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await PropertyService.updateProperty(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () =>
              this.props.history.push("/properties/view/" + this.state.slug),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
            //change tab logic
            if (
              this.state.errors.property_name ||
              this.state.errors.aka_name ||
              this.state.errors.legal_entity_name ||
              this.state.errors.tax_id ||
              this.state.errors.property_type_id ||
              this.state.errors.description ||
              this.state.errors.property_rules ||
              this.state.errors.year_value ||
              this.state.errors.timezone_id ||
              this.state.errors.brand_id ||
              // this.state.errors.home_page_title ||
              this.state.errors.suitability_id ||
              this.state.errors.parking_type_id ||
              this.state.errors.sort_order
            ) {
              // jump to tab 1
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Basic Information"
                )
                .click();
            } else if (
              this.state.errors.street_1 ||
              this.state.errors.street_2 ||
              this.state.errors.city ||
              this.state.errors.zipcode ||
              this.state.errors.country_id ||
              this.state.errors.county_id ||
              this.state.errors.state_id ||
              this.state.errors.metro_id ||
              this.state.errors.sub_metro_id ||
              this.state.errors.latitude ||
              this.state.errors.longitude ||
              this.state.errors.google_map_link ||
              this.state.errors.location_type_id
              // this.state.errors.location_aka_name
            ) {
              // jmmp to tab 2
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Location Information"
                )
                .click();
            } else if (
              this.state.errors.property_phone_number ||
              this.state.errors.listing_phone ||
              this.state.errors.invoice_email ||
              this.state.errors.property_distribution_email ||
              this.state.errors.maintenance_email ||
              this.state.errors.property_website_link
            ) {
              // jump to tab 3
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Contact Information"
                )
                .click();
            } else if (
              this.state.errors.ownership_type_id ||
              this.state.errors.third_party_concierge_id ||
              this.state.errors.third_party_maintenance_id ||
              this.state.errors.third_party_leasing_id ||
              this.state.errors.month_id ||
              this.state.errors.branding_agency_id ||
              this.state.errors.marketing_agency_id ||
              this.state.errors.ad_agency_id ||
              this.state.errors.communities_served_id ||
              this.state.errors.smoking_type_id ||
              this.state.errors.leed_building_id
            ) {
              // jump to tab 4
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Property Attributes"
                )
                .click();
            } else if (
              this.state.errors.total_affordable_units ||
              this.state.errors.affordable_percent_complete ||
              this.state.errors.affordable_program_income_tiers ||
              this.state.errors.affordable_type_id ||
              this.state.errors.unit_number_per_affordable_type
            ) {
              // jump to tab 5
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Affordable Component"
                )
                .click();
            } else if (
              this.state.errors.majority_owner_name ||
              this.state.errors.minority_owner_name_1 ||
              this.state.errors.minority_owner_name_2 ||
              this.state.errors.minority_owner_name_3 ||
              this.state.errors.minority_owner_name_4 ||
              this.state.errors.asset_managers
            ) {
              // jump to tab 6
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Owner Information"
                )
                .click();
            } else if (
              this.state.errors.day ||
              this.state.errors.start_time ||
              this.state.errors.end_time
            ) {
              // jump to tab 6
              document
                .getElementById("uncontrolled-tab-example-tab-Office Hours")
                .click();
            } else if (
              this.state.errors.wi_fi_provider_id ||
              this.state.errors.username ||
              this.state.errors.password
            ) {
              // jump to tab 6
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Wi-Fi Information"
                )
                .click();
            }
            //jump to images tab
            else {
              // jump to tab 1
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Basic Information"
                )
                .click();
            }
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await PropertyService.createProperty(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/properties"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
            //change tab logic
            if (
              this.state.errors.property_name ||
              this.state.errors.aka_name ||
              this.state.errors.legal_entity_name ||
              this.state.errors.tax_id ||
              this.state.errors.property_type_id ||
              this.state.errors.description ||
              this.state.errors.property_rules ||
              this.state.errors.year_value ||
              this.state.errors.timezone_id ||
              this.state.errors.brand_id ||
              // this.state.errors.home_page_title ||
              this.state.errors.suitability_id ||
              this.state.errors.parking_type_id ||
              this.state.errors.sort_order
            ) {
              // jump to tab 1
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Basic Information"
                )
                .click();
            } else if (
              this.state.errors.street_1 ||
              this.state.errors.street_2 ||
              this.state.errors.city ||
              this.state.errors.zipcode ||
              this.state.errors.country_id ||
              this.state.errors.county_id ||
              this.state.errors.state_id ||
              this.state.errors.metro_id ||
              this.state.errors.sub_metro_id ||
              this.state.errors.latitude ||
              this.state.errors.longitude ||
              this.state.errors.google_map_link ||
              this.state.errors.location_type_id
              // this.state.errors.location_aka_name
            ) {
              // jmmp to tab 2
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Location Information"
                )
                .click();
            } else if (
              this.state.errors.property_phone_number ||
              this.state.errors.listing_phone ||
              this.state.errors.invoice_email ||
              this.state.errors.property_distribution_email ||
              this.state.errors.maintenance_email ||
              this.state.errors.property_website_link
            ) {
              // jump to tab 3
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Contact Information"
                )
                .click();
            } else if (
              this.state.errors.ownership_type_id ||
              this.state.errors.third_party_concierge_id ||
              this.state.errors.third_party_maintenance_id ||
              this.state.errors.third_party_leasing_id ||
              this.state.errors.month_id ||
              this.state.errors.branding_agency_id ||
              this.state.errors.marketing_agency_id ||
              this.state.errors.ad_agency_id ||
              this.state.errors.communities_served_id ||
              this.state.errors.smoking_type_id ||
              this.state.errors.leed_building_id
            ) {
              // jump to tab 4
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Property Attributes"
                )
                .click();
            } else if (
              this.state.errors.total_affordable_units ||
              this.state.errors.affordable_percent_complete ||
              this.state.errors.affordable_program_income_tiers ||
              this.state.errors.affordable_type_id ||
              this.state.errors.unit_number_per_affordable_type
            ) {
              // jump to tab 5
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Affordable Component"
                )
                .click();
            } else if (
              this.state.errors.majority_owner_name ||
              this.state.errors.minority_owner_name_1 ||
              this.state.errors.minority_owner_name_2 ||
              this.state.errors.minority_owner_name_3 ||
              this.state.errors.minority_owner_name_4 ||
              this.state.errors.asset_managers
            ) {
              // jump to tab 6
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Owner Information"
                )
                .click();
            } else if (
              this.state.errors.day ||
              this.state.errors.start_time ||
              this.state.errors.end_time
            ) {
              // jump to tab 6
              document
                .getElementById("uncontrolled-tab-example-tab-Office Hours")
                .click();
            } else if (
              this.state.errors.wi_fi_provider_id ||
              this.state.errors.username ||
              this.state.errors.password
            ) {
              // jump to tab 6
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Wi-Fi Information"
                )
                .click();
            }
            //jump to images tab
            else {
              // jump to tab 1
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Basic Information"
                )
                .click();
            }
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessageFor("property_name");
    }
    // } else {
    //   if(this.state.status_id !== true){
    //     this.validator.showMessageFor('property_name');
    //   }else{
    //     this.validator.showMessages();
    //     //change tab logic
    //     if (
    //       this.validator.errorMessages.property_name ||
    //       this.validator.errorMessages.aka_name ||
    //       this.validator.errorMessages.legal_entity_name ||
    //       this.validator.errorMessages.tax_id ||
    //       this.validator.errorMessages.property_type ||
    //       this.validator.errorMessages.brand
    //     ) {
    //       // jump to tab 1
    //       document
    //         .getElementById(
    //           "uncontrolled-tab-example-tab-Basic Information"
    //         )
    //         .click();
    //     } else if (
    //       this.validator.errorMessages.street_1 ||
    //       this.validator.errorMessages.street_2 ||
    //       this.validator.errorMessages.city ||
    //       this.validator.errorMessages.zipcode ||
    //       this.validator.errorMessages.country ||
    //       this.validator.errorMessages.county ||
    //       this.validator.errorMessages.state ||
    //       this.validator.errorMessages.location_aka_name
    //     ) {
    //       // jmmp to tab 2
    //       document
    //         .getElementById(
    //           "uncontrolled-tab-example-tab-Location Information"
    //         )
    //         .click();
    //     } else if (
    //       this.validator.errorMessages.ownership_type
    //     ) {
    //       // jump to tab 4
    //       document
    //         .getElementById(
    //           "uncontrolled-tab-example-tab-Property Attributes"
    //         )
    //         .click();
    //     }else if (
    //       this.validator.errorMessages.majority_owner_name
    //     ) {
    //       // jump to tab 6
    //       document
    //         .getElementById(
    //           "uncontrolled-tab-example-tab-Owner Information"
    //         )
    //         .click();
    //     } else {
    //       // jump to tab 1
    //       document
    //         .getElementById(
    //           "uncontrolled-tab-example-tab-Basic Information"
    //         )
    //         .click();
    //     }
    //   }
    // }
  };

  // get office hours
  async getPropertyOfficeHoursData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      //api call in only update form
      var ohList = [];
      let res = await PropertyService.getPropertyOfficeHours(this.state.slug);

      if (global.successStatus.includes(res.status)) {
        ohList = res.data ? res.data : [];
      }
      if (ohList.length > 0) {
        this.setState({ property_office_data: ohList });
      }
    }
    this.setState({ showSpinner: false, loading: false });
    //api call in add/update both form
  }

  saveOrUpdateOfficeHours = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.property_office_data &&
      !this.validator.errorMessages.property_slug
    ) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        //office hours tab data
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        property_office_data: this.state.property_office_data
          ? this.state.property_office_data
          : {},
      };

      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        // inputData.status_id = this.state.status_id ? this.state.status_id : 2;

        let res = await PropertyService.updatePropertyOfficeHours(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getPropertyOfficeHoursData();
          // setTimeout(
          //   () =>
          //     this.props.history.push("/properties/view/" + this.state.slug),
          //   global.redirect_time
          // );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
            //change tab logic
            if (
              this.state.errors.day ||
              this.state.errors.start_time ||
              this.state.errors.end_time
            ) {
              // jump to tab 6
              document
                .getElementById("uncontrolled-tab-example-tab-Office Hours")
                .click();
            } else {
              // jump to tab 1
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Basic Information"
                )
                .click();
            }
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await PropertyService.createPropertyOfficeHours(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          // setTimeout(
          //   () => this.props.history.push("/properties"),
          //   global.redirect_time
          // );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
            //change tab logic
            if (
              this.state.errors.day ||
              this.state.errors.start_time ||
              this.state.errors.end_time
            ) {
              // jump to tab 6
              document
                .getElementById("uncontrolled-tab-example-tab-Office Hours")
                .click();
            } else {
              // jump to tab 1
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Basic Information"
                )
                .click();
            }
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessageFor("property_name");
    }
  };

  saveOrImage = async (e) => {
    e.preventDefault();
    // if (this.validator.allValid() && this.customValidate()) {
    this.setState({ showSpinner: true, isSubmit: true });
    const FileWidgetObj = new FileWidget();
    const output = await FileWidgetObj.fileUploadFun("register_from");
    let inputData = {
      availableFiles: output.response,
      deletedFiles: this.state.deletedFileList,
    };

    if (this.state.slug !== undefined) {
      let res = await MultiImageService.submitMultiImage(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        // setTimeout(
        //   () =>
        //     this.props.history.push("/properties/view/" + this.state.slug),
        //   global.redirect_time
        // );
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
    // } else {
    //   this.validator.showMessages();
    // }
  };

  onChangePhone(value, country, e, formattedValue){
    //console.log(value, country, e, formattedValue);
    this.setState({property_phone_number:value});
    this.setState({dial_code:country.dialCode});
}

  saveOrUpdatePropertyImages = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.property_logo &&
      !this.validator.errorMessages.property_main_image
    ) {
      this.setState({ showSpinner: true, isSubmit: true });

      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun("register_from");

      let inputData = {
        //images tab data
        ref_id: this.state.property_slug, //ref_id:slug
        ref_table_id: "property",
        path: "properties",
        slug: this.state.property_slug,
        refTableId: 3, //ref_table_id:table name
        property_logo: this.state.property_logo ? this.state.property_logo : "",
        availableFiles: output.response,
        deletedFiles: this.state.deletedFileList,
      };

      if (this.state.slug !== undefined) {
        let res = await PropertyService.PropertyMediaUpload(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          // setTimeout(
          //   () =>
          //     this.props.history.push("/properties/view/" + this.state.slug),
          //   global.redirect_time
          // );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
            //change tab logic
            if (
              this.state.errors.day ||
              this.state.errors.start_time ||
              this.state.errors.end_time
            ) {
              // jump to tab 6
              document
                .getElementById("uncontrolled-tab-example-tab-Property Images")
                .click();
            } else {
              // jump to tab 1
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Basic Information"
                )
                .click();
            }
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await PropertyService.PropertyMediaUpload(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          // setTimeout(
          //   () => this.props.history.push("/properties"),
          //   global.redirect_time
          // );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              // alertMessage += item.errorMessage[0]+'\n';
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
            //change tab logic
            if (
              this.state.errors.day ||
              this.state.errors.start_time ||
              this.state.errors.end_time
            ) {
              // jump to tab 6
              document
                .getElementById("uncontrolled-tab-example-tab-Property Images")
                .click();
            } else {
              // jump to tab 1
              document
                .getElementById(
                  "uncontrolled-tab-example-tab-Basic Information"
                )
                .click();
            }
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessageFor("property_name");
    }
  };

  // get affordable
  async getPropertyAffordable(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var unitTypeData = [];
    var unitTypeDataTotal = 0;
    let res = await PropertyService.getPropertyAffordable(
      this.state.property_slug
    );

    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      resData.day = res.data.affordable_financed === 1 ? true : false;
      resData.total_affordable_units = res.data.total_affordable_units
        ? res.data.total_affordable_units
        : "";
      resData.affordable_percent_complete = res.data.affordable_percent_complete
        ? res.data.affordable_percent_complete
        : "";
      resData.affordable_program_income_tiers = res.data
        .affordable_program_income_tiers
        ? res.data.affordable_program_income_tiers
        : "";
      unitTypeData = res.data.unitTypeData ? res.data.unitTypeData : [];
      unitTypeDataTotal = unitTypeData ? unitTypeData.length : 0;
    }
    this.setState({
      resData: resData,
      showSpinner: false,
      loading: false,
      unitTypeDataTotal: unitTypeDataTotal,
      unitTypeData: unitTypeData,
    });
    this.defaultData = resData;
  }

  saveOrUpdateAffordableComponent = async (e) => {
    e.preventDefault();
    if (
      this.customValidate() &&
      !this.validator.errorMessages.affordable_financed &&
      !this.validator.errorMessages.total_affordable_units &&
      !this.validator.errorMessages.affordable_percent_complete &&
      !this.validator.errorMessages.affordable_program_income_tiers
    ) {
      this.setState({ showSpinner: true, isSubmit: true });

      let inputData = {
        //office hours tab data
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        affordable_financed: this.state.affordable_financed
          ? this.state.affordable_financed
          : 0,
        total_affordable_units: this.state.total_affordable_units
          ? this.state.total_affordable_units
          : "",
        affordable_percent_complete: this.state.affordable_percent_complete
          ? this.state.affordable_percent_complete
          : "",
        affordable_program_income_tiers: this.state
          .affordable_program_income_tiers
          ? this.state.affordable_program_income_tiers
          : "",
      };

      let res = await PropertyService.createPropertyAffordable(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.getPropertyAffordable();
        // setTimeout(
        //   () => this.props.history.push("/properties"),
        //   global.redirect_time
        // );
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
          //change tab logic
          if (
            this.state.errors.day ||
            this.state.errors.start_time ||
            this.state.errors.end_time
          ) {
            // jump to tab 6
            document
              .getElementById(
                "uncontrolled-tab-example-tab-Affordable Component"
              )
              .click();
          } else {
            // jump to tab 1
            document
              .getElementById("uncontrolled-tab-example-tab-Basic Information")
              .click();
          }
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessageFor("property_name");
    }
  };

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
          ? 1
          : 0
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // if (event.target.name === "start_time" || event.target.name === "end_time") {
    //   var regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    //   if(!regex.test(value)){
    //       event.preventDefault();
    //       return false;
    //   }
    // }

    let property_office_data1 = this.state.property_office_data;
    if (
      event.target.name === "start_time" ||
      event.target.name === "end_time" ||
      event.target.name === "closed"
    ) {
      // let k = event.target.getAttribute("data-key");
      property_office_data1[index][event.target.name] = value;
    }
    this.setState({ property_office_data: property_office_data1 });

    if (event.target.name === "country_id") {
      if (event.target.value === "") {
        this.setState({
          state_id: "",
          county_id: "",
          city_id: "",
          stateList: [],
          countyList: [],
          cityList: [],
        });
      } else {
        this.getStateData(event.target.value);
      }
    }
    if (event.target.name === "state_id") {
      if (event.target.value === "") {
        this.setState({
          county_id: "",
          city_id: "",
          countyList: [],
          cityList: [],
        });
      } else {
        this.getCountyData(event.target.value);
        this.getCityData(event.target.value, "");
      }
    }
    if (event.target.name === "county_id") {
      if (event.target.value === "") {
        this.getCityData(input.state_id, "");
      } else {
        this.getCityData(input.state_id, event.target.value);
      }
    }

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleChangeSuitabilityList(value) {
    let suitability_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        suitability_id.push(item.value);
      });
    }
    this.setState({
      selectedSuitabilityList: value,
      suitability_id: suitability_id,
    });
  }
  handleChangeLocationTypeList(value) {
    let location_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_type_id.push(item.value);
      });
    }
    this.setState({
      selectedLocationTypeList: value,
      location_type_id: location_type_id,
    });
  }
  handleChangeCommServedList(value) {
    let communities_served_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        communities_served_id.push(item.value);
      });
    }
    this.setState({
      selectedCommServedList: value,
      communities_served_id: communities_served_id,
    });
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("property_name");
    }
  }

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.year_value !== undefined &&
      input.year_value !== "" &&
      input.year_value !== null &&
      input.year_value.length < 4
    ) {
      isValid = false;
      errors["year_value"] = "The year built must be at least 4 numbers.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  handleChange(event) {
    if (event.target.name === "suitability_id") {
      this.setState({
        suitability_id: Array.from(
          event.target.selectedOptions,
          (item) => item.value
        ),
      });
    }
    if (event.target.name === "location_type_id") {
      this.setState({
        location_type_id: Array.from(
          event.target.selectedOptions,
          (item) => item.value
        ),
      });
    }
    if (event.target.name === "communities_served_id") {
      this.setState({
        communities_served_id: Array.from(
          event.target.selectedOptions,
          (item) => item.value
        ),
      });
    }
    if (event.target.name === "affordable_type_id") {
      this.setState({
        affordable_type_id: Array.from(
          event.target.selectedOptions,
          (item) => item.value
        ),
      });
    }
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <div>
        <main>
          {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
          <Container>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
                <div className="backpage">
                  <Link to="/properties">PROPERTY </Link>
                  <span> / </span>
                  {this.state.slug !== undefined ? (
                    <span>EDIT</span>
                  ) : (
                    <span>ADD</span>
                  )}
                </div>
              </div>

              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>

              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap">
                  <h3>
                    {this.state.slug !== undefined
                      ? this.state.property_name
                        ? this.state.property_name
                        : "Edit"
                      : "Add Property"}
                  </h3>
                  {/* <button
                    className="btn-success"
                    type="button"
                    onClick={this.saveOrUpdateProperty}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Save"}
                  </button> */}
                </div>
              </div>

              <div className="col-md-12 mg-t-46 mg-b-40">
                <div className="tabNavigation">
                  <form id="register_from">
                    <Tabs
                      defaultActiveKey="Basic Information"
                      id="uncontrolled-tab-example"
                    >
                      <Tab eventKey="Basic Information" title="Basic Info">
                        <div className="m-0">
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-12 pl-0">
                              <label className="form-label mg-b-0">
                                Property Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>

                            <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="property_name"
                                value={this.state.property_name}
                                maxLength="50"
                                // placeholder="Property Name"
                              />
                              {this.state.errors.property_name ? (
                                <div className="text-danger">
                                  {this.state.errors.property_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_name",
                                  this.state.property_name,
                                  "required|max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                AKA Name <span className="text-danger">*</span>
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                maxLength="3"
                                type="text"
                                // placeholder="AKA Name"
                                name="aka_name"
                                onKeyPress={
                                  global.onKeyPressEvent.alphaNumValidation
                                }
                                value={this.state.aka_name}
                              />
                              {this.state.errors.aka_name ? (
                                <div className="text-danger">
                                  {this.state.errors.aka_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "aka_name",
                                  this.state.aka_name,
                                  "required|alpha_num_dash|max:3",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Legal Entity Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                maxLength="50"
                                type="text"
                                name="legal_entity_name"
                                value={this.state.legal_entity_name}
                                // placeholder="Legal Entity Name"
                              />
                              {this.state.errors.legal_entity_name ? (
                                <div className="text-danger">
                                  {this.state.errors.legal_entity_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "legal_entity_name",
                                  this.state.legal_entity_name,
                                  "required|max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Tax ID <span className="text-danger">*</span>
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="tax_id"
                                value={this.state.tax_id}
                                maxLength="50"
                                type="text"
                                // placeholder="Tax ID"
                              />
                              {this.state.errors.tax_id ? (
                                <div className="text-danger">
                                  {this.state.errors.tax_id}
                                </div>
                              ) : (
                                this.validator.message(
                                  "tax_id",
                                  this.state.tax_id,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Building / Property Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="property_type_id"
                                value={this.state.property_type_id}
                              >
                                <option key="" value="">
                                  Select Building / Property Type
                                </option>
                                {this.state.propertyList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.property_type_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.property_type_id ? (
                                <div className="text-danger">
                                  {this.state.errors.property_type_id}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_type",
                                  this.state.property_type_id,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-start pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0"></div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <InputGroup className="mt-3">
                                <label className="form-label mg-b-0">
                                  Description
                                </label>

                                <Form.Control
                                  onChange={this.changeHandler}
                                  name="description"
                                  value={this.state.description}
                                  className="form-control max_width_100"
                                  id="description"
                                  as="textarea"
                                  rows={global.textareaRowLength}
                                  // placeholder="Username"
                                />
                              </InputGroup>

                              {this.state.errors.description ? (
                                <div className="text-danger">
                                  {this.state.errors.description}
                                </div>
                              ) : (
                                this.validator.message(
                                  "description",
                                  this.state.description,
                                  "max:100000",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-start pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0"></div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <InputGroup className="mt-3">
                                <label className="form-label mg-b-0">
                                  Property Rules
                                </label>

                                <Form.Control
                                  onChange={this.changeHandler}
                                  name="property_rules"
                                  value={this.state.property_rules}
                                  className="form-control max_width_100"
                                  id="property_rules"
                                  as="textarea"
                                  rows={global.textareaRowLength}
                                  // placeholder="Username"
                                />
                              </InputGroup>
                              {this.state.errors.property_rules ? (
                                <div className="text-danger">
                                  {this.state.errors.property_rules}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_rules",
                                  this.state.property_rules,
                                  "max:100000",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Year Built
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="year_value"
                                value={this.state.year_value}
                                className="form-control max_width_100"
                                id="inlineFormInput"
                                type="text"
                                minLength="4"
                                maxLength="4"
                                autoComplete="off"
                                // placeholder="Year"
                                onKeyPress={
                                  global.onKeyPressEvent.numberValidation
                                }
                              />
                              {this.state.errors.year_value ? (
                                <div className="text-danger">
                                  {this.state.errors.year_value}
                                </div>
                              ) : (
                                this.validator.message(
                                  "year_value",
                                  this.state.year_value,
                                  "",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Property Timezone
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="timezone_id"
                                value={this.state.timezone_id}
                              >
                                <option key="" value="">
                                  Select Property Timezone
                                </option>
                                {this.state.timeZoneList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.timezone_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.timezone_id ? (
                                <div className="text-danger">
                                  {this.state.errors.timezone_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Observe Day Light Savings
                              </label>
                            </div>
                            <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Check
                                name="observer_day_ligth_saving"
                                type={"checkbox"}
                                id={`observer_day_ligth_saving`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.observer_day_ligth_saving}
                              />
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                International Property
                              </label>
                            </div>
                            <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Check
                                name="international_property"
                                type={"checkbox"}
                                id={`international_property`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.international_property}
                              />
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Brand <span className="text-danger">*</span>
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="brand_id"
                                value={this.state.brand_id}
                              >
                                <option key="" value="">
                                  Select Brand
                                </option>
                                {this.state.brandList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.brand_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.brand_id ? (
                                <div className="text-danger">
                                  {this.state.errors.brand_id}
                                </div>
                              ) : (
                                this.validator.message(
                                  "brand",
                                  this.state.brand_id,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Full Listing{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Check
                                name="full_listing"
                                type={"checkbox"}
                                id={`full_listing`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.full_listing}
                              />
                            </div>
                          </div> */}
                          {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Home Page Title
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="home_page_title"
                                value={this.state.home_page_title}
                                maxLength="50"
                                // placeholder="Home Page Title"
                              />
                              {this.state.errors.home_page_title ? (
                                <div className="text-danger">
                                  {this.state.errors.home_page_title}
                                </div>
                              ) : (
                                this.validator.message(
                                  "home_page_title",
                                  this.state.home_page_title,
                                  "max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div> */}
                          <div className="row row-xs align-items-start pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Suitability
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              {/* <select
                                className="h-100 d-inline-block"
                                // onChange={this.changeHandler}
                                onChange={this.handleChange}
                                name="suitability_id"
                                value={this.state.suitability_id}

                                multiple={true}
                              >
                                <option key="" value="">
                                  Select Suitability
                                </option>
                                {this.state.suitabilityList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.suitability_name}
                                    </option>
                                  ))}
                              </select> */}
                              <Select
                                isMulti
                                styles={customStyles}
                                menuPlacement="auto"
                                className="multiselect"
                                classNamePrefix={"my-custom-react-select"}
                                value={this.state.selectedSuitabilityList}
                                options={this.state.newSuitabilityList}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeSuitabilityList(value)
                                }
                                defaultValue={
                                  this.state.selectedSuitabilityList
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                              {this.state.errors.suitability_id ? (
                                <div className="text-danger">
                                  {this.state.errors.suitability_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Parking Type
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="parking_type_id"
                                value={this.state.parking_type_id}
                              >
                                <option key="" value="">
                                  Select Parking Type
                                </option>
                                {this.state.parkingTypeList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.parking_type_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.parking_type_id ? (
                                <div className="text-danger">
                                  {this.state.errors.parking_type_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div> */}
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                ADA Compliant
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0 d-flex justify-content-start">
                              <Form.Check
                                onChange={this.changeHandler}
                                name="ada_complaint"
                                value="1"
                                checked={
                                  this.state.ada_complaint === 1 ? true : false
                                }
                                type={"radio"}
                                id="1"
                                label={"Yes"}
                                className={"rdiobox mg-r-30"}
                              />
                              <Form.Check
                                onChange={this.changeHandler}
                                name="ada_complaint"
                                value="2"
                                checked={
                                  this.state.ada_complaint === 2 ? true : false
                                }
                                type={"radio"}
                                id="2"
                                label={"No"}
                                className={"rdiobox"}
                              />
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Property Logo
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <div className="fileUpload d-flex flex-wrap justify-content-between">
                                <span>
                                  <label className="hand-cursor">
                                    <input
                                      onChange={this.onLogoImageChange}
                                      type="file"
                                      name="property_logo"
                                    />
                                    {this.state.imageUrl ? (
                                      <img
                                        src={this.state.imageUrl}
                                        alt="Logo"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                </span>

                                <ul className="ruLink">
                                  {/* <li>
                                  <Link onClick={this.removeLogo}>Remove</Link>
                                </li> */}
                                  <li>
                                    <label>
                                      <input
                                        onChange={this.onLogoImageChange}
                                        type="file"
                                        name="property_logo"
                                      />
                                    </label>
                                    Change Logo
                                  </li>
                                </ul>
                              </div>
                              {this.state.errors.property_logo ? (
                                <div className="text-danger">
                                  {this.state.errors.property_logo}
                                </div>
                              ) : (
                                ""
                              )}

                              {/* <span className="d-inline-flex align-items-start justify-content-start infosize">
                                <img src={infosize} className="mg-r-5" />
                                <p>
                                Recommended size - 512px by 512px (1:1 ratio). Max file size - 5 MB
                                </p>
                              </span> */}
                            </div>
                          </div>

                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Main Image
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <div className="fileUpload d-flex flex-wrap justify-content-between">
                                <span>
                                  <label className="hand-cursor">
                                    <input
                                      onChange={this.onMainImageChange}
                                      type="file"
                                      name="property_main_image"
                                    />
                                    {this.state.imgPropUrl ? (
                                      <img
                                        src={this.state.imgPropUrl}
                                        alt="Main Image"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                </span>

                                <ul className="ruLink">
                                  {/* <li>
                                  <Link onClick={this.removeLogo}>Remove</Link>
                                </li> */}
                                  <li>
                                    <label>
                                      <input
                                        onChange={this.onMainImageChange}
                                        type="file"
                                        name="property_main_image"
                                      />
                                    </label>
                                    Change Main Image
                                  </li>
                                </ul>
                              </div>
                              {this.state.errors.property_main_image ? (
                                <div className="text-danger">
                                  {this.state.errors.property_main_image}
                                </div>
                              ) : (
                                ""
                              )}

                              {/* <span className="d-inline-flex align-items-start justify-content-start infosize">
                                <img src={infosize} className="mg-r-5" />
                                <p>
                                Recommended size - 1024px by 683px (3:2 ratio). Max file size - 5 MB
                                </p>
                              </span> */}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Status
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                              <b
                                className={
                                  this.state.status_id ? "active" : "inactive"
                                }
                              ></b>
                              <div
                                className={
                                  this.state.status_id
                                    ? "az-toggle on"
                                    : "az-toggle"
                                }
                                onClick={() => this.changeStatus()}
                              >
                                <span></span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="row row-xs align-items-baseline pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Sort Order
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                name="sort_order"
                                value={this.state.sort_order}
                                onKeyPress={
                                  global.onKeyPressEvent.numberValidation
                                }
                                type="text"
                                // placeholder="Sort Order"
                              />
                              <span className="d-inline-flex align-items-start justify-content-start infosize">
                                <img src={infosize} className="mg-r-5" />
                                <p>Sort Order will use for listing sequence.</p>
                              </span>
                              {this.state.errors.sort_order ? (
                                <div className="text-danger">
                                  {this.state.errors.sort_order}
                                </div>
                              ) : (
                                this.validator.message(
                                  "sort_order",
                                  this.state.sort_order,
                                  "numeric|min:0,num",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div> */}
                        </div>
                        <div className="d-flex justify-content-between mt-3 row">
                          <button
                            className="mx-auto m-0 btn btn btn-success btn-block"
                            type="button"
                            onClick={this.saveOrUpdateProperty}
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Location Information"
                        title="Location Info"
                        disabled={this.state.slug === undefined ? true : ""}
                      >
                        <div className="m-0">
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Street 1 <span className="text-danger">*</span>
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="street_1"
                                value={this.state.street_1}
                                // placeholder="Street 1"
                              />
                              {this.state.errors.street_1 ? (
                                <div className="text-danger">
                                  {this.state.errors.street_1}
                                </div>
                              ) : (
                                this.validator.message(
                                  "street_1",
                                  this.state.street_1,
                                  "required|max:100",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Street 2
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="street_2"
                                value={this.state.street_2}
                                // placeholder="Street 2"
                              />
                              {this.state.errors.street_2 ? (
                                <div className="text-danger">
                                  {this.state.errors.street_2}
                                </div>
                              ) : (
                                this.validator.message(
                                  "street_2",
                                  this.state.street_2,
                                  "max:100",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Zip Code <span className="text-danger">*</span>
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="zipcode"
                                value={this.state.zipcode}
                                // placeholder="Zipcode"
                                maxLength="5"
                                onKeyPress={
                                  global.onKeyPressEvent.numberValidation
                                }
                              />
                              {this.state.errors.zipcode ? (
                                <div className="text-danger">
                                  {this.state.errors.zipcode}
                                </div>
                              ) : (
                                this.validator.message(
                                  "zipcode",
                                  this.state.zipcode,
                                  "required|max:5",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Country <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="country_id"
                                value={this.state.country_id}
                              >
                                <option key="" value="">
                                  Select Country
                                </option>
                                {this.state.countryList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.country_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.country_id ? (
                                <div className="text-danger">
                                  {this.state.errors.country_id}
                                </div>
                              ) : (
                                this.validator.message(
                                  "country",
                                  this.state.country_id,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                State <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="state_id"
                                value={this.state.state_id}
                              >
                                <option key="" value="">
                                  Select State
                                </option>
                                {this.state.stateList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.state_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.state_id ? (
                                <div className="text-danger">
                                  {this.state.errors.state_id}
                                </div>
                              ) : (
                                this.validator.message(
                                  "state",
                                  this.state.state_id,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                County
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="county_id"
                                value={this.state.county_id}
                              >
                                <option key="" value="">
                                  Select County
                                </option>
                                {this.state.countyList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.county_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.county_id ? (
                                <div className="text-danger">
                                  {this.state.errors.county_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                City <span className="text-danger">*</span>
                              </label>
                            </div>
                            {/* col */}
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="city_id"
                                value={this.state.city_id}
                              >
                                <option key="" value="">
                                  Select City
                                </option>
                                {this.state.cityList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.city_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.city_id ? (
                                <div className="text-danger">
                                  {this.state.errors.city_id}
                                </div>
                              ) : (
                                this.validator.message(
                                  "city",
                                  this.state.city_id,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* col */}
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">Metro</label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="metro_id"
                                value={this.state.metro_id}
                              >
                                <option key="" value="">
                                  Select Metro
                                </option>
                                {this.state.metroList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.metro_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.metro_id ? (
                                <div className="text-danger">
                                  {this.state.errors.metro_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Sub Metro
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="sub_metro_id"
                                value={this.state.sub_metro_id}
                              >
                                <option key="" value="">
                                  Select Sub Metro
                                </option>
                                {this.state.subMetroList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.sub_metro_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.sub_metro_id ? (
                                <div className="text-danger">
                                  {this.state.errors.sub_metro_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Latitude
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="latitude"
                                value={this.state.latitude}
                                // placeholder="Latitude"
                              />
                              {this.state.errors.latitude ? (
                                <div className="text-danger">
                                  {this.state.errors.latitude}
                                </div>
                              ) : (
                                this.validator.message(
                                  "latitude",
                                  this.state.latitude,
                                  "max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Longitude
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="longitude"
                                value={this.state.longitude}
                                // placeholder="Longitude"
                              />
                              {this.state.errors.longitude ? (
                                <div className="text-danger">
                                  {this.state.errors.longitude}
                                </div>
                              ) : (
                                this.validator.message(
                                  "longitude",
                                  this.state.longitude,
                                  "max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Google Map Link
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="google_map_link"
                                value={this.state.google_map_link}
                                // placeholder="Google Map Link"
                              />
                              {this.state.errors.google_map_link ? (
                                <div className="text-danger">
                                  {this.state.errors.google_map_link}
                                </div>
                              ) : (
                                this.validator.message(
                                  "google_map_link",
                                  this.state.google_map_link,
                                  "max:500",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-start pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Location Type
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              {/* <select
                                className="h-100 d-inline-block"
                                // onChange={this.changeHandler}
                                onChange={this.handleChange}
                                name="location_type_id"
                                value={this.state.location_type_id}

                                multiple={true}
                              >
                                <option key="" value="">
                                  Select Location Type
                                </option>
                                {this.state.locationTypeList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.location_type_name}
                                    </option>
                                  ))}
                              </select> */}
                              <Select
                                isMulti
                                styles={customStyles}
                                menuPlacement="auto"
                                className="multiselect"
                                classNamePrefix={"my-custom-react-select"}
                                value={this.state.selectedLocationTypeList}
                                options={this.state.newLocationTypeList}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeLocationTypeList(value)
                                }
                                defaultValue={
                                  this.state.selectedLocationTypeListt
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                              {this.state.errors.location_type_id ? (
                                <div className="text-danger">
                                  {this.state.errors.location_type_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Location AKA
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="location_aka_name"
                                value={this.state.location_aka_name}
                                // placeholder="Location AKA"

                              />
                              {this.state.errors.location_aka_name ? (
                                <div className="text-danger">
                                  {this.state.errors.location_aka_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "location_aka_name",
                                  this.state.location_aka_name,
                                  "required|max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div> */}
                        </div>
                        <div className="d-flex justify-content-between mt-3 row">
                          <button
                            className="mx-auto m-0 btn btn btn-success btn-block"
                            type="button"
                            onClick={this.saveOrUpdateProperty}
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Contact Information"
                        title="Contact Info"
                        disabled={this.state.slug === undefined ? true : ""}
                      >
                        <div className="m-0">
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Property Phone
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <PhoneInput
                                country={global.country}
                                disableSearchIcon
                                // autoFormat= "true"
                                enableSearch="true"
                                countryCodeEditable="true"
                                value={this.state.property_phone_number}
                                onChange={this.onChangePhone}
                              />
                              {this.state.errors.property_phone_number ? (
                                <div className="text-danger">
                                  {this.state.errors.property_phone_number}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Listing Phone
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="listing_phone"
                                value={this.state.listing_phone}
                                // placeholder="Listing Phone"
                                // onPaste={(e) => {
                                //   e.preventDefault()
                                //   return false;
                                // }} onCopy={(e) => {
                                //   e.preventDefault()
                                //   return false;
                                // }}
                                onKeyPress={
                                  global.onKeyPressEvent.phoneValidation
                                }
                              />
                              {this.state.errors.listing_phone ? (
                                <div className="text-danger">
                                  {this.state.errors.listing_phone}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Property Email
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="property_email"
                                value={this.state.property_email}
                                // placeholder="Property Email"
                              />
                              {this.state.errors.property_email ? (
                                <div className="text-danger">
                                  {this.state.errors.property_email}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Invoice Email
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="invoice_email"
                                value={this.state.invoice_email}
                                // placeholder="Invoice Email"
                              />
                              {this.state.errors.invoice_email ? (
                                <div className="text-danger">
                                  {this.state.errors.invoice_email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "invoice_email",
                                  this.state.invoice_email,
                                  "email",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Property Distribution Email
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="property_distribution_email"
                                value={this.state.property_distribution_email}
                                // placeholder="Property Distribution Email"
                              />
                              {this.state.errors.property_distribution_email ? (
                                <div className="text-danger">
                                  {
                                    this.state.errors
                                      .property_distribution_email
                                  }
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_distribution_email",
                                  this.state.property_distribution_email,
                                  "email",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Maintenance Email
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="maintenance_email"
                                value={this.state.maintenance_email}
                                // placeholder="Maintenance Email"
                              />
                              {this.state.errors.maintenance_email ? (
                                <div className="text-danger">
                                  {this.state.errors.maintenance_email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "maintenance_email",
                                  this.state.maintenance_email,
                                  "email",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Property Website Link
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="property_website_link"
                                value={this.state.property_website_link}
                                // placeholder="Property Website Link"
                              />
                              {this.state.errors.property_website_link ? (
                                <div className="text-danger">
                                  {this.state.errors.property_website_link}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_website_link",
                                  this.state.property_website_link,
                                  "max:200",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 row">
                          <button
                            className="mx-auto m-0 btn btn btn-success btn-block"
                            type="button"
                            onClick={this.saveOrUpdateProperty}
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Property Attributes"
                        title="Property Attributes"
                        disabled={this.state.slug === undefined ? true : ""}
                      >
                        <div className="m-0">
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Ownership Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="ownership_type_id"
                                value={this.state.ownership_type_id}
                              >
                                <option key="" value="">
                                  Select Ownership Type
                                </option>
                                {this.state.ownershipTypeList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.ownership_type_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.ownership_type_id ? (
                                <div className="text-danger">
                                  {this.state.errors.ownership_type_id}
                                </div>
                              ) : (
                                this.validator.message(
                                  "ownership_type",
                                  this.state.ownership_type_id,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Third-Party Concierge
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="third_party_concierge_id"
                                value={this.state.third_party_concierge_id}

                              >
                                <option key="" value="">
                                  Select Third-Party Concierge
                                </option>
                                {this.state.thirdPartyConciergeList
                                  .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.third_party_concierge_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.third_party_concierge_id ? (
                                <div className="text-danger">
                                  {this.state.errors.third_party_concierge_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-2 col-lg-2 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Check
                                name="custom_data_1"
                                type={"checkbox"}
                                id={"custom_data_1"}
                                label={"Set Custom Data"}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.custom_data_1}
                              />
                            </div>
                          </div>
                          {this.state.custom_data_1 ? (
                            <span>
                              <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                                <div className="col-md-3 pl-0">
                                  <label className="form-label mg-b-0"></label>
                                </div>
                                <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="contact_name_1"
                                        value={this.state.contact_name_1}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        maxLength="50"
                                        autoComplete="off"
                                        // placeholder="Contact Name"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="phone_number"
                                        value={this.state.phone_number}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        maxLength="50"
                                        autoComplete="off"
                                        // placeholder="Phone"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                                <div className="col-md-3 pl-0">
                                  <label className="form-label mg-b-0"></label>
                                </div>
                                <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="email"
                                        value={this.state.email}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        maxLength="50"
                                        autoComplete="off"
                                        // placeholder="Email"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="zipcode"
                                        onKeyPress={global.onKeyPressEvent.numberValidation}
                                        value={this.state.zipcode}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        maxLength="5"
                                        autoComplete="off"
                                        // placeholder="Zipcode"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                                <div className="col-md-3 pl-0">
                                  <label className="form-label mg-b-0"></label>
                                </div>
                                <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="street_1"
                                        value={this.state.street_1}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        maxLength="50"
                                        autoComplete="off"
                                        // placeholder="Street 1"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="street_2"
                                        value={this.state.street_2}
                                        className="form-control max_width_100"
                                        id="inlineFormInput"
                                        type="text"
                                        maxLength="50"
                                        autoComplete="off"
                                        // placeholder="Street 2"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                                <div className="col-md-3 pl-0">
                                  <label className="form-label mg-b-0"></label>
                                </div>
                                <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <select
                                        onChange={this.changeHandler}
                                        name="country_slug"
                                        value={this.state.country_slug}
                                      >
                                        <option key="" value="">
                                          Select Country
                                        </option>
                                        {this.state.countryList
                                          .sort((a, b) =>
                                            a.label > b.label ? 1 : -1
                                          )
                                          .map((option) => (
                                            <option
                                              key={option.slug}
                                              value={option.slug}
                                            >
                                              {option.country_name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    <div className="col-md-6">
                                      <select
                                        onChange={this.changeHandler}
                                        name="state_slug"
                                        value={this.state.state_slug}
                                      >
                                        <option key="" value="">
                                          Select State
                                        </option>
                                        {this.state.stateList
                                          .sort((a, b) =>
                                            a.label > b.label ? 1 : -1
                                          )
                                          .map((option) => (
                                            <option
                                              key={option.slug}
                                              value={option.slug}
                                            >
                                              {option.state_name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                                <div className="col-md-3 pl-0">
                                  <label className="form-label mg-b-0"></label>
                                </div>
                                <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <select
                                        onChange={this.changeHandler}
                                        name="county_slug"
                                        value={this.state.county_slug}
                                      >
                                        <option key="" value="">
                                          Select County
                                        </option>
                                        {this.state.countyList
                                          .sort((a, b) =>
                                            a.label > b.label ? 1 : -1
                                          )
                                          .map((option) => (
                                            <option
                                              key={option.slug}
                                              value={option.slug}
                                            >
                                              {option.county_name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    <div className="col-md-6">
                                      <select
                                        onChange={this.changeHandler}
                                        name="city_slug"
                                        value={this.state.city_slug}
                                      >
                                        <option key="" value="">
                                          Select City
                                        </option>
                                        {this.state.cityList
                                          .sort((a, b) =>
                                            a.label > b.label ? 1 : -1
                                          )
                                          .map((option) => (
                                            <option
                                              key={option.slug}
                                              value={option.slug}
                                            >
                                              {option.city_name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          ) : (
                            ""
                          )} */}
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Fiscal Year Start Month
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="month_id"
                                value={this.state.month_id}
                              >
                                <option key="" value="">
                                  Select Fiscal Month
                                </option>
                                {this.state.monthList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.fiscal_month_no}
                                      value={option.fiscal_month_no}
                                    >
                                      {option.fiscal_month_value}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.month_id ? (
                                <div className="text-danger">
                                  {this.state.errors.month_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Communities Served
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              {/* <select
                                className="h-100 d-inline-block"
                                // onChange={this.changeHandler}
                                onChange={this.handleChange}
                                name="communities_served_id"
                                value={this.state.communities_served_id}

                                multiple={true}
                              >
                                <option key="" value="">
                                  Select Communities Served
                                </option>
                                {this.state.commServedList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.communities_served_name}
                                    </option>
                                  ))}
                              </select> */}
                              <Select
                                isMulti
                                styles={customStyles}
                                menuPlacement="auto"
                                className="multiselect"
                                classNamePrefix={"my-custom-react-select"}
                                value={this.state.selectedCommServedList}
                                options={this.state.newCommServedList}
                                getOptionValue={(option) => `${option.label}`}
                                onChange={(value) =>
                                  this.handleChangeCommServedList(value)
                                }
                                defaultValue={this.state.selectedCommServedList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 2,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#fff",
                                    primary75: "#000",
                                    primary50: "#000",
                                    primary25: "#000",
                                  },
                                })}
                              />
                              {this.state.errors.communities_served_id ? (
                                <div className="text-danger">
                                  {this.state.errors.communities_served_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Smoking Type
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="smoking_type_id"
                                value={this.state.smoking_type_id}
                              >
                                <option key="" value="">
                                  Select Smoking Type
                                </option>
                                {this.state.smokingTypeList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.smoking_type_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.smoking_type_id ? (
                                <div className="text-danger">
                                  {this.state.errors.smoking_type_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                LEED Building
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <select
                                className=""
                                onChange={this.changeHandler}
                                name="leed_building_id"
                                value={this.state.leed_building_id}
                              >
                                <option key="" value="">
                                  Select LEED Building
                                </option>
                                {this.state.leedBuildingList
                                  // .sort((a, b) => (a.label > b.label ? 1 : -1))
                                  .map((option) => (
                                    <option
                                      key={option.slug}
                                      value={option.slug}
                                    >
                                      {option.leed_building_name}
                                    </option>
                                  ))}
                              </select>
                              {this.state.errors.leed_building_id ? (
                                <div className="text-danger">
                                  {this.state.errors.leed_building_id}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Asset Manager
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="asset_managers"
                                value={this.state.asset_managers}
                                maxLength="50"
                                // placeholder="Asset Manager"
                              />
                              {this.state.errors.asset_managers ? (
                                <div className="text-danger">
                                  {this.state.errors.asset_managers}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {/* <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Insurance Required
                              </label>
                            </div>
                            <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Check
                                name="insurance_required"
                                type={"checkbox"}
                                id={`insurance_required`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.insurance_required}
                              />
                            </div>
                          </div> */}
                        </div>
                        <div className="d-flex justify-content-between mt-3 row">
                          <button
                            className="mx-auto m-0 btn btn btn-success btn-block"
                            type="button"
                            onClick={this.saveOrUpdateProperty}
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Affordable Component"
                        title="Affordable Component"
                        disabled={this.state.slug === undefined ? true : ""}
                      >
                        <div className="m-0">
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              {" "}
                              <label className="form-label mg-b-0">
                                Affordable Financed
                              </label>
                            </div>
                            <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Check
                                name="affordable_financed"
                                type={"checkbox"}
                                id={`affordable_financed`}
                                label=""
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.affordable_financed}
                              />
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Total Affordable Units
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="total_affordable_units"
                                value={this.state.total_affordable_units}
                                maxLength="50"
                                // placeholder="Total Affordable Units"
                              />
                              {this.state.errors.total_affordable_units ? (
                                <div className="text-danger">
                                  {this.state.errors.total_affordable_units}
                                </div>
                              ) : (
                                this.validator.message(
                                  "total_affordable_units",
                                  this.state.total_affordable_units,
                                  "numeric|min:0,num",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Affordable % Complete
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="affordable_percent_complete"
                                value={this.state.affordable_percent_complete}
                                maxLength="50"
                                // placeholder="Affordable % Complete"
                              />
                              {this.state.errors.affordable_percent_complete ? (
                                <div className="text-danger">
                                  {
                                    this.state.errors
                                      .affordable_percent_complete
                                  }
                                </div>
                              ) : (
                                this.validator.message(
                                  "affordable_percent_complete",
                                  this.state.affordable_percent_complete,
                                  "min:0",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Affordable Program/Income Tiers
                              </label>
                            </div>
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="affordable_program_income_tiers"
                                value={
                                  this.state.affordable_program_income_tiers
                                }
                                maxLength="50"
                                // placeholder="Affordable Program/Income Tiers"
                              />
                              {this.state.errors
                                .affordable_program_income_tiers ? (
                                <div className="text-danger">
                                  {
                                    this.state.errors
                                      .affordable_program_income_tiers
                                  }
                                </div>
                              ) : (
                                this.validator.message(
                                  "affordable_program_income_tiers",
                                  this.state.affordable_program_income_tiers,
                                  "max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Affordable Type / Unit Nos.
                              </label>
                            </div>

                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0 Affordable-table">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">Affordable Type</th>
                                    <th scope="col">Unit Type</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.unitTypeDataTotal > 0 ? (
                                    this.state.unitTypeData.map((item, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>{item.affordable_type_name}</td>
                                          <td>{item.unit_count}</td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="9" align="center">
                                        There are no affordable added in the
                                        system. You may add them by clicking Add
                                        button.
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 row">
                          <button
                            className="mx-auto m-0 btn btn btn-success btn-block"
                            type="button"
                            onClick={this.saveOrUpdateAffordableComponent}
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Owner Information"
                        title="Owner Info"
                        disabled={this.state.slug === undefined ? true : ""}
                      >
                        <div className="m-0">
                          <PropertyOwner propertySlug={this.state.slug} />
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Office Hours"
                        title="Office Hours"
                        disabled={this.state.slug === undefined ? true : ""}
                      >
                        <div className="m-0">
                          <span className="d-inline-flex align-items-start justify-content-start infosize">
                            <img src={infosize} className="mg-r-5" />
                            <p>
                              Time format will be HH:MM AM/PM 12 hours. (i.e.
                              07:30 PM)
                            </p>
                          </span>
                          {this.state.property_office_data.map(
                            (item, index) => (
                              <div
                                className="row row-xs align-items-center pd-t-30 pd-b-30"
                                key={item.key}
                              >
                                <div className="col-md-2 pl-0">
                                  <label className="form-label mg-b-0">
                                    {item.label}
                                  </label>
                                </div>
                                <div className="col-md-3 col-lg-3 mg-t-5 mg-md-t-0 pl-0 react-time-picker">
                                  {/* <input
                                  type="time"
                                  name="start_time"
                                  onChange={(e) => this.changeHandler(e,index)}
                                  value={this.state.start_time}
                                /> */}
                                  <input
                                    type="text"
                                    onKeyPress={
                                      global.onKeyPressEvent.timeValidation
                                    }
                                    maxLength="8"
                                    name="start_time"
                                    onChange={(e) =>
                                      this.changeHandler(e, index)
                                    }
                                    value={item.start_time}
                                  />
                                </div>
                                <div className="col-md-1 col-md-1 mg-t-5 mg-md-t-0 pl-0 text-center">
                                  To
                                </div>
                                <div className="col-md-3 col-lg-3 mg-t-5 mg-md-t-0 pl-0">
                                  {/* <input
                                  type="time"
                                  name="end_time"
                                  onChange={(e) => this.changeHandler(e,index)}
                                  value={this.state.end_time}
                                /> */}
                                  <input
                                    type="text"
                                    onKeyPress={
                                      global.onKeyPressEvent.timeValidation
                                    }
                                    maxLength="8"
                                    name="end_time"
                                    onChange={(e) =>
                                      this.changeHandler(e, index)
                                    }
                                    value={item.end_time}
                                  />
                                </div>
                                <div className="row col-md-2 col-md-2 mg-t-5 mg-md-t-0 pl-0k">
                                  <input
                                    type="checkbox"
                                    id={index}
                                    name="closed"
                                    onChange={(e) =>
                                      this.changeHandler(e, index)
                                    }
                                    checked={item.closed || ""}
                                  />
                                  <label htmlFor={index}>Closed</label>
                                </div>
                                {/* <Form.Check
                                  name="closed"
                                  type={"checkbox"}
                                  id={`closed`}
                                  label={"Closed"}
                                  className={"ckbox"}
                                  onChange={(e) => this.changeHandler(e,index)}
                                  checked={item.closed || ""}
                                /> */}
                                {/* <div className="ckbox form-check"><input type="checkbox" id="checked-checkbox" className="form-check-input" checked="" /><label title="" htmlFor="checked-checkbox" className="form-check-label">Checkbox checked</label></div> */}
                              </div>
                            )
                          )}
                        </div>
                        <div className="d-flex justify-content-between mt-3 row">
                          <button
                            className="mx-auto m-0 btn btn btn-success btn-block"
                            type="button"
                            onClick={this.saveOrUpdateOfficeHours}
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Wi-Fi Information"
                        title="Wi-Fi Info"
                        disabled={this.state.slug === undefined ? true : ""}
                      >
                        <div className="m-0">
                          <PropertyWiFiInfo propertySlug={this.state.slug} />
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Property Images"
                        title="Property Images"
                        disabled={this.state.slug === undefined ? true : ""}
                      >
                        <div className="m-0">
                          {/* row */}

                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-12 pl-0">
                              <label className="form-label mg-b-0">
                                Other Images
                              </label>
                            </div>

                            <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 pl-0">
                              <FileWidget
                                fieldName="file_name"
                                fieldTitle="Select Property Image2"
                                fieldLabel="Other Image"
                                checkValidation="true"
                                allowedType="image/jpg,image/png"
                                maxFileSize={1000}
                                maxFileSizeType="mb"
                                maxFileAllowed={10}
                                uploadOn="s3"
                                slug={this.state.slug}
                                refTableId="3"
                                helpText="to download sample file."
                                sampleFileUrl="/sample.jpg"
                                drag="false"
                                dragText=""
                                inputClassName="form-control"
                                labelClassName="form-label"
                                formID="register_from"
                                uploadType="multiple"
                                resizeOptions="width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium"
                                propertySlug={""}
                                destinationFolder="properties"
                                uploadedFiles={this.state.profile_image2}
                                deletedFiles={this.state.deletedFileList}
                                acceptOnly="image/jpg,image/png"
                                displayPreviewStyle="vertical"
                              />
                              {/* { (this.state.errors.aka_name) ? <div className="text-danger">{this.state.errors.aka_name}</div> : this.validator.message('aka_name', this.state.aka_name, 'required|max:50', { className: 'text-danger' })} */}
                            </div>
                          </div>
                          {/* row */}
                        </div>
                        <div className="d-flex justify-content-between mt-3 row">
                          <button
                            className="mx-auto m-0 btn btn btn-success btn-block"
                            type="button"
                            onClick={this.saveOrImage}
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Third Party Vendors"
                        title="Third Party Vendors"
                        disabled={this.state.slug === undefined ? true : ""}
                      >
                        <PropertyVendors propertySlug={this.state.slug} />
                      </Tab>
                    </Tabs>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </main>
      </div>
    );
  }
}

export default PropertiesForm;

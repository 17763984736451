////////////////////////////////////////////////////////////
//     							                          //
//  Program: CompanyOverview.jsx                                //
//  Application: Dashboard                                //
//  Option: to view Company Information                   //
//  Developer: NP  						                            //
//  Date: 2022-04-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { withRouter } from "react-router";
import CompanyService from "../../services/CompanyService";
import Moment from "moment";
// import companyLogo from './../../assets/images/company_placeholder.png'
import NoCheckOut from "../../assets/images/snapshoticons/CheckOut.svg";

class CheckOutTomorrow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertySlug: this.props.propertySlug,
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: "",
      total_client_count: "",
      checkins_today: [],
      checkouts_today: [],
      checkins_tomorrow: [],
      checkouts_tomorrow: [],
      checkInList: [],
      checkOutList: [],
      cleaningscheduled_today: [],
      cleaningscheduled_tomorrow: [],
      occupiedunits: [],
      bookedunits: [],
      signed_leases: [],
      new_booking_list: [],
    };
  }

  componentDidMount() {
    this.setState({ checkouts_tomorrow: [] });
    this.setState({ spinnerLoader: true, loading: true });
    if (this.state.propertySlug) {
      this.getData(this.state.propertySlug);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ checkouts_tomorrow: [] });
    this.setState({ spinnerLoader: true, loading: true });
    if (nextProps.propertySlug) {
      this.getData(nextProps.propertySlug);
    }
  }
  // for check_in info
  handleClickCheckinList(property_slug, reservation_slug) {
    if (reservation_slug) {
      const win = window.open(
        "/properties/view/" +
          property_slug +
          "/reservations/view/" +
          reservation_slug,
        "_blank"
      );
      win.focus();
    } else {
      return false;
    }
  }
  async getData(propertySlug) {
    this.setState({ spinnerLoader: true, loading: true });
    var resData = {};

    let res = await CompanyService.getCheckoutTomList(propertySlug);

    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      resData.checkouts_tomorrow = res.data.checkouts_tomorrow
        ? res.data.checkouts_tomorrow
        : [];
      resData.checkouts_tomorrow.sort((a, b) =>
        a.unit_name > b.unit_name ? 1 : -1
      );
    }
    this.setState(resData);
    this.setState({ spinnerLoader: false, loading: false });
  }

  render() {
    return (
      <div className="col-xxl-6 col-md-6 col-sm-12 col-12 mg-b-20">
        <div className="card card-table-two ">
          <div className=" d-flex justify-content-between">
            <div className="">
              <h6 className="card-title">Check-outs Tomorrow</h6>
              <span className="d-block mg-b-0">
                All check-outs on the schedule for tomorrow
              </span>
            </div>
            <h6 className="card-title">
              Total Count: {this.state.checkouts_tomorrow.length}
            </h6>
          </div>
          {this.state.checkouts_tomorrow.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-dashboard-two mg-b-0">
                <thead>
                  <tr>
                    <th className="wd-lg-25p tx-left">S.No</th>
                    <th className="wd-lg-25p tx-left">Unit</th>
                    <th className="wd-lg-25p tx-left">Guest</th>
                    <th className="wd-lg-25p tx-left">Cleaning Schedule</th>
                    <th className="wd-lg-25p tx-left">Key Status</th>
                    <th className="wd-lg-25p tx-left">Cleaning Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.checkouts_tomorrow.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        value={item}
                        onClick={() =>
                          this.handleClickCheckinList(
                            item.property_slug,
                            item.reservation_slug
                          )
                        }
                      >
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {i + 1}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.unit_name ? item.unit_name : item.unit_number}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.guest_name ? item.guest_name : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable date_tbl_formate">
                          {item.scheduled_on
                            ? Moment(item.scheduled_on).format(
                                global.timeFormat
                              )
                            : ""}
                        </td>
                        <td className="tx-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.key_status ? item.key_status : ""}
                        </td>
                        <td className="text-left tx-medium tx-inverse hand-cursor td_clickable">
                          {item.cleaning_status ? item.cleaning_status : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="table-responsive overflow-hidden">
                {this.state.spinnerLoader ? (
                  global.snapshotloader
                ) : (
                  <div className="text-center">
                    <img src={NoCheckOut} alt="" />
                    <p>No guests check-outs for tomorrow.</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(CheckOutTomorrow);

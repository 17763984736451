////////////////////////////////////////////////////////////
//
//  Program: FaqsView.js
//  Application:  CorporateApp
//  Option: For view  CorporateApp details
//  Developer: Raj Kumar
//  Date: 2023-03-23
//
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Container, Modal } from "react-bootstrap";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import deleteicon from "../../assets/images/delete.svg";
import CorporateAppService from "../../services/CorporateAppService";
import renderHTML from "react-render-html";
import CMSSidebar from "../common/CMSSidebar";

export class CorporateAppView extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      open: true,
      eventKey: "0",
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      descShowRules: 0,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      toggleSwitchDark2: true,
      slug: this.props.match.params.slug,
      isSubmit: false,
      publishData: false,
      status_id: "",
      description: "",
      title: "",
      content_type: "",
      descShow: 0,
      sort_order: "",
      article_category: "",
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      confirm_back: 0,
      moduleName: "",
      contentTypeName: "",
      thumbnail: "",
    };
    this.fullText = this.fullText.bind(this);
    this.deleteCorporateApp = this.deleteCorporateApp.bind(this);
    this.opendeleteCorporateAppModal =
      this.opendeleteCorporateAppModal.bind(this);
    this.closedeleteCorporateAppModal =
      this.closedeleteCorporateAppModal.bind(this);
    this.fullTextRules = this.fullTextRules.bind(this);
  }

  opendeleteCorporateAppModal(slug) {
    this.setState({ deleteCorporateAppModal: true, slug: slug });
  }

  closedeleteCorporateAppModal() {
    this.setState({ deleteCorporateAppModal: false, slug: "" });
  }

  async deleteCorporateApp(slug) {
    this.setState({ publishData: true });
    this.setState({ showSpinner: true });
    let res = await CorporateAppService.deleteCorporateApp(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteCorporateAppModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.props.history.push("/corporate-apps"),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteCorporateAppModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
    this.setState({ publishData: false });
  }

  async componentDidMount() {
    this.setState({ publishData: true });
    this.setState({ showSpinner: true, loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};

      let res = await CorporateAppService.getCorporateAppBySlug(
        this.state.slug
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};

        // Module Name start
        if (res.data.module_type) {
          let moduleType = global.cmsModuleName.find(
            (module) => module.value === res.data.module_type
          );
          this.setState({ moduleName: moduleType.label });
        }
        // Module Name end

        // Content Type start
        if (res.data.content_type) {
          this.setState({ selectedContentType: [] });
          let contentTypeVal = global.cmsContentType.find(
            (module) => module.value === res.data.content_type
          );
          this.setState({ contentTypeName: contentTypeVal.label });
        }
        // Content Type end
        this.setState({
          thumbnail: res.data.thumbnail ? res.data.thumbnail : "",
        });
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
    this.setState({ publishData: false });
  }

  setOpen(open, id) {
    this.setState({ open: open });
    this.setState({ eventKey: id });
  }

  getTitle() {
    return "Corporate App";
  }

  fullTextRules() {
    if (this.state.descShowRules === 0) {
      this.setState({ descShowRules: 1 });
    } else {
      this.setState({ descShowRules: 0 });
    }
  }

  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  render() {
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <aside>
                    <CMSSidebar />
                  </aside>
                </div>
              </div>
              <div className="col-md-12">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {global.userPermissions.checkPermission(
                        "app-cms-delete"
                      ) && this.state.slug !== undefined ? (
                        <>
                          <span
                            onClick={() => {
                              this.opendeleteCorporateAppModal(this.state.slug);
                            }}
                            className="deleteicon mg-r-20"
                          >
                            <img src={deleteicon} alt="" />
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      {global.userPermissions.checkPermission(
                        "app-cms-update"
                      ) && (
                        <Link
                          to={"/corporate-apps/update/" + this.state.slug}
                          className="mg-l-2"
                        >
                          <button className="btn-success-outline-small">
                            <img src={editIcon} alt="Edit" />
                            Edit
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-30">
                    <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-0">
                      <Alerts
                        show={this.state.showAlertModal}
                        type={this.state.alertModalType}
                        title={this.state.alertModalTitle}
                        message={this.state.alertModalMessage}
                      />
                    </div>
                    <div className="row align-items-center col-md-12 mg-t-30 mg-b-20 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 pd-xs-l-15">
                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-b-15">
                          <p className="az-profile-name-text pl-0 ">Title</p>
                          <p className="media-body pb-0">
                            {this.state.title ? this.state.title : ""}
                          </p>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-b-15">
                          <p className="az-profile-name-text pl-0 ">
                            Module Name
                          </p>
                          <p className="media-body pb-0">
                            {this.state.moduleName}
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-b-15">
                          <p className="az-profile-name-text pl-0 ">
                            Content Type
                          </p>
                          <p className="media-body pb-0">
                            {this.state.contentTypeName}
                          </p>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-b-15">
                          <p className="az-profile-name-text pl-0 ">
                            Article Category
                          </p>
                          <p className="media-body pb-0">
                            {this.state.kb_category &&
                            this.state.kb_category.kb_category_name
                              ? this.state.kb_category.kb_category_name
                              : this.state.article_category
                              ? this.state.article_category
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-b-15">
                          <p className="az-profile-name-text pl-0 ">Property</p>
                          <p className="media-body pb-0">
                            {this.state.property_name
                              ? this.state.property_name
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-b-15">
                          <p className="az-profile-name-text pl-0 ">
                            Internal Notes
                          </p>
                          <p className="media-body pb-0">
                            {this.state.reference ? this.state.reference : ""}
                          </p>
                        </div>
                        <div className="row col-md-12 mg-t-40 mg-md-t-0 mg-t-15">
                          <div className="col-md-12 pd-l-15 pd-r-15 mg-b-15 cms_descp_cnt">
                            <p className="az-profile-name-text pl-0">
                              Description
                            </p>
                            <div className="para ">
                              {this.state.description ? ( //check if property_rules is exist
                                this.state.description.length >
                                global.showMoreLength ? ( //check if length is long
                                  this.state.descShowRules === 0 ? (
                                    <>
                                      <HTMLEllipsis
                                        unsafeHTML={
                                          this.state.description
                                            ? this.state.description
                                            : ""
                                        }
                                        maxLine="6"
                                        ellipsis=""
                                        trimright="true"
                                        basedOn="letters"
                                      />
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={this.fullTextRules}
                                      >
                                        Show More
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      {renderHTML("" + this.state.description)}
                                      <p
                                        className="hand-cursor showmore"
                                        onClick={this.fullTextRules}
                                      >
                                        Show Less
                                      </p>
                                    </>
                                  )
                                ) : (
                                  renderHTML("" + this.state.description)
                                )
                              ) : (
                                ""
                              )}
                              {this.state.file_type === 1 ? (
                                <>
                                  <div className="img_preview_cms">
                                    <img src={this.state.file_url} alt="" />
                                  </div>
                                </>
                              ) : this.state.file_type === 2 ? (
                                <>
                                  <div className="video_preview_cms">
                                    <video width="320" height="240" controls>
                                      <source
                                        src={this.state.file_url}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        {this.state.thumbnail !== "" ? (
                          <>
                            <div className="row col-md-12 mg-t-40 mg-md-t-0 mg-t-15">
                              <div className="col-md-12 pd-l-15 pd-r-15 mg-b-15 cms_descp_cnt">
                                <p className="az-profile-name-text pl-0">
                                  Video Thumbnail
                                </p>
                                <div className="img_preview_cms">
                                  <img src={this.state.thumbnail} alt="" />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <p className="az-profile-name-text pl-0 ">URL</p>
                          <p className="media-body pb-0">
                            {this.state.url ? this.state.url : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal show={this.state.deleteCorporateAppModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteCorporateAppModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteCorporateAppModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCorporateApp(this.state.slug)}
                  disabled={this.state.publishData ? true : false}
                >
                  {this.state.publishData ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default CorporateAppView;

///////////////////////////////////////////////////////////
//  Program: CrmTemplate.jsx                                  //
//  Application: Campaign                                  //
//  Option: add all templates                               //
//  Developer:  Raj Kumar                                       //
//  Date: 2022-12-05                                     //
///////////////////////////////////////////////////////////

import CrmSettingSidebar from "../common/CrmSettingSidebar";
import ContactSidebar from "../common/ContactSidebar";
import NoData from "../common/NoData";
import pluswIcon from "./../../assets/images/plus.svg";
import React, { Component } from "react";
import { Container, Nav, Tab, Modal, InputGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import CrmTemplateService from "../../services/CrmTemplateService";
import Select from "react-select";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import queryString from "query-string";
import BrandSidebar from "../common/BrandSidebar";
import ClientSidebar from "../common/ClientSidebar";

let queryParams = queryString.parse(window.location.search);
class CrmTemplates extends Component {
  constructor(props) {
    super(props);
    const pathStr = props.match.path;
    const pathArray = pathStr.split(":");
    this.state = {
      pathname: window.location.pathname,
      showSpinner: false,
      loading: false,
      total_record: 0,
      records: [],
      selectedTypeList: [],
      userCount: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      deleteRoleModal: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : "",
      brand_slug:
        pathArray[0] === "/brand/view/" ? this.props.match.params.slug : "",
      client_slug:
        pathArray[0] === "/client/view/" ? this.props.match.params.slug : "",
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "template_name",
          text: "Template",
          className: "title hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.template_name;
          },
        },
        {
          key: "is_email_enable",
          text: "Email",
          className: "title hand-cursor text-center",
          sortable: false,
          cell: (record) => {
            return (
              <div>
                <Form.Check
                  disabled={true}
                  name="is_email_enable"
                  checked={record.is_email_enable === 1 ? true : false}
                  type={"checkbox"}
                  id={"emailcheckbox"}
                  label={""}
                  className={"ckbox "}
                />
              </div>
            );
          },
        },
        {
          key: "is_message_enable",
          text: "App Message",
          className: "title hand-cursor text-center",
          sortable: false,
          cell: (record) => {
            return (
              <div>
                <Form.Check
                  disabled={true}
                  name="is_message_enable"
                  checked={record.is_message_enable === 1 ? true : false}
                  type={"checkbox"}
                  id={"emailcheckbox"}
                  label={""}
                  className={"ckbox"}
                />
              </div>
            );
          },
        },

        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active m-0">Active</b>
            ) : (
              <b className="status-inactive">InActive</b>
            );
          },
        },
      ],
      tab: "active",
      brandTabData: localStorage.getItem("emailtemplate_tab_data")
        ? localStorage.getItem("emailtemplate_tab_data")
        : "",
      activeClass: "activeTab",
      checked_value: [],
    };
    this.searchToggle = this.searchToggle.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.filterData = this.filterData.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.UpdateCrmtemplate = this.UpdateCrmtemplate.bind(this);
  }

  handleChangeStatusList(event, record, column) {
    let prevState = [...this.state.checked_value];
    prevState[record.item_index][column] =
      event.target.checked === true ? 1 : 0;
    this.setState({ prevState });
    this.UpdateCrmtemplate(
      column,
      event.target.checked === true ? 1 : 0,
      record.slug
    );
  }

  componentDidMount() {
    switch (this.state.brandTabData) {
      case "active":
        document.getElementById("activeTab").click();
        break;
      case "inactive":
        document.getElementById("inactiveTab").click();
        break;
      default:
        document.getElementById("activeTab").click();
    }
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "activeTab") {
      this.setState({ tab: "active" });
      this.getData("tab=active");
      setTimeout(() => {
        localStorage.setItem(
          "emailtemplate_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    } else if (e === "inactiveTab") {
      this.setState({ tab: "inactive" });
      this.getData("tab=inactive");
      setTimeout(() => {
        localStorage.setItem(
          "emailtemplate_tab_data",
          this.state.tab ? this.state.tab : ""
        );
      }, 5);
    }
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await CrmTemplateService.getCrmTemplateList(
      "property_slug=" +
        (this.state.property_slug ? this.state.property_slug : "") +
        "&brand_slug=" +
        (this.state.brand_slug ? this.state.brand_slug : "") +
        "&client_slug=" +
        (this.state.client_slug ? this.state.client_slug : "") +
        (queryString ? "&" + queryString : "")
    );

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
      this.setState({ checked_value: list });

      totalRecords = res.data ? res.data.total : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  async UpdateCrmtemplate(column, value, slug) {
    let inputData = {
      column: column ? column : column,
      value: value ? value : value,
    };
    this.setState({ loading: true, isSubmit: true });
    let res = await CrmTemplateService.updateCrmTemplatecheckbox(
      slug,
      inputData
    );
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;

    this.setState({
      [event.target.name]: value,
      input: input,
    });
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            newKey = "per_page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };
  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };

  handleChangeUserList(value) {
    let newTypeList = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        newTypeList.push(item.value);
      });
    }
    const type = value !== null ? value.value : [];

    this.setState({
      selectedTypeList: value,
      usertotype: type,
      filtertolable: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  filterData() {
    let queryString =
      "template_name=" +
      (this.state.template_name ? this.state.template_name : "") +
      "&to_email=" +
      (this.state.usertotype ? this.state.usertotype : "") +
      "&from=" +
      (this.state.from ? this.state.from : "") +
      "&is_search=1";

    let moduleSearchString = "";
    moduleSearchString = this.state.template_name
      ? "template name - " + this.state.template_name
      : "";
    moduleSearchString = this.state.usertotype
      ? moduleSearchString
        ? moduleSearchString + " &" + " To - " + this.state.filtertolable
        : " To - " + this.state.filtertolable
      : moduleSearchString;
    moduleSearchString = this.state.from
      ? moduleSearchString
        ? moduleSearchString + " &" + " from - " + this.state.from
        : " type - " + this.state.from
      : moduleSearchString;

    if (this.state.template_name || this.state.usertotype || this.state.from) {
      this.setState({ customQueryString: queryString });
      this.searchToggle();
      this.setState({ is_search: 1 });
      this.setState({ module_search: moduleSearchString });
      this.getData(queryString);
    }
  }

  clearFilter() {
    queryParams = {};
    window.location.search = "";
    this.setState({ is_search: 0 });
    this.setState({ template_name: "", usertotype: "", from: "" });
    this.setState({ module_search: "" });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    global.userPermissions.checkPermission("crm-templates-update") &&
      this.props.history.push(
        this.state.property_slug
          ? "/properties/view/" +
              this.state.property_slug +
              "/crm-template/update/" +
              data.slug
          : this.state.brand_slug
          ? "/brand/view/" +
            this.state.brand_slug +
            "/crm-template/update/" +
            data.slug
          : this.state.client_slug
          ? "/client/view/" +
            this.state.client_slug +
            "/crm-template/update/" +
            data.slug
          : "/crm-template/update/" + data.slug
      );
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };
    return (
      <>
        <main>
          <Container className="innter-container over-fl  container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      {this.state.property_slug ? (
                        <ContactSidebar
                          property_slug={this.state.property_slug}
                        />
                      ) : this.state.brand_slug ? (
                        <BrandSidebar brand_slug={this.state.brand_slug} />
                      ) : this.state.client_slug ? (
                        <ClientSidebar clientSlug={this.state.client_slug} />
                      ) : (
                        <CrmSettingSidebar />
                      )}
                    </aside>
                  </div>
                </div>
                <div className="col-md-10 right">
                  <div className="scrolling-carousel">
                    <div className="col-md-12 mg-t-0 mg-b-0 pd-t-0 pd-b-0 pd-l-15 pd-r-0">
                      <div className="row row-xs">
                        <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                        <div className="col-md-12">
                          <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                            <h3 className="brnd_heading mb-0">Templates</h3>
                            <div className="d-flex flex-wrap">
                              {global.userPermissions.checkPermission(
                                "crm-templates-add"
                              ) && (
                                <Link
                                  to={
                                    this.state.property_slug
                                      ? "/properties/view/" +
                                        this.state.property_slug +
                                        "/crm-template/add"
                                      : this.state.brand_slug
                                      ? "/brand/view/" +
                                        this.state.brand_slug +
                                        "/crm-template/add"
                                      : this.state.client_slug
                                      ? "/client/view/" +
                                        this.state.client_slug +
                                        "/crm-template/add"
                                      : "/crm-template/add"
                                  }
                                >
                                  <button className="btn-success-outline ">
                                    <img src={pluswIcon} alt="Add" /> Add
                                  </button>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Container className="p-0 container">
                      <div className="pd-lg-l-15 pd-xs-l-15">
                        {queryParams.isGlobal === "1" ? (
                          <>
                            <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                              {"Search applied on: " +
                                queryParams.global_col_label +
                                " - " +
                                queryParams.global_search}
                              <button
                                onClick={this.clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.is_search === 1 ? (
                          <>
                            <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0 d-inline-flex align-items-center justify-content-between scrolling-carousel">
                              {"Search applied on: " + this.state.module_search}
                              <button
                                onClick={this.clearFilter}
                                className="mg-l-15 btn-success-outline-small"
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="scrolling-carousel scroll-slide">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="all"
                            onSelect={(e) => this.getActiveClass(e)}
                            eventKey={this.state.activeClass}
                            className="pd-r-60"
                          >
                            <ScrollingCarousel
                              rightArrow={true}
                              leftArrow={true}
                              className="swipesection pd-t-20"
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="activeTab"
                                  id="activeTab"
                                  className={
                                    this.state.activeClass === "activeTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Active
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item>
                                <Nav.Link
                                  eventKey="inactiveTab"
                                  id="inactiveTab"
                                  className={
                                    this.state.activeClass === "inactiveTab"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Inactive
                                </Nav.Link>
                              </Nav.Item>
                            </ScrollingCarousel>
                            <Tab.Content>
                              <Tab.Pane eventKey="activeTab">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-0 brandList">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  ) : (
                                    <NoData msg="active templates" />
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="inactiveTab">
                                <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                                  {this.state.total_record > 0 ||
                                  this.state.filter_value !== "" ? (
                                    <div className="mg-t-0 brandList">
                                      <ReactDatatable
                                        className="table no-wrap mg-b-30 pd-b-2 brandtable"
                                        config={
                                          this.state.total_record >
                                          global.page_size
                                            ? this.state.config
                                            : this.state.config
                                        }
                                        records={this.state.records}
                                        columns={this.state.columns}
                                        extraButtons={this.state.extraButtons}
                                        dynamic={true}
                                        total_record={this.state.total_record}
                                        onChange={this.tableChangeHandler}
                                        onRowClicked={this.rowClickedHandler}
                                      />
                                    </div>
                                  ) : (
                                    <NoData msg="inactive tempaltes" />
                                  )}
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        <Modal
          show={this.state.isSearch}
          className="modal-large"
          centered
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this.filterData();
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.searchToggle()}>
              {global.closee}
            </span>
            <h3 className="text-center">Search in Neighborhood</h3>
            <div className="row col-12 m-0 pd-lg-l-15 pd-lg-r-15 pd-xs-l-0 pd-xs-r-0">
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>Email Template</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="template_name"
                      value={this.state.template_name}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>To</label>
                  <div className="col-md-12 p-0">
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      className="multiselect"
                      classNamePrefix={"my-custom-react-select"}
                      menuPlacement="auto"
                      value={this.state.selectedTypeList}
                      options={this.state.typeList}
                      onChange={(value) => this.handleChangeUserList(value)}
                      defaultValue={this.state.selectedTypeList}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 2,
                        colors: {
                          ...theme.colors,
                          primary: "#fff",
                          primary75: "#000",
                          primary50: "#000",
                          primary25: "#000",
                        },
                      })}
                    />
                  </div>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pd-lg-l-15 pd-lg-r-15 pd-sm-l-15 pd-sm-r-15 pd-xs-l-0 pd-xs-r-0">
                <InputGroup className="mt-3">
                  <label>From</label>
                  <div className="col-md-12 p-0">
                    <Form.Control
                      onChange={this.changeHandler}
                      name="from"
                      value={this.state.from}
                      className="form-control max_width_100"
                      id="inlineFormInput"
                      type="text"
                      maxLength="50"
                      autoComplete="off"
                    />
                  </div>
                </InputGroup>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.searchToggle()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-success btn-block"
                onClick={this.filterData}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "Search"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default CrmTemplates;

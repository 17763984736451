////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ReservationCalendar.jsx                            //
//  Application: Calendar                                 //
//  Option: List all reservations                         //
//  Developer: NP  						                  //
//  Date: 2022-07-29                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React from "react";
import { useCallback, useState } from "react";
import CalendarService from "../../services/CalendarService";
// import NoData from "../common/NoData";

// import AirbnbLogo from "../../assets/images/airbnb_logo.png"
// import { Eventcalendar, Popup, formatDate } from '@mobiscroll/react';
import {
  Eventcalendar,
  setOptions,
  CalendarNav,
  SegmentedGroup,
  SegmentedItem,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
  Popup,
  formatDate,
} from "@mobiscroll/react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import LeasingSidebar from "../common/LeasingSidebar";
import AllPropertyLeasingSidebar from "../common/AllPropertyLeasingSidebar";
import OutSidePropertySelectBox from "../outside-modules/OutSidePropertySelectBox";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const LeaseAvalCalendar = (props) => {
  const [view, setView] = React.useState("month");
  const [calView, setCalView] = React.useState({
    timeline: {
      type: "month",
    },
  });

  const [event, setEvent] = useState({});
  const [events, myEvents] = useState([]);
  const [resources, myResources] = useState([]);
  const [isPopupOpen, setOpen] = useState(false);
  // const property_slug = props.match.params.propertySlug;
  const [property_slug, setPropertySlug] = useState(
    props.match.params.propertySlug !== undefined
      ? props.match.params.propertySlug
      : localStorage.getItem("outSidePorpertySlug")
      ? localStorage.getItem("outSidePorpertySlug")
      : ""
  );
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  // const timerRef = React.useRef(null);
  // const [isTooltipOpen, setTooltipOpen] = useState(false);
  // const [time, setTime] = React.useState("");
  // const [title, setTitle] = React.useState("");
  // const [anchor, setAnchor] = React.useState(null);

  const onPageLoading = React.useCallback((event, inst) => {
    const year = event.month.getFullYear();
    const month = event.month.getMonth();
    if (props.match.params.propertySlug !== undefined) {
      setPropertySlug(props.match.params.propertySlug);
      setTimeout(() => {
        getReservationdata(props.match.params.propertySlug, year, month);
      }, 200);
    } else {
      const propSlug = localStorage.getItem("outSidePorpertySlug")
        ? localStorage.getItem("outSidePorpertySlug")
        : "";
      setPropertySlug(propSlug);
      setTimeout(() => {
        getReservationdata(
          localStorage.getItem("outSidePorpertySlug")
            ? localStorage.getItem("outSidePorpertySlug")
            : "",
          year,
          month
        );
      }, 200);
    }
  }, []);

  const getReservationdata = async (property_slug, year, month) => {
    setPropertySlug(property_slug);

    setYear(year);
    setMonth(month);

    let res = await CalendarService.getLeasing(
      "year=" +
        year +
        "&month=" +
        (month + 1) +
        "&property_slug=" +
        (property_slug ? property_slug : "")
    );

    if (global.successStatus.includes(res.status)) {
      myEvents(res.data.leasingData ? res.data.leasingData : []);
      myResources(res.data.unitData ? res.data.unitData : []);

      // let UnitDataListLength = res.data.unitData.length;
      // setUnitList(UnitDataListLength);
    }
  };

  const myScheduleEvent = React.useCallback((data) => {
    const ev = data.original;
    const color = data.original.color;
    // const color = "#2c5046";
    return (
      <div
        className="md-timeline-template-event"
        style={{ borderColor: color, background: color }}
      >
        <div className="md-timeline-template-event-cont">
          {/* <span className={"mbsc-icon mbsc-font-icon mbsc-icon-airplane"} style={{ background: color }}><img src={ev.img} alt="logo" /></span> */}
          <span className="md-timeline-template-title">{ev.title}</span>
        </div>
      </div>
    );
  }, []);

  const openPopup = useCallback((event) => {
    setOpen(true);
    setEvent(event);
  }, []);

  const closePopup = useCallback(() => {
    setOpen(false);
  }, []);

  const renderCustomResource = (resource) => {
    return (
      <div className="md-resource-header-template-cont" title="tile">
        <div className="md-resource-header-template-name">
          {resource.unit_name ? resource.unit_name : resource.name}
        </div>
        <div className="md-resource-header-template-name">
          {resource.first_name} {resource.last_name}
        </div>
        <div className="md-resource-header-template-name">
          {resource.resident_name}
        </div>
      </div>
    );
  };

  const renderMyHeader = () => {
    return (
      <React.Fragment>
        <CalendarPrev className="md-event-listing-prev" />
        <CalendarNav className="md-event-listing-nav" />
        <CalendarNext className="md-event-listing-next" />
        <div className="md-event-listing-picker">
          <SegmentedGroup value={view} onChange={changeView}>
            <SegmentedItem value="month">Month</SegmentedItem>
            <SegmentedItem value="year">Year</SegmentedItem>
          </SegmentedGroup>
        </div>
        <CalendarToday className="md-event-listing-today mr-4" />
      </React.Fragment>
    );
  };

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "day":
        calView = {
          timeline: {
            type: "day",
          },
        };
        break;
      case "week":
        calView = {
          timeline: {
            type: "week",
            eventList: true,
          },
        };
        break;
      case "year":
        calView = {
          timeline: {
            type: "year",
          },
        };
        break;
      case "month":
      default:
        calView = {
          timeline: {
            type: "month",
          },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };

  const getPropertySlugData = async (property_slug) => {
    setPropertySlug(property_slug);
    getReservationdata(property_slug, year, month);
  };

  return (
    <>
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              {props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <LeasingSidebar property_slug={property_slug} />
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <AllPropertyLeasingSidebar property_slug={property_slug} />
                  </div>
                </div>
              )}
              <div
                className={
                  props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                {/* <div className="col-md-12"> */}
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3 className="mb-0">Leasing Calendar</h3>
                  </div>
                </div>
                {window.location.pathname.includes(
                  "global-lease-avail-calendar"
                ) ? (
                  <div className="col-xxl-2 col-sm-3 pd-r-0 pd-lg-l-45 pd-xs-l-15 mg-b-45">
                    <OutSidePropertySelectBox
                      propertySlug={getPropertySlugData}
                      // outSidePropertySlug={outSidePropertySlug}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="scrolling-carousel pd-xs-l-0 pd-xs-r-0 pd-lg-l-45 pd-lg-r-30 mg-t-30">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="calendar-section pd-lg-l-0 pd-xs-l-15">
                      <Eventcalendar
                        theme="ios"
                        themeVariant="light"
                        clickToCreate={false}
                        dragToCreate={false}
                        dragToMove={false}
                        dragToResize={false}
                        view={calView}
                        data={events}
                        resources={resources}
                        renderScheduleEvent={myScheduleEvent}
                        cssClass="md-timeline-template md-event-listing"
                        onEventClick={(event) => openPopup(event)}
                        showEventTooltip={false}
                        // onEventHoverIn={onEventHoverIn}
                        // onEventHoverOut={onEventHoverOut}
                        renderResource={renderCustomResource}
                        renderHeader={renderMyHeader}
                        onPageLoading={onPageLoading}
                      />
                      <Popup
                        display="center"
                        // fullScreen={true}
                        contentPadding={true}
                        headerText={
                          event && event.event && event.event.title
                            ? event.event.title
                            : ""
                        }
                        isOpen={isPopupOpen}
                        onClose={closePopup}
                      >
                        <div className="text-center popup">
                          <span
                            className="popupClose hand-cursor"
                            onClick={closePopup}
                          >
                            {global.closee}
                          </span>
                          <p className="az-profile-name-text pl-0">
                            Check-in date & time{" "}
                          </p>{" "}
                          <p className="media-body">
                            {" "}
                            {event && event.event && event.event.start
                              ? formatDate(
                                  "MM/DD/YY ",
                                  new Date(event.event.start)
                                )
                              : ""}
                          </p>
                          <p className="az-profile-name-text pl-0">
                            Check-out date & time{" "}
                          </p>
                          <p className="media-body">
                            {" "}
                            {event && event.event && event.event.end
                              ? formatDate(
                                  "MM/DD/YY ",
                                  new Date(event.event.end)
                                )
                              : ""}
                          </p>
                          <Link
                            to={
                              "/properties/view/" +
                              property_slug +
                              "/leasing/" +
                              (event && event.event && event.event.lease_slug
                                ? event.event.lease_slug
                                : "") +
                              "/info"
                            }
                          >
                            <button className="mx-auto m-0 btn btn-outline-light btn-block dark-mode">
                              View
                            </button>
                          </Link>
                        </div>
                      </Popup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};
export default LeaseAvalCalendar;

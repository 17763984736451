////////////////////////////////////////////////////////////
//     							                          //
//  Program: EventTypeService.js                     //
//  Application: EventType                          //
//  Option: Used for manage EventType               //
//  Developer: Ashish Kumar  						      //
//  Date: 2022-01-11                                     //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class EventTypeService {
  getEventType(queryString) {
    let endPoint = "event-type" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createEventType(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "event-type";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getEventTypeBySlug(slug) {
    let endPoint = "event-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateEventType(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "event-type/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteEventType(slug) {
    let endPoint = "event-type/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
 
}

export default new EventTypeService();

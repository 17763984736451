////////////////////////////////////////////////////////////
//  Program: Inspection List.jsx                          //
//  Application:  Inspection  List                        //
//  Option: List of Template                              //
//  Developer: Ashish Kumar                               //
//  Date: 2022-05-20                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import InspectionService from "../../services/InspectionService";
import Moment from "moment";
import { Container } from "react-bootstrap";
import HouseKeepingSidebar from "../common/HouseKeepingSidebar";
import NoData from "../common/NoData";

export class Inspections extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      property_slug: this.props.match.params.propertySlug,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      total_record: 0,
      hideCommercial: 0,
      hideEquipment: 0,
      hideRoom: 0,
      records: [],
      checkList: [],
      config: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },
      config1: {
        sort: {
          column: "",
          order: "",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No data found",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "inspection_no",
          text: "Inspection No.",
          className: "inspection_no hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.inspection_no ? "#" + record.inspection_no : "";
          },
        },
        {
          key: "time_out",
          text: "Time Out",
          className: "time_out hand-cursor",
          sortable: true,
          cell: (record) => {
            return Moment(record.completed_on).format(global.dateTimeFormat);
          },
        },
        {
          key: "zone_type",
          text: "Applicable",
          className: "zone_type hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.zone_type ? record.zone_type.zone_type_name : "";
          },
        },
        {
          key: "technician",
          text: "Technician",
          className: "technician hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.inspector ? record.inspector.staff_contact_name : "";
          },
        },
        {
          key: "property",
          text: "Property",
          className: "property hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.property ? record.property.property_name : "";
          },
        },
        {
          key: "checklist",
          text: "Checklist",
          className: "checklist hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.checklist ? record.checklist.checklist_name : "";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
  }
  componentDidMount() {
    this.getData();
  }

  async getData(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    let res = await InspectionService.getInspectionList(
      "property_slug=" + this.state.property_slug + "&" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
    }
    this.setState({
      loading: false,
      records: list,
      total_record: res.data ? res.data.total : "",
    });

    this.setState({
      completed_on: Moment(list.completed_on).format(global.dateTimeFormat),
    });
  }

  customFilter() {
    let queryString =
      "is_filter=1&zone_type=" +
      this.state.zone_type +
      "&user_slug=" +
      this.state.user +
      "&checklist_slug=" +
      this.state.checklist_name;
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetData() {
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  getTitle() {
    return "Inspections";
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push(
      "/properties/view/" +
      this.state.property_slug +
      "/inspections/" +
      data.slug
    );
  };
  render() {
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <HouseKeepingSidebar
                    property_slug={this.state.property_slug}
                  />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-15 pd-r-15">
                  <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                    <div className="col-md-12 pl-0 pr-0">
                      {this.state.total_record !== 0 ||
                        this.state.filter_value !== "" ? (
                        <div className="mg-t-18 brandList pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-0">
                          <div className="pd-lg-l-0 pd-xs-l-15">
                          <ReactDatatable
                            className="table mg-b-30 pd-b-2 add-no-wrap"
                            config={
                              this.state.total_record > global.page_size
                                ? this.state.config
                                : this.state.config
                            }
                            records={this.state.records}
                            columns={this.state.columns}
                            extraButtons={this.state.extraButtons}
                            dynamic={true}
                            // loading={this.state.loading}
                            total_record={this.state.total_record}
                            onChange={this.tableChangeHandler}
                            onRowClicked={this.rowClickedHandler}
                          />
                          </div>
                        </div>
                      ) : (
                        <NoData />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    );
  }
}

export default Inspections;
